import React, { useEffect, useState, useRef } from 'react';

import { useRecoilState } from 'recoil';
import { pathAtom, loadingAtom, returnDateAtom, gstrUserAtom, userIDAtom } from '../../core/config/atoms';

import './css/main.css';
import './css/style.css';
import './css/summery.css';

import Moment from 'moment';
import html2pdf from 'html2pdf.js/dist/html2pdf';

import imgLoader from '../../assets/images/cloading.gif';
import ServiceGSTR from '../../services/serviceGSTR';
import PanelSummeryPDF from './PanelSummeryPDF';

export default function PanelGSTRSummry() {

    const [path, setPath] = useRecoilState(pathAtom);
    const [loading, setLoading] = useRecoilState(loadingAtom);
    const [gDate] = useRecoilState(returnDateAtom);
    const [GSTUser] = useRecoilState(gstrUserAtom);
    const [userID] = useRecoilState(userIDAtom);

    const pdfRef = useRef(null);

    const [mainData, setMainData] = useState({});
    const [gstrFile, setGSTRFile] = useState({});

    const [open, setOpen] = useState(true);
    const [openMain, setOpenMain] = useState(false);
    const [tabB2BA, setTabB2BA] = useState(false);
    const [tabB2BB, setTabB2BB] = useState(false);
    const [tabB2CO, setTabB2CO] = useState(false);
    const [tab9CDNR, setTab9CDNR] = useState(false);
    const [tab9CDRA, setTab9CDRA] = useState(false);
    const [tab9CDRB, setTab9CDRB] = useState(false);
    const [tab9CDNUR, setTab9CDNUR] = useState(false);
    const [tab9CDNRA, setTab9CDNRA] = useState(false);
    const [tab9CDRAA, setTab9CDRAA] = useState(false);
    const [tab9CDRAB, setTab9CDRAB] = useState(false);
    const [tab9CDNURA, setTab9CDNURA] = useState(false);
    const [tabDoc, setTabDoc] = useState(false);

    const returnService = new ServiceGSTR();

    const [isPrint, setIsPrint] = useState(false);

    const collapseAll = () => {
        setTabB2BA(false);
        setTabB2BB(false);
        setTabB2CO(false);
        setTab9CDNR(false);
        setTab9CDRA(false);
        setTab9CDRB(false);
        setTab9CDNUR(false);
        setTab9CDNRA(false);
        setTab9CDNURA(false);
        setTabDoc(false);
        setOpenMain(false);
    }

    const expandAll = () => {
        setTabB2BA(true);
        setTabB2BB(true);
        setTabB2CO(true);
        setTab9CDNR(true);
        setTab9CDRA(true);
        setTab9CDRB(true);
        setTab9CDNUR(true);
        setTab9CDNRA(true);
        setTab9CDNURA(true);
        setTabDoc(true);
        setOpenMain(true);
    }

    const gotoPath = (path) => {
        setLoading(true);
        setPath(path);
    }

    const getStatus = () => {
        switch (gstrFile.status) {
            case "new":
                return "Not Filed";
            case "draft":
                return "Ready to File";
            case "done":
                return "Filed";
        }
    }

    const getStartY = () => {
        if (new Date(Moment(gDate).format('MM-DD-yyyy')).getMonth() < 3) {
            return Moment(gDate).subtract(1, 'year').format('YYYY');
        } else {
            return Moment(gDate).format('YYYY');
        }
    }

    const getEndY = () => {
        if (new Date(Moment(gDate).format('MM-DD-yyyy')).getMonth() < 3) {
            return Moment(gDate).format('YY');
        } else {
            return Moment(gDate).add(1, 'year').format('YY');
        }
    }

    const loadData = () => {
        setLoading(true);
        (async function () {
            let body = {
                'lid': userID,
                'month': gDate,
            }
            var resB = await returnService.getGstrSummary(body);
            setMainData(resB.data.data);

            var resFiles = await returnService.getGstrFiles(body);
            setGSTRFile(resFiles.data);

            setLoading(false);
            window.scrollTo({
                top: 0,
                behavior: 'smooth',
            });
        })();
    }

    const handleGeneratePDF = async () => {
        const subComponent = pdfRef.current;
        const options = {
            margin: 0.2,
            filename: 'GSTR1 Return Summary.pdf',
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: { scale: 2 },
            jsPDF: { unit: 'mm', format: 'a4', orientation: 'landscape' },
            preserve: true,
        };
        html2pdf().set(options).from(subComponent).save().then(() => {
            setIsPrint(false);
        });
    };

    useEffect(() => {
        loadData();
    }, []);

    return (
        <div class="content-wrapper fade-ng-cloak">
            <div class="dimmer-holder" style={{ display: 'none' }}>
                <div id="dimmer"></div>
            </div>
            {
                loading && <div className='absolute z-50 w-full h-screen flex items-center justify-center bg-gray-200 bg-opacity-25'>
                    <img src={imgLoader} alt="loader" />
                </div>
            }
            <div class="container">
                <div class="mypage">
                    <div class="row">
                        <div class="col-xs-10">
                            <div>
                                <ol class="breadcrumb">
                                    <li>
                                        <a onClick={() => { gotoPath('gstr') }}>Dashboard</a>
                                    </li>
                                    <li>
                                        <a onClick={() => { gotoPath('gstRetrun') }}>Returns</a>
                                    </li>
                                    <li>
                                        <a onClick={() => { gotoPath('gstrForm') }}>GSTR-1/IFF</a>
                                    </li>
                                    <li>Summary</li>
                                </ol>
                            </div>
                        </div>
                        <div class="col-xs-2">
                            <div class="lang dropdown">
                                <span class="dropdown-toggle" data-toggle="dropdown" data-ng-bind="selectedLang">English</span>
                                <ul class="dropdown-menu lang-dpdwn">
                                    <li data-ng-repeat="language in languages">
                                        <a data-ng-click="selectLang(language.cd)" data-ng-bind="language.nm">English</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div data-ng-controller="mainctrl" ng-view="">
                        <div>
                            <div id="mai">
                                <div class="row" style={{ marginRight: 'auto' }}>
                                    <button class="btn btn-primary btn-circle btn-sm pull-right" data-ng-click="getSummary()">
                                        <i class="fa fa-refresh" aria-hidden="true"></i>
                                    </button>
                                </div>

                                <div class="row">
                                    <div class="col-sm-12 col-xs-12">
                                        <div class="panel panel-default ret">
                                            <div class="panel-body" style={{ padding: '5px 15px' }}>
                                                <div class="row">
                                                    <div class="col-sm-4">
                                                        <span class="reg">GSTIN - {GSTUser.gstn}</span>
                                                    </div>
                                                    <div class="col-sm-4">
                                                        <span class="reg">Legal Name - {GSTUser.buzName}</span>
                                                    </div>
                                                    <div class="col-sm-4">
                                                        <span class="reg">Trade Name - {GSTUser.tname}</span>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-sm-4">
                                                        <span class="reg">FY -&nbsp;{getStartY()}-{getEndY()}</span>
                                                    </div>
                                                    <div class="col-sm-4">
                                                        <span class="reg">Tax Period -&nbsp;
                                                            <span>{Moment(gDate).format("MMMM")}</span>
                                                        </span>
                                                    </div>
                                                    <div class="col-sm-4">
                                                        <span class="reg">Status - {getStatus()}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="tabpane" style={{ minHeight: '263px !important' }}>
                                    <div class="col-sm-12 head padding10x10">
                                        <button class="btn btn-new col-sm-12 pull-left" data-ng-click="tog =!tog" style={{ borderRadius: '0em', textAlign: 'left' }} onClick={() => {
                                            setOpen(!open);
                                        }}>Consolidated Summary
                                            {
                                                !open && <span data-ng-hide="!tog" class="pull-right ng-hide">
                                                    <i class="fa fa-fw fa-chevron-down"></i>
                                                </span>
                                            }
                                            {
                                                open && <span data-ng-show="!tog" class="pull-right">
                                                    <i class="fa fa-fw fa-chevron-up"></i>
                                                </span>
                                            }
                                        </button>
                                    </div>
                                    {
                                        (mainData.total !== undefined && open) && <div class="panel-body" data-ng-show="!tog">
                                            <br />
                                            <div class="row">
                                                <div class="col-sm-12">
                                                    <table class="table table-bordered table-responsive">
                                                        <thead style={{ background: '#F8F8F8' }}>
                                                            <tr style={{ backgroundColor: '#c9ece1', color: 'black' }}>
                                                                <th class="text-center verticalCenter" style={{ display: 'table-cell', minWidth: '24em' }} rowspan="2" colspan="2">Description
                                                                    {
                                                                        !openMain && <span data-ng-hide="show_All" style={{ color: '#005b9f' }} className="cursor-pointer" onClick={() => {
                                                                            expandAll();
                                                                        }}>
                                                                            [Expand All <i class="fa fa-fw fa-chevron-down"></i>]
                                                                        </span>
                                                                    }
                                                                    {
                                                                        openMain && <span data-ng-show="show_All" style={{ color: '#005b9f' }} className="cursor-pointer" onClick={() => {
                                                                            collapseAll();
                                                                        }}>
                                                                            [Collapse All <i class="fa fa-fw fa-chevron-up"></i>]
                                                                        </span>
                                                                    }
                                                                </th>
                                                                <th class="text-center verticalCenter" rowspan="2">No. of records</th>
                                                                <th class="text-center verticalCenter" style={{ paddingLeft: '19px', paddingRight: '19px' }} rowspan="2" data-ng-bind="trans.HEAD_DOC_TYPE">Document Type</th>
                                                                <th class="text-center verticalCenter" rowspan="2">Value (₹)</th>
                                                                <th class="text-center verticalCenter">Integrated tax (₹)</th>
                                                                <th class="text-center verticalCenter">Central tax (₹)</th>
                                                                <th class="text-center verticalCenter">State/UT tax (₹)</th>
                                                                <th class="text-center verticalCenter">Cess (₹)</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {/* <!-- 4A - B2B SUM --> */}
                                                            <tr data-ng-if="b2b4adat">
                                                                <td class="antiqueWhite" colspan="9">4A - Taxable outward supplies made to registered persons (other than reverse charge supplies) - B2B Regular</td>
                                                            </tr>
                                                            <tr class="" data-ng-if="b2b4adat">
                                                                <td colspan="2">Total</td>
                                                                <td class="verticalCenter text-center" data-ng-bind="b2b4adat.ttl_rec">{(mainData.b2bA.total.count)}</td>
                                                                <td class="verticalCenter text-center">Invoice</td>
                                                                <td class="verticalCenter text-right">{(mainData.b2bA.total.value).toFixed(2)}</td>
                                                                <td class="verticalCenter text-right">{(mainData.b2bA.total.taxa).toFixed(2)}</td>
                                                                <td class="verticalCenter text-right">{(mainData.b2bA.total.taxb).toFixed(2)}</td>
                                                                <td class="verticalCenter text-right">{(mainData.b2bA.total.taxb).toFixed(2)}</td>
                                                                <td class="verticalCenter text-right">{(mainData.b2bA.total.cess).toFixed(2)}</td>
                                                            </tr>
                                                            {
                                                                mainData.b2bA.data.length !== 0 && <tr data-ng-if="b2b4adat &amp;&amp; !isCptyLimitExceeded" data-ng-hide="(!b2b4adat.cpty_sum || b2b4adat.cpty_sum.length == 0) &amp;&amp; b2b4adat.ttl_rec == 0">
                                                                    <td colspan="9">
                                                                        <div data-ng-click="collapseSummary('b2b4aShow');getSummary('L')">Recipient wise summary
                                                                            {
                                                                                !tabB2BA && <span data-ng-hide="b2b4aShow" class="cursor-pointer" onClick={() => {
                                                                                    setTabB2BA(true);
                                                                                }}>
                                                                                    <i class="fa fa-fw fa-chevron-down"></i>
                                                                                </span>
                                                                            }
                                                                            {
                                                                                tabB2BA && <span data-ng-show="b2b4aShow" class="cursor-pointer" onClick={() => {
                                                                                    setTabB2BA(false);
                                                                                }}>
                                                                                    <i class="fa fa-fw fa-chevron-up"></i>
                                                                                </span>
                                                                            }
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            }
                                                            {
                                                                tabB2BA && mainData.b2bA.data.map((item) => {
                                                                    return <tr data-ng-show="b2b4aShow" data-ng-if="b2b4adat &amp;&amp; !isCptyLimitExceeded" data-ng-repeat="cpty in b2b4adat.cpty_sum">
                                                                        <td class="verticalCenter subsec" data-ng-bind="cpty.ctin">{item.gstn}</td>
                                                                        <td class="verticalCenter text-center" data-ng-bind="cpty.trade_name">{item.name}</td>
                                                                        <td class="verticalCenter text-center" data-ng-bind="cpty.ttl_rec">{item.count}</td>
                                                                        <td class="verticalCenter text-center">Invoice</td>
                                                                        <td class="verticalCenter text-right">{item.value.toFixed(2)}</td>
                                                                        <td class="verticalCenter text-right">{item.taxa.toFixed(2)}</td>
                                                                        <td class="verticalCenter text-right">{item.taxb.toFixed(2)}</td>
                                                                        <td class="verticalCenter text-right">{item.taxb.toFixed(2)}</td>
                                                                        <td class="verticalCenter text-right">{item.cess.toFixed(2)}</td>
                                                                    </tr>
                                                                })
                                                            }

                                                            {/* <!-- 4B - B2B SUMM --> */}
                                                            <tr>
                                                                <td class="antiqueWhite" colspan="9">4B - Taxable outward supplies made to registered persons attracting tax on reverse charge - B2B Reverse charge</td>
                                                            </tr>
                                                            <tr class="">
                                                                <td colspan="2">Total</td>
                                                                <td class="verticalCenter text-center">{(mainData.b2bB.total.count)}</td>
                                                                <td class="verticalCenter text-center">Invoice</td>
                                                                <td class="verticalCenter text-right">{(mainData.b2bB.total.value).toFixed(2)}</td>
                                                                <td class="verticalCenter text-right">{(mainData.b2bB.total.taxa).toFixed(2)}</td>
                                                                <td class="verticalCenter text-right">{(mainData.b2bB.total.taxb).toFixed(2)}</td>
                                                                <td class="verticalCenter text-right">{(mainData.b2bB.total.taxb).toFixed(2)}</td>
                                                                <td class="verticalCenter text-right">{(mainData.b2bB.total.cess).toFixed(2)}</td>
                                                            </tr>
                                                            {
                                                                mainData.b2bB.data.length !== 0 && <tr>
                                                                    <td colspan="9">
                                                                        <div data-ng-click="collapseSummary('b2b4aShow');getSummary('L')">Recipient wise summary
                                                                            {
                                                                                !tabB2BB && <span data-ng-hide="b2b4aShow" class="cursor-pointer" onClick={() => {
                                                                                    setTabB2BB(true);
                                                                                }}>
                                                                                    <i class="fa fa-fw fa-chevron-down"></i>
                                                                                </span>
                                                                            }
                                                                            {
                                                                                tabB2BB && <span data-ng-show="b2b4aShow" class="cursor-pointer" onClick={() => {
                                                                                    setTabB2BB(false);
                                                                                }}>
                                                                                    <i class="fa fa-fw fa-chevron-up"></i>
                                                                                </span>
                                                                            }
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            }
                                                            {
                                                                tabB2BB && mainData.b2bB.data.map((item) => {
                                                                    return <tr data-ng-show="b2b4aShow" data-ng-if="b2b4adat &amp;&amp; !isCptyLimitExceeded" data-ng-repeat="cpty in b2b4adat.cpty_sum">
                                                                        <td class="verticalCenter subsec" data-ng-bind="cpty.ctin">{item.gstn}</td>
                                                                        <td class="verticalCenter text-center" data-ng-bind="cpty.trade_name">{item.name}</td>
                                                                        <td class="verticalCenter text-center" data-ng-bind="cpty.ttl_rec">{item.count}</td>
                                                                        <td class="verticalCenter text-center">Invoice</td>
                                                                        <td class="verticalCenter text-right">{item.value.toFixed(2)}</td>
                                                                        <td class="verticalCenter text-right">{item.taxa.toFixed(2)}</td>
                                                                        <td class="verticalCenter text-right">{item.taxb.toFixed(2)}</td>
                                                                        <td class="verticalCenter text-right">{item.taxb.toFixed(2)}</td>
                                                                        <td class="verticalCenter text-right">{item.cess.toFixed(2)}</td>
                                                                    </tr>
                                                                })
                                                            }

                                                            {/* <!-- 5A-B2CL SUMM --> */}
                                                            <tr data-ng-if="b2cldat &amp;&amp; !IFF">
                                                                <td class="antiqueWhite" colspan="9" data-ng-bind="trans.LBL_SUM_5A_B2CL">5A - Taxable outward inter-state supplies made to unregistered persons (where invoice value is more than Rs.2.5 lakh) - B2CL (Large)</td>
                                                            </tr>
                                                            <tr class="" data-ng-if="b2cldat &amp;&amp; !IFF">
                                                                <td colspan="2">Total</td>
                                                                <td class="verticalCenter text-center">{mainData.b2cl.total.count}</td>
                                                                <td class="verticalCenter text-center">Invoice</td>
                                                                <td class="verticalCenter text-right">{(mainData.b2cl.total.value).toFixed(2)}</td>
                                                                <td class="verticalCenter text-right">{(mainData.b2cl.total.taxa).toFixed(2)}</td>
                                                                <td class="verticalCenter greyCell"></td>
                                                                <td class="verticalCenter greyCell"></td>
                                                                <td class="verticalCenter text-right">{(mainData.b2cl.total.cess).toFixed(2)}</td>
                                                            </tr>

                                                            {/* <!-- 6A - EXP SUM --> */}
                                                            <tr data-ng-if="expdat &amp;&amp; !IFF">
                                                                <td class="antiqueWhite" colspan="9" data-ng-bind="trans.LBL_SUM_6A_EXP">6A - Exports</td>
                                                            </tr>
                                                            <tr class="" data-ng-if="expdat &amp;&amp; !IFF">
                                                                <td colspan="2">Total</td>
                                                                <td class="verticalCenter text-center">{mainData.a6.total.count}</td>
                                                                <td class="verticalCenter text-center">Invoice</td>
                                                                <td class="verticalCenter text-right">{(mainData.a6.total.value).toFixed(2)}</td>
                                                                <td class="verticalCenter text-right">{(mainData.a6.total.taxa).toFixed(2)}</td>
                                                                <td class="verticalCenter greyCell"></td>
                                                                <td class="verticalCenter greyCell"></td>
                                                                <td class="verticalCenter text-right">{(mainData.a6.total.cess).toFixed(2)}</td>
                                                            </tr>
                                                            <tr data-ng-if="expdat &amp;&amp; !IFF" data-ng-repeat="sec in expdat.sub_sections | orderBy : 'typ.length'">
                                                                <td colspan="2" class="subsec">EXPWP</td>
                                                                <td class="verticalCenter text-center">{mainData.a6.wp.count}</td>
                                                                <td class="verticalCenter text-center">Invoice</td>
                                                                <td class="verticalCenter text-right">{(mainData.a6.wp.value).toFixed(2)}</td>
                                                                <td class="verticalCenter text-right">{(mainData.a6.wp.taxa).toFixed(2)}</td>
                                                                <td class="verticalCenter greyCell"></td>
                                                                <td class="verticalCenter greyCell"></td>
                                                                <td class="verticalCenter text-right">{(mainData.a6.total.cess).toFixed(2)}</td>
                                                            </tr>
                                                            <tr data-ng-if="expdat &amp;&amp; !IFF" data-ng-repeat="sec in expdat.sub_sections | orderBy : 'typ.length'">
                                                                <td colspan="2" class="subsec">EXPWOP</td>
                                                                <td class="verticalCenter text-center">{mainData.a6.wop.count}</td>
                                                                <td class="verticalCenter text-center">Invoice</td>
                                                                <td class="verticalCenter text-right">{(mainData.a6.wop.value).toFixed(2)}</td>
                                                                <td class="verticalCenter greyCell"></td>
                                                                <td class="verticalCenter greyCell"></td>
                                                                <td class="verticalCenter greyCell"></td>
                                                                <td class="verticalCenter greyCell"></td>
                                                            </tr>

                                                            {/* <!-- 6B - SEZ DEVELOPER --> */}
                                                            <tr data-ng-if="b2bwpdat || b2bwopdat">
                                                                <td class="antiqueWhite" colspan="9" data-ng-bind="trans.LBL_SUM_6B_SEZ">6B - Supplies made to SEZ unit or SEZ developer - SEZWP/SEZWOP</td>
                                                            </tr>
                                                            <tr class="" data-ng-if="b2bwpdat || b2bwopdat">
                                                                <td colspan="2">Total</td>
                                                                <td class="verticalCenter text-center">0</td>
                                                                <td class="verticalCenter text-center">Invoice</td>
                                                                <td class="verticalCenter text-right">0.00</td>
                                                                <td class="verticalCenter text-right">0.00</td>
                                                                <td class="verticalCenter greyCell"></td>
                                                                <td class="verticalCenter greyCell"></td>
                                                                <td class="verticalCenter text-right">0.00</td>
                                                            </tr>
                                                            <tr class="" data-ng-if="b2bwpdat">
                                                                <td colspan="2" class="subsec" data-ng-bind="trans.LBL_SUM_SEZWP">SEZWP</td>
                                                                <td class="verticalCenter text-center" data-ng-bind="b2bwpdat.ttl_rec">0</td>
                                                                <td class="verticalCenter text-center">Invoice</td>
                                                                <td class="verticalCenter text-right">0.00</td>
                                                                <td class="verticalCenter text-right">0.00</td>
                                                                <td class="verticalCenter greyCell"></td>
                                                                <td class="verticalCenter greyCell"></td>
                                                                <td class="verticalCenter text-right">0.00</td>
                                                            </tr>
                                                            <tr class="" data-ng-if="b2bwopdat">
                                                                <td colspan="2" class="subsec" data-ng-bind="trans.LBL_SUM_SEZWOP">SEZWOP</td>
                                                                <td class="verticalCenter text-center" data-ng-bind="b2bwopdat.ttl_rec">0</td>
                                                                <td class="verticalCenter text-center">Invoice</td>
                                                                <td class="verticalCenter text-right">0.00</td>
                                                                <td class="verticalCenter greyCell"></td>
                                                                <td class="verticalCenter greyCell"></td>
                                                                <td class="verticalCenter greyCell"></td>
                                                                <td class="verticalCenter greyCell"></td>
                                                            </tr>

                                                            {/* <!-- 6C - DEEMED EXPORTS --> */}
                                                            <tr data-ng-if="b2b6cdat">
                                                                <td class="antiqueWhite" colspan="9" data-ng-bind="trans.LBL_SUM_6C_DEXP">6C - Deemed Exports - DE</td>
                                                            </tr>
                                                            <tr class="" data-ng-if="b2b6cdat">
                                                                <td colspan="2">Total</td>
                                                                <td class="verticalCenter text-center" data-ng-bind="b2b6cdat.ttl_rec">0</td>
                                                                <td class="verticalCenter text-center">Invoice</td>
                                                                <td class="verticalCenter text-right">0.00</td>
                                                                <td class="verticalCenter text-right">0.00</td>
                                                                <td class="verticalCenter text-right">0.00</td>
                                                                <td class="verticalCenter text-right">0.00</td>
                                                                <td class="verticalCenter text-right">0.00</td>
                                                            </tr>


                                                            {/* <!-- 7 - B2CS SUM --> */}
                                                            <tr data-ng-if="b2csdat &amp;&amp; !IFF">
                                                                <td class="antiqueWhite" colspan="9" data-ng-bind="trans.LBL_SUM_7_B2CS">7 - Taxable supplies (Net of debit notes and credit notes) to unregistered persons other than the supplies covered in Table 5 - B2CS (Others)</td>
                                                            </tr>
                                                            <tr class="" data-ng-if="b2csdat &amp;&amp; !IFF">
                                                                <td colspan="2">Total</td>
                                                                <td class="verticalCenter text-center">{mainData.b2co.total.count}</td>
                                                                <td class="verticalCenter text-center">Net Value</td>
                                                                <td class="verticalCenter text-right">{(mainData.b2co.total.value).toFixed(2)}</td>
                                                                <td class="verticalCenter text-right">{(mainData.b2co.total.taxa).toFixed(2)}</td>
                                                                <td class="verticalCenter text-right">{(mainData.b2co.total.taxb).toFixed(2)}</td>
                                                                <td class="verticalCenter text-right">{(mainData.b2co.total.taxb).toFixed(2)}</td>
                                                                <td class="verticalCenter text-right">{(mainData.b2co.total.cess).toFixed(2)}</td>
                                                            </tr>
                                                            {
                                                                mainData.b2co.data.length !== 0 && <tr>
                                                                    <td colspan="9">
                                                                        <div>POS wise summary
                                                                            {
                                                                                !tabB2CO && <span data-ng-hide="b2b4aShow" class="cursor-pointer" onClick={() => {
                                                                                    setTabB2CO(true);
                                                                                }}>
                                                                                    <i class="fa fa-fw fa-chevron-down"></i>
                                                                                </span>
                                                                            }
                                                                            {
                                                                                tabB2CO && <span data-ng-show="b2b4aShow" class="cursor-pointer" onClick={() => {
                                                                                    setTabB2CO(false);
                                                                                }}>
                                                                                    <i class="fa fa-fw fa-chevron-up"></i>
                                                                                </span>
                                                                            }
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            }
                                                            {
                                                                tabB2CO && mainData.b2co.data.map((item) => {
                                                                    return <tr>
                                                                        <td colspan="2" class="verticalCenter subsec">{item.pos}</td>
                                                                        <td class="verticalCenter text-center">{item.count}</td>
                                                                        <td class="verticalCenter text-center">Invoice</td>
                                                                        <td class="verticalCenter text-right">{item.value.toFixed(2)}</td>
                                                                        <td class="verticalCenter text-right">{item.taxa.toFixed(2)}</td>
                                                                        <td class="verticalCenter text-right">{item.taxb.toFixed(2)}</td>
                                                                        <td class="verticalCenter text-right">{item.taxb.toFixed(2)}</td>
                                                                        <td class="verticalCenter text-right">{item.cess.toFixed(2)}</td>
                                                                    </tr>
                                                                })
                                                            }

                                                            {/* <!-- 8 - NIL SUM --> */}
                                                            <tr data-ng-if="nildat &amp;&amp; !IFF">
                                                                <td class="antiqueWhite" colspan="9" data-ng-bind="trans.LBL_SUM_8_NIL">8 - Nil rated, exempted and non GST outward supplies</td>
                                                            </tr>
                                                            <tr class="" data-ng-if="nildat &amp;&amp; !IFF">
                                                                <td colspan="4">Total</td>
                                                                <td class="verticalCenter text-right">{(mainData.nill.total.total).toFixed(2)}</td>
                                                                <td class="verticalCenter greyCell"></td>
                                                                <td class="verticalCenter greyCell"></td>
                                                                <td class="verticalCenter greyCell"></td>
                                                                <td class="verticalCenter greyCell"></td>
                                                            </tr>
                                                            <tr data-ng-if="nildat &amp;&amp; !IFF">
                                                                <td colspan="4" class="subsec">Nil</td>
                                                                <td class="verticalCenter text-right">{(mainData.nill.total.vala).toFixed(2)}</td>
                                                                <td class="verticalCenter greyCell"></td>
                                                                <td class="verticalCenter greyCell"></td>
                                                                <td class="verticalCenter greyCell"></td>
                                                                <td class="verticalCenter greyCell"></td>
                                                            </tr>
                                                            <tr data-ng-if="nildat &amp;&amp; !IFF">
                                                                <td colspan="4" class="subsec">Exempted</td>
                                                                <td class="verticalCenter text-right">{(mainData.nill.total.valb).toFixed(2)}</td>
                                                                <td class="verticalCenter greyCell"></td>
                                                                <td class="verticalCenter greyCell"></td>
                                                                <td class="verticalCenter greyCell"></td>
                                                                <td class="verticalCenter greyCell"></td>
                                                            </tr>
                                                            <tr data-ng-if="nildat &amp;&amp; !IFF">
                                                                <td colspan="4" class="subsec">Non-GST</td>
                                                                <td class="verticalCenter text-right">{(mainData.nill.total.valc).toFixed(2)}</td>
                                                                <td class="verticalCenter greyCell"></td>
                                                                <td class="verticalCenter greyCell"></td>
                                                                <td class="verticalCenter greyCell"></td>
                                                                <td class="verticalCenter greyCell"></td>
                                                            </tr>

                                                            {/* <!-- 9A - AMENDMENT B2B REG --> */}
                                                            <tr data-ng-if="b2ba4adat">
                                                                <td class="antiqueWhite" colspan="9" data-ng-bind="trans.LBL_SUM_9A_B2B">9A - Amendment to taxable outward supplies made to registered person in returns of earlier tax periods in table 4 - B2B Regular</td>
                                                            </tr>
                                                            <tr class="" data-ng-if="b2ba4adat">
                                                                <td colspan="2">Amended amount - Total</td>
                                                                <td class="verticalCenter text-center" data-ng-bind="b2ba4adat.ttl_rec">{(mainData.b2bAA.total.count)}</td>
                                                                <td class="verticalCenter text-center">Invoice</td>
                                                                <td class="verticalCenter text-right">{(mainData.b2bAA.total.value).toFixed(2)}</td>
                                                                <td class="verticalCenter text-right">{(mainData.b2bAA.total.taxa).toFixed(2)}</td>
                                                                <td class="verticalCenter text-right">{(mainData.b2bAA.total.taxb).toFixed(2)}</td>
                                                                <td class="verticalCenter text-right">{(mainData.b2bAA.total.taxb).toFixed(2)}</td>
                                                                <td class="verticalCenter text-right">{(mainData.b2bAA.total.cess).toFixed(2)}</td>
                                                            </tr>
                                                            <tr class="" data-ng-if="b2ba4adat">
                                                                <td colspan="2">Net differential amount (Amended - Original)</td>
                                                                <td class="verticalCenter text-center"></td>
                                                                <td class="verticalCenter text-center"></td>
                                                                <td class="verticalCenter text-right">{(mainData.b2bAA.total.value - (mainData.b2bAA.ototal ? mainData.b2bAA.ototal.value : 0)).toFixed(2)}</td>
                                                                <td class="verticalCenter text-right">{(mainData.b2bAA.total.taxa - (mainData.b2bAA.ototal ? mainData.b2bAA.ototal.taxa : 0)).toFixed(2)}</td>
                                                                <td class="verticalCenter text-right">{(mainData.b2bAA.total.taxb - (mainData.b2bAA.ototal ? mainData.b2bAA.ototal.taxb : 0)).toFixed(2)}</td>
                                                                <td class="verticalCenter text-right">{(mainData.b2bAA.total.taxb - (mainData.b2bAA.ototal ? mainData.b2bAA.ototal.taxb : 0)).toFixed(2)}</td>
                                                                <td class="verticalCenter text-right">{(mainData.b2bAA.total.cess - (mainData.b2bAA.ototal ? mainData.b2bAA.ototal.cess : 0)).toFixed(2)}</td>
                                                            </tr>

                                                            {/* <!-- 9A - AMENDMENT B2B REVERSE --> */}
                                                            <tr data-ng-if="b2ba4bdat">
                                                                <td class="antiqueWhite" colspan="9" data-ng-bind="trans.LBL_SUM_9A_B2B_REV">9A - Amendment to taxable outward supplies made to registered person in returns of earlier tax periods in table 4 - B2B Reverse charge</td>
                                                            </tr>
                                                            <tr class="" data-ng-if="b2ba4adat">
                                                                <td colspan="2">Amended amount - Total</td>
                                                                <td class="verticalCenter text-center" data-ng-bind="b2ba4bdat.ttl_rec">{(mainData.b2bAB.total.count)}</td>
                                                                <td class="verticalCenter text-center">Invoice</td>
                                                                <td class="verticalCenter text-right">{(mainData.b2bAB.total.value).toFixed(2)}</td>
                                                                <td class="verticalCenter text-right">{(mainData.b2bAB.total.taxa).toFixed(2)}</td>
                                                                <td class="verticalCenter text-right">{(mainData.b2bAB.total.taxb).toFixed(2)}</td>
                                                                <td class="verticalCenter text-right">{(mainData.b2bAB.total.taxb).toFixed(2)}</td>
                                                                <td class="verticalCenter text-right">{(mainData.b2bAB.total.cess).toFixed(2)}</td>
                                                            </tr>
                                                            <tr class="" data-ng-if="b2ba4bdat">
                                                                <td colspan="2">Net differential amount (Amended - Original)</td>
                                                                <td class="verticalCenter text-center"></td>
                                                                <td class="verticalCenter text-center"></td>
                                                                <td class="verticalCenter text-right">{(mainData.b2bAB.total.value - (mainData.b2bAB.ototal ? mainData.b2bAB.ototal.value : 0)).toFixed(2)}</td>
                                                                <td class="verticalCenter text-right">{(mainData.b2bAB.total.taxa - (mainData.b2bAB.ototal ? mainData.b2bAB.ototal.taxa : 0)).toFixed(2)}</td>
                                                                <td class="verticalCenter text-right">{(mainData.b2bAB.total.taxb - (mainData.b2bAB.ototal ? mainData.b2bAB.ototal.taxb : 0)).toFixed(2)}</td>
                                                                <td class="verticalCenter text-right">{(mainData.b2bAB.total.taxb - (mainData.b2bAB.ototal ? mainData.b2bAB.ototal.taxb : 0)).toFixed(2)}</td>
                                                                <td class="verticalCenter text-right">{(mainData.b2bAB.total.cess - (mainData.b2bAB.ototal ? mainData.b2bAB.ototal.cess : 0)).toFixed(2)}</td>
                                                            </tr>

                                                            {/* <!-- 9A - B2CLA SUM --> */}
                                                            <tr data-ng-if="b2cladat &amp;&amp; !IFF">
                                                                <td class="antiqueWhite" colspan="9" data-ng-bind="trans.LBL_SUM_9A_B2CL">9A - Amendment to Inter-State supplies made to unregistered person (where invoice value is more than Rs.2.5 lakh) in returns of earlier tax periods in table 5 - B2CL (Large)</td>
                                                            </tr>
                                                            <tr class="" data-ng-if="b2cladat &amp;&amp; !IFF">
                                                                <td colspan="2">Amended amount - Total</td>
                                                                <td class="verticalCenter text-center">{mainData.b2cla.total.count}</td>
                                                                <td class="verticalCenter text-center">Invoice</td>
                                                                <td class="verticalCenter text-right">{(mainData.b2cla.total.value).toFixed(2)}</td>
                                                                <td class="verticalCenter text-right">{(mainData.b2cla.total.taxa).toFixed(2)}</td>
                                                                <td class="verticalCenter greyCell"></td>
                                                                <td class="verticalCenter greyCell"></td>
                                                                <td class="verticalCenter text-right">{(mainData.b2cla.total.cess).toFixed(2)}</td>
                                                            </tr>
                                                            <tr class="" data-ng-if="b2cladat &amp;&amp; !IFF">
                                                                <td colspan="2">Net differential amount (Amended - Original)</td>
                                                                <td class="verticalCenter text-center"></td>
                                                                <td class="verticalCenter text-center"></td>
                                                                <td class="verticalCenter text-right">{(mainData.b2cla.total.value - (mainData.b2cla.ototal ? mainData.b2cla.ototal.value : 0)).toFixed(2)}</td>
                                                                <td class="verticalCenter text-right">{(mainData.b2cla.total.taxa - (mainData.b2cla.ototal ? mainData.b2cla.ototal.taxa : 0)).toFixed(2)}</td>
                                                                <td class="verticalCenter greyCell"></td>
                                                                <td class="verticalCenter greyCell"></td>
                                                                <td class="verticalCenter text-right">{(mainData.b2cla.total.cess - (mainData.b2cla.ototal ? mainData.b2cla.ototal.cess : 0)).toFixed(2)}</td>
                                                            </tr>


                                                            {/* <!-- 9A - AMEND TABLE 6A --> */}
                                                            <tr data-ng-if="expadat &amp;&amp; !IFF">
                                                                <td class="antiqueWhite" colspan="9" data-ng-bind="trans.LBL_SUM_6A_AMD_EXP">9A - Amendment to Export supplies in returns of earlier tax periods in table 6A (EXPWP/EXPWOP)</td>
                                                            </tr>
                                                            <tr class="" data-ng-if="expadat &amp;&amp; !IFF">
                                                                <td colspan="2">Amended amount - Total</td>
                                                                <td class="verticalCenter text-center">{mainData.a9.total.count}</td>
                                                                <td class="verticalCenter text-center">Invoice</td>
                                                                <td class="verticalCenter text-right">{(mainData.a9.total.value).toFixed(2)}</td>
                                                                <td class="verticalCenter text-right">{(mainData.a9.total.taxa).toFixed(2)}</td>
                                                                <td class="verticalCenter greyCell"></td>
                                                                <td class="verticalCenter greyCell"></td>
                                                                <td class="verticalCenter text-right">{(mainData.a9.total.cess).toFixed(2)}</td>
                                                            </tr>
                                                            <tr class="" data-ng-if="expadat &amp;&amp; !IFF">
                                                                <td colspan="2">Net differential amount (Amended - Original) - Total</td>
                                                                <td class="verticalCenter text-center"></td>
                                                                <td class="verticalCenter text-center"></td>
                                                                <td class="verticalCenter text-right">{(mainData.a9.total.value - (mainData.a9.ototal ? mainData.a9.ototal.value : 0)).toFixed(2)}</td>
                                                                <td class="verticalCenter text-right">{(mainData.a9.total.taxa - (mainData.a9.ototal ? mainData.a9.ototal.taxa : 0)).toFixed(2)}</td>
                                                                <td class="verticalCenter greyCell"></td>
                                                                <td class="verticalCenter greyCell"></td>
                                                                <td class="verticalCenter text-right">{(mainData.a9.total.cess - (mainData.a9.ototal ? mainData.a9.ototal.cess : 0)).toFixed(2)}</td>
                                                            </tr>
                                                            <tr data-ng-if="expadat &amp;&amp; !IFF" data-ng-repeat="sec in expadat.sub_sections | orderBy : 'typ.length'">
                                                                <td colspan="2" class="subsec">EXPWP</td>
                                                                <td class="verticalCenter text-center">{mainData.a9.wp.count}</td>
                                                                <td class="verticalCenter text-center">Invoice</td>
                                                                <td class="verticalCenter text-right">{(mainData.a9.wp.value).toFixed(2)}</td>
                                                                <td class="verticalCenter text-right">{(mainData.a9.wp.taxa).toFixed(2)}</td>
                                                                <td class="verticalCenter greyCell"></td>
                                                                <td class="verticalCenter greyCell"></td>
                                                                <td class="verticalCenter text-right">{(mainData.a9.wp.cess).toFixed(2)}</td>
                                                            </tr>
                                                            <tr data-ng-if="expadat &amp;&amp; !IFF" data-ng-repeat="sec in expadat.sub_sections | orderBy : 'typ.length'">
                                                                <td colspan="2" class="subsec">EXPWOP</td>
                                                                <td class="verticalCenter text-center">{mainData.a9.wop.count}</td>
                                                                <td class="verticalCenter text-center">Invoice</td>
                                                                <td class="verticalCenter text-right">{(mainData.a9.wop.value).toFixed(2)}</td>
                                                                <td class="verticalCenter greyCell"></td>
                                                                <td class="verticalCenter greyCell"></td>
                                                                <td class="verticalCenter greyCell"></td>
                                                                <td class="verticalCenter greyCell"></td>
                                                            </tr>

                                                            {/* <!-- 9A - AMEND TABLE 6B --> */}
                                                            <tr data-ng-if="b2bawpdat || b2bawopdat">
                                                                <td class="antiqueWhite" colspan="9" data-ng-bind="trans.LBL_SUM_6B_AMD_SEZ">9A - Amendment to supplies made to SEZ unit or SEZ developer in returns of earlier tax periods in table 6B (SEZWP/SEZWOP) </td>
                                                            </tr>
                                                            <tr class="" data-ng-if="b2bawpdat || b2bawopdat">
                                                                <td colspan="2">Amended amount - Total</td>
                                                                <td class="verticalCenter text-center">0</td>
                                                                <td class="verticalCenter text-center">Invoice</td>
                                                                <td class="verticalCenter text-right">0.00</td>
                                                                <td class="verticalCenter text-right">0.00</td>
                                                                <td class="verticalCenter greyCell"></td>
                                                                <td class="verticalCenter greyCell"></td>
                                                                <td class="verticalCenter text-right">0.00</td>
                                                            </tr>
                                                            <tr class="" data-ng-if="b2bawpdat || b2bawopdat">
                                                                <td colspan="2">Net differential amount (Amended - Original) - Total</td>
                                                                <td class="verticalCenter text-center"></td>
                                                                <td class="verticalCenter text-center"></td>
                                                                <td class="verticalCenter text-right">0.00</td>
                                                                <td class="verticalCenter text-right">0.00</td>
                                                                <td class="verticalCenter greyCell"></td>
                                                                <td class="verticalCenter greyCell"></td>
                                                                <td class="verticalCenter text-right">0.00</td>
                                                            </tr>
                                                            <tr class="" data-ng-if="b2bawpdat">
                                                                <td colspan="2" class="subsec" data-ng-bind="trans.LBL_SUM_SEZWP">SEZWP</td>
                                                                <td class="verticalCenter text-center" data-ng-bind="b2bawpdat.ttl_rec">0</td>
                                                                <td class="verticalCenter text-center">Invoice</td>
                                                                <td class="verticalCenter text-right">0.00</td>
                                                                <td class="verticalCenter text-right">0.00</td>
                                                                <td class="verticalCenter greyCell"></td>
                                                                <td class="verticalCenter greyCell"></td>
                                                                <td class="verticalCenter text-right">0.00</td>
                                                            </tr>
                                                            <tr class="" data-ng-if="b2bawopdat">
                                                                <td colspan="2" class="subsec" data-ng-bind="trans.LBL_SUM_SEZWOP">SEZWOP</td>
                                                                <td class="verticalCenter text-center" data-ng-bind="b2bawopdat.ttl_rec">0</td>
                                                                <td class="verticalCenter text-center">Invoice</td>
                                                                <td class="verticalCenter text-right">0.00</td>
                                                                <td class="verticalCenter greyCell"></td>
                                                                <td class="verticalCenter greyCell"></td>
                                                                <td class="verticalCenter greyCell"></td>
                                                                <td class="verticalCenter greyCell"></td>
                                                            </tr>

                                                            {/* <!-- 9A - AMEND TABLE 6C --> */}
                                                            <tr data-ng-if="b2ba6cdat">
                                                                <td class="antiqueWhite" colspan="9" data-ng-bind="trans.LBL_SUM_6C_AMD_DE">9A - Amendment to Deemed Exports in returns of earlier tax periods in table 6C (DE)</td>
                                                            </tr>
                                                            <tr class="" data-ng-if="b2ba6cdat">
                                                                <td colspan="2">Amended amount - Total</td>
                                                                <td class="verticalCenter text-center" data-ng-bind="b2ba6cdat.ttl_rec">0</td>
                                                                <td class="verticalCenter text-center">Invoice</td>
                                                                <td class="verticalCenter text-right">0.00</td>
                                                                <td class="verticalCenter text-right">0.00</td>
                                                                <td class="verticalCenter text-right">0.00</td>
                                                                <td class="verticalCenter text-right">0.00</td>
                                                                <td class="verticalCenter text-right">0.00</td>
                                                            </tr>
                                                            <tr class="" data-ng-if="b2ba6cdat">
                                                                <td colspan="2">Net differential amount (Amended - Original)</td>
                                                                <td class="verticalCenter text-center"></td>
                                                                <td class="verticalCenter text-center"></td>
                                                                <td class="verticalCenter text-right">0.00</td>
                                                                <td class="verticalCenter text-right">0.00</td>
                                                                <td class="verticalCenter text-right">0.00</td>
                                                                <td class="verticalCenter text-right">0.00</td>
                                                                <td class="verticalCenter text-right">0.00</td>
                                                            </tr>

                                                            {/* <!-- 9B - CDNR --> */}
                                                            <tr data-ng-if="cdnrdat">
                                                                <td class="antiqueWhite" colspan="9" data-ng-bind="trans.LBL_SUM_9B_CDNR">9B - Credit/Debit Notes (Registered) - CDNR</td>
                                                            </tr>
                                                            <tr class="" data-ng-if="cdnrdat">
                                                                <td colspan="2">
                                                                    <div data-ng-click="collapseSummary('cdnShow')">Total - Net off debit/credit notes (Debit notes - Credit notes)
                                                                        {
                                                                            !tab9CDNR && <span data-ng-hide="cdnShow" class="cursor-pointer" onClick={() => {
                                                                                setTab9CDNR(true);
                                                                            }}>
                                                                                <i class="fa fa-fw fa-chevron-down"></i>
                                                                            </span>
                                                                        }
                                                                        {
                                                                            tab9CDNR && <span data-ng-show="cdnShow" class="cursor-pointer" onClick={() => {
                                                                                setTab9CDNR(false);
                                                                            }}>
                                                                                <i class="fa fa-fw fa-chevron-up"></i>
                                                                            </span>
                                                                        }
                                                                    </div>
                                                                </td>
                                                                <td class="verticalCenter text-center" data-ng-bind="cdnrdat.ttl_rec">{mainData.cdrA.total.count + mainData.cdrB.total.count}</td>
                                                                <td class="verticalCenter text-center">Note</td>
                                                                <td class="verticalCenter text-right">{(mainData.cdrA.total.value + mainData.cdrB.total.value).toFixed(2)}</td>
                                                                <td class="verticalCenter text-right">{(mainData.cdrA.total.taxa + mainData.cdrB.total.taxa).toFixed(2)}</td>
                                                                <td class="verticalCenter text-right">{(mainData.cdrA.total.taxb + mainData.cdrB.total.taxb).toFixed(2)}</td>
                                                                <td class="verticalCenter text-right">{(mainData.cdrA.total.taxb + mainData.cdrB.total.taxb).toFixed(2)}</td>
                                                                <td class="verticalCenter text-right">{(mainData.cdrA.total.cess + mainData.cdrB.total.cess).toFixed(2)}</td>
                                                            </tr>
                                                            {
                                                                tab9CDNR && <>
                                                                    <tr data-ng-if="cdnr4adat" data-ng-show="cdnShow" class="">
                                                                        <td colspan="9" class="subsec subHeading" data-ng-bind="trans.LBL_SUM_9B_CDNR_B2B">Credit / Debit notes issued to registered person for taxable outward supplies in table 4 other than table 6 - B2B Regular</td>
                                                                    </tr>
                                                                    <tr class="" data-ng-if="cdnr4adat" data-ng-show="cdnShow">
                                                                        <td colspan="2" class="sub_subsec">Net Total (Debit notes – Credit notes)
                                                                        </td>
                                                                        <td class="verticalCenter text-center" data-ng-bind="cdnr4adat.ttl_rec">{mainData.cdrA.total.count}</td>
                                                                        <td class="verticalCenter text-center">Note</td>
                                                                        <td class="verticalCenter text-right">{(mainData.cdrA.total.value).toFixed(2)}</td>
                                                                        <td class="verticalCenter text-right">{(mainData.cdrA.total.taxa).toFixed(2)}</td>
                                                                        <td class="verticalCenter text-right">{(mainData.cdrA.total.taxb).toFixed(2)}</td>
                                                                        <td class="verticalCenter text-right">{(mainData.cdrA.total.taxb).toFixed(2)}</td>
                                                                        <td class="verticalCenter text-right">{(mainData.cdrA.total.cess).toFixed(2)}</td>
                                                                    </tr>
                                                                    {
                                                                        mainData.cdrA.data.length !== 0 && <tr>
                                                                            <td colspan="9" class="sub_subsec">
                                                                                <div>Recipient wise summary
                                                                                    {
                                                                                        !tab9CDRA && <span data-ng-hide="b2b4aShow" class="cursor-pointer" onClick={() => {
                                                                                            setTab9CDRA(true);
                                                                                        }}>
                                                                                            <i class="fa fa-fw fa-chevron-down"></i>
                                                                                        </span>
                                                                                    }
                                                                                    {
                                                                                        tab9CDRA && <span data-ng-show="b2b4aShow" class="cursor-pointer" onClick={() => {
                                                                                            setTab9CDRA(false);
                                                                                        }}>
                                                                                            <i class="fa fa-fw fa-chevron-up"></i>
                                                                                        </span>
                                                                                    }
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    }
                                                                    {
                                                                        tab9CDRA && mainData.cdrA.data.map((item) => {
                                                                            return <tr data-ng-show="b2b4aShow" data-ng-if="b2b4adat &amp;&amp; !isCptyLimitExceeded" data-ng-repeat="cpty in b2b4adat.cpty_sum">
                                                                                <td class="verticalCenter subsec" data-ng-bind="cpty.ctin">{item.gstn}</td>
                                                                                <td class="verticalCenter text-center" data-ng-bind="cpty.trade_name">{item.name}</td>
                                                                                <td class="verticalCenter text-center" data-ng-bind="cpty.ttl_rec">{item.count}</td>
                                                                                <td class="verticalCenter text-center">Invoice</td>
                                                                                <td class="verticalCenter text-right">{item.value.toFixed(2)}</td>
                                                                                <td class="verticalCenter text-right">{item.taxa.toFixed(2)}</td>
                                                                                <td class="verticalCenter text-right">{item.taxb.toFixed(2)}</td>
                                                                                <td class="verticalCenter text-right">{item.taxb.toFixed(2)}</td>
                                                                                <td class="verticalCenter text-right">{item.cess.toFixed(2)}</td>
                                                                            </tr>
                                                                        })
                                                                    }
                                                                    <tr class="">
                                                                        <td colspan="9" class="subsec subHeading" data-ng-bind="trans.LBL_SUM_9B_CDNR_B2B_REV">Credit / Debit notes issued to registered person for taxable outward supplies in table 4 other than table 6 - B2B Reverse charge</td>
                                                                    </tr>
                                                                    <tr class="">
                                                                        <td colspan="2" class="sub_subsec">Net Total (Debit notes – Credit notes)
                                                                        </td>
                                                                        <td class="verticalCenter text-center" data-ng-bind="cdnr4adat.ttl_rec">{mainData.cdrB.total.count}</td>
                                                                        <td class="verticalCenter text-center">Note</td>
                                                                        <td class="verticalCenter text-right">{(mainData.cdrB.total.value).toFixed(2)}</td>
                                                                        <td class="verticalCenter text-right">{(mainData.cdrB.total.taxa).toFixed(2)}</td>
                                                                        <td class="verticalCenter text-right">{(mainData.cdrB.total.taxb).toFixed(2)}</td>
                                                                        <td class="verticalCenter text-right">{(mainData.cdrB.total.taxb).toFixed(2)}</td>
                                                                        <td class="verticalCenter text-right">{(mainData.cdrB.total.cess).toFixed(2)}</td>
                                                                    </tr>
                                                                    {
                                                                        mainData.cdrB.data.length !== 0 && <tr>
                                                                            <td colspan="9" class="sub_subsec">
                                                                                <div>Recipient wise summary
                                                                                    {
                                                                                        !tab9CDRB && <span data-ng-hide="b2b4aShow" class="cursor-pointer" onClick={() => {
                                                                                            setTab9CDRB(true);
                                                                                        }}>
                                                                                            <i class="fa fa-fw fa-chevron-down"></i>
                                                                                        </span>
                                                                                    }
                                                                                    {
                                                                                        tab9CDRB && <span data-ng-show="b2b4aShow" class="cursor-pointer" onClick={() => {
                                                                                            setTab9CDRB(false);
                                                                                        }}>
                                                                                            <i class="fa fa-fw fa-chevron-up"></i>
                                                                                        </span>
                                                                                    }
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    }
                                                                    {
                                                                        tab9CDRB && mainData.cdrB.data.map((item) => {
                                                                            return <tr data-ng-show="b2b4aShow" data-ng-if="b2b4adat &amp;&amp; !isCptyLimitExceeded" data-ng-repeat="cpty in b2b4adat.cpty_sum">
                                                                                <td class="verticalCenter subsec" data-ng-bind="cpty.ctin">{item.gstn}</td>
                                                                                <td class="verticalCenter text-center" data-ng-bind="cpty.trade_name">{item.name}</td>
                                                                                <td class="verticalCenter text-center" data-ng-bind="cpty.ttl_rec">{item.count}</td>
                                                                                <td class="verticalCenter text-center">Invoice</td>
                                                                                <td class="verticalCenter text-right">{item.value.toFixed(2)}</td>
                                                                                <td class="verticalCenter text-right">{item.taxa.toFixed(2)}</td>
                                                                                <td class="verticalCenter text-right">{item.taxb.toFixed(2)}</td>
                                                                                <td class="verticalCenter text-right">{item.taxb.toFixed(2)}</td>
                                                                                <td class="verticalCenter text-right">{item.cess.toFixed(2)}</td>
                                                                            </tr>
                                                                        })
                                                                    }
                                                                    <tr data-ng-if="cdnrwpdat || cdnrwopdat" data-ng-show="cdnShow" class="">
                                                                        <td colspan="9" class="subsec subHeading" data-ng-bind="trans.LBL_SUM_9B_CDNR_SEZ">Credit / Debit notes issued to registered person for taxable outward supplies in table 6B - SEZWP/SEZWOP</td>
                                                                    </tr>
                                                                    <tr class="" data-ng-if="cdnrwpdat || cdnrwopdat" data-ng-show="cdnShow">
                                                                        <td colspan="2" class="sub_subsec">Net Total (Debit notes – Credit notes)
                                                                        </td>
                                                                        <td class="verticalCenter text-center">0</td>
                                                                        <td class="verticalCenter text-center">Note</td>
                                                                        <td class="verticalCenter text-right">0.00</td>
                                                                        <td class="verticalCenter text-right">0.00</td>
                                                                        <td class="verticalCenter greyCell"></td>
                                                                        <td class="verticalCenter greyCell"></td>
                                                                        <td class="verticalCenter text-right">0.00</td>
                                                                    </tr>
                                                                    <tr data-ng-if="cdnr6cdat" data-ng-show="cdnShow" class="">
                                                                        <td colspan="9" class="subsec subHeading" data-ng-bind="trans.LBL_SUM_9B_CDNR_DE">Credit / Debit notes issued to registered person for taxable outward supplies in table 6C - DE</td>
                                                                    </tr>
                                                                    <tr class="" data-ng-if="cdnr6cdat" data-ng-show="cdnShow">
                                                                        <td colspan="2" class="sub_subsec">Net Total (Debit notes – Credit notes)
                                                                        </td>
                                                                        <td class="verticalCenter text-center" data-ng-bind="cdnr6cdat.ttl_rec">0</td>
                                                                        <td class="verticalCenter text-center">Note</td>
                                                                        <td class="verticalCenter text-right">0.00</td>
                                                                        <td class="verticalCenter text-right">0.00</td>
                                                                        <td class="verticalCenter text-right">0.00</td>
                                                                        <td class="verticalCenter text-right">0.00</td>
                                                                        <td class="verticalCenter text-right">0.00</td>
                                                                    </tr>
                                                                </>
                                                            }

                                                            {/* <!-- 9B - CDNUR --> */}
                                                            <tr>
                                                                <td class="antiqueWhite" colspan="9" data-ng-bind="trans.LBL_SUM_9B_CDNUR">9B - Credit/Debit Notes (Unregistered) - CDNUR</td>
                                                            </tr>
                                                            <tr class="">
                                                                <td colspan="2">Total - Net off debit/credit notes (Debit notes - Credit notes)</td>
                                                                <td class="verticalCenter text-center">{mainData.cdu.total.count}</td>
                                                                <td class="verticalCenter text-center">Note</td>
                                                                <td class="verticalCenter text-right">{(mainData.cdu.total.value).toFixed(2)}</td>
                                                                <td class="verticalCenter text-right">{(mainData.cdu.total.taxa).toFixed(2)}</td>
                                                                <td class="verticalCenter greyCell"></td>
                                                                <td class="verticalCenter greyCell"></td>
                                                                <td class="verticalCenter text-right">{(mainData.cdu.total.cess).toFixed(2)}</td>
                                                            </tr>
                                                            <tr data-ng-if="cdnurdat &amp;&amp; !IFF">
                                                                <td colspan="9">
                                                                    <div data-ng-click="collapseSummary('urTypShow')">Unregistered Type
                                                                        {
                                                                            !tab9CDNUR && <span class="cursor-pointer" onClick={() => {
                                                                                setTab9CDNUR(true);
                                                                            }}>
                                                                                <i class="fa fa-fw fa-chevron-down"></i>
                                                                            </span>
                                                                        }
                                                                        {
                                                                            tab9CDNUR && <span class="cursor-pointer" onClick={() => {
                                                                                setTab9CDNUR(false);
                                                                            }}>
                                                                                <i class="fa fa-fw fa-chevron-up"></i>
                                                                            </span>
                                                                        }
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                            {
                                                                tab9CDNUR && <>
                                                                    <tr data-ng-show="urTypShow" data-ng-if="cdnurdat &amp;&amp; !IFF" data-ng-repeat="sec in cdnurdat.sub_sections | orderBy : 'typ.length'">
                                                                        <td colspan="2" class="verticalCenter subsec" data-ng-bind="sec.typ">B2CL</td>
                                                                        <td class="verticalCenter text-center">{mainData.cdu.cl.count}</td>
                                                                        <td class="verticalCenter text-center">Note</td>
                                                                        <td class="verticalCenter text-right">{(mainData.cdu.cl.value).toFixed(2)}</td>
                                                                        <td class="verticalCenter text-right">{(mainData.cdu.cl.taxa).toFixed(2)}</td>
                                                                        <td class="verticalCenter greyCell"></td>
                                                                        <td class="verticalCenter greyCell"></td>
                                                                        <td class="verticalCenter text-right">{(mainData.cdu.cl.cess).toFixed(2)}</td>
                                                                    </tr>
                                                                    <tr data-ng-show="urTypShow" data-ng-if="cdnurdat &amp;&amp; !IFF" data-ng-repeat="sec in cdnurdat.sub_sections | orderBy : 'typ.length'">
                                                                        <td colspan="2" class="verticalCenter subsec" data-ng-bind="sec.typ">EXPWP</td>
                                                                        <td class="verticalCenter text-center">{mainData.cdu.wp.count}</td>
                                                                        <td class="verticalCenter text-center">Note</td>
                                                                        <td class="verticalCenter text-right">{(mainData.cdu.wp.value).toFixed(2)}</td>
                                                                        <td class="verticalCenter text-right">{(mainData.cdu.wp.taxa).toFixed(2)}</td>
                                                                        <td class="verticalCenter greyCell"></td>
                                                                        <td class="verticalCenter greyCell"></td>
                                                                        <td class="verticalCenter text-right">{(mainData.cdu.wp.cess).toFixed(2)}</td>
                                                                    </tr>
                                                                    <tr data-ng-show="urTypShow" data-ng-if="cdnurdat &amp;&amp; !IFF" data-ng-repeat="sec in cdnurdat.sub_sections | orderBy : 'typ.length'">
                                                                        <td colspan="2" class="verticalCenter subsec" data-ng-bind="sec.typ">EXPWOP</td>
                                                                        <td class="verticalCenter text-center">{mainData.cdu.wop.count}</td>
                                                                        <td class="verticalCenter text-center">Note</td>
                                                                        <td class="verticalCenter text-right">{(mainData.cdu.wop.value).toFixed(2)}</td>
                                                                        <td class="verticalCenter greyCell"></td>
                                                                        <td class="verticalCenter greyCell"></td>
                                                                        <td class="verticalCenter greyCell"></td>
                                                                        <td class="verticalCenter greyCell"></td>
                                                                    </tr>
                                                                </>
                                                            }

                                                            {/* <!-- 9C - CDNRA --> */}
                                                            <tr data-ng-if="cdnradat">
                                                                <td class="antiqueWhite" colspan="9" data-ng-bind="trans.LBL_SUM_9C_CDNRA">9C - Amended Credit/Debit Notes (Registered) - CDNRA</td>
                                                            </tr>
                                                            <tr class="" data-ng-if="cdnrdat">
                                                                <td colspan="2">
                                                                    <div data-ng-click="collapseSummary('cdnShow')">Amended amount - Total</div>
                                                                </td>
                                                                <td class="verticalCenter text-center" data-ng-bind="cdnrdat.ttl_rec">{mainData.cdrAA.total.count + mainData.cdrAB.total.count}</td>
                                                                <td class="verticalCenter text-center">Note</td>
                                                                <td class="verticalCenter text-right">{(mainData.cdrAA.total.value + mainData.cdrAB.total.value).toFixed(2)}</td>
                                                                <td class="verticalCenter text-right">{(mainData.cdrAA.total.taxa + mainData.cdrAB.total.taxa).toFixed(2)}</td>
                                                                <td class="verticalCenter text-right">{(mainData.cdrAA.total.taxb + mainData.cdrAB.total.taxb).toFixed(2)}</td>
                                                                <td class="verticalCenter text-right">{(mainData.cdrAA.total.taxb + mainData.cdrAB.total.taxb).toFixed(2)}</td>
                                                                <td class="verticalCenter text-right">{(mainData.cdrAA.total.cess + mainData.cdrAB.total.cess).toFixed(2)}</td>
                                                            </tr>
                                                            <tr class="" data-ng-if="cdnrdat">
                                                                <td colspan="2">
                                                                    <div data-ng-click="collapseSummary('cdnShow')">Net Differential amount (Net Amended Debit notes - Net Amended Credit notes) - Total
                                                                        {
                                                                            !tab9CDNRA && <span data-ng-hide="cdnShow" class="cursor-pointer" onClick={() => {
                                                                                setTab9CDNRA(true);
                                                                            }}>
                                                                                <i class="fa fa-fw fa-chevron-down"></i>
                                                                            </span>
                                                                        }
                                                                        {
                                                                            tab9CDNRA && <span data-ng-show="cdnShow" class="cursor-pointer" onClick={() => {
                                                                                setTab9CDNRA(false);
                                                                            }}>
                                                                                <i class="fa fa-fw fa-chevron-up"></i>
                                                                            </span>
                                                                        }
                                                                    </div>
                                                                </td>
                                                                <td class="verticalCenter text-center" data-ng-bind="cdnrdat.ttl_rec">{mainData.cdrAA.total.count + mainData.cdrAB.total.count}</td>
                                                                <td class="verticalCenter text-center">Note</td>
                                                                <td class="verticalCenter text-right">{mainData.cdrAA.ntotal ? (mainData.cdrAA.ntotal.value + mainData.cdrAB.ntotal.value).toFixed(2) : (mainData.cdrAA.total.value + mainData.cdrAB.total.value).toFixed(2)}</td>
                                                                <td class="verticalCenter text-right">{mainData.cdrAA.ntotal ? (mainData.cdrAA.ntotal.taxa + mainData.cdrAB.ntotal.taxa).toFixed(2) : (mainData.cdrAA.total.taxa + mainData.cdrAB.total.taxa).toFixed(2)}</td>
                                                                <td class="verticalCenter text-right">{mainData.cdrAA.ntotal ? (mainData.cdrAA.ntotal.taxb + mainData.cdrAB.ntotal.taxb).toFixed(2) : (mainData.cdrAA.total.taxb + mainData.cdrAB.total.taxb).toFixed(2)}</td>
                                                                <td class="verticalCenter text-right">{mainData.cdrAA.ntotal ? (mainData.cdrAA.ntotal.taxb + mainData.cdrAB.ntotal.taxb).toFixed(2) : (mainData.cdrAA.total.taxb + mainData.cdrAB.total.taxb).toFixed(2)}</td>
                                                                <td class="verticalCenter text-right">{mainData.cdrAA.ntotal ? (mainData.cdrAA.ntotal.cess + mainData.cdrAB.ntotal.cess).toFixed(2) : (mainData.cdrAA.total.cess + mainData.cdrAB.total.cess).toFixed(2)}</td>
                                                            </tr>
                                                            {
                                                                tab9CDNRA && <>
                                                                    <tr data-ng-if="cdnr4adat" data-ng-show="cdnShow" class="">
                                                                        <td colspan="9" class="subsec subHeading" data-ng-bind="trans.LBL_SUM_9B_CDNR_B2B">Amended Credit / Debit notes issued to registered person for taxable outward supplies in table 4 other than table 6 - B2B Regular</td>
                                                                    </tr>
                                                                    <tr class="" data-ng-if="cdnr4adat" data-ng-show="cdnShow">
                                                                        <td colspan="2" class="sub_subsec">Net total (Net Amended Debit notes - Net Amended Credit notes)
                                                                        </td>
                                                                        <td class="verticalCenter text-center" data-ng-bind="cdnr4adat.ttl_rec">{mainData.cdrAA.total.count}</td>
                                                                        <td class="verticalCenter text-center">Note</td>
                                                                        <td class="verticalCenter text-right">{(mainData.cdrAA.ntotal ? mainData.cdrAA.ntotal.value : 0).toFixed(2)}</td>
                                                                        <td class="verticalCenter text-right">{(mainData.cdrAA.ntotal ? mainData.cdrAA.ntotal.taxa : 0).toFixed(2)}</td>
                                                                        <td class="verticalCenter text-right">{(mainData.cdrAA.ntotal ? mainData.cdrAA.ntotal.taxb : 0).toFixed(2)}</td>
                                                                        <td class="verticalCenter text-right">{(mainData.cdrAA.ntotal ? mainData.cdrAA.ntotal.taxb : 0).toFixed(2)}</td>
                                                                        <td class="verticalCenter text-right">{(mainData.cdrAA.ntotal ? mainData.cdrAA.ntotal.cess : 0).toFixed(2)}</td>
                                                                    </tr>
                                                                    {
                                                                        mainData.cdrAA.data.length !== 0 && <tr>
                                                                            <td colspan="9" class="sub_subsec">
                                                                                <div>Recipient wise summary
                                                                                    {
                                                                                        !tab9CDRAA && <span data-ng-hide="b2b4aShow" class="cursor-pointer" onClick={() => {
                                                                                            setTab9CDRAA(true);
                                                                                        }}>
                                                                                            <i class="fa fa-fw fa-chevron-down"></i>
                                                                                        </span>
                                                                                    }
                                                                                    {
                                                                                        tab9CDRAA && <span data-ng-show="b2b4aShow" class="cursor-pointer" onClick={() => {
                                                                                            setTab9CDRAA(false);
                                                                                        }}>
                                                                                            <i class="fa fa-fw fa-chevron-up"></i>
                                                                                        </span>
                                                                                    }
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    }
                                                                    {
                                                                        tab9CDRAA && mainData.cdrAA.data.map((item) => {
                                                                            return <tr data-ng-show="b2b4aShow" data-ng-if="b2b4adat &amp;&amp; !isCptyLimitExceeded" data-ng-repeat="cpty in b2b4adat.cpty_sum">
                                                                                <td class="verticalCenter subsec" data-ng-bind="cpty.ctin">{item.gstn}</td>
                                                                                <td class="verticalCenter text-center" data-ng-bind="cpty.trade_name">{item.name}</td>
                                                                                <td class="verticalCenter text-center" data-ng-bind="cpty.ttl_rec">{item.count}</td>
                                                                                <td class="verticalCenter text-center">Invoice</td>
                                                                                <td class="verticalCenter text-right">{item.value.toFixed(2)}</td>
                                                                                <td class="verticalCenter text-right">{item.taxa.toFixed(2)}</td>
                                                                                <td class="verticalCenter text-right">{item.taxb.toFixed(2)}</td>
                                                                                <td class="verticalCenter text-right">{item.taxb.toFixed(2)}</td>
                                                                                <td class="verticalCenter text-right">{item.cess.toFixed(2)}</td>
                                                                            </tr>
                                                                        })
                                                                    }
                                                                    <tr class="">
                                                                        <td colspan="9" class="subsec subHeading" data-ng-bind="trans.LBL_SUM_9B_CDNR_B2B_REV">Amended Credit / Debit notes issued to registered person for taxable outward supplies in table 4 other than table 6 - B2B Reverse charge</td>
                                                                    </tr>
                                                                    <tr class="">
                                                                        <td colspan="2" class="sub_subsec">Net total (Net Amended Debit notes - Net Amended Credit notes)</td>
                                                                        <td class="verticalCenter text-center" data-ng-bind="cdnr4adat.ttl_rec">{mainData.cdrAB.total.count}</td>
                                                                        <td class="verticalCenter text-center">Note</td>
                                                                        <td class="verticalCenter text-right">{(mainData.cdrAB.ntotal ? mainData.cdrAB.ntotal.value : 0).toFixed(2)}</td>
                                                                        <td class="verticalCenter text-right">{(mainData.cdrAB.ntotal ? mainData.cdrAB.ntotal.taxa : 0).toFixed(2)}</td>
                                                                        <td class="verticalCenter text-right">{(mainData.cdrAB.ntotal ? mainData.cdrAB.ntotal.taxb : 0).toFixed(2)}</td>
                                                                        <td class="verticalCenter text-right">{(mainData.cdrAB.ntotal ? mainData.cdrAB.ntotal.taxb : 0).toFixed(2)}</td>
                                                                        <td class="verticalCenter text-right">{(mainData.cdrAB.ntotal ? mainData.cdrAB.ntotal.cess : 0).toFixed(2)}</td>
                                                                    </tr>
                                                                    {
                                                                        mainData.cdrAB.data.length !== 0 && <tr>
                                                                            <td colspan="9" class="sub_subsec">
                                                                                <div>Recipient wise summary
                                                                                    {
                                                                                        !tab9CDRAB && <span data-ng-hide="b2b4aShow" class="cursor-pointer" onClick={() => {
                                                                                            setTab9CDRAB(true);
                                                                                        }}>
                                                                                            <i class="fa fa-fw fa-chevron-down"></i>
                                                                                        </span>
                                                                                    }
                                                                                    {
                                                                                        tab9CDRAB && <span data-ng-show="b2b4aShow" class="cursor-pointer" onClick={() => {
                                                                                            setTab9CDRAB(false);
                                                                                        }}>
                                                                                            <i class="fa fa-fw fa-chevron-up"></i>
                                                                                        </span>
                                                                                    }
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    }
                                                                    {
                                                                        tab9CDRAB && mainData.cdrAB.data.map((item) => {
                                                                            return <tr data-ng-show="b2b4aShow" data-ng-if="b2b4adat &amp;&amp; !isCptyLimitExceeded" data-ng-repeat="cpty in b2b4adat.cpty_sum">
                                                                                <td class="verticalCenter subsec" data-ng-bind="cpty.ctin">{item.gstn}</td>
                                                                                <td class="verticalCenter text-center" data-ng-bind="cpty.trade_name">{item.name}</td>
                                                                                <td class="verticalCenter text-center" data-ng-bind="cpty.ttl_rec">{item.count}</td>
                                                                                <td class="verticalCenter text-center">Invoice</td>
                                                                                <td class="verticalCenter text-right">{item.value.toFixed(2)}</td>
                                                                                <td class="verticalCenter text-right">{item.taxa.toFixed(2)}</td>
                                                                                <td class="verticalCenter text-right">{item.taxb.toFixed(2)}</td>
                                                                                <td class="verticalCenter text-right">{item.taxb.toFixed(2)}</td>
                                                                                <td class="verticalCenter text-right">{item.cess.toFixed(2)}</td>
                                                                            </tr>
                                                                        })
                                                                    }
                                                                    <tr data-ng-if="cdnrawpdat || cdnrawopdat" data-ng-show="cdnraShow" class="">
                                                                        <td colspan="9" class="subsec subHeading" data-ng-bind="trans.LB_SUM_9C_CDNRA_SEZ">Amended Credit / Debit notes issued to registered person for taxable outward supplies in table 6B - SEZWP/SEZWOP</td>
                                                                    </tr>
                                                                    <tr class="" data-ng-if="cdnrawpdat || cdnrawopdat" data-ng-show="cdnraShow">
                                                                        <td colspan="2" class="sub_subsec">Net total (Net Amended Debit notes - Net Amended Credit notes)</td>
                                                                        <td class="verticalCenter text-center">0</td>
                                                                        <td class="verticalCenter text-center">Note</td>
                                                                        <td class="verticalCenter text-right">0.00</td>
                                                                        <td class="verticalCenter text-right">0.00</td>
                                                                        <td class="verticalCenter greyCell"></td>
                                                                        <td class="verticalCenter greyCell"></td>
                                                                        <td class="verticalCenter text-right">0.00</td>
                                                                    </tr>
                                                                    <tr data-ng-if="cdnra6cdat" data-ng-show="cdnraShow" class="">
                                                                        <td colspan="9" class="subsec subHeading" data-ng-bind="trans.LBL_SUM_9C_CDNRA_DE">Amended Credit / Debit notes issued to registered person for taxable outward supplies in table 6C - DE</td>
                                                                    </tr>
                                                                    <tr class="" data-ng-if="cdnra6cdat" data-ng-show="cdnraShow">
                                                                        <td colspan="2" class="sub_subsec">Net total (Net Amended Debit notes - Net Amended Credit notes)</td>
                                                                        <td class="verticalCenter text-center" data-ng-bind="cdnra6cdat.ttl_rec">0</td>
                                                                        <td class="verticalCenter text-center">Note</td>
                                                                        <td class="verticalCenter text-right">0.00</td>
                                                                        <td class="verticalCenter text-right">0.00</td>
                                                                        <td class="verticalCenter text-right">0.00</td>
                                                                        <td class="verticalCenter text-right">0.00</td>
                                                                        <td class="verticalCenter text-right">0.00</td>
                                                                    </tr>
                                                                </>
                                                            }

                                                            {/* <!-- 9C - CDNURA --> */}
                                                            <tr data-ng-if="cdnuradat &amp;&amp; !IFF">
                                                                <td class="antiqueWhite" colspan="9" data-ng-bind="trans.LBL_SUM_9C_CDNURA">9C - Amended Credit/Debit Notes (Unregistered) - CDNURA</td>
                                                            </tr>
                                                            <tr class="">
                                                                <td colspan="2">Amended amount - Total</td>
                                                                <td class="verticalCenter text-center">{mainData.cdua.total.count}</td>
                                                                <td class="verticalCenter text-center">Note</td>
                                                                <td class="verticalCenter text-right">{(mainData.cdua.total.value).toFixed(2)}</td>
                                                                <td class="verticalCenter text-right">{(mainData.cdua.total.taxa).toFixed(2)}</td>
                                                                <td class="verticalCenter greyCell"></td>
                                                                <td class="verticalCenter greyCell"></td>
                                                                <td class="verticalCenter text-right">{(mainData.cdua.total.cess).toFixed(2)}</td>
                                                            </tr>
                                                            <tr class="">
                                                                <td colspan="2">Net Differential amount (Net Amended Debit notes - Net Amended Credit notes) - Total</td>
                                                                <td class="verticalCenter text-center">{mainData.cdua.total.count}</td>
                                                                <td class="verticalCenter text-center">Note</td>
                                                                <td class="verticalCenter text-right">{(mainData.cdua.ntotal ? mainData.cdua.ntotal.value : 0).toFixed(2)}</td>
                                                                <td class="verticalCenter text-right">{(mainData.cdua.ntotal ? mainData.cdua.ntotal.taxa : 0).toFixed(2)}</td>
                                                                <td class="verticalCenter greyCell"></td>
                                                                <td class="verticalCenter greyCell"></td>
                                                                <td class="verticalCenter text-right">{(mainData.cdua.cess ? mainData.cdua.ntotal.taxa : 0).toFixed(2)}</td>
                                                            </tr>
                                                            <tr data-ng-if="cdnuradat &amp;&amp; !IFF">
                                                                <td colspan="9">
                                                                    <div data-ng-click="collapseSummary('uraTypShow')">Unregistered Type
                                                                        {
                                                                            !tab9CDNURA && <span class="cursor-pointer" onClick={() => {
                                                                                setTab9CDNURA(true);
                                                                            }}>
                                                                                <i class="fa fa-fw fa-chevron-down"></i>
                                                                            </span>
                                                                        }
                                                                        {
                                                                            tab9CDNURA && <span class="cursor-pointer" onClick={() => {
                                                                                setTab9CDNURA(false);
                                                                            }}>
                                                                                <i class="fa fa-fw fa-chevron-up"></i>
                                                                            </span>
                                                                        }
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                            {
                                                                tab9CDNURA && <>
                                                                    <tr data-ng-show="urTypShow" data-ng-if="cdnurdat &amp;&amp; !IFF" data-ng-repeat="sec in cdnurdat.sub_sections | orderBy : 'typ.length'">
                                                                        <td colspan="2" class="verticalCenter subsec" data-ng-bind="sec.typ">B2CL</td>
                                                                        <td class="verticalCenter text-center">{mainData.cdua.cl.count}</td>
                                                                        <td class="verticalCenter text-center">Note</td>
                                                                        <td class="verticalCenter text-right">{(mainData.cdua.cl.value).toFixed(2)}</td>
                                                                        <td class="verticalCenter text-right">{(mainData.cdua.cl.taxa).toFixed(2)}</td>
                                                                        <td class="verticalCenter greyCell"></td>
                                                                        <td class="verticalCenter greyCell"></td>
                                                                        <td class="verticalCenter text-right">{(mainData.cdua.cl.cess).toFixed(2)}</td>
                                                                    </tr>
                                                                    <tr data-ng-show="urTypShow" data-ng-if="cdnurdat &amp;&amp; !IFF" data-ng-repeat="sec in cdnurdat.sub_sections | orderBy : 'typ.length'">
                                                                        <td colspan="2" class="verticalCenter subsec" data-ng-bind="sec.typ">EXPWP</td>
                                                                        <td class="verticalCenter text-center">{mainData.cdua.wp.count}</td>
                                                                        <td class="verticalCenter text-center">Note</td>
                                                                        <td class="verticalCenter text-right">{(mainData.cdua.wp.value).toFixed(2)}</td>
                                                                        <td class="verticalCenter text-right">{(mainData.cdua.wp.taxa).toFixed(2)}</td>
                                                                        <td class="verticalCenter greyCell"></td>
                                                                        <td class="verticalCenter greyCell"></td>
                                                                        <td class="verticalCenter text-right">{(mainData.cdua.wp.cess).toFixed(2)}</td>
                                                                    </tr>
                                                                    <tr data-ng-show="urTypShow" data-ng-if="cdnurdat &amp;&amp; !IFF" data-ng-repeat="sec in cdnurdat.sub_sections | orderBy : 'typ.length'">
                                                                        <td colspan="2" class="verticalCenter subsec" data-ng-bind="sec.typ">EXPWOP</td>
                                                                        <td class="verticalCenter text-center">{mainData.cdua.wop.count}</td>
                                                                        <td class="verticalCenter text-center">Note</td>
                                                                        <td class="verticalCenter text-right">{(mainData.cdua.wop.value).toFixed(2)}</td>
                                                                        <td class="verticalCenter greyCell"></td>
                                                                        <td class="verticalCenter greyCell"></td>
                                                                        <td class="verticalCenter greyCell"></td>
                                                                        <td class="verticalCenter greyCell"></td>
                                                                    </tr>
                                                                </>
                                                            }

                                                            {/* <!-- 10 - B2CS --> */}
                                                            <tr data-ng-if="b2csadat &amp;&amp; !IFF">
                                                                <td class="antiqueWhite" colspan="9" data-ng-bind="trans.LBL_SUM_10_B2C">10 - Amendment to taxable outward supplies to unregistered person in returns for earlier tax periods in table 7 - B2C (Others)</td>
                                                            </tr>
                                                            <tr class="" data-ng-if="b2csadat &amp;&amp; !IFF">
                                                                <td colspan="2">Amended amount - Total</td>
                                                                <td class="verticalCenter text-center" data-ng-bind="b2csadat.ttl_rec">{mainData.b2coa.total.count}</td>
                                                                <td class="verticalCenter text-center">Net Value</td>
                                                                <td class="verticalCenter text-right">{(mainData.b2coa.total.value).toFixed(2)}</td>
                                                                <td class="verticalCenter text-right">{(mainData.b2coa.total.taxa).toFixed(2)}</td>
                                                                <td class="verticalCenter text-right">{(mainData.b2coa.total.taxb).toFixed(2)}</td>
                                                                <td class="verticalCenter text-right">{(mainData.b2coa.total.taxb).toFixed(2)}</td>
                                                                <td class="verticalCenter text-right">{(mainData.b2coa.total.cess).toFixed(2)}</td>
                                                            </tr>
                                                            <tr class="" data-ng-if="b2csadat &amp;&amp; !IFF">
                                                                <td colspan="2">Net differential amount (Amended - Original)</td>
                                                                <td class="verticalCenter text-center"></td>
                                                                <td class="verticalCenter text-center"></td>
                                                                <td class="verticalCenter text-right">{(mainData.b2coa.total.value - (mainData.b2coa.ototal ? mainData.b2coa.ototal.value : 0)).toFixed(2)}</td>
                                                                <td class="verticalCenter text-right">{(mainData.b2coa.total.taxa - (mainData.b2coa.ototal ? mainData.b2coa.ototal.taxa : 0)).toFixed(2)}</td>
                                                                <td class="verticalCenter text-right">{(mainData.b2coa.total.taxb - (mainData.b2coa.ototal ? mainData.b2coa.ototal.taxb : 0)).toFixed(2)}</td>
                                                                <td class="verticalCenter text-right">{(mainData.b2coa.total.taxb - (mainData.b2coa.ototal ? mainData.b2coa.ototal.taxb : 0)).toFixed(2)}</td>
                                                                <td class="verticalCenter text-right">{(mainData.b2coa.total.cess - (mainData.b2coa.ototal ? mainData.b2coa.ototal.cess : 0)).toFixed(2)}</td>
                                                            </tr>

                                                            {/* <!-- 11A - AT --> */}
                                                            <tr>
                                                                <td class="antiqueWhite" colspan="9" data-ng-bind="trans.LBL_SUM_11A_AT">11A(1), 11A(2) - Advances received for which invoice has not been issued (tax amount to be added to the output tax liability) (Net of Refund Vouchers)</td>
                                                            </tr>
                                                            <tr class="">
                                                                <td colspan="2">Total</td>
                                                                <td class="verticalCenter text-center">{mainData.a11.total.count}</td>
                                                                <td class="verticalCenter text-center">Net Value</td>
                                                                <td class="verticalCenter text-right">{(mainData.a11.total.value).toFixed(2)}</td>
                                                                <td class="verticalCenter text-right">{(mainData.a11.total.taxa).toFixed(2)}</td>
                                                                <td class="verticalCenter text-right">{(mainData.a11.total.taxb).toFixed(2)}</td>
                                                                <td class="verticalCenter text-right">{(mainData.a11.total.taxb).toFixed(2)}</td>
                                                                <td class="verticalCenter text-right">{(mainData.a11.total.cess).toFixed(2)}</td>
                                                            </tr>

                                                            {/* <!-- 11B - TXPD --> */}
                                                            <tr data-ng-if="txpdat &amp;&amp; !IFF">
                                                                <td class="antiqueWhite" colspan="9" data-ng-bind="trans.LBL_SUM_11B_AT">11B(1), 11B(2) - Advance amount received in earlier tax period and adjusted against the supplies being shown in this tax period in Table Nos. 4, 5, 6 and 7</td>
                                                            </tr>
                                                            <tr class="" data-ng-if="txpdat &amp;&amp; !IFF">
                                                                <td colspan="2">Total</td>
                                                                <td class="verticalCenter text-center">{mainData.b11.total.count}</td>
                                                                <td class="verticalCenter text-center">Net Value</td>
                                                                <td class="verticalCenter text-right">{(mainData.b11.total.value).toFixed(2)}</td>
                                                                <td class="verticalCenter text-right">{(mainData.b11.total.taxa).toFixed(2)}</td>
                                                                <td class="verticalCenter text-right">{(mainData.b11.total.taxb).toFixed(2)}</td>
                                                                <td class="verticalCenter text-right">{(mainData.b11.total.taxb).toFixed(2)}</td>
                                                                <td class="verticalCenter text-right">{(mainData.b11.total.cess).toFixed(2)}</td>
                                                            </tr>

                                                            {/* <!-- 11A - ATA --> */}
                                                            <tr data-ng-if="atadat &amp;&amp; !IFF">
                                                                <td class="antiqueWhite" colspan="9" data-ng-bind="trans.LBL_SUM_11A_ATA">11A - Amendment to advances received in returns for earlier tax periods in table 11A(1), 11A(2)</td>
                                                            </tr>
                                                            <tr class="" data-ng-if="atadat &amp;&amp; !IFF">
                                                                <td colspan="2">Amended amount - Total</td>
                                                                <td class="verticalCenter text-center">{mainData.a11a.total.count}</td>
                                                                <td class="verticalCenter text-center">Net Value</td>
                                                                <td class="verticalCenter text-right">{(mainData.a11a.total.value).toFixed(2)}</td>
                                                                <td class="verticalCenter text-right">{(mainData.a11a.total.taxa).toFixed(2)}</td>
                                                                <td class="verticalCenter text-right">{(mainData.a11a.total.taxb).toFixed(2)}</td>
                                                                <td class="verticalCenter text-right">{(mainData.a11a.total.taxb).toFixed(2)}</td>
                                                                <td class="verticalCenter text-right">{(mainData.a11a.total.cess).toFixed(2)}</td>
                                                            </tr>
                                                            <tr class="" data-ng-if="atadat &amp;&amp; !IFF">
                                                                <td colspan="2">Net differential</td>
                                                                <td class="verticalCenter text-center"></td>
                                                                <td class="verticalCenter text-center"></td>
                                                                <td class="verticalCenter text-right">{(mainData.a11a.total.value - (mainData.a11a.ototal ? mainData.a11a.ototal.value : 0)).toFixed(2)}</td>
                                                                <td class="verticalCenter text-right">{(mainData.a11a.total.taxa - (mainData.a11a.ototal ? mainData.a11a.ototal.taxa : 0)).toFixed(2)}</td>
                                                                <td class="verticalCenter text-right">{(mainData.a11a.total.taxb - (mainData.a11a.ototal ? mainData.a11a.ototal.taxb : 0)).toFixed(2)}</td>
                                                                <td class="verticalCenter text-right">{(mainData.a11a.total.taxb - (mainData.a11a.ototal ? mainData.a11a.ototal.taxb : 0)).toFixed(2)}</td>
                                                                <td class="verticalCenter text-right">{(mainData.a11a.total.cess - (mainData.a11a.ototal ? mainData.a11a.ototal.cess : 0)).toFixed(2)}</td>
                                                            </tr>

                                                            {/* <!-- 11B - TXPDA --> */}
                                                            <tr data-ng-if="txpadat &amp;&amp; !IFF">
                                                                <td class="antiqueWhite" colspan="9" data-ng-bind="trans.LBL_SUM_11B_ATA">11B - Amendment to advances adjusted in returns for earlier tax periods in table 11B(1), 11B(2)</td>
                                                            </tr>
                                                            <tr class="" data-ng-if="txpadat &amp;&amp; !IFF">
                                                                <td colspan="2">Amended amount - Total</td>
                                                                <td class="verticalCenter text-center">{mainData.b11a.total.count}</td>
                                                                <td class="verticalCenter text-center">Net Value</td>
                                                                <td class="verticalCenter text-right">{(mainData.b11a.total.value).toFixed(2)}</td>
                                                                <td class="verticalCenter text-right">{(mainData.b11a.total.taxa).toFixed(2)}</td>
                                                                <td class="verticalCenter text-right">{(mainData.b11a.total.taxb).toFixed(2)}</td>
                                                                <td class="verticalCenter text-right">{(mainData.b11a.total.taxb).toFixed(2)}</td>
                                                                <td class="verticalCenter text-right">{(mainData.b11a.total.cess).toFixed(2)}</td>
                                                            </tr>
                                                            <tr class="" data-ng-if="txpadat &amp;&amp; !IFF">
                                                                <td colspan="2">Net differential</td>
                                                                <td class="verticalCenter text-center"></td>
                                                                <td class="verticalCenter text-center"></td>
                                                                <td class="verticalCenter text-right">{(mainData.b11a.total.value - (mainData.b11a.ototal ? mainData.b11a.ototal.value : 0)).toFixed(2)}</td>
                                                                <td class="verticalCenter text-right">{(mainData.b11a.total.taxa - (mainData.b11a.ototal ? mainData.b11a.ototal.taxa : 0)).toFixed(2)}</td>
                                                                <td class="verticalCenter text-right">{(mainData.b11a.total.taxb - (mainData.b11a.ototal ? mainData.b11a.ototal.taxb : 0)).toFixed(2)}</td>
                                                                <td class="verticalCenter text-right">{(mainData.b11a.total.taxb - (mainData.b11a.ototal ? mainData.b11a.ototal.taxb : 0)).toFixed(2)}</td>
                                                                <td class="verticalCenter text-right">{(mainData.b11a.total.cess - (mainData.b11a.ototal ? mainData.b11a.ototal.cess : 0)).toFixed(2)}</td>
                                                            </tr>

                                                            {/* <!-- 12 - HSN --> */}
                                                            <tr data-ng-if="hsndat &amp;&amp; !IFF">
                                                                <td class="antiqueWhite" colspan="9" data-ng-bind="trans.LBL_SUM_HSN">12 - HSN-wise summary of outward supplies</td>
                                                            </tr>
                                                            <tr class="" data-ng-if="hsndat &amp;&amp; !IFF">
                                                                <td colspan="2">Total</td>
                                                                <td class="verticalCenter text-center" data-ng-bind="hsndat.ttl_rec">{mainData.hsn.total.count}</td>
                                                                <td class="verticalCenter text-center">NA</td>
                                                                <td class="verticalCenter text-right">{(mainData.hsn.total.value).toFixed(2)}</td>
                                                                <td class="verticalCenter text-right">{(mainData.hsn.total.taxa).toFixed(2)}</td>
                                                                <td class="verticalCenter text-right">{(mainData.hsn.total.taxb).toFixed(2)}</td>
                                                                <td class="verticalCenter text-right">{(mainData.hsn.total.taxc).toFixed(2)}</td>
                                                                <td class="verticalCenter text-right">{(mainData.hsn.total.cess).toFixed(2)}</td>
                                                            </tr>

                                                            {/* <!-- 13 - DOC ISSUED --> */}
                                                            <tr data-ng-if="docdat &amp;&amp; !IFF">
                                                                <td class="antiqueWhite" colspan="9" data-ng-bind="trans.LBL_DOC_ISSUED">13 - Documents issued</td>
                                                            </tr>
                                                            <tr class="verticalCenter" data-ng-if="docdat &amp;&amp; !IFF">
                                                                <td colspan="2">
                                                                    <div data-ng-click="collapseSummary('diShow')">Net issued documents
                                                                        {
                                                                            !tabDoc && <span class="cursor-pointer" onClick={() => {
                                                                                setTabDoc(true);
                                                                            }}>
                                                                                <i class="fa fa-fw fa-chevron-down"></i>
                                                                            </span>
                                                                        }
                                                                        {
                                                                            tabDoc && <span class="cursor-pointer" onClick={() => {
                                                                                setTabDoc(false);
                                                                            }}>
                                                                                <i class="fa fa-fw fa-chevron-down"></i>
                                                                            </span>
                                                                        }
                                                                    </div>
                                                                </td>
                                                                <td colspan="1" class="verticalCenter text-center" data-ng-bind="docdat.net_doc_issued">{mainData.B13.total.countA}</td>
                                                                <td colspan="1" class="verticalCenter text-center">All Documents</td>
                                                                <td colspan="5" class="verticalCenter text-center"></td>
                                                            </tr>
                                                            {
                                                                tabDoc && <>
                                                                    <tr data-ng-show="diShow" data-ng-if="docdat &amp;&amp; !IFF" class="">
                                                                        <td colspan="2" class="verticalCenter subsec" data-ng-bind="trans.LBL_DOCISS">Documents issued</td>
                                                                        <td class="verticalCenter text-center" data-ng-bind="docdat.ttl_doc_issued">{mainData.B13.total.countB}</td>
                                                                        <td class="verticalCenter greyCell"></td>
                                                                        <td class="verticalCenter greyCell"></td>
                                                                        <td class="verticalCenter greyCell"></td>
                                                                        <td class="verticalCenter greyCell"></td>
                                                                        <td class="verticalCenter greyCell"></td>
                                                                        <td class="verticalCenter greyCell"></td>
                                                                    </tr>
                                                                    <tr data-ng-show="diShow" data-ng-if="docdat &amp;&amp; !IFF" class="">
                                                                        <td colspan="2" class="verticalCenter subsec" data-ng-bind="trans.LBL_DOCCAN">Documents cancelled</td>
                                                                        <td class="verticalCenter text-center" data-ng-bind="docdat.ttl_doc_cancelled">{mainData.B13.total.countC}</td>
                                                                        <td class="verticalCenter greyCell"></td>
                                                                        <td class="verticalCenter greyCell"></td>
                                                                        <td class="verticalCenter greyCell"></td>
                                                                        <td class="verticalCenter greyCell"></td>
                                                                        <td class="verticalCenter greyCell"></td>
                                                                        <td class="verticalCenter greyCell"></td>
                                                                    </tr>
                                                                </>
                                                            }
                                                            {/* <!-- TOTAL LIABILITY --> */}
                                                            <tr style={{ backgroundColor: '#c9ece1', color: 'black', fontWeight: 'bold' }} data-ng-if="ttldat">
                                                                <td colspan="4" data-ng-bind="trans.LBL_SUM_TOT_LIAB">Total Liability (Outward supplies other than Reverse charge)</td>
                                                                <td class="verticalCenter text-right">{(mainData.total.value).toFixed(2)}</td>
                                                                <td class="verticalCenter text-right">{(mainData.total.taxa).toFixed(2)}</td>
                                                                <td class="verticalCenter text-right">{(mainData.total.taxb).toFixed(2)}</td>
                                                                <td class="verticalCenter text-right">{(mainData.total.taxb).toFixed(2)}</td>
                                                                <td class="verticalCenter text-right">{(mainData.total.cess).toFixed(2)}</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    }

                                    <div ref={pdfRef}>
                                        {
                                            isPrint && <PanelSummeryPDF data={mainData} gstrFile={gstrFile} />
                                        }
                                    </div>

                                    <div class="row" style={{ marginTop: '-2%', marginRight: '16px' }}>
                                        <div class="pull-right">
                                            <button type="button" class="btn btn-default" onClick={() => {
                                                gotoPath('gstrForm')
                                            }}>Back</button>&nbsp;
                                            {
                                                gstrFile.status !== "done" &&
                                                <button type="button" class="btn btn-primary" title="Click here to download GSTR-1 summary for all tax periods in PDF format." data-toggle="tooltip" onClick={() => {
                                                    window.scrollTo({
                                                        top: 0,
                                                        behavior: 'smooth',
                                                    });
                                                    setIsPrint(true);
                                                    handleGeneratePDF();
                                                }}>
                                                    <span data-ng-show="fil_stat!='FIL'" class="ng-hide">DOWNLOAD SUMMARY (PDF)</span>
                                                </button>
                                            }
                                            {
                                                gstrFile.status === "done" && <button type="button" class="btn btn-primary" title="Click here to download GSTR-1 summary for all tax periods in PDF format." data-toggle="tooltip" onClick={() => {
                                                    window.scrollTo({
                                                        top: 0,
                                                        behavior: 'smooth',
                                                    });
                                                    setIsPrint(true);
                                                    handleGeneratePDF();
                                                }}>
                                                    <span data-ng-show="fil_stat!='FIL'" class="ng-hide">DOWNLOAD (PDF)</span>
                                                </button>
                                            }
                                            &nbsp;<button type="button" class="btn btn-primary" onClick={() => {
                                                setLoading(true);
                                                gotoPath('gstrFileValid');
                                            }} disabled={gstrFile.status === "done"}>File Statement</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
