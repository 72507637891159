import React, { useState, useEffect } from 'react';

import { useRecoilState } from 'recoil';
import { pathAtom, loadingAtom, gstrFileAtom, digErrorAtom } from '../../../core/config/atoms';

import { useFormik } from 'formik';
import * as Yup from 'yup';
import Tippy from '@tippyjs/react';
import Scrollbars from 'react-custom-scrollbars';

import ServiceGSTF from '../../../services/serviceGSTF';

export default function Panel31Home() {

    const [loading, setLoading] = useRecoilState(loadingAtom);
    const [gstrf, setGSTRF] = useRecoilState(gstrFileAtom);

    const [, setPath] = useRecoilState(pathAtom);
    const [, setDigError] = useRecoilState(digErrorAtom);

    const [submit, setSubmit] = useState(false);

    const [isHelp, setIsHelp] = useState(false);

    const fileService = new ServiceGSTF();

    const formVSchema = Yup.object().shape({
        a: Yup.object().shape({
            total: Yup.object().shape({
                value: Yup.string().required(),
                isChange: Yup.boolean().required(),
            }).required(),
            igst: Yup.object().shape({
                value: Yup.string().required(),
                isChange: Yup.boolean().required(),
            }).required(),
            cgst: Yup.object().shape({
                value: Yup.string().required(),
                isChange: Yup.boolean().required(),
            }).required(),
            sgst: Yup.object().shape({
                value: Yup.string().required(),
                isChange: Yup.boolean().required(),
            }).required(),
            cess: Yup.object().shape({
                value: Yup.string().required(),
                isChange: Yup.boolean().required(),
            }).required(),
        }).required(),
        b: Yup.object().shape({
            total: Yup.object().shape({
                value: Yup.string().required(),
                isChange: Yup.boolean().required(),
            }).required(),
            igst: Yup.object().shape({
                value: Yup.string().required(),
                isChange: Yup.boolean().required(),
            }).required(),
            cess: Yup.object().shape({
                value: Yup.string().required(),
                isChange: Yup.boolean().required(),
            }).required(),
        }).required(),
        c: Yup.object().shape({
            total: Yup.object().shape({
                value: Yup.string().required(),
                isChange: Yup.boolean().required(),
            }).required(),
        }).required(),
        d: Yup.object().shape({
            total: Yup.object().shape({
                value: Yup.string().required(),
                isChange: Yup.boolean().required(),
            }).required(),
            igst: Yup.object().shape({
                value: Yup.string().required(),
                isChange: Yup.boolean().required(),
            }).required(),
            cgst: Yup.object().shape({
                value: Yup.string().required(),
                isChange: Yup.boolean().required(),
            }).required(),
            sgst: Yup.object().shape({
                value: Yup.string().required(),
                isChange: Yup.boolean().required(),
            }).required(),
            cess: Yup.object().shape({
                value: Yup.string().required(),
                isChange: Yup.boolean().required(),
            }).required(),
        }).required(),
        e: Yup.object().shape({
            total: Yup.object().shape({
                value: Yup.string().required(),
                isChange: Yup.boolean().required(),
            }).required(),
        }).required(),
    });

    const { values, errors, touched, setValues, handleChange, handleSubmit, handleReset, resetForm } = useFormik({
        initialValues: {},
        validationSchema: formVSchema,
        onSubmit: values => {
            setLoading(true);
            setSubmit(true);
            var newObj = JSON.parse(JSON.stringify(gstrf.data));
            newObj.a3.user = { ...values };
            var body = {
                id: gstrf._id,
                data: newObj
            };
            fileService.setGSTFDoc(body).then((res) => {
                setLoading(false);
                setSubmit(false);
                if (res.status) {
                    setGSTRF(res.data);
                    gotoPath('gstr3BForm');
                } else {
                    setDigError({ title: 'Error', msg: 'Server error please try again!', isToggle: true });
                    window.scrollTo({
                        top: 0,
                        behavior: 'smooth',
                    });
                }
            });
        }
    });

    const getFormatvalue = (val) => {
        if (!val) return val;
        let number = val.replace(/[^\d.]/g, "");
        if (val.includes(".")) {
            const a = number.split(".");
            if (a[1].length > 2) {
                a[1] = a[1].substring(0, 2);
            }
            if (a[0].length > 13) {
                a[0] = a[0].substring(0, 13);
            }
            number = a[0] + "." + a[1];
        } else {
            if (number.length > 13) {
                number = number.substring(0, 13);
            }
        }
        return number;
    }

    const checkIsChange = (obj) => {
        for (let key in obj) {
            if (typeof obj[key] === "object") {
                if (obj[key].hasOwnProperty("isChange") && obj[key].isChange === true) {
                    return true;
                } else if (checkIsChange(obj[key])) {
                    return true;
                }
            }
        }
        return false;
    }

    const getErrorCustom = (field) => {
        let cls = '';
        switch (field) {
            case 'a.total':
                if (values.a.total.isChange) {
                    cls = 'has-error';
                }
                break;
            case 'a.igst':
                if (values.a.igst.isChange) {
                    cls = 'has-error';
                }
                break;
            case 'a.sgst':
                if (values.a.sgst.isChange) {
                    cls = 'has-error';
                }
                break;
            case 'a.cgst':
                if (values.a.cgst.isChange) {
                    cls = 'has-error';
                }
                break;
            case 'a.cess':
                if (values.a.cess.isChange) {
                    cls = 'has-error';
                }
                break;
            case 'b.total':
                if (values.b.total.isChange) {
                    cls = 'has-error';
                }
                break;
            case 'b.igst':
                if (values.b.igst.isChange) {
                    cls = 'has-error';
                }
                break;
            case 'b.cess':
                if (values.b.cess.isChange) {
                    cls = 'has-error';
                }
            case 'c.total':
                if (values.c.total.isChange) {
                    cls = 'has-error';
                }
                break;
            case 'd.total':
                if (values.d.total.isChange) {
                    cls = 'has-error';
                }
                break;
            case 'd.igst':
                if (values.d.igst.isChange) {
                    cls = 'has-error';
                }
                break;
            case 'd.sgst':
                if (values.d.sgst.isChange) {
                    cls = 'has-error';
                }
                break;
            case 'd.cgst':
                if (values.d.cgst.isChange) {
                    cls = 'has-error';
                }
                break;
            case 'd.cess':
                if (values.d.cess.isChange) {
                    cls = 'has-error';
                }
                break;
            case 'e.total':
                if (values.e.total.isChange) {
                    cls = 'has-error';
                }
                break;
        }
        return cls;
    }

    const gotoPath = (path) => {
        setPath(path);
    }

    function convertValuesToFixedDecimal(obj) {
        const newObj = {};

        for (let key in obj) {
            const value = obj[key];

            if (typeof value === 'object' && value !== null) {
                newObj[key] = convertValuesToFixedDecimal(value);
            } else if (typeof value === 'number') {
                newObj[key] = value.toFixed(2);
            } else {
                newObj[key] = value;
            }
        }

        return newObj;
    }

    const loadData = () => {
        var refs = convertValuesToFixedDecimal(gstrf.data.a3.user);
        setValues({ ...values, ...refs });
        if (gstrf.status === "done" || gstrf.status === "submitted") {
            setSubmit(true);
        }
        setLoading(false);
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    }

    const callCheack = (e) => {
        const keyArr = e.target.id.split('.');

        let user = { ...values };
        let system = { ...gstrf.data.a3.system };

        let rhs = parseFloat(user[keyArr[0]][keyArr[1]][keyArr[2]]);
        let lhs = parseFloat(system[keyArr[0]][keyArr[1]][keyArr[2]]);

        let v = {};

        if (keyArr[1] === "cgst" || keyArr[1] === "sgst") {
            if (keyArr[1] === "cgst") {
                user[keyArr[0]]["sgst"]['value'] = parseFloat(user[keyArr[0]][keyArr[1]]["value"]).toFixed(2).toString();
            }
            if (keyArr[1] === "sgst") {
                user[keyArr[0]]["cgst"]['value'] = parseFloat(user[keyArr[0]][keyArr[1]]["value"]).toFixed(2).toString();
            }
        }

        if (lhs > rhs) {
            if (keyArr[1] === "cgst" || keyArr[1] === "sgst") {
                user[keyArr[0]]["cgst"]['isChange'] = true;
                user[keyArr[0]]["sgst"]['isChange'] = true;
            } else {
                user[keyArr[0]][keyArr[1]]['isChange'] = true;
            }
            v = { ...values, ...user };
        } else {
            if (keyArr[1] === "cgst" || keyArr[1] === "sgst") {
                user[keyArr[0]]["cgst"]['isChange'] = false;
                user[keyArr[0]]["sgst"]['isChange'] = false;
            } else {
                user[keyArr[0]][keyArr[1]]['isChange'] = false;
            }
            v = { ...values, ...user };
        }

        setValues({ ...v });
        handleChange(e);
    }

    useEffect(() => {
        loadData();
    }, []);

    return (
        <>
            {
                isHelp && <div className='w-full h-full overflow-hidden fixed top-0 z-50 flex items-center justify-center bg-black bg-opacity-60'>
                    <Scrollbars>
                        <div className="modal-dialog" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => { setIsHelp(false) }}>
                                        <span aria-hidden="true">×</span>
                                    </button>
                                    <h4 className="modal-title text-[#0b1e59d9]">Tax on outward and reverse charge inward supplies</h4>
                                </div>
                                <div className="modal-body">
                                    <div>
                                        <p>
                                            <span >Effective from Tax period </span>
                                            <span >July </span>
                                            <span >2022 </span>
                                            <span data-ng-bind="trans.IOSUP3B_206">Supplies made under section 9(5) of the CGST Act, 2017 and corresponding provisions in IGST/UTGST/SGST Acts should be reported as mentioned below:</span>
                                        </p>
                                        <ul className='list-disc pl-16'>
                                            <li className="list-item"><p><span data-ng-bind="trans.IOSUP3B_207">An Electronic Commerce Operator liable to pay taxes should reduce such supplies from table 3.1(a) and report it in table 3.1.1(i).</span></p></li>
                                            <li className="list-item"><p><span data-ng-bind="trans.IOSUP3B_208">Supplies made by person on which electronic commerce operator are liable to pay taxes should reduce such supplies in table 3.1(c) and report it in table 3.1.1(ii).</span></p></li>
                                        </ul>
                                    </div>
                                    <p><b className='text-black'>Note: </b><span data-ng-bind="trans.IOSUP3B_209">The values auto-drafted in table 3.1(a) and table 3.1(c) from GSTR-1 include the supplies which are made through electronic commerce operators.</span></p>
                                    <h4 className='text-[#0b1e59d9]'>3.1(a) Outward supplies other than zero rated, nil rated and exempted</h4>
                                    <p data-ng-bind="trans.IOSUP3B_156">Include the taxable value of all inter-State and intra-State B2B as well as B2C supplies made during the tax period. Reporting should be net off debit/credit notes and amendments of amounts pertaining to earlier tax periods, if any.</p>
                                    <p><b className='text-black'>Value of Taxable Supplies</b><span data-ng-bind="trans.IOSUP3B_202">= (Value of invoices) + (Value of Debit Notes) - (Value of Credit Notes) + (Value of advances received for which invoices have not been issued in the same Month) - (Value of advances adjusted against invoices).</span> </p>
                                    <p><b className='text-black'>Integrated Tax, Central Tax, State/UT Tax and Cess</b><span data-ng-bind="trans.IOSUP3B_203">: Only Tax amount should be entered against respective head. Please ensure you declare a tax amount IGST and/or CGST and SGST along with Cess applicable, if any.</span></p>
                                    <p>
                                        <b className='text-black'>Note: </b>
                                        <span >Effective from Tax period </span>
                                        <span >July </span>
                                        <span >2022 </span>
                                        <span data-ng-bind="trans.IOSUP3B_204">Supplies made through an Electronic Commerce Operator (ECO) on which it is liable to pay tax under section 9(5) of the CGST Act, 2017 and corresponding provisions in IGST/UTGST/SGST Acts should be reported in table 3.1.1(i) and Not in table 3.1(a).</span>
                                    </p>
                                    <h4 className='text-[#0b1e59d9]'>3.1(b) Outward taxable supplies (zero rated)</h4>
                                    <p data-ng-bind="trans.IOSUP3B_160">Mention Export Supplies made including supplies to SEZ/SEZ developers. Total taxable value should include supplies on which tax has been charged as well as supplies made against bond or letter of undertaking.</p>
                                    <p data-ng-bind="trans.IOSUP3B_161">Integrated Tax and Cess should include amount of tax, if paid, on the supplies made.</p>
                                    <h4 className='text-[#0b1e59d9]'>3.1(c) Other outward supplies (Nil rated, exempted)</h4>
                                    <p data-ng-bind="trans.IOSUP3B_163">Here include all outward supplies which are not liable to tax either because they are nil rated or exempt through notification. It should not include export supplies or supplies made to SEZ developers or units declared in 3.1(b) above.</p>
                                    <p>
                                        <b className='text-black'>Note: </b>
                                        <span >Effective from Tax period </span>
                                        <span >July </span>
                                        <span >2022 </span>
                                        <span >A person making supplies through an Electronic Commerce Operator, on which Electronic Commerce Operator is liable to pay tax under section 9(5) of the CGST Act, 2017 and corresponding provisions in IGST/UTGST/SGST Acts should report such supplies in table 3.1.1 (ii) and Not in table 3.1(c).</span>
                                    </p>
                                    <h4 className='text-[#0b1e59d9]'>3.1(d) Inward supplies (liable to reverse charge)</h4>
                                    <p data-ng-bind="trans.IOSUP3B_165">Include inward supplies which are subject to reverse charge mechanism. This also includes supplies received from unregistered persons on which tax is liable to be paid by recipient.</p>
                                    <h4 className='text-[#0b1e59d9]'>3.1(e) Non-GST Outward Supplies</h4>
                                    <p data-ng-bind="trans.IOSUP3B_167">Amount in Total taxable value should include aggregate of value of all the supplies which are not chargeable under GST Act e.g. petroleum products.</p>

                                    <div data-ng-if="showNilQuestionaire || autoCalc2bFlag || validGSTR1Q3BRetPrd || validGSTR2BQ3BRetPrd">
                                        <p></p><h4><span className='text-[#0b1e59d9]'>Facilitation in filing GSTR-3B</span></h4><p></p>
                                        <ul className='pl-16 list-disc'>
                                            <li className="list-item"><p><span data-ng-bind="trans.IOSUP3B_HELP1">The summary is generated for tables 3.1 (a), (b), (c), (e) based on filed GSTR-1</span><span data-ng-bind="trans.IOSUP3B_HELP3">. Table 3.1(d) is auto-drafted based on GSTR-2B.</span></p></li>
                                            <li className="list-item"><p><span data-ng-bind="trans.IOSUP3B_HELP4">The values will be auto-drafted in each cells and would be editable.</span></p></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-primary" data-dismiss="modal" data-ng-bind="trans.IOSUP3B_170" onClick={() => { setIsHelp(false) }}>Close</button>
                                </div>
                            </div>
                            <div className='h-[120px]'></div>
                        </div>
                    </Scrollbars>
                </div>
            }
            <div className="content-wrapper fade-ng-cloak">
                <div className="container">
                    <div className="mypage">
                        <div className="row">
                            <div className="col-xs-10">
                                <div data-breadcrumb="" name="Dashboard">
                                    <ol className="breadcrumb" data-ng-controller="crumbCtrl">
                                        <li>
                                            <a onClick={() => { gotoPath('gstr') }}>Dashboard</a>
                                        </li>
                                        <li>
                                            <a onClick={() => { gotoPath('gstRetrun') }}>Returns</a>
                                        </li>
                                        <li>
                                            <a onClick={() => {
                                                setLoading(true);
                                                gotoPath('gstr3BForm')
                                            }}>GSTR-3B</a>
                                        </li>
                                        <li>
                                            Outward and Reverse Charge Inward
                                        </li>
                                    </ol>
                                </div>
                            </div>
                            <div className="col-xs-2">
                                <div className="lang dropdown">
                                    <span className="dropdown-toggle" data-toggle="dropdown" data-ng-bind="selectedLang">English</span>
                                    <ul className="dropdown-menu lang-dpdwn">
                                        <li data-ng-repeat="language in languages">
                                            <a data-ng-click="selectLang(language.cd)" data-ng-bind="language.nm">English</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div>
                            {
                                !loading && <form autocomplete="off" novalidate="" onSubmit={handleSubmit} onReset={handleReset}>
                                    <div className="container">
                                        <div className="row invsumm">
                                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                <div className="row">
                                                    <h4 className="col-xs-11">3.1 Details of Outward Supplies and inward supplies liable to reverse charge (other than those covered by Table 3.1.1)</h4>
                                                    <h4 className="col-xs-1">
                                                        <a onClick={() => { setIsHelp(true) }}>Help</a>
                                                        &nbsp;
                                                        <i className="fa fa-question-circle" aria-hidden="true"></i>
                                                    </h4>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="tabpane">
                                            <div className="alert alert-msg alert-success alert-dismissible accept">
                                                <button type="button" className="close" aria-hidden="true">
                                                    <i className="fa fa-times"></i>
                                                </button>
                                                Last Save request has been processed successfully.
                                            </div>
                                            <div className="alert alert-msg alert-info alert-dismissible">
                                                <button type="button" className="close" data-dismiss="alert" aria-hidden="true">
                                                    <i className="fa fa-times"></i>
                                                </button>
                                                <i className="fa fa-info-circle"></i>&nbsp;
                                                <span>
                                                    Table 3.1(a), (b), (c) and (e) are auto-drafted based on values provided in GSTR-1. Whereas Table 3.1(d) is auto-drafted based on GSTR-2B.
                                                </span>
                                            </div>
                                            <div name="validationmsg"></div>
                                            <div className="row">
                                                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                    {
                                                        checkIsChange(values) && <div className="alert alert-msg alert-danger ng-hide" data-ng-show="gstr3bErrorMsg">
                                                            <div className='flex items-center m-0'>
                                                                <i class="fa fa-warning"></i>
                                                                &nbsp;<p className='m-0'>The information entered by you is at variance with the auto-populated data. The liability has been computed on the basis of the information declared by you in your FOTM GSTR-1.</p>
                                                            </div>
                                                        </div>
                                                    }
                                                    <div className="rettbl-format">
                                                        <div className="table-responsive">
                                                            <table className="table tbl inv exp table-bordered">
                                                                <thead>
                                                                    <tr>
                                                                        <th className="text-left" data-ng-bind="trans.LBL_NATURE_OF_SUP">Nature of Supplies</th>
                                                                        <th className="text-left" data-ng-bind="trans.LBL_TOTAL_TAXABLE_VAL">Total Taxable value (₹)</th>
                                                                        <th className="text-left" data-ng-bind="trans.LBL_GST3B_ITAX">Integrated Tax (₹)</th>
                                                                        <th className="text-left" data-ng-bind="trans.LBL_GST3B_CTAX">Central Tax (₹)</th>
                                                                        <th className="text-left" data-ng-bind="trans.LBL_GST3B_STAX">State/UT Tax (₹)</th>
                                                                        <th className="text-left" data-ng-bind="trans.LBL_GST3B_CS">CESS (₹)</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr>
                                                                        <th className="text-left">
                                                                            <p className="text-left">(a) Outward taxable  supplies  (other than zero rated, nil rated and exempted)</p>
                                                                        </th>
                                                                        <td>
                                                                            <Tippy content={<span style={{ border: '1px solid black' }} className="bg-white px-4 py-2">Your amount: ₹{values.a.total.value} System computed: ₹{gstrf.data.a3.system.a.total.value.toFixed(2)}</span>} placement='bottom' maxWidth={400} className='text-center bg-transparent text-lg text-black w-[400px]' arrow={false}>
                                                                                <div className={getErrorCustom('a.total')}>
                                                                                    <input name="a.total.value" id="a.total.value" value={values.a.total.value} onChange={e => {
                                                                                        const formatValue = getFormatvalue(e.target.value);
                                                                                        e.target.value = formatValue;
                                                                                        handleChange(e);
                                                                                    }} onBlur={e => {
                                                                                        if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                            e.target.value = e.target.value + ".00";
                                                                                        }
                                                                                        callCheack(e);
                                                                                    }} className="total form-control formedit newinv currency" disabled={gstrf.status === "done" || gstrf.status === "submitted"} />
                                                                                </div>
                                                                            </Tippy>
                                                                        </td>
                                                                        <td>
                                                                            <Tippy content={<span style={{ border: '1px solid black' }} className="bg-white px-4 py-2">Your amount: ₹{values.a.igst.value} System computed: ₹{gstrf.data.a3.system.a.igst.value.toFixed(2)}</span>} placement='bottom' maxWidth={400} className='text-center bg-transparent text-lg text-black w-[400px]' arrow={false}>
                                                                                <div className={getErrorCustom('a.igst')}>
                                                                                    <input name="a.igst.value" id="a.igst.value" value={values.a.igst.value} onChange={e => {
                                                                                        const formatValue = getFormatvalue(e.target.value);
                                                                                        e.target.value = formatValue;
                                                                                        handleChange(e);
                                                                                    }} onBlur={e => {
                                                                                        if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                            e.target.value = e.target.value + ".00";
                                                                                        }
                                                                                        callCheack(e);
                                                                                    }} className="form-control formedit newinv currency" disabled={gstrf.status === "done" || gstrf.status === "submitted"} />
                                                                                </div>
                                                                            </Tippy>
                                                                        </td>
                                                                        <td>
                                                                            <Tippy content={<span style={{ border: '1px solid black' }} className="bg-white px-4 py-2">Your amount: ₹{values.a.cgst.value} System computed: ₹{gstrf.data.a3.system.a.cgst.value.toFixed(2)}</span>} placement='bottom' maxWidth={400} className='text-center bg-transparent text-lg text-black w-[400px]' arrow={false}>
                                                                                <div className={getErrorCustom('a.cgst')}>
                                                                                    <input name="a.cgst.value" id="a.cgst.value" value={values.a.cgst.value} onChange={e => {
                                                                                        const formatValue = getFormatvalue(e.target.value);
                                                                                        e.target.value = formatValue;
                                                                                        handleChange(e);
                                                                                    }} onBlur={e => {
                                                                                        if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                            e.target.value = e.target.value + ".00";
                                                                                        }
                                                                                        callCheack(e);
                                                                                    }} className="form-control formedit newinv currency" disabled={gstrf.status === "done" || gstrf.status === "submitted"} />
                                                                                </div>
                                                                            </Tippy>
                                                                        </td>
                                                                        <td >
                                                                            <Tippy content={<span style={{ border: '1px solid black' }} className="bg-white px-4 py-2">Your amount: ₹{values.a.sgst.value} System computed: ₹{gstrf.data.a3.system.a.sgst.value.toFixed(2)}</span>} placement='bottom' maxWidth={400} className='text-center bg-transparent text-lg text-black w-[400px]' arrow={false}>
                                                                                <div className={getErrorCustom('a.sgst')}>
                                                                                    <input name="a.sgst.value" id="a.sgst.value" value={values.a.sgst.value} onChange={e => {
                                                                                        const formatValue = getFormatvalue(e.target.value);
                                                                                        e.target.value = formatValue;
                                                                                        handleChange(e);
                                                                                    }} onBlur={e => {
                                                                                        if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                            e.target.value = e.target.value + ".00";
                                                                                        }
                                                                                        callCheack(e);
                                                                                    }} className="form-control formedit newinv currency" disabled={gstrf.status === "done" || gstrf.status === "submitted"} />
                                                                                </div>
                                                                            </Tippy>
                                                                        </td>
                                                                        <td >
                                                                            <Tippy content={<span style={{ border: '1px solid black' }} className="bg-white px-4 py-2">Your amount: ₹{values.a.cess.value} System computed: ₹{gstrf.data.a3.system.a.cess.value.toFixed(2)}</span>} placement='bottom' maxWidth={400} className='text-center bg-transparent text-lg text-black w-[400px]' arrow={false}>
                                                                                <div className={getErrorCustom('a.cess')}>
                                                                                    <input name="a.cess.value" id="a.cess.value" value={values.a.cess.value} onChange={e => {
                                                                                        const formatValue = getFormatvalue(e.target.value);
                                                                                        e.target.value = formatValue;
                                                                                        handleChange(e);
                                                                                    }} onBlur={e => {
                                                                                        if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                            e.target.value = e.target.value + ".00";
                                                                                        }
                                                                                        callCheack(e);
                                                                                    }} className="form-control formedit newinv currency" disabled={gstrf.status === "done" || gstrf.status === "submitted"} />
                                                                                </div>
                                                                            </Tippy>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th>
                                                                            <p className="text-left">(b) Outward taxable  supplies  (zero rated )</p>
                                                                        </th>
                                                                        <td>
                                                                            <Tippy content={<span style={{ border: '1px solid black' }} className="bg-white px-4 py-2">Your amount: ₹{values.b.total.value} System computed: ₹{gstrf.data.a3.system.b.total.value.toFixed(2)}</span>} placement='bottom' maxWidth={400} className='text-center bg-transparent text-lg text-black w-[400px]' arrow={false}>
                                                                                <div className={getErrorCustom('b.total')}>
                                                                                    <input name="b.total.value" id="b.total.value" value={values.b.total.value} onChange={e => {
                                                                                        const formatValue = getFormatvalue(e.target.value);
                                                                                        e.target.value = formatValue;
                                                                                        handleChange(e);
                                                                                    }} onBlur={e => {
                                                                                        if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                            e.target.value = e.target.value + ".00";
                                                                                        }
                                                                                        callCheack(e);
                                                                                    }} className="total form-control formedit newinv currency" disabled={gstrf.status === "done" || gstrf.status === "submitted"} />
                                                                                </div>
                                                                            </Tippy>
                                                                        </td>
                                                                        <td>
                                                                            <Tippy content={<span style={{ border: '1px solid black' }} className="bg-white px-4 py-2">Your amount: ₹{values.b.igst.value} System computed: ₹{gstrf.data.a3.system.b.igst.value.toFixed(2)}</span>} placement='bottom' maxWidth={400} className='text-center bg-transparent text-lg text-black w-[400px]' arrow={false}>
                                                                                <div className={getErrorCustom('b.igst')}>
                                                                                    <input name="b.igst.value" id="b.igst.value" value={values.b.igst.value} onChange={e => {
                                                                                        const formatValue = getFormatvalue(e.target.value);
                                                                                        e.target.value = formatValue;
                                                                                        handleChange(e);
                                                                                    }} onBlur={e => {
                                                                                        if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                            e.target.value = e.target.value + ".00";
                                                                                        }
                                                                                        callCheack(e);
                                                                                    }} className="form-control formedit newinv currency" disabled={gstrf.status === "done" || gstrf.status === "submitted"} />
                                                                                </div>
                                                                            </Tippy>
                                                                        </td>
                                                                        <td className="gstr3b-blank"></td>
                                                                        <td className="gstr3b-blank"></td>
                                                                        <td>
                                                                            <Tippy content={<span style={{ border: '1px solid black' }} className="bg-white px-4 py-2">Your amount: ₹{values.b.cess.value} System computed: ₹{gstrf.data.a3.system.b.cess.value.toFixed(2)}</span>} placement='bottom' maxWidth={400} className='text-center bg-transparent text-lg text-black w-[400px]' arrow={false}>
                                                                                <div className={getErrorCustom('b.cess')}>
                                                                                    <input name="b.cess.value" id="b.cess.value" value={values.b.cess.value} onChange={e => {
                                                                                        const formatValue = getFormatvalue(e.target.value);
                                                                                        e.target.value = formatValue;
                                                                                        handleChange(e);
                                                                                    }} onBlur={e => {
                                                                                        if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                            e.target.value = e.target.value + ".00";
                                                                                        }
                                                                                        callCheack(e);
                                                                                    }} className="form-control formedit newinv currency" disabled={gstrf.status === "done" || gstrf.status === "submitted"} />
                                                                                </div>
                                                                            </Tippy>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th>
                                                                            <p className="text-left">(c) Other outward supplies (Nil rated, exempted)</p>
                                                                        </th>
                                                                        <td>
                                                                            <Tippy content={<span style={{ border: '1px solid black' }} className="bg-white px-4 py-2">Your amount: ₹{values.c.total.value} System computed: ₹{gstrf.data.a3.system.c.total.value.toFixed(2)}</span>} placement='bottom' maxWidth={400} className='text-center bg-transparent text-lg text-black w-[400px]' arrow={false}>
                                                                                <div className={getErrorCustom('c.total')}>
                                                                                    <input name="c.total.value" id="c.total.value" value={values.c.total.value} onChange={e => {
                                                                                        const formatValue = getFormatvalue(e.target.value);
                                                                                        e.target.value = formatValue;
                                                                                        handleChange(e);
                                                                                    }} onBlur={e => {
                                                                                        if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                            e.target.value = e.target.value + ".00";
                                                                                        }
                                                                                        callCheack(e);
                                                                                    }} className="total form-control formedit newinv currency" disabled={gstrf.status === "done" || gstrf.status === "submitted"} />
                                                                                </div>
                                                                            </Tippy>
                                                                        </td>
                                                                        <td className="gstr3b-blank"></td>
                                                                        <td className="gstr3b-blank"></td>
                                                                        <td className="gstr3b-blank"></td>
                                                                        <td className="gstr3b-blank"></td>
                                                                    </tr>
                                                                    <tr data-ng-if="valid2Brel2rtnprd || valid2BQrel2rtnprd">
                                                                        <th>
                                                                            <p className="text-left">(d) Inward supplies (liable to reverse charge)</p>
                                                                        </th>
                                                                        <td>
                                                                            <Tippy content={<span style={{ border: '1px solid black' }} className="bg-white px-4 py-2">Your amount: ₹{values.d.total.value} System computed: ₹{gstrf.data.a3.system.d.total.value.toFixed(2)}</span>} placement='bottom' maxWidth={400} className='text-center bg-transparent text-lg text-black w-[400px]' arrow={false}>
                                                                                <div className={getErrorCustom('d.total')}>
                                                                                    <input name="d.total.value" id="d.total.value" value={values.d.total.value} onChange={e => {
                                                                                        const formatValue = getFormatvalue(e.target.value);
                                                                                        e.target.value = formatValue;
                                                                                        handleChange(e);
                                                                                    }} onBlur={e => {
                                                                                        if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                            e.target.value = e.target.value + ".00";
                                                                                        }
                                                                                        callCheack(e);
                                                                                    }} className="total form-control formedit newinv currency" disabled={gstrf.status === "done" || gstrf.status === "submitted"} />
                                                                                </div>
                                                                            </Tippy>
                                                                        </td>
                                                                        <td>
                                                                            <Tippy content={<span style={{ border: '1px solid black' }} className="bg-white px-4 py-2">Your amount: ₹{values.d.igst.value} System computed: ₹{gstrf.data.a3.system.d.igst.value.toFixed(2)}</span>} placement='bottom' maxWidth={400} className='text-center bg-transparent text-lg text-black w-[400px]' arrow={false}>
                                                                                <div className={getErrorCustom('d.igst')}>
                                                                                    <input name="d.igst.value" id="d.igst.value" value={values.d.igst.value} onChange={e => {
                                                                                        const formatValue = getFormatvalue(e.target.value);
                                                                                        e.target.value = formatValue;
                                                                                        handleChange(e);
                                                                                    }} onBlur={e => {
                                                                                        if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                            e.target.value = e.target.value + ".00";
                                                                                        }
                                                                                        callCheack(e);
                                                                                    }} className="form-control formedit newinv currency" disabled={gstrf.status === "done" || gstrf.status === "submitted"} />
                                                                                </div>
                                                                            </Tippy>
                                                                        </td>
                                                                        <td>
                                                                            <Tippy content={<span style={{ border: '1px solid black' }} className="bg-white px-4 py-2">Your amount: ₹{values.d.cgst.value} System computed: ₹{gstrf.data.a3.system.d.cgst.value.toFixed(2)}</span>} placement='bottom' maxWidth={400} className='text-center bg-transparent text-lg text-black w-[400px]' arrow={false}>
                                                                                <div className={getErrorCustom('d.cgst')}>
                                                                                    <input name="d.cgst.value" id="d.cgst.value" value={values.d.cgst.value} onChange={e => {
                                                                                        const formatValue = getFormatvalue(e.target.value);
                                                                                        e.target.value = formatValue;
                                                                                        handleChange(e);
                                                                                    }} onBlur={e => {
                                                                                        if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                            e.target.value = e.target.value + ".00";
                                                                                        }
                                                                                        callCheack(e);
                                                                                    }} className="form-control formedit newinv currency" disabled={gstrf.status === "done" || gstrf.status === "submitted"} />
                                                                                </div>
                                                                            </Tippy>
                                                                        </td>
                                                                        <td>
                                                                            <Tippy content={<span style={{ border: '1px solid black' }} className="bg-white px-4 py-2">Your amount: ₹{values.d.sgst.value} System computed: ₹{gstrf.data.a3.system.d.sgst.value.toFixed(2)}</span>} placement='bottom' maxWidth={400} className='text-center bg-transparent text-lg text-black w-[400px]' arrow={false}>
                                                                                <div className={getErrorCustom('d.sgst')}>
                                                                                    <input name="d.sgst.value" id="d.sgst.value" value={values.d.sgst.value} onChange={e => {
                                                                                        const formatValue = getFormatvalue(e.target.value);
                                                                                        e.target.value = formatValue;
                                                                                        handleChange(e);
                                                                                    }} onBlur={e => {
                                                                                        if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                            e.target.value = e.target.value + ".00";
                                                                                        }
                                                                                        callCheack(e);
                                                                                    }} className="form-control formedit newinv currency" disabled={gstrf.status === "done" || gstrf.status === "submitted"} />
                                                                                </div>
                                                                            </Tippy>
                                                                        </td>
                                                                        <td>
                                                                            <Tippy content={<span style={{ border: '1px solid black' }} className="bg-white px-4 py-2">Your amount: ₹{values.d.cess.value} System computed: ₹{gstrf.data.a3.system.d.cess.value.toFixed(2)}</span>} placement='bottom' maxWidth={400} className='text-center bg-transparent text-lg text-black w-[400px]' arrow={false}>
                                                                                <div className={getErrorCustom('d.cess')}>
                                                                                    <input name="d.cess.value" id="d.cess.value" value={values.d.cess.value} onChange={e => {
                                                                                        const formatValue = getFormatvalue(e.target.value);
                                                                                        e.target.value = formatValue;
                                                                                        handleChange(e);
                                                                                    }} onBlur={e => {
                                                                                        if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                            e.target.value = e.target.value + ".00";
                                                                                        }
                                                                                        callCheack(e);
                                                                                    }} className="form-control formedit newinv currency" disabled={gstrf.status === "done" || gstrf.status === "submitted"} />
                                                                                </div>
                                                                            </Tippy>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th>
                                                                            <p className="text-left">(e) Non-GST outward supplies</p>
                                                                        </th>
                                                                        <td>
                                                                            <Tippy content={<span style={{ border: '1px solid black' }} className="bg-white px-4 py-2">Your amount: ₹{values.e.total.value} System computed: ₹{gstrf.data.a3.system.e.total.value.toFixed(2)}</span>} placement='bottom' maxWidth={400} className='text-center bg-transparent text-lg text-black w-[400px]' arrow={false}>
                                                                                <div className={getErrorCustom('e.total')}>
                                                                                    <input name="e.total.value" id="e.total.value" value={values.e.total.value} onChange={e => {
                                                                                        const formatValue = getFormatvalue(e.target.value);
                                                                                        e.target.value = formatValue;
                                                                                        handleChange(e);
                                                                                    }} onBlur={e => {
                                                                                        if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                            e.target.value = e.target.value + ".00";
                                                                                        }
                                                                                        callCheack(e);
                                                                                    }} className="total form-control formedit newinv currency" disabled={gstrf.status === "done" || gstrf.status === "submitted"} />
                                                                                </div>
                                                                            </Tippy>
                                                                        </td>
                                                                        <td className="gstr3b-blank"></td>
                                                                        <td className="gstr3b-blank"></td>
                                                                        <td className="gstr3b-blank"></td>
                                                                        <td className="gstr3b-blank"></td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="btn-toolbar">
                                                <button type="submit" className="btn btn-primary pull-right" disabled={submit} >CONFIRM</button>
                                                <button type="button" className="btn btn-primary pull-right" onClick={() => {
                                                    setLoading(true);
                                                    gotoPath('gstr3BForm');
                                                }}>CANCEL</button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
