import React, { useEffect, useState } from 'react';

import { useRecoilState } from 'recoil';
import { pathAtom, digErrorAtom, bnameAtom, gstrUserAtom, userIDAtom } from '../../core/config/atoms';

import Moment from 'moment';

import ServiceLMS from '../../services/serviceLMS';

import './css/main.css';
import './css/style.css';
import './css/sub.css';
import './css/style3b.css';

export default function PanelGSTRMain() {

    const [path, setPath] = useRecoilState(pathAtom);
    const [digError, setDigError] = useRecoilState(digErrorAtom);
    const [bname, setBName] = useRecoilState(bnameAtom);
    const [GSTUser, setGSTUser] = useRecoilState(gstrUserAtom);
    const [userID] = useRecoilState(userIDAtom);

    const [returnsA, setReturnsA] = useState([]);
    const [returnsB, setReturnsB] = useState([]);

    const [LMSUser, setLMSUser] = useState({});

    const lmsService = new ServiceLMS();

    const gotoPath = (path) => {
        setPath(path);
    }

    const getStartYear = () => {
        let data = Moment(LMSUser.GSTRFilled).toDate();
        let m = data.getMonth();
        let year = "yyyy";
        if (m <= 2) {
            year = Moment(LMSUser.GSTRFilled).subtract(1, "years").format("YYYY");
        } else {
            year = Moment(LMSUser.GSTRFilled).format("YYYY");
        }
        return year !== "" ? year : "xxxx";
    }

    const getEndYear = () => {
        let data = Moment(LMSUser.GSTRFilled).toDate();
        let m = data.getMonth();
        let year = "yyyy";
        if (m >= 3) {
            year = Moment(LMSUser.GSTRFilled).add(1, 'years').format("YYYY");
        } else {
            year = Moment(LMSUser.GSTRFilled).format("YYYY");
        }
        return year !== "" ? year : "xxxx";
    }

    const getReturnsList = (user, numa, numb) => {
        let temp = [];
        let mainDate = Moment(user.GSTReturn).toDate();
        for (let i = 0; i < 5; i++) {
            let cdata = null;
            if (numa > 4) {
                cdata = Moment(user.GSTReturn).add((i - 4), 'months').toDate();
            } else {
                cdata = Moment(user.GSTReturn).add((i - numa), 'months').toDate();
            }
            let mat = {
                fdate: Moment(cdata).format('MMM - YYYY'),
                edate: cdata,
                status: cdata < mainDate ? "Yes" : "No",
            }
            temp.push(mat);
        }
        setReturnsA(temp);

        temp = [];
        mainDate = Moment(user.GSTRFilled).toDate();
        for (let i = 0; i < 5; i++) {
            let cdata = null;
            if (numb > 4) {
                cdata = Moment(user.GSTRFilled).add((i - 4), 'months').toDate();
            } else {
                cdata = Moment(user.GSTRFilled).add((i - numb), 'months').toDate();
            }
            let mat = {
                fdate: Moment(cdata).format('MMM - YYYY'),
                edate: cdata,
                status: cdata < mainDate ? "Yes" : "No",
            }
            temp.push(mat);
        }
        setReturnsB(temp);
    }

    const getStateBasedNum = () => {
        switch (GSTUser.state) {
            case "ANDAMAN AND NICOBAR ISLANDS":
            case "CHHATTISGARH":
            case "MADHYA PRADESH":
            case "GUJARAT":
            case "MAHARASHTRA":
            case "GOA":
            case "KARNATAKA":
            case "KERALA":
            case "ANDHRA PRADESH":
            case "TAMIL NADU":
            case "TELANGANA":
            case "PUDUCHERRY":
            case "THE DADRA AND NAGAR HAVELI AND DAMAN AND DIU":
            case "LAKSHADWEEP":
                return 21;
            case "PUNJAB":
            case "HIMACHAL PRADESH":
            case "UTTARAKHAND":
            case "HARYANA":
            case "RAJASTHAN":
            case "SIKKIM":
            case "UTTAR PRADESH":
            case "BIHAR":
            case "ARUNACHAL PRADESH":
            case "NAGALAND":
            case "TRIPURA":
            case "MEGHALAYA":
            case "MIZORAM":
            case "MANIPUR":
            case "ASSAM":
            case "CHANDIGARH":
            case "DELHI":
            case "JAMMU AND KASHMIR":
            case "JHARKHAND":
            case "LADAKH":
            case "ODISHA":
            case "WEST BENGAL":
                return 23;
            default:
                return 19;
        }
    }

    const loadData = () => {
        let body = {
            id: userID
        };
        lmsService.getLMSUserByID(body).then((res) => {
            if (res.status) {
                setLMSUser(res.data['A']);
                setGSTUser(res.data['B']);
                setBName(res.data['B'].buzName);
                getReturnsList(res.data['A'], res.data['A'].GSTR1Num, res.data['A'].GSTR3BNum);
            } else {
                setDigError({ title: 'Error', msg: 'Server error please try again!', isToggle: true });
            }
        });
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    }

    useEffect(() => {
        loadData();
    }, []);

    return (
        <div class="content-wrapper">
            <div class="dimmer-holder" style={{ display: 'none' }}>
                <div id="dimmer"></div>
            </div>
            <div class="container">
                <div class="mypage">
                    <div class="row" data-ng-controller="transctrl">
                        <div class="col-xs-10">
                            <div data-breadcrumb="" data-target="_self" data-name="Dashboard">
                                <ol class="breadcrumb" data-ng-controller="crumbCtrl">
                                    <li>
                                        <a target="_self" data-ng-bind="name">Dashboard</a>
                                    </li>
                                </ol>
                            </div>
                        </div>
                    </div>
                    <div class="content-pane" data-ng-controller="fowelcomectrl" style={{ minHeight: '473.4px' }}>
                        <div class="row">
                            <div class=" col-xs-6">
                                <p>Last logged in on <strong>xx/xx/xxx xx:xx</strong></p>
                            </div>
                            <div class="col-xs-6 pull-right">
                                <p class="pull-right">Currently logged in from IP: <strong>xxx.xxx.xx.xx</strong>
                                </p>
                                <p></p>
                            </div>
                        </div>
                        <div class="row rowtp-mar">
                            <div class="col-xs-12">
                                <div class="dash-content">
                                    <div class="row">
                                        <div class="col-xs-12 text-center">
                                            <h4 style={{ fontFamily: 'arial', color: '#0b1e59' }}>Welcome {GSTUser.tname} to GST Simulator Portal</h4>
                                        </div>
                                    </div>
                                    <div>
                                        <div class="row" ng-if="showTable">
                                            <div class="col-xs-12 text-center">
                                                <span>
                                                    <b>Return filing preference </b>
                                                </span>
                                                <b><span>(<span>Apr {getStartYear()} - Mar {getEndYear()}</span>) :</span></b>&nbsp;
                                                <span>
                                                    <span data-ng-bind="currFilingPref">Yearly</span>&nbsp;(<a style={{ opacity: 0.7, pointerEvents: 'none' }}>Change</a>)
                                                </span>
                                            </div>
                                        </div>
                                        <br />
                                        <div class="row" data-ng-if="recordSearchData &amp;&amp; recordSearchData.length>0">
                                            <div class="col-lg-1 col-md-1"></div>
                                            <div class="col-md-10 tablehdr" id="maintable">
                                                <span class="spnsze">Returns Calendar (Last 5 return periods)</span>
                                                <table class="styled-table table-bordered table table-responsive" style={{ display: 'table' }}>
                                                    <tbody>
                                                        <tr ng-repeat="r in recordSearchData" ng-init="outerIndex=$index">
                                                            <th class="formnames width100">
                                                                GSTR-1 / IFF
                                                            </th>
                                                            {
                                                                returnsA.map((ret) => {
                                                                    return <td class={`width100 ${ret.status === "Yes" ? "fill-success" : "fill-warning"}`} >
                                                                        <div class="column">
                                                                            <div class="mar2">
                                                                                <b>{ret.fdate}</b><br />
                                                                                <span>{ret.status === "Yes" ? "Filed" : "To be Filed"}</span>
                                                                            </div>
                                                                            {
                                                                                ret.status === "No" && <div class="overlay">
                                                                                    <span class="text" data-ng-if="m.dueDate">Due date
                                                                                        :<br /> {Moment(ret.edate).add(1, 'month').add(10, 'days').format('DD/MM/YYYY')}</span>
                                                                                </div>
                                                                            }
                                                                            {
                                                                                ret.status === "Yes" && <div class="overlay">
                                                                                    <span class="text" data-ng-if="m.dueDate">Filed
                                                                                        :<br /> {Moment(ret.edate).add(1, 'month').add(10, 'days').format('DD/MM/YYYY')}</span>
                                                                                </div>
                                                                            }
                                                                        </div>
                                                                    </td>
                                                                })
                                                            }
                                                        </tr>
                                                        <tr ng-repeat="r in recordSearchData" ng-init="outerIndex=$index">
                                                            <th class="formnames width100">
                                                                GSTR-3B
                                                            </th>
                                                            {
                                                                returnsB.map((ret) => {
                                                                    return <td class={`width100 ${ret.status === "Yes" ? "fill-success" : "fill-warning"}`} >
                                                                        <div class="column">
                                                                            <div class="mar2">
                                                                                <b>{ret.fdate}</b><br />
                                                                                <span>{ret.status === "Yes" ? "Filed" : "To be Filed"}</span>
                                                                            </div>
                                                                            {
                                                                                ret.status === "No" && <div class="overlay">
                                                                                    <span class="text" data-ng-if="m.dueDate">Due date
                                                                                        :<br /> {Moment(ret.edate).add(1, 'month').add(getStateBasedNum(), 'days').format('DD/MM/YYYY')}</span>
                                                                                </div>
                                                                            }
                                                                            {
                                                                                ret.status === "Yes" && <div class="overlay">
                                                                                    <span class="text" data-ng-if="m.dueDate">Filed
                                                                                        :<br /> {Moment(ret.edate).add(1, 'month').add(getStateBasedNum(), 'days').format('DD/MM/YYYY')}</span>
                                                                                </div>
                                                                            }
                                                                        </div>
                                                                    </td>
                                                                })
                                                            }
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            <div class="col-lg-1 col-md-1"></div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-sm-offset-2 col-sm-8 col-xs-12" style={{ marginTop: '48px', border: '1px solid' }}>
                                            <p class="reg" style={{ fontStyle: 'italic', fontSize: '13px', marginTop: '5px' }}>You can navigate to your chosen page through navigation panel given below
                                            </p>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-xs-12 dp-btns">
                                            <div>
                                                <button type="button" class="btn btn-primary pad-l-50 pad-r-50" onClick={() => { gotoPath('gstRetrun') }}><span title="Return Dashboard">Return Dashboard</span> <i class="fa fa-angle-right"></i></button>
                                            </div>&nbsp;
                                            <div data-ng-hide="udata.utype == 'RF'">
                                                <button type="button" class="btn btn-primary pad-l-50 pad-r-50" disabled={true}><span title="Create Challan">Create Challan</span> <i class="fa fa-angle-right"></i></button>
                                            </div>&nbsp;
                                            <div>
                                                <button type="button" class="btn btn-primary pad-l-50 pad-r-50" disabled={true}><span title="Disabled">View Notice(s) and Order(s)</span> <i class="fa fa-angle-right"></i></button>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-xs-12 marginlt">
                                            <div>
                                                <button type="button" class="btn btn-primary pad42" disabled={true}><span title="Annual Return">Annual Return</span> <i class="fa fa-angle-right"></i></button>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-xs-12 text-right" style={{ paddingRight: '59px' }}>
                                            <div>
                                                <strong> Else Go to <i class="fa fa-angle-right"></i><i class="fa fa-angle-right"></i></strong>&nbsp;
                                                <button type="button" class="btn btn-primary pad-l-50 pad-r-50" disabled={true}><span title="Continue to Dashboard">Continue to Dashboard</span> <i class="fa fa-angle-right"></i></button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="profile">
                                    <div class="dash-pane">
                                        <div class="row">
                                            <div class="col-xs-12">
                                                <div class="dp-widgt">
                                                    <p class="tp-dash-ttl"><strong>{GSTUser.tname}</strong></p>
                                                    <p class="tp-dash-gstin"><strong>{GSTUser.gstn}</strong></p>
                                                    <a class="tp-pfl-lnk" onClick={() => { gotoPath('profile') }}><span>View Profile</span> <i class="arw-ryt fa fa-angle-right"></i></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="dash-pane" data-ng-hide="udata.utype == 'RF'">
                                        <div class="row ">
                                            <div class="col-xs-12">
                                                <h4 class="text-center" style={{ fontFamily: 'arial', color: '#0b1e59', paddingLeft: '19px' }}>Quick Links</h4>
                                                <hr />
                                                <ul style={{ listStyle: 'none', paddingLeft: '23px' }}>
                                                    <li>
                                                        <p>
                                                            <a style={{ opacity: 0.7, pointerEvents: 'none' }}>Check Cash Balance </a>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            <a style={{ opacity: 0.7, pointerEvents: 'none' }}>Liability ledger</a>
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            <a style={{ opacity: 0.7, pointerEvents: 'none' }}>Credit ledger</a>
                                                        </p>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}