import APIServiceLookUp from './serviceAPI';
import { API_URL } from './models/AppConstent';

export default class ServiceGSTR2B {
    lookupService = new APIServiceLookUp();

    async get2B(body) {
        var serviceType = "POST";
        var url = `${API_URL}gstr/get2B`;
        var token = '';
        var apiService = this.lookupService.getAPIService(serviceType);
        var response = await apiService.doCall(url, body, token);
        return response;
    }
}