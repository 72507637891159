import React from 'react';

import { useRecoilState } from 'recoil';
import { challanAtom, gstrUserAtom } from '../../../core/config/atoms';

import Moment from 'moment';

import styles from '../css/cprint.module.css';

import ServiceUtility from '../../../services/serviceUtility';

import Logo from '../../../assets/images/logoblue.png';

export default function PanelChallanPDF() {

    const [challan] = useRecoilState(challanAtom);
    const [GSTUser] = useRecoilState(gstrUserAtom);

    const utilService = new ServiceUtility();

    const getTCGST = () => {
        if (challan !== {}) {
            return challan.data.tax.cgst + challan.data.interest.cgst + challan.data.fees.cgst;
        } else {
            return 0;
        }
    }

    const getTSGST = () => {
        if (challan !== {}) {
            return challan.data.tax.sgst + challan.data.interest.sgst + challan.data.fees.sgst;
        } else {
            return 0;
        }
    }

    const getTotal = () => {
        if (challan !== {}) {
            let tax = challan.data.tax.igst + challan.data.tax.cgst + challan.data.tax.sgst + challan.data.tax.cess;
            let intr = challan.data.interest.igst + challan.data.interest.cgst + challan.data.interest.sgst + challan.data.interest.cess;
            let fees = challan.data.fees.cgst + challan.data.fees.sgst;
            return tax + intr + fees;
        } else {
            return 0;
        }
    }

    return (
        <div className={styles.printDoc} id="divToPrint">
            <div className='fixed w-[595pt] h-[400pt] flex flex-col items-center justify-center opacity-20 pointer-events-none' style={{ zIndex: 9999 }}>
                <img src={Logo} alt="ITAA" className='w-[800] mx-auto h-[227px] pointer-events-none' style={{ transform: "rotate(-45deg)" }} />
            </div>
            <table style={{ width: "520pt", borderCollapse: "collapse", margin: "auto" }} cellSpacing={0}>
                <tbody>
                    <tr style={{ height: "28pt" }}>
                        <td style={{ width: "540pt", border: "1px solid" }}>
                            <p className={styles.s1} style={{ paddingTop: "1pt", paddingLeft: "206pt", paddingRight: "205pt", textAlign: "center" }}>
                                GOODS AND SERVICES TAX PAYMENT RECEIPT
                            </p>
                        </td>
                    </tr>
                    <tr style={{ height: "20pt" }}>
                        <td style={{ width: "520pt", borderLeft: "1px solid", borderRight: "1px solid", borderBottom: "1px solid" }} cellSpacing={0}>
                            <div className={`${styles.s1} flex items-center justify-between`} style={{ paddingLeft: "6pt", paddingRight: "6pt", textIndent: "0pt", textAlign: "left" }}>
                                <div>CPIN: <b className='text-[#666]'>{challan.cpin}</b></div>
                                <div>Deposit Date : <b className='text-[#666]'>{Moment(challan.month).add(1, 'month').add(19, 'days').format('DD/MM/YYYY')}</b></div>
                                <div>Deposit Time : <b className='text-[#666]'>{Moment(challan.month).add(1, 'month').add(19, 'days').format('hh:mm:ss')}</b></div>
                                <div>e-Scroll : <b className='text-[#666]'><span className={styles.s2}>NA</span></b></div>
                            </div>
                        </td>
                    </tr>
                    <tr style={{ height: "16pt" }}>
                        <td style={{ width: "520pt", borderLeft: "1px solid", borderRight: "1px solid", borderBottom: "1px solid" }} cellSpacing={0}>
                            <div className={`${styles.s1}`} style={{ paddingLeft: "6pt", paddingRight: "6pt", textIndent: "0pt", textAlign: "left" }}>
                                <div><b className='text-[#666]'><b className='text-[#666]'>Payment Particulars</b></b></div>
                            </div>
                        </td>
                    </tr>
                    <tr style={{ height: "20pt" }}>
                        <td style={{ width: "520pt", borderLeft: "1px solid", borderRight: "1px solid", borderBottom: "1px solid" }} cellSpacing={0}>
                            <div className='flex'>
                                <div className={styles.s1} style={{ paddingLeft: "4pt", textIndent: "0pt", textAlign: "left" }}>
                                    CIN: <b className='text-[#666]'>{challan.tid}</b>
                                </div>
                                <div className={styles.s1} style={{ paddingLeft: "20pt", textIndent: "0pt", textAlign: "left" }}>
                                    Name of Bank: <b className='text-[#666]'>DEMO BANK</b>
                                </div>
                                <div className={styles.s1} style={{ paddingLeft: "20pt", textIndent: "0pt", textAlign: "left" }}>
                                    BANK BRN: <b className='text-[#666]'>XXXXXXXXX</b>
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr style={{ height: "16pt" }}>
                        <td style={{ width: "520pt", borderLeft: "1px solid", borderRight: "1px solid", borderBottom: "1px solid" }} cellSpacing={0}>
                            <div className={`${styles.s1}`} style={{ paddingLeft: "6pt", paddingRight: "6pt", textIndent: "0pt", textAlign: "left" }}>
                                <div><b className='text-[#666]'><b className='text-[#666]'>Details of Taxpayer</b></b></div>
                            </div>
                        </td>
                    </tr>
                    <tr style={{ height: "60pt" }}>
                        <td style={{ width: "520pt", borderLeft: "1px solid", borderRight: "1px solid", borderBottom: "1px solid" }} cellSpacing={0}>
                            <div className='grid grid-cols-3 gap-0'>
                                <div className={`${styles.s1} col-span-1`} style={{ paddingLeft: "4pt", textIndent: "0pt", textAlign: "left" }}>
                                    GSTIN: <b className='text-[#666]'>{GSTUser.gstn}</b>
                                </div>
                                <div className={`${styles.s1} col-span-1`} style={{ paddingLeft: "4pt", textIndent: "0pt", textAlign: "left" }}>
                                    E-mail Id: <b className='text-[#666]'>{GSTUser.email[0]}XXXXX@XXXXXXXXXXXXXXXXXXom</b>
                                </div>
                                <div className={`${styles.s1} col-span-1`} style={{ paddingLeft: "4pt", textIndent: "0pt", textAlign: "left" }}>
                                    Mobile No.: <b className='text-[#666]'>{GSTUser.mobile[0]}XXXXX{`${GSTUser.mobile[6]}${GSTUser.mobile[7]}${GSTUser.mobile[8]}${GSTUser.mobile[9]}`}</b>
                                </div>
                                <div className={`${styles.s1} col-span-1`} style={{ paddingLeft: "4pt", textIndent: "0pt", textAlign: "left" }}>
                                    Name: <b className='text-[#666]'>{GSTUser.buzName}</b>
                                </div>
                                <div className={`${styles.s1} col-span-1`} style={{ paddingLeft: "4pt", textIndent: "0pt", textAlign: "left" }}>
                                    Address: <b className='text-[#666]'>XXXXXXXXXX {GSTUser.state}, {GSTUser.users[0].pncd}</b>
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr style={{ height: "16pt" }}>
                        <td style={{ width: "520pt", borderTop: "1px solid", borderLeft: "1px solid", borderRight: "1px solid", borderBottom: "1px solid" }} cellSpacing={0}>
                            <div className={`${styles.s1}`} style={{ paddingLeft: "6pt", paddingRight: "6pt", textIndent: "0pt", textAlign: "center" }}>
                                <div><b className='text-[#666]'>Reason For Challan</b></div>
                            </div>
                        </td>
                    </tr>
                    <tr style={{ height: "20pt" }}>
                        <td style={{ width: "520pt", borderLeft: "1px solid", borderRight: "1px solid", borderBottom: "1px solid" }} cellSpacing={0}>
                            <div className={`${styles.s1}`} style={{ paddingLeft: "6pt", paddingRight: "6pt", textIndent: "0pt", textAlign: "left" }}>
                                <div>Reason: <b className='text-[#666]'>Any other payment</b></div>
                            </div>
                        </td>
                    </tr>
                    <tr style={{ height: "16pt" }}>
                        <td style={{ width: "520pt", borderTop: "1px solid", borderLeft: "1px solid", borderRight: "1px solid", borderBottom: "1px solid" }} cellSpacing={0}>
                            <div className={`${styles.s1}`} style={{ paddingLeft: "6pt", paddingRight: "6pt", textIndent: "0pt", textAlign: "center" }}>
                                <div><b className='text-[#666]'>Details of Deposit (All Amount in Rs.)</b></div>
                            </div>
                        </td>
                    </tr>
                    <tr style={{ height: "30pt" }}>
                        <td style={{ width: "520pt", borderLeft: "1px solid", borderRight: "1px solid", borderBottom: "1px solid" }} cellSpacing={0}>
                            <div className={`${styles.s1} flex`} style={{ margin: "4pt", border: '1px solid' }}>
                                <div style={{ width: "64pt", borderRight: "1px solid", padding: "2pt", textAlign: "center" }}><b className='text-[#666]'>Government</b></div>
                                <div style={{ width: "60pt", borderRight: "1px solid", padding: "2pt", textAlign: "center" }}><b className='text-[#666]'>Major Head</b></div>
                                <div style={{ width: "388pt", padding: "2pt", textAlign: "center" }}><b className='text-[#666]'>Minor Head</b></div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td style={{ width: "520pt", borderLeft: "1px solid", borderRight: "1px solid", borderBottom: "1px solid" }} cellSpacing={0}>
                            <div className={`${styles.s1} flex`} style={{ margin: "4pt", border: '1px solid' }}>
                                <table>
                                    <tr style={{ borderBottom: "1px solid" }}>
                                        <td style={{ width: "64pt", borderRight: "1px solid", textAlign: "center" }}></td>
                                        <td style={{ width: "60pt", borderRight: "1px solid", textAlign: "center" }}></td>
                                        <td>
                                            <div style={{ width: "388pt" }}>
                                                <table>
                                                    <tr>
                                                        <td className={`${styles.s1}`} style={{ width: "76pt", borderRight: "1px solid", textAlign: "center" }}>Tax</td>
                                                        <td className={`${styles.s1}`} style={{ width: "76pt", borderRight: "1px solid", textAlign: "center" }}>Interest</td>
                                                        <td className={`${styles.s1}`} style={{ width: "76pt", borderRight: "1px solid", textAlign: "center" }}>Panalty</td>
                                                        <td className={`${styles.s1}`} style={{ width: "76pt", borderRight: "1px solid", textAlign: "center" }}>Fee</td>
                                                        <td className={`${styles.s1}`} style={{ width: "76pt", borderRight: "1px solid", textAlign: "center" }}>Others</td>
                                                        <td className={`${styles.s1}`} style={{ width: "76pt", textAlign: "center" }}>Total</td>
                                                    </tr>
                                                </table>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ width: "64pt", borderRight: "1px solid", borderBottom: "1px solid", textAlign: "center" }} rowSpan={4}>Government of India</td>
                                        <td style={{ width: "60pt", borderRight: "1px solid", textAlign: "center", borderBottom: "1px solid" }}>CGST(0005)</td>
                                        <td style={{ borderBottom: "1px solid" }}>
                                            <div style={{ width: "388pt" }}>
                                                <div className='flex'>
                                                    <div className={`${styles.s1}`} style={{ width: "76pt", borderRight: "1px solid", textAlign: "center" }}>{challan.data.tax.cgst === 0 ? "-" : challan.data.tax.cgst}</div>
                                                    <div className={`${styles.s1}`} style={{ width: "76pt", borderRight: "1px solid", textAlign: "center" }}>{challan.data.interest.cgst === 0 ? "-" : challan.data.interest.cgst}</div>
                                                    <div className={`${styles.s1}`} style={{ width: "76pt", borderRight: "1px solid", textAlign: "center" }}>-</div>
                                                    <div className={`${styles.s1}`} style={{ width: "76pt", borderRight: "1px solid", textAlign: "center" }}>{challan.data.fees.cgst === 0 ? "-" : challan.data.fees.cgst}</div>
                                                    <div className={`${styles.s1}`} style={{ width: "76pt", borderRight: "1px solid", textAlign: "center" }}>-</div>
                                                    <div className={`${styles.s1}`} style={{ width: "76pt", textAlign: "center" }}>{getTCGST() === 0 ? "-" : getTCGST()}</div>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ width: "60pt", borderRight: "1px solid", textAlign: "center", borderBottom: "1px solid" }}>IGST(0008)</td>
                                        <td style={{ borderBottom: "1px solid" }}>
                                            <div style={{ width: "388pt" }}>
                                                <div className='flex'>
                                                    <div className={`${styles.s1}`} style={{ width: "76pt", borderRight: "1px solid", textAlign: "center" }}>{challan.data.tax.igst === 0 ? "-" : challan.data.tax.igst}</div>
                                                    <div className={`${styles.s1}`} style={{ width: "76pt", borderRight: "1px solid", textAlign: "center" }}>{challan.data.interest.igst === 0 ? "-" : challan.data.interest.igst}</div>
                                                    <div className={`${styles.s1}`} style={{ width: "76pt", borderRight: "1px solid", textAlign: "center" }}>-</div>
                                                    <div className={`${styles.s1}`} style={{ width: "76pt", borderRight: "1px solid", textAlign: "center" }}>-</div>
                                                    <div className={`${styles.s1}`} style={{ width: "76pt", borderRight: "1px solid", textAlign: "center" }}>-</div>
                                                    <div className={`${styles.s1}`} style={{ width: "76pt", textAlign: "center" }}>{(challan.data.tax.igst + challan.data.interest.igst) === 0 ? "-" : (challan.data.tax.igst + challan.data.interest.igst)}</div>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ width: "60pt", borderRight: "1px solid", textAlign: "center", borderBottom: "1px solid" }}>CESS(0009)</td>
                                        <td style={{ borderBottom: "1px solid" }}>
                                            <div style={{ width: "388pt" }}>
                                                <div className='flex'>
                                                    <div className={`${styles.s1}`} style={{ width: "76pt", borderRight: "1px solid", textAlign: "center" }}>{challan.data.tax.cess === 0 ? "-" : challan.data.tax.cess}</div>
                                                    <div className={`${styles.s1}`} style={{ width: "76pt", borderRight: "1px solid", textAlign: "center" }}>{challan.data.interest.cess === 0 ? "-" : challan.data.interest.cess}</div>
                                                    <div className={`${styles.s1}`} style={{ width: "76pt", borderRight: "1px solid", textAlign: "center" }}>-</div>
                                                    <div className={`${styles.s1}`} style={{ width: "76pt", borderRight: "1px solid", textAlign: "center" }}>-</div>
                                                    <div className={`${styles.s1}`} style={{ width: "76pt", borderRight: "1px solid", textAlign: "center" }}>-</div>
                                                    <div className={`${styles.s1}`} style={{ width: "76pt", textAlign: "center" }}>{(challan.data.tax.cess + challan.data.interest.cess) === 0 ? "-" : (challan.data.tax.cess + challan.data.interest.cess)}</div>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ width: "60pt", borderRight: "1px solid", textAlign: "center", borderBottom: "1px solid" }}>Sub-Total</td>
                                        <td style={{ borderBottom: "1px solid" }}>
                                            <div style={{ width: "388pt" }}>
                                                <div className='flex'>
                                                    <div className={`${styles.s1}`} style={{ width: "76pt", borderRight: "1px solid", textAlign: "center" }}>{(challan.data.tax.cgst + challan.data.tax.igst + challan.data.tax.cess)}</div>
                                                    <div className={`${styles.s1}`} style={{ width: "76pt", borderRight: "1px solid", textAlign: "center" }}>{(challan.data.interest.cgst + challan.data.interest.igst + challan.data.interest.cess)}</div>
                                                    <div className={`${styles.s1}`} style={{ width: "76pt", borderRight: "1px solid", textAlign: "center" }}>0</div>
                                                    <div className={`${styles.s1}`} style={{ width: "76pt", borderRight: "1px solid", textAlign: "center" }}>{challan.data.fees.cgst}</div>
                                                    <div className={`${styles.s1}`} style={{ width: "76pt", borderRight: "1px solid", textAlign: "center" }}>0</div>
                                                    <div className={`${styles.s1}`} style={{ width: "76pt", textAlign: "center" }}>{getTCGST() + (challan.data.tax.igst + challan.data.interest.igst) + (challan.data.tax.cess + challan.data.interest.cess)}</div>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ width: "64pt", borderRight: "1px solid", borderBottom: "1px solid", textAlign: "center" }}>{GSTUser.state}</td>
                                        <td style={{ width: "60pt", borderRight: "1px solid", textAlign: "center", borderBottom: "1px solid" }}>
                                            {utilService.getIsState(GSTUser.state) && <span className=''>SGST(0006)</span>}
                                            {!utilService.getIsState(GSTUser.state) && <span className=''>UTGST(0007)</span>}
                                        </td>
                                        <td style={{ borderBottom: "1px solid" }}>
                                            <div style={{ width: "388pt" }}>
                                                <div className='flex'>
                                                    <div className={`${styles.s1}`} style={{ width: "76pt", borderRight: "1px solid", textAlign: "center" }}>{challan.data.tax.sgst === 0 ? "-" : challan.data.tax.sgst}</div>
                                                    <div className={`${styles.s1}`} style={{ width: "76pt", borderRight: "1px solid", textAlign: "center" }}>{challan.data.interest.sgst === 0 ? "-" : challan.data.interest.sgst}</div>
                                                    <div className={`${styles.s1}`} style={{ width: "76pt", borderRight: "1px solid", textAlign: "center" }}>-</div>
                                                    <div className={`${styles.s1}`} style={{ width: "76pt", borderRight: "1px solid", textAlign: "center" }}>{challan.data.fees.sgst === 0 ? "-" : challan.data.fees.sgst}</div>
                                                    <div className={`${styles.s1}`} style={{ width: "76pt", borderRight: "1px solid", textAlign: "center" }}>-</div>
                                                    <div className={`${styles.s1}`} style={{ width: "76pt", textAlign: "center" }}>{getTSGST() === 0 ? "-" : getTSGST()}</div>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ width: "124pt", borderRight: "1px solid", textAlign: "center", borderBottom: "1px solid" }} colSpan={2}>Total Amount</td>
                                        <td style={{ borderBottom: "1px solid", textAlign: "right" }}>
                                            <div style={{ width: "388pt", paddingRight: '6pt' }}>
                                                <b className='text-[#666] text-right'>{getTotal()}</b>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ width: "124pt", borderRight: "1px solid", textAlign: "center", }} colSpan={2}>Total Amount (in words)</td>
                                        <td style={{ textAlign: "right" }}>
                                            <div style={{ width: "388pt", paddingRight: '6pt' }}>
                                                <b className='text-[#666] text-right'>{utilService.wordify(getTotal())}</b>
                                            </div>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </td>
                    </tr>
                    <tr style={{ height: "20pt" }}>
                        <td style={{ width: "520pt", borderLeft: "1px solid", borderRight: "1px solid", borderBottom: "1px solid" }} cellSpacing={0}>
                            <div className={`${styles.s1}`} style={{ paddingLeft: "6pt", paddingRight: "6pt", textIndent: "0pt", textAlign: "left" }}>
                                <div><b className='text-[#666]'>Mode of Payment: Internet Banking - DEMO BANK</b></div>
                            </div>
                        </td>
                    </tr>
                    <tr style={{ height: "60pt" }}>
                        <td style={{ width: "520pt", borderLeft: "1px solid", borderRight: "1px solid", borderBottom: "1px solid" }} cellSpacing={0}>
                            <p className={styles.s1} style={{ paddingLeft: '6pt', margin: '0' }}>Notes:</p>
                            <ol style={{ paddingLeft: '18pt', margin: '0' }}>
                                <li className={styles.s3} style={{ display: 'list-item' }}>Status of the transaction can be tracked under 'Track Payment Status' at GST website</li>
                                <li className={styles.s3} style={{ display: 'list-item' }}>Payment status will be set as 'Paid' for this transaction.</li>
                                <li className={styles.s3} style={{ display: 'list-item' }}>This is a system generated receipt.</li>
                            </ol>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div >
    )
}
