import React, { useState, useEffect } from 'react';

import { useRecoilState } from 'recoil';
import { loadingAtom, pathAtom, gstrpathAtom, returnDateAtom, gstrUserAtom, userIDAtom, digErrorAtom, challanAtom, isBankAtom } from '../../../core/config/atoms';

import Moment from 'moment';
import { Dialog } from '@headlessui/react';

import ServiceGSTF from '../../../services/serviceGSTF';
import ServiceUtility from '../../../services/serviceUtility';

import imgLoader from '../../../assets/images/cloading.gif';

export default function PanelGenrateChallan() {

    const [loading, setLoading] = useRecoilState(loadingAtom);
    const [path, setPath] = useRecoilState(pathAtom);
    const [gDate] = useRecoilState(returnDateAtom);
    const [GSTUser] = useRecoilState(gstrUserAtom);
    const [userID] = useRecoilState(userIDAtom);

    const [, setGSTRPath] = useRecoilState(gstrpathAtom);
    const [, setCurrentChallan] = useRecoilState(challanAtom);
    const [, setIsBank] = useRecoilState(isBankAtom);

    const [challan, setChallan] = useState({});

    const [selType, setSelType] = useState('');
    const [selBank, setSelBank] = useState(false);
    const [selCheck, setSelCheck] = useState(false);
    const [isTerms, setIsTerms] = useState(false);

    const fileService = new ServiceGSTF();
    const utilService = new ServiceUtility();

    const gotoPath = (path) => {
        setPath(path);
    }

    const getTCGST = () => {
        if (challan !== {}) {
            return challan.data.tax.cgst + challan.data.interest.cgst + challan.data.fees.cgst;
        } else {
            return 0;
        }
    }

    const getTSGST = () => {
        if (challan !== {}) {
            return challan.data.tax.sgst + challan.data.interest.sgst + challan.data.fees.sgst;
        } else {
            return 0;
        }
    }

    const getTotal = () => {
        if (challan !== {}) {
            let tax = challan.data.tax.igst + challan.data.tax.cgst + challan.data.tax.sgst + challan.data.tax.cess;
            let intr = challan.data.interest.igst + challan.data.interest.cgst + challan.data.interest.sgst + challan.data.interest.cess;
            let fees = challan.data.fees.cgst + challan.data.fees.sgst;
            return tax + intr + fees;
        } else {
            return 0;
        }
    }

    const getIsTermSelected = () => {
        if (selCheck && selBank) {
            return false;
        }
        return true;
    }

    const loadData = () => {
        (async function () {
            let body = {
                lid: userID,
                month: gDate,
            }
            var res = await fileService.getChallan(body);
            setChallan(res.data);

            setLoading(false);
            window.scrollTo({
                top: 0,
                behavior: 'smooth',
            });
        })();
    }

    const makePayment = () => {
        setCurrentChallan(challan);
        setGSTRPath('login');
        gotoPath('gstrBank');
        setIsBank(true);
    }

    useEffect(() => {
        loadData();
    }, []);

    return (
        <>
            <div class="content-wrapper">
                {
                    loading && <div className='absolute z-50 w-full h-screen flex items-center justify-center bg-gray-200 bg-opacity-25'>
                        <img src={imgLoader} alt="loader" />
                    </div>
                }
                <div class="container fade-ng-cloak">
                    <div class="mypage">
                        <div class="row" data-ng-controller="transctrl" data-ng-init="init('payment')">
                            <div class="col-xs-10">
                                <div data-breadcrumb="" name="Dashboard">
                                    <ol className="breadcrumb" data-ng-controller="crumbCtrl">
                                        <li>
                                            <a onClick={() => { gotoPath('gstr') }}>Dashboard</a>
                                        </li>
                                        <li>
                                            <a onClick={() => {
                                                setGSTRPath('A6.1');
                                                gotoPath('gstr3B');
                                            }}>Payment</a>
                                        </li>
                                        <li>
                                            Generate Challan
                                        </li>
                                    </ol>
                                </div>
                            </div>
                            <div class="col-xs-2">
                                <div class="lang dropdown">
                                    <span class="dropdown-toggle" data-toggle="dropdown" data-ng-bind="selectedLang">English</span>
                                    <ul class="dropdown-menu lang-dpdwn">
                                        <li data-ng-repeat="language in languages">
                                            <a data-ng-click="selectLang(language.cd)" data-ng-bind="language.nm">English</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        {
                            !loading && <div class="tabpane" data-ng-show="showContent">
                                <div className="alert alert-msg alert-success alert-dismissible accept">
                                    <button type="button" className="close" aria-hidden="true">
                                        <i className="fa fa-times"></i>
                                    </button>
                                    Challan successfully generated.
                                </div>
                                <div class="row">
                                    <div class="col-xs-12">
                                        <h4 data-ng-bind="trans.HEAD_GST_PYRCPT">GST Challan</h4>
                                    </div>
                                </div>
                                <div class="tbl-format">
                                    <div class="row">
                                        <div class="inner">
                                            <div class="col-sm-4 col-xs-12 ">
                                                <p data-ng-bind="trans.HEAD_CPIN">CPIN</p>
                                                <span><strong data-ng-bind="challanData.cpin">{challan.cpin}</strong></span>
                                            </div>
                                            <div class="col-sm-4 col-xs-12 ">
                                                <p data-ng-bind="trans.HEAD_PYMNT_DT">Challan Generation Date</p>
                                                <span><strong data-ng-bind="challanData.pymnt_dt">{Moment(gDate).add(1, 'month').add(19, 'days').format('DD/MM/YYYY')}</strong></span>
                                            </div>
                                            <div class="col-sm-4 col-xs-12 ">
                                                <p data-ng-bind="trans.LBL_PYMNT_TM">Challan Expiry Date</p>
                                                <span><strong data-ng-bind="challanData.pymnt_tmstmp">{Moment(gDate).add(1, 'month').add(34, 'days').format('DD/MM/YYYY')}</strong></span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="inner">
                                            <div class="col-sm-4 col-xs-12 ">
                                                <span><strong data-ng-bind="challanData.cpin">Mode of payment : -</strong></span>
                                            </div>
                                            <div class="col-sm-4 col-xs-12 ">
                                                <span><strong data-ng-bind="challanData.cpin">E-Payment</strong></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <h4 data-ng-bind="trans.HEAD_TP_DETL">Details of Taxpayer</h4>
                                <div class="tbl-format">
                                    <div class="row">
                                        <div class="inner">
                                            <div class="col-sm-4 col-xs-12">
                                                <p data-ng-bind="trans.LBL_GSTIN">GSTIN/Other Id</p>
                                                <span><strong data-ng-bind="challanData.gstin">{GSTUser.gstn}</strong></span>
                                            </div>
                                            <div class="col-sm-4 col-xs-12">
                                                <p data-ng-bind="trans.LBL_EMAIL">Email Address</p>
                                                <span>
                                                    <strong style={{ wordWrap: 'break-word' }} data-ng-bind="challanData.email_id">{GSTUser.email[0]}XXXXX@XXXXXXXXXXXXXXXXXXom</strong>
                                                </span>
                                            </div>
                                            <div class="col-sm-4 col-xs-12">
                                                <p data-ng-bind="trans.LBL_MOBNUM">Mobile Number</p>
                                                <span><strong data-ng-bind="challanData.mobile_num">{GSTUser.mobile[0]}XXXXX{`${GSTUser.mobile[6]}${GSTUser.mobile[7]}${GSTUser.mobile[8]}${GSTUser.mobile[9]}`}</strong></span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="inner">
                                            <div class="col-sm-4 col-xs-12">
                                                <p data-ng-bind="trans.LBL_NAME">Name</p>
                                                <span><strong data-ng-bind="challanData.reg_nam">{GSTUser.buzName}</strong></span>
                                            </div>
                                            <div class="col-sm-4 col-xs-12">
                                                <p data-ng-bind="trans.LBL_ADDR">Address</p>
                                                <span><strong data-ng-bind="challanData.address">XXXXXXXXXX {GSTUser.state}, {GSTUser.users[0].pncd}</strong></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <challan-reason-section>
                                    <div data-ng-if="challanData.chln_rsn &amp;&amp; IS_CHALLAN_REASON_ENABLED">
                                        <div class="row">
                                            <div class="col-sm-4 col-xs-12">
                                                <h4 data-ng-bind="trans.HEAD_RSN_FR_CHLN">Reason For Challan</h4>
                                            </div>
                                        </div>
                                        <div class="tbl-format">
                                            <div class="row">
                                                <div class="inner">
                                                    <div class="col-sm-4 col-xs-12">
                                                        <p data-ng-bind="trans.LBL_REASON">Reason</p>
                                                        <span data-ng-if="challanData.chln_rsn === 'AOP'"><strong data-ng-bind="trans.LBL_AOP">Any other payment</strong></span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </challan-reason-section>
                                <h4 data-ng-bind="trans.HEAD_BP_TAX">Details of Deposit</h4>
                                <div class="row">
                                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                        <div class="table-responsive">
                                            <table class="table tbl inv table-bordered exp" style={{ width: '99%', display: 'table' }}>
                                                <thead>
                                                    <tr>
                                                        <th>
                                                            <p></p>
                                                        </th>
                                                        <th>
                                                            <div class="row">
                                                                <div class="col-xs-12 text-center">
                                                                    <span data-ng-bind="trans.HEAD_TAX">Tax</span> <span> (₹)</span>
                                                                </div>
                                                            </div>
                                                        </th>
                                                        <th>
                                                            <div class="row">
                                                                <div class="col-xs-12 text-center">
                                                                    <span data-ng-bind="trans.HEAD_INTR">Interest</span> <span> (₹)</span>
                                                                </div>
                                                            </div>
                                                        </th>
                                                        <th>
                                                            <div class="row">
                                                                <div class="col-xs-12 text-center">
                                                                    <span data-ng-bind="trans.HEAD_PENALTY">Penalty</span> <span> (₹)</span>
                                                                </div>
                                                            </div>
                                                        </th>
                                                        <th>
                                                            <div class="row">
                                                                <div class="col-xs-12 text-center">
                                                                    <span data-ng-bind="trans.HEAD_FEES">Fees</span> <span> (₹)</span>
                                                                </div>
                                                            </div>
                                                        </th>
                                                        <th>
                                                            <div class="row">
                                                                <div class="col-xs-12 text-center">
                                                                    <span data-ng-bind="trans.HEAD_OTHER">Other</span> <span> (₹)</span>
                                                                </div>
                                                            </div>
                                                        </th>
                                                        <th>
                                                            <div class="row">
                                                                <div class="col-xs-12 text-center">
                                                                    <span data-ng-bind="trans.HEAD_TOT">Total</span> <span> (₹)</span>
                                                                </div>
                                                            </div>
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>
                                                            <label title="Central Goods &amp; Services Tax"><strong data-ng-bind="trans.HEAD_CGST">CGST(0005)</strong></label>
                                                        </td>
                                                        <td class="text-right">
                                                            <label data-ng-bind="challanData.cgst_tax_amt | INR:'pay'">{challan.data.tax.cgst}</label>
                                                        </td>
                                                        <td class="text-right">
                                                            <label data-ng-bind="challanData.cgst_int_amt | INR:'pay'">{challan.data.interest.cgst}</label>
                                                        </td>
                                                        <td class="text-right">
                                                            <label data-ng-bind="challanData.cgst_pen_amt | INR:'pay'">0</label>
                                                        </td>
                                                        <td class="text-right">
                                                            <label data-ng-bind="challanData.cgst_fee_amt | INR:'pay'">{challan.data.fees.cgst}</label>
                                                        </td>
                                                        <td class="text-right">
                                                            <label data-ng-bind="challanData.cgst_oth_amt | INR:'pay'">0</label>
                                                        </td>
                                                        <td class="text-right">
                                                            <label data-ng-bind="challanData.cgst_tot_amt | INR:'pay'">{getTCGST()}</label>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <label title="Integrated Goods &amp; Services Tax"><strong data-ng-bind="trans.HEAD_IGST">IGST(0008)</strong></label>
                                                        </td>
                                                        <td class="text-right">
                                                            <label data-ng-bind="challanData.igst_tax_amt | INR:'pay'">{challan.data.tax.igst}</label>
                                                        </td>
                                                        <td class="text-right">
                                                            <label data-ng-bind="challanData.igst_int_amt | INR:'pay'">{challan.data.interest.igst}</label>
                                                        </td>
                                                        <td class="text-right">
                                                            <label data-ng-bind="challanData.igst_pen_amt | INR:'pay'">0</label>
                                                        </td>
                                                        <td class="text-right">
                                                            <label data-ng-bind="challanData.igst_fee_amt | INR:'pay'">0</label>
                                                        </td>
                                                        <td class="text-right">
                                                            <label data-ng-bind="challanData.igst_oth_amt | INR:'pay'">0</label>
                                                        </td>
                                                        <td class="text-right">
                                                            <label data-ng-bind="challanData.igst_tot_amt | INR:'pay'">{(challan.data.tax.igst + challan.data.interest.igst)}</label>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <label><strong data-ng-bind="trans.HEAD_CESS">CESS(0009)</strong></label>
                                                        </td>
                                                        <td class="text-right">
                                                            <label data-ng-bind="challanData.cess_tax_amt | INR:'pay'">{challan.data.tax.cess}</label>
                                                        </td>
                                                        <td class="text-right">
                                                            <label data-ng-bind="challanData.cess_int_amt | INR:'pay'">{challan.data.interest.cess}</label>
                                                        </td>
                                                        <td class="text-right">
                                                            <label data-ng-bind="challanData.cess_pen_amt | INR:'pay'">0</label>
                                                        </td>
                                                        <td class="text-right">
                                                            <label data-ng-bind="challanData.cess_fee_amt | INR:'pay'">0</label>
                                                        </td>
                                                        <td class="text-right">
                                                            <label data-ng-bind="challanData.cess_oth_amt | INR:'pay'">0</label>
                                                        </td>
                                                        <td class="text-right">
                                                            <label data-ng-bind="challanData.cess_tot_amt | INR:'pay'">{(challan.data.tax.cess + challan.data.interest.cess)}</label>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <label title="State Goods &amp; Services Tax">
                                                                <strong>{GSTUser.state}<br />
                                                                    {utilService.getIsState(GSTUser.state) && <span className=''>SGST(0006)</span>}
                                                                    {!utilService.getIsState(GSTUser.state) && <span className=''>UTGST(0007)</span>}
                                                                </strong>
                                                            </label>
                                                        </td>
                                                        <td class="text-right">
                                                            <label data-ng-bind="challanData.sgst_tax_amt | INR:'pay'">{challan.data.tax.sgst}</label>
                                                        </td>
                                                        <td class="text-right">
                                                            <label data-ng-bind="challanData.sgst_int_amt | INR:'pay'">{challan.data.interest.sgst}</label>
                                                        </td>
                                                        <td class="text-right">
                                                            <label data-ng-bind="challanData.sgst_pen_amt | INR:'pay'">0</label>
                                                        </td>
                                                        <td class="text-right">
                                                            <label data-ng-bind="challanData.sgst_fee_amt | INR:'pay'">{challan.data.fees.sgst}</label>
                                                        </td>
                                                        <td class="text-right">
                                                            <label data-ng-bind="challanData.sgst_oth_amt | INR:'pay'">0</label>
                                                        </td>
                                                        <td class="text-right">
                                                            <label data-ng-bind="challanData.sgst_tot_amt | INR:'pay'">{getTSGST()}</label>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td colspan="2">
                                                            <label data-ng-bind="trans.HEAD_TOT_CHLNAMT">Total Challan Amount:</label>
                                                        </td>
                                                        <td colspan="5">
                                                            <span>  ₹</span>
                                                            <label data-ng-bind="challanData.total_amt |INR:'pay'">{getTotal()}</label>
                                                            <label data-ng-if="challanData.total_amt"> /-</label>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td colspan="2">
                                                            <label data-ng-bind="trans.HEAD_TOTAMT_WORDS">Total Challan Amount (In Words):</label>
                                                        </td>
                                                        <td colspan="5">
                                                            <label data-ng-bind="challanData.total_amt_word">{utilService.wordify(getTotal())}</label>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-4 col-xs-12">
                                        <p class="m-cir">Select Mode of E-Payment</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-lg-3 col-sm-3 col-xs-12">
                                        <ul class="dev-tab" name="payMode">
                                            <li id="pay1" value="EPY">
                                                <a>
                                                    <div className='flex items-center justify-between opacity-25'>
                                                        <div>
                                                            <i class="fa fa-credit-card" aria-hidden="true"></i>&nbsp;<span data-ng-bind="trans.HLP_EPYMNT">Preferred Banks</span>
                                                        </div>
                                                        {selType === 'a' && <i className="fa fa-check text-black"></i>}
                                                    </div>
                                                </a>
                                            </li>
                                            <li id="pay1" value="EPY" onClick={() => { setSelType('b'); }}>
                                                <a>
                                                    <div className='flex items-center justify-between'>
                                                        <div>
                                                            <i class="fa fa-credit-card" aria-hidden="true"></i>&nbsp;<span data-ng-bind="trans.HLP_EPYMNT">Net Banking</span>
                                                        </div>
                                                        {selType === 'b' && <i className="fa fa-check text-black"></i>}
                                                    </div>
                                                </a>
                                            </li>
                                        </ul>
                                        <span data-ng-bind="PMTerror" class="err"></span>
                                    </div>
                                    {
                                        selType !== '' && <div class="col-sm-4 col-xs-12">
                                            <p className="m-cir ml-16"><i>Please select a bank</i></p>
                                            <ul className='ml-10'>
                                                <li>
                                                    <input class="chkbx" id="dbank" type="radio" checked={selBank} onChange={(e) => setSelBank(!selBank)} />
                                                    <label class="reg" for="dbank">DEMO BANK</label>
                                                </li>
                                                <li>
                                                    <input class="chkbx" id="abank" type="radio" checked={false} />
                                                    <label class="reg" for="abank">AXIS BANK</label>
                                                </li>
                                                <li>
                                                    <input class="chkbx" id="abank" type="radio" checked={false} />
                                                    <label class="reg" for="abank">BANK OF INDIA</label>
                                                </li>
                                                <li>
                                                    <input class="chkbx" id="abank" type="radio" checked={false} />
                                                    <label class="reg" for="abank">CANARA BANK</label>
                                                </li>
                                                <li>
                                                    <input class="chkbx" id="abank" type="radio" checked={false} />
                                                    <label class="reg" for="abank">CITY UNION BANK LIMITED</label>
                                                </li>
                                                <li>
                                                    <input class="chkbx" id="abank" type="radio" checked={false} />
                                                    <label class="reg" for="abank">HDFC BANK</label>
                                                </li>
                                                <li>
                                                    <input class="chkbx" id="abank" type="radio" checked={false} />
                                                    <label class="reg" for="abank">IDBI BANK</label>
                                                </li>
                                                <li>
                                                    <input class="chkbx" id="abank" type="radio" checked={false} />
                                                    <label class="reg" for="abank">INDIAN OVERSEAS BANK</label>
                                                </li>
                                                <li>
                                                    <input class="chkbx" id="abank" type="radio" checked={false} />
                                                    <label class="reg" for="abank">KARUR VYSYA BANK</label>
                                                </li>
                                                <li>
                                                    <input class="chkbx" id="abank" type="radio" checked={false} />
                                                    <label class="reg" for="abank">PUNJAB AND SIND BANK</label>
                                                </li>
                                                <li>
                                                    <input class="chkbx" id="abank" type="radio" checked={false} />
                                                    <label class="reg" for="abank">STATE BANK OF INDIA</label>
                                                </li>
                                                <li>
                                                    <input class="chkbx" id="abank" type="radio" checked={false} />
                                                    <label class="reg" for="abank">UNION BANK OF INDIA</label>
                                                </li>
                                            </ul>
                                            <input class="chkbx" id="bbank" type="checkbox" checked={selCheck} onChange={(e) => setSelCheck(!selCheck)} />
                                            <label class="reg"><a onClick={(e) => { setIsTerms(true) }}>Terms and Conditions apply</a></label>
                                        </div>
                                    }
                                    {
                                        selType !== '' && <div class="col-sm-4 col-xs-12">
                                            <p className="ml-16"><i></i></p>
                                            <ul className='ml-10'>
                                                <li>
                                                    <input class="chkbx" id="abank" type="radio" checked={false} />
                                                    <label class="reg" for="abank">BANK OF BARODA</label>
                                                </li>
                                                <li>
                                                    <input class="chkbx" id="abank" type="radio" checked={false} />
                                                    <label class="reg" for="abank">BANK OF MAHARASHTRA</label>
                                                </li>
                                                <li>
                                                    <input class="chkbx" id="abank" type="radio" checked={false} />
                                                    <label class="reg" for="abank">CENTRAL BANK OF INDIA</label>
                                                </li>
                                                <li>
                                                    <input class="chkbx" id="abank" type="radio" checked={false} />
                                                    <label class="reg" for="abank">FEDERAL BANK</label>
                                                </li>
                                                <li>
                                                    <input class="chkbx" id="abank" type="radio" checked={false} />
                                                    <label class="reg" for="abank">ICICI BANK LIMITED</label>
                                                </li>
                                                <li>
                                                    <input class="chkbx" id="abank" type="radio" checked={false} />
                                                    <label class="reg" for="abank">INDIAN BANK</label>
                                                </li>
                                                <li>
                                                    <input class="chkbx" id="abank" type="radio" checked={false} />
                                                    <label class="reg" for="abank">JAMMU AND KASHMIR BANK LIMITED</label>
                                                </li>
                                                <li>
                                                    <input class="chkbx" id="abank" type="radio" checked={false} />
                                                    <label class="reg" for="abank">KOTAK MAHINDRA BANK LIMITED</label>
                                                </li>
                                                <li>
                                                    <input class="chkbx" id="abank" type="radio" checked={false} />
                                                    <label class="reg" for="abank">PUNJAB NATIONAL BANK</label>
                                                </li>
                                                <li>
                                                    <input class="chkbx" id="abank" type="radio" checked={false} />
                                                    <label class="reg" for="abank">UCO BANK</label>
                                                </li>
                                            </ul>
                                        </div>
                                    }
                                </div>
                                <div className="row">
                                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-right pad-b-20">
                                        <button type="button" title="Generate Challan" className="btn btn-default" disabled={true}>DOWNLOAD</button>&nbsp;
                                        <button type="button" title="Generate Challan" className="btn btn-primary sendButton" disabled={getIsTermSelected()} onClick={() => { makePayment(); }}>MAKE PAYMENT</button>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="inner">
                                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 pad-b-20">
                                            <p><strong><i className="fa fa-info-circle"></i>&nbsp;<span>If amount is deducted from bank account and not reflected in electronic cash ledger, you may raise grievance under Services&gt;Payments&gt;Grievance against payment(GST PMT-07)</span></strong></p>
                                            <p><strong><i className="fa fa-info-circle"></i>&nbsp;<span>*Awaiting Bank Confirmation: For e-payment mode of payment, if the maker has made a transaction and checker approval is not communicated by bank to GST System.</span></strong></p>
                                            <p><strong><i className="fa fa-info-circle"></i>&nbsp;<span>*Awaiting Bank Clearance: For OTC mode of payment, if bank has acknowledged the challan but remittance confirmation is not communicated by bank to GST System.</span></strong></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
            <Dialog open={isTerms} onClose={() => setIsTerms(false)} className='overflow-y-auto overflow-x-hidden z-50 w-full h-modal md:h-full fixed top-0 left-0 bg-black bg-opacity-40'>
                <div className='w-full h-full fixed z-50 flex items-center justify-center bg-black bg-opacity-60'>
                    <div id="confirmDlg" class="modal fade fade-scale in" role="dialog" style={{ display: 'block', paddingRight: '17px' }}>
                        <div class="modal-dialog sweet">
                            <div class="modal-content">
                                <div className="modal-header">
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => { setIsTerms(false) }}>
                                        <span aria-hidden="true">×</span>
                                    </button>
                                    <div data-ng-if="gstr3bnegval"><h4 className="modal-title text-center">Terms And Conditions</h4></div>
                                </div>
                                <div className="modal-body">
                                    <ol className='pl-16'>
                                        <li className="list-item">If after payment and deduction from the Taxpayer/User account, the information for successful payment if not received from the bank to GST common Portal, the online receipt will not be displayed on the Taxpayer Dashboard. Taxpayer/User is advised to wait for 30 mins and then verify the payment status before continuing to pay again for the same CPIN. In case the payment is made more than once,</li>
                                        <li className="list-item">In order to verify the payment status the Taxpayer/User can Click on Track Payment status link on the GST Common Portal Home Page. Enter the CPIN to know the status of payment.</li>
                                        <li className="list-item">In case of FAILED CPIN status and the challan amount has been debited from the bank account, the Taxpayer/User is requested to wait for 24 hours. GST Common Portak will reconcile the CPIN with bank and update the status accordingly. Even after 24 hours if the payment status is FAILED, grievance should be raised with details on GST Common Portal.</li>
                                        <li className="list-item"><b style={{ color: '#000' }}>The Challan Identification Number receipt will be generated after the acknowledgement of the challan amount by the bank to GST Common Portal.</b></li>
                                        <li className="list-item">Taxpayer/User shall file missing payment From &lt;GST PMT-7&gt; for the payments against CPIN where Cash Ledger has not been credited but payment has been debited. In case of any help, please write to &lt;helpdesk@gst.gove.in&gt; quoting the CPIN</li>
                                        <li className="list-item">Any fraudulent transaction or misuse can invite Legal action.</li>
                                    </ol>
                                </div>
                                <hr />
                                <div class="modal-footer" style={{ paddingRight: '28px' }}>
                                    <button type="button" className="btn btn-primary float-right" data-dismiss="modal" onClick={() => { setIsTerms(false) }}>Close</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Dialog>
        </>
    );
}
