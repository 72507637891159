import React, { useState } from 'react';
import styles from './css/b2print.module.css';

import { useRecoilState } from 'recoil';
import { gstrUserAtom, returnDateAtom } from '../../core/config/atoms';

import Moment from 'moment';

import Logo from '../../assets/images/logoblue.png';

export default function PanelGSTR2BPDF({ A, B, C, D }) {

    const [GSTUser] = useRecoilState(gstrUserAtom);
    const [gDate] = useRecoilState(returnDateAtom);

    const getStartY = () => {
        if (new Date(Moment(gDate).format('MM-DD-yyyy')).getMonth() < 3) {
            return Moment(gDate).subtract(1, 'year').format('YYYY');
        } else {
            return Moment(gDate).format('YYYY');
        }
    }

    const getEndY = () => {
        if (new Date(Moment(gDate).format('MM-DD-yyyy')).getMonth() < 3) {
            return Moment(gDate).format('YY');
        } else {
            return Moment(gDate).add(1, 'year').format('YY');
        }
    }

    const getITCSummery = (type, get) => {
        let value = 0;
        switch (type) {
            case 'partA':
                switch (get) {
                    case "totalIGST":
                        value = 0;
                        A.forEach((item) => {
                            if (item['revc'] == "No" && item['ITC'] == "Yes") {
                                value = value + item['igst'];
                            }
                        });
                        B.forEach((item) => {
                            if (item['revc'] == "No" && item['ITC'] == "Yes") {
                                value = value + item['igst'];
                            }
                        });
                        C.forEach((item) => {
                            if (item['revc'] == "No" && item['ITC'] == "Yes" && item['notetype'] == "Debit Note") {
                                value = value + item['igst'];
                            }
                        });
                        return value;
                    case "totalCGST":
                        value = 0;
                        A.forEach((item) => {
                            if (item['revc'] == "No" && item['ITC'] == "Yes") {
                                value = value + item['cgst'];
                            }
                        });
                        B.forEach((item) => {
                            if (item['revc'] == "No" && item['ITC'] == "Yes") {
                                value = value + item['cgst'];
                            }
                        });
                        C.forEach((item) => {
                            if (item['revc'] == "No" && item['ITC'] == "Yes" && item['notetype'] == "Debit Note") {
                                value = value + item['cgst'];
                            }
                        });
                        return value;
                    case "totalSGST":
                        value = 0;
                        A.forEach((item) => {
                            if (item['revc'] == "No" && item['ITC'] == "Yes") {
                                value = value + item['sgst'];
                            }
                        });
                        B.forEach((item) => {
                            if (item['revc'] == "No" && item['ITC'] == "Yes") {
                                value = value + item['sgst'];
                            }
                        });
                        C.forEach((item) => {
                            if (item['revc'] == "No" && item['ITC'] == "Yes" && item['notetype'] == "Debit Note") {
                                value = value + item['sgst'];
                            }
                        });
                        return value;
                    case "totalCess":
                        value = 0;
                        A.forEach((item) => {
                            if (item['revc'] == "No" && item['ITC'] == "Yes") {
                                value = value + item['cess'];
                            }
                        });
                        B.forEach((item) => {
                            if (item['revc'] == "No" && item['ITC'] == "Yes") {
                                value = value + item['cess'];
                            }
                        });
                        C.forEach((item) => {
                            if (item['revc'] == "No" && item['ITC'] == "Yes" && item['notetype'] == "Debit Note") {
                                value = value + item['cess'];
                            }
                        });
                        return value;
                    case "b2bIGST":
                        value = 0;
                        A.forEach((item) => {
                            if (item['revc'] == "No" && item['ITC'] == "Yes") {
                                value = value + item['igst'];
                            }
                        });
                        return value;
                    case "b2bCGST":
                        value = 0;
                        A.forEach((item) => {
                            if (item['revc'] == "No" && item['ITC'] == "Yes") {
                                value = value + item['cgst'];
                            }
                        });
                        return value;
                    case "b2bSGST":
                        value = 0;
                        A.forEach((item) => {
                            if (item['revc'] == "No" && item['ITC'] == "Yes") {
                                value = value + item['sgst'];
                            }
                        });
                        return value;
                    case "b2bCess":
                        value = 0;
                        A.forEach((item) => {
                            if (item['revc'] == "No" && item['ITC'] == "Yes") {
                                value = value + item['cess'];
                            }
                        });
                        return value;
                    case "b2bndIGST":
                        value = 0;
                        C.forEach((item) => {
                            if (item['revc'] == "No" && item['ITC'] == "Yes" && item['notetype'] == "Debit Note") {
                                value = value + item['igst'];
                            }
                        });
                        return value;
                    case "b2bndCGST":
                        value = 0;
                        C.forEach((item) => {
                            if (item['revc'] == "No" && item['ITC'] == "Yes" && item['notetype'] == "Debit Note") {
                                value = value + item['cgst'];
                            }
                        });
                        return value;
                    case "b2bndSGST":
                        value = 0;
                        C.forEach((item) => {
                            if (item['revc'] == "No" && item['ITC'] == "Yes" && item['notetype'] == "Debit Note") {
                                value = value + item['sgst'];
                            }
                        });
                        return value;
                    case "b2bndCess":
                        value = 0;
                        C.forEach((item) => {
                            if (item['revc'] == "No" && item['ITC'] == "Yes" && item['notetype'] == "Debit Note") {
                                value = value + item['cess'];
                            }
                        });
                        return value;
                    case "b2baIGST":
                        value = 0;
                        B.forEach((item) => {
                            if (item['revc'] == "No" && item['ITC'] == "Yes") {
                                value = value + item['igst'];
                            }
                        });
                        return value;
                    case "b2baCGST":
                        value = 0;
                        B.forEach((item) => {
                            if (item['revc'] == "No" && item['ITC'] == "Yes") {
                                value = value + item['cgst'];
                            }
                        });
                        return value;
                    case "b2baSGST":
                        value = 0;
                        B.forEach((item) => {
                            if (item['revc'] == "No" && item['ITC'] == "Yes") {
                                value = value + item['sgst'];
                            }
                        });
                        return value;
                    case "b2baCess":
                        value = 0;
                        B.forEach((item) => {
                            if (item['revc'] == "No" && item['ITC'] == "Yes") {
                                value = value + item['cess'];
                            }
                        });
                        return value;
                    case "totalIGSTr":
                        value = 0;
                        A.forEach((item) => {
                            if (item['revc'] == "Yes" && item['ITC'] == "Yes") {
                                value = value + item['igst'];
                            }
                        });
                        B.forEach((item) => {
                            if (item['revc'] == "Yes" && item['ITC'] == "Yes") {
                                value = value + item['igst'];
                            }
                        });
                        C.forEach((item) => {
                            if (item['revc'] == "Yes" && item['ITC'] == "Yes" && item['notetype'] == "Debit Note") {
                                value = value + item['igst'];
                            }
                        });
                        return value;
                    case "totalCGSTr":
                        value = 0;
                        A.forEach((item) => {
                            if (item['revc'] == "Yes" && item['ITC'] == "Yes") {
                                value = value + item['cgst'];
                            }
                        });
                        B.forEach((item) => {
                            if (item['revc'] == "Yes" && item['ITC'] == "Yes") {
                                value = value + item['cgst'];
                            }
                        });
                        C.forEach((item) => {
                            if (item['revc'] == "Yes" && item['ITC'] == "Yes" && item['notetype'] == "Debit Note") {
                                value = value + item['cgst'];
                            }
                        });
                        return value;
                    case "totalSGSTr":
                        value = 0;
                        A.forEach((item) => {
                            if (item['revc'] == "Yes" && item['ITC'] == "Yes") {
                                value = value + item['sgst'];
                            }
                        });
                        B.forEach((item) => {
                            if (item['revc'] == "Yes" && item['ITC'] == "Yes") {
                                value = value + item['sgst'];
                            }
                        });
                        C.forEach((item) => {
                            if (item['revc'] == "Yes" && item['ITC'] == "Yes" && item['notetype'] == "Debit Note") {
                                value = value + item['sgst'];
                            }
                        });
                        return value;
                    case "totalCessr":
                        value = 0;
                        A.forEach((item) => {
                            if (item['revc'] == "Yes" && item['ITC'] == "Yes") {
                                value = value + item['cess'];
                            }
                        });
                        B.forEach((item) => {
                            if (item['revc'] == "Yes" && item['ITC'] == "Yes") {
                                value = value + item['cess'];
                            }
                        });
                        C.forEach((item) => {
                            if (item['revc'] == "Yes" && item['ITC'] == "Yes" && item['notetype'] == "Debit Note") {
                                value = value + item['cess'];
                            }
                        });
                        return value;
                    case "b2bIGSTr":
                        value = 0;
                        A.forEach((item) => {
                            if (item['revc'] == "Yes" && item['ITC'] == "Yes") {
                                value = value + item['igst'];
                            }
                        });
                        return value;
                    case "b2bCGSTr":
                        value = 0;
                        A.forEach((item) => {
                            if (item['revc'] == "Yes" && item['ITC'] == "Yes") {
                                value = value + item['cgst'];
                            }
                        });
                        return value;
                    case "b2bSGSTr":
                        value = 0;
                        A.forEach((item) => {
                            if (item['revc'] == "Yes" && item['ITC'] == "Yes") {
                                value = value + item['sgst'];
                            }
                        });
                        return value;
                    case "b2bCessr":
                        value = 0;
                        A.forEach((item) => {
                            if (item['revc'] == "Yes" && item['ITC'] == "Yes") {
                                value = value + item['cess'];
                            }
                        });
                        return value;
                    case "b2bndIGSTr":
                        value = 0;
                        C.forEach((item) => {
                            if (item['revc'] == "Yes" && item['ITC'] == "Yes" && item['notetype'] == "Debit Note") {
                                value = value + item['igst'];
                            }
                        });
                        return value;
                    case "b2bndCGSTr":
                        value = 0;
                        C.forEach((item) => {
                            if (item['revc'] == "Yes" && item['ITC'] == "Yes" && item['notetype'] == "Debit Note") {
                                value = value + item['cgst'];
                            }
                        });
                        return value;
                    case "b2bndSGSTr":
                        value = 0;
                        C.forEach((item) => {
                            if (item['revc'] == "Yes" && item['ITC'] == "Yes" && item['notetype'] == "Debit Note") {
                                value = value + item['sgst'];
                            }
                        });
                        return value;
                    case "b2bndCessr":
                        value = 0;
                        C.forEach((item) => {
                            if (item['revc'] == "Yes" && item['ITC'] == "Yes" && item['notetype'] == "Debit Note") {
                                value = value + item['cess'];
                            }
                        });
                        return value;
                    case "b2baIGSTr":
                        value = 0;
                        B.forEach((item) => {
                            if (item['revc'] == "Yes" && item['ITC'] == "Yes") {
                                value = value + item['igst'];
                            }
                        });
                        return value;
                    case "b2baCGSTr":
                        value = 0;
                        B.forEach((item) => {
                            if (item['revc'] == "Yes" && item['ITC'] == "Yes") {
                                value = value + item['cgst'];
                            }
                        });
                        return value;
                    case "b2baSGSTr":
                        value = 0;
                        B.forEach((item) => {
                            if (item['revc'] == "Yes" && item['ITC'] == "Yes") {
                                value = value + item['sgst'];
                            }
                        });
                        return value;
                    case "b2baCessr":
                        value = 0;
                        B.forEach((item) => {
                            if (item['revc'] == "Yes" && item['ITC'] == "Yes") {
                                value = value + item['cess'];
                            }
                        });
                        return value;
                    case "impgTotal":
                        value = 0;
                        D.forEach((item) => {
                            value = value + item['igst'];
                        });
                        return value;
                    case "impgCess":
                        value = 0;
                        D.forEach((item) => {
                            value = value + item['cess'];
                        });
                        return value;
                    case "b2bncIGST":
                        value = 0;
                        C.forEach((item) => {
                            if (item['revc'] == "No" && item['ITC'] == "Yes" && item['notetype'] == "Credit Note") {
                                value = value + item['igst'];
                            }
                        });
                        return value;
                    case "b2bncCGST":
                        value = 0;
                        C.forEach((item) => {
                            if (item['revc'] == "No" && item['ITC'] == "Yes" && item['notetype'] == "Credit Note") {
                                value = value + item['cgst'];
                            }
                        });
                        return value;
                    case "b2bncSGST":
                        value = 0;
                        C.forEach((item) => {
                            if (item['revc'] == "No" && item['ITC'] == "Yes" && item['notetype'] == "Credit Note") {
                                value = value + item['sgst'];
                            }
                        });
                        return value;
                    case "b2bncCess":
                        value = 0;
                        C.forEach((item) => {
                            if (item['revc'] == "No" && item['ITC'] == "Yes" && item['notetype'] == "Credit Note") {
                                value = value + item['cess'];
                            }
                        });
                        return value;
                    case "b2bncIGSTr":
                        value = 0;
                        C.forEach((item) => {
                            if (item['revc'] == "Yes" && item['ITC'] == "Yes" && item['notetype'] == "Credit Note") {
                                value = value + item['igst'];
                            }
                        });
                        return value;
                    case "b2bncCGSTr":
                        value = 0;
                        C.forEach((item) => {
                            if (item['revc'] == "Yes" && item['ITC'] == "Yes" && item['notetype'] == "Credit Note") {
                                value = value + item['cgst'];
                            }
                        });
                        return value;
                    case "b2bncSGSTr":
                        value = 0;
                        C.forEach((item) => {
                            if (item['revc'] == "Yes" && item['ITC'] == "Yes" && item['notetype'] == "Credit Note") {
                                value = value + item['sgst'];
                            }
                        });
                        return value;
                    case "b2bncCessr":
                        value = 0;
                        C.forEach((item) => {
                            if (item['revc'] == "Yes" && item['ITC'] == "Yes" && item['notetype'] == "Credit Note") {
                                value = value + item['cess'];
                            }
                        });
                        return value;
                    case "b2bncIGSTT":
                        value = 0;
                        C.forEach((item) => {
                            if (item['ITC'] == "Yes" && item['notetype'] == "Credit Note") {
                                value = value + item['igst'];
                            }
                        });
                        return value;
                    case "b2bncCGSTT":
                        value = 0;
                        C.forEach((item) => {
                            if (item['ITC'] == "Yes" && item['notetype'] == "Credit Note") {
                                value = value + item['cgst'];
                            }
                        });
                        return value;
                    case "b2bncSGSTT":
                        value = 0;
                        C.forEach((item) => {
                            if (item['ITC'] == "Yes" && item['notetype'] == "Credit Note") {
                                value = value + item['sgst'];
                            }
                        });
                        return value;
                    case "b2bncCessT":
                        value = 0;
                        C.forEach((item) => {
                            if (item['ITC'] == "Yes" && item['notetype'] == "Credit Note") {
                                value = value + item['cess'];
                            }
                        });
                        return value;
                    default:
                        value = 0;
                        return value;
                }
                break;
            case 'partB':
                switch (get) {
                    case "totalIGST":
                        value = 0;
                        A.forEach((item) => {
                            if (item['revc'] == "No" && item['ITC'] == "No") {
                                value = value + item['igst'];
                            }
                        });
                        B.forEach((item) => {
                            if (item['revc'] == "No" && item['ITC'] == "No") {
                                value = value + item['igst'];
                            }
                        });
                        C.forEach((item) => {
                            if (item['revc'] == "No" && item['ITC'] == "No" && item['notetype'] == "Debit Note") {
                                value = value + item['igst'];
                            }
                        });
                        return value;
                    case "totalCGST":
                        value = 0;
                        A.forEach((item) => {
                            if (item['revc'] == "No" && item['ITC'] == "No") {
                                value = value + item['cgst'];
                            }
                        });
                        B.forEach((item) => {
                            if (item['revc'] == "No" && item['ITC'] == "No") {
                                value = value + item['cgst'];
                            }
                        });
                        C.forEach((item) => {
                            if (item['revc'] == "No" && item['ITC'] == "No" && item['notetype'] == "Debit Note") {
                                value = value + item['cgst'];
                            }
                        });
                        return value;
                    case "totalSGST":
                        value = 0;
                        A.forEach((item) => {
                            if (item['revc'] == "No" && item['ITC'] == "No") {
                                value = value + item['sgst'];
                            }
                        });
                        B.forEach((item) => {
                            if (item['revc'] == "No" && item['ITC'] == "No") {
                                value = value + item['sgst'];
                            }
                        });
                        C.forEach((item) => {
                            if (item['revc'] == "No" && item['ITC'] == "No" && item['notetype'] == "Debit Note") {
                                value = value + item['sgst'];
                            }
                        });
                        return value;
                    case "totalCess":
                        value = 0;
                        A.forEach((item) => {
                            if (item['revc'] == "No" && item['ITC'] == "No") {
                                value = value + item['cess'];
                            }
                        });
                        B.forEach((item) => {
                            if (item['revc'] == "No" && item['ITC'] == "No") {
                                value = value + item['cess'];
                            }
                        });
                        C.forEach((item) => {
                            if (item['revc'] == "No" && item['ITC'] == "No" && item['notetype'] == "Debit Note") {
                                value = value + item['cess'];
                            }
                        });
                        return value;
                    case "b2bIGST":
                        value = 0;
                        A.forEach((item) => {
                            if (item['revc'] == "No" && item['ITC'] == "No") {
                                value = value + item['igst'];
                            }
                        });
                        return value;
                    case "b2bCGST":
                        value = 0;
                        A.forEach((item) => {
                            if (item['revc'] == "No" && item['ITC'] == "No") {
                                value = value + item['cgst'];
                            }
                        });
                        return value;
                    case "b2bSGST":
                        value = 0;
                        A.forEach((item) => {
                            if (item['revc'] == "No" && item['ITC'] == "No") {
                                value = value + item['sgst'];
                            }
                        });
                        return value;
                    case "b2bCess":
                        value = 0;
                        A.forEach((item) => {
                            if (item['revc'] == "No" && item['ITC'] == "No") {
                                value = value + item['cess'];
                            }
                        });
                        return value;
                    case "b2bndIGST":
                        value = 0;
                        C.forEach((item) => {
                            if (item['revc'] == "No" && item['ITC'] == "No" && item['notetype'] == "Debit Note") {
                                value = value + item['igst'];
                            }
                        });
                        return value;
                    case "b2bndCGST":
                        value = 0;
                        C.forEach((item) => {
                            if (item['revc'] == "No" && item['ITC'] == "No" && item['notetype'] == "Debit Note") {
                                value = value + item['cgst'];
                            }
                        });
                        return value;
                    case "b2bndSGST":
                        value = 0;
                        C.forEach((item) => {
                            if (item['revc'] == "No" && item['ITC'] == "No" && item['notetype'] == "Debit Note") {
                                value = value + item['sgst'];
                            }
                        });
                        return value;
                    case "b2bndCess":
                        value = 0;
                        C.forEach((item) => {
                            if (item['revc'] == "No" && item['ITC'] == "No" && item['notetype'] == "Debit Note") {
                                value = value + item['cess'];
                            }
                        });
                        return value;
                    case "b2baIGST":
                        value = 0;
                        B.forEach((item) => {
                            if (item['revc'] == "No" && item['ITC'] == "No") {
                                value = value + item['igst'];
                            }
                        });
                        return value;
                    case "b2baCGST":
                        value = 0;
                        B.forEach((item) => {
                            if (item['revc'] == "No" && item['ITC'] == "No") {
                                value = value + item['cgst'];
                            }
                        });
                        return value;
                    case "b2baSGST":
                        value = 0;
                        B.forEach((item) => {
                            if (item['revc'] == "No" && item['ITC'] == "No") {
                                value = value + item['sgst'];
                            }
                        });
                        return value;
                    case "b2baCess":
                        value = 0;
                        B.forEach((item) => {
                            if (item['revc'] == "No" && item['ITC'] == "No") {
                                value = value + item['cess'];
                            }
                        });
                        return value;
                    case "totalIGSTr":
                        value = 0;
                        A.forEach((item) => {
                            if (item['revc'] == "Yes" && item['ITC'] == "No") {
                                value = value + item['igst'];
                            }
                        });
                        B.forEach((item) => {
                            if (item['revc'] == "Yes" && item['ITC'] == "No") {
                                value = value + item['igst'];
                            }
                        });
                        C.forEach((item) => {
                            if (item['revc'] == "Yes" && item['ITC'] == "No" && item['notetype'] == "Debit Note") {
                                value = value + item['igst'];
                            }
                        });
                        return value;
                    case "totalCGSTr":
                        value = 0;
                        A.forEach((item) => {
                            if (item['revc'] == "Yes" && item['ITC'] == "No") {
                                value = value + item['cgst'];
                            }
                        });
                        B.forEach((item) => {
                            if (item['revc'] == "Yes" && item['ITC'] == "No") {
                                value = value + item['cgst'];
                            }
                        });
                        C.forEach((item) => {
                            if (item['revc'] == "Yes" && item['ITC'] == "No" && item['notetype'] == "Debit Note") {
                                value = value + item['cgst'];
                            }
                        });
                        return value;
                    case "totalSGSTr":
                        value = 0;
                        A.forEach((item) => {
                            if (item['revc'] == "Yes" && item['ITC'] == "No") {
                                value = value + item['sgst'];
                            }
                        });
                        B.forEach((item) => {
                            if (item['revc'] == "Yes" && item['ITC'] == "No") {
                                value = value + item['sgst'];
                            }
                        });
                        C.forEach((item) => {
                            if (item['revc'] == "Yes" && item['ITC'] == "No" && item['notetype'] == "Debit Note") {
                                value = value + item['sgst'];
                            }
                        });
                        return value;
                    case "totalCessr":
                        value = 0;
                        A.forEach((item) => {
                            if (item['revc'] == "Yes" && item['ITC'] == "No") {
                                value = value + item['cess'];
                            }
                        });
                        B.forEach((item) => {
                            if (item['revc'] == "Yes" && item['ITC'] == "No") {
                                value = value + item['cess'];
                            }
                        });
                        C.forEach((item) => {
                            if (item['revc'] == "Yes" && item['ITC'] == "No" && item['notetype'] == "Debit Note") {
                                value = value + item['cess'];
                            }
                        });
                        return value;
                    case "b2bIGSTr":
                        value = 0;
                        A.forEach((item) => {
                            if (item['revc'] == "Yes" && item['ITC'] == "No") {
                                value = value + item['igst'];
                            }
                        });
                        return value;
                    case "b2bCGSTr":
                        value = 0;
                        A.forEach((item) => {
                            if (item['revc'] == "Yes" && item['ITC'] == "No") {
                                value = value + item['cgst'];
                            }
                        });
                        return value;
                    case "b2bSGSTr":
                        value = 0;
                        A.forEach((item) => {
                            if (item['revc'] == "Yes" && item['ITC'] == "No") {
                                value = value + item['sgst'];
                            }
                        });
                        return value;
                    case "b2bCessr":
                        value = 0;
                        A.forEach((item) => {
                            if (item['revc'] == "Yes" && item['ITC'] == "No") {
                                value = value + item['cess'];
                            }
                        });
                        return value;
                    case "b2bndIGSTr":
                        value = 0;
                        C.forEach((item) => {
                            if (item['revc'] == "Yes" && item['ITC'] == "No" && item['notetype'] == "Debit Note") {
                                value = value + item['igst'];
                            }
                        });
                        return value;
                    case "b2bndCGSTr":
                        value = 0;
                        C.forEach((item) => {
                            if (item['revc'] == "Yes" && item['ITC'] == "No" && item['notetype'] == "Debit Note") {
                                value = value + item['cgst'];
                            }
                        });
                        return value;
                    case "b2bndSGSTr":
                        value = 0;
                        C.forEach((item) => {
                            if (item['revc'] == "Yes" && item['ITC'] == "No" && item['notetype'] == "Debit Note") {
                                value = value + item['sgst'];
                            }
                        });
                        return value;
                    case "b2bndCessr":
                        value = 0;
                        C.forEach((item) => {
                            if (item['revc'] == "Yes" && item['ITC'] == "No" && item['notetype'] == "Debit Note") {
                                value = value + item['cess'];
                            }
                        });
                        return value;
                    case "b2baIGSTr":
                        value = 0;
                        B.forEach((item) => {
                            if (item['revc'] == "Yes" && item['ITC'] == "No") {
                                value = value + item['igst'];
                            }
                        });
                        return value;
                    case "b2baCGSTr":
                        value = 0;
                        B.forEach((item) => {
                            if (item['revc'] == "Yes" && item['ITC'] == "No") {
                                value = value + item['cgst'];
                            }
                        });
                        return value;
                    case "b2baSGSTr":
                        value = 0;
                        B.forEach((item) => {
                            if (item['revc'] == "Yes" && item['ITC'] == "No") {
                                value = value + item['sgst'];
                            }
                        });
                        return value;
                    case "b2baCessr":
                        value = 0;
                        B.forEach((item) => {
                            if (item['revc'] == "Yes" && item['ITC'] == "No") {
                                value = value + item['cess'];
                            }
                        });
                        return value;
                    case "impgTotal":
                        value = 0;
                        D.forEach((item) => {
                            value = value + item['igst'];
                        });
                        return value;
                    case "impgCess":
                        value = 0;
                        D.forEach((item) => {
                            value = value + item['cess'];
                        });
                        return value;
                    case "b2bncIGST":
                        value = 0;
                        C.forEach((item) => {
                            if (item['revc'] == "No" && item['ITC'] == "No" && item['notetype'] == "Credit Note") {
                                value = value + item['igst'];
                            }
                        });
                        return value;
                    case "b2bncCGST":
                        value = 0;
                        C.forEach((item) => {
                            if (item['revc'] == "No" && item['ITC'] == "No" && item['notetype'] == "Credit Note") {
                                value = value + item['cgst'];
                            }
                        });
                        return value;
                    case "b2bncSGST":
                        value = 0;
                        C.forEach((item) => {
                            if (item['revc'] == "No" && item['ITC'] == "No" && item['notetype'] == "Credit Note") {
                                value = value + item['sgst'];
                            }
                        });
                        return value;
                    case "b2bncCess":
                        value = 0;
                        C.forEach((item) => {
                            if (item['revc'] == "No" && item['ITC'] == "No" && item['notetype'] == "Credit Note") {
                                value = value + item['cess'];
                            }
                        });
                        return value;
                    case "b2bncIGSTr":
                        value = 0;
                        C.forEach((item) => {
                            if (item['revc'] == "Yes" && item['ITC'] == "No" && item['notetype'] == "Credit Note") {
                                value = value + item['igst'];
                            }
                        });
                        return value;
                    case "b2bncCGSTr":
                        value = 0;
                        C.forEach((item) => {
                            if (item['revc'] == "Yes" && item['ITC'] == "No" && item['notetype'] == "Credit Note") {
                                value = value + item['cgst'];
                            }
                        });
                        return value;
                    case "b2bncSGSTr":
                        value = 0;
                        C.forEach((item) => {
                            if (item['revc'] == "Yes" && item['ITC'] == "No" && item['notetype'] == "Credit Note") {
                                value = value + item['sgst'];
                            }
                        });
                        return value;
                    case "b2bncCessr":
                        value = 0;
                        C.forEach((item) => {
                            if (item['revc'] == "Yes" && item['ITC'] == "No" && item['notetype'] == "Credit Note") {
                                value = value + item['cess'];
                            }
                        });
                        return value;
                    case "b2bncIGSTT":
                        value = 0;
                        C.forEach((item) => {
                            if (item['ITC'] == "No" && item['notetype'] == "Credit Note") {
                                value = value + item['igst'];
                            }
                        });
                        return value;
                    case "b2bncCGSTT":
                        value = 0;
                        C.forEach((item) => {
                            if (item['ITC'] == "No" && item['notetype'] == "Credit Note") {
                                value = value + item['cgst'];
                            }
                        });
                        return value;
                    case "b2bncSGSTT":
                        value = 0;
                        C.forEach((item) => {
                            if (item['ITC'] == "No" && item['notetype'] == "Credit Note") {
                                value = value + item['sgst'];
                            }
                        });
                        return value;
                    case "b2bncCessT":
                        value = 0;
                        C.forEach((item) => {
                            if (item['ITC'] == "No" && item['notetype'] == "Credit Note") {
                                value = value + item['cess'];
                            }
                        });
                        return value;
                    default:
                        value = 0;
                        return value;
                }
                break;
        }
    }

    return (
        <div className={styles.printDoc} id="divToPrint">
            <div className='fixed w-[1080px] h-[764px] flex flex-col items-center justify-center opacity-20 pointer-events-none' style={{ zIndex: 9999 }}>
                <img src={Logo} alt="ITAA" className='w-[800] mx-auto h-[227px] pointer-events-none' style={{ transform: "rotate(-45deg)" }} />
            </div>
            <p className={styles.s1} style={{ paddingTop: '3pt', paddingLeft: '0pt', textIndent: '0pt', textAlign: 'center' }}>FORM GSTR-2B</p>
            <p className={styles.s2} style={{ paddingTop: '10pt', paddingLeft: '0pt', textIndent: '0pt', textAlign: 'center' }}>Auto-drafted ITC Statement</p>
            <p className={styles.s3} style={{ paddingLeft: '0pt', textIndent: '0pt', textAlign: 'center' }}>(From FORM GSTR-1/IFF, GSTR-5, GSTR-6 and Import data received from ICEGATE)</p>
            <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
            <table style={{ borderCollapse: 'collapse', marginLeft: '548pt', display: 'table' }} cellspacing="0">
                <tr style={{ height: '16pt' }}>
                    <td style={{ width: '76pt', border: 'solid 1pt' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingLeft: '4pt', textIndent: '0pt', textAlign: 'left' }}>Financial Year</p>
                    </td>
                    <td style={{ width: '165pt', border: 'solid 1pt' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingLeft: '4pt', textIndent: '0pt', textAlign: 'left' }}>{getStartY()}-{getEndY()}</p>
                    </td>
                </tr>
                <tr style={{ height: '16pt' }}>
                    <td style={{ width: '76pt', border: 'solid 1pt' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingLeft: '4pt', textIndent: '0pt', textAlign: 'left' }}>Month</p>
                    </td>
                    <td style={{ width: '165pt', border: 'solid 1pt' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingLeft: '4pt', textIndent: '0pt', textAlign: 'left' }}>{Moment(gDate).format("MMMM")}</p>
                    </td>
                </tr>
            </table>
            <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
            <table style={{ borderCollapse: 'collapse', marginLeft: '28pt', display: 'table' }} cellspacing="0">
                <tr style={{ height: '16pt' }}>
                    <td style={{ width: '269pt', border: 'solid 1pt' }} bgcolor="#FEC8B2">
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingLeft: '4pt', textIndent: '0pt', textAlign: 'left' }}>1.GSTIN</p>
                    </td>
                    <td style={{ width: '492pt', border: 'solid 1pt' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingLeft: '4pt', textIndent: '0pt', textAlign: 'left' }}>{GSTUser.gstn}</p>
                    </td>
                </tr>
                <tr style={{ height: '16pt' }}>
                    <td style={{ width: '269pt', border: 'solid 1pt' }} bgcolor="#FEC8B2">
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingLeft: '4pt', textIndent: '0pt', textAlign: 'left' }}>2(a).Legal name of the registered person</p>
                    </td>
                    <td style={{ width: '492pt', border: 'solid 1pt' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingLeft: '4pt', textIndent: '0pt', textAlign: 'left' }}>{GSTUser.buzName}</p>
                    </td>
                </tr>
                <tr style={{ height: '16pt' }}>
                    <td style={{ width: '269pt', border: 'solid 1pt' }} bgcolor="#FEC8B2">
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingLeft: '4pt', textIndent: '0pt', textAlign: 'left' }}>2(b).Trade name, if any</p>
                    </td>
                    <td style={{ width: '492pt', border: 'solid 1pt' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingLeft: '4pt', textIndent: '0pt', textAlign: 'left' }}>{GSTUser.tname}</p>
                    </td>
                </tr>
                <tr style={{ height: '16pt' }}>
                    <td style={{ width: '269pt', border: 'solid 1pt' }} bgcolor="#FEC8B2">
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingLeft: '4pt', textIndent: '0pt', textAlign: 'left' }}>2(c).Date of generation</p>
                    </td>
                    <td style={{ width: '492pt', border: 'solid 1pt' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingLeft: '4pt', textIndent: '0pt', textAlign: 'left' }}>{Moment(gDate).add(13, 'days').format("DD/MM/YYYY")}</p>
                    </td>
                </tr>
            </table>
            <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
            <p style={{ textIndent: '0pt', textAlign: 'left' }} />
            <p className={styles.s8} style={{ paddingTop: '13pt', paddingLeft: '33pt', textIndent: '0pt', textAlign: 'left' }}>3. ITC Available Summary</p>
            <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
            <p className={styles.s9} style={{ paddingTop: '9pt', paddingRight: '20pt', textIndent: '0pt', textAlign: 'right' }}>(Amount in ₹ for all tables)</p>
            <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
            <table style={{ borderCollapse: 'collapse', marginLeft: '28pt', display: 'table' }} cellspacing="0">
                <tr style={{ height: '28pt' }}>
                    <td style={{ width: '43pt', border: 'solid 1pt' }} bgcolor="#203764">
                        <p className={styles.s10} style={{ paddingTop: '6pt', paddingLeft: '7pt', paddingRight: '7pt', textIndent: '0pt', textAlign: 'center' }}>S.no</p>
                    </td>
                    <td style={{ width: '182pt', border: 'solid 1pt' }} bgcolor="#203764">
                        <p className={styles.s10} style={{ paddingTop: '6pt', paddingLeft: '68pt', paddingRight: '67pt', textIndent: '0pt', textAlign: 'center' }}>Heading</p>
                    </td>
                    <td style={{ width: '64pt', border: 'solid 1pt' }} bgcolor="#203764">
                        <p className={styles.s10} style={{ paddingTop: '1pt', paddingLeft: '11pt', paddingRight: '10pt', textIndent: '0pt', textAlign: 'center' }}>GSTR-3B </p>
                        <p className={styles.s10} style={{ paddingLeft: '11pt', paddingRight: '10pt', textIndent: '0pt', textAlign: 'center' }}>table</p>
                    </td>
                    <td style={{ width: '64pt', border: 'solid 1pt' }} bgcolor="#203764">
                        <p className={styles.s10} style={{ paddingTop: '1pt', paddingLeft: '16pt', paddingRight: '1pt', textIndent: '-7pt', textAlign: 'left' }}>Integrated Tax (₹)</p>
                    </td>
                    <td style={{ width: '64pt', border: 'solid 1pt' }} bgcolor="#203764">
                        <p className={styles.s10} style={{ paddingTop: '1pt', paddingLeft: '25pt', paddingRight: '1pt', textIndent: '-19pt', textAlign: 'left' }}>Central Tax (₹)</p>
                    </td>
                    <td style={{ width: '64pt', border: 'solid 1pt' }} bgcolor="#203764">
                        <p className={styles.s10} style={{ paddingTop: '1pt', paddingLeft: '16pt', paddingRight: '1pt', textIndent: '-4pt', textAlign: 'left' }}>State/UT Tax (₹)</p>
                    </td>
                    <td style={{ width: '64pt', border: 'solid 1pt' }} bgcolor="#203764">
                        <p className={styles.s10} style={{ paddingTop: '6pt', paddingLeft: '13pt', textIndent: '0pt', textAlign: 'left' }}>Cess (₹)</p>
                    </td>
                    <td style={{ width: '216pt', border: 'solid 1pt' }} bgcolor="#203764">
                        <p className={styles.s10} style={{ paddingTop: '6pt', paddingLeft: '87pt', paddingRight: '87pt', textIndent: '0pt', textAlign: 'center' }}>Advisory</p>
                    </td>
                </tr>
                <tr style={{ height: '16pt' }}>
                    <td style={{ width: '761pt', border: 'solid 1pt' }} colspan="8" bgcolor="#C65911">
                        <p className={styles.s10} style={{ paddingTop: '1pt', paddingLeft: '4pt', textIndent: '0pt', textAlign: 'left' }}>Credit which may be availed under FORM GSTR-3B</p>
                    </td>
                </tr>
                <tr style={{ height: '16pt' }}>
                    <td style={{ width: '761pt', border: 'solid 1pt' }} colspan="8" bgcolor="#F4B084">
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingLeft: '4pt', textIndent: '0pt', textAlign: 'left' }}>Part A ITC Available - Credit may be claimed in relevant headings in GSTR-3B</p>
                    </td>
                </tr>
                <tr style={{ height: '50pt' }}>
                    <td style={{ width: '43pt', border: 'solid 1pt' }}>
                        <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                        <p className={styles.s4} style={{ textIndent: '0pt', textAlign: 'center' }}>I</p>
                    </td>
                    <td style={{ width: '182pt', border: 'solid 1pt' }}>
                        <p className={styles.s4} style={{ paddingTop: '6pt', paddingLeft: '4pt', paddingRight: '3pt', textIndent: '0pt', textAlign: 'left' }}>All other ITC - Supplies from registered persons other than reverse charge</p>
                    </td>
                    <td style={{ width: '64pt', border: 'solid 1pt' }}>
                        <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                        <p className={styles.s4} style={{ paddingLeft: '11pt', paddingRight: '10pt', textIndent: '0pt', textAlign: 'center' }}>4(A)(5)</p>
                    </td>
                    <td style={{ width: '64pt', border: 'solid 1pt' }}>
                        <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                        <p className={styles.s4} style={{ paddingRight: '3pt', textIndent: '0pt', textAlign: 'right' }}>{getITCSummery('partA', 'totalIGST').toFixed(2)}</p>
                    </td>
                    <td style={{ width: '64pt', border: 'solid 1pt' }}>
                        <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                        <p className={styles.s4} style={{ paddingRight: '3pt', textIndent: '0pt', textAlign: 'right' }}>{getITCSummery('partA', 'totalCGST').toFixed(2)}</p>
                    </td>
                    <td style={{ width: '64pt', border: 'solid 1pt' }}>
                        <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                        <p className={styles.s4} style={{ paddingRight: '3pt', textIndent: '0pt', textAlign: 'right' }}>{getITCSummery('partA', 'totalSGST').toFixed(2)}</p>
                    </td>
                    <td style={{ width: '64pt', border: 'solid 1pt' }}>
                        <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                        <p className={styles.s4} style={{ paddingRight: '3pt', textIndent: '0pt', textAlign: 'right' }}>{getITCSummery('partA', 'totalCess').toFixed(2)}</p>
                    </td>
                    <td style={{ width: '216pt', border: 'solid 1pt' }}>
                        <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                        <p className={styles.s4} style={{ paddingLeft: '4pt', paddingRight: '11pt', textIndent: '0pt', textAlign: 'left' }}>Net input tax credit may be availed under Table 4(A)(5) of FORM GSTR-3B.</p>
                    </td>
                </tr>
                <tr style={{ height: '16pt' }}>
                    <td style={{ width: '43pt', border: 'solid 1pt' }} rowspan="4">
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingLeft: '6pt', textIndent: '0pt', textAlign: 'left' }}>Details</p>
                    </td>
                    <td style={{ width: '182pt', border: 'solid 1pt' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingLeft: '4pt', textIndent: '0pt', textAlign: 'left' }}>B2B - Invoices</p>
                    </td>
                    <td style={{ width: '64pt', border: 'solid 1pt' }} rowspan="4" bgcolor="#AFA8A8">
                        <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                    </td>
                    <td style={{ width: '64pt', border: 'solid 1pt' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingLeft: '3pt', paddingRight: '3pt', textIndent: '0pt', textAlign: 'right' }}>{getITCSummery('partA', 'b2bIGST').toFixed(2)}</p>
                    </td>
                    <td style={{ width: '64pt', border: 'solid 1pt' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingLeft: '3pt', paddingRight: '3pt', textIndent: '0pt', textAlign: 'right' }}>{getITCSummery('partA', 'b2bCGST').toFixed(2)}</p>
                    </td>
                    <td style={{ width: '64pt', border: 'solid 1pt' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingLeft: '3pt', paddingRight: '3pt', textIndent: '0pt', textAlign: 'right' }}>{getITCSummery('partA', 'b2bSGST').toFixed(2)}</p>
                    </td>
                    <td style={{ width: '64pt', border: 'solid 1pt' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingLeft: '3pt', paddingRight: '3pt', textIndent: '0pt', textAlign: 'right' }}>{getITCSummery('partA', 'b2bCess').toFixed(2)}</p>
                    </td>
                    <td style={{ width: '216pt', border: 'solid 1pt' }} rowspan="4" bgcolor="#AFA8A8">
                        <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                    </td>
                </tr>
                <tr style={{ height: '16pt' }}>
                    <td style={{ width: '182pt', border: 'solid 1pt' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingLeft: '4pt', textIndent: '0pt', textAlign: 'left' }}>B2B - Debit Notes</p>
                    </td>
                    <td style={{ width: '64pt', border: 'solid 1pt' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingLeft: '3pt', paddingRight: '3pt', textIndent: '0pt', textAlign: 'right' }}>{getITCSummery('partA', 'b2bndIGST').toFixed(2)}</p>
                    </td>
                    <td style={{ width: '64pt', border: 'solid 1pt' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingLeft: '3pt', paddingRight: '3pt', textIndent: '0pt', textAlign: 'right' }}>{getITCSummery('partA', 'b2bndCGST').toFixed(2)}</p>
                    </td>
                    <td style={{ width: '64pt', border: 'solid 1pt' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingLeft: '3pt', paddingRight: '3pt', textIndent: '0pt', textAlign: 'right' }}>{getITCSummery('partA', 'b2bndSGST').toFixed(2)}</p>
                    </td>
                    <td style={{ width: '64pt', border: 'solid 1pt' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingLeft: '3pt', paddingRight: '3pt', textIndent: '0pt', textAlign: 'right' }}>{getITCSummery('partA', 'b2bndCess').toFixed(2)}</p>
                    </td>
                </tr>
                <tr style={{ height: '16pt' }}>
                    <td style={{ width: '182pt', border: 'solid 1pt' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingLeft: '4pt', textIndent: '0pt', textAlign: 'left' }}>B2B - Invoices (Amendment)</p>
                    </td>
                    <td style={{ width: '64pt', border: 'solid 1pt' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingLeft: '3pt', paddingRight: '3pt', textIndent: '0pt', textAlign: 'right' }}>{getITCSummery('partA', 'b2baIGST').toFixed(2)}</p>
                    </td>
                    <td style={{ width: '64pt', border: 'solid 1pt' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingLeft: '3pt', paddingRight: '3pt', textIndent: '0pt', textAlign: 'right' }}>{getITCSummery('partA', 'b2baCGST').toFixed(2)}</p>
                    </td>
                    <td style={{ width: '64pt', border: 'solid 1pt' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingLeft: '3pt', paddingRight: '3pt', textIndent: '0pt', textAlign: 'right' }}>{getITCSummery('partA', 'b2baSGST').toFixed(2)}</p>
                    </td>
                    <td style={{ width: '64pt', border: 'solid 1pt' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingLeft: '3pt', paddingRight: '3pt', textIndent: '0pt', textAlign: 'right' }}>{getITCSummery('partA', 'b2baCess').toFixed(2)}</p>
                    </td>
                </tr>
                <tr style={{ height: '16pt' }}>
                    <td style={{ width: '182pt', border: 'solid 1pt' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingLeft: '4pt', textIndent: '0pt', textAlign: 'left' }}>B2B - Debit Notes (Amendment)</p>
                    </td>
                    <td style={{ width: '64pt', border: 'solid 1pt' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingLeft: '3pt', paddingRight: '3pt', textIndent: '0pt', textAlign: 'right' }}>0.00</p>
                    </td>
                    <td style={{ width: '64pt', border: 'solid 1pt' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingLeft: '3pt', paddingRight: '3pt', textIndent: '0pt', textAlign: 'right' }}>0.00</p>
                    </td>
                    <td style={{ width: '64pt', border: 'solid 1pt' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingLeft: '3pt', paddingRight: '3pt', textIndent: '0pt', textAlign: 'right' }}>0.00</p>
                    </td>
                    <td style={{ width: '64pt', border: 'solid 1pt' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingLeft: '3pt', paddingRight: '3pt', textIndent: '0pt', textAlign: 'right' }}>0.00</p>
                    </td>
                </tr>
                <tr style={{ height: '28pt' }}>
                    <td style={{ width: '43pt', border: 'solid 1pt' }}>
                        <p className={styles.s4} style={{ paddingTop: '8pt', paddingLeft: '7pt', paddingRight: '6pt', textIndent: '0pt', textAlign: 'center' }}>II</p>
                    </td>
                    <td style={{ width: '182pt', border: 'solid 1pt' }}>
                        <p className={styles.s4} style={{ paddingTop: '8pt', paddingLeft: '4pt', textIndent: '0pt', textAlign: 'left' }}>Inward Supplies from ISD</p>
                    </td>
                    <td style={{ width: '64pt', border: 'solid 1pt' }}>
                        <p className={styles.s4} style={{ paddingTop: '8pt', paddingLeft: '11pt', paddingRight: '10pt', textIndent: '0pt', textAlign: 'center' }}>4(A)(4)</p>
                    </td>
                    <td style={{ width: '64pt', border: 'solid 1pt' }}>
                        <p className={styles.s4} style={{ paddingTop: '8pt', paddingRight: '3pt', textIndent: '0pt', textAlign: 'right' }}>0.00</p>
                    </td>
                    <td style={{ width: '64pt', border: 'solid 1pt' }}>
                        <p className={styles.s4} style={{ paddingTop: '8pt', paddingRight: '3pt', textIndent: '0pt', textAlign: 'right' }}>0.00</p>
                    </td>
                    <td style={{ width: '64pt', border: 'solid 1pt' }}>
                        <p className={styles.s4} style={{ paddingTop: '8pt', paddingRight: '3pt', textIndent: '0pt', textAlign: 'right' }}>0.00</p>
                    </td>
                    <td style={{ width: '64pt', border: 'solid 1pt' }}>
                        <p className={styles.s4} style={{ paddingTop: '8pt', paddingRight: '3pt', textIndent: '0pt', textAlign: 'right' }}>0.00</p>
                    </td>
                    <td style={{ width: '216pt', border: 'solid 1pt' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingLeft: '4pt', paddingRight: '11pt', textIndent: '0pt', textAlign: 'left' }}>Net input tax credit may be availed under
                            Table 4(A)(4) of FORM GSTR-3B.</p>
                    </td>
                </tr>
                <tr style={{ height: '16pt' }}>
                    <td style={{ width: '43pt', border: 'solid 1pt' }} rowspan="2">
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingLeft: '6pt', textIndent: '0pt', textAlign: 'left' }}>Details</p>
                    </td>
                    <td style={{ width: '182pt', border: 'solid 1pt' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingLeft: '4pt', textIndent: '0pt', textAlign: 'left' }}>ISD - Invoices</p>
                    </td>
                    <td style={{ width: '64pt', border: 'solid 1pt' }} rowspan="2" bgcolor="#AFA8A8">
                        <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                    </td>
                    <td style={{ width: '64pt', border: 'solid 1pt' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingLeft: '3pt', paddingRight: '3pt', textIndent: '0pt', textAlign: 'right' }}>0.00</p>
                    </td>
                    <td style={{ width: '64pt', border: 'solid 1pt' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingLeft: '3pt', paddingRight: '3pt', textIndent: '0pt', textAlign: 'right' }}>0.00</p>
                    </td>
                    <td style={{ width: '64pt', border: 'solid 1pt' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingLeft: '3pt', paddingRight: '3pt', textIndent: '0pt', textAlign: 'right' }}>0.00</p>
                    </td>
                    <td style={{ width: '64pt', border: 'solid 1pt' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingLeft: '3pt', paddingRight: '3pt', textIndent: '0pt', textAlign: 'right' }}>0.00</p>
                    </td>
                    <td style={{ width: '216pt', border: 'solid 1pt' }} rowspan="2" bgcolor="#AFA8A8">
                        <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                    </td>
                </tr>
                <tr style={{ height: '16pt' }}>
                    <td style={{ width: '182pt', border: 'solid 1pt' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingLeft: '4pt', textIndent: '0pt', textAlign: 'left' }}>ISD - Invoices (Amendment)</p>
                    </td>
                    <td style={{ width: '64pt', border: 'solid 1pt' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingLeft: '3pt', paddingRight: '3pt', textIndent: '0pt', textAlign: 'right' }}>0.00</p>
                    </td>
                    <td style={{ width: '64pt', border: 'solid 1pt' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingLeft: '3pt', paddingRight: '3pt', textIndent: '0pt', textAlign: 'right' }}>0.00</p>
                    </td>
                    <td style={{ width: '64pt', border: 'solid 1pt' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingLeft: '3pt', paddingRight: '3pt', textIndent: '0pt', textAlign: 'right' }}>0.00</p>
                    </td>
                    <td style={{ width: '64pt', border: 'solid 1pt' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingLeft: '3pt', paddingRight: '3pt', textIndent: '0pt', textAlign: 'right' }}>0.00</p>
                    </td>
                </tr>
                <tr style={{ height: '22pt' }}>
                    <td style={{ width: '43pt', border: 'solid 1pt' }}>
                        <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                    </td>
                    <td style={{ width: '182pt', border: 'solid 1pt' }}>
                        <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                    </td>
                    <td style={{ width: '64pt', border: 'solid 1pt' }}>
                        <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                    </td>
                    <td style={{ width: '64pt', border: 'solid 1pt' }}>
                        <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                    </td>
                    <td style={{ width: '64pt', border: 'solid 1pt' }}>
                        <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                    </td>
                    <td style={{ width: '64pt', border: 'solid 1pt' }}>
                        <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                    </td>
                    <td style={{ width: '64pt', border: 'solid 1pt' }}>
                        <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                    </td>
                    <td style={{ width: '216pt', border: 'solid 1pt' }}>
                        <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                    </td>
                </tr>
            </table>

            <p style={{ pageBreakAfter: 'always' }}>&nbsp;</p>
            <div className='fixed w-[1080px] h-[764px] flex flex-col items-center justify-center opacity-20 pointer-events-none' style={{ zIndex: 9999 }}>
                <img src={Logo} alt="ITAA" className='w-[800] mx-auto h-[227px] pointer-events-none' style={{ transform: "rotate(-45deg)" }} />
            </div>
            <table style={{ borderCollapse: "collapse", marginTop: "36pt", marginLeft: "28pt", display: 'table' }} cellSpacing={0} >
                <tbody>
                    <tr style={{ height: "28pt" }}>
                        <td style={{ width: "43pt", borderTopStyle: "solid", borderTopWidth: "1pt", borderLeftStyle: "solid", borderLeftWidth: "1pt", borderBottomStyle: "solid", borderBottomWidth: "1pt", borderRightStyle: "solid", borderRightWidth: "1pt" }} bgcolor="#203764" >
                            <p className={styles.s10} style={{ paddingTop: "6pt", paddingLeft: "7pt", paddingRight: "6pt", textIndent: "0pt", textAlign: "center" }}                            >
                                S.no
                            </p>
                        </td>
                        <td style={{ width: "182pt", borderTopStyle: "solid", borderTopWidth: "1pt", borderLeftStyle: "solid", borderLeftWidth: "1pt", borderBottomStyle: "solid", borderBottomWidth: "1pt", borderRightStyle: "solid", borderRightWidth: "1pt" }} bgcolor="#203764" >
                            <p className={styles.s10} style={{ paddingTop: "6pt", paddingLeft: "68pt", paddingRight: "67pt", textIndent: "0pt", textAlign: "center" }}                            >
                                Heading
                            </p>
                        </td>
                        <td style={{ width: "64pt", borderTopStyle: "solid", borderTopWidth: "1pt", borderLeftStyle: "solid", borderLeftWidth: "1pt", borderBottomStyle: "solid", borderBottomWidth: "1pt", borderRightStyle: "solid", borderRightWidth: "1pt" }} bgcolor="#203764" >
                            <p className={styles.s10} style={{ paddingTop: "1pt", paddingLeft: "11pt", paddingRight: "10pt", textIndent: "0pt", textAlign: "center" }}                            >
                                GSTR-3B
                            </p>
                            <p className={styles.s10} style={{ paddingLeft: "11pt", paddingRight: "10pt", textIndent: "0pt", textAlign: "center" }} >
                                Table
                            </p>
                        </td>
                        <td style={{ width: "64pt", borderTopStyle: "solid", borderTopWidth: "1pt", borderLeftStyle: "solid", borderLeftWidth: "1pt", borderBottomStyle: "solid", borderBottomWidth: "1pt", borderRightStyle: "solid", borderRightWidth: "1pt" }} bgcolor="#203764" >
                            <p className={styles.s10} style={{ paddingTop: "1pt", paddingLeft: "16pt", paddingRight: "1pt", textIndent: "-7pt", textAlign: "left" }} >
                                Integrated Tax (₹)
                            </p>
                        </td>
                        <td
                            style={{
                                width: "64pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            bgcolor="#203764"
                        >
                            <p
                                className={styles.s10}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "25pt",
                                    paddingRight: "1pt",
                                    textIndent: "-19pt",
                                    textAlign: "left"
                                }}
                            >
                                Central Tax (₹)
                            </p>
                        </td>
                        <td
                            style={{
                                width: "64pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            bgcolor="#203764"
                        >
                            <p
                                className={styles.s10}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "16pt",
                                    paddingRight: "1pt",
                                    textIndent: "-4pt",
                                    textAlign: "left"
                                }}
                            >
                                State/UT Tax (₹)
                            </p>
                        </td>
                        <td
                            style={{
                                width: "64pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            bgcolor="#203764"
                        >
                            <p
                                className={styles.s10}
                                style={{
                                    paddingTop: "6pt",
                                    paddingLeft: "13pt",
                                    textIndent: "0pt",
                                    textAlign: "left"
                                }}
                            >
                                Cess (₹)
                            </p>
                        </td>
                        <td
                            style={{
                                width: "216pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            bgcolor="#203764"
                        >
                            <p
                                className={styles.s10}
                                style={{
                                    paddingTop: "6pt",
                                    paddingLeft: "87pt",
                                    paddingRight: "87pt",
                                    textIndent: "0pt",
                                    textAlign: "center"
                                }}
                            >
                                Advisory
                            </p>
                        </td>
                    </tr>
                    <tr style={{ height: "63pt" }}>
                        <td
                            style={{
                                width: "43pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "7pt",
                                    paddingRight: "6pt",
                                    textIndent: "0pt",
                                    textAlign: "center"
                                }}
                            >
                                III
                            </p>
                        </td>
                        <td
                            style={{
                                width: "182pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "4pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "left"
                                }}
                            >
                                Inward Supplies liable for reverse charge
                            </p>
                        </td>
                        <td
                            style={{
                                width: "64pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "18pt",
                                    textIndent: "0pt",
                                    textAlign: "left"
                                }}
                            >
                                3.1(d)<br /> 4(A)(3)
                            </p>
                        </td>
                        <td
                            style={{
                                width: "64pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right"
                                }}
                            >
                                {getITCSummery('partA', 'totalIGSTr').toFixed(2)}
                            </p>
                        </td>
                        <td
                            style={{
                                width: "64pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right"
                                }}
                            >
                                {getITCSummery('partA', 'totalCGSTr').toFixed(2)}
                            </p>
                        </td>
                        <td
                            style={{
                                width: "64pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right"
                                }}
                            >
                                {getITCSummery('partA', 'totalSGSTr').toFixed(2)}
                            </p>
                        </td>
                        <td
                            style={{
                                width: "64pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right"
                                }}
                            >
                                {getITCSummery('partA', 'totalCessr').toFixed(2)}
                            </p>
                        </td>
                        <td
                            style={{
                                width: "216pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "4pt",
                                    paddingRight: "11pt",
                                    textIndent: "0pt",
                                    textAlign: "left"
                                }}
                            >
                                Net input tax credit may be availed under Table 4(A)(5) of FORM GSTR-3B.
                            </p>
                        </td>
                    </tr>
                    <tr style={{ height: "16pt" }}>
                        <td
                            style={{
                                width: "43pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            rowSpan={4}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "6pt",
                                    textIndent: "0pt",
                                    textAlign: "left"
                                }}
                            >
                                Details
                            </p>
                        </td>
                        <td
                            style={{
                                width: "182pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "4pt",
                                    textIndent: "0pt",
                                    textAlign: "left"
                                }}
                            >
                                B2B - Invoices
                            </p>
                        </td>
                        <td
                            style={{
                                width: "64pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            rowSpan={4}
                            bgcolor="#AFA8A8"
                        >
                            <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                <br />
                            </p>
                        </td>
                        <td
                            style={{
                                width: "64pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right"
                                }}
                            >
                                {getITCSummery('partA', 'b2bIGSTr').toFixed(2)}
                            </p>
                        </td>
                        <td
                            style={{
                                width: "64pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right"
                                }}
                            >
                                {getITCSummery('partA', 'b2bCGSTr').toFixed(2)}
                            </p>
                        </td>
                        <td
                            style={{
                                width: "64pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right"
                                }}
                            >
                                {getITCSummery('partA', 'b2bSGSTr').toFixed(2)}
                            </p>
                        </td>
                        <td
                            style={{
                                width: "64pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right"
                                }}
                            >
                                {getITCSummery('partA', 'b2bCessr').toFixed(2)}
                            </p>
                        </td>
                        <td
                            style={{
                                width: "216pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            rowSpan={4}
                            bgcolor="#AFA8A8"
                        >
                            <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                <br />
                            </p>
                        </td>
                    </tr>
                    <tr style={{ height: "16pt" }}>
                        <td
                            style={{
                                width: "182pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "4pt",
                                    textIndent: "0pt",
                                    textAlign: "left"
                                }}
                            >
                                B2B - Debit Notes
                            </p>
                        </td>
                        <td
                            style={{
                                width: "64pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right"
                                }}
                            >
                                {getITCSummery('partA', 'b2bndIGSTr').toFixed(2)}
                            </p>
                        </td>
                        <td
                            style={{
                                width: "64pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right"
                                }}
                            >
                                {getITCSummery('partA', 'b2bndCGSTr').toFixed(2)}
                            </p>
                        </td>
                        <td
                            style={{
                                width: "64pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right"
                                }}
                            >
                                {getITCSummery('partA', 'b2bndSGSTr').toFixed(2)}
                            </p>
                        </td>
                        <td
                            style={{
                                width: "64pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right"
                                }}
                            >
                                {getITCSummery('partA', 'b2bndCessr').toFixed(2)}
                            </p>
                        </td>
                    </tr>
                    <tr style={{ height: "16pt" }}>
                        <td
                            style={{
                                width: "182pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "4pt",
                                    textIndent: "0pt",
                                    textAlign: "left"
                                }}
                            >
                                B2B - Invoices (Amendment)
                            </p>
                        </td>
                        <td
                            style={{
                                width: "64pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right"
                                }}
                            >
                                {getITCSummery('partA', 'b2baIGSTr').toFixed(2)}
                            </p>
                        </td>
                        <td
                            style={{
                                width: "64pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right"
                                }}
                            >
                                {getITCSummery('partA', 'b2baCGSTr').toFixed(2)}
                            </p>
                        </td>
                        <td
                            style={{
                                width: "64pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right"
                                }}
                            >
                                {getITCSummery('partA', 'b2baSGSTr').toFixed(2)}
                            </p>
                        </td>
                        <td
                            style={{
                                width: "64pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right"
                                }}
                            >
                                {getITCSummery('partA', 'b2baCessr').toFixed(2)}
                            </p>
                        </td>
                    </tr>
                    <tr style={{ height: "16pt" }}>
                        <td
                            style={{
                                width: "182pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "4pt",
                                    textIndent: "0pt",
                                    textAlign: "left"
                                }}
                            >
                                B2B - Debit Notes (Amendment)
                            </p>
                        </td>
                        <td
                            style={{
                                width: "64pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right"
                                }}
                            >
                                0.00
                            </p>
                        </td>
                        <td
                            style={{
                                width: "64pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right"
                                }}
                            >
                                0.00
                            </p>
                        </td>
                        <td
                            style={{
                                width: "64pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right"
                                }}
                            >
                                0.00
                            </p>
                        </td>
                        <td
                            style={{
                                width: "64pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right"
                                }}
                            >
                                0.00
                            </p>
                        </td>
                    </tr>
                    <tr style={{ height: "28pt" }}>
                        <td
                            style={{
                                width: "43pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "8pt",
                                    paddingLeft: "7pt",
                                    paddingRight: "6pt",
                                    textIndent: "0pt",
                                    textAlign: "center"
                                }}
                            >
                                IV
                            </p>
                        </td>
                        <td
                            style={{
                                width: "182pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "8pt",
                                    paddingLeft: "4pt",
                                    textIndent: "0pt",
                                    textAlign: "left"
                                }}
                            >
                                Import of Goods
                            </p>
                        </td>
                        <td
                            style={{
                                width: "64pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "8pt",
                                    paddingLeft: "11pt",
                                    paddingRight: "10pt",
                                    textIndent: "0pt",
                                    textAlign: "center"
                                }}
                            >
                                4(A)(1)
                            </p>
                        </td>
                        <td
                            style={{
                                width: "64pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "8pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right"
                                }}
                            >
                                {getITCSummery('partA', 'impgTotal').toFixed(2)}
                            </p>
                        </td>
                        <td
                            style={{
                                width: "64pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "8pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right"
                                }}
                            >
                                0.00
                            </p>
                        </td>
                        <td
                            style={{
                                width: "64pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "8pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right"
                                }}
                            >
                                0.00
                            </p>
                        </td>
                        <td
                            style={{
                                width: "64pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "8pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right"
                                }}
                            >
                                {getITCSummery('partA', 'impgCess').toFixed(2)}
                            </p>
                        </td>
                        <td
                            style={{
                                width: "216pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "4pt",
                                    paddingRight: "11pt",
                                    textIndent: "0pt",
                                    textAlign: "left"
                                }}
                            >
                                Net input tax credit may be availed under Table 4(A)(1) of FORM GSTR-3B.
                            </p>
                        </td>
                    </tr>
                    <tr style={{ height: "16pt" }}>
                        <td
                            style={{
                                width: "43pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            rowSpan={4}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "6pt",
                                    textIndent: "0pt",
                                    textAlign: "left"
                                }}
                            >
                                Details
                            </p>
                        </td>
                        <td
                            style={{
                                width: "182pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "4pt",
                                    textIndent: "0pt",
                                    textAlign: "left"
                                }}
                            >
                                IMPG - Import of goods from overseas
                            </p>
                        </td>
                        <td
                            style={{
                                width: "64pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            rowSpan={4}
                            bgcolor="#AFA8A8"
                        >
                            <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                <br />
                            </p>
                        </td>
                        <td
                            style={{
                                width: "64pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right"
                                }}
                            >
                                {getITCSummery('partA', 'impgTotal').toFixed(2)}
                            </p>
                        </td>
                        <td
                            style={{
                                width: "64pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right"
                                }}
                            >
                                0.00
                            </p>
                        </td>
                        <td
                            style={{
                                width: "64pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right"
                                }}
                            >
                                0.00
                            </p>
                        </td>
                        <td
                            style={{
                                width: "64pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right"
                                }}
                            >
                                {getITCSummery('partA', 'impgCess').toFixed(2)}
                            </p>
                        </td>
                        <td
                            style={{
                                width: "216pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            rowSpan={4}
                            bgcolor="#AFA8A8"
                        >
                            <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                <br />
                            </p>
                        </td>
                    </tr>
                    <tr style={{ height: "16pt" }}>
                        <td
                            style={{
                                width: "182pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "4pt",
                                    textIndent: "0pt",
                                    textAlign: "left"
                                }}
                            >
                                IMPG (Amendment)
                            </p>
                        </td>
                        <td
                            style={{
                                width: "64pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right"
                                }}
                            >
                                0.00
                            </p>
                        </td>
                        <td
                            style={{
                                width: "64pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right"
                                }}
                            >
                                0.00
                            </p>
                        </td>
                        <td
                            style={{
                                width: "64pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right"
                                }}
                            >
                                0.00
                            </p>
                        </td>
                        <td
                            style={{
                                width: "64pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right"
                                }}
                            >
                                0.00
                            </p>
                        </td>
                    </tr>
                    <tr style={{ height: "16pt" }}>
                        <td
                            style={{
                                width: "182pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "4pt",
                                    textIndent: "0pt",
                                    textAlign: "left"
                                }}
                            >
                                IMPGSEZ - Import of goods from SEZ
                            </p>
                        </td>
                        <td
                            style={{
                                width: "64pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right"
                                }}
                            >
                                0.00
                            </p>
                        </td>
                        <td
                            style={{
                                width: "64pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right"
                                }}
                            >
                                0.00
                            </p>
                        </td>
                        <td
                            style={{
                                width: "64pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right"
                                }}
                            >
                                0.00
                            </p>
                        </td>
                        <td
                            style={{
                                width: "64pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right"
                                }}
                            >
                                0.00
                            </p>
                        </td>
                    </tr>
                    <tr style={{ height: "16pt" }}>
                        <td
                            style={{
                                width: "182pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "4pt",
                                    textIndent: "0pt",
                                    textAlign: "left"
                                }}
                            >
                                IMPGSEZ (Amendment)
                            </p>
                        </td>
                        <td
                            style={{
                                width: "64pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right"
                                }}
                            >
                                0.00
                            </p>
                        </td>
                        <td
                            style={{
                                width: "64pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right"
                                }}
                            >
                                0.00
                            </p>
                        </td>
                        <td
                            style={{
                                width: "64pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right"
                                }}
                            >
                                0.00
                            </p>
                        </td>
                        <td
                            style={{
                                width: "64pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right"
                                }}
                            >
                                0.00
                            </p>
                        </td>
                    </tr>
                    <tr style={{ height: "16pt" }}>
                        <td
                            style={{
                                width: "761pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            colSpan={8}
                            bgcolor="#F4B084"
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "4pt",
                                    textIndent: "0pt",
                                    textAlign: "left"
                                }}
                            >
                                Part B ITC Available – Credit Notes should be net-off against relevant available headings in GSTR-3B
                            </p>
                        </td>
                    </tr>
                    <tr style={{ height: "28pt" }}>
                        <td
                            style={{
                                width: "43pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{ paddingTop: "8pt", textIndent: "0pt", textAlign: "center" }}
                            >
                                I
                            </p>
                        </td>
                        <td
                            style={{
                                width: "182pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "8pt",
                                    paddingLeft: "4pt",
                                    textIndent: "0pt",
                                    textAlign: "left"
                                }}
                            >
                                Others
                            </p>
                        </td>
                        <td
                            style={{
                                width: "64pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "8pt",
                                    paddingLeft: "11pt",
                                    paddingRight: "10pt",
                                    textIndent: "0pt",
                                    textAlign: "center"
                                }}
                            >
                                4(A)
                            </p>
                        </td>
                        <td
                            style={{
                                width: "64pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "8pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right"
                                }}
                            >
                                {getITCSummery('partA', 'b2bncIGSTT').toFixed(2)}
                            </p>
                        </td>
                        <td
                            style={{
                                width: "64pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "8pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right"
                                }}
                            >
                                {getITCSummery('partA', 'b2bncCGSTT').toFixed(2)}
                            </p>
                        </td>
                        <td
                            style={{
                                width: "64pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "8pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right"
                                }}
                            >
                                {getITCSummery('partA', 'b2bncSGSTT').toFixed(2)}
                            </p>
                        </td>
                        <td
                            style={{
                                width: "64pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "8pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right"
                                }}
                            >
                                {getITCSummery('partA', 'b2bncCessT').toFixed(2)}
                            </p>
                        </td>
                        <td
                            style={{
                                width: "216pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "4pt",
                                    textIndent: "0pt",
                                    textAlign: "left"
                                }}
                            >
                                Credit Notes should be net-off against relevant ITC available tables [Table 4A(3,4,5)].
                            </p>
                        </td>
                    </tr>
                    <tr style={{ height: "16pt" }}>
                        <td
                            style={{
                                width: "43pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            rowSpan={6}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "6pt",
                                    textIndent: "0pt",
                                    textAlign: "left"
                                }}
                            >
                                Details
                            </p>
                        </td>
                        <td
                            style={{
                                width: "182pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "4pt",
                                    textIndent: "0pt",
                                    textAlign: "left"
                                }}
                            >
                                B2B - Credit Notes
                            </p>
                        </td>
                        <td
                            style={{
                                width: "64pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "11pt",
                                    paddingRight: "10pt",
                                    textIndent: "0pt",
                                    textAlign: "center"
                                }}
                            >
                                4(A)(5)
                            </p>
                        </td>
                        <td
                            style={{
                                width: "64pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right"
                                }}
                            >
                                {getITCSummery('partA', 'b2bncIGST').toFixed(2)}
                            </p>
                        </td>
                        <td
                            style={{
                                width: "64pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right"
                                }}
                            >
                                {getITCSummery('partA', 'b2bncCGST').toFixed(2)}
                            </p>
                        </td>
                        <td
                            style={{
                                width: "64pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right"
                                }}
                            >
                                {getITCSummery('partA', 'b2bncSGST').toFixed(2)}
                            </p>
                        </td>
                        <td
                            style={{
                                width: "64pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right"
                                }}
                            >
                                {getITCSummery('partA', 'b2bncCess').toFixed(2)}
                            </p>
                        </td>
                        <td
                            style={{
                                width: "216pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            rowSpan={6}
                            bgcolor="#AFA8A8"
                        >
                            <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                <br />
                            </p>
                        </td>
                    </tr>
                    <tr style={{ height: "16pt" }}>
                        <td
                            style={{
                                width: "182pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "4pt",
                                    textIndent: "0pt",
                                    textAlign: "left"
                                }}
                            >
                                B2B - Credit Notes (Amendment)
                            </p>
                        </td>
                        <td
                            style={{
                                width: "64pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "11pt",
                                    paddingRight: "10pt",
                                    textIndent: "0pt",
                                    textAlign: "center"
                                }}
                            >
                                4(A)(5)
                            </p>
                        </td>
                        <td
                            style={{
                                width: "64pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right"
                                }}
                            >
                                0.00
                            </p>
                        </td>
                        <td
                            style={{
                                width: "64pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right"
                                }}
                            >
                                0.00
                            </p>
                        </td>
                        <td
                            style={{
                                width: "64pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right"
                                }}
                            >
                                0.00
                            </p>
                        </td>
                        <td
                            style={{
                                width: "64pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right"
                                }}
                            >
                                0.00
                            </p>
                        </td>
                    </tr>
                    <tr style={{ height: "16pt" }}>
                        <td
                            style={{
                                width: "182pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "4pt",
                                    textIndent: "0pt",
                                    textAlign: "left"
                                }}
                            >
                                B2B - Credit notes (Reverse charge)
                            </p>
                        </td>
                        <td
                            style={{
                                width: "64pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "11pt",
                                    paddingRight: "10pt",
                                    textIndent: "0pt",
                                    textAlign: "center"
                                }}
                            >
                                4(A)(3)
                            </p>
                        </td>
                        <td
                            style={{
                                width: "64pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right"
                                }}
                            >
                                {getITCSummery('partA', 'b2bncIGSTr').toFixed(2)}
                            </p>
                        </td>
                        <td
                            style={{
                                width: "64pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right"
                                }}
                            >
                                {getITCSummery('partA', 'b2bncCGSTr').toFixed(2)}
                            </p>
                        </td>
                        <td
                            style={{
                                width: "64pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right"
                                }}
                            >
                                {getITCSummery('partA', 'b2bncSGSTr').toFixed(2)}
                            </p>
                        </td>
                        <td
                            style={{
                                width: "64pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right"
                                }}
                            >
                                {getITCSummery('partA', 'b2bncCessr').toFixed(2)}
                            </p>
                        </td>
                    </tr>
                    <tr style={{ height: "28pt" }}>
                        <td
                            style={{
                                width: "182pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "4pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "left"
                                }}
                            >
                                B2B - Credit notes (Reverse charge)
                            </p>
                        </td>
                        <td
                            style={{
                                width: "64pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "11pt",
                                    paddingRight: "10pt",
                                    textIndent: "0pt",
                                    textAlign: "center"
                                }}
                            >
                                4(A)(3)
                            </p>
                        </td>
                        <td
                            style={{
                                width: "64pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right"
                                }}
                            >
                                0.00
                            </p>
                        </td>
                        <td
                            style={{
                                width: "64pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right"
                                }}
                            >
                                0.00
                            </p>
                        </td>
                        <td
                            style={{
                                width: "64pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right"
                                }}
                            >
                                0.00
                            </p>
                        </td>
                        <td
                            style={{
                                width: "64pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right"
                                }}
                            >
                                0.00
                            </p>
                        </td>
                    </tr>
                    <tr style={{ height: "16pt" }}>
                        <td
                            style={{
                                width: "182pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "4pt",
                                    textIndent: "0pt",
                                    textAlign: "left"
                                }}
                            >
                                ISD - Credit notes
                            </p>
                        </td>
                        <td
                            style={{
                                width: "64pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "11pt",
                                    paddingRight: "10pt",
                                    textIndent: "0pt",
                                    textAlign: "center"
                                }}
                            >
                                4(A)(4)
                            </p>
                        </td>
                        <td
                            style={{
                                width: "64pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right"
                                }}
                            >
                                0.00
                            </p>
                        </td>
                        <td
                            style={{
                                width: "64pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right"
                                }}
                            >
                                0.00
                            </p>
                        </td>
                        <td
                            style={{
                                width: "64pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right"
                                }}
                            >
                                0.00
                            </p>
                        </td>
                        <td
                            style={{
                                width: "64pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right"
                                }}
                            >
                                0.00
                            </p>
                        </td>
                    </tr>
                    <tr style={{ height: "16pt" }}>
                        <td
                            style={{
                                width: "182pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "4pt",
                                    textIndent: "0pt",
                                    textAlign: "left"
                                }}
                            >
                                ISD - Credit Notes (Amendment)
                            </p>
                        </td>
                        <td
                            style={{
                                width: "64pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "11pt",
                                    paddingRight: "10pt",
                                    textIndent: "0pt",
                                    textAlign: "center"
                                }}
                            >
                                4(A)(4)
                            </p>
                        </td>
                        <td
                            style={{
                                width: "64pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right"
                                }}
                            >
                                0.00
                            </p>
                        </td>
                        <td
                            style={{
                                width: "64pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right"
                                }}
                            >
                                0.00
                            </p>
                        </td>
                        <td
                            style={{
                                width: "64pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right"
                                }}
                            >
                                0.00
                            </p>
                        </td>
                        <td
                            style={{
                                width: "64pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right"
                                }}
                            >
                                0.00
                            </p>
                        </td>
                    </tr>
                </tbody>
            </table>

            <p style={{ pageBreakAfter: 'always' }}>&nbsp;</p>
            <div className='fixed w-[1080px] h-[764px] flex flex-col items-center justify-center opacity-20 pointer-events-none' style={{ zIndex: 9999 }}>
                <img src={Logo} alt="ITAA" className='w-[800] mx-auto h-[227px] pointer-events-none' style={{ transform: "rotate(-45deg)" }} />
            </div>
            <p style={{ textIndent: "0pt", textAlign: "left" }}></p>
            <ol className={styles.l1}>
                <li data-list-text={4}>
                    <p className={styles.s8} style={{ paddingTop: "40pt", paddingLeft: "33pt", textIndent: "-15pt", textAlign: "left" }} >
                        ITC Not Available Summary
                    </p>
                    <p style={{ textIndent: "0pt", textAlign: "left" }}>
                        <br />
                    </p>
                    <p className={styles.s9} style={{ paddingLeft: "5pt", paddingRight: '20pt', textIndent: "0pt", textAlign: "right" }} >
                        (Amount in ₹ for all tables)
                    </p>
                    <p style={{ textIndent: "0pt", textAlign: "left" }}>
                        <br />
                    </p>
                    <table style={{ borderCollapse: "collapse", marginLeft: "28pt", display: 'table' }} cellSpacing={0} >
                        <tbody>
                            <tr style={{ height: "28pt" }}>
                                <td
                                    style={{
                                        width: "43pt",
                                        borderTopStyle: "solid",
                                        borderTopWidth: "1pt",
                                        borderLeftStyle: "solid",
                                        borderLeftWidth: "1pt",
                                        borderBottomStyle: "solid",
                                        borderBottomWidth: "1pt",
                                        borderRightStyle: "solid",
                                        borderRightWidth: "1pt"
                                    }}
                                    bgcolor="#203764"
                                >
                                    <p
                                        className={styles.s10}
                                        style={{
                                            paddingTop: "6pt",
                                            paddingLeft: "7pt",
                                            paddingRight: "6pt",
                                            textIndent: "0pt",
                                            textAlign: "center"
                                        }}
                                    >
                                        S.no
                                    </p>
                                </td>
                                <td
                                    style={{
                                        width: "182pt",
                                        borderTopStyle: "solid",
                                        borderTopWidth: "1pt",
                                        borderLeftStyle: "solid",
                                        borderLeftWidth: "1pt",
                                        borderBottomStyle: "solid",
                                        borderBottomWidth: "1pt",
                                        borderRightStyle: "solid",
                                        borderRightWidth: "1pt"
                                    }}
                                    bgcolor="#203764"
                                >
                                    <p
                                        className={styles.s10}
                                        style={{
                                            paddingTop: "6pt",
                                            paddingLeft: "68pt",
                                            paddingRight: "67pt",
                                            textIndent: "0pt",
                                            textAlign: "center"
                                        }}
                                    >
                                        Heading
                                    </p>
                                </td>
                                <td
                                    style={{
                                        width: "64pt",
                                        borderTopStyle: "solid",
                                        borderTopWidth: "1pt",
                                        borderLeftStyle: "solid",
                                        borderLeftWidth: "1pt",
                                        borderBottomStyle: "solid",
                                        borderBottomWidth: "1pt",
                                        borderRightStyle: "solid",
                                        borderRightWidth: "1pt"
                                    }}
                                    bgcolor="#203764"
                                >
                                    <p
                                        className={styles.s10}
                                        style={{
                                            paddingTop: "1pt",
                                            paddingLeft: "11pt",
                                            paddingRight: "10pt",
                                            textIndent: "0pt",
                                            textAlign: "center"
                                        }}
                                    >
                                        GSTR-3B
                                    </p>
                                    <p
                                        className={styles.s10}
                                        style={{
                                            paddingLeft: "11pt",
                                            paddingRight: "10pt",
                                            textIndent: "0pt",
                                            textAlign: "center"
                                        }}
                                    >
                                        table
                                    </p>
                                </td>
                                <td
                                    style={{
                                        width: "64pt",
                                        borderTopStyle: "solid",
                                        borderTopWidth: "1pt",
                                        borderLeftStyle: "solid",
                                        borderLeftWidth: "1pt",
                                        borderBottomStyle: "solid",
                                        borderBottomWidth: "1pt",
                                        borderRightStyle: "solid",
                                        borderRightWidth: "1pt"
                                    }}
                                    bgcolor="#203764"
                                >
                                    <p
                                        className={styles.s10}
                                        style={{
                                            paddingTop: "1pt",
                                            paddingLeft: "16pt",
                                            paddingRight: "1pt",
                                            textIndent: "-7pt",
                                            textAlign: "left"
                                        }}
                                    >
                                        Integrated Tax (₹)
                                    </p>
                                </td>
                                <td
                                    style={{
                                        width: "64pt",
                                        borderTopStyle: "solid",
                                        borderTopWidth: "1pt",
                                        borderLeftStyle: "solid",
                                        borderLeftWidth: "1pt",
                                        borderBottomStyle: "solid",
                                        borderBottomWidth: "1pt",
                                        borderRightStyle: "solid",
                                        borderRightWidth: "1pt"
                                    }}
                                    bgcolor="#203764"
                                >
                                    <p
                                        className={styles.s10}
                                        style={{
                                            paddingTop: "1pt",
                                            paddingLeft: "25pt",
                                            paddingRight: "1pt",
                                            textIndent: "-19pt",
                                            textAlign: "left"
                                        }}
                                    >
                                        Central Tax (₹)
                                    </p>
                                </td>
                                <td
                                    style={{
                                        width: "64pt",
                                        borderTopStyle: "solid",
                                        borderTopWidth: "1pt",
                                        borderLeftStyle: "solid",
                                        borderLeftWidth: "1pt",
                                        borderBottomStyle: "solid",
                                        borderBottomWidth: "1pt",
                                        borderRightStyle: "solid",
                                        borderRightWidth: "1pt"
                                    }}
                                    bgcolor="#203764"
                                >
                                    <p
                                        className={styles.s10}
                                        style={{
                                            paddingTop: "1pt",
                                            paddingLeft: "16pt",
                                            paddingRight: "1pt",
                                            textIndent: "-4pt",
                                            textAlign: "left"
                                        }}
                                    >
                                        State/UT Tax (₹)
                                    </p>
                                </td>
                                <td
                                    style={{
                                        width: "64pt",
                                        borderTopStyle: "solid",
                                        borderTopWidth: "1pt",
                                        borderLeftStyle: "solid",
                                        borderLeftWidth: "1pt",
                                        borderBottomStyle: "solid",
                                        borderBottomWidth: "1pt",
                                        borderRightStyle: "solid",
                                        borderRightWidth: "1pt"
                                    }}
                                    bgcolor="#203764"
                                >
                                    <p
                                        className={styles.s10}
                                        style={{
                                            paddingTop: "6pt",
                                            paddingLeft: "13pt",
                                            textIndent: "0pt",
                                            textAlign: "left"
                                        }}
                                    >
                                        Cess (₹)
                                    </p>
                                </td>
                                <td
                                    style={{
                                        width: "216pt",
                                        borderTopStyle: "solid",
                                        borderTopWidth: "1pt",
                                        borderLeftStyle: "solid",
                                        borderLeftWidth: "1pt",
                                        borderBottomStyle: "solid",
                                        borderBottomWidth: "1pt",
                                        borderRightStyle: "solid",
                                        borderRightWidth: "1pt"
                                    }}
                                    bgcolor="#203764"
                                >
                                    <p
                                        className={styles.s10}
                                        style={{
                                            paddingTop: "1pt",
                                            paddingLeft: "87pt",
                                            paddingRight: "87pt",
                                            textIndent: "0pt",
                                            textAlign: "center"
                                        }}
                                    >
                                        Advisory
                                    </p>
                                </td>
                            </tr>
                            <tr style={{ height: "16pt" }}>
                                <td
                                    style={{
                                        width: "761pt",
                                        borderTopStyle: "solid",
                                        borderTopWidth: "1pt",
                                        borderLeftStyle: "solid",
                                        borderLeftWidth: "1pt",
                                        borderBottomStyle: "solid",
                                        borderBottomWidth: "1pt",
                                        borderRightStyle: "solid",
                                        borderRightWidth: "1pt"
                                    }}
                                    colSpan={8}
                                    bgcolor="#C65911"
                                >
                                    <p
                                        className={styles.s10}
                                        style={{
                                            paddingTop: "1pt",
                                            paddingLeft: "4pt",
                                            textIndent: "0pt",
                                            textAlign: "left"
                                        }}
                                    >
                                        Credit which may not be availed under FORM GSTR-3B
                                    </p>
                                </td>
                            </tr>
                            <tr style={{ height: "16pt" }}>
                                <td
                                    style={{
                                        width: "761pt",
                                        borderTopStyle: "solid",
                                        borderTopWidth: "1pt",
                                        borderLeftStyle: "solid",
                                        borderLeftWidth: "1pt",
                                        borderBottomStyle: "solid",
                                        borderBottomWidth: "1pt",
                                        borderRightStyle: "solid",
                                        borderRightWidth: "1pt"
                                    }}
                                    colSpan={8}
                                    bgcolor="#F4B084"
                                >
                                    <p
                                        className={styles.s4}
                                        style={{
                                            paddingTop: "1pt",
                                            paddingLeft: "4pt",
                                            textIndent: "0pt",
                                            textAlign: "left"
                                        }}
                                    >
                                        Part A ITC Not Available
                                    </p>
                                </td>
                            </tr>
                            <tr style={{ height: "50pt" }}>
                                <td
                                    style={{
                                        width: "43pt",
                                        borderTopStyle: "solid",
                                        borderTopWidth: "1pt",
                                        borderLeftStyle: "solid",
                                        borderLeftWidth: "1pt",
                                        borderBottomStyle: "solid",
                                        borderBottomWidth: "1pt",
                                        borderRightStyle: "solid",
                                        borderRightWidth: "1pt"
                                    }}
                                >
                                    <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                        <br />
                                    </p>
                                    <p
                                        className={styles.s4}
                                        style={{ textIndent: "0pt", textAlign: "center" }}
                                    >
                                        I
                                    </p>
                                </td>
                                <td
                                    style={{
                                        width: "182pt",
                                        borderTopStyle: "solid",
                                        borderTopWidth: "1pt",
                                        borderLeftStyle: "solid",
                                        borderLeftWidth: "1pt",
                                        borderBottomStyle: "solid",
                                        borderBottomWidth: "1pt",
                                        borderRightStyle: "solid",
                                        borderRightWidth: "1pt"
                                    }}
                                >
                                    <p
                                        className={styles.s4}
                                        style={{
                                            paddingTop: "6pt",
                                            paddingLeft: "4pt",
                                            paddingRight: "3pt",
                                            textIndent: "0pt",
                                            textAlign: "left"
                                        }}
                                    >
                                        All other ITC - Supplies from registered persons other than reverse charge
                                    </p>
                                </td>
                                <td
                                    style={{
                                        width: "64pt",
                                        borderTopStyle: "solid",
                                        borderTopWidth: "1pt",
                                        borderLeftStyle: "solid",
                                        borderLeftWidth: "1pt",
                                        borderBottomStyle: "solid",
                                        borderBottomWidth: "1pt",
                                        borderRightStyle: "solid",
                                        borderRightWidth: "1pt"
                                    }}
                                >
                                    <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                        <br />
                                    </p>
                                    <p
                                        className={styles.s4}
                                        style={{
                                            paddingLeft: "11pt",
                                            paddingRight: "10pt",
                                            textIndent: "0pt",
                                            textAlign: "center"
                                        }}
                                    >
                                        4(D)(2)
                                    </p>
                                </td>
                                <td
                                    style={{
                                        width: "64pt",
                                        borderTopStyle: "solid",
                                        borderTopWidth: "1pt",
                                        borderLeftStyle: "solid",
                                        borderLeftWidth: "1pt",
                                        borderBottomStyle: "solid",
                                        borderBottomWidth: "1pt",
                                        borderRightStyle: "solid",
                                        borderRightWidth: "1pt"
                                    }}
                                >
                                    <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                        <br />
                                    </p>
                                    <p
                                        className={styles.s4}
                                        style={{
                                            paddingRight: "3pt",
                                            textIndent: "0pt",
                                            textAlign: "right"
                                        }}
                                    >
                                        {getITCSummery('partB', 'totalIGST').toFixed(2)}
                                    </p>
                                </td>
                                <td
                                    style={{
                                        width: "64pt",
                                        borderTopStyle: "solid",
                                        borderTopWidth: "1pt",
                                        borderLeftStyle: "solid",
                                        borderLeftWidth: "1pt",
                                        borderBottomStyle: "solid",
                                        borderBottomWidth: "1pt",
                                        borderRightStyle: "solid",
                                        borderRightWidth: "1pt"
                                    }}
                                >
                                    <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                        <br />
                                    </p>
                                    <p
                                        className={styles.s4}
                                        style={{
                                            paddingRight: "3pt",
                                            textIndent: "0pt",
                                            textAlign: "right"
                                        }}
                                    >
                                        {getITCSummery('partB', 'totalCGST').toFixed(2)}
                                    </p>
                                </td>
                                <td
                                    style={{
                                        width: "64pt",
                                        borderTopStyle: "solid",
                                        borderTopWidth: "1pt",
                                        borderLeftStyle: "solid",
                                        borderLeftWidth: "1pt",
                                        borderBottomStyle: "solid",
                                        borderBottomWidth: "1pt",
                                        borderRightStyle: "solid",
                                        borderRightWidth: "1pt"
                                    }}
                                >
                                    <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                        <br />
                                    </p>
                                    <p
                                        className={styles.s4}
                                        style={{
                                            paddingRight: "3pt",
                                            textIndent: "0pt",
                                            textAlign: "right"
                                        }}
                                    >
                                        {getITCSummery('partB', 'totalSGST').toFixed(2)}
                                    </p>
                                </td>
                                <td
                                    style={{
                                        width: "64pt",
                                        borderTopStyle: "solid",
                                        borderTopWidth: "1pt",
                                        borderLeftStyle: "solid",
                                        borderLeftWidth: "1pt",
                                        borderBottomStyle: "solid",
                                        borderBottomWidth: "1pt",
                                        borderRightStyle: "solid",
                                        borderRightWidth: "1pt"
                                    }}
                                >
                                    <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                        <br />
                                    </p>
                                    <p
                                        className={styles.s4}
                                        style={{
                                            paddingRight: "3pt",
                                            textIndent: "0pt",
                                            textAlign: "right"
                                        }}
                                    >
                                        {getITCSummery('partB', 'totalCess').toFixed(2)}
                                    </p>
                                </td>
                                <td
                                    style={{
                                        width: "216pt",
                                        borderTopStyle: "solid",
                                        borderTopWidth: "1pt",
                                        borderLeftStyle: "solid",
                                        borderLeftWidth: "1pt",
                                        borderBottomStyle: "solid",
                                        borderBottomWidth: "1pt",
                                        borderRightStyle: "solid",
                                        borderRightWidth: "1pt"
                                    }}
                                >
                                    <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                        <br />
                                    </p>
                                    <p
                                        className={styles.s4}
                                        style={{
                                            paddingLeft: "4pt",
                                            paddingRight: "11pt",
                                            textIndent: "0pt",
                                            textAlign: "left"
                                        }}
                                    >
                                        Such credit shall not be taken and has to be reported in table 4(D)(2) of FORM GSTR-3B.
                                    </p>
                                </td>
                            </tr>
                            <tr style={{ height: "16pt" }}>
                                <td
                                    style={{
                                        width: "43pt",
                                        borderTopStyle: "solid",
                                        borderTopWidth: "1pt",
                                        borderLeftStyle: "solid",
                                        borderLeftWidth: "1pt",
                                        borderBottomStyle: "solid",
                                        borderBottomWidth: "1pt",
                                        borderRightStyle: "solid",
                                        borderRightWidth: "1pt"
                                    }}
                                    rowSpan={4}
                                >
                                    <p
                                        className={styles.s4}
                                        style={{
                                            paddingTop: "1pt",
                                            paddingLeft: "6pt",
                                            textIndent: "0pt",
                                            textAlign: "left"
                                        }}
                                    >
                                        Details
                                    </p>
                                </td>
                                <td
                                    style={{
                                        width: "182pt",
                                        borderTopStyle: "solid",
                                        borderTopWidth: "1pt",
                                        borderLeftStyle: "solid",
                                        borderLeftWidth: "1pt",
                                        borderBottomStyle: "solid",
                                        borderBottomWidth: "1pt",
                                        borderRightStyle: "solid",
                                        borderRightWidth: "1pt"
                                    }}
                                >
                                    <p
                                        className={styles.s4}
                                        style={{
                                            paddingTop: "1pt",
                                            paddingLeft: "4pt",
                                            textIndent: "0pt",
                                            textAlign: "left"
                                        }}
                                    >
                                        B2B - Invoices
                                    </p>
                                </td>
                                <td
                                    style={{
                                        width: "64pt",
                                        borderTopStyle: "solid",
                                        borderTopWidth: "1pt",
                                        borderLeftStyle: "solid",
                                        borderLeftWidth: "1pt",
                                        borderBottomStyle: "solid",
                                        borderBottomWidth: "1pt",
                                        borderRightStyle: "solid",
                                        borderRightWidth: "1pt"
                                    }}
                                    rowSpan={4}
                                    bgcolor="#AFA8A8"
                                >
                                    <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                        <br />
                                    </p>
                                </td>
                                <td
                                    style={{
                                        width: "64pt",
                                        borderTopStyle: "solid",
                                        borderTopWidth: "1pt",
                                        borderLeftStyle: "solid",
                                        borderLeftWidth: "1pt",
                                        borderBottomStyle: "solid",
                                        borderBottomWidth: "1pt",
                                        borderRightStyle: "solid",
                                        borderRightWidth: "1pt"
                                    }}
                                >
                                    <p
                                        className={styles.s4}
                                        style={{
                                            paddingTop: "1pt",
                                            paddingRight: "3pt",
                                            textIndent: "0pt",
                                            textAlign: "right"
                                        }}
                                    >
                                        {getITCSummery('partB', 'b2bIGST').toFixed(2)}
                                    </p>
                                </td>
                                <td
                                    style={{
                                        width: "64pt",
                                        borderTopStyle: "solid",
                                        borderTopWidth: "1pt",
                                        borderLeftStyle: "solid",
                                        borderLeftWidth: "1pt",
                                        borderBottomStyle: "solid",
                                        borderBottomWidth: "1pt",
                                        borderRightStyle: "solid",
                                        borderRightWidth: "1pt"
                                    }}
                                >
                                    <p
                                        className={styles.s4}
                                        style={{
                                            paddingTop: "1pt",
                                            paddingRight: "3pt",
                                            textIndent: "0pt",
                                            textAlign: "right"
                                        }}
                                    >
                                        {getITCSummery('partB', 'b2bCGST').toFixed(2)}
                                    </p>
                                </td>
                                <td
                                    style={{
                                        width: "64pt",
                                        borderTopStyle: "solid",
                                        borderTopWidth: "1pt",
                                        borderLeftStyle: "solid",
                                        borderLeftWidth: "1pt",
                                        borderBottomStyle: "solid",
                                        borderBottomWidth: "1pt",
                                        borderRightStyle: "solid",
                                        borderRightWidth: "1pt"
                                    }}
                                >
                                    <p
                                        className={styles.s4}
                                        style={{
                                            paddingTop: "1pt",
                                            paddingRight: "3pt",
                                            textIndent: "0pt",
                                            textAlign: "right"
                                        }}
                                    >
                                        {getITCSummery('partB', 'b2bSGST').toFixed(2)}
                                    </p>
                                </td>
                                <td
                                    style={{
                                        width: "64pt",
                                        borderTopStyle: "solid",
                                        borderTopWidth: "1pt",
                                        borderLeftStyle: "solid",
                                        borderLeftWidth: "1pt",
                                        borderBottomStyle: "solid",
                                        borderBottomWidth: "1pt",
                                        borderRightStyle: "solid",
                                        borderRightWidth: "1pt"
                                    }}
                                >
                                    <p
                                        className={styles.s4}
                                        style={{
                                            paddingTop: "1pt",
                                            paddingRight: "3pt",
                                            textIndent: "0pt",
                                            textAlign: "right"
                                        }}
                                    >
                                        {getITCSummery('partB', 'b2bCess').toFixed(2)}
                                    </p>
                                </td>
                                <td
                                    style={{
                                        width: "216pt",
                                        borderTopStyle: "solid",
                                        borderTopWidth: "1pt",
                                        borderLeftStyle: "solid",
                                        borderLeftWidth: "1pt",
                                        borderBottomStyle: "solid",
                                        borderBottomWidth: "1pt",
                                        borderRightStyle: "solid",
                                        borderRightWidth: "1pt"
                                    }}
                                    rowSpan={4}
                                    bgcolor="#AFA8A8"
                                >
                                    <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                        <br />
                                    </p>
                                </td>
                            </tr>
                            <tr style={{ height: "16pt" }}>
                                <td
                                    style={{
                                        width: "182pt",
                                        borderTopStyle: "solid",
                                        borderTopWidth: "1pt",
                                        borderLeftStyle: "solid",
                                        borderLeftWidth: "1pt",
                                        borderBottomStyle: "solid",
                                        borderBottomWidth: "1pt",
                                        borderRightStyle: "solid",
                                        borderRightWidth: "1pt"
                                    }}
                                >
                                    <p
                                        className={styles.s4}
                                        style={{
                                            paddingTop: "1pt",
                                            paddingLeft: "4pt",
                                            textIndent: "0pt",
                                            textAlign: "left"
                                        }}
                                    >
                                        B2B - Debit Notes
                                    </p>
                                </td>
                                <td
                                    style={{
                                        width: "64pt",
                                        borderTopStyle: "solid",
                                        borderTopWidth: "1pt",
                                        borderLeftStyle: "solid",
                                        borderLeftWidth: "1pt",
                                        borderBottomStyle: "solid",
                                        borderBottomWidth: "1pt",
                                        borderRightStyle: "solid",
                                        borderRightWidth: "1pt"
                                    }}
                                >
                                    <p
                                        className={styles.s4}
                                        style={{
                                            paddingTop: "1pt",
                                            paddingRight: "3pt",
                                            textIndent: "0pt",
                                            textAlign: "right"
                                        }}
                                    >
                                        {getITCSummery('partB', 'b2bndIGST').toFixed(2)}
                                    </p>
                                </td>
                                <td
                                    style={{
                                        width: "64pt",
                                        borderTopStyle: "solid",
                                        borderTopWidth: "1pt",
                                        borderLeftStyle: "solid",
                                        borderLeftWidth: "1pt",
                                        borderBottomStyle: "solid",
                                        borderBottomWidth: "1pt",
                                        borderRightStyle: "solid",
                                        borderRightWidth: "1pt"
                                    }}
                                >
                                    <p
                                        className={styles.s4}
                                        style={{
                                            paddingTop: "1pt",
                                            paddingRight: "3pt",
                                            textIndent: "0pt",
                                            textAlign: "right"
                                        }}
                                    >
                                        {getITCSummery('partB', 'b2bndCGST').toFixed(2)}
                                    </p>
                                </td>
                                <td
                                    style={{
                                        width: "64pt",
                                        borderTopStyle: "solid",
                                        borderTopWidth: "1pt",
                                        borderLeftStyle: "solid",
                                        borderLeftWidth: "1pt",
                                        borderBottomStyle: "solid",
                                        borderBottomWidth: "1pt",
                                        borderRightStyle: "solid",
                                        borderRightWidth: "1pt"
                                    }}
                                >
                                    <p
                                        className={styles.s4}
                                        style={{
                                            paddingTop: "1pt",
                                            paddingRight: "3pt",
                                            textIndent: "0pt",
                                            textAlign: "right"
                                        }}
                                    >
                                        {getITCSummery('partB', 'b2bndSGST').toFixed(2)}
                                    </p>
                                </td>
                                <td
                                    style={{
                                        width: "64pt",
                                        borderTopStyle: "solid",
                                        borderTopWidth: "1pt",
                                        borderLeftStyle: "solid",
                                        borderLeftWidth: "1pt",
                                        borderBottomStyle: "solid",
                                        borderBottomWidth: "1pt",
                                        borderRightStyle: "solid",
                                        borderRightWidth: "1pt"
                                    }}
                                >
                                    <p
                                        className={styles.s4}
                                        style={{
                                            paddingTop: "1pt",
                                            paddingRight: "3pt",
                                            textIndent: "0pt",
                                            textAlign: "right"
                                        }}
                                    >
                                        {getITCSummery('partB', 'b2bndCess').toFixed(2)}
                                    </p>
                                </td>
                            </tr>
                            <tr style={{ height: "16pt" }}>
                                <td
                                    style={{
                                        width: "182pt",
                                        borderTopStyle: "solid",
                                        borderTopWidth: "1pt",
                                        borderLeftStyle: "solid",
                                        borderLeftWidth: "1pt",
                                        borderBottomStyle: "solid",
                                        borderBottomWidth: "1pt",
                                        borderRightStyle: "solid",
                                        borderRightWidth: "1pt"
                                    }}
                                >
                                    <p
                                        className={styles.s4}
                                        style={{
                                            paddingTop: "1pt",
                                            paddingLeft: "4pt",
                                            textIndent: "0pt",
                                            textAlign: "left"
                                        }}
                                    >
                                        B2B - Invoices (Amendment)
                                    </p>
                                </td>
                                <td
                                    style={{
                                        width: "64pt",
                                        borderTopStyle: "solid",
                                        borderTopWidth: "1pt",
                                        borderLeftStyle: "solid",
                                        borderLeftWidth: "1pt",
                                        borderBottomStyle: "solid",
                                        borderBottomWidth: "1pt",
                                        borderRightStyle: "solid",
                                        borderRightWidth: "1pt"
                                    }}
                                >
                                    <p
                                        className={styles.s4}
                                        style={{
                                            paddingTop: "1pt",
                                            paddingRight: "3pt",
                                            textIndent: "0pt",
                                            textAlign: "right"
                                        }}
                                    >
                                        {getITCSummery('partB', 'b2baIGST').toFixed(2)}
                                    </p>
                                </td>
                                <td
                                    style={{
                                        width: "64pt",
                                        borderTopStyle: "solid",
                                        borderTopWidth: "1pt",
                                        borderLeftStyle: "solid",
                                        borderLeftWidth: "1pt",
                                        borderBottomStyle: "solid",
                                        borderBottomWidth: "1pt",
                                        borderRightStyle: "solid",
                                        borderRightWidth: "1pt"
                                    }}
                                >
                                    <p
                                        className={styles.s4}
                                        style={{
                                            paddingTop: "1pt",
                                            paddingRight: "3pt",
                                            textIndent: "0pt",
                                            textAlign: "right"
                                        }}
                                    >
                                        {getITCSummery('partB', 'b2baCGST').toFixed(2)}
                                    </p>
                                </td>
                                <td
                                    style={{
                                        width: "64pt",
                                        borderTopStyle: "solid",
                                        borderTopWidth: "1pt",
                                        borderLeftStyle: "solid",
                                        borderLeftWidth: "1pt",
                                        borderBottomStyle: "solid",
                                        borderBottomWidth: "1pt",
                                        borderRightStyle: "solid",
                                        borderRightWidth: "1pt"
                                    }}
                                >
                                    <p
                                        className={styles.s4}
                                        style={{
                                            paddingTop: "1pt",
                                            paddingRight: "3pt",
                                            textIndent: "0pt",
                                            textAlign: "right"
                                        }}
                                    >
                                        {getITCSummery('partB', 'b2baSGST').toFixed(2)}
                                    </p>
                                </td>
                                <td
                                    style={{
                                        width: "64pt",
                                        borderTopStyle: "solid",
                                        borderTopWidth: "1pt",
                                        borderLeftStyle: "solid",
                                        borderLeftWidth: "1pt",
                                        borderBottomStyle: "solid",
                                        borderBottomWidth: "1pt",
                                        borderRightStyle: "solid",
                                        borderRightWidth: "1pt"
                                    }}
                                >
                                    <p
                                        className={styles.s4}
                                        style={{
                                            paddingTop: "1pt",
                                            paddingRight: "3pt",
                                            textIndent: "0pt",
                                            textAlign: "right"
                                        }}
                                    >
                                        {getITCSummery('partB', 'b2baCess').toFixed(2)}
                                    </p>
                                </td>
                            </tr>
                            <tr style={{ height: "16pt" }}>
                                <td
                                    style={{
                                        width: "182pt",
                                        borderTopStyle: "solid",
                                        borderTopWidth: "1pt",
                                        borderLeftStyle: "solid",
                                        borderLeftWidth: "1pt",
                                        borderBottomStyle: "solid",
                                        borderBottomWidth: "1pt",
                                        borderRightStyle: "solid",
                                        borderRightWidth: "1pt"
                                    }}
                                >
                                    <p
                                        className={styles.s4}
                                        style={{
                                            paddingTop: "1pt",
                                            paddingLeft: "4pt",
                                            textIndent: "0pt",
                                            textAlign: "left"
                                        }}
                                    >
                                        B2B - Debit Notes (Amendment)
                                    </p>
                                </td>
                                <td
                                    style={{
                                        width: "64pt",
                                        borderTopStyle: "solid",
                                        borderTopWidth: "1pt",
                                        borderLeftStyle: "solid",
                                        borderLeftWidth: "1pt",
                                        borderBottomStyle: "solid",
                                        borderBottomWidth: "1pt",
                                        borderRightStyle: "solid",
                                        borderRightWidth: "1pt"
                                    }}
                                >
                                    <p
                                        className={styles.s4}
                                        style={{
                                            paddingTop: "1pt",
                                            paddingRight: "3pt",
                                            textIndent: "0pt",
                                            textAlign: "right"
                                        }}
                                    >
                                        0.00
                                    </p>
                                </td>
                                <td
                                    style={{
                                        width: "64pt",
                                        borderTopStyle: "solid",
                                        borderTopWidth: "1pt",
                                        borderLeftStyle: "solid",
                                        borderLeftWidth: "1pt",
                                        borderBottomStyle: "solid",
                                        borderBottomWidth: "1pt",
                                        borderRightStyle: "solid",
                                        borderRightWidth: "1pt"
                                    }}
                                >
                                    <p
                                        className={styles.s4}
                                        style={{
                                            paddingTop: "1pt",
                                            paddingRight: "3pt",
                                            textIndent: "0pt",
                                            textAlign: "right"
                                        }}
                                    >
                                        0.00
                                    </p>
                                </td>
                                <td
                                    style={{
                                        width: "64pt",
                                        borderTopStyle: "solid",
                                        borderTopWidth: "1pt",
                                        borderLeftStyle: "solid",
                                        borderLeftWidth: "1pt",
                                        borderBottomStyle: "solid",
                                        borderBottomWidth: "1pt",
                                        borderRightStyle: "solid",
                                        borderRightWidth: "1pt"
                                    }}
                                >
                                    <p
                                        className={styles.s4}
                                        style={{
                                            paddingTop: "1pt",
                                            paddingRight: "3pt",
                                            textIndent: "0pt",
                                            textAlign: "right"
                                        }}
                                    >
                                        0.00
                                    </p>
                                </td>
                                <td
                                    style={{
                                        width: "64pt",
                                        borderTopStyle: "solid",
                                        borderTopWidth: "1pt",
                                        borderLeftStyle: "solid",
                                        borderLeftWidth: "1pt",
                                        borderBottomStyle: "solid",
                                        borderBottomWidth: "1pt",
                                        borderRightStyle: "solid",
                                        borderRightWidth: "1pt"
                                    }}
                                >
                                    <p
                                        className={styles.s4}
                                        style={{
                                            paddingTop: "1pt",
                                            paddingRight: "3pt",
                                            textIndent: "0pt",
                                            textAlign: "right"
                                        }}
                                    >
                                        0.00
                                    </p>
                                </td>
                            </tr>
                            <tr style={{ height: "28pt" }}>
                                <td
                                    style={{
                                        width: "43pt",
                                        borderTopStyle: "solid",
                                        borderTopWidth: "1pt",
                                        borderLeftStyle: "solid",
                                        borderLeftWidth: "1pt",
                                        borderBottomStyle: "solid",
                                        borderBottomWidth: "1pt",
                                        borderRightStyle: "solid",
                                        borderRightWidth: "1pt"
                                    }}
                                >
                                    <p
                                        className={styles.s4}
                                        style={{
                                            paddingTop: "8pt",
                                            paddingLeft: "7pt",
                                            paddingRight: "6pt",
                                            textIndent: "0pt",
                                            textAlign: "center"
                                        }}
                                    >
                                        II
                                    </p>
                                </td>
                                <td
                                    style={{
                                        width: "182pt",
                                        borderTopStyle: "solid",
                                        borderTopWidth: "1pt",
                                        borderLeftStyle: "solid",
                                        borderLeftWidth: "1pt",
                                        borderBottomStyle: "solid",
                                        borderBottomWidth: "1pt",
                                        borderRightStyle: "solid",
                                        borderRightWidth: "1pt"
                                    }}
                                >
                                    <p
                                        className={styles.s4}
                                        style={{
                                            paddingTop: "8pt",
                                            paddingLeft: "4pt",
                                            textIndent: "0pt",
                                            textAlign: "left"
                                        }}
                                    >
                                        Inward Supplies from ISD
                                    </p>
                                </td>
                                <td
                                    style={{
                                        width: "64pt",
                                        borderTopStyle: "solid",
                                        borderTopWidth: "1pt",
                                        borderLeftStyle: "solid",
                                        borderLeftWidth: "1pt",
                                        borderBottomStyle: "solid",
                                        borderBottomWidth: "1pt",
                                        borderRightStyle: "solid",
                                        borderRightWidth: "1pt"
                                    }}
                                >
                                    <p
                                        className={styles.s4}
                                        style={{
                                            paddingTop: "8pt",
                                            paddingLeft: "11pt",
                                            paddingRight: "10pt",
                                            textIndent: "0pt",
                                            textAlign: "center"
                                        }}
                                    >
                                        4(D)(2)
                                    </p>
                                </td>
                                <td
                                    style={{
                                        width: "64pt",
                                        borderTopStyle: "solid",
                                        borderTopWidth: "1pt",
                                        borderLeftStyle: "solid",
                                        borderLeftWidth: "1pt",
                                        borderBottomStyle: "solid",
                                        borderBottomWidth: "1pt",
                                        borderRightStyle: "solid",
                                        borderRightWidth: "1pt"
                                    }}
                                >
                                    <p
                                        className={styles.s4}
                                        style={{
                                            paddingTop: "8pt",
                                            paddingRight: "3pt",
                                            textIndent: "0pt",
                                            textAlign: "right"
                                        }}
                                    >
                                        0.00
                                    </p>
                                </td>
                                <td
                                    style={{
                                        width: "64pt",
                                        borderTopStyle: "solid",
                                        borderTopWidth: "1pt",
                                        borderLeftStyle: "solid",
                                        borderLeftWidth: "1pt",
                                        borderBottomStyle: "solid",
                                        borderBottomWidth: "1pt",
                                        borderRightStyle: "solid",
                                        borderRightWidth: "1pt"
                                    }}
                                >
                                    <p
                                        className={styles.s4}
                                        style={{
                                            paddingTop: "8pt",
                                            paddingRight: "3pt",
                                            textIndent: "0pt",
                                            textAlign: "right"
                                        }}
                                    >
                                        0.00
                                    </p>
                                </td>
                                <td
                                    style={{
                                        width: "64pt",
                                        borderTopStyle: "solid",
                                        borderTopWidth: "1pt",
                                        borderLeftStyle: "solid",
                                        borderLeftWidth: "1pt",
                                        borderBottomStyle: "solid",
                                        borderBottomWidth: "1pt",
                                        borderRightStyle: "solid",
                                        borderRightWidth: "1pt"
                                    }}
                                >
                                    <p
                                        className={styles.s4}
                                        style={{
                                            paddingTop: "8pt",
                                            paddingRight: "3pt",
                                            textIndent: "0pt",
                                            textAlign: "right"
                                        }}
                                    >
                                        0.00
                                    </p>
                                </td>
                                <td
                                    style={{
                                        width: "64pt",
                                        borderTopStyle: "solid",
                                        borderTopWidth: "1pt",
                                        borderLeftStyle: "solid",
                                        borderLeftWidth: "1pt",
                                        borderBottomStyle: "solid",
                                        borderBottomWidth: "1pt",
                                        borderRightStyle: "solid",
                                        borderRightWidth: "1pt"
                                    }}
                                >
                                    <p
                                        className={styles.s4}
                                        style={{
                                            paddingTop: "8pt",
                                            paddingRight: "3pt",
                                            textIndent: "0pt",
                                            textAlign: "right"
                                        }}
                                    >
                                        0.00
                                    </p>
                                </td>
                                <td
                                    style={{
                                        width: "216pt",
                                        borderTopStyle: "solid",
                                        borderTopWidth: "1pt",
                                        borderLeftStyle: "solid",
                                        borderLeftWidth: "1pt",
                                        borderBottomStyle: "solid",
                                        borderBottomWidth: "1pt",
                                        borderRightStyle: "solid",
                                        borderRightWidth: "1pt"
                                    }}
                                >
                                    <p
                                        className={styles.s4}
                                        style={{
                                            paddingTop: "1pt",
                                            paddingLeft: "4pt",
                                            paddingRight: "11pt",
                                            textIndent: "0pt",
                                            textAlign: "left"
                                        }}
                                    >
                                        Such credit shall not be taken and has to be reported in table 4(D)(2) of FORM GSTR-3B.
                                    </p>
                                </td>
                            </tr>
                            <tr style={{ height: "16pt" }}>
                                <td
                                    style={{
                                        width: "43pt",
                                        borderTopStyle: "solid",
                                        borderTopWidth: "1pt",
                                        borderLeftStyle: "solid",
                                        borderLeftWidth: "1pt",
                                        borderBottomStyle: "solid",
                                        borderBottomWidth: "1pt",
                                        borderRightStyle: "solid",
                                        borderRightWidth: "1pt"
                                    }}
                                    rowSpan={2}
                                >
                                    <p
                                        className={styles.s4}
                                        style={{
                                            paddingTop: "1pt",
                                            paddingLeft: "6pt",
                                            textIndent: "0pt",
                                            textAlign: "left"
                                        }}
                                    >
                                        Details
                                    </p>
                                </td>
                                <td
                                    style={{
                                        width: "182pt",
                                        borderTopStyle: "solid",
                                        borderTopWidth: "1pt",
                                        borderLeftStyle: "solid",
                                        borderLeftWidth: "1pt",
                                        borderBottomStyle: "solid",
                                        borderBottomWidth: "1pt",
                                        borderRightStyle: "solid",
                                        borderRightWidth: "1pt"
                                    }}
                                >
                                    <p
                                        className={styles.s4}
                                        style={{
                                            paddingTop: "1pt",
                                            paddingLeft: "4pt",
                                            textIndent: "0pt",
                                            textAlign: "left"
                                        }}
                                    >
                                        ISD - Invoices
                                    </p>
                                </td>
                                <td
                                    style={{
                                        width: "64pt",
                                        borderTopStyle: "solid",
                                        borderTopWidth: "1pt",
                                        borderLeftStyle: "solid",
                                        borderLeftWidth: "1pt",
                                        borderBottomStyle: "solid",
                                        borderBottomWidth: "1pt",
                                        borderRightStyle: "solid",
                                        borderRightWidth: "1pt"
                                    }}
                                    rowSpan={2}
                                    bgcolor="#AFA8A8"
                                >
                                    <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                        <br />
                                    </p>
                                </td>
                                <td
                                    style={{
                                        width: "64pt",
                                        borderTopStyle: "solid",
                                        borderTopWidth: "1pt",
                                        borderLeftStyle: "solid",
                                        borderLeftWidth: "1pt",
                                        borderBottomStyle: "solid",
                                        borderBottomWidth: "1pt",
                                        borderRightStyle: "solid",
                                        borderRightWidth: "1pt"
                                    }}
                                >
                                    <p
                                        className={styles.s4}
                                        style={{
                                            paddingTop: "1pt",
                                            paddingRight: "3pt",
                                            textIndent: "0pt",
                                            textAlign: "right"
                                        }}
                                    >
                                        0.00
                                    </p>
                                </td>
                                <td
                                    style={{
                                        width: "64pt",
                                        borderTopStyle: "solid",
                                        borderTopWidth: "1pt",
                                        borderLeftStyle: "solid",
                                        borderLeftWidth: "1pt",
                                        borderBottomStyle: "solid",
                                        borderBottomWidth: "1pt",
                                        borderRightStyle: "solid",
                                        borderRightWidth: "1pt"
                                    }}
                                >
                                    <p
                                        className={styles.s4}
                                        style={{
                                            paddingTop: "1pt",
                                            paddingRight: "3pt",
                                            textIndent: "0pt",
                                            textAlign: "right"
                                        }}
                                    >
                                        0.00
                                    </p>
                                </td>
                                <td
                                    style={{
                                        width: "64pt",
                                        borderTopStyle: "solid",
                                        borderTopWidth: "1pt",
                                        borderLeftStyle: "solid",
                                        borderLeftWidth: "1pt",
                                        borderBottomStyle: "solid",
                                        borderBottomWidth: "1pt",
                                        borderRightStyle: "solid",
                                        borderRightWidth: "1pt"
                                    }}
                                >
                                    <p
                                        className={styles.s4}
                                        style={{
                                            paddingTop: "1pt",
                                            paddingRight: "3pt",
                                            textIndent: "0pt",
                                            textAlign: "right"
                                        }}
                                    >
                                        0.00
                                    </p>
                                </td>
                                <td
                                    style={{
                                        width: "64pt",
                                        borderTopStyle: "solid",
                                        borderTopWidth: "1pt",
                                        borderLeftStyle: "solid",
                                        borderLeftWidth: "1pt",
                                        borderBottomStyle: "solid",
                                        borderBottomWidth: "1pt",
                                        borderRightStyle: "solid",
                                        borderRightWidth: "1pt"
                                    }}
                                >
                                    <p
                                        className={styles.s4}
                                        style={{
                                            paddingTop: "1pt",
                                            paddingRight: "3pt",
                                            textIndent: "0pt",
                                            textAlign: "right"
                                        }}
                                    >
                                        0.00
                                    </p>
                                </td>
                                <td
                                    style={{
                                        width: "216pt",
                                        borderTopStyle: "solid",
                                        borderTopWidth: "1pt",
                                        borderLeftStyle: "solid",
                                        borderLeftWidth: "1pt",
                                        borderBottomStyle: "solid",
                                        borderBottomWidth: "1pt",
                                        borderRightStyle: "solid",
                                        borderRightWidth: "1pt"
                                    }}
                                    rowSpan={2}
                                    bgcolor="#AFA8A8"
                                >
                                    <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                        <br />
                                    </p>
                                </td>
                            </tr>
                            <tr style={{ height: "16pt" }}>
                                <td
                                    style={{
                                        width: "182pt",
                                        borderTopStyle: "solid",
                                        borderTopWidth: "1pt",
                                        borderLeftStyle: "solid",
                                        borderLeftWidth: "1pt",
                                        borderBottomStyle: "solid",
                                        borderBottomWidth: "1pt",
                                        borderRightStyle: "solid",
                                        borderRightWidth: "1pt"
                                    }}
                                >
                                    <p
                                        className={styles.s4}
                                        style={{
                                            paddingTop: "1pt",
                                            paddingLeft: "4pt",
                                            textIndent: "0pt",
                                            textAlign: "left"
                                        }}
                                    >
                                        ISD - Invoices (Amendment)
                                    </p>
                                </td>
                                <td
                                    style={{
                                        width: "64pt",
                                        borderTopStyle: "solid",
                                        borderTopWidth: "1pt",
                                        borderLeftStyle: "solid",
                                        borderLeftWidth: "1pt",
                                        borderBottomStyle: "solid",
                                        borderBottomWidth: "1pt",
                                        borderRightStyle: "solid",
                                        borderRightWidth: "1pt"
                                    }}
                                >
                                    <p
                                        className={styles.s4}
                                        style={{
                                            paddingTop: "1pt",
                                            paddingRight: "3pt",
                                            textIndent: "0pt",
                                            textAlign: "right"
                                        }}
                                    >
                                        0.00
                                    </p>
                                </td>
                                <td
                                    style={{
                                        width: "64pt",
                                        borderTopStyle: "solid",
                                        borderTopWidth: "1pt",
                                        borderLeftStyle: "solid",
                                        borderLeftWidth: "1pt",
                                        borderBottomStyle: "solid",
                                        borderBottomWidth: "1pt",
                                        borderRightStyle: "solid",
                                        borderRightWidth: "1pt"
                                    }}
                                >
                                    <p
                                        className={styles.s4}
                                        style={{
                                            paddingTop: "1pt",
                                            paddingRight: "3pt",
                                            textIndent: "0pt",
                                            textAlign: "right"
                                        }}
                                    >
                                        0.00
                                    </p>
                                </td>
                                <td
                                    style={{
                                        width: "64pt",
                                        borderTopStyle: "solid",
                                        borderTopWidth: "1pt",
                                        borderLeftStyle: "solid",
                                        borderLeftWidth: "1pt",
                                        borderBottomStyle: "solid",
                                        borderBottomWidth: "1pt",
                                        borderRightStyle: "solid",
                                        borderRightWidth: "1pt"
                                    }}
                                >
                                    <p
                                        className={styles.s4}
                                        style={{
                                            paddingTop: "1pt",
                                            paddingRight: "3pt",
                                            textIndent: "0pt",
                                            textAlign: "right"
                                        }}
                                    >
                                        0.00
                                    </p>
                                </td>
                                <td
                                    style={{
                                        width: "64pt",
                                        borderTopStyle: "solid",
                                        borderTopWidth: "1pt",
                                        borderLeftStyle: "solid",
                                        borderLeftWidth: "1pt",
                                        borderBottomStyle: "solid",
                                        borderBottomWidth: "1pt",
                                        borderRightStyle: "solid",
                                        borderRightWidth: "1pt"
                                    }}
                                >
                                    <p
                                        className={styles.s4}
                                        style={{
                                            paddingTop: "1pt",
                                            paddingRight: "3pt",
                                            textIndent: "0pt",
                                            textAlign: "right"
                                        }}
                                    >
                                        0.00
                                    </p>
                                </td>
                            </tr>
                            <tr style={{ height: "63pt" }}>
                                <td
                                    style={{
                                        width: "43pt",
                                        borderTopStyle: "solid",
                                        borderTopWidth: "1pt",
                                        borderLeftStyle: "solid",
                                        borderLeftWidth: "1pt",
                                        borderBottomStyle: "solid",
                                        borderBottomWidth: "1pt",
                                        borderRightStyle: "solid",
                                        borderRightWidth: "1pt"
                                    }}
                                >
                                    <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                        <br />
                                    </p>
                                    <p
                                        className={styles.s4}
                                        style={{
                                            paddingTop: "7pt",
                                            paddingLeft: "7pt",
                                            paddingRight: "6pt",
                                            textIndent: "0pt",
                                            textAlign: "center"
                                        }}
                                    >
                                        III
                                    </p>
                                </td>
                                <td
                                    style={{
                                        width: "182pt",
                                        borderTopStyle: "solid",
                                        borderTopWidth: "1pt",
                                        borderLeftStyle: "solid",
                                        borderLeftWidth: "1pt",
                                        borderBottomStyle: "solid",
                                        borderBottomWidth: "1pt",
                                        borderRightStyle: "solid",
                                        borderRightWidth: "1pt"
                                    }}
                                >
                                    <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                        <br />
                                    </p>
                                    <p
                                        className={styles.s4}
                                        style={{
                                            paddingTop: "7pt",
                                            paddingLeft: "4pt",
                                            paddingRight: "3pt",
                                            textIndent: "0pt",
                                            textAlign: "left"
                                        }}
                                    >
                                        Inward Supplies liable for reverse charge
                                    </p>
                                </td>
                                <td
                                    style={{
                                        width: "64pt",
                                        borderTopStyle: "solid",
                                        borderTopWidth: "1pt",
                                        borderLeftStyle: "solid",
                                        borderLeftWidth: "1pt",
                                        borderBottomStyle: "solid",
                                        borderBottomWidth: "1pt",
                                        borderRightStyle: "solid",
                                        borderRightWidth: "1pt"
                                    }}
                                >
                                    <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                        <br />
                                    </p>
                                    <p
                                        className={styles.s4}
                                        style={{
                                            paddingLeft: "18pt",
                                            textIndent: "0pt",
                                            textAlign: "left"
                                        }}
                                    >
                                        3.1(d) <br />4(D)(2)
                                    </p>
                                </td>
                                <td
                                    style={{
                                        width: "64pt",
                                        borderTopStyle: "solid",
                                        borderTopWidth: "1pt",
                                        borderLeftStyle: "solid",
                                        borderLeftWidth: "1pt",
                                        borderBottomStyle: "solid",
                                        borderBottomWidth: "1pt",
                                        borderRightStyle: "solid",
                                        borderRightWidth: "1pt"
                                    }}
                                >
                                    <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                        <br />
                                    </p>
                                    <p
                                        className={styles.s4}
                                        style={{
                                            paddingTop: "7pt",
                                            paddingRight: "3pt",
                                            textIndent: "0pt",
                                            textAlign: "right"
                                        }}
                                    >
                                        {getITCSummery('partB', 'totalIGSTr').toFixed(2)}
                                    </p>
                                </td>
                                <td
                                    style={{
                                        width: "64pt",
                                        borderTopStyle: "solid",
                                        borderTopWidth: "1pt",
                                        borderLeftStyle: "solid",
                                        borderLeftWidth: "1pt",
                                        borderBottomStyle: "solid",
                                        borderBottomWidth: "1pt",
                                        borderRightStyle: "solid",
                                        borderRightWidth: "1pt"
                                    }}
                                >
                                    <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                        <br />
                                    </p>
                                    <p
                                        className={styles.s4}
                                        style={{
                                            paddingTop: "7pt",
                                            paddingRight: "3pt",
                                            textIndent: "0pt",
                                            textAlign: "right"
                                        }}
                                    >
                                        {getITCSummery('partB', 'totalCGSTr').toFixed(2)}
                                    </p>
                                </td>
                                <td
                                    style={{
                                        width: "64pt",
                                        borderTopStyle: "solid",
                                        borderTopWidth: "1pt",
                                        borderLeftStyle: "solid",
                                        borderLeftWidth: "1pt",
                                        borderBottomStyle: "solid",
                                        borderBottomWidth: "1pt",
                                        borderRightStyle: "solid",
                                        borderRightWidth: "1pt"
                                    }}
                                >
                                    <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                        <br />
                                    </p>
                                    <p
                                        className={styles.s4}
                                        style={{
                                            paddingTop: "7pt",
                                            paddingRight: "3pt",
                                            textIndent: "0pt",
                                            textAlign: "right"
                                        }}
                                    >
                                        {getITCSummery('partB', 'totalSGSTr').toFixed(2)}
                                    </p>
                                </td>
                                <td
                                    style={{
                                        width: "64pt",
                                        borderTopStyle: "solid",
                                        borderTopWidth: "1pt",
                                        borderLeftStyle: "solid",
                                        borderLeftWidth: "1pt",
                                        borderBottomStyle: "solid",
                                        borderBottomWidth: "1pt",
                                        borderRightStyle: "solid",
                                        borderRightWidth: "1pt"
                                    }}
                                >
                                    <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                        <br />
                                    </p>
                                    <p
                                        className={styles.s4}
                                        style={{
                                            paddingTop: "7pt",
                                            paddingRight: "3pt",
                                            textIndent: "0pt",
                                            textAlign: "right"
                                        }}
                                    >
                                        {getITCSummery('partB', 'totalCessr').toFixed(2)}
                                    </p>
                                </td>
                                <td
                                    style={{
                                        width: "216pt",
                                        borderTopStyle: "solid",
                                        borderTopWidth: "1pt",
                                        borderLeftStyle: "solid",
                                        borderLeftWidth: "1pt",
                                        borderBottomStyle: "solid",
                                        borderBottomWidth: "1pt",
                                        borderRightStyle: "solid",
                                        borderRightWidth: "1pt"
                                    }}
                                >
                                    <p
                                        className={styles.s4}
                                        style={{
                                            paddingTop: "1pt",
                                            paddingLeft: "4pt",
                                            paddingRight: "11pt",
                                            textIndent: "0pt",
                                            textAlign: "left"
                                        }}
                                    >
                                        These supplies shall be declared in Table 3.1(d) of FORM GSTR-3B for payment of tax. However, credit will not be available on the same and has to be reported in table 4(D)(2) of FORM GSTR-3B.
                                    </p>
                                </td>
                            </tr>
                            <tr style={{ height: "16pt" }}>
                                <td
                                    style={{
                                        width: "43pt",
                                        borderTopStyle: "solid",
                                        borderTopWidth: "1pt",
                                        borderLeftStyle: "solid",
                                        borderLeftWidth: "1pt",
                                        borderBottomStyle: "solid",
                                        borderBottomWidth: "1pt",
                                        borderRightStyle: "solid",
                                        borderRightWidth: "1pt"
                                    }}
                                    rowSpan={4}
                                >
                                    <p
                                        className={styles.s4}
                                        style={{
                                            paddingTop: "1pt",
                                            paddingLeft: "6pt",
                                            textIndent: "0pt",
                                            textAlign: "left"
                                        }}
                                    >
                                        Details
                                    </p>
                                </td>
                                <td
                                    style={{
                                        width: "182pt",
                                        borderTopStyle: "solid",
                                        borderTopWidth: "1pt",
                                        borderLeftStyle: "solid",
                                        borderLeftWidth: "1pt",
                                        borderBottomStyle: "solid",
                                        borderBottomWidth: "1pt",
                                        borderRightStyle: "solid",
                                        borderRightWidth: "1pt"
                                    }}
                                >
                                    <p
                                        className={styles.s4}
                                        style={{
                                            paddingTop: "1pt",
                                            paddingLeft: "4pt",
                                            textIndent: "0pt",
                                            textAlign: "left"
                                        }}
                                    >
                                        B2B - Invoices
                                    </p>
                                </td>
                                <td
                                    style={{
                                        width: "64pt",
                                        borderTopStyle: "solid",
                                        borderTopWidth: "1pt",
                                        borderLeftStyle: "solid",
                                        borderLeftWidth: "1pt",
                                        borderBottomStyle: "solid",
                                        borderBottomWidth: "1pt",
                                        borderRightStyle: "solid",
                                        borderRightWidth: "1pt"
                                    }}
                                    rowSpan={4}
                                    bgcolor="#AFA8A8"
                                >
                                    <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                        <br />
                                    </p>
                                </td>
                                <td
                                    style={{
                                        width: "64pt",
                                        borderTopStyle: "solid",
                                        borderTopWidth: "1pt",
                                        borderLeftStyle: "solid",
                                        borderLeftWidth: "1pt",
                                        borderBottomStyle: "solid",
                                        borderBottomWidth: "1pt",
                                        borderRightStyle: "solid",
                                        borderRightWidth: "1pt"
                                    }}
                                >
                                    <p
                                        className={styles.s4}
                                        style={{
                                            paddingTop: "1pt",
                                            paddingRight: "3pt",
                                            textIndent: "0pt",
                                            textAlign: "right"
                                        }}
                                    >
                                        {getITCSummery('partB', 'b2bIGSTr').toFixed(2)}
                                    </p>
                                </td>
                                <td
                                    style={{
                                        width: "64pt",
                                        borderTopStyle: "solid",
                                        borderTopWidth: "1pt",
                                        borderLeftStyle: "solid",
                                        borderLeftWidth: "1pt",
                                        borderBottomStyle: "solid",
                                        borderBottomWidth: "1pt",
                                        borderRightStyle: "solid",
                                        borderRightWidth: "1pt"
                                    }}
                                >
                                    <p
                                        className={styles.s4}
                                        style={{
                                            paddingTop: "1pt",
                                            paddingRight: "3pt",
                                            textIndent: "0pt",
                                            textAlign: "right"
                                        }}
                                    >
                                        {getITCSummery('partB', 'b2bCGSTr').toFixed(2)}
                                    </p>
                                </td>
                                <td
                                    style={{
                                        width: "64pt",
                                        borderTopStyle: "solid",
                                        borderTopWidth: "1pt",
                                        borderLeftStyle: "solid",
                                        borderLeftWidth: "1pt",
                                        borderBottomStyle: "solid",
                                        borderBottomWidth: "1pt",
                                        borderRightStyle: "solid",
                                        borderRightWidth: "1pt"
                                    }}
                                >
                                    <p
                                        className={styles.s4}
                                        style={{
                                            paddingTop: "1pt",
                                            paddingRight: "3pt",
                                            textIndent: "0pt",
                                            textAlign: "right"
                                        }}
                                    >
                                        {getITCSummery('partB', 'b2bSGSTr').toFixed(2)}
                                    </p>
                                </td>
                                <td
                                    style={{
                                        width: "64pt",
                                        borderTopStyle: "solid",
                                        borderTopWidth: "1pt",
                                        borderLeftStyle: "solid",
                                        borderLeftWidth: "1pt",
                                        borderBottomStyle: "solid",
                                        borderBottomWidth: "1pt",
                                        borderRightStyle: "solid",
                                        borderRightWidth: "1pt"
                                    }}
                                >
                                    <p
                                        className={styles.s4}
                                        style={{
                                            paddingTop: "1pt",
                                            paddingRight: "3pt",
                                            textIndent: "0pt",
                                            textAlign: "right"
                                        }}
                                    >
                                        {getITCSummery('partB', 'b2bCessr').toFixed(2)}
                                    </p>
                                </td>
                                <td
                                    style={{
                                        width: "216pt",
                                        borderTopStyle: "solid",
                                        borderTopWidth: "1pt",
                                        borderLeftStyle: "solid",
                                        borderLeftWidth: "1pt",
                                        borderBottomStyle: "solid",
                                        borderBottomWidth: "1pt",
                                        borderRightStyle: "solid",
                                        borderRightWidth: "1pt"
                                    }}
                                    rowSpan={4}
                                    bgcolor="#AFA8A8"
                                >
                                    <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                        <br />
                                    </p>
                                </td>
                            </tr>
                            <tr style={{ height: "16pt" }}>
                                <td
                                    style={{
                                        width: "182pt",
                                        borderTopStyle: "solid",
                                        borderTopWidth: "1pt",
                                        borderLeftStyle: "solid",
                                        borderLeftWidth: "1pt",
                                        borderBottomStyle: "solid",
                                        borderBottomWidth: "1pt",
                                        borderRightStyle: "solid",
                                        borderRightWidth: "1pt"
                                    }}
                                >
                                    <p
                                        className={styles.s4}
                                        style={{
                                            paddingTop: "1pt",
                                            paddingLeft: "4pt",
                                            textIndent: "0pt",
                                            textAlign: "left"
                                        }}
                                    >
                                        B2B - Debit Notes
                                    </p>
                                </td>
                                <td
                                    style={{
                                        width: "64pt",
                                        borderTopStyle: "solid",
                                        borderTopWidth: "1pt",
                                        borderLeftStyle: "solid",
                                        borderLeftWidth: "1pt",
                                        borderBottomStyle: "solid",
                                        borderBottomWidth: "1pt",
                                        borderRightStyle: "solid",
                                        borderRightWidth: "1pt"
                                    }}
                                >
                                    <p
                                        className={styles.s4}
                                        style={{
                                            paddingTop: "1pt",
                                            paddingRight: "3pt",
                                            textIndent: "0pt",
                                            textAlign: "right"
                                        }}
                                    >
                                        {getITCSummery('partB', 'b2bndIGSTr').toFixed(2)}
                                    </p>
                                </td>
                                <td
                                    style={{
                                        width: "64pt",
                                        borderTopStyle: "solid",
                                        borderTopWidth: "1pt",
                                        borderLeftStyle: "solid",
                                        borderLeftWidth: "1pt",
                                        borderBottomStyle: "solid",
                                        borderBottomWidth: "1pt",
                                        borderRightStyle: "solid",
                                        borderRightWidth: "1pt"
                                    }}
                                >
                                    <p
                                        className={styles.s4}
                                        style={{
                                            paddingTop: "1pt",
                                            paddingRight: "3pt",
                                            textIndent: "0pt",
                                            textAlign: "right"
                                        }}
                                    >
                                        {getITCSummery('partB', 'b2bndCGSTr').toFixed(2)}
                                    </p>
                                </td>
                                <td
                                    style={{
                                        width: "64pt",
                                        borderTopStyle: "solid",
                                        borderTopWidth: "1pt",
                                        borderLeftStyle: "solid",
                                        borderLeftWidth: "1pt",
                                        borderBottomStyle: "solid",
                                        borderBottomWidth: "1pt",
                                        borderRightStyle: "solid",
                                        borderRightWidth: "1pt"
                                    }}
                                >
                                    <p
                                        className={styles.s4}
                                        style={{
                                            paddingTop: "1pt",
                                            paddingRight: "3pt",
                                            textIndent: "0pt",
                                            textAlign: "right"
                                        }}
                                    >
                                        {getITCSummery('partB', 'b2bndSGSTr').toFixed(2)}
                                    </p>
                                </td>
                                <td
                                    style={{
                                        width: "64pt",
                                        borderTopStyle: "solid",
                                        borderTopWidth: "1pt",
                                        borderLeftStyle: "solid",
                                        borderLeftWidth: "1pt",
                                        borderBottomStyle: "solid",
                                        borderBottomWidth: "1pt",
                                        borderRightStyle: "solid",
                                        borderRightWidth: "1pt"
                                    }}
                                >
                                    <p
                                        className={styles.s4}
                                        style={{
                                            paddingTop: "1pt",
                                            paddingRight: "3pt",
                                            textIndent: "0pt",
                                            textAlign: "right"
                                        }}
                                    >
                                        {getITCSummery('partB', 'b2bndCessr').toFixed(2)}
                                    </p>
                                </td>
                            </tr>
                            <tr style={{ height: "16pt" }}>
                                <td
                                    style={{
                                        width: "182pt",
                                        borderTopStyle: "solid",
                                        borderTopWidth: "1pt",
                                        borderLeftStyle: "solid",
                                        borderLeftWidth: "1pt",
                                        borderBottomStyle: "solid",
                                        borderBottomWidth: "1pt",
                                        borderRightStyle: "solid",
                                        borderRightWidth: "1pt"
                                    }}
                                >
                                    <p
                                        className={styles.s4}
                                        style={{
                                            paddingTop: "1pt",
                                            paddingLeft: "4pt",
                                            textIndent: "0pt",
                                            textAlign: "left"
                                        }}
                                    >
                                        B2B - Invoices (Amendment)
                                    </p>
                                </td>
                                <td
                                    style={{
                                        width: "64pt",
                                        borderTopStyle: "solid",
                                        borderTopWidth: "1pt",
                                        borderLeftStyle: "solid",
                                        borderLeftWidth: "1pt",
                                        borderBottomStyle: "solid",
                                        borderBottomWidth: "1pt",
                                        borderRightStyle: "solid",
                                        borderRightWidth: "1pt"
                                    }}
                                >
                                    <p
                                        className={styles.s4}
                                        style={{
                                            paddingTop: "1pt",
                                            paddingRight: "3pt",
                                            textIndent: "0pt",
                                            textAlign: "right"
                                        }}
                                    >
                                        {getITCSummery('partB', 'b2baIGSTr').toFixed(2)}
                                    </p>
                                </td>
                                <td
                                    style={{
                                        width: "64pt",
                                        borderTopStyle: "solid",
                                        borderTopWidth: "1pt",
                                        borderLeftStyle: "solid",
                                        borderLeftWidth: "1pt",
                                        borderBottomStyle: "solid",
                                        borderBottomWidth: "1pt",
                                        borderRightStyle: "solid",
                                        borderRightWidth: "1pt"
                                    }}
                                >
                                    <p
                                        className={styles.s4}
                                        style={{
                                            paddingTop: "1pt",
                                            paddingRight: "3pt",
                                            textIndent: "0pt",
                                            textAlign: "right"
                                        }}
                                    >
                                        {getITCSummery('partB', 'b2baCGSTr').toFixed(2)}
                                    </p>
                                </td>
                                <td
                                    style={{
                                        width: "64pt",
                                        borderTopStyle: "solid",
                                        borderTopWidth: "1pt",
                                        borderLeftStyle: "solid",
                                        borderLeftWidth: "1pt",
                                        borderBottomStyle: "solid",
                                        borderBottomWidth: "1pt",
                                        borderRightStyle: "solid",
                                        borderRightWidth: "1pt"
                                    }}
                                >
                                    <p
                                        className={styles.s4}
                                        style={{
                                            paddingTop: "1pt",
                                            paddingRight: "3pt",
                                            textIndent: "0pt",
                                            textAlign: "right"
                                        }}
                                    >
                                        {getITCSummery('partB', 'b2baSGSTr').toFixed(2)}
                                    </p>
                                </td>
                                <td
                                    style={{
                                        width: "64pt",
                                        borderTopStyle: "solid",
                                        borderTopWidth: "1pt",
                                        borderLeftStyle: "solid",
                                        borderLeftWidth: "1pt",
                                        borderBottomStyle: "solid",
                                        borderBottomWidth: "1pt",
                                        borderRightStyle: "solid",
                                        borderRightWidth: "1pt"
                                    }}
                                >
                                    <p
                                        className={styles.s4}
                                        style={{
                                            paddingTop: "1pt",
                                            paddingRight: "3pt",
                                            textIndent: "0pt",
                                            textAlign: "right"
                                        }}
                                    >
                                        {getITCSummery('partB', 'b2baCessr').toFixed(2)}
                                    </p>
                                </td>
                            </tr>
                            <tr style={{ height: "16pt" }}>
                                <td
                                    style={{
                                        width: "182pt",
                                        borderTopStyle: "solid",
                                        borderTopWidth: "1pt",
                                        borderLeftStyle: "solid",
                                        borderLeftWidth: "1pt",
                                        borderBottomStyle: "solid",
                                        borderBottomWidth: "1pt",
                                        borderRightStyle: "solid",
                                        borderRightWidth: "1pt"
                                    }}
                                >
                                    <p
                                        className={styles.s4}
                                        style={{
                                            paddingTop: "1pt",
                                            paddingLeft: "4pt",
                                            textIndent: "0pt",
                                            textAlign: "left"
                                        }}
                                    >
                                        B2B - Debit Notes (Amendment)
                                    </p>
                                </td>
                                <td
                                    style={{
                                        width: "64pt",
                                        borderTopStyle: "solid",
                                        borderTopWidth: "1pt",
                                        borderLeftStyle: "solid",
                                        borderLeftWidth: "1pt",
                                        borderBottomStyle: "solid",
                                        borderBottomWidth: "1pt",
                                        borderRightStyle: "solid",
                                        borderRightWidth: "1pt"
                                    }}
                                >
                                    <p
                                        className={styles.s4}
                                        style={{
                                            paddingTop: "1pt",
                                            paddingRight: "3pt",
                                            textIndent: "0pt",
                                            textAlign: "right"
                                        }}
                                    >
                                        0.00
                                    </p>
                                </td>
                                <td
                                    style={{
                                        width: "64pt",
                                        borderTopStyle: "solid",
                                        borderTopWidth: "1pt",
                                        borderLeftStyle: "solid",
                                        borderLeftWidth: "1pt",
                                        borderBottomStyle: "solid",
                                        borderBottomWidth: "1pt",
                                        borderRightStyle: "solid",
                                        borderRightWidth: "1pt"
                                    }}
                                >
                                    <p
                                        className={styles.s4}
                                        style={{
                                            paddingTop: "1pt",
                                            paddingRight: "3pt",
                                            textIndent: "0pt",
                                            textAlign: "right"
                                        }}
                                    >
                                        0.00
                                    </p>
                                </td>
                                <td
                                    style={{
                                        width: "64pt",
                                        borderTopStyle: "solid",
                                        borderTopWidth: "1pt",
                                        borderLeftStyle: "solid",
                                        borderLeftWidth: "1pt",
                                        borderBottomStyle: "solid",
                                        borderBottomWidth: "1pt",
                                        borderRightStyle: "solid",
                                        borderRightWidth: "1pt"
                                    }}
                                >
                                    <p
                                        className={styles.s4}
                                        style={{
                                            paddingTop: "1pt",
                                            paddingRight: "3pt",
                                            textIndent: "0pt",
                                            textAlign: "right"
                                        }}
                                    >
                                        0.00
                                    </p>
                                </td>
                                <td
                                    style={{
                                        width: "64pt",
                                        borderTopStyle: "solid",
                                        borderTopWidth: "1pt",
                                        borderLeftStyle: "solid",
                                        borderLeftWidth: "1pt",
                                        borderBottomStyle: "solid",
                                        borderBottomWidth: "1pt",
                                        borderRightStyle: "solid",
                                        borderRightWidth: "1pt"
                                    }}
                                >
                                    <p
                                        className={styles.s4}
                                        style={{
                                            paddingTop: "1pt",
                                            paddingRight: "3pt",
                                            textIndent: "0pt",
                                            textAlign: "right"
                                        }}
                                    >
                                        0.00
                                    </p>
                                </td>
                            </tr>
                            <tr style={{ height: "16pt" }}>
                                <td
                                    style={{
                                        width: "761pt",
                                        borderTopStyle: "solid",
                                        borderTopWidth: "1pt",
                                        borderLeftStyle: "solid",
                                        borderLeftWidth: "1pt",
                                        borderBottomStyle: "solid",
                                        borderBottomWidth: "1pt",
                                        borderRightStyle: "solid",
                                        borderRightWidth: "1pt"
                                    }}
                                    colSpan={8}
                                    bgcolor="#F4B084"
                                >
                                    <p
                                        className={styles.s4}
                                        style={{
                                            paddingTop: "1pt",
                                            paddingLeft: "4pt",
                                            textIndent: "0pt",
                                            textAlign: "left"
                                        }}
                                    >
                                        Part B ITC Not Available – Credit notes should be net-off against relevant ITC available headings in GSTR-3B
                                    </p>
                                </td>
                            </tr>
                            <tr style={{ height: "28pt" }}>
                                <td
                                    style={{
                                        width: "43pt",
                                        borderTopStyle: "solid",
                                        borderTopWidth: "1pt",
                                        borderLeftStyle: "solid",
                                        borderLeftWidth: "1pt",
                                        borderBottomStyle: "solid",
                                        borderBottomWidth: "1pt",
                                        borderRightStyle: "solid",
                                        borderRightWidth: "1pt"
                                    }}
                                >
                                    <p
                                        className={styles.s4}
                                        style={{
                                            paddingTop: "8pt",
                                            textIndent: "0pt",
                                            textAlign: "center"
                                        }}
                                    >
                                        I
                                    </p>
                                </td>
                                <td
                                    style={{
                                        width: "182pt",
                                        borderTopStyle: "solid",
                                        borderTopWidth: "1pt",
                                        borderLeftStyle: "solid",
                                        borderLeftWidth: "1pt",
                                        borderBottomStyle: "solid",
                                        borderBottomWidth: "1pt",
                                        borderRightStyle: "solid",
                                        borderRightWidth: "1pt"
                                    }}
                                >
                                    <p
                                        className={styles.s4}
                                        style={{
                                            paddingTop: "8pt",
                                            paddingLeft: "4pt",
                                            textIndent: "0pt",
                                            textAlign: "left"
                                        }}
                                    >
                                        Others
                                    </p>
                                </td>
                                <td
                                    style={{
                                        width: "64pt",
                                        borderTopStyle: "solid",
                                        borderTopWidth: "1pt",
                                        borderLeftStyle: "solid",
                                        borderLeftWidth: "1pt",
                                        borderBottomStyle: "solid",
                                        borderBottomWidth: "1pt",
                                        borderRightStyle: "solid",
                                        borderRightWidth: "1pt"
                                    }}
                                >
                                    <p
                                        className={styles.s4}
                                        style={{
                                            paddingTop: "8pt",
                                            paddingLeft: "11pt",
                                            paddingRight: "10pt",
                                            textIndent: "0pt",
                                            textAlign: "center"
                                        }}
                                    >
                                        4(A)
                                    </p>
                                </td>
                                <td
                                    style={{
                                        width: "64pt",
                                        borderTopStyle: "solid",
                                        borderTopWidth: "1pt",
                                        borderLeftStyle: "solid",
                                        borderLeftWidth: "1pt",
                                        borderBottomStyle: "solid",
                                        borderBottomWidth: "1pt",
                                        borderRightStyle: "solid",
                                        borderRightWidth: "1pt"
                                    }}
                                >
                                    <p
                                        className={styles.s4}
                                        style={{
                                            paddingTop: "8pt",
                                            paddingRight: "3pt",
                                            textIndent: "0pt",
                                            textAlign: "right"
                                        }}
                                    >
                                        {getITCSummery('partB', 'b2bncIGSTT').toFixed(2)}
                                    </p>
                                </td>
                                <td
                                    style={{
                                        width: "64pt",
                                        borderTopStyle: "solid",
                                        borderTopWidth: "1pt",
                                        borderLeftStyle: "solid",
                                        borderLeftWidth: "1pt",
                                        borderBottomStyle: "solid",
                                        borderBottomWidth: "1pt",
                                        borderRightStyle: "solid",
                                        borderRightWidth: "1pt"
                                    }}
                                >
                                    <p
                                        className={styles.s4}
                                        style={{
                                            paddingTop: "8pt",
                                            paddingRight: "3pt",
                                            textIndent: "0pt",
                                            textAlign: "right"
                                        }}
                                    >
                                        {getITCSummery('partB', 'b2bncCGSTT').toFixed(2)}
                                    </p>
                                </td>
                                <td
                                    style={{
                                        width: "64pt",
                                        borderTopStyle: "solid",
                                        borderTopWidth: "1pt",
                                        borderLeftStyle: "solid",
                                        borderLeftWidth: "1pt",
                                        borderBottomStyle: "solid",
                                        borderBottomWidth: "1pt",
                                        borderRightStyle: "solid",
                                        borderRightWidth: "1pt"
                                    }}
                                >
                                    <p
                                        className={styles.s4}
                                        style={{
                                            paddingTop: "8pt",
                                            paddingRight: "3pt",
                                            textIndent: "0pt",
                                            textAlign: "right"
                                        }}
                                    >
                                        {getITCSummery('partB', 'b2bncSGSTT').toFixed(2)}
                                    </p>
                                </td>
                                <td
                                    style={{
                                        width: "64pt",
                                        borderTopStyle: "solid",
                                        borderTopWidth: "1pt",
                                        borderLeftStyle: "solid",
                                        borderLeftWidth: "1pt",
                                        borderBottomStyle: "solid",
                                        borderBottomWidth: "1pt",
                                        borderRightStyle: "solid",
                                        borderRightWidth: "1pt"
                                    }}
                                >
                                    <p
                                        className={styles.s4}
                                        style={{
                                            paddingTop: "8pt",
                                            paddingRight: "3pt",
                                            textIndent: "0pt",
                                            textAlign: "right"
                                        }}
                                    >
                                        {getITCSummery('partB', 'b2bncCessT').toFixed(2)}
                                    </p>
                                </td>
                                <td
                                    style={{
                                        width: "216pt",
                                        borderTopStyle: "solid",
                                        borderTopWidth: "1pt",
                                        borderLeftStyle: "solid",
                                        borderLeftWidth: "1pt",
                                        borderBottomStyle: "solid",
                                        borderBottomWidth: "1pt",
                                        borderRightStyle: "solid",
                                        borderRightWidth: "1pt"
                                    }}
                                >
                                    <p
                                        className={styles.s4}
                                        style={{
                                            paddingTop: "1pt",
                                            paddingLeft: "4pt",
                                            textIndent: "0pt",
                                            textAlign: "left"
                                        }}
                                    >
                                        Credit Notes should be net-off against relevant ITC available tables [Table 4A(3,4,5)].
                                    </p>
                                </td>
                            </tr>
                            <tr style={{ height: "16pt" }}>
                                <td
                                    style={{
                                        width: "43pt",
                                        borderTopStyle: "solid",
                                        borderTopWidth: "1pt",
                                        borderLeftStyle: "solid",
                                        borderLeftWidth: "1pt",
                                        borderRightStyle: "solid",
                                        borderRightWidth: "1pt"
                                    }}
                                    rowSpan={2}
                                >
                                    <p
                                        className={styles.s4}
                                        style={{
                                            paddingTop: "1pt",
                                            paddingLeft: "6pt",
                                            textIndent: "0pt",
                                            textAlign: "left"
                                        }}
                                    >
                                        Details
                                    </p>
                                </td>
                                <td
                                    style={{
                                        width: "182pt",
                                        borderTopStyle: "solid",
                                        borderTopWidth: "1pt",
                                        borderLeftStyle: "solid",
                                        borderLeftWidth: "1pt",
                                        borderBottomStyle: "solid",
                                        borderBottomWidth: "1pt",
                                        borderRightStyle: "solid",
                                        borderRightWidth: "1pt"
                                    }}
                                >
                                    <p
                                        className={styles.s4}
                                        style={{
                                            paddingTop: "1pt",
                                            paddingLeft: "4pt",
                                            textIndent: "0pt",
                                            textAlign: "left"
                                        }}
                                    >
                                        B2B - Credit Notes
                                    </p>
                                </td>
                                <td
                                    style={{
                                        width: "64pt",
                                        borderTopStyle: "solid",
                                        borderTopWidth: "1pt",
                                        borderLeftStyle: "solid",
                                        borderLeftWidth: "1pt",
                                        borderBottomStyle: "solid",
                                        borderBottomWidth: "1pt",
                                        borderRightStyle: "solid",
                                        borderRightWidth: "1pt"
                                    }}
                                >
                                    <p
                                        className={styles.s4}
                                        style={{
                                            paddingTop: "1pt",
                                            paddingLeft: "11pt",
                                            paddingRight: "10pt",
                                            textIndent: "0pt",
                                            textAlign: "center"
                                        }}
                                    >
                                        4(A)(5)
                                    </p>
                                </td>
                                <td
                                    style={{
                                        width: "64pt",
                                        borderTopStyle: "solid",
                                        borderTopWidth: "1pt",
                                        borderLeftStyle: "solid",
                                        borderLeftWidth: "1pt",
                                        borderBottomStyle: "solid",
                                        borderBottomWidth: "1pt",
                                        borderRightStyle: "solid",
                                        borderRightWidth: "1pt"
                                    }}
                                >
                                    <p
                                        className={styles.s4}
                                        style={{
                                            paddingTop: "1pt",
                                            paddingRight: "3pt",
                                            textIndent: "0pt",
                                            textAlign: "right"
                                        }}
                                    >
                                        {getITCSummery('partB', 'b2bncIGST').toFixed(2)}
                                    </p>
                                </td>
                                <td
                                    style={{
                                        width: "64pt",
                                        borderTopStyle: "solid",
                                        borderTopWidth: "1pt",
                                        borderLeftStyle: "solid",
                                        borderLeftWidth: "1pt",
                                        borderBottomStyle: "solid",
                                        borderBottomWidth: "1pt",
                                        borderRightStyle: "solid",
                                        borderRightWidth: "1pt"
                                    }}
                                >
                                    <p
                                        className={styles.s4}
                                        style={{
                                            paddingTop: "1pt",
                                            paddingRight: "3pt",
                                            textIndent: "0pt",
                                            textAlign: "right"
                                        }}
                                    >
                                        {getITCSummery('partB', 'b2bncCGST').toFixed(2)}
                                    </p>
                                </td>
                                <td
                                    style={{
                                        width: "64pt",
                                        borderTopStyle: "solid",
                                        borderTopWidth: "1pt",
                                        borderLeftStyle: "solid",
                                        borderLeftWidth: "1pt",
                                        borderBottomStyle: "solid",
                                        borderBottomWidth: "1pt",
                                        borderRightStyle: "solid",
                                        borderRightWidth: "1pt"
                                    }}
                                >
                                    <p
                                        className={styles.s4}
                                        style={{
                                            paddingTop: "1pt",
                                            paddingRight: "3pt",
                                            textIndent: "0pt",
                                            textAlign: "right"
                                        }}
                                    >
                                        {getITCSummery('partB', 'b2bncSGST').toFixed(2)}
                                    </p>
                                </td>
                                <td
                                    style={{
                                        width: "64pt",
                                        borderTopStyle: "solid",
                                        borderTopWidth: "1pt",
                                        borderLeftStyle: "solid",
                                        borderLeftWidth: "1pt",
                                        borderBottomStyle: "solid",
                                        borderBottomWidth: "1pt",
                                        borderRightStyle: "solid",
                                        borderRightWidth: "1pt"
                                    }}
                                >
                                    <p
                                        className={styles.s4}
                                        style={{
                                            paddingTop: "1pt",
                                            paddingRight: "3pt",
                                            textIndent: "0pt",
                                            textAlign: "right"
                                        }}
                                    >
                                        {getITCSummery('partB', 'b2bncCess').toFixed(2)}
                                    </p>
                                </td>
                                <td
                                    style={{
                                        width: "216pt",
                                        borderTopStyle: "solid",
                                        borderTopWidth: "1pt",
                                        borderLeftStyle: "solid",
                                        borderLeftWidth: "1pt",
                                        borderRightStyle: "solid",
                                        borderRightWidth: "1pt"
                                    }}
                                    rowSpan={2}
                                    bgcolor="#AFA8A8"
                                >
                                    <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                        <br />
                                    </p>
                                </td>
                            </tr>
                            <tr style={{ height: "16pt" }}>
                                <td
                                    style={{
                                        width: "182pt",
                                        borderTopStyle: "solid",
                                        borderTopWidth: "1pt",
                                        borderLeftStyle: "solid",
                                        borderLeftWidth: "1pt",
                                        borderBottomStyle: "solid",
                                        borderBottomWidth: "1pt",
                                        borderRightStyle: "solid",
                                        borderRightWidth: "1pt"
                                    }}
                                >
                                    <p
                                        className={styles.s4}
                                        style={{
                                            paddingTop: "1pt",
                                            paddingLeft: "4pt",
                                            textIndent: "0pt",
                                            textAlign: "left"
                                        }}
                                    >
                                        B2B - Credit Notes (Amendment)
                                    </p>
                                </td>
                                <td
                                    style={{
                                        width: "64pt",
                                        borderTopStyle: "solid",
                                        borderTopWidth: "1pt",
                                        borderLeftStyle: "solid",
                                        borderLeftWidth: "1pt",
                                        borderBottomStyle: "solid",
                                        borderBottomWidth: "1pt",
                                        borderRightStyle: "solid",
                                        borderRightWidth: "1pt"
                                    }}
                                >
                                    <p
                                        className={styles.s4}
                                        style={{
                                            paddingTop: "1pt",
                                            paddingLeft: "11pt",
                                            paddingRight: "10pt",
                                            textIndent: "0pt",
                                            textAlign: "center"
                                        }}
                                    >
                                        4(A)(5)
                                    </p>
                                </td>
                                <td
                                    style={{
                                        width: "64pt",
                                        borderTopStyle: "solid",
                                        borderTopWidth: "1pt",
                                        borderLeftStyle: "solid",
                                        borderLeftWidth: "1pt",
                                        borderBottomStyle: "solid",
                                        borderBottomWidth: "1pt",
                                        borderRightStyle: "solid",
                                        borderRightWidth: "1pt"
                                    }}
                                >
                                    <p
                                        className={styles.s4}
                                        style={{
                                            paddingTop: "1pt",
                                            paddingRight: "3pt",
                                            textIndent: "0pt",
                                            textAlign: "right"
                                        }}
                                    >
                                        0.00
                                    </p>
                                </td>
                                <td
                                    style={{
                                        width: "64pt",
                                        borderTopStyle: "solid",
                                        borderTopWidth: "1pt",
                                        borderLeftStyle: "solid",
                                        borderLeftWidth: "1pt",
                                        borderBottomStyle: "solid",
                                        borderBottomWidth: "1pt",
                                        borderRightStyle: "solid",
                                        borderRightWidth: "1pt"
                                    }}
                                >
                                    <p
                                        className={styles.s4}
                                        style={{
                                            paddingTop: "1pt",
                                            paddingRight: "3pt",
                                            textIndent: "0pt",
                                            textAlign: "right"
                                        }}
                                    >
                                        0.00
                                    </p>
                                </td>
                                <td
                                    style={{
                                        width: "64pt",
                                        borderTopStyle: "solid",
                                        borderTopWidth: "1pt",
                                        borderLeftStyle: "solid",
                                        borderLeftWidth: "1pt",
                                        borderBottomStyle: "solid",
                                        borderBottomWidth: "1pt",
                                        borderRightStyle: "solid",
                                        borderRightWidth: "1pt"
                                    }}
                                >
                                    <p
                                        className={styles.s4}
                                        style={{
                                            paddingTop: "1pt",
                                            paddingRight: "3pt",
                                            textIndent: "0pt",
                                            textAlign: "right"
                                        }}
                                    >
                                        0.00
                                    </p>
                                </td>
                                <td
                                    style={{
                                        width: "64pt",
                                        borderTopStyle: "solid",
                                        borderTopWidth: "1pt",
                                        borderLeftStyle: "solid",
                                        borderLeftWidth: "1pt",
                                        borderBottomStyle: "solid",
                                        borderBottomWidth: "1pt",
                                        borderRightStyle: "solid",
                                        borderRightWidth: "1pt"
                                    }}
                                >
                                    <p
                                        className={styles.s4}
                                        style={{
                                            paddingTop: "1pt",
                                            paddingRight: "3pt",
                                            textIndent: "0pt",
                                            textAlign: "right"
                                        }}
                                    >
                                        0.00
                                    </p>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <p style={{ pageBreakAfter: 'always' }}>&nbsp;</p>
                    <div className='fixed w-[1080px] h-[764px] flex flex-col items-center justify-center opacity-20 pointer-events-none' style={{ zIndex: 9999 }}>
                        <img src={Logo} alt="ITAA" className='w-[800] mx-auto h-[227px] pointer-events-none' style={{ transform: "rotate(-45deg)" }} />
                    </div>
                    <table style={{ borderCollapse: "collapse", marginTop: "36pt", marginLeft: "28pt", display: 'table' }} cellSpacing={0} >
                        <tbody>
                            <tr style={{ height: "28pt" }}>
                                <td
                                    style={{
                                        width: "43pt",
                                        borderTopStyle: "solid",
                                        borderTopWidth: "1pt",
                                        borderLeftStyle: "solid",
                                        borderLeftWidth: "1pt",
                                        borderBottomStyle: "solid",
                                        borderBottomWidth: "1pt",
                                        borderRightStyle: "solid",
                                        borderRightWidth: "1pt"
                                    }}
                                    bgcolor="#203764"
                                >
                                    <p
                                        className={styles.s10}
                                        style={{
                                            paddingTop: "6pt",
                                            paddingLeft: "10pt",
                                            textIndent: "0pt",
                                            textAlign: "left"
                                        }}
                                    >
                                        S.no.
                                    </p>
                                </td>
                                <td
                                    style={{
                                        width: "182pt",
                                        borderTopStyle: "solid",
                                        borderTopWidth: "1pt",
                                        borderLeftStyle: "solid",
                                        borderLeftWidth: "1pt",
                                        borderBottomStyle: "solid",
                                        borderBottomWidth: "1pt",
                                        borderRightStyle: "solid",
                                        borderRightWidth: "1pt"
                                    }}
                                    bgcolor="#203764"
                                >
                                    <p
                                        className={styles.s10}
                                        style={{
                                            paddingTop: "6pt",
                                            paddingLeft: "68pt",
                                            paddingRight: "67pt",
                                            textIndent: "0pt",
                                            textAlign: "center"
                                        }}
                                    >
                                        Heading
                                    </p>
                                </td>
                                <td
                                    style={{
                                        width: "64pt",
                                        borderTopStyle: "solid",
                                        borderTopWidth: "1pt",
                                        borderLeftStyle: "solid",
                                        borderLeftWidth: "1pt",
                                        borderBottomStyle: "solid",
                                        borderBottomWidth: "1pt",
                                        borderRightStyle: "solid",
                                        borderRightWidth: "1pt"
                                    }}
                                    bgcolor="#203764"
                                >
                                    <p
                                        className={styles.s10}
                                        style={{
                                            paddingTop: "1pt",
                                            paddingLeft: "11pt",
                                            paddingRight: "10pt",
                                            textIndent: "0pt",
                                            textAlign: "center"
                                        }}
                                    >
                                        GSTR-3B
                                    </p>
                                    <p
                                        className={styles.s10}
                                        style={{
                                            paddingLeft: "11pt",
                                            paddingRight: "10pt",
                                            textIndent: "0pt",
                                            textAlign: "center"
                                        }}
                                    >
                                        table
                                    </p>
                                </td>
                                <td
                                    style={{
                                        width: "64pt",
                                        borderTopStyle: "solid",
                                        borderTopWidth: "1pt",
                                        borderLeftStyle: "solid",
                                        borderLeftWidth: "1pt",
                                        borderBottomStyle: "solid",
                                        borderBottomWidth: "1pt",
                                        borderRightStyle: "solid",
                                        borderRightWidth: "1pt"
                                    }}
                                    bgcolor="#203764"
                                >
                                    <p
                                        className={styles.s10}
                                        style={{
                                            paddingTop: "1pt",
                                            paddingLeft: "16pt",
                                            paddingRight: "1pt",
                                            textIndent: "-7pt",
                                            textAlign: "left"
                                        }}
                                    >
                                        Integrated Tax (₹)
                                    </p>
                                </td>
                                <td
                                    style={{
                                        width: "64pt",
                                        borderTopStyle: "solid",
                                        borderTopWidth: "1pt",
                                        borderLeftStyle: "solid",
                                        borderLeftWidth: "1pt",
                                        borderBottomStyle: "solid",
                                        borderBottomWidth: "1pt",
                                        borderRightStyle: "solid",
                                        borderRightWidth: "1pt"
                                    }}
                                    bgcolor="#203764"
                                >
                                    <p
                                        className={styles.s10}
                                        style={{
                                            paddingTop: "1pt",
                                            paddingLeft: "25pt",
                                            paddingRight: "1pt",
                                            textIndent: "-19pt",
                                            textAlign: "left"
                                        }}
                                    >
                                        Central Tax (₹)
                                    </p>
                                </td>
                                <td
                                    style={{
                                        width: "64pt",
                                        borderTopStyle: "solid",
                                        borderTopWidth: "1pt",
                                        borderLeftStyle: "solid",
                                        borderLeftWidth: "1pt",
                                        borderBottomStyle: "solid",
                                        borderBottomWidth: "1pt",
                                        borderRightStyle: "solid",
                                        borderRightWidth: "1pt"
                                    }}
                                    bgcolor="#203764"
                                >
                                    <p
                                        className={styles.s10}
                                        style={{
                                            paddingTop: "1pt",
                                            paddingLeft: "16pt",
                                            paddingRight: "1pt",
                                            textIndent: "-4pt",
                                            textAlign: "left"
                                        }}
                                    >
                                        State/UT Tax (₹)
                                    </p>
                                </td>
                                <td
                                    style={{
                                        width: "64pt",
                                        borderTopStyle: "solid",
                                        borderTopWidth: "1pt",
                                        borderLeftStyle: "solid",
                                        borderLeftWidth: "1pt",
                                        borderBottomStyle: "solid",
                                        borderBottomWidth: "1pt",
                                        borderRightStyle: "solid",
                                        borderRightWidth: "1pt"
                                    }}
                                    bgcolor="#203764"
                                >
                                    <p
                                        className={styles.s10}
                                        style={{
                                            paddingTop: "6pt",
                                            paddingLeft: "13pt",
                                            textIndent: "0pt",
                                            textAlign: "left"
                                        }}
                                    >
                                        Cess (₹)
                                    </p>
                                </td>
                                <td
                                    style={{
                                        width: "216pt",
                                        borderTopStyle: "solid",
                                        borderTopWidth: "1pt",
                                        borderLeftStyle: "solid",
                                        borderLeftWidth: "1pt",
                                        borderBottomStyle: "solid",
                                        borderBottomWidth: "1pt",
                                        borderRightStyle: "solid",
                                        borderRightWidth: "1pt"
                                    }}
                                    bgcolor="#203764"
                                >
                                    <p
                                        className={styles.s10}
                                        style={{
                                            paddingTop: "1pt",
                                            paddingLeft: "87pt",
                                            paddingRight: "87pt",
                                            textIndent: "0pt",
                                            textAlign: "center"
                                        }}
                                    >
                                        Advisory
                                    </p>
                                </td>
                            </tr>
                            <tr style={{ height: "16pt" }}>
                                <td
                                    style={{
                                        width: "43pt",
                                        borderTopStyle: "solid",
                                        borderTopWidth: "1pt",
                                        borderLeftStyle: "solid",
                                        borderLeftWidth: "1pt",
                                        borderBottomStyle: "solid",
                                        borderBottomWidth: "1pt",
                                        borderRightStyle: "solid",
                                        borderRightWidth: "1pt"
                                    }}
                                    rowSpan={4}
                                >
                                    <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                        <br />
                                    </p>
                                </td>
                                <td
                                    style={{
                                        width: "182pt",
                                        borderTopStyle: "solid",
                                        borderTopWidth: "1pt",
                                        borderLeftStyle: "solid",
                                        borderLeftWidth: "1pt",
                                        borderBottomStyle: "solid",
                                        borderBottomWidth: "1pt",
                                        borderRightStyle: "solid",
                                        borderRightWidth: "1pt"
                                    }}
                                >
                                    <p
                                        className={styles.s4}
                                        style={{
                                            paddingTop: "1pt",
                                            paddingLeft: "4pt",
                                            textIndent: "0pt",
                                            textAlign: "left"
                                        }}
                                    >
                                        B2B - Credit notes (Reverse charge)
                                    </p>
                                </td>
                                <td
                                    style={{
                                        width: "64pt",
                                        borderTopStyle: "solid",
                                        borderTopWidth: "1pt",
                                        borderLeftStyle: "solid",
                                        borderLeftWidth: "1pt",
                                        borderBottomStyle: "solid",
                                        borderBottomWidth: "1pt",
                                        borderRightStyle: "solid",
                                        borderRightWidth: "1pt"
                                    }}
                                >
                                    <p
                                        className={styles.s4}
                                        style={{
                                            paddingTop: "1pt",
                                            paddingLeft: "11pt",
                                            paddingRight: "10pt",
                                            textIndent: "0pt",
                                            textAlign: "center"
                                        }}
                                    >
                                        4(A)(3)
                                    </p>
                                </td>
                                <td
                                    style={{
                                        width: "64pt",
                                        borderTopStyle: "solid",
                                        borderTopWidth: "1pt",
                                        borderLeftStyle: "solid",
                                        borderLeftWidth: "1pt",
                                        borderBottomStyle: "solid",
                                        borderBottomWidth: "1pt",
                                        borderRightStyle: "solid",
                                        borderRightWidth: "1pt"
                                    }}
                                >
                                    <p
                                        className={styles.s4}
                                        style={{
                                            paddingTop: "1pt",
                                            paddingRight: "3pt",
                                            textIndent: "0pt",
                                            textAlign: "right"
                                        }}
                                    >
                                        {getITCSummery('partB', 'b2bncIGSTr').toFixed(2)}
                                    </p>
                                </td>
                                <td
                                    style={{
                                        width: "64pt",
                                        borderTopStyle: "solid",
                                        borderTopWidth: "1pt",
                                        borderLeftStyle: "solid",
                                        borderLeftWidth: "1pt",
                                        borderBottomStyle: "solid",
                                        borderBottomWidth: "1pt",
                                        borderRightStyle: "solid",
                                        borderRightWidth: "1pt"
                                    }}
                                >
                                    <p
                                        className={styles.s4}
                                        style={{
                                            paddingTop: "1pt",
                                            paddingRight: "3pt",
                                            textIndent: "0pt",
                                            textAlign: "right"
                                        }}
                                    >
                                        {getITCSummery('partB', 'b2bncCGSTr').toFixed(2)}
                                    </p>
                                </td>
                                <td
                                    style={{
                                        width: "64pt",
                                        borderTopStyle: "solid",
                                        borderTopWidth: "1pt",
                                        borderLeftStyle: "solid",
                                        borderLeftWidth: "1pt",
                                        borderBottomStyle: "solid",
                                        borderBottomWidth: "1pt",
                                        borderRightStyle: "solid",
                                        borderRightWidth: "1pt"
                                    }}
                                >
                                    <p
                                        className={styles.s4}
                                        style={{
                                            paddingTop: "1pt",
                                            paddingRight: "3pt",
                                            textIndent: "0pt",
                                            textAlign: "right"
                                        }}
                                    >
                                        {getITCSummery('partB', 'b2bncSGSTr').toFixed(2)}
                                    </p>
                                </td>
                                <td
                                    style={{
                                        width: "64pt",
                                        borderTopStyle: "solid",
                                        borderTopWidth: "1pt",
                                        borderLeftStyle: "solid",
                                        borderLeftWidth: "1pt",
                                        borderBottomStyle: "solid",
                                        borderBottomWidth: "1pt",
                                        borderRightStyle: "solid",
                                        borderRightWidth: "1pt"
                                    }}
                                >
                                    <p
                                        className={styles.s4}
                                        style={{
                                            paddingTop: "1pt",
                                            paddingRight: "3pt",
                                            textIndent: "0pt",
                                            textAlign: "right"
                                        }}
                                    >
                                        {getITCSummery('partB', 'b2bncCessr').toFixed(2)}
                                    </p>
                                </td>
                                <td
                                    style={{
                                        width: "216pt",
                                        borderTopStyle: "solid",
                                        borderTopWidth: "1pt",
                                        borderLeftStyle: "solid",
                                        borderLeftWidth: "1pt",
                                        borderBottomStyle: "solid",
                                        borderBottomWidth: "1pt",
                                        borderRightStyle: "solid",
                                        borderRightWidth: "1pt"
                                    }}
                                    rowSpan={4}
                                    bgcolor="#AFA8A8"
                                >
                                    <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                        <br />
                                    </p>
                                </td>
                            </tr>
                            <tr style={{ height: "28pt" }}>
                                <td
                                    style={{
                                        width: "182pt",
                                        borderTopStyle: "solid",
                                        borderTopWidth: "1pt",
                                        borderLeftStyle: "solid",
                                        borderLeftWidth: "1pt",
                                        borderBottomStyle: "solid",
                                        borderBottomWidth: "1pt",
                                        borderRightStyle: "solid",
                                        borderRightWidth: "1pt"
                                    }}
                                >
                                    <p
                                        className={styles.s4}
                                        style={{
                                            paddingTop: "1pt",
                                            paddingLeft: "4pt",
                                            paddingRight: "3pt",
                                            textIndent: "0pt",
                                            textAlign: "left"
                                        }}
                                    >
                                        B2B - - Credit notes (Reverse charge) (Amendment)
                                    </p>
                                </td>
                                <td
                                    style={{
                                        width: "64pt",
                                        borderTopStyle: "solid",
                                        borderTopWidth: "1pt",
                                        borderLeftStyle: "solid",
                                        borderLeftWidth: "1pt",
                                        borderBottomStyle: "solid",
                                        borderBottomWidth: "1pt",
                                        borderRightStyle: "solid",
                                        borderRightWidth: "1pt"
                                    }}
                                >
                                    <p
                                        className={styles.s4}
                                        style={{
                                            paddingTop: "1pt",
                                            paddingLeft: "11pt",
                                            paddingRight: "10pt",
                                            textIndent: "0pt",
                                            textAlign: "center"
                                        }}
                                    >
                                        4(A)(3)
                                    </p>
                                </td>
                                <td
                                    style={{
                                        width: "64pt",
                                        borderTopStyle: "solid",
                                        borderTopWidth: "1pt",
                                        borderLeftStyle: "solid",
                                        borderLeftWidth: "1pt",
                                        borderBottomStyle: "solid",
                                        borderBottomWidth: "1pt",
                                        borderRightStyle: "solid",
                                        borderRightWidth: "1pt"
                                    }}
                                >
                                    <p
                                        className={styles.s4}
                                        style={{
                                            paddingTop: "1pt",
                                            paddingRight: "3pt",
                                            textIndent: "0pt",
                                            textAlign: "right"
                                        }}
                                    >
                                        0.00
                                    </p>
                                </td>
                                <td
                                    style={{
                                        width: "64pt",
                                        borderTopStyle: "solid",
                                        borderTopWidth: "1pt",
                                        borderLeftStyle: "solid",
                                        borderLeftWidth: "1pt",
                                        borderBottomStyle: "solid",
                                        borderBottomWidth: "1pt",
                                        borderRightStyle: "solid",
                                        borderRightWidth: "1pt"
                                    }}
                                >
                                    <p
                                        className={styles.s4}
                                        style={{
                                            paddingTop: "1pt",
                                            paddingRight: "3pt",
                                            textIndent: "0pt",
                                            textAlign: "right"
                                        }}
                                    >
                                        0.00
                                    </p>
                                </td>
                                <td
                                    style={{
                                        width: "64pt",
                                        borderTopStyle: "solid",
                                        borderTopWidth: "1pt",
                                        borderLeftStyle: "solid",
                                        borderLeftWidth: "1pt",
                                        borderBottomStyle: "solid",
                                        borderBottomWidth: "1pt",
                                        borderRightStyle: "solid",
                                        borderRightWidth: "1pt"
                                    }}
                                >
                                    <p
                                        className={styles.s4}
                                        style={{
                                            paddingTop: "1pt",
                                            paddingRight: "3pt",
                                            textIndent: "0pt",
                                            textAlign: "right"
                                        }}
                                    >
                                        0.00
                                    </p>
                                </td>
                                <td
                                    style={{
                                        width: "64pt",
                                        borderTopStyle: "solid",
                                        borderTopWidth: "1pt",
                                        borderLeftStyle: "solid",
                                        borderLeftWidth: "1pt",
                                        borderBottomStyle: "solid",
                                        borderBottomWidth: "1pt",
                                        borderRightStyle: "solid",
                                        borderRightWidth: "1pt"
                                    }}
                                >
                                    <p
                                        className={styles.s4}
                                        style={{
                                            paddingTop: "1pt",
                                            paddingRight: "3pt",
                                            textIndent: "0pt",
                                            textAlign: "right"
                                        }}
                                    >
                                        0.00
                                    </p>
                                </td>
                            </tr>
                            <tr style={{ height: "16pt" }}>
                                <td
                                    style={{
                                        width: "182pt",
                                        borderTopStyle: "solid",
                                        borderTopWidth: "1pt",
                                        borderLeftStyle: "solid",
                                        borderLeftWidth: "1pt",
                                        borderBottomStyle: "solid",
                                        borderBottomWidth: "1pt",
                                        borderRightStyle: "solid",
                                        borderRightWidth: "1pt"
                                    }}
                                >
                                    <p
                                        className={styles.s4}
                                        style={{
                                            paddingTop: "1pt",
                                            paddingLeft: "4pt",
                                            textIndent: "0pt",
                                            textAlign: "left"
                                        }}
                                    >
                                        ISD - Credit notes
                                    </p>
                                </td>
                                <td
                                    style={{
                                        width: "64pt",
                                        borderTopStyle: "solid",
                                        borderTopWidth: "1pt",
                                        borderLeftStyle: "solid",
                                        borderLeftWidth: "1pt",
                                        borderBottomStyle: "solid",
                                        borderBottomWidth: "1pt",
                                        borderRightStyle: "solid",
                                        borderRightWidth: "1pt"
                                    }}
                                >
                                    <p
                                        className={styles.s4}
                                        style={{
                                            paddingTop: "1pt",
                                            paddingLeft: "11pt",
                                            paddingRight: "10pt",
                                            textIndent: "0pt",
                                            textAlign: "center"
                                        }}
                                    >
                                        4(A)(4)
                                    </p>
                                </td>
                                <td
                                    style={{
                                        width: "64pt",
                                        borderTopStyle: "solid",
                                        borderTopWidth: "1pt",
                                        borderLeftStyle: "solid",
                                        borderLeftWidth: "1pt",
                                        borderBottomStyle: "solid",
                                        borderBottomWidth: "1pt",
                                        borderRightStyle: "solid",
                                        borderRightWidth: "1pt"
                                    }}
                                >
                                    <p
                                        className={styles.s4}
                                        style={{
                                            paddingTop: "1pt",
                                            paddingRight: "3pt",
                                            textIndent: "0pt",
                                            textAlign: "right"
                                        }}
                                    >
                                        0.00
                                    </p>
                                </td>
                                <td
                                    style={{
                                        width: "64pt",
                                        borderTopStyle: "solid",
                                        borderTopWidth: "1pt",
                                        borderLeftStyle: "solid",
                                        borderLeftWidth: "1pt",
                                        borderBottomStyle: "solid",
                                        borderBottomWidth: "1pt",
                                        borderRightStyle: "solid",
                                        borderRightWidth: "1pt"
                                    }}
                                >
                                    <p
                                        className={styles.s4}
                                        style={{
                                            paddingTop: "1pt",
                                            paddingRight: "3pt",
                                            textIndent: "0pt",
                                            textAlign: "right"
                                        }}
                                    >
                                        0.00
                                    </p>
                                </td>
                                <td
                                    style={{
                                        width: "64pt",
                                        borderTopStyle: "solid",
                                        borderTopWidth: "1pt",
                                        borderLeftStyle: "solid",
                                        borderLeftWidth: "1pt",
                                        borderBottomStyle: "solid",
                                        borderBottomWidth: "1pt",
                                        borderRightStyle: "solid",
                                        borderRightWidth: "1pt"
                                    }}
                                >
                                    <p
                                        className={styles.s4}
                                        style={{
                                            paddingTop: "1pt",
                                            paddingRight: "3pt",
                                            textIndent: "0pt",
                                            textAlign: "right"
                                        }}
                                    >
                                        0.00
                                    </p>
                                </td>
                                <td
                                    style={{
                                        width: "64pt",
                                        borderTopStyle: "solid",
                                        borderTopWidth: "1pt",
                                        borderLeftStyle: "solid",
                                        borderLeftWidth: "1pt",
                                        borderBottomStyle: "solid",
                                        borderBottomWidth: "1pt",
                                        borderRightStyle: "solid",
                                        borderRightWidth: "1pt"
                                    }}
                                >
                                    <p
                                        className={styles.s4}
                                        style={{
                                            paddingTop: "1pt",
                                            paddingRight: "3pt",
                                            textIndent: "0pt",
                                            textAlign: "right"
                                        }}
                                    >
                                        0.00
                                    </p>
                                </td>
                            </tr>
                            <tr style={{ height: "16pt" }}>
                                <td
                                    style={{
                                        width: "182pt",
                                        borderTopStyle: "solid",
                                        borderTopWidth: "1pt",
                                        borderLeftStyle: "solid",
                                        borderLeftWidth: "1pt",
                                        borderBottomStyle: "solid",
                                        borderBottomWidth: "1pt",
                                        borderRightStyle: "solid",
                                        borderRightWidth: "1pt"
                                    }}
                                >
                                    <p
                                        className={styles.s4}
                                        style={{
                                            paddingTop: "1pt",
                                            paddingLeft: "4pt",
                                            textIndent: "0pt",
                                            textAlign: "left"
                                        }}
                                    >
                                        ISD - Credit Notes (Amendment)
                                    </p>
                                </td>
                                <td
                                    style={{
                                        width: "64pt",
                                        borderTopStyle: "solid",
                                        borderTopWidth: "1pt",
                                        borderLeftStyle: "solid",
                                        borderLeftWidth: "1pt",
                                        borderBottomStyle: "solid",
                                        borderBottomWidth: "1pt",
                                        borderRightStyle: "solid",
                                        borderRightWidth: "1pt"
                                    }}
                                >
                                    <p
                                        className={styles.s4}
                                        style={{
                                            paddingTop: "1pt",
                                            paddingLeft: "11pt",
                                            paddingRight: "10pt",
                                            textIndent: "0pt",
                                            textAlign: "center"
                                        }}
                                    >
                                        4(A)(4)
                                    </p>
                                </td>
                                <td
                                    style={{
                                        width: "64pt",
                                        borderTopStyle: "solid",
                                        borderTopWidth: "1pt",
                                        borderLeftStyle: "solid",
                                        borderLeftWidth: "1pt",
                                        borderBottomStyle: "solid",
                                        borderBottomWidth: "1pt",
                                        borderRightStyle: "solid",
                                        borderRightWidth: "1pt"
                                    }}
                                >
                                    <p
                                        className={styles.s4}
                                        style={{
                                            paddingTop: "1pt",
                                            paddingRight: "3pt",
                                            textIndent: "0pt",
                                            textAlign: "right"
                                        }}
                                    >
                                        0.00
                                    </p>
                                </td>
                                <td
                                    style={{
                                        width: "64pt",
                                        borderTopStyle: "solid",
                                        borderTopWidth: "1pt",
                                        borderLeftStyle: "solid",
                                        borderLeftWidth: "1pt",
                                        borderBottomStyle: "solid",
                                        borderBottomWidth: "1pt",
                                        borderRightStyle: "solid",
                                        borderRightWidth: "1pt"
                                    }}
                                >
                                    <p
                                        className={styles.s4}
                                        style={{
                                            paddingTop: "1pt",
                                            paddingRight: "3pt",
                                            textIndent: "0pt",
                                            textAlign: "right"
                                        }}
                                    >
                                        0.00
                                    </p>
                                </td>
                                <td
                                    style={{
                                        width: "64pt",
                                        borderTopStyle: "solid",
                                        borderTopWidth: "1pt",
                                        borderLeftStyle: "solid",
                                        borderLeftWidth: "1pt",
                                        borderBottomStyle: "solid",
                                        borderBottomWidth: "1pt",
                                        borderRightStyle: "solid",
                                        borderRightWidth: "1pt"
                                    }}
                                >
                                    <p
                                        className={styles.s4}
                                        style={{
                                            paddingTop: "1pt",
                                            paddingRight: "3pt",
                                            textIndent: "0pt",
                                            textAlign: "right"
                                        }}
                                    >
                                        0.00
                                    </p>
                                </td>
                                <td
                                    style={{
                                        width: "64pt",
                                        borderTopStyle: "solid",
                                        borderTopWidth: "1pt",
                                        borderLeftStyle: "solid",
                                        borderLeftWidth: "1pt",
                                        borderBottomStyle: "solid",
                                        borderBottomWidth: "1pt",
                                        borderRightStyle: "solid",
                                        borderRightWidth: "1pt"
                                    }}
                                >
                                    <p
                                        className={styles.s4}
                                        style={{
                                            paddingTop: "1pt",
                                            paddingRight: "3pt",
                                            textIndent: "0pt",
                                            textAlign: "right"
                                        }}
                                    >
                                        0.00
                                    </p>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                    <p className={styles.s2} style={{ paddingTop: '4pt', paddingLeft: '28pt', textIndent: '0pt', textAlign: 'left' }}>Instructions:</p>
                    <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                    <ol className={styles.l2} style={{ marginLeft: '28pt', marginRight: '20pt' }}>
                        <li data-list-text={1}>
                            <p
                                style={{
                                    paddingTop: "4pt",
                                    paddingLeft: "22pt",
                                    textIndent: "-18pt",
                                    textAlign: "left"
                                }}
                            >
                                Terms Used :-
                            </p>
                            <ol className={styles.l3}>
                                <li data-list-text="a.">
                                    <p
                                        style={{
                                            paddingTop: "4pt",
                                            paddingLeft: "34pt",
                                            textIndent: "-11pt",
                                            textAlign: "left"
                                        }}
                                    >
                                        ITC – Input tax credit
                                    </p>
                                </li>
                                <li data-list-text="b.">
                                    <p
                                        style={{
                                            paddingTop: "4pt",
                                            paddingLeft: "34pt",
                                            textIndent: "-11pt",
                                            textAlign: "left"
                                        }}
                                    >
                                        B2B – Business to Business
                                    </p>
                                </li>
                                <li data-list-text="c.">
                                    <p
                                        style={{
                                            paddingTop: "4pt",
                                            paddingLeft: "34pt",
                                            textIndent: "-11pt",
                                            textAlign: "left"
                                        }}
                                    >
                                        ISD – Input service distributor
                                    </p>
                                </li>
                                <li data-list-text="d.">
                                    <p
                                        style={{
                                            paddingTop: "4pt",
                                            paddingLeft: "34pt",
                                            textIndent: "-11pt",
                                            textAlign: "left"
                                        }}
                                    >
                                        IMPG – Import of goods
                                    </p>
                                </li>
                                <li data-list-text="e.">
                                    <p
                                        style={{
                                            paddingTop: "4pt",
                                            paddingLeft: "22pt",
                                            textIndent: "0pt",
                                            textAlign: "left"
                                        }}
                                    >
                                        IMPGSEZ – Import of goods from SEZ
                                    </p>
                                </li>
                            </ol>
                        </li>
                        <li data-list-text={2}>
                            <p style={{ paddingLeft: "20pt", textIndent: "-9pt", textAlign: "left" }}>
                                <div style={{ border: '2px solid' }}>
                                    <p
                                        className={styles.s9}
                                        style={{
                                            paddingTop: "1pt",
                                            paddingLeft: "4pt",
                                            textIndent: "0pt",
                                            textAlign: "left"
                                        }}
                                    >
                                        Important Advisory:
                                    </p>
                                    <ol className={styles.l4}>
                                        <li data-list-text="a.">
                                            <p
                                                className={styles.s9}
                                                style={{
                                                    paddingTop: "8pt",
                                                    paddingLeft: "14pt",
                                                    textIndent: "-10pt",
                                                    lineHeight: "136%",
                                                    textAlign: "left"
                                                }}
                                            >
                                                GSTR-2B is a statement which has been generated on the basis of the information furnished by your suppliers in their respective FORMS GSTR-1/IFF,5 and 6. It is a static statement and will be made available once a month. The documents fled by the supplier in any FORMS GSTR-1/IFF,5 and 6 would refect in the next open FORM GSTR-2B of the recipient irrespective of supplier’s date of fling. Taxpayers are advised to refer FORM GSTR-2B for availing credit in FORM GSTR-3B. However, in case for additional details, they may refer to their respective FORM GSTR-2A (which is updated on near real time basis) for more details.
                                            </p>
                                        </li>
                                        <li data-list-text="b.">
                                            <p
                                                className={styles.s9}
                                                style={{
                                                    paddingLeft: "14pt",
                                                    textIndent: "-10pt",
                                                    textAlign: "left"
                                                }}
                                            >
                                                Input tax credit shall be indicated to be non-available in the following scenarios: -
                                            </p>
                                            <ol className={styles.l5}>
                                                <li data-list-text="i.">
                                                    <p
                                                        className={styles.s9}
                                                        style={{
                                                            paddingTop: "4pt",
                                                            paddingLeft: "25pt",
                                                            textIndent: "-10pt",
                                                            lineHeight: "136%",
                                                            textAlign: "left"
                                                        }}
                                                    >
                                                        Invoice or debit note for supply of goods or services or both where the recipient is not entitled to input tax credit as per the provisions of sub-section (4) of Section 16 of CGST Act, 2017.
                                                    </p>
                                                </li>
                                                <li data-list-text="ii.">
                                                    <p
                                                        className={styles.s9}
                                                        style={{
                                                            paddingLeft: "25pt",
                                                            textIndent: "-10pt",
                                                            textAlign: "left"
                                                        }}
                                                    >
                                                        Invoice or debit note where the Supplier (GSTIN) and place of supply are in the same State while recipient is in another State.
                                                    </p>
                                                </li>
                                            </ol>
                                        </li>
                                    </ol>
                                    <p
                                        className={styles.s9}
                                        style={{
                                            paddingTop: "8pt",
                                            paddingLeft: "4pt",
                                            textIndent: "0pt",
                                            lineHeight: "136%",
                                            textAlign: "left"
                                        }}
                                    >
                                        However, there may be other scenarios for which input tax credit may not be available to the taxpayers and the same has not been generated by the system. Taxpayers, should self-assess and reverse such credit in their FORM GSTR-3B.
                                    </p>
                                    <p style={{ textIndent: "0pt", textAlign: "left" }}></p>
                                </div>
                            </p>
                        </li>
                        <li data-list-text={3}>
                            <p style={{ paddingTop: "4pt", paddingLeft: "22pt", textIndent: "-18pt", lineHeight: "136%", textAlign: "left" }} >
                                It may be noted that GSTR-2B will consist of all the GSTR-1/IFFs, 5s and 6s being fled by your respective supplier. Generally, this date will be between fling date of GSTR-1/IFF for previous month (M-1) to fling date of GSTR-1/IFF for the current month (M). For example, GSTR-2B for the month of February will consist of all the documents fled by suppliers in their GSTR-1/IFF, 5 and 6 from 00:00 hours on 12th February to 23:59 hours on 11th March. It may be
                            </p>
                        </li>
                    </ol>
                </li>
            </ol>

            <p style={{ pageBreakAfter: 'always' }}>&nbsp;</p>
            <div className='fixed w-[1080px] h-[764px] flex flex-col items-center justify-center opacity-20 pointer-events-none' style={{ zIndex: 9999 }}>
                <img src={Logo} alt="ITAA" className='w-[800] mx-auto h-[227px] pointer-events-none' style={{ transform: "rotate(-45deg)" }} />
            </div>
            <div style={{ marginTop: "36pt" }}></div>
            <p style={{ marginLeft: '28pt', marginRight: '20pt', paddingTop: "3pt", paddingLeft: "22pt", textIndent: "0pt", lineHeight: "136%", textAlign: "left" }} >
                noted that for import of goods, the data is being updated on real time basis, therefore, imports made in the month (month for which GSTR-2B is being generated for) shall be made available. The dates for which the relevant data has been extracted is available under the “View Advisory” tab on the online portal
            </p>
            <ol className={styles.l6} style={{ marginLeft: '28pt', marginRight: '20pt' }}>
                <li data-list-text={4}>
                    <p style={{ paddingLeft: "22pt", textIndent: "-18pt", textAlign: "left" }}      >
                        It also contains information on imports of goods from the ICEGATE system including data on imports from Special Economic Zones Units / Developers.
                    </p>
                </li>
                <li data-list-text={5}>
                    <p style={{ paddingTop: "4pt", paddingLeft: "22pt", textIndent: "-18pt", lineHeight: "136%", textAlign: "left" }}      >
                        It may be noted that reverse charge credit on import of services is not part of this statement and will be continued to be entered by taxpayers in Table 4(A)(2) of FORM GSTR-3B.
                    </p>
                </li>
                <li data-list-text={6}>
                    <p style={{ paddingLeft: "22pt", textIndent: "-18pt", textAlign: "left" }} >
                        Table 3 captures the summary of ITC available as on the date of generation of FORM GSTR-2B. It is divided into following two parts:
                    </p>
                    <ol className={styles.l7}>
                        <li data-list-text="A.">
                            <p style={{ paddingTop: "4pt", paddingLeft: "35pt", textIndent: "-12pt", textAlign: "left" }} >
                                Part A captures the summary of credit that may be availed in relevant tables of FORM GSTR-3B.
                            </p>
                        </li>
                        <li data-list-text="B.">
                            <p style={{ paddingTop: "4pt", paddingLeft: "35pt", textIndent: "-12pt", textAlign: "left" }} >
                                Part B captures the summary of credit that shall be net-off from relevant table of FORM GSTR-3B.
                            </p>
                        </li>
                    </ol>
                </li>
                <li data-list-text={7}>
                    <p style={{ paddingTop: "4pt", paddingLeft: "22pt", textIndent: "-18pt", lineHeight: "136%", textAlign: "left" }} >
                        Table 4 captures the summary of ITC not available as on the date of generation of FORM GSTR-2B. Credit available in this table shall not be availed as credit in FORM GSTR-3B but to be reported as ineligible ITC in Table 4(D)(2) of FORM GSTR-3B. However, the liability to pay tax on reverse charge basis and the liability to net-off credit on receipt of credit notes continues for such supplies.
                    </p>
                </li>
            </ol>
            <ol className={styles.l9} style={{ marginLeft: '28pt', marginRight: '20pt' }}>
                <li data-list-text="8.">
                    <p style={{ paddingLeft: '22pt', textIndent: '-18pt', lineHeight: '136%', textAlign: 'left' }}>Taxpayers are advised to ensure that the data generated in FORM GSTR-2B is reconciled with their own records and books of accounts. Tax payers shall ensure that</p>
                    <ol className={styles.l8}>
                        <li data-list-text="a.">
                            <p style={{ paddingLeft: '34pt', textIndent: '-11pt', textAlign: 'left' }}>No credit shall be taken twice for any document under any circumstances.</p>
                        </li>
                        <li data-list-text="b.">
                            <p style={{ paddingTop: '4pt', paddingLeft: '34pt', textIndent: '-11pt', textAlign: 'left' }}>Credit shall be reversed wherever necessary.</p>
                        </li>
                        <li data-list-text="c.">
                            <p style={{ paddingLeft: '4pt', paddingLeft: '34pt', textIndent: '-11pt', textAlign: 'left' }}>Tax on reverse charge basis shall be paid.</p>
                        </li>
                    </ol>
                </li>
                <li data-list-text="9.">
                    <p style={{ paddingTop: '4pt', paddingLeft: '22pt', textIndent: '-18pt', lineHeight: '136%', textAlign: 'left' }}>Details of invoices, credit notes, debit notes, ISD invoices, ISD credit and debit notes, bill of entries etc. will also be made available online and through download facility.</p>
                </li>
                <li data-list-text="10.">
                    <p style={{ paddingLeft: '22pt', textIndent: '-18pt', lineHeight: '136%', textAlign: 'left' }}>There may be scenarios where a percentage of the applicable rate of tax rate may be notifed by the Government. A separate column will be provided for invoices / documents where such rate is applicable.</p>
                </li>
                <li data-list-text="11.">
                    <p style={{ paddingLeft: '22pt', textIndent: '-18pt', textAlign: 'left' }}>Table wise instructions:</p>
                </li>
            </ol>
            <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
            <table style={{ borderCollapse: 'collapse', marginLeft: '36pt', marginRight: '20pt', display: 'table' }} cellspacing="0">
                <tr style={{ height: '20pt' }}>
                    <td style={{ width: '190pt', border: 'solid 1pt' }}>
                        <p className={styles.s11} style={{ paddingTop: '1pt', paddingLeft: '44pt', textIndent: '0pt', textAlign: 'left', textDecoration: 'underline' }}>Table No. and Heading</p>
                    </td>
                    <td style={{ width: '553pt', border: 'solid 1pt' }}>
                        <p className={styles.s11} style={{ paddingTop: '1pt', paddingLeft: '244pt', paddingRight: '243pt', textIndent: '0pt', textAlign: 'center', textDecoration: 'underline' }}>Instructions</p>
                    </td>
                </tr>
                <tr style={{ height: '84pt' }}>
                    <td style={{ width: '190pt', border: 'solid 1pt', verticalAlign: 'top' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingLeft: '4pt', textIndent: '0pt', textAlign: 'left' }}>Table 3 Part A Section I</p>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingLeft: '4pt', textIndent: '0pt', lineHeight: '136%', textAlign: 'left' }}>All other ITC - Supplies from registered persons other than reverse charge</p>
                    </td>
                    <td style={{ width: '553pt', border: 'solid 1pt' }}>
                        <ol className={styles.l10}>
                            <li data-list-text="i.">
                                <p className={styles.s4} style={{ paddingTop: '1pt', paddingLeft: '16pt', paddingRight: '21pt', textIndent: '-12pt', lineHeight: '136%', textAlign: 'left' }}>This section consists of the details of supplies (other than those on which tax is to be paid on reverse charge basis), which have been declared and fled by your suppliers in their FORM GSTR-1/IFF and 5.</p>
                            </li>
                            <li data-list-text="ii.">
                                <p className={styles.s4} style={{ paddingLeft: '16pt', textIndent: '-12pt', textAlign: 'left' }}>This table displays only the supplies on which input tax credit is available.</p>
                            </li>
                            <li data-list-text="iii.">
                                <p className={styles.s4} style={{ paddingTop: '4pt', paddingLeft: '16pt', paddingRight: '14pt', textIndent: '-12pt', lineHeight: '136%', textAlign: 'left' }}>ative credit, if any may arise due to amendment in B2B– Invoices and B2B – Debit notes. Such credit shall be netoff in Table 4(A)(5) of FORM GSTR-3B</p>
                            </li>
                        </ol>
                    </td>
                </tr>
                <tr style={{ height: '20pt' }}>
                    <td style={{ width: '190pt', border: 'solid 1pt', verticalAlign: 'top' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingLeft: '4pt', textIndent: '0pt', textAlign: 'left' }}>Table 3 Part A Section II</p>
                    </td>
                    <td style={{ width: '553pt', border: 'solid 1pt' }}>
                        <ol className={styles.l11}>
                            <li data-list-text="i.">
                                <p className={styles.s4} style={{ paddingTop: '1pt', paddingLeft: '16pt', textIndent: '-12pt', textAlign: 'left' }}>This section consists of the details of supplies, which have been declared and fled by an input service distributor in</p>
                            </li>
                        </ol>
                    </td>
                </tr>
            </table>
            <p style={{ pageBreakAfter: 'always' }}>&nbsp;</p>
            <div className='fixed w-[1080px] h-[764px] flex flex-col items-center justify-center opacity-20 pointer-events-none' style={{ zIndex: 9999 }}>
                <img src={Logo} alt="ITAA" className='w-[800] mx-auto h-[227px] pointer-events-none' style={{ transform: "rotate(-45deg)" }} />
            </div>
            <table style={{ borderCollapse: 'collapse', marginLeft: '36pt', marginRight: '20pt', marginTop: '36pt', display: 'table' }} cellspacing="0">
                <tr style={{ height: '68pt' }}>
                    <td style={{ width: '190pt', border: 'solid 1pt', verticalAlign: 'top' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingLeft: '4pt', textIndent: '0pt', textAlign: 'left' }}>Inward Supplies from ISD</p>
                    </td>
                    <td style={{ width: '553pt', border: 'solid 1pt' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingLeft: '16pt', textIndent: '0pt', textAlign: 'left' }}>their FORM GSTR-6.</p>
                        <ol className={styles.l12}>
                            <li data-list-text="ii.">
                                <p className={styles.s4} style={{ paddingTop: '4pt', paddingLeft: '16pt', textIndent: '-12pt', textAlign: 'left' }}>This table displays only the supplies on which ITC is available.</p>
                            </li>
                            <li data-list-text="iii.">
                                <p className={styles.s4} style={{ paddingTop: '4pt', paddingLeft: '16pt', paddingRight: '8pt', textIndent: '-12pt', lineHeight: '136%', textAlign: 'left' }}>Negative credit, if any, may arise due to amendment in ISD Amendments – Invoices. Such credit shall be net-off in table 4(A)(4) of FORM GSTR-3B.</p>
                            </li>
                        </ol>
                    </td>
                </tr>
                <tr style={{ height: '115pt' }}>
                    <td style={{ width: '190pt', border: 'solid 1pt', verticalAlign: 'top' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingLeft: '4pt', textIndent: '0pt', textAlign: 'left' }}>Table 3 Part A Section III</p>
                        <p className={styles.s4} style={{ paddingTop: '4pt', paddingLeft: '4pt', textIndent: '0pt', textAlign: 'left' }}>Inward Supplies liable for reverse charge</p>
                    </td>
                    <td style={{ width: '553pt', border: 'solid 1pt' }}>
                        <ol className={styles.l13}>
                            <li data-list-text="i.">
                                <p className={styles.s4} style={{ paddingTop: '1pt', paddingLeft: '16pt', paddingRight: '27pt', textIndent: '-12pt', lineHeight: '136%', textAlign: 'left' }}>This section consists of the details of supplies on which tax is to be paid on reverse charge basis, which have been declared and fled by your suppliers in their FORM GSTR-1/IFF</p>
                            </li>
                            <li data-list-text="ii.">
                                <p className={styles.s4} style={{ paddingLeft: '16pt', textIndent: '-12pt', textAlign: 'left' }}>This table provides only the supplies on which ITC is available</p>
                            </li>
                            <li data-list-text="iii.">
                                <p className={styles.s4} style={{ paddingTop: '4pt', paddingLeft: '16pt', paddingRight: '28pt', textIndent: '-12pt', lineHeight: '136%', textAlign: 'left' }}>These supplies shall be declared in Table 3.1(d) of FORM GSTR-3B for payment of tax. Credit may be availed under Table 4(A)(3) of FORM GSTR-3B on payment of tax.</p>
                            </li>
                            <li data-list-text="iii.">
                                <p className={styles.s4} style={{ paddingTop: '4pt', paddingLeft: '16pt', paddingRight: '28pt', textIndent: '-12pt', lineHeight: '136%', textAlign: 'left' }}>Negative credit, if any, may arise due to amendment in B2B - Invoices (Reverse Charge) and B2B - Debit notes (Reverse Charge). Such credit shall be net-off in Table 4(A)(3) of FORM GSTR-3B.</p>
                            </li>
                        </ol>
                    </td>
                </tr>
                <tr style={{ height: '131pt' }}>
                    <td style={{ width: '190pt', border: 'solid 1pt', verticalAlign: 'top' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingLeft: '4pt', paddingRight: '54pt', textIndent: '0pt', lineHeight: '136%', textAlign: 'left' }}>Table 3 Part A Section IV Import of Goods</p>
                    </td>
                    <td style={{ width: '553pt', border: 'solid 1pt', verticalAlign: 'top' }}>
                        <ol className={styles.l14}>
                            <li data-list-text="i.">
                                <p className={styles.s4} style={{ paddingTop: '1pt', paddingLeft: '16pt', paddingRight: '14pt', textIndent: '-12pt', lineHeight: '136%', textAlign: 'left' }}>This section provides the details of IGST paid by you on import of goods from overseas and SEZ units / developers on bill of entry and amendment thereof. These details are updated on near real time basis from the ICEGATE system.</p>
                            </li>
                            <li data-list-text="ii.">
                                <p className={styles.s4} style={{ paddingLeft: '16pt', paddingRight: '6pt', textIndent: '-12pt', lineHeight: '136%', textAlign: 'left' }}>This table shall consist of data on the imports made by you (GSTIN) in the month for which GSTR-2B is being generated for.</p>
                            </li>
                            <li data-list-text="iii.">
                                <p className={styles.s4} style={{ paddingLeft: '4pt', paddingRight: '102pt', textIndent: '0pt', lineHeight: '136%', textAlign: 'left' }}>The ICEGATE reference date is the date from which the recipient is eligible to take input tax credit.</p>
                            </li>

                            <li data-list-text="iii.">
                                <p className={styles.s4} style={{ paddingLeft: '4pt', paddingRight: '102pt', textIndent: '0pt', lineHeight: '136%', textAlign: 'left' }}>The table also provides if the Bill of entry was amended.</p>
                            </li>
                            <li data-list-text="iii.">
                                <p className={styles.s4} style={{ paddingLeft: '4pt', paddingRight: '102pt', textIndent: '0pt', lineHeight: '136%', textAlign: 'left' }}> Information is provided in the tables based on data received from ICEGATE. Information on certain imports such as courier imports may not be available.</p>
                            </li>
                        </ol>
                    </td>
                </tr>
                <tr style={{ height: '52pt' }}>
                    <td style={{ width: '190pt', border: 'solid 1pt', verticalAlign: 'top' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingLeft: '4pt', paddingRight: '54pt', textIndent: '0pt', lineHeight: '136%', textAlign: 'left' }}>Table 3 Part B Section I Others</p>
                    </td>
                    <td style={{ width: '553pt', border: 'solid 1pt' }}>
                        <ol className={styles.l15}>
                            <li data-list-text="i.">
                                <p className={styles.s4} style={{ paddingTop: '1pt', paddingLeft: '15pt', paddingRight: '8pt', textIndent: '-10pt', lineHeight: '136%', textAlign: 'left' }}>This section consists of the details of credit notes received and amendment thereof which have been declared and fled by your suppliers in their FORM GSTR-1/IFF and 5</p>
                            </li>
                            <li data-list-text="ii.">
                                <p className={styles.s4} style={{ paddingLeft: '15pt', textIndent: '-10pt', textAlign: 'left' }}>These credit shall be net-off from relevant ITC available Tables [Table 4A(3,4,5)] of FORM GSTR-3B.</p>
                            </li>
                        </ol>
                    </td>
                </tr>
                <tr style={{ height: '84pt' }}>
                    <td style={{ width: '190pt', border: 'solid 1pt', verticalAlign: 'top' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingLeft: '4pt', textIndent: '0pt', textAlign: 'left' }}>Table 4 Part A Section I</p>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingLeft: '4pt', textIndent: '0pt', lineHeight: '136%', textAlign: 'left' }}>All other ITC - Supplies from registered persons other than reverse charge</p>
                    </td>
                    <td style={{ width: '553pt', border: 'solid 1pt' }}>
                        <ol className={styles.l16}>
                            <li data-list-text="i.">
                                <p className={styles.s4} style={{ paddingTop: '1pt', paddingLeft: '16pt', paddingRight: '21pt', textIndent: '-12pt', lineHeight: '136%', textAlign: 'left' }}>This section consists of the details of supplies (other than those on which tax is to be paid on reverse charge basis), which have been declared and fled by your suppliers in their FORM GSTR-1/IFF and 5.</p>
                            </li>
                            <li data-list-text="ii.">
                                <p className={styles.s4} style={{ paddingLeft: '16pt', textIndent: '-12pt', textAlign: 'left' }}>This table provides only the supplies on which ITC is not available.</p>
                            </li>
                            <li data-list-text="iii.">
                                <p className={styles.s4} style={{ paddingTop: '4pt', paddingLeft: '16pt', paddingRight: '3pt', textIndent: '-12pt', lineHeight: '136%', textAlign: 'left' }}>Such credit shall not be taken in FORM GSTR-3B. However, such credit shall be reported as ineligible ITC in Table 4(D)(2) of form GSTR-3B.</p>
                            </li>
                        </ol>
                    </td>
                </tr>
                <tr style={{ height: '52pt' }}>
                    <td style={{ width: '190pt', border: 'solid 1pt', verticalAlign: 'top' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingLeft: '4pt', paddingRight: '54pt', textIndent: '0pt', lineHeight: '136%', textAlign: 'left' }}>Table 4 Part A Section II <br />Inward Supplies from ISD</p>
                    </td>
                    <td style={{ width: '553pt', border: 'solid 1pt' }}>
                        <ol className={styles.l17}>
                            <li data-list-text="i.">
                                <p className={styles.s4} style={{ paddingTop: '1pt', paddingLeft: '16pt', paddingRight: '14pt', textIndent: '-12pt', lineHeight: '136%', textAlign: 'left' }}>This section consists of the details supplies, which have been declared and fled by an input service distributor in their FORM GSTR-6.</p>
                            </li>
                            <li data-list-text="ii.">
                                <p className={styles.s4} style={{ paddingLeft: '16pt', textIndent: '-12pt', textAlign: 'left' }}>This table provides only the supplies on which ITC is not available.</p>
                            </li>
                        </ol>
                    </td>
                </tr>
            </table>

            <p style={{ pageBreakAfter: 'always' }}>&nbsp;</p>
            <div className='fixed w-[1080px] h-[764px] flex flex-col items-center justify-center opacity-20 pointer-events-none' style={{ zIndex: 9999 }}>
                <img src={Logo} alt="ITAA" className='w-[800] mx-auto h-[227px] pointer-events-none' style={{ transform: "rotate(-45deg)" }} />
            </div>
            <p style={{ textIndent: '0pt', textAlign: 'left' }} />
            <table style={{ borderCollapse: 'collapse', marginLeft: '36pt', marginRight: '20pt', marginTop: '36pt', display: 'table' }} cellspacing="0">
                <tr style={{ height: '36pt' }}>
                    <td style={{ width: '190pt', border: 'solid 1pt', verticalAlign: 'top' }}>
                        <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                    </td>
                    <td style={{ width: '553pt', border: 'solid 1pt' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingLeft: '16pt', textIndent: '0pt', lineHeight: '136%', textAlign: 'left' }}>iii. Such credit shall not be taken in FORM GSTR-3B. However, such credit shall be reported as ineligible ITC in Table 4(D)(2) of form GSTR-3B.</p>
                    </td>
                </tr>
                <tr style={{ height: '99pt' }}>
                    <td style={{ width: '190pt', border: 'solid 1pt', verticalAlign: 'top' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingLeft: '4pt', textIndent: '0pt', textAlign: 'left' }}>Table 4 Part A Section III</p>
                        <p className={styles.s4} style={{ paddingTop: '4pt', paddingLeft: '4pt', textIndent: '0pt', textAlign: 'left' }}>Inward Supplies liable for reverse charge</p>
                    </td>
                    <td style={{ width: '553pt', border: 'solid 1pt' }}>
                        <ol className={styles.l18}>
                            <li data-list-text="i.">
                                <p className={styles.s4} style={{ paddingTop: '1pt', paddingLeft: '16pt', paddingRight: '26pt', textIndent: '-12pt', lineHeight: '136%', textAlign: 'left' }}>This section consists of the details of supplies liable for reverse charge, which have been declared and fled by your suppliers in their FORM GSTR-1/IFF.</p>
                            </li>
                            <li data-list-text="ii.">
                                <p className={styles.s4} style={{ paddingLeft: '16pt', textIndent: '-12pt', textAlign: 'left' }}>This table provides only the supplies on which ITC is not available.</p>
                            </li>
                            <li data-list-text="iii.">
                                <p className={styles.s4} style={{ paddingTop: '4pt', paddingLeft: '16pt', paddingRight: '35pt', textIndent: '-12pt', lineHeight: '136%', textAlign: 'left' }}>These supplies shall be declared in Table 3.1(d) of FORM GSTR-3B for payment of tax. However, credit will not be available on such supplies.</p>
                            </li>
                            <li data-list-text="iV.">
                                <p className={styles.s4} style={{ paddingLeft: '4pt', textIndent: '0pt', textAlign: 'left' }}>Such credit shall be reported as ineligible ITC in Table 4(D)(2) of form GSTR-3B</p>
                            </li>
                        </ol>
                    </td>
                </tr>
                <tr style={{ height: '68pt' }}>
                    <td style={{ width: '190pt', border: 'solid 1pt', verticalAlign: 'top' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingLeft: '4pt', paddingRight: '54pt', textIndent: '0pt', lineHeight: '136%', textAlign: 'left' }}>Table 4 Part B Section I <br />Others</p>
                    </td>
                    <td style={{ width: '553pt', border: 'solid 1pt' }}>
                        <ol className={styles.l19}>
                            <li data-list-text="i.">
                                <p className={styles.s4} style={{ paddingTop: '1pt', paddingLeft: '16pt', paddingRight: '16pt', textIndent: '-12pt', lineHeight: '136%', textAlign: 'left' }}>This section consists details the credit notes received and amendment thereof which have been declared and fled by your suppliers in their FORM GSTR-1/IFF and 5</p>
                            </li>
                            <li data-list-text="ii.">
                                <p className={styles.s4} style={{ paddingLeft: '16pt', textIndent: '-12pt', textAlign: 'left' }}>This table provides only the credit notes on which ITC is not available.</p>
                            </li>
                            <li data-list-text="iii.">
                                <p className={styles.s4} style={{ paddingTop: '4pt', paddingLeft: '16pt', textIndent: '-12pt', textAlign: 'left' }}>Such credit shall be net-off from relevant ITC available tables [Table 4A(3,4,5)] of form GSTR-3B.</p>
                            </li>
                        </ol>
                    </td>
                </tr>
            </table>
            <div style={{ height: '280pt' }}></div>
            <p style={{ textIndent: '0pt', textAlign: 'left' }} />
        </div>
    );
}
