import React, { useState, useEffect } from 'react';

import { useRecoilState } from 'recoil';
import { pathAtom, gstrpathAtom, digErrorAtom, returnDateAtom, loadingAtom, gstrUserAtom, gstrQueryAtom, gstrFileStatusAtom, userIDAtom } from '../../../core/config/atoms';

import { Dialog } from '@headlessui/react';

import '../css/main.css';
import '../css/style.css';

import ServiceGSTR from '../../../services/serviceGSTR';
import ServiceUtility from '../../../services/serviceUtility';

import imgLoader from '../../../assets/images/cloading.gif';

export default function PanelA11Home() {

    const [path, setPath] = useRecoilState(pathAtom);
    const [gstrPath, setGSTRPath] = useRecoilState(gstrpathAtom);
    const [digError, setDigError] = useRecoilState(digErrorAtom);
    const [gDate] = useRecoilState(returnDateAtom);
    const [loading, setLoading] = useRecoilState(loadingAtom);
    const [GSTUser] = useRecoilState(gstrUserAtom);
    const [query, setQuery] = useRecoilState(gstrQueryAtom);
    const [fileStatus] = useRecoilState(gstrFileStatusAtom);
    const [userID] = useRecoilState(userIDAtom);

    const [records, setRecords] = useState([]);

    const [digConfirm, setDigConfirm] = useState({ 'type': '0', isToggle: false, data: null });

    const [isAlert, setIsAlrt] = useState(false);

    const returnService = new ServiceGSTR();
    const utilService = new ServiceUtility();

    const gotoPath = (path) => {
        setLoading(true);
        setPath(path);
    }

    const gotoGstrPath = (path) => {
        setGSTRPath(path);
        gotoPath('gstrFile');
    }

    const editInvoice = (id) => {
        setQuery({ type: 'edit', data: id });
        gotoGstrPath('a11b');
    }

    const showDig = (type, data) => {
        setDigConfirm({ 'type': type, isToggle: true, data: data });
    }

    const cancel = () => {
        setDigConfirm({ 'type': '0', isToggle: false, data: null });
    }

    const process = () => {
        switch (digConfirm.type) {
            case '1':
                deleteInvoice(digConfirm.data.id, digConfirm.data.status);
                break;
            default:
                break;
        }
        setDigConfirm({ 'type': '0', isToggle: false, data: null });
    }

    const deleteInvoice = (id, status) => {
        setLoading(true);
        let body = {
            lid: userID,
            type: 'a11',
            status: status,
            month: gDate,
            id: id
        }
        returnService.deleteGstrDoc(body).then((res) => {
            setLoading(false);
            showAlert();
            loadData();
        });
    }

    const showAlert = () => {
        setIsAlrt(true);
        setTimeout(
            () => setIsAlrt(false),
            5000
        );
    }

    const getSelectionRecords = () => {
        let rows = [];
        records.forEach((row) => {
            let s = 'processed';
            if (s === row.status) {
                let tax = utilService.getTotalValue(row.data.items).toFixed(2);
                let igst = (GSTUser.state === row.data.pos ? 0 : utilService.getTotalI(row.data.items)).toFixed(2);
                let cgst = (GSTUser.state === row.data.pos ? utilService.getTotalC(row.data.items) : 0).toFixed(2);
                let sgst = (GSTUser.state === row.data.pos ? utilService.getTotalS(row.data.items) : 0).toFixed(2);
                let cess = utilService.getTotalCess(row.data.items).toFixed(2);
                let action = row.data.status;
                rows.push({ id: row._id, pos: row.data.pos, supply: row.data.supplyty, tax: tax, igst: igst, cgst: cgst, sgst: sgst, cess: cess, action: action });
            }
        });
        return rows;
    }

    const loadData = () => {
        setLoading(true);
        (async function () {
            let body = {
                lid: userID,
                month: gDate,
                type: 'a11'
            }
            var res = await returnService.getGstrDocs(body);
            setRecords(res.data);
            setLoading(false);
            window.scrollTo({
                top: 0,
                behavior: 'smooth',
            });
        })();
    }

    useEffect(() => {
        loadData();
    }, []);

    return (
        <div>
            <Dialog open={digConfirm.isToggle} onClose={() => setDigConfirm({ 'type': '0', isToggle: false, data: null })}>
                <div id="confirmDlg" class="modal fade fade-scale in" role="dialog" style={{ display: 'block', paddingRight: '17px' }}>
                    <div class="modal-dialog sweet">
                        <div class="modal-content">
                            <div class="modal-body">
                                <div class="m-icon m-warning pulseWarning">
                                    <span class="micon-body pulseWarningIns"></span>
                                    <span class="micon-dot pulseWarningIns"></span>
                                </div>
                                <h2>Warning</h2>
                                <p>Are you sure, you want to continue?</p>
                            </div>
                            <div class="modal-footer">
                                <a class="btn btn-default" onClick={() => { cancel() }}>Cancel</a>
                                <a class="btn btn-primary" onClick={() => { process() }}>Proceed</a>
                            </div>
                        </div>
                    </div>
                </div>
            </Dialog>
            <div class="content-wrapper fade-ng-cloak">
                <div class="dimmer-holder" style={{ display: 'none' }}>
                    <div id="dimmer"></div>
                </div>
                {
                    loading && <div className='absolute z-50 w-full h-screen flex items-center justify-center bg-gray-200 bg-opacity-25'>
                        <img src={imgLoader} alt="loader" />
                    </div>
                }
                <div class="container">
                    <div class="mypage">
                        <div class="row" data-ng-controller="transctrl" data-ng-init="init('returns')">
                            <div class="col-xs-10">
                                <div name="Dashboard">
                                    <ol class="breadcrumb" data-ng-controller="crumbCtrl">
                                        <li onClick={() => {
                                            gotoPath('gstr')
                                        }}><a>Dashboard</a></li>
                                        <li onClick={() => {
                                            gotoPath('gstRetrun')
                                        }}><a>Returns</a></li>
                                        <li onClick={() => {
                                            gotoPath('gstrForm')
                                        }}><a>GSTR-1/IFF</a></li>
                                        <li>Tax Liability (Advances Received)</li>
                                    </ol>
                                </div>
                            </div>
                            <div class="col-xs-2">
                                <div class="lang dropdown">
                                    <span class="dropdown-toggle" data-toggle="dropdown" data-ng-bind="selectedLang">English</span>
                                    <ul class="dropdown-menu lang-dpdwn">
                                        <li data-ng-repeat="language in languages">
                                            <a data-ng-click="selectLang(language.cd)" data-ng-bind="language.nm">English</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div class="container">
                                {isAlert && <alert-message ng-show="showMsg" title="" type="msg alert-success" message="" class="ng-hide"><div class="alert alert-msg alert-success">Request accepted successfully<a class="close" onClick={() => { setIsAlrt(false) }} data-dismiss="alert" aria-label="close">×</a><strong></strong>.</div></alert-message>}
                                <div class="row invsumm">
                                    <div class="col-xs-12 col-sm-12 taxp">
                                        <h4 data-ng-bind="trans.TITLE_TAX_LIABILITY">11A(1), 11A(2) - Tax Liability (Advances Received)</h4><button class="btn btn-primary btn-circle btn-sm pull-right" data-toggle="tooltip" title="Refresh" data-ng-click="refresh()" data-ng-disabled="refbtn" style={{ marginTop: '0px' }} disabled="disabled"><i class="fa fa-refresh" aria-hidden="true"></i></button>
                                    </div>
                                </div>
                                <div class="tabpane">
                                    {
                                        records.length !== 0 && <div class="btn-toolbar">
                                            <button type="button" class="btn btn-primary pull-left" onClick={() => { gotoGstrPath('a11b') }} disabled={fileStatus}>Add State wise Details</button>
                                        </div>
                                    }

                                    {records.length === 0 && <div class="alert alert-msg alert-info alert-dismissible" data-ng-show="firttimeflag" data-ng-bind="trans.ERR_NO_REC">There are no records to be displayed.</div>}

                                    {
                                        getSelectionRecords().length !== 0 && < div class="row" data-ng-if="b2binvdt.processedInvoice">
                                            <div class="col-sm-12">
                                                <h4 data-ng-bind="trans.LBL_PRO_RCD">Processed Records</h4>
                                            </div>
                                            <br />

                                            <div class="col-xs-12 col-sm-12">
                                                <div class="row">
                                                    <div class="col-xs-12">
                                                        <div class="col-lg-3 pull-right">
                                                            <div class="form-group pull-right" style={{ display: 'inline-flex' }}>
                                                                <div class="col-lg-9"><label class="form-label">Records Per Page : </label></div>
                                                                <div class="col-lg-3" style={{ paddingLeft: '0', marginLeft: '-0.7em' }}>
                                                                    <select class="form-control ng-pristine ng-untouched ng-valid ng-not-empty" style={{ minWidth: '5em', border: '1px solid' }} ng-model="obj.recPerPageProc" data-ng-change="changeRecordsPerpagePrs()" ng-options="item as item for item in recordsPerpageOptionsb2b" value="item">
                                                                        <option label="10" value="number:10" selected="selected">10</option>
                                                                        <option label="20" value="number:20">20</option>
                                                                        <option label="50" value="number:50">50</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="table-responsive">
                                                    <table class="table exp inv tbl table-bordered ng-table" style={{ display: 'table' }}>
                                                        <thead ng-include="templates.header">
                                                            <tr class="ng-table-sort-header">
                                                                <th title="" ng-repeat="$column in $columns" class="header sortable">
                                                                    <div class="ng-table-header">
                                                                        <span class="sort-indicator">Place of Supply<br />(Name of State)</span>
                                                                    </div>
                                                                </th>
                                                                <th title="" class="header ">
                                                                    <div>
                                                                        <span class="sort-indicator">Supply Type</span>
                                                                    </div>
                                                                </th>
                                                                <th title="" class="header ">
                                                                    <div>
                                                                        <span class="sort-indicator">Gross Advance Received (excluding tax) (₹)</span>
                                                                    </div>
                                                                </th>
                                                                <th title="" class="header ">
                                                                    <div>
                                                                        <span class="sort-indicator">Integrated Tax (₹)</span>
                                                                    </div>
                                                                </th>
                                                                <th title="" class="header ">
                                                                    <div>
                                                                        <span class="sort-indicator">Central tax (₹)</span>
                                                                    </div>
                                                                </th>
                                                                <th title="" class="header ">
                                                                    <div>
                                                                        <span class="sort-indicator">State/UT Tax (₹)</span>
                                                                    </div>
                                                                </th>
                                                                <th title="" class="header ">
                                                                    <div>
                                                                        <span class="sort-indicator">Cess (₹)</span>
                                                                    </div>
                                                                </th>
                                                                <th title="" class="header ">
                                                                    <div>
                                                                        <span class="sort-indicator">Applicable percentage (%)</span>
                                                                    </div>
                                                                </th>
                                                                <th title="" class="header ">
                                                                    <div>
                                                                        <span class="sort-indicator">Actions</span>
                                                                    </div>
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody data-ng-repeat="t in $data">
                                                            {
                                                                getSelectionRecords().map((row) => {
                                                                    return <tr>
                                                                        <td class="text-center">
                                                                            {row.pos}
                                                                        </td>
                                                                        <td class="text-center">
                                                                            {row.supply}
                                                                        </td>
                                                                        <td class="currency">{row.tax}</td>
                                                                        <td class="currency">{row.igst}</td>
                                                                        <td class="currency">{row.cgst}</td>
                                                                        <td class="currency">{row.sgst}</td>
                                                                        <td class="currency">{row.cess}</td>
                                                                        <td class="text-right">-</td>
                                                                        <td style={{ display: 'inline-block' }} class="text-center newinv" data-title-text="Actions">
                                                                            <div className='flex'>
                                                                                <button type="button" class="btn btn-sm btn-primary nomargin" onClick={() => {
                                                                                    editInvoice(row.id);
                                                                                }} disabled={fileStatus}><i class="fa fa-pencil" aria-hidden="true"></i><span class="sr-only">text</span>
                                                                                </button>&nbsp;
                                                                                <button type="button" class="btn btn-sm btn-danger nomargin" title="Delete" onClick={() => {
                                                                                    showDig('1', { id: row.id, status: 'processed' });
                                                                                }} disabled={fileStatus}>
                                                                                    <i class="fa fa-trash"></i><span class="sr-only">text</span>
                                                                                </button>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                })
                                                            }
                                                        </tbody>
                                                    </table>
                                                    <div ng-table-pagination="params" template-url="templates.pagination">
                                                        <div ng-include="templateUrl">
                                                            <div class="ng-table-pager" ng-if="params.data.length">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }


                                    <div class="btn-toolbar">
                                        {
                                            records.length === 0 && <button type="button" class="btn btn-primary pull-right" data-ng-bind="trans.LBL_ADDREC" style={{ marginLeft: '5px' }} onClick={() => {
                                                gotoGstrPath('a11b')
                                            }} disabled={fileStatus}>Add State wise Details</button>
                                        }
                                        <button type="button" class="btn btn-default pull-right" data-ng-bind="trans.LBL_BACK" onClick={() => {
                                            gotoPath('gstrForm')
                                        }}>Back</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
