import React from 'react';

import Moment from 'moment';

export default function UIFooter() {
    return (
        <>
            <div className="f2">
                <div className="container">
                    <div className="row">
                        <div className="col-xs-12">
                            <p>&copy; 2022 ITAA Education PVT. LTD.</p>
                            <p>Site Last Updated on {Moment(Date.now()).format('DD-MM-yyyy')}</p>
                            <p>Designed & Developed by FABDROID</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="f3">
                <div className="container">
                    <div className="row">
                        <div className="col-xs-12">
                            <p className="site">Site best viewed at 1024 x 768 resolution in Internet Explorer 10+, Google Chrome 49+, Firefox 45+ and Safari 6+</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
