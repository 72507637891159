import React, { useState, useEffect, useRef } from 'react';

import { useRecoilState } from 'recoil';
import { pathAtom, loadingAtom, gstrFileAtom, digErrorAtom } from '../../../core/config/atoms';

import { useFormik } from 'formik';
import * as Yup from 'yup';
import Scrollbars from 'react-custom-scrollbars';
import html2pdf from 'html2pdf.js/dist/html2pdf';

import ServiceGSTF from '../../../services/serviceGSTF';
import PanelSysGenPDF from '../PanelSysGenPDF';

export default function Panel51Home() {

    const [loading, setLoading] = useRecoilState(loadingAtom);
    const [gstrf, setGSTRF] = useRecoilState(gstrFileAtom);

    const [, setPath] = useRecoilState(pathAtom);
    const [, setDigError] = useRecoilState(digErrorAtom);

    const [submit, setSubmit] = useState(false);
    const [isHelp, setIsHelp] = useState(false);
    const [isCheck, setIsCheck] = useState(false);

    const [isPrint, setIsPrint] = useState(false);

    const pdfRef = useRef(null);

    const fileService = new ServiceGSTF();

    const formVSchema = Yup.object().shape({
        a: Yup.object().shape({
            igst: Yup.object().shape({
                value: Yup.string().required(),
                isChange: Yup.boolean().required(),
            }).required(),
            cgst: Yup.object().shape({
                value: Yup.string().required(),
                isChange: Yup.boolean().required(),
            }).required(),
            sgst: Yup.object().shape({
                value: Yup.string().required(),
                isChange: Yup.boolean().required(),
            }).required(),
            cess: Yup.object().shape({
                value: Yup.string().required(),
                isChange: Yup.boolean().required(),
            }).required(),
        }).required(),
        b: Yup.object().shape({
            igst: Yup.object().shape({
                value: Yup.string().required(),
                isChange: Yup.boolean().required(),
            }).required(),
            cgst: Yup.object().shape({
                value: Yup.string().required(),
                isChange: Yup.boolean().required(),
            }).required(),
        }).required(),
    });

    const { values, errors, touched, setValues, handleChange, handleSubmit, handleReset, resetForm } = useFormik({
        initialValues: {},
        validationSchema: formVSchema,
        onSubmit: values => {
            setLoading(true);
            setSubmit(true);
            var newObj = JSON.parse(JSON.stringify(gstrf.data));
            newObj.a51.user = { ...values };
            newObj.a51.isCheck = isCheck;
            var body = {
                id: gstrf._id,
                data: newObj
            };
            fileService.setGSTFDoc(body).then((res) => {
                setLoading(false);
                setSubmit(false);
                if (res.status) {
                    setGSTRF(res.data);
                    gotoPath('gstr3BForm');
                } else {
                    setDigError({ title: 'Error', msg: 'Server error please try again!', isToggle: true });
                    window.scrollTo({
                        top: 0,
                        behavior: 'smooth',
                    });
                }
            });
        }
    });

    const getFormatvalue = (val) => {
        if (!val) return val;
        let number = val.replace(/[^\d.]/g, "");
        if (val.includes(".")) {
            const a = number.split(".");
            if (a[1].length > 2) {
                a[1] = a[1].substring(0, 2);
            }
            if (a[0].length > 4) {
                a[0] = a[0].substring(0, 4);
            }
            number = a[0] + "." + a[1];
        } else {
            if (number.length > 4) {
                number = number.substring(0, 4);
            }
        }
        return number;
    }

    const gotoPath = (path) => {
        setPath(path);
    }

    const getDisable = () => {
        if (gstrf.status === "done" || gstrf.status === "submitted") {
            return true;
        } else {
            return !isCheck;
        }
    }

    const getDis = () => {
        if (gstrf.status === "done" || gstrf.status === "submitted") {
            return true;
        } else {
            return false;
        }
    }

    function convertValuesToFixedDecimal(obj) {
        const newObj = {};

        for (let key in obj) {
            const value = obj[key];

            if (typeof value === 'object' && value !== null) {
                newObj[key] = convertValuesToFixedDecimal(value);
            } else if (typeof value === 'number') {
                newObj[key] = value.toFixed(2);
            } else {
                newObj[key] = value;
            }
        }

        return newObj;
    }

    const loadData = () => {
        var refs = convertValuesToFixedDecimal(gstrf.data.a51.user);
        setValues({ ...values, ...refs });
        if (gstrf.status === "done" || gstrf.status === "submitted") {
            setSubmit(true);
            setIsCheck(gstrf.data.a51.isCheck);
        } else {
            setIsCheck(gstrf.data.a51.isCheck);
        }
        setLoading(false);
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    }

    const handleGeneratePDF = async () => {
        const subComponent = pdfRef.current;
        const options = {
            margin: 0.2,
            filename: `GSTR3B System Generated.pdf`,
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: { scale: 2 },
            jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
            preserve: true,
        };
        html2pdf().set(options).from(subComponent).save().then(() => {
            setIsPrint(false);
            window.scrollTo({
                top: 0,
                behavior: 'smooth',
            });
        });
    };

    useEffect(() => {
        loadData();
    }, []);

    return (
        <>
            {
                isHelp && <div className='w-full h-full overflow-hidden fixed top-0 z-50 flex items-center justify-center bg-black bg-opacity-60'>
                    <Scrollbars>
                        <div className="modal-dialog" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => { setIsHelp(false) }}>
                                        <span aria-hidden="true">×</span>
                                    </button>
                                    <h4 className='text-[#0b1e59d9] modal-title'>Interest and Late fee</h4>
                                </div>
                                <div className="modal-body">
                                    <p>Interest is payable on the delayed payment of taxes after the last date as well as for invoices/ debit notes declared in current tax period belonging to earlier tax period.</p>
                                    <p>Interest for both reverse charge as well as for forward charge related liabilities needs to be declared here. </p>
                                    <p data-ng-if="sysIntLive" data-ng-bind="trans.INTERESELATEFEE3B_133_1">Interest and Late fee are auto calculated by the system based on the date of filing and the due date for the return. There is no late fees payable for IGST and CESS and hence the same has been disabled.</p>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={() => { setIsHelp(false) }}>Close</button>
                                </div>
                            </div>
                        </div>
                    </Scrollbars>
                </div>
            }
            <div className="content-wrapper fade-ng-cloak">
                <div className="container">
                    <div className="mypage">
                        <div className="row">
                            <div className="col-xs-10">
                                <div>
                                    <ol className="breadcrumb" data-ng-controller="crumbCtrl">
                                        <li>
                                            <a onClick={() => { gotoPath('gstr') }}>Dashboard</a></li>
                                        <li>
                                            <a onClick={() => { gotoPath('gstRetrun') }}>Returns</a>
                                        </li>
                                        <li>
                                            <a onClick={() => {
                                                setLoading(true);
                                                gotoPath('gstr3BForm')
                                            }}>GSTR-3B</a>
                                        </li>
                                        <li>
                                            <span ng-switch-when="true">Interest and Late Fee</span>
                                        </li>
                                    </ol>
                                </div>
                            </div>
                            <div className="col-xs-2">
                                <div className="lang dropdown">
                                    <span className="dropdown-toggle" data-toggle="dropdown" data-ng-bind="selectedLang">English</span>
                                    <ul className="dropdown-menu lang-dpdwn">
                                        <li>
                                            <a data-ng-bind="language.nm">English</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        {
                            !loading && <div>
                                <form autocomplete="off" novalidate="" onSubmit={handleSubmit} onReset={handleReset}>
                                    <div className="container" data-ng-init="getInterestLateFeeData()">
                                        <div className="row invsumm">
                                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                <div className="pull-left">
                                                    <h4 data-ng-bind="sysIntLive ? trans.HEAD_INTEREST_LTFEE_TILE : trans.HEAD_INTEREST_LTFEE_TILE_OLD">5.1 Interest and Late fee for previous tax period</h4>
                                                </div>
                                                <div className="pull-right" data-ng-if="validLatefeePrd">
                                                    <span style={{ fontSize: '18px' }}><a data-toggle="modal" onClick={() => { setIsHelp(true) }}>Help</a>
                                                        &nbsp;<i className="fa fa-question-circle" aria-hidden="true"></i>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="tabpane">
                                            <div className="alert alert-msg alert-info alert-dismissible mar-t-5 mar-b-5" data-ng-if="!backflag">
                                                <button type="button" className="close" data-dismiss="alert" aria-hidden="true">
                                                    <i className="fa fa-times"></i>
                                                </button>
                                                <i className="fa fa-info-circle"></i> Declare interest payable on tax liabilities on supplies attracting reverse charge as well as other than reverse charge
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                    <div data-ng-if="validInterest">
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="inner">
                                                    <div className="col-sm-12 col-xs-12 col-md-12 col-lg-12">
                                                        <input type="checkbox" className="chkbx ng-pristine ng-untouched ng-valid ng-not-empty ng-valid-required" checked={isCheck} onChange={() => {
                                                            setIsCheck(!isCheck);
                                                        }} disabled={getDis()} />
                                                        <label for="intlatefee"><span data-ng-bind="trans.LBL_INTLATEFEE_INFO">Please select the check box if you wish to declare any Interest liabilities. Please note Interest amounts declared here under respective heads need to be paid in cash in addition to tax liabilities for the month. GSTR 3B can be filed only after complete payment of all liabilities.</span></label>
                                                        <br /><br />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                    <div className="rettbl-format">
                                                        <div className="table-responsive">
                                                            <table className="table tbl inv exp table-bordered" style={{ display: 'table' }}>
                                                                <thead>
                                                                    <tr>
                                                                        <th className="text-center" data-ng-bind="trans.LBL_DESCRIPTION">Description</th>
                                                                        <th className="text-center" data-ng-bind="trans.LBL_GST3B_ITAX">Integrated Tax (₹)</th>
                                                                        <th className="text-center" data-ng-bind="trans.LBL_GST3B_CTAX">Central Tax (₹)</th>
                                                                        <th className="text-center" data-ng-bind="trans.LBL_GST3B_STAX">State/UT Tax (₹)</th>
                                                                        <th className="text-center" data-ng-bind="trans.LBL_GST3B_CS">CESS (₹)</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr>
                                                                        <th>Interest</th>
                                                                        <td className=''>
                                                                            <input name="a.igst.value" id="a.igst.value" value={values.a.igst.value} onChange={e => {
                                                                                const formatValue = getFormatvalue(e.target.value);
                                                                                e.target.value = formatValue;
                                                                                handleChange(e);
                                                                            }} onBlur={e => {
                                                                                if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                    e.target.value = e.target.value + ".00";
                                                                                }
                                                                                handleChange(e);
                                                                            }} className="form-control formedit newinv currency" disabled={getDisable()} />
                                                                        </td>
                                                                        <td className=''>
                                                                            <input name="a.cgst.value" id="a.cgst.value" value={values.a.cgst.value} onChange={e => {
                                                                                const formatValue = getFormatvalue(e.target.value);
                                                                                e.target.value = formatValue;
                                                                                handleChange(e);
                                                                            }} onBlur={e => {
                                                                                if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                    e.target.value = e.target.value + ".00";
                                                                                }
                                                                                handleChange(e);
                                                                            }} className="form-control formedit newinv currency" disabled={getDisable()} />
                                                                        </td>
                                                                        <td className=''>
                                                                            <input name="a.sgst.value" id="a.sgst.value" value={values.a.sgst.value} onChange={e => {
                                                                                const formatValue = getFormatvalue(e.target.value);
                                                                                e.target.value = formatValue;
                                                                                handleChange(e);
                                                                            }} onBlur={e => {
                                                                                if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                    e.target.value = e.target.value + ".00";
                                                                                }
                                                                                handleChange(e);
                                                                            }} className="form-control formedit newinv currency" disabled={getDisable()} />
                                                                        </td>
                                                                        <td className=''>
                                                                            <input name="a.cess.value" id="a.cess.value" value={values.a.cess.value} onChange={e => {
                                                                                const formatValue = getFormatvalue(e.target.value);
                                                                                e.target.value = formatValue;
                                                                                handleChange(e);
                                                                            }} onBlur={e => {
                                                                                if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                    e.target.value = e.target.value + ".00";
                                                                                }
                                                                                handleChange(e);
                                                                            }} className="form-control formedit newinv currency" disabled={getDisable()} />
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th data-ng-if="lateHyperFlag">Late Fees</th>
                                                                        <td className="gstr3b-blank"></td>
                                                                        <td className=''>
                                                                            <input name="b.igst.value" id="b.igst.value" value={values.b.igst.value} onChange={e => {
                                                                                const formatValue = getFormatvalue(e.target.value);
                                                                                e.target.value = formatValue;
                                                                                handleChange(e);
                                                                            }} onBlur={e => {
                                                                                if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                    e.target.value = e.target.value + ".00";
                                                                                }
                                                                                handleChange(e);
                                                                            }} className="form-control formedit newinv currency" disabled={getDisable()} />
                                                                        </td>
                                                                        <td className=''>
                                                                            <input name="b.cgst.value" id="b.cgst.value" value={values.b.cgst.value} onChange={e => {
                                                                                const formatValue = getFormatvalue(e.target.value);
                                                                                e.target.value = formatValue;
                                                                                handleChange(e);
                                                                            }} onBlur={e => {
                                                                                if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                    e.target.value = e.target.value + ".00";
                                                                                }
                                                                                handleChange(e);
                                                                            }} className="form-control formedit newinv currency" disabled={getDisable()} />
                                                                        </td>
                                                                        <td className="gstr3b-blank"></td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="btn-toolbar" data-ng-if="sysIntLive">
                                                <button type="button" className="btn btn-primary pull-right" disabled={true}>RE-COMPUTE INTEREST</button>
                                                <button type="submit" className="btn btn-primary pull-right" disabled={submit}>CONFIRM</button>
                                                <button type="button" className="btn btn-primary pull-right" onClick={() => {
                                                    setLoading(true);
                                                    gotoPath('gstr3BForm')
                                                }}>CANCEL</button>
                                                <button type="button" className="btn btn-danger pull-right" data-toggle="tooltip" title="Click here to download GSTR-3B system generated PDF" onClick={() => {
                                                    setIsPrint(true);
                                                    handleGeneratePDF();
                                                }}>SYSTEM GENERATED GSTR-3B</button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        }
                    </div>
                </div>
            </div>
            <div ref={pdfRef}>
                {isPrint && <PanelSysGenPDF />}
            </div>
        </>
    )
}
