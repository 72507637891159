import React, { useState } from 'react';

import FeatherIcon from 'feather-icons-react';
import { motion, AnimatePresence } from "framer-motion";
import { useNavigate } from 'react-router-dom';

import { useRecoilState } from 'recoil';
import { adminAtom } from '../core/config/atoms';

import itaaLogo from '../assets/images/logoblue.png';
import ServiceLMS from '../services/serviceLMS';

export default function PageAdminLogin() {

    const [isAdmin, setIsAdmin] = useRecoilState(adminAtom);

    const [formData, setFormData] = useState({});

    const [isAlert, setIsAlert] = useState(false);

    const lmsService = new ServiceLMS();

    const navigate = useNavigate();

    const alertFade = {
        hidden: { opacity: 0 },
        show: { opacity: 1 }
    }

    const handleOnChangeForm = (e) => {
        var { value, name } = e.target;
        setFormData({ ...formData, [name]: value });
    }

    const handeleSubmit = (e) => {
        e.preventDefault();
        let body = {
            "username": formData.username,
            "password": formData.password
        }
        lmsService.adminLogin(body).then((res) => {
            if (res.status) {
                setIsAdmin(true);
            } else {
                e.target.reset()
                setIsAlert(true);
                setTimeout(() => {
                    setIsAlert(false);
                }, 3000);
            }
        });
    }

    const goToHome = () => {
        navigate('/');
    }

    return (
        <div className='w-full h-screen overflow-hidden bg-gray-50 flex items-center justify-center'>
            <AnimatePresence>
                <motion.div variants={alertFade}
                    initial="hidden"
                    animate={isAlert ? "show" : "hidden"}
                    transition={{ delay: 0.1 }}
                    className="rounded text-lg font-serif mt-10 mr-8 bg-yellow-100 shadow border border-red-700 h-14 flex flex-col justify-center" style={{ position: 'absolute', right: '20px', top: '20px' }}>
                    <p className="px-4 py-2 flex text-red-600 text-2xl m-0">
                        <FeatherIcon icon="bell" size={18} />&nbsp;&nbsp;
                        Invalid username or password.
                    </p>
                </motion.div>
            </AnimatePresence>
            <div className='bg-white shadow-xl border rounded-lg w-[400px] p-6 flex flex-col items-center'>
                <img src={itaaLogo} alt="ITAA Logo" className='w-60 mt-10' />
                <h1 className='text-3xl'>Admin - Sign In</h1>
                <form className='w-full flex flex-col items-center' id="loginForm" autocomplete="off" onSubmit={handeleSubmit}>
                    <input className="w-full bg-gray-50 h-14 outline-none border rounded px-4 py-1 mt-4" type="text" name="username" required="true" placeholder="Enter username" autocomplete="off" onChange={handleOnChangeForm} value={formData.username} />
                    <input className="w-full bg-gray-50 h-14 outline-none border rounded px-4 py-1 mt-6" type="password" name="password" required="true" placeholder="Enter password" autocomplete="off" onChange={handleOnChangeForm} value={formData.password} />
                    <div className='w-full flex justify-between items-center'>
                        <p className='m-0' onClick={() => goToHome()}><a>Go To Home</a></p>
                        <button type='submit' className='mt-6 w-32 rounded bg-blue-600 px-4 py-2 text-white float-right hover:bg-blue-800'>Login</button>
                    </div>
                </form>
            </div>
        </div>
    )
}
