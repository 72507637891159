import React, { useState, useEffect, useRef } from 'react';

import { useRecoilState } from 'recoil';
import { pathAtom, loadingAtom, gstrUserAtom, digErrorAtom, returnDateAtom, digDownloadAtom, userIDAtom, redirectAtom } from '../../core/config/atoms';

import Moment from 'moment';
import { saveAs } from 'file-saver';
import ExcelJS from 'exceljs';
import { Menu } from '@headlessui/react';
import FeatherIcon from 'feather-icons-react';
import { Dialog } from '@headlessui/react';
import html2pdf from 'html2pdf.js/dist/html2pdf';

import Tippy from '@tippyjs/react';

import 'tippy.js/dist/tippy.css';
import './css/style2b.css';

import ServiceLMS from '../../services/serviceLMS';
import ServiceGSTR2B from '../../services/serviceToB';
import PanelGSTR2BPDF from './PanelGSTR2BPDF';

import B2BSheet from '../../assets/sheets/B2B.xlsx';
import B2BASheet from '../../assets/sheets/B2BA.xlsx';
import CDNRSheet from '../../assets/sheets/CDNR.xlsx';
import IMPGSheet from '../../assets/sheets/IMPG.xlsx';
import MainSheet from '../../assets/sheets/MAIN.xlsx';

export default function Panel2BHome() {

    const [path, setPath] = useRecoilState(pathAtom);
    const [loading, setLoading] = useRecoilState(loadingAtom);
    const [redirect, setRedirect] = useRecoilState(redirectAtom);
    const [digDownload, setDigDownload] = useRecoilState(digDownloadAtom);
    const [GSTUser, setGSTUser] = useRecoilState(gstrUserAtom);
    const [userID] = useRecoilState(userIDAtom);
    const [gDate] = useRecoilState(returnDateAtom);
    const [digError, setDigError] = useRecoilState(digErrorAtom);

    const pdfRef = useRef(null);

    const [b2b, setB2B] = useState([]);
    const [b2ba, setB2BA] = useState([]);
    const [b2bcdr, setB2BCDR] = useState([]);
    const [impg, setIMPG] = useState([]);

    const [selGST, setSelGST] = useState({ 'gst': '', 'name': '' });
    const [searchErr, setSearchErr] = useState({ 'B2BA': false, 'B2BB': false, 'IMPG': false, 'CDRA': false, 'CDRB': false, 'B2BAA': false, 'B2BAB': false });
    const [querySearch, setQuerySearch] = useState({ 'B2BA': '', 'B2BB': '', 'IMPG': '', 'CDRA': '', 'CDRB': '', 'B2BAA': '', 'B2BAB': '' });

    const [fadFillter, setFADFillter] = useState({ 'count': 3, 'filter': ['M', 'N', 'O'] });
    const [fbdFillter, setFBDFillter] = useState({ 'count': 3, 'filter': ['N', 'O', 'P'] });
    const [fcdFillter, setFCDFillter] = useState({ 'count': 1, 'filter': ['M'] });

    const [apFillter, setAPFillter] = useState({ 'invtype': '', 'revc': '', 'ITC': '', 'rtax': '', 'amin': '', 'amax': '', 'bmin': '', 'bmax': '' });

    const [cType, setCType] = useState('b2b');

    const [exMain, setExMain] = useState(true);
    const [nxMain, setNxMain] = useState(true);
    const [isRow, setIsRow] = useState(false);

    const [apFilter, setApFilter] = useState(false);
    const [isFilter, setIsFilter] = useState(false);

    const [subRow, setSubRow] = useState(-1);

    const [exA, setExA] = useState(true);
    const [exB, setExB] = useState(true);
    const [exC, setExC] = useState(true);
    const [exD, setExD] = useState(true);
    const [exE, setExE] = useState(true);

    const [nxA, setNxA] = useState(true);
    const [nxB, setNxB] = useState(true);
    const [nxC, setNxC] = useState(true);
    const [nxD, setNxD] = useState(true);

    const [isTabA, setIsTabA] = useState(true);
    const [isTabB, setIsTabB] = useState(true);
    const [isTabC, setIsTabC] = useState(true);
    const [isTabE, setIsTabE] = useState(true);
    const [isTabD, setIsTabD] = useState(true);

    const [isPrint, setIsPrint] = useState(false);

    const lmsService = new ServiceLMS();
    const b2Service = new ServiceGSTR2B();

    /************************B2B Options ************************/

    const toggleClickA = (item) => {
        let newFill = { ...fadFillter };
        if (!newFill.filter.includes(item)) {
            newFill.filter.push(item);
            newFill.count = newFill.count + 1;
        } else {
            newFill.filter = newFill.filter.filter((str) => str !== item);
            newFill.count = newFill.count - 1;
        }
        setFADFillter(newFill);
    }

    const getToggleA = (item) => {
        return fadFillter.filter.includes(item);
    }

    const toggleClickAll = () => {
        let newFill = { ...fadFillter };
        newFill.filter = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O'];
        newFill.count = 15;
        setFADFillter(newFill);
    }

    const getToggleAAll = () => {
        let rhs = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O'];
        let lhs = [...fadFillter.filter];
        if (rhs.length !== lhs.length) {
            return false;
        }
        for (let i = 0; i < rhs.length; i++) {
            if (rhs[i] !== lhs[i]) {
                return false;
            }
        }
        return true;
    }

    /************************CDR Options ************************/

    const toggleClickB = (item) => {
        let newFill = { ...fbdFillter };
        if (!newFill.filter.includes(item)) {
            newFill.filter.push(item);
            newFill.count = newFill.count + 1;
        } else {
            newFill.filter = newFill.filter.filter((str) => str !== item);
            newFill.count = newFill.count - 1;
        }
        setFBDFillter(newFill);
    }

    const getToggleB = (item) => {
        return fbdFillter.filter.includes(item);
    }

    const toggleClickAllB = () => {
        let newFill = { ...fbdFillter };
        newFill.filter = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P'];
        newFill.count = 16;
        setFBDFillter(newFill);
    }

    const getToggleBAll = () => {
        let rhs = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P'];
        let lhs = [...fbdFillter.filter];
        if (rhs.length !== lhs.length) {
            return false;
        }
        for (let i = 0; i < rhs.length; i++) {
            if (rhs[i] !== lhs[i]) {
                return false;
            }
        }
        return true;
    }

    /************************B2B-A Options ************************/

    const toggleClickC = (item) => {
        let newFill = { ...fcdFillter };
        if (!newFill.filter.includes(item)) {
            newFill.filter.push(item);
            newFill.count = newFill.count + 1;
        } else {
            newFill.filter = newFill.filter.filter((str) => str !== item);
            newFill.count = newFill.count - 1;
        }
        setFCDFillter(newFill);
    }

    const getToggleC = (item) => {
        return fcdFillter.filter.includes(item);
    }

    const toggleClickAllC = () => {
        let newFill = { ...fcdFillter };
        newFill.filter = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O'];
        newFill.count = 15;
        setFCDFillter(newFill);
    }

    const getToggleCAll = () => {
        let rhs = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O'];
        let lhs = [...fcdFillter.filter];
        if (rhs.length !== lhs.length) {
            return false;
        }
        for (let i = 0; i < rhs.length; i++) {
            if (rhs[i] !== lhs[i]) {
                return false;
            }
        }
        return true;
    }

    const handleOnChangeSearch = (e, target) => {
        var { value, name } = e.target;
        const regex = /^[A-Za-z0-9/'\-. ]+$/;
        let err = { ...searchErr };
        if (regex.test(value)) {
            setQuerySearch({ ...querySearch, [target]: value });
            err[target] = false;
        } else {
            setQuerySearch({ ...querySearch, [target]: '' });
            err[target] = true;
        }
        setSearchErr(err);
    }

    /************************B2B - Amend Options ************************/

    const getSize = (type) => {
        switch (type) {
            case "amin":
                return Moment(gDate).format('YYYY-MM-DD');
            case "bmin":
                if (apFillter.amin === '') {
                    return Moment(gDate).format('YYYY-MM-DD');
                } else {
                    return Moment(apFillter.amin).format('YYYY-MM-DD');
                }
            case "cmin":
                if (apFillter.bmin === '') {
                    return Moment(gDate).format('YYYY-MM-DD');
                } else {
                    return Moment(apFillter.bmin).format('YYYY-MM-DD');
                }
            case "max":
                return Moment(gDate).add(1, 'month').add(14, 'days').format('YYYY-MM-DD');
        }
    }

    const changeDateFillter = (e) => {
        var { value, name } = e.target;
        setAPFillter({ ...apFillter, [name]: value });
    }

    const handleOnChangeForm = (e) => {
        var { value, name } = e.target;
        setAPFillter({ ...apFillter, [name]: value });
    }

    const removeAllFilter = () => {
        setSearchErr({ 'B2BA': false, 'B2BB': false, 'IMPG': false, 'CDRA': false, 'CDRB': false, 'B2BAA': false, 'B2BAB': false });
        setQuerySearch({ 'B2BA': '', 'B2BB': '', 'IMPG': '', 'CDRA': '', 'CDRB': '', 'B2BAA': '', 'B2BAB': '' });
        setFADFillter({ 'count': 3, 'filter': ['M', 'N', 'O'] });
        setFBDFillter({ 'count': 3, 'filter': ['N', 'O', 'P'] });
        setFCDFillter({ 'count': 1, 'filter': ['M'] });
        setSubRow(-1);
        setAPFillter({ 'invtype': '', 'revc': '', 'ITC': '', 'rtax': '', 'amin': '', 'amax': '', 'bmin': '', 'bmax': '' });
        setApFilter(false);
        setIsFilter(false);
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    }

    const gotoPath = (path) => {
        setLoading(true);
        setPath(path);
    }

    const downloadAdvice = (url) => {
        setDigDownload({ title: "Do you want to download advisory ?", url: url, isToggle: true });
    }

    const gotoURL = (url) => {
        setRedirect({ url: url, isToggle: true });
    }

    const getStartY = () => {
        if (new Date(Moment(gDate).format('MM-DD-yyyy')).getMonth() < 3) {
            return Moment(gDate).subtract(1, 'year').format('YYYY');
        } else {
            return Moment(gDate).format('YYYY');
        }
    }

    const getEndY = () => {
        if (new Date(Moment(gDate).format('MM-DD-yyyy')).getMonth() < 3) {
            return Moment(gDate).format('YY');
        } else {
            return Moment(gDate).add(1, 'year').format('YY');
        }
    }

    const collapseAll = () => {
        setExMain(true);
        setExA(true);
        setExB(true);
        setExC(true);
        setExD(true);
        setExE(true);
    }

    const ExpandAll = () => {
        setExMain(false);
        setExA(false);
        setExB(false);
        setExC(false);
        setExD(false);
        setExE(false);
    }

    const ncollapseAll = () => {
        setNxMain(true);
        setNxA(true);
        setNxB(true);
        setNxC(true);
        setNxD(true);
    }

    const nExpandAll = () => {
        setNxMain(false);
        setNxA(false);
        setNxB(false);
        setNxC(false);
        setNxD(false);
    }

    const getITCSummery = (type, get) => {
        let value = 0;
        switch (type) {
            case 'partA':
                switch (get) {
                    case "totalIGST":
                        value = 0;
                        b2b.forEach((item) => {
                            if (item['revc'] == "No" && item['ITC'] == "Yes") {
                                value = value + item['igst'];
                            }
                        });
                        b2ba.forEach((item) => {
                            if (item['revc'] == "No" && item['ITC'] == "Yes") {
                                value = value + item['igst'];
                            }
                        });
                        b2bcdr.forEach((item) => {
                            if (item['revc'] == "No" && item['ITC'] == "Yes" && item['notetype'] == "Debit Note") {
                                value = value + item['igst'];
                            }
                        });
                        return value;
                    case "totalCGST":
                        value = 0;
                        b2b.forEach((item) => {
                            if (item['revc'] == "No" && item['ITC'] == "Yes") {
                                value = value + item['cgst'];
                            }
                        });
                        b2ba.forEach((item) => {
                            if (item['revc'] == "No" && item['ITC'] == "Yes") {
                                value = value + item['cgst'];
                            }
                        });
                        b2bcdr.forEach((item) => {
                            if (item['revc'] == "No" && item['ITC'] == "Yes" && item['notetype'] == "Debit Note") {
                                value = value + item['cgst'];
                            }
                        });
                        return value;
                    case "totalSGST":
                        value = 0;
                        b2b.forEach((item) => {
                            if (item['revc'] == "No" && item['ITC'] == "Yes") {
                                value = value + item['sgst'];
                            }
                        });
                        b2ba.forEach((item) => {
                            if (item['revc'] == "No" && item['ITC'] == "Yes") {
                                value = value + item['sgst'];
                            }
                        });
                        b2bcdr.forEach((item) => {
                            if (item['revc'] == "No" && item['ITC'] == "Yes" && item['notetype'] == "Debit Note") {
                                value = value + item['sgst'];
                            }
                        });
                        return value;
                    case "totalCess":
                        value = 0;
                        b2b.forEach((item) => {
                            if (item['revc'] == "No" && item['ITC'] == "Yes") {
                                value = value + item['cess'];
                            }
                        });
                        b2ba.forEach((item) => {
                            if (item['revc'] == "No" && item['ITC'] == "Yes") {
                                value = value + item['cess'];
                            }
                        });
                        b2bcdr.forEach((item) => {
                            if (item['revc'] == "No" && item['ITC'] == "Yes" && item['notetype'] == "Debit Note") {
                                value = value + item['cess'];
                            }
                        });
                        return value;
                    case "b2bIGST":
                        value = 0;
                        b2b.forEach((item) => {
                            if (item['revc'] == "No" && item['ITC'] == "Yes") {
                                value = value + item['igst'];
                            }
                        });
                        return value;
                    case "b2bCGST":
                        value = 0;
                        b2b.forEach((item) => {
                            if (item['revc'] == "No" && item['ITC'] == "Yes") {
                                value = value + item['cgst'];
                            }
                        });
                        return value;
                    case "b2bSGST":
                        value = 0;
                        b2b.forEach((item) => {
                            if (item['revc'] == "No" && item['ITC'] == "Yes") {
                                value = value + item['sgst'];
                            }
                        });
                        return value;
                    case "b2bCess":
                        value = 0;
                        b2b.forEach((item) => {
                            if (item['revc'] == "No" && item['ITC'] == "Yes") {
                                value = value + item['cess'];
                            }
                        });
                        return value;
                    case "b2bndIGST":
                        value = 0;
                        b2bcdr.forEach((item) => {
                            if (item['revc'] == "No" && item['ITC'] == "Yes" && item['notetype'] == "Debit Note") {
                                value = value + item['igst'];
                            }
                        });
                        return value;
                    case "b2bndCGST":
                        value = 0;
                        b2bcdr.forEach((item) => {
                            if (item['revc'] == "No" && item['ITC'] == "Yes" && item['notetype'] == "Debit Note") {
                                value = value + item['cgst'];
                            }
                        });
                        return value;
                    case "b2bndSGST":
                        value = 0;
                        b2bcdr.forEach((item) => {
                            if (item['revc'] == "No" && item['ITC'] == "Yes" && item['notetype'] == "Debit Note") {
                                value = value + item['sgst'];
                            }
                        });
                        return value;
                    case "b2bndCess":
                        value = 0;
                        b2bcdr.forEach((item) => {
                            if (item['revc'] == "No" && item['ITC'] == "Yes" && item['notetype'] == "Debit Note") {
                                value = value + item['cess'];
                            }
                        });
                        return value;
                    case "b2baIGST":
                        value = 0;
                        b2ba.forEach((item) => {
                            if (item['revc'] == "No" && item['ITC'] == "Yes") {
                                value = value + item['igst'];
                            }
                        });
                        return value;
                    case "b2baCGST":
                        value = 0;
                        b2ba.forEach((item) => {
                            if (item['revc'] == "No" && item['ITC'] == "Yes") {
                                value = value + item['cgst'];
                            }
                        });
                        return value;
                    case "b2baSGST":
                        value = 0;
                        b2ba.forEach((item) => {
                            if (item['revc'] == "No" && item['ITC'] == "Yes") {
                                value = value + item['sgst'];
                            }
                        });
                        return value;
                    case "b2baCess":
                        value = 0;
                        b2ba.forEach((item) => {
                            if (item['revc'] == "No" && item['ITC'] == "Yes") {
                                value = value + item['cess'];
                            }
                        });
                        return value;
                    case "totalIGSTr":
                        value = 0;
                        b2b.forEach((item) => {
                            if (item['revc'] == "Yes" && item['ITC'] == "Yes") {
                                value = value + item['igst'];
                            }
                        });
                        b2ba.forEach((item) => {
                            if (item['revc'] == "Yes" && item['ITC'] == "Yes") {
                                value = value + item['igst'];
                            }
                        });
                        b2bcdr.forEach((item) => {
                            if (item['revc'] == "Yes" && item['ITC'] == "Yes" && item['notetype'] == "Debit Note") {
                                value = value + item['igst'];
                            }
                        });
                        return value;
                    case "totalCGSTr":
                        value = 0;
                        b2b.forEach((item) => {
                            if (item['revc'] == "Yes" && item['ITC'] == "Yes") {
                                value = value + item['cgst'];
                            }
                        });
                        b2ba.forEach((item) => {
                            if (item['revc'] == "Yes" && item['ITC'] == "Yes") {
                                value = value + item['cgst'];
                            }
                        });
                        b2bcdr.forEach((item) => {
                            if (item['revc'] == "Yes" && item['ITC'] == "Yes" && item['notetype'] == "Debit Note") {
                                value = value + item['cgst'];
                            }
                        });
                        return value;
                    case "totalSGSTr":
                        value = 0;
                        b2b.forEach((item) => {
                            if (item['revc'] == "Yes" && item['ITC'] == "Yes") {
                                value = value + item['sgst'];
                            }
                        });
                        b2ba.forEach((item) => {
                            if (item['revc'] == "Yes" && item['ITC'] == "Yes") {
                                value = value + item['sgst'];
                            }
                        });
                        b2bcdr.forEach((item) => {
                            if (item['revc'] == "Yes" && item['ITC'] == "Yes" && item['notetype'] == "Debit Note") {
                                value = value + item['sgst'];
                            }
                        });
                        return value;
                    case "totalCessr":
                        value = 0;
                        b2b.forEach((item) => {
                            if (item['revc'] == "Yes" && item['ITC'] == "Yes") {
                                value = value + item['cess'];
                            }
                        });
                        b2ba.forEach((item) => {
                            if (item['revc'] == "Yes" && item['ITC'] == "Yes") {
                                value = value + item['cess'];
                            }
                        });
                        b2bcdr.forEach((item) => {
                            if (item['revc'] == "Yes" && item['ITC'] == "Yes" && item['notetype'] == "Debit Note") {
                                value = value + item['cess'];
                            }
                        });
                        return value;
                    case "b2bIGSTr":
                        value = 0;
                        b2b.forEach((item) => {
                            if (item['revc'] == "Yes" && item['ITC'] == "Yes") {
                                value = value + item['igst'];
                            }
                        });
                        return value;
                    case "b2bCGSTr":
                        value = 0;
                        b2b.forEach((item) => {
                            if (item['revc'] == "Yes" && item['ITC'] == "Yes") {
                                value = value + item['cgst'];
                            }
                        });
                        return value;
                    case "b2bSGSTr":
                        value = 0;
                        b2b.forEach((item) => {
                            if (item['revc'] == "Yes" && item['ITC'] == "Yes") {
                                value = value + item['sgst'];
                            }
                        });
                        return value;
                    case "b2bCessr":
                        value = 0;
                        b2b.forEach((item) => {
                            if (item['revc'] == "Yes" && item['ITC'] == "Yes") {
                                value = value + item['cess'];
                            }
                        });
                        return value;
                    case "b2bndIGSTr":
                        value = 0;
                        b2bcdr.forEach((item) => {
                            if (item['revc'] == "Yes" && item['ITC'] == "Yes" && item['notetype'] == "Debit Note") {
                                value = value + item['igst'];
                            }
                        });
                        return value;
                    case "b2bndCGSTr":
                        value = 0;
                        b2bcdr.forEach((item) => {
                            if (item['revc'] == "Yes" && item['ITC'] == "Yes" && item['notetype'] == "Debit Note") {
                                value = value + item['cgst'];
                            }
                        });
                        return value;
                    case "b2bndSGSTr":
                        value = 0;
                        b2bcdr.forEach((item) => {
                            if (item['revc'] == "Yes" && item['ITC'] == "Yes" && item['notetype'] == "Debit Note") {
                                value = value + item['sgst'];
                            }
                        });
                        return value;
                    case "b2bndCessr":
                        value = 0;
                        b2bcdr.forEach((item) => {
                            if (item['revc'] == "Yes" && item['ITC'] == "Yes" && item['notetype'] == "Debit Note") {
                                value = value + item['cess'];
                            }
                        });
                        return value;
                    case "b2baIGSTr":
                        value = 0;
                        b2ba.forEach((item) => {
                            if (item['revc'] == "Yes" && item['ITC'] == "Yes") {
                                value = value + item['igst'];
                            }
                        });
                        return value;
                    case "b2baCGSTr":
                        value = 0;
                        b2ba.forEach((item) => {
                            if (item['revc'] == "Yes" && item['ITC'] == "Yes") {
                                value = value + item['cgst'];
                            }
                        });
                        return value;
                    case "b2baSGSTr":
                        value = 0;
                        b2ba.forEach((item) => {
                            if (item['revc'] == "Yes" && item['ITC'] == "Yes") {
                                value = value + item['sgst'];
                            }
                        });
                        return value;
                    case "b2baCessr":
                        value = 0;
                        b2ba.forEach((item) => {
                            if (item['revc'] == "Yes" && item['ITC'] == "Yes") {
                                value = value + item['cess'];
                            }
                        });
                        return value;
                    case "impgTotal":
                        value = 0;
                        impg.forEach((item) => {
                            value = value + item['igst'];
                        });
                        return value;
                    case "impgCess":
                        value = 0;
                        impg.forEach((item) => {
                            value = value + item['cess'];
                        });
                        return value;
                    case "b2bncIGST":
                        value = 0;
                        b2bcdr.forEach((item) => {
                            if (item['revc'] == "No" && item['ITC'] == "Yes" && item['notetype'] == "Credit Note") {
                                value = value + item['igst'];
                            }
                        });
                        return value;
                    case "b2bncCGST":
                        value = 0;
                        b2bcdr.forEach((item) => {
                            if (item['revc'] == "No" && item['ITC'] == "Yes" && item['notetype'] == "Credit Note") {
                                value = value + item['cgst'];
                            }
                        });
                        return value;
                    case "b2bncSGST":
                        value = 0;
                        b2bcdr.forEach((item) => {
                            if (item['revc'] == "No" && item['ITC'] == "Yes" && item['notetype'] == "Credit Note") {
                                value = value + item['sgst'];
                            }
                        });
                        return value;
                    case "b2bncCess":
                        value = 0;
                        b2bcdr.forEach((item) => {
                            if (item['revc'] == "No" && item['ITC'] == "Yes" && item['notetype'] == "Credit Note") {
                                value = value + item['cess'];
                            }
                        });
                        return value;
                    case "b2bncIGSTr":
                        value = 0;
                        b2bcdr.forEach((item) => {
                            if (item['revc'] == "Yes" && item['ITC'] == "Yes" && item['notetype'] == "Credit Note") {
                                value = value + item['igst'];
                            }
                        });
                        return value;
                    case "b2bncCGSTr":
                        value = 0;
                        b2bcdr.forEach((item) => {
                            if (item['revc'] == "Yes" && item['ITC'] == "Yes" && item['notetype'] == "Credit Note") {
                                value = value + item['cgst'];
                            }
                        });
                        return value;
                    case "b2bncSGSTr":
                        value = 0;
                        b2bcdr.forEach((item) => {
                            if (item['revc'] == "Yes" && item['ITC'] == "Yes" && item['notetype'] == "Credit Note") {
                                value = value + item['sgst'];
                            }
                        });
                        return value;
                    case "b2bncCessr":
                        value = 0;
                        b2bcdr.forEach((item) => {
                            if (item['revc'] == "Yes" && item['ITC'] == "Yes" && item['notetype'] == "Credit Note") {
                                value = value + item['cess'];
                            }
                        });
                        return value;
                    case "b2bncIGSTT":
                        value = 0;
                        b2bcdr.forEach((item) => {
                            if (item['ITC'] == "Yes" && item['notetype'] == "Credit Note") {
                                value = value + item['igst'];
                            }
                        });
                        return value;
                    case "b2bncCGSTT":
                        value = 0;
                        b2bcdr.forEach((item) => {
                            if (item['ITC'] == "Yes" && item['notetype'] == "Credit Note") {
                                value = value + item['cgst'];
                            }
                        });
                        return value;
                    case "b2bncSGSTT":
                        value = 0;
                        b2bcdr.forEach((item) => {
                            if (item['ITC'] == "Yes" && item['notetype'] == "Credit Note") {
                                value = value + item['sgst'];
                            }
                        });
                        return value;
                    case "b2bncCessT":
                        value = 0;
                        b2bcdr.forEach((item) => {
                            if (item['ITC'] == "Yes" && item['notetype'] == "Credit Note") {
                                value = value + item['cess'];
                            }
                        });
                        return value;
                    default:
                        value = 0;
                        return value;
                }
                break;
            case 'partB':
                switch (get) {
                    case "totalIGST":
                        value = 0;
                        b2b.forEach((item) => {
                            if (item['revc'] == "No" && item['ITC'] == "No") {
                                value = value + item['igst'];
                            }
                        });
                        b2ba.forEach((item) => {
                            if (item['revc'] == "No" && item['ITC'] == "No") {
                                value = value + item['igst'];
                            }
                        });
                        b2bcdr.forEach((item) => {
                            if (item['revc'] == "No" && item['ITC'] == "No" && item['notetype'] == "Debit Note") {
                                value = value + item['igst'];
                            }
                        });
                        return value;
                    case "totalCGST":
                        value = 0;
                        b2b.forEach((item) => {
                            if (item['revc'] == "No" && item['ITC'] == "No") {
                                value = value + item['cgst'];
                            }
                        });
                        b2ba.forEach((item) => {
                            if (item['revc'] == "No" && item['ITC'] == "No") {
                                value = value + item['cgst'];
                            }
                        });
                        b2bcdr.forEach((item) => {
                            if (item['revc'] == "No" && item['ITC'] == "No" && item['notetype'] == "Debit Note") {
                                value = value + item['cgst'];
                            }
                        });
                        return value;
                    case "totalSGST":
                        value = 0;
                        b2b.forEach((item) => {
                            if (item['revc'] == "No" && item['ITC'] == "No") {
                                value = value + item['sgst'];
                            }
                        });
                        b2ba.forEach((item) => {
                            if (item['revc'] == "No" && item['ITC'] == "No") {
                                value = value + item['sgst'];
                            }
                        });
                        b2bcdr.forEach((item) => {
                            if (item['revc'] == "No" && item['ITC'] == "No" && item['notetype'] == "Debit Note") {
                                value = value + item['sgst'];
                            }
                        });
                        return value;
                    case "totalCess":
                        value = 0;
                        b2b.forEach((item) => {
                            if (item['revc'] == "No" && item['ITC'] == "No") {
                                value = value + item['cess'];
                            }
                        });
                        b2ba.forEach((item) => {
                            if (item['revc'] == "No" && item['ITC'] == "No") {
                                value = value + item['cess'];
                            }
                        });
                        b2bcdr.forEach((item) => {
                            if (item['revc'] == "No" && item['ITC'] == "No" && item['notetype'] == "Debit Note") {
                                value = value + item['cess'];
                            }
                        });
                        return value;
                    case "b2bIGST":
                        value = 0;
                        b2b.forEach((item) => {
                            if (item['revc'] == "No" && item['ITC'] == "No") {
                                value = value + item['igst'];
                            }
                        });
                        return value;
                    case "b2bCGST":
                        value = 0;
                        b2b.forEach((item) => {
                            if (item['revc'] == "No" && item['ITC'] == "No") {
                                value = value + item['cgst'];
                            }
                        });
                        return value;
                    case "b2bSGST":
                        value = 0;
                        b2b.forEach((item) => {
                            if (item['revc'] == "No" && item['ITC'] == "No") {
                                value = value + item['sgst'];
                            }
                        });
                        return value;
                    case "b2bCess":
                        value = 0;
                        b2b.forEach((item) => {
                            if (item['revc'] == "No" && item['ITC'] == "No") {
                                value = value + item['cess'];
                            }
                        });
                        return value;
                    case "b2bndIGST":
                        value = 0;
                        b2bcdr.forEach((item) => {
                            if (item['revc'] == "No" && item['ITC'] == "No" && item['notetype'] == "Debit Note") {
                                value = value + item['igst'];
                            }
                        });
                        return value;
                    case "b2bndCGST":
                        value = 0;
                        b2bcdr.forEach((item) => {
                            if (item['revc'] == "No" && item['ITC'] == "No" && item['notetype'] == "Debit Note") {
                                value = value + item['cgst'];
                            }
                        });
                        return value;
                    case "b2bndSGST":
                        value = 0;
                        b2bcdr.forEach((item) => {
                            if (item['revc'] == "No" && item['ITC'] == "No" && item['notetype'] == "Debit Note") {
                                value = value + item['sgst'];
                            }
                        });
                        return value;
                    case "b2bndCess":
                        value = 0;
                        b2bcdr.forEach((item) => {
                            if (item['revc'] == "No" && item['ITC'] == "No" && item['notetype'] == "Debit Note") {
                                value = value + item['cess'];
                            }
                        });
                        return value;
                    case "b2baIGST":
                        value = 0;
                        b2ba.forEach((item) => {
                            if (item['revc'] == "No" && item['ITC'] == "No") {
                                value = value + item['igst'];
                            }
                        });
                        return value;
                    case "b2baCGST":
                        value = 0;
                        b2ba.forEach((item) => {
                            if (item['revc'] == "No" && item['ITC'] == "No") {
                                value = value + item['cgst'];
                            }
                        });
                        return value;
                    case "b2baSGST":
                        value = 0;
                        b2ba.forEach((item) => {
                            if (item['revc'] == "No" && item['ITC'] == "No") {
                                value = value + item['sgst'];
                            }
                        });
                        return value;
                    case "b2baCess":
                        value = 0;
                        b2ba.forEach((item) => {
                            if (item['revc'] == "No" && item['ITC'] == "No") {
                                value = value + item['cess'];
                            }
                        });
                        return value;
                    case "totalIGSTr":
                        value = 0;
                        b2b.forEach((item) => {
                            if (item['revc'] == "Yes" && item['ITC'] == "No") {
                                value = value + item['igst'];
                            }
                        });
                        b2ba.forEach((item) => {
                            if (item['revc'] == "Yes" && item['ITC'] == "No") {
                                value = value + item['igst'];
                            }
                        });
                        b2bcdr.forEach((item) => {
                            if (item['revc'] == "Yes" && item['ITC'] == "No" && item['notetype'] == "Debit Note") {
                                value = value + item['igst'];
                            }
                        });
                        return value;
                    case "totalCGSTr":
                        value = 0;
                        b2b.forEach((item) => {
                            if (item['revc'] == "Yes" && item['ITC'] == "No") {
                                value = value + item['cgst'];
                            }
                        });
                        b2ba.forEach((item) => {
                            if (item['revc'] == "Yes" && item['ITC'] == "No") {
                                value = value + item['cgst'];
                            }
                        });
                        b2bcdr.forEach((item) => {
                            if (item['revc'] == "Yes" && item['ITC'] == "No" && item['notetype'] == "Debit Note") {
                                value = value + item['cgst'];
                            }
                        });
                        return value;
                    case "totalSGSTr":
                        value = 0;
                        b2b.forEach((item) => {
                            if (item['revc'] == "Yes" && item['ITC'] == "No") {
                                value = value + item['sgst'];
                            }
                        });
                        b2ba.forEach((item) => {
                            if (item['revc'] == "Yes" && item['ITC'] == "No") {
                                value = value + item['sgst'];
                            }
                        });
                        b2bcdr.forEach((item) => {
                            if (item['revc'] == "Yes" && item['ITC'] == "No" && item['notetype'] == "Debit Note") {
                                value = value + item['sgst'];
                            }
                        });
                        return value;
                    case "totalCessr":
                        value = 0;
                        b2b.forEach((item) => {
                            if (item['revc'] == "Yes" && item['ITC'] == "No") {
                                value = value + item['cess'];
                            }
                        });
                        b2ba.forEach((item) => {
                            if (item['revc'] == "Yes" && item['ITC'] == "No") {
                                value = value + item['cess'];
                            }
                        });
                        b2bcdr.forEach((item) => {
                            if (item['revc'] == "Yes" && item['ITC'] == "No" && item['notetype'] == "Debit Note") {
                                value = value + item['cess'];
                            }
                        });
                        return value;
                    case "b2bIGSTr":
                        value = 0;
                        b2b.forEach((item) => {
                            if (item['revc'] == "Yes" && item['ITC'] == "No") {
                                value = value + item['igst'];
                            }
                        });
                        return value;
                    case "b2bCGSTr":
                        value = 0;
                        b2b.forEach((item) => {
                            if (item['revc'] == "Yes" && item['ITC'] == "No") {
                                value = value + item['cgst'];
                            }
                        });
                        return value;
                    case "b2bSGSTr":
                        value = 0;
                        b2b.forEach((item) => {
                            if (item['revc'] == "Yes" && item['ITC'] == "No") {
                                value = value + item['sgst'];
                            }
                        });
                        return value;
                    case "b2bCessr":
                        value = 0;
                        b2b.forEach((item) => {
                            if (item['revc'] == "Yes" && item['ITC'] == "No") {
                                value = value + item['cess'];
                            }
                        });
                        return value;
                    case "b2bndIGSTr":
                        value = 0;
                        b2bcdr.forEach((item) => {
                            if (item['revc'] == "Yes" && item['ITC'] == "No" && item['notetype'] == "Debit Note") {
                                value = value + item['igst'];
                            }
                        });
                        return value;
                    case "b2bndCGSTr":
                        value = 0;
                        b2bcdr.forEach((item) => {
                            if (item['revc'] == "Yes" && item['ITC'] == "No" && item['notetype'] == "Debit Note") {
                                value = value + item['cgst'];
                            }
                        });
                        return value;
                    case "b2bndSGSTr":
                        value = 0;
                        b2bcdr.forEach((item) => {
                            if (item['revc'] == "Yes" && item['ITC'] == "No" && item['notetype'] == "Debit Note") {
                                value = value + item['sgst'];
                            }
                        });
                        return value;
                    case "b2bndCessr":
                        value = 0;
                        b2bcdr.forEach((item) => {
                            if (item['revc'] == "Yes" && item['ITC'] == "No" && item['notetype'] == "Debit Note") {
                                value = value + item['cess'];
                            }
                        });
                        return value;
                    case "b2baIGSTr":
                        value = 0;
                        b2ba.forEach((item) => {
                            if (item['revc'] == "Yes" && item['ITC'] == "No") {
                                value = value + item['igst'];
                            }
                        });
                        return value;
                    case "b2baCGSTr":
                        value = 0;
                        b2ba.forEach((item) => {
                            if (item['revc'] == "Yes" && item['ITC'] == "No") {
                                value = value + item['cgst'];
                            }
                        });
                        return value;
                    case "b2baSGSTr":
                        value = 0;
                        b2ba.forEach((item) => {
                            if (item['revc'] == "Yes" && item['ITC'] == "No") {
                                value = value + item['sgst'];
                            }
                        });
                        return value;
                    case "b2baCessr":
                        value = 0;
                        b2ba.forEach((item) => {
                            if (item['revc'] == "Yes" && item['ITC'] == "No") {
                                value = value + item['cess'];
                            }
                        });
                        return value;
                    case "impgTotal":
                        value = 0;
                        impg.forEach((item) => {
                            value = value + item['igst'];
                        });
                        return value;
                    case "impgCess":
                        value = 0;
                        impg.forEach((item) => {
                            value = value + item['cess'];
                        });
                        return value;
                    case "b2bncIGST":
                        value = 0;
                        b2bcdr.forEach((item) => {
                            if (item['revc'] == "No" && item['ITC'] == "No" && item['notetype'] == "Credit Note") {
                                value = value + item['igst'];
                            }
                        });
                        return value;
                    case "b2bncCGST":
                        value = 0;
                        b2bcdr.forEach((item) => {
                            if (item['revc'] == "No" && item['ITC'] == "No" && item['notetype'] == "Credit Note") {
                                value = value + item['cgst'];
                            }
                        });
                        return value;
                    case "b2bncSGST":
                        value = 0;
                        b2bcdr.forEach((item) => {
                            if (item['revc'] == "No" && item['ITC'] == "No" && item['notetype'] == "Credit Note") {
                                value = value + item['sgst'];
                            }
                        });
                        return value;
                    case "b2bncCess":
                        value = 0;
                        b2bcdr.forEach((item) => {
                            if (item['revc'] == "No" && item['ITC'] == "No" && item['notetype'] == "Credit Note") {
                                value = value + item['cess'];
                            }
                        });
                        return value;
                    case "b2bncIGSTr":
                        value = 0;
                        b2bcdr.forEach((item) => {
                            if (item['revc'] == "Yes" && item['ITC'] == "No" && item['notetype'] == "Credit Note") {
                                value = value + item['igst'];
                            }
                        });
                        return value;
                    case "b2bncCGSTr":
                        value = 0;
                        b2bcdr.forEach((item) => {
                            if (item['revc'] == "Yes" && item['ITC'] == "No" && item['notetype'] == "Credit Note") {
                                value = value + item['cgst'];
                            }
                        });
                        return value;
                    case "b2bncSGSTr":
                        value = 0;
                        b2bcdr.forEach((item) => {
                            if (item['revc'] == "Yes" && item['ITC'] == "No" && item['notetype'] == "Credit Note") {
                                value = value + item['sgst'];
                            }
                        });
                        return value;
                    case "b2bncCessr":
                        value = 0;
                        b2bcdr.forEach((item) => {
                            if (item['revc'] == "Yes" && item['ITC'] == "No" && item['notetype'] == "Credit Note") {
                                value = value + item['cess'];
                            }
                        });
                        return value;
                    case "b2bncIGSTT":
                        value = 0;
                        b2bcdr.forEach((item) => {
                            if (item['ITC'] == "No" && item['notetype'] == "Credit Note") {
                                value = value + item['igst'];
                            }
                        });
                        return value;
                    case "b2bncCGSTT":
                        value = 0;
                        b2bcdr.forEach((item) => {
                            if (item['ITC'] == "No" && item['notetype'] == "Credit Note") {
                                value = value + item['cgst'];
                            }
                        });
                        return value;
                    case "b2bncSGSTT":
                        value = 0;
                        b2bcdr.forEach((item) => {
                            if (item['ITC'] == "No" && item['notetype'] == "Credit Note") {
                                value = value + item['sgst'];
                            }
                        });
                        return value;
                    case "b2bncCessT":
                        value = 0;
                        b2bcdr.forEach((item) => {
                            if (item['ITC'] == "No" && item['notetype'] == "Credit Note") {
                                value = value + item['cess'];
                            }
                        });
                        return value;
                    default:
                        value = 0;
                        return value;
                }
                break;
        }
    }

    const getITCISRecors = (type, get) => {
        let value = 0;
        switch (type) {
            case 'partA':
                switch (get) {
                    case "b2bIGST":
                        value = 0;
                        b2b.forEach((item) => {
                            if (item['revc'] == "No" && item['ITC'] == "Yes") {
                                value = value + 1;
                            }
                        });
                        break;
                    case "b2bndIGST":
                        value = 0;
                        b2bcdr.forEach((item) => {
                            if (item['revc'] == "No" && item['ITC'] == "Yes" && item['notetype'] == "Debit Note") {
                                value = value + 1;
                            }
                        });
                        break;
                    case "b2baIGST":
                        value = 0;
                        b2ba.forEach((item) => {
                            if (item['revc'] == "No" && item['ITC'] == "Yes") {
                                value = value + 1;
                            }
                        });
                        break;
                    case "b2bIGSTr":
                        value = 0;
                        b2b.forEach((item) => {
                            if (item['revc'] == "Yes" && item['ITC'] == "Yes") {
                                value = value + 1;
                            }
                        });
                        break;
                    case "b2bndIGSTr":
                        value = 0;
                        b2bcdr.forEach((item) => {
                            if (item['revc'] == "Yes" && item['ITC'] == "Yes" && item['notetype'] == "Debit Note") {
                                value = value + 1;
                            }
                        });
                        break;
                    case "b2baIGSTr":
                        value = 0;
                        b2ba.forEach((item) => {
                            if (item['revc'] == "Yes" && item['ITC'] == "Yes") {
                                value = value + 1;
                            }
                        });
                        break;
                    case "impgTotal":
                        value = 0;
                        impg.forEach((item) => {
                            value = value + 1;
                        });
                        break;
                    case "b2bncIGST":
                        value = 0;
                        b2bcdr.forEach((item) => {
                            if (item['revc'] == "No" && item['ITC'] == "Yes" && item['notetype'] == "Credit Note") {
                                value = value + 1;
                            }
                        });
                        break;
                    case "b2bncIGSTr":
                        value = 0;
                        b2bcdr.forEach((item) => {
                            if (item['revc'] == "Yes" && item['ITC'] == "Yes" && item['notetype'] == "Credit Note") {
                                value = value + 1;
                            }
                        });
                        break;
                    case "b2bncIGSTT":
                        value = 0;
                        b2bcdr.forEach((item) => {
                            if (item['ITC'] == "Yes" && item['notetype'] == "Credit Note") {
                                value = value + 1;
                            }
                        });
                        break;
                    default:
                        value = 0;
                }
                break;
            case 'partB':
                switch (get) {
                    case "b2bIGST":
                        value = 0;
                        b2b.forEach((item) => {
                            if (item['revc'] == "No" && item['ITC'] == "No") {
                                value = value + 1;
                            }
                        });
                        break;
                    case "b2bndIGST":
                        value = 0;
                        b2bcdr.forEach((item) => {
                            if (item['revc'] == "No" && item['ITC'] == "No" && item['notetype'] == "Debit Note") {
                                value = value + 1;
                            }
                        });
                        break;
                    case "b2baIGST":
                        value = 0;
                        b2ba.forEach((item) => {
                            if (item['revc'] == "No" && item['ITC'] == "No") {
                                value = value + 1;
                            }
                        });
                        break;
                    case "b2bIGSTr":
                        value = 0;
                        b2b.forEach((item) => {
                            if (item['revc'] == "Yes" && item['ITC'] == "No") {
                                value = value + 1;
                            }
                        });
                        break;
                    case "b2bndIGSTr":
                        value = 0;
                        b2bcdr.forEach((item) => {
                            if (item['revc'] == "Yes" && item['ITC'] == "No" && item['notetype'] == "Debit Note") {
                                value = value + 1;
                            }
                        });
                        break;
                    case "b2baIGSTr":
                        value = 0;
                        b2ba.forEach((item) => {
                            if (item['revc'] == "Yes" && item['ITC'] == "No") {
                                value = value + 1;
                            }
                        });
                        break;
                    case "impgTotal":
                        value = 0;
                        impg.forEach((item) => {
                            value = value + 1;
                        });
                        break;
                    case "b2bncIGST":
                        value = 0;
                        b2bcdr.forEach((item) => {
                            if (item['revc'] == "No" && item['ITC'] == "No" && item['notetype'] == "Credit Note") {
                                value = value + 1;
                            }
                        });
                        break;
                    case "b2bncIGSTr":
                        value = 0;
                        b2bcdr.forEach((item) => {
                            if (item['revc'] == "Yes" && item['ITC'] == "No" && item['notetype'] == "Credit Note") {
                                value = value + 1;
                            }
                        });
                        break;
                    case "b2bncIGSTT":
                        value = 0;
                        b2bcdr.forEach((item) => {
                            if (item['ITC'] == "No" && item['notetype'] == "Credit Note") {
                                value = value + 1;
                            }
                        });
                        return value;
                    default:
                        value = 0;
                        return value;
                }
                break;
        }
        return value === 0 ? false : true;
    }

    const loadData = () => {
        setLoading(true);
        (async function () {
            let body = {
                id: userID
            };
            var res = await lmsService.getLMSUserByID(body);
            if (res.status) {
                setGSTUser(res.data['B']);
            } else {
                setDigError({ title: 'Error', msg: 'Server error please try again!', isToggle: true });
            }
            let b = { 'state': GSTUser.state, 'month': Moment(gDate).format("MMMM") }
            var resB = await b2Service.get2B(b);

            setB2B(resB.data.data.b2b);
            setB2BA(resB.data.data.b2ba);
            setB2BCDR(resB.data.data.b2bcdr);
            setIMPG(resB.data.data.impg);

            setCType('b2b');
            if (resB.data.data.b2b.length > 0) {
                setIsRow(true);
            } else {
                setIsRow(false);
            }

            setLoading(false);
            window.scrollTo({
                top: 0,
                behavior: 'smooth',
            });
        })();
    }

    const getYDate = (data) => {
        let lhs = Moment(gDate).toDate();
        let comp = Moment(data, 'DD/MM/YYYY').toDate();

        let lowyear = lhs.getMonth() > 2 ? lhs.getFullYear() : lhs.getFullYear() - 1;
        let currentYear = comp.getFullYear();

        const adjust = lowyear - 2022;
        const yearDifference = currentYear - lowyear;
        currentYear = lowyear + yearDifference + adjust;

        comp.setFullYear(currentYear);
        return Moment(comp).format('DD/MM/YYYY');
    }

    const getCurrentPeroid = (data) => {
        let lhs = Moment(gDate).toDate();
        let rhs = Moment(data, "MMM'YY").toDate();

        let lowyear = lhs.getMonth() > 2 ? lhs.getFullYear() : lhs.getFullYear() - 1;
        let currentYear = rhs.getFullYear();

        const adjust = lowyear - 2022;
        const yearDifference = currentYear - lowyear;
        currentYear = lowyear + yearDifference + adjust;

        rhs.setFullYear(currentYear);
        return Moment(rhs).format("MMM'YY");
    }

    const getSummeryData = (type) => {
        var mrecords = [];
        switch (type) {
            case 'b2b':
                b2b.forEach((invoice) => {
                    if (mrecords.find((e) => e.gstn == invoice.GSTN) == undefined) {
                        mrecords.push({ 'gstn': invoice.GSTN, 'name': invoice.name, 'num': invoice.invnum, 'count': 1, 'tax': invoice.taxVal, 'igst': invoice.igst, 'cgst': invoice.cgst, 'sgst': invoice.sgst, 'cess': invoice.cess, 'period': getCurrentPeroid(invoice.period), 'fillDate': getYDate(invoice.fillDate) });
                    } else {
                        let item = mrecords.findIndex((e) => e.gstn == invoice.GSTN);
                        if (mrecords[item].num !== invoice.invnum) {
                            mrecords[item].count = mrecords[item].count + 1;
                        }
                        mrecords[item].tax = mrecords[item].tax + invoice.taxVal;
                        mrecords[item].igst = mrecords[item].igst + invoice.igst;
                        mrecords[item].cgst = mrecords[item].cgst + invoice.cgst;
                        mrecords[item].sgst = mrecords[item].sgst + invoice.sgst;
                        mrecords[item].cess = mrecords[item].cess + invoice.cess;
                    }
                });
                if (querySearch.B2BA !== "") {
                    let value = querySearch.B2BA.toLowerCase();
                    mrecords = mrecords.filter((item) => item.gstn.toLowerCase().includes(value) || item.name.toLowerCase().includes(value) || item.tax.toString().toLowerCase().includes(value) || item.igst.toString().toLowerCase().includes(value) || item.sgst.toString().toLowerCase().includes(value) || item.cess.toString().toLowerCase().includes(value) || item.period.toLowerCase().includes(value) || item.fillDate.toLowerCase().includes(value));
                }
                break;
            case 'b2bA':
                b2ba.forEach((invoice) => {
                    if (mrecords.find((e) => e.gstn == invoice.GSTN) == undefined) {
                        mrecords.push({ 'gstn': invoice.GSTN, 'name': invoice.name, 'count': 1, 'tax': invoice.taxVal, 'igst': invoice.igst, 'cgst': invoice.cgst, 'sgst': invoice.sgst, 'cess': invoice.cess, 'period': getCurrentPeroid(invoice.period), 'fillDate': getYDate(invoice.fillDate) });
                    } else {
                        let item = mrecords.findIndex((e) => e.gstn == invoice.GSTN);
                        mrecords[item].count = mrecords[item].count + 1;
                        mrecords[item].tax = mrecords[item].tax + invoice.taxVal;
                        mrecords[item].igst = mrecords[item].igst + invoice.igst;
                        mrecords[item].cgst = mrecords[item].cgst + invoice.cgst;
                        mrecords[item].sgst = mrecords[item].sgst + invoice.sgst;
                        mrecords[item].cess = mrecords[item].cess + invoice.cess;
                    }
                });
                if (querySearch.B2BAA !== "") {
                    let value = querySearch.B2BAA.toLowerCase();
                    mrecords = mrecords.filter((item) => item.gstn.toLowerCase().includes(value) || item.name.toLowerCase().includes(value) || item.tax.toString().toLowerCase().includes(value) || item.igst.toString().toLowerCase().includes(value) || item.sgst.toString().toLowerCase().includes(value) || item.cess.toString().toLowerCase().includes(value) || item.period.toLowerCase().includes(value) || item.fillDate.toLowerCase().includes(value));
                }
                break;
            case 'Cdr':
                b2bcdr.forEach((invoice) => {
                    if (mrecords.find((e) => e.gstn == invoice.GSTN) == undefined) {
                        mrecords.push({ 'notec': invoice.notetype, 'gstn': invoice.GSTN, 'name': invoice.name, 'count': 1, 'tax': invoice.taxVal, 'igst': invoice.igst, 'cgst': invoice.cgst, 'sgst': invoice.sgst, 'cess': invoice.cess, 'period': getCurrentPeroid(invoice.period), 'fillDate': getYDate(invoice.fillDate) });
                    } else {
                        let item = mrecords.findIndex((e) => e.gstn == invoice.GSTN);
                        mrecords[item].count = mrecords[item].count + 1;
                        mrecords[item].tax = mrecords[item].tax + invoice.taxVal;
                        mrecords[item].igst = mrecords[item].igst + invoice.igst;
                        mrecords[item].cgst = mrecords[item].cgst + invoice.cgst;
                        mrecords[item].sgst = mrecords[item].sgst + invoice.sgst;
                        mrecords[item].cess = mrecords[item].cess + invoice.cess;
                    }
                });
                if (querySearch.CDRA !== "") {
                    let value = querySearch.CDRA.toLowerCase();
                    mrecords = mrecords.filter((item) => item.notec.toLowerCase().includes(value) || item.gstn.toLowerCase().includes(value) || item.name.toLowerCase().includes(value) || item.tax.toString().toLowerCase().includes(value) || item.igst.toString().toLowerCase().includes(value) || item.sgst.toString().toLowerCase().includes(value) || item.cess.toString().toLowerCase().includes(value) || item.period.toLowerCase().includes(value) || item.fillDate.toLowerCase().includes(value));
                }
                break;
        }
        return mrecords;
    }

    const getDocumentData = (type) => {
        var mrecords = [];
        let tempData = [];
        switch (type) {
            case 'b2b':
                tempData = [...b2b];
                if (selGST.gst !== '') {
                    tempData = tempData.filter(e => e.GSTN === selGST.gst);
                }
                tempData.forEach((invoice) => {
                    if (mrecords.find((e) => e.invnum == invoice.invnum) == undefined) {
                        let indata = [];
                        indata.push({ 'rate': invoice.rate, 'tax': invoice.taxVal, 'igst': invoice.igst, 'cgst': invoice.cgst, 'sgst': invoice.sgst, 'cess': invoice.cess });
                        mrecords.push({ 'gstn': invoice.GSTN, 'name': invoice.name, 'invnum': invoice.invnum, 'invtype': invoice.invtype, 'invdate': getYDate(invoice.invDate), 'invval': invoice.invVal, 'pos': invoice.pos, 'revc': invoice.revc, 'tax': invoice.taxVal, 'igst': invoice.igst, 'cgst': invoice.cgst, 'sgst': invoice.sgst, 'cess': invoice.cess, 'peroid': getCurrentPeroid(invoice.period), 'fData': getYDate(invoice.fillDate), 'ITC': invoice.ITC, 'reason': invoice.Reason, 'PTax': '100%', 'source': invoice.Source, 'irn': invoice.IRN, 'irnd': invoice.IRNDate, 'data': indata });
                    } else {
                        let item = mrecords.findIndex((e) => e.invnum == invoice.invnum);
                        mrecords[item].data.push({ 'rate': invoice.rate, 'tax': invoice.taxVal, 'igst': invoice.igst, 'cgst': invoice.cgst, 'sgst': invoice.sgst, 'cess': invoice.cess });
                        mrecords[item].tax = mrecords[item].tax + invoice.taxVal;
                        mrecords[item].igst = mrecords[item].igst + invoice.igst;
                        mrecords[item].cgst = mrecords[item].cgst + invoice.cgst;
                        mrecords[item].sgst = mrecords[item].sgst + invoice.sgst;
                        mrecords[item].cess = mrecords[item].cess + invoice.cess;
                    }
                });
                if (isFilter) {
                    if (apFillter.amin !== "") {
                        mrecords = mrecords.filter((invoice) => Moment(invoice.invdate, 'DD/MM/YYYY').isAfter(Moment(apFillter.amin)));
                    }
                    if (apFillter.amax !== "") {
                        mrecords = mrecords.filter((invoice) => Moment(invoice.invdate, 'DD/MM/YYYY').isBefore(Moment(apFillter.amax)));
                    }
                    if (apFillter.bmin !== "") {
                        mrecords = mrecords.filter((invoice) => Moment(invoice.fData, 'DD/MM/YYYY').isAfter(Moment(apFillter.bmin)));
                    }
                    if (apFillter.bmax !== "") {
                        mrecords = mrecords.filter((invoice) => Moment(invoice.fData, 'DD/MM/YYYY').isBefore(Moment(apFillter.bmax)));
                    }
                    if (apFillter.invtype !== "") {
                        if (apFillter.invtype === "R") {
                            mrecords = mrecords.filter((invoice) => invoice.invtype.includes('Regular'));
                        } else {
                            mrecords = mrecords.filter((invoice) => invoice.invtype.includes('NGRegular'));
                        }
                    }
                    if (apFillter.revc !== "") {
                        if (apFillter.revc === "Yes") {
                            mrecords = mrecords.filter((invoice) => invoice.revc.includes('Yes'));
                        } else {
                            mrecords = mrecords.filter((invoice) => invoice.revc.includes('No'));
                        }
                    }
                    if (apFillter.ITC !== "") {
                        if (apFillter.ITC === "Yes") {
                            mrecords = mrecords.filter((invoice) => invoice.ITC.includes('Yes'));
                        } else {
                            mrecords = mrecords.filter((invoice) => invoice.ITC.includes('No'));
                        }
                    }
                    if (apFillter.rtax !== "") {
                        if (apFillter.rtax === "100") {
                            mrecords = mrecords.filter((invoice) => invoice.PTax.includes('100%'));
                        } else {
                            mrecords = mrecords.filter((invoice) => invoice.PTax.includes('65%'));
                        }
                    }

                }
                if (querySearch.B2BB !== "") {
                    let value = querySearch.B2BB.toString().toLowerCase();
                    mrecords = mrecords.filter((invoice) => invoice.gstn.toLowerCase().includes(value) || invoice.name.toLowerCase().includes(value) || invoice.invnum.toString().toLowerCase().includes(value) || invoice.invtype.toString().toLowerCase().includes(value) || invoice.tax.toString().toLowerCase().includes(value) || invoice.igst.toString().toLowerCase().includes(value) || invoice.cgst.toString().toLowerCase().includes(value) || invoice.sgst.toString().toLowerCase().includes(value) || invoice.cess.toString().toLowerCase().includes(value) || invoice.peroid.toLowerCase().includes(value) || invoice.invdate.toLowerCase().includes(value));
                }
                break;
            case 'b2bA':
                tempData = [...b2ba];
                if (selGST.gst !== '') {
                    tempData = tempData.filter(e => e.GSTN === selGST.gst);
                }
                tempData.forEach((invoice) => {
                    if (mrecords.find((e) => e.invnum == invoice.invnum) == undefined) {
                        let indata = [];
                        indata.push({ 'rate': invoice.rate, 'tax': invoice.taxVal, 'igst': invoice.igst, 'cgst': invoice.cgst, 'sgst': invoice.sgst, 'cess': invoice.cess });
                        mrecords.push({ 'oinvnum': invoice.oinvnum, 'oinvdate': getYDate(invoice.oinvDate), 'gstn': invoice.GSTN, 'name': invoice.name, 'invnum': invoice.invnum, 'invtype': invoice.invtype, 'invdate': getYDate(invoice.invDate), 'invval': invoice.invVal, 'pos': invoice.pos, 'revc': invoice.revc, 'tax': invoice.taxVal, 'igst': invoice.igst, 'cgst': invoice.cgst, 'sgst': invoice.sgst, 'cess': invoice.cess, 'peroid': getCurrentPeroid(invoice.period), 'fData': getYDate(invoice.fillDate), 'ITC': invoice.ITC, 'reason': invoice.Reason, 'PTax': '100%', 'source': invoice.source, 'irn': invoice.IRN, 'irnd': invoice.IRNDate, 'data': indata });
                    } else {
                        let item = mrecords.findIndex((e) => e.invnum == invoice.invnum);
                        mrecords[item].data.push({ 'rate': invoice.rate, 'tax': invoice.taxVal, 'igst': invoice.igst, 'cgst': invoice.cgst, 'sgst': invoice.sgst, 'cess': invoice.cess });
                        mrecords[item].tax = mrecords[item].tax + invoice.taxVal;
                        mrecords[item].igst = mrecords[item].igst + invoice.igst;
                        mrecords[item].cgst = mrecords[item].cgst + invoice.cgst;
                        mrecords[item].sgst = mrecords[item].sgst + invoice.sgst;
                        mrecords[item].cess = mrecords[item].cess + invoice.cess;
                    }
                });
                if (isFilter) {
                    if (apFillter.amin !== "") {
                        mrecords = mrecords.filter((invoice) => Moment(invoice.invdate, 'DD/MM/YYYY').isAfter(Moment(apFillter.amin)));
                    }
                    if (apFillter.amax !== "") {
                        mrecords = mrecords.filter((invoice) => Moment(invoice.invdate, 'DD/MM/YYYY').isBefore(Moment(apFillter.amax)));
                    }
                    if (apFillter.bmin !== "") {
                        mrecords = mrecords.filter((invoice) => Moment(invoice.fData, 'DD/MM/YYYY').isAfter(Moment(apFillter.bmin)));
                    }
                    if (apFillter.bmax !== "") {
                        mrecords = mrecords.filter((invoice) => Moment(invoice.fData, 'DD/MM/YYYY').isBefore(Moment(apFillter.bmax)));
                    }
                    if (apFillter.invtype !== "") {
                        if (apFillter.invtype === "R") {
                            mrecords = mrecords.filter((invoice) => invoice.invtype.includes('Regular'));
                        } else {
                            mrecords = mrecords.filter((invoice) => invoice.invtype.includes('NGRegular'));
                        }
                    }
                    if (apFillter.revc !== "") {
                        if (apFillter.revc === "Yes") {
                            mrecords = mrecords.filter((invoice) => invoice.revc.includes('Yes'));
                        } else {
                            mrecords = mrecords.filter((invoice) => invoice.revc.includes('No'));
                        }
                    }
                    if (apFillter.ITC !== "") {
                        if (apFillter.ITC === "Yes") {
                            mrecords = mrecords.filter((invoice) => invoice.ITC.includes('Yes'));
                        } else {
                            mrecords = mrecords.filter((invoice) => invoice.ITC.includes('No'));
                        }
                    }
                    if (apFillter.rtax !== "") {
                        if (apFillter.rtax === "100") {
                            mrecords = mrecords.filter((invoice) => invoice.PTax.includes('100%'));
                        } else {
                            mrecords = mrecords.filter((invoice) => invoice.PTax.includes('65%'));
                        }
                    }
                }
                if (querySearch.B2BAB !== "") {
                    let value = querySearch.B2BAB.toLowerCase();
                    mrecords = mrecords.filter((invoice) => invoice.gstn.toLowerCase().includes(value) || invoice.name.toLowerCase().includes(value) || invoice.invnum.toString().toLowerCase().includes(value) || invoice.invtype.toString().toLowerCase().includes(value) || invoice.invdate.toLowerCase().includes(value) || invoice.tax.toString().toLowerCase().includes(value) || invoice.igst.toString().toLowerCase().includes(value) || invoice.cgst.toString().toLowerCase().includes(value) || invoice.sgst.toString().toLowerCase().includes(value) || invoice.cess.toString().toLowerCase().includes(value) || invoice.peroid.toLowerCase().includes(value));
                }
                break;
            case 'Cdr':
                tempData = [...b2bcdr];
                if (selGST.gst !== '') {
                    tempData = tempData.filter(e => e.GSTN === selGST.gst);
                }
                tempData.forEach((invoice) => {
                    if (mrecords.find((e) => e.notenum == invoice.notenum) == undefined) {
                        let indata = [];
                        indata.push({ 'rate': invoice.rate, 'tax': invoice.taxVal, 'igst': invoice.igst, 'cgst': invoice.cgst, 'sgst': invoice.sgst, 'cess': invoice.cess });
                        mrecords.push({ 'gstn': invoice.GSTN, 'name': invoice.name, 'notenum': invoice.notenum, 'notetype': invoice.notetype, 'notestype': invoice.notestype, 'notedate': getYDate(invoice.noteDate), 'noteval': invoice.noteVal, 'pos': invoice.pos, 'revc': invoice.revc, 'tax': invoice.taxVal, 'igst': invoice.igst, 'cgst': invoice.cgst, 'sgst': invoice.sgst, 'cess': invoice.cess, 'peroid': getCurrentPeroid(invoice.period), 'fData': getYDate(invoice.fillDate), 'ITC': invoice.ITC, 'reason': invoice.Reason, 'PTax': '100%', 'source': invoice.source, 'irn': invoice.IRN, 'irnd': invoice.IRNDate, 'data': indata });
                    } else {
                        let item = mrecords.findIndex((e) => e.invnum == invoice.invnum);
                        mrecords[item].data.push({ 'rate': invoice.rate, 'tax': invoice.taxVal, 'igst': invoice.igst, 'cgst': invoice.cgst, 'sgst': invoice.sgst, 'cess': invoice.cess });
                        mrecords[item].tax = mrecords[item].tax + invoice.taxVal;
                        mrecords[item].igst = mrecords[item].igst + invoice.igst;
                        mrecords[item].cgst = mrecords[item].cgst + invoice.cgst;
                        mrecords[item].sgst = mrecords[item].sgst + invoice.sgst;
                        mrecords[item].cess = mrecords[item].cess + invoice.cess;
                    }
                });
                if (isFilter) {
                    if (apFillter.amin !== "") {
                        mrecords = mrecords.filter((invoice) => Moment(invoice.notedate, 'DD/MM/YYYY').isAfter(Moment(apFillter.amin)));
                    }
                    if (apFillter.amax !== "") {
                        mrecords = mrecords.filter((invoice) => Moment(invoice.notedate, 'DD/MM/YYYY').isBefore(Moment(apFillter.amax)));
                    }
                    if (apFillter.bmin !== "") {
                        mrecords = mrecords.filter((invoice) => Moment(invoice.fData, 'DD/MM/YYYY').isAfter(Moment(apFillter.bmin)));
                    }
                    if (apFillter.bmax !== "") {
                        mrecords = mrecords.filter((invoice) => Moment(invoice.fData, 'DD/MM/YYYY').isBefore(Moment(apFillter.bmax)));
                    }
                    if (apFillter.invtype !== "") {
                        if (apFillter.invtype === "R") {
                            mrecords = mrecords.filter((invoice) => invoice.notestype.includes('Regular'));
                        } else {
                            mrecords = mrecords.filter((invoice) => invoice.notestype.includes('NGRegular'));
                        }
                    }
                    if (apFillter.revc !== "") {
                        if (apFillter.revc === "Yes") {
                            mrecords = mrecords.filter((invoice) => invoice.revc.includes('Yes'));
                        } else {
                            mrecords = mrecords.filter((invoice) => invoice.revc.includes('No'));
                        }
                    }
                    if (apFillter.ITC !== "") {
                        if (apFillter.ITC === "Yes") {
                            mrecords = mrecords.filter((invoice) => invoice.ITC.includes('Yes'));
                        } else {
                            mrecords = mrecords.filter((invoice) => invoice.ITC.includes('No'));
                        }
                    }
                    if (apFillter.rtax !== "") {
                        if (apFillter.rtax === "100") {
                            mrecords = mrecords.filter((invoice) => invoice.PTax.includes('100%'));
                        } else {
                            mrecords = mrecords.filter((invoice) => invoice.PTax.includes('65%'));
                        }
                    }
                }
                if (querySearch.CDRB !== "") {
                    let value = querySearch.CDRB.toLowerCase();
                    mrecords = mrecords.filter((invoice) => invoice.gstn.toLowerCase().includes(value) || invoice.name.toLowerCase().includes(value) || invoice.notenum.toString().toLowerCase().includes(value) || invoice.notetype.toString().toLowerCase().includes(value) || invoice.notestype.toString().toLowerCase().includes(value) || invoice.notedate.toLowerCase().includes(value) || invoice.tax.toString().toLowerCase().includes(value) || invoice.igst.toString().toLowerCase().includes(value) || invoice.cgst.toString().toLowerCase().includes(value) || invoice.sgst.toString().toLowerCase().includes(value) || invoice.cess.toString().toLowerCase().includes(value) || invoice.peroid.toLowerCase().includes(value));
                }
                break;
            case 'IMPG':
                tempData = [...impg];
                tempData.forEach((invoice) => {
                    mrecords.push({ 'irDate': getYDate(invoice.irDate), 'portcode': invoice.portcode, 'num': invoice.num, 'date': getYDate(invoice.date), 'tax': invoice.taxVal, 'igst': invoice.igst, 'cess': invoice.cess });
                });
                if (isFilter) {
                    mrecords = mrecords.filter((invoice) => invoice.portcode.toString().includes('Regular'));
                    if (apFillter.amin !== "") {
                        mrecords = mrecords.filter((invoice) => Moment(invoice.date, 'DD/MM/YYYY').isAfter(Moment(apFillter.amin)));
                    }
                    if (apFillter.amax !== "") {
                        mrecords = mrecords.filter((invoice) => Moment(invoice.date, 'DD/MM/YYYY').isBefore(Moment(apFillter.amax)));
                    }
                }
                if (querySearch.IMPG !== "") {
                    let value = querySearch.IMPG.toLowerCase();
                    mrecords = mrecords.filter((invoice) => invoice.portcode.toLowerCase().includes(value) || invoice.num.toString().toLowerCase().includes(value) || invoice.irDate.toLowerCase().includes(value));
                }
                break;
        }
        return mrecords;
    }

    const getTabelTitle = () => {
        switch (cType) {
            case 'b2b':
                return "Taxable inward supplies received from registered person - B2B";
            case 'b2bA':
                return "Amendments to previously filed invoices by supplier - B2BA";
            case 'Cdr':
                return "Debit/Credit notes(Original) - B2B CDNR";
            case 'CdrA':
                return "Amendments to previously filed Credit/Debit notes by supplier - B2B CDNRA";
            case 'ISD':
                return "ISD Credits";
            case 'ISDA':
                return "Amendments ISD Credits received - ISDA";
            case 'IMPG':
                return "Import of goods from overseas on bill of entry - IMPG";
            case 'IMPS':
                return "Import of goods from SEZ units/developers on bill of entry - IMPGSEZ";
        }
    }

    const gotoTable = (type) => {
        setCType(type);
        switch (type) {
            case 'b2b':
                setIsTabE(true);
                setIsTabD(false);
                if (b2b.length > 0) {
                    setIsRow(true);
                } else {
                    setIsRow(false);
                }
                setSubRow(-1);
                removeAllFilter();
                break;
            case 'b2bA':
                setIsTabE(true);
                setIsTabD(false);
                if (b2ba.length > 0) {
                    setIsRow(true);
                } else {
                    setIsRow(false);
                }
                setSubRow(-1);
                removeAllFilter();
                break;
            case 'Cdr':
                setIsTabE(true);
                setIsTabD(false);
                if (b2bcdr.length > 0) {
                    setIsRow(true);
                } else {
                    setIsRow(false);
                }
                setSubRow(-1);
                removeAllFilter();
                break;
            case 'IMPG':
                setIsTabE(true);
                setIsTabD(false);
                if (impg.length > 0) {
                    setIsRow(true);
                } else {
                    setIsRow(false);
                }
                setSubRow(-1);
                removeAllFilter();
                break;
            default:
                setIsTabE(true);
                setIsTabD(true);
                setIsRow(false);
                break;
        }
        collapseAll();
        ncollapseAll();
        setIsTabC(true);
        setIsTabB(false);
    }

    const downloadExcel = async (type) => {
        let response = {};
        let data = getDocumentData(type);
        switch (type) {
            case 'b2b':
                response = await fetch(B2BSheet);
                break;
            case 'b2bA':
                response = await fetch(B2BASheet);
                break;
            case 'Cdr':
                response = await fetch(CDNRSheet);
                break;
            case 'IMPG':
                response = await fetch(IMPGSheet);
                break;
            case 'main':
                response = await fetch(MainSheet);
                break;
            default:
                response = await fetch(B2BSheet);
        }

        const fileBlob = await response.blob();

        const reader = new FileReader();
        reader.onload = function (e) {
            const bstr = e.target.result;
            (async function () {
                const workbook = new ExcelJS.Workbook();
                await workbook.xlsx.load(bstr);
                let rowNumber = 7;

                switch (type) {
                    case 'b2b':
                        data.forEach((item) => {
                            item.data.forEach((invs) => {
                                workbook.getWorksheet('B2B').addRow([item.gstn, item.name, item.invnum, item.invtype, item.invdate, item.invval, item.pos, item.revc, invs.rate, item.tax, item.igst, item.cgst, item.sgst, item.cess, item.peroid, item.fData, item.ITC, item.reason, item.PTax, item.source, item.irn, item.irnd], `A${rowNumber}`);
                                rowNumber++;
                            });
                        });
                        break;
                    case 'b2bA':
                        data.forEach((item) => {
                            item.data.forEach((invs) => {
                                workbook.getWorksheet('B2BA').addRow([item.oinvnum, item.oinvdate, item.gstn, item.name, item.invnum, item.invtype, item.invdate, item.invval, item.pos, item.revc, invs.rate, item.tax, item.igst, item.cgst, item.sgst, item.cess, item.peroid, item.fData, item.ITC, item.reason, item.PTax], `A${rowNumber}`);
                                rowNumber++;
                            });
                        });
                        break;
                    case 'Cdr':
                        data.forEach((item) => {
                            item.data.forEach((invs) => {
                                workbook.getWorksheet('B2B-CDNR').addRow([item.gstn, item.name, item.notenum, item.notetype, item.notestype, item.notedate, item.noteval, item.pos, item.revc, invs.rate, item.tax, item.igst, item.cgst, item.sgst, item.cess, item.peroid, item.fData, item.ITC, item.reason, item.PTax, item.source, item.irn, item.irnd], `A${rowNumber}`);
                                rowNumber++;
                            });
                        });
                        break;
                    case 'IMPG':
                        data.forEach((item) => {
                            workbook.getWorksheet('IMPG').addRow([item.irDate, item.portcode, item.num, item.date, item.tax, item.igst, item.cess, "No"], `A${rowNumber}`);
                            rowNumber++;
                        });
                        break;
                    case 'main':
                        rowNumber = 7;
                        let dataA = getDocumentData('b2b');
                        dataA.forEach((item) => {
                            item.data.forEach((invs) => {
                                workbook.getWorksheet('B2B').addRow([item.gstn, item.name, item.invnum, item.invtype, item.invdate, item.invval, item.pos, item.revc, invs.rate, item.tax, item.igst, item.cgst, item.sgst, item.cess, item.peroid, item.fData, item.ITC, item.reason, item.PTax, item.source, item.irn, item.irnd], `A${rowNumber}`);
                                rowNumber++;
                            });
                        });

                        rowNumber = 7;
                        let dataB = getDocumentData('b2bA');
                        dataB.forEach((item) => {
                            item.data.forEach((invs) => {
                                workbook.getWorksheet('B2BA').addRow([item.oinvnum, item.oinvdate, item.gstn, item.name, item.invnum, item.invtype, item.invdate, item.invval, item.pos, item.revc, invs.rate, item.tax, item.igst, item.cgst, item.sgst, item.cess, item.peroid, item.fData, item.ITC, item.reason, item.PTax], `A${rowNumber}`);
                                rowNumber++;
                            });
                        });

                        rowNumber = 7;
                        let dataC = getDocumentData('Cdr');
                        dataC.forEach((item) => {
                            item.data.forEach((invs) => {
                                workbook.getWorksheet('B2B-CDNR').addRow([item.gstn, item.name, item.notenum, item.notetype, item.notestype, item.notedate, item.noteval, item.pos, item.revc, invs.rate, item.tax, item.igst, item.cgst, item.sgst, item.cess, item.peroid, item.fData, item.ITC, item.reason, item.PTax, item.source, item.irn, item.irnd], `A${rowNumber}`);
                                rowNumber++;
                            });
                        });

                        rowNumber = 7;
                        let dataD = getDocumentData('IMPG');
                        dataD.forEach((item) => {
                            workbook.getWorksheet('IMPG').addRow([item.irDate, item.portcode, item.num, item.date, item.tax, item.igst, item.cess, "No"], `A${rowNumber}`);
                            rowNumber++;
                        });

                        const worksheet = workbook.getWorksheet('ITC Available');

                        worksheet.getCell('D9').value = getITCSummery('partA', 'totalIGST').toFixed(2);
                        worksheet.getCell('E9').value = getITCSummery('partA', 'totalCGST').toFixed(2);
                        worksheet.getCell('F9').value = getITCSummery('partA', 'totalSGST').toFixed(2);
                        worksheet.getCell('G9').value = getITCSummery('partA', 'totalCess').toFixed(2);

                        worksheet.getCell('D10').value = getITCSummery('partA', 'b2bIGST').toFixed(2);
                        worksheet.getCell('E10').value = getITCSummery('partA', 'b2bCGST').toFixed(2);
                        worksheet.getCell('F10').value = getITCSummery('partA', 'b2bSGST').toFixed(2);
                        worksheet.getCell('G10').value = getITCSummery('partA', 'b2bCess').toFixed(2);

                        worksheet.getCell('D11').value = getITCSummery('partA', 'b2bndIGST').toFixed(2);
                        worksheet.getCell('E11').value = getITCSummery('partA', 'b2bndCGST').toFixed(2);
                        worksheet.getCell('F11').value = getITCSummery('partA', 'b2bndSGST').toFixed(2);
                        worksheet.getCell('G11').value = getITCSummery('partA', 'b2bndCess').toFixed(2);

                        worksheet.getCell('D12').value = getITCSummery('partA', 'b2baIGST').toFixed(2);
                        worksheet.getCell('E12').value = getITCSummery('partA', 'b2baCGST').toFixed(2);
                        worksheet.getCell('F12').value = getITCSummery('partA', 'b2baSGST').toFixed(2);
                        worksheet.getCell('G12').value = getITCSummery('partA', 'b2baCess').toFixed(2);

                        worksheet.getCell('D17').value = getITCSummery('partA', 'totalIGSTr').toFixed(2);
                        worksheet.getCell('E17').value = getITCSummery('partA', 'totalCGSTr').toFixed(2);
                        worksheet.getCell('F17').value = getITCSummery('partA', 'totalSGSTr').toFixed(2);
                        worksheet.getCell('G17').value = getITCSummery('partA', 'totalCessr').toFixed(2);

                        worksheet.getCell('D18').value = getITCSummery('partA', 'b2bIGSTr').toFixed(2);
                        worksheet.getCell('E18').value = getITCSummery('partA', 'b2bCGSTr').toFixed(2);
                        worksheet.getCell('F18').value = getITCSummery('partA', 'b2bSGSTr').toFixed(2);
                        worksheet.getCell('G18').value = getITCSummery('partA', 'b2bCessr').toFixed(2);

                        worksheet.getCell('D19').value = getITCSummery('partA', 'b2bndIGSTr').toFixed(2);
                        worksheet.getCell('E19').value = getITCSummery('partA', 'b2bndCGSTr').toFixed(2);
                        worksheet.getCell('F19').value = getITCSummery('partA', 'b2bndSGSTr').toFixed(2);
                        worksheet.getCell('G19').value = getITCSummery('partA', 'b2bndCessr').toFixed(2);

                        worksheet.getCell('D20').value = getITCSummery('partA', 'b2baIGSTr').toFixed(2);
                        worksheet.getCell('E20').value = getITCSummery('partA', 'b2baCGSTr').toFixed(2);
                        worksheet.getCell('F20').value = getITCSummery('partA', 'b2baSGSTr').toFixed(2);
                        worksheet.getCell('G20').value = getITCSummery('partA', 'b2baCessr').toFixed(2);

                        let tempA = getITCSummery('partA', 'impgTotal').toFixed(2);
                        let tempB = getITCSummery('partA', 'impgCess').toFixed(2);

                        worksheet.getCell('D22').value = tempA;
                        worksheet.getCell('G22').value = tempB;

                        worksheet.getCell('D23').value = tempA;
                        worksheet.getCell('E23').value = tempB;

                        worksheet.getCell('D28').value = getITCSummery('partA', 'b2bncIGSTT').toFixed(2);
                        worksheet.getCell('E28').value = getITCSummery('partA', 'b2bncCGSTT').toFixed(2);
                        worksheet.getCell('F28').value = getITCSummery('partA', 'b2bncSGSTT').toFixed(2);
                        worksheet.getCell('G28').value = getITCSummery('partA', 'b2bncCessT').toFixed(2);

                        worksheet.getCell('D29').value = getITCSummery('partA', 'b2bncIGST').toFixed(2);
                        worksheet.getCell('E29').value = getITCSummery('partA', 'b2bncCGST').toFixed(2);
                        worksheet.getCell('F29').value = getITCSummery('partA', 'b2bncSGST').toFixed(2);
                        worksheet.getCell('G29').value = getITCSummery('partA', 'b2bncCess').toFixed(2);

                        worksheet.getCell('D31').value = getITCSummery('partA', 'b2bncIGSTr').toFixed(2);
                        worksheet.getCell('E31').value = getITCSummery('partA', 'b2bncCGSTr').toFixed(2);
                        worksheet.getCell('F31').value = getITCSummery('partA', 'b2bncSGSTr').toFixed(2);
                        worksheet.getCell('G31').value = getITCSummery('partA', 'b2bncCessr').toFixed(2);

                        const worksheetB = workbook.getWorksheet('ITC not available');

                        worksheetB.getCell('D9').value = getITCSummery('partB', 'totalIGST').toFixed(2);
                        worksheetB.getCell('E9').value = getITCSummery('partB', 'totalCGST').toFixed(2);
                        worksheetB.getCell('F9').value = getITCSummery('partB', 'totalSGST').toFixed(2);
                        worksheetB.getCell('G9').value = getITCSummery('partB', 'totalCess').toFixed(2);

                        worksheetB.getCell('D10').value = getITCSummery('partB', 'b2bIGST').toFixed(2);
                        worksheetB.getCell('E10').value = getITCSummery('partB', 'b2bCGST').toFixed(2);
                        worksheetB.getCell('F10').value = getITCSummery('partB', 'b2bSGST').toFixed(2);
                        worksheetB.getCell('G10').value = getITCSummery('partB', 'b2bCess').toFixed(2);

                        worksheetB.getCell('D11').value = getITCSummery('partB', 'b2bndIGST').toFixed(2);
                        worksheetB.getCell('E11').value = getITCSummery('partB', 'b2bndCGST').toFixed(2);
                        worksheetB.getCell('F11').value = getITCSummery('partB', 'b2bndSGST').toFixed(2);
                        worksheetB.getCell('G11').value = getITCSummery('partB', 'b2bndCess').toFixed(2);

                        worksheetB.getCell('D12').value = getITCSummery('partB', 'b2baIGST').toFixed(2);
                        worksheetB.getCell('E12').value = getITCSummery('partB', 'b2baCGST').toFixed(2);
                        worksheetB.getCell('F12').value = getITCSummery('partB', 'b2baSGST').toFixed(2);
                        worksheetB.getCell('G12').value = getITCSummery('partB', 'b2baCess').toFixed(2);

                        worksheetB.getCell('D17').value = getITCSummery('partB', 'totalIGSTr').toFixed(2);
                        worksheetB.getCell('E17').value = getITCSummery('partB', 'totalCGSTr').toFixed(2);
                        worksheetB.getCell('F17').value = getITCSummery('partB', 'totalSGSTr').toFixed(2);
                        worksheetB.getCell('G17').value = getITCSummery('partB', 'totalCessr').toFixed(2);

                        worksheetB.getCell('D18').value = getITCSummery('partB', 'b2bIGSTr').toFixed(2);
                        worksheetB.getCell('E18').value = getITCSummery('partB', 'b2bCGSTr').toFixed(2);
                        worksheetB.getCell('F18').value = getITCSummery('partB', 'b2bSGSTr').toFixed(2);
                        worksheetB.getCell('G18').value = getITCSummery('partB', 'b2bCessr').toFixed(2);

                        worksheetB.getCell('D19').value = getITCSummery('partB', 'b2bndIGSTr').toFixed(2);
                        worksheetB.getCell('E19').value = getITCSummery('partB', 'b2bndCGSTr').toFixed(2);
                        worksheetB.getCell('F19').value = getITCSummery('partB', 'b2bndSGSTr').toFixed(2);
                        worksheetB.getCell('G19').value = getITCSummery('partB', 'b2bndCessr').toFixed(2);

                        worksheetB.getCell('D20').value = getITCSummery('partB', 'b2baIGSTr').toFixed(2);
                        worksheetB.getCell('E20').value = getITCSummery('partB', 'b2baCGSTr').toFixed(2);
                        worksheetB.getCell('F20').value = getITCSummery('partB', 'b2baSGSTr').toFixed(2);
                        worksheetB.getCell('G20').value = getITCSummery('partB', 'b2baCessr').toFixed(2);

                        worksheetB.getCell('D23').value = getITCSummery('partB', 'b2bncIGSTT').toFixed(2);
                        worksheetB.getCell('E23').value = getITCSummery('partB', 'b2bncCGSTT').toFixed(2);
                        worksheetB.getCell('F23').value = getITCSummery('partB', 'b2bncSGSTT').toFixed(2);
                        worksheetB.getCell('G23').value = getITCSummery('partB', 'b2bncCessT').toFixed(2);

                        worksheetB.getCell('D24').value = getITCSummery('partB', 'b2bncIGST').toFixed(2);
                        worksheetB.getCell('E24').value = getITCSummery('partB', 'b2bncCGST').toFixed(2);
                        worksheetB.getCell('F24').value = getITCSummery('partB', 'b2bncSGST').toFixed(2);
                        worksheetB.getCell('G24').value = getITCSummery('partB', 'b2bncCess').toFixed(2);

                        worksheetB.getCell('D26').value = getITCSummery('partB', 'b2bncIGSTr').toFixed(2);
                        worksheetB.getCell('E26').value = getITCSummery('partB', 'b2bncCGSTr').toFixed(2);
                        worksheetB.getCell('F26').value = getITCSummery('partB', 'b2bncSGSTr').toFixed(2);
                        worksheetB.getCell('G26').value = getITCSummery('partB', 'b2bncCessr').toFixed(2);

                        const worksheetC = workbook.getWorksheet('Read me');

                        worksheetC.getCell('C4').value = `${getStartY()}-${getEndY()}`;
                        worksheetC.getCell('C5').value = `${Moment(gDate).format("MMMM")}`;
                        worksheetC.getCell('C6').value = GSTUser.gstn;
                        worksheetC.getCell('C7').value = `${GSTUser.buzName.toUpperCase()}`;
                        worksheetC.getCell('C8').value = `${GSTUser.tname.toUpperCase() == "" ? 'NA' : GSTUser.tname.toUpperCase()}`;
                        worksheetC.getCell('C9').value = `${Moment(gDate).add(13, 'days').format("DD/MM/YYYY")}`;

                        break;
                }

                const wbuffer = await workbook.xlsx.writeBuffer();
                const blob = new Blob([wbuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                const href = URL.createObjectURL(blob);
                if (type == 'main') {
                    saveAs(href, `${GSTUser.gstn}_GSTR2B.xlsx`);
                } else {
                    saveAs(href, `2B - ${type}.xlsx`);
                }
            })();
        };
        reader.readAsArrayBuffer(fileBlob);
    }

    const handleGeneratePDF = async () => {
        const subComponent = pdfRef.current;
        const options = {
            margin: 0.2,
            filename: `${GSTUser.gstn}_GSTR2B.pdf`,
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: { scale: 2 },
            jsPDF: { unit: 'mm', format: 'a4', orientation: 'landscape' },
            preserve: true,
        };
        html2pdf().set(options).from(subComponent).save().then(() => {
            setIsPrint(false);
        });
    };

    useEffect(() => {
        loadData();
    }, []);

    return (
        <>
            <div _ngcontent-gwq-c0="" className="bread-trans">
                <app-breadcrumb _ngcontent-gwq-c0="" _nghost-gwq-c3="">
                    <div _ngcontent-gwq-c3="" className="container">
                        <div _ngcontent-gwq-c3="" className="col-md-12 noPaddingLR">
                            <div _ngcontent-gwq-c3="" className="col-md-9 breadcrumb">
                                <ul _ngcontent-gwq-c3="" className="noPaddingLR">
                                    <li _ngcontent-gwq-c3="" className="breadcrumb-item" onClick={() => {
                                        gotoPath('gstr')
                                    }}>
                                        <a _ngcontent-gwq-c3="" className="breadcrumb-font"> Dashboard </a>
                                    </li>
                                    <li _ngcontent-gwq-c3="" className="breadcrumb-item" onClick={() => {
                                        gotoPath('gstRetrun')
                                    }}>
                                        <a _ngcontent-gwq-c3="" className="breadcrumb-font"> Returns </a>
                                    </li>
                                    <li _ngcontent-gwq-c3="" className="breadcrumb-item">
                                        <span _ngcontent-gwq-c3="" className="breadcrumb-font"> GSTR-2B</span>
                                    </li>
                                </ul>
                            </div>
                            <div _ngcontent-gwq-c3="" className="col-md-3 marginT10">
                                <div _ngcontent-gwq-c3="" className="lang dropdown">
                                    <span _ngcontent-gwq-c3="" className="dropdown-toggle" data-toggle="dropdown">English</span>
                                    <ul _ngcontent-gwq-c3="" className="dropdown-menu lang-dpdwn">
                                        <li _ngcontent-gwq-c3=""><a _ngcontent-gwq-c3="">English</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </app-breadcrumb>
            </div>
            <div _ngcontent-gwq-c0="" className="container">
                <app-gstr2b _nghost-gwq-c5="">
                    <div _ngcontent-gwq-c5="" className="container">
                        <div _ngcontent-gwq-c5="" className="row invsumm">
                            <div _ngcontent-gwq-c5="" className="col-xs-12 taxp cursor-pointer" data-target="#userdetails" data-toggle="collapse" onClick={() => { setIsTabA(!isTabA) }}>  GSTR-2B- AUTO-DRAFTED ITC STATEMENT
                                {!isTabA && <i _ngcontent-gwq-c5="" className="fa fa-fw fa-chevron-down pull-right"></i>}
                                {isTabA && <i _ngcontent-gwq-c5="" className="fa fa-fw fa-chevron-up pull-right"></i>}
                            </div>
                        </div>
                        {
                            isTabA &&
                            <div _ngcontent-gwq-c5="" className="mTab" id="userdetails">
                                <div _ngcontent-gwq-c5="" className="row">
                                    <div _ngcontent-gwq-c5="" className="col-md-4">
                                        <span _ngcontent-gwq-c5="">GSTIN- {GSTUser.gstn}</span>
                                    </div>
                                    <div _ngcontent-gwq-c5="" className="col-md-4"><span _ngcontent-gwq-c5="">Legal Name - {GSTUser.buzName.toUpperCase()}</span></div>
                                    <div _ngcontent-gwq-c5="" className="col-md-4"><span _ngcontent-gwq-c5="">Trade Name - {GSTUser.tname.toUpperCase() == "" ? 'NA' : GSTUser.tname.toUpperCase()}</span></div>
                                </div><div _ngcontent-gwq-c5="" className="row">
                                    <div _ngcontent-gwq-c5="" className="col-md-4">
                                        <span _ngcontent-gwq-c5="">Financial Year - {getStartY()}-{getEndY()}</span>
                                    </div>
                                    <div _ngcontent-gwq-c5="" className="col-md-4">
                                        <span _ngcontent-gwq-c5="">Return Period - {Moment(gDate).format("MMMM")}</span>
                                    </div>
                                    <div _ngcontent-gwq-c5="" className="col-md-4"><span _ngcontent-gwq-c5="">Generation date - {Moment(gDate).add(1, 'month').add(13, 'days').format("DD/MM/yyyy")}</span>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                    <br _ngcontent-gwq-c5="" />
                    <div _ngcontent-gwq-c5="" className="container">
                        <div _ngcontent-gwq-c5="" className="tabpane">
                            <ul _ngcontent-gwq-c5="" className="nav nav-tabs">
                                <li _ngcontent-gwq-c5="" class={`nav-item ${isTabB ? 'active' : ''}`}>
                                    <a _ngcontent-gwq-c5="" data-toggle="pill" class={`nav-link ${isTabB ? 'active' : ''}`} onClick={() => {
                                        setIsTabB(true);
                                        setIsTabD(false);
                                        setSelGST({ 'gst': '', 'name': '' });
                                        setSubRow(-1);
                                        setCType('b2b');
                                        if (b2b.length > 0) {
                                            setIsRow(true);
                                        } else {
                                            setIsRow(false);
                                        }
                                    }}>SUMMARY</a></li>
                                <li _ngcontent-gwq-c5="" class={`nav-item ${isTabB ? '' : 'active'}`}>
                                    <a _ngcontent-gwq-c5="" data-toggle="pill" class={`nav-link ${isTabB ? '' : 'active'}`} onClick={() => {
                                        collapseAll();
                                        ncollapseAll();
                                        setIsTabC(true);
                                        setIsTabB(false);
                                        setIsTabD(false);
                                        setIsTabE(true);
                                        setCType('b2b');
                                        if (b2b.length > 0) {
                                            setIsRow(true);
                                        } else {
                                            setIsRow(false);
                                        }
                                    }}>ALL TABLES</a></li>
                                <li _ngcontent-gwq-c5="" className="pull-right"><a _ngcontent-gwq-c5="" data-backdrop="static" data-keyboard="false" data-toggle="modal" onClick={() => {
                                    downloadAdvice("https://storage.googleapis.com/itaa-prod/docs/GSTR-2B_Advisory.pdf");
                                }}>View Advisory</a></li>
                            </ul>
                            <div _ngcontent-gwq-c5="" className="empty"></div>
                            <div _ngcontent-gwq-c5="">
                                <router-outlet _ngcontent-gwq-c5=""></router-outlet>
                                {
                                    isTabB && <app-summary _nghost-gwq-c7="">
                                        <block-ui >
                                            <div className="tab-content ">
                                                <div className="row">
                                                    <div className="col-sm-12 col-md-12 col-lg-12">
                                                    </div>
                                                </div>
                                                <div className='mt-4'></div>
                                                <div className="row !m-[15px]" style={{ marginTop: '15px !important' }}>
                                                    <div className="col-sm-12 col-md-12 col-lg-12">
                                                        <div >
                                                            <div className="empty"></div>
                                                            <div className="empty"></div>
                                                            <div className="row">
                                                                <div className="">
                                                                    <ul _ngcontent-gwq-c7="" className="nav nav-pills" id="myTab" role="tablist">
                                                                        <li _ngcontent-gwq-c7="" class={`nav-item ${isTabC ? 'active' : ''}`} onClick={() => {
                                                                            setIsTabC(true);
                                                                        }}>
                                                                            <a _ngcontent-gwq-c7="" className="nav-link !rounded-md border-right green">ITC available</a>
                                                                        </li>
                                                                        <li _ngcontent-gwq-c7="" class={`nav-item ${isTabC ? '' : 'active'}`} onClick={() => {
                                                                            setIsTabC(false);
                                                                        }}>
                                                                            <a _ngcontent-gwq-c7="" className="nav-link !rounded-md border-right red">ITC not available</a>
                                                                        </li>
                                                                        <span _ngcontent-gwq-c7="" className="pull-right">
                                                                            <button _ngcontent-gwq-c7="" className="btn-help" onClick={() => {
                                                                                gotoURL("https://tutorial.gst.gov.in/contextualhelp/gstr2biff/index.htm#t=GSTR-2B_Summary_Page.htm");
                                                                            }}>HELP <i className="fa fa-question-circle" _ngcontent-gwq-c7="" style={{ color: 'white', marginLeft: '3px' }}></i>
                                                                            </button>
                                                                            &nbsp;&nbsp;
                                                                        </span>
                                                                    </ul>
                                                                    <div className="empty"></div>
                                                                    <div className="tab-content">
                                                                        <div aria-labelledby="itca1-tab" className="tab-pane border-0 active" id="itca1" role="tabpanel">
                                                                            <div className="empty"></div>
                                                                            {
                                                                                isTabC && <div className="row">
                                                                                    <div className="">
                                                                                        <div className="table-responsive">
                                                                                            <table className="table table-bordered">
                                                                                                <thead >
                                                                                                    <tr >
                                                                                                        <th className="thgreen !text-left" nowrap="">S.NO. </th>
                                                                                                        <th className="thgreen !text-left" nowrap=""> Heading&nbsp;
                                                                                                            {
                                                                                                                !exMain && <a onClick={() => {
                                                                                                                    collapseAll();
                                                                                                                }}> [<span >Collapse All</span>&nbsp;<i className="fa fa-chevron-down"></i>] &nbsp;</a>
                                                                                                            }
                                                                                                            {
                                                                                                                exMain && <a onClick={() => {
                                                                                                                    ExpandAll();
                                                                                                                }}> [<span >Expand All</span>&nbsp;<i className="fa fa-chevron-up"></i>] &nbsp;</a>
                                                                                                            }
                                                                                                        </th>
                                                                                                        <th className="thgreen" nowrap="">GSTR-3B table </th>
                                                                                                        <th className="thgreen" nowrap="" style={{ width: '3%', textAlign: 'center' }}> Integrated <br />Tax (₹) </th>
                                                                                                        <th className="thgreen" nowrap="" style={{ width: '13%', textAlign: 'center' }}> Central <br />Tax (₹) </th>
                                                                                                        <th className="thgreen" nowrap="" style={{ width: '13%', textAlign: 'center' }}> State/UT <br />Tax (₹) </th>
                                                                                                        <th className="thgreen" nowrap="" style={{ width: '9%', textAlign: 'center' }}> Cess (₹) </th>
                                                                                                    </tr>
                                                                                                </thead>
                                                                                                <tbody >
                                                                                                    <tr >
                                                                                                        <td className="rev" nowrap="">Part A</td>
                                                                                                        <td colspan="6" nowrap="">ITC Available - Credit may be claimed in relevant headings in GSTR-3B</td>
                                                                                                    </tr>
                                                                                                    <tr >
                                                                                                        <td className="rev" nowrap="">I</td>
                                                                                                        <td className="text-left" nowrap="">All other ITC - Supplies from registered persons
                                                                                                            <a onClick={() => {
                                                                                                                setExA(!exA);
                                                                                                            }}>
                                                                                                                {exA && <i className="fa fa-fw fa-chevron-down" style={{ color: 'black', marginLeft: '5px' }}></i>}
                                                                                                                {!exA && <i className="fa fa-fw fa-chevron-up" style={{ color: 'black', marginLeft: '5px' }}></i>}
                                                                                                            </a>
                                                                                                        </td>
                                                                                                        <td className="rev" nowrap="">4(A)(5)
                                                                                                            <Tippy content="Net input tax credit may be availed under Table 4(A)(5) of FORM GSTR-3B." allowHTML={true} placement='top' className='text-center'>
                                                                                                                <i className="fa fa-info-circle" data-content="html" placement="bottom" tooltip=""></i>
                                                                                                            </Tippy>
                                                                                                        </td>
                                                                                                        <td className="text-right" nowrap="">{getITCSummery('partA', 'totalIGST').toFixed(2)}</td>
                                                                                                        <td className="text-right" nowrap="">{getITCSummery('partA', 'totalCGST').toFixed(2)}</td>
                                                                                                        <td className="text-right" nowrap="">{getITCSummery('partA', 'totalSGST').toFixed(2)}</td>
                                                                                                        <td className="text-right" nowrap="">{getITCSummery('partA', 'totalCess').toFixed(2)}</td>
                                                                                                    </tr>
                                                                                                    {
                                                                                                        !exA && <>
                                                                                                            <tr >
                                                                                                                <td rowspan="4"></td>
                                                                                                                <td className="text-left" colspan="2" nowrap="">
                                                                                                                    {
                                                                                                                        getITCISRecors('partA', 'b2bIGST') ? <a onClick={() => { gotoTable('b2b') }}>B2B - Invoices</a> : <Tippy content="No Records" allowHTML={true} placement='top' className='text-center'>
                                                                                                                            <a onClick={() => { gotoTable('b2b') }}>B2B - Invoices</a>
                                                                                                                        </Tippy>
                                                                                                                    }
                                                                                                                </td>
                                                                                                                <td className="text-right" nowrap="">{getITCSummery('partA', 'b2bIGST').toFixed(2)}</td>
                                                                                                                <td className="text-right" nowrap="">{getITCSummery('partA', 'b2bCGST').toFixed(2)}</td>
                                                                                                                <td className="text-right" nowrap="">{getITCSummery('partA', 'b2bSGST').toFixed(2)}</td>
                                                                                                                <td className="text-right" nowrap="">{getITCSummery('partA', 'b2bCess').toFixed(2)}</td>
                                                                                                            </tr>
                                                                                                            <tr >
                                                                                                                <td className="text-left" colspan="2" nowrap="">
                                                                                                                    {
                                                                                                                        getITCISRecors('partA', 'b2bndIGST') ? <a data-content="html" placement="bottom" onClick={() => { gotoTable('Cdr') }}>B2B - Debit notes</a> : <Tippy content="No Records" allowHTML={true} placement='top' className='text-center'>
                                                                                                                            <a data-content="html" placement="bottom" onClick={() => { gotoTable('Cdr') }}>B2B - Debit notes</a>
                                                                                                                        </Tippy>
                                                                                                                    }
                                                                                                                </td>
                                                                                                                <td className="text-right" nowrap="">{getITCSummery('partA', 'b2bndIGST').toFixed(2)}</td>
                                                                                                                <td className="text-right" nowrap="">{getITCSummery('partA', 'b2bndCGST').toFixed(2)}</td>
                                                                                                                <td className="text-right" nowrap="">{getITCSummery('partA', 'b2bndSGST').toFixed(2)}</td>
                                                                                                                <td className="text-right" nowrap="">{getITCSummery('partA', 'b2bndCess').toFixed(2)}</td>
                                                                                                            </tr>
                                                                                                            <tr >
                                                                                                                <td className="text-left" colspan="2" nowrap="">
                                                                                                                    {
                                                                                                                        getITCISRecors('partA', 'b2baIGST') ? <a data-content="html" placement="bottom" onClick={() => { gotoTable('b2bA') }}>B2B - Invoices (Amendment)</a> : <Tippy content="No Records" allowHTML={true} placement='top' className='text-center'>
                                                                                                                            <a data-content="html" placement="bottom" onClick={() => { gotoTable('b2bA') }}>B2B - Invoices (Amendment)</a>
                                                                                                                        </Tippy>
                                                                                                                    }
                                                                                                                </td>
                                                                                                                <td className="text-right" nowrap="">{getITCSummery('partA', 'b2baIGST').toFixed(2)}</td>
                                                                                                                <td className="text-right" nowrap="">{getITCSummery('partA', 'b2baCGST').toFixed(2)}</td>
                                                                                                                <td className="text-right" nowrap="">{getITCSummery('partA', 'b2baSGST').toFixed(2)}</td>
                                                                                                                <td className="text-right" nowrap="">{getITCSummery('partA', 'b2baCess').toFixed(2)}</td>
                                                                                                            </tr>
                                                                                                            <tr >
                                                                                                                <td className="text-left" colspan="2" nowrap="">
                                                                                                                    {
                                                                                                                        getITCISRecors('partA', 'A') ? <a data-content="html" placement="bottom"> B2B - Debit notes (Amendment)</a> : <Tippy content="No Records" allowHTML={true} placement='top' className='text-center'>
                                                                                                                            <a data-content="html" placement="bottom"> B2B - Debit notes (Amendment)</a>
                                                                                                                        </Tippy>
                                                                                                                    }
                                                                                                                </td>
                                                                                                                <td className="text-right" nowrap="">{getITCSummery('partA', 'A').toFixed(2)}</td>
                                                                                                                <td className="text-right" nowrap="">{getITCSummery('partA', 'A').toFixed(2)}</td>
                                                                                                                <td className="text-right" nowrap="">{getITCSummery('partA', 'A').toFixed(2)}</td>
                                                                                                                <td className="text-right" nowrap="">{getITCSummery('partA', 'A').toFixed(2)}</td>
                                                                                                            </tr>
                                                                                                        </>
                                                                                                    }
                                                                                                    <tr >
                                                                                                        <td className="rev" nowrap="">II</td>
                                                                                                        <td className="text-left" nowrap="">Inward Supplies from ISD
                                                                                                            <a onClick={() => {
                                                                                                                setExB(!exB);
                                                                                                            }}>
                                                                                                                {exB && <i className="fa fa-fw fa-chevron-down" style={{ color: 'black', marginLeft: '5px' }} ></i>}
                                                                                                                {!exB && <i className="fa fa-fw fa-chevron-up" style={{ color: 'black', marginLeft: '5px' }} ></i>}
                                                                                                            </a>
                                                                                                        </td>
                                                                                                        <td className="rev" nowrap="">4(A)(4)
                                                                                                            <Tippy content="Net input tax credit may be availed under Table 4(A)(4) of FORM GSTR-3B." allowHTML={true} placement='top' className='text-center'>
                                                                                                                <i className="fa fa-info-circle" data-content="html" placement="bottom" tooltip=""></i>
                                                                                                            </Tippy>
                                                                                                        </td>
                                                                                                        <td className="text-right" nowrap="">0.00</td>
                                                                                                        <td className="text-right" nowrap="">0.00</td>
                                                                                                        <td className="text-right" nowrap="">0.00</td>
                                                                                                        <td className="text-right" nowrap="">0.00</td>
                                                                                                    </tr>
                                                                                                    {
                                                                                                        !exB && <>
                                                                                                            <tr >
                                                                                                                <td rowspan="2"></td>
                                                                                                                <td className="text-left" colspan="2" nowrap="">
                                                                                                                    <Tippy content="No Records" allowHTML={true} placement='top' className='text-center'>
                                                                                                                        <a className="disabled" data-content="html" placement="bottom">ISD - Invoices</a>
                                                                                                                    </Tippy>
                                                                                                                </td>
                                                                                                                <td className="text-right" nowrap="">0.00</td>
                                                                                                                <td className="text-right" nowrap="">0.00</td>
                                                                                                                <td className="text-right" nowrap="">0.00</td>
                                                                                                                <td className="text-right" nowrap="">0.00</td>
                                                                                                            </tr>
                                                                                                            <tr >
                                                                                                                <td className="text-left" colspan="2" nowrap="">
                                                                                                                    <Tippy content="No Records" allowHTML={true} placement='top' className='text-center'>
                                                                                                                        <a className="disabled" data-content="html" placement="bottom">ISD - Invoices (Amendment)</a>
                                                                                                                    </Tippy>
                                                                                                                </td>
                                                                                                                <td className="text-right" nowrap="">0.00</td>
                                                                                                                <td className="text-right" nowrap="">0.00</td>
                                                                                                                <td className="text-right" nowrap="">0.00</td>
                                                                                                                <td className="text-right" nowrap="">0.00</td>
                                                                                                            </tr>
                                                                                                        </>
                                                                                                    }
                                                                                                    <tr >
                                                                                                        <td className="rev" nowrap="">III</td>
                                                                                                        <td className="text-left" nowrap="">Inward Supplies liable for reverse charge
                                                                                                            <a onClick={() => {
                                                                                                                setExC(!exC);
                                                                                                            }}>
                                                                                                                {exC && <i className="fa fa-fw fa-chevron-down" style={{ color: 'black', marginLeft: '5px' }}></i>}
                                                                                                                {!exC && <i className="fa fa-fw fa-chevron-up" style={{ color: 'black', marginLeft: '5px' }}></i>}
                                                                                                            </a>
                                                                                                        </td>
                                                                                                        <td className="rev" nowrap="">3.1(d) <span style={{ paddingLeft: '4px' }}>4(A)(3)</span>
                                                                                                            <Tippy content="These supplies shall be declared in Table 3.1(d) of FORM GSTR-3B for payment of tax. Net input tax credit may be availed under Table 4(A)(3) of FORM GSTR-3B on payment of tax." allowHTML={true} placement='top' className='text-center'>
                                                                                                                <i className="fa fa-info-circle" data-content="html" placement="bottom" tooltip=""></i>
                                                                                                            </Tippy>
                                                                                                        </td>
                                                                                                        <td className="text-right" nowrap="">{getITCSummery('partA', 'totalIGSTr').toFixed(2)}</td>
                                                                                                        <td className="text-right" nowrap="">{getITCSummery('partA', 'totalCGSTr').toFixed(2)}</td>
                                                                                                        <td className="text-right" nowrap="">{getITCSummery('partA', 'totalSGSTr').toFixed(2)}</td>
                                                                                                        <td className="text-right" nowrap="">{getITCSummery('partA', 'totalCessr').toFixed(2)}</td>
                                                                                                    </tr>
                                                                                                    {
                                                                                                        !exC && <>
                                                                                                            <tr >
                                                                                                                <td rowspan="4"></td>
                                                                                                                <td className="text-left" colspan="2" nowrap="">
                                                                                                                    {
                                                                                                                        getITCISRecors('partA', 'b2bIGSTr') ? <a data-content="html" placement="bottom" onClick={() => { gotoTable('b2b') }}>B2B - Invoices</a> : <Tippy content="No Records" allowHTML={true} placement='top' className='text-center'>
                                                                                                                            <a data-content="html" placement="bottom" onClick={() => { gotoTable('b2b') }}>B2B - Invoices</a>
                                                                                                                        </Tippy>
                                                                                                                    }
                                                                                                                </td>
                                                                                                                <td className="text-right" nowrap="">{getITCSummery('partA', 'b2bIGSTr').toFixed(2)}</td>
                                                                                                                <td className="text-right" nowrap="">{getITCSummery('partA', 'b2bCGSTr').toFixed(2)}</td>
                                                                                                                <td className="text-right" nowrap="">{getITCSummery('partA', 'b2bSGSTr').toFixed(2)}</td>
                                                                                                                <td className="text-right" nowrap="">{getITCSummery('partA', 'b2bCessr').toFixed(2)}</td>
                                                                                                            </tr>
                                                                                                            <tr >
                                                                                                                <td className="text-left" colspan="2" nowrap="">
                                                                                                                    {
                                                                                                                        getITCISRecors('partA', 'b2bndIGSTr') ? <a data-content="html" placement="bottom" onClick={() => { gotoTable('Cdr') }}>B2B - Debit notes</a> : <Tippy content="No Records" allowHTML={true} placement='top' className='text-center'>
                                                                                                                            <a data-content="html" placement="bottom" onClick={() => { gotoTable('Cdr') }}>B2B - Debit notes</a>
                                                                                                                        </Tippy>
                                                                                                                    }
                                                                                                                </td>
                                                                                                                <td className="text-right" nowrap="">{getITCSummery('partA', 'b2bndIGSTr').toFixed(2)}</td>
                                                                                                                <td className="text-right" nowrap="">{getITCSummery('partA', 'b2bndCGSTr').toFixed(2)}</td>
                                                                                                                <td className="text-right" nowrap="">{getITCSummery('partA', 'b2bndSGSTr').toFixed(2)}</td>
                                                                                                                <td className="text-right" nowrap="">{getITCSummery('partA', 'b2bndCessr').toFixed(2)}</td>
                                                                                                            </tr>
                                                                                                            <tr >
                                                                                                                <td className="text-left" colspan="2" nowrap="">
                                                                                                                    {
                                                                                                                        getITCISRecors('partA', 'b2baIGSTr') ? <a data-content="html" placement="bottom" onClick={() => { gotoTable('b2bA') }}>B2B - Invoices (Amendment)</a> : <Tippy content="No Records" allowHTML={true} placement='top' className='text-center'>
                                                                                                                            <a data-content="html" placement="bottom" onClick={() => { gotoTable('b2bA') }}>B2B - Invoices (Amendment)</a>
                                                                                                                        </Tippy>
                                                                                                                    }
                                                                                                                </td>
                                                                                                                <td className="text-right" nowrap="">{getITCSummery('partA', 'b2baIGSTr').toFixed(2)}</td>
                                                                                                                <td className="text-right" nowrap="">{getITCSummery('partA', 'b2baCGSTr').toFixed(2)}</td>
                                                                                                                <td className="text-right" nowrap="">{getITCSummery('partA', 'b2baSGSTr').toFixed(2)}</td>
                                                                                                                <td className="text-right" nowrap="">{getITCSummery('partA', 'b2baCessr').toFixed(2)}</td>
                                                                                                            </tr>
                                                                                                            <tr >
                                                                                                                <td className="text-left" colspan="2" nowrap="">
                                                                                                                    <Tippy content="No Records" allowHTML={true} placement='top' className='text-center'>
                                                                                                                        <a data-content="html" placement="bottom">B2B - Debit notes (Amendment)</a>
                                                                                                                    </Tippy>
                                                                                                                </td>
                                                                                                                <td className="text-right" nowrap="">{getITCSummery('partA', 'A').toFixed(2)}</td>
                                                                                                                <td className="text-right" nowrap="">{getITCSummery('partA', 'A').toFixed(2)}</td>
                                                                                                                <td className="text-right" nowrap="">{getITCSummery('partA', 'A').toFixed(2)}</td>
                                                                                                                <td className="text-right" nowrap="">{getITCSummery('partA', 'A').toFixed(2)}</td>
                                                                                                            </tr>
                                                                                                        </>
                                                                                                    }
                                                                                                    <tr >
                                                                                                        <td className="rev" nowrap="">IV</td>
                                                                                                        <td className="text-left" nowrap="">Import of Goods
                                                                                                            <a onClick={() => {
                                                                                                                setExD(!exD);
                                                                                                            }}>
                                                                                                                {exD && <i className="fa fa-fw fa-chevron-down" style={{ color: 'black', marginLeft: '5px' }}></i>}
                                                                                                                {!exD && <i className="fa fa-fw fa-chevron-up" style={{ color: 'black', marginLeft: '5px' }}></i>}
                                                                                                            </a>
                                                                                                        </td>
                                                                                                        <td className="rev" nowrap="">4(A)(1)
                                                                                                            <Tippy content="Net input tax credit may be availed under Table 4(A)(1) of FORM GSTR-3B." allowHTML={true} placement='top' className='text-center'>
                                                                                                                <i className="fa fa-info-circle" data-content="html" placement="bottom" tooltip=""></i>
                                                                                                            </Tippy>
                                                                                                        </td>
                                                                                                        <td className="text-right" nowrap="">{getITCSummery('partA', 'impgTotal').toFixed(2)}</td>
                                                                                                        <td className="text-right" nowrap="">{getITCSummery('partA', 'A').toFixed(2)}</td>
                                                                                                        <td className="text-right" nowrap="">{getITCSummery('partA', 'A').toFixed(2)}</td>
                                                                                                        <td className="text-right" nowrap="">{getITCSummery('partA', 'impgCess').toFixed(2)}</td>
                                                                                                    </tr>
                                                                                                    {
                                                                                                        !exD && <>
                                                                                                            <tr >
                                                                                                                <td rowspan="4"></td>
                                                                                                                <td className="text-left" colspan="2" nowrap="">
                                                                                                                    {
                                                                                                                        getITCISRecors('partA', 'impgTotal') ? <a data-content="html" placement="bottom" onClick={() => { gotoTable('IMPG') }}>IMPG - Import of goods from overseas</a> : <Tippy content="No Records" allowHTML={true} placement='top' className='text-center'>
                                                                                                                            <a data-content="html" placement="bottom" onClick={() => { gotoTable('IMPG') }}>IMPG - Import of goods from overseas</a>
                                                                                                                        </Tippy>
                                                                                                                    }
                                                                                                                </td>
                                                                                                                <td className="text-right" nowrap="">{getITCSummery('partA', 'impgTotal').toFixed(2)}</td>
                                                                                                                <td className="text-right" nowrap="">{getITCSummery('partA', 'A').toFixed(2)}</td>
                                                                                                                <td className="text-right" nowrap="">{getITCSummery('partA', 'A').toFixed(2)}</td>
                                                                                                                <td className="text-right" nowrap="">{getITCSummery('partA', 'impgCess').toFixed(2)}</td>
                                                                                                            </tr>
                                                                                                            <tr >
                                                                                                                <td className="text-left" colspan="2" nowrap="">
                                                                                                                    <Tippy content="No Records" allowHTML={true} placement='top' className='text-center'>
                                                                                                                        <a className="disabled" data-content="html" placement="bottom">IMPG (Amendment)</a>
                                                                                                                    </Tippy>
                                                                                                                </td>
                                                                                                                <td className="text-right" nowrap="">{getITCSummery('partA', 'A').toFixed(2)}</td>
                                                                                                                <td className="text-right" nowrap="">{getITCSummery('partA', 'A').toFixed(2)}</td>
                                                                                                                <td className="text-right" nowrap="">{getITCSummery('partA', 'A').toFixed(2)}</td>
                                                                                                                <td className="text-right" nowrap="">{getITCSummery('partA', 'A').toFixed(2)}</td>
                                                                                                            </tr>
                                                                                                            <tr >
                                                                                                                <td className="text-left" colspan="2" nowrap="">
                                                                                                                    <Tippy content="No Records" allowHTML={true} placement='top' className='text-center'>
                                                                                                                        <a className="disabled" data-content="html" placement="bottom">IMPGSEZ - Import of goods from SEZ</a>
                                                                                                                    </Tippy>
                                                                                                                </td>
                                                                                                                <td className="text-right" nowrap="">{getITCSummery('partA', 'A').toFixed(2)}</td>
                                                                                                                <td className="text-right" nowrap="">{getITCSummery('partA', 'A').toFixed(2)}</td>
                                                                                                                <td className="text-right" nowrap="">{getITCSummery('partA', 'A').toFixed(2)}</td>
                                                                                                                <td className="text-right" nowrap="">{getITCSummery('partA', 'A').toFixed(2)}</td>
                                                                                                            </tr>
                                                                                                            <tr >
                                                                                                                <td className="text-left" colspan="2" nowrap="">
                                                                                                                    <Tippy content="No Records" allowHTML={true} placement='top' className='text-center'>
                                                                                                                        <a className="disabled" data-content="html" placement="bottom">IMPGSEZ (Amendment)</a>
                                                                                                                    </Tippy>
                                                                                                                </td>
                                                                                                                <td className="text-right" nowrap="">{getITCSummery('partA', 'A').toFixed(2)}</td>
                                                                                                                <td className="text-right" nowrap="">{getITCSummery('partA', 'A').toFixed(2)}</td>
                                                                                                                <td className="text-right" nowrap="">{getITCSummery('partA', 'A').toFixed(2)}</td>
                                                                                                                <td className="text-right" nowrap="">{getITCSummery('partA', 'A').toFixed(2)}</td>
                                                                                                            </tr>
                                                                                                        </>
                                                                                                    }
                                                                                                    <tr >
                                                                                                        <td >Part B</td>
                                                                                                        <td colspan="6">ITC Reversal - Credit may be reversed in relevant headings in GSTR-3B</td>
                                                                                                    </tr>
                                                                                                    <tr >
                                                                                                        <td className="rev" nowrap="">I</td>
                                                                                                        <td className="text-left" nowrap="">
                                                                                                            Others
                                                                                                            <a onClick={() => {
                                                                                                                setExE(!exE);
                                                                                                            }}>
                                                                                                                {exE && <i className="fa fa-fw fa-chevron-down" style={{ color: 'black', marginLeft: '5px' }}></i>}
                                                                                                                {!exE && <i className="fa fa-fw fa-chevron-up" style={{ color: 'black', marginLeft: '5px' }}></i>}
                                                                                                            </a>
                                                                                                        </td>
                                                                                                        <td className="rev" nowrap="">4(B)(2)
                                                                                                            <Tippy content="Credit Notes should be net-off against relevant ITC available tables [Table 4A(3,4,5)]." allowHTML={true} placement='top' className='text-center'>
                                                                                                                <i className="fa fa-info-circle" data-content="html" placement="bottom" tooltip=""></i>
                                                                                                            </Tippy>
                                                                                                        </td>
                                                                                                        <td className="text-right" nowrap="">{getITCSummery('partA', 'b2bncIGSTT').toFixed(2)}</td>
                                                                                                        <td className="text-right" nowrap="">{getITCSummery('partA', 'b2bncCGSTT').toFixed(2)}</td>
                                                                                                        <td className="text-right" nowrap="">{getITCSummery('partA', 'b2bncSGSTT').toFixed(2)}</td>
                                                                                                        <td className="text-right" nowrap="">{getITCSummery('partA', 'b2bncCessT').toFixed(2)}</td>
                                                                                                    </tr>
                                                                                                    {
                                                                                                        !exE && <>
                                                                                                            <tr >
                                                                                                                <td rowspan="2"></td>
                                                                                                                <td className="text-left" colspan="2" nowrap="">
                                                                                                                    {
                                                                                                                        getITCISRecors('partA', 'b2bncIGST') ? <a data-content="html" placement="bottom" onClick={() => { gotoTable('Cdr') }}>B2B - Credit Notes</a> : <Tippy content="No Records" allowHTML={true} placement='top' className='text-center'>
                                                                                                                            <a data-content="html" placement="bottom" onClick={() => { gotoTable('Cdr') }}>B2B - Credit Notes</a>
                                                                                                                        </Tippy>
                                                                                                                    }
                                                                                                                </td>
                                                                                                                <td className="text-right" nowrap="">{getITCSummery('partA', 'b2bncIGST').toFixed(2)}</td>
                                                                                                                <td className="text-right" nowrap="">{getITCSummery('partA', 'b2bncCGST').toFixed(2)}</td>
                                                                                                                <td className="text-right" nowrap="">{getITCSummery('partA', 'b2bncSGST').toFixed(2)}</td>
                                                                                                                <td className="text-right" nowrap="">{getITCSummery('partA', 'b2bncCess').toFixed(2)}</td>
                                                                                                            </tr>
                                                                                                            <tr >
                                                                                                                <td className="text-left" colspan="2" nowrap="">
                                                                                                                    <Tippy content="No Records" allowHTML={true} placement='top' className='text-center'>
                                                                                                                        <a data-content="html" placement="bottom">B2B - Credit notes (Amendment)</a>
                                                                                                                    </Tippy>
                                                                                                                </td>
                                                                                                                <td className="text-right" nowrap="">{getITCSummery('partA', 'A').toFixed(2)}</td>
                                                                                                                <td className="text-right" nowrap="">{getITCSummery('partA', 'A').toFixed(2)}</td>
                                                                                                                <td className="text-right" nowrap="">{getITCSummery('partA', 'A').toFixed(2)}</td>
                                                                                                                <td className="text-right" nowrap="">{getITCSummery('partA', 'A').toFixed(2)}</td>
                                                                                                            </tr>
                                                                                                            <tr >
                                                                                                                <td rowspan="2"></td>
                                                                                                                <td className="text-left" colspan="2" nowrap="">
                                                                                                                    {
                                                                                                                        getITCISRecors('partA', 'b2bncIGSTr') ? <a data-content="html" placement="bottom" onClick={() => { gotoTable('Cdr') }}>B2B - Credit notes (Reverse charge)</a> : <Tippy content="No Records" allowHTML={true} placement='top' className='text-center'>
                                                                                                                            <a data-content="html" placement="bottom" onClick={() => { gotoTable('Cdr') }}>B2B - Credit notes (Reverse charge)</a>
                                                                                                                        </Tippy>
                                                                                                                    }
                                                                                                                </td>
                                                                                                                <td className="text-right" nowrap="">{getITCSummery('partA', 'b2bncIGSTr').toFixed(2)}</td>
                                                                                                                <td className="text-right" nowrap="">{getITCSummery('partA', 'b2bncCGSTr').toFixed(2)}</td>
                                                                                                                <td className="text-right" nowrap="">{getITCSummery('partA', 'b2bncSGSTr').toFixed(2)}</td>
                                                                                                                <td className="text-right" nowrap="">{getITCSummery('partA', 'b2bncCessr').toFixed(2)}</td>
                                                                                                            </tr>
                                                                                                            <tr >
                                                                                                                <td className="text-left" colspan="2" nowrap="">
                                                                                                                    <Tippy content="No Records" allowHTML={true} placement='top' className='text-center'>
                                                                                                                        <a data-content="html" placement="bottom">B2B - Credit notes (Reverse charge) (Amendment)</a>
                                                                                                                    </Tippy>
                                                                                                                </td>
                                                                                                                <td className="text-right" nowrap="">{getITCSummery('partA', 'A').toFixed(2)}</td>
                                                                                                                <td className="text-right" nowrap="">{getITCSummery('partA', 'A').toFixed(2)}</td>
                                                                                                                <td className="text-right" nowrap="">{getITCSummery('partA', 'A').toFixed(2)}</td>
                                                                                                                <td className="text-right" nowrap="">{getITCSummery('partA', 'A').toFixed(2)}</td>
                                                                                                            </tr>
                                                                                                            <tr >
                                                                                                                <td rowspan="2"></td>
                                                                                                                <td className="text-left" colspan="2" nowrap="">
                                                                                                                    <Tippy content="No Records" allowHTML={true} placement='top' className='text-center'>
                                                                                                                        <a className="disabled" data-content="html" placement="bottom">ISD - Credit notes</a>
                                                                                                                    </Tippy>
                                                                                                                </td>
                                                                                                                <td className="text-right" nowrap="">0.00</td>
                                                                                                                <td className="text-right" nowrap="">0.00</td>
                                                                                                                <td className="text-right" nowrap="">0.00</td>
                                                                                                                <td className="text-right" nowrap="">0.00</td>
                                                                                                            </tr>
                                                                                                            <tr >
                                                                                                                <td className="text-left" colspan="2" nowrap="">
                                                                                                                    <Tippy content="No Records" allowHTML={true} placement='top' className='text-center'>
                                                                                                                        <a className="disabled" data-content="html" placement="bottom">ISD - Credit notes (Amendment)</a>
                                                                                                                    </Tippy>
                                                                                                                </td>
                                                                                                                <td className="text-right" nowrap="">0.00</td>
                                                                                                                <td className="text-right" nowrap="">0.00</td>
                                                                                                                <td className="text-right" nowrap="">0.00</td>
                                                                                                                <td className="text-right" nowrap="">0.00</td>
                                                                                                            </tr>
                                                                                                        </>
                                                                                                    }
                                                                                                </tbody>
                                                                                            </table>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            }
                                                                            {
                                                                                !isTabC && <div className="row">
                                                                                    <div className="">
                                                                                        <div className="table-responsive">
                                                                                            <table className="table table-bordered ">
                                                                                                <thead >
                                                                                                    <tr >
                                                                                                        <th className="thred !text-left" nowrap="">S.NO. </th>
                                                                                                        <th className="thred !text-left" nowrap="">Heading&nbsp;
                                                                                                            {
                                                                                                                !nxMain && <a onClick={() => {
                                                                                                                    ncollapseAll();
                                                                                                                }}> [<span >Collapse All</span>&nbsp;<i className="fa fa-chevron-down"></i>] &nbsp;</a>
                                                                                                            }
                                                                                                            {
                                                                                                                nxMain && <a onClick={() => {
                                                                                                                    nExpandAll();
                                                                                                                }}> [<span >Expand All</span>&nbsp;<i className="fa fa-chevron-up"></i>] &nbsp;</a>
                                                                                                            }
                                                                                                        </th>
                                                                                                        <th className="thred" nowrap="">GSTR-3B table </th>
                                                                                                        <th className="thred" nowrap="" style={{ width: '3%', textAlign: 'center' }}> Integrated <br />Tax (₹) </th>
                                                                                                        <th className="thred" nowrap="" style={{ width: '13%', textAlign: 'center' }}> Central <br />Tax (₹) </th>
                                                                                                        <th className="thred" nowrap="" style={{ width: '13%', textAlign: 'center' }}> State/UT <br />Tax (₹) </th>
                                                                                                        <th className="thred" nowrap="" style={{ width: '9%', textAlign: 'center' }}> Cess (₹) </th>
                                                                                                    </tr>
                                                                                                </thead>
                                                                                                <tbody >
                                                                                                    <tr >
                                                                                                        <td className="rev" nowrap="">Part A</td>
                                                                                                        <td colspan="6" nowrap=""></td>
                                                                                                    </tr>
                                                                                                    <tr >
                                                                                                        <td className="rev" nowrap="">I</td>
                                                                                                        <td className="text-left" nowrap="">All other ITC - Supplies from registered persons
                                                                                                            <a onClick={() => {
                                                                                                                setNxA(!nxA);
                                                                                                            }}>
                                                                                                                {nxA && <i className="fa fa-fw fa-chevron-down" style={{ color: 'black', marginLeft: '5px' }}></i>}
                                                                                                                {!nxA && <i className="fa fa-fw fa-chevron-up" style={{ color: 'black', marginLeft: '5px' }}></i>}
                                                                                                            </a>
                                                                                                        </td>
                                                                                                        <td className="rev" nowrap="">NA
                                                                                                            <Tippy content="Such credit shall not be taken and has to be reported in table 4(D)(2) of FORM GSTR-3B." allowHTML={true} placement='top' className='text-center'>
                                                                                                                <i className="fa fa-info-circle" data-content="html" placement="bottom" tooltip=""></i>
                                                                                                            </Tippy>
                                                                                                        </td>
                                                                                                        <td className="text-right" nowrap="">{getITCSummery('partB', 'totalIGST').toFixed(2)}</td>
                                                                                                        <td className="text-right" nowrap="">{getITCSummery('partB', 'totalCGST').toFixed(2)}</td>
                                                                                                        <td className="text-right" nowrap="">{getITCSummery('partB', 'totalSGST').toFixed(2)}</td>
                                                                                                        <td className="text-right" nowrap="">{getITCSummery('partB', 'totalCess').toFixed(2)}</td>
                                                                                                    </tr>
                                                                                                    {
                                                                                                        !nxA && <>
                                                                                                            <tr >
                                                                                                                <td rowspan="4"></td>
                                                                                                                <td className="text-left" colspan="2" nowrap="">
                                                                                                                    {
                                                                                                                        getITCISRecors('partB', 'b2bIGST') ? <a data-content="html" placement="bottom" onClick={() => { gotoTable('b2b') }}>B2B - Invoices</a> : <Tippy content="No Records" allowHTML={true} placement='top' className='text-center'>
                                                                                                                            <a data-content="html" placement="bottom" onClick={() => { gotoTable('b2b') }}>B2B - Invoices</a>
                                                                                                                        </Tippy>
                                                                                                                    }
                                                                                                                </td>
                                                                                                                <td className="text-right" nowrap="">{getITCSummery('partB', 'b2bIGST').toFixed(2)}</td>
                                                                                                                <td className="text-right" nowrap="">{getITCSummery('partB', 'b2bCGST').toFixed(2)}</td>
                                                                                                                <td className="text-right" nowrap="">{getITCSummery('partB', 'b2bSGST').toFixed(2)}</td>
                                                                                                                <td className="text-right" nowrap="">{getITCSummery('partB', 'b2bCess').toFixed(2)}</td>
                                                                                                            </tr>
                                                                                                            <tr >
                                                                                                                <td className="text-left" colspan="2" nowrap="">
                                                                                                                    {
                                                                                                                        getITCISRecors('partB', 'b2bndIGST') ? <a data-content="html" placement="bottom" onClick={() => { gotoTable('Cdr') }}> B2B - Debit notes</a> : <Tippy content="No Records" allowHTML={true} placement='top' className='text-center'>
                                                                                                                            <a data-content="html" placement="bottom" onClick={() => { gotoTable('Cdr') }}> B2B - Debit notes</a>
                                                                                                                        </Tippy>
                                                                                                                    }
                                                                                                                </td>
                                                                                                                <td className="text-right" nowrap="">{getITCSummery('partB', 'b2bndIGST').toFixed(2)}</td>
                                                                                                                <td className="text-right" nowrap="">{getITCSummery('partB', 'b2bndCGST').toFixed(2)}</td>
                                                                                                                <td className="text-right" nowrap="">{getITCSummery('partB', 'b2bndSGST').toFixed(2)}</td>
                                                                                                                <td className="text-right" nowrap="">{getITCSummery('partB', 'b2bndCess').toFixed(2)}</td>
                                                                                                            </tr>
                                                                                                            <tr >
                                                                                                                <td className="text-left" colspan="2" nowrap="">
                                                                                                                    {
                                                                                                                        getITCISRecors('partB', 'b2baIGST') ? <a data-content="html" placement="bottom" onClick={() => { gotoTable('b2bA') }}> B2B - Invoices (Amendment)</a> : <Tippy content="No Records" allowHTML={true} placement='top' className='text-center'>
                                                                                                                            <a data-content="html" placement="bottom" onClick={() => { gotoTable('b2bA') }}> B2B - Invoices (Amendment)</a>
                                                                                                                        </Tippy>
                                                                                                                    }
                                                                                                                </td>
                                                                                                                <td className="text-right" nowrap="">{getITCSummery('partB', 'b2baIGST').toFixed(2)}</td>
                                                                                                                <td className="text-right" nowrap="">{getITCSummery('partB', 'b2baCGST').toFixed(2)}</td>
                                                                                                                <td className="text-right" nowrap="">{getITCSummery('partB', 'b2baSGST').toFixed(2)}</td>
                                                                                                                <td className="text-right" nowrap="">{getITCSummery('partB', 'b2baCess').toFixed(2)}</td>
                                                                                                            </tr>
                                                                                                            <tr >
                                                                                                                <td className="text-left" colspan="2" nowrap="">
                                                                                                                    <Tippy content="No Records" allowHTML={true} placement='top' className='text-center'>
                                                                                                                        <a data-content="html" placement="bottom"> B2B - Debit notes (Amendment)</a>
                                                                                                                    </Tippy>
                                                                                                                </td>
                                                                                                                <td className="text-right" nowrap="">{getITCSummery('partB', 'A').toFixed(2)}</td>
                                                                                                                <td className="text-right" nowrap="">{getITCSummery('partB', 'A').toFixed(2)}</td>
                                                                                                                <td className="text-right" nowrap="">{getITCSummery('partB', 'A').toFixed(2)}</td>
                                                                                                                <td className="text-right" nowrap="">{getITCSummery('partB', 'A').toFixed(2)}</td>
                                                                                                            </tr>
                                                                                                        </>
                                                                                                    }
                                                                                                    <tr >
                                                                                                        <td className="rev" nowrap="">II</td>
                                                                                                        <td className="text-left" nowrap="">Inward Supplies from ISD
                                                                                                            <a onClick={() => {
                                                                                                                setNxB(!nxB);
                                                                                                            }}>
                                                                                                                {nxB && <i className="fa fa-fw fa-chevron-down" style={{ color: 'black', marginLeft: '5px' }}></i>}
                                                                                                                {!nxB && <i className="fa fa-fw fa-chevron-up" style={{ color: 'black', marginLeft: '5px' }}></i>}
                                                                                                            </a>
                                                                                                        </td>
                                                                                                        <td className="rev" nowrap="">NA
                                                                                                            <Tippy content="Such credit shall not be taken and has to be reported in table 4(D)(2) of FORM GSTR-3B." allowHTML={true} placement='top' className='text-center'>
                                                                                                                <i className="fa fa-info-circle" data-content="html" placement="bottom" tooltip=""></i>
                                                                                                            </Tippy>
                                                                                                        </td>
                                                                                                        <td className="text-right" nowrap="">0.00</td>
                                                                                                        <td className="text-right" nowrap="">0.00</td>
                                                                                                        <td className="text-right" nowrap="">0.00</td>
                                                                                                        <td className="text-right" nowrap="">0.00</td>
                                                                                                    </tr>
                                                                                                    {
                                                                                                        !nxB && <>
                                                                                                            <tr >
                                                                                                                <td rowspan="2"></td>
                                                                                                                <td className="text-left" colspan="2" nowrap="">
                                                                                                                    <Tippy content="No Records" allowHTML={true} placement='top' className='text-center'>
                                                                                                                        <a className="disabled" data-content="html" placement="bottom"> ISD - Invoices</a>
                                                                                                                    </Tippy>
                                                                                                                </td>
                                                                                                                <td className="text-right" nowrap="">0.00</td>
                                                                                                                <td className="text-right" nowrap="">0.00</td>
                                                                                                                <td className="text-right" nowrap="">0.00</td>
                                                                                                                <td className="text-right" nowrap="">0.00</td>
                                                                                                            </tr>
                                                                                                            <tr >
                                                                                                                <td className="text-left" colspan="2" nowrap="">
                                                                                                                    <Tippy content="No Records" allowHTML={true} placement='top' className='text-center'>
                                                                                                                        <a className="disabled" data-content="html" placement="bottom"> ISD - Invoices (Amendment)</a>
                                                                                                                    </Tippy>
                                                                                                                </td>
                                                                                                                <td className="text-right" nowrap="">0.00</td>
                                                                                                                <td className="text-right" nowrap="">0.00</td>
                                                                                                                <td className="text-right" nowrap="">0.00</td>
                                                                                                                <td className="text-right" nowrap="">0.00</td>
                                                                                                            </tr>
                                                                                                        </>
                                                                                                    }
                                                                                                    <tr >
                                                                                                        <td className="rev" nowrap="">III</td>
                                                                                                        <td className="text-left" nowrap="">Inward Supplies liable for reverse charge
                                                                                                            <a onClick={() => {
                                                                                                                setNxC(!nxC);
                                                                                                            }}>
                                                                                                                {nxC && <i className="fa fa-fw fa-chevron-down" style={{ color: 'black', marginLeft: '5px' }}></i>}
                                                                                                                {!nxC && <i className="fa fa-fw fa-chevron-up" style={{ color: 'black', marginLeft: '5px' }}></i>}
                                                                                                            </a>
                                                                                                        </td>
                                                                                                        <td className="rev" nowrap="">3.1(d)
                                                                                                            <Tippy content="These supplies shall be declared in Table 3.1(d) of FORM GSTR-3B for payment of tax.However, credit will not be available on the same and has to be reported in table 4(D)(2) of FORM GSTR-3B." allowHTML={true} placement='top' className='text-center'>
                                                                                                                <i className="fa fa-info-circle" data-content="html" placement="bottom" tooltip=""></i>
                                                                                                            </Tippy>
                                                                                                        </td>
                                                                                                        <td className="text-right" nowrap="">{getITCSummery('partB', 'totalIGSTr').toFixed(2)}</td>
                                                                                                        <td className="text-right" nowrap="">{getITCSummery('partB', 'totalCGSTr').toFixed(2)}</td>
                                                                                                        <td className="text-right" nowrap="">{getITCSummery('partB', 'totalSGSTr').toFixed(2)}</td>
                                                                                                        <td className="text-right" nowrap="">{getITCSummery('partB', 'totalCessr').toFixed(2)}</td>
                                                                                                    </tr>
                                                                                                    {
                                                                                                        !nxC && <>
                                                                                                            <tr >
                                                                                                                <td rowspan="4"></td>
                                                                                                                <td className="text-left" colspan="2" nowrap="">
                                                                                                                    {
                                                                                                                        getITCISRecors('partB', 'b2bIGSTr') ? <a data-content="html" placement="bottom" onClick={() => { gotoTable('b2b') }}> B2B - Invoices</a> : <Tippy content="No Records" allowHTML={true} placement='top' className='text-center'>
                                                                                                                            <a data-content="html" placement="bottom" onClick={() => { gotoTable('b2b') }}> B2B - Invoices</a>
                                                                                                                        </Tippy>
                                                                                                                    }
                                                                                                                </td>
                                                                                                                <td className="text-right" nowrap="">{getITCSummery('partB', 'b2bIGSTr').toFixed(2)}</td>
                                                                                                                <td className="text-right" nowrap="">{getITCSummery('partB', 'b2bCGSTr').toFixed(2)}</td>
                                                                                                                <td className="text-right" nowrap="">{getITCSummery('partB', 'b2bSGSTr').toFixed(2)}</td>
                                                                                                                <td className="text-right" nowrap="">{getITCSummery('partB', 'b2bCessr').toFixed(2)}</td>
                                                                                                            </tr>
                                                                                                            <tr >
                                                                                                                <td className="text-left" colspan="2" nowrap="">
                                                                                                                    {
                                                                                                                        getITCISRecors('partB', 'b2bndIGSTr') ? <a data-content="html" placement="bottom" onClick={() => { gotoTable('Cdr') }}> B2B - Debit notes</a> : <Tippy content="No Records" allowHTML={true} placement='top' className='text-center'>
                                                                                                                            <a data-content="html" placement="bottom" onClick={() => { gotoTable('Cdr') }}> B2B - Debit notes</a>
                                                                                                                        </Tippy>
                                                                                                                    }
                                                                                                                </td>
                                                                                                                <td className="text-right" nowrap="">{getITCSummery('partB', 'b2bndIGSTr').toFixed(2)}</td>
                                                                                                                <td className="text-right" nowrap="">{getITCSummery('partB', 'b2bndCGSTr').toFixed(2)}</td>
                                                                                                                <td className="text-right" nowrap="">{getITCSummery('partB', 'b2bndSGSTr').toFixed(2)}</td>
                                                                                                                <td className="text-right" nowrap="">{getITCSummery('partB', 'b2bndCessr').toFixed(2)}</td>
                                                                                                            </tr>
                                                                                                            <tr >
                                                                                                                <td className="text-left" colspan="2" nowrap="">
                                                                                                                    {
                                                                                                                        getITCISRecors('partB', 'b2baIGSTr') ? <a data-content="html" placement="bottom" onClick={() => { gotoTable('b2bA') }}> B2B - Invoices (Amendment)</a> : <Tippy content="No Records" allowHTML={true} placement='top' className='text-center'>
                                                                                                                            <a data-content="html" placement="bottom" onClick={() => { gotoTable('b2bA') }}> B2B - Invoices (Amendment)</a>
                                                                                                                        </Tippy>
                                                                                                                    }
                                                                                                                </td>
                                                                                                                <td className="text-right" nowrap="">{getITCSummery('partB', 'b2baIGSTr').toFixed(2)}</td>
                                                                                                                <td className="text-right" nowrap="">{getITCSummery('partB', 'b2baCGSTr').toFixed(2)}</td>
                                                                                                                <td className="text-right" nowrap="">{getITCSummery('partB', 'b2baSGSTr').toFixed(2)}</td>
                                                                                                                <td className="text-right" nowrap="">{getITCSummery('partB', 'b2baCessr').toFixed(2)}</td>
                                                                                                            </tr>
                                                                                                            <tr >
                                                                                                                <td className="text-left" colspan="2" nowrap="">
                                                                                                                    <Tippy content="No Records" allowHTML={true} placement='top' className='text-center'>
                                                                                                                        <a data-content="html" placement="bottom"> B2B - Debit notes (Amendment)</a>
                                                                                                                    </Tippy>
                                                                                                                </td>
                                                                                                                <td className="text-right" nowrap="">{getITCSummery('partA', 'A').toFixed(2)}</td>
                                                                                                                <td className="text-right" nowrap="">{getITCSummery('partA', 'A').toFixed(2)}</td>
                                                                                                                <td className="text-right" nowrap="">{getITCSummery('partA', 'A').toFixed(2)}</td>
                                                                                                                <td className="text-right" nowrap="">{getITCSummery('partA', 'A').toFixed(2)}</td>
                                                                                                            </tr>
                                                                                                        </>
                                                                                                    }
                                                                                                    <tr >
                                                                                                        <td >Part B</td>
                                                                                                        <td colspan="6">
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                    <tr >
                                                                                                        <td className="rev" nowrap="">I</td>
                                                                                                        <td className="text-left" nowrap="">Others
                                                                                                            <a onClick={() => {
                                                                                                                setNxD(!nxD);
                                                                                                            }}>
                                                                                                                {nxD && <i className="fa fa-fw fa-chevron-down" style={{ color: 'black', marginLeft: '5px' }}></i>}
                                                                                                                {!nxD && <i className="fa fa-fw fa-chevron-up" style={{ color: 'black', marginLeft: '5px' }}></i>}
                                                                                                            </a>
                                                                                                        </td>
                                                                                                        <td className="rev" nowrap="">4(B)(2)
                                                                                                            <Tippy content="Credit Notes should be net-off against relevant ITC available tables [Table 4A(3,4,5)]." allowHTML={true} placement='top' className='text-center'>
                                                                                                                <i className="fa fa-info-circle" data-content="html" placement="bottom" tooltip=""></i>
                                                                                                            </Tippy>
                                                                                                        </td>
                                                                                                        <td className="text-right" nowrap="">{getITCSummery('partB', 'b2bncIGSTT').toFixed(2)}</td>
                                                                                                        <td className="text-right" nowrap="">{getITCSummery('partB', 'b2bncCGSTT').toFixed(2)}</td>
                                                                                                        <td className="text-right" nowrap="">{getITCSummery('partB', 'b2bncSGSTT').toFixed(2)}</td>
                                                                                                        <td className="text-right" nowrap="">{getITCSummery('partB', 'b2bncCessT').toFixed(2)}</td>
                                                                                                    </tr>
                                                                                                    {
                                                                                                        !nxD && <>
                                                                                                            <tr >
                                                                                                                <td rowspan="2"></td>
                                                                                                                <td className="text-left" colspan="2" nowrap="">
                                                                                                                    {
                                                                                                                        getITCISRecors('partB', 'b2bncIGST') ? <a data-content="html" placement="bottom" onClick={() => { gotoTable('Cdr') }}> B2B - Credit Notes</a> : <Tippy content="No Records" allowHTML={true} placement='top' className='text-center'>
                                                                                                                            <a data-content="html" placement="bottom" onClick={() => { gotoTable('Cdr') }}> B2B - Credit Notes</a>
                                                                                                                        </Tippy>
                                                                                                                    }
                                                                                                                </td>
                                                                                                                <td className="text-right" nowrap="">{getITCSummery('partB', 'b2bncIGST').toFixed(2)}</td>
                                                                                                                <td className="text-right" nowrap="">{getITCSummery('partB', 'b2bncCGST').toFixed(2)}</td>
                                                                                                                <td className="text-right" nowrap="">{getITCSummery('partB', 'b2bncSGST').toFixed(2)}</td>
                                                                                                                <td className="text-right" nowrap="">{getITCSummery('partB', 'b2bncCess').toFixed(2)}</td>
                                                                                                            </tr>
                                                                                                            <tr >
                                                                                                                <td className="text-left" colspan="2" nowrap="">
                                                                                                                    <Tippy content="No Records" allowHTML={true} placement='top' className='text-center'>
                                                                                                                        <a className="disabled" data-content="html" placement="bottom">B2B - Credit notes (Amendment)</a>
                                                                                                                    </Tippy>
                                                                                                                </td>
                                                                                                                <td className="text-right" nowrap="">0.00</td>
                                                                                                                <td className="text-right" nowrap="">0.00</td>
                                                                                                                <td className="text-right" nowrap="">0.00</td>
                                                                                                                <td className="text-right" nowrap="">0.00</td>
                                                                                                            </tr>
                                                                                                            <tr >
                                                                                                                <td rowspan="2"></td>
                                                                                                                <td className="text-left" colspan="2" nowrap="">
                                                                                                                    {
                                                                                                                        getITCISRecors('partB', 'b2bncIGSTr') ? <a data-content="html" placement="bottom" onClick={() => { gotoTable('Cdr') }}> B2B - Credit notes (Reverse charge)</a> : <Tippy content="No Records" allowHTML={true} placement='top' className='text-center'>
                                                                                                                            <a data-content="html" placement="bottom" onClick={() => { gotoTable('Cdr') }}> B2B - Credit notes (Reverse charge)</a>
                                                                                                                        </Tippy>
                                                                                                                    }
                                                                                                                </td>
                                                                                                                <td className="text-right" nowrap="">{getITCSummery('partB', 'b2bncIGSTr').toFixed(2)}</td>
                                                                                                                <td className="text-right" nowrap="">{getITCSummery('partB', 'b2bncCGSTr').toFixed(2)}</td>
                                                                                                                <td className="text-right" nowrap="">{getITCSummery('partB', 'b2bncSGSTr').toFixed(2)}</td>
                                                                                                                <td className="text-right" nowrap="">{getITCSummery('partB', 'b2bncCessr').toFixed(2)}</td>
                                                                                                            </tr>
                                                                                                            <tr >
                                                                                                                <td className="text-left" colspan="2" nowrap="">
                                                                                                                    <Tippy content="No Records" allowHTML={true} placement='top' className='text-center'>
                                                                                                                        <a className="disabled" data-content="html" placement="bottom"> B2B - Credit notes (Reverse charge) (Amendment)</a>
                                                                                                                    </Tippy>
                                                                                                                </td>
                                                                                                                <td className="text-right" nowrap="">0.00</td>
                                                                                                                <td className="text-right" nowrap="">0.00</td>
                                                                                                                <td className="text-right" nowrap="">0.00</td>
                                                                                                                <td className="text-right" nowrap="">0.00</td>
                                                                                                            </tr>
                                                                                                            <tr >
                                                                                                                <td rowspan="2"></td>
                                                                                                                <td className="text-left" colspan="2" nowrap="">
                                                                                                                    <Tippy content="No Records" allowHTML={true} placement='top' className='text-center'>
                                                                                                                        <a className="disabled" data-content="html" placement="bottom"> ISD - Credit notes</a>
                                                                                                                    </Tippy>

                                                                                                                </td>
                                                                                                                <td className="text-right" nowrap="">0.00</td>
                                                                                                                <td className="text-right" nowrap="">0.00</td>
                                                                                                                <td className="text-right" nowrap="">0.00</td>
                                                                                                                <td className="text-right" nowrap="">0.00</td>
                                                                                                            </tr>
                                                                                                            <tr >
                                                                                                                <td className="text-left" colspan="2" nowrap="">
                                                                                                                    <Tippy content="No Records" allowHTML={true} placement='top' className='text-center'>
                                                                                                                        <a className="disabled" data-content="html" placement="bottom"> ISD - Credit notes (Amendment)</a>
                                                                                                                    </Tippy>
                                                                                                                </td>
                                                                                                                <td className="text-right" nowrap="">0.00</td>
                                                                                                                <td className="text-right" nowrap="">0.00</td>
                                                                                                                <td className="text-right" nowrap="">0.00</td>
                                                                                                                <td className="text-right" nowrap="">0.00</td>
                                                                                                            </tr>
                                                                                                        </>
                                                                                                    }
                                                                                                </tbody>
                                                                                            </table>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="row" style={{ marginTop: '20px' }}>
                                                                <div className="col-md-10" style={{ textAlign: 'left' }}>
                                                                    <span ></span>
                                                                </div>
                                                                <div className="col-md-2">
                                                                </div>
                                                            </div>
                                                            <div className="w-full float-right">
                                                                <div className="pull-right">
                                                                    <button className="btn" style={{ border: '1px solid black' }} type="button" onClick={() => {
                                                                        gotoPath('gstr')
                                                                    }}> BACK TO DASHBOARD </button>&nbsp;
                                                                    <button className="btn btn-primary" type="button" onClick={() => {
                                                                        window.scrollTo({
                                                                            top: 0,
                                                                            behavior: 'smooth',
                                                                        });
                                                                        setIsPrint(true);
                                                                        handleGeneratePDF();
                                                                    }}> DOWNLOAD GSTR-2B SUMMARY (PDF) </button>&nbsp;
                                                                    <button className="btn btn-primary" type="button" onClick={() => {
                                                                        downloadExcel('main');
                                                                    }}> DOWNLOAD GSTR-2B DETAILS (EXCEL) </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <block-ui-content>
                                                <div className="block-ui-wrapper block-ui-main">
                                                    <div className="block-ui-spinner"><div className="loader"></div>
                                                    </div>
                                                </div>
                                            </block-ui-content>
                                        </block-ui>
                                    </app-summary>
                                }
                                {
                                    !isTabB && < app-all-tables >
                                        <div _ngcontent-gwq-c9="" className="tab-content ">
                                            <br _ngcontent-gwq-c9="" />
                                            <div _ngcontent-gwq-c9="" className="row">
                                                <div _ngcontent-gwq-c9="" className="flex">
                                                    <div _ngcontent-gwq-c9="" className="row" style={{ marginLeft: '8px' }}>
                                                        <div _ngcontent-gwq-c9="" className="col-md-3">
                                                            <div _ngcontent-gwq-c9="" id="myTabDD" role="tablist">
                                                                <div _ngcontent-gwq-c9="" className={`dropdown open`}>
                                                                    <Menu>
                                                                        <Menu.Button style={{ border: '0', backgroundColor: 'white' }}>
                                                                            <div _ngcontent-gwq-c9="" aria-expanded="false" aria-haspopup="true" className="btn btn-custom dropdown-toggle" data-toggle="dropdown" id="dropdownMenuButton" style={{ margin: '2px 0px 2px 0px', fontWeight: 'bolder' }} type="button" onClick={() => {
                                                                                setIsTabE(!isTabE);
                                                                            }}>Select table to view details&nbsp; <i _ngcontent-gwq-c9="" className="fa fa-chevron-down"></i>
                                                                            </div>
                                                                        </Menu.Button>
                                                                        {
                                                                            !isTabE && <Menu.Items _ngcontent-gwq-c9="" aria-labelledby="dropdownMenuButton" className="dropdown-menu" style={{ width: '580px', paddingLeft: '5px' }} static>
                                                                                <a _ngcontent-gwq-c9="" className="dropdown-item ng-star-inserted" style={{ cursor: 'pointer' }} onClick={() => {
                                                                                    setIsTabE(true);
                                                                                    setIsTabD(false);
                                                                                    setCType('b2b');
                                                                                    if (b2b.length > 0) {
                                                                                        setIsRow(true);
                                                                                    } else {
                                                                                        setIsRow(false);
                                                                                    }
                                                                                    removeAllFilter();
                                                                                }}>Taxable inward supplies received from registered person - B2B </a>
                                                                                <br _ngcontent-gwq-c9="" className="ng-star-inserted" />
                                                                                <a _ngcontent-gwq-c9="" className="dropdown-item ng-star-inserted" style={{ cursor: 'pointer' }} onClick={() => {
                                                                                    setIsTabE(true);
                                                                                    setIsTabD(false);
                                                                                    setCType('b2bA');
                                                                                    if (b2ba.length > 0) {
                                                                                        setIsRow(true);
                                                                                    } else {
                                                                                        setIsRow(false);
                                                                                    }
                                                                                    removeAllFilter();
                                                                                }}>Amendments to previously filed invoices by supplier - B2BA </a>
                                                                                <br _ngcontent-gwq-c9="" className="ng-star-inserted" />
                                                                                <a _ngcontent-gwq-c9="" className="dropdown-item ng-star-inserted" style={{ cursor: 'pointer' }} onClick={() => {
                                                                                    setIsTabE(true);
                                                                                    setIsTabD(false);
                                                                                    setCType('Cdr');
                                                                                    if (b2bcdr.length > 0) {
                                                                                        setIsRow(true);
                                                                                    } else {
                                                                                        setIsRow(false);
                                                                                    }
                                                                                    removeAllFilter();
                                                                                }}>Debit/Credit notes(Original) - B2B CDNR </a>
                                                                                <br _ngcontent-gwq-c9="" className="ng-star-inserted" />
                                                                                <a _ngcontent-gwq-c9="" className="dropdown-item ng-star-inserted" style={{ cursor: 'pointer' }} onClick={() => {
                                                                                    setIsTabE(true);
                                                                                    setIsTabD(true);
                                                                                    setCType('CdrA');
                                                                                    setIsRow(false);
                                                                                    removeAllFilter();
                                                                                }}>Amendments to previously filed Credit/Debit notes by supplier - B2B CDNRA </a>
                                                                                <br _ngcontent-gwq-c9="" className="ng-star-inserted" />
                                                                                <a _ngcontent-gwq-c9="" className="dropdown-item ng-star-inserted" style={{ cursor: 'pointer' }} onClick={() => {
                                                                                    setIsTabE(true);
                                                                                    setIsTabD(false);
                                                                                    setCType('ISD');
                                                                                    setIsRow(false);
                                                                                    removeAllFilter();
                                                                                }}>ISD Credits </a>
                                                                                <br _ngcontent-gwq-c9="" className="ng-star-inserted" />
                                                                                <a _ngcontent-gwq-c9="" className="dropdown-item ng-star-inserted" style={{ cursor: 'pointer' }} onClick={() => {
                                                                                    setIsTabE(true);
                                                                                    setIsTabD(false);
                                                                                    setCType('ISDA');
                                                                                    setIsRow(false);
                                                                                    removeAllFilter();
                                                                                }}>Amendments ISD Credits received - ISDA </a>
                                                                                <br _ngcontent-gwq-c9="" className="ng-star-inserted" />
                                                                                <a _ngcontent-gwq-c9="" className="dropdown-item ng-star-inserted" style={{ cursor: 'pointer' }} onClick={() => {
                                                                                    setIsTabE(true);
                                                                                    setIsTabD(false);
                                                                                    setCType('IMPG');
                                                                                    if (impg.length > 0) {
                                                                                        setIsRow(true);
                                                                                    } else {
                                                                                        setIsRow(false);
                                                                                    }
                                                                                    removeAllFilter();
                                                                                }}>Import of goods from overseas on bill of entry - IMPG </a>
                                                                                <br _ngcontent-gwq-c9="" className="ng-star-inserted" />
                                                                                <a _ngcontent-gwq-c9="" className="dropdown-item ng-star-inserted" style={{ cursor: 'pointer' }} onClick={() => {
                                                                                    setIsTabE(true);
                                                                                    setIsTabD(false);
                                                                                    setCType('IMPS');
                                                                                    setIsRow(false);
                                                                                    removeAllFilter();
                                                                                }}>Import of goods from SEZ units/developers on bill of entry - IMPGSEZ </a>
                                                                                <br _ngcontent-gwq-c9="" className="ng-star-inserted" />
                                                                            </Menu.Items>
                                                                        }
                                                                    </Menu>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div _ngcontent-gwq-c9="" className="col-md-9" style={{ paddingRight: '35px' }}>
                                                        <div _ngcontent-gwq-c9="" className="dropdown">
                                                            <div _ngcontent-gwq-c9="" className=" btn-custom1" style={{ margin: '2px 0px 2px 0px', fontWeight: 'bolder' }}>{getTabelTitle()}
                                                                <span _ngcontent-gwq-c9="" className="pull-right">
                                                                    <button _ngcontent-gwq-c9="" className="btn-help" onClick={() => {
                                                                        gotoURL("https://tutorial.gst.gov.in/contextualhelp/gstr2biff/index.htm#t=B2B_Table_of_GSTR-2B.htm");
                                                                    }}>HELP <i _ngcontent-gwq-c9="" className="fa fa-question-circle" style={{ color: 'white', marginLeft: '3px' }}></i></button>&nbsp;&nbsp;
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div _ngcontent-gwq-c9="">
                                                <app-b2b _nghost-gwq-c10="" className="ng-star-inserted">
                                                    <block-ui>
                                                        <div className="">
                                                            <div className="tabpane">
                                                                <div className="row">
                                                                    <div className="empty"></div>
                                                                    <hr style={{ margin: '0px' }} />
                                                                    {
                                                                        !isRow && <div _ngcontent-ede-c16="" className="row ng-star-inserted">
                                                                            <div _ngcontent-ede-c16="" className="col-sm-12 pull-left">
                                                                                <div _ngcontent-ede-c16="" className="alert alert-danger alert-dismissible">
                                                                                    <span _ngcontent-ede-c16="">For current tax period, no details are available to view.</span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                    {
                                                                        (isRow && cType === 'b2b') && <>
                                                                            <div _ngcontent-gwq-c10="" className="row">
                                                                                <div _ngcontent-gwq-c10="" className="col-md-10 ">
                                                                                    <ul _ngcontent-gwq-c10="" className="nav nav-pills ng-star-inserted" id="myTab" role="tablist">
                                                                                        <li _ngcontent-gwq-c10="" className={`nav-item rounded-md ${isTabD ? 'active' : ''}`} onClick={() => {
                                                                                            setIsTabD(true);
                                                                                            removeAllFilter();
                                                                                            setSelGST({ 'gst': '', 'name': '' });
                                                                                            setSubRow(-1);
                                                                                        }}>
                                                                                            <a _ngcontent-gwq-c10="" aria-controls="suppsumm" className="nav-link border-right">Supplier wise Details</a>
                                                                                        </li>
                                                                                        <li _ngcontent-gwq-c10="" className={`nav-item rounded-md ${!isTabD ? 'active' : ''}`} onClick={() => {
                                                                                            setIsTabD(false);
                                                                                            removeAllFilter();
                                                                                        }}>
                                                                                            <a _ngcontent-gwq-c10="" aria-controls="docdet" className="nav-link border-right">Document Details</a>
                                                                                        </li>
                                                                                    </ul>
                                                                                </div>
                                                                                <div _ngcontent-gwq-c10="" className="col-sm-2 col-md-2 form-inline ng-star-inserted" style={{ marginTop: '10px' }}>
                                                                                    <a _ngcontent-gwq-c10="" data-toggle="modal" href="javascript:void(0)" onClick={(e) => {
                                                                                        downloadExcel('b2b');
                                                                                    }}>Download Excel <i _ngcontent-gwq-c10="" className="fa fa-download"></i></a>
                                                                                </div>
                                                                            </div>
                                                                            <hr _ngcontent-gwq-c10="" style={{ margin: '0px' }} />
                                                                            <div _ngcontent-gwq-c10="" className="tab-content ">
                                                                                <div _ngcontent-gwq-c10="" aria-labelledby="docdet-tab" id="docdet" role="tabpanel" className="tab-pane border-0 active ng-star-inserted">
                                                                                    <div _ngcontent-gwq-c10="" className="">
                                                                                        {
                                                                                            isTabD && <>
                                                                                                <div _ngcontent-gwq-c10="" className="row ng-star-inserted flex justify-end">
                                                                                                    <div _ngcontent-gwq-c10="" className=" col-sm-3 form-inline" style={{ width: 'fit-content' }}>
                                                                                                        <label _ngcontent-gwq-c10="" className="plabel">Records Per Page:</label>
                                                                                                        <span>
                                                                                                            <select _ngcontent-gwq-c10="" className=" pull-right form-control" for="display" style={{ width: 'auto', float: 'right', marginLeft: '0px', marginTop: '10px' }} >
                                                                                                                <option _ngcontent-gwq-c10="" selected="" style={{ float: 'right' }} value="10">10</option>
                                                                                                                <option _ngcontent-gwq-c10="" style={{ float: 'right' }} value="20">20</option>
                                                                                                                <option _ngcontent-gwq-c10="" style={{ float: 'right' }} value="50">50</option>
                                                                                                                <option _ngcontent-gwq-c10="" style={{ float: 'right' }} value="100">100</option>
                                                                                                            </select>
                                                                                                        </span>
                                                                                                    </div>
                                                                                                    <div _ngcontent-gwq-c10="" className=" col-sm-2 form-inline" style={{ marginTop: '10px', width: 'fit-100px', marginRight: '-40px' }}>
                                                                                                        <Tippy content="Only numbers(0-9), characters(A-Z), space and special characters(/ ' - .) are allowed." allowHTML={true} placement='top' className='text-center'>
                                                                                                            <span _ngcontent-gwq-c10="" style={{ width: '80%' }}>
                                                                                                                <input _ngcontent-gwq-c10="" autocomplete="off" className="modsearch form-control ng-untouched ng-pristine ng-valid" maxLength="32" name="search" placeholder="Search..." style={{ border: 'none', width: '90%' }} type="text" onChange={(e) => { handleOnChangeSearch(e, 'B2BA') }} value={querySearch.B2BA} />
                                                                                                                <i _ngcontent-gwq-c10="" className="fa fa-search" style={{ marginTop: '4px' }}></i>
                                                                                                                {searchErr.B2BA && <span className="text-red-600 text-sm" hidden=""> Enter valid chararcters </span>}
                                                                                                            </span>
                                                                                                        </Tippy>
                                                                                                    </div>
                                                                                                    <div _ngcontent-gwq-c10="" className=" col-sm-1 form-inline"></div>
                                                                                                </div>
                                                                                                <div _ngcontent-gwq-c10="" className="row ng-star-inserted mt-4">
                                                                                                    <div _ngcontent-gwq-c10="" className="col-sm-12 pull-left">
                                                                                                        <div _ngcontent-gwq-c10="" className="table-responsive ng-star-inserted">
                                                                                                            <table _ngcontent-gwq-c10="" className="table table-bordered ">
                                                                                                                <thead>
                                                                                                                    <tr>
                                                                                                                        <th className="org" nowrap="">S.NO. </th>
                                                                                                                        <th className="org ng-star-inserted" nowrap="" style={{ cursor: 'pointer' }}>GSTIN of supplier
                                                                                                                            &nbsp;<span>
                                                                                                                                <i className="fa fas fa-sort-asc ng-star-inserted"></i>
                                                                                                                            </span>
                                                                                                                            <span></span>
                                                                                                                        </th>
                                                                                                                        <th className="org" nowrap="" style={{ cursor: 'pointer' }}> Trade/legal <br /> name
                                                                                                                            &nbsp;<span><i className="fa fas fa-sort-asc ng-star-inserted"></i></span>
                                                                                                                            <span></span>
                                                                                                                        </th>
                                                                                                                        <th className="org" nowrap="" style={{ cursor: 'pointer' }}> Number of <br /> records
                                                                                                                            &nbsp;<span><i className="fa fas fa-sort-asc ng-star-inserted"></i></span>
                                                                                                                            <span></span>
                                                                                                                        </th>
                                                                                                                        <th className="org" nowrap="" style={{ cursor: 'pointer' }}> Taxable <br /> Value (₹)
                                                                                                                            &nbsp;<span><i className="fa fas fa-sort-asc ng-star-inserted"></i></span>
                                                                                                                            <span></span>
                                                                                                                        </th>
                                                                                                                        <th className="org" nowrap="" style={{ width: '3%', cursor: 'pointer' }}>Integrated <br />Tax (₹)
                                                                                                                            &nbsp;<span><i className="fa fas fa-sort-asc ng-star-inserted"></i></span>
                                                                                                                            <span></span>
                                                                                                                        </th>
                                                                                                                        <th className="org" nowrap="" style={{ width: '13%', cursor: 'pointer' }}>Central <br />Tax (₹)
                                                                                                                            &nbsp;<span><i className="fa fas fa-sort-asc ng-star-inserted"></i></span>
                                                                                                                            <span></span>
                                                                                                                        </th>
                                                                                                                        <th className="org" nowrap="" style={{ width: '13%', cursor: 'pointer' }}>State/UT <br />Tax (₹)
                                                                                                                            &nbsp;<span><i className="fa fas fa-sort-asc ng-star-inserted"></i></span>
                                                                                                                            <span></span>
                                                                                                                        </th>
                                                                                                                        <th className="org" nowrap="" style={{ width: '9%', cursor: 'pointer' }}>Cess (₹)
                                                                                                                            &nbsp;<span><i className="fa fas fa-sort-asc ng-star-inserted"></i></span>
                                                                                                                            <span></span>
                                                                                                                        </th>
                                                                                                                        <th className="org" nowrap="" style={{ width: '9%', cursor: 'pointer' }}>
                                                                                                                            <span className="ng-star-inserted"> GSTR-1/IFF/GSTR-5 </span>
                                                                                                                            &nbsp;<br /> Period <span>
                                                                                                                                <i className="fa fas fa-sort-asc ng-star-inserted"></i>
                                                                                                                            </span>
                                                                                                                            <span></span>
                                                                                                                        </th>
                                                                                                                        <th className="org" nowrap="" style={{ width: '9%', cursor: 'pointer' }}>
                                                                                                                            <span className="ng-star-inserted"> GSTR-1/IFF/GSTR-5 Filing </span>
                                                                                                                            <br /> Date
                                                                                                                            &nbsp;<span>
                                                                                                                                <i className="fa fas fa-sort-asc ng-star-inserted"></i>
                                                                                                                            </span>
                                                                                                                            <span>
                                                                                                                            </span>
                                                                                                                        </th>
                                                                                                                    </tr>
                                                                                                                </thead>
                                                                                                                <tbody>
                                                                                                                    {
                                                                                                                        getSummeryData('b2b').map((item, index) => {
                                                                                                                            return <tr>
                                                                                                                                <td className="rowPadding">{(index + 1)}</td>
                                                                                                                                <td className="rowPadding text-center"><a onClick={() => {
                                                                                                                                    let newFill = { ...fadFillter };
                                                                                                                                    newFill.filter = ['A', 'M'];
                                                                                                                                    newFill.count = 2;
                                                                                                                                    setFADFillter(newFill);
                                                                                                                                    setIsTabD(false);
                                                                                                                                    setSelGST({ 'gst': item.gstn, 'name': item.name });
                                                                                                                                }}>{item.gstn}</a></td>
                                                                                                                                <td className="rowPadding"><p style={{ 'width': '380px', margin: '0', padding: '0' }}>{item.name}</p></td>
                                                                                                                                <td className="rowPadding text-center">{item.count}</td>
                                                                                                                                <td className="rowPadding text-right">{item.tax.toFixed(2)}</td>
                                                                                                                                <td className="rowPadding text-right">{item.igst.toFixed(2)}</td>
                                                                                                                                <td className="rowPadding text-right">{item.cgst.toFixed(2)}</td>
                                                                                                                                <td className="rowPadding text-right">{item.sgst.toFixed(2)}</td>
                                                                                                                                <td className="rowPadding text-right">{item.cess.toFixed(2)}</td>
                                                                                                                                <td className="rowPadding text-center">{item.period}</td>
                                                                                                                                <td className="rowPadding text-center">{item.fillDate}</td>
                                                                                                                            </tr>
                                                                                                                        })
                                                                                                                    }
                                                                                                                </tbody>
                                                                                                            </table>
                                                                                                            <pagination-controls _ngcontent-gwq-c10="" className="ng-star-inserted">
                                                                                                                <pagination-template>
                                                                                                                    <ul _ngcontent-gwq-c10="" className="ngx-pagination ng-star-inserted" role="navigation" aria-label="Pagination">
                                                                                                                        <li _ngcontent-gwq-c10="" className="pagination-previous disabled ng-star-inserted">
                                                                                                                            <span _ngcontent-gwq-c10="" className="ng-star-inserted"> Previous <span className="show-for-sr">page</span></span>
                                                                                                                        </li>
                                                                                                                        <li _ngcontent-gwq-c10="" className="current ng-star-inserted">
                                                                                                                            <span _ngcontent-gwq-c10="" className="show-for-sr ng-star-inserted">You're on page </span>
                                                                                                                            <span _ngcontent-gwq-c10="" className="ng-star-inserted">1</span>
                                                                                                                        </li>
                                                                                                                        <li _ngcontent-gwq-c10="" className="pagination-next disabled ng-star-inserted">
                                                                                                                            <span _ngcontent-gwq-c10="" className="ng-star-inserted"> Next <span className="show-for-sr">page</span></span>
                                                                                                                        </li>
                                                                                                                    </ul>
                                                                                                                </pagination-template>
                                                                                                            </pagination-controls>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </>
                                                                                        }
                                                                                        {
                                                                                            !isTabD && <>
                                                                                                <div _ngcontent-gwq-c12="" className="row form-inline ng-star-inserted">
                                                                                                    <div _ngcontent-gwq-c12="" className="col-sm-4 form-inline flex items-center mt-2" style={{ width: 'fit-content' }}>
                                                                                                        <label _ngcontent-gwq-c12="" className="plabel">Display/Hide Columns:</label>&nbsp;
                                                                                                        <span>
                                                                                                            <ng-multiselect-dropdown _ngcontent-gwq-c12="" className="multiselectDrp ng-untouched ng-valid ng-dirty" _nghost-gwq-c12="">
                                                                                                                <div _ngcontent-gwq-c12="" className="multiselect-dropdown" tabindex="=0">
                                                                                                                    <Menu>
                                                                                                                        <Menu.Button _ngcontent-gwq-c12="" style={{ border: '0', backgroundColor: 'white', minWidth: '160px' }}>
                                                                                                                            <span _ngcontent-gwq-c12="" className="dropdown-btn" tabindex="-1">
                                                                                                                                <span _ngcontent-gwq-c12="" className='!float-right' style={{ paddingRight: '4px' }}>
                                                                                                                                    <span _ngcontent-gwq-c12="" style={{ paddingRight: '6px' }} className="ng-star-inserted">{fadFillter.count === 0 ? '0' : `+${fadFillter.count}`}</span>
                                                                                                                                    <span _ngcontent-gwq-c12="" className="dropdown-down">
                                                                                                                                    </span>
                                                                                                                                </span>
                                                                                                                            </span>
                                                                                                                        </Menu.Button>
                                                                                                                        <Menu.Items>
                                                                                                                            <div _ngcontent-gwq-c12="" className="dropdown-list">
                                                                                                                                <ul _ngcontent-gwq-c12="" className="item1">
                                                                                                                                    <li _ngcontent-gwq-c12="" className="multiselect-item-checkbox ng-star-inserted" style={{ borderBottom: '1px solid #ccc', padding: '10px' }} onClick={() => { toggleClickAll() }}>
                                                                                                                                        <input _ngcontent-gwq-c12="" aria-label="multiselect-select-all" type="checkbox" checked={getToggleAAll()} />
                                                                                                                                        <div _ngcontent-gwq-c12="">Select All</div>
                                                                                                                                    </li>
                                                                                                                                </ul>
                                                                                                                                <ul _ngcontent-gwq-c12="" className="item2" style={{ maxHeight: '197px' }}>
                                                                                                                                    <li _ngcontent-gwq-c12="" className="multiselect-item-checkbox ng-star-inserted" onClick={() => { toggleClickA('A') }} >
                                                                                                                                        <input _ngcontent-gwq-c12="" aria-label="multiselect-item" type="checkbox" checked={getToggleA('A')} />
                                                                                                                                        <div _ngcontent-gwq-c12="">Trade/legal name</div>
                                                                                                                                    </li>
                                                                                                                                    <li _ngcontent-gwq-c12="" className="multiselect-item-checkbox ng-star-inserted" onClick={() => { toggleClickA('B') }}>
                                                                                                                                        <input _ngcontent-gwq-c12="" aria-label="multiselect-item" type="checkbox" checked={getToggleA('B')} />
                                                                                                                                        <div _ngcontent-gwq-c12="">Invoice number</div>
                                                                                                                                    </li>
                                                                                                                                    <li _ngcontent-gwq-c12="" className="multiselect-item-checkbox ng-star-inserted" onClick={() => { toggleClickA('C') }}>
                                                                                                                                        <input _ngcontent-gwq-c12="" aria-label="multiselect-item" type="checkbox" checked={getToggleA('C')} />
                                                                                                                                        <div _ngcontent-gwq-c12="">Invoice type</div></li>
                                                                                                                                    <li _ngcontent-gwq-c12="" className="multiselect-item-checkbox ng-star-inserted" onClick={() => { toggleClickA('D') }}>
                                                                                                                                        <input _ngcontent-gwq-c12="" aria-label="multiselect-item" type="checkbox" checked={getToggleA('D')} />
                                                                                                                                        <div _ngcontent-gwq-c12="">Invoice Date</div>
                                                                                                                                    </li>
                                                                                                                                    <li _ngcontent-gwq-c12="" className="multiselect-item-checkbox ng-star-inserted" onClick={() => { toggleClickA('E') }}>
                                                                                                                                        <input _ngcontent-gwq-c12="" aria-label="multiselect-item" type="checkbox" checked={getToggleA('E')} />
                                                                                                                                        <div _ngcontent-gwq-c12="">Invoice Value</div>
                                                                                                                                    </li>
                                                                                                                                    <li _ngcontent-gwq-c12="" className="multiselect-item-checkbox ng-star-inserted" onClick={() => { toggleClickA('F') }}>
                                                                                                                                        <input _ngcontent-gwq-c12="" aria-label="multiselect-item" type="checkbox" checked={getToggleA('F')} />
                                                                                                                                        <div _ngcontent-gwq-c12="">Place of supply</div>
                                                                                                                                    </li>
                                                                                                                                    <li _ngcontent-gwq-c12="" className="multiselect-item-checkbox ng-star-inserted" onClick={() => { toggleClickA('G') }}>
                                                                                                                                        <input _ngcontent-gwq-c12="" aria-label="multiselect-item" type="checkbox" checked={getToggleA('G')} />
                                                                                                                                        <div _ngcontent-gwq-c12="">Supply Attract Reverse Charge</div>
                                                                                                                                    </li>
                                                                                                                                    <li _ngcontent-gwq-c12="" className="multiselect-item-checkbox ng-star-inserted" onClick={() => { toggleClickA('H') }}>
                                                                                                                                        <input _ngcontent-gwq-c12="" aria-label="multiselect-item" type="checkbox" checked={getToggleA('H')} />
                                                                                                                                        <div _ngcontent-gwq-c12="">Cess</div>
                                                                                                                                    </li>
                                                                                                                                    <li _ngcontent-gwq-c12="" className="multiselect-item-checkbox ng-star-inserted" onClick={() => { toggleClickA('I') }}>
                                                                                                                                        <input _ngcontent-gwq-c12="" aria-label="multiselect-item" type="checkbox" checked={getToggleA('I')} />
                                                                                                                                        <div _ngcontent-gwq-c12="">GSTR-1/IFF/GSTR-5 Period</div>
                                                                                                                                    </li>
                                                                                                                                    <li _ngcontent-gwq-c12="" className="multiselect-item-checkbox ng-star-inserted" onClick={() => { toggleClickA('J') }}>
                                                                                                                                        <input _ngcontent-gwq-c12="" aria-label="multiselect-item" type="checkbox" checked={getToggleA('J')} />
                                                                                                                                        <div _ngcontent-gwq-c12="">GSTR-1/IFF/GSTR-5 Filing Date</div>
                                                                                                                                    </li>
                                                                                                                                    <li _ngcontent-gwq-c12="" className="multiselect-item-checkbox ng-star-inserted" onClick={() => { toggleClickA('K') }}>
                                                                                                                                        <input _ngcontent-gwq-c12="" aria-label="multiselect-item" type="checkbox" checked={getToggleA('K')} />
                                                                                                                                        <div _ngcontent-gwq-c12="">ITC Availability</div>
                                                                                                                                    </li>
                                                                                                                                    <li _ngcontent-gwq-c12="" className="multiselect-item-checkbox ng-star-inserted" onClick={() => { toggleClickA('L') }}>
                                                                                                                                        <input _ngcontent-gwq-c12="" aria-label="multiselect-item" type="checkbox" checked={getToggleA('L')} />
                                                                                                                                        <div _ngcontent-gwq-c12="">Reason</div>
                                                                                                                                    </li>
                                                                                                                                    <li _ngcontent-gwq-c12="" className="multiselect-item-checkbox ng-star-inserted" onClick={() => { toggleClickA('M') }}>
                                                                                                                                        <input _ngcontent-gwq-c12="" aria-label="multiselect-item" type="checkbox" checked={getToggleA('M')} />
                                                                                                                                        <div _ngcontent-gwq-c12="">Applicable % of Tax Rate</div>
                                                                                                                                    </li>
                                                                                                                                    <li _ngcontent-gwq-c12="" className="multiselect-item-checkbox ng-star-inserted" onClick={() => { toggleClickA('N') }}>
                                                                                                                                        <input _ngcontent-gwq-c12="" aria-label="multiselect-item" type="checkbox" checked={getToggleA('N')} />
                                                                                                                                        <div _ngcontent-gwq-c12="">IRN</div>
                                                                                                                                    </li>
                                                                                                                                    <li _ngcontent-gwq-c12="" className="multiselect-item-checkbox ng-star-inserted" onClick={() => { toggleClickA('O') }}>
                                                                                                                                        <input _ngcontent-gwq-c12="" aria-label="multiselect-item" type="checkbox" checked={getToggleA('O')} />
                                                                                                                                        <div _ngcontent-gwq-c12="">IRN Date</div>
                                                                                                                                    </li>
                                                                                                                                </ul>
                                                                                                                            </div>
                                                                                                                        </Menu.Items>
                                                                                                                    </Menu>
                                                                                                                </div>
                                                                                                            </ng-multiselect-dropdown>
                                                                                                        </span>
                                                                                                    </div>
                                                                                                    <div _ngcontent-gwq-c10="" className=" col-sm-3 form-inline" style={{ width: 'fit-content' }}>
                                                                                                        <label _ngcontent-gwq-c10="" className="plabel">Records Per Page:</label>
                                                                                                        <span>
                                                                                                            <select _ngcontent-gwq-c10="" className=" pull-right form-control" for="display" style={{ width: 'auto', float: 'right', marginLeft: '0px', marginTop: '10px' }} >
                                                                                                                <option _ngcontent-gwq-c10="" selected="" style={{ float: 'right' }} value="10">10</option>
                                                                                                                <option _ngcontent-gwq-c10="" style={{ float: 'right' }} value="20">20</option>
                                                                                                                <option _ngcontent-gwq-c10="" style={{ float: 'right' }} value="50">50</option>
                                                                                                                <option _ngcontent-gwq-c10="" style={{ float: 'right' }} value="100">100</option>
                                                                                                            </select>
                                                                                                        </span>
                                                                                                    </div>
                                                                                                    <div _ngcontent-gwq-c10="" className=" col-sm-3 form-inline " style={{ width: 'fit-content' }}>
                                                                                                        <a _ngcontent-gwq-c10="" href='javascript:void(0)' className="btn btn-default" data-backdrop="static" data-toggle="modal" style={{ height: '37px', marginTop: '10px!important' }} onClick={() => {
                                                                                                            setApFilter(true);
                                                                                                        }}>Apply filter
                                                                                                            <i _ngcontent-gwq-c10="" className="fa fa-filter" style={{ paddingLeft: '3px' }}></i>
                                                                                                        </a>
                                                                                                        {
                                                                                                            isFilter && <a id="closeFilter" href='javascript:void(0)'>
                                                                                                                <Tippy content="Remove Filter" allowHTML={true} placement='top' className='text-center'>
                                                                                                                    <i className="fa fal fa-times-circle" onClick={() => {
                                                                                                                        setAPFillter({ 'invtype': '', 'revc': '', 'ITC': '', 'rtax': '', 'amin': '', 'amax': '', 'bmin': '', 'bmax': '' });
                                                                                                                        setIsFilter(false);
                                                                                                                    }}></i>
                                                                                                                </Tippy>
                                                                                                            </a>
                                                                                                        }
                                                                                                    </div>
                                                                                                    <div _ngcontent-gwq-c10="" className=" col-sm-2 form-inline" style={{ marginTop: '10px', width: 'fit-100px', marginRight: '-40px' }}>
                                                                                                        <Tippy content="Only numbers(0-9), characters(A-Z), space and special characters(/ ' - .) are allowed." allowHTML={true} placement='top' className='text-center'>
                                                                                                            <span _ngcontent-gwq-c10="" style={{ width: '80%' }}>
                                                                                                                <input _ngcontent-gwq-c10="" autocomplete="off" className="modsearch form-control ng-untouched ng-pristine ng-valid" maxLength="32" name="search" placeholder="Search..." style={{ border: 'none', width: '90%' }} type="text" onChange={(e) => { handleOnChangeSearch(e, 'B2BB') }} value={querySearch.B2BB} />
                                                                                                                <i _ngcontent-gwq-c10="" className="fa fa-search" style={{ marginTop: '4px' }}></i>
                                                                                                                {searchErr.B2BB && <span className="text-red-600 text-sm" hidden=""> Enter valid chararcters </span>}
                                                                                                            </span>
                                                                                                        </Tippy>
                                                                                                    </div>
                                                                                                </div>
                                                                                                {
                                                                                                    selGST.gst !== '' && <div _ngcontent-gwq-c10="" className="row ng-star-inserted" id="pill">
                                                                                                        <div _ngcontent-gwq-c10="" className="col-md-12 col-sm-12">
                                                                                                            <div _ngcontent-gwq-c10="" className="pillbutton">
                                                                                                                <a _ngcontent-gwq-c10="" onClick={() => {
                                                                                                                    setSelGST({ 'gst': '', 'name': '' });
                                                                                                                    setSubRow(-1);
                                                                                                                }}>
                                                                                                                    {selGST.gst}&nbsp;
                                                                                                                    <i _ngcontent-gwq-c10="" className="fa fa-close"></i>
                                                                                                                </a>
                                                                                                            </div>
                                                                                                            <div _ngcontent-gwq-c10="" className="pillbutton ng-star-inserted">{selGST.name}</div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                }
                                                                                                <div className="row ng-star-inserted mt-4">
                                                                                                    <div className="col-sm-12 pull-left">
                                                                                                        {
                                                                                                            getDocumentData('b2b').length !== 0 && <div className="table-responsive ng-star-inserted">
                                                                                                                <table className="table table-bordered ">
                                                                                                                    <thead>
                                                                                                                        <tr className='bg-blue-100'>
                                                                                                                            <th className="org" nowrap="">S.NO. </th>
                                                                                                                            {
                                                                                                                                selGST.gst === '' && <th className="org ng-star-inserted" style={{ cursor: 'pointer' }}>
                                                                                                                                    GSTIN of supplier<i className="fa fas fa-sort-asc ng-star-inserted"></i>
                                                                                                                                </th>
                                                                                                                            }
                                                                                                                            {
                                                                                                                                !getToggleA('A') && <th className="org" style={{ cursor: 'pointer' }}>
                                                                                                                                    Trade/legal <br /> name &nbsp;<i className="fa fas fa-sort-asc ng-star-inserted"></i>
                                                                                                                                    <span></span>
                                                                                                                                </th>
                                                                                                                            }
                                                                                                                            {
                                                                                                                                !getToggleA('B') && <th className="org" nowrap="" style={{ cursor: 'pointer' }}>Invoice number
                                                                                                                                    &nbsp;<span><i className="fa fas fa-sort-asc ng-star-inserted"></i></span>
                                                                                                                                </th>
                                                                                                                            }
                                                                                                                            {
                                                                                                                                !getToggleA('C') && <th className="org" nowrap="" style={{ cursor: 'pointer' }}>Invoice type
                                                                                                                                    &nbsp;<span><i className="fa fas fa-sort-asc ng-star-inserted"></i></span>
                                                                                                                                </th>
                                                                                                                            }
                                                                                                                            {
                                                                                                                                !getToggleA('D') && <th className="org" nowrap="" style={{ cursor: 'pointer' }}>Invoice <br />Date
                                                                                                                                    &nbsp;<span><i className="fa fas fa-sort-asc ng-star-inserted"></i></span>
                                                                                                                                    <span></span>
                                                                                                                                </th>
                                                                                                                            }
                                                                                                                            {
                                                                                                                                !getToggleA('E') && <th className="org" nowrap="" style={{ cursor: 'pointer' }}>Invoice <br /> Value (₹)
                                                                                                                                    &nbsp;<span><i className="fa fas fa-sort-asc ng-star-inserted"></i></span>
                                                                                                                                    <span></span>
                                                                                                                                </th>
                                                                                                                            }
                                                                                                                            {
                                                                                                                                !getToggleA('F') && <th className="org" nowrap="" style={{ cursor: 'pointer' }}>Place of <br /> supply
                                                                                                                                    &nbsp;<span><i className="fa fas fa-sort-asc ng-star-inserted"></i></span>
                                                                                                                                    <span></span>
                                                                                                                                </th>
                                                                                                                            }
                                                                                                                            {
                                                                                                                                !getToggleA('G') && <th className="org" nowrap="" style={{ cursor: 'pointer' }}>Supply Attract <br />Reverse Charge
                                                                                                                                    &nbsp;<span><i className="fa fas fa-sort-asc ng-star-inserted"></i></span>
                                                                                                                                    <span></span>
                                                                                                                                </th>
                                                                                                                            }
                                                                                                                            <th className="org" nowrap="" style={{ cursor: 'pointer' }}> Total Taxable <br /> Value (₹)
                                                                                                                                &nbsp;<span><i className="fa fas fa-sort-asc ng-star-inserted"></i></span>
                                                                                                                                <span></span>
                                                                                                                            </th>
                                                                                                                            <th className="org" nowrap="" style={{ width: '3%', cursor: 'pointer' }}>Integrated <br />Tax (₹)
                                                                                                                                &nbsp;<span><i className="fa fas fa-sort-asc ng-star-inserted"></i></span>
                                                                                                                                <span></span>
                                                                                                                            </th>
                                                                                                                            <th className="org" nowrap="" style={{ width: '13%', cursor: 'pointer' }}>Central <br />Tax (₹)
                                                                                                                                &nbsp;<span><i className="fa fas fa-sort-asc ng-star-inserted"></i></span>
                                                                                                                                <span></span>
                                                                                                                            </th>
                                                                                                                            <th className="org" nowrap="" style={{ width: '13%', cursor: 'pointer' }}>State/UT <br />Tax (₹)
                                                                                                                                &nbsp;<span><i className="fa fas fa-sort-asc ng-star-inserted"></i></span>
                                                                                                                                <span></span>
                                                                                                                            </th>
                                                                                                                            {
                                                                                                                                !getToggleA('H') && <th className="org" nowrap="" style={{ width: '9%', cursor: 'pointer' }}>Cess (₹)
                                                                                                                                    &nbsp;<span><i className="fa fas fa-sort-asc ng-star-inserted"></i></span>
                                                                                                                                    <span></span>
                                                                                                                                </th>
                                                                                                                            }
                                                                                                                            {
                                                                                                                                !getToggleA('I') && <th className="org" nowrap="" style={{ width: '9%', cursor: 'pointer' }}>
                                                                                                                                    <span className="ng-star-inserted"> GSTR-1/IFF/GSTR-5 </span>
                                                                                                                                    <br /> Period &nbsp;<span>
                                                                                                                                        <i className="fa fas fa-sort-asc ng-star-inserted"></i>
                                                                                                                                    </span>
                                                                                                                                    <span></span>
                                                                                                                                </th>
                                                                                                                            }
                                                                                                                            {
                                                                                                                                !getToggleA('J') && <th className="org" nowrap="" style={{ width: '9%', cursor: 'pointer' }}>
                                                                                                                                    <span className="ng-star-inserted"> GSTR-1/IFF/GSTR-5 Filing </span>
                                                                                                                                    <br /> Date
                                                                                                                                    &nbsp;<span>
                                                                                                                                        <i className="fa fas fa-sort-asc ng-star-inserted"></i>
                                                                                                                                    </span>
                                                                                                                                    <span>
                                                                                                                                    </span>
                                                                                                                                </th>
                                                                                                                            }
                                                                                                                            {
                                                                                                                                !getToggleA('K') && <th className="org" nowrap="" style={{ width: '9%', cursor: 'pointer' }}>ITC Availability
                                                                                                                                    &nbsp;<span>
                                                                                                                                        <i className="fa fas fa-sort-asc ng-star-inserted"></i>
                                                                                                                                    </span>
                                                                                                                                    <span></span>
                                                                                                                                </th>
                                                                                                                            }
                                                                                                                            {
                                                                                                                                !getToggleA('L') && <th className="org" nowrap="" style={{ width: '9%', cursor: 'pointer' }}>Reason
                                                                                                                                    &nbsp;<span><i className="fa fas fa-sort-asc ng-star-inserted"></i>
                                                                                                                                    </span>
                                                                                                                                    <span></span>
                                                                                                                                </th>
                                                                                                                            }
                                                                                                                            {
                                                                                                                                !getToggleA('M') && <th className="org" nowrap="" style={{ cursor: 'pointer' }} hidden=""> Applicable % of
                                                                                                                                    <br />Tax Rate &nbsp;<span>
                                                                                                                                        <i className="fa fas fa-sort-asc ng-star-inserted"></i>
                                                                                                                                    </span>
                                                                                                                                    <span></span>
                                                                                                                                </th>
                                                                                                                            }
                                                                                                                            <th className="org" nowrap="" style={{ cursor: 'pointer' }}>Source
                                                                                                                                &nbsp;<span><i className="fa fas fa-sort-asc ng-star-inserted"></i></span>
                                                                                                                                <span></span>
                                                                                                                            </th>
                                                                                                                            {
                                                                                                                                !getToggleA('N') && <th className="org" nowrap="" style={{ cursor: 'pointer' }} hidden="">IRN
                                                                                                                                    &nbsp;<span><i className="fa fas fa-sort-asc ng-star-inserted"></i></span>
                                                                                                                                    <span></span>
                                                                                                                                </th>
                                                                                                                            }
                                                                                                                            {
                                                                                                                                !getToggleA('O') && <th className="org" nowrap="" style={{ cursor: 'pointer' }} hidden=""> IRN date
                                                                                                                                    &nbsp;<span><i className="fa fas fa-sort-asc ng-star-inserted"></i></span>
                                                                                                                                    <span></span>
                                                                                                                                </th>
                                                                                                                            }
                                                                                                                        </tr>
                                                                                                                    </thead>
                                                                                                                    <tbody>
                                                                                                                        {
                                                                                                                            getDocumentData('b2b').map((item, index) => {
                                                                                                                                return <>
                                                                                                                                    <tr>
                                                                                                                                        <td className="rowPadding">{(index + 1)}</td>
                                                                                                                                        {selGST.gst === '' && <td className="rowPadding">{item.gstn}</td>}
                                                                                                                                        {!getToggleA('A') && <td className="rowPadding"><p style={{ 'width': '380px', margin: '0', padding: '0' }}>{item.name}</p></td>}
                                                                                                                                        {!getToggleA('B') && <td className="rowPadding text-center">
                                                                                                                                            <div className="flex items-center justify-center">
                                                                                                                                                {item.invnum}&nbsp; <FeatherIcon icon="chevron-down" size={22} className="text-blue-600 font-bold" onClick={() => {
                                                                                                                                                    subRow === index ? setSubRow(-1) : setSubRow(index);
                                                                                                                                                }} />
                                                                                                                                            </div>
                                                                                                                                        </td>}
                                                                                                                                        {!getToggleA('C') && <td className="rowPadding text-center">{item.invtype}</td>}
                                                                                                                                        {!getToggleA('D') && <td className="rowPadding">{item.invdate}</td>}
                                                                                                                                        {!getToggleA('E') && <td className="rowPadding text-right">{item.invval.toFixed(2)}</td>}
                                                                                                                                        {!getToggleA('F') && <td className="rowPadding text-center">{item.pos}</td>}
                                                                                                                                        {!getToggleA('G') && <td className="rowPadding text-center">{item.revc}</td>}
                                                                                                                                        <td className="rowPadding text-right">{item.tax.toFixed(2)}</td>
                                                                                                                                        <td className="rowPadding text-right">{item.igst.toFixed(2)}</td>
                                                                                                                                        <td className="rowPadding text-right">{item.cgst.toFixed(2)}</td>
                                                                                                                                        <td className="rowPadding text-right">{item.sgst.toFixed(2)}</td>
                                                                                                                                        {!getToggleA('H') && <td className="rowPadding text-right">{item.cess.toFixed(2)}</td>}
                                                                                                                                        {!getToggleA('I') && <td className="rowPadding text-center">{item.peroid}</td>}
                                                                                                                                        {!getToggleA('J') && <td className="rowPadding text-center">{item.fData}</td>}
                                                                                                                                        {!getToggleA('K') && <td className="rowPadding text-center">{item.ITC}</td>}
                                                                                                                                        {!getToggleA('L') && <td className="rowPadding"><p style={{ 'width': '460px', margin: '0', padding: '0' }}>{item.reason}</p></td>}
                                                                                                                                        {!getToggleA('M') && <td className="rowPadding text-center">{item.PTax}</td>}
                                                                                                                                        <td className="rowPadding">{item.source}</td>
                                                                                                                                        {!getToggleA('N') && <td className="rowPadding text-center">{item.irn}</td>}
                                                                                                                                        {!getToggleA('O') && <td className="rowPadding text-center">{item.irnd}</td>}
                                                                                                                                    </tr>
                                                                                                                                    {
                                                                                                                                        subRow === index && <tr>
                                                                                                                                            <td colSpan={1}></td>
                                                                                                                                            <td colSpan={5}>
                                                                                                                                                <table className='w-full table table-bordered'>
                                                                                                                                                    <thead className='w-full bg-gray-200'>
                                                                                                                                                        <th className='text-gray-900 px-6 py-1'>Tax Rate (%)</th>
                                                                                                                                                        <th className='text-gray-900 px-6 py-1'>Taxable Value (₹)</th>
                                                                                                                                                        <th className='text-gray-900 px-6 py-1'>Integrated Tax (₹)</th>
                                                                                                                                                        <th className='text-gray-900 px-6 py-1'>Central Tax (₹)</th>
                                                                                                                                                        <th className='text-gray-900 px-6 py-1'>State/UT Tax (₹)</th>
                                                                                                                                                        <th className='text-gray-900 px-6 py-1'>Cess Tax (₹)</th>
                                                                                                                                                    </thead>
                                                                                                                                                    <tbody>
                                                                                                                                                        {
                                                                                                                                                            item.data.map((sub) => {
                                                                                                                                                                return <tr>
                                                                                                                                                                    <td>{sub.rate}</td>
                                                                                                                                                                    <td>{sub.tax.toFixed(2)}</td>
                                                                                                                                                                    <td>{sub.igst.toFixed(2)}</td>
                                                                                                                                                                    <td>{sub.cgst.toFixed(2)}</td>
                                                                                                                                                                    <td>{sub.sgst.toFixed(2)}</td>
                                                                                                                                                                    <td>{sub.cess.toFixed(2)}</td>
                                                                                                                                                                </tr>
                                                                                                                                                            })
                                                                                                                                                        }
                                                                                                                                                    </tbody>
                                                                                                                                                </table>
                                                                                                                                            </td>
                                                                                                                                            <td colSpan={16}></td>
                                                                                                                                        </tr>
                                                                                                                                    }
                                                                                                                                </>
                                                                                                                            })
                                                                                                                        }
                                                                                                                    </tbody>
                                                                                                                </table>
                                                                                                                <pagination-controls className="ng-star-inserted">
                                                                                                                    <pagination-template>
                                                                                                                        <ul className="ngx-pagination ng-star-inserted" role="navigation" aria-label="Pagination">
                                                                                                                            <li className="pagination-previous disabled ng-star-inserted">
                                                                                                                                <span className="ng-star-inserted"> Previous <span className="show-for-sr">page</span></span>
                                                                                                                            </li>
                                                                                                                            <li className="current ng-star-inserted">
                                                                                                                                <span className="show-for-sr ng-star-inserted">You're on page </span>
                                                                                                                                <span className="ng-star-inserted">1</span>
                                                                                                                            </li>
                                                                                                                            <li className="pagination-next disabled ng-star-inserted">
                                                                                                                                <span className="ng-star-inserted"> Next <span className="show-for-sr">page</span></span>
                                                                                                                            </li>
                                                                                                                        </ul>
                                                                                                                    </pagination-template>
                                                                                                                </pagination-controls>
                                                                                                            </div>
                                                                                                        }
                                                                                                        {
                                                                                                            getDocumentData('b2b').length === 0 && <div _ngcontent-joo-c10="" className="row ng-star-inserted">
                                                                                                                <div _ngcontent-joo-c10="" className="col-sm-12 pull-left">
                                                                                                                    <div _ngcontent-joo-c10="" className="alert alert-danger alert-dismissible ng-star-inserted">
                                                                                                                        <span _ngcontent-joo-c10="">No record(s) found for the relevant input.</span>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        }
                                                                                                    </div>
                                                                                                </div>
                                                                                            </>
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </>
                                                                    }
                                                                    {
                                                                        (isRow && cType === 'b2bA') && <>
                                                                            <div _ngcontent-gwq-c10="" className="row">
                                                                                <div _ngcontent-gwq-c10="" className="col-md-10 ">
                                                                                    <ul _ngcontent-gwq-c10="" className="nav nav-pills ng-star-inserted" id="myTab" role="tablist">
                                                                                        <li _ngcontent-gwq-c10="" className={`nav-item rounded-md ${isTabD ? 'active' : ''}`} onClick={() => {
                                                                                            setIsTabD(true);
                                                                                            removeAllFilter();
                                                                                            setSelGST({ 'gst': '', 'name': '' });
                                                                                            setSubRow(-1);
                                                                                        }}>
                                                                                            <a aria-controls="suppsumm" _ngcontent-gwq-c10="" className="nav-link border-right">Supplier wise Details</a>
                                                                                        </li>
                                                                                        <li _ngcontent-gwq-c10="" className={`nav-item rounded-md ${!isTabD ? 'active' : ''}`} onClick={() => {
                                                                                            removeAllFilter();
                                                                                            setIsTabD(false);
                                                                                        }}>
                                                                                            <a aria-controls="docdet" _ngcontent-gwq-c10="" className="nav-link border-right">Document Details</a>
                                                                                        </li>
                                                                                    </ul>
                                                                                </div>
                                                                                <div _ngcontent-gwq-c10="" className="col-sm-2 col-md-2 form-inline ng-star-inserted" style={{ marginTop: '18px' }}>
                                                                                    <a data-toggle="modal" href="javascript:void(0)" onClick={(e) => {
                                                                                        downloadExcel('b2bA');
                                                                                    }} >Download Excel <i _ngcontent-gwq-c10="" className="fa fa-download"></i></a>
                                                                                </div>
                                                                            </div>
                                                                            <hr _ngcontent-gwq-c10="" style={{ margin: '0px' }} />
                                                                            <div _ngcontent-gwq-c10="" className="tab-content ">
                                                                                <div _ngcontent-gwq-c10="" aria-labelledby="docdet-tab" id="docdet" role="tabpanel" className="tab-pane border-0 active ng-star-inserted">
                                                                                    <div _ngcontent-gwq-c10="" className="">
                                                                                        {
                                                                                            isTabD && <>
                                                                                                <div _ngcontent-gwq-c10="" className="row ng-star-inserted flex justify-end">
                                                                                                    <div _ngcontent-gwq-c10="" className=" col-sm-3 form-inline" style={{ width: 'fit-content' }}>
                                                                                                        <label _ngcontent-gwq-c10="" className="plabel">Records Per Page:</label>
                                                                                                        <span>
                                                                                                            <select _ngcontent-gwq-c10="" className=" pull-right form-control" for="display" style={{ width: 'auto', float: 'right', marginLeft: '0px', marginTop: '10px' }} >
                                                                                                                <option _ngcontent-gwq-c10="" selected="" style={{ float: 'right' }} value="10">10</option>
                                                                                                                <option _ngcontent-gwq-c10="" style={{ float: 'right' }} value="20">20</option>
                                                                                                                <option _ngcontent-gwq-c10="" style={{ float: 'right' }} value="50">50</option>
                                                                                                                <option _ngcontent-gwq-c10="" style={{ float: 'right' }} value="100">100</option>
                                                                                                            </select>
                                                                                                        </span>
                                                                                                    </div>
                                                                                                    <div _ngcontent-gwq-c10="" className=" col-sm-2 form-inline" style={{ marginTop: '10px', width: 'fit-100px', marginRight: '-40px' }}>
                                                                                                        <Tippy content="Only numbers(0-9), characters(A-Z), space and special characters(/ ' - .) are allowed." allowHTML={true} placement='top' className='text-center'>
                                                                                                            <span _ngcontent-gwq-c10="" style={{ width: '80%' }}>
                                                                                                                <input autocomplete="off" _ngcontent-gwq-c10="" className="modsearch form-control ng-untouched ng-pristine ng-valid" maxlength="32" name="search" placeholder="Search..." style={{ border: 'none', width: '90%' }} type="text" onChange={(e) => { handleOnChangeSearch(e, 'B2BAA') }} value={querySearch.B2BAA} />
                                                                                                                <i _ngcontent-gwq-c10="" className="fa fa-search" style={{ marginTop: '4px' }}></i>
                                                                                                                {searchErr.B2BAA && <span className="text-red-600 text-sm" hidden=""> Enter valid chararcters </span>}
                                                                                                            </span>
                                                                                                        </Tippy>
                                                                                                    </div>
                                                                                                    <div _ngcontent-gwq-c10="" className=" col-sm-1 form-inline"></div>
                                                                                                </div>
                                                                                                <div _ngcontent-gwq-c10="" className="row ng-star-inserted mt-4">
                                                                                                    <div _ngcontent-gwq-c10="" className="col-sm-12 pull-left">
                                                                                                        <div _ngcontent-gwq-c10="" className="table-responsive ng-star-inserted">
                                                                                                            <table _ngcontent-gwq-c10="" className="table table-bordered ">
                                                                                                                <thead>
                                                                                                                    <tr>
                                                                                                                        <th className="org" nowrap="">S.NO. </th>
                                                                                                                        <th className="org ng-star-inserted" nowrap="" style={{ cursor: 'pointer' }}>GSTIN of supplier
                                                                                                                            &nbsp;<span>
                                                                                                                                <i className="fa fas fa-sort-asc ng-star-inserted"></i>
                                                                                                                            </span>
                                                                                                                            <span></span>
                                                                                                                        </th>
                                                                                                                        <th className="org" nowrap="" style={{ cursor: 'pointer' }}> Trade/legal <br /> name
                                                                                                                            &nbsp;<span><i className="fa fas fa-sort-asc ng-star-inserted"></i></span>
                                                                                                                            <span></span>
                                                                                                                        </th>
                                                                                                                        <th className="org" nowrap="" style={{ cursor: 'pointer' }}> Number of <br /> records
                                                                                                                            &nbsp;<span><i className="fa fas fa-sort-asc ng-star-inserted"></i></span>
                                                                                                                            <span></span>
                                                                                                                        </th>
                                                                                                                        <th className="org" nowrap="" style={{ cursor: 'pointer' }}> Taxable <br /> Value (₹)
                                                                                                                            &nbsp;<span><i className="fa fas fa-sort-asc ng-star-inserted"></i></span>
                                                                                                                            <span></span>
                                                                                                                        </th>
                                                                                                                        <th className="org" nowrap="" style={{ width: '3%', cursor: 'pointer' }}>Integrated <br />Tax (₹)
                                                                                                                            &nbsp;<span><i className="fa fas fa-sort-asc ng-star-inserted"></i></span>
                                                                                                                            <span></span>
                                                                                                                        </th>
                                                                                                                        <th className="org" nowrap="" style={{ width: '13%', cursor: 'pointer' }}>Central <br />Tax (₹)
                                                                                                                            &nbsp;<span><i className="fa fas fa-sort-asc ng-star-inserted"></i></span>
                                                                                                                            <span></span>
                                                                                                                        </th>
                                                                                                                        <th className="org" nowrap="" style={{ width: '13%', cursor: 'pointer' }}>State/UT <br />Tax (₹)
                                                                                                                            &nbsp;<span><i className="fa fas fa-sort-asc ng-star-inserted"></i></span>
                                                                                                                            <span></span>
                                                                                                                        </th>
                                                                                                                        <th className="org" nowrap="" style={{ width: '9%', cursor: 'pointer' }}>Cess (₹)
                                                                                                                            &nbsp;<span><i className="fa fas fa-sort-asc ng-star-inserted"></i></span>
                                                                                                                            <span></span>
                                                                                                                        </th>
                                                                                                                        <th className="org" nowrap="" style={{ width: '9%', cursor: 'pointer' }}>
                                                                                                                            <span className="ng-star-inserted"> GSTR-1/IFF/GSTR-5 </span>
                                                                                                                            &nbsp;<br /> Period <span>
                                                                                                                                <i className="fa fas fa-sort-asc ng-star-inserted"></i>
                                                                                                                            </span>
                                                                                                                            <span></span>
                                                                                                                        </th>
                                                                                                                        <th className="org" nowrap="" style={{ width: '9%', cursor: 'pointer' }}>
                                                                                                                            <span className="ng-star-inserted"> GSTR-1/IFF/GSTR-5 Filing </span>
                                                                                                                            <br /> Date
                                                                                                                            &nbsp;<span>
                                                                                                                                <i className="fa fas fa-sort-asc ng-star-inserted"></i>
                                                                                                                            </span>
                                                                                                                            <span>
                                                                                                                            </span>
                                                                                                                        </th>
                                                                                                                    </tr>
                                                                                                                </thead>
                                                                                                                <tbody>
                                                                                                                    {
                                                                                                                        getSummeryData('b2bA').map((item, index) => {
                                                                                                                            return <tr>
                                                                                                                                <td className="rowPadding">{(index + 1)}</td>
                                                                                                                                <td className="rowPadding text-center"><a onClick={() => {
                                                                                                                                    let newFill = { ...fcdFillter };
                                                                                                                                    newFill.filter = ['M'];
                                                                                                                                    newFill.count = 1;
                                                                                                                                    setFCDFillter(newFill);
                                                                                                                                    setIsTabD(false);
                                                                                                                                    setSelGST({ 'gst': item.gstn, 'name': item.name });
                                                                                                                                }}>{item.gstn}</a></td>
                                                                                                                                <td className="rowPadding"><p style={{ 'width': '380px', margin: '0', padding: '0' }}>{item.name}</p></td>
                                                                                                                                <td className="rowPadding text-center">{item.count}</td>
                                                                                                                                <td className="rowPadding text-right">{item.tax.toFixed(2)}</td>
                                                                                                                                <td className="rowPadding text-right">{item.igst.toFixed(2)}</td>
                                                                                                                                <td className="rowPadding text-right">{item.cgst.toFixed(2)}</td>
                                                                                                                                <td className="rowPadding text-right">{item.sgst.toFixed(2)}</td>
                                                                                                                                <td className="rowPadding text-right">{item.cess.toFixed(2)}</td>
                                                                                                                                <td className="rowPadding text-center">{item.period}</td>
                                                                                                                                <td className="rowPadding text-center">{item.fillDate}</td>
                                                                                                                            </tr>
                                                                                                                        })
                                                                                                                    }
                                                                                                                </tbody>
                                                                                                            </table>
                                                                                                            <pagination-controls _ngcontent-gwq-c10="" className="ng-star-inserted">
                                                                                                                <pagination-template>
                                                                                                                    <ul _ngcontent-gwq-c10="" className="ngx-pagination ng-star-inserted" role="navigation" aria-label="Pagination">
                                                                                                                        <li _ngcontent-gwq-c10="" className="pagination-previous disabled ng-star-inserted">
                                                                                                                            <span _ngcontent-gwq-c10="" className="ng-star-inserted"> Previous <span _ngcontent-gwq-c10="" className="show-for-sr">page</span></span>
                                                                                                                        </li>
                                                                                                                        <li _ngcontent-gwq-c10="" className="current ng-star-inserted">
                                                                                                                            <span _ngcontent-gwq-c10="" className="show-for-sr ng-star-inserted">You're on page </span>
                                                                                                                            <span _ngcontent-gwq-c10="" className="ng-star-inserted">1</span>
                                                                                                                        </li>
                                                                                                                        <li _ngcontent-gwq-c10="" className="pagination-next disabled ng-star-inserted">
                                                                                                                            <span _ngcontent-gwq-c10="" className="ng-star-inserted"> Next <span _ngcontent-gwq-c10="" className="show-for-sr">page</span></span>
                                                                                                                        </li>
                                                                                                                    </ul>
                                                                                                                </pagination-template>
                                                                                                            </pagination-controls>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </>
                                                                                        }
                                                                                        {
                                                                                            !isTabD && <>
                                                                                                <div className="row form-inline ng-star-inserted">
                                                                                                    <div _ngcontent-gwq-c12="" className="col-sm-4 form-inline flex items-center mt-2" style={{ width: 'fit-content' }}>
                                                                                                        <label _ngcontent-gwq-c12="" className="plabel">Display/Hide Columns:</label>&nbsp;
                                                                                                        <span>
                                                                                                            <ng-multiselect-dropdown _ngcontent-gwq-c12="" className="multiselectDrp ng-untouched ng-valid ng-dirty" _nghost-gwq-c12="">
                                                                                                                <div _ngcontent-gwq-c12="" className="multiselect-dropdown" tabindex="=0">
                                                                                                                    <Menu>
                                                                                                                        <Menu.Button _ngcontent-gwq-c12="" style={{ border: '0', backgroundColor: 'white', minWidth: '160px' }}>
                                                                                                                            <span _ngcontent-gwq-c12="" className="dropdown-btn" tabindex="-1">
                                                                                                                                <span _ngcontent-gwq-c12="" className='!float-right' style={{ paddingRight: '4px' }}>
                                                                                                                                    <span _ngcontent-gwq-c12="" style={{ paddingRight: '6px' }} className="ng-star-inserted">{fcdFillter.count === 0 ? '0' : `+${fcdFillter.count}`}</span>
                                                                                                                                    <span _ngcontent-gwq-c12="" className="dropdown-down">
                                                                                                                                    </span>
                                                                                                                                </span>
                                                                                                                            </span>
                                                                                                                        </Menu.Button>
                                                                                                                        <Menu.Items>
                                                                                                                            <div _ngcontent-gwq-c12="" className="dropdown-list">
                                                                                                                                <ul _ngcontent-gwq-c12="" className="item1">
                                                                                                                                    <li _ngcontent-gwq-c12="" className="multiselect-item-checkbox ng-star-inserted" style={{ borderBottom: '1px solid #ccc', padding: '10px' }} onClick={() => { toggleClickAllC() }}>
                                                                                                                                        <input _ngcontent-gwq-c12="" aria-label="multiselect-select-all" type="checkbox" checked={getToggleCAll()} />
                                                                                                                                        <div _ngcontent-gwq-c12="">Select All</div>
                                                                                                                                    </li>
                                                                                                                                </ul>
                                                                                                                                <ul _ngcontent-gwq-c12="" className="item2" style={{ maxHeight: '197px' }}>
                                                                                                                                    <li _ngcontent-gwq-c12="" className="multiselect-item-checkbox ng-star-inserted" onClick={() => { toggleClickC('N') }}>
                                                                                                                                        <input _ngcontent-gwq-c12="" aria-label="multiselect-item" type="checkbox" checked={getToggleC('N')} />
                                                                                                                                        <div _ngcontent-gwq-c12="">Original Invoice Number</div>
                                                                                                                                    </li>
                                                                                                                                    <li _ngcontent-gwq-c12="" className="multiselect-item-checkbox ng-star-inserted" onClick={() => { toggleClickC('O') }}>
                                                                                                                                        <input _ngcontent-gwq-c12="" aria-label="multiselect-item" type="checkbox" checked={getToggleC('O')} />
                                                                                                                                        <div _ngcontent-gwq-c12="">Original Invoice date</div>
                                                                                                                                    </li>
                                                                                                                                    <li _ngcontent-gwq-c12="" className="multiselect-item-checkbox ng-star-inserted" onClick={() => { toggleClickC('A') }} >
                                                                                                                                        <input _ngcontent-gwq-c12="" aria-label="multiselect-item" type="checkbox" checked={getToggleC('A')} />
                                                                                                                                        <div _ngcontent-gwq-c12="">Trade/legal name</div>
                                                                                                                                    </li>
                                                                                                                                    <li _ngcontent-gwq-c12="" className="multiselect-item-checkbox ng-star-inserted" onClick={() => { toggleClickC('B') }}>
                                                                                                                                        <input _ngcontent-gwq-c12="" aria-label="multiselect-item" type="checkbox" checked={getToggleC('B')} />
                                                                                                                                        <div _ngcontent-gwq-c12="">Invoice number</div>
                                                                                                                                    </li>
                                                                                                                                    <li _ngcontent-gwq-c12="" className="multiselect-item-checkbox ng-star-inserted" onClick={() => { toggleClickC('C') }}>
                                                                                                                                        <input _ngcontent-gwq-c12="" aria-label="multiselect-item" type="checkbox" checked={getToggleC('C')} />
                                                                                                                                        <div _ngcontent-gwq-c12="">Invoice type</div></li>
                                                                                                                                    <li _ngcontent-gwq-c12="" className="multiselect-item-checkbox ng-star-inserted" onClick={() => { toggleClickC('D') }}>
                                                                                                                                        <input _ngcontent-gwq-c12="" aria-label="multiselect-item" type="checkbox" checked={getToggleC('D')} />
                                                                                                                                        <div _ngcontent-gwq-c12="">Invoice Date</div>
                                                                                                                                    </li>
                                                                                                                                    <li _ngcontent-gwq-c12="" className="multiselect-item-checkbox ng-star-inserted" onClick={() => { toggleClickC('E') }}>
                                                                                                                                        <input _ngcontent-gwq-c12="" aria-label="multiselect-item" type="checkbox" checked={getToggleC('E')} />
                                                                                                                                        <div _ngcontent-gwq-c12="">Invoice Value</div>
                                                                                                                                    </li>
                                                                                                                                    <li _ngcontent-gwq-c12="" className="multiselect-item-checkbox ng-star-inserted" onClick={() => { toggleClickC('F') }}>
                                                                                                                                        <input _ngcontent-gwq-c12="" aria-label="multiselect-item" type="checkbox" checked={getToggleC('F')} />
                                                                                                                                        <div _ngcontent-gwq-c12="">Place of supply</div>
                                                                                                                                    </li>
                                                                                                                                    <li _ngcontent-gwq-c12="" className="multiselect-item-checkbox ng-star-inserted" onClick={() => { toggleClickC('G') }}>
                                                                                                                                        <input _ngcontent-gwq-c12="" aria-label="multiselect-item" type="checkbox" checked={getToggleC('G')} />
                                                                                                                                        <div _ngcontent-gwq-c12="">Supply Attract Reverse Charge</div>
                                                                                                                                    </li>
                                                                                                                                    <li _ngcontent-gwq-c12="" className="multiselect-item-checkbox ng-star-inserted" onClick={() => { toggleClickC('H') }}>
                                                                                                                                        <input _ngcontent-gwq-c12="" aria-label="multiselect-item" type="checkbox" checked={getToggleC('H')} />
                                                                                                                                        <div _ngcontent-gwq-c12="">Cess</div>
                                                                                                                                    </li>
                                                                                                                                    <li _ngcontent-gwq-c12="" className="multiselect-item-checkbox ng-star-inserted" onClick={() => { toggleClickC('I') }}>
                                                                                                                                        <input _ngcontent-gwq-c12="" aria-label="multiselect-item" type="checkbox" checked={getToggleC('I')} />
                                                                                                                                        <div _ngcontent-gwq-c12="">GSTR-1/IFF/GSTR-5 Period</div>
                                                                                                                                    </li>
                                                                                                                                    <li _ngcontent-gwq-c12="" className="multiselect-item-checkbox ng-star-inserted" onClick={() => { toggleClickC('J') }}>
                                                                                                                                        <input _ngcontent-gwq-c12="" aria-label="multiselect-item" type="checkbox" checked={getToggleC('J')} />
                                                                                                                                        <div _ngcontent-gwq-c12="">GSTR-1/IFF/GSTR-5 Filing Date</div>
                                                                                                                                    </li>
                                                                                                                                    <li _ngcontent-gwq-c12="" className="multiselect-item-checkbox ng-star-inserted" onClick={() => { toggleClickC('K') }}>
                                                                                                                                        <input _ngcontent-gwq-c12="" aria-label="multiselect-item" type="checkbox" checked={getToggleC('K')} />
                                                                                                                                        <div _ngcontent-gwq-c12="">ITC Availability</div>
                                                                                                                                    </li>
                                                                                                                                    <li _ngcontent-gwq-c12="" className="multiselect-item-checkbox ng-star-inserted" onClick={() => { toggleClickC('L') }}>
                                                                                                                                        <input _ngcontent-gwq-c12="" aria-label="multiselect-item" type="checkbox" checked={getToggleC('L')} />
                                                                                                                                        <div _ngcontent-gwq-c12="">Reason</div>
                                                                                                                                    </li>
                                                                                                                                    <li _ngcontent-gwq-c12="" className="multiselect-item-checkbox ng-star-inserted" onClick={() => { toggleClickC('M') }}>
                                                                                                                                        <input _ngcontent-gwq-c12="" aria-label="multiselect-item" type="checkbox" checked={getToggleC('M')} />
                                                                                                                                        <div _ngcontent-gwq-c12="">Applicable % of Tax Rate</div>
                                                                                                                                    </li>
                                                                                                                                </ul>
                                                                                                                            </div>
                                                                                                                        </Menu.Items>
                                                                                                                    </Menu>
                                                                                                                </div>
                                                                                                            </ng-multiselect-dropdown>
                                                                                                        </span>
                                                                                                    </div>
                                                                                                    <div _ngcontent-gwq-c10="" className=" col-sm-3 form-inline" style={{ width: 'fit-content' }}>
                                                                                                        <label _ngcontent-gwq-c10="" className="plabel">Records Per Page:</label>
                                                                                                        <span>
                                                                                                            <select _ngcontent-gwq-c10="" className=" pull-right form-control" for="display" style={{ width: 'auto', float: 'right', marginLeft: '0px', marginTop: '10px' }} >
                                                                                                                <option _ngcontent-gwq-c10="" selected="" style={{ float: 'right' }} value="10">10</option>
                                                                                                                <option _ngcontent-gwq-c10="" style={{ float: 'right' }} value="20">20</option>
                                                                                                                <option _ngcontent-gwq-c10="" style={{ float: 'right' }} value="50">50</option>
                                                                                                                <option _ngcontent-gwq-c10="" style={{ float: 'right' }} value="100">100</option>
                                                                                                            </select>
                                                                                                        </span>
                                                                                                    </div>
                                                                                                    <div _ngcontent-gwq-c10="" className=" col-sm-3 form-inline " style={{ width: 'fit-content' }}>
                                                                                                        <a _ngcontent-gwq-c10="" href='javascript:void(0)' className="btn btn-default" data-backdrop="static" data-toggle="modal" style={{ height: '37px', marginTop: '10px!important' }} onClick={() => {
                                                                                                            setApFilter(true);
                                                                                                        }}>
                                                                                                            Apply filter
                                                                                                            <i _ngcontent-gwq-c10="" className="fa fa-filter" style={{ paddingLeft: '3px' }}></i>
                                                                                                        </a>
                                                                                                        {
                                                                                                            isFilter && <a id="closeFilter" href='javascript:void(0)'>
                                                                                                                <Tippy content="Remove Filter" allowHTML={true} placement='top' className='text-center'>
                                                                                                                    <i className="fa fal fa-times-circle" onClick={() => {
                                                                                                                        setAPFillter({ 'invtype': '', 'revc': '', 'ITC': '', 'rtax': '', 'amin': '', 'amax': '', 'bmin': '', 'bmax': '' });
                                                                                                                        setIsFilter(false);
                                                                                                                    }}></i>
                                                                                                                </Tippy>
                                                                                                            </a>
                                                                                                        }
                                                                                                    </div>
                                                                                                    <div _ngcontent-gwq-c10="" className=" col-sm-2 form-inline" style={{ marginTop: '10px', width: 'fit-100px', marginRight: '-40px' }}>
                                                                                                        <Tippy content="Only numbers(0-9), characters(A-Z), space and special characters(/ ' - .) are allowed." allowHTML={true} placement='top' className='text-center'>
                                                                                                            <span _ngcontent-gwq-c10="" style={{ width: '80%' }}>
                                                                                                                <input _ngcontent-gwq-c10="" autocomplete="off" className="modsearch form-control ng-untouched ng-pristine ng-valid" maxlength="32" name="search" placeholder="Search..." style={{ border: 'none', width: '90%' }} type="text" onChange={(e) => { handleOnChangeSearch(e, 'B2BAB') }} value={querySearch.B2BAB} />
                                                                                                                <i _ngcontent-gwq-c10="" className="fa fa-search" style={{ marginTop: '4px' }}></i>
                                                                                                                {searchErr.B2BAB && <span className="text-red-600 text-sm" hidden=""> Enter valid chararcters </span>}
                                                                                                            </span>
                                                                                                        </Tippy>
                                                                                                    </div>
                                                                                                </div>
                                                                                                {
                                                                                                    selGST.gst !== '' && <div _ngcontent-gwq-c10="" className="row ng-star-inserted" id="pill">
                                                                                                        <div _ngcontent-gwq-c10="" className="col-md-12 col-sm-12">
                                                                                                            <div _ngcontent-gwq-c10="" className="pillbutton">
                                                                                                                <a onClick={() => {
                                                                                                                    setSelGST({ 'gst': '', 'name': '' });
                                                                                                                    setSubRow(-1);
                                                                                                                }}>
                                                                                                                    {selGST.gst}&nbsp;
                                                                                                                    <i _ngcontent-gwq-c10="" className="fa fa-close"></i>
                                                                                                                </a>
                                                                                                            </div>
                                                                                                            <div _ngcontent-gwq-c10="" className="pillbutton ng-star-inserted">{selGST.name}</div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                }
                                                                                                <div className="row ng-star-inserted mt-4">
                                                                                                    <div className="col-sm-12 pull-left">
                                                                                                        {
                                                                                                            getDocumentData('b2bA').length !== 0 && <div className="table-responsive ng-star-inserted">
                                                                                                                <table className="table table-bordered ">
                                                                                                                    <thead>
                                                                                                                        <tr className='bg-blue-100'>
                                                                                                                            <th className="org" nowrap="">S.NO. </th>
                                                                                                                            {
                                                                                                                                !getToggleC('N') && <th className="org" nowrap="" style={{ cursor: 'pointer' }}>Original <br /> Invoice number
                                                                                                                                    &nbsp;<span><i className="fa fas fa-sort-asc ng-star-inserted"></i></span>
                                                                                                                                </th>
                                                                                                                            }
                                                                                                                            {
                                                                                                                                !getToggleC('O') && <th className="org" nowrap="" style={{ cursor: 'pointer' }}>Original <br /> Invoice date
                                                                                                                                    &nbsp;<span><i className="fa fas fa-sort-asc ng-star-inserted"></i></span>
                                                                                                                                </th>
                                                                                                                            }
                                                                                                                            {
                                                                                                                                selGST.gst === '' && <th className="org ng-star-inserted" nowrap="" style={{ cursor: 'pointer' }}>GSTIN of supplier
                                                                                                                                    &nbsp;<span>
                                                                                                                                        <i className="fa fas fa-sort-asc ng-star-inserted"></i>
                                                                                                                                    </span>
                                                                                                                                </th>
                                                                                                                            }
                                                                                                                            {
                                                                                                                                !getToggleC('A') && <th className="org" nowrap="" style={{ cursor: 'pointer' }}> Trade/legal <br /> name
                                                                                                                                    &nbsp;<span><i className="fa fas fa-sort-asc ng-star-inserted"></i></span>
                                                                                                                                    <span></span>
                                                                                                                                </th>
                                                                                                                            }
                                                                                                                            {
                                                                                                                                !getToggleC('B') && <th className="org" nowrap="" style={{ cursor: 'pointer' }}>Invoice number
                                                                                                                                    &nbsp;<span><i className="fa fas fa-sort-asc ng-star-inserted"></i></span>
                                                                                                                                    <span></span>
                                                                                                                                </th>
                                                                                                                            }
                                                                                                                            {
                                                                                                                                !getToggleC('C') && <th className="org" nowrap="" style={{ cursor: 'pointer' }}>Invoice type
                                                                                                                                    &nbsp;<span><i className="fa fas fa-sort-asc ng-star-inserted"></i></span>
                                                                                                                                    <span></span>
                                                                                                                                </th>
                                                                                                                            }
                                                                                                                            {
                                                                                                                                !getToggleC('D') && <th className="org" nowrap="" style={{ cursor: 'pointer' }}>Invoice <br />Date
                                                                                                                                    &nbsp;<span><i className="fa fas fa-sort-asc ng-star-inserted"></i></span>
                                                                                                                                    <span></span>
                                                                                                                                </th>
                                                                                                                            }
                                                                                                                            {
                                                                                                                                !getToggleC('E') && <th className="org" nowrap="" style={{ cursor: 'pointer' }}>Invoice <br /> Value (₹)
                                                                                                                                    &nbsp;<span><i className="fa fas fa-sort-asc ng-star-inserted"></i></span>
                                                                                                                                    <span></span>
                                                                                                                                </th>
                                                                                                                            }
                                                                                                                            {
                                                                                                                                !getToggleC('F') && <th className="org" nowrap="" style={{ cursor: 'pointer' }}>Place of <br /> supply
                                                                                                                                    &nbsp;<span><i className="fa fas fa-sort-asc ng-star-inserted"></i></span>
                                                                                                                                    <span></span>
                                                                                                                                </th>
                                                                                                                            }
                                                                                                                            {
                                                                                                                                !getToggleC('G') && <th className="org" nowrap="" style={{ cursor: 'pointer' }}>Supply Attract <br />Reverse Charge
                                                                                                                                    &nbsp;<span><i className="fa fas fa-sort-asc ng-star-inserted"></i></span>
                                                                                                                                    <span></span>
                                                                                                                                </th>
                                                                                                                            }
                                                                                                                            <th className="org" nowrap="" style={{ cursor: 'pointer' }}> Total Taxable <br /> Value (₹)
                                                                                                                                &nbsp;<span><i className="fa fas fa-sort-asc ng-star-inserted"></i></span>
                                                                                                                                <span></span>
                                                                                                                            </th>
                                                                                                                            <th className="org" nowrap="" style={{ width: '3%', cursor: 'pointer' }}>Integrated <br />Tax (₹)
                                                                                                                                &nbsp;<span><i className="fa fas fa-sort-asc ng-star-inserted"></i></span>
                                                                                                                                <span></span>
                                                                                                                            </th>
                                                                                                                            <th className="org" nowrap="" style={{ width: '13%', cursor: 'pointer' }}>Central <br />Tax (₹)
                                                                                                                                &nbsp;<span><i className="fa fas fa-sort-asc ng-star-inserted"></i></span>
                                                                                                                                <span></span>
                                                                                                                            </th>
                                                                                                                            <th className="org" nowrap="" style={{ width: '13%', cursor: 'pointer' }}>State/UT <br />Tax (₹)
                                                                                                                                &nbsp;<span><i className="fa fas fa-sort-asc ng-star-inserted"></i></span>
                                                                                                                                <span></span>
                                                                                                                            </th>
                                                                                                                            {
                                                                                                                                !getToggleC('H') && <th className="org" nowrap="" style={{ width: '9%', cursor: 'pointer' }}>Cess (₹)
                                                                                                                                    &nbsp;<span><i className="fa fas fa-sort-asc ng-star-inserted"></i></span>
                                                                                                                                    <span></span>
                                                                                                                                </th>
                                                                                                                            }
                                                                                                                            {
                                                                                                                                !getToggleC('I') && <th className="org" nowrap="" style={{ width: '9%', cursor: 'pointer' }}>
                                                                                                                                    <span className="ng-star-inserted"> GSTR-1/IFF/GSTR-5 </span>
                                                                                                                                    <br /> Period &nbsp;<span>
                                                                                                                                        <i className="fa fas fa-sort-asc ng-star-inserted"></i>
                                                                                                                                    </span>
                                                                                                                                </th>
                                                                                                                            }
                                                                                                                            {
                                                                                                                                !getToggleC('J') && <th className="org" nowrap="" style={{ width: '9%', cursor: 'pointer' }}>
                                                                                                                                    <span className="ng-star-inserted"> GSTR-1/IFF/GSTR-5 Filing </span>
                                                                                                                                    <br /> Date
                                                                                                                                    &nbsp;<span>
                                                                                                                                        <i className="fa fas fa-sort-asc ng-star-inserted"></i>
                                                                                                                                    </span>
                                                                                                                                </th>
                                                                                                                            }
                                                                                                                            {
                                                                                                                                !getToggleC('K') && <th className="org" nowrap="" style={{ width: '9%', cursor: 'pointer' }}>ITC Availability
                                                                                                                                    &nbsp;<span>
                                                                                                                                        <i className="fa fas fa-sort-asc ng-star-inserted"></i>
                                                                                                                                    </span>
                                                                                                                                </th>
                                                                                                                            }
                                                                                                                            {
                                                                                                                                !getToggleC('L') && <th className="org" nowrap="" style={{ width: '9%', cursor: 'pointer' }}>Reason
                                                                                                                                    &nbsp;<span><i className="fa fas fa-sort-asc ng-star-inserted"></i>
                                                                                                                                    </span>
                                                                                                                                </th>
                                                                                                                            }
                                                                                                                            {
                                                                                                                                !getToggleC('M') && <th className="org" nowrap="" style={{ cursor: 'pointer' }}> Applicable % of
                                                                                                                                    <br />Tax Rate &nbsp;<span>
                                                                                                                                        <i className="fa fas fa-sort-asc ng-star-inserted"></i>
                                                                                                                                    </span>
                                                                                                                                </th>
                                                                                                                            }
                                                                                                                        </tr>
                                                                                                                    </thead>
                                                                                                                    <tbody>
                                                                                                                        {
                                                                                                                            getDocumentData('b2bA').map((item, index) => {
                                                                                                                                return <>
                                                                                                                                    <tr>
                                                                                                                                        <td className="rowPadding">{(index + 1)}</td>
                                                                                                                                        {!getToggleC('N') && <td className="rowPadding">{item.oinvnum}</td>}
                                                                                                                                        {!getToggleC('O') && <td className="rowPadding">{item.oinvdate}</td>}
                                                                                                                                        {selGST.gst === '' && <td className="rowPadding">{item.gstn}</td>}
                                                                                                                                        {!getToggleC('A') && <td className="rowPadding"><p style={{ 'width': '380px', margin: '0', padding: '0' }}>{item.name}</p></td>}
                                                                                                                                        {!getToggleC('B') && <td className="rowPadding text-center">
                                                                                                                                            <div className="flex items-center justify-center">
                                                                                                                                                {item.invnum}&nbsp; <FeatherIcon icon="chevron-down" size={22} className="text-blue-600 font-bold" onClick={() => {
                                                                                                                                                    subRow === index ? setSubRow(-1) : setSubRow(index);
                                                                                                                                                }} />
                                                                                                                                            </div>
                                                                                                                                        </td>
                                                                                                                                        }
                                                                                                                                        {!getToggleC('C') && <td className="rowPadding text-center">{item.invtype}</td>}
                                                                                                                                        {!getToggleC('D') && <td className="rowPadding text-center">{item.invdate}</td>}
                                                                                                                                        {!getToggleC('E') && <td className="rowPadding text-right">{item.invval.toFixed(2)}</td>}
                                                                                                                                        {!getToggleC('F') && <td className="rowPadding text-center">{item.pos}</td>}
                                                                                                                                        {!getToggleC('G') && <td className="rowPadding text-center">{item.revc}</td>}
                                                                                                                                        <td className="rowPadding text-right">{item.tax.toFixed(2)}</td>
                                                                                                                                        <td className="rowPadding text-right">{item.igst.toFixed(2)}</td>
                                                                                                                                        <td className="rowPadding text-right">{item.cgst.toFixed(2)}</td>
                                                                                                                                        <td className="rowPadding text-right">{item.sgst.toFixed(2)}</td>
                                                                                                                                        {!getToggleC('H') && <td className="rowPadding text-right">{item.cess.toFixed(2)}</td>}
                                                                                                                                        {!getToggleC('I') && <td className="rowPadding text-center">{item.peroid}</td>}
                                                                                                                                        {!getToggleC('J') && <td className="rowPadding text-center">{item.fData}</td>}
                                                                                                                                        {!getToggleC('K') && <td className="rowPadding text-center">{item.ITC}</td>}
                                                                                                                                        {!getToggleC('L') && <td className="rowPadding"><p style={{ 'width': '320px', margin: '0', padding: '0' }}>{item.reason}</p></td>}
                                                                                                                                        {!getToggleC('M') && <td className="rowPadding text-center">{item.PTax}</td>}
                                                                                                                                    </tr>
                                                                                                                                    {
                                                                                                                                        subRow === index && <tr>
                                                                                                                                            <td colSpan={1}></td>
                                                                                                                                            <td colSpan={5}>
                                                                                                                                                <table className='w-full table table-bordered'>
                                                                                                                                                    <thead className='w-full bg-gray-200'>
                                                                                                                                                        <th className='text-gray-900 px-6 py-1'>Tax Rate (%)</th>
                                                                                                                                                        <th className='text-gray-900 px-6 py-1'>Taxable Value (₹)</th>
                                                                                                                                                        <th className='text-gray-900 px-6 py-1'>Integrated Tax (₹)</th>
                                                                                                                                                        <th className='text-gray-900 px-6 py-1'>Central Tax (₹)</th>
                                                                                                                                                        <th className='text-gray-900 px-6 py-1'>State/UT Tax (₹)</th>
                                                                                                                                                        <th className='text-gray-900 px-6 py-1'>Cess Tax (₹)</th>
                                                                                                                                                    </thead>
                                                                                                                                                    <tbody>
                                                                                                                                                        {
                                                                                                                                                            item.data.map((sub) => {
                                                                                                                                                                return <tr>
                                                                                                                                                                    <td>{sub.rate}</td>
                                                                                                                                                                    <td>{sub.tax.toFixed(2)}</td>
                                                                                                                                                                    <td>{sub.igst.toFixed(2)}</td>
                                                                                                                                                                    <td>{sub.cgst.toFixed(2)}</td>
                                                                                                                                                                    <td>{sub.sgst.toFixed(2)}</td>
                                                                                                                                                                    <td>{sub.cess.toFixed(2)}</td>
                                                                                                                                                                </tr>
                                                                                                                                                            })
                                                                                                                                                        }
                                                                                                                                                    </tbody>
                                                                                                                                                </table>
                                                                                                                                            </td>
                                                                                                                                            <td colSpan={13}></td>
                                                                                                                                        </tr>
                                                                                                                                    }
                                                                                                                                </>
                                                                                                                            })
                                                                                                                        }
                                                                                                                    </tbody>
                                                                                                                </table>
                                                                                                                <pagination-controls className="ng-star-inserted">
                                                                                                                    <pagination-template>
                                                                                                                        <ul className="ngx-pagination ng-star-inserted" role="navigation" aria-label="Pagination">
                                                                                                                            <li className="pagination-previous disabled ng-star-inserted">
                                                                                                                                <span className="ng-star-inserted"> Previous <span className="show-for-sr">page</span></span>
                                                                                                                            </li>
                                                                                                                            <li className="current ng-star-inserted">
                                                                                                                                <span className="show-for-sr ng-star-inserted">You're on page </span>
                                                                                                                                <span className="ng-star-inserted">1</span>
                                                                                                                            </li>
                                                                                                                            <li className="pagination-next disabled ng-star-inserted">
                                                                                                                                <span className="ng-star-inserted"> Next <span className="show-for-sr">page</span></span>
                                                                                                                            </li>
                                                                                                                        </ul>
                                                                                                                    </pagination-template>
                                                                                                                </pagination-controls>
                                                                                                            </div>
                                                                                                        }
                                                                                                        {
                                                                                                            getDocumentData('b2bA').length === 0 && <div _ngcontent-joo-c10="" className="row ng-star-inserted">
                                                                                                                <div _ngcontent-joo-c10="" className="col-sm-12 pull-left">
                                                                                                                    <div _ngcontent-joo-c10="" className="alert alert-danger alert-dismissible ng-star-inserted">
                                                                                                                        <span _ngcontent-joo-c10="">No record(s) found for the relevant input.</span>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        }
                                                                                                    </div>
                                                                                                </div>
                                                                                            </>
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </>
                                                                    }
                                                                    {
                                                                        (isRow && cType === 'Cdr') && <>
                                                                            <div _ngcontent-gwq-c10="" className="row">
                                                                                <div _ngcontent-gwq-c10="" className="col-md-10 ">
                                                                                    <ul _ngcontent-gwq-c10="" className="nav nav-pills ng-star-inserted" id="myTab" role="tablist">
                                                                                        <li _ngcontent-gwq-c10="" className={`nav-item rounded-md ${isTabD ? 'active' : ''}`} onClick={() => {
                                                                                            setIsTabD(true);
                                                                                            removeAllFilter();
                                                                                            setSelGST({ 'gst': '', 'name': '' });
                                                                                            setSubRow(-1);
                                                                                        }}>
                                                                                            <a aria-controls="suppsumm" _ngcontent-gwq-c10="" className="nav-link border-right">Supplier wise Details</a>
                                                                                        </li>
                                                                                        <li _ngcontent-gwq-c10="" className={`nav-item rounded-md ${!isTabD ? 'active' : ''}`} onClick={() => {
                                                                                            setIsTabD(false);
                                                                                            removeAllFilter();
                                                                                        }}>
                                                                                            <a aria-controls="docdet" _ngcontent-gwq-c10="" className="nav-link border-right">Document Details</a>
                                                                                        </li>
                                                                                    </ul>
                                                                                </div>
                                                                                <div _ngcontent-gwq-c10="" className="col-sm-2 col-md-2 form-inline ng-star-inserted" style={{ marginTop: '18px' }}>
                                                                                    <a data-toggle="modal" href="javascript:void(0)" onClick={(e) => {
                                                                                        downloadExcel('Cdr');
                                                                                    }}>Download Excel <i _ngcontent-gwq-c10="" className="fa fa-download"></i></a>
                                                                                </div>
                                                                            </div>
                                                                            <hr _ngcontent-gwq-c10="" style={{ margin: '0px' }} />
                                                                            <div _ngcontent-gwq-c10="" className="tab-content ">
                                                                                <div _ngcontent-gwq-c10="" aria-labelledby="docdet-tab" id="docdet" role="tabpanel" className="tab-pane border-0 active ng-star-inserted">
                                                                                    <div _ngcontent-gwq-c10="" className="">
                                                                                        {
                                                                                            isTabD && <>
                                                                                                <div _ngcontent-gwq-c10="" className="row ng-star-inserted flex justify-end">
                                                                                                    <div _ngcontent-gwq-c10="" className=" col-sm-3 form-inline" style={{ width: 'fit-content' }}>
                                                                                                        <label _ngcontent-gwq-c10="" className="plabel">Records Per Page:</label>
                                                                                                        <span>
                                                                                                            <select _ngcontent-gwq-c10="" className="pull-right form-control" for="display" style={{ width: 'auto', float: 'right', marginLeft: '0px', marginTop: '10px' }} >
                                                                                                                <option _ngcontent-gwq-c10="" selected="" style={{ float: 'right' }} value="10">10</option>
                                                                                                                <option _ngcontent-gwq-c10="" style={{ float: 'right' }} value="20">20</option>
                                                                                                                <option _ngcontent-gwq-c10="" style={{ float: 'right' }} value="50">50</option>
                                                                                                                <option _ngcontent-gwq-c10="" style={{ float: 'right' }} value="100">100</option>
                                                                                                            </select>
                                                                                                        </span>
                                                                                                    </div>
                                                                                                    <div _ngcontent-gwq-c10="" className=" col-sm-2 form-inline" style={{ marginTop: '10px', width: 'fit-100px', marginRight: '-40px' }}>
                                                                                                        <Tippy content="Only numbers(0-9), characters(A-Z), space and special characters(/ ' - .) are allowed." allowHTML={true} placement='top' className='text-center'>
                                                                                                            <span _ngcontent-gwq-c10="" style={{ width: '80%' }}>
                                                                                                                <input _ngcontent-gwq-c10="" autocomplete="off" className="modsearch form-control ng-untouched ng-pristine ng-valid" maxlength="32" name="search" placeholder="Search..." style={{ border: 'none', width: '90%' }} type="text" onChange={(e) => { handleOnChangeSearch(e, 'CDRA') }} value={querySearch.CDRA} />
                                                                                                                <i _ngcontent-gwq-c10="" className="fa fa-search" style={{ marginTop: '4px' }}></i>
                                                                                                                {searchErr.CDRA && <span className="text-red-600 text-sm" hidden=""> Enter valid chararcters </span>}
                                                                                                            </span>
                                                                                                        </Tippy>
                                                                                                    </div>
                                                                                                    <div _ngcontent-gwq-c10="" className=" col-sm-1 form-inline"></div>
                                                                                                </div>
                                                                                                <div _ngcontent-gwq-c10="" className="row ng-star-inserted mt-4">
                                                                                                    <div _ngcontent-gwq-c10="" className="col-sm-12 pull-left">
                                                                                                        <div _ngcontent-gwq-c10="" className="table-responsive ng-star-inserted">
                                                                                                            <table _ngcontent-gwq-c10="" className="table table-bordered ">
                                                                                                                <thead>
                                                                                                                    <tr>
                                                                                                                        <th className="org" nowrap="">S.NO. </th>
                                                                                                                        <th className="org ng-star-inserted" nowrap="" style={{ cursor: 'pointer' }}>GSTIN of supplier
                                                                                                                            &nbsp;<span>
                                                                                                                                <i className="fa fas fa-sort-asc ng-star-inserted"></i>
                                                                                                                            </span>
                                                                                                                            <span></span>
                                                                                                                        </th>
                                                                                                                        <th className="org" nowrap="" style={{ cursor: 'pointer' }}> Trade/legal <br /> name
                                                                                                                            &nbsp;<span><i className="fa fas fa-sort-asc ng-star-inserted"></i></span>
                                                                                                                            <span></span>
                                                                                                                        </th>
                                                                                                                        <th className="org" nowrap="" style={{ cursor: 'pointer' }}> Number of <br /> records
                                                                                                                            &nbsp;<span><i className="fa fas fa-sort-asc ng-star-inserted"></i></span>
                                                                                                                            <span></span>
                                                                                                                        </th>
                                                                                                                        <th className="org" nowrap="" style={{ cursor: 'pointer' }}> Note <br /> Type
                                                                                                                            &nbsp;<span><i className="fa fas fa-sort-asc ng-star-inserted"></i></span>
                                                                                                                            <span></span>
                                                                                                                        </th>
                                                                                                                        <th className="org" nowrap="" style={{ cursor: 'pointer' }}> Taxable <br /> Value (₹)
                                                                                                                            &nbsp;<span><i className="fa fas fa-sort-asc ng-star-inserted"></i></span>
                                                                                                                            <span></span>
                                                                                                                        </th>
                                                                                                                        <th className="org" nowrap="" style={{ width: '3%', cursor: 'pointer' }}>Integrated <br />Tax (₹)
                                                                                                                            &nbsp;<span><i className="fa fas fa-sort-asc ng-star-inserted"></i></span>
                                                                                                                            <span></span>
                                                                                                                        </th>
                                                                                                                        <th className="org" nowrap="" style={{ width: '13%', cursor: 'pointer' }}>Central <br />Tax (₹)
                                                                                                                            &nbsp;<span><i className="fa fas fa-sort-asc ng-star-inserted"></i></span>
                                                                                                                            <span></span>
                                                                                                                        </th>
                                                                                                                        <th className="org" nowrap="" style={{ width: '13%', cursor: 'pointer' }}>State/UT <br />Tax (₹)
                                                                                                                            &nbsp;<span><i className="fa fas fa-sort-asc ng-star-inserted"></i></span>
                                                                                                                            <span></span>
                                                                                                                        </th>
                                                                                                                        <th className="org" nowrap="" style={{ width: '9%', cursor: 'pointer' }}>Cess (₹)
                                                                                                                            &nbsp;<span><i className="fa fas fa-sort-asc ng-star-inserted"></i></span>
                                                                                                                            <span></span>
                                                                                                                        </th>
                                                                                                                        <th className="org" nowrap="" style={{ width: '9%', cursor: 'pointer' }}>
                                                                                                                            <span className="ng-star-inserted"> GSTR-1/IFF/GSTR-5 </span>
                                                                                                                            &nbsp;<br /> Period <span>
                                                                                                                                <i className="fa fas fa-sort-asc ng-star-inserted"></i>
                                                                                                                            </span>
                                                                                                                            <span></span>
                                                                                                                        </th>
                                                                                                                        <th className="org" nowrap="" style={{ width: '9%', cursor: 'pointer' }}>
                                                                                                                            <span className="ng-star-inserted"> GSTR-1/IFF/GSTR-5 Filing </span>
                                                                                                                            <br /> Date
                                                                                                                            &nbsp;<span>
                                                                                                                                <i className="fa fas fa-sort-asc ng-star-inserted"></i>
                                                                                                                            </span>
                                                                                                                            <span>
                                                                                                                            </span>
                                                                                                                        </th>
                                                                                                                    </tr>
                                                                                                                </thead>
                                                                                                                <tbody>
                                                                                                                    {
                                                                                                                        getSummeryData('Cdr').map((item, index) => {
                                                                                                                            return <tr>
                                                                                                                                <td className="rowPadding">{(index + 1)}</td>
                                                                                                                                <td className="rowPadding"><a onClick={() => {
                                                                                                                                    let newFill = { ...fbdFillter };
                                                                                                                                    newFill.filter = ['A', 'N'];
                                                                                                                                    newFill.count = 2;
                                                                                                                                    setFBDFillter(newFill);
                                                                                                                                    setIsTabD(false);
                                                                                                                                    setSelGST({ 'gst': item.gstn, 'name': item.name });
                                                                                                                                }}>{item.gstn}</a></td>
                                                                                                                                <td className="rowPadding"><p style={{ 'width': '380px', margin: '0', padding: '0' }}>{item.name}</p></td>
                                                                                                                                <td className="rowPadding text-center">{item.count}</td>
                                                                                                                                <td className="rowPadding text-center"><p style={{ 'width': '100px', margin: '0', padding: '0' }}>{item.notec}</p></td>
                                                                                                                                <td className="rowPadding text-right">{item.tax.toFixed(2)}</td>
                                                                                                                                <td className="rowPadding text-right">{item.igst.toFixed(2)}</td>
                                                                                                                                <td className="rowPadding text-right">{item.cgst.toFixed(2)}</td>
                                                                                                                                <td className="rowPadding text-right">{item.sgst.toFixed(2)}</td>
                                                                                                                                <td className="rowPadding text-right">{item.cess.toFixed(2)}</td>
                                                                                                                                <td className="rowPadding text-center">{item.period}</td>
                                                                                                                                <td className="rowPadding text-center">{item.fillDate}</td>
                                                                                                                            </tr>
                                                                                                                        })
                                                                                                                    }
                                                                                                                </tbody>
                                                                                                            </table>
                                                                                                            <pagination-controls _ngcontent-gwq-c10="" className="ng-star-inserted">
                                                                                                                <pagination-template>
                                                                                                                    <ul _ngcontent-gwq-c10="" className="ngx-pagination ng-star-inserted" role="navigation" aria-label="Pagination">
                                                                                                                        <li _ngcontent-gwq-c10="" className="pagination-previous disabled ng-star-inserted">
                                                                                                                            <span _ngcontent-gwq-c10="" className="ng-star-inserted"> Previous <span _ngcontent-gwq-c10="" className="show-for-sr">page</span></span>
                                                                                                                        </li>
                                                                                                                        <li _ngcontent-gwq-c10="" className="current ng-star-inserted">
                                                                                                                            <span _ngcontent-gwq-c10="" className="show-for-sr ng-star-inserted">You're on page </span>
                                                                                                                            <span _ngcontent-gwq-c10="" className="ng-star-inserted">1</span>
                                                                                                                        </li>
                                                                                                                        <li _ngcontent-gwq-c10="" className="pagination-next disabled ng-star-inserted">
                                                                                                                            <span _ngcontent-gwq-c10="" className="ng-star-inserted"> Next <span _ngcontent-gwq-c10="" className="show-for-sr">page</span></span>
                                                                                                                        </li>
                                                                                                                    </ul>
                                                                                                                </pagination-template>
                                                                                                            </pagination-controls>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </>
                                                                                        }
                                                                                        {
                                                                                            !isTabD && <>
                                                                                                <div _ngcontent-gwq-c10="" className="row form-inline ng-star-inserted">
                                                                                                    <div _ngcontent-gwq-c12="" className="col-sm-4 form-inline flex items-center mt-2" style={{ width: 'fit-content' }}>
                                                                                                        <label _ngcontent-gwq-c12="" className="plabel">Display/Hide Columns:</label>&nbsp;
                                                                                                        <span>
                                                                                                            <ng-multiselect-dropdown _ngcontent-gwq-c12="" className="multiselectDrp ng-untouched ng-valid ng-dirty" _nghost-gwq-c12="">
                                                                                                                <div _ngcontent-gwq-c12="" className="multiselect-dropdown" tabindex="=0">
                                                                                                                    <Menu>
                                                                                                                        <Menu.Button _ngcontent-gwq-c12="" style={{ border: '0', backgroundColor: 'white', minWidth: '160px' }}>
                                                                                                                            <span _ngcontent-gwq-c12="" className="dropdown-btn" tabindex="-1">
                                                                                                                                <span _ngcontent-gwq-c12="" className='!float-right' style={{ paddingRight: '4px' }}>
                                                                                                                                    <span _ngcontent-gwq-c12="" style={{ paddingRight: '6px' }} className="ng-star-inserted">{fbdFillter.count === 0 ? '0' : `+${fbdFillter.count}`}</span>
                                                                                                                                    <span _ngcontent-gwq-c12="" className="dropdown-down">
                                                                                                                                    </span>
                                                                                                                                </span>
                                                                                                                            </span>
                                                                                                                        </Menu.Button>
                                                                                                                        <Menu.Items>
                                                                                                                            <div _ngcontent-gwq-c12="" className="dropdown-list">
                                                                                                                                <ul _ngcontent-gwq-c12="" className="item1">
                                                                                                                                    <li _ngcontent-gwq-c12="" className="multiselect-item-checkbox ng-star-inserted" style={{ borderBottom: '1px solid #ccc', padding: '10px' }} onClick={() => { toggleClickAllB() }}>
                                                                                                                                        <input _ngcontent-gwq-c12="" aria-label="multiselect-select-all" type="checkbox" checked={getToggleBAll()} />
                                                                                                                                        <div _ngcontent-gwq-c12="">Select All</div>
                                                                                                                                    </li>
                                                                                                                                </ul>
                                                                                                                                <ul _ngcontent-gwq-c12="" className="item2" style={{ maxHeight: '197px' }}>
                                                                                                                                    <li _ngcontent-gwq-c12="" className="multiselect-item-checkbox ng-star-inserted" onClick={() => { toggleClickB('A') }} >
                                                                                                                                        <input _ngcontent-gwq-c12="" aria-label="multiselect-item" type="checkbox" checked={getToggleB('A')} />
                                                                                                                                        <div _ngcontent-gwq-c12="">Trade/legal name</div>
                                                                                                                                    </li>
                                                                                                                                    <li _ngcontent-gwq-c12="" className="multiselect-item-checkbox ng-star-inserted" onClick={() => { toggleClickB('B') }}>
                                                                                                                                        <input _ngcontent-gwq-c12="" aria-label="multiselect-item" type="checkbox" checked={getToggleB('B')} />
                                                                                                                                        <div _ngcontent-gwq-c12="">Note number</div>
                                                                                                                                    </li>
                                                                                                                                    <li _ngcontent-gwq-c12="" className="multiselect-item-checkbox ng-star-inserted" onClick={() => { toggleClickB('C') }}>
                                                                                                                                        <input _ngcontent-gwq-c12="" aria-label="multiselect-item" type="checkbox" checked={getToggleB('C')} />
                                                                                                                                        <div _ngcontent-gwq-c12="">Note type</div></li>
                                                                                                                                    <li _ngcontent-gwq-c12="" className="multiselect-item-checkbox ng-star-inserted" onClick={() => { toggleClickB('D') }}>
                                                                                                                                        <input _ngcontent-gwq-c12="" aria-label="multiselect-item" type="checkbox" checked={getToggleB('D')} />
                                                                                                                                        <div _ngcontent-gwq-c12="">Note Supply Type</div>
                                                                                                                                    </li>
                                                                                                                                    <li _ngcontent-gwq-c12="" className="multiselect-item-checkbox ng-star-inserted" onClick={() => { toggleClickB('E') }}>
                                                                                                                                        <input _ngcontent-gwq-c12="" aria-label="multiselect-item" type="checkbox" checked={getToggleB('E')} />
                                                                                                                                        <div _ngcontent-gwq-c12="">Note Date</div>
                                                                                                                                    </li>
                                                                                                                                    <li _ngcontent-gwq-c12="" className="multiselect-item-checkbox ng-star-inserted" onClick={() => { toggleClickB('F') }}>
                                                                                                                                        <input _ngcontent-gwq-c12="" aria-label="multiselect-item" type="checkbox" checked={getToggleB('F')} />
                                                                                                                                        <div _ngcontent-gwq-c12="">Note Value</div>
                                                                                                                                    </li>
                                                                                                                                    <li _ngcontent-gwq-c12="" className="multiselect-item-checkbox ng-star-inserted" onClick={() => { toggleClickB('G') }}>
                                                                                                                                        <input _ngcontent-gwq-c12="" aria-label="multiselect-item" type="checkbox" checked={getToggleB('G')} />
                                                                                                                                        <div _ngcontent-gwq-c12="">Place of supply</div>
                                                                                                                                    </li>
                                                                                                                                    <li _ngcontent-gwq-c12="" className="multiselect-item-checkbox ng-star-inserted" onClick={() => { toggleClickB('H') }}>
                                                                                                                                        <input _ngcontent-gwq-c12="" aria-label="multiselect-item" type="checkbox" checked={getToggleB('H')} />
                                                                                                                                        <div _ngcontent-gwq-c12="">Supply Attract Reverse Charge</div>
                                                                                                                                    </li>
                                                                                                                                    <li _ngcontent-gwq-c12="" className="multiselect-item-checkbox ng-star-inserted" onClick={() => { toggleClickB('I') }}>
                                                                                                                                        <input _ngcontent-gwq-c12="" aria-label="multiselect-item" type="checkbox" checked={getToggleB('J')} />
                                                                                                                                        <div _ngcontent-gwq-c12="">Cess</div>
                                                                                                                                    </li>
                                                                                                                                    <li _ngcontent-gwq-c12="" className="multiselect-item-checkbox ng-star-inserted" onClick={() => { toggleClickB('J') }}>
                                                                                                                                        <input _ngcontent-gwq-c12="" aria-label="multiselect-item" type="checkbox" checked={getToggleB('J')} />
                                                                                                                                        <div _ngcontent-gwq-c12="">GSTR-1/IFF/GSTR-5 Period</div>
                                                                                                                                    </li>
                                                                                                                                    <li _ngcontent-gwq-c12="" className="multiselect-item-checkbox ng-star-inserted" onClick={() => { toggleClickB('K') }}>
                                                                                                                                        <input _ngcontent-gwq-c12="" aria-label="multiselect-item" type="checkbox" checked={getToggleB('K')} />
                                                                                                                                        <div _ngcontent-gwq-c12="">GSTR-1/IFF/GSTR-5 Filing Date</div>
                                                                                                                                    </li>
                                                                                                                                    <li _ngcontent-gwq-c12="" className="multiselect-item-checkbox ng-star-inserted" onClick={() => { toggleClickB('L') }}>
                                                                                                                                        <input _ngcontent-gwq-c12="" aria-label="multiselect-item" type="checkbox" checked={getToggleB('L')} />
                                                                                                                                        <div _ngcontent-gwq-c12="">ITC Availability</div>
                                                                                                                                    </li>
                                                                                                                                    <li _ngcontent-gwq-c12="" className="multiselect-item-checkbox ng-star-inserted" onClick={() => { toggleClickB('M') }}>
                                                                                                                                        <input _ngcontent-gwq-c12="" aria-label="multiselect-item" type="checkbox" checked={getToggleB('M')} />
                                                                                                                                        <div _ngcontent-gwq-c12="">Reason</div>
                                                                                                                                    </li>
                                                                                                                                    <li _ngcontent-gwq-c12="" className="multiselect-item-checkbox ng-star-inserted" onClick={() => { toggleClickB('N') }}>
                                                                                                                                        <input _ngcontent-gwq-c12="" aria-label="multiselect-item" type="checkbox" checked={getToggleB('N')} />
                                                                                                                                        <div _ngcontent-gwq-c12="">Applicable % of Tax Rate</div>
                                                                                                                                    </li>
                                                                                                                                    <li _ngcontent-gwq-c12="" className="multiselect-item-checkbox ng-star-inserted" onClick={() => { toggleClickB('O') }}>
                                                                                                                                        <input _ngcontent-gwq-c12="" aria-label="multiselect-item" type="checkbox" checked={getToggleB('O')} />
                                                                                                                                        <div _ngcontent-gwq-c12="">IRN</div>
                                                                                                                                    </li>
                                                                                                                                    <li _ngcontent-gwq-c12="" className="multiselect-item-checkbox ng-star-inserted" onClick={() => { toggleClickB('P') }}>
                                                                                                                                        <input _ngcontent-gwq-c12="" aria-label="multiselect-item" type="checkbox" checked={getToggleB('P')} />
                                                                                                                                        <div _ngcontent-gwq-c12="">IRN Date</div>
                                                                                                                                    </li>
                                                                                                                                </ul>
                                                                                                                            </div>
                                                                                                                        </Menu.Items>
                                                                                                                    </Menu>
                                                                                                                </div>
                                                                                                            </ng-multiselect-dropdown>
                                                                                                        </span>
                                                                                                    </div>
                                                                                                    <div _ngcontent-gwq-c10="" className=" col-sm-3 form-inline" style={{ width: 'fit-content' }}>
                                                                                                        <label _ngcontent-gwq-c10="" className="plabel">Records Per Page:</label>
                                                                                                        <span>
                                                                                                            <select _ngcontent-gwq-c10="" className=" pull-right form-control" for="display" style={{ width: 'auto', float: 'right', marginLeft: '0px', marginTop: '10px' }} >
                                                                                                                <option _ngcontent-gwq-c10="" selected="" style={{ float: 'right' }} value="10">10</option>
                                                                                                                <option _ngcontent-gwq-c10="" style={{ float: 'right' }} value="20">20</option>
                                                                                                                <option _ngcontent-gwq-c10="" style={{ float: 'right' }} value="50">50</option>
                                                                                                                <option _ngcontent-gwq-c10="" style={{ float: 'right' }} value="100">100</option>
                                                                                                            </select>
                                                                                                        </span>
                                                                                                    </div>
                                                                                                    <div _ngcontent-gwq-c10="" className="col-sm-3 form-inline " style={{ width: 'fit-content' }}>
                                                                                                        <a _ngcontent-gwq-c10="" href='javascript:void(0)' className="btn btn-default" data-backdrop="static" data-toggle="modal" style={{ height: '37px', marginTop: '10px!important' }} onClick={() => {
                                                                                                            setApFilter(true);
                                                                                                        }}>
                                                                                                            Apply filter
                                                                                                            <i _ngcontent-gwq-c10="" className="fa fa-filter" style={{ paddingLeft: '3px' }}></i>
                                                                                                        </a>
                                                                                                        {
                                                                                                            isFilter && <a id="closeFilter" href='javascript:void(0)'>
                                                                                                                <Tippy content="Remove Filter" allowHTML={true} placement='top' className='text-center'>
                                                                                                                    <i className="fa fal fa-times-circle" onClick={() => {
                                                                                                                        setAPFillter({ 'invtype': '', 'revc': '', 'ITC': '', 'rtax': '', 'amin': '', 'amax': '', 'bmin': '', 'bmax': '' });
                                                                                                                        setIsFilter(false);
                                                                                                                    }}></i>
                                                                                                                </Tippy>
                                                                                                            </a>
                                                                                                        }
                                                                                                    </div>
                                                                                                    <div _ngcontent-gwq-c10="" className=" col-sm-2 form-inline" style={{ marginTop: '10px', width: 'fit-100px', marginRight: '-40px' }}>
                                                                                                        <Tippy content="Only numbers(0-9), characters(A-Z), space and special characters(/ ' - .) are allowed." allowHTML={true} placement='top' className='text-center'>
                                                                                                            <span _ngcontent-gwq-c10="" style={{ width: '80%' }}>
                                                                                                                <input _ngcontent-gwq-c10="" autocomplete="off" className="modsearch form-control ng-untouched ng-pristine ng-valid" maxlength="32" name="search" placeholder="Search..." style={{ border: 'none', width: '90%' }} type="text" onChange={(e) => { handleOnChangeSearch(e, 'CDRB') }} value={querySearch.CDRB} />
                                                                                                                <i _ngcontent-gwq-c10="" className="fa fa-search" style={{ marginTop: '4px' }}></i>
                                                                                                                {searchErr.CDRB && <span className="text-red-600 text-sm" hidden=""> Enter valid chararcters </span>}
                                                                                                            </span>
                                                                                                        </Tippy>
                                                                                                    </div>
                                                                                                </div>
                                                                                                {
                                                                                                    selGST.gst !== '' && <div _ngcontent-gwq-c10="" className="row ng-star-inserted" id="pill">
                                                                                                        <div _ngcontent-gwq-c10="" className="col-md-12 col-sm-12">
                                                                                                            <div _ngcontent-gwq-c10="" className="pillbutton">
                                                                                                                <a _ngcontent-gwq-c10="" onClick={() => {
                                                                                                                    setSelGST({ 'gst': '', 'name': '' });
                                                                                                                    setSubRow(-1);
                                                                                                                }}>
                                                                                                                    {selGST.gst}&nbsp;
                                                                                                                    <i _ngcontent-gwq-c10="" className="fa fa-close"></i>
                                                                                                                </a>
                                                                                                            </div>
                                                                                                            <div _ngcontent-gwq-c10="" className="pillbutton ng-star-inserted">{selGST.name}</div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                }
                                                                                                <div className="row ng-star-inserted mt-4">
                                                                                                    <div className="col-sm-12 pull-left">
                                                                                                        {
                                                                                                            getDocumentData('Cdr').length !== 0 && <div className="table-responsive ng-star-inserted">
                                                                                                                <table className="table table-bordered ">
                                                                                                                    <thead>
                                                                                                                        <tr className='bg-blue-100'>
                                                                                                                            <th className="org" nowrap="">S.NO. </th>
                                                                                                                            {
                                                                                                                                selGST.gst === '' && <th className="org ng-star-inserted" nowrap="" style={{ cursor: 'pointer' }}>GSTIN of supplier
                                                                                                                                    &nbsp;<span>
                                                                                                                                        <i className="fa fas fa-sort-asc ng-star-inserted"></i>
                                                                                                                                    </span>
                                                                                                                                </th>
                                                                                                                            }
                                                                                                                            {
                                                                                                                                !getToggleB('A') && <th className="org" nowrap="" style={{ cursor: 'pointer' }}> Trade/legal <br /> name
                                                                                                                                    &nbsp;<span><i className="fa fas fa-sort-asc ng-star-inserted"></i></span>
                                                                                                                                </th>
                                                                                                                            }
                                                                                                                            {
                                                                                                                                !getToggleB('B') && <th className="org" nowrap="" style={{ cursor: 'pointer' }}>Note number
                                                                                                                                    &nbsp;<span><i className="fa fas fa-sort-asc ng-star-inserted"></i></span>
                                                                                                                                </th>
                                                                                                                            }
                                                                                                                            {
                                                                                                                                !getToggleB('C') && <th className="org" nowrap="" style={{ cursor: 'pointer' }}>Note type
                                                                                                                                    &nbsp;<span><i className="fa fas fa-sort-asc ng-star-inserted"></i></span>
                                                                                                                                </th>
                                                                                                                            }
                                                                                                                            {
                                                                                                                                !getToggleB('D') && <th className="org" nowrap="" style={{ cursor: 'pointer' }}>Note Supply type
                                                                                                                                    &nbsp;<span><i className="fa fas fa-sort-asc ng-star-inserted"></i></span>
                                                                                                                                </th>
                                                                                                                            }
                                                                                                                            {
                                                                                                                                !getToggleB('E') && <th className="org" nowrap="" style={{ cursor: 'pointer' }}>Note <br />Date
                                                                                                                                    &nbsp;<span><i className="fa fas fa-sort-asc ng-star-inserted"></i></span>
                                                                                                                                </th>
                                                                                                                            }
                                                                                                                            {
                                                                                                                                !getToggleB('F') && <th className="org" nowrap="" style={{ cursor: 'pointer' }}>Note <br /> Value (₹)
                                                                                                                                    &nbsp;<span><i className="fa fas fa-sort-asc ng-star-inserted"></i></span>
                                                                                                                                </th>
                                                                                                                            }
                                                                                                                            {
                                                                                                                                !getToggleB('G') && <th className="org" nowrap="" style={{ cursor: 'pointer' }}>Place of <br /> supply
                                                                                                                                    &nbsp;<span><i className="fa fas fa-sort-asc ng-star-inserted"></i></span>
                                                                                                                                </th>
                                                                                                                            }
                                                                                                                            {
                                                                                                                                !getToggleB('H') && <th className="org" nowrap="" style={{ cursor: 'pointer' }}>Supply Attract <br />Reverse Charge
                                                                                                                                    &nbsp;<span><i className="fa fas fa-sort-asc ng-star-inserted"></i></span>
                                                                                                                                </th>
                                                                                                                            }
                                                                                                                            <th className="org" nowrap="" style={{ cursor: 'pointer' }}> Total Taxable <br /> Value (₹)
                                                                                                                                &nbsp;<span><i className="fa fas fa-sort-asc ng-star-inserted"></i></span>
                                                                                                                            </th>
                                                                                                                            <th className="org" nowrap="" style={{ width: '3%', cursor: 'pointer' }}>Integrated <br />Tax (₹)
                                                                                                                                &nbsp;<span><i className="fa fas fa-sort-asc ng-star-inserted"></i></span>
                                                                                                                            </th>
                                                                                                                            <th className="org" nowrap="" style={{ width: '13%', cursor: 'pointer' }}>Central <br />Tax (₹)
                                                                                                                                &nbsp;<span><i className="fa fas fa-sort-asc ng-star-inserted"></i></span>
                                                                                                                            </th>
                                                                                                                            <th className="org" nowrap="" style={{ width: '13%', cursor: 'pointer' }}>State/UT <br />Tax (₹)
                                                                                                                                &nbsp;<span><i className="fa fas fa-sort-asc ng-star-inserted"></i></span>
                                                                                                                            </th>
                                                                                                                            {
                                                                                                                                !getToggleB('I') && <th className="org" nowrap="" style={{ width: '9%', cursor: 'pointer' }}>Cess (₹)
                                                                                                                                    &nbsp;<span><i className="fa fas fa-sort-asc ng-star-inserted"></i></span>
                                                                                                                                </th>
                                                                                                                            }
                                                                                                                            {
                                                                                                                                !getToggleB('J') && <th className="org" nowrap="" style={{ width: '9%', cursor: 'pointer' }}>
                                                                                                                                    <span className="ng-star-inserted"> GSTR-1/IFF/GSTR-5 </span>
                                                                                                                                    <br /> Period &nbsp;<span>
                                                                                                                                        <i className="fa fas fa-sort-asc ng-star-inserted"></i>
                                                                                                                                    </span>
                                                                                                                                </th>
                                                                                                                            }
                                                                                                                            {
                                                                                                                                !getToggleB('K') && <th className="org" nowrap="" style={{ width: '9%', cursor: 'pointer' }}>
                                                                                                                                    <span className="ng-star-inserted"> GSTR-1/IFF/GSTR-5 Filing </span>
                                                                                                                                    <br /> Date
                                                                                                                                    &nbsp;<span>
                                                                                                                                        <i className="fa fas fa-sort-asc ng-star-inserted"></i>
                                                                                                                                    </span>
                                                                                                                                </th>
                                                                                                                            }
                                                                                                                            {
                                                                                                                                !getToggleB('L') && <th className="org" nowrap="" style={{ width: '9%', cursor: 'pointer' }}>ITC Availability
                                                                                                                                    &nbsp;<span>
                                                                                                                                        <i className="fa fas fa-sort-asc ng-star-inserted"></i>
                                                                                                                                    </span>
                                                                                                                                </th>
                                                                                                                            }
                                                                                                                            {
                                                                                                                                !getToggleB('M') && <th className="org" nowrap="" style={{ width: '9%', cursor: 'pointer' }}>Reason
                                                                                                                                    &nbsp;<span><i className="fa fas fa-sort-asc ng-star-inserted"></i>
                                                                                                                                    </span>
                                                                                                                                </th>
                                                                                                                            }
                                                                                                                            {
                                                                                                                                !getToggleB('N') && <th className="org" nowrap="" style={{ cursor: 'pointer' }} hidden=""> Applicable % of
                                                                                                                                    <br />Tax Rate &nbsp;<span>
                                                                                                                                        <i className="fa fas fa-sort-asc ng-star-inserted"></i>
                                                                                                                                    </span>
                                                                                                                                </th>
                                                                                                                            }
                                                                                                                            <th className="org" nowrap="" style={{ cursor: 'pointer' }}>Source
                                                                                                                                &nbsp;<span><i className="fa fas fa-sort-asc ng-star-inserted"></i></span>
                                                                                                                            </th>
                                                                                                                            {
                                                                                                                                !getToggleB('O') && <th className="org" nowrap="" style={{ cursor: 'pointer' }} hidden="">IRN
                                                                                                                                    &nbsp;<span><i className="fa fas fa-sort-asc ng-star-inserted"></i></span>
                                                                                                                                </th>
                                                                                                                            }
                                                                                                                            {
                                                                                                                                !getToggleB('P') && <th className="org" nowrap="" style={{ cursor: 'pointer' }} hidden=""> IRN date
                                                                                                                                    &nbsp;<span><i className="fa fas fa-sort-asc ng-star-inserted"></i></span>
                                                                                                                                </th>
                                                                                                                            }
                                                                                                                        </tr>
                                                                                                                    </thead>
                                                                                                                    <tbody>
                                                                                                                        {
                                                                                                                            getDocumentData('Cdr').map((item, index) => {
                                                                                                                                return <>
                                                                                                                                    <tr>
                                                                                                                                        <td className="rowPadding">{(index + 1)}</td>
                                                                                                                                        {selGST.gst === '' && <td className="rowPadding">{item.gstn}</td>}
                                                                                                                                        {!getToggleB('A') && <td className="rowPadding"><p style={{ 'width': '380px', margin: '0', padding: '0' }}>{item.name}</p></td>}
                                                                                                                                        {
                                                                                                                                            !getToggleB('B') && <td className="rowPadding text-center">
                                                                                                                                                <div className="flex items-center justify-center">
                                                                                                                                                    {item.notenum}&nbsp; <FeatherIcon icon="chevron-down" size={22} className="text-blue-600 font-bold" onClick={() => {
                                                                                                                                                        subRow === index ? setSubRow(-1) : setSubRow(index);
                                                                                                                                                    }} />
                                                                                                                                                </div>
                                                                                                                                            </td>
                                                                                                                                        }
                                                                                                                                        {!getToggleB('C') && <td className="rowPadding text-center">{item.notetype}</td>}
                                                                                                                                        {!getToggleB('D') && <td className="rowPadding text-center">{item.notestype}</td>}
                                                                                                                                        {!getToggleB('E') && <td className="rowPadding text-center">{item.notedate}</td>}
                                                                                                                                        {!getToggleB('F') && <td className="rowPadding text-right">{item.noteval.toFixed(2)}</td>}
                                                                                                                                        {!getToggleB('G') && <td className="rowPadding text-center">{item.pos}</td>}
                                                                                                                                        {!getToggleB('H') && <td className="rowPadding text-center">{item.revc}</td>}
                                                                                                                                        <td className="rowPadding text-right">{item.tax.toFixed(2)}</td>
                                                                                                                                        <td className="rowPadding text-right">{item.igst.toFixed(2)}</td>
                                                                                                                                        <td className="rowPadding text-right">{item.cgst.toFixed(2)}</td>
                                                                                                                                        <td className="rowPadding text-right">{item.sgst.toFixed(2)}</td>
                                                                                                                                        {!getToggleB('I') && <td className="rowPadding text-right">{item.cess.toFixed(2)}</td>}
                                                                                                                                        {!getToggleB('J') && <td className="rowPadding text-center">{item.peroid}</td>}
                                                                                                                                        {!getToggleB('K') && <td className="rowPadding text-center">{item.fData}</td>}
                                                                                                                                        {!getToggleB('L') && <td className="rowPadding text-center">{item.ITC}</td>}
                                                                                                                                        {!getToggleB('M') && <td className="rowPadding"><p style={{ 'width': '320px', margin: '0', padding: '0' }}>{item.reason}</p></td>}
                                                                                                                                        {!getToggleB('N') && <td className="text-center">{item.PTax}</td>}
                                                                                                                                        <td className="rowPadding">{item.source}</td>
                                                                                                                                        {!getToggleB('O') && <td className="rowPadding text-center">{item.irn}</td>}
                                                                                                                                        {!getToggleB('P') && <td className="rowPadding text-center">{item.irnd}</td>}
                                                                                                                                    </tr>
                                                                                                                                    {
                                                                                                                                        subRow === index && <tr>
                                                                                                                                            <td colSpan={1}></td>
                                                                                                                                            <td colSpan={6}>
                                                                                                                                                <table className='w-full table table-bordered'>
                                                                                                                                                    <thead className='w-full bg-gray-200'>
                                                                                                                                                        <th className='text-gray-900 px-6 py-1'>Tax Rate (%)</th>
                                                                                                                                                        <th className='text-gray-900 px-6 py-1'>Taxable Value (₹)</th>
                                                                                                                                                        <th className='text-gray-900 px-6 py-1'>Integrated Tax (₹)</th>
                                                                                                                                                        <th className='text-gray-900 px-6 py-1'>Central Tax (₹)</th>
                                                                                                                                                        <th className='text-gray-900 px-6 py-1'>State/UT Tax (₹)</th>
                                                                                                                                                        <th className='text-gray-900 px-6 py-1'>Cess Tax (₹)</th>
                                                                                                                                                    </thead>
                                                                                                                                                    <tbody>
                                                                                                                                                        {
                                                                                                                                                            item.data.map((sub) => {
                                                                                                                                                                return <tr>
                                                                                                                                                                    <td>{sub.rate}</td>
                                                                                                                                                                    <td>{sub.tax.toFixed(2)}</td>
                                                                                                                                                                    <td>{sub.igst.toFixed(2)}</td>
                                                                                                                                                                    <td>{sub.cgst.toFixed(2)}</td>
                                                                                                                                                                    <td>{sub.sgst.toFixed(2)}</td>
                                                                                                                                                                    <td>{sub.cess.toFixed(2)}</td>
                                                                                                                                                                </tr>
                                                                                                                                                            })
                                                                                                                                                        }
                                                                                                                                                    </tbody>
                                                                                                                                                </table>
                                                                                                                                            </td>
                                                                                                                                            <td colSpan={17}></td>
                                                                                                                                        </tr>
                                                                                                                                    }
                                                                                                                                </>
                                                                                                                            })
                                                                                                                        }
                                                                                                                    </tbody>
                                                                                                                </table>
                                                                                                                <pagination-controls className="ng-star-inserted">
                                                                                                                    <pagination-template>
                                                                                                                        <ul className="ngx-pagination ng-star-inserted" role="navigation" aria-label="Pagination">
                                                                                                                            <li className="pagination-previous disabled ng-star-inserted">
                                                                                                                                <span className="ng-star-inserted"> Previous <span className="show-for-sr">page</span></span>
                                                                                                                            </li>
                                                                                                                            <li className="current ng-star-inserted">
                                                                                                                                <span className="show-for-sr ng-star-inserted">You're on page </span>
                                                                                                                                <span className="ng-star-inserted">1</span>
                                                                                                                            </li>
                                                                                                                            <li className="pagination-next disabled ng-star-inserted">
                                                                                                                                <span className="ng-star-inserted"> Next <span className="show-for-sr">page</span></span>
                                                                                                                            </li>
                                                                                                                        </ul>
                                                                                                                    </pagination-template>
                                                                                                                </pagination-controls>
                                                                                                            </div>
                                                                                                        }
                                                                                                        {
                                                                                                            getDocumentData('Cdr').length === 0 && <div _ngcontent-joo-c10="" className="row ng-star-inserted">
                                                                                                                <div _ngcontent-joo-c10="" className="col-sm-12 pull-left">
                                                                                                                    <div _ngcontent-joo-c10="" className="alert alert-danger alert-dismissible ng-star-inserted">
                                                                                                                        <span _ngcontent-joo-c10="">No record(s) found for the relevant input.</span>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        }
                                                                                                    </div>
                                                                                                </div>
                                                                                            </>
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </>
                                                                    }
                                                                    {
                                                                        (isRow && cType === 'IMPG') && <>
                                                                            <div _ngcontent-gwq-c10="" className="row">
                                                                                <div _ngcontent-gwq-c10="" className="col-md-10 ">
                                                                                    <ul _ngcontent-gwq-c10="" className="nav nav-pills ng-star-inserted" id="myTab" role="tablist">
                                                                                        <li _ngcontent-gwq-c10="" class={`nav-item rounded-md ${isTabD ? '' : 'active'}`} onClick={() => {
                                                                                            setIsTabD(false);
                                                                                            setSubRow(-1);
                                                                                            removeAllFilter();
                                                                                        }}>
                                                                                            <a _ngcontent-gwq-c10="" aria-controls="docdet" className="nav-link border-right">Document Details</a>
                                                                                        </li>
                                                                                    </ul>
                                                                                </div>
                                                                                <div _ngcontent-gwq-c10="" className="col-sm-2 col-md-2 form-inline ng-star-inserted" style={{ marginTop: '18px' }}>
                                                                                    <a _ngcontent-gwq-c10="" data-toggle="modal" href="javascript:void(0)" onClick={(e) => {
                                                                                        downloadExcel('IMPG');
                                                                                    }}>Download Excel <i _ngcontent-gwq-c10="" className="fa fa-download"></i></a>
                                                                                </div>
                                                                            </div>
                                                                            <hr _ngcontent-gwq-c10="" style={{ margin: '0px' }} />
                                                                            <div _ngcontent-gwq-c10="" className="tab-content ">
                                                                                <div _ngcontent-gwq-c10="" aria-labelledby="docdet-tab" id="docdet" role="tabpanel" className="tab-pane border-0 active ng-star-inserted">
                                                                                    <div _ngcontent-gwq-c10="" className="">
                                                                                        {
                                                                                            !isTabD && <>
                                                                                                <div _ngcontent-gwq-c10="" className="row form-inline ng-star-inserted">
                                                                                                    <div _ngcontent-gwq-c10="" className=" col-sm-3 form-inline" style={{ width: 'fit-content' }}>
                                                                                                        <label _ngcontent-gwq-c10="" className="plabel">Records Per Page:</label>
                                                                                                        <span>
                                                                                                            <select _ngcontent-gwq-c10="" className=" pull-right form-control" for="display" style={{ width: 'auto', float: 'right', marginLeft: '0px', marginTop: '10px' }} >
                                                                                                                <option _ngcontent-gwq-c10="" selected="" style={{ float: 'right' }} value="10">10</option>
                                                                                                                <option _ngcontent-gwq-c10="" style={{ float: 'right' }} value="20">20</option>
                                                                                                                <option _ngcontent-gwq-c10="" style={{ float: 'right' }} value="50">50</option>
                                                                                                                <option _ngcontent-gwq-c10="" style={{ float: 'right' }} value="100">100</option>
                                                                                                            </select>
                                                                                                        </span>
                                                                                                    </div>
                                                                                                    <div _ngcontent-gwq-c10="" className=" col-sm-3 form-inline " style={{ width: 'fit-content' }}>
                                                                                                        <a _ngcontent-gwq-c10="" href='javascript:void(0)' className="btn btn-default" data-backdrop="static" data-toggle="modal" style={{ height: '37px', marginTop: '10px!important' }} onClick={() => {
                                                                                                            setApFilter(true);
                                                                                                        }}>
                                                                                                            Apply filter
                                                                                                            <i _ngcontent-gwq-c10="" className="fa fa-filter" style={{ paddingLeft: '3px' }}></i>
                                                                                                        </a>
                                                                                                        {
                                                                                                            isFilter && <a id="closeFilter" href='javascript:void(0)'>
                                                                                                                <Tippy content="Remove Filter" allowHTML={true} placement='top' className='text-center'>
                                                                                                                    <i className="fa fal fa-times-circle" onClick={() => {
                                                                                                                        setAPFillter({ 'invtype': '', 'revc': '', 'ITC': '', 'rtax': '', 'amin': '', 'amax': '', 'bmin': '', 'bmax': '' });
                                                                                                                        setIsFilter(false);
                                                                                                                    }}></i>
                                                                                                                </Tippy>
                                                                                                            </a>
                                                                                                        }
                                                                                                    </div>
                                                                                                    <div _ngcontent-gwq-c10="" className=" col-sm-2 form-inline" style={{ marginTop: '10px', width: 'fit-100px', marginRight: '-40px' }}>
                                                                                                        <Tippy content="Only numbers(0-9), characters(A-Z), space and special characters(/ ' - .) are allowed." allowHTML={true} placement='top' className='text-center'>
                                                                                                            <span _ngcontent-gwq-c10="" style={{ width: '80%' }}>
                                                                                                                <input _ngcontent-gwq-c10="" autocomplete="off" className="modsearch form-control ng-untouched ng-pristine ng-valid" maxlength="32" name="search" placeholder="Search..." style={{ border: 'none', width: '90%' }} type="text" onChange={(e) => { handleOnChangeSearch(e, 'IMPG') }} value={querySearch.IMPG} />
                                                                                                                <i _ngcontent-gwq-c10="" className="fa fa-search" style={{ marginTop: '4px' }}></i>
                                                                                                                {searchErr.IMPG && <span className="text-red-600 text-sm" hidden=""> Enter valid chararcters </span>}
                                                                                                            </span>
                                                                                                        </Tippy>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div _ngcontent-gwq-c10="" className="row ng-star-inserted mt-4">
                                                                                                    <div _ngcontent-gwq-c10="" className="col-sm-12 pull-left">
                                                                                                        {
                                                                                                            getDocumentData('IMPG').length !== 0 && <div _ngcontent-gwq-c10="" className="table-responsive ng-star-inserted">
                                                                                                                <table _ngcontent-gwq-c10="" className="table table-bordered ">
                                                                                                                    <thead>
                                                                                                                        <tr _ngcontent-gwq-c10="" className='bg-blue-100'>
                                                                                                                            <th _ngcontent-gwq-c10="" className="org" nowrap="">S.NO. </th>
                                                                                                                            <th _ngcontent-gwq-c10="" className="org ng-star-inserted" nowrap="" style={{ cursor: 'pointer' }}>ICEGATE Reference Date
                                                                                                                                &nbsp;<span>
                                                                                                                                    <i _ngcontent-gwq-c10="" className="fa fas fa-sort-asc ng-star-inserted"></i>
                                                                                                                                </span>
                                                                                                                            </th>
                                                                                                                            <th _ngcontent-gwq-c10="" className="org" nowrap="" style={{ cursor: 'pointer' }}> Port Code
                                                                                                                                &nbsp;<span><i _ngcontent-gwq-c10="" className="fa fas fa-sort-asc ng-star-inserted"></i></span>
                                                                                                                            </th>
                                                                                                                            <th _ngcontent-gwq-c10="" className="org" nowrap="" style={{ cursor: 'pointer' }}>Bill of Entry <br /> Number
                                                                                                                                &nbsp;<span><i _ngcontent-gwq-c10="" className="fa fas fa-sort-asc ng-star-inserted"></i></span>
                                                                                                                            </th>
                                                                                                                            <th _ngcontent-gwq-c10="" className="org" nowrap="" style={{ cursor: 'pointer' }}>Bill of Entry <br /> Date
                                                                                                                                &nbsp;<span><i _ngcontent-gwq-c10="" className="fa fas fa-sort-asc ng-star-inserted"></i></span>
                                                                                                                            </th>
                                                                                                                            <th _ngcontent-gwq-c10="" className="org" nowrap="" style={{ cursor: 'pointer' }}> Taxable <br /> Value (₹)
                                                                                                                                &nbsp;<span><i _ngcontent-gwq-c10="" className="fa fas fa-sort-asc ng-star-inserted"></i></span>
                                                                                                                            </th>
                                                                                                                            <th _ngcontent-gwq-c10="" className="org" nowrap="" style={{ width: '3%', cursor: 'pointer' }}>Integrated <br />Tax (₹)
                                                                                                                                &nbsp;<span><i _ngcontent-gwq-c10="" className="fa fas fa-sort-asc ng-star-inserted"></i></span>
                                                                                                                            </th>
                                                                                                                            <th _ngcontent-gwq-c10="" className="org" nowrap="" style={{ width: '9%', cursor: 'pointer' }}>Cess (₹)
                                                                                                                                &nbsp;<span><i _ngcontent-gwq-c10="" className="fa fas fa-sort-asc ng-star-inserted"></i></span>
                                                                                                                            </th>
                                                                                                                            <th _ngcontent-gwq-c10="" className="org" nowrap="" style={{ width: '9%', cursor: 'pointer' }}>Amended (Yes)
                                                                                                                                &nbsp;<span>
                                                                                                                                    <i _ngcontent-gwq-c10="" className="fa fas fa-sort-asc ng-star-inserted"></i>
                                                                                                                                </span>
                                                                                                                            </th>
                                                                                                                        </tr>
                                                                                                                    </thead>
                                                                                                                    <tbody>
                                                                                                                        {
                                                                                                                            getDocumentData('IMPG').map((item, index) => {
                                                                                                                                return <tr>
                                                                                                                                    <td className="rowPadding">{(index + 1)}</td>
                                                                                                                                    <td className="rowPadding"><p style={{ 'width': '380px', margin: '0', padding: '0' }}>{item.irDate}</p></td>
                                                                                                                                    <td className='rowPadding text-center'>{item.portcode}</td>
                                                                                                                                    <td className='rowPadding text-center'>{item.num}</td>
                                                                                                                                    <td className='rowPadding text-center'>{item.date}</td>
                                                                                                                                    <td className='rowPadding text-right'>{item.tax.toFixed(2)}</td>
                                                                                                                                    <td className='rowPadding text-right'>{item.igst.toFixed(2)}</td>
                                                                                                                                    <td className='rowPadding text-right'>{item.cess.toFixed(2)}</td>
                                                                                                                                    <td className='rowPadding text-center'>NO</td>
                                                                                                                                </tr>
                                                                                                                            })
                                                                                                                        }
                                                                                                                    </tbody>
                                                                                                                </table>
                                                                                                                <pagination-controls _ngcontent-gwq-c10="" className="ng-star-inserted">
                                                                                                                    <pagination-template>
                                                                                                                        <ul _ngcontent-gwq-c10="" className="ngx-pagination ng-star-inserted" role="navigation" aria-label="Pagination">
                                                                                                                            <li _ngcontent-gwq-c10="" className="pagination-previous disabled ng-star-inserted">
                                                                                                                                <span _ngcontent-gwq-c10="" className="ng-star-inserted"> Previous <span _ngcontent-gwq-c10="" className="show-for-sr">page</span></span>
                                                                                                                            </li>
                                                                                                                            <li _ngcontent-gwq-c10="" className="current ng-star-inserted">
                                                                                                                                <span _ngcontent-gwq-c10="" className="show-for-sr ng-star-inserted">You're on page </span>
                                                                                                                                <span _ngcontent-gwq-c10="" className="ng-star-inserted">1</span>
                                                                                                                            </li>
                                                                                                                            <li _ngcontent-gwq-c10="" className="pagination-next disabled ng-star-inserted">
                                                                                                                                <span _ngcontent-gwq-c10="" className="ng-star-inserted"> Next <span _ngcontent-gwq-c10="" className="show-for-sr">page</span></span>
                                                                                                                            </li>
                                                                                                                        </ul>
                                                                                                                    </pagination-template>
                                                                                                                </pagination-controls>
                                                                                                            </div>
                                                                                                        }
                                                                                                        {
                                                                                                            getDocumentData('IMPG').length === 0 && <div _ngcontent-joo-c10="" className="row ng-star-inserted">
                                                                                                                <div _ngcontent-joo-c10="" className="col-sm-12 pull-left">
                                                                                                                    <div _ngcontent-joo-c10="" className="alert alert-danger alert-dismissible ng-star-inserted">
                                                                                                                        <span _ngcontent-joo-c10="">No record(s) found for the relevant input.</span>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        }
                                                                                                    </div>
                                                                                                </div>
                                                                                            </>
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </>
                                                                    }
                                                                    <Dialog open={apFilter} onClose={() => setApFilter(false)}>
                                                                        <div _ngcontent-mkn-c10="" className="modal in" style={{ display: 'block', paddingRight: '17px' }}>
                                                                            <div _ngcontent-mkn-c10="" className="modal-center">
                                                                                <div _ngcontent-mkn-c10="" className="modal-dialog">
                                                                                    <div _ngcontent-mkn-c10="" className="modal-content" style={{ width: '725px' }}>
                                                                                        <div _ngcontent-mkn-c10="" className="p-0">
                                                                                            <div _ngcontent-mkn-c10="" className="col-sm-12 pull-left head">
                                                                                                <div _ngcontent-mkn-c10="" className="row top" style={{ backgroundColor: '#17C4BB' }}>
                                                                                                    <div _ngcontent-mkn-c10="" className="col-xs-12 col-sm-12 taxp" id="modal-title">
                                                                                                        <button _ngcontent-mkn-c10="" className="close" data-dismiss="modal" style={{ marginTop: '8px' }} type="button" onClick={() => {
                                                                                                            setApFilter(false);
                                                                                                        }}> × </button>
                                                                                                        <h4 _ngcontent-mkn-c10="" style={{ paddingLeft: '10px', color: '#0b1e59' }}> Apply filter</h4>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div _ngcontent-mkn-c10="" className="tabpane">
                                                                                                <div _ngcontent-mkn-c10="" className="row" style={{ marginTop: '30px' }}>
                                                                                                    <table _ngcontent-mkn-c10="" className="table table-borderless" id="table" style={{ width: 'fit-content' }}>
                                                                                                        <thead _ngcontent-mkn-c10=""></thead>
                                                                                                        <tbody _ngcontent-mkn-c10="">
                                                                                                            <tr _ngcontent-mkn-c10="">
                                                                                                                <td _ngcontent-mkn-c10="" className="filterHeader border-none" style={{ border: 'none !important' }}><span _ngcontent-mkn-c10="">Invoice Date :</span></td>
                                                                                                                <td _ngcontent-mkn-c10="" className="border-none" style={{ border: 'none !important' }}>
                                                                                                                    From
                                                                                                                    <p-calendar _ngcontent-mkn-c10="" className="relative" style={{ margin: '6px' }}>
                                                                                                                        <span className="ng-tns-c13-0 ui-calendar">
                                                                                                                            <input autocomplete="off" type="date" className="ng-tns-c13-0 ui-inputtext ui-widget ui-state-default ui-corner-all" name="amin" min={getSize('amin')} max={getSize('max')} placeholder="dd/mm/yyyy" style={{ padding: '4px', width: '180px' }} onChange={changeDateFillter} />
                                                                                                                        </span>
                                                                                                                    </p-calendar>
                                                                                                                    To
                                                                                                                    <p-calendar _ngcontent-mkn-c10="" dateformat="dd/mm/yy" placeholder="dd/mm/yyyy" className="ng-tns-c13-1 ng-untouched ng-pristine ng-valid" style={{ margin: '6px' }}>
                                                                                                                        <span className="ng-tns-c13-1 ui-calendar">
                                                                                                                            <input autocomplete="off" type="date" className="ng-tns-c13-1 ui-inputtext ui-widget ui-state-default ui-corner-all" name="amax" min={getSize('bmin')} max={getSize('max')} placeholder="dd/mm/yyyy" style={{ padding: '4px', width: '180px' }} onChange={changeDateFillter} />
                                                                                                                        </span>
                                                                                                                    </p-calendar>
                                                                                                                </td>
                                                                                                            </tr>
                                                                                                            <tr _ngcontent-mkn-c10="">
                                                                                                                <td _ngcontent-mkn-c10="" className="filterHeader border-none" style={{ border: 'none !important' }}><span _ngcontent-mkn-c10="">Invoice type :</span></td>
                                                                                                                <td _ngcontent-mkn-c10="" className="border-none" style={{ border: 'none !important' }}>
                                                                                                                    <div _ngcontent-mkn-c10="" className="form-group">
                                                                                                                        <select _ngcontent-mkn-c10="" className="form-control pcal" name="invtype" value={apFillter.invtype} onChange={handleOnChangeForm}>
                                                                                                                            <option _ngcontent-mkn-c10="" value="">Select</option>
                                                                                                                            <option _ngcontent-mkn-c10="" value="R">Regular</option>
                                                                                                                            <option _ngcontent-mkn-c10="" value="DE">Deemed Export</option>
                                                                                                                            <option _ngcontent-mkn-c10="" value="SEWP">SEZ supplies with payment of tax</option>
                                                                                                                            <option _ngcontent-mkn-c10="" value="SEWOP">SEZ supplies without payment of tax </option>
                                                                                                                        </select>
                                                                                                                    </div>
                                                                                                                </td>
                                                                                                            </tr>
                                                                                                            <tr _ngcontent-mkn-c10="">
                                                                                                                <td _ngcontent-mkn-c10="" className="filterHeader border-none" style={{ border: 'none !important' }}><span _ngcontent-mkn-c10="">Supply Attract Reverse Charge :</span></td>
                                                                                                                <td _ngcontent-mkn-c10="" className="border-none" style={{ border: 'none !important' }}>
                                                                                                                    <div _ngcontent-mkn-c10="" className="form-group">
                                                                                                                        <select _ngcontent-mkn-c10="" className="form-control pcal" name="revc" value={apFillter.revc} onChange={handleOnChangeForm}>
                                                                                                                            <option _ngcontent-mkn-c10="" value="">Select</option>
                                                                                                                            <option _ngcontent-mkn-c10="" value="Yes">Yes</option>
                                                                                                                            <option _ngcontent-mkn-c10="" value="No">No</option>
                                                                                                                        </select>
                                                                                                                    </div>
                                                                                                                </td>
                                                                                                            </tr>
                                                                                                            <tr _ngcontent-mkn-c10="">
                                                                                                                <td _ngcontent-mkn-c10="" className="filterHeader border-none" style={{ border: 'none !important' }}>
                                                                                                                    <span _ngcontent-mkn-c10="" className="ng-star-inserted"> GSTR-1/IFF/GSTR-5 Filing Date: </span>
                                                                                                                </td>
                                                                                                                <td _ngcontent-mkn-c10="" className="border-none" style={{ border: 'none !important' }}>
                                                                                                                    From
                                                                                                                    <p-calendar _ngcontent-mkn-c10="" dateformat="dd/mm/yy" placeholder="dd/mm/yyyy" className="ng-tns-c13-2 ng-untouched ng-pristine ng-valid" style={{ margin: '6px' }}>
                                                                                                                        <span className="ng-tns-c13-2 ui-calendar">
                                                                                                                            <input autocomplete="off" type="date" className="ng-tns-c13-2 ui-inputtext ui-widget ui-state-default ui-corner-all" name="bmin" min={getSize('amin')} max={getSize('max')} placeholder="dd/mm/yyyy" style={{ padding: '4px', width: '180px' }} onChange={changeDateFillter} />
                                                                                                                        </span>
                                                                                                                    </p-calendar>
                                                                                                                    To
                                                                                                                    <p-calendar _ngcontent-mkn-c10="" dateformat="dd/mm/yy" placeholder="dd/mm/yyyy" className="ng-tns-c13-3 ng-untouched ng-pristine ng-valid" style={{ margin: '6px' }}>
                                                                                                                        <span className="ng-tns-c13-3 ui-calendar">
                                                                                                                            <input autocomplete="off" type="date" className="ng-tns-c13-3 ui-inputtext ui-widget ui-state-default ui-corner-all" name="bmax" min={getSize('cmin')} max={getSize('max')} placeholder="dd/mm/yyyy" style={{ padding: '4px', width: '180px' }} onChange={changeDateFillter} />
                                                                                                                        </span>
                                                                                                                    </p-calendar>
                                                                                                                </td>
                                                                                                            </tr>
                                                                                                            <tr _ngcontent-mkn-c10="">
                                                                                                                <td _ngcontent-mkn-c10="" className="filterHeader border-none" style={{ border: 'none !important' }}><span _ngcontent-mkn-c10="">ITC Availability :</span></td>
                                                                                                                <td _ngcontent-mkn-c10="" className="border-none" style={{ border: 'none !important' }}>
                                                                                                                    <div _ngcontent-mkn-c10="" className="form-group">
                                                                                                                        <select _ngcontent-mkn-c10="" className="form-control pcal" name="ITC" value={apFillter.ITC} onChange={handleOnChangeForm}>
                                                                                                                            <option _ngcontent-mkn-c10="" value="">Select</option>
                                                                                                                            <option _ngcontent-mkn-c10="" value="Yes">Yes</option>
                                                                                                                            <option _ngcontent-mkn-c10="" value="No">No</option>
                                                                                                                        </select>
                                                                                                                    </div>
                                                                                                                </td>
                                                                                                            </tr>
                                                                                                            <tr _ngcontent-mkn-c10="">
                                                                                                                <td _ngcontent-mkn-c10="" className="filterHeader border-none" style={{ border: 'none !important' }}><span _ngcontent-mkn-c10="">Applicable % of Tax Rate :</span></td>
                                                                                                                <td _ngcontent-mkn-c10="" className="border-none" style={{ border: 'none !important' }}>
                                                                                                                    <div _ngcontent-mkn-c10="" className="form-group">
                                                                                                                        <select _ngcontent-mkn-c10="" className="form-control pcal" name="rtax" value={apFillter.rtax} onChange={handleOnChangeForm}>
                                                                                                                            <option _ngcontent-mkn-c10="" value="">Select</option>
                                                                                                                            <option _ngcontent-mkn-c10="" value="65">65</option>
                                                                                                                            <option _ngcontent-mkn-c10="" value="100">100</option>
                                                                                                                        </select>
                                                                                                                    </div>
                                                                                                                </td>
                                                                                                            </tr>
                                                                                                        </tbody>
                                                                                                    </table>
                                                                                                </div>
                                                                                                <div _ngcontent-mkn-c10="" className="row" style={{ marginTop: '-25px' }}>
                                                                                                    <div _ngcontent-mkn-c10="" className="col-sm-12 col-xs-12 text-right next-tab-nav">
                                                                                                        <a _ngcontent-mkn-c10="">
                                                                                                            <button _ngcontent-mkn-c10="" className="btn btn-default" data-dismiss="modal" type="button" onClick={() => {
                                                                                                                setApFilter(false);
                                                                                                            }} style={{ padding: '10px 22px', backgroundColor: 'white', borderRadius: '4px' }}> Back </button>
                                                                                                        </a>&nbsp;
                                                                                                        <a _ngcontent-mkn-c10="">
                                                                                                            <button _ngcontent-mkn-c10="" className="btn btn-primary" type="button" onClick={() => {
                                                                                                                setAPFillter({ 'invtype': '', 'revc': '', 'ITC': '', 'rtax': '', 'amin': '', 'amax': '', 'bmin': '', 'bmax': '' });
                                                                                                                setIsFilter(false);
                                                                                                            }} style={{ padding: '10px 22px', backgroundColor: '#337ab7!important', borderRadius: '4px' }}>Reset</button>
                                                                                                        </a>&nbsp;
                                                                                                        <a _ngcontent-mkn-c10="">
                                                                                                            <button _ngcontent-mkn-c10="" className="btn btn-primary" data-dismiss="modal" type="button" onClick={() => {
                                                                                                                let isVal = false;
                                                                                                                for (let key in apFillter) {
                                                                                                                    if (apFillter.hasOwnProperty(key) && apFillter[key] !== '') {
                                                                                                                        isVal = true;
                                                                                                                        break;
                                                                                                                    }
                                                                                                                }
                                                                                                                setIsFilter(isVal);
                                                                                                                setApFilter(false);
                                                                                                            }} style={{ padding: '10px 22px', backgroundColor: '#337ab7!important', borderRadius: '4px' }}>Apply</button>
                                                                                                        </a>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </Dialog>
                                                                </div>
                                                            </div>
                                                            <div _ngcontent-gwq-c10="" className="row">
                                                                <div _ngcontent-gwq-c10="" className="col-sm-12 col-xs-12 text-right " style={{ marginBottom: '-20px' }}>
                                                                    <a _ngcontent-gwq-c10="" onClick={() => {
                                                                        setIsTabB(true);
                                                                        removeAllFilter();
                                                                        setSelGST({ 'gst': '', 'name': '' });
                                                                        setSubRow(-1);
                                                                    }}>
                                                                        <button _ngcontent-gwq-c10="" className="btn btn-default" type="button">Back to Summary</button>
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <block-ui-content>
                                                            <div className="block-ui-wrapper block-ui-main">
                                                                <div className="block-ui-spinner ng-star-inserted">
                                                                    <div className="loader">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </block-ui-content>
                                                    </block-ui>
                                                </app-b2b >
                                            </div>
                                        </div>
                                    </app-all-tables>
                                }
                            </div>
                        </div>
                    </div>
                </app-gstr2b>
            </div >
            <div ref={pdfRef}>
                {isPrint && <PanelGSTR2BPDF A={b2b} B={b2ba} C={b2bcdr} D={impg} />}
            </div>
        </>
    );
}