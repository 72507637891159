import React, { useEffect, useState } from 'react';

import { useRecoilState } from 'recoil';
import { pathAtom, loadingAtom, returnDateAtom, gstrUserAtom, digErrorAtom, userIDAtom, userAtom } from '../../core/config/atoms';

import './css/main.css';
import './css/style.css';

import Moment from 'moment';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Dialog } from '@headlessui/react';

import imgLoader from '../../assets/images/cloading.gif';
import icDone from '../../assets/images/icons/done.png';
import ServiceLMS from '../../services/serviceLMS';
import ServiceUtility from '../../services/serviceUtility';
import ServiceGSTR from '../../services/serviceGSTR';
import ServiceLog from '../../services/serviceLog';

export default function PanelFileValid() {
    const [path, setPath] = useRecoilState(pathAtom);
    const [loading, setLoading] = useRecoilState(loadingAtom);
    const [gDate] = useRecoilState(returnDateAtom);
    const [GSTUser] = useRecoilState(gstrUserAtom);
    const [digError, setDigError] = useRecoilState(digErrorAtom);
    const [userID] = useRecoilState(userIDAtom);
    const [userAuth] = useRecoilState(userAtom);

    const [gstUser, setGSTUser] = useState({});
    const [gstrFile, setGSTRFile] = useState({});

    const [gstUsers, setGSTUsers] = useState([]);

    const [otpText, setOTPText] = useState('');

    const [otp, setOTP] = useState(0);

    const [selectedUser, setSelectedUser] = useState(-1);

    const [isSelected, setIsSelected] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [otpError, setOTPError] = useState(false);
    const [isAlert, setIsAlrt] = useState(false);
    const [submit, setSubmit] = useState(false);

    const lmsService = new ServiceLMS();
    const utilService = new ServiceUtility();
    const returnService = new ServiceGSTR();
    const logService = new ServiceLog();

    const formSchema = Yup.object().shape({
        authveri: Yup.boolean().required('This information is required'),
    });

    const { values, errors, touched, dirty, handleChange, handleSubmit, handleReset, resetForm, setValues } = useFormik({
        initialValues: {
            authveri: false,
            authsign: '',
        },
        validationSchema: formSchema,
        onSubmit: values => {
            let ot = utilService.getCode();
            setOTP(ot);
            setSubmit(true);
            setIsOpen(true);
        }
    });

    const gotoPath = (path) => {
        setLoading(true);
        setPath(path);
    }

    const handleOnChangeForm = (e) => {
        var { value, name } = e.target;
        setOTPText(value);
    }

    const showAlert = () => {
        setIsAlrt(true);
    }

    const genLog = () => {
        let body = {
            id: userAuth.id,
            name: userAuth.name,
            msg: "User filled GSTR-1 file for the month of " + Moment(gDate).format('MMMM - YYYY').toString(),
            role: userAuth.role,
        }
        logService.setLog(body).then((res) => {
            showAlert();
            loadData();
        });
    }

    const validate = () => {
        const otpRegex = RegExp(
            /^[0-9]{6}$/
        );
        if (otpRegex.test(otpText)) {
            if (otp == otpText) {
                setLoading(true);
                let body = { 'lid': userID, 'month': gDate, uid: values.authsign };
                returnService.setGsrtFiled(body).then((res) => {
                    setLoading(false);
                    setIsOpen(false);
                    if (res.statuscode === 200) {
                        genLog();
                    } else {
                        setDigError({ title: 'Error', msg: 'Server error please try again!', isToggle: true });
                    }
                });
            } else {
                setOTPError(true);
            }
        } else {
            setOTPError(true);
        }
    }

    const getEnable = () => {
        if (values.authveri && !submit) {
            if (isSelected) {
                return false;
            } else {
                return true;
            }
        } else {
            return true;
        }
    }

    const getStatus = () => {
        switch (gstrFile.status) {
            case "new":
                return "Not Filed";
            case "draft":
                return "Ready to File";
            case "done":
                return "Filed";
        }
    }

    const getStartY = () => {
        if (new Date(Moment(gDate).format('MM-DD-yyyy')).getMonth() < 3) {
            return Moment(gDate).subtract(1, 'year').format('YYYY');
        } else {
            return Moment(gDate).format('YYYY');
        }
    }

    const getEndY = () => {
        if (new Date(Moment(gDate).format('MM-DD-yyyy')).getMonth() < 3) {
            return Moment(gDate).format('YY');
        } else {
            return Moment(gDate).add(1, 'year').format('YY');
        }
    }

    const loadData = () => {
        setLoading(true);
        (async function () {
            let body = {
                id: userID
            };
            var res = await lmsService.getLMSUserByID(body);
            setGSTUser(res.data['B']);
            setGSTUsers(res.data['B']['users']);

            var resFiles = await returnService.getGstrFiles({ 'lid': userID, 'month': gDate });
            setGSTRFile(resFiles.data);

            if (resFiles.data.status === "done") {
                setValues({ ...values, authveri: true, authsign: resFiles.data.uid });
            }

            setLoading(false);
            window.scrollTo({
                top: 0,
                behavior: 'smooth',
            });
        })();
    }

    useEffect(() => {
        loadData();
    }, []);

    return (
        <div class="content-wrapper fade-ng-cloak">
            {
                <Dialog open={isOpen} onClose={() => setIsOpen(false)}>
                    <div id="confirmDlg" class="modal fade fade-scale in" role="dialog" style={{ display: 'block', paddingRight: '17px' }}>
                        <div class="modal-dialog">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <p className='text-left text-2xl text-blue-900 font-semibold'>Validate One Time Password(OTP)</p>
                                </div>
                                <div class="modal-body">
                                    <alert-message ng-show="showMsg" title="" type="msg alert-success" message="" class="ng-hide">
                                        <div class="alert alert-msg alert-success">One Time Password(OTP) has been sent : Your OTP is {otp}.</div>
                                    </alert-message>
                                    <div class="row">
                                        <div class={`col-xs-12 ${otpError ? 'has-error' : 'has-success'}`}>
                                            <label for="veriOTP" class="reg m-cir">Enter One Time Password(OTP)</label>
                                            <input id="veriOTP" name="veriOTP" class="form-control" type="text" placeholder="Enter OTP" maxLength={6} required="" onChange={(e) => {
                                                e.target.value = utilService.formatOnlyNumber(e.target.value);
                                                handleOnChangeForm(e);
                                            }} />
                                            {otpError && <p class="err" data-ng-bind="errVar">Please enter a valid OTP.</p>}
                                        </div>
                                    </div>
                                    <p></p>
                                </div>
                                <div class="modal-footer">
                                    <a class="btn btn-default" onClick={() => { setIsOpen(false) }}>CANCEL</a>
                                    <a class="btn btn-primary" disabled={true}>RESEND OTP</a>
                                    <a class="btn btn-primary" onClick={() => { validate() }}>VALIDATE OTP</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </Dialog>
            }
            <div class="dimmer-holder" style={{ display: 'none' }}>
                <div id="dimmer"></div>
            </div>
            {
                loading && <div className='absolute z-50 w-full h-screen flex items-center justify-center bg-gray-200 bg-opacity-25'>
                    <img src={imgLoader} alt="loader" />
                </div>
            }
            <div class="container">
                <div class="mypage">
                    <div class="row">
                        <div class="col-xs-10">
                            <div>
                                <ol class="breadcrumb">
                                    <li>
                                        <a onClick={() => { gotoPath('gstr') }}>Dashboard</a>
                                    </li>
                                    <li>
                                        <a onClick={() => { gotoPath('gstRetrun') }}>Returns</a>
                                    </li>
                                    <li>
                                        <a onClick={() => { gotoPath('gstrForm') }}>GSTR-1/IFF</a>
                                    </li>
                                    <li>Validation</li>
                                </ol>
                            </div>
                        </div>
                        <div class="col-xs-2">
                            <div class="lang dropdown">
                                <span class="dropdown-toggle" data-toggle="dropdown" data-ng-bind="selectedLang">English</span>
                                <ul class="dropdown-menu lang-dpdwn">
                                    <li data-ng-repeat="language in languages">
                                        <a data-ng-click="selectLang(language.cd)" data-ng-bind="language.nm">English</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    {
                        !loading && <div data-ng-controller="mainctrl" ng-view="">
                            {isAlert && <alert-message ng-show="showMsg" title="" type="msg alert-success" message="" class="ng-hide">
                                <div class="alert alert-msg alert-success flex items-center">
                                    <img src={icDone} alt='Done Image' className='w-6 h-6' />&nbsp;<p>GSTR of GSTN - <b className='text-green-800'>{gstUser.gstn}</b> for the Return Period - <b className='text-green-800'>{Moment(gDate).format('MMMM')} - {getStartY()} - {getEndY()}</b> has been successfully filed. The Acknowledgement Reference Number is <b className='text-green-800'>{utilService.getGSTRACK()}</b>. The GSTR1 can be viwed on your Dashboard Login=&gt;Taxpayer Dashboard=&gt;Returns.</p>
                                </div>
                            </alert-message>
                            }
                            <div>
                                <div id="mai">
                                    <div class="row">
                                        <div class="col-sm-12 col-xs-12">
                                            <div class="panel panel-default ret">
                                                <div class="panel-body" style={{ padding: '5px 15px' }}>
                                                    <div class="row">
                                                        <div class="col-sm-4">
                                                            <span class="reg">GSTIN - {GSTUser.gstn}</span>
                                                        </div>
                                                        <div class="col-sm-4">
                                                            <span class="reg">Legal Name - {GSTUser.buzName}</span>
                                                        </div>
                                                        <div class="col-sm-4">
                                                            <span class="reg">Trade Name - {GSTUser.tname}</span>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-sm-4">
                                                            <span class="reg">FY -&nbsp;{getStartY()}-{getEndY()}</span>
                                                        </div>
                                                        <div class="col-sm-4">
                                                            <span class="reg">Tax Period -&nbsp;
                                                                <span>{Moment(gDate).format("MMMM")}</span>
                                                            </span>
                                                        </div>
                                                        <div class="col-sm-4">
                                                            <span class="reg">Status - {getStatus()}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="tabpane" style={{ minHeight: '263px !important' }}>
                                        <div class="row invsumm">
                                            <div class="col-xs-12 col-sm-8 text-white">
                                                <h4 data-ng-bind="trans.LBL_B2B_INVOICES_GSTR1" className='!text-white'>Returns Filing For GST GSTR1</h4>
                                            </div>
                                            <div class="col-sm-4 taxp">
                                                <button class="btn btn-primary btn-circle btn-sm pull-right" data-toggle="tooltip" title="Refresh" disabled={true} style={{ marginLeft: '5px' }}>
                                                    <i class="fa fa-refresh" aria-hidden="true"></i>
                                                </button>
                                            </div>
                                        </div>
                                        <form className='mt-2' onSubmit={handleSubmit} onReset={handleReset} autocomplete="off" novalidate="">
                                            <fieldset>
                                                <div class="row">
                                                    <div class="inner">
                                                        <div class="col-xs-12 radio-order">
                                                            <input type="checkbox" class="chkbx" id="authveri" required="" name="authveri" onChange={handleChange} value={values.authveri} checked={values.authveri} disabled={gstrFile.status === "done"} />
                                                            <label for="authveri"><span>I hereby solemnly affirm and declare that the information given herein above is true and correct to the best of my knowledge and belief and nothing has been concealed therefrom.</span></label>
                                                        </div>
                                                    </div>
                                                </div>
                                                {
                                                    values.authveri && <div class="row mt-2">
                                                        <div class="inner">
                                                            <div class="col-xs-12 col-sm-6">
                                                                <label for="authsign" class="reg m-cir">Authorized Signatory</label>
                                                                <select class="form-control" id="authsign" name="authsign" required="" autofocus="" onChange={(e) => {
                                                                    let value = e.target.value;
                                                                    if (value >= 0) {
                                                                        setIsSelected(true);
                                                                        setSelectedUser(parseInt(value));
                                                                    } else {
                                                                        setIsSelected(false);
                                                                        setSelectedUser(-1);
                                                                    }
                                                                    handleChange(e);
                                                                }} value={values.authsign} disabled={gstrFile.status === "done"}>
                                                                    <option value="">Select</option>
                                                                    {
                                                                        gstUsers.map((item, index) => {
                                                                            return <option value={index} className='uppercase'>{item.pd_fnm} {item.pd_mname} {item.pd_lname}[{item.pan.toUpperCase()}]</option>
                                                                        })
                                                                    }
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                            </fieldset>
                                            <div class="row next-tab-nav">
                                                <div class="inner">
                                                    <div class="col-xs-12 text-right">
                                                        <a class="btn btn-default" style={{ marginRight: '8px' }} onClick={() => {
                                                            gotoPath('gstrSummary');
                                                        }}>Back</a>
                                                        <button class="btn btn-primary hidden-sm hidden-xs" type="button" style={{ marginRight: '8px' }} disabled="disabled">Submit with DSC</button>
                                                        <button class="btn btn-primary hidden-sm hidden-xs" type="submit" disabled={getEnable()}>Submit with EVC</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                        <div class="row p-8">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>
    );
}
