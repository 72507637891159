import React, { useState, useEffect, useRef } from 'react';

import { useRecoilState } from 'recoil';
import { pathAtom, loadingAtom, trnLevelAtom, trnAtom, digErrorAtom, dirtyAtom, docAtom, digStorageAtom, userIDAtom, userAtom } from '../core/config/atoms';

import { useFormik } from 'formik';
import * as Yup from 'yup';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import { ThemeProvider } from "@material-ui/styles";
import { createMuiTheme } from "@material-ui/core";
import DateFnsUtils from '@date-io/date-fns';
import Moment from 'moment';
import { Dialog } from '@headlessui/react';

import ServiceAuth from '../services/serviceAuth';
import ServiceUtility from '../services/serviceUtility';
import ServiceOption from '../services/serviceOptions';

import icImg from '../assets/images/misc/jpeg.png';

export default function ComponentTRNLevelB() {

    const [path, setPath] = useRecoilState(pathAtom);
    const [loading, setLoading] = useRecoilState(loadingAtom);
    const [trnLevel, SetTRNLevel] = useRecoilState(trnLevelAtom);
    const [trnno] = useRecoilState(trnAtom);
    const [digError, setDigError] = useRecoilState(digErrorAtom);
    const [digDoc, setDigDoc] = useRecoilState(digStorageAtom);
    const [doc, setDoc] = useRecoilState(docAtom);
    const [isDirty, setIsDirty] = useRecoilState(dirtyAtom);
    const [userID] = useRecoilState(userIDAtom);
    const [userAuth] = useRecoilState(userAtom);

    const [gstUsers, setGSTUsers] = useState([]);
    const [markers, setMarkers] = useState([]);

    const [map, setMap] = useState();

    const [gstUser, setGSTUser] = useState({});
    const [digConfirm, setDigConfirm] = useState({ 'type': '0', isToggle: false, data: null });

    const [errodDob, setErrodDob] = useState("");

    const [mainDate, setMainDate] = useState(new Date());

    const [selectedUser, setSelectedUser] = useState(-1);

    const [selOption, setSelOption] = useState('');

    const [isOpen, setIsOpen] = useState(false);
    const [isForm, setIsForm] = useState(true);
    const [isSubmit, setIsSubmit] = useState(false);
    const [isPincode, setIsPincode] = useState(false);
    const [isPrev, setIsPrev] = useState(false);
    const [isValid, setIsValid] = useState(false);

    const ref = useRef();

    const formSchema = Yup.object().shape({
        pd_fnm: Yup.string().required('First Name is required.'),
        fd_fname: Yup.string().required('Name of Father is required.'),
        pd_dob: Yup.string().required('Please select Date of Birth.').matches(/^[0-9]{2}-[0-9]{2}-[0-9]{4}$/, { message: 'Please enter a valid date' }),
        pd_mobile: Yup.string().required('Mobile Number is required.').matches(/^[0-9]{10}$/, { message: 'Please enter a valid mobile number' }),
        pd_email: Yup.string().required('Email Address is required.').matches(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, { message: 'Please enter a valid email address' }),
        gd: Yup.string().required('Gender is required.'),
        dig: Yup.string().required('Designation / Status is required.'),
        pan: Yup.string().required('Permanent Account Number is required.').matches(/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/, { message: 'Please enter a valid pan card number' }),
        pncd: Yup.string().required('PIN Code is required.').matches(/^[0-9]{6}$/, { message: 'Please enter a valid pincode' }),
        pd_state: Yup.string().required('State is required.'),
        dst: Yup.string().required('District is required.'),
        city: Yup.string().required('City / Town / Village is required.'),
        pd_road: Yup.string().required('Road / Street is required.'),
        pd_bdnum: Yup.string().required('Building No. / Flat No. is required.'),
    });

    const { values, errors, touched, dirty, handleChange, handleSubmit, handleReset, resetForm, setValues } = useFormik({
        enableReinitialize: true,
        initialValues: {
            pd_fnm: '',
            pd_mname: '',
            pd_lname: '',
            fd_fname: '',
            fd_mname: '',
            fd_lname: '',
            pd_dob: '',
            pd_mobile: '',
            pd_email: '',
            telstd: '',
            telno: '',
            gd: '',
            dig: '',
            pan: '',
            pd_cntry: '',
            pncd: '',
            pd_state: '',
            dst: '',
            city: '',
            pd_locality: '',
            pd_road: '',
            pd_bdname: '',
            pd_bdnum: '',
            pd_flrnum: '',
            pd_landmark: '',
            pri_auth: false,
        },
        validationSchema: formSchema,
        onSubmit: values => {
            let isMinor = true;
            if (values.pri_auth) {
                let rhs = Moment(values.pd_dob, 'DD-MM-YYYY');
                let lhs = Moment();
                let result = lhs.diff(rhs, 'years');
                if (result < 18) {
                    isMinor = false;
                } else {
                    isMinor = true;
                }
            }
            if (isMinor) {
                if (doc.isFile) {
                    setIsValid(false);
                    setIsSubmit(true);
                    setLoading(true);

                    let formData = { ...values };

                    if (values.pri_auth) {
                        formData['isAuth'] = '1';
                    } else {
                        formData['isPrime'] = '0';
                        formData['isAuth'] = '0';
                    }
                    formData['isProm'] = '1';
                    let data = [...trnLevel.form];
                    data[1] = "1";
                    if (doc.type === 'storage') {
                        formData['userPhoto'] = {
                            filename: doc.doc.filename,
                            fileurl: doc.doc.fileurl,
                            filetype: 'storage',
                        };
                        let body = {}
                        if (isPrev) {
                            let tempUsers = [...gstUsers];
                            tempUsers[selectedUser] = formData;
                            body = {
                                users: [...tempUsers],
                                trnno: trnno,
                                lid: userID,
                                statusArr: data,
                                user: userAuth,
                            }
                        } else {
                            body = {
                                users: [...gstUsers, formData],
                                trnno: trnno,
                                lid: userID,
                                statusArr: data,
                                user: userAuth,
                            }
                        }
                        authService.setGSTUserFormB(body).then((res) => {
                            if (res.statuscode === 200) {
                                setDoc({ isFile: false, type: '', doc: null });
                                SetTRNLevel({ ...trnLevel, level: 'sa3' });
                            } else {
                                setDigError({ title: 'Error', msg: 'Server error please try again!', isToggle: true });
                            }
                            setLoading(false);
                        });
                    } else {
                        let mPromis = [];
                        var formDataA = new FormData();
                        formDataA.append("doc", doc.doc);
                        formDataA.append("id", userID);
                        mPromis.push(authService.setDocument(formDataA, ''));
                        Promise.all(mPromis).then((d) => {
                            if (d[0].data.status) {
                                let ddata = d[0].data.data;

                                formData['userPhoto'] = {
                                    filename: ddata.filename,
                                    fileurl: ddata.fileurl,
                                    filetype: 'doc',
                                };
                                let body = {}
                                if (isPrev) {
                                    let tempUsers = [...gstUsers];
                                    tempUsers[selectedUser] = formData;
                                    body = {
                                        users: [...tempUsers],
                                        trnno: trnno,
                                        lid: userID,
                                        statusArr: data,
                                        user: userAuth,
                                    }
                                } else {
                                    body = {
                                        users: [...gstUsers, formData],
                                        trnno: trnno,
                                        lid: userID,
                                        statusArr: data,
                                        user: userAuth,
                                    }
                                }
                                authService.setGSTUserFormB(body).then((res) => {
                                    if (res.statuscode === 200) {
                                        setDoc({ isFile: false, type: '', doc: null });
                                        SetTRNLevel({ ...trnLevel, level: 'sa3' });
                                    } else {
                                        setDigError({ title: 'Error', msg: 'Server error please try again!', isToggle: true });
                                    }
                                    setLoading(false);
                                });
                            } else {
                                setLoading(false);
                                setDigError({ title: 'Error', msg: 'Server error please try again!', isToggle: true });
                            }
                        });
                    }
                } else {
                    setIsValid(true);
                }
            } else {
                setErrodDob("error");
            }
        }
    });

    const materialTheme = createMuiTheme({
        overrides: {
            MuiTypography: {
                subtitle1: {
                    fontSize: '16px',
                },
                caption: {
                    fontSize: '16px',
                    fontWeight: 'bold'
                },
                body1: {
                    "fontSize": '16px',
                },
                body2: {
                    "fontSize": '16px',
                }
            },
            MuiPickersToolbar: {
                toolbar: {
                    backgroundColor: '#239B56',
                },
            },
            MuiPickersDay: {
                day: {
                    color: '#17202A',
                },
                daySelected: {
                    backgroundColor: '#239B56',
                },
                dayDisabled: {
                    color: '#808B96',
                },
                current: {
                    color: '#239B56',
                },
            },
            MuiButton: {
                textPrimary: {
                    color: '#2E86C1',
                    fontSize: '12px',
                    border: '1px solid #2E86C1',
                    backgroundColor: 'transprent'
                }
            },
        },
    });

    const authService = new ServiceAuth();
    const utilService = new ServiceUtility();
    const optionService = new ServiceOption();

    const gotoPath = (path) => {
        SetTRNLevel({ ...trnLevel, level: path });
    }

    const openPicker = (id) => {
        setSelOption(id);
        setIsOpen(true);
    }

    const handleDateChange = (e) => {
        switch (selOption) {
            case 'pd_dob':
                setValues({ ...values, pd_dob: Moment(e).format('DD-MM-YYYY').toString() });
                break;
            default:
                break;
        }
        setSelOption('');
    }

    const openBox = (e) => {
        e.preventDefault();
        setDoc({ isFile: false, type: '', doc: null });
        setDigDoc({ title: '', msg: '', isToggle: true });
    }

    const cancel = () => {
        setDigConfirm({ 'type': '0', isToggle: false, data: null });
    }

    const process = () => {
        switch (digConfirm.type) {
            case '1':
                removeDoc();
                break;
            case '2':
                deleteUser();
            default:
                break;
        }
        setDigConfirm({ 'type': '0', isToggle: false, data: null });
    }

    const showDig = (type, data) => {
        setDigConfirm({ 'type': type, isToggle: true, data: data });
    }

    const resetAddress = () => {
        setIsPincode(false);
        let tempMarks = [...markers];
        for (let i = 0; i < tempMarks.length; i++) {
            tempMarks[i].setMap(null);
        }
        setMarkers(tempMarks);
        setValues({ ...values, 'pd_landmark': '', 'pd_flrnum': '', 'pd_bdnum': '', 'pd_bdname': '', 'pd_road': '', 'pd_locality': '', 'city': '', 'dst': '', 'pd_state': '', 'pncd': '' });
    }

    const removeDoc = () => {
        setDoc({ isFile: false, type: '', doc: null });
    }

    const deleteUser = () => {
        setLoading(true);
        let us = [...gstUsers];
        us.splice(selectedUser, 1);
        let data = [...trnLevel.form];
        data[1] = "0";
        let body = {
            users: [...us],
            trnno: trnno,
            lid: userID,
            statusArr: data,
            user: userAuth,
        }
        authService.setGSTUserFormB(body).then((res) => {
            if (res.statuscode === 200) {
                loadData();
            } else {
                setDigError({ title: 'Error', msg: 'Server error please try again!', isToggle: true });
            }
            setLoading(false);
        });
    }

    const handelePincode = (e) => {
        let value = e.target.value;
        handleChange(e);
        if (value.length == 6) {
            optionService.getLocation({ 'Pincode': value }).then((res) => {
                setIsPincode(true);
                const geocoder = new window.google.maps.Geocoder();
                var address = res.data['RegionName'].replace(' Region', '').toUpperCase() + " ," + res.data['StateName'] + " " + value;
                geocoder.geocode({ 'address': address }, function (results, status) {
                    if (status == 'OK') {
                        map.setCenter(results[0].geometry.location);
                        let tempMarks = [...markers];
                        for (let i = 0; i < tempMarks.length; i++) {
                            tempMarks[i].setMap(null);
                        }
                        tempMarks.push(
                            new window.google.maps.Marker({
                                map: map,
                                position: results[0].geometry.location
                            })
                        );
                        setMarkers(tempMarks);
                    } else {
                        alert('Geocode was not successful for the following reason: ' + status);
                    }
                });
                setValues({ ...values, 'pd_cntry': 'IND', 'pd_state': res.data['StateName'], 'dst': res.data['District'], 'city': res.data['RegionName'].replace(' Region', '').toUpperCase(), 'pncd': value });
            });
        } else {
            setIsPincode(false);
        }
    }

    const loadData = () => {
        (async function () {
            var res = await authService.getGSTUserByTRN({ 'trn': trnno, 'stdOwn': userID });
            setGSTUser(res.data[0]);
            SetTRNLevel({ ...trnLevel, form: res.data[0]['statusArr'], percentage: utilService.calculatePercentage(res.data[0]['statusArr']) });
            setGSTUsers(res.data[0]['users']);
            if (res.data[0]['statusArr'][1] === '1') {
                setIsPrev(true);
                let promIndex = res.data[0]['users'].findIndex(item => {
                    return item.isProm === '1';
                });
                let tempUsers = res.data[0]['users'];
                setDoc({ isFile: true, type: tempUsers[promIndex].userPhoto.filetype, doc: tempUsers[promIndex].userPhoto });
                setValues({ ...values, ...tempUsers[promIndex], pri_auth: tempUsers[promIndex].isAuth === '1' });
                setSelectedUser(promIndex);
                setIsPincode(true);
            } else {
                setValues({ ...values, pan: res.data[0].panNumber });
                setIsPrev(false);
                setDoc({ isFile: false, type: '', doc: null });
            }
            window.scrollTo({
                top: 0,
                behavior: 'smooth',
            });
        })();
    }

    useEffect(() => {
        const dmap = new window.google.maps.Map(ref.current, {
            center: { lat: 19.200, lng: 72.800 },
            zoom: 12
        });
        setMap(dmap);
        loadData();
    }, []);

    useEffect(() => {
        setIsDirty(dirty);
    }, [dirty]);

    return (
        <form class="tabpane" name="newRegForm" id="newRegForm" onSubmit={handleSubmit} onReset={handleReset} autocomplete="off" noValidate>
            <Dialog open={digConfirm.isToggle} onClose={() => setDigConfirm({ 'type': '0', isToggle: false, data: null })}>
                <div id="confirmDlg" class="modal fade fade-scale in" role="dialog" style={{ display: 'block', paddingRight: '17px' }}>
                    <div class="modal-dialog sweet">
                        <div class="modal-content">
                            <div class="modal-body">
                                <div class="m-icon m-warning pulseWarning">
                                    <span class="micon-body pulseWarningIns"></span>
                                    <span class="micon-dot pulseWarningIns"></span>
                                </div>
                                <h2>Warning</h2>
                                <p>Are you sure, you want to continue?</p>
                            </div>
                            <div class="modal-footer">
                                <a class="btn btn-default" onClick={() => { cancel() }}>Cancel</a>
                                <a class="btn btn-primary" onClick={() => { process() }}>Proceed</a>
                            </div>
                        </div>
                    </div>
                </div>
            </Dialog>
            <p data-ng-show="showForm" class="mand-text">indicates mandatory fields</p>
            <h4>
                <span>Details of Proprietor</span>
            </h4>
            <hr />
            {
                !isForm && <div data-ng-show="!showForm" class="ng-hide">
                    {
                        gstUsers.length != 0 && <div class="row">
                            <div class="col-xs-12" data-ng-if="rpayload.opdtls.length > 0">
                                <p class="text-right">
                                    <span>
                                        <i class="fa fa-user-circle"></i>&nbsp;
                                        <span data-ng-bind="trans.LBL_AUTHPRO_SIGN">Promoter, also Authorized Signatory</span>
                                    </span>
                                </p>
                                <p class="text-right">
                                    <span>
                                        <i class="fa fa-user-o"></i>&nbsp;
                                        <span data-ng-bind="trans.LBL_PART">Partner/Promoter</span>
                                    </span>
                                </p>
                            </div>
                            <div class="col-xs-12">
                                <div name="staus_msg"></div>
                                <div class="table-responsive">
                                    <table class="table table-stripped">
                                        <thead>
                                            <tr>
                                                <th data-ng-bind="trans.LBL_SL_NO">Sl No</th>
                                                <th data-ng-bind="trans.LBL_NAME">Name</th>
                                                <th data-ng-bind="trans.LBL_DESGN">Designation / Status</th>
                                                <th data-ng-bind="trans.LBL_ACTIONS">Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                gstUsers.map((item, index) => {
                                                    if (item.isProm !== '1') {
                                                        return <></>
                                                    }
                                                    return <tr>
                                                        <td>{index + 1}</td>
                                                        <td>
                                                            <span class="pdg-box">
                                                                <span>
                                                                    {item.isAuth === '1' && <i class="fa fa-user-circle"></i>}
                                                                    {item.isAuth === '0' && <i class="fa fa-user-o"></i>}
                                                                </span>
                                                            </span>
                                                            {item.pd_fnm} {item.pd_mname} {item.pd_lname}
                                                        </td>
                                                        <td>{item.dig}</td>
                                                        <td>
                                                            <div>
                                                                <button title="Edit" type="button" class="btn btn-sm btn-primary" style={{ marginRight: '8px' }} onClick={() => {
                                                                    setIsForm(true)
                                                                }}>
                                                                    <i class="fa fa-pencil"></i>&nbsp;
                                                                    <span>Edit</span>
                                                                </button>
                                                                <button title="Delete" type="button" class="btn btn-sm btn-danger" onClick={() => { showDig('2', index) }}>
                                                                    <i class="fa fa-trash"></i>&nbsp;
                                                                    <span>Delete</span>
                                                                </button>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    }
                    {
                        gstUsers.length == 0 && <div class="alert alert-warning">
                            <p>You do not have any submitted applications</p>
                        </div>
                    }
                    <div class="row next-tab-nav">
                        <div class="col-xs-12 text-right">
                            <a class="btn btn-default" onClick={() => { setIsForm(true) }} style={{ marginRight: '8px' }}>Back</a>
                            <button title="Add New" type="button" class="btn btn-primary" disabled={gstUsers.length >= 1} onClick={() => { setIsForm(true) }} style={{ marginRight: '8px' }}>Add New</button>
                            <button class="btn btn-primary" disabled={gstUsers.length >= 1} onClick={() => {
                                gotoPath('sa3');
                            }}>Continue</button>
                        </div>
                    </div>
                </div>
            }
            {
                isForm && <div>
                    <div class="row next-tab-nav">
                        <fieldset className='p-6'>
                            <h4>
                                <i class="fa fa-user"></i>&nbsp;
                                <span>Personal Information</span>
                            </h4>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <ThemeProvider theme={materialTheme}>
                                    <DatePicker
                                        variant="dialog"
                                        open={isOpen}
                                        onOpen={() => setIsOpen(true)}
                                        onClose={() => setIsOpen(false)}
                                        label="Open me from button"
                                        format="dd/MM/yyyy"
                                        value={mainDate}
                                        maxDate={new Date()}
                                        onChange={handleDateChange}
                                        style={{ display: 'none' }}
                                    />
                                </ThemeProvider>
                            </MuiPickersUtilsProvider>
                            <div class="tbl-format">

                                <div class="row">
                                    <div class="inner">
                                        <h5 class="col-xs-12">Name of Person</h5>
                                        <div class={`col-sm-4 col-xs-12 ${touched.pd_fnm ? errors.pd_fnm ? 'has-error' : 'has-success' : ''}`}>
                                            <label for="pd_fnm" class="reg m-cir">First Name</label>
                                            <input value={values.pd_fnm} id="pd_fnm" name="pd_fnm" class="form-control" type="text" required="" placeholder="Enter First Name" maxLength={99} autofocus="" onChange={handleChange} />
                                            {(errors.pd_fnm && touched.pd_fnm) && <p class="err">{errors.pd_fnm}</p>}
                                        </div>
                                        <div class="col-sm-4 col-xs-12">
                                            <label for="pd_mname" class="reg">Middle Name</label>
                                            <input value={values.pd_mname} id="pd_mname" name="pd_mname" class="form-control" type="text" placeholder="Enter Middle Name" maxLength={99} onChange={handleChange} />
                                        </div>
                                        <div class="col-sm-4 col-xs-12">
                                            <label for="pd_lname" class="reg">Last Name</label>
                                            <input value={values.pd_lname} class="form-control" name="pd_lname" id="pd_lname" type="text" placeholder="Enter Last Name" maxLength={99} onChange={handleChange} />
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="inner">
                                        <h5 class="col-sm-12 col-xs-12">Name of Father</h5>
                                        <div class={`col-sm-4 col-xs-12 ${touched.fd_fname ? errors.fd_fname ? 'has-error' : 'has-success' : ''}`}>
                                            <label for="fd_fname" class="reg m-cir">First Name</label>
                                            <input value={values.fd_fname} id="fd_fname" name="fd_fname" class="form-control" type="text" required="" placeholder="Enter First Name" maxLength={99} onChange={handleChange} />
                                            {(errors.fd_fname && touched.fd_fname) && <p class="err">{errors.fd_fname}</p>}
                                        </div>
                                        <div class="col-sm-4 col-xs-12">
                                            <label for="fd_mname" class="reg">Middle Name</label>
                                            <input value={values.fd_mname} id="fd_mname" name="fd_mname" class="form-control" type="text" placeholder="Enter Middle Name" maxLength={99} onChange={handleChange} />
                                        </div>
                                        <div class="col-sm-4 col-xs-12">
                                            <label for="fd_lname" class="reg">Last Name</label>
                                            <input value={values.fd_lname} class="form-control" name="fd_lname" maxLength={99} id="fd_lname" type="text" placeholder="Enter Last Name" onChange={handleChange} />
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="inner">
                                        <div class={`col-sm-4 col-xs-12 ${touched.pd_dob ? errors.pd_dob ? 'has-error' : 'has-success' : ''}`}>
                                            <label for="pd_dob" class="reg m-cir">Date of Birth</label>
                                            <div class="datepicker-icon input-group">
                                                <input value={values.pd_dob !== undefined ? values.pd_dob.substring(0, 10) : ''} class="form-control date-picker" type="text" name="pd_dob" id="pd_dob" placeholder="DD/MM/YYYY" required="" onChange={handleChange} />
                                                <span class="input-group-addon cursor-pointer" onClick={() => {
                                                    setErrodDob("");
                                                    openPicker('pd_dob');
                                                }}><span class="fa fa-calendar"></span></span>
                                            </div>
                                            {(errors.pd_dob && touched.pd_dob) && <p class="err">{errors.pd_dob}</p>}
                                            {errodDob != "" && <p class="err">Date of Birth must be above minor.</p>}
                                        </div>
                                        <div class={`col-sm-4 col-xs-12 ${touched.pd_mobile ? errors.pd_mobile ? 'has-error' : 'has-success' : ''}`}>
                                            <label for="pd_mobile" class="reg m-cir">
                                                <i class="type-ico fa fa-mobile"></i>&nbsp;
                                                <span>Mobile Number</span>
                                            </label>
                                            <div class="input-group">
                                                <span class="input-group-addon" id="pd_mob">+91</span>
                                                <input value={values.pd_mobile} id="pd_mobile" name="pd_mobile" type="text" class="number form-control" placeholder="Enter Mobile Number" maxlength="10" required="" onChange={(e) => {
                                                    e.target.value = utilService.formatOnlyNumber(e.target.value);
                                                    handleChange(e);
                                                }} />
                                            </div>
                                            {(errors.pd_mobile && touched.pd_mobile) && <p class="err">{errors.pd_mobile}</p>}
                                        </div>
                                        <div class={`col-sm-4 col-xs-12 ${touched.pd_email ? errors.pd_email ? 'has-error' : 'has-success' : ''}`}>
                                            <label for="pd_email" class="reg m-cir">
                                                <i class="fa fa-envelope-open-o"></i>&nbsp;
                                                <span>Email Address</span>
                                            </label>
                                            <input value={values.pd_email} name="pd_email" id="pd_email" class="form-control" type="email" placeholder="Enter Email Address" maxlength="254" required="" onChange={handleChange} />
                                            {(errors.pd_email && touched.pd_email) && <p class="err">{errors.pd_email}</p>}
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="inner">
                                        <div class={`col-sm-4 col-xs-12 radio-order ${touched.gd ? errors.gd ? 'has-error' : 'has-success' : ''}`}>
                                            <fieldset>
                                                <legend class="reg m-cir">Gender</legend>
                                                <input type="radio" name="gd" id="gd" value="M" required="" onChange={handleChange} checked={values.gd === 'M'} />
                                                <label for="radiomale">
                                                    <i class="type-ico fa fa-male"></i>&nbsp;
                                                    <span data-ng-bind="trans.LBL_MALE">Male</span>
                                                </label>
                                                <input type="radio" name="gd" id="gd" value="F" required="" onChange={handleChange} checked={values.gd === 'F'} />
                                                <label for="radiofemale">
                                                    <i class="type-ico fa fa-female"></i>&nbsp;
                                                    <span data-ng-bind="trans.LBL_FEMALE">Female</span>
                                                </label>
                                                <input type="radio" name="gd" id="gd" value="O" required="" onChange={handleChange} checked={values.gd === 'O'} />
                                                <label for="radiotrans">
                                                    <i class="type-ico fa fa-street-view "></i>&nbsp;
                                                    <span data-ng-bind="trans.LBL_TRNSGNDR">Others</span>
                                                </label>
                                            </fieldset>
                                            {(errors.gd && touched.gd) && <p class="err">{errors.gd}</p>}
                                        </div>
                                        <div class="col-sm-4 col-xs-12">
                                            <label for="telstd" class="reg">
                                                <i class="type-ico fa fa-phone"></i>
                                                <span>Telephone Number (with STD Code)</span>
                                            </label>
                                            <div class="input-group">
                                                <div class="col-sm-3 col-xs-3 addrow" style={{ padding: 0 }}>
                                                    <input value={values.telstd} type="text" id="telstd" name="telstd" class="number form-control" placeholder="STD" maxLength={3} onChange={(e) => {
                                                        e.target.value = utilService.formatOnlyNumber(e.target.value);
                                                        handleChange(e);
                                                    }} />
                                                </div>
                                                <div class="col-sm-9 col-xs-9 addrow" style={{ padding: 0 }}>
                                                    <input value={values.telno} type="text" id="telno" name="telno" class="number form-control" placeholder="Enter Telephone Number" onChange={(e) => {
                                                        e.target.value = utilService.formatOnlyNumber(e.target.value);
                                                        handleChange(e);
                                                    }} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <h4>
                                <i class="fa fa-id-card"></i>&nbsp;
                                <span>Identity Information</span>
                            </h4>
                            <div class="tbl-format">
                                <div class="row">
                                    <div class="inner">
                                        <div class={`col-sm-4 col-xs-12 ${touched.dig ? errors.dig ? 'has-error' : 'has-success' : ''}`}>
                                            <label for="dig" class="reg m-cir">Designation / Status</label>
                                            <input value={values.dig} id="dig" name="dig" class="form-control" placeholder="Enter Designation" maxLength={25} required="" onChange={handleChange} />
                                            {(errors.dig && touched.dig) && <p class="err">{errors.dig}</p>}
                                        </div>
                                        <div class="col-sm-4 col-xs-12">
                                            <label for="din" class="reg">Director Identification Number</label>
                                            <span>
                                                <i class="fa fa-info-circle"> </i>
                                            </span>
                                            <input id="din" name="din" class="number form-control" type="text" placeholder="Enter DIN Number" maxLength={8} disabled={true} />
                                        </div>
                                        <div class="col-sm-4 col-xs-12">
                                            <label for="pd_cit_ind" class="reg">Are you a citizen of India?</label>
                                            <div class="switch round">
                                                <input id="pd_cit_ind" name="pd_cit_ind" type="checkbox" checked="checked" disabled={true} />
                                                <label for="pd_cit_ind">
                                                    <span class="switch-on" style={{ left: '4px' }}><span>Yes</span></span>
                                                    <span class="switch-off" style={{ left: '35px' }}><span>No</span></span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="inner">
                                        <div class={`col-sm-4 col-xs-12 ${touched.pan ? errors.pan ? 'has-error' : 'has-success' : ''}`}>
                                            <label for="pan" class="reg  m-cir">Permanent Account Number (PAN)</label>
                                            <input value={values.pan} id="pan" name="pan" class="form-control upper-input" type="text" placeholder="Enter Permanent Account Number (PAN)" maxLength={10} required="required" onChange={(e) => {
                                                e.target.value = e.target.value.toUpperCase();
                                                handleChange(e);
                                            }} />
                                            {(errors.pan && touched.pan) && <p class="err">{errors.pan}</p>}
                                        </div>
                                        <div class="col-sm-4 col-xs-12">
                                            <label for="ppno" class="reg">Passport Number (In case of Foreigner)</label>
                                            <input class="form-control upper-input" id="ppno" name="ppno" placeholder="Enter Passport Number" maxLength={15} disabled={true} />
                                        </div>
                                        <div class="col-sm-4 col-xs-12">
                                            <label class="reg" for="uid">Aadhaar Number</label>
                                            <span>
                                                <i class="fa fa-info-circle"></i>
                                            </span>
                                            <input name="uid" id="uid" class="form-control number" placeholder="Enter Aadhaar Number" disabled="disabled" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <h4>
                                <i class="fa fa-envelope"></i>&nbsp;
                                <span>Residential Address</span>
                            </h4>
                            <div>
                                <div ref={ref} id="map" style={{ minHeight: '400px' }}></div>
                            </div>
                            <div class="tbl-format">
                                <div class="row">
                                    <div class="inner">
                                        <div class="col-sm-4 col-xs-12">
                                            <label for="pd_cntry " class="reg m-cir ">Country</label>
                                            <select class="form-control" name="pd_cntry" id="pd_cntry" required="" disabled onChange={handleChange}>
                                                <option value="">Select</option>
                                                <option value="IND" selected="selected">India</option>
                                            </select>
                                        </div>
                                        <div class={`col-sm-4 col-xs-12 ${touched.pncd ? errors.pncd ? 'has-error' : 'has-success' : ''}`}>
                                            <label class="reg m-cir" for="pncd">PIN Code</label>
                                            <input value={values.pncd} name="pncd" id="pncd" type="text" class="form-control" placeholder="Enter PIN Code" required="" autocomplete="!!!!!!" maxLength={6} onChange={(e) => {
                                                e.target.value = utilService.formatOnlyNumber(e.target.value);
                                                handelePincode(e)
                                            }} />
                                            {(errors.pncd && touched.pncd) && <p class="err">{errors.pncd}</p>}
                                        </div>
                                        <div class={`col-sm-4 col-xs-12 ${touched.pd_state ? errors.pd_state ? 'has-error' : 'has-success' : ''}`}>
                                            <label for="pd_state" class="reg m-cir" data-ng-bind="trans.LBL_STATE">State</label>
                                            <input value={values.pd_state} id="pd_state" name="pd_state" type="text" class="form-control" placeholder="Enter State Name" required="" maxLength={60} autocomplete="!!!!!!" onChange={handleChange} />
                                            {(errors.pd_state && touched.pd_state) && <p class="err">{errors.pd_state}</p>}
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="inner">
                                        <div class={`col-sm-4 col-xs-12 ${touched.dst ? errors.dst ? 'has-error' : 'has-success' : ''}`}>
                                            <label class="reg m-cir" for="dst" data-ng-bind="trans.LBL_DISTRICT">District</label>
                                            <input value={values.dst} id="dst" name="dst" type="text" class="form-control" placeholder="Enter District Name" autocomplete="!!!!!!" maxLength={60} required="" onChange={handleChange} disabled={!isPincode} />
                                            {(isPincode && errors.dst && touched.dst) && <p class="err">{errors.dst}</p>}
                                        </div>
                                        <div class={`col-sm-4 col-xs-12 ${touched.city ? errors.city ? 'has-error' : 'has-success' : ''}`}>
                                            <label class="reg m-cir" for="city" data-ng-bind="trans.LBL_LAV_NO">City / Town / Village</label>
                                            <input value={values.city} class="form-control" id="city" name="city" type="text" placeholder="Enter City / Town / Village" data-ng-model="pd.rsad.loc" required="" autocomplete="!!!!!!" onChange={handleChange} disabled={!isPincode} />
                                            {(isPincode && errors.city && touched.city) && <p class="err">{errors.city}</p>}
                                        </div>
                                        <div class="col-sm-4 col-xs-12">
                                            <label class="reg" for="pd_locality" data-ng-bind="trans.LBL_LOSUBL">Locality/Sub Locality</label>
                                            <input value={values.pd_locality} class="form-control" maxLength={60} id="pd_locality" name="pd_locality" placeholder="Enter Locality / Sublocality" autocomplete="!!!!!!" onChange={handleChange} disabled={!isPincode} />
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="inner">
                                        <div class={`col-sm-4 col-xs-12 ${touched.pd_road ? errors.pd_road ? 'has-error' : 'has-success' : ''}`}>
                                            <label class="reg m-cir" for="pd_road" data-ng-bind="trans.LBL_RSL_NO">Road / Street</label>
                                            <input value={values.pd_road} class="form-control" id="pd_road" name="pd_road" placeholder="Enter Road / Street / Lane" maxLength={60} autocomplete="!!!!!!" required="" onChange={handleChange} disabled={!isPincode} />
                                            {(isPincode && errors.pd_road && touched.pd_road) && <p class="err">{errors.pd_road}</p>}
                                        </div>
                                        <div class="col-sm-4 col-xs-12">
                                            <label class="reg" for="pd_bdname" data-ng-bind="trans.LBL_NM_BULD">Name of the Premises / Building</label>
                                            <input value={values.pd_bdname} class="form-control" maxLength={60} id="pd_bdname" name="pd_bdname" placeholder="Enter Name of Premises / Building" autocomplete="!!!!!!" onChange={handleChange} disabled={!isPincode} />
                                        </div>
                                        <div class={`col-sm-4 col-xs-12 ${touched.pd_bdnum ? errors.pd_bdnum ? 'has-error' : 'has-success' : ''}`}>
                                            <label class="reg m-cir" for="pd_bdnum" data-ng-bind="trans.LBL_BFD_NO">Building No. / Flat No.</label>
                                            <input value={values.pd_bdnum} class="form-control" id="pd_bdnum" name="pd_bdnum" placeholder="Enter Building No. / Flat No. / Door No." maxLength={60} autocomplete="!!!!!!" onChange={handleChange} required="" disabled={!isPincode} />
                                            {(isPincode && errors.pd_bdnum && touched.pd_bdnum) && <p class="err">{errors.pd_bdnum}</p>}
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="inner">
                                        <div class="col-sm-4 col-xs-12">
                                            <label class="reg" for="pd_flrnum">Floor No.</label>
                                            <input value={values.pd_flrnum} class="form-control" maxLength={60} id="pd_flrnum" name="pd_flrnum" placeholder="Enter Floor No." autocomplete="!!!!!!" onChange={handleChange} disabled={!isPincode} />
                                        </div>
                                        <div class="col-sm-4 col-xs-12">
                                            <label class="reg" for="pd_landmark">Nearby Landmark</label>
                                            <input value={values.pd_landmark} class="form-control" maxLength={60} id="pd_landmark" name="pd_landmark" placeholder="Enter Nearby Landmark" autocomplete="!!!!!!" onChange={handleChange} disabled={!isPincode} />
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="inner">
                                        <div class="col-sm-4 col-xs-12">
                                        </div>
                                        <div class="col-sm-4 col-xs-12">
                                            <center onClick={() => { resetAddress() }}><button type="button" class="btn btn-primary"><i class="fa fa-refresh" aria-hidden="true"></i> &nbsp;Reset Address</button></center>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <h4>
                                <i class="fa fa-cloud-upload"></i>&nbsp;
                                <span >Document Upload</span>
                            </h4>
                            <div class="tbl-format">
                                <div class="row">
                                    {
                                        !doc.isFile && <div class="inner">
                                            <div class="col-sm-6 col-xs-12">
                                                <label class="reg m-cir" for="pd_upload2"><span>Upload Photograph (of person whose information has been given above)</span></label>
                                                <span class="help-block">
                                                    <p class="help-block">
                                                        <i class="fa fa-info-circle"></i>&nbsp;
                                                        <span>Only JPEG file format is allowed</span>
                                                    </p>
                                                    <p class="help-block">
                                                        <i class="fa fa-info-circle"></i>&nbsp;
                                                        <span>Maximum file size for upload is </span>100 KB
                                                    </p>
                                                </span>
                                                <input id="pd_upload" name="pd_upload" type="file" onClick={(e) => openBox(e)} />
                                                {(isValid && !doc.isFile) && <p class="err" data-ng-bind="errVar">Attach photo of the promoter.</p>}
                                            </div>
                                            <div class="col-sm-6 col-xs-12">
                                                <div class="row">
                                                    <div class="col-xs-2 selfie-seprator">
                                                        <div class="vseparator">
                                                            <div class="line"></div>
                                                            <div class="wordwrapper">
                                                                <div class="word">OR</div>
                                                            </div>
                                                        </div>
                                                        <div class="hseparator upload">OR</div>
                                                    </div>
                                                    <div class="col-xs-10">
                                                        <div class="col-xs-12 text-center">
                                                            <button title="Take Picture" type="button" class="btn btn-primary" disabled={true}>
                                                                <i class="fa fa-camera"></i> Take Picture</button>
                                                            <p>
                                                                <span class="fa fa-info-circle">&nbsp;You can use your device camera to take selfie photograph.</span>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    {
                                        doc.isFile && <div class="inner">
                                            <div class="col-sm-8 col-xs-12">
                                                <ul style={{ listStyle: 'none' }}>
                                                    {
                                                        doc.type === 'storage' && <li>
                                                            <div className='flex justify-between'>
                                                                <a href={doc.doc['fileurl']} target="_black" style={{ display: 'inlineBlock' }}>
                                                                    <img alt="preview" src={icImg} />
                                                                    <p>Photo</p>
                                                                </a>
                                                                <button title="Delete" type="button" class="btn btn-sm btn-danger pull-right" style={{ verticalAlign: 'Top', marginLeft: '20px' }} onClick={() => { showDig('1', 0) }}>
                                                                    <i class="fa fa-trash"></i> <span data-ng-bind="trans.LBL_DELETE">Delete</span>
                                                                </button>
                                                            </div>
                                                        </li>
                                                    }
                                                    {
                                                        doc.type !== 'storage' && <li>
                                                            <div className='flex justify-between'>
                                                                <a href={doc.doc['fileurl']} target="_black" style={{ display: 'inlineBlock' }}>
                                                                    <img alt="preview" src={icImg} />
                                                                    <p>Photo</p>
                                                                </a>
                                                                <button title="Delete" type="button" class="btn btn-sm btn-danger pull-right h-14" style={{ verticalAlign: 'top', marginLeft: '20px' }} onClick={() => { showDig('1', 0) }}>
                                                                    <i class="fa fa-trash"></i> <span data-ng-bind="trans.LBL_DELETE">Delete</span>
                                                                </button>
                                                            </div>
                                                        </li>
                                                    }
                                                </ul>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                            <h4>Other Information</h4>
                            <div class="tbl-format">
                                <div class="row">
                                    <div class="col-xs-12">
                                        <label class="reg" for="pri_auth">Also Authorized Signatory</label>
                                        <div class="switch round">
                                            <input id="pri_auth" name="pri_auth" type="checkbox" checked={values.pri_auth} onChange={(e) => {
                                                setErrodDob("");
                                                handleChange(e);
                                            }} />
                                            <label for="pri_auth">
                                                <span class="switch-on" style={{ left: '4px' }}><span>Yes</span></span>
                                                <span class="switch-off" style={{ left: '35px' }}><span>No</span></span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </fieldset>
                    </div >
                    <div class="row next-tab-nav">
                        <div class="col-xs-12 text-right">
                            <a title="Back" class="btn btn-default" style={{ marginRight: '8px' }} onClick={() => {
                                gotoPath('sa1');
                            }}>Back</a>
                            <button title="Show List" type="button" class="btn btn-default" style={{ marginRight: '8px' }} onClick={() => {
                                setIsForm(false);
                            }}>Show List</button>&nbsp;
                            <button title="Add New" type="button" class="btn btn-primary" disabled="disabled" style={{ marginRight: '8px' }}>Add New</button>&nbsp;
                            <button title="Save &amp; Continue" type="submit" class="btn btn-primary" disabled={isSubmit}>Save &amp; Continue</button>
                        </div>
                    </div>
                </div >
            }
        </form >
    );
}
