import React, { useState, useEffect } from 'react';

import { useRecoilState } from 'recoil';
import { loadingAtom, pathAtom, gstrpathAtom, returnDateAtom, userIDAtom, gstrFileAtom, gstrUserAtom, digErrorAtom, itcSetOffAtom } from '../../../core/config/atoms';

import ServiceGSTF from '../../../services/serviceGSTF';
import ServiceUtility from '../../../services/serviceUtility';

export default function PanelCreateChallan() {

    const [gDate] = useRecoilState(returnDateAtom);
    const [userID] = useRecoilState(userIDAtom);
    const [gstrf] = useRecoilState(gstrFileAtom);
    const [GSTUser] = useRecoilState(gstrUserAtom);
    const [setoff] = useRecoilState(itcSetOffAtom);

    const [, setDigError] = useRecoilState(digErrorAtom);
    const [, setPath] = useRecoilState(pathAtom);
    const [, setGSTRPath] = useRecoilState(gstrpathAtom);
    const [, setLoading] = useRecoilState(loadingAtom);

    const [selType, setSelType] = useState('');

    const fileService = new ServiceGSTF();
    const utilService = new ServiceUtility();

    const gotoPath = (path) => {
        setPath(path);
    }

    const getIGST = (type) => {
        let value = 0;
        switch (type) {
            case 'A3.1':
                value = parseFloat(gstrf.data.a3.user.a.igst.value) + parseFloat(gstrf.data.a3.user.b.igst.value) + parseFloat(gstrf.data.a3.user.d.igst.value);
                break;
            case 'A4':
                value = parseFloat(gstrf.data.a4.user.a.rowa.igst.value) + parseFloat(gstrf.data.a4.user.a.rowb.igst.value) + parseFloat(gstrf.data.a4.user.a.rowc.igst.value) + parseFloat(gstrf.data.a4.user.a.rowd.igst.value) + parseFloat(gstrf.data.a4.user.a.rowe.igst.value);
                value = value + parseFloat(gstrf.data.a4.user.b.rowa.igst.value) + parseFloat(gstrf.data.a4.user.b.rowb.igst.value);
                value = value + parseFloat(gstrf.data.a4.user.c.igst.value);
                value = value + parseFloat(gstrf.data.a4.user.d.rowa.igst.value) + parseFloat(gstrf.data.a4.user.d.rowb.igst.value);
                break;
            case 'A5':
                value = parseFloat(gstrf.data.a5.user.a.igst.value) + parseFloat(gstrf.data.a5.user.b.igst.value);
                break;
            case 'A51':
                value = parseFloat(gstrf.data.a51.user.a.igst.value);
                break;
        }
        return value;
    }

    const getCGST = (type) => {
        let value = 0;
        switch (type) {
            case 'A3.1':
                value = parseFloat(gstrf.data.a3.user.a.cgst.value) + parseFloat(gstrf.data.a3.user.d.cgst.value);
                break;
            case 'A4':
                value = parseFloat(gstrf.data.a4.user.a.rowc.cgst.value) + parseFloat(gstrf.data.a4.user.a.rowd.cgst.value) + parseFloat(gstrf.data.a4.user.a.rowe.cgst.value);
                value = value + parseFloat(gstrf.data.a4.user.b.rowa.cgst.value) + parseFloat(gstrf.data.a4.user.b.rowb.cgst.value);
                value = value + parseFloat(gstrf.data.a4.user.c.cgst.value);
                value = value + parseFloat(gstrf.data.a4.user.d.rowa.cgst.value) + parseFloat(gstrf.data.a4.user.d.rowb.cgst.value);
                break;
            case 'A5':
                value = parseFloat(gstrf.data.a5.user.a.cgst.value) + parseFloat(gstrf.data.a5.user.b.cgst.value);
                break;
            case 'A51':
                value = parseFloat(gstrf.data.a51.user.a.cgst.value) + parseFloat(gstrf.data.a51.user.b.igst.value);
                break;
            case 'A51A':
                value = parseFloat(gstrf.data.a51.user.a.cgst.value);
                break;
            case 'A51B':
                value = parseFloat(gstrf.data.a51.user.b.igst.value);
                break;
        }
        return value;
    }

    const getSGST = (type) => {
        let value = 0;
        switch (type) {
            case 'A3.1':
                value = parseFloat(gstrf.data.a3.user.a.sgst.value) + parseFloat(gstrf.data.a3.user.d.sgst.value);
                break;
            case 'A4':
                value = parseFloat(gstrf.data.a4.user.a.rowc.sgst.value) + parseFloat(gstrf.data.a4.user.a.rowd.sgst.value) + parseFloat(gstrf.data.a4.user.a.rowe.sgst.value);
                value = value + parseFloat(gstrf.data.a4.user.b.rowa.sgst.value) + parseFloat(gstrf.data.a4.user.b.rowb.sgst.value);
                value = value + parseFloat(gstrf.data.a4.user.c.sgst.value);
                value = value + parseFloat(gstrf.data.a4.user.d.rowa.sgst.value) + parseFloat(gstrf.data.a4.user.d.rowb.sgst.value);
                break;
            case 'A51':
                value = parseFloat(gstrf.data.a51.user.a.sgst.value) + parseFloat(gstrf.data.a51.user.b.cgst.value);
                break;
            case 'A51A':
                value = parseFloat(gstrf.data.a51.user.a.sgst.value);
                break;
            case 'A51B':
                value = parseFloat(gstrf.data.a51.user.b.cgst.value);
                break;
        }
        return value;
    }

    const getCess = (type) => {
        let value = 0;
        switch (type) {
            case 'A3.1':
                value = parseFloat(gstrf.data.a3.user.a.cess.value) + parseFloat(gstrf.data.a3.user.b.cess.value) + parseFloat(gstrf.data.a3.user.d.cess.value);
                break;
            case 'A4':
                value = parseFloat(gstrf.data.a4.user.a.rowa.cess.value) + parseFloat(gstrf.data.a4.user.a.rowb.cess.value) + parseFloat(gstrf.data.a4.user.a.rowc.cess.value) + parseFloat(gstrf.data.a4.user.a.rowd.cess.value) + parseFloat(gstrf.data.a4.user.a.rowe.cess.value);
                value = value + parseFloat(gstrf.data.a4.user.b.rowa.cess.value) + parseFloat(gstrf.data.a4.user.b.rowb.cess.value);
                value = value + parseFloat(gstrf.data.a4.user.c.cess.value);
                value = value + parseFloat(gstrf.data.a4.user.d.rowa.cess.value) + parseFloat(gstrf.data.a4.user.d.rowb.cess.value);
                break;
            case 'A51':
                value = parseFloat(gstrf.data.a51.user.a.cess.value);
                break;
        }
        return value;
    }

    const getTCGST = () => {
        let total = parseFloat(setoff.balb) + getCGST('A51A') + getCGST('A51B');
        return total;
    }

    const getTSGST = () => {
        let total = parseFloat(setoff.balc) + getSGST('A51A') + getSGST('A51B');
        return total;
    }

    const getTIGST = () => {
        return getIGST('A51') + parseFloat(setoff.bala);
    }

    const getTCess = () => {
        return getCess('A51') + parseFloat(setoff.bald);
    }

    const loadData = () => {
        (async function () {
            setLoading(false);
            window.scrollTo({
                top: 0,
                behavior: 'smooth',
            });
        })();
    }

    const createChallan = () => {
        let body = {
            lid: userID,
            month: gDate,
            cpin: utilService.getCPIN(),
            mop: selType,
            data: {
                tax: {
                    igst: parseFloat(setoff.bala),
                    cgst: parseFloat(setoff.balb),
                    sgst: parseFloat(setoff.balc),
                    cess: parseFloat(setoff.bald),
                },
                interest: {
                    igst: getIGST('A51'),
                    sgst: getSGST('A51A'),
                    cgst: getCGST('A51A'),
                    cess: getCess('A51'),
                },
                fees: {
                    sgst: getSGST('A51B'),
                    cgst: getCGST('A51B'),
                }
            }
        }
        fileService.creatChllan(body).then((res) => {
            if (res.status) {
                setLoading(true);
                setGSTRPath('genrate');
                gotoPath('gstr3BChallan');
            } else {
                setDigError({ title: 'Error', msg: 'Server error please try again!', isToggle: true });
            }
        });
    }

    useEffect(() => {
        loadData();
    }, []);

    return (
        <div class="content-wrapper">
            <div class="container fade-ng-cloak">
                <div class="mypage">

                    <div class="row" data-ng-controller="transctrl" data-ng-init="init('payment')">
                        <div class="col-xs-10">
                            <div data-breadcrumb="" name="Dashboard">
                                <ol className="breadcrumb" data-ng-controller="crumbCtrl">
                                    <li><a onClick={() => { gotoPath('gstr') }}>Dashboard</a></li>
                                    <li><a onClick={() => {
                                        setGSTRPath('A6.1');
                                        gotoPath('gstr3B');
                                    }}>Payment</a></li>
                                    <li>
                                        Create Challan
                                    </li>
                                </ol>
                            </div>
                        </div>
                        <div class="col-xs-2">
                            <div class="lang dropdown">
                                <span class="dropdown-toggle" data-toggle="dropdown" data-ng-bind="selectedLang">English</span>
                                <ul class="dropdown-menu lang-dpdwn">
                                    <li data-ng-repeat="language in languages">
                                        <a data-ng-click="selectLang(language.cd)" data-ng-bind="language.nm">English</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div class="tabpane" data-ng-show="showContent">
                        <form class="ng-pristine ng-valid ng-valid-pattern ng-valid-maxlength" role="form" name="challanTab" autocomplete="off" novalidate="">
                            <challan-reason-section>
                                <div data-ng-if="challanData.chln_rsn &amp;&amp; IS_CHALLAN_REASON_ENABLED"> <div class="row"> <div class="col-sm-4 col-xs-12"> <h4 data-ng-bind="trans.HEAD_RSN_FR_CHLN">Reason For Challan</h4> </div>
                                </div>
                                    <div class="tbl-format">
                                        <div class="row">
                                            <div class="inner">
                                                <div class="col-sm-4 col-xs-12"> <p data-ng-bind="trans.LBL_REASON">Reason</p>
                                                    <span data-ng-if="challanData.chln_rsn === 'AOP'"><strong data-ng-bind="trans.LBL_AOP">Any other payment</strong></span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </challan-reason-section>
                            <div class="row">
                                <div class="col-sm-4 col-xs-12">
                                    <h4 data-ng-bind="trans.HEAD_BP_TAX">Details of Deposit</h4>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <div class="table-responsive">
                                        <table style={{ width: '99%' }} class="table tbl inv table-bordered exp">
                                            <thead>
                                                <tr>
                                                    <th style={{ width: '14%' }}>
                                                        <p></p>
                                                    </th>
                                                    <th style={{ width: '14%' }}>
                                                        <div class="row">
                                                            <div class="col-xs-12 text-center">
                                                                <label data-ng-bind="trans.HEAD_TAX">Tax</label>
                                                                <label>
                                                                    (
                                                                    <i class="fa fa-inr" araia-hidden="true"></i>)
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </th>
                                                    <th style={{ width: '14%' }}>
                                                        <div class="row">
                                                            <div class="col-xs-12 text-center">
                                                                <label data-ng-bind="trans.HEAD_INTR">Interest</label>
                                                                <label>
                                                                    (
                                                                    <i class="fa fa-inr" araia-hidden="true"></i>)
                                                                </label>
                                                            </div>
                                                        </div>

                                                    </th>
                                                    <th style={{ width: '14%' }}>
                                                        <div class="row">
                                                            <div class="col-xs-12 text-center">
                                                                <label data-ng-bind="trans.HEAD_PENALTY">Penalty</label>
                                                                <label>
                                                                    (
                                                                    <i class="fa fa-inr" araia-hidden="true"></i>)
                                                                </label>
                                                            </div>
                                                        </div>

                                                    </th>
                                                    <th style={{ width: '14%' }}>
                                                        <div class="row">
                                                            <div class="col-xs-12 text-center">
                                                                <label data-ng-bind="trans.HEAD_FEES">Fees</label>
                                                                <label>
                                                                    (
                                                                    <i class="fa fa-inr" araia-hidden="true"></i>)
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </th>
                                                    <th style={{ width: '14%' }}>
                                                        <div class="row">
                                                            <div class="col-xs-12 text-center">
                                                                <label data-ng-bind="trans.HEAD_OTHER">Other</label>
                                                                <label>
                                                                    (
                                                                    <i class="fa fa-inr" araia-hidden="true"></i>)
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </th>
                                                    <th>
                                                        <div class="row">
                                                            <div class="col-xs-12 text-center">
                                                                <label data-ng-bind="trans.HEAD_TOT">Total</label>
                                                                <label>
                                                                    (
                                                                    <i class="fa fa-inr" araia-hidden="true"></i>)
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <label data-ng-bind="trans.HEAD_CGST" title="Central Goods &amp; Services Tax">CGST(0005)</label>
                                                    </td>
                                                    <td>
                                                        <input class="form-control textbox txt number text-right" type="text" readOnly={true} value={setoff.balb} />
                                                    </td>
                                                    <td>
                                                        <input class="form-control textbox txt number text-right" type="text" readOnly={true} value={getCGST('A51A').toFixed(2)} />
                                                    </td>
                                                    <td>
                                                        <input class="form-control textbox txt number text-right" type="text" readOnly={true} value={'0.00'} />
                                                    </td>
                                                    <td>
                                                        <input class="form-control textbox txt number text-right" type="text" readOnly={true} value={getCGST('A51B').toFixed(2)} />
                                                    </td>
                                                    <td>
                                                        <input class="form-control textbox txt number text-right" type="text" readOnly={true} value={'0.00'} />
                                                    </td>
                                                    <td width="15%">
                                                        <input class="form-control textbox txt number text-right" type="text" readOnly={true} value={getTCGST().toFixed(2)} />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <label data-ng-bind="trans.HEAD_IGST" title="Integrated Goods &amp; Services Tax">IGST(0008)</label>
                                                    </td>
                                                    <td>
                                                        <input class="form-control textbox txt number text-right" type="text" readOnly={true} value={setoff.bala} />
                                                    </td>
                                                    <td>
                                                        <input class="form-control textbox txt number text-right" type="text" readOnly={true} value={getIGST('A51').toFixed(2)} />
                                                    </td>
                                                    <td>
                                                        <input class="form-control textbox txt number text-right" type="text" readOnly={true} value={'0.00'} />
                                                    </td>
                                                    <td>
                                                        <input class="form-control textbox txt number text-right" type="text" readOnly={true} value={'0.00'} />
                                                    </td>
                                                    <td>
                                                        <input class="form-control textbox txt number text-right" type="text" readOnly={true} value={'0.00'} />
                                                    </td>
                                                    <td width="15%">
                                                        <input class="form-control textbox txt number text-right" type="text" readOnly={true} value={getTIGST().toFixed(2)} />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <label data-ng-bind="trans.HEAD_CESS">CESS(0009)</label>
                                                    </td>
                                                    <td>
                                                        <input class="form-control textbox txt number text-right" type="text" readOnly={true} value={setoff.bald} />
                                                    </td>
                                                    <td>
                                                        <input class="form-control textbox txt number text-right" type="text" readOnly={true} value={getCess('A51').toFixed(2)} />
                                                    </td>
                                                    <td>
                                                        <input class="form-control textbox txt number text-right" type="text" readOnly={true} value={'0.00'} />
                                                    </td>
                                                    <td>
                                                        <input class="form-control textbox txt number text-right" type="text" readOnly={true} value={'0.00'} />
                                                    </td>
                                                    <td>
                                                        <input class="form-control textbox txt number text-right" type="text" readOnly={true} value={'0.00'} />
                                                    </td>
                                                    <td width="15%">
                                                        <input class="form-control textbox txt number text-right" type="text" readOnly={true} value={getTCess().toFixed(2)} />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <label title="State Goods &amp; Services Tax">{GSTUser.state}<br />
                                                            {utilService.getIsState(GSTUser.state) && <span className=''>SGST(0006)</span>}
                                                            {!utilService.getIsState(GSTUser.state) && <span className=''>UTGST(0007)</span>}
                                                        </label>
                                                    </td>
                                                    <td>
                                                        <input class="form-control textbox txt number text-right" type="text" readOnly={true} value={setoff.balc} />
                                                    </td>
                                                    <td>
                                                        <input class="form-control textbox txt number text-right" type="text" readOnly={true} value={getSGST('A51A').toFixed(2)} />
                                                    </td>
                                                    <td>
                                                        <input class="form-control textbox txt number text-right" type="text" readOnly={true} value={'0.00'} />
                                                    </td>
                                                    <td>
                                                        <input class="form-control textbox txt number text-right" type="text" readOnly={true} value={getSGST('A51B').toFixed(2)} />
                                                    </td>
                                                    <td>
                                                        <input class="form-control textbox txt number text-right" type="text" readOnly={true} value={'0.00'} />
                                                    </td>
                                                    <td width="15%">
                                                        <input class="form-control textbox txt number text-right" type="text" readOnly={true} value={getTSGST().toFixed(2)} />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td colspan="2">
                                                        <label data-ng-bind="trans.HEAD_TOT_CHLNAMT">Total Challan Amount:</label>
                                                    </td>
                                                    <td colspan="5">
                                                        <span>  ₹</span>
                                                        <label data-ng-bind="challanData.total_amt |INR:'pay'">{(getTCGST() + getTSGST() + getTIGST() + getTCess()).toFixed(2)}</label>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td colspan="2">
                                                        <label data-ng-bind="trans.HEAD_TOTAMT_WORDS">Total Challan Amount (In Words):</label>
                                                    </td>
                                                    <td colspan="5">
                                                        <label data-ng-bind="challanData.total_amt_word">{utilService.wordify((getTCGST() + getTSGST() + getTIGST() + getTCess()))}</label>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </form>
                        <div class="row">
                            <div class="col-sm-4 col-xs-12">
                                <h4 class="m-cir" data-ng-bind="trans.HEAD_PYMNT_MODE">Payment Modes</h4>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-3 col-sm-3 col-xs-12" data-ng-class="{ 'has-error':OTCerror || totErr || challanTab.$submitted &amp;&amp; challanTab.payMode.$error.required}">
                                <ul class="dev-tab" name="payMode">
                                    <li id="pay1" value="EPY" onClick={() => {
                                        setSelType('a');
                                    }}>
                                        <a>
                                            <div className='flex items-center justify-between'>
                                                <div>
                                                    <i class="fa fa-credit-card" aria-hidden="true"></i>&nbsp;<span data-ng-bind="trans.HLP_EPYMNT">E-Payment</span>
                                                </div>
                                                {selType === 'a' && <i className="fa fa-check text-black"></i>}
                                            </div>
                                        </a>
                                    </li>
                                    <li id="pay2" value="OTC">
                                        <a>
                                            <div className='flex items-center justify-between opacity-25'>
                                                <div>
                                                    <i class="fa fa-money" aria-hidden="true"></i>&nbsp;<span data-ng-bind="trans.HLP_OTC">Over The Counter</span><i id="pay2t"></i>
                                                </div>
                                                {selType === 'b' && <i className="fa fa-check text-black"></i>}
                                            </div>
                                        </a>
                                    </li>
                                    <li id="pay3" value="NER">
                                        <a>
                                            <div className='flex items-center justify-between opacity-25'>
                                                <div>
                                                    <i class="fa fa-globe" aria-hidden="true"></i>&nbsp;<span data-ng-bind="trans.HLP_NER">NEFT/RTGS</span> <i id="pay3t"></i>
                                                </div>
                                                {selType === 'c' && <i className="fa fa-check text-black"></i>}
                                            </div>
                                        </a>
                                    </li>
                                </ul>
                                <span data-ng-bind="PMTerror" class="err"></span>
                            </div>
                            <div class="row">
                                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-right pad-b-20">
                                    <button type="button" title="Generate Challan" class="btn btn-primary sendButton" disabled={selType === ''} onClick={() => { createChallan(); }}>Generate Challan</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
}
