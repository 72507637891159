import React, { useState, useEffect } from 'react';

import { useRecoilState } from 'recoil';
import { pathAtom, digErrorAtom, returnDateAtom, loadingAtom, gstrUserAtom, userIDAtom, userAtom, gstrFileAtom, dig3BAtom } from '../../core/config/atoms';

import Moment from 'moment';

import ServiceLMS from '../../services/serviceLMS';
import ServiceGSTR from '../../services/serviceGSTR';
import ServiceLog from '../../services/serviceLog';
import ServiceGSTF from '../../services/serviceGSTF';

export default function PanelGSTReturn() {

    const [loading, setLoading] = useRecoilState(loadingAtom);
    const [returnDate, setReturnDate] = useRecoilState(returnDateAtom);
    const [userAuth] = useRecoilState(userAtom);
    const [GSTUser] = useRecoilState(gstrUserAtom);
    const [userID] = useRecoilState(userIDAtom);
    const [gstrf, setGSTRF] = useRecoilState(gstrFileAtom);

    const [, setSDigOpen] = useRecoilState(dig3BAtom);
    const [, setDigError] = useRecoilState(digErrorAtom);
    const [, setPath] = useRecoilState(pathAtom);

    const [fyears, setFYears] = useState([]);
    const [quarters, setQuarters] = useState([]);
    const [months, setMonths] = useState([]);

    const [LMSUser, setLMSUser] = useState({});
    const [gstrFile, setGSTRFile] = useState({});
    const [searchData, setSearchData] = useState({});

    const [searchMon, setSearchMon] = useState('');

    const [isSearch, setSearch] = useState(false);

    const lmsService = new ServiceLMS();
    const returnService = new ServiceGSTR();
    const fillService = new ServiceGSTF();
    const logService = new ServiceLog();

    const gotoPath = (path) => {
        setPath(path);
    }

    const searchDate = () => {
        let s = Moment(searchData.mon).toDate().toISOString();
        s = s.split('.')[0];
        s = s + ".000z";
        let search = Moment(s, 'YYYY-MM-DDTHH:mm:ss.SSSZ').toDate();
        setSearchMon(search);
        returnService.getGstrFiles({ lid: userID, month: search }).then((res) => {
            if (res.status) {
                setGSTRFile(res.data);
                setReturnDate(search);
                fillService.getGSTFDoc({ lid: userID, month: search }).then((res) => {
                    if (res.status) {
                        setGSTRF(res.data);
                    }
                });
                setSearch(true);
            } else {
                setDigError({ title: 'Error', msg: 'Server error please try again!', isToggle: true });
            }
        });

    }

    const getStateBasedNum = () => {
        switch (GSTUser.state) {
            case "ANDAMAN AND NICOBAR ISLANDS":
            case "CHHATTISGARH":
            case "MADHYA PRADESH":
            case "GUJARAT":
            case "MAHARASHTRA":
            case "GOA":
            case "KARNATAKA":
            case "KERALA":
            case "ANDHRA PRADESH":
            case "TAMIL NADU":
            case "TELANGANA":
            case "PUDUCHERRY":
            case "THE DADRA AND NAGAR HAVELI AND DAMAN AND DIU":
            case "LAKSHADWEEP":
                return 21;
            case "PUNJAB":
            case "HIMACHAL PRADESH":
            case "UTTARAKHAND":
            case "HARYANA":
            case "RAJASTHAN":
            case "SIKKIM":
            case "UTTAR PRADESH":
            case "BIHAR":
            case "ARUNACHAL PRADESH":
            case "NAGALAND":
            case "TRIPURA":
            case "MEGHALAYA":
            case "MIZORAM":
            case "MANIPUR":
            case "ASSAM":
            case "CHANDIGARH":
            case "DELHI":
            case "JAMMU AND KASHMIR":
            case "JHARKHAND":
            case "LADAKH":
            case "ODISHA":
            case "WEST BENGAL":
                return 23;
            default:
                return 19;
        }
    }

    const changeYear = (value) => {
        let qu = [];
        let ma = [];

        let temp = Moment(LMSUser.GSTReturn).toDate();
        let rhs = Moment(LMSUser.GSTReturn).toDate();

        if (rhs.getMonth() <= 2) {
            rhs = Moment(rhs).subtract(1, 'year').toDate();
            rhs.setMonth(3);
        } else {
            rhs.setMonth(3);
        }

        let lhs = Moment(value).toDate();

        if (lhs.getFullYear() < rhs.getFullYear()) {
            qu.push({ label: 'Quarter 1 (Apr - Jun)', data: 'A', status: true });
            qu.push({ label: 'Quarter 2 (Jul - Sep)', data: 'B', status: true });
            qu.push({ label: 'Quarter 3 (Oct - Dec)', data: 'C', status: true });
            qu.push({ label: 'Quarter 4 (Jan - Mar)', data: 'D', status: true });

            ma.push({ label: Moment(lhs).format('MMMM'), data: lhs, status: true });
            ma.push({ label: Moment(lhs).add(1, 'month').format('MMMM'), data: Moment(lhs).add(1, 'month').toDate(), status: true });
            ma.push({ label: Moment(lhs).add(2, 'month').format('MMMM'), data: Moment(lhs).add(2, 'month').toDate(), status: true });

            setSearchData({ 'year': lhs, 'quarter': 'A', "mon": lhs });
            setQuarters(qu);
            setMonths(ma);
            setLoading(false);
        } else {
            if (lhs.getTime() >= temp.getTime()) {
                qu.push({ label: 'Quarter 1 (Apr - Jun)', data: 'A', status: true });
                qu.push({ label: 'Quarter 2 (Jul - Sep)', data: 'B', status: false });
                qu.push({ label: 'Quarter 3 (Oct - Dec)', data: 'C', status: false });
                qu.push({ label: 'Quarter 4 (Jan - Mar)', data: 'D', status: false });
            } else {
                if (temp.getFullYear() > lhs.getFullYear()) {
                    qu.push({ label: 'Quarter 1 (Apr - Jun)', data: 'A', status: true });
                    qu.push({ label: 'Quarter 2 (Jul - Sep)', data: 'B', status: true });
                    qu.push({ label: 'Quarter 3 (Oct - Dec)', data: 'C', status: true });
                    qu.push({ label: 'Quarter 4 (Jan - Mar)', data: 'D', status: temp.getMonth() >= 0 });
                } else {
                    qu.push({ label: 'Quarter 1 (Apr - Jun)', data: 'A', status: temp.getMonth() >= 3 });
                    qu.push({ label: 'Quarter 2 (Jul - Sep)', data: 'B', status: temp.getMonth() >= 6 });
                    qu.push({ label: 'Quarter 3 (Oct - Dec)', data: 'C', status: temp.getMonth() >= 9 });
                    qu.push({ label: 'Quarter 4 (Jan - Mar)', data: 'D', status: false });
                }
            }

            let AQ = calActiveQuter(temp);

            switch (AQ) {
                case "A":
                    if (temp.getMonth() == 3) {
                        ma.push({ label: Moment(temp).format('MMMM'), data: temp, status: true });
                        ma.push({ label: "May", data: '', status: false });
                        ma.push({ label: "June", data: '', status: false });
                    } else if (temp.getMonth() == 4) {
                        ma.push({ label: Moment(temp).subtract(1, 'month').format('MMMM'), data: Moment(temp).subtract(1, 'month').toDate(), status: true });
                        ma.push({ label: Moment(temp).format('MMMM'), data: temp, status: true });
                        ma.push({ label: "June", data: '', status: false });
                    } else {
                        ma.push({ label: Moment(temp).subtract(2, 'month').format('MMMM'), data: Moment(temp).subtract(2, 'month').toDate(), status: true });
                        ma.push({ label: Moment(temp).subtract(1, 'month').format('MMMM'), data: Moment(temp).subtract(1, 'month').toDate(), status: true });
                        ma.push({ label: Moment(temp).format('MMMM'), data: temp, status: true });
                    }
                    break;
                case "B":
                    if (temp.getMonth() == 6) {
                        ma.push({ label: Moment(temp).format('MMMM'), data: temp, status: true });
                        ma.push({ label: "August", data: '', status: false });
                        ma.push({ label: "September", data: '', status: false });
                    } else if (temp.getMonth() == 7) {
                        ma.push({ label: Moment(temp).subtract(1, 'month').format('MMMM'), data: Moment(temp).subtract(1, 'month').toDate(), status: true });
                        ma.push({ label: Moment(temp).format('MMMM'), data: temp, status: true });
                        ma.push({ label: "September", data: '', status: false });
                    } else {
                        ma.push({ label: Moment(temp).subtract(2, 'month').format('MMMM'), data: Moment(temp).subtract(2, 'month').toDate(), status: true });
                        ma.push({ label: Moment(temp).subtract(1, 'month').format('MMMM'), data: Moment(temp).subtract(1, 'month').toDate(), status: true });
                        ma.push({ label: Moment(temp).format('MMMM'), data: temp, status: true });
                    }
                    break;
                case "C":
                    if (temp.getMonth() == 9) {
                        ma.push({ label: Moment(temp).format('MMMM'), data: temp, status: true });
                        ma.push({ label: "November", data: '', status: false });
                        ma.push({ label: "December", data: '', status: false });
                    } else if (temp.getMonth() == 10) {
                        ma.push({ label: Moment(temp).subtract(1, 'month').format('MMMM'), data: Moment(temp).subtract(1, 'month').toDate(), status: true });
                        ma.push({ label: Moment(temp).format('MMMM'), data: temp, status: true });
                        ma.push({ label: "December", data: '', status: false });
                    } else {
                        ma.push({ label: Moment(temp).subtract(2, 'month').format('MMMM'), data: Moment(temp).subtract(2, 'month').toDate(), status: true });
                        ma.push({ label: Moment(temp).subtract(1, 'month').format('MMMM'), data: Moment(temp).subtract(1, 'month').toDate(), status: true });
                        ma.push({ label: Moment(temp).format('MMMM'), data: temp, status: true });
                    }
                    break;
                case "D":
                    if (temp.getMonth() == 0) {
                        ma.push({ label: Moment(temp).format('MMMM'), data: temp, status: true });
                        ma.push({ label: "February", data: '', status: false });
                        ma.push({ label: "March", data: '', status: false });
                    } else if (temp.getMonth() == 1) {
                        ma.push({ label: Moment(temp).subtract(1, 'month').format('MMMM'), data: Moment(temp).subtract(1, 'month').toDate(), status: true });
                        ma.push({ label: Moment(temp).format('MMMM'), data: temp, status: true });
                        ma.push({ label: "March", data: '', status: false });
                    } else {
                        ma.push({ label: Moment(temp).subtract(2, 'month').format('MMMM'), data: Moment(temp).subtract(2, 'month').toDate(), status: true });
                        ma.push({ label: Moment(temp).subtract(1, 'month').format('MMMM'), data: Moment(temp).subtract(1, 'month').toDate(), status: true });
                        ma.push({ label: Moment(temp).format('MMMM'), data: temp, status: true });
                    }
                    break;
            }

            setSearchData({ 'year': lhs, 'quarter': AQ, "mon": temp });
            setQuarters(qu);
            setMonths(ma);
            setLoading(false);
        }
    }

    const changeQuater = (value) => {
        let ma = [];

        let temp = Moment(LMSUser.GSTReturn).toDate();
        let rhs = Moment(LMSUser.GSTReturn).toDate();

        if (rhs.getMonth() <= 2) {
            rhs = Moment(rhs).subtract(1, 'year').toDate();
        }

        rhs.setMonth(3);
        let lhs = Moment(searchData.year).toDate();

        if (lhs.getFullYear() < rhs.getFullYear()) {
            switch (value) {
                case "A":
                    ma.push({ label: Moment(lhs).format('MMMM'), data: lhs, status: true });
                    ma.push({ label: Moment(lhs).add(1, 'month').format('MMMM'), data: Moment(lhs).add(1, 'month').toDate(), status: true });
                    ma.push({ label: Moment(lhs).add(2, 'month').format('MMMM'), data: Moment(lhs).add(2, 'month').toDate(), status: true });
                    setSearchData({ ...searchData, 'quarter': value, "mon": lhs });
                    break;
                case "B":
                    ma.push({ label: Moment(lhs).add(3, 'month').format('MMMM'), data: Moment(lhs).add(3, 'month').toDate(), status: true });
                    ma.push({ label: Moment(lhs).add(4, 'month').format('MMMM'), data: Moment(lhs).add(4, 'month').toDate(), status: true });
                    ma.push({ label: Moment(lhs).add(5, 'month').format('MMMM'), data: Moment(lhs).add(5, 'month').toDate(), status: true });
                    setSearchData({ ...searchData, 'quarter': value, "mon": Moment(lhs).add(3, 'month').toDate() });
                    break;
                case "C":
                    ma.push({ label: Moment(lhs).add(6, 'month').format('MMMM'), data: Moment(lhs).add(6, 'month').toDate(), status: true });
                    ma.push({ label: Moment(lhs).add(7, 'month').format('MMMM'), data: Moment(lhs).add(7, 'month').toDate(), status: true });
                    ma.push({ label: Moment(lhs).add(8, 'month').format('MMMM'), data: Moment(lhs).add(8, 'month').toDate(), status: true });
                    setSearchData({ ...searchData, 'quarter': value, "mon": Moment(lhs).add(6, 'month').toDate() });
                    break;
                case "D":
                    ma.push({ label: Moment(lhs).add(9, 'month').format('MMMM'), data: Moment(lhs).add(9, 'month').toDate(), status: true });
                    ma.push({ label: Moment(lhs).add(10, 'month').format('MMMM'), data: Moment(lhs).add(10, 'month').toDate(), status: true });
                    ma.push({ label: Moment(lhs).add(11, 'month').format('MMMM'), data: Moment(lhs).add(11, 'month').toDate(), status: true });
                    setSearchData({ ...searchData, 'quarter': value, "mon": Moment(lhs).add(9, 'month').toDate() });
                    break;
            }
        } else if (lhs.getFullYear() === rhs.getFullYear()) {
            switch (value) {
                case "A":
                    if (temp.getFullYear() > lhs.getFullYear() || temp.getMonth() >= 6) {
                        ma.push({ label: Moment(lhs).format('MMMM'), data: lhs, status: true });
                        ma.push({ label: Moment(lhs).add(1, 'month').format('MMMM'), data: Moment(lhs).add(1, 'month').toDate(), status: true });
                        ma.push({ label: Moment(lhs).add(2, 'month').format('MMMM'), data: Moment(lhs).add(2, 'month').toDate(), status: true });
                        setSearchData({ ...searchData, 'quarter': value, "mon": lhs });
                    } else {
                        if (temp.getMonth() == 3) {
                            ma.push({ label: Moment(temp).format('MMMM'), data: temp, status: true });
                            ma.push({ label: "May", data: '', status: false });
                            ma.push({ label: "June", data: '', status: false });
                        } else if (temp.getMonth() == 4) {
                            ma.push({ label: Moment(temp).subtract(1, 'month').format('MMMM'), data: Moment(temp).subtract(1, 'month').toDate(), status: true });
                            ma.push({ label: Moment(temp).format('MMMM'), data: temp, status: true });
                            ma.push({ label: "June", data: '', status: false });
                        } else {
                            ma.push({ label: Moment(temp).subtract(2, 'month').format('MMMM'), data: Moment(temp).subtract(2, 'month').toDate(), status: true });
                            ma.push({ label: Moment(temp).subtract(1, 'month').format('MMMM'), data: Moment(temp).subtract(1, 'month').toDate(), status: true });
                            ma.push({ label: Moment(temp).format('MMMM'), data: temp, status: true });
                        }
                        setSearchData({ ...searchData, 'quarter': value, "mon": temp });
                    }
                    break;
                case "B":
                    if (temp.getFullYear() > lhs.getFullYear() || temp.getMonth() >= 9) {
                        ma.push({ label: Moment(lhs).add(3, 'month').format('MMMM'), data: Moment(lhs).add(3, 'month').toDate(), status: true });
                        ma.push({ label: Moment(lhs).add(4, 'month').format('MMMM'), data: Moment(lhs).add(4, 'month').toDate(), status: true });
                        ma.push({ label: Moment(lhs).add(5, 'month').format('MMMM'), data: Moment(lhs).add(5, 'month').toDate(), status: true });
                        setSearchData({ ...searchData, 'quarter': value, "mon": Moment(lhs).add(3, 'month').toDate() });
                    } else {
                        if (temp.getMonth() == 6) {
                            ma.push({ label: Moment(temp).format('MMMM'), data: temp, status: true });
                            ma.push({ label: "August", data: '', status: false });
                            ma.push({ label: "September", data: '', status: false });
                        } else if (temp.getMonth() == 7) {
                            ma.push({ label: Moment(temp).subtract(1, 'month').format('MMMM'), data: Moment(temp).subtract(1, 'month').toDate(), status: true });
                            ma.push({ label: Moment(temp).format('MMMM'), data: temp, status: true });
                            ma.push({ label: "September", data: '', status: false });
                        } else {
                            ma.push({ label: Moment(temp).subtract(2, 'month').format('MMMM'), data: Moment(temp).subtract(2, 'month').toDate(), status: true });
                            ma.push({ label: Moment(temp).subtract(1, 'month').format('MMMM'), data: Moment(temp).subtract(1, 'month').toDate(), status: true });
                            ma.push({ label: Moment(temp).format('MMMM'), data: temp, status: true });
                        }
                        setSearchData({ ...searchData, 'quarter': value, "mon": temp });
                    }
                    break;
                case "C":
                    if (temp.getFullYear() > lhs.getFullYear()) {
                        ma.push({ label: Moment(lhs).add(6, 'month').format('MMMM'), data: Moment(lhs).add(6, 'month').toDate(), status: true });
                        ma.push({ label: Moment(lhs).add(7, 'month').format('MMMM'), data: Moment(lhs).add(7, 'month').toDate(), status: true });
                        ma.push({ label: Moment(lhs).add(8, 'month').format('MMMM'), data: Moment(lhs).add(8, 'month').toDate(), status: true });
                        setSearchData({ ...searchData, 'quarter': value, "mon": Moment(lhs).add(6, 'month').toDate() });
                    } else {
                        if (temp.getMonth() == 9) {
                            ma.push({ label: Moment(temp).format('MMMM'), data: temp, status: true });
                            ma.push({ label: "November", data: '', status: false });
                            ma.push({ label: "December", data: '', status: false });
                        } else if (temp.getMonth() == 10) {
                            ma.push({ label: Moment(temp).subtract(1, 'month').format('MMMM'), data: Moment(temp).subtract(1, 'month').toDate(), status: true });
                            ma.push({ label: Moment(temp).format('MMMM'), data: temp, status: true });
                            ma.push({ label: "December", data: '', status: false });
                        } else {
                            ma.push({ label: Moment(temp).subtract(2, 'month').format('MMMM'), data: Moment(temp).subtract(2, 'month').toDate(), status: true });
                            ma.push({ label: Moment(temp).subtract(1, 'month').format('MMMM'), data: Moment(temp).subtract(1, 'month').toDate(), status: true });
                            ma.push({ label: Moment(temp).format('MMMM'), data: temp, status: true });
                        }
                        setSearchData({ ...searchData, 'quarter': value, "mon": temp });
                    }
                    break;
                case "D":
                    if (temp.getMonth() == 0) {
                        ma.push({ label: Moment(temp).format('MMMM'), data: temp, status: true });
                        ma.push({ label: "February", data: '', status: false });
                        ma.push({ label: "March", data: '', status: false });
                    } else if (temp.getMonth() == 1) {
                        ma.push({ label: Moment(temp).subtract(1, 'month').format('MMMM'), data: Moment(temp).subtract(1, 'month').toDate(), status: true });
                        ma.push({ label: Moment(temp).format('MMMM'), data: temp, status: true });
                        ma.push({ label: "March", data: '', status: false });
                    } else {
                        ma.push({ label: Moment(temp).subtract(2, 'month').format('MMMM'), data: Moment(temp).subtract(2, 'month').toDate(), status: true });
                        ma.push({ label: Moment(temp).subtract(1, 'month').format('MMMM'), data: Moment(temp).subtract(1, 'month').toDate(), status: true });
                        ma.push({ label: Moment(temp).format('MMMM'), data: temp, status: true });
                    }
                    setSearchData({ ...searchData, 'quarter': value, "mon": temp });
                    break;
            }
        } else {
            switch (value) {
                case "A":
                    if (temp.getMonth() == 3) {
                        ma.push({ label: Moment(temp).format('MMMM'), data: temp, status: true });
                        ma.push({ label: "May", data: '', status: false });
                        ma.push({ label: "June", data: '', status: false });
                    } else if (temp.getMonth() == 4) {
                        ma.push({ label: Moment(temp).subtract(1, 'month').format('MMMM'), data: Moment(temp).subtract(1, 'month').toDate(), status: true });
                        ma.push({ label: Moment(temp).format('MMMM'), data: temp, status: true });
                        ma.push({ label: "June", data: '', status: false });
                    } else {
                        ma.push({ label: Moment(temp).subtract(2, 'month').format('MMMM'), data: Moment(temp).subtract(2, 'month').toDate(), status: true });
                        ma.push({ label: Moment(temp).subtract(1, 'month').format('MMMM'), data: Moment(temp).subtract(1, 'month').toDate(), status: true });
                        ma.push({ label: Moment(temp).format('MMMM'), data: temp, status: true });
                    }
                    break;
                case "B":
                    if (temp.getMonth() == 6) {
                        ma.push({ label: Moment(temp).format('MMMM'), data: temp, status: true });
                        ma.push({ label: "August", data: '', status: false });
                        ma.push({ label: "September", data: '', status: false });
                    } else if (temp.getMonth() == 7) {
                        ma.push({ label: Moment(temp).subtract(1, 'month').format('MMMM'), data: Moment(temp).subtract(1, 'month').toDate(), status: true });
                        ma.push({ label: Moment(temp).format('MMMM'), data: temp, status: true });
                        ma.push({ label: "September", data: '', status: false });
                    } else {
                        ma.push({ label: Moment(temp).subtract(2, 'month').format('MMMM'), data: Moment(temp).subtract(2, 'month').toDate(), status: true });
                        ma.push({ label: Moment(temp).subtract(1, 'month').format('MMMM'), data: Moment(temp).subtract(1, 'month').toDate(), status: true });
                        ma.push({ label: Moment(temp).format('MMMM'), data: temp, status: true });
                    }
                    break;
                case "C":
                    if (temp.getMonth() == 9) {
                        ma.push({ label: Moment(temp).format('MMMM'), data: temp, status: true });
                        ma.push({ label: "November", data: '', status: false });
                        ma.push({ label: "December", data: '', status: false });
                    } else if (temp.getMonth() == 10) {
                        ma.push({ label: Moment(temp).subtract(1, 'month').format('MMMM'), data: Moment(temp).subtract(1, 'month').toDate(), status: true });
                        ma.push({ label: Moment(temp).format('MMMM'), data: temp, status: true });
                        ma.push({ label: "December", data: '', status: false });
                    } else {
                        ma.push({ label: Moment(temp).subtract(2, 'month').format('MMMM'), data: Moment(temp).subtract(2, 'month').toDate(), status: true });
                        ma.push({ label: Moment(temp).subtract(1, 'month').format('MMMM'), data: Moment(temp).subtract(1, 'month').toDate(), status: true });
                        ma.push({ label: Moment(temp).format('MMMM'), data: temp, status: true });
                    }
                    break;
                case "D":
                    if (temp.getMonth() == 0) {
                        ma.push({ label: Moment(temp).format('MMMM'), data: temp, status: true });
                        ma.push({ label: "February", data: '', status: false });
                        ma.push({ label: "March", data: '', status: false });
                    } else if (temp.getMonth() == 1) {
                        ma.push({ label: Moment(temp).subtract(1, 'month').format('MMMM'), data: Moment(temp).subtract(1, 'month').toDate(), status: true });
                        ma.push({ label: Moment(temp).format('MMMM'), data: temp, status: true });
                        ma.push({ label: "March", data: '', status: false });
                    } else {
                        ma.push({ label: Moment(temp).subtract(2, 'month').format('MMMM'), data: Moment(temp).subtract(2, 'month').toDate(), status: true });
                        ma.push({ label: Moment(temp).subtract(1, 'month').format('MMMM'), data: Moment(temp).subtract(1, 'month').toDate(), status: true });
                        ma.push({ label: Moment(temp).format('MMMM'), data: temp, status: true });
                    }
                    break;
            }
            setSearchData({ ...searchData, 'quarter': value, "mon": temp });
        }

        setMonths(ma);
        setLoading(false);
    }

    const onChnagehandel = (e) => {
        setSearch(false);
        switch (e.target.name) {
            case "year":
                setLoading(true);
                changeYear(e.target.value);
                break;
            case "quarter":
                setLoading(true);
                changeQuater(e.target.value);
                break;
            case "mon":
                setSearchData({ ...searchData, [e.target.name]: e.target.value });
                break;
        }
    }

    const calActiveQuter = (data) => {
        if (data.getMonth() >= 3 && data.getMonth() <= 5) {
            return 'A';
        }
        if (data.getMonth() >= 6 && data.getMonth() <= 8) {
            return 'B';
        }
        if (data.getMonth() >= 9 && data.getMonth() <= 11) {
            return 'C';
        }
        if (data.getMonth() >= 0 && data.getMonth() <= 2) {
            return 'D';
        }
    }

    const calcInit = (user) => {
        let ye = [];
        let qu = [];
        let ma = [];

        if (user !== {}) {
            let count = (user.GSTR1Num / 12).toString()[0];
            let data = Moment(user.createdAt).toDate();
            if (data.getMonth() > 3) {
                data.setMonth(3);
                data.setDate(1);
            } else {
                data = Moment(user.createdAt).subtract(1, 'year').toDate();
                data.setMonth(3);
                data.setDate(1);
            }

            for (let index = parseInt(count); index > 0; index--) {
                let offset = (index + 1);
                let partA = Moment(data).add(index, 'years').format("YYYY");
                let partB = Moment(data).add((index + 1), 'years').format("YY");
                let label = partA + "-" + partB;
                ye.push({ label: label, data: Moment(data).add(offset, 'years').toDate(), status: true });
            }

            let partA = Moment(data).format("YYYY");
            let partB = Moment(data).add(1, 'years').format("YY");
            let label = partA + "-" + partB;
            ye.push({ label: label, data: data, status: true });
        }

        // ye.push({ label: '2022-23', data: '', status: false });
        ye.push({ label: '2021-22', data: '', status: false });
        ye.push({ label: '2020-21', data: '', status: false });
        ye.push({ label: '2019-20', data: '', status: false });
        ye.push({ label: '2018-19', data: '', status: false });
        ye.push({ label: '2017-18', data: '', status: false });

        let temp = Moment(user.GSTReturn).toDate();

        if (ye[0].data.getTime() >= temp.getTime()) {
            qu.push({ label: 'Quarter 1 (Apr - Jun)', data: 'A', status: true });
            qu.push({ label: 'Quarter 2 (Jul - Sep)', data: 'B', status: false });
            qu.push({ label: 'Quarter 3 (Oct - Dec)', data: 'C', status: false });
            qu.push({ label: 'Quarter 4 (Jan - Mar)', data: 'D', status: false });
        } else {
            if (temp.getFullYear() > ye[0].data.getFullYear()) {
                qu.push({ label: 'Quarter 1 (Apr - Jun)', data: 'A', status: true });
                qu.push({ label: 'Quarter 2 (Jul - Sep)', data: 'B', status: true });
                qu.push({ label: 'Quarter 3 (Oct - Dec)', data: 'C', status: true });
                qu.push({ label: 'Quarter 4 (Jan - Mar)', data: 'D', status: temp.getMonth() >= 0 });
            } else {
                qu.push({ label: 'Quarter 1 (Apr - Jun)', data: 'A', status: temp.getMonth() >= 3 });
                qu.push({ label: 'Quarter 2 (Jul - Sep)', data: 'B', status: temp.getMonth() >= 6 });
                qu.push({ label: 'Quarter 3 (Oct - Dec)', data: 'C', status: temp.getMonth() >= 9 });
                qu.push({ label: 'Quarter 4 (Jan - Mar)', data: 'D', status: false });
            }
        }

        let AQ = calActiveQuter(temp);

        switch (AQ) {
            case "A":
                if (temp.getMonth() == 3) {
                    ma.push({ label: Moment(temp).format('MMMM'), data: temp, status: true });
                    ma.push({ label: "May", data: '', status: false });
                    ma.push({ label: "June", data: '', status: false });
                } else if (temp.getMonth() == 4) {
                    ma.push({ label: Moment(temp).subtract(1, 'month').format('MMMM'), data: Moment(temp).subtract(1, 'month').toDate(), status: true });
                    ma.push({ label: Moment(temp).format('MMMM'), data: temp, status: true });
                    ma.push({ label: "June", data: '', status: false });
                } else {
                    ma.push({ label: Moment(temp).subtract(2, 'month').format('MMMM'), data: Moment(temp).subtract(2, 'month').toDate(), status: true });
                    ma.push({ label: Moment(temp).subtract(1, 'month').format('MMMM'), data: Moment(temp).subtract(1, 'month').toDate(), status: true });
                    ma.push({ label: Moment(temp).format('MMMM'), data: temp, status: true });
                }
                break;
            case "B":
                if (temp.getMonth() == 6) {
                    ma.push({ label: Moment(temp).format('MMMM'), data: temp, status: true });
                    ma.push({ label: "August", data: '', status: false });
                    ma.push({ label: "September", data: '', status: false });
                } else if (temp.getMonth() == 7) {
                    ma.push({ label: Moment(temp).subtract(1, 'month').format('MMMM'), data: Moment(temp).subtract(1, 'month').toDate(), status: true });
                    ma.push({ label: Moment(temp).format('MMMM'), data: temp, status: true });
                    ma.push({ label: "September", data: '', status: false });
                } else {
                    ma.push({ label: Moment(temp).subtract(2, 'month').format('MMMM'), data: Moment(temp).subtract(2, 'month').toDate(), status: true });
                    ma.push({ label: Moment(temp).subtract(1, 'month').format('MMMM'), data: Moment(temp).subtract(1, 'month').toDate(), status: true });
                    ma.push({ label: Moment(temp).format('MMMM'), data: temp, status: true });
                }
                break;
            case "C":
                if (temp.getMonth() == 9) {
                    ma.push({ label: Moment(temp).format('MMMM'), data: temp, status: true });
                    ma.push({ label: "November", data: '', status: false });
                    ma.push({ label: "December", data: '', status: false });
                } else if (temp.getMonth() == 10) {
                    ma.push({ label: Moment(temp).subtract(1, 'month').format('MMMM'), data: Moment(temp).subtract(1, 'month').toDate(), status: true });
                    ma.push({ label: Moment(temp).format('MMMM'), data: temp, status: true });
                    ma.push({ label: "December", data: '', status: false });
                } else {
                    ma.push({ label: Moment(temp).subtract(2, 'month').format('MMMM'), data: Moment(temp).subtract(2, 'month').toDate(), status: true });
                    ma.push({ label: Moment(temp).subtract(1, 'month').format('MMMM'), data: Moment(temp).subtract(1, 'month').toDate(), status: true });
                    ma.push({ label: Moment(temp).format('MMMM'), data: temp, status: true });
                }
                break;
            case "D":
                if (temp.getMonth() == 0) {
                    ma.push({ label: Moment(temp).format('MMMM'), data: temp, status: true });
                    ma.push({ label: "February", data: '', status: false });
                    ma.push({ label: "March", data: '', status: false });
                } else if (temp.getMonth() == 1) {
                    ma.push({ label: Moment(temp).subtract(1, 'month').format('MMMM'), data: Moment(temp).subtract(1, 'month').toDate(), status: true });
                    ma.push({ label: Moment(temp).format('MMMM'), data: temp, status: true });
                    ma.push({ label: "March", data: '', status: false });
                } else {
                    ma.push({ label: Moment(temp).subtract(2, 'month').format('MMMM'), data: Moment(temp).subtract(2, 'month').toDate(), status: true });
                    ma.push({ label: Moment(temp).subtract(1, 'month').format('MMMM'), data: Moment(temp).subtract(1, 'month').toDate(), status: true });
                    ma.push({ label: Moment(temp).format('MMMM'), data: temp, status: true });
                }
                break;
        }

        setSearchData({ 'year': ye[0].data, 'quarter': AQ, 'mon': temp });
        setFYears(ye);
        setQuarters(qu);
        setMonths(ma);
        setLoading(false);
    }

    const genLog = () => {
        let body = {
            id: userAuth.id,
            name: userAuth.name,
            msg: "User preparing GSTR-1 file for the month of " + Moment(searchData.mon).format('MMMM - YYYY').toString(),
            role: userAuth.role,
        }
        logService.setLog(body).then((res) => {
            setLoading(true);
            gotoPath('gstrForm');
        });
    }

    const getGSTR3B = () => {
        if (gstrFile.status === "done") {
            let rhs = Moment(LMSUser.GSTRFilled).toDate();
            let lhs = Moment(searchData.mon).toDate();

            let compRhs = Math.floor(rhs.getTime() / 1000);
            let compLhs = Math.floor(lhs.getTime() / 1000);

            if (compLhs <= compRhs) {
                return false;
            } else {
                return true;
            }
        } else {
            return true;
        }
    }

    const gotoGSTR3B = () => {
        fillService.checkGstrDoc({ lid: userID, month: searchMon, state: GSTUser.state, tmonth: Moment(searchMon).format("MMMM") }).then((res) => {
            if (res.status) {
                setLoading(true);
                gotoPath('gstr3BForm');
            } else {
                setDigError({ title: 'Error', msg: 'Server error please try again!', isToggle: true });
            }
        });
    }

    const loadData = () => {
        setLoading(true);
        let body = {
            id: userID
        };
        lmsService.getLMSUserByID(body).then((res) => {
            if (res.status) {
                setLMSUser(res.data['A']);
                calcInit(res.data['A']);
            } else {
                setDigError({ title: 'Error', msg: 'Server error please try again!', isToggle: true });
            }
            window.scrollTo({
                top: 0,
                behavior: 'smooth',
            });
        });
    }

    const getNew3B = () => {
        if (gstrf !== null) {
            if (gstrf.status === "submitted" || gstrf.status === "done") {
                return false;
            } else {
                return true;
            }
        } else {
            return true;
        }
    }

    useEffect(() => {
        loadData();
    }, []);

    return (
        <div class="content-wrapper">
            <div class="dimmer-holder" style={{ display: 'none' }}>
                <div id="dimmer"></div>
            </div>
            <div class="container">
                <div class="mypage">

                    <div class="row" data-ng-controller="transctrl" data-ng-init="init('returns')">
                        <div class="col-xs-10">
                            <div name="Dashboard">
                                <ol class="breadcrumb">
                                    <li><a target="" data-ng-bind="name" onClick={() => { gotoPath('gstr') }}>Dashboard</a></li>
                                    <li><span ng-switch-when="true">Returns</span></li>
                                </ol>
                            </div>
                        </div>
                        <div class="col-xs-2">
                            <div class="lang dropdown">
                                <span class="dropdown-toggle" data-toggle="dropdown" data-ng-bind="selectedLang">English</span>
                                <ul class="dropdown-menu lang-dpdwn">
                                    <li data-ng-repeat="language in languages">
                                        <a>English</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    {
                        !loading && <div class="content-pane-returns">
                            <div class="row">
                                <div class="col-sm-12">
                                    <h4>File Returns</h4>
                                    <hr />
                                </div>
                            </div>
                            <marquee class="mark">
                                <span> Nil return for GSTR-1, GSTR-3B and CMP-08 can now be filed through SMS.</span>
                            </marquee>
                            <p class="mand-text">Indicates Mandatory Fields</p>
                            <form name="dashboard" novalidate="">
                                <div class="row">

                                    <div class="col-sm-3 col-xs-12">
                                        <label for="year" class="reg m-cir">Financial Year</label>
                                        <select name="year" class="form-control" value={searchData.year} onChange={onChnagehandel}>
                                            {
                                                fyears.map((ye) => {
                                                    return <option value={ye.data} disabled={!ye.status}>{ye.label}</option>
                                                })
                                            }
                                        </select>
                                    </div>

                                    <div class="col-sm-3 col-xs-12">
                                        <label for="quarter" class="reg m-cir" data-ng-bind="trans.LBL_QTR">Quarter</label>
                                        <select name="quarter" class="form-control" value={searchData.quarter} onChange={onChnagehandel} >
                                            {
                                                quarters.map((qu) => {
                                                    return <option value={qu.data} disabled={!qu.status}>{qu.label}</option>
                                                })
                                            }
                                        </select>
                                    </div>

                                    <div class="col-sm-3 col-xs-12">
                                        <label for="mon" class="reg m-cir" data-ng-bind="trans.LBL_PRD">Period</label>
                                        <select name="mon" class="form-control" value={searchData.mon} onChange={onChnagehandel}>
                                            {
                                                months.map((mo) => {
                                                    return <option value={mo.data} disabled={!mo.status}>{mo.label}</option>
                                                })
                                            }
                                        </select>
                                    </div>

                                    <div class="col-sm-3 col-xs-12">
                                        <button class="btn btn-primary srchbtn" type="button" onClick={() => {
                                            searchDate();
                                        }} style={{ margin: '24px 0 0 0' }}>Search</button>
                                    </div>

                                </div>
                                <div class="row">
                                    <div class="col-sm-12 col-xs-12">
                                        <p style={{ backgroundColor: 'beige', padding: '6px 12px' }}>Note: Return filing must be in sequential order of respective months in a Financial year.</p>
                                    </div>
                                </div>
                            </form>
                            <hr />
                            {!isSearch && <div class="row retpad"></div>}
                            <div class="row" name="generate" id="generate">
                            </div>
                        </div>
                    }

                    <div class="row">
                    </div>

                    <div class="row">
                        <div class="alert alert-msg alert-info alert-dismissible mar-t-5 mar-b-5 infoalign" data-ng-if="enableGstr3bQuarterly &amp;&amp; gstr_userType!='CA' &amp;&amp; gstr_userPref =='M' &amp;&amp; disableinfo">
                            <button type="button" class="close" data-ng-click="closefunc('info')">
                                <i class="fa fa-times"></i>
                            </button>
                            <i class="fa fa-info-circle"></i>&nbsp;
                            <span>You have selected to file the return on monthly frequency, GSTR-1 and GSTR-3B shall be required to be filed for each month of the quarter.</span>
                        </div>
                    </div>

                    {
                        isSearch && <div class="card text-center">

                            <div class="row">
                                <div class="col-sm-4 col-xs-12 col-md-4 col-lg-4">
                                    <div>
                                        <a>
                                            <div class="hd">
                                                <p class="inv">Details of outward supplies of goods or services</p>
                                                <p>GSTR1</p>
                                            </div>
                                        </a>
                                        {
                                            (gstrFile.status !== "done" && gstrFile.status !== "draft") && <div class="ct">
                                                <p class="f-wt"><span>Due Date - </span><span>{Moment(searchData.mon).add(10, 'days').add(1, 'month').format("DD/MM/YYYY")}</span></p>
                                                <div class="row">
                                                    <div class="col-lg-6">
                                                        <button type="button" class="btn btn-primary smallbutton" onClick={() => {
                                                            genLog();
                                                        }} style={{ width: '100%', padding: '6px 0px' }}>Prepare Online</button>
                                                    </div>
                                                    <div class="col-lg-6">
                                                        <button class="btn btn-primary smallbutton" style={{ width: '100%', padding: '6px 0px' }} disabled={true}>Prepare Offline</button>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        {
                                            gstrFile.status === "draft" && <div class="ct">
                                                <p class="f-wt">Status- <span>Ready to File</span></p>
                                                <div class="row">
                                                    <div class="col-lg-6">
                                                        <button type="button" class="btn btn-primary smallbutton" onClick={() => {
                                                            genLog();
                                                        }} style={{ width: '100%', padding: '6px 0px' }}>Prepare Online</button>
                                                    </div>
                                                    <div class="col-lg-6">
                                                        <button class="btn btn-primary smallbutton" style={{ width: '100%', padding: '6px 0px' }} disabled={true}>Prepare Offline</button>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        {
                                            gstrFile.status === "done" && <div class="ct">
                                                <p class="f-wt">Status- <span>Filed</span></p>
                                                <div class="row">
                                                    <div class="col-lg-6">
                                                        <button type="button" class="btn btn-primary smallbutton" style={{ padding: '5px 25px' }} onClick={() => {
                                                            setLoading(true);
                                                            gotoPath('gstrForm')
                                                        }}>VIEW</button>
                                                    </div>
                                                    <div class="col-lg-6">
                                                        <button class="btn btn-primary smallbutton" disabled={true}>Download</button>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>
                                <div class="col-sm-4 col-xs-12 col-md-4 col-lg-4">
                                    <div data-ng-if="x.status=='NF' &amp;&amp; !userPrefFlag">
                                        {/* <!-- <div data-ng-class="{'disableTile':x.return_ty ==='GSTR1'}"> </div> --> */}
                                        <a data-ng-click="page_rtp(x.return_ty,x.due_dt,x.status)">
                                            <div class="hd" data-ng-if="x.return_ty != 'GSTR3B' &amp;&amp; x.return_ty!='GSTR4A' &amp;&amp; !(x.return_ty=='CMP08' &amp;&amp; isCmp08) &amp;&amp; x.return_ty!='GSTR3V1'&amp;&amp; x.rtn_subtype == undefined">
                                                <p class="inv" data-ng-if="x.return_ty!='GSTR5A'">Auto Drafted details (For view only)</p>
                                                <p data-ng-if="x.return_ty!='GSTR1E' &amp;&amp; x.return_ty!='GSTR2X' &amp;&amp; x.return_ty!='GSTR2BQ'" data-ng-bind="x.return_ty">GSTR2A</p>
                                            </div>
                                        </a>
                                        <div class="ct" data-ng-if="x.return_ty!='GSTR4A' &amp;&amp; x.return_ty!='GSTR3V1'">
                                            <div class="row" data-ng-if="x.return_ty=='GSTR2A' || x.return_ty=='GSTR2B' || x.return_ty==='GSTR2BQ'">
                                                <p data-ng-init="x.return_ty=='GSTR2A' ? gstr2aFlag=x.return_ty: ''" style={{ display: 'none' }}></p>
                                                <div class="col-sm-6 col-xs-6">
                                                    <button class="btn btn-primary" data-ng-click="page_rtp(x.return_ty,x.due_dt,x.status)" data-toggle="tooltip" title="" data-ng-disabled="generate" disabled={true}>View</button>
                                                </div>
                                                <div class="col-sm-5 col-xs-6">
                                                    <button class="btn btn-primary pull-right" data-ng-click="offlinepath(x.return_ty)" disabled={true}>Download</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-4 col-xs-12 col-md-4 col-lg-4">
                                    <div>
                                        <a>
                                            <div class="hd">
                                                <p class="inv">Auto - drafted ITC Statement for the month</p>
                                                <p>GSTR2B</p>
                                            </div>
                                        </a>
                                        <div class="ct">
                                            <div class="row">
                                                <p style={{ display: 'none' }}></p>
                                                <div class="col-sm-6 col-xs-6">
                                                    <button class="btn btn-primary" onClick={() => {
                                                        setLoading(true);
                                                        gotoPath('gstr2BHome')
                                                    }}>View</button>
                                                </div>
                                                <div class="col-sm-5 col-xs-6">
                                                    <button class="btn btn-primary pull-right" data-ng-click="offlinepath(x.return_ty)" disabled={true}>Download</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div>
                                    <div class="col-sm-4 col-xs-12 col-md-4 col-lg-4">
                                        <div data-ng-if="x.status=='NF' &amp;&amp; !userPrefFlag">
                                            <a data-ng-click="page_rtp(x.return_ty,x.due_dt,x.status)">
                                                <div class="hd" data-ng-if="x.return_ty != 'GSTR3B' &amp;&amp; x.return_ty!='GSTR4A' &amp;&amp; !(x.return_ty=='CMP08' &amp;&amp; isCmp08) &amp;&amp; x.return_ty!='GSTR3V1'&amp;&amp; x.rtn_subtype == undefined">
                                                    <p class="inv" data-ng-if="x.return_ty!='GSTR5A'">Monthly Return</p>
                                                    <p data-ng-if="x.return_ty!='GSTR1E' &amp;&amp; x.return_ty!='GSTR2X' &amp;&amp; x.return_ty!='GSTR2BQ'" data-ng-bind="x.return_ty">GSTR3B</p>
                                                </div>
                                            </a>
                                            <div class="ct">
                                                {getNew3B() && <p class="f-wt"><span>Due Date - </span><span>{Moment(returnDate).add(getStateBasedNum(), 'days').add(1, 'month').format("DD/MM/YYYY")}</span></p>}
                                                {gstrf !== null && gstrf.status === "submitted" && <p class="f-wt">Status - <span>Submitted</span></p>}
                                                {gstrf !== null && gstrf.status === "done" && <p class="f-wt">Status - <span>Filed</span></p>}
                                                {
                                                    gstrf !== null && gstrf.status === "submitted" && <div class="row flex items-center justify-center">
                                                        <div class="col-lg-6">
                                                            <button type="button" class="btn btn-primary smallbutton" style={{ width: '100%', padding: '6px 0px' }} onClick={() => {
                                                                gotoGSTR3B();
                                                            }}>FILE GSTR-3B</button>
                                                        </div>
                                                    </div>
                                                }
                                                {
                                                    getNew3B() && <div class="row">
                                                        <div class="col-lg-6">
                                                            <button type="button" class="btn btn-primary smallbutton" style={{ width: '100%', padding: '6px 0px' }} disabled={getGSTR3B()} onClick={() => {
                                                                setSDigOpen(true);
                                                                gotoGSTR3B();
                                                            }}>Prepare Online</button>
                                                        </div>
                                                        <div class="col-lg-6">
                                                            <button class="btn btn-primary smallbutton" style={{ width: '100%', padding: '6px 0px' }} disabled={true}>Prepare Offline</button>
                                                        </div>
                                                    </div>
                                                }
                                                {
                                                    gstrf !== null && gstrf.status === "done" && <div class="row">
                                                        <div class="col-lg-6">
                                                            <button type="button" class="btn btn-primary smallbutton" style={{ width: '100%', padding: '6px 0px' }} onClick={() => {
                                                                gotoGSTR3B();
                                                            }}>VIEW GSTR-3B</button>
                                                        </div>
                                                        <div class="col-lg-6">
                                                            <button class="btn btn-primary smallbutton" style={{ width: '100%', padding: '6px 0px' }} disabled={true}>DOWNLOAD</button>
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    }

                </div>
            </div>
        </div >
    );
}
