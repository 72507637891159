import { atom, DefaultValue } from 'recoil';

const localStorageEffect = key => ({ setSelf, onSet }) => {
    const savedValue = localStorage.getItem(key)
    if (savedValue != null) {
        setSelf(JSON.parse(savedValue));
    }

    onSet(newValue => {
        if (newValue instanceof DefaultValue) {
            localStorage.removeItem(key);
        } else {
            localStorage.setItem(key, JSON.stringify(newValue));
        }
    });
};

export const refreshAtom = atom({
    key: "refreshAtom",
    default: false
});

export const loginAtom = atom({
    key: "loginAtom",
    default: false
});

export const userIDAtom = atom({
    key: "userIDAtom",
    default: 0
});

export const userAtom = atom({
    key: "userAtom",
    default: {}
});

export const lmsAtom = atom({
    key: "lmsAtom",
    default: false
});

export const adminAtom = atom({
    key: "adminAtom",
    default: false
});

export const adminPathAtom = atom({
    key: "adminPathAtom",
    default: { 'main': '', 'sub': '' }
});

export const navAtom = atom({
    key: "navAtom",
    default: 'home'
});

export const pathAtom = atom({
    key: "pathAtom",
    default: 'home'
});

export const gstrpathAtom = atom({
    key: "gstrpathAtom",
    default: 'b2b'
});

export const redirectAtom = atom({
    key: "redirectAtom",
    default: { url: '', isToggle: false }
});

export const trnAtom = atom({
    key: "trnAtom",
    default: ''
});

export const loadingAtom = atom({
    key: "loadingAtom",
    default: true
});

export const trnLevelAtom = atom({
    key: "trnLevelAtom",
    default: { level: "sa1", form: ['0', '0', '0', '0', '0', '0', '0', '0', '0', '0'], percentage: 0 }
});

export const digErrorAtom = atom({
    key: "digErrorAtom",
    default: { title: '', msg: '', isToggle: false }
});

export const digMainErrorAtom = atom({
    key: "digMainErrorAtom",
    default: { title: '', msg: '', isToggle: false }
});

export const docAtom = atom({
    key: "docAtom",
    default: { isFile: false, type: '', doc: null }
});

export const digStorageAtom = atom({
    key: "digStorageAtom",
    default: { title: '', msg: '', isToggle: false }
});

export const digDownloadAtom = atom({
    key: "digDownloadAtom",
    default: { title: '', url: '', isToggle: false }
});

export const dirtyAtom = atom({
    key: "dirtyAtom",
    default: false
});

export const bnameAtom = atom({
    key: "",
    default: false
});

export const returnDateAtom = atom({
    key: "returnDateAtom",
    default: null
});

export const gstrUserAtom = atom({
    key: "gstrUserAtom",
    default: {}
});

export const gstrQueryAtom = atom({
    key: "gstrQueryAtom",
    default: { type: '', data: '' }
});

export const gstrFileStatusAtom = atom({
    key: "gstrFileStatusAtom",
    default: false
});

export const gstrFileAtom = atom({
    key: "gstrFileAtom",
    default: {}
});

export const challanAtom = atom({
    key: "challanAtom",
    default: {}
});

export const dig3BAtom = atom({
    key: "dig3BAtom",
    default: false
});

export const itcSetOffAtom = atom({
    key: "itcSetOffAtom",
    default: {}
});

export const isBankAtom = atom({
    key: "isBankAtom",
    default: false
});