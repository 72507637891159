import React, { useState, useEffect, useRef } from 'react';

import { useRecoilState } from 'recoil';
import { pathAtom, loadingAtom, gstrUserAtom, returnDateAtom, gstrpathAtom, redirectAtom, userIDAtom, gstrFileAtom, digErrorAtom, dig3BAtom } from '../../core/config/atoms';

import Tippy from '@tippyjs/react';
import { Dialog } from '@headlessui/react';
import html2pdf from 'html2pdf.js/dist/html2pdf';

import Moment from 'moment';

import PanelSysGenPDF from './PanelSysGenPDF';
import PanelPreviewPDF from './PanelPreviewPDF';
import PanelFiledPDF from './PanelFiledPDF';

import ServiceGSTF from '../../services/serviceGSTF';
import ServiceGSTR from '../../services/serviceGSTR';
import ServiceGSTR2B from '../../services/serviceToB';

import 'tippy.js/dist/tippy.css';

export default function Panel3BHome() {

    const [GSTUser] = useRecoilState(gstrUserAtom);

    const [loading, setLoading] = useRecoilState(loadingAtom);
    const [gDate] = useRecoilState(returnDateAtom);
    const [userID] = useRecoilState(userIDAtom);
    const [gstrf, setGSTRF] = useRecoilState(gstrFileAtom);
    const [isSDigOpen, setSDigOpen] = useRecoilState(dig3BAtom);

    const [, setRedirect] = useRecoilState(redirectAtom);
    const [, setDigError] = useRecoilState(digErrorAtom);
    const [, setPath] = useRecoilState(pathAtom);
    const [, setGSTRPath] = useRecoilState(gstrpathAtom);

    const [challans, setChallans] = useState([]);

    const [b2b, setB2B] = useState([]);
    const [b2ba, setB2BA] = useState([]);
    const [b2bcdr, setB2BCDR] = useState([]);
    const [impg, setIMPG] = useState([]);

    const [mainData, setMainData] = useState({});

    const [setoff, setSetoff] = useState({
        cess: '0.00',
        igst: {
            igsta: '0.00',
            igstb: '0.00',
            igstc: '0.00',
        },
        cgst: {
            cgsta: '0.00',
            cgstb: '0.00',
        },
        sgst: {
            sgsta: '0.00',
            sgstb: '0.00',
        },
        bal: {
            bala: '0.00',
            balb: '0.00',
            balc: '0.00',
            bald: '0.00',
        }
    });

    const [digWarnning, setDigWarnning] = useState(false);

    const [isPrintA, setIsPrintA] = useState(false);
    const [isPrintB, setIsPrintB] = useState(false);
    const [isPrintC, setIsPrintC] = useState(false);
    const [isErr, setIsErr] = useState(false);

    const pdfRefA = useRef(null);
    const pdfRefB = useRef(null);
    const pdfRefC = useRef(null);

    const fileService = new ServiceGSTF();
    const returnService = new ServiceGSTR();
    const b2Service = new ServiceGSTR2B();

    const gotoURL = (url) => {
        setRedirect({ url: url, isToggle: true });
    }

    const gotoPath = (path) => {
        setPath(path);
    }

    const gotoGstrPath = (path) => {
        setGSTRPath(path);
        gotoPath('gstr3B');
    }

    const getStartY = () => {
        if (new Date(Moment(gDate).format('MM-DD-yyyy')).getMonth() < 3) {
            return Moment(gDate).subtract(1, 'year').format('YYYY');
        } else {
            return Moment(gDate).format('YYYY');
        }
    }

    const getEndY = () => {
        if (new Date(Moment(gDate).format('MM-DD-yyyy')).getMonth() < 3) {
            return Moment(gDate).format('YY');
        } else {
            return Moment(gDate).add(1, 'year').format('YY');
        }
    }

    const loadData = () => {
        (async function () {
            var res = await fileService.getGSTFDoc({ lid: userID, month: gDate });
            if (res.status) {
                setGSTRF(res.data);
            } else {
                setDigError({ title: 'Error', msg: 'Server error please try again!', isToggle: true });
            }

            let body = {
                'lid': userID,
                'month': gDate,
                'status': 'paid'
            };
            var chRes = await fileService.getChallanByFilter(body);
            setChallans(chRes.data);

            let resB = await fileService.getGSTLedger({ lid: userID, month: gDate });
            if (resB.status && resB.data) {
                setSetoff({ ...resB.data.data.setoff });
            }

            let bodyB = {
                'lid': userID,
                'month': gDate,
            }
            var resC = await returnService.getGstrSummary(bodyB);
            setMainData(resC.data.data);

            let b = { 'state': GSTUser.state, 'month': Moment(gDate).format("MMMM") }
            var resd = await b2Service.get2B(b);

            setB2B(resd.data.data.b2b);
            setB2BA(resd.data.data.b2ba);
            setB2BCDR(resd.data.data.b2bcdr);
            setIMPG(resd.data.data.impg);

            setLoading(false);
            window.scrollTo({
                top: 0,
                behavior: 'smooth',
            });
        })();
    }

    const getIGST = (type) => {
        let value = 0;
        switch (type) {
            case 'A3.1':
                value = parseFloat(gstrf.data.a3.user.a.igst.value) + parseFloat(gstrf.data.a3.user.b.igst.value) + parseFloat(gstrf.data.a3.user.d.igst.value);
                break;
            case 'A4':
                value = parseFloat(gstrf.data.a4.user.a.rowa.igst.value) + parseFloat(gstrf.data.a4.user.a.rowb.igst.value) + parseFloat(gstrf.data.a4.user.a.rowc.igst.value) + parseFloat(gstrf.data.a4.user.a.rowd.igst.value) + parseFloat(gstrf.data.a4.user.a.rowe.igst.value);
                value = value - (parseFloat(gstrf.data.a4.user.b.rowa.igst.value) + parseFloat(gstrf.data.a4.user.b.rowb.igst.value));
                break;
            case 'A5':
                value = parseFloat(gstrf.data.a5.user.a.igst.value) + parseFloat(gstrf.data.a5.user.b.igst.value);
                break;
            case 'A51':
                value = parseFloat(gstrf.data.a51.user.a.igst.value);
                break;
        }
        return value;
    }

    const getCGST = (type) => {
        let value = 0;
        switch (type) {
            case 'A3.1':
                value = parseFloat(gstrf.data.a3.user.a.cgst.value) + parseFloat(gstrf.data.a3.user.d.cgst.value);
                break;
            case 'A4':
                value = parseFloat(gstrf.data.a4.user.a.rowc.cgst.value) + parseFloat(gstrf.data.a4.user.a.rowd.cgst.value) + parseFloat(gstrf.data.a4.user.a.rowe.cgst.value);
                value = value - (parseFloat(gstrf.data.a4.user.b.rowa.cgst.value) + parseFloat(gstrf.data.a4.user.b.rowb.cgst.value));
                break;
            case 'A5':
                value = parseFloat(gstrf.data.a5.user.a.cgst.value) + parseFloat(gstrf.data.a5.user.b.cgst.value);
                break;
            case 'A51':
                value = parseFloat(gstrf.data.a51.user.a.cgst.value) + parseFloat(gstrf.data.a51.user.b.igst.value);
                break;
        }
        return value;
    }

    const getSGST = (type) => {
        let value = 0;
        switch (type) {
            case 'A3.1':
                value = parseFloat(gstrf.data.a3.user.a.sgst.value) + parseFloat(gstrf.data.a3.user.d.sgst.value);
                break;
            case 'A4':
                value = parseFloat(gstrf.data.a4.user.a.rowc.sgst.value) + parseFloat(gstrf.data.a4.user.a.rowd.sgst.value) + parseFloat(gstrf.data.a4.user.a.rowe.sgst.value);
                value = value - (parseFloat(gstrf.data.a4.user.b.rowa.sgst.value) + parseFloat(gstrf.data.a4.user.b.rowb.sgst.value));
                break;
            case 'A51':
                value = parseFloat(gstrf.data.a51.user.a.sgst.value) + parseFloat(gstrf.data.a51.user.b.cgst.value);
                break;
        }
        return value;
    }

    const getCess = (type) => {
        let value = 0;
        switch (type) {
            case 'A3.1':
                value = parseFloat(gstrf.data.a3.user.a.cess.value) + parseFloat(gstrf.data.a3.user.b.cess.value) + parseFloat(gstrf.data.a3.user.d.cess.value);
                break;
            case 'A4':
                value = parseFloat(gstrf.data.a4.user.a.rowa.cess.value) + parseFloat(gstrf.data.a4.user.a.rowb.cess.value) + parseFloat(gstrf.data.a4.user.a.rowc.cess.value) + parseFloat(gstrf.data.a4.user.a.rowd.cess.value) + parseFloat(gstrf.data.a4.user.a.rowe.cess.value);
                value = value - (parseFloat(gstrf.data.a4.user.b.rowa.cess.value) + parseFloat(gstrf.data.a4.user.b.rowb.cess.value));
                break;
            case 'A51':
                value = parseFloat(gstrf.data.a51.user.a.cess.value);
                break;
        }
        return value;
    }

    const checkIsChange = (obj) => {
        for (let key in obj) {
            if (typeof obj[key] === "object") {
                if (obj[key].hasOwnProperty("isChange") && obj[key].isChange === true) {
                    return true;
                } else if (checkIsChange(obj[key])) {
                    return true;
                }
            }
        }
        return false;
    }

    const cheackIsChangeList = (obj) => {
        for (let key in obj) {
            return obj[key].some((invoice) => invoice.isChange === true);
        }
        return false;
    }

    const saveGSTR3B = () => {
        if (get3Igst() > getIGST('A3.1')) {
            setIsErr(true);
            setLoading(false);
            window.scrollTo({
                top: 0,
                behavior: 'smooth',
            });
        } else {
            setIsErr(false);
            let b2 = {
                'igst': parseFloat(getIGST('A4')) > 0 ? parseFloat(getIGST('A4').toFixed(2)) : 0.00,
                'cgst': parseFloat(getCGST('A4')) > 0 ? parseFloat(getCGST('A4').toFixed(2)) : 0.00,
                'sgst': parseFloat(getSGST('A4')) > 0 ? parseFloat(getSGST('A4').toFixed(2)) : 0.00,
                'cess': parseFloat(getCess('A4')) > 0 ? parseFloat(getCess('A4').toFixed(2)) : 0.00
            };
            fileService.saveGSTFDoc({ id: gstrf._id, lid: userID, month: gDate, data: b2 }).then((res) => {
                if (res.status) {
                    setGSTRF(res.data);
                } else {
                    setDigError({ title: 'Error', msg: 'Server error please try again!', isToggle: true });
                }
                setLoading(false);
                window.scrollTo({
                    top: 0,
                    behavior: 'smooth',
                });
            });
        }
    }

    const getStatus = (sts) => {
        switch (sts) {
            case 'draft':
            case 'saved':
                return 'Not Filed';
            case 'submitted':
                return 'Submitted';
            case 'done':
                return 'Filed';
        }
    }

    const get3Total = () => {
        let total = 0;

        Object.keys(gstrf.data.a32).forEach((key) => {
            const list = gstrf.data.a32[key];
            list.forEach((item) => {
                total += parseFloat(item.total);
            });
        });

        return total.toFixed(2);
    }

    const get3Igst = () => {
        let igst = 0;

        Object.keys(gstrf.data.a32).forEach((key) => {
            const list = gstrf.data.a32[key];
            list.forEach((item) => {
                igst += parseFloat(item.igst);
            });
        });

        return igst.toFixed(2);
    }

    const handleGeneratePDFA = async () => {
        const subComponent = pdfRefA.current;
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
        const options = {
            margin: [18, 0.2, 18, 0.2],
            filename: `System Generated 3B - ${Moment(gDate).format("MMM")} Month.pdf`,
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: { scale: 2 },
            jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
            preserve: true,
        };
        html2pdf().set(options).from(subComponent).toPdf().get('pdf').then((pdf) => {
            var totalPages = pdf.internal.getNumberOfPages();
            console.log(pdf);
            for (let i = 1; i <= totalPages; i++) {
                pdf.setPage(i);
                pdf.setFontSize(10);
                pdf.setTextColor(0);
                pdf.text('System Generated FORM GSTR-3B', (pdf.internal.pageSize.getWidth() - 60), 8);
                pdf.setFontSize(8);
                pdf.text('For Information and Guidance Purposes Only', (pdf.internal.pageSize.getWidth() - 60), 11);
                pdf.setFontSize(12);
                pdf.text('[' + i + ']', (pdf.internal.pageSize.getWidth() / 2), (pdf.internal.pageSize.getHeight() - 10));
            }
        }).save().then(() => {
            setIsPrintA(false);
        });
    };

    const handleGeneratePDFB = async () => {
        const subComponent = pdfRefB.current;
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
        const options = {
            margin: 0.2,
            filename: `Preview Draft GSTR3B.pdf`,
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: { scale: 2 },
            jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
            preserve: true,
        };
        html2pdf().set(options).from(subComponent).save().then(() => {
            setIsPrintB(false);
        });
    };

    const handleGeneratePDFC = async () => {
        const subComponent = pdfRefC.current;
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
        const options = {
            margin: 0.2,
            filename: `Filed 3B - ${Moment(gDate).format("MMM")} Month.pdf`,
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: { scale: 2 },
            jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
            preserve: true,
        };
        html2pdf().set(options).from(subComponent).save().then(() => {
            setIsPrintC(false);
        });
    };

    const getTotal = (challan) => {
        let tax = challan.data.tax.igst + challan.data.tax.cgst + challan.data.tax.sgst + challan.data.tax.cess;
        let intr = challan.data.interest.igst + challan.data.interest.cgst + challan.data.interest.sgst + challan.data.interest.cess;
        let fees = challan.data.fees.cgst + challan.data.fees.sgst;
        return tax + intr + fees;
    }

    const getPaidByCash = () => {
        let val = 0;
        if (challans.length === 0) {
            return "0.00";
        } else {
            challans.map((challan) => {
                val = val + parseFloat(getTotal(challan));
            })
            return val.toFixed(2);
        }
    }

    const getPaidByCredit = () => {
        var igst = parseFloat(setoff.igst.igsta) + parseFloat(setoff.igst.igstb) + parseFloat(setoff.igst.igstc);
        var sgst = parseFloat(setoff.sgst.sgsta) + parseFloat(setoff.sgst.sgstb);
        var cgst = parseFloat(setoff.cgst.cgsta) + parseFloat(setoff.cgst.cgstb);
        var val = 0;
        val = val + parseFloat(setoff.cess) + igst + sgst + cgst;
        return val.toFixed(2);
    }

    useEffect(() => {
        loadData();
    }, []);

    return (
        <>
            <div className="content-wrapper fade-ng-cloak">
                <div className="dimmer-holder" style={{ display: 'none' }}>
                    <div id="dimmer"></div>
                </div>
                <div className="container">
                    <div className="mypage">
                        <div className="row">
                            <div className="col-xs-10">
                                <div name="Dashboard">
                                    <ol className="breadcrumb">
                                        <li onClick={() => { gotoPath('gstr') }}><a>Dashboard</a></li>
                                        <li onClick={() => { gotoPath('gstRetrun') }}>
                                            <span>
                                                <a>Returns</a>
                                            </span>
                                        </li>
                                        <li><span ng-switch-when="true">GSTR-3B</span></li>
                                    </ol>
                                </div>
                            </div>
                            <div className="col-xs-2">
                                <div className="lang dropdown">
                                    <span className="dropdown-toggle">English</span>
                                    <ul className="dropdown-menu lang-dpdwn">
                                        <li>
                                            <a>English</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div>
                            {!loading && <div className="myPage">

                                <div className="row">
                                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                        <h4 className="pull-left mar-t-0">GSTR-3B - Monthly Return</h4>
                                        <h4 className=" pull-right">
                                            <button className="btn btn-primary btn-circle btn-sm" style={{ marginTop: '0px' }}><i className="fa fa-refresh" aria-hidden="true"></i></button>
                                        </h4>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-sm-12 col-xs-12">
                                        <div className="panel panel-default ret">
                                            <div className="panel-body">
                                                <p></p>
                                                <div className="row">
                                                    <div className="col-sm-3">
                                                        <p className="reg">GSTIN -  {GSTUser.gstn}</p>
                                                    </div>
                                                    <div className="col-sm-3">
                                                        <p className="reg">Legal Name - {GSTUser.buzName.toUpperCase()}</p>
                                                    </div>
                                                    <div className="col-sm-3">
                                                        <p className="reg">Status - {getStatus(gstrf.status)}</p>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-sm-3">
                                                        <p className="reg">FY -  {getStartY()}-{getEndY()}</p>
                                                    </div>
                                                    <div className="col-sm-3">
                                                        <p className="reg">Return Period -  {Moment(gDate).format("MMMM")}</p>
                                                    </div>
                                                    <div className="col-sm-3">
                                                        <p className="reg" data-ng-if="showPdfLink == false">Due Date -  {Moment(gDate).add(1, 'month').add(19, 'days').format("DD/MM/yyyy")}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div>
                                    <div className="row">
                                        <div className="col-sm-14 col-xs-12">
                                            <div className="helpwrap">
                                                <div className="bar whiteside">
                                                    <h4 className="text-center">Help</h4>
                                                    <p>Please click on a box (tile) and enter relevant details therein. Save and click on the next box to
                                                        enter relevant details. Once you have filled up the information relating to a tile, you will
                                                        see gross (summary) figures on the tiles. You can view the preview by clicking on Preview button.
                                                        You can click on Back button to go to previous screen anytime. Data saved by you will not be
                                                        deleted.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <br />

                                    {
                                        isErr && <div className="alert alert-msg alert-danger ng-hide" data-ng-show="gstr3bErrorMsg">
                                            <button type="button" className="close" aria-hidden="true">
                                                <i class="fa fa-times"></i>
                                            </button>
                                            <div className='flex items-center m-0'>
                                                <i class="fa fa-warning"></i>
                                                &nbsp;<p className='m-0'>Total amount of Integrated Tax in section 3.2 should be less than or equal to the sum of Integrated tax declared in section 3.1(a) and 3.1.1(i) which is Rs. {getIGST('A3.1').toFixed(2)}</p>
                                            </div>
                                        </div>
                                    }

                                    <div className="alert alert-msg alert-success alert-dismissible accept">
                                        <button type="button" className="close" aria-hidden="true">
                                            <i className="fa fa-times"></i>
                                        </button>
                                        Last Save request has been processed successfully.
                                    </div>

                                    {
                                        checkIsChange(gstrf.data.a3.user) && <div className="alert alert-msg alert-danger ng-hide" data-ng-show="gstr3bErrorMsg">
                                            <button type="button" className="close" aria-hidden="true">
                                                <i class="fa fa-times"></i>
                                            </button>
                                            <div className='flex items-center m-0'>
                                                <i class="fa fa-warning"></i>
                                                &nbsp;<p className='m-0'>For Table 3.1 & 3.2 - The information entered by you is at variance with the auto-populated data. The liability has been computed on the basis of the information declared by you in your FROM GSTR-1.</p>
                                            </div>
                                        </div>
                                    }

                                    {
                                        checkIsChange(gstrf.data.a4.user) && <div className="alert alert-msg alert-danger ng-hide" data-ng-show="gstr3bErrorMsg">
                                            <button type="button" className="close" aria-hidden="true">
                                                <i class="fa fa-times"></i>
                                            </button>
                                            <div className='flex items-center m-0'>
                                                <i class="fa fa-warning"></i>
                                                &nbsp;<p className='m-0'>For table 4 - The input tax credit values have been auto-populated on the basis of the GSTR-2B generated for you. However, the values entered by you is at variance with the auto-populated values in table 4A. Please note that upward variance with the auto-populated input tax credit values is in contravention to Rule 36(4) of the CGST Rules, 2017.</p>
                                            </div>
                                            <br />
                                        </div>
                                    }
                                    <br />
                                </div>

                                <div className="row">
                                    <div className="col-sm-12 col-xs-12">
                                        <span className=" pull-left">
                                            <a onClick={() => {
                                                setSDigOpen(true);
                                            }}>Click here</a> for system generated summary status for GSTR-3B. </span>
                                        <span className="pull-right ret-t-info" style={{ fontSize: 'small' }}>
                                            <a onClick={() => {
                                                gotoURL("https://tutorial.gst.gov.in/userguide/returns/index.htm#t=Create_and_Submit_GSTR3B.htm");
                                            }}>Help Manual</a>
                                        </span>
                                    </div>
                                </div>

                                <div className="card">
                                    <div className="row">
                                        <div className="col-sm-4 col-xs-12" onClick={() => {
                                            setLoading(true);
                                            gotoGstrPath('A3.1');
                                        }}>
                                            <Tippy content="Details of Outward Supplies and inward supplies liable to reverse charge." allowHTML={true} placement='top' className='text-center'>
                                                <a>
                                                    <div className={`hd ${checkIsChange(gstrf.data.a3.user) ? 'card-error' : ''}`} >
                                                        <p className="inv">3.1 Tax on outward and reverse charge inward supplies
                                                        </p>
                                                    </div>
                                                    <div className="ct cardht">
                                                        <div className="row">
                                                            <div className="col-sm-6">
                                                                <p>Integrated Tax</p>
                                                                <p className="val">₹{getIGST('A3.1').toFixed(2)}</p>
                                                            </div>
                                                            <div className="col-sm-6">
                                                                <p>Central Tax</p>
                                                                <p className="val">₹{getCGST('A3.1').toFixed(2)}</p>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-sm-6">
                                                                <p>State/UT Tax</p>
                                                                <p className="val">₹{getSGST('A3.1').toFixed(2)}</p>
                                                            </div>
                                                            <div className="col-sm-6">
                                                                <p>CESS (₹)</p>
                                                                <p className="val">₹{getCess('A3.1').toFixed(2)}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </a>
                                            </Tippy>
                                        </div>

                                        <div className="col-sm-4 col-xs-12" onClick={() => {
                                            setLoading(true);
                                            gotoGstrPath('A3.11');
                                        }}>
                                            <Tippy content="Details of supplies notified under section 9(5) of the CGST Act, 2017 and corresponding provisions in IGST/UTGST/SGST Acts" allowHTML={true} placement='top' className='text-center'>
                                                <a>
                                                    <div className={`hd ${checkIsChange(gstrf.data.a31.user) ? 'card-error' : ''}`}>
                                                        <p className="inv">3.1.1 Supplies notified under section 9(5) of the CGST Act, 2017
                                                        </p>
                                                    </div>
                                                    <div className="ct cardht">
                                                        <div className="row">
                                                            <div className="col-sm-6">
                                                                <p>Integrated Tax</p>
                                                                <p className="val">₹{parseFloat(gstrf.data.a31.user.a.igst.value).toFixed(2)}</p>
                                                            </div>
                                                            <div className="col-sm-6">
                                                                <p>Central Tax</p>
                                                                <p className="val">₹{parseFloat(gstrf.data.a31.user.a.cgst.value).toFixed(2)}</p>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-sm-6">
                                                                <p>State/UT Tax</p>
                                                                <p className="val">₹{parseFloat(gstrf.data.a31.user.a.sgst.value).toFixed(2)}</p>
                                                            </div>
                                                            <div className="col-sm-6">
                                                                <p>CESS (₹)</p>
                                                                <p className="val">₹{parseFloat(gstrf.data.a31.user.a.cess.value).toFixed(2)}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </a>
                                            </Tippy>
                                        </div>

                                        <div className="col-sm-4 col-xs-12" onClick={() => {
                                            setLoading(true);
                                            gotoGstrPath('A3.2')
                                        }}>
                                            <Tippy content="Details of inter-State supplies made to unregistered persons, composition taxable persons and UIN holders" allowHTML={true} placement='top' className='text-center'>
                                                <a>
                                                    <div className={`hd ${cheackIsChangeList(gstrf.data.a32) ? 'card-error' : ''}`} >
                                                        <p className="inv">3.2 Inter-state supplies
                                                        </p>
                                                    </div>
                                                    <div className="ct cardht">
                                                        <div className="row">
                                                            <div className="col-sm-6">
                                                                <p>Taxable Value</p>
                                                                <p className="val">₹{get3Total()}</p>
                                                            </div>
                                                            <div className="col-sm-6">
                                                                <p>Integrated Tax</p>
                                                                <p className="val">₹{get3Igst()}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </a>
                                            </Tippy>
                                        </div>

                                        <div className="col-sm-4 col-xs-12" onClick={() => {
                                            setLoading(true);
                                            gotoGstrPath('A4')
                                        }}>
                                            <Tippy content="Eligible ITC" allowHTML={true} placement='top' className='text-center'>
                                                <a>
                                                    <div className={`hd ${checkIsChange(gstrf.data.a4.user) ? 'card-error' : ''}`}>
                                                        <p className="inv">4. Eligible ITC
                                                        </p>
                                                    </div>
                                                    <div className="ct cardht">
                                                        <div className="row">
                                                            <div className="col-sm-6">
                                                                <p>Integrated Tax</p>
                                                                <p className="val">₹{getIGST('A4').toFixed(2)}</p>
                                                            </div>
                                                            <div className="col-sm-6">
                                                                <p>Central Tax</p>
                                                                <p className="val">₹{getCGST('A4').toFixed(2)}</p>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-sm-6">
                                                                <p>State/UT Tax</p>
                                                                <p className="val">₹{getSGST('A4').toFixed(2)}</p>
                                                            </div>
                                                            <div className="col-sm-6">
                                                                <p>CESS (₹)</p>
                                                                <p className="val">₹{getCess('A4').toFixed(2)}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </a>
                                            </Tippy>
                                        </div>

                                        <div className="col-sm-4 col-xs-12" onClick={() => {
                                            setLoading(true);
                                            gotoGstrPath('A5');
                                        }}>
                                            <Tippy content="Values of exempt, nil-rated and non-GST inward supplies" allowHTML={true} placement='top' className='text-center'>
                                                <a>
                                                    <div className={`hd`}>
                                                        <p className="inv">5. Exempt, nil and Non GST inward supplies
                                                        </p>
                                                    </div>
                                                    <div className="ct cardht">
                                                        <div className="row">
                                                            <div className="col-sm-6">
                                                                <p>Inter-state supplies</p>
                                                                <p className="val">₹{getIGST('A5').toFixed(2)}</p>
                                                            </div>
                                                            <div className="col-sm-6">
                                                                <p>Intra-state supplies</p>
                                                                <p className="val">₹{getCGST('A5').toFixed(2)}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </a>
                                            </Tippy>
                                        </div>

                                        <div className="col-sm-4 col-xs-12" onClick={() => {
                                            setLoading(true);
                                            gotoGstrPath('A5.1')
                                        }}>
                                            <Tippy content="Interest &amp; late fee payable" allowHTML={true} placement='top' className='text-center'>
                                                <a>
                                                    <div className={`hd ${checkIsChange(gstrf.data.a51.user) ? 'card-error' : ''}`}>
                                                        <p className="inv" data-ng-if="validInterestRtnPrd">5.1 Interest and Late fee for previous tax period
                                                        </p>
                                                    </div>
                                                    <div className="ct cardht">
                                                        <div className="row">
                                                            <div className="col-sm-6">
                                                                <p>Integrated Tax</p>
                                                                <p className="val">₹{getIGST('A51').toFixed(2)}</p>
                                                            </div>
                                                            <div className="col-sm-6">
                                                                <p>Central Tax</p>
                                                                <p className="val">₹{getCGST('A51').toFixed(2)}</p>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-sm-6">
                                                                <p>State/UT Tax</p>
                                                                <p className="val">₹{getSGST('A51').toFixed(2)}</p>
                                                            </div>
                                                            <div className="col-sm-6">
                                                                <p>CESS (₹)</p>
                                                                <p className="val">₹{getCess('A51').toFixed(2)}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </a>
                                            </Tippy>
                                        </div>

                                        {
                                            gstrf.status === "done" && <div className="col-sm-4 col-xs-12" onClick={() => {
                                                setLoading(true);
                                                gotoGstrPath('A6.1');
                                            }}>
                                                <a>
                                                    <div className="hd">
                                                        <p className="inv">6.1 Payment of tax</p>
                                                    </div>
                                                    <div className="ct cardht">
                                                        <div className="row">
                                                            <div className="col-sm-6">
                                                                <p>Balance Liability</p>
                                                                <p className="val">₹0.00</p>
                                                            </div>
                                                            <div className="col-sm-6">
                                                                <p>Paid through Cash</p>
                                                                <p className="val">₹{getPaidByCash()}</p>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-sm-6">
                                                                <p>Paid through Credit</p>
                                                                <p className="val">₹{getPaidByCredit()}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>
                                        }

                                        <div className="col-sm-12 col-xs-12">
                                            <div className="panel panel-primary mt-2per" data-ng-if="!backflag">
                                                <div className="panel-heading text-center">Important Message</div>
                                                <div className="panel-body">Once you have filled the relevant tables, please follow the following steps for filing:-
                                                    <ul className='ml-16'>
                                                        <li>Please click on 'Save GSTR3B' on the summary page.</li>
                                                        <li>You may download and preview/save the draft GSTR-3B.</li>
                                                        <li>Click on 'Proceed to payment' to offset your liabilities.</li>
                                                        <li>In case of insufficient cash balance to set off the liabilities, challan creation facility
                                                            has been provided on the same screen.</li>
                                                        <li>After setting off liabilities, GSTR-3B can be filed by attaching EVC.</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                                <div className="row mar-b">
                                    {
                                        gstrf.status !== "done" && <div className="col-sm-12 text-right">
                                            <button className="btn btn-default" onClick={() => {
                                                setLoading(true);
                                                gotoPath('gstRetrun');
                                            }}>Back</button>&nbsp;
                                            <Tippy content="Click here to download GSTR-3B system generated PDF." allowHTML={true} placement='top' className='text-center'>
                                                <button type="button" className="btn btn-danger" onClick={(e) => {
                                                    setIsPrintA(true);
                                                    handleGeneratePDFA();
                                                }}>SYSTEM GENERATED GSTR-3B</button>
                                            </Tippy>&nbsp;
                                            <button type="button" className="btn btn-primary" onClick={() => {
                                                setLoading(true);
                                                saveGSTR3B();
                                            }} disabled={gstrf.status === "submitted"}>SAVE GSTR3B</button>&nbsp;
                                            <Tippy content="Please click here to download the Summary page of GSTR-3b for your review." allowHTML={true} placement='top' className='text-center'>
                                                <button type="button" className="btn btn-primary" onClick={(e) => {
                                                    setIsPrintB(true);
                                                    handleGeneratePDFB();
                                                }}>Preview draft GSTR-3B</button>
                                            </Tippy>&nbsp;
                                            <button type="button" className="btn btn-primary" title="Payment of Tax" disabled={gstrf.status === "draft"} onClick={() => {
                                                if (gstrf.status === "saved" || gstrf.status === "submitted") {
                                                    if (checkIsChange(gstrf.data.a4.user)) {
                                                        setDigWarnning(true);
                                                    } else {
                                                        setLoading(true);
                                                        gotoGstrPath('A6.1');
                                                    }
                                                }
                                            }}>Proceed to payment</button>
                                        </div>
                                    }
                                    {
                                        gstrf.status === "done" && <div className="col-sm-12 text-right">
                                            <button className="btn btn-default" onClick={() => {
                                                setLoading(true);
                                                gotoPath('gstRetrun');
                                            }} >Back</button>&nbsp;
                                            <button type="button" className="btn btn-primary" disabled="disabled">SAVE GSTR3B</button>&nbsp;
                                            <Tippy content="Please click here to download the Summary page of GSTR-3b for your review." allowHTML={true} placement='top' className='text-center'>
                                                <button type="button" className="btn btn-primary" onClick={() => {
                                                    setIsPrintC(true);
                                                    handleGeneratePDFC();
                                                }}>Download Filed GSTR-3B</button>
                                            </Tippy>&nbsp;
                                            <Tippy content="Click here to download GSTR-3B system generated PDF." allowHTML={true} placement='top' className='text-center'>
                                                <a className="btn btn-danger" onClick={() => {
                                                    setIsPrintA(true);
                                                    handleGeneratePDFA();
                                                }}>SYSTEM GENERATED GSTR-3B</a>
                                            </Tippy>
                                        </div>
                                    }
                                </div>
                            </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
            <Dialog open={isSDigOpen} onClose={() => setSDigOpen(false)} className='overflow-y-auto overflow-x-hidden z-50 w-full h-modal md:h-full fixed top-0 left-0 bg-black bg-opacity-40'>
                <div className="w-[850px]" style={{ position: 'fixed', margin: '0px 25%', top: '140px' }}>
                    <div className="modal-content w-[850px]" style={{ width: '850px !important;' }}>
                        <div className="modal-body">
                            <div>
                                <div className="row" style={{ marginLeft: 'auto' }}>
                                    <h4>System generated summary for GSTR-3B:</h4>
                                </div>
                                <div className="row">
                                    <div className="col-sm-12 col-xs-12">
                                        <div className="table-responsive">
                                            <table className="table tbl inv table-bordered">
                                                <tbody>
                                                    <tr>
                                                        <th className="text-center w-[180px]" width='180px'>
                                                            GSTR-3B Table
                                                        </th>
                                                        <th className="text-center w-[100px]">
                                                            Source Form
                                                        </th>
                                                        <th className="text-center w-[100px]">
                                                            Form status
                                                        </th>
                                                        <th className="text-center w-[100px]">
                                                            Summary status
                                                        </th>
                                                        <th className="text-center">
                                                            Advisory
                                                        </th>
                                                    </tr>
                                                    <tr>
                                                        <td className="text-center">
                                                            3.1(a, b, c, e), 3.2<br />
                                                            Liability
                                                        </td>
                                                        <td className="text-center">
                                                            GSTR-1
                                                        </td>
                                                        <td className="table-success bg-green-200 text-center status" style={{ border: '1px solid green !importent' }}>
                                                            Filed
                                                        </td>
                                                        <td className="table-success bg-green-200 text-center summary" style={{ border: '1px solid green !importent' }}>
                                                            Yes
                                                        </td>
                                                        <td rowspan="3" className="wid40">
                                                            System has generated summary of Table 3.1 (a, b, c, e) and Table 3.2 of FORM GSTR-3B based on your GSTR-1 filed by you for the current return period. System has generated summary of Table 3.1(d) and Table 4 of FORM GSTR-3B based on your GSTR-2B generated for the current return period. To view the system generated details, you may click on System generated PDF button available in the GSTR-3B dashboard page. You may continue to save or file your FORM GSTR-3B.
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="text-center">
                                                            3.1(d)<br />
                                                            Reverse Charge
                                                        </td>
                                                        <td className="text-center">
                                                            GSTR-2B
                                                        </td>
                                                        <td className="table-success bg-green-200 text-center status">
                                                            Generated
                                                        </td>
                                                        <td className="table-success bg-green-200 text-center summary">
                                                            Yes
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="text-center">
                                                            4A(1, 3, 4, 5), 4B(2)<br />
                                                            Input Tax Credit
                                                        </td>
                                                        <td className="text-center">
                                                            GSTR-2B
                                                        </td>
                                                        <td className="table-success bg-green-200 text-center status">
                                                            Generated
                                                        </td>
                                                        <td className="table-success bg-green-200 text-center summary">
                                                            Yes
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="pull-right" style={{ display: 'block', padding: '0%' }}>
                                            <button type="button" style={{ margin: '0px!important' }} className="btn btn-default" onClick={() => {
                                                setSDigOpen(false);
                                            }}>CLOSE</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Dialog>
            <Dialog open={digWarnning} onClose={() => setDigWarnning(false)} className='overflow-y-auto overflow-x-hidden z-50 w-full h-modal md:h-full fixed top-0 left-0 bg-black bg-opacity-40'>
                <div className='w-full h-full fixed z-50 flex items-center justify-center bg-black bg-opacity-60'>
                    <div id="confirmDlg" class="modal fade fade-scale in" role="dialog" style={{ display: 'block', paddingRight: '17px' }}>
                        <div class="modal-dialog sweet">
                            <div class="modal-content">
                                <div class="modal-body">
                                    <div class="m-icon m-warning pulseWarning">
                                        <span class="micon-body pulseWarningIns"></span>
                                        <span class="micon-dot pulseWarningIns"></span>
                                    </div>
                                    <h2>Warning</h2>
                                    <p>For table 4 - The input tax credit values have been auto-populated on the basis of the GSTR-2B generated for you. However, the values entered by you is at variance with the auto-populated values in the 4A. Please not that upward variance with the auto-populated input tax credit values is in contravention to Rule 36(4) of the CGST Rules, 2017. Kindly ensure that the values entered by you are correct before proceeding.</p>
                                </div>
                                <div class="modal-footer">
                                    <a class="btn btn-default" onClick={() => { setDigWarnning(false) }}>CANCEL</a>
                                    <a class="btn btn-primary" onClick={() => {
                                        setLoading(true);
                                        gotoGstrPath('A6.1');
                                    }}>PROCEED</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Dialog>
            <div ref={pdfRefA}>
                {isPrintA && <PanelSysGenPDF gstr={mainData} A={b2b} B={b2ba} C={b2bcdr} D={impg} />}
            </div>
            <div ref={pdfRefB}>
                {isPrintB && <PanelPreviewPDF setoff={setoff} />}
            </div>
            <div ref={pdfRefC}>
                {isPrintC && <PanelFiledPDF setoff={setoff} />}
            </div>
        </>
    );
}
