import React from 'react';

import { useRecoilState } from 'recoil';
import { pathAtom } from '../core/config/atoms';

export default function PanelLogout() {
    const [path, setPath] = useRecoilState(pathAtom);

    const gotoPath = (path) => {
        setPath(path);
    }

    return (
        <div class="content-wrapper">
            <div class="dimmer-holder">
                <div id="dimmer"></div>
            </div>
            <div class="container">
                <div class="row">
                    <div class="col-xs-12">
                        <ol class="breadcrumb">
                            <li><a onClick={() => {
                                gotoPath('home');
                            }}>Home</a></li>
                        </ol>
                    </div>
                </div>
                <div class="mypage">
                    <div class="content-pane" style={{ minHeight: '473.4px' }}>
                        <div class="row">
                            <div class="col-sm-offset-3 col-sm-6 col-xs-12">
                                <div class="ui-card pg-center">
                                    <div class="ui-card-icon">
                                        <p>
                                            <i class="fa fa-unlock-alt"></i>
                                        </p>
                                    </div>
                                    <div class="ui-card-text">
                                        <h4>You have successfully logged out of GST Portal.</h4>
                                        <p>Click <a onClick={() => {
                                            gotoPath('login');
                                        }}>here</a> to login again.
                                            You are requested to close this page for security reasons.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
