import React, { useEffect, useState } from 'react';

import { useRecoilState } from 'recoil';
import { pathAtom, loadingAtom, trnAtom, trnLevelAtom, dirtyAtom, docAtom, userIDAtom } from '../core/config/atoms';

import Moment from 'react-moment';
import { Wrapper } from "@googlemaps/react-wrapper";

import imgLoader from '../assets/images/cloading.gif';

import ServiceAuth from '../services/serviceAuth';
import ServiceUtility from '../services/serviceUtility';

import ComponentTRNLevelA from '../component/ComponentTRNLevelA';
import ComponentTRNLevelB from '../component/ComponentTRNLevelB';
import ComponentTRNLevelC from '../component/ComponentTRNLevelC';
import ComponentTRNLevelD from '../component/ComponentTRNLevelD';
import ComponentTRNLevelE from '../component/ComponentTRNLevelE';
import ComponentTRNLevelF from '../component/ComponentTRNLevelF';
import ComponentTRNLevelG from '../component/ComponentTRNLevelG';
import ComponentTRNLevelH from '../component/ComponentTRNLevelH';
import ComponentTRNLevelI from '../component/ComponentTRNLevelI';
import ComponentTRNLevelJ from '../component/ComponentTRNLevelJ';
import ComponentTRNLevelK from '../component/ComponentTRNLevelK';

export default function PanelTrnForms() {

    const [path, setPath] = useRecoilState(pathAtom);
    const [trnLevel, SetTRNLevel] = useRecoilState(trnLevelAtom);
    const [loading] = useRecoilState(loadingAtom);
    const [trnno] = useRecoilState(trnAtom);
    const [doc, setDoc] = useRecoilState(docAtom);
    const [isDirty, setIsDirty] = useRecoilState(dirtyAtom);
    const [userID] = useRecoilState(userIDAtom);

    const [gstUser, setGSTUser] = useState({});

    const authService = new ServiceAuth();
    const utilService = new ServiceUtility();

    const openTab = (tab) => {
        let ans = true;
        if (isDirty) {
            ans = window.confirm("Changes you made may not be saved.");
        }
        if (ans) {
            setIsDirty(false);
            setDoc({ isFile: false, type: '', doc: null });
            if (trnLevel.form[0] == '1') {
                SetTRNLevel({ ...trnLevel, level: tab });
            }
        }
    }

    const gotoBack = () => {
        setPath('trnDashboard');
        SetTRNLevel({ ...trnLevel, level: 'sa1' });
    }

    const loadData = () => {
        (async function () {
            var res = await authService.getGSTUserByTRN({ 'trn': trnno, 'stdOwn': userID });
            setGSTUser(res.data[0]);
            SetTRNLevel({ ...trnLevel, form: res.data[0]['statusArr'], percentage: utilService.calculatePercentage(res.data[0]['statusArr']) });
        })();
    }

    useEffect(() => {
        loadData();
    }, []);

    return (
        <div class="content-wrapper">
            {
                loading && <div className='absolute z-50 w-full h-screen flex items-center justify-center bg-gray-200 bg-opacity-25'>
                    <img src={imgLoader} alt="loader" />
                </div>
            }
            <div class="container">
                <div class="mypage">
                    <div class="row">
                        <div class="col-xs-10">
                            <div>
                                <ol class="breadcrumb">
                                    <li onClick={() => {
                                        gotoBack();
                                    }}><a>Dashboard</a></li>
                                    <li><span>{
                                        (() => {
                                            switch (trnLevel.level) {
                                                case 'sa1':
                                                    return "Business Details";
                                                case 'sa2':
                                                    return "Promoter / Partners";
                                                case 'sa3':
                                                    return "Authorized Signatory";
                                                case 'sa4':
                                                    return "Authorized Representative";
                                                case 'sa5':
                                                    return "Principal Place of Business";
                                                case 'sa6':
                                                    return "Additional Places of Business";
                                                case 'sa7':
                                                    return "Goods and Services";
                                                case 'sa8':
                                                    return "State Specific Information";
                                                case 'sa9':
                                                    return "Aadhaar Authentication";
                                                case 'sa10':
                                                    return "Verification";
                                                case 'sa11':
                                                    return "Application for New Registration";
                                            }
                                        })()}</span></li>
                                </ol>
                            </div>
                        </div>
                        <div class="col-xs-2">
                            <div class="lang dropdown">
                                <span class="dropdown-toggle">English</span>
                                <ul class="dropdown-menu lang-dpdwn">
                                    <li>
                                        <a>English</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="dimmer-holder" style={{ display: 'none' }}>
                        <div id="dimmer"></div>
                    </div>
                    <div ng-view="">
                        {
                            trnLevel.level !== 'sa11' && <>
                                <div class="marqueeclr text-red-600 bg-yellow-100 flex items-center" style={{ height: '26px', paddingLeft: '8px' }}>
                                    <i class="fa fa-bell "></i>
                                    <marquee width="95%"><b>Disclaimer:</b> This is a simulation of the GST portal used only for educational purpose. This simulation doesn't have any real implications on the GST portal. When re-directed to the actual GST portal,  the user will be responsible for any action taken and it's implications.</marquee>
                                </div>
                                <div class="row regapp">
                                    <div class="col-xs-12 col-sm-12">
                                        <div class="col-sm-3 col-xs-12 headapptype">
                                            <p class="lbl" data-ng-bind="trans.LBL_APLTY">Application Type</p>
                                            <div data-ng-if="!clrPayload">
                                                <p class="apptype" data-ng-if="rpayload.rgdtls.aplty=='APLRG'">New Registration</p>
                                            </div>
                                        </div>
                                        <div class="col-sm-3 col-xs-12 appdue">
                                            <p class="lbl" data-ng-bind="trans.LBL_DUE_DATE">Due Date to Complete</p>
                                            <p class="date" data-ng-if="!clrPayload" ><Moment format="DD/MM/YYYY">{gstUser['expiryAt']}</Moment></p>
                                        </div>
                                        <div class="col-sm-3 col-xs-12 appmodify">
                                            <p class="lbl" data-ng-bind="trans.LBL_LAST_MODIFIED">Last Modified</p>
                                            <p class="date" data-ng-if="!clrPayload"><Moment format="DD/MM/YYYY">{gstUser['updatedAt']}</Moment></p>
                                        </div>
                                        <div class="col-sm-3 col-xs-12 appperc">
                                            <p class="lbl" data-ng-bind="trans.LBL_PROFILE">Profile</p>
                                            <p class="date" data-ng-bind="pfilled+'%'">{trnLevel.percentage}%</p>
                                        </div>
                                    </div>
                                </div>

                                <ul class="nav nav-tabs responsive r1 reg-tabs" data-ng-class="{'adhr' : rpayload.bkacdtls}">
                                    <li class={`${trnLevel.level === 'sa1' ? 'active' : ''} ${trnLevel.form[0] === '1' ? 'complete' : 'incomplete'}`} onClick={() => { openTab('sa1'); }}>
                                        <a><span class="navicon business"></span>Business Details</a>
                                    </li>
                                    <li class={`${trnLevel.level === 'sa2' ? 'active' : ''} ${trnLevel.form[1] === '1' ? 'complete' : 'incomplete'}`} onClick={() => { openTab('sa2'); }}>
                                        <a><span class="navicon partners"></span>Promoter / Partners</a>
                                    </li>
                                    <li class={`${trnLevel.level === 'sa3' ? 'active' : ''} ${trnLevel.form[2] === '1' ? 'complete' : 'incomplete'}`} onClick={() => { openTab('sa3'); }}>
                                        <a><span class="navicon auth-sig"></span>Authorized Signatory</a>
                                    </li>
                                    <li class={`${trnLevel.level === 'sa4' ? 'active' : ''} ${trnLevel.form[3] === '1' ? 'complete' : 'incomplete'}`} onClick={() => { openTab('sa4'); }}>
                                        <a><span class="navicon auth-sig-rep"></span>Authorized Representative</a>
                                    </li>
                                    <li class={`${trnLevel.level === 'sa5' ? 'active' : ''} ${trnLevel.form[4] === '1' ? 'complete' : 'incomplete'}`} onClick={() => { openTab('sa5'); }}>
                                        <a><span class="navicon bplace"></span>Principal Place of Business</a>
                                    </li>
                                    <li class={`${trnLevel.level === 'sa6' ? 'active' : ''} ${trnLevel.form[5] === '1' ? 'complete' : 'incomplete'}`} onClick={() => { openTab('sa6'); }}>
                                        <a><span class="navicon abplace"></span>Additional Places of Business</a>
                                    </li>
                                    <li class={`${trnLevel.level === 'sa7' ? 'active' : ''} ${trnLevel.form[6] === '1' ? 'complete' : 'incomplete'}`} onClick={() => { openTab('sa7'); }}>
                                        <a><span class="navicon goods"></span>Goods and Services</a>
                                    </li>
                                    <li class={`${trnLevel.level === 'sa8' ? 'active' : ''} ${trnLevel.form[7] === '1' ? 'complete' : 'incomplete'}`} onClick={() => { openTab('sa8'); }}>
                                        <a><span class="navicon state"></span>State Specific Information</a>
                                    </li>
                                    <li class={`${trnLevel.level === 'sa9' ? 'active' : ''} ${trnLevel.form[8] === '1' ? 'complete' : 'incomplete'}`} onClick={() => { openTab('sa9'); }}>
                                        <a><span class="navicon fa fa-id-card fa-2x"></span>Aadhaar Authentication</a>
                                    </li>
                                    <li class={`${trnLevel.level === 'sa10' ? 'active' : ''} ${trnLevel.form[9] === '1' ? 'complete' : 'incomplete'}`} onClick={() => { openTab('sa10'); }}>
                                        <a><span class="navicon declaration"></span>Verification</a>
                                    </li>
                                </ul>
                            </>
                        }
                        <Wrapper apiKey={"AIzaSyDrhhlmFq9Z5kBsCxjNKqUqBfI-GeJ5ips"}>
                            {(() => {
                                switch (trnLevel.level) {
                                    case 'sa1':
                                        return (<ComponentTRNLevelA />);
                                    case 'sa2':
                                        return (<ComponentTRNLevelB />);
                                    case 'sa3':
                                        return (<ComponentTRNLevelC />);
                                    case 'sa4':
                                        return (<ComponentTRNLevelD />);
                                    case 'sa5':
                                        return (<ComponentTRNLevelE />);
                                    case 'sa6':
                                        return (<ComponentTRNLevelF />);
                                    case 'sa7':
                                        return (<ComponentTRNLevelG />);
                                    case 'sa8':
                                        return (<ComponentTRNLevelH />);
                                    case 'sa9':
                                        return (<ComponentTRNLevelI />);
                                    case 'sa10':
                                        return (<ComponentTRNLevelJ />);
                                    case 'sa11':
                                        return (<ComponentTRNLevelK />);
                                }
                            })()}
                        </Wrapper>
                    </div>
                </div>
            </div>
        </div >
    );
}
