import React, { useState, useEffect } from 'react';

import { useFormik } from 'formik';
import * as Yup from 'yup';

import { useRecoilState } from 'recoil';
import { userIDAtom, pathAtom, gstrpathAtom } from '../../../core/config/atoms';

import { motion, AnimatePresence } from "framer-motion";
import FeatherIcon from 'feather-icons-react';

import bankBg from '../../../assets/images/bankbg.png';
import bankLogo from '../../../assets/images/banklogo.png';
import bankCr from '../../../assets/images/bankcr.png';
import captchBg from '../../../assets/images/misc/captcha.png';

import ServiceUtility from '../../../services/serviceUtility';
import ServiceLMS from '../../../services/serviceLMS';

export default function PnaelBankLogin() {

    const [userID] = useRecoilState(userIDAtom);

    const [path, setPath] = useRecoilState(pathAtom);

    const [, setGSTRPath] = useRecoilState(gstrpathAtom);

    const [captch, setCaptch] = useState(0);

    const [isSubmit, setIsSubmit] = useState(false);
    const [isInvalid, setIsInvalid] = useState(false);
    const [captchError, setCaptchError] = useState(false);
    const [isAlert, setIsAlert] = useState(false);

    const utilService = new ServiceUtility();
    const lmsService = new ServiceLMS();

    const alertFade = {
        hidden: { opacity: 0 },
        show: { opacity: 1 }
    }

    const formSchema = Yup.object().shape({
        username: Yup.string().required('Please enter the mandatory fields'),
        password: Yup.string().required('Please enter the mandatory fields'),
        captcha: Yup.string().required('Please enter the mandatory fields').matches(/^[0-9]{6}$/, { message: 'Enter characters as displayed in the CAPTCHA image' }),
    });

    const gotoPath = (path) => {
        setPath(path);
    }

    const { values, errors, touched, dirty, handleChange, handleSubmit, handleReset, resetForm, setValues } = useFormik({
        enableReinitialize: true,
        initialValues: {
            username: '',
            password: '',
            captcha: '',
        },
        validationSchema: formSchema,
        onSubmit: values => {
            setIsInvalid(false);
            if (captch.toString() === values.captcha) {
                setIsSubmit(true);
                let body = {
                    "username": values.username,
                    "password": values.password,
                    "id": userID,
                }
                lmsService.checkLogin(body).then((res) => {
                    if (res.status) {
                        setGSTRPath('home');
                        gotoPath('gstrBank');
                    } else {
                        setIsInvalid(true);
                        setIsSubmit(false);
                        setIsAlert(true);
                        setTimeout(() => {
                            setIsAlert(false);
                        }, 3000);
                    }
                });
            } else {
                setCaptchError(true);
                setCaptch(utilService.getCode());
                setValues({ ...values, captcha: '' });
            }
        }
    });

    const loadData = () => {
        setCaptch(utilService.getCode());
    }

    useEffect(() => {
        loadData();
    }, []);

    return (
        <div className='w-full h-screen'>
            <AnimatePresence>
                <motion.div variants={alertFade}
                    initial="hidden"
                    animate={isAlert ? "show" : "hidden"}
                    transition={{ delay: 0.1 }}
                    className="rounded text-lg font-serif mt-10 mr-8 bg-yellow-100 shadow border border-red-700 h-14 flex flex-col justify-center" style={{ position: 'absolute', right: '20px', top: '20px' }}>
                    <p className="px-4 py-2 flex text-red-600 text-2xl m-0">
                        <FeatherIcon icon="bell" size={18} />&nbsp;&nbsp;
                        Invalid username or password.
                    </p>
                </motion.div>
            </AnimatePresence>
            <img src={bankBg} alt='Background Image' className='w-full h-screen absolute' />
            <div className='w-[480px] bg-white absolute p-8 rounded-lg realtive mt-[12%] ml-[14%]'>
                <img src={bankLogo} alt='Background Image' className='w-[320px] absolute top-[-40px] left-[20px]' />
                <p className='mt-8'>Login to</p>
                <h4 className='text-4xl'><b>Corporate Internet Banking</b></h4>
                <form noValidate onSubmit={handleSubmit}>
                    <div class="row">
                        <div class={`col-xs-12 ${touched.username ? errors.username ? 'has-error' : 'has-success' : ''}`}>
                            <label for="username" class="m-cir reg">Username</label>
                            <div class="has-feedback">
                                <input value={values.username} id="username" class="form-control pad-r-0" type="text" placeholder="Enter Username" name="username" required="true" autofocus autocomplete="off" onChange={handleChange} />
                                <span class="glyphicon glyphicon-ok form-control-feedback"></span>
                            </div>
                            {(errors.username && touched.username) && <p class="err m-0">{errors.username}</p>}
                        </div>
                    </div>
                    <div class="row">
                        <div class={`col-xs-12 ${touched.password ? errors.password ? 'has-error' : 'has-success' : ''}`}>
                            <label for="user_pass" class="m-cir reg">Password</label>
                            <div class="has-feedback">
                                <input value={values.password} id="password" class="form-control" type="password" name="password" maxLength={15} required="true" placeholder="Enter Password" autocomplete="off" onChange={handleChange} />
                                <span class="glyphicon glyphicon-ok form-control-feedback"></span>
                            </div>
                            {(errors.password && touched.password) && <p class="err m-0">{errors.password}</p>}
                        </div>
                    </div>
                    <div class="row">
                        <div class={`col-xs-12 ${touched.captcha ? errors.captcha ? 'has-error' : 'has-success' : ''}`}>
                            <label class="reg m-cir" for="captch">Type the characters you see in the image below</label>
                            <input value={values.captcha} class="form-control number" id="captcha" name="captcha" placeholder="Enter characters as displayed in the CAPTCHA image" minLength={6} maxLength={6} required="" onChange={(e) => {
                                e.target.value = utilService.formatOnlyNumber(e.target.value);
                                handleChange(e);
                            }} />
                            {(errors.captcha && touched.captcha) && <p class="err m-0">{errors.captcha}</p>}
                            <div class="row">
                                <div class="col-sm-12 col-xs-12">
                                    <div data-captcha="" data-captcha-object="captchaObj">
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <th rowspan="2" className='border border-black'>
                                                        <div className='relative'>
                                                            <p className='text-black font-black text-5xl absolute left-10 top-4'>{captch}</p>
                                                            <img className="captcha" src={captchBg} />
                                                        </div>
                                                    </th>
                                                    <th>
                                                        <button type="button" className='border border-gray-700 hover:bg-gray-500' style={{ backgroundColor: 'rgb(240, 240, 240)' }}>
                                                            <i class="fa fa-volume-up p-2"></i>
                                                        </button>
                                                    </th>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <button type="button" className='border border-gray-700 hover:bg-gray-500' style={{ backgroundColor: 'rgb(240, 240, 240)' }} onClick={() => {
                                                            setCaptch(utilService.getCode());
                                                        }}>
                                                            <i class="fa fa-refresh p-2"></i>
                                                        </button>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                            <button type="submit" class="btn btn-primary" disabled={isSubmit}>CONTINUE TO LOGIN</button>
                        </div>
                    </div>
                </form>
            </div>
            <div className='w-[720px] h-[480px] bg-white absolute rounded-lg mt-[12%] ml-[46%]'>
                <img src={bankCr} alt='Background Image' className='w-full absolute rounded-lg' />
            </div>
        </div>
    );
}
