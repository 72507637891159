import React, { useState, useEffect } from 'react';

import { useRecoilState } from 'recoil';
import { pathAtom, redirectAtom } from '../core/config/atoms';

import ServiceUtility from '../services/serviceUtility';

import captchBg from '../assets/images/misc/captcha.png';

export default function PanelRegisterB() {
    // const [dig, setDig] = useRecoilState(digAtom);
    const [path, setPath] = useRecoilState(pathAtom);
    const [redirect, setRedirect] = useRecoilState(redirectAtom);

    const [captch, setCaptch] = useState(0);

    const [isOTP, setIsOTP] = useState(false);
    const [isSelected, setIsSelected] = useState(true);

    const utilService = new ServiceUtility();

    const gotoPath = (path) => {
        setPath(path);
    }

    const gotoURL = (url) => {
        setRedirect({ url: url, isToggle: true });
    }

    useEffect(() => {
        setCaptch(utilService.getCode());
    }, []);

    return (
        <div class="content-wrapper">
            <div class="dimmer-holder" style={{ display: 'none' }}>
                <div id="dimmer"></div>
            </div>
            <div class="container">
                <div class="mypage">
                    <div class="row">
                        <div class="col-xs-10">
                            <div name="Home">
                                <ol class="breadcrumb">
                                    <li>
                                        <a onClick={() => {
                                            gotoPath('home');
                                        }}>Home</a>
                                    </li>
                                    <li>
                                        <span ng-switch-when="true">Registration</span>
                                    </li>
                                </ol>
                            </div>
                        </div>
                        <div class="col-xs-2">
                            <div class="lang dropdown">
                                <span class="dropdown-toggle">English</span>
                                <ul class="dropdown-menu lang-dpdwn">
                                    <li data-ng-repeat="language in languages">
                                        <a>English</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="content-pane" style={{ minHeight: '473.4px' }}>
                        <div>
                            <form class="" autoComplete="off" noValidate="" >
                                <div class="row">
                                    <div class="col-lg-offset-5 col-lg-4 col-md-offset-3 col-md-6 col-sm-offset-2 col-sm-8 col-xs-12">
                                        <div class="contain">
                                            <ul class="progressbar">
                                                <li class={`z1 step-on`}>
                                                    <p>
                                                        <span class="circle on-page">1</span>
                                                    </p>
                                                    <span>User Credentials</span>
                                                </li>
                                                <li class={`z2`}>
                                                    <p>
                                                        <span class="circle not-active">2</span>
                                                    </p>
                                                    <span>OTP Verification</span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-offset-2 col-md-offset-3 col-md-6 col-sm-8 col-xs-12">
                                        <p></p>
                                        <h4>New Registration</h4>
                                        <hr />
                                        {
                                            !isOTP && <div class="row">
                                                <div class="col-xs-12 flex">
                                                    <div checked={isSelected} onClick={() => {
                                                        setIsOTP(false);
                                                        setIsSelected(true);
                                                    }}>
                                                        <input type="radio" name="typ" id="radionew" checked={isSelected} />
                                                        <label For="radionew">New Registration</label>
                                                    </div>
                                                    <div onClick={() => {
                                                        setIsOTP(false);
                                                        setIsSelected(false);
                                                    }}>
                                                        <input type="radio" name="typ" id="radiotrn" checked={!isSelected} />
                                                        <label For="radiotrn" data-ng-bind="trans.LBL_TRN">Temporary Reference Number (TRN)</label>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        {
                                            (isSelected && !isOTP) && <fieldset disabled={true}>
                                                <div className="row">
                                                    <div className="col-xs-12">
                                                        <label className="reg m-cir" for="applnType">I am a</label>
                                                        <select className={`form-control`} name="applnType" autoFocus="" required="">
                                                            <option value="">Select</option>
                                                            <option value="TAX" className='text-black'>Taxpayer</option>
                                                            <option value="" disabled>Tax Deductor</option>
                                                            <option value="" disabled>Tax Collector (e-Commerce)</option>
                                                            <option value="" disabled>GST Practitioner</option>
                                                            <option value="" disabled>Non Resident Taxable Person</option>
                                                            <option value="" disabled>United Nation Body </option>
                                                            <option value="" disabled>Consulate or Embassy of Foreign Country </option>
                                                            <option value="" disabled>Other Notified Person</option>
                                                            <option value="" disabled>Non-Resident Online Services Provider</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-xs-12">
                                                        <label for="applnState" className="reg m-cir">State / UT</label>
                                                        <select className={`form-control`} id="state" name="state" autoFocus="" required="required">
                                                            <option className="">Select</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-xs-12">
                                                        <label class="reg" for="applnDistr">District</label>
                                                        <select class={`form-control`} id="district" name="district" autoFocus="" required="required">
                                                            <option value="">Select</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-xs-12">
                                                        <label for="bnm" class="reg m-cir">
                                                            <span>Legal Name of the Business</span>
                                                            <span class="help">(As mentioned in PAN)</span>
                                                        </label>
                                                        <input type="text" id="bname" name="bname" autoComplete='off' placeholder="Enter Legal Name of Business" class="form-control       " pattern="/^[a-zA-Z0-9\_&amp;'\-\.\/\,\}\]\[&quot;&quot;()?@!#%$~*;+= ]{1,99}$/" maxlength="99" autoFocus="" required="" />
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-xs-12">
                                                        <label class="reg m-cir" for="pan_card">Permanent Account Number (PAN)</label>
                                                        <div class="has-feedback">
                                                            <input class={`form-control uppercase`} id="panCard" autoComplete='off' name="panCard" placeholder="Enter Permanent Account Number (PAN)" type="text" maxlength="10" pattern="^[a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}$" autoCapitalize={true} required="" />
                                                        </div>
                                                        <span id="helpBlock" class="help-block">
                                                            <i class="fa fa-info-circle"></i>
                                                            <span> If you don't have PAN, Click</span>
                                                            <a class="link" tabIndex="-1" onClick={() => { gotoURL('https://tin.tin.nsdl.com/pan/') }}>
                                                                <span> here </span>
                                                            </a>
                                                            <span>to apply</span>
                                                        </span>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-xs-12">
                                                        <label class="reg m-cir" for="email">
                                                            <span>Email Address</span>
                                                        </label>
                                                        <div class="input-group">
                                                            <span class="input-group-addon" id="ba2"><i class="fa fa-envelope"></i></span>
                                                            <input id="email" class={`form-control`} type="text" autoComplete='off' placeholder="Enter Email Address" name="email" maxLength="254" pattern="^[a-zA-Z0-9!#$%&amp;'*+\/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&amp;'*+\/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?$" required="" />
                                                        </div>
                                                        <span class="help-block"><i class="fa fa-info-circle"></i><span> OTP will be sent to this Email Address</span></span>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-xs-12">
                                                        <label for="mobile" class="reg m-cir">
                                                            <span>Mobile Number</span>
                                                        </label>
                                                        <div class="input-group">
                                                            <span class="input-group-addon" id="ba">+91</span>
                                                            <input id="mobile" name="mobile" type="text" class={`number form-control`} placeholder="Enter Mobile Number" autoComplete='off' aria-describedby="ba" maxLength="10" pattern="^[0-9]{10}$" minLength="10" required="required" />
                                                        </div>
                                                        <span class="help-block"><i class="fa fa-info-circle"></i> <span>Separate OTP will be sent to this mobile number</span></span>
                                                    </div>
                                                </div>
                                            </fieldset>
                                        }
                                        {
                                            (!isSelected && !isOTP) && <fieldset disabled={true}>
                                                <div class="row">
                                                    <div class="col-xs-12">
                                                        <label class="reg m-cir" for="trnno">Temporary Reference Number (TRN)</label>
                                                        <input class={`form-control`} id="trnno" name="trnno" placeholder="Enter Temporary Reference Number (TRN)" type="text" maxLength={15} pattern="[0-9]{12}(T|t)(R|r)(N|n)$" required="" />
                                                    </div>
                                                    <div class="col-xs-12">
                                                        <label class="reg m-cir" for="captch">Type the characters you see in the image below</label>
                                                        <input class={`form-control number`} id="captch" name="captch" placeholder="Enter characters as displayed in the CAPTCHA image" pattern="^([0-9]){6}$" minLength={6} maxLength={6} required="" />
                                                        <div class="row">
                                                            <div class="col-sm-12 col-xs-12">
                                                                <div data-captcha="" data-captcha-object="captchaObj">
                                                                    <table>
                                                                        <tbody>
                                                                            <tr>
                                                                                <th rowspan="2" className='border border-black'>
                                                                                    <div className='relative'>
                                                                                        <p className='text-black font-black text-5xl absolute left-10 top-4'>{captch}</p>
                                                                                        <img className="captcha" src={captchBg} />
                                                                                    </div>
                                                                                </th>
                                                                                <th>
                                                                                    <button type="button" className='border border-gray-700 hover:bg-gray-500' style={{ backgroundColor: 'rgb(240, 240, 240)' }}>
                                                                                        <i class="fa fa-volume-up p-2"></i>
                                                                                    </button>
                                                                                </th>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>
                                                                                    <button type="button" className='border border-gray-700 hover:bg-gray-500' style={{ backgroundColor: 'rgb(240, 240, 240)' }}>
                                                                                        <i class="fa fa-refresh p-2"></i>
                                                                                    </button>
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                    <p class="err ng-hide" ng-show="captchaErr" data-ng-bind="captchaErr">
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </fieldset>
                                        }
                                        <p class="mand-text">indicates mandatory fields</p>
                                        <div class="row">
                                            <div class="col-xs-12 flex items-center justify-end">
                                                <button type='reset' class="text-gray-900 border border-black hover:text-black" style={{ width: '140px', height: '40px', marginRight: '20px', display: isOTP ? '' : 'none' }}>Back</button>
                                                <button type='submit' class="btn btn-block btn-primary" style={{ width: isOTP ? '140px' : '', height: '40px' }} disabled={true}>Proceed</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                            <div class="row">
                                <div class="col-xs-12">
                                    <div class="alert alert-warning">
                                        <i class="fa fa-info-circle"></i>
                                        <strong>&nbsp;This Process cannot be covered in this simulation. Kindly continue the process.</strong>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </div >
    );
}
