import React, { useState, useEffect } from 'react';

import { useRecoilState } from 'recoil';
import { pathAtom, loadingAtom, gstrFileAtom, digErrorAtom } from '../../../core/config/atoms';

import { useFormik } from 'formik';
import * as Yup from 'yup';
import Scrollbars from 'react-custom-scrollbars';

import ServiceGSTF from '../../../services/serviceGSTF';

export default function Panel5Home() {

    const [loading, setLoading] = useRecoilState(loadingAtom);
    const [gstrf, setGSTRF] = useRecoilState(gstrFileAtom);

    const [, setPath] = useRecoilState(pathAtom);
    const [, setDigError] = useRecoilState(digErrorAtom);

    const [submit, setSubmit] = useState(false);

    const [isHelp, setIsHelp] = useState(false);

    const fileService = new ServiceGSTF();

    const formVSchema = Yup.object().shape({
        a: Yup.object().shape({
            igst: Yup.object().shape({
                value: Yup.string().required(),
                isChange: Yup.boolean().required(),
            }).required(),
            cgst: Yup.object().shape({
                value: Yup.string().required(),
                isChange: Yup.boolean().required(),
            }).required(),
        }).required(),
        b: Yup.object().shape({
            igst: Yup.object().shape({
                value: Yup.string().required(),
                isChange: Yup.boolean().required(),
            }).required(),
            cgst: Yup.object().shape({
                value: Yup.string().required(),
                isChange: Yup.boolean().required(),
            }).required(),
        }).required(),
    });

    const { values, errors, touched, setValues, handleChange, handleSubmit, handleReset, resetForm } = useFormik({
        initialValues: {},
        validationSchema: formVSchema,
        onSubmit: values => {
            setLoading(true);
            setSubmit(true);
            var newObj = JSON.parse(JSON.stringify(gstrf.data));
            newObj.a5.user = { ...values };
            var body = {
                id: gstrf._id,
                data: newObj
            };
            fileService.setGSTFDoc(body).then((res) => {
                setLoading(false);
                setSubmit(false);
                if (res.status) {
                    setGSTRF(res.data);
                    gotoPath('gstr3BForm');
                } else {
                    setDigError({ title: 'Error', msg: 'Server error please try again!', isToggle: true });
                    window.scrollTo({
                        top: 0,
                        behavior: 'smooth',
                    });
                }
            });
        }
    });

    const getFormatvalue = (val) => {
        if (!val) return val;
        let number = val.replace(/[^\d.]/g, "");
        if (val.includes(".")) {
            const a = number.split(".");
            if (a[1].length > 2) {
                a[1] = a[1].substring(0, 2);
            }
            if (a[0].length > 13) {
                a[0] = a[0].substring(0, 13);
            }
            number = a[0] + "." + a[1];
        } else {
            if (number.length > 13) {
                number = number.substring(0, 13);
            }
        }
        return number;
    }

    const getErrorCustom = (field) => {
        let cls = '';
        switch (field) {
            case 'a.igst':
                if (values.a.igst.isChange) {
                    cls = 'has-error';
                }
                break;
            case 'a.cgst':
                if (values.a.cgst.isChange) {
                    cls = 'has-error';
                }
                break;
            case 'b.igst':
                if (values.b.igst.isChange) {
                    cls = 'has-error';
                }
                break;
            case 'b.cgst':
                if (values.b.cgst.isChange) {
                    cls = 'has-error';
                }
                break;
        }
        return cls;
    }

    const gotoPath = (path) => {
        setPath(path);
    }

    function convertValuesToFixedDecimal(obj) {
        const newObj = {};

        for (let key in obj) {
            const value = obj[key];

            if (typeof value === 'object' && value !== null) {
                newObj[key] = convertValuesToFixedDecimal(value);
            } else if (typeof value === 'number') {
                newObj[key] = value.toFixed(2);
            } else {
                newObj[key] = value;
            }
        }

        return newObj;
    }

    const loadData = () => {
        var refs = convertValuesToFixedDecimal(gstrf.data.a5.user);
        setValues({ ...values, ...refs });
        setLoading(false);
        if (gstrf.status === "done" || gstrf.status === "submitted") {
            setSubmit(true);
        }
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    }

    useEffect(() => {
        loadData();
    }, []);

    return (
        <>
            {
                isHelp && <div className='w-full h-full overflow-hidden fixed top-0 z-50 flex items-center justify-center bg-black bg-opacity-60'>
                    <Scrollbars>
                        <div className="modal-dialog" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => { setIsHelp(false) }}>
                                        <span aria-hidden="true">×</span>
                                    </button>
                                    <h4 className='text-[#0b1e59d9] modal-title'>Exempt, nil and Non GST inward supplies</h4>
                                </div>
                                <div className="modal-body">
                                    <p>Declare the values of inward supplies with respect to the following, in this section:</p>
                                    <ol className='pl-16'>
                                        <li className="list-item">From suppliers under composition scheme, Supplies exempt from tax and Nil rated supplies.</li>
                                        <li className="list-item">Supplies which are not covered under GST Act.</li>
                                    </ol>
                                    <p>The above values have to be declared separately for Intra-State and Inter-State supplies.</p>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={() => { setIsHelp(false) }}>Close</button>
                                </div>
                            </div>
                        </div>
                    </Scrollbars>
                </div>
            }
            <div className="content-wrapper fade-ng-cloak">
                <div className="container">
                    <div className="mypage">
                        <div className="row">
                            <div className="col-xs-10">
                                <div>
                                    <ol className="breadcrumb" data-ng-controller="crumbCtrl">
                                        <li>
                                            <a onClick={() => { gotoPath('gstr') }}>Dashboard</a></li>
                                        <li>
                                            <a onClick={() => { gotoPath('gstRetrun') }}>Returns</a>
                                        </li>
                                        <li>
                                            <a onClick={() => {
                                                setLoading(true);
                                                gotoPath('gstr3BForm')
                                            }}>GSTR-3B</a>
                                        </li>
                                        <li>
                                            <span ng-switch-when="true">Inward Supplies</span>
                                        </li>
                                    </ol>
                                </div>
                            </div>
                            <div className="col-xs-2">
                                <div className="lang dropdown">
                                    <span className="dropdown-toggle" data-toggle="dropdown" data-ng-bind="selectedLang">English</span>
                                    <ul className="dropdown-menu lang-dpdwn">
                                        <li>
                                            <a data-ng-bind="language.nm">English</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        {
                            !loading && <div>
                                <form autocomplete="off" novalidate="" onSubmit={handleSubmit} onReset={handleReset}>
                                    <div className="container" data-ng-init="getInwardSupData()">
                                        <div className="row invsumm">
                                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                <div className="pull-left">
                                                    <h4 data-ng-bind="trans.HEAD_ISUP_DETAILS">5. Values of exempt, nil-rated and non-GST inward supplies</h4>
                                                </div>
                                                <div className="pull-right">
                                                    <h4><a data-toggle="modal" onClick={() => { setIsHelp(true) }}>Help</a>
                                                        &nbsp;<i className="fa fa-question-circle" aria-hidden="true"></i>
                                                    </h4>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="tabpane">
                                            <div ng-show="statusMsg &amp;&amp; statusMessage" className="alert alert-msg alert-success alert-dismissible accept">
                                                <button type="button" className="close" data-ng-click="hideAlert()" aria-hidden="true">
                                                    <i className="fa fa-times"></i>
                                                </button>
                                                Last Save request has been processed successfully.
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                    <div className="rettbl-format">
                                                        <div className="table-responsive">
                                                            <table className="table tbl inv exp table-bordered" style={{ display: 'table' }}>
                                                                <thead>
                                                                    <tr>
                                                                        <th className="text-center" data-ng-bind="trans.LBL_NATURE_OF_SUP">Nature of Supplies</th>
                                                                        <th className="text-center" data-ng-bind="trans.LBL_INTER_STATE_SUP">Inter-State Supplies (₹)</th>
                                                                        <th className="text-center" data-ng-bind="trans.LBL_INTRA_STATE_SUP">Intra-State Supplies (₹)</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr ng-repeat="obj in inward_sup.isup_details">
                                                                        <th ng-switch="obj.ty">
                                                                            <p className='text-left'>
                                                                                From a supplier under composition scheme, Exempt and Nil rated supply
                                                                            </p>
                                                                        </th>
                                                                        <td className={getErrorCustom('a.igst')}>
                                                                            <input name="a.igst.value" id="a.igst.value" value={values.a.igst.value} onChange={e => {
                                                                                const formatValue = getFormatvalue(e.target.value);
                                                                                e.target.value = formatValue;
                                                                                handleChange(e);
                                                                            }} onBlur={e => {
                                                                                if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                    e.target.value = e.target.value + ".00";
                                                                                }
                                                                                handleChange(e);
                                                                            }} className="total form-control formedit newinv currency" disabled={gstrf.status === "done" || gstrf.status === "submitted"} />
                                                                        </td>
                                                                        <td className={getErrorCustom('a.cgst')}>
                                                                            <input name="a.cgst.value" id="a.cgst.value" value={values.a.cgst.value} onChange={e => {
                                                                                const formatValue = getFormatvalue(e.target.value);
                                                                                e.target.value = formatValue;
                                                                                handleChange(e);
                                                                            }} onBlur={e => {
                                                                                if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                    e.target.value = e.target.value + ".00";
                                                                                }
                                                                                handleChange(e);
                                                                            }} className="total form-control formedit newinv currency" disabled={gstrf.status === "done" || gstrf.status === "submitted"} />
                                                                        </td>
                                                                    </tr>
                                                                    <tr ng-repeat="obj in inward_sup.isup_details">
                                                                        <th ng-switch="obj.ty">
                                                                            <p className='text-left'>Non GST supply</p>
                                                                        </th>
                                                                        <td className={getErrorCustom('b.igst')}>
                                                                            <input name="b.igst.value" id="b.igst.value" value={values.b.igst.value} onChange={e => {
                                                                                const formatValue = getFormatvalue(e.target.value);
                                                                                e.target.value = formatValue;
                                                                                handleChange(e);
                                                                            }} onBlur={e => {
                                                                                if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                    e.target.value = e.target.value + ".00";
                                                                                }
                                                                                handleChange(e);
                                                                            }} className="total form-control formedit newinv currency" disabled={gstrf.status === "done" || gstrf.status === "submitted"} />
                                                                        </td>
                                                                        <td className={getErrorCustom('b.cgst')}>
                                                                            <input name="b.cgst.value" id="b.cgst.value" value={values.b.cgst.value} onChange={e => {
                                                                                const formatValue = getFormatvalue(e.target.value);
                                                                                e.target.value = formatValue;
                                                                                handleChange(e);
                                                                            }} onBlur={e => {
                                                                                if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                    e.target.value = e.target.value + ".00";
                                                                                }
                                                                                handleChange(e);
                                                                            }} className="total form-control formedit newinv currency" disabled={gstrf.status === "done" || gstrf.status === "submitted"} />
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="btn-toolbar">
                                                <button type="submit" className="btn btn-primary pull-right" disabled={submit}>CONFIRM</button>
                                                <button type="button" className="btn btn-primary pull-right" onClick={() => {
                                                    setLoading(true);
                                                    gotoPath('gstr3BForm')
                                                }}>CANCEL</button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </>
    )
}
