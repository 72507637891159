import React, { useState, useEffect } from 'react';

import { useRecoilState } from 'recoil';
import { pathAtom, gstrpathAtom, digErrorAtom, returnDateAtom, loadingAtom, gstrUserAtom, gstrQueryAtom, gstrFileStatusAtom, userIDAtom } from '../../../core/config/atoms';

import { Dialog } from '@headlessui/react';

import '../css/main.css';
import '../css/style.css';

import ServiceGSTR from '../../../services/serviceGSTR';
import ServiceUtility from '../../../services/serviceUtility';

import imgLoader from '../../../assets/images/cloading.gif';

export default function PanelB2COHome() {

  const [path, setPath] = useRecoilState(pathAtom);
  const [gstrPath, setGSTRPath] = useRecoilState(gstrpathAtom);
  const [digError, setDigError] = useRecoilState(digErrorAtom);
  const [gDate] = useRecoilState(returnDateAtom);
  const [loading, setLoading] = useRecoilState(loadingAtom);
  const [GSTUser] = useRecoilState(gstrUserAtom);
  const [query, setQuery] = useRecoilState(gstrQueryAtom);
  const [fileStatus] = useRecoilState(gstrFileStatusAtom);
  const [userID] = useRecoilState(userIDAtom);

  const [records, setRecords] = useState([]);

  const [digConfirm, setDigConfirm] = useState({ 'type': '0', isToggle: false, data: null });

  const [isAlert, setIsAlrt] = useState(false);

  const returnService = new ServiceGSTR();
  const utilService = new ServiceUtility();

  const gotoPath = (path) => {
    setLoading(true);
    setPath(path);
  }

  const gotoGstrPath = (path) => {
    setGSTRPath(path);
    gotoPath('gstrFile');
  }

  const getSelectionRecords = () => {
    let rows = [];
    records.forEach((row) => {
      let s = 'processed';
      if (s === row.status) {
        let igst = parseFloat(row.data.taxa).toFixed(2);
        let cgst = parseFloat(row.data.taxb).toFixed(2);
        let sgst = parseFloat(row.data.taxb).toFixed(2);
        let cess = row.data.cess !== "" ? parseFloat(row.data.cess).toFixed(2) : parseFloat("0.00").toFixed(2);
        let action = row.data.status;
        rows.push({ id: row._id, pos: row.data.pos, rate: row.data.rate, total: parseFloat(row.data.invval).toFixed(2), igst: igst, cgst: cgst, sgst: sgst, cess: cess, action: action });
      }
    });
    return rows;
  }

  const editInvoice = (id) => {
    setQuery({ type: 'edit', data: id });
    gotoGstrPath('b2cob');
  }

  const showDig = (type, data) => {
    setDigConfirm({ 'type': type, isToggle: true, data: data });
  }

  const cancel = () => {
    setDigConfirm({ 'type': '0', isToggle: false, data: null });
  }

  const process = () => {
    switch (digConfirm.type) {
      case '1':
        deleteInvoice(digConfirm.data.id, digConfirm.data.status);
        break;
      default:
        break;
    }
    setDigConfirm({ 'type': '0', isToggle: false, data: null });
  }

  const deleteInvoice = (id, status) => {
    setLoading(true);
    let body = {
      lid: userID,
      type: 'b2co',
      status: status,
      month: gDate,
      id: id
    }
    returnService.deleteGstrDoc(body).then((res) => {
      setLoading(false);
      showAlert();
      loadData();
    });
  }

  const showAlert = () => {
    setIsAlrt(true);
    setTimeout(
      () => setIsAlrt(false),
      5000
    );
  }

  const loadData = () => {
    setLoading(true);
    (async function () {
      let body = {
        lid: userID,
        month: gDate,
        type: 'b2co'
      }
      var res = await returnService.getGstrDocs(body);
      setRecords(res.data);
      setLoading(false);
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    })();
  }

  useEffect(() => {
    loadData();
  }, []);

  return (
    <div>
      <Dialog open={digConfirm.isToggle} onClose={() => setDigConfirm({ 'type': '0', isToggle: false, data: null })}>
        <div id="confirmDlg" class="modal fade fade-scale in" role="dialog" style={{ display: 'block', paddingRight: '17px' }}>
          <div class="modal-dialog sweet">
            <div class="modal-content">
              <div class="modal-body">
                <div class="m-icon m-warning pulseWarning">
                  <span class="micon-body pulseWarningIns"></span>
                  <span class="micon-dot pulseWarningIns"></span>
                </div>
                <h2>Warning</h2>
                <p>Are you sure, you want to continue?</p>
              </div>
              <div class="modal-footer">
                <a class="btn btn-default" onClick={() => { cancel() }}>Cancel</a>
                <a class="btn btn-primary" onClick={() => { process() }}>Proceed</a>
              </div>
            </div>
          </div>
        </div>
      </Dialog>
      <div class="content-wrapper fade-ng-cloak">
        <div class="dimmer-holder" style={{ display: 'none' }}>
          <div id="dimmer"></div>
        </div>
        {
          loading && <div className='absolute z-50 w-full h-screen flex items-center justify-center bg-gray-200 bg-opacity-25'>
            <img src={imgLoader} alt="loader" />
          </div>
        }
        <div class="container">
          <div class="mypage">
            <div class="row" data-ng-controller="transctrl">
              {isAlert && <alert-message ng-show="showMsg" title="" type="msg alert-success" message="" class="ng-hide"><div class="alert alert-msg alert-success">Request accepted successfully<a class="close" onClick={() => { setIsAlrt(false) }} data-dismiss="alert" aria-label="close">×</a><strong></strong>.</div></alert-message>}
              <div class="col-xs-10">
                <div data-breadcrumb="" name="Dashboard">
                  <ol class="breadcrumb" data-ng-controller="crumbCtrl">
                    <li onClick={() => {
                      gotoPath('gstr')
                    }}><a>Dashboard</a></li>
                    <li onClick={() => {
                      gotoPath('gstRetrun')
                    }}><a>Returns</a></li>
                    <li onClick={() => {
                      gotoPath('gstrForm')
                    }}><a>GSTR-1/IFF</a></li>
                    <li>B2CS</li>
                  </ol>
                </div>
              </div>
              <div class="col-xs-2">
                <div class="lang dropdown">
                  <span class="dropdown-toggle" data-toggle="dropdown" data-ng-bind="selectedLang">English</span>
                  <ul class="dropdown-menu lang-dpdwn">
                    <li data-ng-repeat="language in languages">
                      <a data-ng-click="selectLang(language.cd)" data-ng-bind="language.nm">English</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div data-ng-controller="mainctrl" ng-view="">
              <div>
                <div class="row invsumm">
                  <div class="col-xs-12 col-sm-12 taxp">
                    <h4>7 - B2C (Others)</h4><button class="btn btn-primary btn-circle btn-sm pull-right" data-toggle="tooltip" title="Refresh" disabled={true} style={{ marginTop: '0px' }}><i class="fa fa-refresh" aria-hidden="true"></i></button>
                    <button type="button" class="btn btn-primary btn-sm pull-right" disabled={true} style={{ marginTop: '0px', marginRight: '5px' }}>
                      <span>Help</span>&nbsp;
                      <i class="fa fa-question-circle" aria-hidden="true"></i>
                    </button>
                  </div>
                </div>
                <form class="ng-pristine ng-valid" autocomplete="off" novalidate="">
                  <div class="tabpane">

                    {
                      records.length !== 0 && <div class="btn-toolbar ng-hide">
                        <button type="button" class="btn btn-primary pull-left" data-ng-bind="trans.LBL_ADDREC" style={{ marginLeft: '5px' }} onClick={() => {
                          gotoGstrPath('b2cob')
                        }} disabled={fileStatus}>Add Record</button>
                        <a>
                          <button type="button" class="btn btn-primary pull-right" style={{ marginLeft: '5px' }} data-toggle="tooltip" title="To import EWB data into B2CL Section" disabled={true}>Import EWb Data</button>
                        </a>
                      </div>
                    }

                    {
                      records.length === 0 && <alert-message title="" type="msg alert-info" message="" class="ng-hide">
                        <div class="alert alert-msg alert-info">There are no records to be displayed.<a class="close" data-dismiss="alert" aria-label="close">×</a><strong></strong>.</div>
                      </alert-message>
                    }

                    {
                      getSelectionRecords().length !== 0 && < div class="row" data-ng-if="b2binvdt.processedInvoice">
                        <div class="col-sm-12">
                          <h4 data-ng-bind="trans.LBL_PRO_RCD">Processed Records</h4>
                        </div>
                        <br />

                        <div class="col-xs-12 col-sm-12">
                          <div class="row">
                            <div class="col-xs-12">
                              <div class="col-lg-3 pull-right">
                                <div class="form-group pull-right" style={{ display: 'inline-flex' }}>
                                  <div class="col-lg-9"><label class="form-label">Records Per Page : </label></div>
                                  <div class="col-lg-3" style={{ paddingLeft: '0', marginLeft: '-0.7em' }}>
                                    <select class="form-control ng-pristine ng-untouched ng-valid ng-not-empty" style={{ minWidth: '5em', border: '1px solid' }} ng-model="obj.recPerPageProc" data-ng-change="changeRecordsPerpagePrs()" ng-options="item as item for item in recordsPerpageOptionsb2b" value="item">
                                      <option label="10" value="number:10" selected="selected">10</option>
                                      <option label="20" value="number:20">20</option>
                                      <option label="50" value="number:50">50</option>
                                    </select>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="table-responsive">
                            <table class="table exp inv tbl table-bordered ng-table" style={{ display: 'table' }}>
                              <thead ng-include="templates.header">
                                <tr class="ng-table-sort-header">
                                  <th title="" ng-repeat="$column in $columns" class="header sortable">
                                    <div class="ng-table-header">
                                      <span class="sort-indicator">Place of supply <br />(Name of state)</span>
                                    </div>
                                  </th>
                                  <th title="" class="header ">
                                    <div>
                                      <span class="sort-indicator">Rate<br />(%)</span>
                                    </div>
                                  </th>
                                  <th title="" class="header ">
                                    <div>
                                      <span class="sort-indicator">Total taxable value (₹)</span>
                                    </div>
                                  </th>
                                  <th title="" class="header ">
                                    <div>
                                      <span class="sort-indicator">Integrated Tax (₹)</span>
                                    </div>
                                  </th>
                                  <th title="" class="header ">
                                    <div>
                                      <span class="sort-indicator">Central tax (₹)</span>
                                    </div>
                                  </th>
                                  <th title="" class="header ">
                                    <div>
                                      <span class="sort-indicator">State/UT Tax (₹)</span>
                                    </div>
                                  </th>
                                  <th title="" class="header ">
                                    <div>
                                      <span class="sort-indicator">Cess (₹)</span>
                                    </div>
                                  </th>
                                  <th title="" class="header ">
                                    <div>
                                      <span class="sort-indicator">Applicable percentage (%)</span>
                                    </div>
                                  </th>
                                  <th title="" class="header ">
                                    <div>
                                      <span class="sort-indicator">Actions</span>
                                    </div>
                                  </th>
                                </tr>
                              </thead>
                              <tbody data-ng-repeat="t in $data">
                                {
                                  getSelectionRecords().map((row) => {
                                    return <tr>
                                      <td class="text-center">
                                        {row.pos}
                                      </td>
                                      <td class="currency">{row.rate}</td>
                                      <td class="currency">{row.total}</td>
                                      <td class="currency">{row.igst}</td>
                                      <td class="currency">{row.cgst}</td>
                                      <td class="currency">{row.sgst}</td>
                                      <td class="currency">{row.cess}</td>
                                      <td class="text-center">-</td>
                                      <td style={{ display: 'inline-block' }} class="text-center newinv" data-title-text="Actions">
                                        <div className='flex'>
                                          <button type="button" class="btn btn-sm btn-primary nomargin" onClick={() => {
                                            editInvoice(row.id);
                                          }} disabled={fileStatus}><i class="fa fa-pencil" aria-hidden="true"></i><span class="sr-only">text</span>
                                          </button>&nbsp;
                                          <button type="button" class="btn btn-sm btn-danger nomargin" title="Delete" onClick={() => {
                                            showDig('1', { id: row.id, status: 'processed' });
                                          }} disabled={fileStatus}>
                                            <i class="fa fa-trash"></i><span class="sr-only">text</span>
                                          </button>
                                        </div>
                                      </td>
                                    </tr>
                                  })
                                }
                              </tbody>
                            </table>
                            <div ng-table-pagination="params" template-url="templates.pagination">
                              <div ng-include="templateUrl">
                                <div class="ng-table-pager" ng-if="params.data.length">
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    }

                    <div class="btn-toolbar">
                      {
                        records.length === 0 && <div data-ng-hide="cnt.b2bCptSummary" class="ng-hide">
                          <a>
                            <button type="button" class="btn btn-primary pull-right" style={{ marginLeft: '5px' }} title="To import EWB data into B2B Section" disabled={true}>Import EWb Data</button>
                          </a>
                          <button type="button" class="btn btn-primary pull-right" style={{ marginLeft: '5px' }} onClick={() => {
                            gotoGstrPath('b2cob')
                          }} disabled={fileStatus}>Add Record</button>
                        </div>
                      }
                      <button type="button" class="btn btn-default pull-right" onClick={() => {
                        gotoPath('gstrForm')
                      }}>Back</button>
                    </div>

                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
