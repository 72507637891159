import React, { useState, useEffect } from 'react';

import Moment from 'react-moment';

import { useRecoilState } from 'recoil';
import { trnAtom, pathAtom, trnLevelAtom, userIDAtom } from '../core/config/atoms';

import ServiceAuth from '../services/serviceAuth';

export default function PanelTrnDashboard() {

    const [path, setPath] = useRecoilState(pathAtom);
    const [trnno] = useRecoilState(trnAtom);
    const [trnLevel, SetTRNLevel] = useRecoilState(trnLevelAtom);
    const [userID] = useRecoilState(userIDAtom);

    const [gstUser, setGSTUser] = useState({});

    const [isShow, setIsShow] = useState(false);

    const authService = new ServiceAuth();

    const editForm = () => {
        setPath('trnForms');
        SetTRNLevel({ ...trnLevel, level: 'sa1' });
    }

    const loadData = () => {
        (async function () {
            var res = await authService.getGSTUserByTRN({ 'trn': trnno, 'stdOwn': userID });
            setGSTUser(res.data[0]);

            let rhs = new Date(res.data[0].updatedAt);
            let lhs = new Date();
            const one_minte = 1000 * 60;

            let result = Math.ceil((lhs.getTime() - rhs.getTime()) / (one_minte));

            if (result > 15) {
                setIsShow(true);
            }

            window.scrollTo(0, 0)
        })();
    }

    useEffect(() => {
        loadData();
    }, []);

    return (
        <>
            <div class="content-wrapper">
                <div class="container">
                    <div class="mypage">
                        <div class="row">
                            <div class="col-xs-10">
                                <div data-breadcrumb="">
                                    <ol class="breadcrumb">
                                        <li><a>Dashboard</a></li>
                                    </ol>
                                </div>
                            </div>
                            <div class="col-xs-2">
                                <div class="lang dropdown">
                                    <span>English</span>
                                    <ul class="dropdown-menu lang-dpdwn">
                                        <li>
                                            <a>English</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="dimmer-holder" style={{ display: 'none' }}>
                            <div id="dimmer"></div>
                        </div>
                        <div class="content-pane" style={{ minHeight: '473.4px' }}>
                            <div class="row">
                                <div class="col-sm-12 col-xs-12">
                                    <h4>My Saved Applications</h4>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-12">
                                    <div class="table-responsive" data-ng-if="savedata &amp;&amp; savedata.length > 0">
                                        <table class="table table-vertical-align thead-dark table-hover table-bordered">
                                            <thead>
                                                <tr>
                                                    <th width="15%" class="vrtcl-midl text-center">Creation Date</th>
                                                    <th class="vrtcl-midl text-center">Form No.</th>
                                                    <th class="vrtcl-midl text-center" style={{ width: '20%' }}>Form Description </th>
                                                    <th class="vrtcl-midl text-center">Expiry Date</th>
                                                    <th class="vrtcl-midl text-center">Status</th>
                                                    <th class="vrtcl-midl text-center">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr class="vrtcl-midl text-center">
                                                    <td><Moment format="DD/MM/YYYY">{gstUser['createdAt']}</Moment></td>
                                                    <td>GST REG-01</td>
                                                    <td>Application for New Registration</td>
                                                    <td><Moment format="DD/MM/YYYY">{gstUser['expiryAt']}</Moment></td>
                                                    <td>
                                                        {
                                                            gstUser.status === "draft" && <>Draft&nbsp;
                                                                <a className='link'><i class="fa fa-info-circle" data-toggle="tooltip" title="Details of status"></i>
                                                                </a>
                                                            </>
                                                        }
                                                        {
                                                            gstUser.status === "done" && <>Completed&nbsp;
                                                                <a className='link'><i class="fa fa-info-circle" data-toggle="tooltip" title="Details of status"></i>
                                                                </a>
                                                            </>
                                                        }
                                                    </td>
                                                    <td class="text-center">
                                                        {
                                                            gstUser.status === "draft" && <button type="button" class="btn btn-sm btn-primary" onClick={() => {
                                                                if (gstUser.status === "draft") {
                                                                    editForm();
                                                                }
                                                            }}><i class="fa fa-pencil"></i> </button>
                                                        }
                                                        {
                                                            gstUser.status === "done" && <>-</>
                                                        }
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-6 col-xs-12">
                                    <h4 data-ng-bind="trans.HEAD_TRACK_ARN">Track Application Status</h4>
                                    {gstUser.status !== "done" && <p>You do not have any submitted applications</p>}
                                    {gstUser.status === "done" && isShow && <p>Your GSTN Number is : {gstUser.gstn}</p>}
                                    {gstUser.status === "done" && !isShow && <p>Your GSTN Number will be available in 15 minutes</p>}
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
