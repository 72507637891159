import React, { useState, useEffect } from 'react';

import { useRecoilState } from 'recoil';
import { pathAtom, loadingAtom, gstrFileAtom, digErrorAtom } from '../../../core/config/atoms';

import { useFormik } from 'formik';
import * as Yup from 'yup';
import Scrollbars from 'react-custom-scrollbars';

import ServiceGSTF from '../../../services/serviceGSTF';

export default function Panel311Home() {

    const [loading, setLoading] = useRecoilState(loadingAtom);
    const [gstrf, setGSTRF] = useRecoilState(gstrFileAtom);

    const [, setPath] = useRecoilState(pathAtom);
    const [, setDigError] = useRecoilState(digErrorAtom);

    const [submit, setSubmit] = useState(false);

    const [isHelp, setIsHelp] = useState(false);

    const fileService = new ServiceGSTF();

    const formVSchema = Yup.object().shape({
        a: Yup.object().shape({
            total: Yup.object().shape({
                value: Yup.string().required(),
                isChange: Yup.boolean().required(),
            }).required(),
            igst: Yup.object().shape({
                value: Yup.string().required(),
                isChange: Yup.boolean().required(),
            }).required(),
            cgst: Yup.object().shape({
                value: Yup.string().required(),
                isChange: Yup.boolean().required(),
            }).required(),
            sgst: Yup.object().shape({
                value: Yup.string().required(),
                isChange: Yup.boolean().required(),
            }).required(),
            cess: Yup.object().shape({
                value: Yup.string().required(),
                isChange: Yup.boolean().required(),
            }).required(),
        }).required(),
        b: Yup.object().shape({
            total: Yup.object().shape({
                value: Yup.string().required(),
                isChange: Yup.boolean().required(),
            }).required(),
        }).required(),
    });

    const { values, errors, touched, setValues, handleChange, handleSubmit, handleReset, resetForm } = useFormik({
        initialValues: {},
        validationSchema: formVSchema,
        onSubmit: values => {
            setLoading(true);
            setSubmit(true);
            var newObj = JSON.parse(JSON.stringify(gstrf.data));
            newObj.a31.user = { ...values };
            var body = {
                id: gstrf._id,
                data: newObj
            };
            fileService.setGSTFDoc(body).then((res) => {
                setLoading(false);
                setSubmit(false);
                if (res.status) {
                    setGSTRF(res.data);
                    gotoPath('gstr3BForm');
                } else {
                    setDigError({ title: 'Error', msg: 'Server error please try again!', isToggle: true });
                    window.scrollTo({
                        top: 0,
                        behavior: 'smooth',
                    });
                }
            });
        }
    });

    const getFormatvalue = (val) => {
        if (!val) return val;
        let number = val.replace(/[^\d.]/g, "");
        if (val.includes(".")) {
            const a = number.split(".");
            if (a[1].length > 2) {
                a[1] = a[1].substring(0, 2);
            }
            if (a[0].length > 13) {
                a[0] = a[0].substring(0, 13);
            }
            number = a[0] + "." + a[1];
        } else {
            if (number.length > 13) {
                number = number.substring(0, 13);
            }
        }
        return number;
    }

    const getErrorCustom = (field) => {
        let cls = '';
        switch (field) {
            case 'a.total':
                if (values.a.total.isChange) {
                    cls = 'has-error';
                }
                break;
            case 'a.igst':
                if (values.a.igst.isChange) {
                    cls = 'has-error';
                }
                break;
            case 'a.sgst':
                if (values.a.sgst.isChange) {
                    cls = 'has-error';
                }
                break;
            case 'a.cgst':
                if (values.a.cgst.isChange) {
                    cls = 'has-error';
                }
                break;
            case 'a.cess':
                if (values.a.cess.isChange) {
                    cls = 'has-error';
                }
                break;
            case 'b.total':
                if (values.b.total.isChange) {
                    cls = 'has-error';
                }
                break;
        }
        return cls;
    }

    const gotoPath = (path) => {
        setPath(path);
    }

    function convertValuesToFixedDecimal(obj) {
        const newObj = {};

        for (let key in obj) {
            const value = obj[key];

            if (typeof value === 'object' && value !== null) {
                newObj[key] = convertValuesToFixedDecimal(value);
            } else if (typeof value === 'number') {
                newObj[key] = value.toFixed(2);
            } else {
                newObj[key] = value;
            }
        }

        return newObj;
    }

    const loadData = () => {
        var refs = convertValuesToFixedDecimal(gstrf.data.a31.user);
        setValues({ ...values, ...refs });
        if (gstrf.status === "done") {
            setSubmit(true);
        }
        setLoading(false);
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    }

    useEffect(() => {
        loadData();
    }, []);

    return (
        <>
            {
                isHelp && <div className='w-full h-full overflow-hidden fixed top-0 z-50 flex items-center justify-center bg-black bg-opacity-60'>
                    <Scrollbars>
                        <div class="modal-dialog" role="document">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <button type="button" class="close" data-dismiss="modal" aria-label="Close" onClick={() => { setIsHelp(false) }}>
                                        <span aria-hidden="true">×</span>
                                    </button>
                                    <h4 data-ng-bind="trans.ECO3B_100" className='text-[#0b1e59d9] modal-title'>Supplies through e-commerce operators</h4>
                                </div>
                                <div class="modal-body">
                                    <h4 data-ng-bind="trans.ECO3B_101" className='text-[#0b1e59d9] text-2xl font-bold'>3.1.1 (i): Taxable supplies on which electronic commerce operator pays tax u/s 9(5)</h4>
                                    <p data-ng-bind="trans.ECO3B_102">Supplies made through an Electronic Commerce operator (ECO) under section 9(5) of the CGST Act, 2017 and corresponding provisions in IGST/UTGST/SGST Acts on which ECO is liable to pay tax should be declared here by ECO. These supplies should NOT be reported in table 3.1(a).</p>
                                    <h4 data-ng-bind="trans.ECO3B_103" className='text-[#0b1e59d9] text-2xl font-bold'>3.1.1 (ii) Taxable supplies made by registered person through electronic commerce operator, on which electronic commerce operator is required to pay tax u/s 9(5)</h4>
                                    <p data-ng-bind="trans.ECO3B_104">A person making supplies through an Electronic Commerce Operator, on which Electronic Commerce Operator are liable to pay tax under section 9(5) of the CGST Act, 2017 and corresponding provisions in IGST/UTGST/SGST Acts should be declared here by registered person. These supplies should NOT be reported in table 3.1(c).</p>
                                </div>
                                <div class="modal-footer">
                                    <button type="button" class="btn btn-primary" data-dismiss="modal" data-ng-bind="trans.ELGITC3B_183" onClick={() => { setIsHelp(false) }}>Close</button>
                                </div>
                            </div>
                        </div>
                    </Scrollbars>
                </div>
            }
            <div className="content-wrapper fade-ng-cloak">
                <div className="container">
                    <div className="mypage">
                        <div className="row">
                            <div className="col-xs-10">
                                <div>
                                    <ol className="breadcrumb" data-ng-controller="crumbCtrl">
                                        <li>
                                            <a onClick={() => { gotoPath('gstr') }}>Dashboard</a></li>
                                        <li>
                                            <a onClick={() => { gotoPath('gstRetrun') }}>Returns</a>
                                        </li>
                                        <li>
                                            <a onClick={() => {
                                                setLoading(true);
                                                gotoPath('gstr3BForm')
                                            }}>GSTR-3B</a>
                                        </li>
                                        <li>
                                            <span ng-switch-when="true">Supplies through E-commerce operators</span>
                                        </li>
                                    </ol>
                                </div>
                            </div>
                            <div className="col-xs-2">
                                <div className="lang dropdown">
                                    <span className="dropdown-toggle" data-toggle="dropdown" data-ng-bind="selectedLang">English</span>
                                    <ul className="dropdown-menu lang-dpdwn">
                                        <li>
                                            <a data-ng-bind="language.nm">English</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        {
                            !loading && <div>
                                <form autocomplete="off" novalidate="" onSubmit={handleSubmit} onReset={handleReset}>
                                    <div className="container">
                                        <div className="row invsumm">
                                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                <div className="pull-left head-90">
                                                    <h4>3.1.1 Details of supplies notified under section 9(5) of the CGST Act, 2017 and corresponding provision in IGST/UTGST/SGST Acts</h4>
                                                </div>
                                                <div className="pull-right">
                                                    <h4>
                                                        <a data-toggle="modal" onClick={() => { setIsHelp(true) }}>Help</a>
                                                        &nbsp;<i className="fa fa-question-circle" aria-hidden="true"></i>
                                                    </h4>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="tabpane">
                                            <div name="validationmsg"></div>
                                            <div className="row">
                                                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                    <div className="rettbl-format">
                                                        <div className="table-responsive">
                                                            <table className="table tbl inv exp table-bordered">
                                                                <thead>
                                                                    <tr>
                                                                        <th className="text-center" data-ng-bind="trans.LBL_DESCRIPTION">Description</th>
                                                                        <th className="text-center" data-ng-bind="trans.LBL_TAXABLE_VALUE_RS">Total Taxable Value(₹)</th>
                                                                        <th className="text-center" data-ng-bind="trans.LBL_GST3B_ITAX">Integrated Tax (₹)</th>
                                                                        <th className="text-center" data-ng-bind="trans.LBL_GST3B_CTAX">Central Tax (₹)</th>
                                                                        <th className="text-center" data-ng-bind="trans.LBL_GST3B_STAX">State/UT Tax (₹)</th>
                                                                        <th className="text-center" data-ng-bind="trans.LBL_GST3B_CS">CESS (₹)</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr>
                                                                        <td className="table-row">
                                                                            <p className='p-2 m-0'>(i) Taxable supplies on which electronic commerce operator pays tax u/s 9(5)<br /> [to be furnished by electronic commerce operator]</p>
                                                                        </td>
                                                                        <td className={getErrorCustom('a.total')}>
                                                                            <input name="a.total.value" id="a.total.value" value={values.a.total.value} onChange={e => {
                                                                                const formatValue = getFormatvalue(e.target.value);
                                                                                e.target.value = formatValue;
                                                                                handleChange(e);
                                                                            }} onBlur={e => {
                                                                                if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                    e.target.value = e.target.value + ".00";
                                                                                }
                                                                                handleChange(e);
                                                                            }} className="total form-control formedit newinv currency" disabled={true} />
                                                                        </td>
                                                                        <td className={getErrorCustom('a.igst')}>
                                                                            <input name="a.igst.value" id="a.igst.value" value={values.a.igst.value} onChange={e => {
                                                                                const formatValue = getFormatvalue(e.target.value);
                                                                                e.target.value = formatValue;
                                                                                handleChange(e);
                                                                            }} onBlur={e => {
                                                                                if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                    e.target.value = e.target.value + ".00";
                                                                                }
                                                                                handleChange(e);
                                                                            }} className="form-control formedit newinv currency" disabled={true} />
                                                                        </td>
                                                                        <td className={getErrorCustom('a.cgst')}>
                                                                            <input name="a.cgst.value" id="a.cgst.value" value={values.a.cgst.value} onChange={e => {
                                                                                const formatValue = getFormatvalue(e.target.value);
                                                                                e.target.value = formatValue;
                                                                                handleChange(e);
                                                                            }} onBlur={e => {
                                                                                if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                    e.target.value = e.target.value + ".00";
                                                                                }
                                                                                handleChange(e);
                                                                            }} className="form-control formedit newinv currency" disabled={true} />
                                                                        </td>
                                                                        <td className={getErrorCustom('a.sgst')}>
                                                                            <input name="a.sgst.value" id="a.sgst.value" value={values.a.sgst.value} onChange={e => {
                                                                                const formatValue = getFormatvalue(e.target.value);
                                                                                e.target.value = formatValue;
                                                                                handleChange(e);
                                                                            }} onBlur={e => {
                                                                                if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                    e.target.value = e.target.value + ".00";
                                                                                }
                                                                                handleChange(e);
                                                                            }} className="form-control formedit newinv currency" disabled={true} />
                                                                        </td>
                                                                        <td className={getErrorCustom('a.cess')}>
                                                                            <input name="a.cess.value" id="a.cess.value" value={values.a.cess.value} onChange={e => {
                                                                                const formatValue = getFormatvalue(e.target.value);
                                                                                e.target.value = formatValue;
                                                                                handleChange(e);
                                                                            }} onBlur={e => {
                                                                                if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                    e.target.value = e.target.value + ".00";
                                                                                }
                                                                                handleChange(e);
                                                                            }} className="form-control formedit newinv currency" disabled={true} />
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className="table-row">
                                                                            <p className='p-2 m-0'>(ii) Taxable supplies made by registered person through electronic commerce operator, on which electronic commerce operator is required  to pay tax u/s 9(5)<br /> [to be furnished by registered person making supplies through electronic commerce operator]</p>
                                                                        </td>
                                                                        <td className={getErrorCustom('b.total')}>
                                                                            <input name="b.total.value" id="b.total.value" value={values.b.total.value} onChange={e => {
                                                                                const formatValue = getFormatvalue(e.target.value);
                                                                                e.target.value = formatValue;
                                                                                handleChange(e);
                                                                            }} onBlur={e => {
                                                                                if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                    e.target.value = e.target.value + ".00";
                                                                                }
                                                                                handleChange(e);
                                                                            }} className="total form-control formedit newinv currency" disabled={true} />
                                                                        </td>
                                                                        <td className="gstr3b-blank"></td>
                                                                        <td className="gstr3b-blank"></td>
                                                                        <td className="gstr3b-blank"></td>
                                                                        <td className="gstr3b-blank"></td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="btn-toolbar">
                                                <button type="submit" className="btn btn-primary pull-right" disabled={submit}>CONFIRM</button>
                                                <button type="button" className="btn btn-primary pull-right" onClick={() => {
                                                    setLoading(true);
                                                    gotoPath('gstr3BForm')
                                                }}>CANCEL</button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </>
    )
}
