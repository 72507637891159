export default class ServiceUtility {

    getCode() {
        return Math.floor(100000 + Math.random() * 900000);
    }

    getTrnCode() {
        return Math.floor(100000000000 + Math.random() * 900000000000);
    }

    getAckCode() {
        return Math.floor(100000000000 + Math.random() * 900000000000);
    }

    getCPIN() {
        return Math.floor(10000000000000 + Math.random() * 10000000000000);
    }

    getTID() {
        return Math.floor(100000000000000 + Math.random() * 100000000000000);
    }

    getGSTRACK() {
        return "AA" + (Math.floor(100000000000 + Math.random() * 900000000000)) + "K";
    }

    patternMatch(pattern, text) {
        const validRegl = new RegExp(pattern);
        return validRegl.test(text);
    }

    formatOnlyNumber(val) {
        if (!val) return val;
        let number = val.replace(/[^\d.]/g, "");
        return number;
    }

    calculatePercentage(sts) {
        let per = 0;
        per = per + (sts[0] === '1' ? 20 : 0);
        per = per + (sts[1] === '1' ? 20 : 0);
        per = per + (sts[2] === '1' ? 20 : 0);
        per = per + (sts[4] === '1' ? 20 : 0);
        per = per + (sts[8] === '1' ? 20 : 0);
        return per;
    }

    getRCode(num) {
        return Math.floor((1 * parseInt(num)) + Math.random() * (9 * parseInt(num)));
    }

    getStateCode(state) {
        switch (state) {
            case "ANDAMAN AND NICOBAR ISLANDS":
                return "35";
            case "ANDHRA PRADESH":
                return "37";
            case "ARUNACHAL PRADESH":
                return "12";
            case "ASSAM":
                return "18";
            case "BIHAR":
                return "10";
            case "CHANDIGARH":
                return "04";
            case "CHHATTISGARH":
                return "22";
            case "DELHI":
                return "07";
            case "GOA":
                return "30";
            case "GUJARAT":
                return "24";
            case "HARYANA":
                return "06";
            case "HIMACHAL PRADESH":
                return "02";
            case "JAMMU AND KASHMIR":
                return "01";
            case "JHARKHAND":
                return "20";
            case "KARNATAKA":
                return "29";
            case "KERALA":
                return "32";
            case "LADAKH":
                return "38";
            case "LAKSHADWEEP":
                return "31";
            case "MADHYA PRADESH":
                return "23";
            case "MAHARASHTRA":
                return "27";
            case "MANIPUR":
                return "14";
            case "MEGHALAYA":
                return "17";
            case "MIZORAM":
                return "15";
            case "NAGALAND":
                return "23";
            case "ODISHA":
                return "21";
            case "PUDUCHERRY":
                return "34";
            case "PUNJAB":
                return "03";
            case "RAJASTHAN":
                return "08";
            case "SIKKIM":
                return "11";
            case "TAMIL NADU":
                return "33";
            case "TELANGANA":
                return "36";
            case "THE DADRA AND NAGAR HAVELI AND DAMAN AND DIU":
                return "26";
            case "TRIPURA":
                return "16";
            case "UTTAR PRADESH":
                return "09";
            case "UTTARAKHAND":
                return "05";
            case "WEST BENGAL":
                return "19";
            default:
                return "97";
        }
    }

    getIsState(state) {
        switch (state) {
            case "ANDAMAN AND NICOBAR ISLANDS":
                return false;
            case "ANDHRA PRADESH":
                return true;
            case "ARUNACHAL PRADESH":
                return true;
            case "ASSAM":
                return true;
            case "BIHAR":
                return true;
            case "CHANDIGARH":
                return false;
            case "CHHATTISGARH":
                return true;
            case "DELHI":
                return false;
            case "GOA":
                return true;
            case "GUJARAT":
                return true;
            case "HARYANA":
                return true;
            case "HIMACHAL PRADESH":
                return true;
            case "JAMMU AND KASHMIR":
                return true;
            case "JHARKHAND":
                return true;
            case "KARNATAKA":
                return true;
            case "KERALA":
                return true;
            case "LADAKH":
                return true;
            case "LAKSHADWEEP":
                return false;
            case "MADHYA PRADESH":
                return true;
            case "MAHARASHTRA":
                return true;
            case "MANIPUR":
                return true;
            case "MEGHALAYA":
                return true;
            case "MIZORAM":
                return true;
            case "NAGALAND":
                return true;
            case "ODISHA":
                return true;
            case "PUDUCHERRY":
                return false;
            case "PUNJAB":
                return true;
            case "RAJASTHAN":
                return true;
            case "SIKKIM":
                return true;
            case "TAMIL NADU":
                return true;
            case "TELANGANA":
                return true;
            case "THE DADRA AND NAGAR HAVELI AND DAMAN AND DIU":
                return false;
            case "TRIPURA":
                return true;
            case "UTTAR PRADESH":
                return true;
            case "UTTARAKHAND":
                return true;
            case "WEST BENGAL":
                return true;
            default:
                return false;
        }
    }

    getCodeState(code) {
        switch (code) {
            case "35":
                return "ANDAMAN AND NICOBAR ISLANDS";
            case "37":
                return "ANDHRA PRADESH";
            case "12":
                return "ARUNACHAL PRADESH";
            case "18":
                return "ASSAM";
            case "10":
                return "BIHAR";
            case "04":
                return "CHANDIGARH";
            case "22":
                return "CHHATTISGARH";
            case "07":
                return "DELHI";
            case "30":
                return "GOA";
            case "24":
                return "GUJARAT";
            case "06":
                return "HARYANA";
            case "02":
                return "HIMACHAL PRADESH";
            case "01":
                return "JAMMU AND KASHMIR";
            case "20":
                return "JHARKHAND";
            case "29":
                return "KARNATAKA";
            case "32":
                return "KERALA";
            case "38":
                return "LADAKH";
            case "31":
                return "LAKSHADWEEP";
            case "23":
                return "MADHYA PRADESH";
            case "27":
                return "MAHARASHTRA";
            case "14":
                return "MANIPUR";
            case "17":
                return "MEGHALAYA";
            case "15":
                return "MIZORAM";
            case "23":
                return "NAGALAND";
            case "21":
                return "ODISHA";
            case "34":
                return "PUDUCHERRY";
            case "03":
                return "PUNJAB";
            case "08":
                return "RAJASTHAN";
            case "11":
                return "SIKKIM";
            case "33":
                return "TAMIL NADU";
            case "36":
                return "TELANGANA";
            case "26":
                return "THE DADRA AND NAGAR HAVELI AND DAMAN AND DIU";
            case "16":
                return "TRIPURA";
            case "09":
                return "UTTAR PRADESH";
            case "05":
                return "UTTARAKHAND";
            case "19":
                return "WEST BENGAL";
            default:
                return "OTHER";
        }
    }

    validateForm(errors) {
        let valid = true;
        Object.values(errors).forEach(val => val.length > 0 && (valid = false));
        return valid;
    };

    getTotalValue(items) {
        let val = 0;
        val = val + parseFloat(items.a.total === "" ? "00.00" : items.a.total);
        val = val + parseFloat(items.b.total === "" ? "00.00" : items.b.total);
        val = val + parseFloat(items.c.total === "" ? "00.00" : items.c.total);
        val = val + parseFloat(items.d.total === "" ? "00.00" : items.d.total);
        val = val + parseFloat(items.e.total === "" ? "00.00" : items.e.total);
        val = val + parseFloat(items.f.total === "" ? "00.00" : items.f.total);
        val = val + parseFloat(items.g.total === "" ? "00.00" : items.g.total);
        val = val + parseFloat(items.h.total === "" ? "00.00" : items.h.total);
        val = val + parseFloat(items.i.total === "" ? "00.00" : items.i.total);
        val = val + parseFloat(items.j.total === "" ? "00.00" : items.j.total);
        val = val + parseFloat(items.k.total === "" ? "00.00" : items.k.total);
        val = val + parseFloat(items.l.total === "" ? "00.00" : items.l.total);
        return val;
    }

    getTotalTax(items) {
        let val = 0;
        val = val + parseFloat(items.a.total === "" ? "00.00" : items.a.total) + parseFloat(items.a.taxa === "" ? "00.00" : items.a.taxa) + parseFloat(items.a.cess === "" ? "00.00" : items.a.cess);
        val = val + parseFloat(items.b.total === "" ? "00.00" : items.b.total) + parseFloat(items.b.taxa === "" ? "00.00" : items.b.taxa) + parseFloat(items.b.cess === "" ? "00.00" : items.b.cess);
        val = val + parseFloat(items.c.total === "" ? "00.00" : items.c.total) + parseFloat(items.c.taxa === "" ? "00.00" : items.c.taxa) + parseFloat(items.c.cess === "" ? "00.00" : items.c.cess);
        val = val + parseFloat(items.d.total === "" ? "00.00" : items.d.total) + parseFloat(items.d.taxa === "" ? "00.00" : items.d.taxa) + parseFloat(items.d.cess === "" ? "00.00" : items.d.cess);
        val = val + parseFloat(items.e.total === "" ? "00.00" : items.e.total) + parseFloat(items.e.taxa === "" ? "00.00" : items.e.taxa) + parseFloat(items.e.cess === "" ? "00.00" : items.e.cess);
        val = val + parseFloat(items.f.total === "" ? "00.00" : items.f.total) + parseFloat(items.f.taxa === "" ? "00.00" : items.f.taxa) + parseFloat(items.f.cess === "" ? "00.00" : items.f.cess);
        val = val + parseFloat(items.g.total === "" ? "00.00" : items.g.total) + parseFloat(items.g.taxa === "" ? "00.00" : items.g.taxa) + parseFloat(items.g.cess === "" ? "00.00" : items.g.cess);
        val = val + parseFloat(items.h.total === "" ? "00.00" : items.h.total) + parseFloat(items.h.taxa === "" ? "00.00" : items.h.taxa) + parseFloat(items.h.cess === "" ? "00.00" : items.h.cess);
        val = val + parseFloat(items.i.total === "" ? "00.00" : items.i.total) + parseFloat(items.i.taxa === "" ? "00.00" : items.i.taxa) + parseFloat(items.i.cess === "" ? "00.00" : items.i.cess);
        val = val + parseFloat(items.j.total === "" ? "00.00" : items.j.total) + parseFloat(items.j.taxa === "" ? "00.00" : items.j.taxa) + parseFloat(items.j.cess === "" ? "00.00" : items.j.cess);
        val = val + parseFloat(items.k.total === "" ? "00.00" : items.k.total) + parseFloat(items.k.taxa === "" ? "00.00" : items.k.taxa) + parseFloat(items.k.cess === "" ? "00.00" : items.k.cess);
        val = val + parseFloat(items.l.total === "" ? "00.00" : items.l.total) + parseFloat(items.l.taxa === "" ? "00.00" : items.l.taxa) + parseFloat(items.l.cess === "" ? "00.00" : items.l.cess);
        return val;
    }

    getTotalI(items) {
        let val = 0;
        val = val + parseFloat(items.a.taxa === "" ? "00.00" : items.a.taxa);
        val = val + parseFloat(items.b.taxa === "" ? "00.00" : items.b.taxa);
        val = val + parseFloat(items.c.taxa === "" ? "00.00" : items.c.taxa);
        val = val + parseFloat(items.d.taxa === "" ? "00.00" : items.d.taxa);
        val = val + parseFloat(items.e.taxa === "" ? "00.00" : items.e.taxa);
        val = val + parseFloat(items.f.taxa === "" ? "00.00" : items.f.taxa);
        val = val + parseFloat(items.g.taxa === "" ? "00.00" : items.g.taxa);
        val = val + parseFloat(items.h.taxa === "" ? "00.00" : items.h.taxa);
        val = val + parseFloat(items.i.taxa === "" ? "00.00" : items.i.taxa);
        val = val + parseFloat(items.j.taxa === "" ? "00.00" : items.j.taxa);
        val = val + parseFloat(items.k.taxa === "" ? "00.00" : items.k.taxa);
        val = val + parseFloat(items.l.taxa === "" ? "00.00" : items.l.taxa);
        return val;
    }

    getTotalC(items) {
        let val = 0;
        val = val + parseFloat(items.a.taxb === "" ? "00.00" : items.a.taxb);
        val = val + parseFloat(items.b.taxb === "" ? "00.00" : items.b.taxb);
        val = val + parseFloat(items.c.taxb === "" ? "00.00" : items.c.taxb);
        val = val + parseFloat(items.d.taxb === "" ? "00.00" : items.d.taxb);
        val = val + parseFloat(items.e.taxb === "" ? "00.00" : items.e.taxb);
        val = val + parseFloat(items.f.taxb === "" ? "00.00" : items.f.taxb);
        val = val + parseFloat(items.g.taxb === "" ? "00.00" : items.g.taxb);
        val = val + parseFloat(items.h.taxb === "" ? "00.00" : items.h.taxb);
        val = val + parseFloat(items.i.taxb === "" ? "00.00" : items.i.taxb);
        val = val + parseFloat(items.j.taxb === "" ? "00.00" : items.j.taxb);
        val = val + parseFloat(items.k.taxb === "" ? "00.00" : items.k.taxb);
        val = val + parseFloat(items.l.taxb === "" ? "00.00" : items.l.taxb);
        return val;
    }

    getTotalS(items) {
        let val = 0;
        val = val + parseFloat(items.a.taxb === "" ? "00.00" : items.a.taxb);
        val = val + parseFloat(items.b.taxb === "" ? "00.00" : items.b.taxb);
        val = val + parseFloat(items.c.taxb === "" ? "00.00" : items.c.taxb);
        val = val + parseFloat(items.d.taxb === "" ? "00.00" : items.d.taxb);
        val = val + parseFloat(items.e.taxb === "" ? "00.00" : items.e.taxb);
        val = val + parseFloat(items.f.taxb === "" ? "00.00" : items.f.taxb);
        val = val + parseFloat(items.g.taxb === "" ? "00.00" : items.g.taxb);
        val = val + parseFloat(items.h.taxb === "" ? "00.00" : items.h.taxb);
        val = val + parseFloat(items.i.taxb === "" ? "00.00" : items.i.taxb);
        val = val + parseFloat(items.j.taxb === "" ? "00.00" : items.j.taxb);
        val = val + parseFloat(items.k.taxb === "" ? "00.00" : items.k.taxb);
        val = val + parseFloat(items.l.taxb === "" ? "00.00" : items.l.taxb);
        return val;
    }

    getTotalCess(items) {
        let val = 0;
        val = val + parseFloat(items.a.cess === "" ? "00.00" : items.a.cess);
        val = val + parseFloat(items.b.cess === "" ? "00.00" : items.b.cess);
        val = val + parseFloat(items.c.cess === "" ? "00.00" : items.c.cess);
        val = val + parseFloat(items.d.cess === "" ? "00.00" : items.d.cess);
        val = val + parseFloat(items.e.cess === "" ? "00.00" : items.e.cess);
        val = val + parseFloat(items.f.cess === "" ? "00.00" : items.f.cess);
        val = val + parseFloat(items.g.cess === "" ? "00.00" : items.g.cess);
        val = val + parseFloat(items.h.cess === "" ? "00.00" : items.h.cess);
        val = val + parseFloat(items.i.cess === "" ? "00.00" : items.i.cess);
        val = val + parseFloat(items.j.cess === "" ? "00.00" : items.j.cess);
        val = val + parseFloat(items.k.cess === "" ? "00.00" : items.k.cess);
        val = val + parseFloat(items.l.cess === "" ? "00.00" : items.l.cess);
        return val;
    }

    wordify(amount) {
        return this.RsPaise(amount);
    };

    Rs(amount) {
        var words = new Array();
        words[0] = 'Zero'; words[1] = 'One'; words[2] = 'Two'; words[3] = 'Three'; words[4] = 'Four'; words[5] = 'Five'; words[6] = 'Six'; words[7] = 'Seven'; words[8] = 'Eight'; words[9] = 'Nine'; words[10] = 'Ten'; words[11] = 'Eleven'; words[12] = 'Twelve'; words[13] = 'Thirteen'; words[14] = 'Fourteen'; words[15] = 'Fifteen'; words[16] = 'Sixteen'; words[17] = 'Seventeen'; words[18] = 'Eighteen'; words[19] = 'Nineteen'; words[20] = 'Twenty'; words[30] = 'Thirty'; words[40] = 'Forty'; words[50] = 'Fifty'; words[60] = 'Sixty'; words[70] = 'Seventy'; words[80] = 'Eighty'; words[90] = 'Ninety'; var op;
        amount = amount.toString();
        var atemp = amount.split('.');
        var number = atemp[0].split(',').join('');
        var n_length = number.length;
        var words_string = '';
        if (n_length <= 9) {
            var n_array = new Array(0, 0, 0, 0, 0, 0, 0, 0, 0);
            var received_n_array = new Array();
            for (var i = 0; i < n_length; i++) {
                received_n_array[i] = number.substr(i, 1);
            }
            for (var i = 9 - n_length, j = 0; i < 9; i++, j++) {
                n_array[i] = received_n_array[j];
            }
            for (var i = 0, j = 1; i < 9; i++, j++) {
                if (i == 0 || i == 2 || i == 4 || i == 7) {
                    if (n_array[i] == 1) {
                        n_array[j] = 10 + parseInt(n_array[j]);
                        n_array[i] = 0;
                    }
                }
            }
            let value = '';
            for (var i = 0; i < 9; i++) {
                if (i == 0 || i == 2 || i == 4 || i == 7) {
                    value = n_array[i] * 10;
                } else {
                    value = n_array[i];
                }
                if (value != 0) {
                    words_string += words[value] + ' ';
                }
                if ((i == 1 && value != 0) || (i == 0 && value != 0 && n_array[i + 1] == 0)) {
                    words_string += 'Crores ';
                }
                if ((i == 3 && value != 0) || (i == 2 && value != 0 && n_array[i + 1] == 0)) {
                    words_string += 'Lakhs ';
                }
                if ((i == 5 && value != 0) || (i == 4 && value != 0 && n_array[i + 1] == 0)) {
                    words_string += 'Thousand ';
                }
                if (i == 6 && value != 0 && (n_array[i + 1] != 0 && n_array[i + 2] != 0)) {
                    words_string += 'Hundred and ';
                } else if (i == 6 && value != 0) {
                    words_string += 'Hundred ';
                }
            }
            words_string = words_string.split(' ').join(' ');
        }
        return words_string;
    }

    RsPaise(n) {
        let nums = n.toString().split('.');
        var whole = this.Rs(nums[0]);
        if (nums[1] == null) nums[1] = 0;
        if (nums[1].length == 1) nums[1] = nums[1] + '0';
        if (nums[1].length > 2) { nums[1] = nums[1].substring(2, nums[1].length - 1) }
        let op = "";
        if (nums.length == 2) {
            if (nums[0] <= 9) { nums[0] = nums[0] * 10 } else { nums[0] = nums[0] };
            var fraction = this.Rs(nums[1]);
            if (whole == '' && fraction == '') { op = 'Zero only'; }
            if (whole == '' && fraction != '') { op = 'paise ' + fraction + ' only'; }
            if (whole != '' && fraction == '') { op = 'Rupees ' + whole + ' only'; }
            if (whole != '' && fraction != '') { op = 'Rupees ' + whole + 'and paise ' + fraction + ' only'; }
            let amt = n;
            if (amt > 999999999.99) { op = 'Oops!!! The amount is too big to convert'; }
            if (isNaN(amt) == true) { op = 'Error : Amount in number appears to be incorrect. Please Check.'; }
            return op;
        }
    }

}