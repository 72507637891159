import React, { useEffect, useState, useRef } from 'react';

import { useRecoilState } from 'recoil';
import { pathAtom, loadingAtom, returnDateAtom, gstrUserAtom, digErrorAtom, userIDAtom, userAtom, gstrFileAtom } from '../../../core/config/atoms';

import Moment from 'moment';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Dialog } from '@headlessui/react';
import html2pdf from 'html2pdf.js/dist/html2pdf';

import imgLoader from '../../../assets/images/cloading.gif';

import ServiceLMS from '../../../services/serviceLMS';
import ServiceUtility from '../../../services/serviceUtility';
import ServiceGSTF from '../../../services/serviceGSTF';
import ServiceLog from '../../../services/serviceLog';

import PanelPreviewPDF from '../PanelPreviewPDF';
import PanelFiledPDF from '../PanelFiledPDF';

export default function PanelVerifyHome() {

    const [userID] = useRecoilState(userIDAtom);
    const [loading, setLoading] = useRecoilState(loadingAtom);
    const [gstrf, setGSTRF] = useRecoilState(gstrFileAtom);
    const [gDate] = useRecoilState(returnDateAtom);
    const [userAuth] = useRecoilState(userAtom);

    const [, setPath] = useRecoilState(pathAtom);
    const [, setDigError] = useRecoilState(digErrorAtom);

    const [gstUsers, setGSTUsers] = useState([]);

    const [gstUser, setGSTUser] = useState({});

    const [setoff, setSetoff] = useState({
        cess: '0.00',
        igst: {
            igsta: '0.00',
            igstb: '0.00',
            igstc: '0.00',
        },
        cgst: {
            cgsta: '0.00',
            cgstb: '0.00',
        },
        sgst: {
            sgsta: '0.00',
            sgstb: '0.00',
        },
        bal: {
            bala: '0.00',
            balb: '0.00',
            balc: '0.00',
            bald: '0.00',
        }
    });

    const [otpText, setOTPText] = useState('');
    const [ack, setAck] = useState('');

    const [otp, setOTP] = useState(0);

    const [, setSelectedUser] = useState(-1);

    const [isSelected, setIsSelected] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [otpError, setOTPError] = useState(false);
    const [submit, setSubmit] = useState(false);
    const [digWarnning, setDigWarnning] = useState(false);

    const [isPrintA, setIsPrintA] = useState(false);
    const [isPrintB, setIsPrintB] = useState(false);

    const pdfRefA = useRef(null);
    const pdfRefB = useRef(null);

    const lmsService = new ServiceLMS();
    const utilService = new ServiceUtility();
    const fileService = new ServiceGSTF();
    const logService = new ServiceLog();

    const formSchema = Yup.object().shape({
        authveri: Yup.boolean().required('This information is required'),
    });

    const { values, errors, touched, dirty, handleChange, handleSubmit, handleReset, resetForm, setValues } = useFormik({
        initialValues: {
            authveri: false,
            authsign: '',
        },
        validationSchema: formSchema,
        onSubmit: values => {
            let ot = utilService.getCode();
            setOTP(ot);
            setSubmit(true);
            setIsOpen(true);
        }
    });

    const gotoPath = (path) => {
        setLoading(true);
        setPath(path);
    }

    const getEnable = () => {
        if (values.authveri && !submit) {
            if (isSelected) {
                return false;
            } else {
                return true;
            }
        } else {
            return true;
        }
    }

    const handleOnChangeForm = (e) => {
        var { value, name } = e.target;
        setOTPText(value);
    }

    const validate = () => {
        const otpRegex = RegExp(
            /^[0-9]{6}$/
        );
        if (otpRegex.test(otpText)) {
            if (otp == otpText) {
                setLoading(true);
                let a = `AB${utilService.getAckCode()}W`;
                let body = { 'lid': userID, 'month': gDate, uid: values.authsign, ack: a };
                fileService.submitGSTRFDoc(body).then((res) => {
                    setAck(a);
                    setLoading(false);
                    setIsOpen(false);
                    if (res.statuscode === 200) {
                        setGSTRF(res.data);
                        genLog();
                    } else {
                        setDigError({ title: 'Error', msg: 'Server error please try again!', isToggle: true });
                    }
                });
            } else {
                setOTPError(true);
            }
        } else {
            setOTPError(true);
        }
    }

    const genLog = () => {
        let body = {
            id: userAuth.id,
            name: userAuth.name,
            msg: "User filled GSTR-3B file for the month of " + Moment(gDate).format('MMMM - YYYY').toString(),
            role: userAuth.role,
        }
        logService.setLog(body).then((res) => {
            setDigWarnning(true);
            loadData();
        });
    }

    const handleGeneratePDFA = async () => {
        const subComponent = pdfRefA.current;
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
        const options = {
            margin: 0.2,
            filename: `Filed 3B - ${Moment(gDate).format("MMM")} Month.pdf`,
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: { scale: 2 },
            jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
            preserve: true,
        };
        html2pdf().set(options).from(subComponent).save().then(() => {
            setIsPrintA(false);
        });
    };

    const handleGeneratePDFB = async () => {
        const subComponent = pdfRefB.current;
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
        const options = {
            margin: 0.2,
            filename: `Preview Draft GSTR3B.pdf`,
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: { scale: 2 },
            jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
            preserve: true,
        };
        html2pdf().set(options).from(subComponent).save().then(() => {
            setIsPrintB(false);
        });
    };

    const loadData = () => {
        setLoading(true);
        (async function () {
            let body = {
                id: userID
            };
            var res = await lmsService.getLMSUserByID(body);
            setGSTUser(res.data['B']);
            setGSTUsers(res.data['B']['users']);

            let resB = await fileService.getGSTLedger({ lid: userID, month: gDate });
            if (resB.status) {
                setSetoff({ ...resB.data.data.setoff });
            }

            setLoading(false);
            window.scrollTo({
                top: 0,
                behavior: 'smooth',
            });
        })();
    }

    useEffect(() => {
        loadData();
    }, []);

    return (
        <>
            <div class="content-wrapper fade-ng-cloak">
                {
                    <Dialog open={isOpen} onClose={() => setIsOpen(false)}>
                        <div id="confirmDlg" class="modal fade fade-scale in" role="dialog" style={{ display: 'block', paddingRight: '17px' }}>
                            <div class="modal-dialog">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <p className='text-left text-2xl text-blue-900 font-semibold'>Validate One Time Password(OTP)</p>
                                    </div>
                                    <div class="modal-body">
                                        <alert-message ng-show="showMsg" title="" type="msg alert-success" message="" class="ng-hide">
                                            <div class="alert alert-msg alert-success">One Time Password(OTP) has been sent : Your OTP is {otp}.</div>
                                        </alert-message>
                                        <div class="row">
                                            <div class={`col-xs-12 ${otpError ? 'has-error' : 'has-success'}`}>
                                                <label for="veriOTP" class="reg m-cir">Enter One Time Password(OTP)</label>
                                                <input id="veriOTP" name="veriOTP" class="form-control" type="text" placeholder="Enter OTP" maxLength={6} required="" onChange={(e) => {
                                                    e.target.value = utilService.formatOnlyNumber(e.target.value);
                                                    handleOnChangeForm(e);
                                                }} />
                                                {otpError && <p class="err" data-ng-bind="errVar">Please enter a valid OTP.</p>}
                                            </div>
                                        </div>
                                        <p></p>
                                    </div>
                                    <div class="modal-footer">
                                        <a class="btn btn-default" onClick={() => { setIsOpen(false) }}>CANCEL</a>
                                        <a class="btn btn-primary" onClick={() => { validate() }}>VERIFY</a>
                                        <a class="btn btn-primary" disabled={true}>RESEND OTP</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Dialog>
                }
                <Dialog open={digWarnning} onClose={() => setDigWarnning(false)} className='overflow-y-auto overflow-x-hidden z-50 w-full h-modal md:h-full fixed top-0 left-0 bg-black bg-opacity-40'>
                    <div className='w-full h-full fixed z-50 flex items-center justify-center bg-black bg-opacity-60'>
                        <div id="confirmDlg" className="modal fade fade-scale in" role="dialog" style={{ display: 'block', paddingRight: '17px' }}>
                            <div class="modal-dialog sweet">
                                <div class="modal-content">
                                    <div class="modal-body">
                                        <div className="m-icon m-success animate">
                                            <span className="m-line m-tip animateSuccessTip"></span>
                                            <span className="m-line m-long animateSuccessLong"></span>
                                            <div className="m-placeholder"></div>
                                            <div className="m-fix"></div>
                                        </div>
                                        <h2>Filing Successful</h2>
                                        <p>GSTR-3B of GSTIN <b className='text-black'>{gstUser.gstn}</b> for the period <b className='text-black'>{Moment(gDate).format('MMMM - YYYY')}</b> has been successfully filed on <b className='text-black'>{Moment(gDate).add(1, 'month').add(19, 'days').format('DD/MM/YYYY hh:mm')}</b>.<br />
                                            The Acknowledgment Reference Number: is <b className='text-black'>{ack}</b>.<br />
                                            The GSTR-3B can be viewed on your Dashboard Login=&gt;Taxpayer=&gt;Dashboard=&gt;Returns=&gt;View e-filed return<br />
                                            This message is sent to your registered Email ID and Mobile Number.
                                        </p>
                                    </div>
                                    <div class="modal-footer">
                                        <a class="btn btn-primary" onClick={() => { setDigWarnning(false) }}>OK</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Dialog>
                <div class="dimmer-holder" style={{ display: 'none' }}>
                    <div id="dimmer"></div>
                </div>
                {
                    loading && <div className='absolute z-50 w-full h-screen flex items-center justify-center bg-gray-200 bg-opacity-25'>
                        <img src={imgLoader} alt="loader" />
                    </div>
                }
                <div class="container">
                    <div class="mypage">
                        <div class="row">
                            <div class="col-xs-10">
                                <div>
                                    <ol class="breadcrumb">
                                        <li>
                                            <a onClick={() => { gotoPath('gstr') }}>Dashboard</a>
                                        </li>
                                        <li>
                                            <a onClick={() => { gotoPath('gstRetrun') }}>Returns</a>
                                        </li>
                                        <li>
                                            <a onClick={() => { gotoPath('gstr3BForm') }}>GSTR-3B</a>
                                        </li>
                                        <li>Filing of Tax</li>
                                    </ol>
                                </div>
                            </div>
                            <div class="col-xs-2">
                                <div class="lang dropdown">
                                    <span class="dropdown-toggle" data-toggle="dropdown" data-ng-bind="selectedLang">English</span>
                                    <ul class="dropdown-menu lang-dpdwn">
                                        <li data-ng-repeat="language in languages">
                                            <a data-ng-click="selectLang(language.cd)" data-ng-bind="language.nm">English</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        {
                            !loading && <div data-ng-controller="mainctrl" ng-view="">
                                <div>
                                    <div id="mai">
                                        <div class="tabpane" style={{ minHeight: '263px !important' }}>
                                            <h4>Verification</h4>
                                            <form className='mt-2' onSubmit={handleSubmit} onReset={handleReset} autocomplete="off" novalidate="">
                                                <fieldset>
                                                    <div class="row">
                                                        <div class="inner">
                                                            <div class="col-xs-12 radio-order">
                                                                <input type="checkbox" class="chkbx" id="authveri" required="" name="authveri" onChange={handleChange} value={values.authveri} checked={values.authveri} disabled={gstrf.status === "done"} />
                                                                <label for="authveri"><span>I/We hereby solemnly affirm and declare that the information given herein above is true and correct (in respect of Form GSTR-3B) to the best of my knowledge and belief and nothing has been concealed therefrom.</span></label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {
                                                        values.authveri && <div class="row mt-2">
                                                            <div class="inner">
                                                                <div class="col-xs-12 col-sm-6">
                                                                    <label for="authsign" class="reg m-cir">Authorized Signatory</label>
                                                                    <select class="form-control" id="authsign" name="authsign" required="" autofocus="" onChange={(e) => {
                                                                        let value = e.target.value;
                                                                        if (value >= 0) {
                                                                            setIsSelected(true);
                                                                            setSelectedUser(parseInt(value));
                                                                        } else {
                                                                            setIsSelected(false);
                                                                            setSelectedUser(-1);
                                                                        }
                                                                        handleChange(e);
                                                                    }} value={values.authsign} disabled={gstrf.status === "done"}>
                                                                        <option value="">Select</option>
                                                                        {
                                                                            gstUsers.map((item, index) => {
                                                                                return <option value={index} className='uppercase'>{item.pd_fnm} {item.pd_mname} {item.pd_lname}[{item.pan.toUpperCase()}]</option>
                                                                            })
                                                                        }
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }
                                                </fieldset>
                                                <div class="row next-tab-nav">
                                                    <div class="inner">
                                                        <div class="col-xs-12 text-right">
                                                            <a class="btn btn-default" style={{ marginRight: '8px' }} onClick={() => {
                                                                gotoPath('gstr3BForm');
                                                            }}>Back</a>
                                                            <button class="btn btn-primary" type="button" disabled={true}>TAX LIABILITY BREAKUP, AS APPLICABLE</button>&nbsp;
                                                            <button class="btn btn-primary" type="button" onClick={() => {
                                                                if (gstrf.status !== "done") {
                                                                    setIsPrintB(true);
                                                                    handleGeneratePDFB();
                                                                } else {
                                                                    setIsPrintA(true);
                                                                    handleGeneratePDFA();
                                                                }
                                                            }}>{gstrf.status === "done" ? "DOWNLOAD Filed GSTR-3B" : "PREVIEW DRAFT GSTR-3B"}</button>&nbsp;
                                                            <button class="btn btn-primary hidden-sm hidden-xs" type="button" style={{ marginRight: '8px' }} disabled="disabled">File GSTR-3B with DSC</button>
                                                            <button class="btn btn-primary hidden-sm hidden-xs" type="submit" disabled={getEnable()}>File GSTR-3B with EVC</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                            <div class="row p-8">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
            <div ref={pdfRefA}>
                {isPrintA && <PanelFiledPDF setoff={setoff} />}
            </div>
            <div ref={pdfRefB}>
                {isPrintB && <PanelPreviewPDF setoff={setoff} />}
            </div>
        </>
    );
}
