import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import PathAdmin from './paths/PathAdmin';
import PathGST from './paths/PathGST';

export default function App() {
    return (
        <>
            <Router>
                <Routes>
                    <Route exact path="/" element={<PathGST />} />
                    <Route exact path="/gst-admin" element={<PathAdmin />} />
                </Routes>
            </Router>
        </>
    )
}