import React, { useState, useEffect } from 'react';

import { useRecoilState } from 'recoil';
import { pathAtom, gstrpathAtom, digErrorAtom, returnDateAtom, loadingAtom, gstrUserAtom, gstrQueryAtom, userIDAtom } from '../../../core/config/atoms';

import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import { ThemeProvider } from "@material-ui/styles";
import { createMuiTheme } from "@material-ui/core";
import DateFnsUtils from '@date-io/date-fns';
import Moment from 'moment';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import ServiceOption from '../../../services/serviceOptions';
import ServiceGSTR from '../../../services/serviceGSTR';
import ServiceUtility from '../../../services/serviceUtility';

import '../css/main.css';
import '../css/style.css';

import imgLoader from '../../../assets/images/cloading.gif';

export default function PanelB2CLForm() {

    const [path, setPath] = useRecoilState(pathAtom);
    const [gstrPath, setGSTRPath] = useRecoilState(gstrpathAtom);
    const [digError, setDigError] = useRecoilState(digErrorAtom);
    const [gDate] = useRecoilState(returnDateAtom);
    const [loading, setLoading] = useRecoilState(loadingAtom);
    const [GSTUser] = useRecoilState(gstrUserAtom);
    const [query, setQuery] = useRecoilState(gstrQueryAtom);
    const [userID] = useRecoilState(userIDAtom);

    const [states, setStates] = useState([]);

    const [selOption, setSelOption] = useState('');
    const [invno, setInvno] = useState('');
    const [invval, setInvval] = useState('');
    const [editid, setEditid] = useState('');
    const [title, setTitle] = useState('');

    const [mainDate, setMainDate] = useState(Moment(gDate).add('month', 1).toDate());

    const [isItem, setIsItem] = useState(false);
    const [submit, setSubmit] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [isView, setIsView] = useState(false);
    const [isAlert, setIsAlrt] = useState(false);
    const [isErrorAlert, setIsErrorAlert] = useState(false);
    const [isItems, setIsItems] = useState(false);

    const optionService = new ServiceOption();
    const returnService = new ServiceGSTR();
    const utilService = new ServiceUtility();

    const materialTheme = createMuiTheme({
        overrides: {
            MuiTypography: {
                subtitle1: {
                    fontSize: '16px',
                },
                caption: {
                    fontSize: '16px',
                    fontWeight: 'bold'
                },
                body1: {
                    "fontSize": '16px',
                },
                body2: {
                    "fontSize": '16px',
                }
            },
            MuiPickersToolbar: {
                toolbar: {
                    backgroundColor: '#239B56',
                },
            },
            MuiPickersDay: {
                day: {
                    color: '#17202A',
                },
                daySelected: {
                    backgroundColor: '#239B56',
                },
                dayDisabled: {
                    color: '#808B96',
                },
                current: {
                    color: '#239B56',
                },
            },
            MuiButton: {
                textPrimary: {
                    color: '#2E86C1',
                    fontSize: '12px',
                    border: '1px solid #2E86C1',
                    backgroundColor: 'transprent'
                }
            },
        },
    });

    const formVSchema = Yup.object().shape({
        pos: Yup.string().required('This information is required'),
        supplyty: Yup.string().required('This information is required'),
        inv_no: Yup.string().matches(/^(?=[/-0]*[a-zA-Z0-9/-]*[a-zA-Z1-9]+[a-zA-Z0-9/-]*).{1,16}$/, { message: 'Please enter valid invoice number' }).max(16, { message: 'Please enter valid invoice number' }).required('This information is required'),
        invdate: Yup.string().required('This information is required'),
        invval: Yup.string().max(13, { message: 'Please enter valid amout' }).required('This information is required'),
    });

    const { values, errors, touched, setValues, handleChange, handleSubmit, handleReset, resetForm } = useFormik({
        initialValues: {
            pos: '',
            supplyty: 'Inter-state',
            inv_no: '',
            invdate: '',
            invval: '',
            items: {
                a: {
                    total: '',
                    taxa: '',
                    taxb: '',
                    cess: '',
                },
                b: {
                    total: '',
                    taxa: '',
                    taxb: '',
                    cess: '',
                },
                c: {
                    total: '',
                    taxa: '',
                    taxb: '',
                    cess: '',
                },
                d: {
                    total: '',
                    taxa: '',
                    taxb: '',
                    cess: '',
                },
                e: {
                    total: '',
                    taxa: '',
                    taxb: '',
                    cess: '',
                },
                f: {
                    total: '',
                    taxa: '',
                    taxb: '',
                    cess: '',
                },
                g: {
                    total: '',
                    taxa: '',
                    taxb: '',
                    cess: '',
                },
                h: {
                    total: '',
                    taxa: '',
                    taxb: '',
                    cess: '',
                },
                i: {
                    total: '',
                    taxa: '',
                    taxb: '',
                    cess: '',
                },
                j: {
                    total: '',
                    taxa: '',
                    taxb: '',
                    cess: '',
                },
                k: {
                    total: '',
                    taxa: '',
                    taxb: '',
                    cess: '',
                },
                l: {
                    total: '',
                    taxa: '',
                    taxb: '',
                    cess: '',
                }
            }
        },
        validationSchema: formVSchema,
        onSubmit: values => {
            if (checkItems()) {
                setIsItems(false);
                let body = {};
                if (parseFloat(values.invval) >= 250000.00) {
                    setSubmit(true);
                    if (isEdit) {
                        values.status = 'EDIT';
                        body = {
                            id: editid,
                            data: values,
                        }
                        returnService.updateGstrDoc(body).then((res) => {
                            if (res.status) {
                                gotoGstrPath('b2cla');
                            } else {
                                setDigError({ title: 'Error', msg: 'Server error please try again!', isToggle: true });
                            }
                            setSubmit(false);
                        });
                    } else {
                        values.status = 'ADD';
                        body = {
                            month: gDate,
                            lid: userID,
                            type: 'b2cl',
                            data: values,
                        }
                        returnService.setGstrDoc(body).then((res) => {
                            if (res.status) {
                                if (res.statuscode === 202) {
                                    setInvno(values.inv_no);
                                } else {
                                    showAlert();
                                    resetData(values);
                                }
                            } else {
                                setDigError({ title: 'Error', msg: 'Server error please try again!', isToggle: true });
                            }
                            setSubmit(false);
                            window.scrollTo({
                                top: 0,
                                behavior: 'smooth',
                            });
                        });
                    }
                } else {
                    setInvval("Error");
                    setSubmit(false);
                }
            } else {
                setIsItems(true);
            }
        }
    });

    const checkItems = () => {
        return utilService.getTotalValue(values.items) === 0 ? false : true;
    }

    const gotoPath = (path) => {
        setLoading(true);
        setPath(path);
    }

    const gotoGstrPath = (path) => {
        setGSTRPath(path);
        gotoPath('gstrFile');
    }

    const openPicker = (id) => {
        setSelOption(id);
        setIsOpen(true);
    }

    const handleDateChange = (d) => {
        switch (selOption) {
            case 'invdate':
                values.invdate = Moment(d).format('DD-MM-YYYY').toString();
                break;
            default:
                break;
        }
        setSelOption('');
    }

    const getFormatvalue = (val) => {
        if (!val) return val;
        let number = val.replace(/[^\d.]/g, "");
        if (val.includes(".")) {
            const a = number.split(".");
            if (a[1].length > 2) {
                a[1] = a[1].substring(0, 2);
            }
            if (a[0].length > 13) {
                a[0] = a[0].substring(0, 13);
            }
            number = a[0] + "." + a[1];
        } else {
            if (number.length > 13) {
                number = number.substring(0, 13);
            }
        }
        return number;
    }

    const getSubFormatvalue = (val) => {
        if (!val) return val;
        let number = val.replace(/[^\d.]/g, "");
        if (val.includes(".")) {
            const a = number.split(".");
            if (a[1].length > 2) {
                a[1] = a[1].substring(0, 2);
            }
            if (a[0].length > 11) {
                a[0] = a[0].substring(0, 11);
            }
            number = a[0] + "." + a[1];
        } else {
            if (number.length > 11) {
                number = number.substring(0, 11);
            }
        }
        return number;
    }

    const showAlert = () => {
        setIsAlrt(true);
        setTimeout(
            () => setIsAlrt(false),
            5000
        );
    }

    const resetData = (val) => {
        resetForm();
        setIsOpen(false);
        setIsEdit(false);
        setValues({
            pos: '',
            supplyty: val.supplyty,
            inv_no: '',
            invdate: '',
            invval: '',
            items: {
                a: {
                    total: '',
                    taxa: '',
                    taxb: '',
                    cess: '',
                },
                b: {
                    total: '',
                    taxa: '',
                    taxb: '',
                    cess: '',
                },
                c: {
                    total: '',
                    taxa: '',
                    taxb: '',
                    cess: '',
                },
                d: {
                    total: '',
                    taxa: '',
                    taxb: '',
                    cess: '',
                },
                e: {
                    total: '',
                    taxa: '',
                    taxb: '',
                    cess: '',
                },
                f: {
                    total: '',
                    taxa: '',
                    taxb: '',
                    cess: '',
                },
                g: {
                    total: '',
                    taxa: '',
                    taxb: '',
                    cess: '',
                },
                h: {
                    total: '',
                    taxa: '',
                    taxb: '',
                    cess: '',
                },
                i: {
                    total: '',
                    taxa: '',
                    taxb: '',
                    cess: '',
                },
                j: {
                    total: '',
                    taxa: '',
                    taxb: '',
                    cess: '',
                },
                k: {
                    total: '',
                    taxa: '',
                    taxb: '',
                    cess: '',
                },
                l: {
                    total: '',
                    taxa: '',
                    taxb: '',
                    cess: '',
                }

            }
        });
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    }

    const loadData = () => {
        (async function () {
            var resB = await optionService.getStates({});
            setStates(resB.data);
            if (query.type === 'view') {
                let body = {
                    id: query.data
                };
                var doc = await returnService.getGstrDocById(body);
                setValues({ ...values, ...doc.data.data });
                setIsView(true);
                setIsItem(true);
                setQuery({ type: '', data: '' });
                setTitle('View');
            } else if (query.type === 'edit') {
                let body = {
                    id: query.data
                };
                var doc = await returnService.getGstrDocById(body);
                setValues({ ...values, ...doc.data.data });
                setIsItem(true);
                setIsEdit(true);
                setIsView(false);
                if (doc.data.status === 'pending') {
                    setIsErrorAlert(true);
                } else {
                    setIsErrorAlert(false);
                }
                setEditid(doc.data._id);
                setQuery({ type: '', data: '' });
                setTitle('Edit');
            } else {
                setIsView(false);
                setIsItem(true);
                setQuery({ type: '', data: '' });
                setTitle('');
            }
            setLoading(false);
            window.scrollTo({
                top: 0,
                behavior: 'smooth',
            });
        })();
    }

    useEffect(() => {
        loadData();
    }, []);

    return (
        <div class="content-wrapper fade-ng-cloak">
            <div class="dimmer-holder" style={{ display: 'none' }}>
                <div id="dimmer"></div>
            </div>
            {
                loading && <div className='absolute z-50 w-full h-screen flex items-center justify-center bg-gray-200 bg-opacity-25'>
                    <img src={imgLoader} alt="loader" />
                </div>
            }
            <div class="container">
                <div class="mypage">
                    <div class="row" data-ng-controller="transctrl" data-ng-init="init('returns')">
                        <div class="col-xs-10">
                            <div data-breadcrumb="" name="Dashboard">
                                <ol class="breadcrumb" data-ng-controller="crumbCtrl">
                                    <li><a onClick={() => {
                                        gotoPath('gstr')
                                    }}>Dashboard</a></li>
                                    <li><a onClick={() => {
                                        gotoPath('gstRetrun')
                                    }}>Returns</a></li>
                                    <li><a onClick={() => {
                                        gotoPath('gstrForm')
                                    }}>GSTR-1/IFF</a></li>
                                    <li>B2CL</li>
                                </ol>
                            </div>
                        </div>
                        <div class="col-xs-2">
                            <div class="lang dropdown">
                                <span class="dropdown-toggle" data-toggle="dropdown" data-ng-bind="selectedLang">English</span>
                                <ul class="dropdown-menu lang-dpdwn">
                                    <li data-ng-repeat="language in languages">
                                        <a data-ng-click="selectLang(language.cd)" data-ng-bind="language.nm">English</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div data-ng-controller="mainctrl" ng-view="">
                        {isAlert && <alert-message ng-show="showMsg" title="" type="msg alert-success" message="" class="ng-hide"><div class="alert alert-msg alert-success">Request accepted successfully<a class="close" onClick={() => { setIsAlrt(false) }} data-dismiss="alert" aria-label="close">×</a><strong></strong>.</div></alert-message>}
                        {isErrorAlert && <alert-message ng-show="showMsg" title="" type="msg alert-danger" message="" class="ng-hide"><div class="alert alert-msg alert-danger"><b className='alert-msg alert-danger'>Error!</b> Invoice may already exist in another section or type. Do enter the correct invoice number or delete existing invoice and upload again<a class="close" onClick={() => { setIsAlrt(false) }} data-dismiss="alert" aria-label="close">×</a><strong></strong>.</div></alert-message>}
                        <form name="b2cl_addpage" autocomplete="off" novalidate="" onSubmit={handleSubmit} onReset={handleReset}>
                            <div class="row invsumm">
                                <div class="col-sm-12 col-xs-12">
                                    <h4 data-ng-hide="posEditDis || viewb2cl" data-ng-bind="trans.TITLE_B2C_INVOICES_DETAILS">B2C(Large) Invoices - {title} Details</h4>
                                </div>
                            </div>
                            <div class="tabpane">
                                <div class="rettbl-format" style={{ marginTop: '-20px' }}>
                                    <div class="row">
                                        <div class="col-sm-8" style={{ paddingLeft: '0px' }}>
                                            <a onClick={() => {
                                                gotoGstrPath('b2cla')
                                            }}>
                                                <i class="fa fa-arrow-circle-left" style={{ fontSize: 'xx-large', color: '#17c4bb' }}></i>
                                            </a>
                                        </div>
                                        <div class="col-sm-4" style={{ paddingRight: '0px' }}>
                                            <span class="mand-text pull-right" data-ng-bind="trans.HLP_MAND_FIELD">Indicates Mandatory Fields</span>
                                        </div>
                                    </div>
                                </div>
                                <fieldset class="tbl-format rettbl-format" disabled={isView}>
                                    <div class="row">
                                        <div class="col-sm-8">
                                            <br />
                                            <input type="checkbox" class="chkbx ng-pristine ng-untouched ng-valid ng-empty" id="rate_flag" data-ng-model="rate_flag" data-ng-checked="rate_flag=='Y'" data-ng-true-value="'Y'" data-ng-false-value="'N'" data-ng-change="cleartaxes()" data-ng-disabled="viewb2cl" disabled={true} />
                                            <label for="rate_flag">Is the supply eligible to be taxed at a differential percentage (%) of the existing rate of tax, as notified by the Government?</label>
                                        </div>
                                    </div>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <ThemeProvider theme={materialTheme}>
                                            <DatePicker
                                                variant="dialog"
                                                open={isOpen}
                                                onOpen={() => setIsOpen(true)}
                                                onClose={() => setIsOpen(false)}
                                                label="Open me from button"
                                                format="dd/MM/yyyy"
                                                value={mainDate}
                                                maxDate={mainDate}
                                                minDate={new Date('06-01-2017')}
                                                onChange={handleDateChange}
                                                style={{ display: 'none' }}
                                            />
                                        </ThemeProvider>
                                    </MuiPickersUtilsProvider>
                                    <div class="row">
                                        <div class={`col-sm-4 ${touched.pos ? errors.pos ? 'has-error' : 'has-success' : ''}`}>
                                            <label class="reg m-cir" for="pos"><span data-ng-bind="trans.LBL_POS">POS</span>
                                                <i class="fa fa-info-circle" title="Place of Supply(Only if different from location of recipient)" aria-hidden="true"></i>
                                            </label>
                                            <select name="pos" class="form-control" required="" value={values.pos} id="pos" onChange={e => {
                                                handleChange(e);
                                                let val = e.target.value;
                                                if (val === GSTUser.state) {
                                                    setIsItem(true);
                                                    values.supplyty = "Intra-state";
                                                } else {
                                                    setIsItem(true);
                                                    values.supplyty = "Inter-state";
                                                }
                                            }} disabled={isEdit}>
                                                <option value="" selected="selected" disabled="">Select</option>
                                                {
                                                    states.length !== 0 && states.map((s, index) => {
                                                        return <option value={s} selected={values.pos == s} disabled={s === GSTUser.state} key={index}>{s}</option>
                                                    })
                                                }
                                            </select>
                                            {(errors.pos && touched.pos) && <span className='err'>{errors.pos}</span>}
                                        </div>
                                        <div class={`col-sm-4 ${touched.inv_no ? errors.inv_no ? 'has-error' : 'has-success' : ''}`}>
                                            <label class="reg m-cir" for="inv_no" data-ng-bind="trans.LBL_INVOICE_NUMBER">Invoice no.</label>
                                            <input type="text" name="inv_no" id="inv_no" class="form-control formedit ng-pristine ng-untouched ng-empty ng-invalid ng-invalid-required ng-valid-pattern ng-valid-maxlength" maxLength={16} required="" value={values.inv_no} onChange={(e) => {
                                                setInvno('');
                                                handleChange(e);
                                            }} disabled={isEdit} />
                                            {(errors.inv_no && touched.inv_no) && <span className='err'>{errors.inv_no}</span>}
                                            {invno !== "" && <span className='err'>Invoice no. '{invno}' already exists in the current peroid.</span>}
                                        </div>
                                        <div class={`col-sm-4 ${touched.invdate ? errors.invdate ? 'has-error' : 'has-success' : ''}`}>
                                            <label class="reg m-cir" for="invdate" data-ng-bind="trans.LBL_INVOICE_DATE">Invoice date</label>
                                            <div class="datepicker-icon input-group">
                                                <input name="invdate" id="invdate" class="form-control date-picker ng-pristine ng-untouched ng-empty ng-invalid ng-invalid-required ng-valid-pattern" placeholder="DD/MM/YYYY" required="" value={values.invdate.substring(0, 10)} onChange={handleDateChange} disabled={isEdit} />
                                                <span class="input-group-addon" onClick={() => { openPicker('invdate') }}><span class="fa fa-calendar"></span></span>
                                            </div>
                                            {(errors.invdate && touched.invdate) && <span className='err'>{errors.invdate}</span>}
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class={`col-sm-4 ${touched.supplyty ? errors.supplyty ? 'has-error' : 'has-success' : ''}`}>
                                            <label class="reg" for="stype" data-ng-bind="trans.LBL_SUPPLY_TYPE">Supply Type</label>
                                            <input class="form-control ng-pristine ng-untouched ng-valid ng-not-empty" name="supplyty" id="supplyty" value={values.supplyty} readOnly={true} />
                                            {
                                                (errors.supplyty && touched.supplyty) && <span className='err'>{errors.supplyty}</span>
                                            }
                                        </div>
                                        <div class={`col-sm-4 ${touched.invval ? errors.invval ? 'has-error' : 'has-success' : ''}`}>
                                            <label class="reg m-cir" for="invval" data-ng-bind="trans.LBL_INVOICE_VALUE">Total invoice value (₹)</label>
                                            <input name="invval" class="form-control inter text-right ng-pristine ng-untouched ng-empty ng-invalid ng-invalid-required" id="invval" maxLength={16} value={values.invval} onChange={e => {
                                                const formatValue = getFormatvalue(e.target.value);
                                                e.target.value = formatValue;
                                                setInvval('');
                                                handleChange(e);
                                            }} onBlur={e => {
                                                if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                    e.target.value = e.target.value + ".00";
                                                }
                                                handleChange(e);
                                            }} />
                                            {(errors.invval && touched.invval) && <span className='err'>{errors.invval}</span>}
                                            {invval !== "" && <span className='err'>Invoice value should be grater than ₹ 2,50,000/-</span>}
                                        </div>
                                    </div>
                                    {
                                        isItem && <div>
                                            <fieldset data-ng-disabled="work_flow || b2baview || cdnraview">
                                                <div class="rettbl-format">
                                                    <div class="row">
                                                        <div class="col-xs-12 col-sm-12">
                                                            <h4 class="no-mar" data-ng-bind="trans.HEAD_ITEM_DETAILS">Item details</h4>
                                                            {isItems && <span className='err'>Do add an item</span>}
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-sm-12">
                                                            <div class="table-responsive">
                                                                <table class="table exp inv tbl table-bordered table-responsive" style={{ 'display': 'table' }}>
                                                                    <thead>
                                                                        <tr>
                                                                            <th rowspan="2" class="text-center">Rate (%)</th>
                                                                            <th rowspan="2" class="text-center m-cir">Taxable value (₹)</th>
                                                                            <th colspan="3" class="text-center">Amount of Tax</th>
                                                                        </tr>
                                                                        <tr>
                                                                            {
                                                                                values.supplyty === "Inter-state" && <th class="text-center m-cir">Integrated Tax (₹)</th>
                                                                            }
                                                                            {
                                                                                values.supplyty === "Intra-state" && <>
                                                                                    <th class="text-center m-cir">State Tax (₹)</th>
                                                                                    <th class="text-center m-cir">Central Tax (₹)</th>
                                                                                </>
                                                                            }
                                                                            <th class="text-center">Cess (₹)</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <tr>
                                                                            <td class="text-center">0%</td>
                                                                            <td class="text-center">
                                                                                <input id="items.a.total" class="form-control newinv formedit currency" maxLength={16} value={values.items.a.total} onChange={e => {
                                                                                    const formatValue = getSubFormatvalue(e.target.value);
                                                                                    e.target.value = formatValue;
                                                                                    if (e.target.value === "") {
                                                                                        values.items.a.taxa = '';
                                                                                        values.items.a.taxb = '';
                                                                                        values.items.a.cess = '';
                                                                                    } else {
                                                                                        values.items.a.taxa = 0;
                                                                                        values.items.a.taxb = 0;
                                                                                        values.items.a.cess = '';
                                                                                    }
                                                                                    handleChange(e);
                                                                                }} onBlur={e => {
                                                                                    if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                        e.target.value = e.target.value + ".00";
                                                                                    }
                                                                                    handleChange(e);
                                                                                }} />
                                                                            </td>
                                                                            {
                                                                                values.supplyty === "Inter-state" && <td class="text-center">
                                                                                    <input id="items.a.taxa" class="form-control newinv formedit currency" maxLength={16} value={values.items.a.taxa} onChange={e => {
                                                                                        const formatValue = getSubFormatvalue(e.target.value);
                                                                                        e.target.value = formatValue;
                                                                                        handleChange(e);
                                                                                    }} onBlur={e => {
                                                                                        if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                            e.target.value = e.target.value + ".00";
                                                                                        }
                                                                                        handleChange(e);
                                                                                    }} disabled={values.items.a.total === ""} />
                                                                                </td>
                                                                            }
                                                                            {
                                                                                values.supplyty === "Intra-state" && <>
                                                                                    <td class="text-center">
                                                                                        <input id="items.a.taxb" class="form-control newinv formedit currency" maxLength={16} value={values.items.a.taxb} onChange={e => {
                                                                                            const formatValue = getSubFormatvalue(e.target.value);
                                                                                            e.target.value = formatValue;
                                                                                            handleChange(e);
                                                                                        }} onBlur={e => {
                                                                                            if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                                e.target.value = e.target.value + ".00";
                                                                                            }
                                                                                            handleChange(e);
                                                                                        }} disabled={values.items.a.total === ""} />
                                                                                    </td>
                                                                                    <td class="text-center">
                                                                                        <input id="items.a.taxb" class="form-control newinv formedit currency" maxLength={16} value={values.items.a.taxb} onChange={e => {
                                                                                            const formatValue = getSubFormatvalue(e.target.value);
                                                                                            e.target.value = formatValue;
                                                                                            handleChange(e);
                                                                                        }} onBlur={e => {
                                                                                            if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                                e.target.value = e.target.value + ".00";
                                                                                            }
                                                                                            handleChange(e);
                                                                                        }} disabled={values.items.a.total === ""} />
                                                                                    </td>
                                                                                </>
                                                                            }
                                                                            <td class="text-center">
                                                                                <input id="items.a.cess" class="form-control newinv formedit currency" maxLength={16} value={values.items.a.cess} onChange={e => {
                                                                                    const formatValue = getSubFormatvalue(e.target.value);
                                                                                    e.target.value = formatValue;
                                                                                    handleChange(e);
                                                                                }} onBlur={e => {
                                                                                    if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                        e.target.value = e.target.value + ".00";
                                                                                    }
                                                                                    handleChange(e);
                                                                                }} disabled={values.items.a.total === ""} />
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td class="text-center">0.1%</td>
                                                                            <td class="text-center">
                                                                                <input id="items.b.total" class="form-control newinv formedit currency" maxLength={16} value={values.items.b.total} onChange={e => {
                                                                                    const formatValue = getSubFormatvalue(e.target.value);
                                                                                    e.target.value = formatValue;
                                                                                    if (e.target.value === "") {
                                                                                        values.items.b.taxa = '';
                                                                                        values.items.b.taxb = '';
                                                                                        values.items.b.cess = '';
                                                                                    } else {
                                                                                        values.items.b.taxa = (parseFloat(e.target.value) * 0.001).toFixed(2);
                                                                                        values.items.b.taxb = ((parseFloat(e.target.value) * 0.001) / 2).toFixed(2);
                                                                                    }
                                                                                    handleChange(e);
                                                                                }} onBlur={e => {
                                                                                    if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                        e.target.value = e.target.value + ".00";
                                                                                    }
                                                                                    handleChange(e);
                                                                                }} />
                                                                            </td>
                                                                            {
                                                                                values.supplyty === "Inter-state" && <td class="text-center">
                                                                                    <input id="items.b.taxa" class="form-control newinv formedit currency" maxLength={16} value={values.items.b.taxa} onChange={e => {
                                                                                        const formatValue = getSubFormatvalue(e.target.value);
                                                                                        e.target.value = formatValue;
                                                                                        handleChange(e);
                                                                                    }} onBlur={e => {
                                                                                        if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                            e.target.value = e.target.value + ".00";
                                                                                        }
                                                                                        handleChange(e);
                                                                                    }} disabled={values.items.b.total === ""} />
                                                                                </td>
                                                                            }
                                                                            {
                                                                                values.supplyty === "Intra-state" && <>
                                                                                    <td class="text-center">
                                                                                        <input id="items.b.taxb" class="form-control newinv formedit currency" maxLength={16} value={values.items.b.taxb} onChange={e => {
                                                                                            const formatValue = getSubFormatvalue(e.target.value);
                                                                                            e.target.value = formatValue;
                                                                                            handleChange(e);
                                                                                        }} onBlur={e => {
                                                                                            if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                                e.target.value = e.target.value + ".00";
                                                                                            }
                                                                                            handleChange(e);
                                                                                        }} disabled={values.items.b.total === ""} />
                                                                                    </td>
                                                                                    <td class="text-center">
                                                                                        <input id="items.b.taxb" class="form-control newinv formedit currency" maxLength={16} value={values.items.b.taxb} onChange={e => {
                                                                                            const formatValue = getSubFormatvalue(e.target.value);
                                                                                            e.target.value = formatValue;
                                                                                            handleChange(e);
                                                                                        }} onBlur={e => {
                                                                                            if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                                e.target.value = e.target.value + ".00";
                                                                                            }
                                                                                            handleChange(e);
                                                                                        }} disabled={values.items.b.total === ""} />
                                                                                    </td>
                                                                                </>
                                                                            }
                                                                            <td class="text-center">
                                                                                <input id="items.b.cess" class="form-control newinv formedit currency" maxLength={16} value={values.items.b.cess} onChange={e => {
                                                                                    const formatValue = getSubFormatvalue(e.target.value);
                                                                                    e.target.value = formatValue;
                                                                                    handleChange(e);
                                                                                }} onBlur={e => {
                                                                                    if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                        e.target.value = e.target.value + ".00";
                                                                                    }
                                                                                    handleChange(e);
                                                                                }} disabled={values.items.b.total === ""} />
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td class="text-center">0.25%</td>
                                                                            <td class="text-center">
                                                                                <input id="items.c.total" class="form-control newinv formedit currency" maxLength={16} value={values.items.c.total} onChange={e => {
                                                                                    const formatValue = getSubFormatvalue(e.target.value);
                                                                                    e.target.value = formatValue;
                                                                                    if (e.target.value === "") {
                                                                                        values.items.c.taxa = '';
                                                                                        values.items.c.taxb = '';
                                                                                        values.items.c.cess = '';
                                                                                    } else {
                                                                                        values.items.c.taxa = (parseFloat(e.target.value) * 0.025).toFixed(2);
                                                                                        values.items.c.taxb = ((parseFloat(e.target.value) * 0.025) / 2).toFixed(2);
                                                                                    }
                                                                                    handleChange(e);
                                                                                }} onBlur={e => {
                                                                                    if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                        e.target.value = e.target.value + ".00";
                                                                                    }
                                                                                    handleChange(e);
                                                                                }} />
                                                                            </td>
                                                                            {
                                                                                values.supplyty === "Inter-state" && <td class="text-center">
                                                                                    <input id="items.c.taxa" class="form-control newinv formedit currency" maxLength={16} value={values.items.c.taxa} onChange={e => {
                                                                                        const formatValue = getSubFormatvalue(e.target.value);
                                                                                        e.target.value = formatValue;
                                                                                        handleChange(e);
                                                                                    }} onBlur={e => {
                                                                                        if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                            e.target.value = e.target.value + ".00";
                                                                                        }
                                                                                        handleChange(e);
                                                                                    }} disabled={values.items.c.total === ""} />
                                                                                </td>
                                                                            }
                                                                            {
                                                                                values.supplyty === "Intra-state" && <>
                                                                                    <td class="text-center">
                                                                                        <input id="items.c.taxb" class="form-control newinv formedit currency" maxLength={16} value={values.items.c.taxb} onChange={e => {
                                                                                            const formatValue = getSubFormatvalue(e.target.value);
                                                                                            e.target.value = formatValue;
                                                                                            handleChange(e);
                                                                                        }} onBlur={e => {
                                                                                            if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                                e.target.value = e.target.value + ".00";
                                                                                            }
                                                                                            handleChange(e);
                                                                                        }} disabled={values.items.c.total === ""} />
                                                                                    </td>
                                                                                    <td class="text-center">
                                                                                        <input id="items.c.taxb" class="form-control newinv formedit currency" maxLength={16} value={values.items.c.taxb} onChange={e => {
                                                                                            const formatValue = getSubFormatvalue(e.target.value);
                                                                                            e.target.value = formatValue;
                                                                                            handleChange(e);
                                                                                        }} onBlur={e => {
                                                                                            if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                                e.target.value = e.target.value + ".00";
                                                                                            }
                                                                                            handleChange(e);
                                                                                        }} disabled={values.items.c.total === ""} />
                                                                                    </td>
                                                                                </>
                                                                            }
                                                                            <td class="text-center">
                                                                                <input id="items.c.cess" class="form-control newinv formedit currency" maxLength={16} value={values.items.c.cess} onChange={e => {
                                                                                    const formatValue = getSubFormatvalue(e.target.value);
                                                                                    e.target.value = formatValue;
                                                                                    handleChange(e);
                                                                                }} onBlur={e => {
                                                                                    if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                        e.target.value = e.target.value + ".00";
                                                                                    }
                                                                                    handleChange(e);
                                                                                }} disabled={values.items.c.total === ""} />
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td class="text-center">1%</td>
                                                                            <td class="text-center">
                                                                                <input id="items.d.total" class="form-control newinv formedit currency" maxLength={16} value={values.items.d.total} onChange={e => {
                                                                                    const formatValue = getSubFormatvalue(e.target.value);
                                                                                    e.target.value = formatValue;
                                                                                    if (e.target.value === "") {
                                                                                        values.items.d.taxa = '';
                                                                                        values.items.d.taxb = '';
                                                                                        values.items.d.cess = '';
                                                                                    } else {
                                                                                        values.items.d.taxa = (parseFloat(e.target.value) * 0.01).toFixed(2);
                                                                                        values.items.d.taxb = ((parseFloat(e.target.value) * 0.01) / 2).toFixed(2);
                                                                                    }
                                                                                    handleChange(e);
                                                                                }} onBlur={e => {
                                                                                    if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                        e.target.value = e.target.value + ".00";
                                                                                    }
                                                                                    handleChange(e);
                                                                                }} />
                                                                            </td>
                                                                            {
                                                                                values.supplyty === "Inter-state" && <td class="text-center">
                                                                                    <input id="items.d.taxa" class="form-control newinv formedit currency" maxLength={16} value={values.items.d.taxa} onChange={e => {
                                                                                        const formatValue = getSubFormatvalue(e.target.value);
                                                                                        e.target.value = formatValue;
                                                                                        handleChange(e);
                                                                                    }} onBlur={e => {
                                                                                        if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                            e.target.value = e.target.value + ".00";
                                                                                        }
                                                                                        handleChange(e);
                                                                                    }} disabled={values.items.d.total === ""} />
                                                                                </td>
                                                                            }
                                                                            {
                                                                                values.supplyty === "Intra-state" && <>
                                                                                    <td class="text-center">
                                                                                        <input id="items.d.taxb" class="form-control newinv formedit currency" maxLength={16} value={values.items.d.taxb} onChange={e => {
                                                                                            const formatValue = getSubFormatvalue(e.target.value);
                                                                                            e.target.value = formatValue;
                                                                                            handleChange(e);
                                                                                        }} onBlur={e => {
                                                                                            if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                                e.target.value = e.target.value + ".00";
                                                                                            }
                                                                                            handleChange(e);
                                                                                        }} disabled={values.items.d.total === ""} />
                                                                                    </td>
                                                                                    <td class="text-center">
                                                                                        <input id="items.d.taxb" class="form-control newinv formedit currency" maxLength={16} value={values.items.d.taxb} onChange={e => {
                                                                                            const formatValue = getSubFormatvalue(e.target.value);
                                                                                            e.target.value = formatValue;
                                                                                            handleChange(e);
                                                                                        }} onBlur={e => {
                                                                                            if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                                e.target.value = e.target.value + ".00";
                                                                                            }
                                                                                            handleChange(e);
                                                                                        }} disabled={values.items.d.total === ""} />
                                                                                    </td>
                                                                                </>
                                                                            }
                                                                            <td class="text-center">
                                                                                <input id="items.d.cess" class="form-control newinv formedit currency" maxLength={16} value={values.items.d.cess} onChange={e => {
                                                                                    const formatValue = getSubFormatvalue(e.target.value);
                                                                                    e.target.value = formatValue;
                                                                                    handleChange(e);
                                                                                }} onBlur={e => {
                                                                                    if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                        e.target.value = e.target.value + ".00";
                                                                                    }
                                                                                    handleChange(e);
                                                                                }} disabled={values.items.d.total === ""} />
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td class="text-center">1.5%</td>
                                                                            <td class="text-center">
                                                                                <input id="items.e.total" class="form-control newinv formedit currency" maxLength={16} value={values.items.e.total} onChange={e => {
                                                                                    const formatValue = getSubFormatvalue(e.target.value);
                                                                                    e.target.value = formatValue;
                                                                                    if (e.target.value === "") {
                                                                                        values.items.e.taxa = '';
                                                                                        values.items.e.taxb = '';
                                                                                        values.items.e.cess = '';
                                                                                    } else {
                                                                                        values.items.e.taxa = (parseFloat(e.target.value) * 0.015).toFixed(2);
                                                                                        values.items.e.taxb = ((parseFloat(e.target.value) * 0.015) / 2).toFixed(2);
                                                                                    }
                                                                                    handleChange(e);
                                                                                }} onBlur={e => {
                                                                                    if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                        e.target.value = e.target.value + ".00";
                                                                                    }
                                                                                    handleChange(e);
                                                                                }} />
                                                                            </td>
                                                                            {
                                                                                values.supplyty === "Inter-state" && <td class="text-center">
                                                                                    <input id="items.e.taxa" class="form-control newinv formedit currency" maxLength={16} value={values.items.e.taxa} onChange={e => {
                                                                                        const formatValue = getSubFormatvalue(e.target.value);
                                                                                        e.target.value = formatValue;
                                                                                        handleChange(e);
                                                                                    }} onBlur={e => {
                                                                                        if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                            e.target.value = e.target.value + ".00";
                                                                                        }
                                                                                        handleChange(e);
                                                                                    }} disabled={values.items.e.total === ""} />
                                                                                </td>
                                                                            }
                                                                            {
                                                                                values.supplyty === "Intra-state" && <>
                                                                                    <td class="text-center">
                                                                                        <input id="items.e.taxb" class="form-control newinv formedit currency" maxLength={16} value={values.items.e.taxb} onChange={e => {
                                                                                            const formatValue = getSubFormatvalue(e.target.value);
                                                                                            e.target.value = formatValue;
                                                                                            handleChange(e);
                                                                                        }} onBlur={e => {
                                                                                            if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                                e.target.value = e.target.value + ".00";
                                                                                            }
                                                                                            handleChange(e);
                                                                                        }} disabled={values.items.e.total === ""} />
                                                                                    </td>
                                                                                    <td class="text-center">
                                                                                        <input id="items.e.taxb" class="form-control newinv formedit currency" maxLength={16} value={values.items.e.taxb} onChange={e => {
                                                                                            const formatValue = getSubFormatvalue(e.target.value);
                                                                                            e.target.value = formatValue;
                                                                                            handleChange(e);
                                                                                        }} onBlur={e => {
                                                                                            if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                                e.target.value = e.target.value + ".00";
                                                                                            }
                                                                                            handleChange(e);
                                                                                        }} disabled={values.items.e.total === ""} />
                                                                                    </td>
                                                                                </>
                                                                            }
                                                                            <td class="text-center">
                                                                                <input id="items.e.cess" class="form-control newinv formedit currency" maxLength={16} value={values.items.e.cess} onChange={e => {
                                                                                    const formatValue = getSubFormatvalue(e.target.value);
                                                                                    e.target.value = formatValue;
                                                                                    handleChange(e);
                                                                                }} onBlur={e => {
                                                                                    if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                        e.target.value = e.target.value + ".00";
                                                                                    }
                                                                                    handleChange(e);
                                                                                }} disabled={values.items.e.total === ""} />
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td class="text-center">3%</td>
                                                                            <td class="text-center">
                                                                                <input id="items.f.total" class="form-control newinv formedit currency" maxLength={16} value={values.items.f.total} onChange={e => {
                                                                                    const formatValue = getSubFormatvalue(e.target.value);
                                                                                    e.target.value = formatValue;
                                                                                    if (e.target.value === "") {
                                                                                        values.items.f.taxa = '';
                                                                                        values.items.f.taxb = '';
                                                                                        values.items.f.cess = '';
                                                                                    } else {
                                                                                        values.items.f.taxa = (parseFloat(e.target.value) * 0.03).toFixed(2);
                                                                                        values.items.f.taxb = ((parseFloat(e.target.value) * 0.03) / 2).toFixed(2);
                                                                                    }
                                                                                    handleChange(e);
                                                                                }} onBlur={e => {
                                                                                    if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                        e.target.value = e.target.value + ".00";
                                                                                    }
                                                                                    handleChange(e);
                                                                                }} />
                                                                            </td>
                                                                            {
                                                                                values.supplyty === "Inter-state" && <td class="text-center">
                                                                                    <input id="items.f.taxa" class="form-control newinv formedit currency" maxLength={16} value={values.items.f.taxa} onChange={e => {
                                                                                        const formatValue = getSubFormatvalue(e.target.value);
                                                                                        e.target.value = formatValue;
                                                                                        handleChange(e);
                                                                                    }} onBlur={e => {
                                                                                        if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                            e.target.value = e.target.value + ".00";
                                                                                        }
                                                                                        handleChange(e);
                                                                                    }} disabled={values.items.f.total === ""} />
                                                                                </td>
                                                                            }
                                                                            {
                                                                                values.supplyty === "Intra-state" && <>
                                                                                    <td class="text-center">
                                                                                        <input id="items.f.taxb" class="form-control newinv formedit currency" maxLength={16} value={values.items.f.taxb} onChange={e => {
                                                                                            const formatValue = getSubFormatvalue(e.target.value);
                                                                                            e.target.value = formatValue;
                                                                                            handleChange(e);
                                                                                        }} onBlur={e => {
                                                                                            if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                                e.target.value = e.target.value + ".00";
                                                                                            }
                                                                                            handleChange(e);
                                                                                        }} disabled={values.items.f.total === ""} />
                                                                                    </td>
                                                                                    <td class="text-center">
                                                                                        <input id="items.f.taxb" class="form-control newinv formedit currency" maxLength={16} value={values.items.f.taxb} onChange={e => {
                                                                                            const formatValue = getSubFormatvalue(e.target.value);
                                                                                            e.target.value = formatValue;
                                                                                            handleChange(e);
                                                                                        }} onBlur={e => {
                                                                                            if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                                e.target.value = e.target.value + ".00";
                                                                                            }
                                                                                            handleChange(e);
                                                                                        }} disabled={values.items.f.total === ""} />
                                                                                    </td>
                                                                                </>
                                                                            }
                                                                            <td class="text-center">
                                                                                <input id="items.f.cess" class="form-control newinv formedit currency" maxLength={16} value={values.items.f.cess} onChange={e => {
                                                                                    const formatValue = getSubFormatvalue(e.target.value);
                                                                                    e.target.value = formatValue;
                                                                                    handleChange(e);
                                                                                }} onBlur={e => {
                                                                                    if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                        e.target.value = e.target.value + ".00";
                                                                                    }
                                                                                    handleChange(e);
                                                                                }} disabled={values.items.f.total === ""} />
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td class="text-center">5%</td>
                                                                            <td class="text-center">
                                                                                <input id="items.g.total" class="form-control newinv formedit currency" maxLength={16} value={values.items.g.total} onChange={e => {
                                                                                    const formatValue = getSubFormatvalue(e.target.value);
                                                                                    e.target.value = formatValue;
                                                                                    if (e.target.value === "") {
                                                                                        values.items.g.taxa = '';
                                                                                        values.items.g.taxb = '';
                                                                                        values.items.g.cess = '';
                                                                                    } else {
                                                                                        values.items.g.taxa = (parseFloat(e.target.value) * 0.05).toFixed(2);
                                                                                        values.items.g.taxb = ((parseFloat(e.target.value) * 0.05) / 2).toFixed(2);
                                                                                    }
                                                                                    handleChange(e);
                                                                                }} onBlur={e => {
                                                                                    if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                        e.target.value = e.target.value + ".00";
                                                                                    }
                                                                                    handleChange(e);
                                                                                }} />
                                                                            </td>
                                                                            {
                                                                                values.supplyty === "Inter-state" && <td class="text-center">
                                                                                    <input id="items.g.taxa" class="form-control newinv formedit currency" maxLength={16} value={values.items.g.taxa} onChange={e => {
                                                                                        const formatValue = getSubFormatvalue(e.target.value);
                                                                                        e.target.value = formatValue;
                                                                                        handleChange(e);
                                                                                    }} onBlur={e => {
                                                                                        if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                            e.target.value = e.target.value + ".00";
                                                                                        }
                                                                                        handleChange(e);
                                                                                    }} disabled={values.items.g.total === ""} />
                                                                                </td>
                                                                            }
                                                                            {
                                                                                values.supplyty === "Intra-state" && <>
                                                                                    <td class="text-center">
                                                                                        <input id="items.g.taxb" class="form-control newinv formedit currency" maxLength={16} value={values.items.g.taxb} onChange={e => {
                                                                                            const formatValue = getSubFormatvalue(e.target.value);
                                                                                            e.target.value = formatValue;
                                                                                            handleChange(e);
                                                                                        }} onBlur={e => {
                                                                                            if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                                e.target.value = e.target.value + ".00";
                                                                                            }
                                                                                            handleChange(e);
                                                                                        }} disabled={values.items.g.total === ""} />
                                                                                    </td>
                                                                                    <td class="text-center">
                                                                                        <input id="items.g.taxb" class="form-control newinv formedit currency" maxLength={16} value={values.items.g.taxb} onChange={e => {
                                                                                            const formatValue = getSubFormatvalue(e.target.value);
                                                                                            e.target.value = formatValue;
                                                                                            handleChange(e);
                                                                                        }} onBlur={e => {
                                                                                            if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                                e.target.value = e.target.value + ".00";
                                                                                            }
                                                                                            handleChange(e);
                                                                                        }} disabled={values.items.g.total === ""} />
                                                                                    </td>
                                                                                </>
                                                                            }
                                                                            <td class="text-center">
                                                                                <input id="items.g.cess" class="form-control newinv formedit currency" maxLength={16} value={values.items.g.cess} onChange={e => {
                                                                                    const formatValue = getSubFormatvalue(e.target.value);
                                                                                    e.target.value = formatValue;
                                                                                    handleChange(e);
                                                                                }} onBlur={e => {
                                                                                    if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                        e.target.value = e.target.value + ".00";
                                                                                    }
                                                                                    handleChange(e);
                                                                                }} disabled={values.items.g.total === ""} />
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td class="text-center">6%</td>
                                                                            <td class="text-center">
                                                                                <input id="items.h.total" class="form-control newinv formedit currency" maxLength={16} value={values.items.h.total} onChange={e => {
                                                                                    const formatValue = getSubFormatvalue(e.target.value);
                                                                                    e.target.value = formatValue;
                                                                                    if (e.target.value === "") {
                                                                                        values.items.h.taxa = '';
                                                                                        values.items.h.taxb = '';
                                                                                        values.items.h.cess = '';
                                                                                    } else {
                                                                                        values.items.h.taxa = (parseFloat(e.target.value) * 0.06).toFixed(2);
                                                                                        values.items.h.taxb = ((parseFloat(e.target.value) * 0.06) / 2).toFixed(2);
                                                                                    }
                                                                                    handleChange(e);
                                                                                }} onBlur={e => {
                                                                                    if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                        e.target.value = e.target.value + ".00";
                                                                                    }
                                                                                    handleChange(e);
                                                                                }} />
                                                                            </td>
                                                                            {
                                                                                values.supplyty === "Inter-state" && <td class="text-center">
                                                                                    <input id="items.h.taxa" class="form-control newinv formedit currency" maxLength={16} value={values.items.h.taxa} onChange={e => {
                                                                                        const formatValue = getSubFormatvalue(e.target.value);
                                                                                        e.target.value = formatValue;
                                                                                        handleChange(e);
                                                                                    }} onBlur={e => {
                                                                                        if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                            e.target.value = e.target.value + ".00";
                                                                                        }
                                                                                        handleChange(e);
                                                                                    }} disabled={values.items.h.total === ""} />
                                                                                </td>
                                                                            }
                                                                            {
                                                                                values.supplyty === "Intra-state" && <>
                                                                                    <td class="text-center">
                                                                                        <input id="items.h.taxb" class="form-control newinv formedit currency" maxLength={16} value={values.items.h.taxb} onChange={e => {
                                                                                            const formatValue = getSubFormatvalue(e.target.value);
                                                                                            e.target.value = formatValue;
                                                                                            handleChange(e);
                                                                                        }} onBlur={e => {
                                                                                            if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                                e.target.value = e.target.value + ".00";
                                                                                            }
                                                                                            handleChange(e);
                                                                                        }} disabled={values.items.h.total === ""} />
                                                                                    </td>
                                                                                    <td class="text-center">
                                                                                        <input id="items.h.taxb" class="form-control newinv formedit currency" maxLength={16} value={values.items.h.taxb} onChange={e => {
                                                                                            const formatValue = getSubFormatvalue(e.target.value);
                                                                                            e.target.value = formatValue;
                                                                                            handleChange(e);
                                                                                        }} onBlur={e => {
                                                                                            if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                                e.target.value = e.target.value + ".00";
                                                                                            }
                                                                                            handleChange(e);
                                                                                        }} disabled={values.items.h.total === ""} />
                                                                                    </td>
                                                                                </>
                                                                            }
                                                                            <td class="text-center">
                                                                                <input id="items.h.cess" class="form-control newinv formedit currency" maxLength={16} value={values.items.h.cess} onChange={e => {
                                                                                    const formatValue = getSubFormatvalue(e.target.value);
                                                                                    e.target.value = formatValue;
                                                                                    handleChange(e);
                                                                                }} onBlur={e => {
                                                                                    if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                        e.target.value = e.target.value + ".00";
                                                                                    }
                                                                                    handleChange(e);
                                                                                }} disabled={values.items.h.total === ""} />
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td class="text-center">7.5%</td>
                                                                            <td class="text-center">
                                                                                <input id="items.i.total" class="form-control newinv formedit currency" maxLength={16} value={values.items.i.total} onChange={e => {
                                                                                    const formatValue = getSubFormatvalue(e.target.value);
                                                                                    e.target.value = formatValue;
                                                                                    if (e.target.value === "") {
                                                                                        values.items.i.taxa = '';
                                                                                        values.items.i.taxb = '';
                                                                                        values.items.i.cess = '';
                                                                                    } else {
                                                                                        values.items.i.taxa = (parseFloat(e.target.value) * 0.075).toFixed(2);
                                                                                        values.items.i.taxb = ((parseFloat(e.target.value) * 0.075) / 2).toFixed(2);
                                                                                    }
                                                                                    handleChange(e);
                                                                                }} onBlur={e => {
                                                                                    if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                        e.target.value = e.target.value + ".00";
                                                                                    }
                                                                                    handleChange(e);
                                                                                }} />
                                                                            </td>
                                                                            {
                                                                                values.supplyty === "Inter-state" && <td class="text-center">
                                                                                    <input id="items.i.taxa" class="form-control newinv formedit currency" maxLength={16} value={values.items.i.taxa} onChange={e => {
                                                                                        const formatValue = getSubFormatvalue(e.target.value);
                                                                                        e.target.value = formatValue;
                                                                                        handleChange(e);
                                                                                    }} onBlur={e => {
                                                                                        if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                            e.target.value = e.target.value + ".00";
                                                                                        }
                                                                                        handleChange(e);
                                                                                    }} disabled={values.items.i.total === ""} />
                                                                                </td>
                                                                            }
                                                                            {
                                                                                values.supplyty === "Intra-state" && <>
                                                                                    <td class="text-center">
                                                                                        <input id="items.i.taxb" class="form-control newinv formedit currency" maxLength={16} value={values.items.i.taxb} onChange={e => {
                                                                                            const formatValue = getSubFormatvalue(e.target.value);
                                                                                            e.target.value = formatValue;
                                                                                            handleChange(e);
                                                                                        }} onBlur={e => {
                                                                                            if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                                e.target.value = e.target.value + ".00";
                                                                                            }
                                                                                            handleChange(e);
                                                                                        }} disabled={values.items.i.total === ""} />
                                                                                    </td>
                                                                                    <td class="text-center">
                                                                                        <input id="items.i.taxb" class="form-control newinv formedit currency" maxLength={16} value={values.items.i.taxb} onChange={e => {
                                                                                            const formatValue = getSubFormatvalue(e.target.value);
                                                                                            e.target.value = formatValue;
                                                                                            handleChange(e);
                                                                                        }} onBlur={e => {
                                                                                            if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                                e.target.value = e.target.value + ".00";
                                                                                            }
                                                                                            handleChange(e);
                                                                                        }} disabled={values.items.i.total === ""} />
                                                                                    </td>
                                                                                </>
                                                                            }
                                                                            <td class="text-center">
                                                                                <input id="items.i.cess" class="form-control newinv formedit currency" maxLength={16} value={values.items.i.cess} onChange={e => {
                                                                                    const formatValue = getSubFormatvalue(e.target.value);
                                                                                    e.target.value = formatValue;
                                                                                    handleChange(e);
                                                                                }} onBlur={e => {
                                                                                    if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                        e.target.value = e.target.value + ".00";
                                                                                    }
                                                                                    handleChange(e);
                                                                                }} disabled={values.items.i.total === ""} />
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td class="text-center">12%</td>
                                                                            <td class="text-center">
                                                                                <input id="items.j.total" class="form-control newinv formedit currency" maxLength={16} value={values.items.j.total} onChange={e => {
                                                                                    const formatValue = getSubFormatvalue(e.target.value);
                                                                                    e.target.value = formatValue;
                                                                                    if (e.target.value === "") {
                                                                                        values.items.j.taxa = '';
                                                                                        values.items.j.taxb = '';
                                                                                        values.items.j.cess = '';
                                                                                    } else {
                                                                                        values.items.j.taxa = (parseFloat(e.target.value) * 0.12).toFixed(2);
                                                                                        values.items.j.taxb = ((parseFloat(e.target.value) * 0.12) / 2).toFixed(2);
                                                                                    }
                                                                                    handleChange(e);
                                                                                }} onBlur={e => {
                                                                                    if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                        e.target.value = e.target.value + ".00";
                                                                                    }
                                                                                    handleChange(e);
                                                                                }} />
                                                                            </td>
                                                                            {
                                                                                values.supplyty === "Inter-state" && <td class="text-center">
                                                                                    <input id="items.j.taxa" class="form-control newinv formedit currency" maxLength={16} value={values.items.j.taxa} onChange={e => {
                                                                                        const formatValue = getSubFormatvalue(e.target.value);
                                                                                        e.target.value = formatValue;
                                                                                        handleChange(e);
                                                                                    }} onBlur={e => {
                                                                                        if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                            e.target.value = e.target.value + ".00";
                                                                                        }
                                                                                        handleChange(e);
                                                                                    }} disabled={values.items.j.total === ""} />
                                                                                </td>
                                                                            }
                                                                            {
                                                                                values.supplyty === "Intra-state" && <>
                                                                                    <td class="text-center">
                                                                                        <input id="items.j.taxb" class="form-control newinv formedit currency" maxLength={16} value={values.items.j.taxb} onChange={e => {
                                                                                            const formatValue = getSubFormatvalue(e.target.value);
                                                                                            e.target.value = formatValue;
                                                                                            handleChange(e);
                                                                                        }} onBlur={e => {
                                                                                            if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                                e.target.value = e.target.value + ".00";
                                                                                            }
                                                                                            handleChange(e);
                                                                                        }} disabled={values.items.j.total === ""} />
                                                                                    </td>
                                                                                    <td class="text-center">
                                                                                        <input id="items.j.taxb" class="form-control newinv formedit currency" maxLength={16} value={values.items.j.taxb} onChange={e => {
                                                                                            const formatValue = getSubFormatvalue(e.target.value);
                                                                                            e.target.value = formatValue;
                                                                                            handleChange(e);
                                                                                        }} onBlur={e => {
                                                                                            if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                                e.target.value = e.target.value + ".00";
                                                                                            }
                                                                                            handleChange(e);
                                                                                        }} disabled={values.items.j.total === ""} />
                                                                                    </td>
                                                                                </>
                                                                            }
                                                                            <td class="text-center">
                                                                                <input id="items.j.cess" class="form-control newinv formedit currency" maxLength={16} value={values.items.j.cess} onChange={e => {
                                                                                    const formatValue = getSubFormatvalue(e.target.value);
                                                                                    e.target.value = formatValue;
                                                                                    handleChange(e);
                                                                                }} onBlur={e => {
                                                                                    if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                        e.target.value = e.target.value + ".00";
                                                                                    }
                                                                                    handleChange(e);
                                                                                }} disabled={values.items.j.total === ""} />
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td class="text-center">18%</td>
                                                                            <td class="text-center">
                                                                                <input id="items.k.total" class="form-control newinv formedit currency" maxLength={16} value={values.items.k.total} onChange={e => {
                                                                                    const formatValue = getSubFormatvalue(e.target.value);
                                                                                    e.target.value = formatValue;
                                                                                    if (e.target.value === "") {
                                                                                        values.items.k.taxa = '';
                                                                                        values.items.k.taxb = '';
                                                                                        values.items.k.cess = '';
                                                                                    } else {
                                                                                        values.items.k.taxa = (parseFloat(e.target.value) * 0.18).toFixed(2);
                                                                                        values.items.k.taxb = ((parseFloat(e.target.value) * 0.18) / 2).toFixed(2);
                                                                                    }
                                                                                    handleChange(e);
                                                                                }} onBlur={e => {
                                                                                    if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                        e.target.value = e.target.value + ".00";
                                                                                    }
                                                                                    handleChange(e);
                                                                                }} />
                                                                            </td>
                                                                            {
                                                                                values.supplyty === "Inter-state" && <td class="text-center">
                                                                                    <input id="items.k.taxa" class="form-control newinv formedit currency" maxLength={16} value={values.items.k.taxa} onChange={e => {
                                                                                        const formatValue = getSubFormatvalue(e.target.value);
                                                                                        e.target.value = formatValue;
                                                                                        handleChange(e);
                                                                                    }} onBlur={e => {
                                                                                        if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                            e.target.value = e.target.value + ".00";
                                                                                        }
                                                                                        handleChange(e);
                                                                                    }} disabled={values.items.k.total === ""} />
                                                                                </td>
                                                                            }
                                                                            {
                                                                                values.supplyty === "Intra-state" && <>
                                                                                    <td class="text-center">
                                                                                        <input id="items.k.taxb" class="form-control newinv formedit currency" maxLength={16} value={values.items.k.taxb} onChange={e => {
                                                                                            const formatValue = getSubFormatvalue(e.target.value);
                                                                                            e.target.value = formatValue;
                                                                                            handleChange(e);
                                                                                        }} onBlur={e => {
                                                                                            if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                                e.target.value = e.target.value + ".00";
                                                                                            }
                                                                                            handleChange(e);
                                                                                        }} disabled={values.items.k.total === ""} />
                                                                                    </td>
                                                                                    <td class="text-center">
                                                                                        <input id="items.k.taxb" class="form-control newinv formedit currency" maxLength={16} value={values.items.k.taxb} onChange={e => {
                                                                                            const formatValue = getSubFormatvalue(e.target.value);
                                                                                            e.target.value = formatValue;
                                                                                            handleChange(e);
                                                                                        }} onBlur={e => {
                                                                                            if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                                e.target.value = e.target.value + ".00";
                                                                                            }
                                                                                            handleChange(e);
                                                                                        }} disabled={values.items.k.total === ""} />
                                                                                    </td>
                                                                                </>
                                                                            }
                                                                            <td class="text-center">
                                                                                <input id="items.k.cess" class="form-control newinv formedit currency" maxLength={16} value={values.items.k.cess} onChange={e => {
                                                                                    const formatValue = getSubFormatvalue(e.target.value);
                                                                                    e.target.value = formatValue;
                                                                                    handleChange(e);
                                                                                }} onBlur={e => {
                                                                                    if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                        e.target.value = e.target.value + ".00";
                                                                                    }
                                                                                    handleChange(e);
                                                                                }} disabled={values.items.k.total === ""} />
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td class="text-center">28%</td>
                                                                            <td class="text-center">
                                                                                <input id="items.l.total" class="form-control newinv formedit currency" maxLength={16} value={values.items.l.total} onChange={e => {
                                                                                    const formatValue = getSubFormatvalue(e.target.value);
                                                                                    e.target.value = formatValue;
                                                                                    if (e.target.value === "") {
                                                                                        values.items.l.taxa = '';
                                                                                        values.items.l.taxb = '';
                                                                                        values.items.l.cess = '';
                                                                                    } else {
                                                                                        values.items.l.taxa = (parseFloat(e.target.value) * 0.28).toFixed(2);
                                                                                        values.items.l.taxb = ((parseFloat(e.target.value) * 0.28) / 2).toFixed(2);
                                                                                    }
                                                                                    handleChange(e);
                                                                                }} onBlur={e => {
                                                                                    if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                        e.target.value = e.target.value + ".00";
                                                                                    }
                                                                                    handleChange(e);
                                                                                }} />
                                                                            </td>
                                                                            {
                                                                                values.supplyty === "Inter-state" && <td class="text-center">
                                                                                    <input id="items.l.taxa" class="form-control newinv formedit currency" maxLength={16} value={values.items.l.taxa} onChange={e => {
                                                                                        const formatValue = getSubFormatvalue(e.target.value);
                                                                                        e.target.value = formatValue;
                                                                                        handleChange(e);
                                                                                    }} onBlur={e => {
                                                                                        if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                            e.target.value = e.target.value + ".00";
                                                                                        }
                                                                                        handleChange(e);
                                                                                    }} disabled={values.items.l.total === ""} />
                                                                                </td>
                                                                            }
                                                                            {
                                                                                values.supplyty === "Intra-state" && <>
                                                                                    <td class="text-center">
                                                                                        <input id="items.l.taxb" class="form-control newinv formedit currency" maxLength={16} value={values.items.l.taxb} onChange={e => {
                                                                                            const formatValue = getSubFormatvalue(e.target.value);
                                                                                            e.target.value = formatValue;
                                                                                            handleChange(e);
                                                                                        }} onBlur={e => {
                                                                                            if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                                e.target.value = e.target.value + ".00";
                                                                                            }
                                                                                            handleChange(e);
                                                                                        }} disabled={values.items.l.total === ""} />
                                                                                    </td>
                                                                                    <td class="text-center">
                                                                                        <input id="items.l.taxb" class="form-control newinv formedit currency" maxLength={16} value={values.items.l.taxb} onChange={e => {
                                                                                            const formatValue = getSubFormatvalue(e.target.value);
                                                                                            e.target.value = formatValue;
                                                                                            handleChange(e);
                                                                                        }} onBlur={e => {
                                                                                            if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                                e.target.value = e.target.value + ".00";
                                                                                            }
                                                                                            handleChange(e);
                                                                                        }} disabled={values.items.l.total === ""} />
                                                                                    </td>
                                                                                </>
                                                                            }
                                                                            <td class="text-center">
                                                                                <input id="items.l.cess" class="form-control newinv formedit currency" maxLength={16} value={values.items.l.cess} onChange={e => {
                                                                                    const formatValue = getSubFormatvalue(e.target.value);
                                                                                    e.target.value = formatValue;
                                                                                    handleChange(e);
                                                                                }} onBlur={e => {
                                                                                    if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                        e.target.value = e.target.value + ".00";
                                                                                    }
                                                                                    handleChange(e);
                                                                                }} disabled={values.items.l.total === ""} />
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </fieldset>
                                        </div>
                                    }
                                </fieldset>

                                <div class="row">
                                    <div class="col-sm-12 col-xs-12 text-right next-tab-nav">
                                        <button type="button" class="btn btn-default" onClick={() => {
                                            gotoGstrPath('b2cla')
                                        }} >Back</button>&nbsp;&nbsp;
                                        {!isView && <button type="submit" class="btn btn-primary accpt" disabled={submit}>Save</button>}
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}
