import React, { useState, useEffect } from 'react';

import { useRecoilState } from 'recoil';
import { pathAtom, gstrpathAtom, digErrorAtom, returnDateAtom, loadingAtom, gstrUserAtom, gstrQueryAtom, gstrFileStatusAtom, userIDAtom } from '../../../core/config/atoms';

import Moment from 'moment';
import { Dialog } from '@headlessui/react';

import ServiceGSTR from '../../../services/serviceGSTR';
import ServiceUtility from '../../../services/serviceUtility';

import imgLoader from '../../../assets/images/cloading.gif';

import '../css/main.css';
import '../css/style.css';

export default function PanelCDRHome() {

    const [path, setPath] = useRecoilState(pathAtom);
    const [gstrPath, setGSTRPath] = useRecoilState(gstrpathAtom);
    const [digError, setDigError] = useRecoilState(digErrorAtom);
    const [gDate] = useRecoilState(returnDateAtom);
    const [loading, setLoading] = useRecoilState(loadingAtom);
    const [GSTUser] = useRecoilState(gstrUserAtom);
    const [query, setQuery] = useRecoilState(gstrQueryAtom);
    const [fileStatus] = useRecoilState(gstrFileStatusAtom);
    const [userID] = useRecoilState(userIDAtom);

    const [records, setRecords] = useState([]);

    const [selection, setSelection] = useState({});
    const [digConfirm, setDigConfirm] = useState({ 'type': '0', isToggle: false, data: null });

    const [isSelect, setIsSelect] = useState(false);
    const [isAlert, setIsAlrt] = useState(false);

    const returnService = new ServiceGSTR();
    const utilService = new ServiceUtility();

    const gotoPath = (path) => {
        setLoading(true);
        setPath(path);
    }

    const gotoGstrPath = (path) => {
        setGSTRPath(path);
        gotoPath('gstrFile');
    }

    const getSummeryRecords = () => {
        let rows = [];
        records.forEach((row) => {
            if (rows.length === 0) {
                let c = row.status === 'processed' ? 1 : 0;
                let p = row.status !== 'processed' ? 1 : 0;
                rows.push({ gstn: row.data.ruid, tname: row.data.name, c: c, p: p });
            } else {
                let index = rows.findIndex((e) => e.gstn === row.data.ruid);
                if (index < 0) {
                    let c = row.status === 'processed' ? 1 : 0;
                    let p = row.status !== 'processed' ? 1 : 0;
                    rows.push({ gstn: row.data.ruid, tname: row.data.name, c: c, p: p });
                } else {
                    if (row.status === 'processed') {
                        rows[index].c = rows[index].c + 1;
                    } else {
                        rows[index].p = rows[index].p + 1;
                    }
                }
            }
        });
        return rows;
    }

    const showNormal = (gstn, name) => {
        setSelection({ gstn: gstn, name: name, status: 'C' });
        setIsSelect(true);
    }

    const showPending = (gstn, name) => {
        setSelection({ gstn: gstn, name: name, status: 'P' });
        setIsSelect(true);
    }

    const addNew = (gstn) => {
        setQuery({ type: 'add', data: gstn });
        gotoGstrPath('cdrb');
    }

    const viewInvoice = (id) => {
        setQuery({ type: 'view', data: id });
        gotoGstrPath('cdrb');
    }

    const editInvoice = (id) => {
        setQuery({ type: 'edit', data: id });
        gotoGstrPath('cdrb');
    }

    const showDig = (type, data) => {
        setDigConfirm({ 'type': type, isToggle: true, data: data });
    }

    const cancel = () => {
        setDigConfirm({ 'type': '0', isToggle: false, data: null });
    }

    const process = () => {
        switch (digConfirm.type) {
            case '1':
                deleteInvoice(digConfirm.data.id, digConfirm.data.status);
                break;
            default:
                break;
        }
        setDigConfirm({ 'type': '0', isToggle: false, data: null });
    }

    const deleteInvoice = (id, status) => {
        setLoading(true);
        let body = {
            lid: userID,
            type: 'cdr',
            status: status,
            month: gDate,
            id: id
        }
        returnService.deleteGstrDoc(body).then((res) => {
            setLoading(false);
            setIsSelect(false);
            showAlert();
            loadData();
        });
    }

    const getSelectionRecords = () => {
        let rows = [];
        records.forEach((row) => {
            let s = selection.status === 'C' ? 'processed' : 'pending';
            if (row.data.ruid === selection.gstn && s === row.status) {
                let tax = utilService.getTotalValue(row.data.items).toFixed(2);
                let total = parseFloat(row.data.cdrval).toFixed(2);
                let igst = (GSTUser.state === row.data.pos ? 0 : utilService.getTotalI(row.data.items)).toFixed(2);
                let cgst = (GSTUser.state === row.data.pos ? utilService.getTotalC(row.data.items) : 0).toFixed(2);
                let sgst = (GSTUser.state === row.data.pos ? utilService.getTotalS(row.data.items) : 0).toFixed(2);
                let cess = utilService.getTotalCess(row.data.items).toFixed(2);
                let ntype = row.data.notetype === "C" ? "Credit" : "Debit";
                let action = row.data.status;
                rows.push({ id: row._id, invno: row.data.cdr_no, date: Moment(row.data.cdrdate, 'DD-MM-YYYY').toDate(), ntype: ntype, total: total, tax: tax, igst: igst, cgst: cgst, sgst: sgst, cess: cess, action: action });
            }
        });
        return rows;
    }

    const showAlert = () => {
        setIsAlrt(true);
        setTimeout(
            () => setIsAlrt(false),
            5000
        );
    }

    const loadData = () => {
        setLoading(true);
        (async function () {
            let body = {
                lid: userID,
                month: gDate,
                type: 'cdr'
            }
            var res = await returnService.getGstrDocs(body);
            setRecords(res.data);
            if (query.type === 'view') {
                if (query.data['status']) {
                    showPending(query.data['gstn'], query.data['name']);
                } else {
                    showNormal(query.data['gstn'], query.data['name']);
                }
                setQuery({ type: '', data: '' });
            }
            setLoading(false);
            window.scrollTo({
                top: 0,
                behavior: 'smooth',
            });
        })();
    }

    useEffect(() => {
        loadData();
    }, []);

    return (
        <div>
            <Dialog open={digConfirm.isToggle} onClose={() => setDigConfirm({ 'type': '0', isToggle: false, data: null })}>
                <div id="confirmDlg" class="modal fade fade-scale in" role="dialog" style={{ display: 'block', paddingRight: '17px' }}>
                    <div class="modal-dialog sweet">
                        <div class="modal-content">
                            <div class="modal-body">
                                <div class="m-icon m-warning pulseWarning">
                                    <span class="micon-body pulseWarningIns"></span>
                                    <span class="micon-dot pulseWarningIns"></span>
                                </div>
                                <h2>Warning</h2>
                                <p>Are you sure, you want to continue?</p>
                            </div>
                            <div class="modal-footer">
                                <a class="btn btn-default" onClick={() => { cancel() }}>Cancel</a>
                                <a class="btn btn-primary" onClick={() => { process() }}>Proceed</a>
                            </div>
                        </div>
                    </div>
                </div>
            </Dialog>
            <div class="content-wrapper fade-ng-cloak">
                <div class="dimmer-holder" style={{ display: 'none' }}>
                    <div id="dimmer"></div>
                </div>
                {
                    loading && <div className='absolute z-50 w-full h-screen flex items-center justify-center bg-gray-200 bg-opacity-25'>
                        <img src={imgLoader} alt="loader" />
                    </div>
                }
                <div class="container">
                    {
                        !loading && <div class="mypage">
                            <div class="row" data-ng-controller="transctrl" data-ng-init="init('returns')">
                                {isAlert && <alert-message ng-show="showMsg" title="" type="msg alert-success" message="" class="ng-hide"><div class="alert alert-msg alert-success">Request accepted successfully<a class="close" onClick={() => { setIsAlrt(false) }} data-dismiss="alert" aria-label="close">×</a><strong></strong>.</div></alert-message>}
                                <div class="col-xs-10">
                                    <div data-breadcrumb="" name="Dashboard"><ol class="breadcrumb" data-ng-controller="crumbCtrl">
                                        <li onClick={() => {
                                            gotoPath('gstr')
                                        }}><a>Dashboard</a></li>
                                        <li onClick={() => {
                                            gotoPath('gstRetrun')
                                        }}><a>Returns</a></li>
                                        <li onClick={() => {
                                            gotoPath('gstrForm')
                                        }}><a>GSTR-1/IFF</a></li>
                                        <li>CDNR</li>
                                    </ol>
                                    </div>
                                </div>
                                <div class="col-xs-2">
                                    <div class="lang dropdown">
                                        <span class="dropdown-toggle" data-toggle="dropdown" data-ng-bind="selectedLang">English</span>
                                        <ul class="dropdown-menu lang-dpdwn">
                                            <li data-ng-repeat="language in languages">
                                                <a data-ng-click="selectLang(language.cd)" data-ng-bind="language.nm">English</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div class="row invsumm">
                                    <div class="col-xs-10 col-sm-10 taxp">
                                        <h4 data-ng-bind="trans.LBL_CRED_DEBT_NOTES_REG">9B - Credit / Debit Notes (Registered)</h4>
                                    </div>
                                    <div class="col-xs-2 col-sm-2">
                                        <button class="btn btn-primary btn-circle btn-sm pull-right" data-toggle="tooltip" title="Refresh" disabled={true}><i class="fa fa-refresh" aria-hidden="true"></i></button>
                                        <button data-ng-if="cdndelink=='Y'" type="button" class="btn btn-primary btn-sm" style={{ marginLeft: '45px' }} disabled={true}>
                                            <span>Help</span>&nbsp;
                                            <i class="fa fa-question-circle" aria-hidden="true"></i>
                                        </button>
                                    </div>
                                    <div class="col-sm-12">
                                        <ul class="nav nav-tabs ret-tabs">
                                            <li className={`${isSelect ? '' : 'active'}`} onClick={() => {
                                                setIsSelect(false);
                                            }}>
                                                <a>Recipient wise count</a>
                                            </li>
                                            {
                                                isSelect && <>
                                                    {
                                                        selection.status === 'C' && <li className={`${isSelect ? 'active' : ''}`}>
                                                            <a>Document wise details</a>
                                                        </li>
                                                    }
                                                    {
                                                        selection.status === 'P' && <li className={`${isSelect ? 'active' : ''}`}>
                                                            <a>Pending/Errored Record</a>
                                                        </li>
                                                    }
                                                </>
                                            }
                                        </ul>
                                    </div>
                                </div>
                                <div class="tabpane">
                                    {
                                        records.length !== 0 && !isSelect && <div class="btn-toolbar">
                                            <button type="button" class="btn btn-primary pull-left" style={{ marginLeft: '5px' }} onClick={() => {
                                                gotoGstrPath('cdrb')
                                            }} disabled={fileStatus}>Add Record</button>
                                        </div>
                                    }

                                    {
                                        records.length === 0 && <alert-message title="" type="msg alert-info" message="" class="ng-hide">
                                            <div class="alert alert-msg alert-info">There are no records to be displayed<a class="close" data-dismiss="alert" aria-label="close">×</a><strong></strong>.</div>
                                        </alert-message>
                                    }

                                    {
                                        records.length !== 0 && !isSelect && <>
                                            <div class="row" data-ng-show="cnt.b2bCptSummary">
                                                <div class="col-sm-12">
                                                    <h4>Record Details</h4>
                                                </div>
                                            </div>
                                            <div class="row" data-ng-show="cnt.b2bCptSummary">
                                                <div class="col-sm-12">
                                                    <div class="table-responsive">
                                                        <table class="table tbl inv table-bordered ng-table" style={{ display: 'table' }}>
                                                            <thead ng-include="templates.header">
                                                                <tr class="ng-table-sort-header">
                                                                    <th title="">
                                                                        <div class="ng-table-header">
                                                                            <span class="sort-indicator">Recipient Details </span>
                                                                        </div>
                                                                    </th>
                                                                    <th title="" class="header ">
                                                                        <div class="ng-table-header">
                                                                            <span class="sort-indicator">Trade/Legal Name</span>
                                                                        </div>
                                                                    </th>
                                                                    <th title="" class="header "> <div class="ng-table-header">
                                                                        <span class="sort-indicator">Taxpayer Type</span>
                                                                    </div>
                                                                    </th>
                                                                    <th title="" class="header sortable">
                                                                        <div class="ng-table-header">
                                                                            <span class="sort-indicator">Processed Note</span>
                                                                        </div>
                                                                    </th>
                                                                    <th title="" class="header sortable">
                                                                        <div class="ng-table-header">
                                                                            <span class="sort-indicator">Pending/Errored Note</span>
                                                                        </div>
                                                                    </th>
                                                                    <th title="" class="header">
                                                                        <div class="ng-table-header">
                                                                            <span class="sort-indicator">Add Note</span>
                                                                        </div>
                                                                    </th>
                                                                </tr>
                                                            </thead>
                                                            <tbody data-ng-repeat="cpty in $data">
                                                                {
                                                                    getSummeryRecords().map((row) => {
                                                                        return <tr>
                                                                            <td class="text-center">{row.gstn}</td>
                                                                            <td class="text-center" >{row.tname}</td>
                                                                            <td class="text-center"><div>Regular taxpayer</div></td>
                                                                            <td class="text-center">
                                                                                <a className={`${row.c === 0 ? 'alink' : ''}`} onClick={() => {
                                                                                    showNormal(row.gstn, row.tname);
                                                                                }}>{row.c}</a>
                                                                            </td>
                                                                            <td class="text-center">
                                                                                <a className={`${row.p === 0 ? 'alink' : ''}`} onClick={() => {
                                                                                    showPending(row.gstn, row.tname);
                                                                                }}>{row.p}</a>
                                                                            </td>
                                                                            <td class="text-center">
                                                                                <button style={{ backgroundColor: 'green', color: 'white' }} onClick={() => {
                                                                                    addNew(row.gstn);
                                                                                }} disabled={fileStatus}>
                                                                                    <i class="fa fa-plus-circle"></i>
                                                                                </button>
                                                                            </td>
                                                                        </tr>
                                                                    })
                                                                }
                                                            </tbody>
                                                        </table>
                                                        <div ng-table-pagination="params" template-url="templates.pagination">
                                                            <div ng-include="templateUrl">
                                                                <div class="ng-table-pager" ng-if="params.data.length"></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    }

                                    {
                                        isSelect && selection.status === 'C' && <div class="row" data-ng-if="b2binvdt.processedInvoice">
                                            <div class="col-sm-12">
                                                <h4 data-ng-bind="trans.LBL_PRO_RCD">Processed Records</h4>
                                            </div>
                                            <br />
                                            <div class="col-xs-12 col-sm-12">
                                                <div class="row">
                                                    <div class="col-sm-12" id="pill">
                                                        <div className='flex'>
                                                            <div className="bg-gray-300 rounded-full px-4 mr-4 text-black">
                                                                <span data-ng-bind="obj.recCtin">{selection.gstn}</span>
                                                            </div>
                                                            <div className="bg-gray-300 rounded-full px-4 text-black">
                                                                <span data-ng-bind="obj.tradeName">{selection.name}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-xs-12">
                                                        <div class="col-lg-2 pull-right">
                                                            <div class="form-group pull-right" style={{ display: 'inline-flex' }}>
                                                                <input class="form-control pull-right ng-pristine ng-untouched ng-valid ng-empty" type="text" style={{ width: '93%', border: '1px solid', fontFamily: 'FontAwesome' }} name="searchprs" ng-model="obj.searchTextPro" data-ng-change="customGlobalFilter('prs')" placeholder="&nbsp;Search..." title="Only numbers(0-9), characters(A-Z), space and special characters(/ ' - .) are allowed." data-toggle="tooltip" />
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-3 pull-right">
                                                            <div class="form-group pull-right" style={{ display: 'inline-flex' }}>
                                                                <div class="col-lg-9"><label class="form-label">Records Per Page : </label></div>
                                                                <div class="col-lg-3" style={{ paddingLeft: '0', marginLeft: '-0.7em' }}>
                                                                    <select class="form-control ng-pristine ng-untouched ng-valid ng-not-empty" style={{ minWidth: '5em', border: '1px solid' }} disabled={true} value="item">
                                                                        <option label="10" value="number:10" selected="selected">10</option>
                                                                        <option label="20" value="number:20">20</option>
                                                                        <option label="50" value="number:50">50</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div data-ng-if="ENABLE_EINVOICE" class="col-lg-4 pull-right">
                                                            <div class="form-group pull-right" style={{ display: 'inline-flex' }}>
                                                                <div class="col-lg-9" style={{ marginTop: '7px', fontWeight: 'bold' }}>
                                                                    <span class="pull-right" data-ng-bind="trans.LBL_DISPLAY_HIDE">Display/Hide Columns:</span>
                                                                </div>
                                                                <div class="col-lg-3" style={{ paddingLeft: '0' }}>
                                                                    <div ng-dropdown-multiselect="" events="multiSelectEvents" options="dropdownList" checkboxes="true" selected-model="selectedItems" extra-settings="myDropdownModelsettings"><div class="multiselect-parent btn-group dropdown-multiselect" ng-class="{open: open}"><div ng-transclude="toggleDropdown" ng-click="toggleDropdown()"><toggle-dropdown>
                                                                        <span class="btn btn-default custom-trigger" style={{ margin: '0px 0px 20px 0px!important' }}>
                                                                            <span class="caret"></span>
                                                                        </span>
                                                                    </toggle-dropdown></div></div></div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="table-responsive">
                                                    <table class="table exp inv tbl table-bordered ng-table" style={{ display: 'table' }}>
                                                        <thead ng-include="templates.header">
                                                            <tr class="ng-table-sort-header">
                                                                <th title="" ng-repeat="$column in $columns" class="header sortable">
                                                                    <div class="ng-table-header">
                                                                        <span class="sort-indicator">C/D Note No.</span>
                                                                    </div>
                                                                </th>
                                                                <th title="" class="header ">
                                                                    <div>
                                                                        <span class="sort-indicator">C/D Note Date</span>
                                                                    </div>
                                                                </th>
                                                                <th title="" class="header ">
                                                                    <div>
                                                                        <span class="sort-indicator">Note Type</span>
                                                                    </div>
                                                                </th>
                                                                <th title="" class="header ">
                                                                    <div>
                                                                        <span class="sort-indicator">Note value (₹)</span>
                                                                    </div>
                                                                </th>
                                                                <th title="" class="header ">
                                                                    <div>
                                                                        <span class="sort-indicator">Taxable value (₹)</span>
                                                                    </div>
                                                                </th>
                                                                <th title="" class="header ">
                                                                    <div>
                                                                        <span class="sort-indicator">Integrated Tax (₹)</span>
                                                                    </div>
                                                                </th>
                                                                <th title="" class="header ">
                                                                    <div>
                                                                        <span class="sort-indicator">Central tax (₹)</span>
                                                                    </div>
                                                                </th>
                                                                <th title="" class="header ">
                                                                    <div>
                                                                        <span class="sort-indicator">State/UT Tax (₹)</span>
                                                                    </div>
                                                                </th>
                                                                <th title="" class="header ">
                                                                    <div>
                                                                        <span class="sort-indicator">Cess (₹)</span>
                                                                    </div>
                                                                </th>
                                                                <th title="" class="header ">
                                                                    <div>
                                                                        <span class="sort-indicator">Source</span>
                                                                    </div>
                                                                </th>
                                                                <th title="" class="header ">
                                                                    <div>
                                                                        <span class="sort-indicator">Actions</span>
                                                                    </div>
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody data-ng-repeat="t in $data">
                                                            {
                                                                getSelectionRecords().map((row) => {
                                                                    return <tr>
                                                                        <td class="text-center">
                                                                            <a title="Click on the Invoice Number to view record" data-toggle="tooltip" onClick={() => {
                                                                                viewInvoice(row.id);
                                                                            }}>
                                                                                {row.invno}
                                                                            </a>
                                                                        </td>
                                                                        <td class="text-center w-10">
                                                                            {Moment(row.date).format("DD/MM/YYYY")}
                                                                        </td>
                                                                        <td class="text-center">{row.ntype}</td>
                                                                        <td class="currency">{row.total}</td>
                                                                        <td class="currency">{row.tax}</td>
                                                                        <td class="currency">{row.igst}</td>
                                                                        <td class="currency">{row.cgst}</td>
                                                                        <td class="currency">{row.sgst}</td>
                                                                        <td class="currency">{row.cess}</td>
                                                                        <td class="text-center"></td>
                                                                        <td style={{ display: 'inline-block' }} class="text-center newinv" data-title-text="Actions">
                                                                            <div className='flex'>
                                                                                <button type="button" class="btn btn-sm btn-primary nomargin" onClick={() => {
                                                                                    editInvoice(row.id);
                                                                                }} disabled={fileStatus}><i class="fa fa-pencil" aria-hidden="true"></i><span class="sr-only">text</span>
                                                                                </button>&nbsp;
                                                                                <button type="button" class="btn btn-sm btn-danger nomargin" title="Delete" onClick={() => {
                                                                                    showDig('1', { id: row.id, status: 'processed' });
                                                                                }} disabled={fileStatus}>
                                                                                    <i class="fa fa-trash"></i><span class="sr-only">text</span>
                                                                                </button>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                })
                                                            }
                                                        </tbody>
                                                    </table>
                                                    <div ng-table-pagination="params" template-url="templates.pagination">
                                                        <div ng-include="templateUrl">
                                                            <div class="ng-table-pager" ng-if="params.data.length">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }

                                    {
                                        isSelect && selection.status === 'P' && <div class="row" data-ng-if="b2binvdt.processedInvoice">
                                            <div class="col-sm-12">
                                                <h4 data-ng-bind="trans.LBL_PRO_RCD">Pending records (These will be added after validation)</h4>
                                            </div>
                                            <br />

                                            <div class="col-xs-12 col-sm-12">
                                                <div class="row">
                                                    <div class="col-sm-12" id="pill">
                                                        <div className='flex'>
                                                            <div className="bg-gray-300 rounded-full px-4 mr-4 text-black">
                                                                <span data-ng-bind="obj.recCtin">{selection.gstn}</span>
                                                            </div>
                                                            <div className="bg-gray-300 rounded-full px-4 text-black">
                                                                <span data-ng-bind="obj.tradeName">{selection.name}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="table-responsive">
                                                    <table class="table exp inv tbl table-bordered ng-table" style={{ display: 'table' }}>
                                                        <thead ng-include="templates.header">
                                                            <tr class="ng-table-sort-header">
                                                                <th title="" ng-repeat="$column in $columns" class="header sortable">
                                                                    <div class="ng-table-header">
                                                                        <span class="sort-indicator">C/D Note No.</span>
                                                                    </div>
                                                                </th>
                                                                <th title="" class="header ">
                                                                    <div>
                                                                        <span class="sort-indicator">C/D Note Date</span>
                                                                    </div>
                                                                </th>
                                                                <th title="" class="header ">
                                                                    <div>
                                                                        <span class="sort-indicator">Note Type</span>
                                                                    </div>
                                                                </th>
                                                                <th title="" class="header ">
                                                                    <div>
                                                                        <span class="sort-indicator">Note value (₹)</span>
                                                                    </div>
                                                                </th>
                                                                <th title="" class="header ">
                                                                    <div>
                                                                        <span class="sort-indicator">Taxable value (₹)</span>
                                                                    </div>
                                                                </th>
                                                                <th title="" class="header ">
                                                                    <div>
                                                                        <span class="sort-indicator">Integrated Tax (₹)</span>
                                                                    </div>
                                                                </th>
                                                                <th title="" class="header ">
                                                                    <div>
                                                                        <span class="sort-indicator">Central tax (₹)</span>
                                                                    </div>
                                                                </th>
                                                                <th title="" class="header ">
                                                                    <div>
                                                                        <span class="sort-indicator">State/UT Tax (₹)</span>
                                                                    </div>
                                                                </th>
                                                                <th title="" class="header ">
                                                                    <div>
                                                                        <span class="sort-indicator">Cess (₹)</span>
                                                                    </div>
                                                                </th>
                                                                <th title="" class="header ">
                                                                    <div>
                                                                        <span class="sort-indicator">Action Taken</span>
                                                                    </div>
                                                                </th>
                                                                <th title="" class="header ">
                                                                    <div>
                                                                        <span class="sort-indicator">Status</span>
                                                                    </div>
                                                                </th>
                                                                <th title="" class="header ">
                                                                    <div>
                                                                        <span class="sort-indicator">Actions</span>
                                                                    </div>
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody data-ng-repeat="t in $data">
                                                            {
                                                                getSelectionRecords().map((row) => {
                                                                    return <tr>
                                                                        <td class="text-center">
                                                                            <a title="Click on the Invoice Number to view record" data-toggle="tooltip" onClick={() => {
                                                                                editInvoice(row.id);
                                                                            }}>
                                                                                {row.invno}
                                                                            </a>
                                                                        </td>
                                                                        <td class="text-center w-10">
                                                                            {Moment(row.date).format("DD/MM/YYYY")}
                                                                        </td>
                                                                        <td class="text-center">{row.ntype}</td>
                                                                        <td class="currency">{row.total}</td>
                                                                        <td class="currency">{row.tax}</td>
                                                                        <td class="currency">{row.igst}</td>
                                                                        <td class="currency">{row.cgst}</td>
                                                                        <td class="currency">{row.sgst}</td>
                                                                        <td class="currency">{row.cess}</td>
                                                                        <td class="text-center">{row.action}</td>
                                                                        <td class="text-center">Processed with error</td>
                                                                        <td style={{ display: 'inline-block' }} class="text-center newinv" data-title-text="Actions">
                                                                            <div className='flex'>
                                                                                <button type="button" class="btn btn-sm btn-primary nomargin" onClick={() => {
                                                                                    editInvoice(row.id);
                                                                                }} disabled={fileStatus}><i class="fa fa-pencil" aria-hidden="true"></i><span class="sr-only">text</span>
                                                                                </button>&nbsp;
                                                                                <button type="button" class="btn btn-sm btn-danger nomargin" title="Delete" onClick={() => {
                                                                                    showDig('1', { id: row.id, status: 'pending' });
                                                                                }} disabled={fileStatus}>
                                                                                    <i class="fa fa-trash"></i><span class="sr-only">text</span>
                                                                                </button>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                })
                                                            }
                                                        </tbody>
                                                    </table>
                                                    <div ng-table-pagination="params" template-url="templates.pagination">
                                                        <div ng-include="templateUrl">
                                                            <div class="ng-table-pager" ng-if="params.data.length">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }

                                    <div class="btn-toolbar">
                                        {
                                            records.length === 0 && <div data-ng-hide="cnt.b2bCptSummary" class="ng-hide">
                                                <button type="button" class="btn btn-primary pull-right" style={{ marginLeft: '5px' }} onClick={() => {
                                                    gotoGstrPath('cdrb')
                                                }} disabled={fileStatus}>Add Record</button>
                                            </div>
                                        }
                                        <button type="button" class="btn btn-default pull-right" onClick={() => {
                                            gotoPath('gstrForm')
                                        }}>Back</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>
    );
}
