import React, { useState, useEffect } from 'react';

import { useRecoilState } from 'recoil';
import { digStorageAtom, docAtom } from '../../config/atoms';

import { useAlert } from 'react-alert';

import ServiceAuth from '../../../services/serviceAuth';

import icJpg from '../../../assets/images/misc/jpeg.png';
import icPdf from '../../../assets/images/misc/pdf.png';

import '../../../assets/styles/app.css';

const allowedExtensionsA = ["jpg", "pdf"];

export default function DigStorage() {

    const [dig, setDig] = useRecoilState(digStorageAtom);
    const [doc, setDoc] = useRecoilState(docAtom);

    const [bFiles, setBFiles] = useState(null);
    const [storage, setStorage] = useState([]);

    const [selIndex, setSelIndex] = useState(0);
    const [isFile, setIsFile] = useState(false);

    const authService = new ServiceAuth();
    const alert = useAlert();

    const cancel = () => {
        setDig({ title: '', msg: '', isToggle: false });
    }

    const setIndex = (index) => {
        setIsFile(false);
        setSelIndex(index);
    }

    const select = () => {
        if (!isFile) {
            setDoc({ isFile: true, type: 'storage', doc: storage[selIndex] });
            cancel();
        } else {
            setDoc({ isFile: true, type: 'doc', doc: bFiles });
            cancel();
        }
    }

    const handleFileChange = (e) => {
        if (e.target.files.length > 0) {
            let isError = false;

            for (let i = 0; i < e.target.files.length; i++) {
                const inputFile = e.target.files[i];
                const fileExtension = inputFile?.name.split(".")[1];
                console.log(inputFile?.name);
                if (!allowedExtensionsA.includes(fileExtension)) {
                    alert.show("Only .jpg file format is allowed, please try again!", { type: 'error' });
                    isError = true;
                }
            }

            if (isError) {
                return;
            }

            setBFiles(e.target.files[0]);
            setIsFile(true);
            setSelIndex(-1);
        }
    };

    useEffect(() => {
        (async function () {
            var res = await authService.getStorageBox({});
            setStorage(res.data);
        })();
    }, []);

    return (
        <div className='w-full h-full overflow-hidden fixed z-50 flex items-center justify-center bg-black bg-opacity-60'>
            <div id="confirmDlg" class="modal fade fade-scale in" role="dialog" style={{ display: 'block', padding: '100px' }}>
                <div className='bg-white w-[600px] rounded-md px-8 py-4 m-auto'>
                    <h2 className='m-0 text-4xl font-medium'>Storage Box</h2>
                    <p>Please select the approprite document or upload from computer.</p>
                    <hr />
                    <div className='grid grid-cols-5 gap-2 ml-10 my-4'>
                        {
                            storage.map((doc, index) => {
                                return <div className={`w-28 h-28 col-span-1 mt-2 flex flex-col items-center cursor-pointer`} key={index} onClick={() => { setIndex(index) }}>
                                    <div className={`w-16 h-20 ${selIndex === index ? 'bg-blue-600' : ''}`}>
                                        <img src={doc.filetype === '.jpg' ? icJpg : icPdf} alt="icon" className='w-full' />
                                    </div>
                                    <p className='text-center text-sm mt-2'>{doc.filename}</p>
                                </div>
                            })
                        }
                    </div>
                    <hr />
                    <input id="tr_upload" name="tr_upload" type="file" placeholder='Choose from computer' onChange={handleFileChange} />
                    <div style={{ marginTop: '10px', float: 'right' }}>
                        <a class="btn btn-default" onClick={() => { cancel() }} style={{ marginRight: '10px' }}>CANCEL</a>
                        <a class="btn btn-primary" onClick={() => { select() }} >SELECT</a>
                    </div>
                    <div style={{ clear: 'both' }}></div>
                </div>
            </div>
        </div>
    );
}
