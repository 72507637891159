import React, { useEffect, useRef } from 'react';

import { useRecoilState } from 'recoil';
import { lmsAtom } from '../core/config/atoms';

import PageLogin from '../pages/PageLogin';
import PageHome from '../pages/PageHome';
import PageResponsive from '../pages/PageResponsive';

import '../assets/styles/bootstrap.css';
import '../assets/styles/main.css';

export default function PathGST() {

    const [isLMS, setIsLMS] = useRecoilState(lmsAtom);

    const screenSize = useRef(window.innerWidth);

    useEffect(() => {
        window.addEventListener("resize", () => {
            screenSize.current = window.innerWidth;
        });
        return () => {
            window.removeEventListener("resize", () => {
                screenSize.current = window.innerWidth;
            });
        }
    }, []);

    return (
        <div>
            {
                screenSize.current > 1024 && isLMS && <PageHome />
            }
            {
                screenSize.current > 1024 && !isLMS && <PageLogin />
            }
            {
                screenSize.current <= 1024 && <PageResponsive />
            }
        </div>
    );
}