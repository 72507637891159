import React from 'react';
import styles from './css/print.module.css';

import { useRecoilState } from 'recoil';
import { gstrUserAtom, returnDateAtom } from '../../core/config/atoms';

import Logo from '../../assets/images/logoblue.png';

import Moment from 'moment';

export default function PanelSummeryPDF({ data, gstrFile }) {

    const [GSTUser] = useRecoilState(gstrUserAtom);
    const [gDate] = useRecoilState(returnDateAtom);

    const getStartY = () => {
        if (new Date(Moment(gDate).format('MM-DD-yyyy')).getMonth() < 3) {
            return Moment(gDate).subtract(1, 'year').format('YYYY');
        } else {
            return Moment(gDate).format('YYYY');
        }
    }

    const getEndY = () => {
        if (new Date(Moment(gDate).format('MM-DD-yyyy')).getMonth() < 3) {
            return Moment(gDate).format('YY');
        } else {
            return Moment(gDate).add(1, 'year').format('YY');
        }
    }

    const getName = () => {
        let name = "";
        GSTUser.users.forEach(element => {
            if (element.isPrime === "1") {
                name = element.pd_fnm + " " + element.pd_mname + " " + element.pd_lname;
            }
        });
        return name;
    }

    const getDesignation = () => {
        let dst = "";
        GSTUser.users.forEach(element => {
            if (element.isPrime === "1") {
                dst = element.dig;
            }
        });
        return dst;
    }

    return (
        <div className={styles.printDoc} id="divToPrint">
            <div className='fixed w-[1080px] h-[764px] flex flex-col items-center justify-center opacity-20 pointer-events-none' style={{ zIndex: 9999 }}>
                <img src={Logo} alt="ITAA" className='w-[800] mx-auto h-[227px] pointer-events-none' style={{ transform: "rotate(-45deg)" }} />
            </div>
            <p className={styles.s1} style={{ paddingTop: '3pt', textAlign: 'center' }}>FORM GSTR-1</p>
            <p className={styles.s1} style={{ paddingTop: '3pt', textAlign: 'center' }}>[See rule 59(1)]</p>
            <p className={styles.s1} style={{ paddingTop: '5pt', textAlign: 'center' }}>Details of outward supplies of goods or services</p>
            <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
            <table style={{ borderCollapse: "collapse", marginLeft: "620pt", display: "table", cellSpacing: "0pt !important" }}>
                <tr style={{ height: "13pt" }}>
                    <td style={{ margin: 0, padding: 0, width: "88pt", borderCollapse: "collapse", border: '1px solid black' }} bgcolor="#FBE4D5">
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingLeft: '4pt', textIndent: '0pt', textAlign: 'left' }}>Financial year</p>
                    </td>
                    <td style={{ margin: 0, width: "74pt", borderCollapse: "collapse", border: '1px solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingLeft: '4pt', textIndent: '0pt', textAlign: 'left' }}>{getStartY()}-{getEndY()}</p>
                    </td>
                </tr>
                <tr style={{ height: "13pt" }}>
                    <td style={{ margin: 0, width: "88pt", borderCollapse: "collapse", border: '1px solid black' }} bgcolor="#FBE4D5">
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingLeft: '4pt', textIndent: '0pt', textAlign: 'left' }}>Tax period</p>
                    </td>
                    <td style={{ margin: 0, width: "74pt", borderCollapse: "collapse", border: '1px solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingLeft: '4pt', textIndent: '0pt', textAlign: 'left' }}>{Moment(gDate).format("MMMM")}</p>
                    </td>
                </tr>
            </table>
            <table style={{ borderCollapse: "collapse", margin: "auto", marginTop: "5pt" }} cellSpacing="0">
                <tr style={{ height: "13pt" }}>
                    <td
                        style={{ width: "45pt", borderTopStyle: "solid", borderColor: 'black', borderTopWidth: "1pt", borderLeftStyle: "solid", borderLeftWidth: "1pt", borderBottomStyle: "solid", borderBottomWidth: "1pt", borderRightStyle: "solid", borderRightWidth: "1pt" }}>
                        <p className={styles.s4} style={{ paddingTop: "1pt", paddingLeft: "4pt", textIndent: "0pt", textAlign: "left" }}>1
                        </p>
                    </td>
                    <td style={{ width: "271pt", borderTopStyle: "solid", borderColor: 'black', borderTopWidth: "1pt", borderLeftStyle: "solid", borderLeftWidth: "1pt", borderBottomStyle: "solid", borderBottomWidth: "1pt", borderRightStyle: "solid", borderRightWidth: "1pt" }}
                        colspan="2" bgcolor="#FBE4D5">
                        <p className={styles.s4} style={{ paddingTop: "1pt", paddingLeft: "4pt", textIndent: "0pt", textAlign: "left" }}>
                            GSTIN
                        </p>
                    </td>
                    <td
                        style={{ width: "446pt", borderTopStyle: "solid", borderColor: 'black', borderTopWidth: "1pt", borderLeftStyle: "solid", borderLeftWidth: "1pt", borderBottomStyle: "solid", borderBottomWidth: "1pt", borderRightStyle: "solid", borderRightWidth: "1pt" }}>
                        <p className={styles.s4} style={{ paddingTop: "1pt", paddingLeft: "4pt", textIndent: "0pt", textAlign: "left" }}>
                            {GSTUser.gstn}
                        </p>
                    </td>
                </tr>
                <tr style={{ height: "13pt" }}>
                    <td style={{ width: "45pt", borderTopStyle: "solid", borderColor: 'black', borderTopWidth: "1pt", borderLeftStyle: "solid", borderLeftWidth: "1pt", borderBottomStyle: "solid", borderBottomWidth: "1pt", borderRightStyle: "solid", borderRightWidth: "1pt" }}
                        rowspan="4">
                        <p className={styles.s4} style={{ paddingTop: "1pt", paddingLeft: "4pt", textIndent: "0pt", textAlign: "left" }}>2
                        </p>
                    </td>
                    <td style={{ width: "44pt", borderTopStyle: "solid", borderColor: 'black', borderTopWidth: "1pt", borderLeftStyle: "solid", borderLeftWidth: "1pt", borderBottomStyle: "solid", borderBottomWidth: "1pt", borderRightStyle: "solid", borderRightWidth: "1pt" }}
                        bgcolor="#FBE4D5">
                        <p className={styles.s4} style={{ paddingTop: "1pt", paddingLeft: "4pt", textIndent: "0pt", textAlign: "left" }}>(a)
                        </p>
                    </td>
                    <td style={{ width: "227pt", borderTopStyle: "solid", borderColor: 'black', borderTopWidth: "1pt", borderLeftStyle: "solid", borderLeftWidth: "1pt", borderBottomStyle: "solid", borderBottomWidth: "1pt", borderRightStyle: "solid", borderRightWidth: "1pt" }}
                        bgcolor="#FBE4D5">
                        <p className={styles.s4} style={{ paddingTop: "1pt", paddingLeft: "4pt", textIndent: "0pt", textAlign: "left" }}>
                            Legal
                            name
                            of the registered person</p>
                    </td>
                    <td
                        style={{ width: "446pt", borderTopStyle: "solid", borderColor: 'black', borderTopWidth: "1pt", borderLeftStyle: "solid", borderLeftWidth: "1pt", borderBottomStyle: "solid", borderBottomWidth: "1pt", borderRightStyle: "solid", borderRightWidth: "1pt" }}>
                        <p className={styles.s4} style={{ paddingTop: "1pt", paddingLeft: "4pt", textIndent: "0pt", textAlign: "left" }}>
                            {GSTUser.buzName}
                        </p>
                    </td>
                </tr>
                <tr style={{ height: "13pt" }}>
                    <td style={{ width: "44pt", borderTopStyle: "solid", borderColor: 'black', borderTopWidth: "1pt", borderLeftStyle: "solid", borderLeftWidth: "1pt", borderBottomStyle: "solid", borderBottomWidth: "1pt", borderRightStyle: "solid", borderRightWidth: "1pt" }}
                        bgcolor="#FBE4D5">
                        <p className={styles.s4} style={{ paddingTop: "1pt", paddingLeft: "4pt", textIndent: "0pt", textAlign: "left" }}>(b)
                        </p>
                    </td>
                    <td style={{ width: "227pt", borderTopStyle: "solid", borderColor: 'black', borderTopWidth: "1pt", borderLeftStyle: "solid", borderLeftWidth: "1pt", borderBottomStyle: "solid", borderBottomWidth: "1pt", borderRightStyle: "solid", borderRightWidth: "1pt" }}
                        bgcolor="#FBE4D5">
                        <p className={styles.s4} style={{ paddingTop: "1pt", paddingLeft: "4pt", textIndent: "0pt", textAlign: "left" }}>
                            Trade
                            name
                            if any</p>
                    </td>
                    <td
                        style={{ width: "446pt", borderTopStyle: "solid", borderColor: 'black', borderTopWidth: "1pt", borderLeftStyle: "solid", borderLeftWidth: "1pt", borderBottomStyle: "solid", borderBottomWidth: "1pt", borderRightStyle: "solid", borderRightWidth: "1pt" }}>
                        <p className={styles.s4} style={{ paddingTop: "1pt", paddingLeft: "4pt", textIndent: "0pt", textAlign: "left" }}>
                            {GSTUser.tname}
                        </p>
                    </td>
                </tr>
                <tr style={{ height: "13pt" }}>
                    <td style={{ width: "44pt", borderTopStyle: "solid", borderColor: 'black', borderTopWidth: "1pt", borderLeftStyle: "solid", borderLeftWidth: "1pt", borderBottomStyle: "solid", borderBottomWidth: "1pt", borderRightStyle: "solid", borderRightWidth: "1pt" }}
                        bgcolor="#FBE4D5">
                        <p className={styles.s4} style={{ paddingTop: "1pt", paddingLeft: "4pt", textIndent: "0pt", textAlign: "left" }}>(c)
                        </p>
                    </td>
                    <td style={{ width: "227pt", borderTopStyle: "solid", borderColor: 'black', borderTopWidth: "1pt", borderLeftStyle: "solid", borderLeftWidth: "1pt", borderBottomStyle: "solid", borderBottomWidth: "1pt", borderRightStyle: "solid", borderRightWidth: "1pt" }}
                        bgcolor="#FBE4D5">
                        <p className={styles.s4} style={{ paddingTop: "1pt", paddingLeft: "4pt", textIndent: "0pt", textAlign: "left" }}>ARN
                        </p>
                    </td>
                    <td
                        style={{ width: "446pt", borderTopStyle: "solid", borderColor: 'black', borderTopWidth: "1pt", borderLeftStyle: "solid", borderLeftWidth: "1pt", borderBottomStyle: "solid", borderBottomWidth: "1pt", borderRightStyle: "solid", borderRightWidth: "1pt" }}>
                        <p className={styles.s4} style={{ paddingTop: "1pt", paddingLeft: "4pt", textIndent: "0pt", textAlign: "left" }}>
                            -</p>
                    </td>
                </tr>
                <tr style={{ height: "13pt" }}>
                    <td style={{ width: "44pt", borderTopStyle: "solid", borderColor: 'black', borderTopWidth: "1pt", borderLeftStyle: "solid", borderLeftWidth: "1pt", borderBottomStyle: "solid", borderBottomWidth: "1pt", borderRightStyle: "solid", borderRightWidth: "1pt" }}
                        bgcolor="#FBE4D5">
                        <p className={styles.s4} style={{ paddingTop: "1pt", paddingLeft: "4pt", textIndent: "0pt", textAlign: "left" }}>(d)
                        </p>
                    </td>
                    <td style={{ width: "227pt", borderTopStyle: "solid", borderColor: 'black', borderTopWidth: "1pt", borderLeftStyle: "solid", borderLeftWidth: "1pt", borderBottomStyle: "solid", borderBottomWidth: "1pt", borderRightStyle: "solid", borderRightWidth: "1pt" }}
                        bgcolor="#FBE4D5">
                        <p className={styles.s4} style={{ paddingTop: "1pt", paddingLeft: "4pt", textIndent: "0pt", textAlign: "left" }}>ARN
                            date
                        </p>
                    </td>
                    <td
                        style={{ width: "446pt", borderTopStyle: "solid", borderColor: 'black', borderTopWidth: "1pt", borderLeftStyle: "solid", borderLeftWidth: "1pt", borderBottomStyle: "solid", borderBottomWidth: "1pt", borderRightStyle: "solid", borderRightWidth: "1pt" }}>
                        <p className={styles.s4} style={{ paddingTop: "1pt", paddingLeft: "4pt", textIndent: "0pt", textAlign: "left" }}>
                            -
                        </p>
                    </td>
                </tr>
            </table>
            <table style={{ borderCollapse: "collapse", margin: "auto", marginTop: "5pt" }} cellSpacing="0">
                <tr style={{ height: "25pt" }} className="text-white">
                    <td style={{ width: '217pt', borderColor: 'black', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }}
                        bgcolor="#002060">
                        <p className={styles.s5} style={{ paddingTop: '6pt', paddingLeft: '85pt', paddingRight: '84pt', textIndent: '0pt', textAlign: 'center' }}>
                            Description
                        </p>
                    </td>
                    <td style={{ width: '57pt', borderColor: 'black', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }}
                        bgcolor="#002060">
                        <p className={styles.s5} style={{ paddingTop: '1pt', paddingLeft: '13pt', textIndent: '2pt', textAlign: 'left' }}>
                            No. of records
                        </p>
                    </td>
                    <td style={{ width: '64pt', borderColor: 'black', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }}
                        bgcolor="#002060">
                        <p className={styles.s5} style={{ paddingTop: '1pt', paddingLeft: '22pt', textIndent: '-11pt', textAlign: 'left' }}>
                            Document Type
                        </p>
                    </td>
                    <td style={{ width: '84pt', borderColor: 'black', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }}
                        bgcolor="#002060">
                        <p className={styles.s5} style={{ paddingTop: '6pt', paddingLeft: '24pt', textIndent: '0pt', textAlign: 'left' }}>
                            Value (₹)
                        </p>
                    </td>
                    <td style={{ width: '85pt', borderColor: 'black', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }}
                        bgcolor="#002060">
                        <p className={styles.s5} style={{ paddingTop: '6pt', paddingRight: '6pt', textIndent: '0pt', textAlign: 'right' }}>
                            Integrated Tax (₹)
                        </p>
                    </td>
                    <td style={{ width: '85pt', borderColor: 'black', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }}
                        bgcolor="#002060">
                        <p className={styles.s5} style={{ paddingTop: '6pt', paddingLeft: '13pt', textIndent: '0pt', textAlign: 'left' }}>
                            Central Tax (₹)
                        </p>
                    </td>
                    <td style={{ width: '85pt', borderColor: 'black', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }}
                        bgcolor="#002060">
                        <p className={styles.s5} style={{ paddingTop: '6pt', paddingLeft: '9pt', textIndent: '0pt', textAlign: 'left' }}>
                            State/UT Tax (₹)
                        </p>
                    </td>
                    <td style={{ width: '84pt', borderColor: 'black', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }}
                        bgcolor="#002060">
                        <p className={styles.s5} style={{ paddingTop: '6pt', paddingLeft: '25pt', textIndent: '0pt', textAlign: 'left' }}>
                            Cess (₹)
                        </p>
                    </td>
                </tr>
                <tr style={{ height: '13pt' }}>
                    <td style={{ width: '761pt', borderColor: 'black', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }}
                        colspan="8" bgcolor="#FBE4D5">
                        <p className={styles.s6} style={{ paddingTop: '1pt', paddingLeft: '4pt', textIndent: '0pt', textAlign: 'left' }}>
                            4A - Taxable outward supplies made to registered persons (other than reverse charge supplies) - B2B Regular
                        </p>
                    </td>
                </tr>
                <tr style={{ height: '13pt' }}>
                    <td
                        style={{ width: '217pt', borderColor: 'black', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingLeft: '4pt', textIndent: '0pt', textAlign: 'left' }}>
                            Total
                        </p>
                    </td>
                    <td
                        style={{ width: '57pt', borderColor: 'black', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }}>
                        <p className={styles.s4}
                            style={{ paddingTop: '1pt', paddingLeft: '23pt', paddingRight: '23pt', textIndent: '0pt', textAlign: 'center' }}>
                            {(data.b2bA.total.count)}
                        </p>
                    </td>
                    <td
                        style={{ width: '64pt', borderColor: 'black', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '19pt', textIndent: '0pt', textAlign: 'right' }}>
                            Invoice
                        </p>
                    </td>
                    <td
                        style={{ width: '84pt', borderColor: 'black', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '3pt', textIndent: '0pt', textAlign: 'right' }}>
                            {(data.b2bA.total.value).toFixed(2)}
                        </p>
                    </td>
                    <td
                        style={{ width: '85pt', borderColor: 'black', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '3pt', textIndent: '0pt', textAlign: 'right' }}>
                            {(data.b2bA.total.taxa).toFixed(2)}
                        </p>
                    </td>
                    <td
                        style={{ width: '85pt', borderColor: 'black', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '3pt', textIndent: '0pt', textAlign: 'right' }}>
                            {(data.b2bA.total.taxb).toFixed(2)}
                        </p>
                    </td>
                    <td
                        style={{ width: '85pt', borderColor: 'black', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '3pt', textIndent: '0pt', textAlign: 'right' }}>
                            {(data.b2bA.total.taxb).toFixed(2)}
                        </p>
                    </td>
                    <td
                        style={{ width: '84pt', borderColor: 'black', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '3pt', textIndent: '0pt', textAlign: 'right' }}>
                            {(data.b2bA.total.cess).toFixed(2)}
                        </p>
                    </td>
                </tr>
                <tr style={{ height: '13pt' }}>
                    <td style={{ width: '761pt', borderColor: 'black', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }}
                        colspan="8">
                        <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                    </td>
                </tr>
                <tr style={{ height: '13pt' }}>
                    <td style={{ width: '761pt', borderColor: 'black', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }}
                        colspan="8" bgcolor="#FBE4D5">
                        <p className={styles.s6} style={{ paddingTop: '1pt', paddingLeft: '4pt', textIndent: '0pt', textAlign: 'left' }}>
                            4B -  Taxable outward supplies made to registered persons attracting tax on reverse charge - B2B Reverse charge
                        </p>
                    </td>
                </tr>
                <tr style={{ height: '13pt' }}>
                    <td style={{ width: '217pt', borderColor: 'black', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingLeft: '4pt', textIndent: '0pt', textAlign: 'left' }}>
                            Total
                        </p>
                    </td>
                    <td style={{ width: '57pt', borderColor: 'black', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', textIndent: '0pt', textAlign: 'center' }}>{(data.b2bB.total.count)}</p>
                    </td>
                    <td style={{ width: '64pt', borderColor: 'black', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '19pt', textIndent: '0pt', textAlign: 'right' }}>
                            Invoice
                        </p>
                    </td>
                    <td style={{ width: '84pt', borderColor: 'black', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '3pt', textIndent: '0pt', textAlign: 'right' }}>
                            {(data.b2bB.total.value).toFixed(2)}
                        </p>
                    </td>
                    <td style={{ width: '85pt', borderColor: 'black', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '3pt', textIndent: '0pt', textAlign: 'right' }}>
                            {(data.b2bB.total.taxa).toFixed(2)}
                        </p>
                    </td>
                    <td style={{ width: '85pt', borderColor: 'black', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '3pt', textIndent: '0pt', textAlign: 'right' }}>
                            {(data.b2bB.total.taxb).toFixed(2)}
                        </p>
                    </td>
                    <td style={{ width: '85pt', borderColor: 'black', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '3pt', textIndent: '0pt', textAlign: 'right' }}>
                            {(data.b2bB.total.taxb).toFixed(2)}
                        </p>
                    </td>
                    <td style={{ width: '84pt', borderColor: 'black', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '3pt', textIndent: '0pt', textAlign: 'right' }}>
                            {(data.b2bB.total.cess).toFixed(2)}
                        </p>
                    </td>
                </tr>
                <tr style={{ height: '13pt' }}>
                    <td style={{ width: '761pt', borderColor: 'black', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }}
                        colspan="8">
                        <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                    </td>
                </tr>
                <tr style={{ height: '13pt' }}>
                    <td style={{ width: '761pt', borderColor: 'black', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }} colspan="8" bgcolor="#FBE4D5">
                        <p className={styles.s6} style={{ paddingTop: '1pt', paddingLeft: '4pt', textIndent: '0pt', textAlign: 'left' }}>5A -
                            Taxable outward inter-state supplies made to unregistered persons (where invoice value is more than Rs.2.5 lakh) - B2CL (Large)
                        </p>
                    </td>
                </tr>
                <tr style={{ height: '13pt' }}>
                    <td style={{ width: '217pt', borderColor: 'black', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingLeft: '4pt', textIndent: '0pt', textAlign: 'left' }}>
                            Total
                        </p>
                    </td>
                    <td style={{ width: '57pt', borderColor: 'black', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', textIndent: '0pt', textAlign: 'center' }}>{data.b2cl.total.count}</p>
                    </td>
                    <td style={{ width: '64pt', borderColor: 'black', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '19pt', textIndent: '0pt', textAlign: 'right' }}>
                            Invoice
                        </p>
                    </td>
                    <td style={{ width: '84pt', borderColor: 'black', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '3pt', textIndent: '0pt', textAlign: 'right' }}>
                            {(data.b2cl.total.value).toFixed(2)}
                        </p>
                    </td>
                    <td style={{ width: '85pt', borderColor: 'black', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '3pt', textIndent: '0pt', textAlign: 'right' }}>
                            {(data.b2cl.total.taxa).toFixed(2)}
                        </p>
                    </td>
                    <td style={{ width: '85pt', borderColor: 'black', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }}
                        bgcolor="#949494">
                        <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                    </td>
                    <td style={{ width: '85pt', borderColor: 'black', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }}
                        bgcolor="#949494">
                        <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                    </td>
                    <td style={{ width: '84pt', borderColor: 'black', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '3pt', textIndent: '0pt', textAlign: 'right' }}>
                            {(data.b2cl.total.cess).toFixed(2)}
                        </p>
                    </td>
                </tr>
                <tr style={{ height: '13pt' }}>
                    <td style={{ width: '761pt', borderColor: 'black', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }}
                        colspan="8">
                        <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                    </td>
                </tr>
                <tr style={{ height: '13pt' }}>
                    <td style={{ width: '761pt', borderColor: 'black', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }} colspan="8" bgcolor="#FBE4D5">
                        <p className={styles.s6} style={{ paddingTop: '1pt', paddingLeft: '4pt', textIndent: '0pt', textAlign: 'left' }}>
                            6A – Exports (with/without payment)
                        </p>
                    </td>
                </tr>
                <tr style={{ height: '13pt' }}>
                    <td
                        style={{ width: '217pt', borderColor: 'black', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingLeft: '4pt', textIndent: '0pt', textAlign: 'left' }}>
                            Total
                        </p>
                    </td>
                    <td
                        style={{ width: '57pt', borderColor: 'black', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', textIndent: '0pt', textAlign: 'center' }}>{data.a6.total.count}</p>
                    </td>
                    <td
                        style={{ width: '64pt', borderColor: 'black', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '19pt', textIndent: '0pt', textAlign: 'right' }}>
                            Invoice
                        </p>
                    </td>
                    <td
                        style={{ width: '84pt', borderColor: 'black', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '3pt', textIndent: '0pt', textAlign: 'right' }}>
                            {(data.a6.total.value).toFixed(2)}
                        </p>
                    </td>
                    <td
                        style={{ width: '85pt', borderColor: 'black', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '3pt', textIndent: '0pt', textAlign: 'right' }}>
                            {(data.a6.total.taxa).toFixed(2)}
                        </p>
                    </td>
                    <td style={{ width: '85pt', borderColor: 'black', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }}
                        bgcolor="#949494">
                        <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                    </td>
                    <td style={{ width: '85pt', borderColor: 'black', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }}
                        bgcolor="#949494">
                        <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                    </td>
                    <td
                        style={{ width: '84pt', borderColor: 'black', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '3pt', textIndent: '0pt', textAlign: 'right' }}>
                            {(data.a6.total.cess).toFixed(2)}
                        </p>
                    </td>
                </tr>
                <tr style={{ height: '13pt' }}>
                    <td
                        style={{ width: '217pt', borderColor: 'black', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingLeft: '19pt', textIndent: '0pt', textAlign: 'left' }}>-
                            EXPWP
                        </p>
                    </td>
                    <td
                        style={{ width: '57pt', borderColor: 'black', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', textIndent: '0pt', textAlign: 'center' }}>{data.a6.wp.count}</p>
                    </td>
                    <td
                        style={{ width: '64pt', borderColor: 'black', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '19pt', textIndent: '0pt', textAlign: 'right' }}>
                            Invoice
                        </p>
                    </td>
                    <td
                        style={{ width: '84pt', borderColor: 'black', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '3pt', textIndent: '0pt', textAlign: 'right' }}>
                            {(data.a6.wp.value).toFixed(2)}
                        </p>
                    </td>
                    <td
                        style={{ width: '85pt', borderColor: 'black', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '3pt', textIndent: '0pt', textAlign: 'right' }}>
                            {(data.a6.wp.taxa).toFixed(2)}
                        </p>
                    </td>
                    <td style={{ width: '85pt', borderColor: 'black', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }}
                        bgcolor="#949494">
                        <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                    </td>
                    <td style={{ width: '85pt', borderColor: 'black', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }}
                        bgcolor="#949494">
                        <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                    </td>
                    <td
                        style={{ width: '84pt', borderColor: 'black', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '3pt', textIndent: '0pt', textAlign: 'right' }}>
                            {(data.a6.total.cess).toFixed(2)}
                        </p>
                    </td>
                </tr>
                <tr style={{ height: '13pt' }}>
                    <td
                        style={{ width: '217pt', borderColor: 'black', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingLeft: '19pt', textIndent: '0pt', textAlign: 'left' }}>-
                            EXPWOP
                        </p>
                    </td>
                    <td
                        style={{ width: '57pt', borderColor: 'black', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', textIndent: '0pt', textAlign: 'center' }}>{data.a6.wop.count}</p>
                    </td>
                    <td
                        style={{ width: '64pt', borderColor: 'black', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '19pt', textIndent: '0pt', textAlign: 'right' }}>
                            Invoice
                        </p>
                    </td>
                    <td
                        style={{ width: '84pt', borderColor: 'black', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '3pt', textIndent: '0pt', textAlign: 'right' }}>
                            {(data.a6.wop.value).toFixed(2)}
                        </p>
                    </td>
                    <td style={{ width: '85pt', borderColor: 'black', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }}
                        bgcolor="#949494">
                        <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                    </td>
                    <td style={{ width: '85pt', borderColor: 'black', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }}
                        bgcolor="#949494">
                        <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                    </td>
                    <td style={{ width: '85pt', borderColor: 'black', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }}
                        bgcolor="#949494">
                        <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                    </td>
                    <td style={{ width: '84pt', borderColor: 'black', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }}
                        bgcolor="#949494">
                        <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                    </td>
                </tr>
                <tr style={{ height: '13pt' }}>
                    <td style={{ width: '761pt', borderColor: 'black', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }}
                        colspan="8">
                        <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                    </td>
                </tr>
                <tr style={{ height: '13pt' }}>
                    <td style={{ width: '761pt', borderColor: 'black', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }}
                        colspan="8" bgcolor="#FBE4D5">
                        <p className={styles.s6} style={{ paddingTop: '1pt', paddingLeft: '4pt', textIndent: '0pt', textAlign: 'left' }}>6B -
                            Supplies made to SEZ unit or SEZ developer - SEZWP/SEZWOP</p>
                    </td>
                </tr>
                <tr style={{ height: '13pt' }}>
                    <td
                        style={{ width: '217pt', borderColor: 'black', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingLeft: '4pt', textIndent: '0pt', textAlign: 'left' }}>
                            Total
                        </p>
                    </td>
                    <td
                        style={{ width: '57pt', borderColor: 'black', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', textIndent: '0pt', textAlign: 'center' }}>0</p>
                    </td>
                    <td
                        style={{ width: '64pt', borderColor: 'black', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '19pt', textIndent: '0pt', textAlign: 'right' }}>
                            Invoice
                        </p>
                    </td>
                    <td
                        style={{ width: '84pt', borderColor: 'black', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '3pt', textIndent: '0pt', textAlign: 'right' }}>
                            0.00
                        </p>
                    </td>
                    <td
                        style={{ width: '85pt', borderColor: 'black', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '3pt', textIndent: '0pt', textAlign: 'right' }}>
                            0.00
                        </p>
                    </td>
                    <td style={{ width: '85pt', borderColor: 'black', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }}
                        bgcolor="#949494">
                        <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                    </td>
                    <td style={{ width: '85pt', borderColor: 'black', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }}
                        bgcolor="#949494">
                        <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                    </td>
                    <td
                        style={{ width: '84pt', borderColor: 'black', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '3pt', textIndent: '0pt', textAlign: 'right' }}>
                            0.00
                        </p>
                    </td>
                </tr>
                <tr style={{ height: '13pt' }}>
                    <td
                        style={{ width: '217pt', borderColor: 'black', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingLeft: '19pt', textIndent: '0pt', textAlign: 'left' }}>-
                            SEZWP
                        </p>
                    </td>
                    <td
                        style={{ width: '57pt', borderColor: 'black', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', textIndent: '0pt', textAlign: 'center' }}>0</p>
                    </td>
                    <td
                        style={{ width: '64pt', borderColor: 'black', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '19pt', textIndent: '0pt', textAlign: 'right' }}>
                            Invoice
                        </p>
                    </td>
                    <td
                        style={{ width: '84pt', borderColor: 'black', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '3pt', textIndent: '0pt', textAlign: 'right' }}>
                            0.00
                        </p>
                    </td>
                    <td
                        style={{ width: '85pt', borderColor: 'black', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '3pt', textIndent: '0pt', textAlign: 'right' }}>
                            0.00
                        </p>
                    </td>
                    <td style={{ width: '85pt', borderColor: 'black', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }}
                        bgcolor="#949494">
                        <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                    </td>
                    <td style={{ width: '85pt', borderColor: 'black', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }}
                        bgcolor="#949494">
                        <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                    </td>
                    <td
                        style={{ width: '84pt', borderColor: 'black', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '3pt', textIndent: '0pt', textAlign: 'right' }}>
                            0.00
                        </p>
                    </td>
                </tr>
                <tr style={{ height: '13pt' }}>
                    <td
                        style={{ width: '217pt', borderColor: 'black', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingLeft: '19pt', textIndent: '0pt', textAlign: 'left' }}>-
                            SEZWOP
                        </p>
                    </td>
                    <td
                        style={{ width: '57pt', borderColor: 'black', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', textIndent: '0pt', textAlign: 'center' }}>0</p>
                    </td>
                    <td
                        style={{ width: '64pt', borderColor: 'black', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '19pt', textIndent: '0pt', textAlign: 'right' }}>
                            Invoice
                        </p>
                    </td>
                    <td
                        style={{ width: '84pt', borderColor: 'black', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '3pt', textIndent: '0pt', textAlign: 'right' }}>
                            0.00
                        </p>
                    </td>
                    <td style={{ width: '85pt', borderColor: 'black', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }}
                        bgcolor="#949494">
                        <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                    </td>
                    <td style={{ width: '85pt', borderColor: 'black', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }}
                        bgcolor="#949494">
                        <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                    </td>
                    <td style={{ width: '85pt', borderColor: 'black', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }}
                        bgcolor="#949494">
                        <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                    </td>
                    <td style={{ width: '84pt', borderColor: 'black', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }}
                        bgcolor="#949494">
                        <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                    </td>
                </tr>
                <tr style={{ height: '13pt' }}>
                    <td style={{ width: '761pt', borderColor: 'black', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }}
                        colspan="8">
                        <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                    </td>
                </tr>
                <tr style={{ height: '13pt' }}>
                    <td style={{ width: '761pt', borderColor: 'black', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }}
                        colspan="8" bgcolor="#FBE4D5">
                        <p className={styles.s6} style={{ paddingTop: '1pt', paddingLeft: '4pt', textIndent: '0pt', textAlign: 'left' }}>6C -
                            Deemed
                            Exports – DE</p>
                    </td>
                </tr>
                <tr style={{ height: '13pt' }}>
                    <td
                        style={{ width: '217pt', borderColor: 'black', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingLeft: '4pt', textIndent: '0pt', textAlign: 'left' }}>
                            Total
                        </p>
                    </td>
                    <td
                        style={{ width: '57pt', borderColor: 'black', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', textIndent: '0pt', textAlign: 'center' }}>0</p>
                    </td>
                    <td
                        style={{ width: '64pt', borderColor: 'black', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '19pt', textIndent: '0pt', textAlign: 'right' }}>
                            Invoice
                        </p>
                    </td>
                    <td
                        style={{ width: '84pt', borderColor: 'black', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '3pt', textIndent: '0pt', textAlign: 'right' }}>
                            0.00
                        </p>
                    </td>
                    <td
                        style={{ width: '85pt', borderColor: 'black', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '3pt', textIndent: '0pt', textAlign: 'right' }}>
                            0.00
                        </p>
                    </td>
                    <td
                        style={{ width: '85pt', borderColor: 'black', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '3pt', textIndent: '0pt', textAlign: 'right' }}>
                            0.00
                        </p>
                    </td>
                    <td
                        style={{ width: '85pt', borderColor: 'black', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '3pt', textIndent: '0pt', textAlign: 'right' }}>
                            0.00
                        </p>
                    </td>
                    <td
                        style={{ width: '84pt', borderColor: 'black', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '3pt', textIndent: '0pt', textAlign: 'right' }}>
                            0.00
                        </p>
                    </td>
                </tr>
                <tr style={{ height: '13pt' }}>
                    <td style={{ width: '761pt', borderColor: 'black', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }} colspan="8">
                        <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                    </td>
                </tr>
                <tr style={{ height: '13pt' }}>
                    <td style={{ width: '761pt', borderColor: 'black', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }} colspan="8" bgcolor="#FBE4D5">
                        <p className={styles.s6} style={{ paddingTop: '1pt', paddingLeft: '4pt', textIndent: '0pt', textAlign: 'left' }}>
                            7 - Taxable supplies (Net of debit and credit notes) to unregistered persons (other than the supplies covered in Table 5) - B2CS (Others)
                        </p>
                    </td>
                </tr>
            </table>
            <p style={{ pageBreakAfter: 'always' }}>&nbsp;</p>
            <div className='fixed w-[1080px] h-[764px] flex flex-col items-center justify-center opacity-20 pointer-events-none' style={{ zIndex: 9999 }}>
                <img src={Logo} alt="ITAA" className='w-[800] mx-auto h-[227px] pointer-events-none' style={{ transform: "rotate(-45deg)" }} />
            </div>
            <table style={{ borderCollapse: "collapse", margin: "auto", marginTop: "5pt" }} cellspacing="0">
                <tr style={{ height: '25pt' }}>
                    <td style={{ width: '217pt', border: '1pt solid black' }} bgcolor="#002060">
                        <p className={styles.s5} style={{ paddingTop: '6pt', paddingLeft: '85pt', paddingRight: '84pt', textIndent: '0pt', textAlign: 'center' }}>
                            Description
                        </p>
                    </td>
                    <td style={{ width: '57pt', border: '1pt solid black' }} bgcolor="#002060">
                        <p className={styles.s5} style={{ paddingTop: '1pt', paddingLeft: '13pt', textIndent: '2pt', textAlign: 'left' }}>
                            No. of records
                        </p>
                    </td>
                    <td style={{ width: '64pt', border: '1pt solid black' }} bgcolor="#002060">
                        <p className={styles.s5} style={{ paddingTop: '1pt', paddingLeft: '22pt', textIndent: '-11pt', textAlign: 'left' }}>
                            Document Type
                        </p>
                    </td>
                    <td style={{ width: '84pt', border: '1pt solid black' }} bgcolor="#002060">
                        <p className={styles.s5} style={{ paddingTop: '6pt', paddingLeft: '24pt', textIndent: '0pt', textAlign: 'left' }}>
                            Value (₹)
                        </p>
                    </td>
                    <td style={{ width: '85pt', border: '1pt solid black' }} bgcolor="#002060">
                        <p className={styles.s5} style={{ paddingTop: '6pt', paddingRight: '6pt', textIndent: '0pt', textAlign: 'right' }}>
                            Integrated Tax (₹)
                        </p>
                    </td>
                    <td style={{ width: '85pt', border: '1pt solid black' }} bgcolor="#002060">
                        <p className={styles.s5} style={{ paddingTop: '6pt', paddingLeft: '13pt', textIndent: '0pt', textAlign: 'left' }}>
                            Central Tax (₹)
                        </p>
                    </td>
                    <td style={{ width: '85pt', border: '1pt solid black' }} bgcolor="#002060">
                        <p className={styles.s5} style={{ paddingTop: '6pt', paddingLeft: '9pt', textIndent: '0pt', textAlign: 'left' }}>
                            State/UT Tax (₹)
                        </p>
                    </td>
                    <td style={{ width: '84pt', border: '1pt solid black' }} bgcolor="#002060">
                        <p className={styles.s5} style={{ paddingTop: '6pt', paddingLeft: '25pt', textIndent: '0pt', textAlign: 'left' }}>
                            Cess (₹)
                        </p>
                    </td>
                </tr>
                <tr style={{ height: '13pt' }}>
                    <td style={{ width: '217pt', border: '1px solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingLeft: '4pt', textIndent: '0pt', textAlign: 'left' }}>
                            Total
                        </p>
                    </td>
                    <td style={{ width: '57pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '25pt', textIndent: '0pt', textAlign: 'right' }}>
                            {data.b2co.total.count}
                        </p>
                    </td>
                    <td style={{ width: '64pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingLeft: '7pt', paddingRight: '6pt', textIndent: '0pt', textAlign: 'center' }}>
                            Net Value
                        </p>
                    </td>
                    <td style={{ width: '84pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '3pt', textIndent: '0pt', textAlign: 'right' }}>
                            {(data.b2co.total.value).toFixed(2)}
                        </p>
                    </td>
                    <td style={{ width: '85pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '3pt', textIndent: '0pt', textAlign: 'right' }}>
                            {(data.b2co.total.taxa).toFixed(2)}
                        </p>
                    </td>
                    <td style={{ width: '85pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '3pt', textIndent: '0pt', textAlign: 'right' }}>
                            {(data.b2co.total.taxb).toFixed(2)}
                        </p>
                    </td>
                    <td style={{ width: '85pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '3pt', textIndent: '0pt', textAlign: 'right' }}>
                            {(data.b2co.total.taxb).toFixed(2)}
                        </p>
                    </td>
                    <td style={{ width: '84pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '3pt', textIndent: '0pt', textAlign: 'right' }}>
                            {(data.b2co.total.cess).toFixed(2)}
                        </p>
                    </td>
                </tr>
                <tr style={{ height: '13pt' }}>
                    <td style={{ width: '761pt', border: '1pt solid black' }} colspan="8">
                        <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                    </td>
                </tr>
                <tr style={{ height: '13pt' }}>
                    <td style={{ width: '761pt', border: '1pt solid black' }} colspan="8" bgcolor="#FBE4D5">
                        <p className={styles.s6} style={{ paddingTop: '1pt', paddingLeft: '4pt', textIndent: '0pt', textAlign: 'left' }}>
                            8 - Nil rated, exempted and non GST outward supplies
                        </p>
                    </td>
                </tr>
                <tr style={{ height: '13pt' }}>
                    <td style={{ width: '338pt', border: '1pt solid black' }} colspan="3">
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingLeft: '4pt', textIndent: '0pt', textAlign: 'left' }}>
                            Total
                        </p>
                    </td>
                    <td style={{ width: '84pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '3pt', textIndent: '0pt', textAlign: 'right' }}>
                            {(data.nill.total.total).toFixed(2)}
                        </p>
                    </td>
                    <td style={{ width: '85pt', border: '1pt solid black' }} bgcolor="#949494">
                        <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                    </td>
                    <td style={{ width: '85pt', border: '1pt solid black' }} bgcolor="#949494">
                        <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                    </td>
                    <td style={{ width: '85pt', border: '1pt solid black' }} bgcolor="#949494">
                        <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                    </td>
                    <td style={{ width: '84pt', border: '1pt solid black' }} bgcolor="#949494">
                        <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                    </td>
                </tr>
                <tr style={{ height: '13pt' }}>
                    <td style={{ width: '338pt', border: '1pt solid black' }} colspan="3">
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingLeft: '19pt', textIndent: '0pt', textAlign: 'left' }}>-
                            Nil
                        </p>
                    </td>
                    <td style={{ width: '84pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '3pt', textIndent: '0pt', textAlign: 'right' }}>
                            {(data.nill.total.vala).toFixed(2)}
                        </p>
                    </td>
                    <td style={{ width: '85pt', border: '1pt solid black' }} bgcolor="#949494">
                        <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                    </td>
                    <td style={{ width: '85pt', border: '1pt solid black' }} bgcolor="#949494">
                        <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                    </td>
                    <td style={{ width: '85pt', border: '1pt solid black' }} bgcolor="#949494">
                        <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                    </td>
                    <td style={{ width: '84pt', border: '1pt solid black' }} bgcolor="#949494">
                        <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                    </td>
                </tr>
                <tr style={{ height: '13pt' }}>
                    <td style={{ width: '338pt', border: '1pt solid black' }} colspan="3">
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingLeft: '19pt', textIndent: '0pt', textAlign: 'left' }}>-
                            Exempted
                        </p>
                    </td>
                    <td
                        style={{ width: '84pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '3pt', textIndent: '0pt', textAlign: 'right' }}>
                            {(data.nill.total.valb).toFixed(2)}
                        </p>
                    </td>
                    <td style={{ width: '85pt', border: '1pt solid black' }} bgcolor="#949494">
                        <p style={{ textIndent: '0pt', textAlign: 'left' }}><br />
                        </p>
                    </td>
                    <td style={{ width: '85pt', border: '1pt solid black' }} bgcolor="#949494">
                        <p style={{ textIndent: '0pt', textAlign: 'left' }}><br />
                        </p>
                    </td>
                    <td style={{ width: '85pt', border: '1pt solid black' }} bgcolor="#949494">
                        <p style={{ textIndent: '0pt', textAlign: 'left' }}><br />
                        </p>
                    </td>
                    <td style={{ width: '84pt', border: '1pt solid black' }} bgcolor="#949494">
                        <p style={{ textIndent: '0pt', textAlign: 'left' }}><br />
                        </p>
                    </td>
                </tr>
                <tr style={{ height: '13pt' }}>
                    <td style={{ width: '338pt', border: '1pt solid black' }} colspan="3">
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingLeft: '19pt', textIndent: '0pt', textAlign: 'left' }}>-
                            Non-GST
                        </p>
                    </td>
                    <td
                        style={{ width: '84pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '3pt', textIndent: '0pt', textAlign: 'right' }}>
                            {(data.nill.total.valc).toFixed(2)}
                        </p>
                    </td>
                    <td style={{ width: '85pt', border: '1pt solid black' }} bgcolor="#949494">
                        <p style={{ textIndent: '0pt', textAlign: 'left' }}><br />
                        </p>
                    </td>
                    <td style={{ width: '85pt', border: '1pt solid black' }} bgcolor="#949494">
                        <p style={{ textIndent: '0pt', textAlign: 'left' }}><br />
                        </p>
                    </td>
                    <td style={{ width: '85pt', border: '1pt solid black' }} bgcolor="#949494">
                        <p style={{ textIndent: '0pt', textAlign: 'left' }}><br />
                        </p>
                    </td>
                    <td style={{ width: '84pt', border: '1pt solid black' }} bgcolor="#949494">
                        <p style={{ textIndent: '0pt', textAlign: 'left' }}><br />
                        </p>
                    </td>
                </tr>
                <tr style={{ height: '13pt' }}>
                    <td style={{ width: '761pt', border: '1pt solid black' }}
                        colspan="8">
                        <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                    </td>
                </tr>
                <tr style={{ height: '13pt' }}>
                    <td style={{ width: '761pt', border: '1pt solid black' }} colspan="8" bgcolor="#FBE4D5">
                        <p className={styles.s6} style={{ paddingTop: '1pt', paddingLeft: '4pt', textIndent: '0pt', textAlign: 'left' }}>9A -
                            Amendment to taxable outward supplies made to registered person in returns of earlier tax
                            periods in
                            table 4 - B2B Regular</p>
                    </td>
                </tr>
                <tr style={{ height: '13pt' }}>
                    <td style={{ width: '217pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingLeft: '4pt', textIndent: '0pt', textAlign: 'left' }}>
                            Amended amount - Total
                        </p>
                    </td>
                    <td style={{ width: '57pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '25pt', textIndent: '0pt', textAlign: 'right' }}>
                            {(data.b2bAA.total.count)}
                        </p>
                    </td>
                    <td style={{ width: '64pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingLeft: '7pt', paddingRight: '6pt', textIndent: '0pt', textAlign: 'center' }}>
                            Invoice
                        </p>
                    </td>
                    <td style={{ width: '84pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '3pt', textIndent: '0pt', textAlign: 'right' }}>
                            {(data.b2bAA.total.value).toFixed(2)}
                        </p>
                    </td>
                    <td style={{ width: '85pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '3pt', textIndent: '0pt', textAlign: 'right' }}>
                            {(data.b2bAA.total.taxa).toFixed(2)}
                        </p>
                    </td>
                    <td style={{ width: '85pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '3pt', textIndent: '0pt', textAlign: 'right' }}>
                            {(data.b2bAA.total.taxb).toFixed(2)}
                        </p>
                    </td>
                    <td style={{ width: '85pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '3pt', textIndent: '0pt', textAlign: 'right' }}>
                            {(data.b2bAA.total.taxb).toFixed(2)}
                        </p>
                    </td>
                    <td style={{ width: '84pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '3pt', textIndent: '0pt', textAlign: 'right' }}>
                            {(data.b2bAA.total.cess).toFixed(2)}
                        </p>
                    </td>
                </tr>
                <tr style={{ height: '13pt' }}>
                    <td style={{ width: '217pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingLeft: '4pt', textIndent: '0pt', textAlign: 'left' }}>
                            Net differential amount (Amended - Original)
                        </p>
                    </td>
                    <td style={{ width: '57pt', border: '1pt solid black' }}>
                        <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                    </td>
                    <td
                        style={{ width: '64pt', border: '1pt solid black' }}>
                        <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                    </td>
                    <td style={{ width: '84pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '3pt', textIndent: '0pt', textAlign: 'right' }}>
                            {(data.b2bAA.total.value - (data.b2bAA.ototal ? data.b2bAA.ototal.value : 0)).toFixed(2)}
                        </p>
                    </td>
                    <td style={{ width: '85pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '3pt', textIndent: '0pt', textAlign: 'right' }}>
                            {(data.b2bAA.total.taxa - (data.b2bAA.ototal ? data.b2bAA.ototal.taxa : 0)).toFixed(2)}
                        </p>
                    </td>
                    <td style={{ width: '85pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '3pt', textIndent: '0pt', textAlign: 'right' }}>
                            {(data.b2bAA.total.taxb - (data.b2bAA.ototal ? data.b2bAA.ototal.taxb : 0)).toFixed(2)}
                        </p>
                    </td>
                    <td style={{ width: '85pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '3pt', textIndent: '0pt', textAlign: 'right' }}>
                            {(data.b2bAA.total.taxb - (data.b2bAA.ototal ? data.b2bAA.ototal.taxb : 0)).toFixed(2)}
                        </p>
                    </td>
                    <td style={{ width: '84pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '3pt', textIndent: '0pt', textAlign: 'right' }}>
                            {(data.b2bAA.total.cess - (data.b2bAA.ototal ? data.b2bAA.ototal.cess : 0)).toFixed(2)}
                        </p>
                    </td>
                </tr>
                <tr style={{ height: '13pt' }}>
                    <td style={{ width: '761pt', border: '1pt solid black' }} colspan="8">
                        <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                    </td>
                </tr>
                <tr style={{ height: '13pt' }}>
                    <td style={{ width: '761pt', border: '1pt solid black' }}
                        colspan="8" bgcolor="#FBE4D5">
                        <p className={styles.s6} style={{ paddingTop: '1pt', paddingLeft: '4pt', textIndent: '0pt', textAlign: 'left' }}>9A -
                            Amendment to taxable outward supplies made to registered person in returns of earlier tax
                            periods in
                            table 4 - B2B Reverse charge</p>
                    </td>
                </tr>
                <tr style={{ height: '13pt' }}>
                    <td style={{ width: '217pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingLeft: '4pt', textIndent: '0pt', textAlign: 'left' }}>
                            Amended amount - Total
                        </p>
                    </td>
                    <td style={{ width: '57pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '25pt', textIndent: '0pt', textAlign: 'right' }}>
                            {(data.b2bAB.total.count)}
                        </p>
                    </td>
                    <td style={{ width: '64pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingLeft: '7pt', paddingRight: '6pt', textIndent: '0pt', textAlign: 'center' }}>
                            Invoice
                        </p>
                    </td>
                    <td style={{ width: '84pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '3pt', textIndent: '0pt', textAlign: 'right' }}>
                            {(data.b2bAB.total.value).toFixed(2)}
                        </p>
                    </td>
                    <td style={{ width: '85pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '3pt', textIndent: '0pt', textAlign: 'right' }}>
                            {(data.b2bAB.total.taxa).toFixed(2)}
                        </p>
                    </td>
                    <td style={{ width: '85pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '3pt', textIndent: '0pt', textAlign: 'right' }}>
                            {(data.b2bAB.total.taxb).toFixed(2)}
                        </p>
                    </td>
                    <td style={{ width: '85pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '3pt', textIndent: '0pt', textAlign: 'right' }}>
                            {(data.b2bAB.total.taxb).toFixed(2)}
                        </p>
                    </td>
                    <td style={{ width: '84pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '3pt', textIndent: '0pt', textAlign: 'right' }}>
                            {(data.b2bAB.total.cess).toFixed(2)}
                        </p>
                    </td>
                </tr>
                <tr style={{ height: '13pt' }}>
                    <td style={{ width: '217pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingLeft: '4pt', textIndent: '0pt', textAlign: 'left' }}>
                            Net differential amount (Amended - Original)
                        </p>
                    </td>
                    <td style={{ width: '57pt', border: '1pt solid black' }}>
                        <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                    </td>
                    <td style={{ width: '64pt', border: '1pt solid black' }}>
                        <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                    </td>
                    <td style={{ width: '84pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '3pt', textIndent: '0pt', textAlign: 'right' }}>
                            {(data.b2bAB.total.value - (data.b2bAB.ototal ? data.b2bAB.ototal.value : 0)).toFixed(2)}
                        </p>
                    </td>
                    <td style={{ width: '85pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '3pt', textIndent: '0pt', textAlign: 'right' }}>
                            {(data.b2bAB.total.taxa - (data.b2bAB.ototal ? data.b2bAB.ototal.taxa : 0)).toFixed(2)}
                        </p>
                    </td>
                    <td style={{ width: '85pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '3pt', textIndent: '0pt', textAlign: 'right' }}>
                            {(data.b2bAB.total.taxb - (data.b2bAB.ototal ? data.b2bAB.ototal.taxb : 0)).toFixed(2)}
                        </p>
                    </td>
                    <td style={{ width: '85pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '3pt', textIndent: '0pt', textAlign: 'right' }}>
                            {(data.b2bAB.total.taxb - (data.b2bAB.ototal ? data.b2bAB.ototal.taxb : 0)).toFixed(2)}
                        </p>
                    </td>
                    <td style={{ width: '84pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '3pt', textIndent: '0pt', textAlign: 'right' }}>
                            {(data.b2bAB.total.cess - (data.b2bAB.ototal ? data.b2bAB.ototal.cess : 0)).toFixed(2)}
                        </p>
                    </td>
                </tr>
                <tr style={{ height: '13pt' }}>
                    <td style={{ width: '761pt', border: '1pt solid black' }} colspan="8">
                        <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                    </td>
                </tr>
                <tr style={{ height: '13pt' }}>
                    <td style={{ width: '761pt', border: '1pt solid black' }} colspan="8" bgcolor="#FBE4D5">
                        <p className={styles.s6} style={{ paddingTop: '1pt', paddingLeft: '4pt', textIndent: '0pt', textAlign: 'left' }}>
                            9A - Amendment to Inter-State supplies made to unregistered person (where invoice value is more than Rs.2.5 lakh) in returns of earlier tax periods in table 5 - B2CL (Large)
                        </p>
                    </td>
                </tr>
                <tr style={{ height: '13pt' }}>
                    <td style={{ width: '217pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingLeft: '4pt', textIndent: '0pt', textAlign: 'left' }}>
                            Amended amount - Total
                        </p>
                    </td>
                    <td style={{ width: '57pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '25pt', textIndent: '0pt', textAlign: 'right' }}>
                            {data.b2cla.total.count}
                        </p>
                    </td>
                    <td style={{ width: '64pt', border: '1pt solid black' }}>
                        <p className={styles.s4}
                            style={{ paddingTop: '1pt', paddingLeft: '7pt', paddingRight: '6pt', textIndent: '0pt', textAlign: 'center' }}>
                            Invoice</p>
                    </td>
                    <td style={{ width: '84pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '3pt', textIndent: '0pt', textAlign: 'right' }}>
                            {(data.b2cla.total.value).toFixed(2)}
                        </p>
                    </td>
                    <td style={{ width: '85pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '3pt', textIndent: '0pt', textAlign: 'right' }}>
                            {(data.b2cla.total.taxa).toFixed(2)}
                        </p>
                    </td>
                    <td style={{ width: '85pt', border: '1pt solid black' }} bgcolor="#949494">
                        <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                    </td>
                    <td style={{ width: '85pt', border: '1pt solid black' }} bgcolor="#949494">
                        <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                    </td>
                    <td style={{ width: '84pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '3pt', textIndent: '0pt', textAlign: 'right' }}>
                            {(data.b2cla.total.cess).toFixed(2)}
                        </p>
                    </td>
                </tr>
                <tr style={{ height: '13pt' }}>
                    <td style={{ width: '217pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingLeft: '4pt', textIndent: '0pt', textAlign: 'left' }}>
                            Net differential amount (Amended - Original)
                        </p>
                    </td>
                    <td style={{ width: '57pt', border: '1pt solid black' }}>
                        <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                    </td>
                    <td style={{ width: '64pt', border: '1pt solid black' }}>
                        <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                    </td>
                    <td style={{ width: '84pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '3pt', textIndent: '0pt', textAlign: 'right' }}>
                            {(data.b2cla.total.value - (data.b2cla.ototal ? data.b2cla.ototal.value : 0)).toFixed(2)}
                        </p>
                    </td>
                    <td style={{ width: '85pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '3pt', textIndent: '0pt', textAlign: 'right' }}>
                            {(data.b2cla.total.taxa - (data.b2cla.ototal ? data.b2cla.ototal.taxa : 0)).toFixed(2)}
                        </p>
                    </td>
                    <td style={{ width: '85pt', border: '1pt solid black' }} bgcolor="#949494">
                        <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                    </td>
                    <td style={{ width: '85pt', border: '1pt solid black' }} bgcolor="#949494">
                        <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                    </td>
                    <td style={{ width: '84pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '3pt', textIndent: '0pt', textAlign: 'right' }}>
                            {(data.b2cla.total.cess - (data.b2cla.ototal ? data.b2cla.ototal.cess : 0)).toFixed(2)}
                        </p>
                    </td>
                </tr>
                <tr style={{ height: '13pt' }}>
                    <td style={{ width: '761pt', border: '1pt solid black' }} colspan="8">
                        <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                    </td>
                </tr>
                <tr style={{ height: '13pt' }}>
                    <td style={{ width: '761pt', border: '1pt solid black' }} colspan="8" bgcolor="#FBE4D5">
                        <p className={styles.s6} style={{ paddingTop: '1pt', paddingLeft: '4pt', textIndent: '0pt', textAlign: 'left' }}>
                            9A - Amendment to Export supplies in returns of earlier tax periods in table 6A (EXPWP/EXPWOP)
                        </p>
                    </td>
                </tr>
                <tr style={{ height: '13pt' }}>
                    <td style={{ width: '217pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingLeft: '4pt', textIndent: '0pt', textAlign: 'left' }}>
                            Amended amount - Total
                        </p>
                    </td>
                    <td style={{ width: '57pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '25pt', textIndent: '0pt', textAlign: 'right' }}>
                            {data.a9.total.count}
                        </p>
                    </td>
                    <td style={{ width: '64pt', border: '1pt solid black' }}>
                        <p className={styles.s4}
                            style={{ paddingTop: '1pt', paddingLeft: '7pt', paddingRight: '6pt', textIndent: '0pt', textAlign: 'center' }}>
                            Invoice</p>
                    </td>
                    <td style={{ width: '84pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '3pt', textIndent: '0pt', textAlign: 'right' }}>
                            {(data.a9.total.value).toFixed(2)}
                        </p>
                    </td>
                    <td style={{ width: '85pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '3pt', textIndent: '0pt', textAlign: 'right' }}>
                            {(data.a9.total.taxa).toFixed(2)}
                        </p>
                    </td>
                    <td style={{ width: '85pt', border: '1pt solid black' }} bgcolor="#949494">
                        <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                    </td>
                    <td style={{ width: '85pt', border: '1pt solid black' }} bgcolor="#949494">
                        <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                    </td>
                    <td style={{ width: '84pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '3pt', textIndent: '0pt', textAlign: 'right' }}>
                            {(data.a9.total.cess).toFixed(2)}
                        </p>
                    </td>
                </tr>
                <tr style={{ height: '13pt' }}>
                    <td style={{ width: '217pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingLeft: '4pt', textIndent: '0pt', textAlign: 'left' }}>
                            Net differential amount (Amended - Original) - Total
                        </p>
                    </td>
                    <td style={{ width: '57pt', border: '1pt solid black' }}>
                        <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                    </td>
                    <td style={{ width: '64pt', border: '1pt solid black' }}>
                        <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                    </td>
                    <td style={{ width: '84pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '3pt', textIndent: '0pt', textAlign: 'right' }}>
                            {(data.a9.total.value - (data.a9.ototal ? data.a9.ototal.value : 0)).toFixed(2)}
                        </p>
                    </td>
                    <td style={{ width: '85pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '3pt', textIndent: '0pt', textAlign: 'right' }}>
                            {(data.a9.total.taxa - (data.a9.ototal ? data.a9.ototal.taxa : 0)).toFixed(2)}
                        </p>
                    </td>
                    <td style={{ width: '85pt', border: '1pt solid black' }} bgcolor="#949494">
                        <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                    </td>
                    <td style={{ width: '85pt', border: '1pt solid black' }} bgcolor="#949494">
                        <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                    </td>
                    <td style={{ width: '84pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '3pt', textIndent: '0pt', textAlign: 'right' }}>
                            {(data.a9.total.cess - (data.a9.ototal ? data.a9.ototal.cess : 0)).toFixed(2)}
                        </p>
                    </td>
                </tr>
                <tr style={{ height: '13pt' }}>
                    <td style={{ width: '217pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingLeft: '19pt', textIndent: '0pt', textAlign: 'left' }}>-
                            EXPWP
                        </p>
                    </td>
                    <td style={{ width: '57pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '25pt', textIndent: '0pt', textAlign: 'right' }}>
                            {data.a9.wp.count}
                        </p>
                    </td>
                    <td style={{ width: '64pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingLeft: '7pt', paddingRight: '6pt', textIndent: '0pt', textAlign: 'center' }}>
                            Invoice
                        </p>
                    </td>
                    <td style={{ width: '84pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '3pt', textIndent: '0pt', textAlign: 'right' }}>
                            {(data.a9.wp.value).toFixed(2)}
                        </p>
                    </td>
                    <td style={{ width: '85pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '3pt', textIndent: '0pt', textAlign: 'right' }}>
                            {(data.a9.wp.taxa).toFixed(2)}
                        </p>
                    </td>
                    <td style={{ width: '85pt', border: '1pt solid black' }} bgcolor="#949494">
                        <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                    </td>
                    <td style={{ width: '85pt', border: '1pt solid black' }} bgcolor="#949494">
                        <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                    </td>
                    <td style={{ width: '84pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '3pt', textIndent: '0pt', textAlign: 'right' }}>
                            {(data.a9.wp.cess).toFixed(2)}
                        </p>
                    </td>
                </tr>
                <tr style={{ height: '13pt' }}>
                    <td style={{ width: '217pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingLeft: '19pt', textIndent: '0pt', textAlign: 'left' }}>-
                            EXPWOP
                        </p>
                    </td>
                    <td style={{ width: '57pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '25pt', textIndent: '0pt', textAlign: 'right' }}>
                            {data.a9.wop.count}
                        </p>
                    </td>
                    <td style={{ width: '64pt', border: '1pt solid black' }}>
                        <p className={styles.s4}
                            style={{ paddingTop: '1pt', paddingLeft: '7pt', paddingRight: '6pt', textIndent: '0pt', textAlign: 'center' }}>
                            Invoice</p>
                    </td>
                    <td style={{ width: '84pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '3pt', textIndent: '0pt', textAlign: 'right' }}>
                            {(data.a9.wop.value).toFixed(2)}
                        </p>
                    </td>
                    <td style={{ width: '85pt', border: '1pt solid black' }} bgcolor="#949494">
                        <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                    </td>
                    <td style={{ width: '85pt', border: '1pt solid black' }} bgcolor="#949494">
                        <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                    </td>
                    <td style={{ width: '85pt', border: '1pt solid black' }} bgcolor="#949494">
                        <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                    </td>
                    <td style={{ width: '84pt', border: '1pt solid black' }} bgcolor="#949494">
                        <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                    </td>
                </tr>
                <tr style={{ height: '13pt' }}>
                    <td style={{ width: '761pt', border: '1pt solid black' }} colspan="8">
                        <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                    </td>
                </tr>
                <tr style={{ height: '13pt' }}>
                    <td style={{ width: '761pt', border: '1pt solid black' }} colspan="8" bgcolor="#FBE4D5">
                        <p className={styles.s6} style={{ paddingTop: '1pt', paddingLeft: '4pt', textIndent: '0pt', textAlign: 'left' }}>
                            9A - Amendment to supplies made to SEZ units or SEZ developers in returns of earlier tax periods in table 6B (SEZWP/SEZWOP)
                        </p>
                    </td>
                </tr>
                <tr style={{ height: '13pt' }}>
                    <td style={{ width: '217pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingLeft: '4pt', textIndent: '0pt', textAlign: 'left' }}>
                            Amended amount - Total
                        </p>
                    </td>
                    <td style={{ width: '57pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '25pt', textIndent: '0pt', textAlign: 'right' }}>
                            0
                        </p>
                    </td>
                    <td style={{ width: '64pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingLeft: '7pt', paddingRight: '6pt', textIndent: '0pt', textAlign: 'center' }}>
                            Invoice
                        </p>
                    </td>
                    <td style={{ width: '84pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '3pt', textIndent: '0pt', textAlign: 'right' }}>
                            0.00
                        </p>
                    </td>
                    <td style={{ width: '85pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '3pt', textIndent: '0pt', textAlign: 'right' }}>
                            0.00
                        </p>
                    </td>
                    <td style={{ width: '85pt', border: '1pt solid black' }} bgcolor="#949494">
                        <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                    </td>
                    <td style={{ width: '85pt', border: '1pt solid black' }} bgcolor="#949494">
                        <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                    </td>
                    <td style={{ width: '84pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '3pt', textIndent: '0pt', textAlign: 'right' }}>
                            0.00
                        </p>
                    </td>
                </tr>
                <tr style={{ height: '13pt' }}>
                    <td style={{ width: '217pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingLeft: '4pt', textIndent: '0pt', textAlign: 'left' }}>
                            Net differential amount (Amended - Original) - Total
                        </p>
                    </td>
                    <td style={{ width: '57pt', border: '1pt solid black' }}>
                        <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                    </td>
                    <td style={{ width: '64pt', border: '1pt solid black' }}>
                        <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                    </td>
                    <td style={{ width: '84pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '3pt', textIndent: '0pt', textAlign: 'right' }}>
                            0.00
                        </p>
                    </td>
                    <td style={{ width: '85pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '3pt', textIndent: '0pt', textAlign: 'right' }}>
                            0.00
                        </p>
                    </td>
                    <td style={{ width: '85pt', border: '1pt solid black' }} bgcolor="#949494">
                        <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                    </td>
                    <td style={{ width: '85pt', border: '1pt solid black' }} bgcolor="#949494">
                        <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                    </td>
                    <td style={{ width: '84pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '3pt', textIndent: '0pt', textAlign: 'right' }}>
                            0.00
                        </p>
                    </td>
                </tr>
                <tr style={{ height: '13pt' }}>
                    <td style={{ width: '217pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingLeft: '19pt', textIndent: '0pt', textAlign: 'left' }}>-
                            SEZWP
                        </p>
                    </td>
                    <td style={{ width: '57pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '25pt', textIndent: '0pt', textAlign: 'right' }}>
                            0
                        </p>
                    </td>
                    <td style={{ width: '64pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingLeft: '7pt', paddingRight: '6pt', textIndent: '0pt', textAlign: 'center' }}>
                            Invoice
                        </p>
                    </td>
                    <td style={{ width: '84pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '3pt', textIndent: '0pt', textAlign: 'right' }}>
                            0.00
                        </p>
                    </td>
                    <td style={{ width: '85pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '3pt', textIndent: '0pt', textAlign: 'right' }}>
                            0.00
                        </p>
                    </td>
                    <td style={{ width: '85pt', border: '1pt solid black' }} bgcolor="#949494">
                        <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                    </td>
                    <td style={{ width: '85pt', border: '1pt solid black' }} bgcolor="#949494">
                        <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                    </td>
                    <td style={{ width: '84pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '3pt', textIndent: '0pt', textAlign: 'right' }}>
                            0.00
                        </p>
                    </td>
                </tr>
                <tr style={{ height: '13pt' }}>
                    <td style={{ width: '217pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingLeft: '19pt', textIndent: '0pt', textAlign: 'left' }}>-
                            SEZWOP
                        </p>
                    </td>
                    <td style={{ width: '57pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '25pt', textIndent: '0pt', textAlign: 'right' }}>
                            0
                        </p>
                    </td>
                    <td style={{ width: '64pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingLeft: '7pt', paddingRight: '6pt', textIndent: '0pt', textAlign: 'center' }}>
                            Invoice
                        </p>
                    </td>
                    <td style={{ width: '84pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '3pt', textIndent: '0pt', textAlign: 'right' }}>
                            0.00
                        </p>
                    </td>
                    <td style={{ width: '85pt', border: '1pt solid black' }} bgcolor="#949494">
                        <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                    </td>
                    <td style={{ width: '85pt', border: '1pt solid black' }} bgcolor="#949494">
                        <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                    </td>
                    <td style={{ width: '85pt', border: '1pt solid black' }} bgcolor="#949494">
                        <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                    </td>
                    <td style={{ width: '84pt', border: '1pt solid black' }} bgcolor="#949494">
                        <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                    </td>
                </tr>
                <tr style={{ height: '13pt' }}>
                    <td style={{ width: '761pt', border: '1pt solid black' }}
                        colspan="8">
                        <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                    </td>
                </tr>
                <tr style={{ height: '13pt' }}>
                    <td style={{ width: '761pt', border: '1pt solid black' }} colspan="8" bgcolor="#FBE4D5">
                        <p className={styles.s6} style={{ paddingTop: '1pt', paddingLeft: '4pt', textIndent: '0pt', textAlign: 'left' }}>
                            9A - Amendment to Deemed Exports in returns of earlier tax periods in table 6C (DE)
                        </p>
                    </td>
                </tr>
                <tr style={{ height: '13pt' }}>
                    <td style={{ width: '217pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingLeft: '4pt', textIndent: '0pt', textAlign: 'left' }}>
                            Amended amount - Total
                        </p>
                    </td>
                    <td style={{ width: '57pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '25pt', textIndent: '0pt', textAlign: 'right' }}>
                            0
                        </p>
                    </td>
                    <td style={{ width: '64pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingLeft: '7pt', paddingRight: '6pt', textIndent: '0pt', textAlign: 'center' }}>
                            Invoice
                        </p>
                    </td>
                    <td style={{ width: '84pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '3pt', textIndent: '0pt', textAlign: 'right' }}>
                            0.00
                        </p>
                    </td>
                    <td style={{ width: '85pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '3pt', textIndent: '0pt', textAlign: 'right' }}>
                            0.00
                        </p>
                    </td>
                    <td style={{ width: '85pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '3pt', textIndent: '0pt', textAlign: 'right' }}>
                            0.00
                        </p>
                    </td>
                    <td style={{ width: '85pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '3pt', textIndent: '0pt', textAlign: 'right' }}>
                            0.00
                        </p>
                    </td>
                    <td style={{ width: '84pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '3pt', textIndent: '0pt', textAlign: 'right' }}>
                            0.00
                        </p>
                    </td>
                </tr>
                <tr style={{ height: '13pt' }}>
                    <td style={{ width: '217pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingLeft: '4pt', textIndent: '0pt', textAlign: 'left' }}>
                            Net differential amount (Amended - Original)
                        </p>
                    </td>
                    <td style={{ width: '57pt', border: '1pt solid black' }}>
                        <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                    </td>
                    <td style={{ width: '64pt', border: '1pt solid black' }}>
                        <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                    </td>
                    <td style={{ width: '84pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '3pt', textIndent: '0pt', textAlign: 'right' }}>
                            0.00
                        </p>
                    </td>
                    <td style={{ width: '85pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '3pt', textIndent: '0pt', textAlign: 'right' }}>
                            0.00
                        </p>
                    </td>
                    <td style={{ width: '85pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '3pt', textIndent: '0pt', textAlign: 'right' }}>
                            0.00
                        </p>
                    </td>
                    <td style={{ width: '85pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '3pt', textIndent: '0pt', textAlign: 'right' }}>
                            0.00
                        </p>
                    </td>
                    <td style={{ width: '84pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '3pt', textIndent: '0pt', textAlign: 'right' }}>
                            0.00
                        </p>
                    </td>
                </tr>
                <tr style={{ height: '13pt' }}>
                    <td style={{ width: '761pt', border: '1pt solid black' }} colspan="8">
                        <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                    </td>
                </tr>
                <tr style={{ height: '13pt' }}>
                    <td style={{ width: '761pt', border: '1pt solid black' }} colspan="8" bgcolor="#FBE4D5">
                        <p className={styles.s6} style={{ paddingTop: '1pt', paddingLeft: '4pt', textIndent: '0pt', textAlign: 'left' }}>
                            9B - Credit/Debit Notes (Registered) – CDNR
                        </p>
                    </td>
                </tr>
            </table>
            <div className='fixed w-[1080px] h-[764px] flex flex-col items-center justify-center opacity-20 pointer-events-none' style={{ zIndex: 9999 }}>
                <img src={Logo} alt="ITAA" className='w-[800] mx-auto h-[227px] pointer-events-none' style={{ transform: "rotate(-45deg)" }} />
            </div>
            <table style={{ borderCollapse: "collapse", margin: "auto", marginTop: "25pt" }} cellspacing="0">
                <tr style={{ height: '25pt' }}>
                    <td style={{ width: '217pt', border: '1pt solid black' }} bgcolor="#002060">
                        <p className={styles.s5} style={{ paddingTop: '6pt', paddingLeft: '85pt', paddingRight: '84pt', textIndent: '0pt', textAlign: 'center' }}>Description</p>
                    </td>
                    <td style={{ width: '57pt', border: '1pt solid black' }} bgcolor="#002060">
                        <p className={styles.s5} style={{ paddingTop: '1pt', paddingLeft: '13pt', textIndent: '2pt', textAlign: 'left' }}>No. of records</p>
                    </td>
                    <td style={{ width: '64pt', border: '1pt solid black' }} bgcolor="#002060">
                        <p className={styles.s5} style={{ paddingTop: '1pt', paddingLeft: '22pt', textIndent: '-11pt', textAlign: 'left' }}>Document Type</p>
                    </td>
                    <td style={{ width: '84pt', border: '1pt solid black' }} bgcolor="#002060">
                        <p className={styles.s5} style={{ paddingTop: '6pt', paddingLeft: '24pt', textIndent: '0pt', textAlign: 'left' }}>Value (₹)</p>
                    </td>
                    <td style={{ width: '85pt', border: '1pt solid black' }} bgcolor="#002060">
                        <p className={styles.s5} style={{ paddingTop: '6pt', paddingRight: '6pt', textIndent: '0pt', textAlign: 'right' }}>Integrated Tax (₹)</p>
                    </td>
                    <td style={{ width: '85pt', border: '1pt solid black' }} bgcolor="#002060">
                        <p className={styles.s5} style={{ paddingTop: '6pt', paddingLeft: '13pt', textIndent: '0pt', textAlign: 'left' }}>Central Tax (₹)</p>
                    </td>
                    <td style={{ width: '85pt', border: '1pt solid black' }} bgcolor="#002060">
                        <p className={styles.s5} style={{ paddingTop: '6pt', paddingLeft: '9pt', textIndent: '0pt', textAlign: 'left' }}>State/UT Tax (₹)</p>
                    </td>
                    <td style={{ width: '84pt', border: '1pt solid black' }} bgcolor="#002060">
                        <p className={styles.s5} style={{ paddingTop: '6pt', paddingLeft: '25pt', textIndent: '0pt', textAlign: 'left' }}>Cess (₹)</p>
                    </td>
                </tr>
                <tr style={{ height: '13pt' }}>
                    <td style={{ width: '217pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingLeft: '4pt', textIndent: '0pt', textAlign: 'left' }}>
                            Total - Net off debit/credit notes (Debit notes - Credit notes)
                        </p>
                    </td>
                    <td style={{ width: '57pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '25pt', textIndent: '0pt', textAlign: 'right' }}>
                            {data.cdrA.total.count + data.cdrB.total.count}
                        </p>
                    </td>
                    <td style={{ width: '64pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingLeft: '7pt', paddingRight: '6pt', textIndent: '0pt', textAlign: 'center' }}>
                            Note
                        </p>
                    </td>
                    <td style={{ width: '84pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '3pt', textIndent: '0pt', textAlign: 'right' }}>
                            {(data.cdrA.total.value + data.cdrB.total.value).toFixed(2)}
                        </p>
                    </td>
                    <td style={{ width: '85pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '3pt', textIndent: '0pt', textAlign: 'right' }}>
                            {(data.cdrA.total.taxa + data.cdrB.total.taxa).toFixed(2)}
                        </p>
                    </td>
                    <td style={{ width: '85pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '3pt', textIndent: '0pt', textAlign: 'right' }}>
                            {(data.cdrA.total.taxb + data.cdrB.total.taxb).toFixed(2)}
                        </p>
                    </td>
                    <td style={{ width: '85pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '3pt', textIndent: '0pt', textAlign: 'right' }}>
                            {(data.cdrA.total.taxb + data.cdrB.total.taxb).toFixed(2)}
                        </p>
                    </td>
                    <td style={{ width: '84pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '3pt', textIndent: '0pt', textAlign: 'right' }}>
                            {(data.cdrA.total.cess + data.cdrB.total.cess).toFixed(2)}
                        </p>
                    </td>
                </tr>
                <tr style={{ height: '13pt' }}>
                    <td style={{ width: '761pt', border: '1pt solid black' }} colspan="8">
                        <p className={styles.s5b} style={{ paddingTop: '1pt', paddingLeft: '19pt', textIndent: '0pt', textAlign: 'left' }}>
                            Credit / Debit notes issued to registered person for taxable outward supplies in table 4 other than table 6 - B2B Regular
                        </p>
                    </td>
                </tr>
                <tr style={{ height: '13pt' }}>
                    <td style={{ width: '217pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingLeft: '34pt', textIndent: '0pt', textAlign: 'left' }}>
                            Net Total (Debit notes – Credit notes)
                        </p>
                    </td>
                    <td style={{ width: '57pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '25pt', textIndent: '0pt', textAlign: 'right' }}>
                            {data.cdrA.total.count}
                        </p>
                    </td>
                    <td style={{ width: '64pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingLeft: '7pt', paddingRight: '6pt', textIndent: '0pt', textAlign: 'center' }}>
                            Note
                        </p>
                    </td>
                    <td style={{ width: '84pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '3pt', textIndent: '0pt', textAlign: 'right' }}>
                            {(data.cdrA.total.value).toFixed(2)}
                        </p>
                    </td>
                    <td style={{ width: '85pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '3pt', textIndent: '0pt', textAlign: 'right' }}>
                            {(data.cdrA.total.taxa).toFixed(2)}
                        </p>
                    </td>
                    <td style={{ width: '85pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '3pt', textIndent: '0pt', textAlign: 'right' }}>
                            {(data.cdrA.total.taxb).toFixed(2)}
                        </p>
                    </td>
                    <td style={{ width: '85pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '3pt', textIndent: '0pt', textAlign: 'right' }}>
                            {(data.cdrA.total.taxb).toFixed(2)}
                        </p>
                    </td>
                    <td style={{ width: '84pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '3pt', textIndent: '0pt', textAlign: 'right' }}>
                            {(data.cdrA.total.cess).toFixed(2)}
                        </p>
                    </td>
                </tr>
                <tr style={{ height: '13pt' }}>
                    <td style={{ width: '761pt', border: '1pt solid black' }} colspan="8">
                        <p className={styles.s5b} style={{ paddingTop: '1pt', paddingLeft: '19pt', textIndent: '0pt', textAlign: 'left' }}>
                            Credit / Debit notes issued to registered person for taxable outward supplies in table 4 other than table 6 - B2B Reverse charge
                        </p>
                    </td>
                </tr>
                <tr style={{ height: '13pt' }}>
                    <td style={{ width: '217pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingLeft: '34pt', textIndent: '0pt', textAlign: 'left' }}>
                            Net Total (Debit notes – Credit notes)
                        </p>
                    </td>
                    <td style={{ width: '57pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '25pt', textIndent: '0pt', textAlign: 'right' }}>
                            {data.cdrB.total.count}
                        </p>
                    </td>
                    <td style={{ width: '64pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingLeft: '7pt', paddingRight: '6pt', textIndent: '0pt', textAlign: 'center' }}>
                            Note
                        </p>
                    </td>
                    <td style={{ width: '84pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '3pt', textIndent: '0pt', textAlign: 'right' }}>
                            {(data.cdrB.total.value).toFixed(2)}
                        </p>
                    </td>
                    <td style={{ width: '85pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '3pt', textIndent: '0pt', textAlign: 'right' }}>
                            {(data.cdrB.total.taxa).toFixed(2)}
                        </p>
                    </td>
                    <td style={{ width: '85pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '3pt', textIndent: '0pt', textAlign: 'right' }}>
                            {(data.cdrB.total.taxb).toFixed(2)}
                        </p>
                    </td>
                    <td style={{ width: '85pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '3pt', textIndent: '0pt', textAlign: 'right' }}>
                            {(data.cdrB.total.taxb).toFixed(2)}
                        </p>
                    </td>
                    <td style={{ width: '84pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '3pt', textIndent: '0pt', textAlign: 'right' }}>
                            {(data.cdrB.total.cess).toFixed(2)}
                        </p>
                    </td>
                </tr>
                <tr style={{ height: '13pt' }}>
                    <td style={{ width: '761pt', border: '1pt solid black' }} colspan="8">
                        <p className={styles.s5b} style={{ paddingTop: '1pt', paddingLeft: '19pt', textIndent: '0pt', textAlign: 'left' }}>
                            Credit / Debit notes issued to registered person for taxable outward supplies in table 6B - SEZWP/SEZWOP
                        </p>
                    </td>
                </tr>
                <tr style={{ height: '13pt' }}>
                    <td style={{ width: '217pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingLeft: '34pt', textIndent: '0pt', textAlign: 'left' }}>
                            Net Total (Debit notes – Credit notes)
                        </p>
                    </td>
                    <td style={{ width: '57pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '25pt', textIndent: '0pt', textAlign: 'right' }}>
                            0
                        </p>
                    </td>
                    <td style={{ width: '64pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingLeft: '7pt', paddingRight: '6pt', textIndent: '0pt', textAlign: 'center' }}>
                            Note
                        </p>
                    </td>
                    <td style={{ width: '84pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '3pt', textIndent: '0pt', textAlign: 'right' }}>
                            0.00
                        </p>
                    </td>
                    <td style={{ width: '85pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '3pt', textIndent: '0pt', textAlign: 'right' }}>
                            0.00
                        </p>
                    </td>
                    <td style={{ width: '85pt', border: '1pt solid black' }} bgcolor="#949494">
                        <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                    </td>
                    <td style={{ width: '85pt', border: '1pt solid black' }} bgcolor="#949494">
                        <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                    </td>
                    <td style={{ width: '84pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '3pt', textIndent: '0pt', textAlign: 'right' }}>
                            0.00
                        </p>
                    </td>
                </tr>
                <tr style={{ height: '13pt' }}>
                    <td style={{ width: '761pt', border: '1pt solid black' }} colspan="8">
                        <p className={styles.s5b} style={{ paddingTop: '1pt', paddingLeft: '19pt', textIndent: '0pt', textAlign: 'left' }}>
                            Credit / Debit notes issued to registered person for taxable outward supplies in table 6C – DE
                        </p>
                    </td>
                </tr>
                <tr style={{ height: '13pt' }}>
                    <td style={{ width: '217pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingLeft: '34pt', textIndent: '0pt', textAlign: 'left' }}>
                            Net Total (Debit notes – Credit notes)
                        </p>
                    </td>
                    <td style={{ width: '57pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '25pt', textIndent: '0pt', textAlign: 'right' }}>
                            0
                        </p>
                    </td>
                    <td style={{ width: '64pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingLeft: '7pt', paddingRight: '6pt', textIndent: '0pt', textAlign: 'center' }}>
                            Note
                        </p>
                    </td>
                    <td style={{ width: '84pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '3pt', textIndent: '0pt', textAlign: 'right' }}>
                            0.00
                        </p>
                    </td>
                    <td style={{ width: '85pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '3pt', textIndent: '0pt', textAlign: 'right' }}>
                            0.00
                        </p>
                    </td>
                    <td style={{ width: '85pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '3pt', textIndent: '0pt', textAlign: 'right' }}>
                            0.00
                        </p>
                    </td>
                    <td style={{ width: '85pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '3pt', textIndent: '0pt', textAlign: 'right' }}>
                            0.00
                        </p>
                    </td>
                    <td style={{ width: '84pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '3pt', textIndent: '0pt', textAlign: 'right' }}>
                            0.00
                        </p>
                    </td>
                </tr>
                <tr style={{ height: '13pt' }}>
                    <td style={{ width: '761pt', border: '1pt solid black' }} colspan="8">
                        <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                    </td>
                </tr>
                <tr style={{ height: '13pt' }}>
                    <td style={{ width: '761pt', border: '1pt solid black' }} colspan="8" bgcolor="#FBE4D5">
                        <p className={styles.s5b} style={{ paddingTop: '1pt', paddingLeft: '4pt', textIndent: '0pt', textAlign: 'left' }}>
                            9B - Credit/Debit Notes (Unregistered) – CDNUR
                        </p>
                    </td>
                </tr>
                <tr style={{ height: '13pt' }}>
                    <td style={{ width: '217pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingLeft: '4pt', textIndent: '0pt', textAlign: 'left' }}>
                            Total - Net off debit/credit notes (Debit notes - Credit notes)
                        </p>
                    </td>
                    <td style={{ width: '57pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '25pt', textIndent: '0pt', textAlign: 'right' }}>
                            {data.cdu.total.count}
                        </p>
                    </td>
                    <td style={{ width: '64pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingLeft: '7pt', paddingRight: '6pt', textIndent: '0pt', textAlign: 'center' }}>
                            Note
                        </p>
                    </td>
                    <td style={{ width: '84pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '3pt', textIndent: '0pt', textAlign: 'right' }}>
                            {(data.cdu.total.value).toFixed(2)}
                        </p>
                    </td>
                    <td style={{ width: '85pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '3pt', textIndent: '0pt', textAlign: 'right' }}>
                            {(data.cdu.total.taxa).toFixed(2)}
                        </p>
                    </td>
                    <td style={{ width: '85pt', border: '1pt solid black' }} bgcolor="#949494">
                        <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                    </td>
                    <td style={{ width: '85pt', border: '1pt solid black' }} bgcolor="#949494">
                        <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                    </td>
                    <td style={{ width: '84pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '3pt', textIndent: '0pt', textAlign: 'right' }}>
                            {(data.cdu.total.cess).toFixed(2)}
                        </p>
                    </td>
                </tr>
                <tr style={{ height: '13pt' }}>
                    <td style={{ width: '761pt', border: '1pt solid black' }} colspan="8">
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingLeft: '19pt', textIndent: '0pt', textAlign: 'left' }}>
                            Unregistered Type
                        </p>
                    </td>
                </tr>
                <tr style={{ height: '13pt' }}>
                    <td style={{ width: '217pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingLeft: '34pt', textIndent: '0pt', textAlign: 'left' }}>-
                            B2CL
                        </p>
                    </td>
                    <td style={{ width: '57pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '25pt', textIndent: '0pt', textAlign: 'right' }}>
                            {data.cdu.cl.count}
                        </p>
                    </td>
                    <td style={{ width: '64pt', border: '1pt solid black' }}>
                        <p className={styles.s4}
                            style={{ paddingTop: '1pt', paddingLeft: '7pt', paddingRight: '6pt', textIndent: '0pt', textAlign: 'center' }}>
                            Note</p>
                    </td>
                    <td style={{ width: '84pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '3pt', textIndent: '0pt', textAlign: 'right' }}>
                            {(data.cdu.cl.value).toFixed(2)}
                        </p>
                    </td>
                    <td style={{ width: '85pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '3pt', textIndent: '0pt', textAlign: 'right' }}>
                            {(data.cdu.cl.taxa).toFixed(2)}
                        </p>
                    </td>
                    <td style={{ width: '85pt', border: '1pt solid black' }} bgcolor="#949494">
                        <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                    </td>
                    <td style={{ width: '85pt', border: '1pt solid black' }} bgcolor="#949494">
                        <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                    </td>
                    <td style={{ width: '84pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '3pt', textIndent: '0pt', textAlign: 'right' }}>
                            {(data.cdu.cl.cess).toFixed(2)}
                        </p>
                    </td>
                </tr>
                <tr style={{ height: '13pt' }}>
                    <td style={{ width: '217pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingLeft: '34pt', textIndent: '0pt', textAlign: 'left' }}>-
                            EXPWP
                        </p>
                    </td>
                    <td style={{ width: '57pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '25pt', textIndent: '0pt', textAlign: 'right' }}>
                            {data.cdu.wp.count}
                        </p>
                    </td>
                    <td style={{ width: '64pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingLeft: '7pt', paddingRight: '6pt', textIndent: '0pt', textAlign: 'center' }}>
                            Note
                        </p>
                    </td>
                    <td style={{ width: '84pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '3pt', textIndent: '0pt', textAlign: 'right' }}>
                            {(data.cdu.wp.value).toFixed(2)}
                        </p>
                    </td>
                    <td style={{ width: '85pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '3pt', textIndent: '0pt', textAlign: 'right' }}>
                            {(data.cdu.wp.taxa).toFixed(2)}
                        </p>
                    </td>
                    <td style={{ width: '85pt', border: '1pt solid black' }} bgcolor="#949494">
                        <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                    </td>
                    <td style={{ width: '85pt', border: '1pt solid black' }} bgcolor="#949494">
                        <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                    </td>
                    <td style={{ width: '84pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '3pt', textIndent: '0pt', textAlign: 'right' }}>
                            {(data.cdu.wp.cess).toFixed(2)}
                        </p>
                    </td>
                </tr>
                <tr style={{ height: '13pt' }}>
                    <td style={{ width: '217pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingLeft: '34pt', textIndent: '0pt', textAlign: 'left' }}>
                            - EXPWOP
                        </p>
                    </td>
                    <td style={{ width: '57pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '25pt', textIndent: '0pt', textAlign: 'right' }}>
                            {data.cdu.wop.count}
                        </p>
                    </td>
                    <td style={{ width: '64pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingLeft: '7pt', paddingRight: '6pt', textIndent: '0pt', textAlign: 'center' }}>
                            Note
                        </p>
                    </td>
                    <td style={{ width: '84pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '3pt', textIndent: '0pt', textAlign: 'right' }}>
                            {(data.cdu.wop.value).toFixed(2)}
                        </p>
                    </td>
                    <td style={{ width: '85pt', border: '1pt solid black' }} bgcolor="#949494">
                        <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                    </td>
                    <td style={{ width: '85pt', border: '1pt solid black' }} bgcolor="#949494">
                        <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                    </td>
                    <td style={{ width: '85pt', border: '1pt solid black' }} bgcolor="#949494">
                        <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                    </td>
                    <td style={{ width: '84pt', border: '1pt solid black' }} bgcolor="#949494">
                        <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                    </td>
                </tr>
                <tr style={{ height: '13pt' }}>
                    <td style={{ width: '761pt', border: '1pt solid black' }} colspan="8">
                        <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                    </td>
                </tr>
                <tr style={{ height: '13pt' }}>
                    <td style={{ width: '761pt', border: '1pt solid black' }} colspan="8" bgcolor="#FBE4D5">
                        <p className={styles.s5b} style={{ paddingTop: '1pt', paddingLeft: '4pt', textIndent: '0pt', textAlign: 'left' }}>
                            9C - Amended Credit/Debit Notes (Registered) - CDNRA
                        </p>
                    </td>
                </tr>
                <tr style={{ height: '13pt' }}>
                    <td style={{ width: '217pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingLeft: '4pt', textIndent: '0pt', textAlign: 'left' }}>
                            Amended amount - Total
                        </p>
                    </td>
                    <td style={{ width: '57pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '25pt', textIndent: '0pt', textAlign: 'right' }}>
                            {data.cdrAA.total.count + data.cdrAB.total.count}
                        </p>
                    </td>
                    <td style={{ width: '64pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingLeft: '7pt', paddingRight: '6pt', textIndent: '0pt', textAlign: 'center' }}>
                            Note
                        </p>
                    </td>
                    <td style={{ width: '84pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '3pt', textIndent: '0pt', textAlign: 'right' }}>
                            {(data.cdrAA.total.value + data.cdrAB.total.value).toFixed(2)}
                        </p>
                    </td>
                    <td style={{ width: '85pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '3pt', textIndent: '0pt', textAlign: 'right' }}>
                            {(data.cdrAA.total.taxa + data.cdrAB.total.taxa).toFixed(2)}
                        </p>
                    </td>
                    <td style={{ width: '85pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '3pt', textIndent: '0pt', textAlign: 'right' }}>
                            {(data.cdrAA.total.taxb + data.cdrAB.total.taxb).toFixed(2)}
                        </p>
                    </td>
                    <td style={{ width: '85pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '3pt', textIndent: '0pt', textAlign: 'right' }}>
                            {(data.cdrAA.total.taxb + data.cdrAB.total.taxb).toFixed(2)}
                        </p>
                    </td>
                    <td style={{ width: '84pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '3pt', textIndent: '0pt', textAlign: 'right' }}>
                            {(data.cdrAA.total.cess + data.cdrAB.total.cess).toFixed(2)}
                        </p>
                    </td>
                </tr>
                <tr style={{ height: '22pt' }}>
                    <td style={{ width: '217pt', border: '1pt solid black' }}>
                        <p className={styles.s4b} style={{ paddingTop: '1pt', paddingLeft: '4pt', textIndent: '0pt', textAlign: 'left' }}>
                            Net Differential amount (Net Amended Debit notes - Net Amended Credit notes) - Total
                        </p>
                    </td>
                    <td style={{ width: '57pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '25pt', textIndent: '0pt', textAlign: 'right' }}>
                            {data.cdrAA.total.count + data.cdrAB.total.count}
                        </p>
                    </td>
                    <td style={{ width: '64pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingLeft: '7pt', paddingRight: '6pt', textIndent: '0pt', textAlign: 'center' }}>
                            Note
                        </p>
                    </td>
                    <td style={{ width: '84pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '3pt', textIndent: '0pt', textAlign: 'right' }}>
                            {data.cdrAA.ntotal ? (data.cdrAA.ntotal.value + data.cdrAB.ntotal.value).toFixed(2) : (data.cdrAA.total.value + data.cdrAB.total.value).toFixed(2)}
                        </p>
                    </td>
                    <td style={{ width: '85pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '3pt', textIndent: '0pt', textAlign: 'right' }}>
                            {data.cdrAA.ntotal ? (data.cdrAA.ntotal.taxa + data.cdrAB.ntotal.taxa).toFixed(2) : (data.cdrAA.total.taxa + data.cdrAB.total.taxa).toFixed(2)}
                        </p>
                    </td>
                    <td style={{ width: '85pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '3pt', textIndent: '0pt', textAlign: 'right' }}>
                            {data.cdrAA.ntotal ? (data.cdrAA.ntotal.taxb + data.cdrAB.ntotal.taxb).toFixed(2) : (data.cdrAA.total.taxb + data.cdrAB.total.taxb).toFixed(2)}
                        </p>
                    </td>
                    <td style={{ width: '85pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '3pt', textIndent: '0pt', textAlign: 'right' }}>
                            {data.cdrAA.ntotal ? (data.cdrAA.ntotal.taxb + data.cdrAB.ntotal.taxb).toFixed(2) : (data.cdrAA.total.taxb + data.cdrAB.total.taxb).toFixed(2)}
                        </p>
                    </td>
                    <td style={{ width: '84pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '3pt', textIndent: '0pt', textAlign: 'right' }}>
                            {data.cdrAA.ntotal ? (data.cdrAA.ntotal.cess + data.cdrAB.ntotal.cess).toFixed(2) : (data.cdrAA.total.cess + data.cdrAB.total.cess).toFixed(2)}
                        </p>
                    </td>
                </tr>
                <tr style={{ height: '13pt' }}>
                    <td style={{ width: '761pt', border: '1pt solid black' }} colspan="8">
                        <p className={styles.s5b} style={{ paddingTop: '1pt', paddingLeft: '19pt', textIndent: '0pt', textAlign: 'left' }}>
                            Amended Credit / Debit notes issued to registered person for taxable outward supplies in table 4 other than table 6 - B2B Regular
                        </p>
                    </td>
                </tr>
                <tr style={{ height: '22pt' }}>
                    <td style={{ width: '217pt', border: '1pt solid black' }}>
                        <p className={styles.s4b} style={{ paddingTop: '1pt', paddingLeft: '34pt', textIndent: '0pt', textAlign: 'left' }}>
                            Net total (Net Amended Debit notes - Net Amended Credit notes)
                        </p>
                    </td>
                    <td style={{ width: '57pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '25pt', textIndent: '0pt', textAlign: 'right' }}>
                            {data.cdrAA.total.count}
                        </p>
                    </td>
                    <td style={{ width: '64pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingLeft: '7pt', paddingRight: '6pt', textIndent: '0pt', textAlign: 'center' }}>
                            Note
                        </p>
                    </td>
                    <td style={{ width: '84pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '3pt', textIndent: '0pt', textAlign: 'right' }}>
                            {(data.cdrAA.ntotal ? data.cdrAA.ntotal.value : 0).toFixed(2)}
                        </p>
                    </td>
                    <td style={{ width: '85pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '3pt', textIndent: '0pt', textAlign: 'right' }}>
                            {(data.cdrAA.ntotal ? data.cdrAA.ntotal.taxa : 0).toFixed(2)}
                        </p>
                    </td>
                    <td style={{ width: '85pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '3pt', textIndent: '0pt', textAlign: 'right' }}>
                            {(data.cdrAA.ntotal ? data.cdrAA.ntotal.taxb : 0).toFixed(2)}
                        </p>
                    </td>
                    <td style={{ width: '85pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '3pt', textIndent: '0pt', textAlign: 'right' }}>
                            {(data.cdrAA.ntotal ? data.cdrAA.ntotal.taxb : 0).toFixed(2)}
                        </p>
                    </td>
                    <td style={{ width: '84pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '3pt', textIndent: '0pt', textAlign: 'right' }}>
                            {(data.cdrAA.ntotal ? data.cdrAA.ntotal.cess : 0).toFixed(2)}
                        </p>
                    </td>
                </tr>
                <tr style={{ height: '13pt' }}>
                    <td style={{ width: '761pt', border: '1pt solid black' }} colspan="8">
                        <p className={styles.s5b} style={{ paddingTop: '1pt', paddingLeft: '19pt', textIndent: '0pt', textAlign: 'left' }}>
                            Amended Credit / Debit notes issued to registered person for taxable outward supplies in table 4 other than table 6 - B2B Reverse charge
                        </p>
                    </td>
                </tr>
                <tr style={{ height: '22pt' }}>
                    <td style={{ width: '217pt', border: '1pt solid black' }}>
                        <p className={styles.s4b} style={{ paddingTop: '1pt', paddingLeft: '34pt', textIndent: '0pt', textAlign: 'left' }}>
                            Net total (Net Amended Debit notes - Net Amended Credit notes)
                        </p>
                    </td>
                    <td style={{ width: '57pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '25pt', textIndent: '0pt', textAlign: 'right' }}>
                            {data.cdrAB.total.count}
                        </p>
                    </td>
                    <td style={{ width: '64pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingLeft: '7pt', paddingRight: '6pt', textIndent: '0pt', textAlign: 'center' }}>
                            Note
                        </p>
                    </td>
                    <td style={{ width: '84pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '3pt', textIndent: '0pt', textAlign: 'right' }}>
                            {(data.cdrAB.ntotal ? data.cdrAB.ntotal.value : 0).toFixed(2)}
                        </p>
                    </td>
                    <td style={{ width: '85pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '3pt', textIndent: '0pt', textAlign: 'right' }}>
                            {(data.cdrAB.ntotal ? data.cdrAB.ntotal.taxa : 0).toFixed(2)}
                        </p>
                    </td>
                    <td style={{ width: '85pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '3pt', textIndent: '0pt', textAlign: 'right' }}>
                            {(data.cdrAB.ntotal ? data.cdrAB.ntotal.taxb : 0).toFixed(2)}
                        </p>
                    </td>
                    <td style={{ width: '85pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '3pt', textIndent: '0pt', textAlign: 'right' }}>
                            {(data.cdrAB.ntotal ? data.cdrAB.ntotal.taxb : 0).toFixed(2)}
                        </p>
                    </td>
                    <td style={{ width: '84pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '3pt', textIndent: '0pt', textAlign: 'right' }}>
                            {(data.cdrAB.ntotal ? data.cdrAB.ntotal.cess : 0).toFixed(2)}
                        </p>
                    </td>
                </tr>
                <tr style={{ height: '13pt' }}>
                    <td style={{ width: '761pt', border: '1pt solid black' }} colspan="8">
                        <p className={styles.s5b} style={{ paddingTop: '1pt', paddingLeft: '19pt', textIndent: '0pt', textAlign: 'left' }}>
                            Amended Credit / Debit notes issued to registered person for taxable outward supplies in table 6B - SEZWP/SEZWOP
                        </p>
                    </td>
                </tr>
                <tr style={{ height: '22pt' }}>
                    <td style={{ width: '217pt', border: '1pt solid black' }}>
                        <p className={styles.s4b} style={{ paddingTop: '1pt', paddingLeft: '34pt', textIndent: '0pt', textAlign: 'left' }}>
                            Net total (Net Amended Debit notes - Net Amended Credit notes)
                        </p>
                    </td>
                    <td style={{ width: '57pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '25pt', textIndent: '0pt', textAlign: 'right' }}>
                            0
                        </p>
                    </td>
                    <td style={{ width: '64pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingLeft: '7pt', paddingRight: '6pt', textIndent: '0pt', textAlign: 'center' }}>
                            Note
                        </p>
                    </td>
                    <td style={{ width: '84pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '3pt', textIndent: '0pt', textAlign: 'right' }}>
                            0.00
                        </p>
                    </td>
                    <td style={{ width: '85pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '3pt', textIndent: '0pt', textAlign: 'right' }}>
                            0.00
                        </p>
                    </td>
                    <td style={{ width: '85pt', border: '1pt solid black' }} bgcolor="#949494">
                        <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                    </td>
                    <td style={{ width: '85pt', border: '1pt solid black' }} bgcolor="#949494">
                        <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                    </td>
                    <td style={{ width: '84pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '3pt', textIndent: '0pt', textAlign: 'right' }}>
                            0.00
                        </p>
                    </td>
                </tr>
                <tr style={{ height: '13pt' }}>
                    <td style={{ width: '761pt', border: '1pt solid black' }} colspan="8">
                        <p className={styles.s5b} style={{ paddingTop: '1pt', paddingLeft: '19pt', textIndent: '0pt', textAlign: 'left' }}>
                            Amended Credit / Debit notes issued to registered person for taxable outward supplies in table 6C – DE
                        </p>
                    </td>
                </tr>
                <tr style={{ height: '22pt' }}>
                    <td style={{ width: '217pt', border: '1pt solid black' }}>
                        <p className={styles.s4b} style={{ paddingTop: '1pt', paddingLeft: '34pt', textIndent: '0pt', textAlign: 'left' }}>
                            Net total (Net Amended Debit notes - Net Amended Credit notes)
                        </p>
                    </td>
                    <td style={{ width: '57pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '25pt', textIndent: '0pt', textAlign: 'right' }}>
                            0
                        </p>
                    </td>
                    <td style={{ width: '64pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingLeft: '7pt', paddingRight: '6pt', textIndent: '0pt', textAlign: 'center' }}>
                            Note
                        </p>
                    </td>
                    <td style={{ width: '84pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '3pt', textIndent: '0pt', textAlign: 'right' }}>
                            0.00
                        </p>
                    </td>
                    <td style={{ width: '85pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '3pt', textIndent: '0pt', textAlign: 'right' }}>
                            0.00
                        </p>
                    </td>
                    <td style={{ width: '85pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '3pt', textIndent: '0pt', textAlign: 'right' }}>
                            0.00
                        </p>
                    </td>
                    <td style={{ width: '85pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '3pt', textIndent: '0pt', textAlign: 'right' }}>
                            0.00
                        </p>
                    </td>
                    <td style={{ width: '84pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '3pt', textIndent: '0pt', textAlign: 'right' }}>
                            0.00
                        </p>
                    </td>
                </tr>
                <tr style={{ height: '13pt' }}>
                    <td style={{ width: '761pt', border: '1pt solid black' }} colspan="8">
                        <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                    </td>
                </tr>
                <tr style={{ height: '13pt' }}>
                    <td style={{ width: '761pt', border: '1pt solid black' }} colspan="8" bgcolor="#FBE4D5">
                        <p className={styles.s5b} style={{ paddingTop: '1pt', paddingLeft: '4pt', textIndent: '0pt', textAlign: 'left' }}>9C -
                            Amended Credit/Debit Notes (Unregistered) - CDNURA
                        </p>
                    </td>
                </tr>
                <tr style={{ height: '13pt' }}>
                    <td style={{ width: '217pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingLeft: '4pt', textIndent: '0pt', textAlign: 'left' }}>
                            Amended amount - Total
                        </p>
                    </td>
                    <td style={{ width: '57pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '25pt', textIndent: '0pt', textAlign: 'right' }}>
                            {data.cdua.total.count}
                        </p>
                    </td>
                    <td style={{ width: '64pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingLeft: '7pt', paddingRight: '6pt', textIndent: '0pt', textAlign: 'center' }}>
                            Note
                        </p>
                    </td>
                    <td style={{ width: '84pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '3pt', textIndent: '0pt', textAlign: 'right' }}>
                            {(data.cdua.total.value).toFixed(2)}
                        </p>
                    </td>
                    <td style={{ width: '85pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '3pt', textIndent: '0pt', textAlign: 'right' }}>
                            {(data.cdua.total.taxa).toFixed(2)}
                        </p>
                    </td>
                    <td style={{ width: '85pt', border: '1pt solid black' }} bgcolor="#949494">
                        <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                    </td>
                    <td style={{ width: '85pt', border: '1pt solid black' }} bgcolor="#949494">
                        <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                    </td>
                    <td style={{ width: '84pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '3pt', textIndent: '0pt', textAlign: 'right' }}>
                            {(data.cdua.total.cess).toFixed(2)}
                        </p>
                    </td>
                </tr>
                <tr style={{ height: '22pt' }}>
                    <td style={{ width: '217pt', border: '1pt solid black' }}>
                        <p className={styles.s4b} style={{ paddingTop: '1pt', paddingLeft: '4pt', textIndent: '0pt', textAlign: 'left' }}>
                            Net Differential amount (Net Amended Debit notes - Net Amended Credit notes) - Total
                        </p>
                    </td>
                    <td style={{ width: '57pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '25pt', textIndent: '0pt', textAlign: 'right' }}>
                            {data.cdua.total.count}
                        </p>
                    </td>
                    <td style={{ width: '64pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingLeft: '7pt', paddingRight: '6pt', textIndent: '0pt', textAlign: 'center' }}>
                            Note
                        </p>
                    </td>
                    <td style={{ width: '84pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '3pt', textIndent: '0pt', textAlign: 'right' }}>
                            {(data.cdua.ntotal ? data.cdua.ntotal.value : 0).toFixed(2)}
                        </p>
                    </td>
                    <td style={{ width: '85pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '3pt', textIndent: '0pt', textAlign: 'right' }}>
                            {(data.cdua.ntotal ? data.cdua.ntotal.taxa : 0).toFixed(2)}
                        </p>
                    </td>
                    <td style={{ width: '85pt', border: '1pt solid black' }} bgcolor="#949494">
                        <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                    </td>
                    <td style={{ width: '85pt', border: '1pt solid black' }} bgcolor="#949494">
                        <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                    </td>
                    <td style={{ width: '84pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '3pt', textIndent: '0pt', textAlign: 'right' }}>
                            {(data.cdua.ntotal ? data.cdua.ntotal.cess : 0).toFixed(2)}
                        </p>
                    </td>
                </tr>
                <tr style={{ height: '13pt' }}>
                    <td style={{ width: '761pt', border: '1pt solid black' }} colspan="8">
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingLeft: '19pt', textIndent: '0pt', textAlign: 'left' }}>
                            Unregistered Type
                        </p>
                    </td>
                </tr>
            </table>
            <p style={{ pageBreakAfter: 'always' }}>&nbsp;</p>
            <div className='fixed w-[1080px] h-[764px] flex flex-col items-center justify-center opacity-20 pointer-events-none' style={{ zIndex: 9999 }}>
                <img src={Logo} alt="ITAA" className='w-[800] mx-auto h-[227px] pointer-events-none' style={{ transform: "rotate(-45deg)" }} />
            </div>
            <table style={{ borderCollapse: "collapse", margin: "auto", marginTop: "5pt" }} cellspacing="0">
                <tr style={{ height: '25pt' }}>
                    <td style={{ width: '217pt', border: '1pt solid black' }} bgcolor="#002060">
                        <p className={styles.s5} style={{ paddingTop: '6pt', paddingLeft: '85pt', paddingRight: '84pt', textIndent: '0pt', textAlign: 'center' }}>
                            Description
                        </p>
                    </td>
                    <td style={{ width: '57pt', border: '1pt solid black' }} bgcolor="#002060">
                        <p className={styles.s5} style={{ paddingTop: '1pt', paddingLeft: '13pt', textIndent: '2pt', textAlign: 'left' }}>No. of records</p>
                    </td>
                    <td style={{ width: '64pt', border: '1pt solid black' }} bgcolor="#002060">
                        <p className={styles.s5} style={{ paddingTop: '1pt', paddingLeft: '22pt', textIndent: '-11pt', textAlign: 'left' }}>
                            Document Type
                        </p>
                    </td>
                    <td style={{ width: '84pt', border: '1pt solid black' }} bgcolor="#002060">
                        <p className={styles.s5} style={{ paddingTop: '6pt', paddingLeft: '24pt', textIndent: '-11pt', textAlign: 'left' }}>
                            Value (₹)
                        </p>
                    </td>
                    <td style={{ width: '85pt', border: '1pt solid black' }}
                        bgcolor="#002060">
                        <p className={styles.s5} style={{ paddingTop: '6pt', paddingRight: '6pt', textIndent: '0pt', textAlign: 'right' }}>
                            Integrated Tax (₹)
                        </p>
                    </td>
                    <td style={{ width: '85pt', border: '1pt solid black' }} bgcolor="#002060">
                        <p className={styles.s5} style={{ paddingTop: '6pt', paddingLeft: '13pt', textIndent: '0pt', textAlign: 'left' }}>
                            Central Tax (₹)
                        </p>
                    </td>
                    <td style={{ width: '85pt', border: '1pt solid black' }} bgcolor="#002060">
                        <p className={styles.s5} style={{ paddingTop: '6pt', paddingLeft: '9pt', textIndent: '0pt', textAlign: 'left' }}>
                            State/UT Tax (₹)
                        </p>
                    </td>
                    <td style={{ width: '84pt', border: '1pt solid black' }} bgcolor="#002060">
                        <p className={styles.s5} style={{ paddingTop: '6pt', paddingLeft: '25pt', textIndent: '0pt', textAlign: 'left' }}>
                            Cess (₹)
                        </p>
                    </td>
                </tr>
                <tr style={{ height: '13pt' }}>
                    <td style={{ width: '217pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingLeft: '34pt', textIndent: '0pt', textAlign: 'left' }}>
                            - B2CL
                        </p>
                    </td>
                    <td style={{ width: '57pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', textIndent: '0pt', textAlign: 'center' }}>
                            {data.cdua.cl.count}
                        </p>
                    </td>
                    <td style={{ width: '64pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingLeft: '7pt', paddingRight: '6pt', textIndent: '0pt', textAlign: 'center' }}>
                            Note</p>
                    </td>
                    <td style={{ width: '84pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '3pt', textIndent: '0pt', textAlign: 'right' }}>
                            {(data.cdua.cl.value).toFixed(2)}
                        </p>
                    </td>
                    <td style={{ width: '85pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '3pt', textIndent: '0pt', textAlign: 'right' }}>
                            {(data.cdua.cl.taxa).toFixed(2)}
                        </p>
                    </td>
                    <td style={{ width: '85pt', border: '1pt solid black' }} bgcolor="#949494">
                        <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                    </td>
                    <td style={{ width: '85pt', border: '1pt solid black' }} bgcolor="#949494">
                        <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                    </td>
                    <td style={{ width: '84pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '3pt', textIndent: '0pt', textAlign: 'right' }}>
                            {(data.cdua.cl.cess).toFixed(2)}
                        </p>
                    </td>
                </tr>
                <tr style={{ height: '13pt' }}>
                    <td style={{ width: '217pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingLeft: '34pt', textIndent: '0pt', textAlign: 'left' }}>
                            - EXPWP
                        </p>
                    </td>
                    <td style={{ width: '57pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', textIndent: '0pt', textAlign: 'center' }}>
                            {data.cdua.wp.count}
                        </p>
                    </td>
                    <td style={{ width: '64pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingLeft: '7pt', paddingRight: '6pt', textIndent: '0pt', textAlign: 'center' }}>
                            Note
                        </p>
                    </td>
                    <td style={{ width: '84pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '3pt', textIndent: '0pt', textAlign: 'right' }}>
                            {(data.cdua.wp.value).toFixed(2)}
                        </p>
                    </td>
                    <td style={{ width: '85pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '3pt', textIndent: '0pt', textAlign: 'right' }}>
                            {(data.cdua.wp.taxa).toFixed(2)}
                        </p>
                    </td>
                    <td style={{ width: '85pt', border: '1pt solid black' }} bgcolor="#949494">
                        <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                    </td>
                    <td style={{ width: '85pt', border: '1pt solid black' }} bgcolor="#949494">
                        <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                    </td>
                    <td style={{ width: '84pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '3pt', textIndent: '0pt', textAlign: 'right' }}>
                            {(data.cdua.wp.cess).toFixed(2)}
                        </p>
                    </td>
                </tr>
                <tr style={{ height: '13pt' }}>
                    <td style={{ width: '217pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingLeft: '34pt', textIndent: '0pt', textAlign: 'left' }}>
                            - EXPWOP
                        </p>
                    </td>
                    <td style={{ width: '57pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', textIndent: '0pt', textAlign: 'center' }}>
                            {data.cdua.wop.count}
                        </p>
                    </td>
                    <td style={{ width: '64pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingLeft: '7pt', paddingRight: '6pt', textIndent: '0pt', textAlign: 'center' }}>
                            Note
                        </p>
                    </td>
                    <td style={{ width: '84pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '3pt', textIndent: '0pt', textAlign: 'right' }}>
                            {(data.cdua.wop.value).toFixed(2)}
                        </p>
                    </td>
                    <td style={{ width: '85pt', border: '1pt solid black' }} bgcolor="#949494">
                        <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                    </td>
                    <td style={{ width: '85pt', border: '1pt solid black' }} bgcolor="#949494">
                        <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                    </td>
                    <td style={{ width: '85pt', border: '1pt solid black' }} bgcolor="#949494">
                        <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                    </td>
                    <td style={{ width: '84pt', border: '1pt solid black' }} bgcolor="#949494">
                        <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                    </td>
                </tr>
                <tr style={{ height: '13pt' }}>
                    <td style={{ width: '761pt', border: '1pt solid black' }} colspan="8">
                        <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                    </td>
                </tr>
                <tr style={{ height: '13pt' }}>
                    <td style={{ width: '761pt', border: '1pt solid black' }} colspan="8" bgcolor="#FBE4D5">
                        <p className={styles.s6} style={{ paddingTop: '1pt', paddingLeft: '4pt', textIndent: '0pt', textAlign: 'left' }}>
                            10 - Amendment to taxable outward supplies made to unregistered person in returns for earlier tax periods in table 7 - B2C (Others)
                        </p>
                    </td>
                </tr>
                <tr style={{ height: '13pt' }}>
                    <td style={{ width: '217pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingLeft: '4pt', textIndent: '0pt', textAlign: 'left' }}>
                            Amended amount - Total
                        </p>
                    </td>
                    <td style={{ width: '57pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', textIndent: '0pt', textAlign: 'center' }}>{data.b2coa.total.count}</p>
                    </td>
                    <td style={{ width: '64pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingLeft: '7pt', paddingRight: '6pt', textIndent: '0pt', textAlign: 'center' }}>
                            Net Value
                        </p>
                    </td>
                    <td style={{ width: '84pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '3pt', textIndent: '0pt', textAlign: 'right' }}>
                            {(data.b2coa.total.value).toFixed(2)}
                        </p>
                    </td>
                    <td style={{ width: '85pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '3pt', textIndent: '0pt', textAlign: 'right' }}>
                            {(data.b2coa.total.taxa).toFixed(2)}
                        </p>
                    </td>
                    <td style={{ width: '85pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '3pt', textIndent: '0pt', textAlign: 'right' }}>
                            {(data.b2coa.total.taxb).toFixed(2)}
                        </p>
                    </td>
                    <td style={{ width: '85pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '3pt', textIndent: '0pt', textAlign: 'right' }}>
                            {(data.b2coa.total.taxb).toFixed(2)}
                        </p>
                    </td>
                    <td style={{ width: '84pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '3pt', textIndent: '0pt', textAlign: 'right' }}>
                            {(data.b2coa.total.cess).toFixed(2)}
                        </p>
                    </td>
                </tr>
                <tr style={{ height: '13pt' }}>
                    <td style={{ width: '217pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingLeft: '4pt', textIndent: '0pt', textAlign: 'left' }}>
                            Net differential amount (Amended - Original)
                        </p>
                    </td>
                    <td style={{ width: '57pt', border: '1pt solid black' }}>
                        <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                    </td>
                    <td style={{ width: '64pt', border: '1pt solid black' }}>
                        <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                    </td>
                    <td style={{ width: '84pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '3pt', textIndent: '0pt', textAlign: 'right' }}>
                            {(data.b2coa.total.value - (data.b2coa.ototal ? data.b2coa.ototal.value : 0)).toFixed(2)}
                        </p>
                    </td>
                    <td style={{ width: '85pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '3pt', textIndent: '0pt', textAlign: 'right' }}>
                            {(data.b2coa.total.taxa - (data.b2coa.ototal ? data.b2coa.ototal.taxa : 0)).toFixed(2)}
                        </p>
                    </td>
                    <td style={{ width: '85pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '3pt', textIndent: '0pt', textAlign: 'right' }}>
                            {(data.b2coa.total.taxb - (data.b2coa.ototal ? data.b2coa.ototal.taxb : 0)).toFixed(2)}
                        </p>
                    </td>
                    <td style={{ width: '85pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '3pt', textIndent: '0pt', textAlign: 'right' }}>
                            {(data.b2coa.total.taxb - (data.b2coa.ototal ? data.b2coa.ototal.taxb : 0)).toFixed(2)}
                        </p>
                    </td>
                    <td style={{ width: '84pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '3pt', textIndent: '0pt', textAlign: 'right' }}>
                            {(data.b2coa.total.cess - (data.b2coa.ototal ? data.b2coa.ototal.cess : 0)).toFixed(2)}
                        </p>
                    </td>
                </tr>
                <tr style={{ height: '13pt' }}>
                    <td style={{ width: '761pt', border: '1pt solid black' }} colspan="8">
                        <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                    </td>
                </tr>
                <tr style={{ height: '13pt' }}>
                    <td style={{ width: '761pt', border: '1pt solid black' }} colspan="8" bgcolor="#FBE4D5">
                        <p className={styles.s6} style={{ paddingTop: '1pt', paddingLeft: '4pt', textIndent: '0pt', textAlign: 'left' }}>
                            11A(1), 11A(2) - Advances received for which invoice has not been issued (tax amount to be added to the output tax liability) (Net of refund vouchers)
                        </p>
                    </td>
                </tr>
                <tr style={{ height: '13pt' }}>
                    <td style={{ width: '217pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingLeft: '4pt', textIndent: '0pt', textAlign: 'left' }}>
                            Total
                        </p>
                    </td>
                    <td style={{ width: '57pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', textIndent: '0pt', textAlign: 'center' }}>
                            {data.a11.total.count}
                        </p>
                    </td>
                    <td style={{ width: '64pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingLeft: '7pt', paddingRight: '6pt', textIndent: '0pt', textAlign: 'center' }}>
                            Net Value</p>
                    </td>
                    <td style={{ width: '84pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '3pt', textIndent: '0pt', textAlign: 'right' }}>
                            {(data.a11.total.value).toFixed(2)}
                        </p>
                    </td>
                    <td style={{ width: '85pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '3pt', textIndent: '0pt', textAlign: 'right' }}>
                            {(data.a11.total.taxa).toFixed(2)}
                        </p>
                    </td>
                    <td style={{ width: '85pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '3pt', textIndent: '0pt', textAlign: 'right' }}>
                            {(data.a11.total.taxb).toFixed(2)}
                        </p>
                    </td>
                    <td style={{ width: '85pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '3pt', textIndent: '0pt', textAlign: 'right' }}>
                            {(data.a11.total.taxb).toFixed(2)}
                        </p>
                    </td>
                    <td style={{ width: '84pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '3pt', textIndent: '0pt', textAlign: 'right' }}>
                            {(data.a11.total.cess).toFixed(2)}
                        </p>
                    </td>
                </tr>
                <tr style={{ height: '13pt' }}>
                    <td style={{ width: '761pt', border: '1pt solid black' }} colspan="8">
                        <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                    </td>
                </tr>
                <tr style={{ height: '13pt' }}>
                    <td style={{ width: '761pt', border: '1pt solid black' }} colspan="8" bgcolor="#FBE4D5">
                        <p className={styles.s6} style={{ paddingTop: '1pt', paddingLeft: '4pt', textIndent: '0pt', textAlign: 'left' }}>
                            11B(1), 11B(2) - Advance amount received in earlier tax period and adjusted against the supplies being shown in this tax period in Table Nos. 4, 5, 6 and 7
                        </p>
                    </td>
                </tr>
                <tr style={{ height: '13pt' }}>
                    <td style={{ width: '217pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingLeft: '4pt', textIndent: '0pt', textAlign: 'left' }}>
                            Total
                        </p>
                    </td>
                    <td style={{ width: '57pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', textIndent: '0pt', textAlign: 'center' }}>
                            {data.b11.total.count}
                        </p>
                    </td>
                    <td style={{ width: '64pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingLeft: '7pt', paddingRight: '6pt', textIndent: '0pt', textAlign: 'center' }}>
                            Net Value
                        </p>
                    </td>
                    <td style={{ width: '84pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '3pt', textIndent: '0pt', textAlign: 'right' }}>
                            {(data.b11.total.value).toFixed(2)}
                        </p>
                    </td>
                    <td style={{ width: '85pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '3pt', textIndent: '0pt', textAlign: 'right' }}>
                            {(data.b11.total.taxa).toFixed(2)}
                        </p>
                    </td>
                    <td style={{ width: '85pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '3pt', textIndent: '0pt', textAlign: 'right' }}>
                            {(data.b11.total.taxb).toFixed(2)}
                        </p>
                    </td>
                    <td style={{ width: '85pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '3pt', textIndent: '0pt', textAlign: 'right' }}>
                            {(data.b11.total.taxb).toFixed(2)}
                        </p>
                    </td>
                    <td style={{ width: '84pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '3pt', textIndent: '0pt', textAlign: 'right' }}>
                            {(data.b11.total.cess).toFixed(2)}
                        </p>
                    </td>
                </tr>
                <tr style={{ height: '13pt' }}>
                    <td style={{ width: '761pt', border: '1pt solid black' }} colspan="8">
                        <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                    </td>
                </tr>
                <tr style={{ height: '13pt' }}>
                    <td style={{ width: '761pt', border: '1pt solid black' }} colspan="8" bgcolor="#FBE4D5">
                        <p className={styles.s6} style={{ paddingTop: '1pt', paddingLeft: '4pt', textIndent: '0pt', textAlign: 'left' }}>
                            11A - Amendment to advances received in returns for earlier tax periods in table 11A(1), 11A(2)
                        </p>
                    </td>
                </tr>
                <tr style={{ height: '13pt' }}>
                    <td style={{ width: '217pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingLeft: '4pt', textIndent: '0pt', textAlign: 'left' }}>
                            Amended amount - Total
                        </p>
                    </td>
                    <td style={{ width: '57pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', textIndent: '0pt', textAlign: 'center' }}>
                            {data.a11a.total.count}
                        </p>
                    </td>
                    <td style={{ width: '64pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingLeft: '7pt', paddingRight: '6pt', textIndent: '0pt', textAlign: 'center' }}>
                            Net Value
                        </p>
                    </td>
                    <td style={{ width: '84pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '3pt', textIndent: '0pt', textAlign: 'right' }}>
                            {(data.a11a.total.value).toFixed(2)}
                        </p>
                    </td>
                    <td style={{ width: '85pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '3pt', textIndent: '0pt', textAlign: 'right' }}>
                            {(data.a11a.total.taxa).toFixed(2)}
                        </p>
                    </td>
                    <td style={{ width: '85pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '3pt', textIndent: '0pt', textAlign: 'right' }}>
                            {(data.a11a.total.taxb).toFixed(2)}
                        </p>
                    </td>
                    <td style={{ width: '85pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '3pt', textIndent: '0pt', textAlign: 'right' }}>
                            {(data.a11a.total.taxb).toFixed(2)}
                        </p>
                    </td>
                    <td style={{ width: '84pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '3pt', textIndent: '0pt', textAlign: 'right' }}>
                            {(data.a11a.total.cess).toFixed(2)}
                        </p>
                    </td>
                </tr>
                <tr style={{ height: '13pt' }}>
                    <td style={{ width: '217pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingLeft: '4pt', textIndent: '0pt', textAlign: 'left' }}>
                            Total
                        </p>
                    </td>
                    <td style={{ width: '57pt', border: '1pt solid black' }}>
                        <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                    </td>
                    <td style={{ width: '64pt', border: '1pt solid black' }}>
                        <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                    </td>
                    <td style={{ width: '84pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '3pt', textIndent: '0pt', textAlign: 'right' }}>
                            {(data.a11a.total.value - (data.a11a.ototal ? data.a11a.ototal.value : 0)).toFixed(2)}
                        </p>
                    </td>
                    <td style={{ width: '85pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '3pt', textIndent: '0pt', textAlign: 'right' }}>
                            {(data.a11a.total.taxa - (data.a11a.ototal ? data.a11a.ototal.taxa : 0)).toFixed(2)}
                        </p>
                    </td>
                    <td style={{ width: '85pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '3pt', textIndent: '0pt', textAlign: 'right' }}>
                            {(data.a11a.total.taxb - (data.a11a.ototal ? data.a11a.ototal.taxb : 0)).toFixed(2)}
                        </p>
                    </td>
                    <td style={{ width: '85pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '3pt', textIndent: '0pt', textAlign: 'right' }}>
                            {(data.a11a.total.taxb - (data.a11a.ototal ? data.a11a.ototal.taxb : 0)).toFixed(2)}
                        </p>
                    </td>
                    <td style={{ width: '84pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '3pt', textIndent: '0pt', textAlign: 'right' }}>
                            {(data.a11a.total.cess - (data.a11a.ototal ? data.a11a.ototal.cess : 0)).toFixed(2)}
                        </p>
                    </td>
                </tr>
                <tr style={{ height: '13pt' }}>
                    <td style={{ width: '761pt', border: '1pt solid black' }} colspan="8">
                        <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                    </td>
                </tr>
                <tr style={{ height: '13pt' }}>
                    <td style={{ width: '761pt', border: '1pt solid black' }} colspan="8" bgcolor="#FBE4D5">
                        <p className={styles.s6} style={{ paddingTop: '1pt', paddingLeft: '4pt', textIndent: '0pt', textAlign: 'left' }}>
                            11B - Amendment to advances adjusted in returns for earlier tax periods in table 11B(1), 11B(2)
                        </p>
                    </td>
                </tr>
                <tr style={{ height: '13pt' }}>
                    <td style={{ width: '217pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingLeft: '4pt', textIndent: '0pt', textAlign: 'left' }}>
                            Amended amount - Total
                        </p>
                    </td>
                    <td style={{ width: '57pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', textIndent: '0pt', textAlign: 'center' }}>
                            {data.b11a.total.count}
                        </p>
                    </td>
                    <td style={{ width: '64pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingLeft: '7pt', paddingRight: '6pt', textIndent: '0pt', textAlign: 'center' }}>
                            Net Value
                        </p>
                    </td>
                    <td style={{ width: '84pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '3pt', textIndent: '0pt', textAlign: 'right' }}>
                            {(data.b11a.total.value).toFixed(2)}
                        </p>
                    </td>
                    <td style={{ width: '85pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '3pt', textIndent: '0pt', textAlign: 'right' }}>
                            {(data.b11a.total.taxa).toFixed(2)}
                        </p>
                    </td>
                    <td style={{ width: '85pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '3pt', textIndent: '0pt', textAlign: 'right' }}>
                            {(data.b11a.total.taxb).toFixed(2)}
                        </p>
                    </td>
                    <td style={{ width: '85pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '3pt', textIndent: '0pt', textAlign: 'right' }}>
                            {(data.b11a.total.taxb).toFixed(2)}
                        </p>
                    </td>
                    <td style={{ width: '84pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '3pt', textIndent: '0pt', textAlign: 'right' }}>
                            {(data.b11a.total.cess).toFixed(2)}
                        </p>
                    </td>
                </tr>
                <tr style={{ height: '13pt' }}>
                    <td style={{ width: '217pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingLeft: '4pt', textIndent: '0pt', textAlign: 'left' }}>
                            Total
                        </p>
                    </td>
                    <td style={{ width: '57pt', border: '1pt solid black' }}>
                        <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                    </td>
                    <td style={{ width: '64pt', border: '1pt solid black' }}>
                        <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                    </td>
                    <td style={{ width: '84pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '3pt', textIndent: '0pt', textAlign: 'right' }}>
                            {(data.b11a.total.value - (data.b11a.ototal ? data.b11a.ototal.value : 0)).toFixed(2)}
                        </p>
                    </td>
                    <td style={{ width: '85pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '3pt', textIndent: '0pt', textAlign: 'right' }}>
                            {(data.b11a.total.taxa - (data.b11a.ototal ? data.b11a.ototal.taxa : 0)).toFixed(2)}
                        </p>
                    </td>
                    <td style={{ width: '85pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '3pt', textIndent: '0pt', textAlign: 'right' }}>
                            {(data.b11a.total.taxb - (data.b11a.ototal ? data.b11a.ototal.taxb : 0)).toFixed(2)}
                        </p>
                    </td>
                    <td style={{ width: '85pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '3pt', textIndent: '0pt', textAlign: 'right' }}>
                            {(data.b11a.total.taxb - (data.b11a.ototal ? data.b11a.ototal.taxb : 0)).toFixed(2)}
                        </p>
                    </td>
                    <td style={{ width: '84pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '3pt', textIndent: '0pt', textAlign: 'right' }}>
                            {(data.b11a.total.cess - (data.b11a.ototal ? data.b11a.ototal.cess : 0)).toFixed(2)}
                        </p>
                    </td>
                </tr>
                <tr style={{ height: '13pt' }}>
                    <td style={{ width: '761pt', border: '1pt solid black' }} colspan="8">
                        <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                    </td>
                </tr>
                <tr style={{ height: '13pt' }}>
                    <td style={{ width: '761pt', border: '1pt solid black' }} colspan="8" bgcolor="#FBE4D5">
                        <p className={styles.s6} style={{ paddingTop: '1pt', paddingLeft: '4pt', textIndent: '0pt', textAlign: 'left' }}>
                            12 - HSN-wise summary of outward supplies
                        </p>
                    </td>
                </tr>
                <tr style={{ height: '13pt' }}>
                    <td style={{ width: '217pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingLeft: '4pt', textIndent: '0pt', textAlign: 'left' }}>
                            Total
                        </p>
                    </td>
                    <td style={{ width: '57pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', textIndent: '0pt', textAlign: 'center' }}>
                            {data.hsn.total.count}
                        </p>
                    </td>
                    <td style={{ width: '64pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingLeft: '7pt', paddingRight: '6pt', textIndent: '0pt', textAlign: 'center' }}>
                            NA
                        </p>
                    </td>
                    <td style={{ width: '84pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '3pt', textIndent: '0pt', textAlign: 'right' }}>
                            {(data.hsn.total.value).toFixed(2)}
                        </p>
                    </td>
                    <td style={{ width: '85pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '3pt', textIndent: '0pt', textAlign: 'right' }}>
                            {(data.hsn.total.taxa).toFixed(2)}
                        </p>
                    </td>
                    <td style={{ width: '85pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '3pt', textIndent: '0pt', textAlign: 'right' }}>
                            {(data.hsn.total.taxb).toFixed(2)}
                        </p>
                    </td>
                    <td style={{ width: '85pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '3pt', textIndent: '0pt', textAlign: 'right' }}>
                            {(data.hsn.total.taxc).toFixed(2)}
                        </p>
                    </td>
                    <td style={{ width: '84pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '3pt', textIndent: '0pt', textAlign: 'right' }}>
                            {(data.hsn.total.cess).toFixed(2)}
                        </p>
                    </td>
                </tr>
                <tr style={{ height: '13pt' }}>
                    <td style={{ width: '761pt', border: '1pt solid black' }} colspan="8">
                        <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                    </td>
                </tr>
                <tr style={{ height: '13pt' }}>
                    <td style={{ width: '761pt', border: '1pt solid black' }} colspan="8" bgcolor="#FBE4D5">
                        <p className={styles.s6} style={{ paddingTop: '1pt', paddingLeft: '4pt', textIndent: '0pt', textAlign: 'left' }}>
                            13 - Documents issued
                        </p>
                    </td>
                </tr>
                <tr style={{ height: '13pt' }}>
                    <td style={{ width: '217pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingLeft: '4pt', textIndent: '0pt', textAlign: 'left' }}>
                            Net issued documents
                        </p>
                    </td>
                    <td style={{ width: '57pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingLeft: '23pt', paddingRight: '23pt', textIndent: '0pt', textAlign: 'center' }}>
                            {data.B13.total.countA}
                        </p>
                    </td>
                    <td style={{ width: '64pt', border: '1pt solid black' }}>
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingLeft: '7pt', paddingRight: '6pt', textIndent: '0pt', textAlign: 'center' }}>
                            All Documents
                        </p>
                    </td>
                    <td style={{ width: '84pt', border: '1pt solid black' }} bgcolor="#949494">
                        <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                    </td>
                    <td style={{ width: '85pt', border: '1pt solid black' }} bgcolor="#949494">
                        <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                    </td>
                    <td style={{ width: '85pt', border: '1pt solid black' }} bgcolor="#949494">
                        <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                    </td>
                    <td style={{ width: '85pt', border: '1pt solid black' }} bgcolor="#949494">
                        <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                    </td>
                    <td style={{ width: '84pt', border: '1pt solid black' }} bgcolor="#949494">
                        <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                    </td>
                </tr>
                <tr style={{ height: '13pt' }}>
                    <td style={{ width: '761pt', border: '1pt solid black' }} colspan="8">
                        <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                    </td>
                </tr>
                <tr style={{ height: '13pt' }}>
                    <td style={{ width: '338pt', border: '1pt solid black' }} colspan="3" bgcolor="#ED7D31">
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingLeft: '4pt', textIndent: '0pt', textAlign: 'left' }}>
                            Total Liability (Outward supplies other than Reverse charge)
                        </p>
                    </td>
                    <td style={{ width: '84pt', border: '1pt solid black' }} bgcolor="#ED7D31">
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '3pt', textIndent: '0pt', textAlign: 'right' }}>
                            {(data.total.value).toFixed(2)}
                        </p>
                    </td>
                    <td style={{ width: '85pt', border: '1pt solid black' }} bgcolor="#ED7D31">
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '3pt', textIndent: '0pt', textAlign: 'right' }}>
                            {(data.total.taxa).toFixed(2)}
                        </p>
                    </td>
                    <td style={{ width: '85pt', border: '1pt solid black' }} bgcolor="#ED7D31">
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '3pt', textIndent: '0pt', textAlign: 'right' }}>
                            {(data.total.taxb).toFixed(2)}
                        </p>
                    </td>
                    <td style={{ width: '85pt', border: '1pt solid black' }} bgcolor="#ED7D31">
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '3pt', textIndent: '0pt', textAlign: 'right' }}>
                            {(data.total.taxb).toFixed(2)}
                        </p>
                    </td>
                    <td style={{ width: '84pt', border: '1pt solid black' }} bgcolor="#ED7D31">
                        <p className={styles.s4} style={{ paddingTop: '1pt', paddingRight: '3pt', textIndent: '0pt', textAlign: 'right' }}>
                            {(data.total.cess).toFixed(2)}
                        </p>
                    </td>
                </tr>
            </table>
            <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
            <p style={{ textIndent: '0pt', textAlign: 'left' }} />
            {
                gstrFile.status === "done" && <div className='w-[761pt] text-lg' style={{ margin: 'auto' }}>
                    <p className='text-black font-medium'>Verification:</p>
                    <p>
                        I hereby solemnly affirm and declare that the information given herein above is true and correct to the best of my knowledge and belief and nothing has been
                        concealed there from and in case of any reduction in output tax liability the benefit thereof has been/will be passed on to the recipient of supply.
                    </p>
                    <p><br /></p>
                    <p>Date: {Moment(gDate).add('days', 19).format('DD/MM/YYYY')}</p>
                    <p>Signature</p>
                    <p>Name of Authorized Signatory</p>
                    <p><b className='text-black'>{getName()}</b></p>
                    <p>Designation/Status: <b className='text-black'>{getDesignation()}</b></p>
                </div>
            }
        </div >
    )
}