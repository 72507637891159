import React from 'react';

import { useRecoilState } from 'recoil';
import { digMainErrorAtom } from '../../config/atoms';

import '../../../assets/styles/app.css';

export default function DigMainError() {

    const [dig, setDig] = useRecoilState(digMainErrorAtom);

    const cancel = () => {
        setDig({ title: '', msg: '', isToggle: false });
    }

    return (
        <div className='w-full h-full overflow-hidden fixed z-50 flex items-center justify-center bg-black bg-opacity-60'>
            <div id="confirmDlg" class="modal fade fade-scale in" role="dialog" style={{ display: 'block', paddingRight: '17px' }}>
                <div class="modal-dialog sweet">
                    <div class="modal-content">
                        <div class="modal-body">
                            <div class="m-icon m-error">
                                <span class="x-mark">
                                    <span class="m-line m-left"></span>
                                    <span class="m-line m-right"></span>
                                </span>
                            </div>
                            <h2>{dig.title}</h2>
                            <p>{dig.msg}</p>
                        </div>
                        <div class="modal-footer">
                            <a class="btn btn-primary" onClick={() => { cancel() }}>OK</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
