import React, { useState, useEffect } from 'react';

import { useRecoilState } from 'recoil';
import { pathAtom, gstrpathAtom, digErrorAtom, returnDateAtom, loadingAtom, gstrUserAtom, gstrFileStatusAtom, userIDAtom } from '../../../core/config/atoms';

import { useFormik } from 'formik';

import ServiceGSTR from '../../../services/serviceGSTR';

import '../css/main.css';
import '../css/style.css';

import imgLoader from '../../../assets/images/cloading.gif';

export default function PanelNillHome() {

    const [path, setPath] = useRecoilState(pathAtom);
    const [gstrPath, setGSTRPath] = useRecoilState(gstrpathAtom);
    const [digError, setDigError] = useRecoilState(digErrorAtom);
    const [gDate] = useRecoilState(returnDateAtom);
    const [loading, setLoading] = useRecoilState(loadingAtom);
    const [GSTUser] = useRecoilState(gstrUserAtom);
    const [fileStatus] = useRecoilState(gstrFileStatusAtom);
    const [userID] = useRecoilState(userIDAtom);

    const [prevDoc, setPrevDoc] = useState({});

    const [isAlert, setIsAlrt] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [submit, setSubmit] = useState(false);

    const returnService = new ServiceGSTR();

    const { values, errors, touched, setValues, handleChange, handleSubmit, handleReset, resetForm } = useFormik({
        initialValues: {
            invaval1: '0.00',
            invaval2: '0.00',
            invaval3: '0.00',
            invbval1: '0.00',
            invbval2: '0.00',
            invbval3: '0.00',
            invcval1: '0.00',
            invcval2: '0.00',
            invcval3: '0.00',
            invdval1: '0.00',
            invdval2: '0.00',
            invdval3: '0.00',
        },
        onSubmit: values => {
            setSubmit(true);
            let body = {};
            const da = getFinalValues();
            if (isEdit) {
                body = {
                    id: prevDoc._id,
                    data: da,
                }
                returnService.updateGstrDoc(body).then((res) => {
                    if (res.status) {
                        showAlert();
                        loadData();
                    } else {
                        setDigError({ title: 'Error', msg: 'Server error please try again!', isToggle: true });
                    }
                    setSubmit(false);
                    window.scrollTo({
                        top: 0,
                        behavior: 'smooth',
                    });
                });
            } else {
                body = {
                    month: gDate,
                    lid: userID,
                    type: 'nill',
                    data: da,
                }
                returnService.setGstrDoc(body).then((res) => {
                    if (res.status) {
                        showAlert();
                        loadData();
                    } else {
                        setDigError({ title: 'Error', msg: 'Server error please try again!', isToggle: true });
                    }
                    setSubmit(false);
                    window.scrollTo({
                        top: 0,
                        behavior: 'smooth',
                    });
                });
            }
        },
    });

    const gotoPath = (path) => {
        setLoading(true);
        setPath(path);
    }

    const getFormatvalue = (val) => {
        if (!val) return val;
        let number = val.replace(/[^\d.]/g, "");
        if (val.includes(".")) {
            const a = number.split(".");
            if (a[1].length > 2) {
                a[1] = a[1].substring(0, 2);
            }
            if (a[0].length > 11) {
                a[0] = a[0].substring(0, 11);
            }
            number = a[0] + "." + a[1];
        } else {
            if (number.length > 11) {
                number = number.substring(0, 11);
            }
        }
        return number;
    }

    const showAlert = () => {
        setIsAlrt(true);
        setTimeout(
            () => setIsAlrt(false),
            5000
        );
    }

    const getFinalValues = () => {
        values.invaval1 = parseFloat(values.invaval1).toFixed(2);
        values.invaval2 = parseFloat(values.invaval2).toFixed(2);
        values.invaval3 = parseFloat(values.invaval3).toFixed(2);
        values.invbval1 = parseFloat(values.invbval1).toFixed(2);
        values.invbval2 = parseFloat(values.invbval2).toFixed(2);
        values.invbval3 = parseFloat(values.invbval3).toFixed(2);
        values.invcval1 = parseFloat(values.invcval1).toFixed(2);
        values.invcval2 = parseFloat(values.invcval2).toFixed(2);
        values.invcval3 = parseFloat(values.invcval3).toFixed(2);
        values.invdval1 = parseFloat(values.invdval1).toFixed(2);
        values.invdval2 = parseFloat(values.invdval2).toFixed(2);
        values.invdval3 = parseFloat(values.invdval3).toFixed(2);
        return values;
    }

    const loadData = () => {
        document.getElementById("nilPage").reset();
        setLoading(true);
        (async function () {
            let body = {
                lid: userID,
                month: gDate,
                type: 'nill'
            }
            var res = await returnService.getGstrDocs(body);
            if (res.status) {
                setSubmit(false);
                if (res.data.length != 0) {
                    setIsEdit(true);
                    setValues(res.data[0].data);
                    setPrevDoc(res.data[0]);
                } else {
                    setIsEdit(false);
                    setPrevDoc({});
                }
            } else {
                setDigError({ title: 'Error', msg: 'Server error please try again!', isToggle: true });
            }
            setLoading(false);
            window.scrollTo({
                top: 0,
                behavior: 'smooth',
            });
        })();
    }

    useEffect(() => {
        loadData();
    }, []);

    return (
        <div class="content-wrapper fade-ng-cloak">
            <div class="dimmer-holder" style={{ display: 'none' }}>
                <div id="dimmer"></div>
            </div>
            {
                loading && <div className='absolute z-50 w-full h-screen flex items-center justify-center bg-gray-200 bg-opacity-25'>
                    <img src={imgLoader} alt="loader" />
                </div>
            }
            <div class="container">
                {
                    !loading && <div class="mypage">
                        <div class="row" data-ng-controller="transctrl" data-ng-init="init('returns')">
                            <div class="col-xs-10">
                                <div data-breadcrumb="" name="Dashboard">
                                    <ol class="breadcrumb" data-ng-controller="crumbCtrl">
                                        <li><a onClick={() => {
                                            gotoPath('gstr')
                                        }}>Dashboard</a></li>
                                        <li onClick={() => {
                                            gotoPath('gstRetrun')
                                        }}><a>Returns</a></li>
                                        <li onClick={() => {
                                            gotoPath('gstrForm')
                                        }}><a>GSTR-1/IFF</a></li>
                                        <li>NIL-RATED</li>
                                    </ol>
                                </div>
                            </div>
                            <div class="col-xs-2">
                                <div class="lang dropdown">
                                    <span class="dropdown-toggle" data-toggle="dropdown" data-ng-bind="selectedLang">English</span>
                                    <ul class="dropdown-menu lang-dpdwn">
                                        <li data-ng-repeat="language in languages">
                                            <a data-ng-click="selectLang(language.cd)" data-ng-bind="language.nm">English</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div data-ng-controller="mainctrl" ng-view="">
                            <div class="container">
                                {isAlert && <alert-message ng-show="showMsg" title="" type="msg alert-success" message="" class="ng-hide"><div class="alert alert-msg alert-success">Your records is been proceed successfully<a class="close" onClick={() => { setIsAlrt(false) }} data-dismiss="alert" aria-label="close">×</a><strong></strong>.</div></alert-message>}
                                <form name="nilPage" id='nilPage' novalidate="" autocomplete="off" onSubmit={handleSubmit} onReset={handleReset}>
                                    <div class="row invsumm">
                                        <div class="col-xs-12 col-sm-8">
                                            <h4 data-ng-bind="trans.HEAD_NIL_TABLE">8A, 8B, 8C, 8D - Nil Rated, Exempted and Non-GST Supplies</h4>
                                        </div>
                                        <div class="col-xs-12 col-sm-4">
                                            <h4>
                                                <button class="btn btn-primary btn-circle btn-sm pull-right" style={{ marginTop: '0px' }} disabled={true}><i class="fa fa-refresh" aria-hidden="true"></i></button>
                                            </h4>
                                        </div>
                                    </div>
                                    <div class="tabpane">
                                        <h4 data-ng-bind="trans.HEAD_ITEM_DETAILS">Item details</h4>
                                        <hr />
                                        <div class="row">
                                            <div class="col-lg-12 col-md-12 col-sm-12">
                                                <div class="table-responsive">
                                                    <fieldset disabled={fileStatus}>
                                                        <table class="table inv exp tbl table-bordered" style={{ display: 'table' }}>
                                                            <thead>
                                                                <tr>
                                                                    <th class="text-center">Description</th>
                                                                    <th class="text-center">Nil Rated Supplies (₹)</th>
                                                                    <th class="text-center">Exempted(Other than Nil rated/non-GST supply) (₹)</th>
                                                                    <th class="text-center">Non-GST Supplies (₹)</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <td>Intra-state supplies to registered person</td>
                                                                    <td class="currency">
                                                                        <div class="input-group">
                                                                            <input id="invaval1" class="form-control newinv formedit currency" type="text" value={values.invaval1} onChange={e => {
                                                                                const formatValue = getFormatvalue(e.target.value);
                                                                                e.target.value = formatValue;
                                                                                handleChange(e);
                                                                            }} onBlur={e => {
                                                                                if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                    e.target.value = e.target.value + ".00";
                                                                                }
                                                                                handleChange(e);
                                                                            }} />
                                                                        </div>
                                                                    </td>
                                                                    <td class="currency">
                                                                        <input id="invaval2" class="form-control newinv formedit currency" title="currency" type="text" value={values.invaval2} onChange={e => {
                                                                            const formatValue = getFormatvalue(e.target.value);
                                                                            e.target.value = formatValue;
                                                                            handleChange(e);
                                                                        }} onBlur={e => {
                                                                            if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                e.target.value = e.target.value + ".00";
                                                                            }
                                                                            handleChange(e);
                                                                        }} />
                                                                    </td>
                                                                    <td class="currency">
                                                                        <input id="invaval3" isneg="true" class="form-control formedit newinv currency" value={values.invaval3} onChange={e => {
                                                                            const formatValue = getFormatvalue(e.target.value);
                                                                            e.target.value = formatValue;
                                                                            handleChange(e);
                                                                        }} onBlur={e => {
                                                                            if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                e.target.value = e.target.value + ".00";
                                                                            }
                                                                            handleChange(e);
                                                                        }} />
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Intra-state supplies to unregistered person</td>
                                                                    <td class="currency">
                                                                        <div class="input-group">
                                                                            <input id="invbval1" isneg="true" class="form-control newinv formedit currency" value={values.invbval1} onChange={e => {
                                                                                const formatValue = getFormatvalue(e.target.value);
                                                                                e.target.value = formatValue;
                                                                                handleChange(e);
                                                                            }} onBlur={e => {
                                                                                if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                    e.target.value = e.target.value + ".00";
                                                                                }
                                                                                handleChange(e);
                                                                            }} />
                                                                        </div>
                                                                    </td>
                                                                    <td class="currency">
                                                                        <input id="invbval2" isneg="true" class="form-control newinv formedit currency" value={values.invbval2} onChange={e => {
                                                                            const formatValue = getFormatvalue(e.target.value);
                                                                            e.target.value = formatValue;
                                                                            handleChange(e);
                                                                        }} onBlur={e => {
                                                                            if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                e.target.value = e.target.value + ".00";
                                                                            }
                                                                            handleChange(e);
                                                                        }} />
                                                                    </td>
                                                                    <td class="currency">
                                                                        <input id="invbval3" isneg="true" class="form-control formedit newinv currency" value={values.invbval3} onChange={e => {
                                                                            const formatValue = getFormatvalue(e.target.value);
                                                                            e.target.value = formatValue;
                                                                            handleChange(e);
                                                                        }} onBlur={e => {
                                                                            if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                e.target.value = e.target.value + ".00";
                                                                            }
                                                                            handleChange(e);
                                                                        }} />
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Inter-state supplies to registered person</td>
                                                                    <td class="currency">
                                                                        <div class="input-group">
                                                                            <input id="invcval1" isneg="true" class="form-control newinv formedit currency" value={values.invcval1} onChange={e => {
                                                                                const formatValue = getFormatvalue(e.target.value);
                                                                                e.target.value = formatValue;
                                                                                handleChange(e);
                                                                            }} onBlur={e => {
                                                                                if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                    e.target.value = e.target.value + ".00";
                                                                                }
                                                                                handleChange(e);
                                                                            }} />
                                                                        </div>
                                                                    </td>
                                                                    <td class="currency">
                                                                        <input id="invcval2" isneg="true" class="form-control newinv formedit currency" value={values.invcval2} onChange={e => {
                                                                            const formatValue = getFormatvalue(e.target.value);
                                                                            e.target.value = formatValue;
                                                                            handleChange(e);
                                                                        }} onBlur={e => {
                                                                            if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                e.target.value = e.target.value + ".00";
                                                                            }
                                                                            handleChange(e);
                                                                        }} />
                                                                    </td>
                                                                    <td class="currency">
                                                                        <input id="invcval3" isneg="true" class="form-control formedit newinv currency" value={values.invcval3} onChange={e => {
                                                                            const formatValue = getFormatvalue(e.target.value);
                                                                            e.target.value = formatValue;
                                                                            handleChange(e);
                                                                        }} onBlur={e => {
                                                                            if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                e.target.value = e.target.value + ".00";
                                                                            }
                                                                            handleChange(e);
                                                                        }} />
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Inter-state supplies to unregistered person</td>
                                                                    <td class="currency">
                                                                        <div class="input-group">
                                                                            <input id="invdval1" isneg="true" class="form-control newinv formedit currency" value={values.invdval1} onChange={e => {
                                                                                const formatValue = getFormatvalue(e.target.value);
                                                                                e.target.value = formatValue;
                                                                                handleChange(e);
                                                                            }} onBlur={e => {
                                                                                if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                    e.target.value = e.target.value + ".00";
                                                                                }
                                                                                handleChange(e);
                                                                            }} />
                                                                        </div>
                                                                    </td>
                                                                    <td class="currency">
                                                                        <input id="invdval2" isneg="true" class="form-control newinv formedit currency" value={values.invdval2} onChange={e => {
                                                                            const formatValue = getFormatvalue(e.target.value);
                                                                            e.target.value = formatValue;
                                                                            handleChange(e);
                                                                        }} onBlur={e => {
                                                                            if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                e.target.value = e.target.value + ".00";
                                                                            }
                                                                            handleChange(e);
                                                                        }} />
                                                                    </td>
                                                                    <td class="currency">
                                                                        <input id="invdval3" isneg="true" class="form-control formedit newinv currency" value={values.invdval3} onChange={e => {
                                                                            const formatValue = getFormatvalue(e.target.value);
                                                                            e.target.value = formatValue;
                                                                            handleChange(e);
                                                                        }} onBlur={e => {
                                                                            if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                e.target.value = e.target.value + ".00";
                                                                            }
                                                                            handleChange(e);
                                                                        }} />
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </fieldset>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="btn-toolbar">
                                            <button type="submit" class="btn btn-primary pull-right accpt" disabled={submit || fileStatus}>{isEdit ? "EDIT" : "Save"}</button>
                                            <button type="button" class="btn btn-default pull-right" onClick={() => {
                                                gotoPath('gstrForm')
                                            }}>Back</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </div>
    );

}