import React, { useEffect } from 'react';

import UINavigationBar from '../core/ui/navigation/UINavigationBar';

import { useRecoilState } from 'recoil';
import { adminPathAtom } from '../core/config/atoms';

import FragmentStd from '../fragments/FragmentStd';
import FragmentSMan from '../fragments/FragmentSMan';
import FragmentFac from '../fragments/FragmentFac';
import FragmentMan from '../fragments/FragmentMan';

export default function PageAdminHome() {

    const [aPath, setAPath] = useRecoilState(adminPathAtom);

    useEffect(() => {
        if (aPath.sub == '') {
            setAPath({ main: 'stdlog', sub: 'sa1' });
        }
    });

    return (
        <div className='w-full h-screen overflow-hidden flex'>
            <UINavigationBar />
            {(() => {
                switch (aPath['main']) {
                    case 'stdlog':
                        return (<FragmentStd />);
                    case 'stdman':
                        return (<FragmentSMan />);
                    case 'faclog':
                        return (<FragmentFac />);
                    case 'facman':
                        return (<FragmentMan />);
                    default:
                        return (
                            <div className="w-full h-full text-5xl flex items-center justify-center" style={{ height: "80vh" }}>
                                Coming Soon
                            </div>
                        );
                }
            })()}
        </div>
    );
}
