import React, { useState, useEffect } from 'react';

import { Scrollbars } from 'react-custom-scrollbars';
import { RangeDatePicker } from 'react-google-flight-datepicker';
import ReactPaginate from 'react-paginate';
import { useAlert } from 'react-alert';

import Moment from 'moment';

import 'react-google-flight-datepicker/dist/main.css';

import ServiceLog from '../services/serviceLog';

export default function FragmentFac() {

    const [sdate, setSDate] = useState(Moment().startOf('month').subtract('months', 6).toDate());
    const [edate, setEDate] = useState(Moment().toDate());

    const [logs, setLogs] = useState([]);
    const [logsList, setLogsList] = useState([]);

    const [query, setQuery] = useState('');

    const [itemOffset, setItemOffset] = useState(0);
    const [pagecount, setPagecount] = useState(0);

    const alert = useAlert();

    const logService = new ServiceLog();

    const handleOnChangeSearch = (e) => {
        var { value, name } = e.target;
        setQuery(value);
        if (value.length > 0) {
            let list = logsList.filter((u) => {
                return u.username.toLowerCase().includes(value.toLowerCase())
            });
            setLogs(list);
        } else {
            setLogs(logsList);
        }
    }

    const onDateChange = (start, end) => {
        setSDate(start);
        setEDate(end);
    }

    const loadData = () => {
        (async function () {
            let body = {
                'role': 'faculty',
                'createdAt': {
                    '$gte': sdate,
                    '$lt': edate
                }
            };
            var res = await logService.getAllLogs(body, '');
            if (res['status']) {
                const endOffset = itemOffset + 10;
                const currentItems = res.data.slice(itemOffset, endOffset);
                setPagecount(Math.ceil(res.data.length / 10));
                setLogs(currentItems);
                setLogsList(res.data);
            } else {
                alert.show('Server error please try again', { type: 'error' });
            }
        })();
    }

    const handlePageClick = (event) => {
        const newOffset = (event.selected * 10) % logsList.length;
        setItemOffset(newOffset);
        const endOffset = newOffset + 10;
        const currentItems = logsList.slice(newOffset, endOffset);
        setPagecount(Math.ceil(logsList.length / 10));
        setLogs(currentItems);
    };

    useEffect(() => {
        loadData();
    }, []);

    return (
        <div className="w-full h-full py-3 px-3">
            <Scrollbars>
                <div className="grid grid-cols-12 grid-gap-2">
                    <div className="col-span-12 mt-2">
                        <div class="intro-y flex items-center justify-between h-10">
                            <h2 class="text-4xl font-medium truncate ml-2">
                                Faculty Logs
                            </h2>
                        </div>
                    </div>
                    <hr />
                    <div className="col-span-12 mt-4 px-2">
                        <div className="w-full shadow-md rounded-md border">
                            <table className="min-w-full divide-y divide-gray-200 px-4">
                                <thead className="bg-gray-50">
                                    <tr className=''>
                                        <th scope="col" className="border-b border-gray-200" colSpan={5}>
                                            <div className='w-full grid grid-cols-12 gap-2'>
                                                <div className="items-center py-2 col-span-11 px-2 grid grid-cols-12 gap-2">
                                                    <div className='col-span-4'>
                                                        <RangeDatePicker
                                                            startDate={sdate}
                                                            endDate={edate}
                                                            minDate={new Date(2022, 11, 1)}
                                                            maxDate={Moment().toDate()}
                                                            disabled={false}
                                                            onCloseCalendar={(e) => {
                                                                loadData();
                                                            }}
                                                            onChange={(startDate, endDate) => onDateChange(startDate, endDate)}
                                                            className=""
                                                            startWeekDay="monday"
                                                        />
                                                    </div>
                                                    <div className='w-full flex col-span-8 items-center'>
                                                        <div className='w-[140px]'>
                                                            <p>Search :</p>
                                                        </div>
                                                        <input type="text" value={query} onChange={handleOnChangeSearch} name='search' placeholder="Search by logs." className="w-full h-10 px-4 font-sans text-sm outline-none rounded border" />
                                                        <button className='bg-blue-600 p-4 text-white font-normal ml-6 text-xl flex h-10 items-center rounded'><i className="las la-search mr-2"></i>Search</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </th>
                                    </tr>
                                    <tr>
                                        <th scope="col" className="px-6 py-3 text-lg font-medium text-gray-500 uppercase tracking-wider cursor-pointer" style={{ textAlign: 'left !important' }}>
                                            Log ID
                                        </th>
                                        <th scope="col" className="px-6 py-3 text-lg font-medium text-gray-500 uppercase tracking-wider cursor-pointer" style={{ textAlign: 'left !important' }}>
                                            Log Date
                                        </th>
                                        <th scope="col" className="px-6 py-3 text-lg font-medium text-gray-500 uppercase tracking-wider cursor-pointer" style={{ textAlign: 'left !important' }}>
                                            USER ID
                                        </th>
                                        <th scope="col" className="px-6 py-3 text-lg font-medium text-gray-500 uppercase tracking-wider cursor-pointer" style={{ textAlign: 'left !important' }}>
                                            Log Message
                                        </th>
                                        <th scope="col" className="px-6 py-3 text-lg font-medium text-gray-500 uppercase tracking-wider cursor-pointer" style={{ textAlign: 'left !important' }}>
                                            Status
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className="bg-white divide-y divide-gray-200">
                                    {
                                        logs.length === 0 && <tr className="cursor-pointer hover:bg-gray-100">
                                            <td className="px-6 py-4 whitespace-nowrap" colSpan="5">
                                                <div className="text-sm text-blue-600 text-center">
                                                    No data found.
                                                </div>
                                            </td>
                                        </tr>
                                    }
                                    {
                                        logs.length !== 0 && logs.map((log) => {
                                            return <tr className="hover:bg-gray-100">
                                                <td className="px-6 py-4 whitespace-nowrap">
                                                    <div className="text-sm font-bold text-gray-900">
                                                        {log.logid}
                                                    </div>
                                                </td>
                                                <td className="px-6 py-4 whitespace-nowrap">
                                                    <div className="text-sm font-bold text-gray-900">
                                                        {log.createdAt}
                                                    </div>
                                                </td>
                                                <td className="px-6 py-4 whitespace-nowrap">
                                                    <div className="text-sm font-bold text-gray-900">
                                                        <span>{log.username}</span><br />
                                                        <span>User ID: {log.userid}</span>
                                                    </div>
                                                </td>
                                                <td className="px-6 py-4 whitespace-nowrap">
                                                    <div className="text-sm font-bold text-gray-900">
                                                        {log.log}
                                                    </div>
                                                </td>
                                                <td className="px-6 py-4 whitespace-nowrap">
                                                    <div className="text-sm font-bold text-gray-900">
                                                        <span className='bg-green-100 text-green-600 px-2 py-1 rounded-full'>{log.status}</span>
                                                    </div>
                                                </td>
                                            </tr>
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <ReactPaginate
                    breakLabel="..."
                    nextLabel=">"
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={5}
                    pageCount={pagecount}
                    previousLabel="<"
                    renderOnZeroPageCount={null}
                    className="flex mt-4 list-none"
                    pageLinkClassName="mx-2"
                />
            </Scrollbars>
        </div>
    );
}
