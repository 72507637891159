import React from 'react';

import { useRecoilState } from 'recoil';
import { redirectAtom } from '../../config/atoms';

import '../../../assets/styles/app.css';

export default function DigRedirect() {

    const [redirect, setRedirect] = useRecoilState(redirectAtom);

    const gotoLink = () => {
        window.open(redirect.url);
        setRedirect({ url: '', isToggle: false });
    }

    const cancel = () => {
        setRedirect({ url: '', isToggle: false });
    }

    return (
        <div className='w-full h-full overflow-hidden fixed z-50 flex items-center justify-center bg-black bg-opacity-60'>
            <div id="confirmDlg" class="modal fade fade-scale in" role="dialog" style={{ display: 'block', paddingRight: '17px' }}>
                <div class="modal-dialog sweet">
                    <div class="modal-content">
                        <div class="modal-body">
                            <div class="m-icon m-warning pulseWarning">
                                <span class="micon-body pulseWarningIns"></span>
                                <span class="micon-dot pulseWarningIns"></span>
                            </div>
                            <h2>Warning</h2>
                            <p>You are being redirected to the actual GST Portal, for any actions on the portal ITAA will not be responsible. Do you still want to continue ?</p>
                        </div>
                        <div class="modal-footer">
                            <a class="btn btn-default" onClick={() => { gotoLink() }}>YES</a>
                            <a class="btn btn-primary" onClick={() => { cancel() }}>NO</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
