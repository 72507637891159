import APIServiceLookUp from './serviceAPI';
import { API_URL } from './models/AppConstent';

export default class ServiceLMS {
    lookupService = new APIServiceLookUp();

    async lsmLogin(body) {
        var serviceType = "POST";
        var url = `${API_URL}lmsAPI/login`;
        var token = '';
        var apiService = this.lookupService.getAPIService(serviceType);
        var response = await apiService.doCall(url, body, token);
        return response;
    }

    async getCourse(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}lmsAPI/enrollment`;
        var token = '';
        var apiService = this.lookupService.getAPIService(serviceType);
        var response = await apiService.doCall(url, body, token);
        return response;
    }

    async login(body) {
        var serviceType = "POST";
        var url = `${API_URL}lms/login`;
        var token = '';
        var apiService = this.lookupService.getAPIService(serviceType);
        var response = await apiService.doCall(url, body, token);
        return response;
    }

    async checkLogin(body) {
        var serviceType = "POST";
        var url = `${API_URL}lms/checkLogin`;
        var token = '';
        var apiService = this.lookupService.getAPIService(serviceType);
        var response = await apiService.doCall(url, body, token);
        return response;
    }

    async adminLogin(body) {
        var serviceType = "POST";
        var url = `${API_URL}lms/adminLogin`;
        var token = '';
        var apiService = this.lookupService.getAPIService(serviceType);
        var response = await apiService.doCall(url, body, token);
        return response;
    }

    async getLMSUserByID(body) {
        var serviceType = "POST";
        var url = `${API_URL}lms/getLMSUserByID`;
        var token = '';
        var apiService = this.lookupService.getAPIService(serviceType);
        var response = await apiService.doCall(url, body, token);
        return response;
    }

    async getAllLMSUser(body) {
        var serviceType = "POST";
        var url = `${API_URL}lms/getAllLMSUser`;
        var token = '';
        var apiService = this.lookupService.getAPIService(serviceType);
        var response = await apiService.doCall(url, body, token);
        return response;
    }

    async addLMSUser(body) {
        var serviceType = "POST";
        var url = `${API_URL}lms/addLMSUser`;
        var token = '';
        var apiService = this.lookupService.getAPIService(serviceType);
        var response = await apiService.doCall(url, body, token);
        return response;
    }

    async addLMSStudent(body) {
        var serviceType = "POST";
        var url = `${API_URL}lms/addLMSStudent`;
        var token = '';
        var apiService = this.lookupService.getAPIService(serviceType);
        var response = await apiService.doCall(url, body, token);
        return response;
    }

    async updateLMSUser(body) {
        var serviceType = "POST";
        var url = `${API_URL}lms/updateLMSUser`;
        var token = '';
        var apiService = this.lookupService.getAPIService(serviceType);
        var response = await apiService.doCall(url, body, token);
        return response;
    }

    async deleteUser(body) {
        var serviceType = "POST";
        var url = `${API_URL}lms/deleteUser`;
        var token = '';
        var apiService = this.lookupService.getAPIService(serviceType);
        var response = await apiService.doCall(url, body, token);
        return response;
    }

    async sendNorification(body) {
        var serviceType = "POST";
        var url = `${API_URL}misc/sendEmail`;
        var token = '';
        var apiService = this.lookupService.getAPIService(serviceType);
        var response = await apiService.doCall(url, body, token);
        return response;
    }

}