import React, { useState, useEffect } from 'react';

import { useRecoilState } from 'recoil';
import { trnAtom, pathAtom, loadingAtom, trnLevelAtom, digErrorAtom, userIDAtom, userAtom } from '../core/config/atoms';

import ServiceAuth from '../services/serviceAuth';
import ServiceOption from '../services/serviceOptions';
import ServiceUtility from '../services/serviceUtility';

import { Dialog } from '@headlessui/react';

export default function ComponentTRNLevelG() {

    const [path, setPath] = useRecoilState(pathAtom);
    const [loading, setLoading] = useRecoilState(loadingAtom);
    const [trnLevel, SetTRNLevel] = useRecoilState(trnLevelAtom);
    const [trnno] = useRecoilState(trnAtom);
    const [digError, setDigError] = useRecoilState(digErrorAtom);
    const [userID] = useRecoilState(userIDAtom);
    const [userAuth] = useRecoilState(userAtom);

    const [gstUser, setGSTUser] = useState({});
    const [digConfirm, setDigConfirm] = useState({ 'type': '0', isToggle: false, data: null });

    const [hsns, setHsns] = useState([]);
    const [sacs, setSacs] = useState([]);

    const [fullHsn, setFullHsn] = useState([]);
    const [topHsn, setTopHsn] = useState([]);

    const [fullSac, setFullSac] = useState([]);
    const [topSac, setTopSac] = useState([]);

    const [query, setQuery] = useState("");

    const [isHsn, setIsHsn] = useState(true);
    const [isSearching, setIsSearching] = useState(false);
    const [errorDig, setErrorDig] = useState(false);

    const authService = new ServiceAuth();
    const utilService = new ServiceUtility();
    const optionsService = new ServiceOption();

    const addHsn = (item) => {
        setQuery("");
        setIsSearching(false);
        setTopHsn([]);
        setHsns([...hsns, item]);
    }

    const deleteHsn = (index) => {
        let data = [...hsns];
        if (data.length === 1) {
            setHsns([]);
            if (sacs.length == 0) {
                resetData();
            }
        } else {
            data.splice(index, 1);
            setHsns(data);
        }
    }

    const addSac = (item) => {
        setQuery("");
        setIsSearching(false);
        setTopSac([]);
        setSacs([...sacs, item]);
    }

    const deleteSac = (index) => {
        let data = [...sacs];
        if (data.length === 1) {
            setSacs([]);
            if (hsns.length == 0) {
                resetData();
            }
        } else {
            data.splice(index, 1);
            setSacs(data);
        }
        if (sacs.length == 0 && hsns.length == 0) {
            resetData();
        }
    }

    const handleOnChangeForm = (e) => {
        var { value, name } = e.target;
        if (value.length >= 2) {
            setIsSearching(true);
        } else {
            setIsSearching(false);
        }
        switch (name) {
            case 'gs_hsn':
                console.log(value);
                if (value.length >= 2) {
                    setTopHsn([]);
                    const queryRegex = RegExp(
                        /^[0-9]*$/
                    );
                    let data = [];
                    console.log("Done HSN Here");
                    if (queryRegex.test(value)) {
                        data = [...fullHsn.filter(item =>
                            item.title.toLowerCase().includes(value.toLowerCase())
                        )];
                    } else {
                        data = [...fullHsn.filter(item =>
                            item.description.toLowerCase().includes(value.toLowerCase())
                        )];
                    }
                    setTopHsn(data);
                    setIsSearching(false);
                }
                break;
            case 'gs_ssn':
                if (value.length >= 2) {
                    setTopSac([]);
                    let data = [...fullSac.filter(item =>
                        item.title.toLowerCase().includes(value.toLowerCase()) || item.description.toLowerCase().includes(value.toLowerCase())
                    )];
                    setTopSac(data.slice(0, 5));
                    setIsSearching(false);
                }
                break;
        }
        setQuery(value);
    }

    const handeleSubmitLevel = (e) => {
        e.preventDefault();
        if (hsns.length <= 0 && sacs <= 0) {
            setErrorDig(true);
        } else {
            setLoading(true);
            let data = [...trnLevel.form];
            data[6] = "1";
            let body = {
                hsns: hsns,
                sacs: sacs,
                trnno: trnno,
                lid: userID,
                statusArr: data,
                user: userAuth,
            }
            authService.setGSTUserFormG(body).then((res) => {
                if (res.statuscode === 200) {
                    SetTRNLevel({ ...trnLevel, level: 'sa8' });
                } else {
                    setDigError({ title: 'Error', msg: 'Server error please try again!', isToggle: true });
                }
                setLoading(false);
            });
        }
    }

    const showDig = (type, data) => {
        setDigConfirm({ 'type': type, isToggle: true, data: data });
    }

    const cancel = () => {
        setDigConfirm({ 'type': '0', isToggle: false });
    }

    const process = () => {
        switch (digConfirm.type) {
            case '1':
                deleteHsn(digConfirm.data);
                break;
            case '2':
                deleteSac(digConfirm.data);
                break;
            default:
                break;

        }
        setDigConfirm({ 'type': '0', isToggle: false, data: null });
    }

    const gotoPath = (path) => {
        SetTRNLevel({ ...trnLevel, level: path });
    }

    const resetData = () => {
        setLoading(true);
        let data = [...trnLevel.form];
        data[6] = "0";
        let body = {
            hsns: hsns,
            sacs: sacs,
            trnno: trnno,
            lid: userID,
            statusArr: data,
            user: userAuth,
        }
        authService.setGSTUserFormG(body).then((res) => {
            if (res.statuscode === 200) {
                loadStatus();
            } else {
                setDigError({ title: 'Error', msg: 'Server error please try again!', isToggle: true });
                setLoading(false);
            }
        });
    }

    const loadStatus = () => {
        (async function () {
            var res = await authService.getGSTUserByTRN({ 'trn': trnno, 'stdOwn': userID });
            SetTRNLevel({ ...trnLevel, form: res.data[0]['statusArr'], percentage: utilService.calculatePercentage(res.data[0]['statusArr']) });
            setLoading(false);
        })();
    }

    const loadData = () => {
        (async function () {
            setLoading(true);
            var res = await authService.getGSTUserByTRN({ 'trn': trnno, 'stdOwn': userID });
            setGSTUser(res.data[0]);

            SetTRNLevel({ ...trnLevel, form: res.data[0]['statusArr'], percentage: utilService.calculatePercentage(res.data[0]['statusArr']) });

            if (res.data[0]['statusArr'][6] === '1') {
                setHsns(res.data[0]['hsns']);
                setSacs(res.data[0]['sacs']);
            }

            let body = {};

            var resB = await optionsService.getFillterHsn(body);
            setFullHsn(resB.data);

            var resA = await optionsService.getFillterSac(body);
            setFullSac(resA.data);

            setLoading(false);
            window.scrollTo({
                top: 0,
                behavior: 'smooth',
            });
        })();
    }

    useEffect(() => {
        loadData();
    }, []);

    return (
        <form class="tabpane" name="newRegForm" id="newRegForm" autocomplete="off" noValidate="" onSubmit={handeleSubmitLevel}>
            <Dialog open={errorDig} onClose={() => setErrorDig(false)}>
                <div id="confirmDlg" class="modal fade fade-scale in" role="dialog" style={{ display: 'block', paddingRight: '17px' }}>
                    <div class="modal-dialog sweet">
                        <div class="modal-content">
                            <div class="modal-body">
                                <div class="m-icon m-error">
                                    <span class="x-mark">
                                        <span class="m-line m-left">
                                        </span>
                                        <span class="m-line m-right">
                                        </span>
                                    </span>
                                </div>
                                <h2>Error</h2>
                                <p>Atleast one is mandatory.</p>
                            </div>
                            <div class="modal-footer">
                                <a class="btn btn-default" onClick={() => { setErrorDig(false) }}>Close</a>
                            </div>
                        </div>
                    </div>
                </div>
            </Dialog>
            <Dialog open={digConfirm.isToggle} onClose={() => setDigConfirm({ 'type': '0', isToggle: false, data: null })}>
                <div id="confirmDlg" class="modal fade fade-scale in" role="dialog" style={{ display: 'block', paddingRight: '17px' }}>
                    <div class="modal-dialog sweet">
                        <div class="modal-content">
                            <div class="modal-body">
                                <div class="m-icon m-warning pulseWarning">
                                    <span class="micon-body pulseWarningIns"></span>
                                    <span class="micon-dot pulseWarningIns"></span>
                                </div>
                                <h2>Warning</h2>
                                <p>Are you sure, you want to continue?</p>
                            </div>
                            <div class="modal-footer">
                                <a class="btn btn-default" onClick={() => { cancel() }}>Cancel</a>
                                <a class="btn btn-primary" onClick={() => { process() }}>Proceed</a>
                            </div>
                        </div>
                    </div>
                </div>
            </Dialog>
            <ul class="nav nav-tabs tp-tabs">
                <li class={`${isHsn ? 'active' : ''}`} onClick={() => {
                    setQuery("");
                    setIsSearching(false);
                    setIsHsn(true);
                }}><a data-show-tab="">Goods</a></li>
                <li class={`${!isHsn ? 'active' : ''}`} onClick={() => {
                    setQuery("");
                    setIsSearching(false);
                    setIsHsn(false);
                }}><a data-show-tab="">Services</a></li>
            </ul>
            <div class="tab-content">
                {
                    isHsn && <div id="goods" class={`tab-pane fade ${isHsn ? 'active in' : ''}`}>
                        <h4>Details of Goods / Commodities supplied by the business</h4>
                        <hr />
                        <p class="bold">Please specify top 5 Commodities</p>
                        <fieldset class="page-format">
                            <div class="row">
                                <div class="col-xs-12" id="add_search">
                                    <fieldset>
                                        <div class="row">
                                            <div class="col-sm-6 col-xs-12">
                                                <label for="" class="reg">Search HSN Chapter by Name or Code</label>
                                                <div autocomplete-dropdown="" id="gs_hsn" input-name="gs_hsn" placeholder="Search HSN Chapter" pause="500" minlength={2} >
                                                    <div class="autocomplete-holder">
                                                        <input value={query} id="gs_hsn" name="gs_hsn" type="text" placeholder="Search HSN Chapter" maxLength={10} class="form-control" autocapitalize="off" autocorrect="off" autocomplete="off" onChange={handleOnChangeForm} />
                                                        {
                                                            query.length >= '2' && <div id="gs_hsn" class="autocomplete-dropdown">
                                                                {isSearching && <div class="autocomplete-searching">Searching..</div>}
                                                                <div style={{ maxHeight: '360px', overflow: 'scroll' }}>
                                                                    {
                                                                        topHsn.length >= 1 && topHsn.map((item) => {
                                                                            return <div class="text-black p-2 hover:bg-blue-200" onClick={() => addHsn(item)} key={item.title}>{item.title} - {item.description}</div>
                                                                        })
                                                                    }
                                                                </div>
                                                                {!isSearching && topHsn.length === 0 && <div class="autocomplete-searching">No Results Found</div>}
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </fieldset>
                                </div>
                            </div>
                            {
                                hsns.length !== 0 && <div class="">
                                    <h4>List of Goods</h4>
                                    <div class="table-responsove">
                                        <table class="table table-striped" style={{ display: 'table' }}>
                                            <thead>
                                                <tr>
                                                    <th>Sl No</th>
                                                    <th>HSN Code</th>
                                                    <th>Description of Goods</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    hsns.map((item, index) => {
                                                        return <tr key={index}>
                                                            <td>{index + 1}</td>
                                                            <td>{item.title}</td>
                                                            <td>{item.description}</td>
                                                            <td><button type="button" class="btn btn-sm btn-danger" onClick={() => { showDig('1', index) }}>
                                                                <i class="fa fa-trash"></i> Delete </button>
                                                            </td>
                                                        </tr>
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            }
                        </fieldset>
                    </div >
                }
                {
                    !isHsn && <div id="services" class={`tab-pane fade ${isHsn ? '' : 'active in'}`}>
                        <h4>Details of Services offered by the Business</h4>
                        <hr />
                        <p class="bold">Please specify top 5 Services</p>
                        <fieldset class="page-format">
                            <div class="row">
                                <div class="col-xs-12" id="add_search_serv">
                                    <fieldset>
                                        <div class="row">
                                            <div class="col-sm-6 col-xs-12">
                                                <label for="" class="reg">Search by Name or Code</label>
                                                <div autocomplete-dropdown="" id="gs_ssn" input-name="gs_ssn" placeholder="Search Service Classification Code" pause="500" minlength={2}>
                                                    <div class="autocomplete-holder">
                                                        <input id="gs_ssn" name="gs_ssn" type="text" placeholder="Search Service Classification Code" maxLength={10} class="form-control" autocapitalize="off" autocorrect="off" autocomplete="off" onChange={handleOnChangeForm} />
                                                        {
                                                            query.length >= '2' && <div id="gs_ssn" class="autocomplete-dropdown">
                                                                {isSearching && <div class="autocomplete-searching">Searching..</div>}
                                                                {
                                                                    topSac.length >= 1 && topSac.map((item) => {
                                                                        return <div class="text-black p-2 hover:bg-blue-200" onClick={() => addSac(item)} key={item.title}>{item.title} - {item.description}</div>
                                                                    })
                                                                }
                                                                {!isSearching && topSac.length === 0 && <div class="autocomplete-searching">No Results Found</div>}
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </fieldset>
                                </div>
                            </div>
                            {
                                sacs.length !== 0 && <div class="">
                                    <h4>List of Services</h4>
                                    <div class="table-responsive">
                                        <table class="table" style={{ display: 'table' }}>
                                            <thead>
                                                <tr>
                                                    <th>Sl No</th>
                                                    <th>Service Classification Code</th>
                                                    <th>Description of Services</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    sacs.map((item, index) => {
                                                        return <tr key={index}>
                                                            <td>{index + 1}</td>
                                                            <td>{item.title}</td>
                                                            <td>{item.description}</td>
                                                            <td><button type="button" class="btn btn-sm btn-danger" onClick={() => { showDig('2', index) }}>
                                                                <i class="fa fa-trash"></i> Delete </button>
                                                            </td>
                                                        </tr>
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            }
                        </fieldset>
                    </div>
                }
            </div >
            <div class="row next-tab-nav">
                <div class="col-xs-12 text-right">
                    <a class="btn btn-default" style={{ marginRight: '8px' }} onClick={() => {
                        gotoPath('sa6');
                    }}>Back</a>
                    <button title="Save &amp; Continue" type="submit" class="btn btn-primary">Save &amp; Continue</button>
                </div>
            </div>
        </form >
    );
}
