import React, { useEffect } from 'react';

import { useRecoilState } from 'recoil';
import { trnAtom, pathAtom, loadingAtom, trnLevelAtom, userIDAtom } from '../core/config/atoms';

import ServiceAuth from '../services/serviceAuth';
import ServiceUtility from '../services/serviceUtility';

export default function ComponentTRNLevelD() {

    const [path, setPath] = useRecoilState(pathAtom);
    const [loading, setLoading] = useRecoilState(loadingAtom);
    const [trnLevel, SetTRNLevel] = useRecoilState(trnLevelAtom);
    const [trnno] = useRecoilState(trnAtom);
    const [userID] = useRecoilState(userIDAtom);

    const authService = new ServiceAuth();
    const utilService = new ServiceUtility();

    const gotoTab = (path) => {
        SetTRNLevel({ ...trnLevel, level: path });
    }

    const loadData = () => {
        (async function () {
            setLoading(true);
            var res = await authService.getGSTUserByTRN({ 'trn': trnno, 'stdOwn': userID });
            SetTRNLevel({ ...trnLevel, form: res.data[0]['statusArr'], percentage: utilService.calculatePercentage(res.data[0]['statusArr']) });
            setLoading(false);
            window.scrollTo({
                top: 0,
                behavior: 'smooth',
            });
        })();
    }

    useEffect(() => {
        loadData();
    }, []);

    return (
        <form class="tabpane" name="newRegForm" id="newRegForm" autocomplete="off" novalidate="">
            <h4><span>Details of Additional Places of your Business</span></h4>
            <div>
                <div class="row">
                    <div class="col-xs-12">
                        <div class="alert alert-warning">
                            <i class="fa fa-info-circle"></i>
                            <strong>&nbsp;This Process cannot be covered in this simulation. Kindly continue the process.</strong>
                        </div>
                    </div>
                </div>
                <div class="row next-tab-nav">
                    <div class="col-xs-12 text-right ">
                        <a class="btn btn-default" onClick={() => {
                            gotoTab('sa5');
                        }} style={{ marginRight: '8px' }}>Back</a>
                        <button type="button" class="btn btn-primary" disabled="disabled" style={{ marginRight: '8px' }}>Add New</button>
                        <button type="button" class="btn btn-primary" onClick={() => {
                            gotoTab('sa7');
                        }}>Continue</button>
                    </div>
                </div>
            </div>
        </form>
    )
}
