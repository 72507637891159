import React from 'react';

import { useRecoilState } from 'recoil';
import { digDownloadAtom } from '../../config/atoms';

import '../../../assets/styles/app.css';

export default function DigDownload() {

    const [digDownload, setDigDownload] = useRecoilState(digDownloadAtom);

    const download = () => {
        const link = document.createElement('a');
        link.href = digDownload.url;
        link.download = "GSTR-2B_Advisory.pdf";
        link.target = '_blank';
        link.rel = 'noopener noreferrer';
        link.click();
        link.remove();
        setDigDownload({ title: '', url: '', isToggle: false });
    }

    const cancel = () => {
        setDigDownload({ title: '', url: '', isToggle: false });
    }

    return (
        <div className='w-full h-full overflow-hidden fixed z-50 flex items-center justify-center bg-black bg-opacity-60'>
            <div id="confirmDlg" class="modal fade fade-scale in" role="dialog" style={{ display: 'block', paddingRight: '17px' }}>
                <div class="modal-dialog sweet">
                    <div class="modal-content">
                        <div class="modal-body">
                            <div class="m-icon m-warning pulseWarning">
                                <span class="micon-body pulseWarningIns"></span>
                                <span class="micon-dot pulseWarningIns"></span>
                            </div>
                            <h2>Warning</h2>
                            <p>{digDownload.title}</p>
                        </div>
                        <div class="modal-footer">
                            <a class="btn btn-default" onClick={() => { download() }}>YES</a>
                            <a class="btn btn-primary" onClick={() => { cancel() }}>NO</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
