import React, { useState, useEffect } from 'react';

import { useRecoilState } from 'recoil';
import { loadingAtom, pathAtom, gstrpathAtom, digErrorAtom, gstrUserAtom, returnDateAtom, gstrQueryAtom, userIDAtom } from '../../../core/config/atoms';

import Moment from 'moment';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import imgLoader from '../../../assets/images/cloading.gif';

import ServiceGSTR from '../../../services/serviceGSTR';
import ServiceUtility from '../../../services/serviceUtility';
import ServiceOption from '../../../services/serviceOptions';

export default function PanelAA11Form() {

    const [path, setPath] = useRecoilState(pathAtom);
    const [gstrPath, setGSTRPath] = useRecoilState(gstrpathAtom);
    const [loading, setLoading] = useRecoilState(loadingAtom);
    const [gDate] = useRecoilState(returnDateAtom);
    const [digError, setDigError] = useRecoilState(digErrorAtom);
    const [GSTUser] = useRecoilState(gstrUserAtom);
    const [query, setQuery] = useRecoilState(gstrQueryAtom);
    const [userID] = useRecoilState(userIDAtom);

    const [states, setStates] = useState([]);
    const [OldData, setOldData] = useState({});

    const [editid, setEditid] = useState('');
    const [amid, setAmid] = useState('');
    const [pos, setPos] = useState('');
    const [isErrorAlert, setIsErrorAlert] = useState("");

    const [isEdit, setIsEdit] = useState(false);
    const [isView, setIsView] = useState(false);
    const [submit, setSubmit] = useState(false);
    const [isAlert, setIsAlrt] = useState(false);
    const [isItem, setIsItem] = useState(false);
    const [isItems, setIsItems] = useState(false);

    const returnService = new ServiceGSTR();
    const utilService = new ServiceUtility();
    const optionService = new ServiceOption();

    const formVSchema = Yup.object().shape({
        pos: Yup.string().required('This information is required'),
        supplyty: Yup.string().required('This information is required'),
    });

    const { values, errors, touched, setValues, handleChange, handleSubmit, handleReset, resetForm } = useFormik({
        initialValues: {
            pos: '',
            supplyty: '',
            year: '',
            month: '',
            oid: '',
            error: '',
            items: {
                a: {
                    total: '',
                    taxa: '',
                    taxb: '',
                    cess: '',
                },
                b: {
                    total: '',
                    taxa: '',
                    taxb: '',
                    cess: '',
                },
                c: {
                    total: '',
                    taxa: '',
                    taxb: '',
                    cess: '',
                },
                d: {
                    total: '',
                    taxa: '',
                    taxb: '',
                    cess: '',
                },
                e: {
                    total: '',
                    taxa: '',
                    taxb: '',
                    cess: '',
                },
                f: {
                    total: '',
                    taxa: '',
                    taxb: '',
                    cess: '',
                },
                g: {
                    total: '',
                    taxa: '',
                    taxb: '',
                    cess: '',
                },
                h: {
                    total: '',
                    taxa: '',
                    taxb: '',
                    cess: '',
                },
                i: {
                    total: '',
                    taxa: '',
                    taxb: '',
                    cess: '',
                },
                j: {
                    total: '',
                    taxa: '',
                    taxb: '',
                    cess: '',
                },
                k: {
                    total: '',
                    taxa: '',
                    taxb: '',
                    cess: '',
                },
                l: {
                    total: '',
                    taxa: '',
                    taxb: '',
                    cess: '',
                }

            }
        },
        validationSchema: formVSchema,
        onSubmit: values => {
            if (checkItems()) {
                setIsItems(false);
                let body = {};
                setSubmit(true);
                if (isEdit) {
                    values.status = 'EDIT';
                    body = {
                        id: editid,
                        data: values,
                    }
                    returnService.updateGstrDoc(body).then((res) => {
                        if (res.status) {
                            setQuery({ type: 'done', data: 'done' });
                            gotoGstrPath('aa11a');
                        } else {
                            setDigError({ title: 'Error', msg: 'Server error please try again!', isToggle: true });
                        }
                        setSubmit(false);
                    });
                } else {
                    values.status = 'ADD';
                    body = {
                        month: gDate,
                        lid: userID,
                        type: 'a11a',
                        oid: amid,
                        data: values,
                        odata: OldData,
                    }
                    returnService.setGstrDoc(body).then((res) => {
                        if (res.status) {
                            if (res.statuscode === 202) {
                                setPos(values.pos);
                            } else {
                                setQuery({ type: 'done', data: 'done' });
                                gotoGstrPath('aa11a');
                            }
                        } else {
                            setDigError({ title: 'Error', msg: 'Server error please try again!', isToggle: true });
                        }
                        setSubmit(false);
                        window.scrollTo({
                            top: 0,
                            behavior: 'smooth',
                        });
                    });
                }
            } else {
                setIsItems(true);
            }
        }
    });

    const checkItems = () => {
        return utilService.getTotalValue(values.items) === 0 ? false : true;
    }

    const gotoPath = (path) => {
        setLoading(true);
        setPath(path);
    }

    const gotoGstrPath = (path) => {
        setGSTRPath(path);
        gotoPath('gstrFile');
    }

    const getFormatvalue = (val) => {
        if (!val) return val;
        let number = val.replace(/[^\d.]/g, "");
        if (val.includes(".")) {
            const a = number.split(".");
            if (a[1].length > 2) {
                a[1] = a[1].substring(0, 2);
            }
            if (a[0].length > 11) {
                a[0] = a[0].substring(0, 11);
            }
            number = a[0] + "." + a[1];
        } else {
            if (number.length > 11) {
                number = number.substring(0, 11);
            }
        }
        return number;
    }

    const getFYear = (y) => {
        let partA = Moment(y).format('yyyy');
        let partB = Moment(y).add(1, 'year').format('yy');
        return partA + " - " + partB;
    }

    const loadData = () => {
        (async function () {
            var resB = await optionService.getStates({});
            setStates(resB.data);
            setAmid(query.data.id);
            let year = query.data.year;
            let mon = query.data.month;
            let pos = query.data.pos;

            if (query.type === 'amend') {
                let body = {
                    id: query.data.id
                };
                var doc = await returnService.getGstrDocById(body);
                let val = { ...values, ...doc.data.data, oid: query.data.id };
                val.year = year;
                val.month = mon;
                setValues(val);
                const odata = JSON.parse(JSON.stringify(val));
                setOldData(odata);
                setIsItem(true);
                setQuery({ type: '', data: '' });
            } else if (query.type === 'new') {
                let val = { ...values };
                val.year = year;
                val.month = mon;
                val.pos = pos;
                if (pos === GSTUser.state) {
                    val.supplyty = "Intra-state";
                } else {
                    val.supplyty = "Inter-state";
                }
                setValues(val);
                const odata = JSON.parse(JSON.stringify(val));
                setOldData(odata);
                setIsItem(true);
                setQuery({ type: '', data: '' });
            } else if (query.type === 'edit') {
                let body = {
                    id: query.data
                };
                var doc = await returnService.getGstrDocById(body);
                setValues({ ...values, ...doc.data.data });
                setIsItem(true);
                setIsEdit(true);
                setIsView(false);
                if (doc.data.status === 'pending') {
                    setIsErrorAlert(true);
                } else {
                    setIsErrorAlert(false);
                }
                setEditid(doc.data._id);
                setQuery({ type: '', data: '' });
            }

            setLoading(false);
            window.scrollTo({
                top: 0,
                behavior: 'smooth',
            });
        })();
    }

    const getError = () => {
        switch (values.error) {
            case "invoice":
                return "Ethier the original document no. or date is invalid or the revised document no. already exsist in database. Kindly re-check & enter correct details.";
            case "nfill":
                return "GSTR 1 for the period in which the document was originally reported has not been filed. Please file the same before amendment";
            case "date":
                return "Revised invoice/note is Invalid. Revised date cannot be later than the last day of the tax peroid when original invoice/note was uploaded. Kindly delete the record and add it again.";
            default:
                return "";
        }
    }

    useEffect(() => {
        loadData();
    }, []);

    return (
        <div class="content-wrapper fade-ng-cloak">
            <div class="dimmer-holder" style={{ display: 'none' }}>
                <div id="dimmer"></div>
            </div>
            {
                loading && <div className='absolute z-50 w-full h-screen flex items-center justify-center bg-gray-200 bg-opacity-25'>
                    <img src={imgLoader} alt="loader" />
                </div>
            }
            <div class="container">
                <div class="mypage">
                    <div class="row" data-ng-controller="transctrl" data-ng-init="init('returns')">
                        <div class="col-xs-10">
                            <div data-breadcrumb="">
                                <ol class="breadcrumb" data-ng-controller="crumbCtrl">
                                    <li onClick={() => {
                                        gotoPath('gstr')
                                    }}><a>Dashboard</a></li>
                                    <li onClick={() => {
                                        gotoPath('gstRetrun')
                                    }}><a>Returns</a></li>
                                    <li onClick={() => {
                                        gotoPath('gstrForm')
                                    }}><a>GSTR-1/IFF</a></li>
                                    <li>Amended Tax Liability (Advances Received)</li>
                                </ol>
                            </div>
                        </div>
                        <div class="col-xs-2">
                            <div class="lang dropdown">
                                <span class="dropdown-toggle" data-toggle="dropdown" data-ng-bind="selectedLang">English</span>
                                <ul class="dropdown-menu lang-dpdwn">
                                    <li data-ng-repeat="language in languages">
                                        <a data-ng-click="selectLang(language.cd)" data-ng-bind="language.nm">English</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div>
                        {isAlert && <alert-message ng-show="showMsg" title="" type="msg alert-success" message="" class="ng-hide"><div class="alert alert-msg alert-success">Request accepted successfully<a class="close" onClick={() => { setIsAlrt(false) }} data-dismiss="alert" aria-label="close">×</a><strong></strong>.</div></alert-message>}
                        {isErrorAlert && <alert-message ng-show="showMsg" title="" type="msg alert-danger" message="" class="ng-hide"><div class="alert alert-msg alert-danger"><b className='alert-msg alert-danger'>Error!</b>  {getError()}<a class="close" onClick={() => { setIsErrorAlert(false) }} data-dismiss="alert" aria-label="close">×</a><strong></strong>.</div></alert-message>}
                        <form name="atPage" autocomplete="off" novalidate="" onSubmit={handleSubmit} onReset={handleReset}>
                            <div class="row invsumm">
                                <div class="col-sm-12 col-xs-12">
                                    <h4>Tax Liability (Advance Received) - Edit Details</h4>
                                </div>
                            </div>
                            <div class="tabpane">
                                <p class="mand-text" data-ng-bind="trans.HLP_MAND_FIELD">Indicates Mandatory Fields</p>
                                <span>Note: Declare here the tax liability arising on account of receipt of consideration for which invoices have not been issued in the same tax period.</span>
                                <fieldset class="tbl-format rettbl-format" disabled={isView}>
                                    <div class="row">
                                        <div class={`col-sm-4 ${touched.pos ? errors.pos ? 'has-error' : 'has-success' : ''}`}>
                                            <label class="reg m-cir" for="pos"><span data-ng-bind="trans.LBL_POS">POS</span>
                                                <i class="fa fa-info-circle" title="Place of Supply(Only if different from location of recipient)" aria-hidden="true"></i>
                                            </label>
                                            <select name="pos" class="form-control" required="" value={values.pos} id="pos" onChange={e => {
                                                let val = e.target.value;
                                                setPos('');
                                                if (val === GSTUser.state) {
                                                    setIsItem(true);
                                                    setValues({ ...values, 'supplyty': "Intra-state", "pos": val });
                                                } else {
                                                    setIsItem(true);
                                                    setValues({ ...values, 'supplyty': "Inter-state", "pos": val });
                                                }
                                            }} disabled={true}>
                                                <option value="" selected="selected" disabled="">Select</option>
                                                {
                                                    states.length !== 0 && states.map((s) => {
                                                        return <option value={s} selected={values.pos == s}>{s}</option>
                                                    })
                                                }
                                            </select>
                                            {
                                                (errors.pos && touched.pos) && <span className='err'>{errors.pos}</span>
                                            }
                                            {pos !== "" && <span className='err'>Entry for '{pos}' already exists in the current peroid.</span>}
                                        </div>
                                        <div class={`col-sm-4 ${touched.supplyty ? errors.supplyty ? 'has-error' : 'has-success' : ''}`}>
                                            <label class="reg" for="stype">Supply Type</label>
                                            <input class="form-control ng-pristine ng-untouched ng-valid ng-not-empty" name="supplyty" id="supplyty" value={values.supplyty} readOnly={true} />
                                            {(errors.supplyty && touched.supplyty) && <span className='err'>{errors.supplyty}</span>}
                                        </div>
                                        <div class={`col-sm-4 ${touched.year ? errors.year ? 'has-error' : 'has-success' : ''}`}>
                                            <label class="reg m-cir" for="stype">Financial Year</label>
                                            <input class="form-control ng-pristine ng-untouched ng-valid ng-not-empty" name="year" id="year" value={getFYear(values.year)} readOnly={true} />
                                            {(errors.year && touched.year) && <span className='err'>{errors.year}</span>}
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class={`col-sm-4 ${touched.month ? errors.month ? 'has-error' : 'has-success' : ''}`}>
                                            <label class="reg m-cir" for="stype">Return Filling Period</label>
                                            <input class="form-control ng-pristine ng-untouched ng-valid ng-not-empty" name="month" id="month" value={(Moment(values.month).format('MMMM'))} readOnly={true} />
                                            {(errors.month && touched.month) && <span className='err'>{errors.month}</span>}
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-sm-8">
                                            <br />
                                            <input type="checkbox" class="chkbx" disabled={true} />
                                            <label for="rate_flag">Is the supply eligible to be taxed at a differential percentage (%) of the existing rate of tax, as notified by the Government?</label>
                                        </div>
                                    </div>
                                    {
                                        isItem && <div>
                                            <fieldset data-ng-disabled="work_flow || b2baview || cdnraview">
                                                <div class="rettbl-format">
                                                    <div class="row">
                                                        <div class="col-xs-12 col-sm-12">
                                                            <h4 class="no-mar" data-ng-bind="trans.HEAD_ITEM_DETAILS">Item details</h4>
                                                            {isItems && <span className='err'>Do add an item</span>}
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-sm-12">
                                                            <div class="table-responsive">
                                                                <table class="table exp inv tbl table-bordered table-responsive" style={{ display: 'table' }}>
                                                                    <thead>
                                                                        <tr>
                                                                            <th rowspan="2" class="text-center">Rate (%)</th>
                                                                            <th rowspan="2" class="text-center m-cir">Taxable value (₹)</th>
                                                                            <th colspan="3" class="text-center">Amount of Tax</th>
                                                                        </tr>
                                                                        <tr>
                                                                            {
                                                                                values.supplyty === "Inter-state" && <th class="text-center m-cir">Integrated Tax (₹)</th>
                                                                            }
                                                                            {
                                                                                values.supplyty === "Intra-state" && <>
                                                                                    <th class="text-center m-cir">State Tax (₹)</th>
                                                                                    <th class="text-center m-cir">Central Tax (₹)</th>
                                                                                </>
                                                                            }
                                                                            <th class="text-center">Cess (₹)</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <tr>
                                                                            <td class="text-center">0%</td>
                                                                            <td class="text-center">
                                                                                <input id="items.a.total" class="form-control newinv formedit currency" maxLength={16} value={values.items.a.total} onChange={e => {
                                                                                    const formatValue = getFormatvalue(e.target.value);
                                                                                    e.target.value = formatValue;
                                                                                    if (e.target.value === "") {
                                                                                        values.items.a.taxa = '';
                                                                                        values.items.a.taxb = '';
                                                                                        values.items.a.cess = '';
                                                                                    } else {
                                                                                        values.items.a.taxa = 0;
                                                                                        values.items.a.taxb = 0;
                                                                                        values.items.a.cess = '';
                                                                                    }
                                                                                    handleChange(e);
                                                                                }} onBlur={e => {
                                                                                    if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                        e.target.value = e.target.value + ".00";
                                                                                    }
                                                                                    handleChange(e);
                                                                                }} />
                                                                            </td>
                                                                            {
                                                                                values.supplyty === "Inter-state" && <td class="text-center">
                                                                                    <input id="items.a.taxa" class="form-control newinv formedit currency" maxLength={16} value={values.items.a.taxa} onChange={e => {
                                                                                        const formatValue = getFormatvalue(e.target.value);
                                                                                        e.target.value = formatValue;
                                                                                        handleChange(e);
                                                                                    }} onBlur={e => {
                                                                                        if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                            e.target.value = e.target.value + ".00";
                                                                                        }
                                                                                        handleChange(e);
                                                                                    }} disabled={values.items.a.total === ""} />
                                                                                </td>
                                                                            }
                                                                            {
                                                                                values.supplyty === "Intra-state" && <>
                                                                                    <td class="text-center">
                                                                                        <input id="items.a.taxb" class="form-control newinv formedit currency" maxLength={16} value={values.items.a.taxb} onChange={e => {
                                                                                            const formatValue = getFormatvalue(e.target.value);
                                                                                            e.target.value = formatValue;
                                                                                            handleChange(e);
                                                                                        }} onBlur={e => {
                                                                                            if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                                e.target.value = e.target.value + ".00";
                                                                                            }
                                                                                            handleChange(e);
                                                                                        }} disabled={values.items.a.total === ""} />
                                                                                    </td>
                                                                                    <td class="text-center">
                                                                                        <input id="items.a.taxb" class="form-control newinv formedit currency" maxLength={16} value={values.items.a.taxb} onChange={e => {
                                                                                            const formatValue = getFormatvalue(e.target.value);
                                                                                            e.target.value = formatValue;
                                                                                            handleChange(e);
                                                                                        }} onBlur={e => {
                                                                                            if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                                e.target.value = e.target.value + ".00";
                                                                                            }
                                                                                            handleChange(e);
                                                                                        }} disabled={values.items.a.total === ""} />
                                                                                    </td>
                                                                                </>
                                                                            }
                                                                            <td class="text-center">
                                                                                <input id="items.a.cess" class="form-control newinv formedit currency" maxLength={16} value={values.items.a.cess} onChange={e => {
                                                                                    const formatValue = getFormatvalue(e.target.value);
                                                                                    e.target.value = formatValue;
                                                                                    handleChange(e);
                                                                                }} onBlur={e => {
                                                                                    if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                        e.target.value = e.target.value + ".00";
                                                                                    }
                                                                                    handleChange(e);
                                                                                }} disabled={values.items.a.total === ""} />
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td class="text-center">0.1%</td>
                                                                            <td class="text-center">
                                                                                <input id="items.b.total" class="form-control newinv formedit currency" maxLength={16} value={values.items.b.total} onChange={e => {
                                                                                    const formatValue = getFormatvalue(e.target.value);
                                                                                    e.target.value = formatValue;
                                                                                    if (e.target.value === "") {
                                                                                        values.items.b.taxa = '';
                                                                                        values.items.b.taxb = '';
                                                                                        values.items.b.cess = '';
                                                                                    } else {
                                                                                        values.items.b.taxa = (parseFloat(e.target.value) * 0.001).toFixed(2);
                                                                                        values.items.b.taxb = ((parseFloat(e.target.value) * 0.001) / 2).toFixed(2);
                                                                                    }
                                                                                    handleChange(e);
                                                                                }} onBlur={e => {
                                                                                    if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                        e.target.value = e.target.value + ".00";
                                                                                    }
                                                                                    handleChange(e);
                                                                                }} />
                                                                            </td>
                                                                            {
                                                                                values.supplyty === "Inter-state" && <td class="text-center">
                                                                                    <input id="items.b.taxa" class="form-control newinv formedit currency" maxLength={16} value={values.items.b.taxa} onChange={e => {
                                                                                        const formatValue = getFormatvalue(e.target.value);
                                                                                        e.target.value = formatValue;
                                                                                        handleChange(e);
                                                                                    }} onBlur={e => {
                                                                                        if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                            e.target.value = e.target.value + ".00";
                                                                                        }
                                                                                        handleChange(e);
                                                                                    }} disabled={values.items.b.total === ""} />
                                                                                </td>
                                                                            }
                                                                            {
                                                                                values.supplyty === "Intra-state" && <>
                                                                                    <td class="text-center">
                                                                                        <input id="items.b.taxb" class="form-control newinv formedit currency" maxLength={16} value={values.items.b.taxb} onChange={e => {
                                                                                            const formatValue = getFormatvalue(e.target.value);
                                                                                            e.target.value = formatValue;
                                                                                            handleChange(e);
                                                                                        }} onBlur={e => {
                                                                                            if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                                e.target.value = e.target.value + ".00";
                                                                                            }
                                                                                            handleChange(e);
                                                                                        }} disabled={values.items.b.total === ""} />
                                                                                    </td>
                                                                                    <td class="text-center">
                                                                                        <input id="items.b.taxb" class="form-control newinv formedit currency" maxLength={16} value={values.items.b.taxb} onChange={e => {
                                                                                            const formatValue = getFormatvalue(e.target.value);
                                                                                            e.target.value = formatValue;
                                                                                            handleChange(e);
                                                                                        }} onBlur={e => {
                                                                                            if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                                e.target.value = e.target.value + ".00";
                                                                                            }
                                                                                            handleChange(e);
                                                                                        }} disabled={values.items.b.total === ""} />
                                                                                    </td>
                                                                                </>
                                                                            }
                                                                            <td class="text-center">
                                                                                <input id="items.b.cess" class="form-control newinv formedit currency" maxLength={16} value={values.items.b.cess} onChange={e => {
                                                                                    const formatValue = getFormatvalue(e.target.value);
                                                                                    e.target.value = formatValue;
                                                                                    handleChange(e);
                                                                                }} onBlur={e => {
                                                                                    if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                        e.target.value = e.target.value + ".00";
                                                                                    }
                                                                                    handleChange(e);
                                                                                }} disabled={values.items.b.total === ""} />
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td class="text-center">0.25%</td>
                                                                            <td class="text-center">
                                                                                <input id="items.c.total" class="form-control newinv formedit currency" maxLength={16} value={values.items.c.total} onChange={e => {
                                                                                    const formatValue = getFormatvalue(e.target.value);
                                                                                    e.target.value = formatValue;
                                                                                    if (e.target.value === "") {
                                                                                        values.items.c.taxa = '';
                                                                                        values.items.c.taxb = '';
                                                                                        values.items.c.cess = '';
                                                                                    } else {
                                                                                        values.items.c.taxa = (parseFloat(e.target.value) * 0.025).toFixed(2);
                                                                                        values.items.c.taxb = ((parseFloat(e.target.value) * 0.025) / 2).toFixed(2);
                                                                                    }
                                                                                    handleChange(e);
                                                                                }} onBlur={e => {
                                                                                    if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                        e.target.value = e.target.value + ".00";
                                                                                    }
                                                                                    handleChange(e);
                                                                                }} />
                                                                            </td>
                                                                            {
                                                                                values.supplyty === "Inter-state" && <td class="text-center">
                                                                                    <input id="items.c.taxa" class="form-control newinv formedit currency" maxLength={16} value={values.items.c.taxa} onChange={e => {
                                                                                        const formatValue = getFormatvalue(e.target.value);
                                                                                        e.target.value = formatValue;
                                                                                        handleChange(e);
                                                                                    }} onBlur={e => {
                                                                                        if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                            e.target.value = e.target.value + ".00";
                                                                                        }
                                                                                        handleChange(e);
                                                                                    }} disabled={values.items.c.total === ""} />
                                                                                </td>
                                                                            }
                                                                            {
                                                                                values.supplyty === "Intra-state" && <>
                                                                                    <td class="text-center">
                                                                                        <input id="items.c.taxb" class="form-control newinv formedit currency" maxLength={16} value={values.items.c.taxb} onChange={e => {
                                                                                            const formatValue = getFormatvalue(e.target.value);
                                                                                            e.target.value = formatValue;
                                                                                            handleChange(e);
                                                                                        }} onBlur={e => {
                                                                                            if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                                e.target.value = e.target.value + ".00";
                                                                                            }
                                                                                            handleChange(e);
                                                                                        }} disabled={values.items.c.total === ""} />
                                                                                    </td>
                                                                                    <td class="text-center">
                                                                                        <input id="items.c.taxb" class="form-control newinv formedit currency" maxLength={16} value={values.items.c.taxb} onChange={e => {
                                                                                            const formatValue = getFormatvalue(e.target.value);
                                                                                            e.target.value = formatValue;
                                                                                            handleChange(e);
                                                                                        }} onBlur={e => {
                                                                                            if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                                e.target.value = e.target.value + ".00";
                                                                                            }
                                                                                            handleChange(e);
                                                                                        }} disabled={values.items.c.total === ""} />
                                                                                    </td>
                                                                                </>
                                                                            }
                                                                            <td class="text-center">
                                                                                <input id="items.c.cess" class="form-control newinv formedit currency" maxLength={16} value={values.items.c.cess} onChange={e => {
                                                                                    const formatValue = getFormatvalue(e.target.value);
                                                                                    e.target.value = formatValue;
                                                                                    handleChange(e);
                                                                                }} onBlur={e => {
                                                                                    if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                        e.target.value = e.target.value + ".00";
                                                                                    }
                                                                                    handleChange(e);
                                                                                }} disabled={values.items.c.total === ""} />
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td class="text-center">1%</td>
                                                                            <td class="text-center">
                                                                                <input id="items.d.total" class="form-control newinv formedit currency" maxLength={16} value={values.items.d.total} onChange={e => {
                                                                                    const formatValue = getFormatvalue(e.target.value);
                                                                                    e.target.value = formatValue;
                                                                                    if (e.target.value === "") {
                                                                                        values.items.d.taxa = '';
                                                                                        values.items.d.taxb = '';
                                                                                        values.items.d.cess = '';
                                                                                    } else {
                                                                                        values.items.d.taxa = (parseFloat(e.target.value) * 0.01).toFixed(2);
                                                                                        values.items.d.taxb = ((parseFloat(e.target.value) * 0.01) / 2).toFixed(2);
                                                                                    }
                                                                                    handleChange(e);
                                                                                }} onBlur={e => {
                                                                                    if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                        e.target.value = e.target.value + ".00";
                                                                                    }
                                                                                    handleChange(e);
                                                                                }} />
                                                                            </td>
                                                                            {
                                                                                values.supplyty === "Inter-state" && <td class="text-center">
                                                                                    <input id="items.d.taxa" class="form-control newinv formedit currency" maxLength={16} value={values.items.d.taxa} onChange={e => {
                                                                                        const formatValue = getFormatvalue(e.target.value);
                                                                                        e.target.value = formatValue;
                                                                                        handleChange(e);
                                                                                    }} onBlur={e => {
                                                                                        if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                            e.target.value = e.target.value + ".00";
                                                                                        }
                                                                                        handleChange(e);
                                                                                    }} disabled={values.items.d.total === ""} />
                                                                                </td>
                                                                            }
                                                                            {
                                                                                values.supplyty === "Intra-state" && <>
                                                                                    <td class="text-center">
                                                                                        <input id="items.d.taxb" class="form-control newinv formedit currency" maxLength={16} value={values.items.d.taxb} onChange={e => {
                                                                                            const formatValue = getFormatvalue(e.target.value);
                                                                                            e.target.value = formatValue;
                                                                                            handleChange(e);
                                                                                        }} onBlur={e => {
                                                                                            if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                                e.target.value = e.target.value + ".00";
                                                                                            }
                                                                                            handleChange(e);
                                                                                        }} disabled={values.items.d.total === ""} />
                                                                                    </td>
                                                                                    <td class="text-center">
                                                                                        <input id="items.d.taxb" class="form-control newinv formedit currency" maxLength={16} value={values.items.d.taxb} onChange={e => {
                                                                                            const formatValue = getFormatvalue(e.target.value);
                                                                                            e.target.value = formatValue;
                                                                                            handleChange(e);
                                                                                        }} onBlur={e => {
                                                                                            if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                                e.target.value = e.target.value + ".00";
                                                                                            }
                                                                                            handleChange(e);
                                                                                        }} disabled={values.items.d.total === ""} />
                                                                                    </td>
                                                                                </>
                                                                            }
                                                                            <td class="text-center">
                                                                                <input id="items.d.cess" class="form-control newinv formedit currency" maxLength={16} value={values.items.d.cess} onChange={e => {
                                                                                    const formatValue = getFormatvalue(e.target.value);
                                                                                    e.target.value = formatValue;
                                                                                    handleChange(e);
                                                                                }} onBlur={e => {
                                                                                    if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                        e.target.value = e.target.value + ".00";
                                                                                    }
                                                                                    handleChange(e);
                                                                                }} disabled={values.items.d.total === ""} />
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td class="text-center">1.5%</td>
                                                                            <td class="text-center">
                                                                                <input id="items.e.total" class="form-control newinv formedit currency" maxLength={16} value={values.items.e.total} onChange={e => {
                                                                                    const formatValue = getFormatvalue(e.target.value);
                                                                                    e.target.value = formatValue;
                                                                                    if (e.target.value === "") {
                                                                                        values.items.e.taxa = '';
                                                                                        values.items.e.taxb = '';
                                                                                        values.items.e.cess = '';
                                                                                    } else {
                                                                                        values.items.e.taxa = (parseFloat(e.target.value) * 0.015).toFixed(2);
                                                                                        values.items.e.taxb = ((parseFloat(e.target.value) * 0.015) / 2).toFixed(2);
                                                                                    }
                                                                                    handleChange(e);
                                                                                }} onBlur={e => {
                                                                                    if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                        e.target.value = e.target.value + ".00";
                                                                                    }
                                                                                    handleChange(e);
                                                                                }} />
                                                                            </td>
                                                                            {
                                                                                values.supplyty === "Inter-state" && <td class="text-center">
                                                                                    <input id="items.e.taxa" class="form-control newinv formedit currency" maxLength={16} value={values.items.e.taxa} onChange={e => {
                                                                                        const formatValue = getFormatvalue(e.target.value);
                                                                                        e.target.value = formatValue;
                                                                                        handleChange(e);
                                                                                    }} onBlur={e => {
                                                                                        if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                            e.target.value = e.target.value + ".00";
                                                                                        }
                                                                                        handleChange(e);
                                                                                    }} disabled={values.items.e.total === ""} />
                                                                                </td>
                                                                            }
                                                                            {
                                                                                values.supplyty === "Intra-state" && <>
                                                                                    <td class="text-center">
                                                                                        <input id="items.e.taxb" class="form-control newinv formedit currency" maxLength={16} value={values.items.e.taxb} onChange={e => {
                                                                                            const formatValue = getFormatvalue(e.target.value);
                                                                                            e.target.value = formatValue;
                                                                                            handleChange(e);
                                                                                        }} onBlur={e => {
                                                                                            if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                                e.target.value = e.target.value + ".00";
                                                                                            }
                                                                                            handleChange(e);
                                                                                        }} disabled={values.items.e.total === ""} />
                                                                                    </td>
                                                                                    <td class="text-center">
                                                                                        <input id="items.e.taxb" class="form-control newinv formedit currency" maxLength={16} value={values.items.e.taxb} onChange={e => {
                                                                                            const formatValue = getFormatvalue(e.target.value);
                                                                                            e.target.value = formatValue;
                                                                                            handleChange(e);
                                                                                        }} onBlur={e => {
                                                                                            if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                                e.target.value = e.target.value + ".00";
                                                                                            }
                                                                                            handleChange(e);
                                                                                        }} disabled={values.items.e.total === ""} />
                                                                                    </td>
                                                                                </>
                                                                            }
                                                                            <td class="text-center">
                                                                                <input id="items.e.cess" class="form-control newinv formedit currency" maxLength={16} value={values.items.e.cess} onChange={e => {
                                                                                    const formatValue = getFormatvalue(e.target.value);
                                                                                    e.target.value = formatValue;
                                                                                    handleChange(e);
                                                                                }} onBlur={e => {
                                                                                    if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                        e.target.value = e.target.value + ".00";
                                                                                    }
                                                                                    handleChange(e);
                                                                                }} disabled={values.items.e.total === ""} />
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td class="text-center">3%</td>
                                                                            <td class="text-center">
                                                                                <input id="items.f.total" class="form-control newinv formedit currency" maxLength={16} value={values.items.f.total} onChange={e => {
                                                                                    const formatValue = getFormatvalue(e.target.value);
                                                                                    e.target.value = formatValue;
                                                                                    if (e.target.value === "") {
                                                                                        values.items.f.taxa = '';
                                                                                        values.items.f.taxb = '';
                                                                                        values.items.f.cess = '';
                                                                                    } else {
                                                                                        values.items.f.taxa = (parseFloat(e.target.value) * 0.03).toFixed(2);
                                                                                        values.items.f.taxb = ((parseFloat(e.target.value) * 0.03) / 2).toFixed(2);
                                                                                    }
                                                                                    handleChange(e);
                                                                                }} onBlur={e => {
                                                                                    if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                        e.target.value = e.target.value + ".00";
                                                                                    }
                                                                                    handleChange(e);
                                                                                }} />
                                                                            </td>
                                                                            {
                                                                                values.supplyty === "Inter-state" && <td class="text-center">
                                                                                    <input id="items.f.taxa" class="form-control newinv formedit currency" maxLength={16} value={values.items.f.taxa} onChange={e => {
                                                                                        const formatValue = getFormatvalue(e.target.value);
                                                                                        e.target.value = formatValue;
                                                                                        handleChange(e);
                                                                                    }} onBlur={e => {
                                                                                        if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                            e.target.value = e.target.value + ".00";
                                                                                        }
                                                                                        handleChange(e);
                                                                                    }} disabled={values.items.f.total === ""} />
                                                                                </td>
                                                                            }
                                                                            {
                                                                                values.supplyty === "Intra-state" && <>
                                                                                    <td class="text-center">
                                                                                        <input id="items.f.taxb" class="form-control newinv formedit currency" maxLength={16} value={values.items.f.taxb} onChange={e => {
                                                                                            const formatValue = getFormatvalue(e.target.value);
                                                                                            e.target.value = formatValue;
                                                                                            handleChange(e);
                                                                                        }} onBlur={e => {
                                                                                            if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                                e.target.value = e.target.value + ".00";
                                                                                            }
                                                                                            handleChange(e);
                                                                                        }} disabled={values.items.f.total === ""} />
                                                                                    </td>
                                                                                    <td class="text-center">
                                                                                        <input id="items.f.taxb" class="form-control newinv formedit currency" maxLength={16} value={values.items.f.taxb} onChange={e => {
                                                                                            const formatValue = getFormatvalue(e.target.value);
                                                                                            e.target.value = formatValue;
                                                                                            handleChange(e);
                                                                                        }} onBlur={e => {
                                                                                            if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                                e.target.value = e.target.value + ".00";
                                                                                            }
                                                                                            handleChange(e);
                                                                                        }} disabled={values.items.f.total === ""} />
                                                                                    </td>
                                                                                </>
                                                                            }
                                                                            <td class="text-center">
                                                                                <input id="items.f.cess" class="form-control newinv formedit currency" maxLength={16} value={values.items.f.cess} onChange={e => {
                                                                                    const formatValue = getFormatvalue(e.target.value);
                                                                                    e.target.value = formatValue;
                                                                                    handleChange(e);
                                                                                }} onBlur={e => {
                                                                                    if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                        e.target.value = e.target.value + ".00";
                                                                                    }
                                                                                    handleChange(e);
                                                                                }} disabled={values.items.f.total === ""} />
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td class="text-center">5%</td>
                                                                            <td class="text-center">
                                                                                <input id="items.g.total" class="form-control newinv formedit currency" maxLength={16} value={values.items.g.total} onChange={e => {
                                                                                    const formatValue = getFormatvalue(e.target.value);
                                                                                    e.target.value = formatValue;
                                                                                    if (e.target.value === "") {
                                                                                        values.items.g.taxa = '';
                                                                                        values.items.g.taxb = '';
                                                                                        values.items.g.cess = '';
                                                                                    } else {
                                                                                        values.items.g.taxa = (parseFloat(e.target.value) * 0.05).toFixed(2);
                                                                                        values.items.g.taxb = ((parseFloat(e.target.value) * 0.05) / 2).toFixed(2);
                                                                                    }
                                                                                    handleChange(e);
                                                                                }} onBlur={e => {
                                                                                    if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                        e.target.value = e.target.value + ".00";
                                                                                    }
                                                                                    handleChange(e);
                                                                                }} />
                                                                            </td>
                                                                            {
                                                                                values.supplyty === "Inter-state" && <td class="text-center">
                                                                                    <input id="items.g.taxa" class="form-control newinv formedit currency" maxLength={16} value={values.items.g.taxa} onChange={e => {
                                                                                        const formatValue = getFormatvalue(e.target.value);
                                                                                        e.target.value = formatValue;
                                                                                        handleChange(e);
                                                                                    }} onBlur={e => {
                                                                                        if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                            e.target.value = e.target.value + ".00";
                                                                                        }
                                                                                        handleChange(e);
                                                                                    }} disabled={values.items.g.total === ""} />
                                                                                </td>
                                                                            }
                                                                            {
                                                                                values.supplyty === "Intra-state" && <>
                                                                                    <td class="text-center">
                                                                                        <input id="items.g.taxb" class="form-control newinv formedit currency" maxLength={16} value={values.items.g.taxb} onChange={e => {
                                                                                            const formatValue = getFormatvalue(e.target.value);
                                                                                            e.target.value = formatValue;
                                                                                            handleChange(e);
                                                                                        }} onBlur={e => {
                                                                                            if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                                e.target.value = e.target.value + ".00";
                                                                                            }
                                                                                            handleChange(e);
                                                                                        }} disabled={values.items.g.total === ""} />
                                                                                    </td>
                                                                                    <td class="text-center">
                                                                                        <input id="items.g.taxb" class="form-control newinv formedit currency" maxLength={16} value={values.items.g.taxb} onChange={e => {
                                                                                            const formatValue = getFormatvalue(e.target.value);
                                                                                            e.target.value = formatValue;
                                                                                            handleChange(e);
                                                                                        }} onBlur={e => {
                                                                                            if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                                e.target.value = e.target.value + ".00";
                                                                                            }
                                                                                            handleChange(e);
                                                                                        }} disabled={values.items.g.total === ""} />
                                                                                    </td>
                                                                                </>
                                                                            }
                                                                            <td class="text-center">
                                                                                <input id="items.g.cess" class="form-control newinv formedit currency" maxLength={16} value={values.items.g.cess} onChange={e => {
                                                                                    const formatValue = getFormatvalue(e.target.value);
                                                                                    e.target.value = formatValue;
                                                                                    handleChange(e);
                                                                                }} onBlur={e => {
                                                                                    if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                        e.target.value = e.target.value + ".00";
                                                                                    }
                                                                                    handleChange(e);
                                                                                }} disabled={values.items.g.total === ""} />
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td class="text-center">6%</td>
                                                                            <td class="text-center">
                                                                                <input id="items.h.total" class="form-control newinv formedit currency" maxLength={16} value={values.items.h.total} onChange={e => {
                                                                                    const formatValue = getFormatvalue(e.target.value);
                                                                                    e.target.value = formatValue;
                                                                                    if (e.target.value === "") {
                                                                                        values.items.h.taxa = '';
                                                                                        values.items.h.taxb = '';
                                                                                        values.items.h.cess = '';
                                                                                    } else {
                                                                                        values.items.h.taxa = (parseFloat(e.target.value) * 0.06).toFixed(2);
                                                                                        values.items.h.taxb = ((parseFloat(e.target.value) * 0.06) / 2).toFixed(2);
                                                                                    }
                                                                                    handleChange(e);
                                                                                }} onBlur={e => {
                                                                                    if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                        e.target.value = e.target.value + ".00";
                                                                                    }
                                                                                    handleChange(e);
                                                                                }} />
                                                                            </td>
                                                                            {
                                                                                values.supplyty === "Inter-state" && <td class="text-center">
                                                                                    <input id="items.h.taxa" class="form-control newinv formedit currency" maxLength={16} value={values.items.h.taxa} onChange={e => {
                                                                                        const formatValue = getFormatvalue(e.target.value);
                                                                                        e.target.value = formatValue;
                                                                                        handleChange(e);
                                                                                    }} onBlur={e => {
                                                                                        if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                            e.target.value = e.target.value + ".00";
                                                                                        }
                                                                                        handleChange(e);
                                                                                    }} disabled={values.items.h.total === ""} />
                                                                                </td>
                                                                            }
                                                                            {
                                                                                values.supplyty === "Intra-state" && <>
                                                                                    <td class="text-center">
                                                                                        <input id="items.h.taxb" class="form-control newinv formedit currency" maxLength={16} value={values.items.h.taxb} onChange={e => {
                                                                                            const formatValue = getFormatvalue(e.target.value);
                                                                                            e.target.value = formatValue;
                                                                                            handleChange(e);
                                                                                        }} onBlur={e => {
                                                                                            if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                                e.target.value = e.target.value + ".00";
                                                                                            }
                                                                                            handleChange(e);
                                                                                        }} disabled={values.items.h.total === ""} />
                                                                                    </td>
                                                                                    <td class="text-center">
                                                                                        <input id="items.h.taxb" class="form-control newinv formedit currency" maxLength={16} value={values.items.h.taxb} onChange={e => {
                                                                                            const formatValue = getFormatvalue(e.target.value);
                                                                                            e.target.value = formatValue;
                                                                                            handleChange(e);
                                                                                        }} onBlur={e => {
                                                                                            if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                                e.target.value = e.target.value + ".00";
                                                                                            }
                                                                                            handleChange(e);
                                                                                        }} disabled={values.items.h.total === ""} />
                                                                                    </td>
                                                                                </>
                                                                            }
                                                                            <td class="text-center">
                                                                                <input id="items.h.cess" class="form-control newinv formedit currency" maxLength={16} value={values.items.h.cess} onChange={e => {
                                                                                    const formatValue = getFormatvalue(e.target.value);
                                                                                    e.target.value = formatValue;
                                                                                    handleChange(e);
                                                                                }} onBlur={e => {
                                                                                    if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                        e.target.value = e.target.value + ".00";
                                                                                    }
                                                                                    handleChange(e);
                                                                                }} disabled={values.items.h.total === ""} />
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td class="text-center">7.5%</td>
                                                                            <td class="text-center">
                                                                                <input id="items.i.total" class="form-control newinv formedit currency" maxLength={16} value={values.items.i.total} onChange={e => {
                                                                                    const formatValue = getFormatvalue(e.target.value);
                                                                                    e.target.value = formatValue;
                                                                                    if (e.target.value === "") {
                                                                                        values.items.i.taxa = '';
                                                                                        values.items.i.taxb = '';
                                                                                        values.items.i.cess = '';
                                                                                    } else {
                                                                                        values.items.i.taxa = (parseFloat(e.target.value) * 0.075).toFixed(2);
                                                                                        values.items.i.taxb = ((parseFloat(e.target.value) * 0.075) / 2).toFixed(2);
                                                                                    }
                                                                                    handleChange(e);
                                                                                }} onBlur={e => {
                                                                                    if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                        e.target.value = e.target.value + ".00";
                                                                                    }
                                                                                    handleChange(e);
                                                                                }} />
                                                                            </td>
                                                                            {
                                                                                values.supplyty === "Inter-state" && <td class="text-center">
                                                                                    <input id="items.i.taxa" class="form-control newinv formedit currency" maxLength={16} value={values.items.i.taxa} onChange={e => {
                                                                                        const formatValue = getFormatvalue(e.target.value);
                                                                                        e.target.value = formatValue;
                                                                                        handleChange(e);
                                                                                    }} onBlur={e => {
                                                                                        if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                            e.target.value = e.target.value + ".00";
                                                                                        }
                                                                                        handleChange(e);
                                                                                    }} disabled={values.items.i.total === ""} />
                                                                                </td>
                                                                            }
                                                                            {
                                                                                values.supplyty === "Intra-state" && <>
                                                                                    <td class="text-center">
                                                                                        <input id="items.i.taxb" class="form-control newinv formedit currency" maxLength={16} value={values.items.i.taxb} onChange={e => {
                                                                                            const formatValue = getFormatvalue(e.target.value);
                                                                                            e.target.value = formatValue;
                                                                                            handleChange(e);
                                                                                        }} onBlur={e => {
                                                                                            if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                                e.target.value = e.target.value + ".00";
                                                                                            }
                                                                                            handleChange(e);
                                                                                        }} disabled={values.items.i.total === ""} />
                                                                                    </td>
                                                                                    <td class="text-center">
                                                                                        <input id="items.i.taxb" class="form-control newinv formedit currency" maxLength={16} value={values.items.i.taxb} onChange={e => {
                                                                                            const formatValue = getFormatvalue(e.target.value);
                                                                                            e.target.value = formatValue;
                                                                                            handleChange(e);
                                                                                        }} onBlur={e => {
                                                                                            if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                                e.target.value = e.target.value + ".00";
                                                                                            }
                                                                                            handleChange(e);
                                                                                        }} disabled={values.items.i.total === ""} />
                                                                                    </td>
                                                                                </>
                                                                            }
                                                                            <td class="text-center">
                                                                                <input id="items.i.cess" class="form-control newinv formedit currency" maxLength={16} value={values.items.i.cess} onChange={e => {
                                                                                    const formatValue = getFormatvalue(e.target.value);
                                                                                    e.target.value = formatValue;
                                                                                    handleChange(e);
                                                                                }} onBlur={e => {
                                                                                    if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                        e.target.value = e.target.value + ".00";
                                                                                    }
                                                                                    handleChange(e);
                                                                                }} disabled={values.items.i.total === ""} />
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td class="text-center">12%</td>
                                                                            <td class="text-center">
                                                                                <input id="items.j.total" class="form-control newinv formedit currency" maxLength={16} value={values.items.j.total} onChange={e => {
                                                                                    const formatValue = getFormatvalue(e.target.value);
                                                                                    e.target.value = formatValue;
                                                                                    if (e.target.value === "") {
                                                                                        values.items.j.taxa = '';
                                                                                        values.items.j.taxb = '';
                                                                                        values.items.j.cess = '';
                                                                                    } else {
                                                                                        values.items.j.taxa = (parseFloat(e.target.value) * 0.12).toFixed(2);
                                                                                        values.items.j.taxb = ((parseFloat(e.target.value) * 0.12) / 2).toFixed(2);
                                                                                    }
                                                                                    handleChange(e);
                                                                                }} onBlur={e => {
                                                                                    if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                        e.target.value = e.target.value + ".00";
                                                                                    }
                                                                                    handleChange(e);
                                                                                }} />
                                                                            </td>
                                                                            {
                                                                                values.supplyty === "Inter-state" && <td class="text-center">
                                                                                    <input id="items.j.taxa" class="form-control newinv formedit currency" maxLength={16} value={values.items.j.taxa} onChange={e => {
                                                                                        const formatValue = getFormatvalue(e.target.value);
                                                                                        e.target.value = formatValue;
                                                                                        handleChange(e);
                                                                                    }} onBlur={e => {
                                                                                        if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                            e.target.value = e.target.value + ".00";
                                                                                        }
                                                                                        handleChange(e);
                                                                                    }} disabled={values.items.j.total === ""} />
                                                                                </td>
                                                                            }
                                                                            {
                                                                                values.supplyty === "Intra-state" && <>
                                                                                    <td class="text-center">
                                                                                        <input id="items.j.taxb" class="form-control newinv formedit currency" maxLength={16} value={values.items.j.taxb} onChange={e => {
                                                                                            const formatValue = getFormatvalue(e.target.value);
                                                                                            e.target.value = formatValue;
                                                                                            handleChange(e);
                                                                                        }} onBlur={e => {
                                                                                            if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                                e.target.value = e.target.value + ".00";
                                                                                            }
                                                                                            handleChange(e);
                                                                                        }} disabled={values.items.j.total === ""} />
                                                                                    </td>
                                                                                    <td class="text-center">
                                                                                        <input id="items.j.taxb" class="form-control newinv formedit currency" maxLength={16} value={values.items.j.taxb} onChange={e => {
                                                                                            const formatValue = getFormatvalue(e.target.value);
                                                                                            e.target.value = formatValue;
                                                                                            handleChange(e);
                                                                                        }} onBlur={e => {
                                                                                            if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                                e.target.value = e.target.value + ".00";
                                                                                            }
                                                                                            handleChange(e);
                                                                                        }} disabled={values.items.j.total === ""} />
                                                                                    </td>
                                                                                </>
                                                                            }
                                                                            <td class="text-center">
                                                                                <input id="items.j.cess" class="form-control newinv formedit currency" maxLength={16} value={values.items.j.cess} onChange={e => {
                                                                                    const formatValue = getFormatvalue(e.target.value);
                                                                                    e.target.value = formatValue;
                                                                                    handleChange(e);
                                                                                }} onBlur={e => {
                                                                                    if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                        e.target.value = e.target.value + ".00";
                                                                                    }
                                                                                    handleChange(e);
                                                                                }} disabled={values.items.j.total === ""} />
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td class="text-center">18%</td>
                                                                            <td class="text-center">
                                                                                <input id="items.k.total" class="form-control newinv formedit currency" maxLength={16} value={values.items.k.total} onChange={e => {
                                                                                    const formatValue = getFormatvalue(e.target.value);
                                                                                    e.target.value = formatValue;
                                                                                    if (e.target.value === "") {
                                                                                        values.items.k.taxa = '';
                                                                                        values.items.k.taxb = '';
                                                                                        values.items.k.cess = '';
                                                                                    } else {
                                                                                        values.items.k.taxa = (parseFloat(e.target.value) * 0.18).toFixed(2);
                                                                                        values.items.k.taxb = ((parseFloat(e.target.value) * 0.18) / 2).toFixed(2);
                                                                                    }
                                                                                    handleChange(e);
                                                                                }} onBlur={e => {
                                                                                    if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                        e.target.value = e.target.value + ".00";
                                                                                    }
                                                                                    handleChange(e);
                                                                                }} />
                                                                            </td>
                                                                            {
                                                                                values.supplyty === "Inter-state" && <td class="text-center">
                                                                                    <input id="items.k.taxa" class="form-control newinv formedit currency" maxLength={16} value={values.items.k.taxa} onChange={e => {
                                                                                        const formatValue = getFormatvalue(e.target.value);
                                                                                        e.target.value = formatValue;
                                                                                        handleChange(e);
                                                                                    }} onBlur={e => {
                                                                                        if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                            e.target.value = e.target.value + ".00";
                                                                                        }
                                                                                        handleChange(e);
                                                                                    }} disabled={values.items.k.total === ""} />
                                                                                </td>
                                                                            }
                                                                            {
                                                                                values.supplyty === "Intra-state" && <>
                                                                                    <td class="text-center">
                                                                                        <input id="items.k.taxb" class="form-control newinv formedit currency" maxLength={16} value={values.items.k.taxb} onChange={e => {
                                                                                            const formatValue = getFormatvalue(e.target.value);
                                                                                            e.target.value = formatValue;
                                                                                            handleChange(e);
                                                                                        }} onBlur={e => {
                                                                                            if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                                e.target.value = e.target.value + ".00";
                                                                                            }
                                                                                            handleChange(e);
                                                                                        }} disabled={values.items.k.total === ""} />
                                                                                    </td>
                                                                                    <td class="text-center">
                                                                                        <input id="items.k.taxb" class="form-control newinv formedit currency" maxLength={16} value={values.items.k.taxb} onChange={e => {
                                                                                            const formatValue = getFormatvalue(e.target.value);
                                                                                            e.target.value = formatValue;
                                                                                            handleChange(e);
                                                                                        }} onBlur={e => {
                                                                                            if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                                e.target.value = e.target.value + ".00";
                                                                                            }
                                                                                            handleChange(e);
                                                                                        }} disabled={values.items.k.total === ""} />
                                                                                    </td>
                                                                                </>
                                                                            }
                                                                            <td class="text-center">
                                                                                <input id="items.k.cess" class="form-control newinv formedit currency" maxLength={16} value={values.items.k.cess} onChange={e => {
                                                                                    const formatValue = getFormatvalue(e.target.value);
                                                                                    e.target.value = formatValue;
                                                                                    handleChange(e);
                                                                                }} onBlur={e => {
                                                                                    if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                        e.target.value = e.target.value + ".00";
                                                                                    }
                                                                                    handleChange(e);
                                                                                }} disabled={values.items.k.total === ""} />
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td class="text-center">28%</td>
                                                                            <td class="text-center">
                                                                                <input id="items.l.total" class="form-control newinv formedit currency" maxLength={16} value={values.items.l.total} onChange={e => {
                                                                                    const formatValue = getFormatvalue(e.target.value);
                                                                                    e.target.value = formatValue;
                                                                                    if (e.target.value === "") {
                                                                                        values.items.l.taxa = '';
                                                                                        values.items.l.taxb = '';
                                                                                        values.items.l.cess = '';
                                                                                    } else {
                                                                                        values.items.l.taxa = (parseFloat(e.target.value) * 0.28).toFixed(2);
                                                                                        values.items.l.taxb = ((parseFloat(e.target.value) * 0.28) / 2).toFixed(2);
                                                                                    }
                                                                                    handleChange(e);
                                                                                }} onBlur={e => {
                                                                                    if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                        e.target.value = e.target.value + ".00";
                                                                                    }
                                                                                    handleChange(e);
                                                                                }} />
                                                                            </td>
                                                                            {
                                                                                values.supplyty === "Inter-state" && <td class="text-center">
                                                                                    <input id="items.l.taxa" class="form-control newinv formedit currency" maxLength={16} value={values.items.l.taxa} onChange={e => {
                                                                                        const formatValue = getFormatvalue(e.target.value);
                                                                                        e.target.value = formatValue;
                                                                                        handleChange(e);
                                                                                    }} onBlur={e => {
                                                                                        if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                            e.target.value = e.target.value + ".00";
                                                                                        }
                                                                                        handleChange(e);
                                                                                    }} disabled={values.items.l.total === ""} />
                                                                                </td>
                                                                            }
                                                                            {
                                                                                values.supplyty === "Intra-state" && <>
                                                                                    <td class="text-center">
                                                                                        <input id="items.l.taxb" class="form-control newinv formedit currency" maxLength={16} value={values.items.l.taxb} onChange={e => {
                                                                                            const formatValue = getFormatvalue(e.target.value);
                                                                                            e.target.value = formatValue;
                                                                                            handleChange(e);
                                                                                        }} onBlur={e => {
                                                                                            if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                                e.target.value = e.target.value + ".00";
                                                                                            }
                                                                                            handleChange(e);
                                                                                        }} disabled={values.items.l.total === ""} />
                                                                                    </td>
                                                                                    <td class="text-center">
                                                                                        <input id="items.l.taxb" class="form-control newinv formedit currency" maxLength={16} value={values.items.l.taxb} onChange={e => {
                                                                                            const formatValue = getFormatvalue(e.target.value);
                                                                                            e.target.value = formatValue;
                                                                                            handleChange(e);
                                                                                        }} onBlur={e => {
                                                                                            if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                                e.target.value = e.target.value + ".00";
                                                                                            }
                                                                                            handleChange(e);
                                                                                        }} disabled={values.items.l.total === ""} />
                                                                                    </td>
                                                                                </>
                                                                            }
                                                                            <td class="text-center">
                                                                                <input id="items.l.cess" class="form-control newinv formedit currency" maxLength={16} value={values.items.l.cess} onChange={e => {
                                                                                    const formatValue = getFormatvalue(e.target.value);
                                                                                    e.target.value = formatValue;
                                                                                    handleChange(e);
                                                                                }} onBlur={e => {
                                                                                    if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                        e.target.value = e.target.value + ".00";
                                                                                    }
                                                                                    handleChange(e);
                                                                                }} disabled={values.items.l.total === ""} />
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </fieldset>
                                        </div>
                                    }
                                </fieldset>
                                <div class="row">
                                    <div class="col-sm-12 col-xs-12 text-right next-tab-nav">
                                        <button type="button" class="btn btn-default" onClick={() => {
                                            gotoGstrPath('aa11a')
                                        }} >Back</button>&nbsp;&nbsp;
                                        {!isView && <button type="submit" class="btn btn-primary accpt" disabled={submit}>Save</button>}
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}
