import React, { useEffect } from 'react';

import { useRecoilState } from 'recoil';
import { challanAtom, pathAtom, gstrpathAtom } from '../../../core/config/atoms';

export default function PanelSuccessChallan() {

    const [challan] = useRecoilState(challanAtom);

    const [, setPath] = useRecoilState(pathAtom);
    const [, setGSTRPath] = useRecoilState(gstrpathAtom);

    const getTotal = () => {
        if (challan !== {}) {
            let tax = challan.data.tax.igst + challan.data.tax.cgst + challan.data.tax.sgst + challan.data.tax.cess;
            let intr = challan.data.interest.igst + challan.data.interest.cgst + challan.data.interest.sgst + challan.data.interest.cess;
            let fees = challan.data.fees.cgst + challan.data.fees.sgst;
            return tax + intr + fees;
        } else {
            return 0;
        }
    }

    const gotoPath = (path) => {
        setPath(path);
    }

    const contineu = () => {
        setGSTRPath('A6.1');
        gotoPath('gstr3B');
    }

    const viewRecipt = () => {
        setGSTRPath('view');
        gotoPath('gstr3BChallan');
    }

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    }, []);

    return (
        <div class="content-wrapper">
            <div class="container fade-ng-cloak">
                <div class="mypage">
                    <div class="tabpane" data-ng-show="showContent">
                        <h4 className='pb-8'>Payment Summary</h4>
                        <div className="alert alert-msg alert-success alert-dismissible accept mt-2 font-semibold">
                            Your Payment is successfully completed.
                        </div>
                        <p>Tax Remittance of <b style={{ color: '#000' }}>Rs. {getTotal()}</b> via BANK Transaction ID <b style={{ color: '#000' }}>{challan.tid}</b> successfully completed. <a onClick={(e) => {
                            viewRecipt();
                        }}>View Receipt</a></p>
                        <h3 className='text-[#255581]'>Thank You!</h3>
                        <p className='cursor-not-allowed'><a className='opacity-60 pointer-events-none'>Click here to view your Cash Ledger</a></p>
                        <div className="row">
                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-right pad-b-20">
                                <button type="button" title="Generate Challan" className="btn btn-primary" onClick={(e) => {
                                    contineu();
                                }}>CONTINUE</button>&nbsp;
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
