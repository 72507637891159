import React from 'react';

import { useRecoilState } from 'recoil';
import { redirectAtom, pathAtom } from '../core/config/atoms';

import Moment from 'moment';
import Tippy from '@tippyjs/react';

import 'tippy.js/dist/tippy.css';

export default function PanelHome() {

    const news = [
        { "date": "10/05/2022", "title": "Reporting 6% rate in GSTR-1", "url": "https://www.gst.gov.in/newsandupdates/read/539" },
        { "date": "10/05/2022", "title": "Module wise new functionalities deployed on the GST Portal for taxpayers", "url": "https://www.gst.gov.in/newsandupdates/read/540" },
        { "date": "02/05/2022", "title": "Annual Aggregate Turnover (AATO) computation for FY 2021-22", "url": "https://www.gst.gov.in/newsandupdates/read/537" },
    ];

    const [redirect, setRedirect] = useRecoilState(redirectAtom);
    const [path, setPath] = useRecoilState(pathAtom);

    const gotoPath = (path) => {
        setPath(path);
    }

    const gotoURL = (url) => {
        setRedirect({ url: url, isToggle: true });
    }

    const getStartMonth = (m) => {
        var start = "";
        if (m <= 3) {
            start = "Jan"
        } else if (m <= 6) {
            start = "Apr"
        } else if (m <= 9) {
            start = "Jul"
        } else if (m <= 12) {
            start = "Oct"
        }
        return start;
    }

    const getEndMonth = (m) => {
        var start = "";
        if (m <= 3) {
            start = "Mar"
        } else if (m <= 6) {
            start = "Jun"
        } else if (m <= 9) {
            start = "Sep"
        } else if (m <= 12) {
            start = "Dec"
        }
        return start;
    }

    const getFileMonth = (m) => {
        var start = "";
        if (m <= 3) {
            start = "Apr"
        } else if (m <= 6) {
            start = "Jul"
        } else if (m <= 9) {
            start = "Oct"
        } else if (m <= 12) {
            start = "Jan"
        }
        return start;
    }

    return (
        <div className="content-wrapper">
            <div className="container-fluid lbanner">
                <div className="col-xs-12 lbanner-img-r1"></div>
                <div className="land-alerts" data-ng-controller="landingCtrl">
                    <i className="fa fa-bell" aria-hidden="true" style={{ paddingLeft: '18px', marginTop: '7px' }}></i>
                    <p className="marquee" data-ng-bind-html="alerts"><b>Disclaimer:</b> This is a simulation of the GST portal used only for educational purpose. This simulation doesn't have any real implications on the GST portal. When re-directed to the actual GST portal,  the user will be responsible for any action taken and it's implications.</p>
                </div>
            </div>
            <div className="content-wrapper">
                <div className="l-widgets">
                    <div className="container">
                        <div class="row">
                            <div class="col-md-5 col-sm-5 col-xs-12">
                                <div class="col-md-5 col-sm-5 col-xs-5"><h4>News|Updates</h4></div>
                                <div class="col-md-7 col-sm-7 col-xs-7" style={{ textAlign: 'right' }}>
                                    <h5 style={{ fontSize: '14px' }}><a onClick={() => { gotoURL("https://services.gst.gov.in/services/advisory/advisoryandreleases") }}>Advisories </a></h5>
                                </div>
                                <div class="row panels">
                                    <div class="col-xs-12">
                                        <div class="panel news">
                                            <div class="panel-body">
                                                <div class="row mt-4">
                                                    <div class="col-sm-12">
                                                        <p class="dt">
                                                            ITAA Update
                                                        </p>
                                                        <p><a href='https://itaaedu.com/' target="_blank">Click here to view the ITAA updates</a></p>
                                                    </div>
                                                </div>
                                                <div class="row mt-6 mb-4">
                                                    <div class="col-sm-12">
                                                        <p class="dt">
                                                            GST Update
                                                        </p>
                                                        <p><a onClick={() => { gotoURL("https://www.gst.gov.in/newsandupdates") }}>Click here to view the GST updates</a></p>
                                                    </div>
                                                </div>
                                                {/* <div class="row" data-ng-if="newsList.length > 0">
                                                    <div class="col-sm-12">
                                                        <p class="pull-right">
                                                            <a onClick={() => { gotoURL("//www.gst.gov.in/newsandupdates") }}>
                                                                View all &nbsp;
                                                                <i class="fa fa-angle-double-right"></i>
                                                            </a>
                                                        </p>
                                                    </div>
                                                </div> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-7 col-sm-7 col-xs-12">
                                <div class="row panels">
                                    <div class="col-xs-12">
                                        <h4>Popular help topics</h4>
                                        <div class="panel" style={{ borderBottom: 'none' }}>
                                            <div class="panel-body">
                                                <div class="row">
                                                    <div class="col-sm-6">
                                                        <ul class="shrt-info">
                                                            <li>
                                                                <p>
                                                                    <i class="fa fa-arrow-circle-right"></i>
                                                                    <a onClick={() => { gotoURL("https://tutorial.gst.gov.in/userguide/compositionpoc/optforcomposition.htm") }}> How can I Opt for Composition? </a>
                                                                </p>
                                                            </li>
                                                            <li>
                                                                <p>
                                                                    <i class="fa fa-arrow-circle-right"></i>
                                                                    <a onClick={() => { gotoURL("https://www.gst.gov.in/help/offlineutility") }}> How can I use the Returns Offline tool? </a>
                                                                </p>
                                                            </li>
                                                            <li>
                                                                <p>
                                                                    <i class="fa fa-arrow-circle-right"></i>
                                                                    <a onClick={() => { gotoURL("https://www.gst.gov.in/help/refund") }}> How do I apply for refund?
                                                                    </a>
                                                                </p>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <div class="col-sm-6">
                                                        <ul class="shrt-info">
                                                            <li>
                                                                <p>
                                                                    <i class="fa fa-arrow-circle-right"></i>
                                                                    <a onClick={() => { gotoURL("https://www.gst.gov.in/help/demandRecovery") }}> How do I file intimation about voluntary payment?</a>
                                                                </p>
                                                            </li>
                                                            <li>
                                                                <p>
                                                                    <i class="fa fa-arrow-circle-right"></i>
                                                                    <a onClick={() => { gotoURL("https://www.gst.gov.in/help/appeal") }}> How to file an appeal?</a>
                                                                </p>
                                                            </li>
                                                            <li>
                                                                <p>
                                                                    <i class="fa fa-arrow-circle-right"></i>
                                                                    <a onClick={() => { gotoURL("https://www.gst.gov.in/help/enrollmentwithgst") }}> How do I register with GST? </a>
                                                                </p>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row" style={{ borderBottom: '2px solid #2ec9c1' }}>
                                    <div class="col-sm-12 col-xs-12">
                                        <div class="panel" style={{ backgroundColor: '#18436b', marginBottom: '5px' }}>
                                            <div class="panel-body" style={{ padding: '5px' }}>
                                                <span className='text-white'>
                                                    For any queries, Kindly post them in the Queries section on LMS.&nbsp;
                                                    <span><a className="hover:text-main text-white underline" href="https://learn.itaaedu.com/site/login">Click here</a></span>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="impdates">
                        <div class="container">
                            <div class="row">
                                <div class="col-sm-5 col-xs-12">
                                    <div class="row panels">
                                        <div class="col-xs-12">
                                            <h4>Taxpayers (Normal/TDS/TCS)</h4>
                                            <div class="panel one">
                                                <div class="panel-body">
                                                    <div class="row">
                                                        <div class="col-sm-6">
                                                            <ul class="shrt-info">
                                                                <li>
                                                                    <p>
                                                                        <i class="fa fa-lock icon"></i>
                                                                        <a onClick={() => { gotoPath("register") }}>Register Now
                                                                        </a>
                                                                    </p>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                        <div class="col-sm-6">
                                                            <ul class="shrt-info">
                                                                <li>
                                                                    <p>
                                                                        <i class="fa fa-user icon"></i>
                                                                        <a onClick={() => { gotoPath("demoPage1") }}>Find a GST Practitioner
                                                                        </a>
                                                                    </p>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-xs-12">
                                            <h4>GST Practitioners</h4>
                                            <div class="panel one">
                                                <div class="panel-body">
                                                    <div class="row">
                                                        <div class="col-sm-6">
                                                            <ul class="shrt-info">
                                                                <li>
                                                                    <p>
                                                                        <i class="fa fa-lock icon"></i>
                                                                        <a onClick={() => { gotoPath("registerb") }}>Register Now</a>
                                                                    </p>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                        <div class="col-sm-6">
                                                            <ul class="shrt-info">
                                                                <li>
                                                                    <p>
                                                                        <i class="fa fa-user icon"></i>
                                                                        <a onClick={() => { gotoPath("demoPage2") }}>Find a Taxpayer</a>
                                                                    </p>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-7 col-xs-12">
                                    <h4 class="headimp" style={{ width: '30%', float: 'left' }}>Upcoming Due Dates</h4>
                                    <div class="row">
                                        <div class="col-sm-12 col-xs-12">
                                            <ul class="dates-list impul">
                                                <li>
                                                    <div class="row">
                                                        <div class="col-xs-12">
                                                            <div class="square-box b1" style={{ background: '#1d867a', height: '32px', width: '174px' }}>
                                                                <div class="square-content" style={{ fontSize: '12px' }}>
                                                                    <span className=''>GSTR-3B ({Moment().subtract(1, 'months').format('MMM')}, 2022)</span>&nbsp;
                                                                    <Tippy content="Turnover exceeding 5 Cr or opted to file monthly return." placement='top'>
                                                                        <i class="fa fa-info-circle"></i>
                                                                    </Tippy>
                                                                </div>
                                                            </div>
                                                            <p class="imp">
                                                                {Moment().format('MMM')} 20th, 2022
                                                            </p>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div class="row">
                                                        <div class="col-xs-12">
                                                            <div class="square-box b1" style={{ background: '#1d867a', height: '32px', width: '174px' }}>
                                                                <div class="square-content" style={{ fontSize: '12px' }}>
                                                                    <span className=''>GSTR-3B ({getStartMonth(Moment().format('M'))}-{getEndMonth(Moment().format('M'))}, 2022)</span>&nbsp;
                                                                    <Tippy content="Opted for quarterly filing as per QRMP scheme." placement='top'>
                                                                        <i class="fa fa-info-circle"></i>
                                                                    </Tippy>
                                                                </div>
                                                            </div>
                                                            <p class="imp">
                                                                {getFileMonth(Moment().format('M'))} 22nd, 24th, 2022
                                                            </p>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div class="row">
                                                        <div class="col-xs-12">
                                                            <div class="square-box b1" style={{ background: '#1d867a', height: '32px', width: '174px' }}>
                                                                <div class="square-content" style={{ fontSize: '12px' }}>
                                                                    <span className=''>GSTR-1 ({Moment().subtract(1, 'months').format('MMM')}, 2022)</span>&nbsp;
                                                                    <Tippy content="Turnover exceeding 5 Cr or opted to file monthly return." placement='top'>
                                                                        <i class="fa fa-info-circle"></i>
                                                                    </Tippy>
                                                                </div>
                                                            </div>
                                                            <p class="imp">
                                                                {Moment().format('MMM')} 11th, 2022
                                                            </p>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div class="row">
                                                        <div class="col-xs-12">
                                                            <div class="square-box b1" style={{ background: '#1d867a', height: '32px', width: '174px' }}>
                                                                <div class="square-content" style={{ fontSize: '12px' }}>
                                                                    <span class="">IFF (Optional) ({Moment().subtract(1, 'months').format('MMM')},2022)</span>&nbsp;
                                                                    <Tippy content="Opted for quarterly filing as per QRMP scheme." placement='top'>
                                                                        <i class="fa fa-info-circle"></i>
                                                                    </Tippy>
                                                                </div>
                                                            </div>
                                                            <p class="imp">
                                                                {Moment().format('MMM')} 13th, 2022
                                                            </p>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div class="row">
                                                        <div class="col-xs-12">
                                                            <div class="square-box b1" style={{ background: '#1d867a', height: '32px', width: '174px' }}>
                                                                <div class="square-content" style={{ fontSize: '12px' }}>
                                                                    <span class="">GSTR-1 ({getStartMonth(Moment().format('M'))}-{getEndMonth(Moment().format('M'))}, 2022)</span>
                                                                    &nbsp;
                                                                    <Tippy content="Opted for quarterly filing as per QRMP scheme." placement='top'>
                                                                        <i class="fa fa-info-circle"></i>
                                                                    </Tippy>
                                                                </div>
                                                            </div>
                                                            <p class="imp">
                                                                {getFileMonth(Moment().format('M'))} 13th, 2022
                                                            </p>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div class="row">
                                                        <div class="col-xs-12">
                                                            <div class="square-box b1" style={{ background: '#1d867a', height: '32px', width: '174px' }}>
                                                                <div class="square-content" style={{ fontSize: '12px' }}>
                                                                    <span class="">CMP-08 ({getStartMonth(Moment().format('M'))}-{getEndMonth(Moment().format('M'))}, 2022)</span>
                                                                </div>
                                                            </div>
                                                            <p class="imp">
                                                                {getFileMonth(Moment().format('M'))} 18th, 2022
                                                            </p>
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                            <h4 class="headoth">Others:</h4>
                                            <ul class="dates-list impul lst" style={{ marginBottom: '40px' }}>
                                                <li>
                                                    <div class="row">
                                                        <div class="col-xs-12">
                                                            <div class="square-box b1 refd" style={{ background: '#1d867a', height: '32px', width: '174px' }}>
                                                                <div class="square-content" style={{ fontSize: '12px' }}>
                                                                    <span class="">GSTR-5 ({Moment().subtract(1, 'months').format('MMM')}, 2022)</span>
                                                                </div>
                                                            </div>
                                                            <p class="imp">{Moment().format('MMM')} 20th, 2022</p>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div class="row">
                                                        <div class="col-xs-12">
                                                            <div class="square-box b1 refd" style={{ background: '#1d867a', height: '32px', width: '174px' }}>
                                                                <div class="square-content" style={{ fontSize: '12px' }}>
                                                                    <span class="">GSTR-5A ({Moment().subtract(1, 'months').format('MMM')}, 2022)</span>
                                                                </div>
                                                            </div>
                                                            <p class="imp">{Moment().format('MMM')} 20th, 2022</p>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div class="row">
                                                        <div class="col-xs-12">
                                                            <div class="square-box b1 refd" style={{ background: '#1d867a', height: '32px', width: '174px' }}>
                                                                <div class="square-content" style={{ fontSize: '12px' }}>
                                                                    <span class="">GSTR-6 ({Moment().subtract(1, 'months').format('MMM')}, 2022)</span>
                                                                </div>
                                                            </div>
                                                            <p class="imp">{Moment().format('MMM')} 13th, 2022</p>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div class="row">
                                                        <div class="col-xs-12">
                                                            <div class="square-box b1 refd" style={{ background: '#1d867a', height: '32px', width: '174px' }}>
                                                                <div class="square-content" style={{ fontSize: '12px' }}>
                                                                    <span class="">GSTR-7 ({Moment().subtract(1, 'months').format('MMM')}, 2022)</span>
                                                                </div>
                                                            </div>
                                                            <p class="imp">{Moment().format('MMM')} 10th, 2022</p>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div class="row">
                                                        <div class="col-xs-12">
                                                            <div class="square-box b1 refd" style={{ background: '#1d867a', height: '32px', width: '174px' }}>
                                                                <div class="square-content" style={{ fontSize: '12px' }}>
                                                                    <span class="">GSTR-8 ({Moment().subtract(1, 'months').format('MMM')}, 2022)</span>
                                                                </div>
                                                            </div>
                                                            <p class="imp">{Moment().format('MMM')} 10th, 2022</p>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div class="row">
                                                        <div class="col-xs-12">
                                                            <div class="square-box b1 refd" style={{ background: '#1d867a', height: '32px', width: '174px' }}>
                                                                <div class="square-content" style={{ fontSize: '12px' }}>
                                                                    <span class="">RFD-10</span>
                                                                </div>
                                                            </div>
                                                            <p style={{ fontSize: '8px', marginBottom: '1px' }} class="imp">18 Months after the end of quarter for which refund is to be claimed</p>
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
