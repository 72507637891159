import React from 'react';
import ReactDOM from 'react-dom/client';

import { RecoilRoot } from 'recoil';

import { transitions, positions, Provider as AlertProvider } from 'react-alert';
import FeatherIcon from 'feather-icons-react';

import App from './app';
// import ProgressBar from './core/widgets/ui/misc/ProgressBar';

const optionAlert = {
  position: positions.TOP_RIGHT,
  timeout: 6000,
  offset: '30px',
  transition: transitions.FADE
}

const AlertTemplate = ({ style, options, message, close }) => (
  <>
    <div className={`rounded text-lg font-serif mt-10 mr-8 ${(() => {
      switch (options.type) {
        case 'success':
          return "bg-green-400";
        case 'error2':
          return "bg-red-400";
        default:
          return "bg-yellow-400";
      }
    })()} shadow`}>
      <p className={`px-4 py-2 flex ${options.from === "fms" ? 'text-xl' : ''} ${(() => {
        switch (options.type) {
          case 'info':
            return "text-blue-400";
          case 'success':
            return "text-green-800";
          case 'error':
            return "text-red-800";
          case 'error2':
            return "text-white";
          default:
            return "text-gray-900";
        }
      })()}`}>
        <FeatherIcon icon="bell" size={18} />&nbsp;&nbsp;{message}
      </p>
    </div>
  </>
);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RecoilRoot>
      <AlertProvider template={AlertTemplate} {...optionAlert}>
        <App />
      </AlertProvider>
    </RecoilRoot>
  </React.StrictMode>
);