import React, { useState, useEffect } from 'react';

import { useRecoilState } from 'recoil';
import { pathAtom, gstrpathAtom, digErrorAtom, returnDateAtom, loadingAtom, gstrUserAtom, gstrFileStatusAtom, userIDAtom } from '../../../core/config/atoms';

import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Dialog } from '@headlessui/react';

import '../css/main.css';
import '../css/style.css';

import ServiceGSTR from '../../../services/serviceGSTR';

import imgLoader from '../../../assets/images/cloading.gif';

export default function PanelA13Home() {

    const [path, setPath] = useRecoilState(pathAtom);
    const [gstrPath, setGSTRPath] = useRecoilState(gstrpathAtom);
    const [digError, setDigError] = useRecoilState(digErrorAtom);
    const [gDate] = useRecoilState(returnDateAtom);
    const [loading, setLoading] = useRecoilState(loadingAtom);
    const [GSTUser] = useRecoilState(gstrUserAtom);
    const [fileStatus] = useRecoilState(gstrFileStatusAtom);
    const [userID] = useRecoilState(userIDAtom);

    const [initVal, setInitVal] = useState({
        ios: [],
        iisup: [],
        rev: [],
        dn: [],
        cn: [],
        rv: [],
        pv: [],
        rfv: [],
        dchw: [],
        dch: [],
        dchg: [],
        dcho: [],
    });

    const [emap, setEmap] = useState([]);

    const [digConfirm, setDigConfirm] = useState({ 'type': '0', title: '', isToggle: false, data: null });

    const [invno, setInvno] = useState('');

    const [isAdd, setIsAdd] = useState(false);
    const [isAlert, setIsAlrt] = useState(false);
    const [isValid, setIsValid] = useState(false);
    const [submit, setSubmit] = useState(false);
    const [isEdit, setIsEdit] = useState(false);

    const returnService = new ServiceGSTR();

    const formVSchema = Yup.object().shape({
        ios: Yup.array().of(
            Yup.object().shape({
                from: Yup.string().required('This information is required'),
                to: Yup.string().required('This information is required'),
                total: Yup.string().required('This information is required'),
                cancelled: Yup.string().required('This information is required'),
                net: Yup.string(),
                type: Yup.string(),
            })
        ),
        iisup: Yup.array().of(
            Yup.object().shape({
                from: Yup.string().required('This information is required'),
                to: Yup.string().required('This information is required'),
                total: Yup.string().required('This information is required'),
                cancelled: Yup.string().required('This information is required'),
                net: Yup.string(),
                type: Yup.string(),
            })
        ),
        rev: Yup.array().of(
            Yup.object().shape({
                from: Yup.string().required('This information is required'),
                to: Yup.string().required('This information is required'),
                total: Yup.string().required('This information is required'),
                cancelled: Yup.string().required('This information is required'),
                net: Yup.string(),
                type: Yup.string(),
            })
        ),
        dn: Yup.array().of(
            Yup.object().shape({
                from: Yup.string().required('This information is required'),
                to: Yup.string().required('This information is required'),
                total: Yup.string().required('This information is required'),
                cancelled: Yup.string().required('This information is required'),
                net: Yup.string(),
                type: Yup.string(),
            })
        ),
        cn: Yup.array().of(
            Yup.object().shape({
                from: Yup.string().required('This information is required'),
                to: Yup.string().required('This information is required'),
                total: Yup.string().required('This information is required'),
                cancelled: Yup.string().required('This information is required'),
                net: Yup.string(),
                type: Yup.string(),
            })
        ),
        rv: Yup.array().of(
            Yup.object().shape({
                from: Yup.string().required('This information is required'),
                to: Yup.string().required('This information is required'),
                total: Yup.string().required('This information is required'),
                cancelled: Yup.string().required('This information is required'),
                net: Yup.string(),
                type: Yup.string(),
            })
        ),
        pv: Yup.array().of(
            Yup.object().shape({
                from: Yup.string().required('This information is required'),
                to: Yup.string().required('This information is required'),
                total: Yup.string().required('This information is required'),
                cancelled: Yup.string().required('This information is required'),
                net: Yup.string(),
                type: Yup.string(),
            })
        ),
        rfv: Yup.array().of(
            Yup.object().shape({
                from: Yup.string().required('This information is required'),
                to: Yup.string().required('This information is required'),
                total: Yup.string().required('This information is required'),
                cancelled: Yup.string().required('This information is required'),
                net: Yup.string(),
                type: Yup.string(),
            })
        ),
        dchw: Yup.array().of(
            Yup.object().shape({
                from: Yup.string().required('This information is required'),
                to: Yup.string().required('This information is required'),
                total: Yup.string().required('This information is required'),
                cancelled: Yup.string().required('This information is required'),
                net: Yup.string(),
                type: Yup.string(),
            })
        ),
        dch: Yup.array().of(
            Yup.object().shape({
                from: Yup.string().required('This information is required'),
                to: Yup.string().required('This information is required'),
                total: Yup.string().required('This information is required'),
                cancelled: Yup.string().required('This information is required'),
                net: Yup.string(),
                type: Yup.string(),
            })
        ),
        dchg: Yup.array().of(
            Yup.object().shape({
                from: Yup.string().required('This information is required'),
                to: Yup.string().required('This information is required'),
                total: Yup.string().required('This information is required'),
                cancelled: Yup.string().required('This information is required'),
                net: Yup.string(),
                type: Yup.string(),
            })
        ),
        dcho: Yup.array().of(
            Yup.object().shape({
                from: Yup.string().required('This information is required'),
                to: Yup.string().required('This information is required'),
                total: Yup.string().required('This information is required'),
                cancelled: Yup.string().required('This information is required'),
                net: Yup.string(),
                type: Yup.string(),
            })
        )
    });

    const gotoPath = (path) => {
        setLoading(true);
        setPath(path);
    }

    const getFormatvalue = (val) => {
        if (!val) return val;
        let number = val.replace(/[^\d]/g, "");
        if (number.length > 10) {
            number = number.substring(0, 10);
        }
        return number;
    }

    const getTFormatvalue = (val) => {
        if (!val) return val;
        if (val.length > 16) {
            val = val.substring(0, 16);
        }
        return val;
    }

    const handleOnChangeForm = (e) => {
        var { value, name } = e.target;
        let val = { ...initVal };
        let vr = name.split('.');
        switch (vr[0]) {
            case 'ios':
                switch (vr[2]) {
                    case 'from':
                        val.ios[vr[1]].from = value;
                        break;
                    case 'to':
                        val.ios[vr[1]].to = value;
                        break;
                    case 'total':
                        val.ios[vr[1]].total = value;
                        break;
                    case 'cancelled':
                        val.ios[vr[1]].cancelled = value;
                        let key = "ios[" + vr[1] + "].can";
                        if (parseInt(val.ios[vr[1]].total) < parseInt(value)) {
                            if (!emap.includes(key)) {
                                let em = [...emap];
                                em.push(key);
                                setEmap(em);
                            }
                        } else {
                            if (emap.includes(key)) {
                                let em = [...emap];
                                em.splice(em.findIndex((e) => e === key), 1);
                                setEmap(em);
                            }
                        }
                        break;
                }
                if (val.ios[vr[1]].total !== "" && val.ios[vr[1]].cancelled !== "") {
                    val.ios[vr[1]].net = parseInt(val.ios[vr[1]].total) - parseInt(val.ios[vr[1]].cancelled);
                }
                break;
            case 'iisup':
                switch (vr[2]) {
                    case 'from':
                        val.iisup[vr[1]].from = value;
                        break;
                    case 'to':
                        val.iisup[vr[1]].to = value;
                        break;
                    case 'total':
                        val.iisup[vr[1]].total = value;
                        break;
                    case 'cancelled':
                        val.iisup[vr[1]].cancelled = value;
                        let key = "iisup[" + vr[1] + "].can";
                        if (parseInt(val.iisup[vr[1]].total) < parseInt(value)) {
                            if (!emap.includes(key)) {
                                let em = [...emap];
                                em.push(key);
                                setEmap(em);
                            }
                        } else {
                            if (emap.includes(key)) {
                                let em = [...emap];
                                em.splice(em.findIndex((e) => e === key), 1);
                                setEmap(em);
                            }
                        }
                        break;
                }
                if (val.iisup[vr[1]].total !== "" && val.iisup[vr[1]].cancelled !== "") {
                    val.iisup[vr[1]].net = parseInt(val.iisup[vr[1]].total) - parseInt(val.iisup[vr[1]].cancelled);
                }
                break;
            case 'rev':
                switch (vr[2]) {
                    case 'from':
                        val.rev[vr[1]].from = value;
                        break;
                    case 'to':
                        val.rev[vr[1]].to = value;
                        break;
                    case 'total':
                        val.rev[vr[1]].total = value;
                        break;
                    case 'cancelled':
                        val.rev[vr[1]].cancelled = value;
                        let key = "rev[" + vr[1] + "].can";
                        if (parseInt(val.rev[vr[1]].total) < parseInt(value)) {
                            if (!emap.includes(key)) {
                                let em = [...emap];
                                em.push(key);
                                setEmap(em);
                            }
                        } else {
                            if (emap.includes(key)) {
                                let em = [...emap];
                                em.splice(em.findIndex((e) => e === key), 1);
                                setEmap(em);
                            }
                        }
                        break;
                }
                if (val.rev[vr[1]].total !== "" && val.rev[vr[1]].cancelled !== "") {
                    val.rev[vr[1]].net = parseInt(val.rev[vr[1]].total) - parseInt(val.rev[vr[1]].cancelled);
                }
                break;
            case 'dn':
                switch (vr[2]) {
                    case 'from':
                        val.dn[vr[1]].from = value;
                        break;
                    case 'to':
                        val.dn[vr[1]].to = value;
                        break;
                    case 'total':
                        val.dn[vr[1]].total = value;
                        break;
                    case 'cancelled':
                        val.dn[vr[1]].cancelled = value;
                        let key = "dn[" + vr[1] + "].can";
                        if (parseInt(val.dn[vr[1]].total) < parseInt(value)) {
                            if (!emap.includes(key)) {
                                let em = [...emap];
                                em.push(key);
                                setEmap(em);
                            }
                        } else {
                            if (emap.includes(key)) {
                                let em = [...emap];
                                em.splice(em.findIndex((e) => e === key), 1);
                                setEmap(em);
                            }
                        }
                        break;
                }
                if (val.dn[vr[1]].total !== "" && val.dn[vr[1]].cancelled !== "") {
                    val.dn[vr[1]].net = parseInt(val.dn[vr[1]].total) - parseInt(val.dn[vr[1]].cancelled);
                }
                break;
            case 'cn':
                switch (vr[2]) {
                    case 'from':
                        val.cn[vr[1]].from = value;
                        break;
                    case 'to':
                        val.cn[vr[1]].to = value;
                        break;
                    case 'total':
                        val.cn[vr[1]].total = value;
                        break;
                    case 'cancelled':
                        val.cn[vr[1]].cancelled = value;
                        let key = "cn[" + vr[1] + "].can";
                        if (parseInt(val.cn[vr[1]].total) < parseInt(value)) {
                            if (!emap.includes(key)) {
                                let em = [...emap];
                                em.push(key);
                                setEmap(em);
                            }
                        } else {
                            if (emap.includes(key)) {
                                let em = [...emap];
                                em.splice(em.findIndex((e) => e === key), 1);
                                setEmap(em);
                            }
                        }
                        break;
                }
                if (val.cn[vr[1]].total !== "" && val.cn[vr[1]].cancelled !== "") {
                    val.cn[vr[1]].net = parseInt(val.cn[vr[1]].total) - parseInt(val.cn[vr[1]].cancelled);
                }
                break;
            case 'rv':
                switch (vr[2]) {
                    case 'from':
                        val.rv[vr[1]].from = value;
                        break;
                    case 'to':
                        val.rv[vr[1]].to = value;
                        break;
                    case 'total':
                        val.rv[vr[1]].total = value;
                        break;
                    case 'cancelled':
                        val.rv[vr[1]].cancelled = value;
                        let key = "rv[" + vr[1] + "].can";
                        if (parseInt(val.rv[vr[1]].total) < parseInt(value)) {
                            if (!emap.includes(key)) {
                                let em = [...emap];
                                em.push(key);
                                setEmap(em);
                            }
                        } else {
                            if (emap.includes(key)) {
                                let em = [...emap];
                                em.splice(em.findIndex((e) => e === key), 1);
                                setEmap(em);
                            }
                        }
                        break;
                }
                if (val.rv[vr[1]].total !== "" && val.rv[vr[1]].cancelled !== "") {
                    val.rv[vr[1]].net = parseInt(val.rv[vr[1]].total) - parseInt(val.rv[vr[1]].cancelled);
                }
                break;
            case 'pv':
                switch (vr[2]) {
                    case 'from':
                        val.pv[vr[1]].from = value;
                        break;
                    case 'to':
                        val.pv[vr[1]].to = value;
                        break;
                    case 'total':
                        val.pv[vr[1]].total = value;
                        break;
                    case 'cancelled':
                        val.pv[vr[1]].cancelled = value;
                        let key = "pv[" + vr[1] + "].can";
                        if (parseInt(val.pv[vr[1]].total) < parseInt(value)) {
                            if (!emap.includes(key)) {
                                let em = [...emap];
                                em.push(key);
                                setEmap(em);
                            }
                        } else {
                            if (emap.includes(key)) {
                                let em = [...emap];
                                em.splice(em.findIndex((e) => e === key), 1);
                                setEmap(em);
                            }
                        }
                        break;
                }
                if (val.pv[vr[1]].total !== "" && val.pv[vr[1]].cancelled !== "") {
                    val.pv[vr[1]].net = parseInt(val.pv[vr[1]].total) - parseInt(val.pv[vr[1]].cancelled);
                }
                break;
            case 'rfv':
                switch (vr[2]) {
                    case 'from':
                        val.rfv[vr[1]].from = value;
                        break;
                    case 'to':
                        val.rfv[vr[1]].to = value;
                        break;
                    case 'total':
                        val.rfv[vr[1]].total = value;
                        break;
                    case 'cancelled':
                        val.rfv[vr[1]].cancelled = value;
                        let key = "rfv[" + vr[1] + "].can";
                        if (parseInt(val.rfv[vr[1]].total) < parseInt(value)) {
                            if (!emap.includes(key)) {
                                let em = [...emap];
                                em.push(key);
                                setEmap(em);
                            }
                        } else {
                            if (emap.includes(key)) {
                                let em = [...emap];
                                em.splice(em.findIndex((e) => e === key), 1);
                                setEmap(em);
                            }
                        }
                        break;
                }
                if (val.rfv[vr[1]].total !== "" && val.rfv[vr[1]].cancelled !== "") {
                    val.rfv[vr[1]].net = parseInt(val.rfv[vr[1]].total) - parseInt(val.rfv[vr[1]].cancelled);
                }
                break;
            case 'dchw':
                switch (vr[2]) {
                    case 'from':
                        val.dchw[vr[1]].from = value;
                        break;
                    case 'to':
                        val.dchw[vr[1]].to = value;
                        break;
                    case 'total':
                        val.dchw[vr[1]].total = value;
                        break;
                    case 'cancelled':
                        val.dchw[vr[1]].cancelled = value;
                        let key = "dchw[" + vr[1] + "].can";
                        if (parseInt(val.dchw[vr[1]].total) < parseInt(value)) {
                            if (!emap.includes(key)) {
                                let em = [...emap];
                                em.push(key);
                                setEmap(em);
                            }
                        } else {
                            if (emap.includes(key)) {
                                let em = [...emap];
                                em.splice(em.findIndex((e) => e === key), 1);
                                setEmap(em);
                            }
                        }
                        break;
                }
                if (val.dchw[vr[1]].total !== "" && val.dchw[vr[1]].cancelled !== "") {
                    val.dchw[vr[1]].net = parseInt(val.dchw[vr[1]].total) - parseInt(val.dchw[vr[1]].cancelled);
                }
                break;
            case 'dch':
                switch (vr[2]) {
                    case 'from':
                        val.dch[vr[1]].from = value;
                        break;
                    case 'to':
                        val.dch[vr[1]].to = value;
                        break;
                    case 'total':
                        val.dch[vr[1]].total = value;
                        break;
                    case 'cancelled':
                        val.dch[vr[1]].cancelled = value;
                        let key = "dch[" + vr[1] + "].can";
                        if (parseInt(val.dch[vr[1]].total) < parseInt(value)) {
                            if (!emap.includes(key)) {
                                let em = [...emap];
                                em.push(key);
                                setEmap(em);
                            }
                        } else {
                            if (emap.includes(key)) {
                                let em = [...emap];
                                em.splice(em.findIndex((e) => e === key), 1);
                                setEmap(em);
                            }
                        }
                        break;
                }
                if (val.dch[vr[1]].total !== "" && val.dch[vr[1]].cancelled !== "") {
                    val.dch[vr[1]].net = parseInt(val.dch[vr[1]].total) - parseInt(val.dch[vr[1]].cancelled);
                }
                break;
            case 'dchg':
                switch (vr[2]) {
                    case 'from':
                        val.dchg[vr[1]].from = value;
                        break;
                    case 'to':
                        val.dchg[vr[1]].to = value;
                        break;
                    case 'total':
                        val.dchg[vr[1]].total = value;
                        break;
                    case 'cancelled':
                        val.dchg[vr[1]].cancelled = value;
                        let key = "dchg[" + vr[1] + "].can";
                        if (parseInt(val.dchg[vr[1]].total) < parseInt(value)) {
                            if (!emap.includes(key)) {
                                let em = [...emap];
                                em.push(key);
                                setEmap(em);
                            }
                        } else {
                            if (emap.includes(key)) {
                                let em = [...emap];
                                em.splice(em.findIndex((e) => e === key), 1);
                                setEmap(em);
                            }
                        }
                        break;
                }
                if (val.dchg[vr[1]].total !== "" && val.dchg[vr[1]].cancelled !== "") {
                    val.dchg[vr[1]].net = parseInt(val.dchg[vr[1]].total) - parseInt(val.dchg[vr[1]].cancelled);
                }
                break;
            case 'dcho':
                switch (vr[2]) {
                    case 'from':
                        val.dcho[vr[1]].from = value;
                        break;
                    case 'to':
                        val.dcho[vr[1]].to = value;
                        break;
                    case 'total':
                        val.dcho[vr[1]].total = value;
                        break;
                    case 'cancelled':
                        val.dcho[vr[1]].cancelled = value;
                        let key = "dcho[" + vr[1] + "].can";
                        if (parseInt(val.dcho[vr[1]].total) < parseInt(value)) {
                            if (!emap.includes(key)) {
                                let em = [...emap];
                                em.push(key);
                                setEmap(em);
                            }
                        } else {
                            if (emap.includes(key)) {
                                let em = [...emap];
                                em.splice(em.findIndex((e) => e === key), 1);
                                setEmap(em);
                            }
                        }
                        break;
                }
                if (val.dcho[vr[1]].total !== "" && val.dcho[vr[1]].cancelled !== "") {
                    val.dcho[vr[1]].net = parseInt(val.dcho[vr[1]].total) - parseInt(val.dcho[vr[1]].cancelled);
                }
                break;
        }
        setInitVal(val);
    }

    const handeleSubmit = (e) => {
        e.preventDefault();
        setInvno('');
        formVSchema.isValid(initVal).then(function (valid) {
            console.log(valid);
            if (valid) {
                setSubmit(true);
                setLoading(true);
                window.scrollTo({
                    top: 0,
                    behavior: 'smooth',
                });

                let body = {};
                let count = 0;

                let recs = [];

                console.log(initVal.ios);

                if (initVal.ios.length !== 0) {
                    recs = [...recs, ...initVal.ios];
                }
                if (initVal.iisup.length !== 0) {
                    recs = [...recs, ...initVal.iisup];
                }
                if (initVal.rev.length !== 0) {
                    recs = [...recs, ...initVal.rev];
                }
                if (initVal.dn.length !== 0) {
                    recs = [...recs, ...initVal.dn];
                }
                if (initVal.cn.length !== 0) {
                    recs = [...recs, ...initVal.cn];
                }
                if (initVal.rv.length !== 0) {
                    recs = [...recs, ...initVal.iisup];
                }
                if (initVal.pv.length !== 0) {
                    recs = [...recs, ...initVal.pv];
                }
                if (initVal.rfv.length !== 0) {
                    recs = [...recs, ...initVal.rfv];
                }
                if (initVal.dchw.length !== 0) {
                    recs = [...recs, ...initVal.dchw];
                }
                if (initVal.dch.length !== 0) {
                    recs = [...recs, ...initVal.dch];
                }
                if (initVal.dchg.length !== 0) {
                    recs = [...recs, ...initVal.dchg];
                }
                if (initVal.dcho.length !== 0) {
                    recs = [...recs, ...initVal.dcho];
                }

                console.log(recs);

                if (isEdit) {
                    body = {
                        month: gDate,
                        lid: userID,
                        type: 'a13',
                    }
                    returnService.deleteAllA13GstrDoc(body).then((res) => {
                        if (res.status) {
                            recs.forEach((val, index) => {
                                setTimeout(function () {
                                    body = {
                                        month: gDate,
                                        lid: userID,
                                        type: 'a13',
                                        data: val,
                                    }
                                    returnService.setGstrDoc(body).then((res) => {
                                        console.log(res);
                                        if (res.status) {
                                            count = count + 1;
                                            if (recs.length >= count) {
                                                showAlert();
                                                setIsAdd(false);
                                                setSubmit(false);
                                                setLoading(false);
                                                window.scrollTo({
                                                    top: 0,
                                                    behavior: 'smooth',
                                                });
                                            }
                                        } else {
                                            setDigError({ title: 'Error', msg: 'Server error please try again!', isToggle: true });
                                            setSubmit(false);
                                            window.scrollTo({
                                                top: 0,
                                                behavior: 'smooth',
                                            });
                                        }
                                    });
                                }, parseInt(index) * 100);
                            });
                        } else {
                            setDigError({ title: 'Error', msg: 'Server error please try again!', isToggle: true });
                            setSubmit(false);
                            window.scrollTo({
                                top: 0,
                                behavior: 'smooth',
                            });
                        }
                    });
                } else {
                    recs.forEach((val, index) => {
                        setTimeout(function () {
                            body = {
                                month: gDate,
                                lid: userID,
                                type: 'a13',
                                data: val,
                            }
                            returnService.setGstrDoc(body).then((res) => {
                                console.log(res);
                                if (res.status) {
                                    count = count + 1;
                                    if (recs.length >= count) {
                                        showAlert();
                                        setIsAdd(false);
                                        setSubmit(false);
                                        setLoading(false);
                                        window.scrollTo({
                                            top: 0,
                                            behavior: 'smooth',
                                        });
                                    }
                                } else {
                                    setDigError({ title: 'Error', msg: 'Server error please try again!', isToggle: true });
                                    setSubmit(false);
                                    window.scrollTo({
                                        top: 0,
                                        behavior: 'smooth',
                                    });
                                }
                            });
                        }, parseInt(index) * 100);
                    });
                }
            } else {
                setInvno('Please enter all the mandatory fields.');
            }
        })
    }

    const showAlert = () => {
        setIsAlrt(true);
        setTimeout(
            () => setIsAlrt(false),
            5000
        );
    }

    const addRow = (type) => {
        setInvno('');
        if (!isAdd) {
            setIsAdd(true);
            setIsValid(false);
            pushDoc(type);
        } else {
            formVSchema.isValid(initVal).then(function (valid) {
                if (valid) {
                    setIsValid(false);
                    pushDoc(type);
                } else {
                    setIsValid(true);
                }
            });
        }
    }

    const pushDoc = (type) => {
        let val = { ...initVal };
        switch (type) {
            case 'ios':
                val.ios.push({ from: '', to: '', total: '', cancelled: '', net: '', type: type });
                setInitVal(val);
                break;
            case 'iisup':
                val.iisup.push({ from: '', to: '', total: '', cancelled: '', net: '', type: type });
                setInitVal(val);
                break;
            case 'rev':
                val.rev.push({ from: '', to: '', total: '', cancelled: '', net: '', type: type });
                setInitVal(val);
                break;
            case 'dn':
                val.dn.push({ from: '', to: '', total: '', cancelled: '', net: '', type: type });
                setInitVal(val);
                break;
            case 'cn':
                val.cn.push({ from: '', to: '', total: '', cancelled: '', net: '', type: type });
                setInitVal(val);
                break;
            case 'rv':
                val.rv.push({ from: '', to: '', total: '', cancelled: '', net: '', type: type });
                setInitVal(val);
                break;
            case 'pv':
                val.pv.push({ from: '', to: '', total: '', cancelled: '', net: '', type: type });
                setInitVal(val);
                break;
            case 'rfv':
                val.rfv.push({ from: '', to: '', total: '', cancelled: '', net: '', type: type });
                setInitVal(val);
                break;
            case 'dchw':
                val.dchw.push({ from: '', to: '', total: '', cancelled: '', net: '', type: type });
                setInitVal(val);
                break;
            case 'dch':
                val.dch.push({ from: '', to: '', total: '', cancelled: '', net: '', type: type });
                setInitVal(val);
                break;
            case 'dchg':
                val.dchg.push({ from: '', to: '', total: '', cancelled: '', net: '', type: type });
                setInitVal(val);
                break;
            case 'dcho':
                val.dcho.push({ from: '', to: '', total: '', cancelled: '', net: '', type: type });
                setInitVal(val);
                break;
        }
    }

    const popDoc = (type, index) => {
        let val = { ...initVal };
        let ty = []
        switch (type) {
            case 'ios':
                ty = [...val.ios];
                ty.splice(index, 1);
                val.ios = ty;
                setInitVal(val);
                break;
            case 'iisup':
                ty = [...val.iisup];
                ty.splice(index, 1);
                val.iisup = ty;
                setInitVal(val);
                break;
            case 'rev':
                ty = [...val.rev];
                ty.splice(index, 1);
                val.rev = ty;
                setInitVal(val);
                break;
            case 'dn':
                ty = [...val.dn];
                ty.splice(index, 1);
                val.dn = ty;
                setInitVal(val);
                break;
            case 'cn':
                ty = [...val.cn];
                ty.splice(index, 1);
                val.cn = ty;
                setInitVal(val);
                break;
            case 'rv':
                ty = [...val.rv];
                ty.splice(index, 1);
                val.rv = ty;
                setInitVal(val);
                break;
            case 'pv':
                ty = [...val.pv];
                ty.splice(index, 1);
                val.pv = ty;
                setInitVal(val);
                break;
            case 'rfv':
                ty = [...val.rfv];
                ty.splice(index, 1);
                val.rfv = ty;
                setInitVal(val);
                break;
            case 'dchw':
                ty = [...val.dchw];
                ty.splice(index, 1);
                val.dchw = ty;
                setInitVal(val);
                break;
            case 'dch':
                ty = [...val.dch];
                ty.splice(index, 1);
                val.dch = ty;
                setInitVal(val);
                break;
            case 'dchg':
                ty = [...val.dchg];
                ty.splice(index, 1);
                val.dchg = ty;
                setInitVal(val);
                break;
            case 'dcho':
                ty = [...val.dcho];
                ty.splice(index, 1);
                val.dcho = ty;
                setInitVal(val);
                break;
        }
    }

    const showDig = (type, data, title) => {
        setDigConfirm({ 'type': type, title: title, isToggle: true, data: data });
    }

    const cancel = () => {
        setDigConfirm({ 'type': '0', title: '', isToggle: false, data: null });
    }

    const process = () => {
        switch (digConfirm.type) {
            case '1':
                let val = { ...initVal };
                switch (digConfirm.data['type']) {
                    case 'ios':
                        popDoc('ios', digConfirm.data['id']);
                        break;
                    case 'iisup':
                        popDoc('iisup', digConfirm.data['id']);
                        break;
                    case 'rev':
                        popDoc('rev', digConfirm.data['id']);
                        break;
                    case 'dn':
                        popDoc('dn', digConfirm.data['id']);
                        break;
                    case 'cn':
                        popDoc('cn', digConfirm.data['id']);
                        break;
                    case 'rv':
                        popDoc('rv', digConfirm.data['id']);
                        break;
                    case 'pv':
                        popDoc('pv', digConfirm.data['id']);
                        break;
                    case 'rfv':
                        popDoc('rfv', digConfirm.data['id']);
                        break;
                    case 'dchw':
                        popDoc('dchw', digConfirm.data['id']);
                        break;
                    case 'dch':
                        popDoc('dch', digConfirm.data['id']);
                        break;
                    case 'dchg':
                        popDoc('dchg', digConfirm.data['id']);
                        break;
                    case 'dcho':
                        popDoc('dcho', digConfirm.data['id']);
                        break;
                }
                break;
            case '2':
                gotoPath('gstrForm');
                break;
            default:
                break;
        }
        setDigConfirm({ 'type': '0', title: '', isToggle: false, data: null });
    }

    const processRecords = (items) => {
        let data = {
            ios: [],
            iisup: [],
            rev: [],
            dn: [],
            cn: [],
            rv: [],
            pv: [],
            rfv: [],
            dchw: [],
            dch: [],
            dchg: [],
            dcho: [],
        };

        items.forEach((item) => {
            if (item.data.type === "ios") {
                data.ios.push(item.data);
            }
            if (item.data.type === "iisup") {
                data.iisup.push(item.data);
            }
            if (item.data.type === "rev") {
                data.rev.push(item.data);
            }
            if (item.data.type === "dn") {
                data.dn.push(item.data);
            }
            if (item.data.type === "cn") {
                data.cn.push(item.data);
            }
            if (item.data.type === "rv") {
                data.rv.push(item.data);
            }
            if (item.data.type === "pv") {
                data.pv.push(item.data);
            }
            if (item.data.type === "rfv") {
                data.rfv.push(item.data);
            }
            if (item.data.type === "dchw") {
                data.dchw.push(item.data);
            }
            if (item.data.type === "dch") {
                data.dch.push(item.data);
            }
            if (item.data.type === "dchg") {
                data.dchg.push(item.data);
            }
            if (item.data.type === "dcho") {
                data.dcho.push(item.data);
            }
        });

        if (items.length > 0) {
            setIsEdit(true);
        }

        setInitVal(data);
    }

    const loadData = () => {
        (async function () {
            setLoading(true);

            let bodyB = {
                lid: userID,
                month: gDate,
                type: 'a13'
            }
            var res = await returnService.getGstrDocs(bodyB);
            processRecords(res.data);

            setLoading(false);
            window.scrollTo({
                top: 0,
                behavior: 'smooth',
            });
        })();
    }

    useEffect(() => {
        loadData();
    }, []);

    return (
        <div class="content-wrapper fade-ng-cloak">
            <Dialog open={digConfirm.isToggle} onClose={() => setDigConfirm({ 'type': '0', isToggle: false, data: null })}>
                <div id="confirmDlg" class="modal fade fade-scale in" role="dialog" style={{ display: 'block', paddingRight: '17px' }}>
                    <div class="modal-dialog sweet">
                        <div class="modal-content">
                            <div class="modal-body">
                                <div class="m-icon m-warning pulseWarning">
                                    <span class="micon-body pulseWarningIns"></span>
                                    <span class="micon-dot pulseWarningIns"></span>
                                </div>
                                <h2>Warning</h2>
                                <p>{digConfirm.title}</p>
                            </div>
                            <div class="modal-footer">
                                <a class="btn btn-default" onClick={() => { cancel() }}>Cancel</a>
                                <a class="btn btn-primary" onClick={() => { process() }}>{digConfirm.type === "2" ? "OK" : "Proceed"}</a>
                            </div>
                        </div>
                    </div>
                </div>
            </Dialog>
            <div class="dimmer-holder" style={{ display: 'none' }}>
                <div id="dimmer"></div>
            </div>
            {
                loading && <div className='absolute z-50 w-full h-screen flex items-center justify-center bg-gray-200 bg-opacity-25'>
                    <img src={imgLoader} alt="loader" />
                </div>
            }
            <div class="container">
                <div class="mypage">
                    <div class="row" data-ng-controller="transctrl" data-ng-init="init('returns')">
                        <div class="col-xs-10">
                            <div data-breadcrumb="" name="Dashboard">
                                <ol class="breadcrumb" data-ng-controller="crumbCtrl">
                                    <li onClick={() => {
                                        gotoPath('gstr')
                                    }}><a>Dashboard</a></li>
                                    <li onClick={() => {
                                        gotoPath('gstRetrun')
                                    }}><a>Returns</a></li>
                                    <li onClick={() => {
                                        gotoPath('gstrForm')
                                    }}><a>GSTR-1/IFF</a></li>
                                    <li>Documents Issued</li>
                                </ol>
                            </div>
                        </div>
                        <div class="col-xs-2">
                            <div class="lang dropdown">
                                <span class="dropdown-toggle" data-toggle="dropdown" data-ng-bind="selectedLang">English</span>
                                <ul class="dropdown-menu lang-dpdwn">
                                    <li data-ng-repeat="language in languages">
                                        <a data-ng-click="selectLang(language.cd)" data-ng-bind="language.nm">English</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div>
                            {isAlert && <alert-message ng-show="showMsg" title="" type="msg alert-success" message="" class="ng-hide"><div class="alert alert-msg alert-success">Request accepted successfully<a class="close" onClick={() => { setIsAlrt(false) }} data-dismiss="alert" aria-label="close">×</a><strong></strong>.</div></alert-message>}
                            <form name="docPage" id="docPage" novalidate="" onSubmit={handeleSubmit} autocomplete="off">
                                <div class="row invsumm">
                                    <div class="col-xs-12 col-sm-12 taxp">
                                        <h4 data-ng-bind="trans.HEAD_DOC_ISSUED">13 - Documents issued during the tax period </h4>
                                        <button class="btn btn-primary btn-circle btn-sm pull-right" data-toggle="tooltip" title="Refresh" disabled={true} style={{ marginTop: '0px' }}>
                                            <i class="fa fa-refresh" aria-hidden="true"></i>
                                        </button>
                                    </div>
                                </div>
                                <div class="tabpane">
                                    <div class="row">
                                        <span class="col-xs-12 col-sm-12">
                                            Note: Kindly click on save button after any modification( add, edit, delete) to save the changes
                                        </span>
                                    </div>
                                    <div class="row">
                                        <div class="col-lg-12 col-md-12 col-sm-12">
                                            <fieldset disabled={fileStatus}>
                                                <div class="table-responsive">
                                                    <div>
                                                        <table class="table inv exp tbl table-bordered" style={{ display: 'table' }}>
                                                            <caption>
                                                                <h4>1. Invoices for outward supply</h4>
                                                            </caption>
                                                            <thead>
                                                                <tr>
                                                                    <th rowspan="2" class="text-center">No.</th>
                                                                    <th colspan="2" class="text-center m-cir" data-ng-bind="trans.HEAD_SN">Sr. No.</th>
                                                                    <th rowspan="2" class="text-center m-cir" data-ng-bind="trans.HEAD_TOT_NUM">Total number</th>
                                                                    <th rowspan="2" class="text-center m-cir" data-ng-bind="trans.HEAD_CAN">Cancelled</th>
                                                                    <th rowspan="2" class="text-center m-cir" data-ng-bind="trans.HEAD_NET_ISSUED">Net issued</th>
                                                                    <th rowspan="2" class="text-center" data-ng-bind="trans.HEAD_ACTION">Action</th>
                                                                </tr>
                                                                <tr>
                                                                    <th class="text-center">From</th>
                                                                    <th class="text-center">To</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    initVal.ios.length === 0 && <tr>
                                                                        <td colspan="7">
                                                                            <div class="alert alert-msg alert-info alert-dismissible">There are no documents to be displayed.</div>
                                                                        </td>
                                                                    </tr>
                                                                }
                                                                {
                                                                    initVal.ios.length !== 0 && initVal.ios.map((item, index) => {
                                                                        return <tr key={index}>
                                                                            <td class="text-center">
                                                                                <div>
                                                                                    {(index + 1)}
                                                                                </div>
                                                                            </td>
                                                                            <td>
                                                                                <div>
                                                                                    <input type="text" class="form-control" name={`ios.${index}.from`} value={initVal.ios[index].from} onChange={(e) => {
                                                                                        const formatValue = getTFormatvalue(e.target.value);
                                                                                        e.target.value = formatValue;
                                                                                        handleOnChangeForm(e)
                                                                                    }} />
                                                                                    {(isValid && initVal.ios[index].from === "") && <span className='err'>This information is required</span>}
                                                                                </div>
                                                                            </td>
                                                                            <td>
                                                                                <div>
                                                                                    <input type="text" class="form-control" name={`ios.${index}.to`} value={initVal.ios[index].to} onChange={(e) => {
                                                                                        const formatValue = getTFormatvalue(e.target.value);
                                                                                        e.target.value = formatValue;
                                                                                        handleOnChangeForm(e)
                                                                                    }} />
                                                                                    {(isValid && initVal.ios[index].to === "") && <span className='err'>This information is required</span>}
                                                                                </div>
                                                                            </td>
                                                                            <td>
                                                                                <div>
                                                                                    <input type="text" class="form-control" name={`ios.${index}.total`} value={initVal.ios[index].total} onChange={(e) => {
                                                                                        const formatValue = getFormatvalue(e.target.value);
                                                                                        e.target.value = formatValue;
                                                                                        handleOnChangeForm(e);
                                                                                    }} />
                                                                                    {(isValid && initVal.ios[index].total === "") && <span className='err'>This information is required</span>}
                                                                                </div>
                                                                            </td>
                                                                            <td>
                                                                                <div>
                                                                                    <input type="text" class="form-control" name={`ios.${index}.cancelled`} value={initVal.ios[index].cancelled} onChange={(e) => {
                                                                                        const formatValue = getFormatvalue(e.target.value);
                                                                                        e.target.value = formatValue;
                                                                                        handleOnChangeForm(e);
                                                                                    }} />
                                                                                    {(isValid && initVal.ios[index].cancelled === "") && <span className='err'>This information is required</span>}
                                                                                    {(emap.includes("ios[" + index + "].can")) && <span className='err'>Cancelled docs can not be greater than total docs</span>}
                                                                                </div>
                                                                            </td>
                                                                            <td>
                                                                                <div>
                                                                                    <input type="text" class="form-control" name={`ios.${index}.cancelled`} value={initVal.ios[index].net} disabled={true} />
                                                                                </div>
                                                                            </td>
                                                                            <td class="text-center">
                                                                                <div className='flex'>
                                                                                    <button type="button" class="btn btn-sm btn-danger nomargin" title="Delete" onClick={() => {
                                                                                        showDig('1', { type: 'ios', id: index }, 'Are you sure, you want to continue?');
                                                                                    }} disabled={fileStatus}>
                                                                                        <i class="fa fa-trash"></i><span class="sr-only">text</span>
                                                                                    </button>
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    })
                                                                }
                                                            </tbody>
                                                        </table>
                                                        <div class="row">
                                                            <div class="col-sm-12 text-right">
                                                                <button type="button" class="btn btn-primary" onClick={() => {
                                                                    addRow('ios');
                                                                }} disabled={initVal.ios.length === 5 || fileStatus}>Add Document</button>
                                                            </div>
                                                        </div>
                                                        <hr />
                                                    </div>
                                                    <div>
                                                        <table class="table inv exp tbl table-bordered" style={{ display: 'table' }}>
                                                            <caption>
                                                                <h4>2. Invoices for inward supply from unregistered person</h4>
                                                            </caption>
                                                            <thead>
                                                                <tr>
                                                                    <th rowspan="2" class="text-center">No.</th>
                                                                    <th colspan="2" class="text-center m-cir" data-ng-bind="trans.HEAD_SN">Sr. No.</th>
                                                                    <th rowspan="2" class="text-center m-cir" data-ng-bind="trans.HEAD_TOT_NUM">Total number</th>
                                                                    <th rowspan="2" class="text-center m-cir" data-ng-bind="trans.HEAD_CAN">Cancelled</th>
                                                                    <th rowspan="2" class="text-center m-cir" data-ng-bind="trans.HEAD_NET_ISSUED">Net issued</th>
                                                                    <th rowspan="2" class="text-center" data-ng-bind="trans.HEAD_ACTION">Action</th>
                                                                </tr>
                                                                <tr>
                                                                    <th class="text-center">From</th>
                                                                    <th class="text-center">To</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    initVal.iisup.length === 0 && <tr>
                                                                        <td colspan="7">
                                                                            <div class="alert alert-msg alert-info alert-dismissible">There are no documents to be displayed.</div>
                                                                        </td>
                                                                    </tr>
                                                                }
                                                                {
                                                                    initVal.iisup.length !== 0 && initVal.iisup.map((item, index) => {
                                                                        return <tr key={index}>
                                                                            <td class="text-center">
                                                                                <div>
                                                                                    {(index + 1)}
                                                                                </div>
                                                                            </td>
                                                                            <td>
                                                                                <div>
                                                                                    <input type="text" class="form-control" name={`iisup.${index}.from`} value={initVal.iisup[index].from} onChange={(e) => {
                                                                                        const formatValue = getTFormatvalue(e.target.value);
                                                                                        e.target.value = formatValue;
                                                                                        handleOnChangeForm(e)
                                                                                    }} />
                                                                                    {(isValid && initVal.iisup[index].from === "") && <span className='err'>This information is required</span>}
                                                                                </div>
                                                                            </td>
                                                                            <td>
                                                                                <div>
                                                                                    <input type="text" class="form-control" name={`iisup.${index}.to`} value={initVal.iisup[index].to} onChange={(e) => {
                                                                                        const formatValue = getTFormatvalue(e.target.value);
                                                                                        e.target.value = formatValue;
                                                                                        handleOnChangeForm(e)
                                                                                    }} />
                                                                                    {(isValid && initVal.iisup[index].to === "") && <span className='err'>This information is required</span>}
                                                                                </div>
                                                                            </td>
                                                                            <td>
                                                                                <div>
                                                                                    <input type="text" class="form-control" name={`iisup.${index}.total`} value={initVal.iisup[index].total} onChange={(e) => {
                                                                                        const formatValue = getFormatvalue(e.target.value);
                                                                                        e.target.value = formatValue;
                                                                                        handleOnChangeForm(e);
                                                                                    }} />
                                                                                    {(isValid && initVal.iisup[index].total === "") && <span className='err'>This information is required</span>}
                                                                                </div>
                                                                            </td>
                                                                            <td>
                                                                                <div>
                                                                                    <input type="text" class="form-control" name={`iisup.${index}.cancelled`} value={initVal.iisup[index].cancelled} onChange={(e) => {
                                                                                        const formatValue = getFormatvalue(e.target.value);
                                                                                        e.target.value = formatValue;
                                                                                        handleOnChangeForm(e);
                                                                                    }} />
                                                                                    {(isValid && initVal.iisup[index].cancelled === "") && <span className='err'>This information is required</span>}
                                                                                    {(emap.includes("iisup[" + index + "].can")) && <span className='err'>Cancelled docs can not be greater than total docs</span>}
                                                                                </div>
                                                                            </td>
                                                                            <td>
                                                                                <div>
                                                                                    <input type="text" class="form-control" name={`iisup.${index}.cancelled`} value={initVal.iisup[index].net} disabled={true} />
                                                                                </div>
                                                                            </td>
                                                                            <td class="text-center">
                                                                                <div className='flex'>
                                                                                    <button type="button" class="btn btn-sm btn-danger nomargin" title="Delete" onClick={() => {
                                                                                        showDig('1', { type: 'iisup', id: index }, 'Are you sure, you want to continue?');
                                                                                    }} disabled={fileStatus}>
                                                                                        <i class="fa fa-trash"></i><span class="sr-only">text</span>
                                                                                    </button>
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    })
                                                                }
                                                            </tbody>
                                                        </table>
                                                        <div class="row">
                                                            <div class="col-sm-12 text-right">
                                                                <button type="button" class="btn btn-primary" onClick={() => {
                                                                    addRow('iisup');
                                                                }} disabled={initVal.iisup.length === 5 || fileStatus}>Add Document</button>
                                                            </div>
                                                        </div>
                                                        <hr />
                                                    </div>
                                                    <div>
                                                        <table class="table inv exp tbl table-bordered" style={{ display: 'table' }}>
                                                            <caption>
                                                                <h4>3. Revised Invoice</h4>
                                                            </caption>
                                                            <thead>
                                                                <tr>
                                                                    <th rowspan="2" class="text-center">No.</th>
                                                                    <th colspan="2" class="text-center m-cir" data-ng-bind="trans.HEAD_SN">Sr. No.</th>
                                                                    <th rowspan="2" class="text-center m-cir" data-ng-bind="trans.HEAD_TOT_NUM">Total number</th>
                                                                    <th rowspan="2" class="text-center m-cir" data-ng-bind="trans.HEAD_CAN">Cancelled</th>
                                                                    <th rowspan="2" class="text-center m-cir" data-ng-bind="trans.HEAD_NET_ISSUED">Net issued</th>
                                                                    <th rowspan="2" class="text-center" data-ng-bind="trans.HEAD_ACTION">Action</th>
                                                                </tr>
                                                                <tr>
                                                                    <th class="text-center">From</th>
                                                                    <th class="text-center">To</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    initVal.rev.length === 0 && <tr>
                                                                        <td colspan="7">
                                                                            <div class="alert alert-msg alert-info alert-dismissible">There are no documents to be displayed.</div>
                                                                        </td>
                                                                    </tr>
                                                                }
                                                                {
                                                                    initVal.rev.length !== 0 && initVal.rev.map((item, index) => {
                                                                        return <tr key={index}>
                                                                            <td class="text-center">
                                                                                <div>
                                                                                    {(index + 1)}
                                                                                </div>
                                                                            </td>
                                                                            <td>
                                                                                <div>
                                                                                    <input type="text" class="form-control" name={`rev.${index}.from`} value={initVal.rev[index].from} onChange={(e) => {
                                                                                        const formatValue = getTFormatvalue(e.target.value);
                                                                                        e.target.value = formatValue;
                                                                                        handleOnChangeForm(e)
                                                                                    }} />
                                                                                    {(isValid && initVal.rev[index].from === "") && <span className='err'>This information is required</span>}
                                                                                </div>
                                                                            </td>
                                                                            <td>
                                                                                <div>
                                                                                    <input type="text" class="form-control" name={`rev.${index}.to`} value={initVal.rev[index].to} onChange={(e) => {
                                                                                        const formatValue = getTFormatvalue(e.target.value);
                                                                                        e.target.value = formatValue;
                                                                                        handleOnChangeForm(e)
                                                                                    }} />
                                                                                    {(isValid && initVal.rev[index].to === "") && <span className='err'>This information is required</span>}
                                                                                </div>
                                                                            </td>
                                                                            <td>
                                                                                <div>
                                                                                    <input type="text" class="form-control" name={`rev.${index}.total`} value={initVal.rev[index].total} onChange={(e) => {
                                                                                        const formatValue = getFormatvalue(e.target.value);
                                                                                        e.target.value = formatValue;
                                                                                        handleOnChangeForm(e);
                                                                                    }} />
                                                                                    {(isValid && initVal.rev[index].total === "") && <span className='err'>This information is required</span>}
                                                                                </div>
                                                                            </td>
                                                                            <td>
                                                                                <div>
                                                                                    <input type="text" class="form-control" name={`rev.${index}.cancelled`} value={initVal.rev[index].cancelled} onChange={(e) => {
                                                                                        const formatValue = getFormatvalue(e.target.value);
                                                                                        e.target.value = formatValue;
                                                                                        handleOnChangeForm(e);
                                                                                    }} />
                                                                                    {(isValid && initVal.rev[index].cancelled === "") && <span className='err'>This information is required</span>}
                                                                                    {(emap.includes("rev[" + index + "].can")) && <span className='err'>Cancelled docs can not be greater than total docs</span>}
                                                                                </div>
                                                                            </td>
                                                                            <td>
                                                                                <div>
                                                                                    <input type="text" class="form-control" name={`rev.${index}.cancelled`} value={initVal.rev[index].net} disabled={true} />
                                                                                </div>
                                                                            </td>
                                                                            <td class="text-center">
                                                                                <div className='flex'>
                                                                                    <button type="button" class="btn btn-sm btn-danger nomargin" title="Delete" onClick={() => {
                                                                                        showDig('1', { type: 'rev', id: index }, 'Are you sure, you want to continue?');
                                                                                    }} disabled={fileStatus}>
                                                                                        <i class="fa fa-trash"></i><span class="sr-only">text</span>
                                                                                    </button>
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    })
                                                                }
                                                            </tbody>
                                                        </table>
                                                        <div class="row">
                                                            <div class="col-sm-12 text-right">
                                                                <button type="button" class="btn btn-primary" onClick={() => {
                                                                    addRow('rev');
                                                                }} disabled={initVal.rev.length === 5 || fileStatus}>Add Document</button>
                                                            </div>
                                                        </div>
                                                        <hr />
                                                    </div>
                                                    <div>
                                                        <table class="table inv exp tbl table-bordered" style={{ display: 'table' }}>
                                                            <caption>
                                                                <h4>4. Debit Note</h4>
                                                            </caption>
                                                            <thead>
                                                                <tr>
                                                                    <th rowspan="2" class="text-center">No.</th>
                                                                    <th colspan="2" class="text-center m-cir" data-ng-bind="trans.HEAD_SN">Sr. No.</th>
                                                                    <th rowspan="2" class="text-center m-cir" data-ng-bind="trans.HEAD_TOT_NUM">Total number</th>
                                                                    <th rowspan="2" class="text-center m-cir" data-ng-bind="trans.HEAD_CAN">Cancelled</th>
                                                                    <th rowspan="2" class="text-center m-cir" data-ng-bind="trans.HEAD_NET_ISSUED">Net issued</th>
                                                                    <th rowspan="2" class="text-center" data-ng-bind="trans.HEAD_ACTION">Action</th>
                                                                </tr>
                                                                <tr>
                                                                    <th class="text-center">From</th>
                                                                    <th class="text-center">To</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    initVal.dn.length === 0 && <tr>
                                                                        <td colspan="7">
                                                                            <div class="alert alert-msg alert-info alert-dismissible">There are no documents to be displayed.</div>
                                                                        </td>
                                                                    </tr>
                                                                }
                                                                {
                                                                    initVal.dn.length !== 0 && initVal.dn.map((item, index) => {
                                                                        return <tr key={index}>
                                                                            <td class="text-center">
                                                                                <div>
                                                                                    {(index + 1)}
                                                                                </div>
                                                                            </td>
                                                                            <td>
                                                                                <div>
                                                                                    <input type="text" class="form-control" name={`dn.${index}.from`} value={initVal.dn[index].from} onChange={(e) => {
                                                                                        const formatValue = getTFormatvalue(e.target.value);
                                                                                        e.target.value = formatValue;
                                                                                        handleOnChangeForm(e)
                                                                                    }} />
                                                                                    {(isValid && initVal.dn[index].from === "") && <span className='err'>This information is required</span>}
                                                                                </div>
                                                                            </td>
                                                                            <td>
                                                                                <div>
                                                                                    <input type="text" class="form-control" name={`dn.${index}.to`} value={initVal.dn[index].to} onChange={(e) => {
                                                                                        const formatValue = getTFormatvalue(e.target.value);
                                                                                        e.target.value = formatValue;
                                                                                        handleOnChangeForm(e)
                                                                                    }} />
                                                                                    {(isValid && initVal.dn[index].to === "") && <span className='err'>This information is required</span>}
                                                                                </div>
                                                                            </td>
                                                                            <td>
                                                                                <div>
                                                                                    <input type="text" class="form-control" name={`dn.${index}.total`} value={initVal.dn[index].total} onChange={(e) => {
                                                                                        const formatValue = getFormatvalue(e.target.value);
                                                                                        e.target.value = formatValue;
                                                                                        handleOnChangeForm(e);
                                                                                    }} />
                                                                                    {(isValid && initVal.dn[index].total === "") && <span className='err'>This information is required</span>}
                                                                                </div>
                                                                            </td>
                                                                            <td>
                                                                                <div>
                                                                                    <input type="text" class="form-control" name={`dn.${index}.cancelled`} value={initVal.dn[index].cancelled} onChange={(e) => {
                                                                                        const formatValue = getFormatvalue(e.target.value);
                                                                                        e.target.value = formatValue;
                                                                                        handleOnChangeForm(e);
                                                                                    }} />
                                                                                    {(isValid && initVal.dn[index].cancelled === "") && <span className='err'>This information is required</span>}
                                                                                    {(emap.includes("dn[" + index + "].can")) && <span className='err'>Cancelled docs can not be greater than total docs</span>}
                                                                                </div>
                                                                            </td>
                                                                            <td>
                                                                                <div>
                                                                                    <input type="text" class="form-control" name={`dn.${index}.cancelled`} value={initVal.dn[index].net} disabled={true} />
                                                                                </div>
                                                                            </td>
                                                                            <td class="text-center">
                                                                                <div className='flex'>
                                                                                    <button type="button" class="btn btn-sm btn-danger nomargin" title="Delete" onClick={() => {
                                                                                        showDig('1', { type: 'dn', id: index }, 'Are you sure, you want to continue?');
                                                                                    }} disabled={fileStatus}>
                                                                                        <i class="fa fa-trash"></i><span class="sr-only">text</span>
                                                                                    </button>
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    })
                                                                }
                                                            </tbody>
                                                        </table>
                                                        <div class="row">
                                                            <div class="col-sm-12 text-right">
                                                                <button type="button" class="btn btn-primary" onClick={() => {
                                                                    addRow('dn');
                                                                }} disabled={initVal.dn.length === 5 || fileStatus}>Add Document</button>
                                                            </div>
                                                        </div>
                                                        <hr />
                                                    </div>
                                                    <div>
                                                        <table class="table inv exp tbl table-bordered" style={{ display: 'table' }}>
                                                            <caption>
                                                                <h4>5. Credit Note</h4>
                                                            </caption>
                                                            <thead>
                                                                <tr>
                                                                    <th rowspan="2" class="text-center">No.</th>
                                                                    <th colspan="2" class="text-center m-cir" data-ng-bind="trans.HEAD_SN">Sr. No.</th>
                                                                    <th rowspan="2" class="text-center m-cir" data-ng-bind="trans.HEAD_TOT_NUM">Total number</th>
                                                                    <th rowspan="2" class="text-center m-cir" data-ng-bind="trans.HEAD_CAN">Cancelled</th>
                                                                    <th rowspan="2" class="text-center m-cir" data-ng-bind="trans.HEAD_NET_ISSUED">Net issued</th>
                                                                    <th rowspan="2" class="text-center" data-ng-bind="trans.HEAD_ACTION">Action</th>
                                                                </tr>
                                                                <tr>
                                                                    <th class="text-center">From</th>
                                                                    <th class="text-center">To</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    initVal.cn.length === 0 && <tr>
                                                                        <td colspan="7">
                                                                            <div class="alert alert-msg alert-info alert-dismissible">There are no documents to be displayed.</div>
                                                                        </td>
                                                                    </tr>
                                                                }
                                                                {
                                                                    initVal.cn.length !== 0 && initVal.cn.map((item, index) => {
                                                                        return <tr key={index}>
                                                                            <td class="text-center">
                                                                                <div>
                                                                                    {(index + 1)}
                                                                                </div>
                                                                            </td>
                                                                            <td>
                                                                                <div>
                                                                                    <input type="text" class="form-control" name={`cn.${index}.from`} value={initVal.cn[index].from} onChange={(e) => {
                                                                                        const formatValue = getTFormatvalue(e.target.value);
                                                                                        e.target.value = formatValue;
                                                                                        handleOnChangeForm(e)
                                                                                    }} />
                                                                                    {(isValid && initVal.cn[index].from === "") && <span className='err'>This information is required</span>}
                                                                                </div>
                                                                            </td>
                                                                            <td>
                                                                                <div>
                                                                                    <input type="text" class="form-control" name={`cn.${index}.to`} value={initVal.cn[index].to} onChange={(e) => {
                                                                                        const formatValue = getTFormatvalue(e.target.value);
                                                                                        e.target.value = formatValue;
                                                                                        handleOnChangeForm(e)
                                                                                    }} />
                                                                                    {(isValid && initVal.cn[index].to === "") && <span className='err'>This information is required</span>}
                                                                                </div>
                                                                            </td>
                                                                            <td>
                                                                                <div>
                                                                                    <input type="text" class="form-control" name={`cn.${index}.total`} value={initVal.cn[index].total} onChange={(e) => {
                                                                                        const formatValue = getFormatvalue(e.target.value);
                                                                                        e.target.value = formatValue;
                                                                                        handleOnChangeForm(e);
                                                                                    }} />
                                                                                    {(isValid && initVal.cn[index].total === "") && <span className='err'>This information is required</span>}
                                                                                </div>
                                                                            </td>
                                                                            <td>
                                                                                <div>
                                                                                    <input type="text" class="form-control" name={`cn.${index}.cancelled`} value={initVal.cn[index].cancelled} onChange={(e) => {
                                                                                        const formatValue = getFormatvalue(e.target.value);
                                                                                        e.target.value = formatValue;
                                                                                        handleOnChangeForm(e);
                                                                                    }} />
                                                                                    {(isValid && initVal.cn[index].cancelled === "") && <span className='err'>This information is required</span>}
                                                                                    {(emap.includes("cn[" + index + "].can")) && <span className='err'>Cancelled docs can not be greater than total docs</span>}
                                                                                </div>
                                                                            </td>
                                                                            <td>
                                                                                <div>
                                                                                    <input type="text" class="form-control" name={`cn.${index}.cancelled`} value={initVal.cn[index].net} disabled={true} />
                                                                                </div>
                                                                            </td>
                                                                            <td class="text-center">
                                                                                <div className='flex'>
                                                                                    <button type="button" class="btn btn-sm btn-danger nomargin" title="Delete" onClick={() => {
                                                                                        showDig('1', { type: 'cn', id: index }, 'Are you sure, you want to continue?');
                                                                                    }} disabled={fileStatus}>
                                                                                        <i class="fa fa-trash"></i><span class="sr-only">text</span>
                                                                                    </button>
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    })
                                                                }
                                                            </tbody>
                                                        </table>
                                                        <div class="row">
                                                            <div class="col-sm-12 text-right">
                                                                <button type="button" class="btn btn-primary" onClick={() => {
                                                                    addRow('cn');
                                                                }} disabled={initVal.cn.length === 5 || fileStatus}>Add Document</button>
                                                            </div>
                                                        </div>
                                                        <hr />
                                                    </div>
                                                    <div>
                                                        <table class="table inv exp tbl table-bordered" style={{ display: 'table' }}>
                                                            <caption>
                                                                <h4>6. Receipt voucher</h4>
                                                            </caption>
                                                            <thead>
                                                                <tr>
                                                                    <th rowspan="2" class="text-center">No.</th>
                                                                    <th colspan="2" class="text-center m-cir" data-ng-bind="trans.HEAD_SN">Sr. No.</th>
                                                                    <th rowspan="2" class="text-center m-cir" data-ng-bind="trans.HEAD_TOT_NUM">Total number</th>
                                                                    <th rowspan="2" class="text-center m-cir" data-ng-bind="trans.HEAD_CAN">Cancelled</th>
                                                                    <th rowspan="2" class="text-center m-cir" data-ng-bind="trans.HEAD_NET_ISSUED">Net issued</th>
                                                                    <th rowspan="2" class="text-center" data-ng-bind="trans.HEAD_ACTION">Action</th>
                                                                </tr>
                                                                <tr>
                                                                    <th class="text-center">From</th>
                                                                    <th class="text-center">To</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    initVal.rv.length === 0 && <tr>
                                                                        <td colspan="7">
                                                                            <div class="alert alert-msg alert-info alert-dismissible">There are no documents to be displayed.</div>
                                                                        </td>
                                                                    </tr>
                                                                }
                                                                {
                                                                    initVal.rv.length !== 0 && initVal.rv.map((item, index) => {
                                                                        return <tr key={index}>
                                                                            <td class="text-center">
                                                                                <div>
                                                                                    {(index + 1)}
                                                                                </div>
                                                                            </td>
                                                                            <td>
                                                                                <div>
                                                                                    <input type="text" class="form-control" name={`rv.${index}.from`} value={initVal.rv[index].from} onChange={(e) => {
                                                                                        const formatValue = getTFormatvalue(e.target.value);
                                                                                        e.target.value = formatValue;
                                                                                        handleOnChangeForm(e)
                                                                                    }} />
                                                                                    {(isValid && initVal.rv[index].from === "") && <span className='err'>This information is required</span>}
                                                                                </div>
                                                                            </td>
                                                                            <td>
                                                                                <div>
                                                                                    <input type="text" class="form-control" name={`rv.${index}.to`} value={initVal.rv[index].to} onChange={(e) => {
                                                                                        const formatValue = getTFormatvalue(e.target.value);
                                                                                        e.target.value = formatValue;
                                                                                        handleOnChangeForm(e)
                                                                                    }} />
                                                                                    {(isValid && initVal.rv[index].to === "") && <span className='err'>This information is required</span>}
                                                                                </div>
                                                                            </td>
                                                                            <td>
                                                                                <div>
                                                                                    <input type="text" class="form-control" name={`rv.${index}.total`} value={initVal.rv[index].total} onChange={(e) => {
                                                                                        const formatValue = getFormatvalue(e.target.value);
                                                                                        e.target.value = formatValue;
                                                                                        handleOnChangeForm(e);
                                                                                    }} />
                                                                                    {(isValid && initVal.rv[index].total === "") && <span className='err'>This information is required</span>}
                                                                                </div>
                                                                            </td>
                                                                            <td>
                                                                                <div>
                                                                                    <input type="text" class="form-control" name={`rv.${index}.cancelled`} value={initVal.rv[index].cancelled} onChange={(e) => {
                                                                                        const formatValue = getFormatvalue(e.target.value);
                                                                                        e.target.value = formatValue;
                                                                                        handleOnChangeForm(e);
                                                                                    }} />
                                                                                    {(isValid && initVal.rv[index].cancelled === "") && <span className='err'>This information is required</span>}
                                                                                    {(emap.includes("rv[" + index + "].can")) && <span className='err'>Cancelled docs can not be greater than total docs</span>}
                                                                                </div>
                                                                            </td>
                                                                            <td>
                                                                                <div>
                                                                                    <input type="text" class="form-control" name={`rv.${index}.cancelled`} value={initVal.rv[index].net} disabled={true} />
                                                                                </div>
                                                                            </td>
                                                                            <td class="text-center">
                                                                                <div className='flex'>
                                                                                    <button type="button" class="btn btn-sm btn-danger nomargin" title="Delete" onClick={() => {
                                                                                        showDig('1', { type: 'rv', id: index }, 'Are you sure, you want to continue?');
                                                                                    }} disabled={fileStatus}>
                                                                                        <i class="fa fa-trash"></i><span class="sr-only">text</span>
                                                                                    </button>
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    })
                                                                }
                                                            </tbody>
                                                        </table>
                                                        <div class="row">
                                                            <div class="col-sm-12 text-right">
                                                                <button type="button" class="btn btn-primary" onClick={() => {
                                                                    addRow('rv');
                                                                }} disabled={initVal.rv.length === 5 || fileStatus}>Add Document</button>
                                                            </div>
                                                        </div>
                                                        <hr />
                                                    </div>
                                                    <div>
                                                        <table class="table inv exp tbl table-bordered" style={{ display: 'table' }}>
                                                            <caption>
                                                                <h4>7. Payment Voucher</h4>
                                                            </caption>
                                                            <thead>
                                                                <tr>
                                                                    <th rowspan="2" class="text-center">No.</th>
                                                                    <th colspan="2" class="text-center m-cir" data-ng-bind="trans.HEAD_SN">Sr. No.</th>
                                                                    <th rowspan="2" class="text-center m-cir" data-ng-bind="trans.HEAD_TOT_NUM">Total number</th>
                                                                    <th rowspan="2" class="text-center m-cir" data-ng-bind="trans.HEAD_CAN">Cancelled</th>
                                                                    <th rowspan="2" class="text-center m-cir" data-ng-bind="trans.HEAD_NET_ISSUED">Net issued</th>
                                                                    <th rowspan="2" class="text-center" data-ng-bind="trans.HEAD_ACTION">Action</th>
                                                                </tr>
                                                                <tr>
                                                                    <th class="text-center">From</th>
                                                                    <th class="text-center">To</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    initVal.pv.length === 0 && <tr>
                                                                        <td colspan="7">
                                                                            <div class="alert alert-msg alert-info alert-dismissible">There are no documents to be displayed.</div>
                                                                        </td>
                                                                    </tr>
                                                                }
                                                                {
                                                                    initVal.pv.length !== 0 && initVal.pv.map((item, index) => {
                                                                        return <tr key={index}>
                                                                            <td class="text-center">
                                                                                <div>
                                                                                    {(index + 1)}
                                                                                </div>
                                                                            </td>
                                                                            <td>
                                                                                <div>
                                                                                    <input type="text" class="form-control" name={`pv.${index}.from`} value={initVal.pv[index].from} onChange={(e) => {
                                                                                        const formatValue = getTFormatvalue(e.target.value);
                                                                                        e.target.value = formatValue;
                                                                                        handleOnChangeForm(e)
                                                                                    }} />
                                                                                    {(isValid && initVal.pv[index].from === "") && <span className='err'>This information is required</span>}
                                                                                </div>
                                                                            </td>
                                                                            <td>
                                                                                <div>
                                                                                    <input type="text" class="form-control" name={`pv.${index}.to`} value={initVal.pv[index].to} onChange={(e) => {
                                                                                        const formatValue = getTFormatvalue(e.target.value);
                                                                                        e.target.value = formatValue;
                                                                                        handleOnChangeForm(e)
                                                                                    }} />
                                                                                    {(isValid && initVal.pv[index].to === "") && <span className='err'>This information is required</span>}
                                                                                </div>
                                                                            </td>
                                                                            <td>
                                                                                <div>
                                                                                    <input type="text" class="form-control" name={`pv.${index}.total`} value={initVal.pv[index].total} onChange={(e) => {
                                                                                        const formatValue = getFormatvalue(e.target.value);
                                                                                        e.target.value = formatValue;
                                                                                        handleOnChangeForm(e);
                                                                                    }} />
                                                                                    {(isValid && initVal.pv[index].total === "") && <span className='err'>This information is required</span>}
                                                                                </div>
                                                                            </td>
                                                                            <td>
                                                                                <div>
                                                                                    <input type="text" class="form-control" name={`pv.${index}.cancelled`} value={initVal.pv[index].cancelled} onChange={(e) => {
                                                                                        const formatValue = getFormatvalue(e.target.value);
                                                                                        e.target.value = formatValue;
                                                                                        handleOnChangeForm(e);
                                                                                    }} />
                                                                                    {(isValid && initVal.pv[index].cancelled === "") && <span className='err'>This information is required</span>}
                                                                                    {(emap.includes("pv[" + index + "].can")) && <span className='err'>Cancelled docs can not be greater than total docs</span>}
                                                                                </div>
                                                                            </td>
                                                                            <td>
                                                                                <div>
                                                                                    <input type="text" class="form-control" name={`pv.${index}.cancelled`} value={initVal.pv[index].net} disabled={true} />
                                                                                </div>
                                                                            </td>
                                                                            <td class="text-center">
                                                                                <div className='flex'>
                                                                                    <button type="button" class="btn btn-sm btn-danger nomargin" title="Delete" onClick={() => {
                                                                                        showDig('1', { type: 'pv', id: index }, 'Are you sure, you want to continue?');
                                                                                    }} disabled={fileStatus}>
                                                                                        <i class="fa fa-trash"></i><span class="sr-only">text</span>
                                                                                    </button>
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    })
                                                                }
                                                            </tbody>
                                                        </table>
                                                        <div class="row">
                                                            <div class="col-sm-12 text-right">
                                                                <button type="button" class="btn btn-primary" onClick={() => {
                                                                    addRow('pv');
                                                                }} disabled={initVal.pv.length === 5 || fileStatus}>Add Document</button>
                                                            </div>
                                                        </div>
                                                        <hr />
                                                    </div>
                                                    <div>
                                                        <table class="table inv exp tbl table-bordered" style={{ display: 'table' }}>
                                                            <caption>
                                                                <h4>8. Refund voucher</h4>
                                                            </caption>
                                                            <thead>
                                                                <tr>
                                                                    <th rowspan="2" class="text-center">No.</th>
                                                                    <th colspan="2" class="text-center m-cir" data-ng-bind="trans.HEAD_SN">Sr. No.</th>
                                                                    <th rowspan="2" class="text-center m-cir" data-ng-bind="trans.HEAD_TOT_NUM">Total number</th>
                                                                    <th rowspan="2" class="text-center m-cir" data-ng-bind="trans.HEAD_CAN">Cancelled</th>
                                                                    <th rowspan="2" class="text-center m-cir" data-ng-bind="trans.HEAD_NET_ISSUED">Net issued</th>
                                                                    <th rowspan="2" class="text-center" data-ng-bind="trans.HEAD_ACTION">Action</th>
                                                                </tr>
                                                                <tr>
                                                                    <th class="text-center">From</th>
                                                                    <th class="text-center">To</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    initVal.rfv.length === 0 && <tr>
                                                                        <td colspan="7">
                                                                            <div class="alert alert-msg alert-info alert-dismissible">There are no documents to be displayed.</div>
                                                                        </td>
                                                                    </tr>
                                                                }
                                                                {
                                                                    initVal.rfv.length !== 0 && initVal.rfv.map((item, index) => {
                                                                        return <tr key={index}>
                                                                            <td class="text-center">
                                                                                <div>
                                                                                    {(index + 1)}
                                                                                </div>
                                                                            </td>
                                                                            <td>
                                                                                <div>
                                                                                    <input type="text" class="form-control" name={`rfv.${index}.from`} value={initVal.rfv[index].from} onChange={(e) => {
                                                                                        const formatValue = getTFormatvalue(e.target.value);
                                                                                        e.target.value = formatValue;
                                                                                        handleOnChangeForm(e)
                                                                                    }} />
                                                                                    {(isValid && initVal.rfv[index].from === "") && <span className='err'>This information is required</span>}
                                                                                </div>
                                                                            </td>
                                                                            <td>
                                                                                <div>
                                                                                    <input type="text" class="form-control" name={`rfv.${index}.to`} value={initVal.rfv[index].to} onChange={(e) => {
                                                                                        const formatValue = getTFormatvalue(e.target.value);
                                                                                        e.target.value = formatValue;
                                                                                        handleOnChangeForm(e)
                                                                                    }} />
                                                                                    {(isValid && initVal.rfv[index].to === "") && <span className='err'>This information is required</span>}
                                                                                </div>
                                                                            </td>
                                                                            <td>
                                                                                <div>
                                                                                    <input type="text" class="form-control" name={`rfv.${index}.total`} value={initVal.rfv[index].total} onChange={(e) => {
                                                                                        const formatValue = getFormatvalue(e.target.value);
                                                                                        e.target.value = formatValue;
                                                                                        handleOnChangeForm(e);
                                                                                    }} />
                                                                                    {(isValid && initVal.rfv[index].total === "") && <span className='err'>This information is required</span>}
                                                                                </div>
                                                                            </td>
                                                                            <td>
                                                                                <div>
                                                                                    <input type="text" class="form-control" name={`rfv.${index}.cancelled`} value={initVal.rfv[index].cancelled} onChange={(e) => {
                                                                                        const formatValue = getFormatvalue(e.target.value);
                                                                                        e.target.value = formatValue;
                                                                                        handleOnChangeForm(e);
                                                                                    }} />
                                                                                    {(isValid && initVal.rfv[index].cancelled === "") && <span className='err'>This information is required</span>}
                                                                                    {(emap.includes("rfv[" + index + "].can")) && <span className='err'>Cancelled docs can not be greater than total docs</span>}
                                                                                </div>
                                                                            </td>
                                                                            <td>
                                                                                <div>
                                                                                    <input type="text" class="form-control" name={`rfv.${index}.cancelled`} value={initVal.rfv[index].net} disabled={true} />
                                                                                </div>
                                                                            </td>
                                                                            <td class="text-center">
                                                                                <div className='flex'>
                                                                                    <button type="button" class="btn btn-sm btn-danger nomargin" title="Delete" onClick={() => {
                                                                                        showDig('1', { type: 'rfv', id: index }, 'Are you sure, you want to continue?');
                                                                                    }} disabled={fileStatus}>
                                                                                        <i class="fa fa-trash"></i><span class="sr-only">text</span>
                                                                                    </button>
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    })
                                                                }
                                                            </tbody>
                                                        </table>
                                                        <div class="row">
                                                            <div class="col-sm-12 text-right">
                                                                <button type="button" class="btn btn-primary" onClick={() => {
                                                                    addRow('rfv');
                                                                }} disabled={initVal.rfv.length === 5 || fileStatus}>Add Document</button>
                                                            </div>
                                                        </div>
                                                        <hr />
                                                    </div>
                                                    <div>
                                                        <table class="table inv exp tbl table-bordered" style={{ display: 'table' }}>
                                                            <caption>
                                                                <h4>9. Delivery Challan for job work</h4>
                                                            </caption>
                                                            <thead>
                                                                <tr>
                                                                    <th rowspan="2" class="text-center">No.</th>
                                                                    <th colspan="2" class="text-center m-cir" data-ng-bind="trans.HEAD_SN">Sr. No.</th>
                                                                    <th rowspan="2" class="text-center m-cir" data-ng-bind="trans.HEAD_TOT_NUM">Total number</th>
                                                                    <th rowspan="2" class="text-center m-cir" data-ng-bind="trans.HEAD_CAN">Cancelled</th>
                                                                    <th rowspan="2" class="text-center m-cir" data-ng-bind="trans.HEAD_NET_ISSUED">Net issued</th>
                                                                    <th rowspan="2" class="text-center" data-ng-bind="trans.HEAD_ACTION">Action</th>
                                                                </tr>
                                                                <tr>
                                                                    <th class="text-center">From</th>
                                                                    <th class="text-center">To</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    initVal.dchw.length === 0 && <tr>
                                                                        <td colspan="7">
                                                                            <div class="alert alert-msg alert-info alert-dismissible">There are no documents to be displayed.</div>
                                                                        </td>
                                                                    </tr>
                                                                }
                                                                {
                                                                    initVal.dchw.length !== 0 && initVal.dchw.map((item, index) => {
                                                                        return <tr key={index}>
                                                                            <td class="text-center">
                                                                                <div>
                                                                                    {(index + 1)}
                                                                                </div>
                                                                            </td>
                                                                            <td>
                                                                                <div>
                                                                                    <input type="text" class="form-control" name={`dchw.${index}.from`} value={initVal.dchw[index].from} onChange={(e) => {
                                                                                        const formatValue = getTFormatvalue(e.target.value);
                                                                                        e.target.value = formatValue;
                                                                                        handleOnChangeForm(e)
                                                                                    }} />
                                                                                    {(isValid && initVal.dchw[index].from === "") && <span className='err'>This information is required</span>}
                                                                                </div>
                                                                            </td>
                                                                            <td>
                                                                                <div>
                                                                                    <input type="text" class="form-control" name={`dchw.${index}.to`} value={initVal.dchw[index].to} onChange={(e) => {
                                                                                        const formatValue = getTFormatvalue(e.target.value);
                                                                                        e.target.value = formatValue;
                                                                                        handleOnChangeForm(e)
                                                                                    }} />
                                                                                    {(isValid && initVal.dchw[index].to === "") && <span className='err'>This information is required</span>}
                                                                                </div>
                                                                            </td>
                                                                            <td>
                                                                                <div>
                                                                                    <input type="text" class="form-control" name={`dchw.${index}.total`} value={initVal.dchw[index].total} onChange={(e) => {
                                                                                        const formatValue = getFormatvalue(e.target.value);
                                                                                        e.target.value = formatValue;
                                                                                        handleOnChangeForm(e);
                                                                                    }} />
                                                                                    {(isValid && initVal.dchw[index].total === "") && <span className='err'>This information is required</span>}
                                                                                </div>
                                                                            </td>
                                                                            <td>
                                                                                <div>
                                                                                    <input type="text" class="form-control" name={`dchw.${index}.cancelled`} value={initVal.dchw[index].cancelled} onChange={(e) => {
                                                                                        const formatValue = getFormatvalue(e.target.value);
                                                                                        e.target.value = formatValue;
                                                                                        handleOnChangeForm(e);
                                                                                    }} />
                                                                                    {(isValid && initVal.dchw[index].cancelled === "") && <span className='err'>This information is required</span>}
                                                                                    {(emap.includes("dchw[" + index + "].can")) && <span className='err'>Cancelled docs can not be greater than total docs</span>}
                                                                                </div>
                                                                            </td>
                                                                            <td>
                                                                                <div>
                                                                                    <input type="text" class="form-control" name={`dchw.${index}.cancelled`} value={initVal.dchw[index].net} disabled={true} />
                                                                                </div>
                                                                            </td>
                                                                            <td class="text-center">
                                                                                <div className='flex'>
                                                                                    <button type="button" class="btn btn-sm btn-danger nomargin" title="Delete" onClick={() => {
                                                                                        showDig('1', { type: 'dchw', id: index }, 'Are you sure, you want to continue?');
                                                                                    }} disabled={fileStatus}>
                                                                                        <i class="fa fa-trash"></i><span class="sr-only">text</span>
                                                                                    </button>
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    })
                                                                }
                                                            </tbody>
                                                        </table>
                                                        <div class="row">
                                                            <div class="col-sm-12 text-right">
                                                                <button type="button" class="btn btn-primary" onClick={() => {
                                                                    addRow('dchw');
                                                                }} disabled={initVal.dchw.length === 5 || fileStatus}>Add Document</button>
                                                            </div>
                                                        </div>
                                                        <hr />
                                                    </div>
                                                    <div>
                                                        <table class="table inv exp tbl table-bordered" style={{ display: 'table' }}>
                                                            <caption>
                                                                <h4>10. Delivery Challan for supply on approval</h4>
                                                            </caption>
                                                            <thead>
                                                                <tr>
                                                                    <th rowspan="2" class="text-center">No.</th>
                                                                    <th colspan="2" class="text-center m-cir" data-ng-bind="trans.HEAD_SN">Sr. No.</th>
                                                                    <th rowspan="2" class="text-center m-cir" data-ng-bind="trans.HEAD_TOT_NUM">Total number</th>
                                                                    <th rowspan="2" class="text-center m-cir" data-ng-bind="trans.HEAD_CAN">Cancelled</th>
                                                                    <th rowspan="2" class="text-center m-cir" data-ng-bind="trans.HEAD_NET_ISSUED">Net issued</th>
                                                                    <th rowspan="2" class="text-center" data-ng-bind="trans.HEAD_ACTION">Action</th>
                                                                </tr>
                                                                <tr>
                                                                    <th class="text-center">From</th>
                                                                    <th class="text-center">To</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    initVal.dch.length === 0 && <tr>
                                                                        <td colspan="7">
                                                                            <div class="alert alert-msg alert-info alert-dismissible">There are no documents to be displayed.</div>
                                                                        </td>
                                                                    </tr>
                                                                }
                                                                {
                                                                    initVal.dch.length !== 0 && initVal.dch.map((item, index) => {
                                                                        return <tr key={index}>
                                                                            <td class="text-center">
                                                                                <div>
                                                                                    {(index + 1)}
                                                                                </div>
                                                                            </td>
                                                                            <td>
                                                                                <div>
                                                                                    <input type="text" class="form-control" name={`dch.${index}.from`} value={initVal.dch[index].from} onChange={(e) => {
                                                                                        const formatValue = getTFormatvalue(e.target.value);
                                                                                        e.target.value = formatValue;
                                                                                        handleOnChangeForm(e)
                                                                                    }} />
                                                                                    {(isValid && initVal.dch[index].from === "") && <span className='err'>This information is required</span>}
                                                                                </div>
                                                                            </td>
                                                                            <td>
                                                                                <div>
                                                                                    <input type="text" class="form-control" name={`dch.${index}.to`} value={initVal.dch[index].to} onChange={(e) => {
                                                                                        const formatValue = getTFormatvalue(e.target.value);
                                                                                        e.target.value = formatValue;
                                                                                        handleOnChangeForm(e)
                                                                                    }} />
                                                                                    {(isValid && initVal.dch[index].to === "") && <span className='err'>This information is required</span>}
                                                                                </div>
                                                                            </td>
                                                                            <td>
                                                                                <div>
                                                                                    <input type="text" class="form-control" name={`dch.${index}.total`} value={initVal.dch[index].total} onChange={(e) => {
                                                                                        const formatValue = getFormatvalue(e.target.value);
                                                                                        e.target.value = formatValue;
                                                                                        handleOnChangeForm(e);
                                                                                    }} />
                                                                                    {(isValid && initVal.dch[index].total === "") && <span className='err'>This information is required</span>}
                                                                                </div>
                                                                            </td>
                                                                            <td>
                                                                                <div>
                                                                                    <input type="text" class="form-control" name={`dch.${index}.cancelled`} value={initVal.dch[index].cancelled} onChange={(e) => {
                                                                                        const formatValue = getFormatvalue(e.target.value);
                                                                                        e.target.value = formatValue;
                                                                                        handleOnChangeForm(e);
                                                                                    }} />
                                                                                    {(isValid && initVal.dch[index].cancelled === "") && <span className='err'>This information is required</span>}
                                                                                    {(emap.includes("dch[" + index + "].can")) && <span className='err'>Cancelled docs can not be greater than total docs</span>}
                                                                                </div>
                                                                            </td>
                                                                            <td>
                                                                                <div>
                                                                                    <input type="text" class="form-control" name={`dch.${index}.cancelled`} value={initVal.dch[index].net} disabled={true} />
                                                                                </div>
                                                                            </td>
                                                                            <td class="text-center">
                                                                                <div className='flex'>
                                                                                    <button type="button" class="btn btn-sm btn-danger nomargin" title="Delete" onClick={() => {
                                                                                        showDig('1', { type: 'dch', id: index }, 'Are you sure, you want to continue?');
                                                                                    }} disabled={fileStatus}>
                                                                                        <i class="fa fa-trash"></i><span class="sr-only">text</span>
                                                                                    </button>
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    })
                                                                }
                                                            </tbody>
                                                        </table>
                                                        <div class="row">
                                                            <div class="col-sm-12 text-right">
                                                                <button type="button" class="btn btn-primary" onClick={() => {
                                                                    addRow('dch');
                                                                }} disabled={initVal.dch.length === 5 || fileStatus}>Add Document</button>
                                                            </div>
                                                        </div>
                                                        <hr />
                                                    </div>
                                                    <div>
                                                        <table class="table inv exp tbl table-bordered" style={{ display: 'table' }}>
                                                            <caption>
                                                                <h4>11. Delivery Challan in case of liquid gas</h4>
                                                            </caption>
                                                            <thead>
                                                                <tr>
                                                                    <th rowspan="2" class="text-center">No.</th>
                                                                    <th colspan="2" class="text-center m-cir" data-ng-bind="trans.HEAD_SN">Sr. No.</th>
                                                                    <th rowspan="2" class="text-center m-cir" data-ng-bind="trans.HEAD_TOT_NUM">Total number</th>
                                                                    <th rowspan="2" class="text-center m-cir" data-ng-bind="trans.HEAD_CAN">Cancelled</th>
                                                                    <th rowspan="2" class="text-center m-cir" data-ng-bind="trans.HEAD_NET_ISSUED">Net issued</th>
                                                                    <th rowspan="2" class="text-center" data-ng-bind="trans.HEAD_ACTION">Action</th>
                                                                </tr>
                                                                <tr>
                                                                    <th class="text-center" data-ng-bind="trans.HEAD_FROM">From</th>
                                                                    <th class="text-center" data-ng-bind="trans.HEAD_TO">To</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    initVal.dchg.length === 0 && <tr>
                                                                        <td colspan="7">
                                                                            <div class="alert alert-msg alert-info alert-dismissible">There are no documents to be displayed.</div>
                                                                        </td>
                                                                    </tr>
                                                                }
                                                                {
                                                                    initVal.dchg.length !== 0 && initVal.dchg.map((item, index) => {
                                                                        return <tr key={index}>
                                                                            <td class="text-center">
                                                                                <div>
                                                                                    {(index + 1)}
                                                                                </div>
                                                                            </td>
                                                                            <td>
                                                                                <div>
                                                                                    <input type="text" class="form-control" name={`dchg.${index}.from`} value={initVal.dchg[index].from} onChange={(e) => {
                                                                                        const formatValue = getTFormatvalue(e.target.value);
                                                                                        e.target.value = formatValue;
                                                                                        handleOnChangeForm(e)
                                                                                    }} />
                                                                                    {(isValid && initVal.dchg[index].from === "") && <span className='err'>This information is required</span>}
                                                                                </div>
                                                                            </td>
                                                                            <td>
                                                                                <div>
                                                                                    <input type="text" class="form-control" name={`dchg.${index}.to`} value={initVal.dchg[index].to} onChange={(e) => {
                                                                                        const formatValue = getTFormatvalue(e.target.value);
                                                                                        e.target.value = formatValue;
                                                                                        handleOnChangeForm(e)
                                                                                    }} />
                                                                                    {(isValid && initVal.dchg[index].to === "") && <span className='err'>This information is required</span>}
                                                                                </div>
                                                                            </td>
                                                                            <td>
                                                                                <div>
                                                                                    <input type="text" class="form-control" name={`dchg.${index}.total`} value={initVal.dchg[index].total} onChange={(e) => {
                                                                                        const formatValue = getFormatvalue(e.target.value);
                                                                                        e.target.value = formatValue;
                                                                                        handleOnChangeForm(e);
                                                                                    }} />
                                                                                    {(isValid && initVal.dchg[index].total === "") && <span className='err'>This information is required</span>}
                                                                                </div>
                                                                            </td>
                                                                            <td>
                                                                                <div>
                                                                                    <input type="text" class="form-control" name={`dchg.${index}.cancelled`} value={initVal.dchg[index].cancelled} onChange={(e) => {
                                                                                        const formatValue = getFormatvalue(e.target.value);
                                                                                        e.target.value = formatValue;
                                                                                        handleOnChangeForm(e);
                                                                                    }} />
                                                                                    {(isValid && initVal.dchg[index].cancelled === "") && <span className='err'>This information is required</span>}
                                                                                    {(emap.includes("dchg[" + index + "].can")) && <span className='err'>Cancelled docs can not be greater than total docs</span>}
                                                                                </div>
                                                                            </td>
                                                                            <td>
                                                                                <div>
                                                                                    <input type="text" class="form-control" name={`dchg.${index}.cancelled`} value={initVal.dchg[index].net} disabled={true} />
                                                                                </div>
                                                                            </td>
                                                                            <td class="text-center">
                                                                                <div className='flex'>
                                                                                    <button type="button" class="btn btn-sm btn-danger nomargin" title="Delete" onClick={() => {
                                                                                        showDig('1', { type: 'dchg', id: index }, 'Are you sure, you want to continue?');
                                                                                    }} disabled={fileStatus}>
                                                                                        <i class="fa fa-trash"></i><span class="sr-only">text</span>
                                                                                    </button>
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    })
                                                                }
                                                            </tbody>
                                                        </table>
                                                        <div class="row">
                                                            <div class="col-sm-12 text-right">
                                                                <button type="button" class="btn btn-primary" onClick={() => {
                                                                    addRow('dchg');
                                                                }} disabled={initVal.dchg.length === 5 || fileStatus}>Add Document</button>
                                                            </div>
                                                        </div>
                                                        <hr />
                                                    </div>
                                                    <div>
                                                        <table class="table inv exp tbl table-bordered" style={{ display: 'table' }}>
                                                            <caption>
                                                                <h4>12. Delivery Challan in cases other than by way of supply (excluding at S no. 9 to 11)</h4>
                                                            </caption>
                                                            <thead>
                                                                <tr>
                                                                    <th rowspan="2" class="text-center">No.</th>
                                                                    <th colspan="2" class="text-center m-cir" data-ng-bind="trans.HEAD_SN">Sr. No.</th>
                                                                    <th rowspan="2" class="text-center m-cir" data-ng-bind="trans.HEAD_TOT_NUM">Total number</th>
                                                                    <th rowspan="2" class="text-center m-cir" data-ng-bind="trans.HEAD_CAN">Cancelled</th>
                                                                    <th rowspan="2" class="text-center m-cir" data-ng-bind="trans.HEAD_NET_ISSUED">Net issued</th>
                                                                    <th rowspan="2" class="text-center" data-ng-bind="trans.HEAD_ACTION">Action</th>
                                                                </tr>
                                                                <tr>
                                                                    <th class="text-center">From</th>
                                                                    <th class="text-center">To</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    initVal.dcho.length === 0 && <tr>
                                                                        <td colspan="7">
                                                                            <div class="alert alert-msg alert-info alert-dismissible">There are no documents to be displayed.</div>
                                                                        </td>
                                                                    </tr>
                                                                }
                                                                {
                                                                    initVal.dcho.length !== 0 && initVal.dcho.map((item, index) => {
                                                                        return <tr key={index}>
                                                                            <td class="text-center">
                                                                                <div>
                                                                                    {(index + 1)}
                                                                                </div>
                                                                            </td>
                                                                            <td>
                                                                                <div>
                                                                                    <input type="text" class="form-control" name={`dcho.${index}.from`} value={initVal.dcho[index].from} onChange={(e) => {
                                                                                        const formatValue = getTFormatvalue(e.target.value);
                                                                                        e.target.value = formatValue;
                                                                                        handleOnChangeForm(e)
                                                                                    }} />
                                                                                    {(isValid && initVal.dcho[index].from === "") && <span className='err'>This information is required</span>}
                                                                                </div>
                                                                            </td>
                                                                            <td>
                                                                                <div>
                                                                                    <input type="text" class="form-control" name={`dcho.${index}.to`} value={initVal.dcho[index].to} onChange={(e) => {
                                                                                        const formatValue = getTFormatvalue(e.target.value);
                                                                                        e.target.value = formatValue;
                                                                                        handleOnChangeForm(e)
                                                                                    }} />
                                                                                    {(isValid && initVal.dcho[index].to === "") && <span className='err'>This information is required</span>}
                                                                                </div>
                                                                            </td>
                                                                            <td>
                                                                                <div>
                                                                                    <input type="text" class="form-control" name={`dcho.${index}.total`} value={initVal.dcho[index].total} onChange={(e) => {
                                                                                        const formatValue = getFormatvalue(e.target.value);
                                                                                        e.target.value = formatValue;
                                                                                        handleOnChangeForm(e);
                                                                                    }} />
                                                                                    {(isValid && initVal.dcho[index].total === "") && <span className='err'>This information is required</span>}
                                                                                </div>
                                                                            </td>
                                                                            <td>
                                                                                <div>
                                                                                    <input type="text" class="form-control" name={`dcho.${index}.cancelled`} value={initVal.dcho[index].cancelled} onChange={(e) => {
                                                                                        const formatValue = getFormatvalue(e.target.value);
                                                                                        e.target.value = formatValue;
                                                                                        handleOnChangeForm(e);
                                                                                    }} />
                                                                                    {(isValid && initVal.dcho[index].cancelled === "") && <span className='err'>This information is required</span>}
                                                                                    {(emap.includes("dcho[" + index + "].can")) && <span className='err'>Cancelled docs can not be greater than total docs</span>}
                                                                                </div>
                                                                            </td>
                                                                            <td>
                                                                                <div>
                                                                                    <input type="text" class="form-control" name={`dcho.${index}.cancelled`} value={initVal.dcho[index].net} disabled={true} />
                                                                                </div>
                                                                            </td>
                                                                            <td class="text-center">
                                                                                <div className='flex'>
                                                                                    <button type="button" class="btn btn-sm btn-danger nomargin" title="Delete" onClick={() => {
                                                                                        showDig('1', { type: 'dcho', id: index }, 'Are you sure, you want to continue?');
                                                                                    }} disabled={fileStatus}>
                                                                                        <i class="fa fa-trash"></i><span class="sr-only">text</span>
                                                                                    </button>
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    })
                                                                }
                                                            </tbody>
                                                        </table>
                                                        <div class="row">
                                                            <div class="col-sm-12 text-right">
                                                                <button type="button" class="btn btn-primary" onClick={() => {
                                                                    addRow('dcho');
                                                                }} disabled={initVal.dcho.length === 5 || fileStatus}>Add Document</button>
                                                            </div>
                                                        </div>
                                                        <hr />
                                                    </div>
                                                    {invno !== "" && <span className='err'>{invno}</span>}
                                                </div>
                                            </fieldset>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-sm-12 col-xs-12 text-right next-tab-nav">
                                            <button type="button" class="btn btn-default" onClick={() => {
                                                if (isAdd) {
                                                    showDig('2', {}, 'All details entered in the fields will be deleted, do you want to continue?');
                                                } else {
                                                    gotoPath('gstrForm');
                                                }
                                            }}>Back</button>&nbsp;
                                            <button type="submit" class="btn btn-primary accpt" disabled={submit || fileStatus}>Save</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
