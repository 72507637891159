import React, { useState, useEffect } from 'react';

import { Scrollbars } from 'react-custom-scrollbars';
import { RangeDatePicker } from 'react-google-flight-datepicker';
import Lottie from 'lottie-react';
import ReactPaginate from 'react-paginate';
import { useAlert } from 'react-alert';
import { motion } from "framer-motion";
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';

import { Dialog } from '@headlessui/react';

import Moment from 'moment';

import 'react-google-flight-datepicker/dist/main.css';

import animBtnLoading from '../assets/anime/anim-btnLoading.json';

import ServiceLMS from '../services/serviceLMS';

export default function FragmentMan() {

    const [sdate, setSDate] = useState(Moment().startOf('month').subtract('months', 6).toDate());
    const [edate, setEDate] = useState(Moment().toDate());

    const [users, setUsers] = useState([]);
    const [usersList, setUsersList] = useState([]);

    const [formData, setFormData] = useState({});
    const [digConfirm, setDigConfirm] = useState({ 'type': '0', isToggle: false, data: null });

    const [editId, setEditId] = useState('');

    const [isOpen, setIsOpen] = useState(false);
    const [submit, setSubmit] = useState(false);
    const [isUpdate, setIsUpdate] = useState(false);

    const [itemOffset, setItemOffset] = useState(0);
    const [pagecount, setPagecount] = useState(0);

    const [query, setQuery] = useState('');
    const [endMin, setEndMin] = useState(Moment().format('YYYY-MM-DD'));

    const alert = useAlert();

    const lmsService = new ServiceLMS();

    const handleOnChangeSearch = (e) => {
        var { value, name } = e.target;
        setQuery(value);
        if (value.length > 0) {
            let list = usersList.filter((u) => {
                return u.username.toLowerCase().includes(value.toLowerCase())
            });
            setUsers(list);
        } else {
            setUsers(usersList);
        }
    }

    const onDateChange = (start, end) => {
        setSDate(start);
        setEDate(end);
    }

    const loadData = () => {
        (async function () {
            let body = {
                'role': 'faculty',
                'createdAt': {
                    '$gte': sdate,
                    '$lt': edate
                }
            };
            var res = await lmsService.getAllLMSUser(body, '');
            if (res['status']) {
                const endOffset = itemOffset + 10;
                const currentItems = res.data.slice(itemOffset, endOffset);
                setPagecount(Math.ceil(res.data.length / 10));
                setUsers(currentItems);
                setUsersList(res.data);
            } else {
                alert.show('Server error please try again', { type: 'error' });
            }
        })();
    }

    const handlePageClick = (event) => {
        const newOffset = (event.selected * 10) % usersList.length;
        setItemOffset(newOffset);
        const endOffset = newOffset + 10;
        const currentItems = usersList.slice(newOffset, endOffset);
        setPagecount(Math.ceil(usersList.length / 10));
        setUsers(currentItems);
    };

    const handleOnChange = (e) => {
        var { value, name } = e.target;
        setFormData({ ...formData, [name]: value });
        if (name === "sdate") {
            console.log(value);
            setEndMin(Moment(value, 'yyyy/MM/DD').format('YYYY-MM-DD'));
        }
    }

    const handeleSubmit = (e) => {
        e.preventDefault();
        if (isOpen && !isUpdate) {
            setSubmit(true);
            let body = {
                "email": formData.email,
                "username": formData.username,
                "password": formData.password,
                "sdate": Moment(formData.sdate, 'yyyy/MM/DD').toISOString(),
                "edate": Moment(formData.edate, 'yyyy/MM/DD').toISOString(),
            }
            lmsService.addLMSUser(body, '').then((d) => {
                document.getElementById("user-form").reset();
                if (d.status) {
                    sendNotification(body.email, body.username, body.password, Moment(body.sdate).format('DD/MM/YYYY'), Moment(body.edate).format('DD/MM/YYYY'), 'new');
                } else {
                    alert.show("User creation error.", { type: 'error' });
                }
            });
        }

        if (isOpen && isUpdate) {
            let body = {
                "id": editId,
                "data": {
                    "password": formData.password,
                    "startDate": Moment(formData.sdate, 'yyyy/MM/DD').toISOString(),
                    "endDate": Moment(formData.edate, 'yyyy/MM/DD').toISOString(),
                }
            }
            lmsService.updateLMSUser(body, '').then((d) => {
                document.getElementById("user-form").reset();
                if (d.status) {
                    sendNotification(formData.email, formData.username, body.data.password, Moment(body.data.startDate).format('DD/MM/YYYY'), Moment(body.data.endDate).format('DD/MM/YYYY'), 'edit');
                } else {
                    alert.show("User creation error.", { type: 'error' });
                }
            });
        }
    }

    const deleteUser = (id) => {
        let body = {
            "id": id,
        }
        lmsService.deleteUser(body, '').then((d) => {
            if (d.status) {
                loadData();
                alert.show("User Delete.", { type: 'error2', from: 'fms' });
            } else {
                alert.show("User delete error.", { type: 'error', from: 'fms' });
            }
        });
    }

    const showDig = (type, data) => {
        setDigConfirm({ 'type': type, isToggle: true, data: data });
    }

    const process = () => {
        switch (digConfirm.type) {
            case '1':
                deleteUser(digConfirm.data.id);
                break;
            case '2':
                sendNotification(digConfirm.data.user.email, digConfirm.data.user.username, digConfirm.data.user.password, Moment(digConfirm.data.user.startDate).format('DD/MM/YYYY'), Moment(digConfirm.data.user.endDate).format('DD/MM/YYYY'), "send");
            default:
                break;
        }
        setDigConfirm({ 'type': '0', isToggle: false, data: null });
    }

    const cancel = () => {
        setDigConfirm({ 'type': '0', isToggle: false, data: null });
    }

    const sendNotification = (email, username, password, start, end, type) => {
        let body = {
            "username": username,
            "password": password,
            "to": email,
            "start": start,
            "end": end
        }
        lmsService.sendNorification(body, '').then((d) => {
            if (d.status) {
                if (type === "new") {
                    setSubmit(false);
                    setIsOpen(false);
                    setEditId('');
                    setFormData({});
                    alert.show("New user created.", { type: 'success', from: 'fms' });
                } else if (type === "edit") {
                    setSubmit(false);
                    setIsOpen(false);
                    setEditId('');
                    setFormData({});
                    alert.show("User updated.", { type: 'success', from: 'fms' });
                } else if (type === "send") {
                    alert.show("Notification send to: " + email, { type: 'success', from: 'fms' });
                }
                loadData();
            } else {
                alert.show("User creation error.", { type: 'error', from: 'fms' });
            }
        });
    }

    useEffect(() => {
        loadData();
    }, []);


    return (
        <div className="w-full h-full py-3 px-3">
            <Dialog open={isOpen} onClose={() => {
                setEditId('');
                setIsOpen(false);
                document.getElementById("user-form").reset();
                if (isUpdate) {
                    setIsUpdate(false);
                    setFormData({});
                }
            }} className='overflow-y-auto overflow-x-hidden z-50 w-full h-modal md:h-full fixed top-0 left-0 bg-black bg-opacity-70'>
                <div className="relative w-full max-w-3xl h-full md:h-auto mx-auto bg-white shadow-lg rounded-md mt-10 p-4">
                    <div className='flex items-center justify-between'>
                        <h3 className="text-2xl font-semibold text-gray-900">
                            {isUpdate ? "Edit" : "Add"} User &nbsp;
                        </h3>
                        <span className='bg-gray-200 hover:bg-gray-100 w-10 h-10 rounded-full cursor-pointer flex items-center justify-center' onClick={() => {
                            setEditId('');
                            setIsOpen(false);
                            document.getElementById("user-form").reset();
                            if (isUpdate) {
                                setIsUpdate(false);
                                setFormData({});
                            }
                        }}>
                            <i className="las la-times text-lg text-black"></i>
                        </span>
                    </div>
                    <hr className='mt-2' />
                    <form className='mt-4' onSubmit={handeleSubmit} id="user-form">
                        <div className="mb-6">
                            <label className="block mb-2 text-lg font-medium text-gray-600">User Email<sup className="text-red-600">*</sup></label>
                            <input type="text" name="email" value={formData.email} className="bg-gray-50 border border-gray-300 text-gray-900 text-lg rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="i.e. email" required onChange={handleOnChange} disabled={isUpdate} />
                        </div>
                        <div className="mb-6">
                            <label className="block mb-2 text-lg font-medium text-gray-600">Username<sup className="text-red-600">*</sup></label>
                            <input type="text" name="username" value={formData.username} className="bg-gray-50 border border-gray-300 text-gray-900 text-lg rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="i.e. username" required onChange={handleOnChange} disabled={isUpdate} />
                        </div>
                        <div className="mb-6">
                            <label className="block mb-2 text-lg font-medium text-gray-600">Password<sup className="text-red-600">*</sup></label>
                            <input type="text" name="password" value={formData.password} className="bg-gray-50 border border-gray-300 text-gray-900 text-lg rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="i.e. password" required onChange={handleOnChange} />
                        </div>
                        <div className='mb-6 grid grid-cols-2 gap-2'>
                            <div className="col-span-1">
                                <label className="block mb-2 text-lg font-medium text-gray-600">Start Date<sup className="text-red-600">*</sup></label>
                                <input type="date" name="sdate" value={formData.sdate} className="bg-gray-50 border border-gray-300 text-gray-900 text-lg rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="i.e. dd/mm/yyyy" required onChange={handleOnChange} />
                            </div>
                            <div className="col-span-1">
                                <label className="block mb-2 text-lg font-medium text-gray-600">End Date<sup className="text-red-600">*</sup></label>
                                <input type="date" name="edate" min={endMin} value={formData.edate} className="bg-gray-50 border border-gray-300 text-gray-900 text-lg rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="i.e. dd/mm/yyyy" required onChange={handleOnChange} />
                            </div>
                        </div>
                        <div className='flex items-end'>
                            <button type="submit" className="text-white bg-green-600 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-xl w-full sm:w-auto px-5 py-2.5 text-center" disabled={submit}>
                                {submit && <Lottie animationData={animBtnLoading} className="w-8 h-8" loop={true} />}
                                {!submit && !isUpdate && "Add"}
                                {!submit && isUpdate && "Update"}
                            </button>
                        </div>
                    </form>
                </div>
            </Dialog>
            <Dialog open={digConfirm.isToggle} onClose={() => setDigConfirm({ 'type': '0', isToggle: false, data: null })}>
                <div id="confirmDlg" class="modal fade fade-scale in" role="dialog" style={{ display: 'block', paddingRight: '17px' }}>
                    <div class="modal-dialog sweet">
                        <div class="modal-content">
                            <div class="modal-body">
                                <div class="m-icon m-warning pulseWarning">
                                    <span class="micon-body pulseWarningIns"></span>
                                    <span class="micon-dot pulseWarningIns"></span>
                                </div>
                                <h2>Warning</h2>
                                <p>Are you sure, you want to continue?</p>
                            </div>
                            <div class="modal-footer">
                                <a class="btn btn-default" onClick={() => { cancel() }}>Cancel</a>
                                <a class="btn btn-primary" onClick={() => { process() }}>Proceed</a>
                            </div>
                        </div>
                    </div>
                </div>
            </Dialog>
            <Scrollbars>
                <div className="grid grid-cols-12 grid-gap-2">
                    <div className="col-span-12 mt-2">
                        <div class="intro-y flex items-center justify-between h-10 mt-4">
                            <h2 class="text-4xl font-medium truncate ml-2">
                                User Managment
                            </h2>
                            <motion.button className='bg-blue-600 hover:bg-blue-800 mr-6 font-medium text-white py-4 px-6 rounded-md' onClick={() => {
                                setIsOpen(true);
                            }} whileHover={{ scale: 1.1 }} >ADD USER</motion.button>
                        </div>
                    </div>
                    <hr />
                    <div className="col-span-12 mt-4 px-2">
                        <div className="w-full shadow-md rounded-md border">
                            <table className="min-w-full divide-y divide-gray-200 px-4">
                                <thead className="bg-gray-50">
                                    <tr className=''>
                                        <th scope="col" className="border-b border-gray-200" colSpan={6}>
                                            <div className='w-full grid grid-cols-12 gap-2'>
                                                <div className="items-center py-2 col-span-11 px-2 grid grid-cols-12 gap-2">
                                                    <div className='col-span-4'>
                                                        <RangeDatePicker
                                                            startDate={sdate}
                                                            endDate={edate}
                                                            minDate={new Date(2022, 11, 1)}
                                                            maxDate={Moment().toDate()}
                                                            disabled={false}
                                                            onCloseCalendar={(e) => {
                                                                loadData();
                                                            }}
                                                            onChange={(startDate, endDate) => onDateChange(startDate, endDate)}
                                                            className=""
                                                            startWeekDay="monday"
                                                        />
                                                    </div>
                                                    <div className='w-full flex col-span-8 items-center'>
                                                        <div className='w-[140px]'>
                                                            <p>Search :</p>
                                                        </div>
                                                        <input type="text" value={query} onChange={handleOnChangeSearch} name='search' placeholder="Search by username." className="w-full h-10 px-4 font-sans text-sm outline-none rounded border" />
                                                        <button className='bg-blue-600 p-4 text-white font-normal ml-6 text-xl flex h-10 items-center rounded'><i className="las la-search mr-2"></i>Search</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </th>
                                    </tr>
                                    <tr>
                                        <th scope="col" className="px-6 py-3 text-lg font-medium text-gray-500 uppercase tracking-wider cursor-pointer" style={{ textAlign: 'left !important' }}>
                                            Email ID
                                        </th>
                                        <th scope="col" className="px-6 py-3 text-lg font-medium text-gray-500 uppercase tracking-wider cursor-pointer" style={{ textAlign: 'left !important' }}>
                                            Username
                                        </th>
                                        <th scope="col" className="px-6 py-3 text-lg font-medium text-gray-500 uppercase tracking-wider cursor-pointer" style={{ textAlign: 'left !important' }}>
                                            Password
                                        </th>
                                        <th scope="col" className="px-6 py-3 text-lg font-medium text-gray-500 uppercase tracking-wider cursor-pointer" style={{ textAlign: 'left !important' }}>
                                            Start Date
                                        </th>
                                        <th scope="col" className="px-6 py-3 text-lg font-medium text-gray-500 uppercase tracking-wider cursor-pointer" style={{ textAlign: 'left !important' }}>
                                            End Date
                                        </th>
                                        <th scope="col" className="px-6 py-3 text-lg font-medium text-gray-500 uppercase tracking-wider cursor-pointer" style={{ textAlign: 'left !important' }}>
                                            Action
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className="bg-white divide-y divide-gray-200">
                                    {
                                        users.length === 0 && <tr className="cursor-pointer hover:bg-gray-100">
                                            <td className="px-6 py-4 whitespace-nowrap" colSpan="6">
                                                <div className="text-sm text-blue-600 text-center">
                                                    No data found.
                                                </div>
                                            </td>
                                        </tr>
                                    }
                                    {
                                        users.length !== 0 && users.map((user) => {
                                            return <tr className="hover:bg-gray-100">
                                                <td className="px-6 py-4 whitespace-nowrap">
                                                    <div className="text-sm text-center font-bold text-gray-900">
                                                        {user.email === undefined ? "NA" : user.email}
                                                    </div>
                                                </td>
                                                <td className="px-6 py-4 whitespace-nowrap">
                                                    <div className="text-sm text-center font-bold text-gray-900">
                                                        {user.username}
                                                    </div>
                                                </td>
                                                <td className="px-6 py-4 whitespace-nowrap">
                                                    <div className="text-sm text-center font-bold text-gray-900">
                                                        {user.password}
                                                    </div>
                                                </td>
                                                <td className="px-6 py-4 whitespace-nowrap">
                                                    <div className="text-sm text-center font-bold text-gray-900">
                                                        {Moment(user.startDate).format('DD/MM/yyyy')}
                                                    </div>
                                                </td>
                                                <td className="px-6 py-4 whitespace-nowrap">
                                                    <div className="text-sm text-center font-bold text-gray-900">
                                                        {Moment(user.endDate).format('DD/MM/yyyy')}
                                                    </div>
                                                </td>
                                                <td className="px-6 py-4 whitespace-nowrap">
                                                    <div className="text-sm font-bold text-gray-900 flex">
                                                        <Tippy content="Edit User" placement='top'>
                                                            <span className='bg-blue-100 text-blue-600 p-4 mx-2 w-12 h-12 rounded-md flex items-center justify-center cursor-pointer' onClick={() => {
                                                                setEditId(user._id);
                                                                setFormData({ ...formData, 'email': user.email === undefined ? "" : user.email, 'username': user.username, 'password': user.password, 'sdate': Moment(user.startDate).format('yyyy-MM-DD'), 'edate': Moment(user.endDate).format('yyyy-MM-DD') });
                                                                setIsOpen(true);
                                                                setIsUpdate(true);
                                                            }}>
                                                                <i className="las la-pen text-4xl"></i>
                                                            </span>
                                                        </Tippy>
                                                        <Tippy content="Delete User" placement='top'>
                                                            <span className='bg-red-100 text-red-600 p-4 mx-2 w-12 h-12 rounded-md flex items-center justify-center cursor-pointer' onClick={() => {
                                                                showDig('1', { id: user.userID });
                                                            }}>
                                                                <i className="las la-trash text-4xl"></i>
                                                            </span>
                                                        </Tippy>
                                                        <Tippy content="Send email" placement='top'>
                                                            <span className='bg-orange-100 text-orange-600 p-4 mx-2 w-12 h-12 rounded-md flex items-center justify-center cursor-pointer' onClick={() => {
                                                                showDig('2', { user: user });
                                                            }}>
                                                                <i className="las la-envelope text-4xl"></i>
                                                            </span>
                                                        </Tippy>
                                                    </div>
                                                </td>
                                            </tr>
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <ReactPaginate
                    breakLabel="..."
                    nextLabel=">"
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={5}
                    pageCount={pagecount}
                    previousLabel="<"
                    renderOnZeroPageCount={null}
                    className="flex mt-4 list-none"
                    pageLinkClassName="mx-2"
                />
            </Scrollbars>
        </div>
    );
}
