import React from 'react';
import styles from './css/print.module.css';

import { useRecoilState } from 'recoil';
import { gstrUserAtom, returnDateAtom, gstrFileAtom } from '../../core/config/atoms';

import Moment from 'moment';

import Logo from '../../assets/images/logoblue.png';

export default function PanelFiledPDF({ setoff }) {
    const [GSTUser] = useRecoilState(gstrUserAtom);
    const [gDate] = useRecoilState(returnDateAtom);
    const [gstrf] = useRecoilState(gstrFileAtom);

    const getStartY = () => {
        if (new Date(Moment(gDate).format('MM-DD-yyyy')).getMonth() < 3) {
            return Moment(gDate).subtract(1, 'year').format('YYYY');
        } else {
            return Moment(gDate).format('YYYY');
        }
    }

    const getEndY = () => {
        if (new Date(Moment(gDate).format('MM-DD-yyyy')).getMonth() < 3) {
            return Moment(gDate).format('YY');
        } else {
            return Moment(gDate).add(1, 'year').format('YY');
        }
    }

    const get3Total = (code) => {
        let total = 0;
        let list = [];

        switch (code) {
            case "lista":
                list = gstrf.data.a32[code];
                list.forEach((item) => {
                    total += parseFloat(item.total);
                });
                break;
            case "listb":
                list = gstrf.data.a32[code];
                list.forEach((item) => {
                    total += parseFloat(item.total);
                });
                break;
            case "listc":
                list = gstrf.data.a32[code];
                list.forEach((item) => {
                    total += parseFloat(item.total);
                });
                break;
        }

        return total.toFixed(2);
    }

    const get3Igst = (code) => {
        let igst = 0;
        let list = [];

        switch (code) {
            case "lista":
                list = gstrf.data.a32[code];
                list.forEach((item) => {
                    igst += parseFloat(item.igst);
                });
                break;
            case "listb":
                list = gstrf.data.a32[code];
                list.forEach((item) => {
                    igst += parseFloat(item.igst);
                });
                break;
            case "listc":
                list = gstrf.data.a32[code];
                list.forEach((item) => {
                    igst += parseFloat(item.igst);
                });
                break;
        }

        return igst.toFixed(2);
    }

    const getIGST = (type) => {
        let value = 0;
        switch (type) {
            case 'A3.1':
                value = parseFloat(gstrf.data.a3.user.a.igst.value) + parseFloat(gstrf.data.a3.user.b.igst.value) + parseFloat(gstrf.data.a3.user.d.igst.value);
                break;
            case 'A4':
                value = parseFloat(gstrf.data.a4.user.a.rowa.igst.value) + parseFloat(gstrf.data.a4.user.a.rowb.igst.value) + parseFloat(gstrf.data.a4.user.a.rowc.igst.value) + parseFloat(gstrf.data.a4.user.a.rowd.igst.value) + parseFloat(gstrf.data.a4.user.a.rowe.igst.value);
                value = value - (parseFloat(gstrf.data.a4.user.b.rowa.igst.value) + parseFloat(gstrf.data.a4.user.b.rowb.igst.value));
                value = value + parseFloat(gstrf.data.a4.user.d.rowa.igst.value) + parseFloat(gstrf.data.a4.user.d.rowb.igst.value);
                break;
            case 'A5':
                value = parseFloat(gstrf.data.a5.user.a.igst.value) + parseFloat(gstrf.data.a5.user.b.igst.value);
                break;
            case 'A51':
                value = parseFloat(gstrf.data.a51.user.a.igst.value);
                break;
        }
        return value;
    }

    const getCGST = (type) => {
        let value = 0;
        switch (type) {
            case 'A3.1':
                value = parseFloat(gstrf.data.a3.user.a.cgst.value) + parseFloat(gstrf.data.a3.user.d.cgst.value);
                break;
            case 'A4':
                value = parseFloat(gstrf.data.a4.user.a.rowc.cgst.value) + parseFloat(gstrf.data.a4.user.a.rowd.cgst.value) + parseFloat(gstrf.data.a4.user.a.rowe.cgst.value);
                value = value - (parseFloat(gstrf.data.a4.user.b.rowa.cgst.value) + parseFloat(gstrf.data.a4.user.b.rowb.cgst.value));
                value = value + parseFloat(gstrf.data.a4.user.d.rowa.cgst.value) + parseFloat(gstrf.data.a4.user.d.rowb.cgst.value);
                break;
            case 'A5':
                value = parseFloat(gstrf.data.a5.user.a.cgst.value) + parseFloat(gstrf.data.a5.user.b.cgst.value);
                break;
            case 'A51':
                value = parseFloat(gstrf.data.a51.user.a.cgst.value) + parseFloat(gstrf.data.a51.user.b.igst.value);
                break;
        }
        return value;
    }

    const getSGST = (type) => {
        let value = 0;
        switch (type) {
            case 'A3.1':
                value = parseFloat(gstrf.data.a3.user.a.sgst.value) + parseFloat(gstrf.data.a3.user.d.sgst.value);
                break;
            case 'A4':
                value = parseFloat(gstrf.data.a4.user.a.rowc.sgst.value) + parseFloat(gstrf.data.a4.user.a.rowd.sgst.value) + parseFloat(gstrf.data.a4.user.a.rowe.sgst.value);
                value = value - (parseFloat(gstrf.data.a4.user.b.rowa.sgst.value) + parseFloat(gstrf.data.a4.user.b.rowb.sgst.value));
                value = value + parseFloat(gstrf.data.a4.user.d.rowa.sgst.value) + parseFloat(gstrf.data.a4.user.d.rowb.sgst.value);
                break;
            case 'A51':
                value = parseFloat(gstrf.data.a51.user.a.sgst.value) + parseFloat(gstrf.data.a51.user.b.cgst.value);
                break;
        }
        return value;
    }

    const getCess = (type) => {
        let value = 0;
        switch (type) {
            case 'A3.1':
                value = parseFloat(gstrf.data.a3.user.a.cess.value) + parseFloat(gstrf.data.a3.user.b.cess.value) + parseFloat(gstrf.data.a3.user.d.cess.value);
                break;
            case 'A4':
                value = parseFloat(gstrf.data.a4.user.a.rowa.cess.value) + parseFloat(gstrf.data.a4.user.a.rowb.cess.value) + parseFloat(gstrf.data.a4.user.a.rowc.cess.value) + parseFloat(gstrf.data.a4.user.a.rowd.cess.value) + parseFloat(gstrf.data.a4.user.a.rowe.cess.value);
                value = value - (parseFloat(gstrf.data.a4.user.b.rowa.cess.value) + parseFloat(gstrf.data.a4.user.b.rowb.cess.value));
                value = value + parseFloat(gstrf.data.a4.user.d.rowa.cess.value) + parseFloat(gstrf.data.a4.user.d.rowb.cess.value);
                break;
            case 'A51':
                value = parseFloat(gstrf.data.a51.user.a.cess.value);
                break;
        }
        return value;
    }

    const getName = () => {
        let name = "";
        GSTUser.users.forEach(element => {
            if (element.isPrime === "1") {
                name = element.pd_fnm + " " + element.pd_mname + " " + element.pd_lname;
            }
        });
        return name;
    }

    const getDesignation = () => {
        let dst = "";
        GSTUser.users.forEach(element => {
            if (element.isPrime === "1") {
                dst = element.dig;
            }
        });
        return dst;
    }

    return (
        <div className={styles.printDoc} id="divToPrint">
            <div className='fixed w-[595pt] h-[800pt] flex flex-col items-center justify-center opacity-20 pointer-events-none' style={{ zIndex: 9999 }}>
                <img src={Logo} alt="ITAA" className='w-[800] mx-auto h-[227px] pointer-events-none' style={{ transform: "rotate(-45deg)" }} />
            </div>
            <p className={styles.s1} style={{ textIndent: "0pt", textAlign: "center" }}>
                Form GSTR-3B
            </p>
            <p className={styles.s2} style={{ paddingTop: "10pt", textIndent: "0pt", textAlign: "center" }}>
                [See rule 61(5)]
            </p>
            <p style={{ textIndent: "0pt", textAlign: "left" }}>
                <br />
            </p>
            <p style={{ textIndent: "0pt", textAlign: "left" }}>
                <br />
            </p>
            <table style={{ borderCollapse: "collapse", marginLeft: "380pt" }} cellSpacing={0} >
                <tbody>
                    <tr style={{ height: "15pt" }}>
                        <td
                            style={{
                                width: "84pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            bgcolor="#F9D2B0"
                        >
                            <p
                                className={styles.s3}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "4pt",
                                    textIndent: "0pt",
                                    textAlign: "left"
                                }}
                            >
                                Year
                            </p>
                        </td>
                        <td
                            style={{
                                width: "108pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s3}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "4pt",
                                    textIndent: "0pt",
                                    textAlign: "left"
                                }}
                            >
                                {getStartY()}-{getEndY()}
                            </p>
                        </td>
                    </tr>
                    <tr style={{ height: "15pt" }}>
                        <td
                            style={{
                                width: "84pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            bgcolor="#F9D2B0"
                        >
                            <p
                                className={styles.s3}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "4pt",
                                    textIndent: "0pt",
                                    textAlign: "left"
                                }}
                            >
                                Period
                            </p>
                        </td>
                        <td
                            style={{
                                width: "108pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s3}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "4pt",
                                    textIndent: "0pt",
                                    textAlign: "left"
                                }}
                            >
                                {Moment(gDate).format("MMMM")}
                            </p>
                        </td>
                    </tr>
                </tbody>
            </table>
            <p style={{ textIndent: "0pt", textAlign: "left" }}>
                <br />
            </p>
            <table style={{ borderCollapse: "collapse", marginLeft: "18pt" }} cellSpacing={0} >
                <tbody>
                    <tr style={{ height: "14pt" }}>
                        <td
                            style={{
                                width: "209pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            bgcolor="#F9D2B0"
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "4pt",
                                    textIndent: "0pt",
                                    textAlign: "left"
                                }}
                            >
                                1. GSTIN
                            </p>
                        </td>
                        <td
                            style={{
                                width: "346pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p className={styles.s4}
                                style={{
                                    paddingLeft: "4pt",
                                    textIndent: "0pt",
                                    lineHeight: "10pt",
                                    textAlign: "left"
                                }}
                            >
                                {GSTUser.gstn}
                            </p>
                        </td>
                    </tr>
                    <tr style={{ height: "14pt" }}>
                        <td
                            style={{
                                width: "209pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            bgcolor="#F9D2B0"
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "4pt",
                                    textIndent: "0pt",
                                    textAlign: "left"
                                }}
                            >
                                2(a). Legal name of the registered person
                            </p>
                        </td>
                        <td
                            style={{
                                width: "346pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p className={styles.s4}
                                style={{
                                    paddingLeft: "4pt",
                                    textIndent: "0pt",
                                    lineHeight: "10pt",
                                    textAlign: "left"
                                }}
                            >
                                {GSTUser.buzName}
                            </p>
                        </td>
                    </tr>
                    <tr style={{ height: "14pt" }}>
                        <td
                            style={{
                                width: "209pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            bgcolor="#F9D2B0"
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "4pt",
                                    textIndent: "0pt",
                                    textAlign: "left"
                                }}
                            >
                                2(b). Trade name, if any
                            </p>
                        </td>
                        <td
                            style={{
                                width: "346pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p className={styles.s4}
                                style={{
                                    paddingLeft: "4pt",
                                    textIndent: "0pt",
                                    lineHeight: "10pt",
                                    textAlign: "left"
                                }}
                            >
                                {GSTUser.tname}
                            </p>
                        </td>
                    </tr>
                    <tr style={{ height: "14pt" }}>
                        <td
                            style={{
                                width: "209pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            bgcolor="#F9D2B0"
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "4pt",
                                    textIndent: "0pt",
                                    textAlign: "left"
                                }}
                            >
                                2(c). ARN
                            </p>
                        </td>
                        <td
                            style={{
                                width: "346pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p className={styles.s4}
                                style={{
                                    paddingLeft: "4pt",
                                    textIndent: "0pt",
                                    lineHeight: "10pt",
                                    textAlign: "left"
                                }}
                            >
                                {gstrf.ack}
                            </p>
                        </td>
                    </tr>
                    <tr style={{ height: "14pt" }}>
                        <td
                            style={{
                                width: "209pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            bgcolor="#F9D2B0"
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "4pt",
                                    textIndent: "0pt",
                                    textAlign: "left"
                                }}
                            >
                                2(d). Date of ARN
                            </p>
                        </td>
                        <td
                            style={{
                                width: "346pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p className={styles.s4}
                                style={{
                                    paddingLeft: "4pt",
                                    textIndent: "0pt",
                                    lineHeight: "10pt",
                                    textAlign: "left"
                                }}
                            >
                                {Moment(gDate).add('days', 19).add('month', 1).format('DD-MM-yyyy')}
                            </p>
                        </td>
                    </tr>
                </tbody>
            </table>
            <p className={styles.s5} style={{ textAlign: "right", marginRight: "23pt" }}>
                (Amount in ₹ for all tables)
            </p>
            <p style={{ textIndent: "0pt", textAlign: "left" }}>
                <br />
            </p>
            <p className={styles.sh} style={{ width: '555pt', marginLeft: "18pt", paddingTop: "1pt", paddingBottom: "1pt", paddingLeft: "5pt", textIndent: "0pt", textAlign: "left", backgroundColor: "#FBE9D8" }}>
                3.1 Details of Outward supplies and inward supplies liable to reverse charge (other than those covered by Table 3.1.1)
            </p>
            <p style={{ paddingLeft: "5pt", paddingTop: "5pt", textIndent: "0pt", textAlign: "left" }} />
            <table style={{ borderCollapse: "collapse", marginLeft: "18pt" }} cellSpacing={0}>
                <tbody>
                    <tr style={{ height: "26pt" }}>
                        <td
                            style={{
                                width: "274pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            bgcolor="#F1F1F1"
                        >
                            <p
                                className={styles.s3}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "4pt",
                                    textIndent: "0pt",
                                    textAlign: "left"
                                }}
                            >
                                Nature of Supplies
                            </p>
                        </td>
                        <td
                            style={{
                                width: "79pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            bgcolor="#F1F1F1"
                        >
                            <p
                                className={styles.s3}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "4pt",
                                    paddingRight: "5pt",
                                    textIndent: "0pt",
                                    textAlign: "left"
                                }}
                            >
                                Total taxable value
                            </p>
                        </td>
                        <td
                            style={{
                                width: "63pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            bgcolor="#F1F1F1"
                        >
                            <p
                                className={styles.s3}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "4pt",
                                    paddingRight: "9pt",
                                    textIndent: "0pt",
                                    textAlign: "left"
                                }}
                            >
                                Integrated tax
                            </p>
                        </td>
                        <td
                            style={{
                                width: "52pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            bgcolor="#F1F1F1"
                        >
                            <p
                                className={styles.s3}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "4pt",
                                    paddingRight: "9pt",
                                    textIndent: "0pt",
                                    textAlign: "left"
                                }}
                            >
                                Central tax
                            </p>
                        </td>
                        <td
                            style={{
                                width: "57pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            bgcolor="#F1F1F1"
                        >
                            <p
                                className={styles.s3}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "4pt",
                                    paddingRight: "16pt",
                                    textIndent: "0pt",
                                    textAlign: "left"
                                }}
                            >
                                State/ UT tax
                            </p>
                        </td>
                        <td
                            style={{
                                width: "29pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            bgcolor="#F1F1F1"
                        >
                            <p
                                className={styles.s3}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "center"
                                }}
                            >
                                Cess
                            </p>
                        </td>
                    </tr>
                    <tr style={{ height: "26pt" }}>
                        <td
                            style={{
                                width: "274pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s3}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "4pt",
                                    textIndent: "0pt",
                                    textAlign: "left"
                                }}
                            >
                                (a) Outward taxable supplies (other than zero rated, nil rated and exempted)
                            </p>
                        </td>
                        <td style={{
                            width: "79pt",
                            borderTopStyle: "solid",
                            borderTopWidth: "1pt",
                            borderLeftStyle: "solid",
                            borderLeftWidth: "1pt",
                            borderBottomStyle: "solid",
                            borderBottomWidth: "1pt",
                            borderRightStyle: "solid",
                            borderRightWidth: "1pt"
                        }}>
                            <p className={styles.s3} style={{
                                paddingTop: "1pt",
                                paddingRight: "7pt",
                                textIndent: "0pt",
                                textAlign: "right"
                            }}>
                                {parseFloat(gstrf.data.a3.user.a.total.value).toFixed(2)}
                            </p>
                        </td>
                        <td
                            style={{
                                width: "63pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s3}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "5pt",
                                    textIndent: "0pt",
                                    textAlign: "right"
                                }}
                            >
                                {parseFloat(gstrf.data.a3.user.a.igst.value).toFixed(2)}
                            </p>
                        </td>
                        <td
                            style={{
                                width: "52pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s3}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "6pt",
                                    textIndent: "0pt",
                                    textAlign: "right"
                                }}
                            >
                                {parseFloat(gstrf.data.a3.user.a.cgst.value).toFixed(2)}
                            </p>
                        </td>
                        <td
                            style={{
                                width: "57pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s3}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "6pt",
                                    textIndent: "0pt",
                                    textAlign: "right"
                                }}
                            >
                                {parseFloat(gstrf.data.a3.user.a.sgst.value).toFixed(2)}
                            </p>
                        </td>
                        <td
                            style={{
                                width: "29pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s3}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "3pt",
                                    paddingRight: "1pt",
                                    textIndent: "0pt",
                                    textAlign: "center"
                                }}
                            >
                                {parseFloat(gstrf.data.a3.user.a.cess.value).toFixed(2)}
                            </p>
                        </td>
                    </tr>
                    <tr style={{ height: "15pt" }}>
                        <td
                            style={{
                                width: "274pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s3}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "4pt",
                                    textIndent: "0pt",
                                    textAlign: "left"
                                }}
                            >
                                (b) Outward taxable supplies (zero rated)
                            </p>
                        </td>
                        <td
                            style={{
                                width: "79pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s3}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "5pt",
                                    textIndent: "0pt",
                                    textAlign: "right"
                                }}
                            >
                                {parseFloat(gstrf.data.a3.user.b.total.value).toFixed(2)}
                            </p>
                        </td>
                        <td
                            style={{
                                width: "63pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s3}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "5pt",
                                    textIndent: "0pt",
                                    textAlign: "right"
                                }}
                            >
                                {parseFloat(gstrf.data.a3.user.b.igst.value).toFixed(2)}
                            </p>
                        </td>
                        <td
                            style={{
                                width: "52pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s3}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "1pt",
                                    textIndent: "0pt",
                                    textAlign: "center"
                                }}
                            >
                                -
                            </p>
                        </td>
                        <td
                            style={{
                                width: "57pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s3}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "1pt",
                                    textIndent: "0pt",
                                    textAlign: "center"
                                }}
                            >
                                -
                            </p>
                        </td>
                        <td
                            style={{
                                width: "29pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s3}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "3pt",
                                    paddingRight: "2pt",
                                    textIndent: "0pt",
                                    textAlign: "center"
                                }}
                            >
                                {parseFloat(gstrf.data.a3.user.b.cess.value).toFixed(2)}
                            </p>
                        </td>
                    </tr>
                    <tr style={{ height: "15pt" }}>
                        <td
                            style={{
                                width: "274pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s3}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "4pt",
                                    textIndent: "0pt",
                                    textAlign: "left"
                                }}
                            >
                                (c ) Other outward supplies (nil rated, exempted)
                            </p>
                        </td>
                        <td
                            style={{
                                width: "79pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s3}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "5pt",
                                    textIndent: "0pt",
                                    textAlign: "right"
                                }}
                            >
                                {parseFloat(gstrf.data.a3.user.c.total.value).toFixed(2)}
                            </p>
                        </td>
                        <td
                            style={{
                                width: "63pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s3}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "30pt",
                                    textIndent: "0pt",
                                    textAlign: "left"
                                }}
                            >
                                -
                            </p>
                        </td>
                        <td
                            style={{
                                width: "52pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s3}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "1pt",
                                    textIndent: "0pt",
                                    textAlign: "center"
                                }}
                            >
                                -
                            </p>
                        </td>
                        <td
                            style={{
                                width: "57pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s3}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "1pt",
                                    textIndent: "0pt",
                                    textAlign: "center"
                                }}
                            >
                                -
                            </p>
                        </td>
                        <td
                            style={{
                                width: "29pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s3}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "1pt",
                                    textIndent: "0pt",
                                    textAlign: "center"
                                }}
                            >
                                -
                            </p>
                        </td>
                    </tr>
                    <tr style={{ height: "15pt" }}>
                        <td
                            style={{
                                width: "274pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s3}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "4pt",
                                    textIndent: "0pt",
                                    textAlign: "left"
                                }}
                            >
                                (d) Inward supplies (liable to reverse charge)
                            </p>
                        </td>
                        <td
                            style={{
                                width: "79pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s3}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "5pt",
                                    textIndent: "0pt",
                                    textAlign: "right"
                                }}
                            >
                                {parseFloat(gstrf.data.a3.user.d.total.value).toFixed(2)}
                            </p>
                        </td>
                        <td
                            style={{
                                width: "63pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s3}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "5pt",
                                    textIndent: "0pt",
                                    textAlign: "right"
                                }}
                            >
                                {parseFloat(gstrf.data.a3.user.d.igst.value).toFixed(2)}
                            </p>
                        </td>
                        <td
                            style={{
                                width: "52pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s3}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "5pt",
                                    textIndent: "0pt",
                                    textAlign: "right"
                                }}
                            >
                                {parseFloat(gstrf.data.a3.user.d.cgst.value).toFixed(2)}
                            </p>
                        </td>
                        <td
                            style={{
                                width: "57pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s3}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "5pt",
                                    textIndent: "0pt",
                                    textAlign: "right"
                                }}
                            >
                                {parseFloat(gstrf.data.a3.user.d.sgst.value).toFixed(2)}
                            </p>
                        </td>
                        <td
                            style={{
                                width: "29pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s3}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "3pt",
                                    paddingRight: "1pt",
                                    textIndent: "0pt",
                                    textAlign: "center"
                                }}
                            >
                                {parseFloat(gstrf.data.a3.user.d.cess.value).toFixed(2)}
                            </p>
                        </td>
                    </tr>
                    <tr style={{ height: "15pt" }}>
                        <td
                            style={{
                                width: "274pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s3}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "4pt",
                                    textIndent: "0pt",
                                    textAlign: "left"
                                }}
                            >
                                (e) Non-GST outward supplies
                            </p>
                        </td>
                        <td
                            style={{
                                width: "79pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s3}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "5pt",
                                    textIndent: "0pt",
                                    textAlign: "right"
                                }}
                            >
                                {parseFloat(gstrf.data.a3.user.e.total.value).toFixed(2)}
                            </p>
                        </td>
                        <td
                            style={{
                                width: "63pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s3}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "30pt",
                                    textIndent: "0pt",
                                    textAlign: "left"
                                }}
                            >
                                -
                            </p>
                        </td>
                        <td
                            style={{
                                width: "52pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s3}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "1pt",
                                    textIndent: "0pt",
                                    textAlign: "center"
                                }}
                            >
                                -
                            </p>
                        </td>
                        <td
                            style={{
                                width: "57pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s3}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "1pt",
                                    textIndent: "0pt",
                                    textAlign: "center"
                                }}
                            >
                                -
                            </p>
                        </td>
                        <td
                            style={{
                                width: "29pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s3}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "1pt",
                                    textIndent: "0pt",
                                    textAlign: "center"
                                }}
                            >
                                -
                            </p>
                        </td>
                    </tr>
                </tbody>
            </table>
            <p style={{ textIndent: "0pt", textAlign: "left" }}>
                <br />
            </p>
            <p className={styles.sh} style={{ width: '555pt', marginLeft: "18pt", paddingTop: "1pt", paddingBottom: "1pt", paddingLeft: "5pt", textIndent: "0pt", textAlign: "left", backgroundColor: "#FBE9D8" }}>
                3.1.1 Details of Supplies SGST Acts under section 9(5) of the CGST Act, 2017 and corresponding provisions in IGST/ UTGST/SGST Acts
            </p>
            <p style={{ paddingLeft: "5pt", paddingTop: "5pt", textIndent: "0pt", textAlign: "left" }} />
            <table style={{ borderCollapse: "collapse", marginLeft: "18pt" }} cellSpacing={0}>
                <tbody>
                    <tr style={{ height: "36pt" }}>
                        <td
                            style={{
                                width: "335pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            bgcolor="#F1F1F1"
                        >
                            <p
                                className={styles.s3}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "4pt",
                                    textIndent: "0pt",
                                    textAlign: "left"
                                }}
                            >
                                Nature of Supplies
                            </p>
                        </td>
                        <td
                            style={{
                                width: "52pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            bgcolor="#F1F1F1"
                        >
                            <p
                                className={styles.s3}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "4pt",
                                    textIndent: "0pt",
                                    textAlign: "left"
                                }}
                            >
                                Total taxable value
                            </p>
                        </td>
                        <td
                            style={{
                                width: "55pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            bgcolor="#F1F1F1"
                        >
                            <p
                                className={styles.s3}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "4pt",
                                    textIndent: "0pt",
                                    textAlign: "left"
                                }}
                            >
                                Integrated tax
                            </p>
                        </td>
                        <td
                            style={{
                                width: "42pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            bgcolor="#F1F1F1"
                        >
                            <p
                                className={styles.s3}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "4pt",
                                    textIndent: "0pt",
                                    textAlign: "left"
                                }}
                            >
                                Central tax
                            </p>
                        </td>
                        <td
                            style={{
                                width: "41pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            bgcolor="#F1F1F1"
                        >
                            <p
                                className={styles.s3}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "4pt",
                                    paddingRight: "10pt",
                                    textIndent: "0pt",
                                    textAlign: "left"
                                }}
                            >
                                State/ UT tax
                            </p>
                        </td>
                        <td
                            style={{
                                width: "29pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            bgcolor="#F1F1F1"
                        >
                            <p
                                className={styles.s3}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "center"
                                }}
                            >
                                Cess
                            </p>
                        </td>
                    </tr>
                    <tr style={{ height: "26pt" }}>
                        <td
                            style={{
                                width: "335pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s3}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "4pt",
                                    paddingRight: "5pt",
                                    textIndent: "0pt",
                                    textAlign: "left"
                                }}
                            >
                                (i) Taxable supplies on which electronic commerce operator pays tax u/s 9(5) [to be furnished by electronic commerce operator]
                            </p>
                        </td>
                        <td
                            style={{
                                width: "52pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s3}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "5pt",
                                    textIndent: "0pt",
                                    textAlign: "right"
                                }}
                            >
                                {parseFloat(gstrf.data.a31.user.a.total.value).toFixed(2)}
                            </p>
                        </td>
                        <td
                            style={{
                                width: "55pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s3}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "32pt",
                                    textIndent: "0pt",
                                    textAlign: "left"
                                }}
                            >
                                {parseFloat(gstrf.data.a31.user.a.igst.value).toFixed(2)}
                            </p>
                        </td>
                        <td
                            style={{
                                width: "42pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s3}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "20pt",
                                    textIndent: "0pt",
                                    textAlign: "left"
                                }}
                            >
                                {parseFloat(gstrf.data.a31.user.a.cgst.value).toFixed(2)}
                            </p>
                        </td>
                        <td
                            style={{
                                width: "41pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s3}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "19pt",
                                    textIndent: "0pt",
                                    textAlign: "left"
                                }}
                            >
                                {parseFloat(gstrf.data.a31.user.a.sgst.value).toFixed(2)}
                            </p>
                        </td>
                        <td
                            style={{
                                width: "29pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s3}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "3pt",
                                    paddingRight: "1pt",
                                    textIndent: "0pt",
                                    textAlign: "center"
                                }}
                            >
                                {parseFloat(gstrf.data.a31.user.a.cess.value).toFixed(2)}
                            </p>
                        </td>
                    </tr>
                    <tr style={{ height: "47pt" }}>
                        <td
                            style={{
                                width: "335pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s3}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "4pt",
                                    paddingRight: "5pt",
                                    textIndent: "0pt",
                                    textAlign: "left"
                                }}
                            >
                                (ii) Taxable supplies made by registered person through electronic
                                commerce operator, on which electronic commerce operator is required
                                to pay tax u/s 9(5) [to be furnished by registered person making
                                supplies through electronic commerce operator]
                            </p>
                        </td>
                        <td
                            style={{
                                width: "52pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s3}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "5pt",
                                    textIndent: "0pt",
                                    textAlign: "right"
                                }}
                            >
                                {parseFloat(gstrf.data.a31.user.b.total.value).toFixed(2)}
                            </p>
                        </td>
                        <td
                            style={{
                                width: "55pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s3}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "1pt",
                                    textIndent: "0pt",
                                    textAlign: "center"
                                }}
                            >
                                -
                            </p>
                        </td>
                        <td
                            style={{
                                width: "42pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s3}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "19pt",
                                    textIndent: "0pt",
                                    textAlign: "left"
                                }}
                            >
                                -
                            </p>
                        </td>
                        <td
                            style={{
                                width: "41pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s3}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "19pt",
                                    textIndent: "0pt",
                                    textAlign: "left"
                                }}
                            >
                                -
                            </p>
                        </td>
                        <td
                            style={{
                                width: "29pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s3}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "1pt",
                                    textIndent: "0pt",
                                    textAlign: "center"
                                }}
                            >
                                -
                            </p>
                        </td>
                    </tr>
                </tbody>
            </table>
            <p style={{ textIndent: "0pt", textAlign: "left" }}>
                <br />
            </p>
            <p className={styles.sh} style={{ width: '555pt', marginLeft: "18pt", paddingTop: "1pt", paddingBottom: "1pt", paddingLeft: "5pt", textIndent: "0pt", textAlign: "left", backgroundColor: "#FBE9D8" }}>
                3.2 Out of supplies made in 3.1 (a) and 3.1.1 (i), details of inter-state supplies made
            </p>
            <p style={{ paddingLeft: "5pt", paddingTop: "5pt", textIndent: "0pt", textAlign: "left" }} />
            <table style={{ borderCollapse: "collapse", marginLeft: "18pt" }} cellSpacing={0} >
                <tbody>
                    <tr style={{ height: "15pt" }}>
                        <td
                            style={{
                                width: "185pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            bgcolor="#F1F1F1"
                        >
                            <p
                                className={styles.s3}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "4pt",
                                    textIndent: "0pt",
                                    textAlign: "left"
                                }}
                            >
                                Nature of Supplies
                            </p>
                        </td>
                        <td
                            style={{
                                width: "185pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            bgcolor="#F1F1F1"
                        >
                            <p
                                className={styles.s3}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "4pt",
                                    textIndent: "0pt",
                                    textAlign: "left"
                                }}
                            >
                                Total taxable value
                            </p>
                        </td>
                        <td
                            style={{
                                width: "184pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            bgcolor="#F1F1F1"
                        >
                            <p
                                className={styles.s3}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "4pt",
                                    textIndent: "0pt",
                                    textAlign: "left"
                                }}
                            >
                                Integrated tax
                            </p>
                        </td>
                    </tr>
                    <tr style={{ height: "15pt" }}>
                        <td
                            style={{
                                width: "185pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s3}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "4pt",
                                    textIndent: "0pt",
                                    textAlign: "left"
                                }}
                            >
                                Supplies made to Unregistered Persons
                            </p>
                        </td>
                        <td
                            style={{
                                width: "185pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s3}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "5pt",
                                    textIndent: "0pt",
                                    textAlign: "right"
                                }}
                            >
                                {get3Total('lista')}
                            </p>
                        </td>
                        <td
                            style={{
                                width: "184pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s3}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "5pt",
                                    textIndent: "0pt",
                                    textAlign: "right"
                                }}
                            >
                                {get3Igst('lista')}
                            </p>
                        </td>
                    </tr>
                    <tr style={{ height: "26pt" }}>
                        <td
                            style={{
                                width: "185pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s3}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "4pt",
                                    textIndent: "0pt",
                                    textAlign: "left"
                                }}
                            >
                                Supplies made to Composition Taxable Persons
                            </p>
                        </td>
                        <td
                            style={{
                                width: "185pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s3}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "5pt",
                                    textIndent: "0pt",
                                    textAlign: "right"
                                }}
                            >
                                {get3Total('listb')}
                            </p>
                        </td>
                        <td
                            style={{
                                width: "184pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s3}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "5pt",
                                    textIndent: "0pt",
                                    textAlign: "right"
                                }}
                            >
                                {get3Igst('listb')}
                            </p>
                        </td>
                    </tr>
                    <tr style={{ height: "15pt" }}>
                        <td
                            style={{
                                width: "185pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s3}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "4pt",
                                    textIndent: "0pt",
                                    textAlign: "left"
                                }}
                            >
                                Supplies made to UIN holders
                            </p>
                        </td>
                        <td
                            style={{
                                width: "185pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s3}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "5pt",
                                    textIndent: "0pt",
                                    textAlign: "right"
                                }}
                            >
                                {get3Total('listc')}
                            </p>
                        </td>
                        <td
                            style={{
                                width: "184pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s3}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "5pt",
                                    textIndent: "0pt",
                                    textAlign: "right"
                                }}
                            >
                                {get3Igst('listc')}
                            </p>
                        </td>
                    </tr>
                </tbody>
            </table>
            <p style={{ textIndent: "0pt", textAlign: "left" }}>
                <br />
            </p>
            <p className={styles.sh} style={{ width: '555pt', marginLeft: "18pt", paddingTop: "1pt", paddingBottom: "1pt", paddingLeft: "5pt", textIndent: "0pt", textAlign: "left", backgroundColor: "#FBE9D8" }}>
                4. Eligible ITC
            </p>
            <p style={{ paddingLeft: "5pt", paddingTop: "5pt", textIndent: "0pt", textAlign: "left" }} />
            <table style={{ borderCollapse: "collapse", marginLeft: "18pt" }} cellSpacing={0} >
                <tbody>
                    <tr style={{ height: "15pt" }}>
                        <td
                            style={{
                                width: "289pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            bgcolor="#F1F1F1"
                        >
                            <p
                                className={styles.s3}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "4pt",
                                    textIndent: "0pt",
                                    textAlign: "left"
                                }}
                            >
                                Details
                            </p>
                        </td>
                        <td
                            style={{
                                width: "70pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            bgcolor="#F1F1F1"
                        >
                            <p
                                className={styles.s3}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "4pt",
                                    textIndent: "0pt",
                                    textAlign: "left"
                                }}
                            >
                                Integrated tax
                            </p>
                        </td>
                        <td
                            style={{
                                width: "70pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            bgcolor="#F1F1F1"
                        >
                            <p
                                className={styles.s3}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "4pt",
                                    textIndent: "0pt",
                                    textAlign: "left"
                                }}
                            >
                                Central tax
                            </p>
                        </td>
                        <td
                            style={{
                                width: "70pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            bgcolor="#F1F1F1"
                        >
                            <p
                                className={styles.s3}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "4pt",
                                    textIndent: "0pt",
                                    textAlign: "left"
                                }}
                            >
                                State/ UT tax
                            </p>
                        </td>
                        <td
                            style={{
                                width: "55pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            bgcolor="#F1F1F1"
                        >
                            <p
                                className={styles.s3}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "4pt",
                                    textIndent: "0pt",
                                    textAlign: "left"
                                }}
                            >
                                Cess
                            </p>
                        </td>
                    </tr>
                    <tr style={{ height: "15pt" }}>
                        <td
                            style={{
                                width: "289pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s3}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "4pt",
                                    textIndent: "0pt",
                                    textAlign: "left"
                                }}
                            >
                                A. ITC Available (whether in full or part)
                            </p>
                        </td>
                        <td
                            style={{
                                width: "70pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                <br />
                            </p>
                        </td>
                        <td
                            style={{
                                width: "70pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                <br />
                            </p>
                        </td>
                        <td
                            style={{
                                width: "70pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                <br />
                            </p>
                        </td>
                        <td
                            style={{
                                width: "55pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                <br />
                            </p>
                        </td>
                    </tr>
                    <tr style={{ height: "15pt" }}>
                        <td
                            style={{
                                width: "289pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s3}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "4pt",
                                    textIndent: "0pt",
                                    textAlign: "left"
                                }}
                            >
                                (1) Import of goods
                            </p>
                        </td>
                        <td
                            style={{
                                width: "70pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s3}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "5pt",
                                    textIndent: "0pt",
                                    textAlign: "right"
                                }}
                            >
                                {gstrf.data.a4.user.a.rowa.igst.value}
                            </p>
                        </td>
                        <td
                            style={{
                                width: "70pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s3}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "5pt",
                                    textIndent: "0pt",
                                    textAlign: "right"
                                }}
                            >
                                0.00
                            </p>
                        </td>
                        <td
                            style={{
                                width: "70pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s3}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "5pt",
                                    textIndent: "0pt",
                                    textAlign: "right"
                                }}
                            >
                                0.00
                            </p>
                        </td>
                        <td
                            style={{
                                width: "55pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s3}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "5pt",
                                    textIndent: "0pt",
                                    textAlign: "right"
                                }}
                            >
                                {gstrf.data.a4.user.a.rowa.cess.value}
                            </p>
                        </td>
                    </tr>
                    <tr style={{ height: "15pt" }}>
                        <td
                            style={{
                                width: "289pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s3}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "4pt",
                                    textIndent: "0pt",
                                    textAlign: "left"
                                }}
                            >
                                (2) Import of services
                            </p>
                        </td>
                        <td
                            style={{
                                width: "70pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s3}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "5pt",
                                    textIndent: "0pt",
                                    textAlign: "right"
                                }}
                            >
                                {gstrf.data.a4.user.a.rowb.igst.value}
                            </p>
                        </td>
                        <td
                            style={{
                                width: "70pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s3}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "5pt",
                                    textIndent: "0pt",
                                    textAlign: "right"
                                }}
                            >
                                0.00
                            </p>
                        </td>
                        <td
                            style={{
                                width: "70pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s3}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "5pt",
                                    textIndent: "0pt",
                                    textAlign: "right"
                                }}
                            >
                                0.00
                            </p>
                        </td>
                        <td
                            style={{
                                width: "55pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s3}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "5pt",
                                    textIndent: "0pt",
                                    textAlign: "right"
                                }}
                            >
                                {gstrf.data.a4.user.a.rowb.cess.value}
                            </p>
                        </td>
                    </tr>
                    <tr style={{ height: "15pt" }}>
                        <td
                            style={{
                                width: "289pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s3}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "4pt",
                                    textIndent: "0pt",
                                    textAlign: "left"
                                }}
                            >
                                (3) Inward supplies liable to reverse charge (other than 1 &amp; 2 above)
                            </p>
                        </td>
                        <td
                            style={{
                                width: "70pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s3}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "5pt",
                                    textIndent: "0pt",
                                    textAlign: "right"
                                }}
                            >
                                {gstrf.data.a4.user.a.rowc.igst.value}
                            </p>
                        </td>
                        <td
                            style={{
                                width: "70pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s3}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "5pt",
                                    textIndent: "0pt",
                                    textAlign: "right"
                                }}
                            >
                                {gstrf.data.a4.user.a.rowc.cgst.value}
                            </p>
                        </td>
                        <td
                            style={{
                                width: "70pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s3}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "5pt",
                                    textIndent: "0pt",
                                    textAlign: "right"
                                }}
                            >
                                {gstrf.data.a4.user.a.rowc.sgst.value}
                            </p>
                        </td>
                        <td
                            style={{
                                width: "55pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s3}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "5pt",
                                    textIndent: "0pt",
                                    textAlign: "right"
                                }}
                            >
                                {gstrf.data.a4.user.a.rowc.cess.value}
                            </p>
                        </td>
                    </tr>
                </tbody>
            </table>
            <p style={{ pageBreakAfter: 'always' }}>&nbsp;</p>
            <div className='fixed w-[595pt] h-[800pt] flex flex-col items-center justify-center opacity-20 pointer-events-none' style={{ zIndex: 9999 }}>
                <img src={Logo} alt="ITAA" className='w-[800] mx-auto h-[227px] pointer-events-none' style={{ transform: "rotate(-45deg)" }} />
            </div>
            <table style={{ borderCollapse: "collapse", marginLeft: "18pt", marginTop: "20pt" }} cellSpacing={0}>
                <tbody>
                    <tr style={{ height: "15pt" }}>
                        <td
                            style={{
                                width: "289pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s3}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "4pt",
                                    textIndent: "0pt",
                                    textAlign: "left"
                                }}
                            >
                                (4) Inward supplies from ISD
                            </p>
                        </td>
                        <td
                            style={{
                                width: "70pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s3}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right"
                                }}
                            >
                                {gstrf.data.a4.user.a.rowd.igst.value}
                            </p>
                        </td>
                        <td
                            style={{
                                width: "70pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s3}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right"
                                }}
                            >
                                {gstrf.data.a4.user.a.rowd.cgst.value}
                            </p>
                        </td>
                        <td
                            style={{
                                width: "70pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s3}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right"
                                }}
                            >
                                {gstrf.data.a4.user.a.rowd.sgst.value}
                            </p>
                        </td>
                        <td
                            style={{
                                width: "55pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s3}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right"
                                }}
                            >
                                {gstrf.data.a4.user.a.rowd.cess.value}
                            </p>
                        </td>
                    </tr>
                    <tr style={{ height: "15pt" }}>
                        <td
                            style={{
                                width: "289pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s3}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "4pt",
                                    textIndent: "0pt",
                                    textAlign: "left"
                                }}
                            >
                                (5) All other ITC
                            </p>
                        </td>
                        <td
                            style={{
                                width: "70pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s3}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right"
                                }}
                            >
                                {gstrf.data.a4.user.a.rowe.igst.value}
                            </p>
                        </td>
                        <td
                            style={{
                                width: "70pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s3}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right"
                                }}
                            >
                                {gstrf.data.a4.user.a.rowe.cgst.value}
                            </p>
                        </td>
                        <td
                            style={{
                                width: "70pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s3}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right"
                                }}
                            >
                                {gstrf.data.a4.user.a.rowe.sgst.value}
                            </p>
                        </td>
                        <td
                            style={{
                                width: "55pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s3}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right"
                                }}
                            >
                                {gstrf.data.a4.user.a.rowe.cess.value}
                            </p>
                        </td>
                    </tr>
                    <tr style={{ height: "15pt" }}>
                        <td
                            style={{
                                width: "289pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s3}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "4pt",
                                    textIndent: "0pt",
                                    textAlign: "left"
                                }}
                            >
                                B. ITC Reversed
                            </p>
                        </td>
                        <td
                            style={{
                                width: "70pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                <br />
                            </p>
                        </td>
                        <td
                            style={{
                                width: "70pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                <br />
                            </p>
                        </td>
                        <td
                            style={{
                                width: "70pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                <br />
                            </p>
                        </td>
                        <td
                            style={{
                                width: "55pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                <br />
                            </p>
                        </td>
                    </tr>
                    <tr style={{ height: "15pt" }}>
                        <td
                            style={{
                                width: "289pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s3}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "4pt",
                                    textIndent: "0pt",
                                    textAlign: "left"
                                }}
                            >
                                (1) As per rules 38,42 &amp; 43 of CGST Rules and section 17(5)
                            </p>
                        </td>
                        <td
                            style={{
                                width: "70pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s3}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right"
                                }}
                            >
                                {gstrf.data.a4.user.b.rowa.igst.value}
                            </p>
                        </td>
                        <td
                            style={{
                                width: "70pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s3}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right"
                                }}
                            >
                                {gstrf.data.a4.user.b.rowa.cgst.value}
                            </p>
                        </td>
                        <td
                            style={{
                                width: "70pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s3}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right"
                                }}
                            >
                                {gstrf.data.a4.user.b.rowa.sgst.value}
                            </p>
                        </td>
                        <td
                            style={{
                                width: "55pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s3}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right"
                                }}
                            >
                                {gstrf.data.a4.user.b.rowa.cess.value}
                            </p>
                        </td>
                    </tr>
                    <tr style={{ height: "15pt" }}>
                        <td
                            style={{
                                width: "289pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s3}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "4pt",
                                    textIndent: "0pt",
                                    textAlign: "left"
                                }}
                            >
                                (2) Others
                            </p>
                        </td>
                        <td
                            style={{
                                width: "70pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s3}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right"
                                }}
                            >
                                {gstrf.data.a4.user.b.rowb.igst.value}
                            </p>
                        </td>
                        <td
                            style={{
                                width: "70pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s3}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right"
                                }}
                            >
                                {gstrf.data.a4.user.b.rowb.cgst.value}
                            </p>
                        </td>
                        <td
                            style={{
                                width: "70pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s3}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right"
                                }}
                            >
                                {gstrf.data.a4.user.b.rowb.sgst.value}
                            </p>
                        </td>
                        <td
                            style={{
                                width: "55pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s3}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right"
                                }}
                            >
                                {gstrf.data.a4.user.b.rowb.cess.value}
                            </p>
                        </td>
                    </tr>
                    <tr style={{ height: "15pt" }}>
                        <td
                            style={{
                                width: "289pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s3}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "4pt",
                                    textIndent: "0pt",
                                    textAlign: "left"
                                }}
                            >
                                C. Net ITC available (A-B)
                            </p>
                        </td>
                        <td
                            style={{
                                width: "70pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s3}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right"
                                }}
                            >
                                {parseFloat(gstrf.data.a4.user.c.igst).toFixed(2)}
                            </p>
                        </td>
                        <td
                            style={{
                                width: "70pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s3}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right"
                                }}
                            >
                                {parseFloat(gstrf.data.a4.user.c.cgst).toFixed(2)}
                            </p>
                        </td>
                        <td
                            style={{
                                width: "70pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s3}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right"
                                }}
                            >
                                {parseFloat(gstrf.data.a4.user.c.sgst).toFixed(2)}
                            </p>
                        </td>
                        <td
                            style={{
                                width: "55pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s3}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right"
                                }}
                            >
                                {parseFloat(gstrf.data.a4.user.c.cess).toFixed(2)}
                            </p>
                        </td>
                    </tr>
                    <tr style={{ height: "15pt" }}>
                        <td
                            style={{
                                width: "289pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s3}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "4pt",
                                    textIndent: "0pt",
                                    textAlign: "left"
                                }}
                            >
                                (D) Other Details
                            </p>
                        </td>
                        <td
                            style={{
                                width: "70pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s3}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right"
                                }}
                            >
                                {(parseFloat(gstrf.data.a4.user.d.rowa.igst.value) + parseFloat(gstrf.data.a4.user.d.rowb.igst.value)).toFixed(2)}
                            </p>
                        </td>
                        <td
                            style={{
                                width: "70pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s3}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right"
                                }}
                            >
                                {(parseFloat(gstrf.data.a4.user.d.rowa.cgst.value) + parseFloat(gstrf.data.a4.user.d.rowb.cgst.value)).toFixed(2)}
                            </p>
                        </td>
                        <td
                            style={{
                                width: "70pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s3}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right"
                                }}
                            >
                                {(parseFloat(gstrf.data.a4.user.d.rowa.sgst.value) + parseFloat(gstrf.data.a4.user.d.rowb.sgst.value)).toFixed(2)}
                            </p>
                        </td>
                        <td
                            style={{
                                width: "55pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s3}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right"
                                }}
                            >
                                {(parseFloat(gstrf.data.a4.user.d.rowa.cess.value) + parseFloat(gstrf.data.a4.user.d.rowb.cess.value)).toFixed(2)}
                            </p>
                        </td>
                    </tr>
                    <tr style={{ height: "26pt" }}>
                        <td
                            style={{
                                width: "289pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s3}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "4pt",
                                    textIndent: "0pt",
                                    textAlign: "left"
                                }}
                            >
                                (1) ITC reclaimed which was reversed under Table 4(B)(2) in earlier
                                tax period
                            </p>
                        </td>
                        <td
                            style={{
                                width: "70pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s3}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right"
                                }}
                            >
                                {gstrf.data.a4.user.d.rowa.igst.value}
                            </p>
                        </td>
                        <td
                            style={{
                                width: "70pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s3}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right"
                                }}
                            >
                                {gstrf.data.a4.user.d.rowa.cgst.value}
                            </p>
                        </td>
                        <td
                            style={{
                                width: "70pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s3}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right"
                                }}
                            >
                                {gstrf.data.a4.user.d.rowa.sgst.value}
                            </p>
                        </td>
                        <td
                            style={{
                                width: "55pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s3}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right"
                                }}
                            >
                                {gstrf.data.a4.user.d.rowa.cess.value}
                            </p>
                        </td>
                    </tr>
                    <tr style={{ height: "15pt" }}>
                        <td
                            style={{
                                width: "289pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s3}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "4pt",
                                    textIndent: "0pt",
                                    textAlign: "left"
                                }}
                            >
                                (2) Ineligible ITC under section 16(4) &amp; ITC restricted due to
                                PoS rules
                            </p>
                        </td>
                        <td
                            style={{
                                width: "70pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s3}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right"
                                }}
                            >
                                {gstrf.data.a4.user.d.rowb.igst.value}
                            </p>
                        </td>
                        <td
                            style={{
                                width: "70pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s3}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right"
                                }}
                            >
                                {gstrf.data.a4.user.d.rowb.cgst.value}
                            </p>
                        </td>
                        <td
                            style={{
                                width: "70pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s3}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right"
                                }}
                            >
                                {gstrf.data.a4.user.d.rowb.sgst.value}
                            </p>
                        </td>
                        <td
                            style={{
                                width: "55pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s3}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right"
                                }}
                            >
                                {gstrf.data.a4.user.d.rowb.cess.value}
                            </p>
                        </td>
                    </tr>
                </tbody>
            </table>
            <p style={{ textIndent: "0pt", textAlign: "left" }}>
                <br />
            </p>
            <p className={styles.sh} style={{ width: '555pt', marginLeft: "18pt", paddingTop: "1pt", paddingBottom: "1pt", paddingLeft: "5pt", textIndent: "0pt", textAlign: "left", backgroundColor: "#FBE9D8" }}>
                5 Values of exempt, nil-rated and non-GST inward supplies
            </p>
            <p style={{ paddingLeft: "5pt", paddingTop: "5pt", textIndent: "0pt", textAlign: "left" }} />
            <table style={{ borderCollapse: "collapse", marginLeft: "18pt" }} cellSpacing={0} >
                <tbody>
                    <tr style={{ height: "15pt" }}>
                        <td
                            style={{
                                width: "325pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            bgcolor="#F2F2F2"
                        >
                            <p
                                className={styles.s3}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "4pt",
                                    textIndent: "0pt",
                                    textAlign: "left"
                                }}
                            >
                                Nature of Supplies
                            </p>
                        </td>
                        <td
                            style={{
                                width: "115pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            bgcolor="#F2F2F2"
                        >
                            <p
                                className={styles.s3}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "4pt",
                                    textIndent: "0pt",
                                    textAlign: "left"
                                }}
                            >
                                Inter- State supplies
                            </p>
                        </td>
                        <td
                            style={{
                                width: "114pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            bgcolor="#F2F2F2"
                        >
                            <p
                                className={styles.s3}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "4pt",
                                    textIndent: "0pt",
                                    textAlign: "left"
                                }}
                            >
                                Intra- State supplies
                            </p>
                        </td>
                    </tr>
                    <tr style={{ height: "15pt" }}>
                        <td
                            style={{
                                width: "325pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s3}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "4pt",
                                    textIndent: "0pt",
                                    textAlign: "left"
                                }}
                            >
                                From a supplier under composition scheme, Exempt, Nil rated supply
                            </p>
                        </td>
                        <td
                            style={{
                                width: "115pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s3}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right"
                                }}
                            >
                                {parseFloat(gstrf.data.a5.user.a.igst.value).toFixed(2)}
                            </p>
                        </td>
                        <td
                            style={{
                                width: "114pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s3}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right"
                                }}
                            >
                                {parseFloat(gstrf.data.a5.user.a.cgst.value).toFixed(2)}
                            </p>
                        </td>
                    </tr>
                    <tr style={{ height: "15pt" }}>
                        <td
                            style={{
                                width: "325pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s3}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "4pt",
                                    textIndent: "0pt",
                                    textAlign: "left"
                                }}
                            >
                                Non GST supply
                            </p>
                        </td>
                        <td
                            style={{
                                width: "115pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s3}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right"
                                }}
                            >
                                {parseFloat(gstrf.data.a5.user.b.igst.value).toFixed(2)}
                            </p>
                        </td>
                        <td
                            style={{
                                width: "114pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s3}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right"
                                }}
                            >
                                {parseFloat(gstrf.data.a5.user.b.cgst.value).toFixed(2)}
                            </p>
                        </td>
                    </tr>
                </tbody>
            </table>
            <p style={{ textIndent: "0pt", textAlign: "left" }}>
                <br />
            </p>
            <p className={styles.sh} style={{ width: '555pt', marginLeft: "18pt", paddingTop: "1pt", paddingBottom: "1pt", paddingLeft: "5pt", textIndent: "0pt", textAlign: "left", backgroundColor: "#FBE9D8" }}>
                5.1 Interest and Late fee for previous tax period
            </p>
            <p style={{ paddingLeft: "5pt", paddingTop: "5pt", textIndent: "0pt", textAlign: "left" }} />
            <table style={{ borderCollapse: "collapse", marginLeft: "18pt" }} cellSpacing={0} >
                <tbody>
                    <tr style={{ height: "15pt" }}>
                        <td
                            style={{
                                width: "111pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            bgcolor="#F2F2F2"
                        >
                            <p
                                className={styles.s3}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "4pt",
                                    textIndent: "0pt",
                                    textAlign: "left"
                                }}
                            >
                                Details
                            </p>
                        </td>
                        <td
                            style={{
                                width: "111pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            bgcolor="#F2F2F2"
                        >
                            <p
                                className={styles.s3}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "4pt",
                                    textIndent: "0pt",
                                    textAlign: "left"
                                }}
                            >
                                Integrated tax
                            </p>
                        </td>
                        <td
                            style={{
                                width: "111pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            bgcolor="#F2F2F2"
                        >
                            <p
                                className={styles.s3}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "4pt",
                                    textIndent: "0pt",
                                    textAlign: "left"
                                }}
                            >
                                Central tax
                            </p>
                        </td>
                        <td
                            style={{
                                width: "110pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            bgcolor="#F2F2F2"
                        >
                            <p
                                className={styles.s3}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "4pt",
                                    textIndent: "0pt",
                                    textAlign: "left"
                                }}
                            >
                                State/UT tax
                            </p>
                        </td>
                        <td
                            style={{
                                width: "111pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            bgcolor="#F2F2F2"
                        >
                            <p
                                className={styles.s3}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "4pt",
                                    textIndent: "0pt",
                                    textAlign: "left"
                                }}
                            >
                                Cess
                            </p>
                        </td>
                    </tr>
                    <tr style={{ height: "26pt" }}>
                        <td
                            style={{
                                width: "111pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s3}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "4pt",
                                    paddingRight: "4pt",
                                    textIndent: "0pt",
                                    textAlign: "left"
                                }}
                            >
                                System computed Interest
                            </p>
                        </td>
                        <td
                            style={{
                                width: "111pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s3}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "1pt",
                                    textIndent: "0pt",
                                    textAlign: "center"
                                }}
                            >
                                -
                            </p>
                        </td>
                        <td
                            style={{
                                width: "111pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s3}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "1pt",
                                    textIndent: "0pt",
                                    textAlign: "center"
                                }}
                            >
                                -
                            </p>
                        </td>
                        <td
                            style={{
                                width: "110pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s3}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "1pt",
                                    textIndent: "0pt",
                                    textAlign: "center"
                                }}
                            >
                                -
                            </p>
                        </td>
                        <td
                            style={{
                                width: "111pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s3}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "1pt",
                                    textIndent: "0pt",
                                    textAlign: "center"
                                }}
                            >
                                -
                            </p>
                        </td>
                    </tr>
                    <tr style={{ height: "15pt" }}>
                        <td
                            style={{
                                width: "111pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s3}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "4pt",
                                    textIndent: "0pt",
                                    textAlign: "left"
                                }}
                            >
                                Interest Paid
                            </p>
                        </td>
                        <td
                            style={{
                                width: "111pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s3}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right"
                                }}
                            >
                                {parseFloat(gstrf.data.a51.user.a.igst.value).toFixed(2)}
                            </p>
                        </td>
                        <td
                            style={{
                                width: "111pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s3}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right"
                                }}
                            >
                                {parseFloat(gstrf.data.a51.user.a.cgst.value).toFixed(2)}
                            </p>
                        </td>
                        <td
                            style={{
                                width: "110pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s3}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right"
                                }}
                            >
                                {parseFloat(gstrf.data.a51.user.a.sgst.value).toFixed(2)}
                            </p>
                        </td>
                        <td
                            style={{
                                width: "111pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s3}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right"
                                }}
                            >
                                {parseFloat(gstrf.data.a51.user.a.cess.value).toFixed(2)}
                            </p>
                        </td>
                    </tr>
                    <tr style={{ height: "15pt" }}>
                        <td
                            style={{
                                width: "111pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s3}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "4pt",
                                    textIndent: "0pt",
                                    textAlign: "left"
                                }}
                            >
                                Late fee
                            </p>
                        </td>
                        <td
                            style={{
                                width: "111pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s3}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "1pt",
                                    textIndent: "0pt",
                                    textAlign: "center"
                                }}
                            >
                                -
                            </p>
                        </td>
                        <td
                            style={{
                                width: "111pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s3}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right"
                                }}
                            >
                                {parseFloat(gstrf.data.a51.user.b.igst.value).toFixed(2)}
                            </p>
                        </td>
                        <td
                            style={{
                                width: "110pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s3}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right"
                                }}
                            >
                                {parseFloat(gstrf.data.a51.user.b.cgst.value).toFixed(2)}
                            </p>
                        </td>
                        <td
                            style={{
                                width: "111pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s3}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "1pt",
                                    textIndent: "0pt",
                                    textAlign: "center"
                                }}
                            >
                                -
                            </p>
                        </td>
                    </tr>
                </tbody>
            </table>
            <p style={{ textIndent: "0pt", textAlign: "left" }}>
                <br />
            </p>
            <p className={styles.sh} style={{ width: '555pt', marginLeft: "18pt", paddingTop: "1pt", paddingBottom: "1pt", paddingLeft: "5pt", textIndent: "0pt", textAlign: "left", backgroundColor: "#FBE9D8" }}>
                6.1 Payment of tax
            </p>
            <p style={{ paddingLeft: "5pt", paddingTop: "5pt", textIndent: "0pt", textAlign: "left" }} />
            <table style={{ borderCollapse: "collapse", marginLeft: "18pt" }} cellSpacing={0}>
                <tbody>
                    <tr style={{ height: "15pt" }}>
                        <td
                            style={{
                                width: "62pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            rowSpan={2}
                            bgcolor="#F2F2F2"
                        >
                            <p
                                className={styles.s3}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "4pt",
                                    textIndent: "0pt",
                                    textAlign: "left"
                                }}
                            >
                                Description
                            </p>
                        </td>
                        <td
                            style={{
                                width: "72pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            rowSpan={2}
                            bgcolor="#F2F2F2"
                        >
                            <p
                                className={styles.s3}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "4pt",
                                    paddingRight: "30pt",
                                    textIndent: "0pt",
                                    textAlign: "left"
                                }}
                            >
                                Total tax payable
                            </p>
                        </td>
                        <td
                            style={{
                                width: "195pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            colSpan={4}
                            bgcolor="#F2F2F2"
                        >
                            <p
                                className={styles.s3}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "56pt",
                                    textIndent: "0pt",
                                    textAlign: "left"
                                }}
                            >
                                Tax paid through ITC
                            </p>
                        </td>
                        <td
                            style={{
                                width: "65pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            rowSpan={2}
                            bgcolor="#F2F2F2"
                        >
                            <p
                                className={styles.s3}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "4pt",
                                    paddingRight: "15pt",
                                    textIndent: "0pt",
                                    textAlign: "left"
                                }}
                            >
                                Tax paid in cash
                            </p>
                        </td>
                        <td
                            style={{
                                width: "80pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            rowSpan={2}
                            bgcolor="#F2F2F2"
                        >
                            <p
                                className={styles.s3}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "4pt",
                                    textIndent: "0pt",
                                    textAlign: "left"
                                }}
                            >
                                Interest paid in cash
                            </p>
                        </td>
                        <td
                            style={{
                                width: "80pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            rowSpan={2}
                            bgcolor="#F2F2F2"
                        >
                            <p
                                className={styles.s3}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "4pt",
                                    textIndent: "0pt",
                                    textAlign: "left"
                                }}
                            >
                                Late fee paid in cash
                            </p>
                        </td>
                    </tr>
                    <tr style={{ height: "26pt" }}>
                        <td
                            style={{
                                width: "62pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            bgcolor="#F2F2F2"
                        >
                            <p
                                className={styles.s3}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "4pt",
                                    paddingRight: "5pt",
                                    textIndent: "0pt",
                                    textAlign: "left"
                                }}
                            >
                                Integrated tax
                            </p>
                        </td>
                        <td
                            style={{
                                width: "49pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            bgcolor="#F2F2F2"
                        >
                            <p
                                className={styles.s3}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "4pt",
                                    paddingRight: "14pt",
                                    textIndent: "0pt",
                                    textAlign: "left"
                                }}
                            >
                                Central tax
                            </p>
                        </td>
                        <td
                            style={{
                                width: "55pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            bgcolor="#F2F2F2"
                        >
                            <p
                                className={styles.s3}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "4pt",
                                    paddingRight: "12pt",
                                    textIndent: "0pt",
                                    textAlign: "left"
                                }}
                            >
                                State/UT tax
                            </p>
                        </td>
                        <td
                            style={{
                                width: "29pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            bgcolor="#F2F2F2"
                        >
                            <p
                                className={styles.s3}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "3pt",
                                    paddingRight: "2pt",
                                    textIndent: "0pt",
                                    textAlign: "center"
                                }}
                            >
                                Cess
                            </p>
                        </td>
                    </tr>
                    <tr style={{ height: "15pt" }}>
                        <td
                            style={{
                                width: "554pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            colSpan={9}
                        >
                            <p
                                className={styles.s3}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "4pt",
                                    textIndent: "0pt",
                                    textAlign: "left"
                                }}
                            >
                                (A) Other than reverse charge
                            </p>
                        </td>
                    </tr>
                    <tr style={{ height: "26pt" }}>
                        <td
                            style={{
                                width: "62pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s3}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "4pt",
                                    paddingRight: "15pt",
                                    textIndent: "0pt",
                                    textAlign: "left"
                                }}
                            >
                                Integrated tax
                            </p>
                        </td>
                        <td
                            style={{
                                width: "72pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s3}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right"
                                }}
                            >
                                {getIGST('A3.1').toFixed(2)}
                            </p>
                        </td>
                        <td
                            style={{
                                width: "62pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s3}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right"
                                }}
                            >
                                {parseFloat(setoff.igst.igsta).toFixed(2)}
                            </p>
                        </td>
                        <td
                            style={{
                                width: "49pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s3}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right"
                                }}
                            >
                                {parseFloat(setoff.cgst.cgsta).toFixed(2)}
                            </p>
                        </td>
                        <td
                            style={{
                                width: "55pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s3}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right"
                                }}
                            >
                                {parseFloat(setoff.sgst.sgsta).toFixed(2)}
                            </p>
                        </td>
                        <td
                            style={{
                                width: "29pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s3}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "1pt",
                                    textIndent: "0pt",
                                    textAlign: "center"
                                }}
                            >
                                -
                            </p>
                        </td>
                        <td
                            style={{
                                width: "65pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s3}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right"
                                }}
                            >
                                {parseFloat(setoff.bal.bala).toFixed(2)}
                            </p>
                        </td>
                        <td
                            style={{
                                width: "80pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s3}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right"
                                }}
                            >
                                {getIGST('A51').toFixed(2)}
                            </p>
                        </td>
                        <td
                            style={{
                                width: "80pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s3}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "38pt",
                                    textIndent: "0pt",
                                    textAlign: "left"
                                }}
                            >
                                -
                            </p>
                        </td>
                    </tr>
                    <tr style={{ height: "15pt" }}>
                        <td
                            style={{
                                width: "62pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s3}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "4pt",
                                    textIndent: "0pt",
                                    textAlign: "left"
                                }}
                            >
                                Central tax
                            </p>
                        </td>
                        <td
                            style={{
                                width: "72pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s3}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right"
                                }}
                            >
                                {getCGST('A3.1').toFixed(2)}
                            </p>
                        </td>
                        <td
                            style={{
                                width: "62pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s3}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right"
                                }}
                            >
                                {parseFloat(setoff.igst.igstb).toFixed(2)}
                            </p>
                        </td>
                        <td
                            style={{
                                width: "49pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s3}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right"
                                }}
                            >
                                {parseFloat(setoff.cgst.cgstb).toFixed(2)}
                            </p>
                        </td>
                        <td
                            style={{
                                width: "55pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s3}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "26pt",
                                    textIndent: "0pt",
                                    textAlign: "left"
                                }}
                            >
                                -
                            </p>
                        </td>
                        <td
                            style={{
                                width: "29pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s3}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "1pt",
                                    textIndent: "0pt",
                                    textAlign: "center"
                                }}
                            >
                                -
                            </p>
                        </td>
                        <td
                            style={{
                                width: "65pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s3}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right"
                                }}
                            >
                                {parseFloat(setoff.bal.balb).toFixed(2)}
                            </p>
                        </td>
                        <td
                            style={{
                                width: "80pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s3}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right"
                                }}
                            >
                                {getCGST('A51').toFixed(2)}
                            </p>
                        </td>
                        <td
                            style={{
                                width: "80pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s3}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right"
                                }}
                            >
                                {getIGST('A5').toFixed(2)}
                            </p>
                        </td>
                    </tr>
                    <tr style={{ height: "15pt" }}>
                        <td
                            style={{
                                width: "62pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s3}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "4pt",
                                    textIndent: "0pt",
                                    textAlign: "left"
                                }}
                            >
                                State/UT tax
                            </p>
                        </td>
                        <td
                            style={{
                                width: "72pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s3}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right"
                                }}
                            >
                                {getSGST('A3.1').toFixed(2)}
                            </p>
                        </td>
                        <td
                            style={{
                                width: "62pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s3}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right"
                                }}
                            >
                                {parseFloat(setoff.igst.igstc).toFixed(2)}
                            </p>
                        </td>
                        <td
                            style={{
                                width: "49pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s3}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "23pt",
                                    textIndent: "0pt",
                                    textAlign: "left"
                                }}
                            >
                                -
                            </p>
                        </td>
                        <td
                            style={{
                                width: "55pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s3}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right"
                                }}
                            >
                                {parseFloat(setoff.sgst.sgstb).toFixed(2)}
                            </p>
                        </td>
                        <td
                            style={{
                                width: "29pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s3}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "1pt",
                                    textIndent: "0pt",
                                    textAlign: "center"
                                }}
                            >
                                -
                            </p>
                        </td>
                        <td
                            style={{
                                width: "65pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s3}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right"
                                }}
                            >
                                {parseFloat(setoff.bal.balc).toFixed(2)}
                            </p>
                        </td>
                        <td
                            style={{
                                width: "80pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s3}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right"
                                }}
                            >
                                {getSGST('A51').toFixed(2)}
                            </p>
                        </td>
                        <td
                            style={{
                                width: "80pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s3}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right"
                                }}
                            >
                                {getCGST('A5').toFixed(2)}
                            </p>
                        </td>
                    </tr>
                    <tr style={{ height: "15pt" }}>
                        <td
                            style={{
                                width: "62pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s3}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "4pt",
                                    textIndent: "0pt",
                                    textAlign: "left"
                                }}
                            >
                                Cess
                            </p>
                        </td>
                        <td
                            style={{
                                width: "72pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s3}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right"
                                }}
                            >
                                {getCess('A3.1').toFixed(2)}
                            </p>
                        </td>
                        <td
                            style={{
                                width: "62pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s3}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "29pt",
                                    textIndent: "0pt",
                                    textAlign: "left"
                                }}
                            >
                                -
                            </p>
                        </td>
                        <td
                            style={{
                                width: "49pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s3}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "23pt",
                                    textIndent: "0pt",
                                    textAlign: "left"
                                }}
                            >
                                -
                            </p>
                        </td>
                        <td
                            style={{
                                width: "55pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s3}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "26pt",
                                    textIndent: "0pt",
                                    textAlign: "left"
                                }}
                            >
                                -
                            </p>
                        </td>
                        <td
                            style={{
                                width: "29pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s3}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "center"
                                }}
                            >
                                {parseFloat(setoff.cess).toFixed(2)}
                            </p>
                        </td>
                        <td
                            style={{
                                width: "65pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s3}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right"
                                }}
                            >
                                {parseFloat(setoff.bal.bald).toFixed(2)}
                            </p>
                        </td>
                        <td
                            style={{
                                width: "80pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s3}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right"
                                }}
                            >
                                {getCess('A51').toFixed(2)}
                            </p>
                        </td>
                        <td
                            style={{
                                width: "80pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s3}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "38pt",
                                    textIndent: "0pt",
                                    textAlign: "left"
                                }}
                            >
                                -
                            </p>
                        </td>
                    </tr>
                    <tr style={{ height: "15pt" }}>
                        <td
                            style={{
                                width: "554pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            colSpan={9}
                        >
                            <p
                                className={styles.s3}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "4pt",
                                    textIndent: "0pt",
                                    textAlign: "left"
                                }}
                            >
                                (B) Reverse charge
                            </p>
                        </td>
                    </tr>
                    <tr style={{ height: "26pt" }}>
                        <td
                            style={{
                                width: "62pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s3}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "4pt",
                                    paddingRight: "15pt",
                                    textIndent: "0pt",
                                    textAlign: "left"
                                }}
                            >
                                Integrated tax
                            </p>
                        </td>
                        <td
                            style={{
                                width: "72pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s3}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right"
                                }}
                            >
                                0.00
                            </p>
                        </td>
                        <td
                            style={{
                                width: "62pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s3}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "29pt",
                                    textIndent: "0pt",
                                    textAlign: "left"
                                }}
                            >
                                -
                            </p>
                        </td>
                        <td
                            style={{
                                width: "49pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s3}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "23pt",
                                    textIndent: "0pt",
                                    textAlign: "left"
                                }}
                            >
                                -
                            </p>
                        </td>
                        <td
                            style={{
                                width: "55pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s3}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "26pt",
                                    textIndent: "0pt",
                                    textAlign: "left"
                                }}
                            >
                                -
                            </p>
                        </td>
                        <td
                            style={{
                                width: "29pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s3}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "1pt",
                                    textIndent: "0pt",
                                    textAlign: "center"
                                }}
                            >
                                -
                            </p>
                        </td>
                        <td
                            style={{
                                width: "65pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s3}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right"
                                }}
                            >
                                0.00
                            </p>
                        </td>
                        <td
                            style={{
                                width: "80pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s3}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "39pt",
                                    textIndent: "0pt",
                                    textAlign: "left"
                                }}
                            >
                                -
                            </p>
                        </td>
                        <td
                            style={{
                                width: "80pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s3}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "38pt",
                                    textIndent: "0pt",
                                    textAlign: "left"
                                }}
                            >
                                -
                            </p>
                        </td>
                    </tr>
                    <tr style={{ height: "15pt" }}>
                        <td
                            style={{
                                width: "62pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s3}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "4pt",
                                    textIndent: "0pt",
                                    textAlign: "left"
                                }}
                            >
                                Central tax
                            </p>
                        </td>
                        <td
                            style={{
                                width: "72pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s3}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right"
                                }}
                            >
                                0.00
                            </p>
                        </td>
                        <td
                            style={{
                                width: "62pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s3}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "29pt",
                                    textIndent: "0pt",
                                    textAlign: "left"
                                }}
                            >
                                -
                            </p>
                        </td>
                        <td
                            style={{
                                width: "49pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s3}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "23pt",
                                    textIndent: "0pt",
                                    textAlign: "left"
                                }}
                            >
                                -
                            </p>
                        </td>
                        <td
                            style={{
                                width: "55pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s3}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "26pt",
                                    textIndent: "0pt",
                                    textAlign: "left"
                                }}
                            >
                                -
                            </p>
                        </td>
                        <td
                            style={{
                                width: "29pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s3}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "1pt",
                                    textIndent: "0pt",
                                    textAlign: "center"
                                }}
                            >
                                -
                            </p>
                        </td>
                        <td
                            style={{
                                width: "65pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s3}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right"
                                }}
                            >
                                0.00
                            </p>
                        </td>
                        <td
                            style={{
                                width: "80pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s3}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "39pt",
                                    textIndent: "0pt",
                                    textAlign: "left"
                                }}
                            >
                                -
                            </p>
                        </td>
                        <td
                            style={{
                                width: "80pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s3}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "38pt",
                                    textIndent: "0pt",
                                    textAlign: "left"
                                }}
                            >
                                -
                            </p>
                        </td>
                    </tr>
                    <tr style={{ height: "15pt" }}>
                        <td
                            style={{
                                width: "62pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s3}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "4pt",
                                    textIndent: "0pt",
                                    textAlign: "left"
                                }}
                            >
                                State/UT tax
                            </p>
                        </td>
                        <td
                            style={{
                                width: "72pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s3}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right"
                                }}
                            >
                                0.00
                            </p>
                        </td>
                        <td
                            style={{
                                width: "62pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s3}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "29pt",
                                    textIndent: "0pt",
                                    textAlign: "left"
                                }}
                            >
                                -
                            </p>
                        </td>
                        <td
                            style={{
                                width: "49pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s3}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "23pt",
                                    textIndent: "0pt",
                                    textAlign: "left"
                                }}
                            >
                                -
                            </p>
                        </td>
                        <td
                            style={{
                                width: "55pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s3}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "26pt",
                                    textIndent: "0pt",
                                    textAlign: "left"
                                }}
                            >
                                -
                            </p>
                        </td>
                        <td
                            style={{
                                width: "29pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s3}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "1pt",
                                    textIndent: "0pt",
                                    textAlign: "center"
                                }}
                            >
                                -
                            </p>
                        </td>
                        <td
                            style={{
                                width: "65pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s3}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right"
                                }}
                            >
                                0.00
                            </p>
                        </td>
                        <td
                            style={{
                                width: "80pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s3}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "39pt",
                                    textIndent: "0pt",
                                    textAlign: "left"
                                }}
                            >
                                -
                            </p>
                        </td>
                        <td
                            style={{
                                width: "80pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s3}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "38pt",
                                    textIndent: "0pt",
                                    textAlign: "left"
                                }}
                            >
                                -
                            </p>
                        </td>
                    </tr>
                    <tr style={{ height: "15pt" }}>
                        <td
                            style={{
                                width: "62pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s3}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "4pt",
                                    textIndent: "0pt",
                                    textAlign: "left"
                                }}
                            >
                                Cess
                            </p>
                        </td>
                        <td
                            style={{
                                width: "72pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s3}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right"
                                }}
                            >
                                0.00
                            </p>
                        </td>
                        <td
                            style={{
                                width: "62pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s3}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "29pt",
                                    textIndent: "0pt",
                                    textAlign: "left"
                                }}
                            >
                                -
                            </p>
                        </td>
                        <td
                            style={{
                                width: "49pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s3}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "23pt",
                                    textIndent: "0pt",
                                    textAlign: "left"
                                }}
                            >
                                -
                            </p>
                        </td>
                        <td
                            style={{
                                width: "55pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s3}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "26pt",
                                    textIndent: "0pt",
                                    textAlign: "left"
                                }}
                            >
                                -
                            </p>
                        </td>
                        <td
                            style={{
                                width: "29pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s3}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "1pt",
                                    textIndent: "0pt",
                                    textAlign: "center"
                                }}
                            >
                                -
                            </p>
                        </td>
                        <td
                            style={{
                                width: "65pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s3}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right"
                                }}
                            >
                                0.00
                            </p>
                        </td>
                        <td
                            style={{
                                width: "80pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s3}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "39pt",
                                    textIndent: "0pt",
                                    textAlign: "left"
                                }}
                            >
                                -
                            </p>
                        </td>
                        <td
                            style={{
                                width: "80pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s3}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "38pt",
                                    textIndent: "0pt",
                                    textAlign: "left"
                                }}
                            >
                                -
                            </p>
                        </td>
                    </tr>
                </tbody>
            </table>
            <p style={{ textIndent: "0pt", textAlign: "left" }}>
                <br />
            </p>
            <p className={styles.sh} style={{ width: '555pt', marginLeft: "18pt", paddingTop: "1pt", paddingBottom: "1pt", paddingLeft: "5pt", textIndent: "0pt", textAlign: "left", backgroundColor: "#FBE9D8" }}>
                Breakup of tax liability declared (for interest computation)
            </p>
            <p style={{ paddingLeft: "5pt", paddingTop: "5pt", textIndent: "0pt", textAlign: "left" }} />
            <table style={{ borderCollapse: "collapse", marginLeft: "18pt" }} cellSpacing={0} >
                <tbody>
                    <tr style={{ height: "15pt" }}>
                        <td
                            style={{
                                width: "111pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            bgcolor="#F2F2F2"
                        >
                            <p
                                className={styles.s3}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "4pt",
                                    textIndent: "0pt",
                                    textAlign: "left"
                                }}
                            >
                                Period
                            </p>
                        </td>
                        <td
                            style={{
                                width: "111pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            bgcolor="#F2F2F2"
                        >
                            <p
                                className={styles.s3}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "4pt",
                                    textIndent: "0pt",
                                    textAlign: "left"
                                }}
                            >
                                Integrated tax
                            </p>
                        </td>
                        <td
                            style={{
                                width: "111pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            bgcolor="#F2F2F2"
                        >
                            <p
                                className={styles.s3}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "4pt",
                                    textIndent: "0pt",
                                    textAlign: "left"
                                }}
                            >
                                Central tax
                            </p>
                        </td>
                        <td
                            style={{
                                width: "110pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            bgcolor="#F2F2F2"
                        >
                            <p
                                className={styles.s3}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "4pt",
                                    textIndent: "0pt",
                                    textAlign: "left"
                                }}
                            >
                                State/UT tax
                            </p>
                        </td>
                        <td
                            style={{
                                width: "111pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            bgcolor="#F2F2F2"
                        >
                            <p
                                className={styles.s3}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "4pt",
                                    textIndent: "0pt",
                                    textAlign: "left"
                                }}
                            >
                                Cess
                            </p>
                        </td>
                    </tr>
                    <tr style={{ height: "15pt" }}>
                        <td
                            style={{
                                width: "111pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s3}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "4pt",
                                    paddingRight: "4pt",
                                    textIndent: "0pt",
                                    textAlign: "left"
                                }}
                            >
                                {Moment(gDate).format("MMMM yyyy")}
                            </p>
                        </td>
                        <td
                            style={{
                                width: "111pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s3}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "1pt",
                                    paddingRight: "4pt",
                                    textIndent: "0pt",
                                    textAlign: "right"
                                }}
                            >
                                {getIGST('A3.1').toFixed(2)}
                            </p>
                        </td>
                        <td
                            style={{
                                width: "111pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s3}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "1pt",
                                    paddingRight: "4pt",
                                    textIndent: "0pt",
                                    textAlign: "right"
                                }}
                            >
                                {getCGST('A3.1').toFixed(2)}
                            </p>
                        </td>
                        <td
                            style={{
                                width: "110pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s3}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "1pt",
                                    paddingRight: "4pt",
                                    textIndent: "0pt",
                                    textAlign: "right"
                                }}
                            >
                                {getSGST('A3.1').toFixed(2)}
                            </p>
                        </td>
                        <td
                            style={{
                                width: "111pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s3}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "1pt",
                                    paddingRight: "4pt",
                                    textIndent: "0pt",
                                    textAlign: "right"
                                }}
                            >
                                {getCess('A3.1').toFixed(2)}
                            </p>
                        </td>
                    </tr>
                </tbody>
            </table>
            <p style={{ textIndent: "0pt", textAlign: "left" }}>
                <br />
            </p>
            <p className={styles.sh} style={{ paddingLeft: "18pt", textIndent: "0pt", textAlign: "left" }}>Verifcation:</p>
            <p className={styles.s3} style={{ paddingLeft: "18pt", textIndent: "0pt", textAlign: "left" }}>I hereby solemnly affrm and declare that the information given herein above is true and correct to the best of my knowledge and belief and nothing has been concealed there from.</p>
            <div style={{ paddingLeft: "18pt", paddingTop: "8pt", textIndent: "0pt", textAlign: "left" }} className="flex">
                <div className='w-1/2'>
                    <p className={styles.s3}>Date: {Moment(gDate).add('days', 19).format('DD/MM/YYYY')}</p>
                </div>
                <div>
                    <p className={styles.s3}>Name of Authorized Signatory</p>
                    <p className={styles.s3}><b className='text-black'>{getName()}</b></p>
                    <p className={styles.s3} style={{ paddingTop: '5pt' }}>Designation/Status: <br /><b className='text-black'>{getDesignation()}</b></p>
                </div>
            </div>
        </div>
    )
}
