import React, { useState, useEffect } from 'react';

import { useRecoilState } from 'recoil';
import { pathAtom, loadingAtom, trnLevelAtom, trnAtom, digErrorAtom, dirtyAtom, digStorageAtom, docAtom, userIDAtom, userAtom } from '../core/config/atoms';

import ServiceAuth from '../services/serviceAuth';
import ServiceUtility from '../services/serviceUtility';

import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Dialog } from '@headlessui/react';

import icPdf from '../assets/images/misc/pdf.png';

export default function ComponentTRNLevelI() {

    const [path, setPath] = useRecoilState(pathAtom);
    const [loading, setLoading] = useRecoilState(loadingAtom);
    const [trnLevel, SetTRNLevel] = useRecoilState(trnLevelAtom);
    const [trnno] = useRecoilState(trnAtom);
    const [digError, setDigError] = useRecoilState(digErrorAtom);
    const [digDoc, setDigDoc] = useRecoilState(digStorageAtom);
    const [isDirty, setIsDirty] = useRecoilState(dirtyAtom);
    const [doc, setDoc] = useRecoilState(docAtom);
    const [userID] = useRecoilState(userIDAtom);
    const [userAuth] = useRecoilState(userAtom);

    const [gstUsers, setGSTUsers] = useState([]);

    const [gstUser, setGSTUser] = useState({});
    const [formData, setFormData] = useState({});

    const [docData, setDocData] = useState({ "A": [], "B": [] });

    const [digCConfirm, setDigCConfirm] = useState({ 'type': '0', isToggle: false, data: null });
    const [digConfirm, setDigConfirm] = useState({ 'type': '0', isToggle: false, data: null });
    const [errors, setErrors] = useState({ 'enrlmnidpd0': 'error', 'enrlmnidpd1': 'error' });

    const [selID, setSelID] = useState('');

    const [isAdh, setIsAdh] = useState(true);
    const [isValid, setIsValid] = useState(false);
    const [isSubmit, setIsSubmit] = useState(false);

    const authService = new ServiceAuth();
    const utilService = new ServiceUtility();

    const gotoPath = (path) => {
        SetTRNLevel({ ...trnLevel, level: path });
    }

    const showDig = (type, data) => {
        setDigConfirm({ 'type': type, isToggle: true, data: data });
    }

    const process = () => {
        switch (digConfirm.type) {
            case '1':
                setIsAdh(false);
                break;
            default:
                break;
        }
        setDigConfirm({ 'type': '0', isToggle: false, data: null });
    }

    const cancel = () => {
        setDigConfirm({ 'type': '0', isToggle: false });
        setDigCConfirm({ 'type': '0', isToggle: false, data: null });
    }

    const showCDig = (type, data) => {
        setDigCConfirm({ 'type': type, isToggle: true, data: data });
    }

    const processC = () => {
        switch (digCConfirm.type) {
            case "1":
                removeDoc(digCConfirm.data);
                break;
            default:
                break;
        }
        setDigCConfirm({ 'type': '0', isToggle: false, data: null });
    }

    const removeDoc = (rData) => {
        if (docData[rData["t"]].length == 1) {
            if (rData["t"] === "A") {
                setDocData({ ...docData, "A": [] });
            } else {
                setDocData({ ...docData, "B": [] });
            }
        } else {
            let d = docData[rData["t"]].splice(rData["index"], 1);
            if (rData["t"] === "A") {
                setDocData({ ...docData, "A": d });
            } else {
                setDocData({ ...docData, "B": d });
            }
        }
    }

    const handleOnChangeForm = (e) => {
        var { value, name } = e.target;
        const adhaaRegex = RegExp(
            /^[0-9]{12}$/
        );

        switch (name) {
            case 'enrlmnidpd0':
                if (adhaaRegex.test(value.toUpperCase())) {
                    errors.enrlmnidpd0 = '';
                    setFormData({ ...formData, 'enrlmntmstmppd0': 'XX/XX/XXXX XX:XX:XX', [name]: value });
                } else {
                    errors.enrlmnidpd0 = 'error';
                    setFormData({ ...formData, [name]: value });
                }
                break;
            case 'enrlmnidpd1':
                if (adhaaRegex.test(value.toUpperCase())) {
                    errors.enrlmnidpd1 = '';
                    setFormData({ ...formData, 'enrlmntmstmppd1': 'XX/XX/XXXX XX:XX:XX', [name]: value });
                } else {
                    errors.enrlmnidpd1 = 'error';
                    setFormData({ ...formData, [name]: value });
                }
                break;
            case 'type0':
            case 'type1':
                errors.type = value.length < 1 ? 'error' : '';
                setFormData({ ...formData, [name]: value });
                break;
            default:
                if (formData[name] == 'true') {
                    setFormData({ ...formData, [name]: 'false' });
                } else {
                    setFormData({ ...formData, [name]: 'true' });
                }
                break;
        }
    }

    const adharAuth = () => {
        if (isAdh) {
            showDig('1', '');
        } else {
            setIsAdh(true);
        }
    }

    const openBox = (e) => {
        e.preventDefault();
        let name = e.target.name;
        let first = name.includes("0");
        let r = true;
        if (r) {
            if (first) {
                setSelID("A");
            } else {
                setSelID("B");
            }
            setDoc({ isFile: false, type: '', doc: [] });
            setDigDoc({ title: '', msg: '', isToggle: true });
        } else {
            //Todo: add error in aadhr form if its thier.
        }
    }

    const handeleSubmitLevel = (e) => {
        e.preventDefault();
        if (gstUsers.length == 0) {
            setIsDirty(false);
            SetTRNLevel({ ...trnLevel, level: 'sa10' });
        } else {
            setIsDirty(true);
            if (isAdh) {
                setLoading(true);
                let data = [...trnLevel.form];
                data[8] = "1";
                let aadhaar = [];
                let count = 0;

                gstUsers.forEach((u) => {
                    let b = {
                        checked: u.isPrime == '1' ? true : (formData['chkboxop' + count] === 'true'),
                        pan: u.pan,
                        data: {
                            doc: {},
                            eid: formData['enrlmnidpd' + count]
                        }
                    }
                    aadhaar.push(b);
                    count++;
                });

                let body = {
                    addhar: aadhaar,
                    addharType: isAdh,
                    trnno: trnno,
                    lid: userID,
                    statusArr: data,
                    user: userAuth,
                }
                authService.setGSTUserFormI(body).then((res) => {
                    if (res.statuscode === 200) {
                        setIsDirty(false);
                        setDoc({ isFile: false, type: '', doc: null });
                        SetTRNLevel({ ...trnLevel, level: 'sa10' });
                    } else {
                        setDigError({ title: 'Error', msg: 'Server error please try again!', isToggle: true });
                    }
                    setLoading(false);
                });
            } else {
                let error = { ...errors };
                if (gstUsers.length === 1) {
                    error['enrlmnidpd1'] = '';
                }
                if (utilService.validateForm(error)) {
                    let er = uploadDocument();
                    if (!er) {
                        setLoading(true);
                        let data = [...trnLevel.form];
                        data[8] = "1";
                        let aadhaar = [];
                        let count = 0;

                        gstUsers.forEach((u, inex) => {
                            let b = {
                                checked: u.isPrime == '1' ? true : (formData['chkboxop' + count] === 'true'),
                                pan: u.pan,
                                data: {
                                    doc: docData[inex === 0 ? "A" : "B"],
                                    eid: formData['enrlmnidpd' + count]
                                }
                            }
                            aadhaar.push(b);
                            count++;
                        });

                        let body = {
                            addhar: aadhaar,
                            addharType: isAdh,
                            trnno: trnno,
                            lid: userID,
                            statusArr: data,
                            user: userAuth,
                        }

                        authService.setGSTUserFormI(body).then((res) => {
                            if (res.statuscode === 200) {
                                setIsDirty(false);
                                setDoc({ isFile: false, type: '', doc: null });
                                SetTRNLevel({ ...trnLevel, level: 'sa10' });
                            } else {
                                setDigError({ title: 'Error', msg: 'Server error please try again!', isToggle: true });
                            }
                            setLoading(false);
                        });
                    } else {
                        setIsValid(true);
                    }
                } else {
                    setIsValid(true);
                }
            }
        }
    }

    const uploadDocument = () => {
        let er = false;
        gstUsers.forEach((u, inex) => {
            if (!er) {
                er = docData[inex === 0 ? "A" : "B"].length === 0;
            }
        });
        return er;
        //Todo: upload normal document.
    }

    const changeFile = () => {
        if (doc.isFile) {
            let mDoc = {
                type: doc.type,
                title: selID === "A" ? formData['type0'] : formData['type1'],
                file: doc.doc
            }
            let arr0 = [...docData["A"]];
            let arr1 = [...docData["B"]];
            if (selID === "A") {
                arr0.push(mDoc);
                setDocData({ ...docData, "A": arr0 });
            } else {
                arr1.push(mDoc);
                setDocData({ ...docData, "B": arr1 });
            }
            setDoc({ isFile: false, type: '', doc: null });
        }
    }

    const loadData = () => {
        (async function () {
            setLoading(true);
            var res = await authService.getGSTUserByTRN({ 'trn': trnno, 'stdOwn': userID });
            SetTRNLevel({ ...trnLevel, form: res.data[0]['statusArr'], percentage: utilService.calculatePercentage(res.data[0]['statusArr']) });
            let userD = res.data[0]['users'];
            userD = userD.filter(el => {
                return el.isPrime == '1' || el.isProm == '1';
            });
            setGSTUser(res.data[0]);
            setGSTUsers(userD);

            let adh = res.data[0]['addhar'];
            setIsAdh(res.data[0]['addharType']);
            let formD = { ...formData };
            for (let ind = 0; ind < adh.length; ind++) {
                let name = "chkboxop" + ind;
                if (adh[ind].checked) {
                    formD = { ...formD, [name]: 'true' }
                } else {
                    formD = { ...formD, [name]: 'false' }
                }

                if (!res.data[0]['addharType']) {
                    let nameb = "enrlmnidpd" + ind;
                    let namec = "enrlmntmstmppd" + ind;
                    formD = { ...formD, [nameb]: adh[ind].data.eid, [namec]: 'XX/XX/XXXX XX:XX:XX' };
                }
            }
            setFormData(formD);

            setLoading(false);
        })();
    }

    useEffect(() => {
        loadData();
    }, []);

    useEffect(() => {
        changeFile();
    }, [doc]);

    return (
        <form class="tabpane" name="newRegForm" id="newRegForm" autocomplete="off" noValidate onSubmit={handeleSubmitLevel}>
            <Dialog open={digConfirm.isToggle} onClose={() => setDigConfirm({ 'type': '0', isToggle: false, data: null })}>
                <div class="modal fade fade-scale in" style={{ display: 'block', paddingRight: '17px' }}>
                    <div class="modal-dialog sweet">
                        <div class="modal-content">
                            <div class="modal-body">
                                <div class="m-icon m-warning pulseWarning">
                                    <span class="micon-body pulseWarningIns"></span>
                                    <span class="micon-dot pulseWarningIns"></span>
                                </div>
                                <h2>Warning</h2>
                                <p>You have opted 'No' for Aadhaar authentication. So if you wish you can upload E-KYC documents of Primary Authorized Signatory and one Promotor/ Partner. Further the Registration shall be issued after site verification.</p>
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-primary" onClick={() => process()}>OK</button>
                            </div>
                        </div>
                    </div>
                </div>
            </Dialog>
            <Dialog open={digCConfirm.isToggle} onClose={() => setDigCConfirm({ 'type': '0', isToggle: false, data: null })}>
                <div id="confirmDlg" class="modal fade fade-scale in" role="dialog" style={{ display: 'block', paddingRight: '17px' }}>
                    <div class="modal-dialog sweet">
                        <div class="modal-content">
                            <div class="modal-body">
                                <div class="m-icon m-warning pulseWarning">
                                    <span class="micon-body pulseWarningIns"></span>
                                    <span class="micon-dot pulseWarningIns"></span>
                                </div>
                                <h2>Warning</h2>
                                <p>Are you sure, you want to continue?</p>
                            </div>
                            <div class="modal-footer">
                                <a class="btn btn-default" onClick={() => { cancel() }}>Cancel</a>
                                <a class="btn btn-primary" onClick={() => { processC() }}>Proceed</a>
                            </div>
                        </div>
                    </div>
                </div>
            </Dialog>
            <fieldset>
                <h4><span>Aadhaar Authentication</span></h4>
                <div class="tbl-format">
                    <div class="row">
                        <div class="inner">
                            <div class="col-sm-12 col-xs-12">
                                <label class="reg m-cir" for="auth">Do you want to opt for Aadhaar Authentication of details of Promoter/Partner, Primary Authorized Signatory added by you?</label>
                                <div class="switch round">
                                    <input id="auth" name="auth" type="checkbox" checked={isAdh} onChange={() => adharAuth()} />
                                    <label for="auth">
                                        <span class="switch-on" style={{ left: '4px' }}><span>Yes</span></span>
                                        <span class="switch-off" style={{ left: '35px' }}><span>No</span></span>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {
                    isAdh && <>
                        <div class="row">
                            <div class="inner">
                                <div class="col-sm-12 col-xs-12">
                                    <ol className='px-8 my-4 text-justify'>
                                        <li data-ng-bind="trans.LBL_ADHAR_HLP2">1. Authentication request shall be shared on mobile number, email upon submission of application of Promotor/Partner, and Primary Authorized Signatory which are selected.</li>
                                        <li data-ng-bind="trans.LBL_ADHAR_HLP3">2. ARN would be generated once Aadhaar Authentication exercise is completed for all applicable persons whose name are selected in this table.</li>
                                        <li data-ng-bind="trans.LBL_ADHAR_HLP5">3. Kindly select at least one person from Promotor/Partner for Aadhaar authentication.</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="inner">
                                {
                                    gstUsers.length !== 0 && <div class="col-sm-12 col-xs-12">
                                        <div class="table-responsive">
                                            <table class="table table-stripped">
                                                <thead>
                                                    <tr>
                                                        <th className='tl'>Select</th>
                                                        <th className='tl'>Sl No</th>
                                                        <th className='tl'>Name</th>
                                                        <th className='tl'>Citizen/ Resident of India</th>
                                                        <th className='tl'>Promoter/ Partner</th>
                                                        <th className='tl'>Primary Authorized Signatory</th>
                                                        <th className='tl'>Designation</th>
                                                        <th className='tl'>Email Address</th>
                                                        <th className='tl'>Mobile Number</th>
                                                        <th className='tl'>Status</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        gstUsers.map((user, index) => {
                                                            return <tr key={index}>
                                                                <td>
                                                                    <input type="checkbox" id="chkboxop" class="chkbx" name={`chkboxop${index}`} checked={user.isPrime === '1' || formData[`chkboxop${index}`] === 'true'} disabled={user.isPrime === '1'} onChange={handleOnChangeForm} />
                                                                    <label for="chkboxpauth"></label>
                                                                </td>
                                                                <td>{index + 1}</td>
                                                                <td>
                                                                    <p className='tw'>{user.pd_fnm} {user.pd_mname} {user.pd_lname}</p>
                                                                </td>
                                                                <td>Yes</td>
                                                                <td>{user.isProm === '1' ? 'Yes' : 'No'}</td>
                                                                <td>{user.isPrime === '1' ? 'Yes' : 'No'}</td>
                                                                <td>{user.dig}</td>
                                                                <td>{user.pd_email}</td>
                                                                <td>{user.pd_mobile}</td>
                                                                <td>{user.isPrime === '1' || formData[`chkboxop${index}`] === 'true' ? 'Require Authentication' : 'NA'}</td>
                                                            </tr>
                                                        })
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                }
                                {
                                    gstUsers.length === 0 && <div class="col-sm-12 col-xs-12">
                                        <div class="table-responsive">
                                            <table class="table table-stripped">
                                                <thead>
                                                    <tr>
                                                        <th className='tl'>Select</th>
                                                        <th className='tl'>Sl No</th>
                                                        <th className='tl'>Name</th>
                                                        <th className='tl'>Citizen/ Resident of India</th>
                                                        <th className='tl'>Promoter/ Partner</th>
                                                        <th className='tl'>Primary Authorized Signatory</th>
                                                        <th className='tl'>Designation</th>
                                                        <th className='tl'>Email Address</th>
                                                        <th className='tl'>Mobile Number</th>
                                                        <th className='tl'>Status</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                }
                                <div class="col-sm-12 col-xs-12">
                                    <span class="err">Note: Aadhaar authentication link will be received on e-mail IDs of the Promoters/ Partners or Authorized Signatories as mentioned in the registration application and is valid only for 15 days. However it cannot be covered in this simulation. Kindly Continue the Process.</span>
                                </div>
                            </div>
                        </div>
                    </>
                }
                {
                    !isAdh && <>
                        <div class="row">
                            <div class="inner">
                                <div class="col-sm-12 col-xs-12">
                                    <ol>
                                        <li style={{ color: 'red' }}>Application will be marked for Site visit mandatorily.</li>
                                        <li style={{ color: 'red' }}>If you wish you can upload E-KYC documents for Primary Authorized signatory and at least one Promotor/Partner to proceed further.</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="inner">
                                {
                                    gstUsers.length !== 0 && <div class="col-sm-12 col-xs-12">
                                        <div class="table-responsive">
                                            <table class="table table-stripped">
                                                <thead className='text-left'>
                                                    <tr>
                                                        <th className='tl'>Select</th>
                                                        <th className='tl'>Sl No</th>
                                                        <th className='tl tw'>Name</th>
                                                        <th className='tl'>Citizen/ Resident of India</th>
                                                        <th className='tl'>Promoter/ Partner</th>
                                                        <th className='tl'>Primary Authorized Signatory</th>
                                                        <th className='tl'>Designation</th>
                                                        <th className='tl'>Aadhaar Enrolment ID</th>
                                                        <th>
                                                            <p className='tl'>Document Upload&nbsp;
                                                                <span>
                                                                    <i class="fa fa-info-circle" data-toggle="modal" data-target="#ekycdocmdl"> </i>
                                                                </span>
                                                            </p>
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        gstUsers.map((user, index) => {
                                                            return <tr>
                                                                <td class="text-center">
                                                                    <input type="checkbox" id="chkboxop0" class="chkbx" name={`chkboxop${index}`} checked={user.isPrime === '1' || formData[`chkboxop${index}`] === 'true'} disabled={user.isPrime === '1'} onChange={handleOnChangeForm} />
                                                                    <label for="chkboxpauth"></label>
                                                                </td>
                                                                <td>{index + 1}</td>
                                                                <td>
                                                                    <p className='tw'>{user.pd_fnm} {user.pd_mname} {user.pd_lname}</p>
                                                                </td>
                                                                <td>Yes</td>
                                                                <td>{user.isProm === '1' ? 'Yes' : 'No'}</td>
                                                                <td>{user.isPrime === '1' ? 'Yes' : 'No'}</td>
                                                                <td>{user.dig}</td>
                                                                <td>
                                                                    {
                                                                        <fieldset>
                                                                            <span className={isDirty ? isValid && errors[`enrlmnidpd${index}`] !== '' ? 'has-error' : 'has-success' : ''}>
                                                                                <input value={formData['enrlmnidpd' + index]} id={`enrlmnidpd${index}`} name={`enrlmnidpd${index}`} class="number form-control" type="text" placeholder="Enter Aadhaar Enrolment ID" maxLength={14} minLength={14} onChange={handleOnChangeForm} />
                                                                            </span>
                                                                            <span className={isDirty ? isValid && errors[`enrlmnidpd${index}`] !== '' ? 'has-error' : 'has-success' : ''}>
                                                                                <input value={formData['enrlmntmstmppd' + index]} id={`enrlmntmstmppd${index}`} name={`enrlmntmstmppd${index}`} placeholder="DD/MM/YYYY HH:MM:SS" class="form-control" style={{ width: '200px', display: 'inline-block' }} maxLength={19} minLength={19} disabled={true} />
                                                                            </span>
                                                                        </fieldset>
                                                                    }
                                                                </td>
                                                                <td className='twa'>
                                                                    {
                                                                        <fieldset className='twa'>
                                                                            <div>
                                                                                {
                                                                                    docData[index === 0 ? "A" : "B"].length < 2 && <>
                                                                                        <label class="reg" for={`type${index}`}>Type of E-KYC Document</label>
                                                                                        <select class="form-control" name={`type${index}`} id={`type${index}`} onChange={handleOnChangeForm}>
                                                                                            <option value="" data-ng-bind="trans.LBL_SELECT">Select</option>
                                                                                            <option value="ADEN">Aadhaar Enrolment ID slip</option>
                                                                                            <option value="PSPT">Passport</option>
                                                                                            <option value="EPIC">EPIC (Voter ID Card)</option>
                                                                                            <option value="BPWP">Bank passbook with photograph</option>
                                                                                            <option value="DLIL">Driving license, 1988 (59 of 1988)</option>
                                                                                            <option value="AOTH">Others</option>
                                                                                            <option value="KYCF" disabled="disabled">KYC Form</option>
                                                                                            <option value="CICA" disabled="disabled">Certificate Issued by competent authority</option>
                                                                                        </select>
                                                                                        {(isDirty && isValid && errors.type !== '') && <p class="err">Please select any one document type.</p>}
                                                                                        <span class="help-block">
                                                                                            <p class="help-block">
                                                                                                <i class="fa fa-info-circle"></i>&nbsp;<span>File with PDF or JPEG format is only allowed.</span>
                                                                                            </p>
                                                                                        </span>
                                                                                        <input id={`upload_sign${index}`} name={`upload_sign${index}`} type="file" onClick={(e) => openBox(e)} />
                                                                                        {(isDirty && docData[index === 0 ? "A" : "B"].length === 0) && <p class="err">This information is required.</p>}
                                                                                    </>
                                                                                }
                                                                                {
                                                                                    docData[index === 0 ? "A" : "B"].length !== 0 && <div style={{ marginTop: '20px' }}>
                                                                                        <ul style={{ listStyle: 'none' }}>
                                                                                            {
                                                                                                docData[index === 0 ? "A" : "B"].map((d, ind) => {
                                                                                                    return <li data-ng-repeat="doc in row.adhdcupdtls">
                                                                                                        <div>
                                                                                                            <a href={d.file.fileurl} style={{ display: 'inline-block' }}>
                                                                                                                <img alt="preview" src={icPdf} />
                                                                                                                <p>{d.title}</p>
                                                                                                            </a>
                                                                                                            <button type="button" class="btn btn-sm btn-danger pull-right" style={{ verticalAlign: 'top', marginLeft: '20px' }} >
                                                                                                                <i class="fa fa-trash"></i> <span onClick={() => showCDig('1', { "t": index === 0 ? "A" : "B", "index": ind })}>Delete</span>
                                                                                                            </button>
                                                                                                        </div>
                                                                                                    </li>
                                                                                                })
                                                                                            }
                                                                                        </ul>
                                                                                    </div>
                                                                                }
                                                                            </div>
                                                                        </fieldset>
                                                                    }
                                                                </td>
                                                            </tr>
                                                        })
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </>
                }
            </fieldset>
            <div class="row">
                <div class="col-xs-12 next-tab-nav text-right">
                    <a title="Back" class="btn btn-default" onClick={() => {
                        gotoPath('sa5');
                    }} style={{ marginRight: '8px' }}>Back</a>
                    <button title="Save &amp; Continue" type="submit" class="btn btn-primary" disabled={isSubmit}>Save &amp; Continue</button>
                </div>
            </div>
        </form>
    );
}
