import React, { useState } from 'react';

import moment from 'moment';
import { Dialog } from '@headlessui/react';

import { useRecoilState } from 'recoil';
import { lmsAtom, userIDAtom, userAtom, refreshAtom } from '../core/config/atoms';

import FeatherIcon from 'feather-icons-react';
import Lottie from 'lottie-react';
import { motion, AnimatePresence } from "framer-motion";

import itaaLogo from '../assets/images/logoblue.png';
import animBtnLoading from '../assets/anime/anim-btnLoading.json';
import ServiceLMS from '../services/serviceLMS';

export default function PageLogin() {

    const [isLMS, setIsLMS] = useRecoilState(lmsAtom);
    const [userID, setUserID] = useRecoilState(userIDAtom);
    const [userAuth, setUserAuth] = useRecoilState(userAtom);
    const [isRefresh, setRefresh] = useRecoilState(refreshAtom);

    const [formData, setFormData] = useState({});

    const [isAlert, setIsAlert] = useState(false);
    const [isSubmit, setIsSubmit] = useState(false);
    const [isError, setIsError] = useState(false);

    const lmsService = new ServiceLMS();

    const alertFade = {
        hidden: { opacity: 0 },
        show: { opacity: 1 }
    }

    const handleOnChangeForm = (e) => {
        var { value, name } = e.target;
        setFormData({ ...formData, [name]: value });
        setIsError(false);
    }

    const getEndDate = (lst) => {
        let eDate = moment().subtract(1, 'day');
        for (let i = 0; i < lst.length; i++) {
            if (lst[i].title.includes("Tax Pro Professional") || lst[i].title.includes("GST PRO")) {
                let lhs = moment(lst[i].endDate, 'YYYY-MM-DD');
                if (lhs.isAfter(eDate)) {
                    eDate = lhs;
                }
            }
        }
        return eDate.toDate();
    }

    const refresh = () => {
        setRefresh(false);
        localStorage.clear();
        window.location.reload();
    }

    const handeleSubmit = (e) => {
        e.preventDefault();
        setIsSubmit(true);
        if (formData.username == 'admin@itaaedu.com' && formData.password == 'admin@2024') {
            setUserID("0");
            setUserAuth({ 'name': formData.username, 'id': "0", 'role': 'admin' });
            let body = {
                "username": formData.username,
                "password": formData.password,
                "role": "admin",
                "id": "0",
                "endDate": moment().add(20, 'days').toDate().toISOString()
            }
            lmsService.login(body).then((res) => {
                if (res.status) {
                    setIsLMS(true);
                } else {
                    e.target.reset()
                    setIsAlert(true);
                    setIsError(true);
                    setTimeout(() => {
                        setIsAlert(false);
                    }, 3000);
                }
                setIsSubmit(false);
            });
        } else {
            (async function () {
                let rToken = await window.getCaptch();
                let body = {
                    "email": formData.username,
                    "password": formData.password,
                    "reCaptchaToken": rToken
                }
                var userRes = await lmsService.lsmLogin(body);
                if (userRes.status) {
                    let edate = moment(userRes.data.endDate);
                    let currentDate = moment();
                    if (moment(edate).isAfter(currentDate)) {
                        let uid = userRes.data.userID;
                        setUserID(uid);
                        setUserAuth({ 'name': formData.username, 'id': uid, 'role': userRes.data.role });
                        let body = {
                            "username": formData.username,
                            "password": formData.password,
                            "id": uid,
                            "role": userRes.data.role,
                            "endDate": edate.toISOString()
                        }
                        lmsService.login(body).then((res) => {
                            if (res.status) {
                                setIsLMS(true);
                            } else {
                                e.target.reset()
                                setIsAlert(true);
                                setIsError(true);
                                setTimeout(() => {
                                    setIsAlert(false);
                                }, 3000);
                                setIsSubmit(false);
                            }
                        });
                    } else {
                        e.target.reset()
                        setIsAlert(true);
                        setIsError(true);
                        setTimeout(() => {
                            setIsAlert(false);
                        }, 3000);
                        setIsSubmit(false);
                    }
                } else {
                    e.target.reset()
                    setIsAlert(true);
                    setIsError(true);
                    setTimeout(() => {
                        setIsAlert(false);
                    }, 3000);
                    setIsSubmit(false);
                }
            })();
        }
    }

    return (
        <div className='w-full h-screen overflow-hidden bg-gray-50 flex items-center justify-center'>
            <Dialog open={isRefresh} onClose={() => setRefresh(false)}>
                <div id="confirmDlg" class="modal fade fade-scale in" role="dialog" style={{ display: 'block', paddingRight: '17px' }}>
                    <div class="modal-dialog sweet">
                        <div class="modal-content">
                            <div class="modal-body">
                                <div class="m-icon m-warning pulseWarning">
                                    <span class="micon-body pulseWarningIns"></span>
                                    <span class="micon-dot pulseWarningIns"></span>
                                </div>
                                <h2>Warning</h2>
                                <p className='text-center'>You have been logged out.</p>
                            </div>
                            <div class="modal-footer">
                                <a class="btn btn-default" onClick={() => { refresh() }}>OK</a>
                            </div>
                        </div>
                    </div>
                </div>
            </Dialog>
            <AnimatePresence>
                <motion.div variants={alertFade}
                    initial="hidden"
                    animate={isAlert ? "show" : "hidden"}
                    transition={{ delay: 0.1 }}
                    className="rounded text-lg font-serif mt-10 mr-8 bg-yellow-100 shadow border border-red-700 h-14 flex flex-col justify-center" style={{ position: 'absolute', right: '20px', top: '20px' }}>
                    <p className="px-4 py-2 flex text-red-600 text-2xl m-0">
                        <FeatherIcon icon="bell" size={18} />&nbsp;&nbsp;
                        Invalid credentials.
                    </p>
                </motion.div>
            </AnimatePresence>
            <div className='bg-white shadow-xl border rounded-lg w-[400px] p-6 flex flex-col items-center'>
                <img src={itaaLogo} alt="ITAA Logo" className='w-60 mt-10' />
                <h1 className='text-3xl'>Sign In</h1>
                <form className='w-full flex flex-col items-center' id="loginForm" autoComplete="off" onSubmit={handeleSubmit}>
                    <input className={`w-full bg-gray-50 h-14 outline-none border rounded px-4 py-1 mt-4 ${isError ? 'border-red-600 shadow shadow-red-100' : ''}`} type="text" name="username" required={true} placeholder="Enter username" autoComplete="off" onChange={handleOnChangeForm} value={formData.username} />
                    {isError && <p className='err w-full mt-1 text-left px-1'>Invalid username.</p>}
                    <input className={`w-full bg-gray-50 h-14 outline-none border rounded px-4 py-1 mt-6 ${isError ? 'border-red-600 shadow shadow-red-100' : ''}`} type="password" name="password" required={true} placeholder="Enter password" autoComplete="off" onChange={handleOnChangeForm} value={formData.password} />
                    {isError && <p className='err w-full mt-1 text-left px-1'>Invalid password.</p>}
                    <div className='w-full'>
                        <button type='submit' className={`mt-6 w-32 rounded bg-blue-600 px-4 py-2 text-white float-right hover:bg-blue-800 ${isSubmit ? 'flex items-center justify-center' : ''}`} disabled={isSubmit}>
                            {isSubmit && <Lottie animationData={animBtnLoading} className="w-10 h-10" loop={true} />}
                            {!isSubmit && <span>Login</span>}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    )
}

