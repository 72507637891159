import React from 'react';

import { useRecoilState } from 'recoil';
import { pathAtom } from '../core/config/atoms';

export default function CompPage2() {

    const [path, setPath] = useRecoilState(pathAtom);

    const gotoPath = (path) => {
        setPath(path);
    }

    return (
        <div class="content-wrapper">
            <div class="dimmer-holder" style={{ display: 'none' }}>
                <div id="dimmer"></div>
            </div>
            <div class="container">
                <div class="mypage">
                    <div class="row" data-ng-controller="transctrl" data-ng-init="init('services')">
                        <div class="col-xs-10">
                            <div>
                                <ol class="breadcrumb">
                                    <li>
                                        <a onClick={() => {
                                            gotoPath('home');
                                        }}>Home</a>
                                    </li>
                                    <li>
                                        <span><a>Search Taxpayer</a></span>
                                    </li>
                                    <li>
                                        <span ng-switch-when="true">Search by GSTIN/UIN</span>
                                    </li>
                                </ol>
                            </div>
                        </div>
                    </div>
                    <div class="content-pane flex flex-col" style={{ minHeight: '493.125px' }}>
                        <div data-ng-view="">
                            <div class="tabpane">
                                {/* <div class="row">
                                    <div class="col-sm-12">
                                        <p class="alert err  alert-danger ng-hide" id="alertMsg" data-ng-hide="alertMsgLogin"><span data-ng-bind="errorMsg1"></span></p>
                                    </div>
                                </div> */}
                                <div class="row">
                                    <div class="col-sm-4 col-xs-12">
                                        <h4 data-ng-bind="trans.HEAD_SEARCH_TP">Search Taxpayer</h4>
                                    </div>
                                    <p class="mand-text" data-ng-bind="trans.HLP_MAND_FIELD">indicates mandatory fields</p>
                                </div>
                                <form name="searchtaxp" role="form" data-ng-submit="searchTaxpayerpreLogin();" novalidate="" data-accessible-form="" class="ng-pristine ng-invalid ng-invalid-required ng-valid-maxlength" autocomplete="off">
                                    <div class="row">

                                        <div class="col-sm-6 col-xs-12">
                                            <p for="for_gstin" class="m-cir reg" data-ng-bind="trans.LBL_GSTIN_TAXPAYER">GSTIN/UIN of the Taxpayer</p>
                                            <div class="has-feedback" data-ng-class="{'has-error': searchtaxp.$submitted &amp;&amp; searchtaxp.for_gstin.$invalid &amp;&amp; !searchtaxp.cap.$error.invalid_captcha, 'has-success': searchtaxp.$submitted &amp;&amp; searchtaxp.for_gstin.$valid}">
                                                <input type="text" name="for_gstin" id="for_gstin" ng-keyup="event=$event;showCap1(for_gstin.num);" placeholder="Enter GSTIN/UIN of the Taxpayer" data-ng-model="for_gstin.num" class="form-control ng-pristine ng-untouched ng-empty ng-invalid ng-invalid-required ng-valid-maxlength" maxlength="15" data-ng-change="searchtaxp.for_gstin.$setValidity('pattern', true);" required="" disabled={true} />
                                            </div>
                                            {/* <!--<p class="err" data-ng-show="searchtaxp.for_gstin.$error.pattern" data-ng-bind="trans.ERR_INV_CHAR"></p> --> */}
                                            {/* <span class="err ng-hide" data-ng-show="searchtaxp.$submitted &amp;&amp; searchtaxp.for_gstin.$error.pattern || for_w_gst" data-ng-bind="trans.ERR_GSTIN">The GSTIN/UIN that you have entered is invalid. Please enter a valid GSTIN/UIN.</span>
                                            <span class="err ng-hide" data-ng-show="searchtaxp.$submitted &amp;&amp; searchtaxp.for_gstin.$error.required" data-ng-bind="trans.ERR_BLANK">Please enter a GSTIN/UIN.</span> */}
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-sm-6 col-xs-12">
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-xs-6">
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-sm-6 col-xs-12">
                                            <button type="reset" class="btn btn-primary " data-ng-bind="trans.LBL_SRCH" id="lotsearch" disabled={true}>Search</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div id="addltrd" class="modal fade fade-scale" role="dialog" data-backdrop="static" data-keyboard="false">
                                <div class="modal-dialog sweet">
                                    <div class="modal-content">
                                        <div class="modal-body">
                                            <h2 data-ng-bind="trans.LBL_TRADE_ADDTIONAL">Additional Trade Name</h2>
                                        </div>
                                        <div class="modal-footer">
                                            <button type="button" title="OK" class="btn btn-primary" data-dismiss="modal"><span data-ng-bind="trans.LBL_OK">OK</span></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='flex-grow'></div>
                        <p className='bg-yellow-100 text-2xl font-bold rounded px-4 py-2'>For further process, you may visit the actual GST portal.</p>
                    </div>
                </div>
            </div>
        </div>
    );
}
