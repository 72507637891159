import React, { useState, useEffect } from 'react';

import { useRecoilState } from 'recoil';
import { pathAtom, gstrpathAtom, returnDateAtom, loadingAtom, gstrUserAtom, gstrQueryAtom, gstrFileStatusAtom, userIDAtom } from '../../../core/config/atoms';

import Moment from 'moment';
import { Dialog, Menu } from '@headlessui/react';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import ReactPaginate from 'react-paginate';

import ServiceLMS from '../../../services/serviceLMS';
import ServiceGSTR from '../../../services/serviceGSTR';
import ServiceUtility from '../../../services/serviceUtility';

import imgLoader from '../../../assets/images/cloading.gif';

import '../css/main.css';
import './css/style.css';
import '../css/style.css';

export default function PanelB2BHome() {

    const [path, setPath] = useRecoilState(pathAtom);
    const [gstrPath, setGSTRPath] = useRecoilState(gstrpathAtom);
    const [gDate] = useRecoilState(returnDateAtom);
    const [loading, setLoading] = useRecoilState(loadingAtom);
    const [GSTUser] = useRecoilState(gstrUserAtom);
    const [query, setQuery] = useRecoilState(gstrQueryAtom);
    const [fileStatus] = useRecoilState(gstrFileStatusAtom);
    const [userID] = useRecoilState(userIDAtom);

    const [records, setRecords] = useState([]);
    const [recordsTemp, setRecordsTemp] = useState([]);
    const [recordsTempList, setRecordsTempList] = useState([]);

    const [selection, setSelection] = useState({});
    const [digConfirm, setDigConfirm] = useState({ 'type': '0', isToggle: false, data: null });

    const [querySearch, setQuerySearch] = useState('');

    const [itemPerpage, setItemPerpage] = useState(10);
    const [itemOffset, setItemOffset] = useState(0);
    const [pagecount, setPagecount] = useState(0);

    const [isSelect, setIsSelect] = useState(false);
    const [isAlert, setIsAlrt] = useState(false);

    const [cola, setColA] = useState(false);
    const [colb, setColB] = useState(false);

    const lmsService = new ServiceLMS();
    const returnService = new ServiceGSTR();
    const utilService = new ServiceUtility();

    const handleOnChangeSearch = (e) => {
        var { value, name } = e.target;
        setQuerySearch(value);
        if (selection.status === 'C') {
            showNormal(selection.gstn, selection.name, true, value);
        } else {
            showPending(selection.gstn, selection.name, true, value);
        }
    }

    const gotoPath = (path) => {
        setLoading(true);
        setPath(path);
    }

    const gotoGstrPath = (path) => {
        setGSTRPath(path);
        gotoPath('gstrFile');
    }

    const getSummeryRecords = () => {
        let rows = [];
        records.forEach((row) => {
            if (rows.length === 0) {
                let c = row.status === 'processed' ? 1 : 0;
                let p = row.status !== 'processed' ? 1 : 0;
                rows.push({ gstn: row.data.ruid, tname: row.data.name, c: c, p: p });
            } else {
                let index = rows.findIndex((e) => e.gstn === row.data.ruid);
                if (index < 0) {
                    let c = row.status === 'processed' ? 1 : 0;
                    let p = row.status !== 'processed' ? 1 : 0;
                    rows.push({ gstn: row.data.ruid, tname: row.data.name, c: c, p: p });
                } else {
                    if (row.status === 'processed') {
                        rows[index].c = rows[index].c + 1;
                    } else {
                        rows[index].p = rows[index].p + 1;
                    }
                }
            }
        });

        return rows;
    }

    const showNormal = (gstn, name, isSearch, value) => {
        setColA(false);
        setColB(false);
        if (!isSearch) {
            setQuerySearch('');
        }
        setSelection({ gstn: gstn, name: name, status: 'C' });
        setIsSelect(true);
        let rows = [];
        let currentItems = [];
        records.forEach((row) => {
            let s = 'processed';
            if (row.data.ruid === gstn && s === row.status) {
                let tax = utilService.getTotalValue(row.data.items).toFixed(2);
                let total = parseFloat(row.data.invval).toFixed(2);
                let igst = (GSTUser.state === row.data.pos ? 0 : utilService.getTotalI(row.data.items)).toFixed(2);
                let cgst = (GSTUser.state === row.data.pos ? utilService.getTotalC(row.data.items) : 0).toFixed(2);
                let sgst = (GSTUser.state === row.data.pos ? utilService.getTotalS(row.data.items) : 0).toFixed(2);
                let cess = utilService.getTotalCess(row.data.items).toFixed(2);
                let action = row.data.status;
                let data = { id: row._id, invno: row.data.inv_no, date: Moment(row.data.invdate, 'DD-MM-YYYY').toDate(), total: total, tax: tax, igst: igst, cgst: cgst, sgst: sgst, cess: cess, action: action }
                if (isSearch) {
                    if (value.length == 0) {
                        rows.push(data);
                    } else {
                        value = value.replace("/", "@");
                        if (data.date.toLocaleString().substring(0, 10).includes(value) || data.invno.includes(value) || data.total.includes(value) || data.tax.includes(value) || data.igst.includes(value) || data.cgst.includes(value) || data.cess.includes(value)) {
                            rows.push(data);
                        }
                    }
                } else {
                    rows.push(data);
                }
            }
        });
        setRecordsTempList(rows);
        if (rows.length > itemPerpage) {
            const endOffset = itemOffset + itemPerpage;
            currentItems = rows.slice(itemOffset, endOffset);
            setPagecount(Math.ceil(rows.length / itemPerpage));
        } else {
            currentItems = [...rows];
        }
        setRecordsTemp(currentItems);
    }

    const showPending = (gstn, name, isSearch, value) => {
        setColA(false);
        setColB(false);
        if (!isSearch) {
            setQuerySearch('');
        }
        setSelection({ gstn: gstn, name: name, status: 'P' });
        setIsSelect(true);
        let rows = [];
        let currentItems = [];
        records.forEach((row) => {
            let s = 'pending';
            if (row.data.ruid === gstn && s === row.status) {
                let tax = utilService.getTotalValue(row.data.items).toFixed(2);
                let total = parseFloat(row.data.invval).toFixed(2);
                let igst = (GSTUser.state === row.data.pos ? 0 : utilService.getTotalI(row.data.items)).toFixed(2);
                let cgst = (GSTUser.state === row.data.pos ? utilService.getTotalC(row.data.items) : 0).toFixed(2);
                let sgst = (GSTUser.state === row.data.pos ? utilService.getTotalS(row.data.items) : 0).toFixed(2);
                let cess = utilService.getTotalCess(row.data.items).toFixed(2);
                let action = row.data.status;
                let data = { id: row._id, invno: row.data.inv_no, date: Moment(row.data.invdate, 'DD-MM-YYYY').toDate(), total: total, tax: tax, igst: igst, cgst: cgst, sgst: sgst, cess: cess, action: action }
                if (isSearch) {
                    if (value.length == 0) {
                        rows.push(data);
                    } else {
                        if (data.invno.includes(value) || data.total.includes(value) || data.tax.includes(value) || data.igst.includes(value) || data.cgst.includes(value) || data.cess.includes(value)) {
                            rows.push(data);
                        }
                    }
                } else {
                    rows.push(data);
                }
            }
        });
        setRecordsTempList(rows);
        if (rows.length > itemPerpage) {
            const endOffset = itemOffset + itemPerpage;
            currentItems = rows.slice(itemOffset, endOffset);
            setPagecount(Math.ceil(rows.length / itemPerpage));
        } else {
            currentItems = [...rows];
        }
        setRecordsTemp(currentItems);
    }

    const updatePageination = (count) => {
        let rows = [...recordsTempList];
        let currentItems = [];
        setItemOffset(0);
        if (rows.length > count) {
            currentItems = rows.slice(0, count);
            setPagecount(Math.ceil(rows.length / count));
        } else {
            currentItems = [...rows];
            setPagecount(0);
        }
        setRecordsTemp(currentItems);
    }

    const getSpan = () => {
        let c = selection.status === 'C' ? 10 : 11;
        if (cola) {
            c = c + 1;
        }
        if (colb) {
            c = c + 1;
        }
        return c;
    }

    const showDig = (type, data) => {
        setDigConfirm({ 'type': type, isToggle: true, data: data });
    }

    const cancel = () => {
        setDigConfirm({ 'type': '0', isToggle: false, data: null });
    }

    const process = () => {
        switch (digConfirm.type) {
            case '1':
                deleteInvoice(digConfirm.data.id, digConfirm.data.status);
                break;
            default:
                break;
        }
        setDigConfirm({ 'type': '0', isToggle: false, data: null });
    }

    const deleteInvoice = (id, status) => {
        setLoading(true);
        let body = {
            lid: userID,
            type: 'b2b',
            status: status,
            month: gDate,
            id: id
        }
        returnService.deleteGstrDoc(body).then((res) => {
            setLoading(false);
            setIsSelect(false);
            setRecordsTemp([]);
            setRecordsTempList([]);
            showAlert();
            loadData();
        });
    }

    const showAlert = () => {
        setIsAlrt(true);
        setTimeout(
            () => setIsAlrt(false),
            5000
        );
    }

    const loadData = () => {
        setLoading(true);
        (async function () {
            let body = {
                lid: userID,
                month: gDate,
                type: 'b2b'
            }
            var res = await returnService.getGstrDocs(body);
            setRecords(res.data);
            setLoading(false);
            window.scrollTo({
                top: 0,
                behavior: 'smooth',
            });
        })();
    }

    const addNew = (gstn) => {
        setQuery({ type: 'add', data: gstn });
        gotoGstrPath('b2bb');
    }

    const viewInvoice = (id) => {
        setQuery({ type: 'view', data: id });
        gotoGstrPath('b2bb');
    }

    const editInvoice = (id) => {
        setQuery({ type: 'edit', data: id });
        gotoGstrPath('b2bb');
    }

    const getCount = () => {
        let c = 0;
        if (cola) {
            c = c + 1;
        }
        if (colb) {
            c = c + 1;
        }
        return c;
    }

    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemPerpage) % recordsTempList.length;
        setItemOffset(newOffset);
        const endOffset = newOffset + itemPerpage;
        const currentItems = recordsTempList.slice(newOffset, endOffset);
        setPagecount(Math.ceil(recordsTempList.length / 10));
        setRecordsTemp(currentItems);
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    useEffect(() => {
        loadData();
    }, []);

    useEffect(() => {
        if (records.length > 0) {
            if (query.type === 'view') {
                if (query.data['status']) {
                    showPending(query.data['gstn'], query.data['name'], false);
                } else {
                    showNormal(query.data['gstn'], query.data['name'], false);
                }
                setQuery({ type: '', data: '' });
            }
        }
    }, [records]);

    return (
        <div>
            <Dialog open={digConfirm.isToggle} onClose={() => setDigConfirm({ 'type': '0', isToggle: false, data: null })}>
                <div id="confirmDlg" class="modal fade fade-scale in" role="dialog" style={{ display: 'block', paddingRight: '17px' }}>
                    <div class="modal-dialog sweet">
                        <div class="modal-content">
                            <div class="modal-body">
                                <div class="m-icon m-warning pulseWarning">
                                    <span class="micon-body pulseWarningIns"></span>
                                    <span class="micon-dot pulseWarningIns"></span>
                                </div>
                                <h2>Warning</h2>
                                <p>Are you sure, you want to continue?</p>
                            </div>
                            <div class="modal-footer">
                                <a class="btn btn-default" onClick={() => { cancel() }}>Cancel</a>
                                <a class="btn btn-primary" onClick={() => { process() }}>Proceed</a>
                            </div>
                        </div>
                    </div>
                </div>
            </Dialog>
            <div class="content-wrapper fade-ng-cloak">
                <div class="dimmer-holder" style={{ display: 'none' }}>
                    <div id="dimmer"></div>
                </div>
            </div>
            {
                loading && <div className='absolute z-50 w-full h-screen flex items-center justify-center bg-gray-200 bg-opacity-25'>
                    <img src={imgLoader} alt="loader" />
                </div>
            }
            <div class="container">
                {
                    !loading && <div class="mypage">
                        <div class="row" data-ng-controller="transctrl" data-ng-init="init('returns')">
                            {isAlert && <alert-message ng-show="showMsg" title="" type="msg alert-success" message="" class="ng-hide"><div class="alert alert-msg alert-success">Request accepted successfully<a class="close" onClick={() => { setIsAlrt(false) }} data-dismiss="alert" aria-label="close">×</a><strong></strong>.</div></alert-message>}
                            <div class="col-xs-10">
                                <div name="Dashboard">
                                    <ol class="breadcrumb" data-ng-controller="crumbCtrl">
                                        <li>
                                            <a onClick={() => {
                                                gotoPath('gstr')
                                            }}>Dashboard</a></li>
                                        <li>
                                            <a onClick={() => {
                                                gotoPath('gstRetrun')
                                            }}>Returns</a>
                                        </li>
                                        <li>
                                            <a onClick={() => {
                                                gotoPath('gstrForm')
                                            }}>GSTR-1/IFF</a>
                                        </li>
                                        <li>
                                            <span ng-switch-when="true">B2B</span>
                                        </li>
                                    </ol>
                                </div>
                            </div>
                            <div class="col-xs-2">
                                <div class="lang dropdown">
                                    <span class="dropdown-toggle" data-toggle="dropdown" data-ng-bind="selectedLang">English</span>
                                    <ul class="dropdown-menu lang-dpdwn">
                                        <li data-ng-repeat="language in languages">
                                            <a data-ng-click="selectLang(language.cd)" data-ng-bind="language.nm">English</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div class="row">
                                <div class="row">
                                    <div class="col-sm-12 col-xs-12">
                                        <div class="panel panel-default ret">
                                            <div class="panel-body" style={{ padding: '5px 15px' }}>
                                                <div class="row">
                                                    <div class="col-sm-4">
                                                        <span class="reg">GSTIN - {GSTUser.gstn}</span>
                                                    </div>
                                                    <div class="col-sm-4">
                                                        <span class="reg">Legal Name - {GSTUser.buzName.toUpperCase()}</span>
                                                    </div>
                                                    <div class="col-sm-4">
                                                        <span class="reg">Trade Name - {GSTUser.tname.toUpperCase()}</span>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-sm-4">
                                                        <span class="reg">FY - 2022-23</span>
                                                    </div>
                                                    <div class="col-sm-4">
                                                        <span class="reg">Tax Period -
                                                            <span>{Moment(gDate).format("MMMM")}</span>
                                                        </span>
                                                    </div>
                                                    <div class="col-sm-4">
                                                        <span class="reg">Status - Not Filed</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row invsumm">
                                    <div class="col-xs-12 col-sm-8 ">
                                        <h4 data-ng-bind="trans.LBL_B2B_INVOICES_GSTR1">4A, 4B, 6B, 6C - B2B, SEZ, DE Invoices</h4>
                                    </div>
                                    <div class="col-sm-4 taxp">
                                        <button class="btn btn-primary btn-circle btn-sm pull-right" data-toggle="tooltip" title="Refresh" disabled={true} style={{ marginLeft: '5px' }}>
                                            <i class="fa fa-refresh" aria-hidden="true"></i>
                                        </button>
                                        <button type="button" class="btn btn-primary btn-sm pull-right" disabled={true}>
                                            <span>Help</span>&nbsp;
                                            <i class="fa fa-question-circle" aria-hidden="true"></i>
                                        </button>
                                    </div>
                                    <ul class="nav nav-tabs ret-tabs">
                                        <li className={`${isSelect ? '' : 'active'}`} onClick={() => {
                                            setIsSelect(false);
                                            setRecordsTemp([]);
                                            setRecordsTempList([]);
                                        }}>
                                            <a>Recipient wise count</a>
                                        </li>
                                        {
                                            isSelect && <>
                                                {
                                                    selection.status === 'C' && <li className={`${isSelect ? 'active' : ''}`}>
                                                        <a>Document wise details</a>
                                                    </li>
                                                }
                                                {
                                                    selection.status === 'P' && <li className={`${isSelect ? 'active' : ''}`}>
                                                        <a>Pending/Errored Record</a>
                                                    </li>
                                                }
                                            </>
                                        }
                                    </ul>
                                </div>
                                <div class="tabpane">
                                    {
                                        records.length !== 0 && !isSelect && <div class="btn-toolbar">
                                            <button type="button" class="btn btn-primary pull-left" style={{ marginLeft: '5px' }} onClick={() => {
                                                gotoGstrPath('b2bb')
                                            }} disabled={fileStatus}>Add Record</button>
                                            <a>
                                                <button type="button" class="btn btn-primary pull-right" style={{ marginLeft: '5px' }} disabled={true}>Import EWb Data</button>
                                            </a>
                                        </div>
                                    }

                                    {
                                        records.length === 0 && <alert-message title="" type="msg alert-info" message="" class="ng-hide">
                                            <div class="alert alert-msg alert-info">There are no records to be displayed<a class="close" data-dismiss="alert" aria-label="close">×</a><strong></strong>.</div>
                                        </alert-message>
                                    }

                                    {
                                        records.length !== 0 && !isSelect && <>
                                            <div class="row" data-ng-show="cnt.b2bCptSummary">
                                                <div class="col-sm-12">
                                                    <h4>Record Details</h4>
                                                </div>
                                            </div>
                                            <div class="row" data-ng-show="cnt.b2bCptSummary">
                                                <div class="col-sm-12">
                                                    <div class="table-responsive">
                                                        <table class="table tbl inv table-bordered ng-table" style={{ display: 'table' }}>
                                                            <thead ng-include="templates.header">
                                                                <tr class="ng-table-sort-header">
                                                                    <th title="">
                                                                        <div class="ng-table-header">
                                                                            <span class="sort-indicator">Recipient Details </span>
                                                                        </div>
                                                                    </th>
                                                                    <th title="" class="header ">
                                                                        <div class="ng-table-header">
                                                                            <span class="sort-indicator">Trade/Legal Name</span>
                                                                        </div>
                                                                    </th>
                                                                    <th title="" class="header "> <div class="ng-table-header">
                                                                        <span class="sort-indicator">Taxpayer Type</span>
                                                                    </div>
                                                                    </th>
                                                                    <th title="" class="header sortable">
                                                                        <div class="ng-table-header">
                                                                            <span class="sort-indicator">Processed Records</span>
                                                                        </div>
                                                                    </th>
                                                                    <th title="" class="header sortable">
                                                                        <div class="ng-table-header">
                                                                            <span class="sort-indicator">Pending/Errored Invoices</span>
                                                                        </div>
                                                                    </th>
                                                                    <th title="" class="header">
                                                                        <div class="ng-table-header">
                                                                            <span class="sort-indicator">Add Invoice</span>
                                                                        </div>
                                                                    </th>
                                                                </tr>
                                                            </thead>
                                                            <tbody data-ng-repeat="cpty in $data">
                                                                {
                                                                    getSummeryRecords().map((row) => {
                                                                        return <tr>
                                                                            <td class="text-center">{row.gstn}</td>
                                                                            <td class="text-center" >{row.tname}</td>
                                                                            <td class="text-center"><div>Regular taxpayer</div></td>
                                                                            <td class="text-center">
                                                                                <a className={`${row.c === 0 ? 'alink' : ''}`} onClick={() => {
                                                                                    showNormal(row.gstn, row.tname, false);
                                                                                }}>{row.c}</a>
                                                                            </td>
                                                                            <td class="text-center">
                                                                                <a className={`${row.p === 0 ? 'alink' : ''}`} onClick={() => {
                                                                                    showPending(row.gstn, row.tname, false);
                                                                                }}>{row.p}</a>
                                                                            </td>
                                                                            <td class="text-center">
                                                                                <button style={{ backgroundColor: 'green', color: 'white' }} onClick={() => {
                                                                                    addNew(row.gstn);
                                                                                }} disabled={fileStatus}>
                                                                                    <i class="fa fa-plus-circle"></i>
                                                                                </button>
                                                                            </td>
                                                                        </tr>
                                                                    })
                                                                }
                                                            </tbody>
                                                        </table>
                                                        <div ng-table-pagination="params" template-url="templates.pagination">
                                                            <div ng-include="templateUrl">
                                                                <div class="ng-table-pager" ng-if="params.data.length"></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    }

                                    {
                                        isSelect && selection.status === 'C' && <div class="row" data-ng-if="b2binvdt.processedInvoice">
                                            <div class="col-sm-12">
                                                <h4 data-ng-bind="trans.LBL_PRO_RCD">Processed Records</h4>
                                            </div>
                                            <br />

                                            <div class="col-xs-12 col-sm-12">
                                                <div class="row">
                                                    <div class="col-sm-12" id="pill">
                                                        <div className='flex'>
                                                            <div className="bg-gray-300 rounded-full px-4 mr-4 text-black">
                                                                <span data-ng-bind="obj.recCtin">{selection.gstn}</span>
                                                            </div>
                                                            <div className="bg-gray-300 rounded-full px-4 text-black">
                                                                <span data-ng-bind="obj.tradeName">{selection.name}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-xs-12">
                                                        <Tippy content="Only numbers(0-9), characters(A-Z), space and special characters(/ ' - .) are allowed." allowHTML={true} placement='top' className='text-center'>
                                                            <div class="col-lg-2 pull-right">
                                                                <div class="form-group pull-right" style={{ display: 'inline-flex' }}>
                                                                    <input class="form-control pull-right" type="text" style={{ width: '93%', border: '1px solid', fontFamily: 'FontAwesome' }} name="searchprs" placeholder="&nbsp;Search..." onChange={handleOnChangeSearch} value={querySearch} />
                                                                </div>
                                                            </div>
                                                        </Tippy>
                                                        <div className="col-lg-3 pull-right">
                                                            <div className="form-group pull-right" style={{ display: 'inline-flex' }}>
                                                                <div className="col-lg-9"><label class="form-label">Records Per Page : </label></div>
                                                                <div className="col-lg-3" style={{ paddingLeft: '0', marginLeft: '-0.7em' }}>
                                                                    <select className="form-control" style={{ minWidth: '5em', border: '1px solid' }} value={itemPerpage} onChange={(e) => {
                                                                        setItemPerpage(parseInt(e.target.value));
                                                                        updatePageination(parseInt(e.target.value));
                                                                    }}>
                                                                        <option label="10" value="10" selected={itemPerpage == 10}>10</option>
                                                                        <option label="20" value="20" selected={itemPerpage == 20}>20</option>
                                                                        <option label="50" value="50" selected={itemPerpage == 50}>50</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-4 pull-right">
                                                            <div className="form-group pull-right" style={{ display: 'inline-flex' }}>
                                                                <div className="col-lg-9" style={{ marginTop: '7px', fontWeight: 'bold' }}>
                                                                    <span className="pull-right">Display/Hide Columns:</span>
                                                                </div>
                                                                <div class="col-lg-3" style={{ paddingLeft: '0' }}>
                                                                    <div>
                                                                        <div className="multiselect-parent btn-group dropdown-multiselect">
                                                                            <Menu>
                                                                                <Menu.Button style={{ border: '0', backgroundColor: 'white' }}>
                                                                                    <toggle-dropdown>
                                                                                        <span class="btn btn-default custom-trigger" style={{ margin: '0px 0px 20px 0px!important' }}>
                                                                                            <span class="caret"></span>
                                                                                        </span>
                                                                                    </toggle-dropdown>
                                                                                </Menu.Button>
                                                                                <Menu.Items>
                                                                                    <ul className="dropdown-menu dropdown-menu-form" style={{ display: 'block', height: '200px', overflow: 'auto' }}>
                                                                                        <li>
                                                                                            <a tabindex="-1" id="selectAll" onClick={() => {
                                                                                                setColA(true);
                                                                                                setColB(true);
                                                                                            }}>
                                                                                                <span class="glyphicon glyphicon-ok"></span> Check All</a>
                                                                                        </li>
                                                                                        <li>
                                                                                            <a tabindex="-1" id="deselectAll" onClick={() => {
                                                                                                setColA(false);
                                                                                                setColB(false);
                                                                                            }}>
                                                                                                <span class="glyphicon glyphicon-remove"></span> Uncheck All
                                                                                            </a>
                                                                                        </li>
                                                                                        <li class="divider"></li>
                                                                                        <li role="presentation">
                                                                                            <a class="option" tabindex="-1">
                                                                                                <div class="checkbox">
                                                                                                    <label>
                                                                                                        <input class="checkboxInput" type="checkbox" onClick={() => { setColA(!cola) }} checked={cola} />
                                                                                                        <span>
                                                                                                            <span>IRN</span>
                                                                                                        </span>
                                                                                                    </label>
                                                                                                </div>
                                                                                            </a>
                                                                                        </li>
                                                                                        <li role="presentation">
                                                                                            <a class="option" tabindex="-1">
                                                                                                <div class="checkbox">
                                                                                                    <label>
                                                                                                        <input class="checkboxInput" type="checkbox" onClick={() => { setColB(!colb) }} checked={colb} />
                                                                                                        <span>
                                                                                                            <span>IRN Date</span>
                                                                                                        </span>
                                                                                                    </label>
                                                                                                </div>
                                                                                            </a>
                                                                                        </li>
                                                                                        <li class="divider ng-hide" ng-show="settings.selectionLimit > 1"></li>
                                                                                        <li role="presentation" ng-show="settings.selectionLimit > 1" class="ng-hide">
                                                                                            <a role="menuitem">{getCount()} / 2 checked</a>
                                                                                        </li>
                                                                                    </ul>
                                                                                </Menu.Items>
                                                                            </Menu>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="table-responsive">
                                                    <table class="table exp inv tbl table-bordered ng-table" style={{ display: 'table' }}>
                                                        <thead ng-include="templates.header">
                                                            <tr class="ng-table-sort-header">
                                                                <th title="" ng-repeat="$column in $columns" class="header sortable">
                                                                    <div class="ng-table-header">
                                                                        <span class="sort-indicator">Invoice no.</span>
                                                                    </div>
                                                                </th>
                                                                <th title="" class="header ">
                                                                    <div>
                                                                        <span class="sort-indicator">Invoice date</span>
                                                                    </div>
                                                                </th>
                                                                <th title="" class="header ">
                                                                    <div>
                                                                        <span class="sort-indicator">Total invoice value (₹)</span>
                                                                    </div>
                                                                </th>
                                                                <th title="" class="header ">
                                                                    <div>
                                                                        <span class="sort-indicator">Total taxable value (₹)</span>
                                                                    </div>
                                                                </th>
                                                                <th title="" class="header ">
                                                                    <div>
                                                                        <span class="sort-indicator">Integrated Tax (₹)</span>
                                                                    </div>
                                                                </th>
                                                                <th title="" class="header ">
                                                                    <div>
                                                                        <span class="sort-indicator">Central tax (₹)</span>
                                                                    </div>
                                                                </th>
                                                                <th title="" class="header ">
                                                                    <div>
                                                                        <span class="sort-indicator">State/UT Tax (₹)</span>
                                                                    </div>
                                                                </th>
                                                                <th title="" class="header ">
                                                                    <div>
                                                                        <span class="sort-indicator">Cess (₹)</span>
                                                                    </div>
                                                                </th>
                                                                <th title="" class="header ">
                                                                    <div>
                                                                        <span class="sort-indicator">Source</span>
                                                                    </div>
                                                                </th>
                                                                {
                                                                    cola && <th title="" class="header ">
                                                                        <div>
                                                                            <span class="sort-indicator">IRN</span>
                                                                        </div>
                                                                    </th>
                                                                }
                                                                {
                                                                    colb && <th title="" class="header ">
                                                                        <div>
                                                                            <span class="sort-indicator">IRN Date</span>
                                                                        </div>
                                                                    </th>
                                                                }
                                                                <th title="" class="header ">
                                                                    <div>
                                                                        <span class="sort-indicator">Actions</span>
                                                                    </div>
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody data-ng-repeat="t in $data">
                                                            {
                                                                recordsTemp.map((row) => {
                                                                    return <tr>
                                                                        <td class="text-center">
                                                                            <a title="Click on the Invoice Number to view record" data-toggle="tooltip" onClick={() => {
                                                                                viewInvoice(row.id);
                                                                            }}>
                                                                                {row.invno}
                                                                            </a>
                                                                        </td>
                                                                        <td class="text-center w-10">
                                                                            {Moment(row.date).format("DD/MM/YYYY")}
                                                                        </td>
                                                                        <td class="currency">{row.total}</td>
                                                                        <td class="currency">{row.tax}</td>
                                                                        <td class="currency">{row.igst}</td>
                                                                        <td class="currency">{row.cgst}</td>
                                                                        <td class="currency">{row.sgst}</td>
                                                                        <td class="currency">{row.cess}</td>
                                                                        {cola && <td class="currency"></td>}
                                                                        {colb && <td class="currency"></td>}
                                                                        <td class="text-center"></td>
                                                                        <td style={{ display: 'inline-block' }} class="text-center newinv" data-title-text="Actions">
                                                                            <div className='flex'>
                                                                                <button type="button" class="btn btn-sm btn-primary nomargin" onClick={() => {
                                                                                    editInvoice(row.id);
                                                                                }} disabled={fileStatus}><i class="fa fa-pencil" aria-hidden="true"></i><span class="sr-only">text</span>
                                                                                </button>&nbsp;
                                                                                <button type="button" class="btn btn-sm btn-danger nomargin" title="Delete" onClick={() => {
                                                                                    showDig('1', { id: row.id, status: 'processed' });
                                                                                }} disabled={fileStatus}>
                                                                                    <i class="fa fa-trash"></i><span class="sr-only">text</span>
                                                                                </button>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                })
                                                            }
                                                            {
                                                                recordsTemp.length == 0 && <tr>
                                                                    <td class="text-center" colSpan={getSpan()}>No records found.</td>
                                                                </tr>
                                                            }
                                                        </tbody>
                                                    </table>
                                                    <ReactPaginate
                                                        breakLabel="..."
                                                        nextLabel=">"
                                                        onPageChange={handlePageClick}
                                                        pageRangeDisplayed={5}
                                                        pageCount={pagecount}
                                                        previousLabel="<"
                                                        renderOnZeroPageCount={null}
                                                        className="flex mt-4 list-none"
                                                        pageLinkClassName="mx-2"
                                                    />
                                                    <div ng-table-pagination="params" template-url="templates.pagination">
                                                        <div ng-include="templateUrl">
                                                            <div class="ng-table-pager" ng-if="params.data.length">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }

                                    {
                                        isSelect && selection.status === 'P' && <div class="row" data-ng-if="b2binvdt.processedInvoice">
                                            <div class="col-sm-12">
                                                <h4 data-ng-bind="trans.LBL_PRO_RCD">Pending records (These will be added after validation)</h4>
                                            </div>
                                            <br />

                                            <div class="col-xs-12 col-sm-12">
                                                <div class="row">
                                                    <div class="col-sm-12" id="pill">
                                                        <div className='flex'>
                                                            <div className="bg-gray-300 rounded-full px-4 mr-4 text-black">
                                                                <span data-ng-bind="obj.recCtin">{selection.gstn}</span>
                                                            </div>
                                                            <div className="bg-gray-300 rounded-full px-4 text-black">
                                                                <span data-ng-bind="obj.tradeName">{selection.name}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-xs-12">
                                                        <Tippy content="Only numbers(0-9), characters(A-Z), space and special characters(/ ' - .) are allowed." allowHTML={true} placement='top' className='text-center'>
                                                            <div class="col-lg-2 pull-right">
                                                                <div class="form-group pull-right" style={{ display: 'inline-flex' }}>
                                                                    <input class="form-control pull-right" type="text" style={{ width: '93%', border: '1px solid', fontFamily: 'FontAwesome' }} name="searchprs" placeholder="&nbsp;Search..." onChange={handleOnChangeSearch} value={querySearch} />
                                                                </div>
                                                            </div>
                                                        </Tippy>
                                                        <div class="col-lg-3 pull-right">
                                                            <div class="form-group pull-right" style={{ display: 'inline-flex' }}>
                                                                <div class="col-lg-9"><label class="form-label">Records Per Page : </label></div>
                                                                <div class="col-lg-3" style={{ paddingLeft: '0', marginLeft: '-0.7em' }}>
                                                                    <select class="form-control" style={{ minWidth: '5em', border: '1px solid' }} value="item">
                                                                        <option label="10" value="number:10" selected="selected">10</option>
                                                                        <option label="20" value="number:20">20</option>
                                                                        <option label="50" value="number:50">50</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div data-ng-if="ENABLE_EINVOICE" class="col-lg-4 pull-right">
                                                            <div class="form-group pull-right" style={{ display: 'inline-flex' }}>
                                                                <div class="col-lg-9" style={{ marginTop: '7px', fontWeight: 'bold' }}>
                                                                    <span class="pull-right" data-ng-bind="trans.LBL_DISPLAY_HIDE">Display/Hide Columns:</span>
                                                                </div>
                                                                <div class="col-lg-3" style={{ paddingLeft: '0' }}>
                                                                    <div>
                                                                        <div class="multiselect-parent btn-group dropdown-multiselect">
                                                                            <Menu>
                                                                                <Menu.Button style={{ border: '0', backgroundColor: 'white' }}>
                                                                                    <toggle-dropdown>
                                                                                        <span class="btn btn-default custom-trigger" style={{ margin: '0px 0px 20px 0px!important' }}>
                                                                                            <span class="caret"></span>
                                                                                        </span>
                                                                                    </toggle-dropdown>
                                                                                </Menu.Button>
                                                                                <Menu.Items>
                                                                                    <ul className="dropdown-menu dropdown-menu-form" style={{ display: 'block', height: '200px', overflow: 'auto' }}>
                                                                                        <li>
                                                                                            <a tabindex="-1" id="selectAll" onClick={() => {
                                                                                                setColA(true);
                                                                                                setColB(true);
                                                                                            }}>
                                                                                                <span class="glyphicon glyphicon-ok"></span> Check All</a>
                                                                                        </li>
                                                                                        <li>
                                                                                            <a tabindex="-1" id="deselectAll" onClick={() => {
                                                                                                setColA(false);
                                                                                                setColB(false);
                                                                                            }}>
                                                                                                <span class="glyphicon glyphicon-remove"></span> Uncheck All
                                                                                            </a>
                                                                                        </li>
                                                                                        <li class="divider"></li>
                                                                                        <li role="presentation">
                                                                                            <a class="option" tabindex="-1">
                                                                                                <div class="checkbox">
                                                                                                    <label>
                                                                                                        <input class="checkboxInput" type="checkbox" onClick={() => { setColA(!cola) }} checked={cola} />
                                                                                                        <span>
                                                                                                            <span>IRN</span>
                                                                                                        </span>
                                                                                                    </label>
                                                                                                </div>
                                                                                            </a>
                                                                                        </li>
                                                                                        <li role="presentation">
                                                                                            <a class="option" tabindex="-1">
                                                                                                <div class="checkbox">
                                                                                                    <label>
                                                                                                        <input class="checkboxInput" type="checkbox" onClick={() => { setColB(!colb) }} checked={colb} />
                                                                                                        <span>
                                                                                                            <span>IRN Date</span>
                                                                                                        </span>
                                                                                                    </label>
                                                                                                </div>
                                                                                            </a>
                                                                                        </li>
                                                                                        <li class="divider ng-hide" ng-show="settings.selectionLimit > 1"></li>
                                                                                        <li role="presentation" ng-show="settings.selectionLimit > 1" class="ng-hide">
                                                                                            <a role="menuitem">{getCount()} / 2 checked</a>
                                                                                        </li>
                                                                                    </ul>
                                                                                </Menu.Items>
                                                                            </Menu>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="table-responsive">
                                                    <table class="table exp inv tbl table-bordered ng-table" style={{ display: 'table' }}>
                                                        <thead ng-include="templates.header">
                                                            <tr class="ng-table-sort-header">
                                                                <th title="" ng-repeat="$column in $columns" class="header sortable">
                                                                    <div class="ng-table-header">
                                                                        <span class="sort-indicator">Invoice no.</span>
                                                                    </div>
                                                                </th>
                                                                <th title="" class="header ">
                                                                    <div>
                                                                        <span class="sort-indicator">Invoice date</span>
                                                                    </div>
                                                                </th>
                                                                <th title="" class="header ">
                                                                    <div>
                                                                        <span class="sort-indicator">Total invoice value (₹)</span>
                                                                    </div>
                                                                </th>
                                                                <th title="" class="header ">
                                                                    <div>
                                                                        <span class="sort-indicator">Total taxable value (₹)</span>
                                                                    </div>
                                                                </th>
                                                                <th title="" class="header ">
                                                                    <div>
                                                                        <span class="sort-indicator">Integrated Tax (₹)</span>
                                                                    </div>
                                                                </th>
                                                                <th title="" class="header ">
                                                                    <div>
                                                                        <span class="sort-indicator">Central tax (₹)</span>
                                                                    </div>
                                                                </th>
                                                                <th title="" class="header ">
                                                                    <div>
                                                                        <span class="sort-indicator">State/UT Tax (₹)</span>
                                                                    </div>
                                                                </th>
                                                                <th title="" class="header ">
                                                                    <div>
                                                                        <span class="sort-indicator">Cess (₹)</span>
                                                                    </div>
                                                                </th>
                                                                <th title="" class="header ">
                                                                    <div>
                                                                        <span class="sort-indicator">Action Taken</span>
                                                                    </div>
                                                                </th>
                                                                {
                                                                    cola && <th title="" class="header ">
                                                                        <div>
                                                                            <span class="sort-indicator">IRN</span>
                                                                        </div>
                                                                    </th>
                                                                }
                                                                {
                                                                    colb && <th title="" class="header ">
                                                                        <div>
                                                                            <span class="sort-indicator">IRN Date</span>
                                                                        </div>
                                                                    </th>
                                                                }
                                                                <th title="" class="header ">
                                                                    <div>
                                                                        <span class="sort-indicator">Status</span>
                                                                    </div>
                                                                </th>
                                                                <th title="" class="header ">
                                                                    <div>
                                                                        <span class="sort-indicator">Actions</span>
                                                                    </div>
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody data-ng-repeat="t in $data">
                                                            {
                                                                recordsTemp.map((row) => {
                                                                    return <tr>
                                                                        <td class="text-center">
                                                                            <a title="Click on the Invoice Number to view record" data-toggle="tooltip" onClick={() => {
                                                                                editInvoice(row.id);
                                                                            }}>
                                                                                {row.invno}
                                                                            </a>
                                                                        </td>
                                                                        <td class="text-center w-10">
                                                                            {Moment(row.date).format("DD/MM/YYYY")}
                                                                        </td>
                                                                        <td class="currency">{row.total}</td>
                                                                        <td class="currency">{row.tax}</td>
                                                                        <td class="currency">{row.igst}</td>
                                                                        <td class="currency">{row.cgst}</td>
                                                                        <td class="currency">{row.sgst}</td>
                                                                        <td class="currency">{row.cess}</td>
                                                                        <td class="text-center">{row.action}</td>
                                                                        {cola && <td class="currency"></td>}
                                                                        {colb && <td class="currency"></td>}
                                                                        <td class="text-center">Processed with error</td>
                                                                        <td style={{ display: 'inline-block' }} class="text-center newinv" data-title-text="Actions">
                                                                            <div className='flex'>
                                                                                <button type="button" class="btn btn-sm btn-primary nomargin" onClick={() => {
                                                                                    editInvoice(row.id);
                                                                                }} disabled={fileStatus}><i class="fa fa-pencil" aria-hidden="true"></i><span class="sr-only">text</span>
                                                                                </button>&nbsp;
                                                                                <button type="button" class="btn btn-sm btn-danger nomargin" title="Delete" onClick={() => {
                                                                                    showDig('1', { id: row.id, status: 'pending' });
                                                                                }} disabled={fileStatus}>
                                                                                    <i class="fa fa-trash"></i><span class="sr-only">text</span>
                                                                                </button>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                })
                                                            }
                                                            {
                                                                recordsTemp.length == 0 && <tr>
                                                                    <td class="text-center" colSpan={getSpan()}>No records found.</td>
                                                                </tr>
                                                            }
                                                        </tbody>
                                                    </table>
                                                    <ReactPaginate
                                                        breakLabel="..."
                                                        nextLabel=">"
                                                        onPageChange={handlePageClick}
                                                        pageRangeDisplayed={5}
                                                        pageCount={pagecount}
                                                        previousLabel="<"
                                                        renderOnZeroPageCount={null}
                                                        className="flex mt-4 list-none"
                                                        pageLinkClassName="mx-2"
                                                    />
                                                    <div ng-table-pagination="params" template-url="templates.pagination">
                                                        <div ng-include="templateUrl">
                                                            <div class="ng-table-pager" ng-if="params.data.length">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }

                                    <div class="btn-toolbar">
                                        {
                                            records.length === 0 && <div data-ng-hide="cnt.b2bCptSummary" class="ng-hide">
                                                <a>
                                                    <button type="button" class="btn btn-primary pull-right" style={{ marginLeft: '5px' }} title="To import EWB data into B2B Section" disabled={true}>Import EWb Data</button>
                                                </a>
                                                <button type="button" class="btn btn-primary pull-right" style={{ marginLeft: '5px' }} onClick={() => {
                                                    gotoGstrPath('b2bb')
                                                }} disabled={fileStatus}>Add Record</button>
                                            </div>
                                        }
                                        <button type="button" class="btn btn-default pull-right" onClick={() => {
                                            gotoPath('gstrForm')
                                        }}>Back</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </div >
    );
}
