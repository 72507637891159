import React, { useState, useEffect, useRef } from 'react';

import { useRecoilState } from 'recoil';
import { pathAtom, loadingAtom, trnLevelAtom, trnAtom, digErrorAtom, dirtyAtom, redirectAtom, docAtom, digStorageAtom, userIDAtom, userAtom } from '../core/config/atoms';

import ServiceAuth from '../services/serviceAuth';
import ServiceUtility from '../services/serviceUtility';
import ServiceOption from '../services/serviceOptions';

import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Dialog } from '@headlessui/react';

import icPdf from '../assets/images/misc/pdf.png';

export default function ComponentTRNLevelE() {

    const [path, setPath] = useRecoilState(pathAtom);
    const [loading, setLoading] = useRecoilState(loadingAtom);
    const [trnLevel, SetTRNLevel] = useRecoilState(trnLevelAtom);
    const [trnno] = useRecoilState(trnAtom);
    const [digError, setDigError] = useRecoilState(digErrorAtom);
    const [isDirty, setIsDirty] = useRecoilState(dirtyAtom);
    const [digs, setDigS] = useRecoilState(digStorageAtom);
    const [redirect, setRedirect] = useRecoilState(redirectAtom);
    const [doc, setDoc] = useRecoilState(docAtom);
    const [userID] = useRecoilState(userIDAtom);
    const [userAuth] = useRecoilState(userAtom);

    const ref = useRef();

    const [docs, setDocs] = useState([]);
    const [boxs, setBoxs] = useState([]);
    const [markers, setMarkers] = useState([]);

    const [map, setMap] = useState();

    const [gstUser, setGSTUser] = useState({});
    const [digConfirm, setDigConfirm] = useState({ 'type': '0', isToggle: false, data: null });

    const [isSubmit, setIsSubmit] = useState(false);
    const [isPincode, setIsPincode] = useState(false);
    const [fileError, setFileError] = useState(false);
    const [isP, setIsP] = useState(false);
    const [isS, setIsS] = useState(false);
    const [isValid, setIsValid] = useState(false);
    const [errorDig, setErrorDig] = useState(false);
    const [errorDigB, setErrorDigB] = useState(false);

    const authService = new ServiceAuth();
    const utilService = new ServiceUtility();
    const optionService = new ServiceOption();

    const formSchema = Yup.object().shape({
        pncd: Yup.string().required('PIN Code is required.').matches(/^[0-9]{6}$/, { message: 'Please enter a valid pincode' }),
        dst: Yup.string().required('District is required.'),
        loc: Yup.string().required('City / Town / Village is required.'),
        st: Yup.string().required('Road / Street / Lane is required.'),
        bno: Yup.string().required('Building No. / Flat No. is required.'),
        stateJudi: Yup.string().required('State jurisdiction is required.'),
        commJudi: Yup.string().required('Commissionerate is required.'),
        divJudi: Yup.string().required('Division is required.'),
        rangeJudi: Yup.string().required('Range is required.'),
        bp_mobile: Yup.string().required('Mobile Number is required.').matches(/^[0-9]{10}$/, { message: 'Please enter a valid mobile number' }),
        bp_email: Yup.string().required('Email Address is required.').matches(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, { message: 'Please enter a valid email address' }),
        bp_buss_poss: Yup.string().required('This information is required.'),
        bp_up_type: Yup.string().required('This information is required.'),
    });

    const { values, errors, touched, dirty, handleChange, handleSubmit, handleReset, resetForm, setValues } = useFormik({
        enableReinitialize: true,
        initialValues: {
            pncd: '',
            dst: '',
            loc: '',
            ppbzdtls_locality: '',
            st: '',
            bp_bdname: '',
            bno: '',
            bp_flrnum: '',
            ppbzdtls_landmark: '',
            bp_latitude: '',
            bp_longitude: '',
            stateJudi: '',
            commJudi: '',
            divJudi: '',
            rangeJudi: '',
            bp_email: '',
            bp_mobile: '',
            tlphnoStd: '',
            tlphno: '',
            fxnostd: '',
            fxno: '',
            bp_buss_poss: '',
            bp_up_type: '',
            othBuss: '',
        },
        validationSchema: formSchema,
        onSubmit: values => {
            if (boxs.length === 0) {
                setErrorDigB(true);
            } else {
                if (docs.length != 0) {
                    if (boxs.includes('OTH') && values.othBuss === "") {
                        setIsValid(true);
                    } else {
                        setIsSubmit(true);
                        setIsValid(false);
                        setLoading(true);

                        let isDoc = false;
                        docs.forEach((d) => {
                            if (d.type != 'storage') {
                                isDoc = true;
                            }
                        });

                        let mPromis = [];

                        let formData = { ...values };

                        if (!isDoc) {
                            let data = [...trnLevel.form];
                            data[4] = "1";

                            formData['boxs'] = boxs;
                            formData['docs'] = docs;

                            let body = {
                                plant: [formData],
                                trnno: trnno,
                                lid: userID,
                                statusArr: data,
                                user: userAuth,
                            }

                            authService.setGSTUserFormE(body).then((res) => {
                                if (res.statuscode === 200) {
                                    setLoading(false);
                                    setIsDirty(false);
                                    setDoc({ isFile: false, type: '', doc: null });
                                    SetTRNLevel({ ...trnLevel, level: 'sa6' });
                                } else {
                                    setDigError({ title: 'Error', msg: 'Server error please try again!', isToggle: true });
                                }
                            });
                        } else {
                            docs.forEach((d) => {
                                if (d.type != 'storage') {
                                    var formDataA = new FormData();
                                    formDataA.append("doc", d.doc);
                                    formDataA.append("id", userID);
                                    mPromis.push(authService.setDocument(formDataA, ''));
                                }
                            });

                            Promise.all(mPromis).then((r) => {
                                let mDocs = [];
                                let count = 0;

                                docs.forEach((ds) => {
                                    if (ds.type != 'storage') {
                                        let ddata = r[count].data.data;
                                        let f = {
                                            filename: ddata.filename,
                                            fileurl: ddata.fileurl,
                                            filetype: 'doc',
                                        }
                                        count++;
                                        ds.file = f;
                                        mDocs.push(ds);
                                    } else {
                                        mDocs.push(ds);
                                    }
                                });

                                let data = [...trnLevel.form];
                                data[4] = "1";

                                formData['boxs'] = boxs;
                                formData['docs'] = mDocs;

                                let body = {
                                    plant: [formData],
                                    trnno: trnno,
                                    lid: userID,
                                    statusArr: data,
                                    user: userAuth,
                                }

                                authService.setGSTUserFormE(body).then((res) => {
                                    if (res.statuscode === 200) {
                                        setLoading(false);
                                        setIsDirty(false);
                                        setDoc({ isFile: false, type: '', doc: null });
                                        SetTRNLevel({ ...trnLevel, level: 'sa6' });
                                    } else {
                                        setDigError({ title: 'Error', msg: 'Server error please try again!', isToggle: true });
                                    }
                                });
                            });
                        }

                    }
                } else {
                    setIsValid(true);
                }
            }
        }
    });

    const gotoPath = (path) => {
        SetTRNLevel({ ...trnLevel, level: path });
    }

    const handleOnCheckBox = (data) => {
        let tempB = [...boxs];
        if (data === 'OTH') {
            setValues({ ...values, 'othBuss': '' });
        }
        if (tempB.includes(data)) {
            let index = tempB.indexOf(data);
            tempB.splice(index, 1);
        } else {
            tempB.push(data);
        }
        setBoxs(tempB);
    }

    const resetAddress = () => {
        setIsPincode(false);
        let tempMarks = [...markers];
        for (let i = 0; i < tempMarks.length; i++) {
            tempMarks[i].setMap(null);
        }
        setMarkers(tempMarks);
        setValues({ ...values, 'pncd': '', 'dst': '', 'loc': '', 'ppbzdtls_locality': '', 'st': '', 'bp_bdname': '', 'bno': '', 'bp_flrnum': '', 'ppbzdtls_landmark': '', 'bp_latitude': '', 'bp_longitude': '' });
    }

    const gotoURL = (url) => {
        setRedirect({ url: url, isToggle: true });
    }

    const getByTitle = (title) => {
        switch (title) {
            case 'ELCB':
                return 'Electricity Bill';
            case 'LOWN':
                return 'Legal ownership document';
            case 'CMUK':
                return 'Municipal Khata Copy';
            case 'PAXR':
                return 'Property Tax Receipt';
            case 'CNLR':
                return 'Consent Letter';
            case 'TAXR':
                return 'Rent / Lease agreement';
            case 'TAXC':
                return 'Rent receipt with NOC (In case of no/expired agreement)';
            default:
                return '';
        }
    }

    const cancel = () => {
        setDigConfirm({ 'type': '0', isToggle: false });
    }

    const showDig = (type, data) => {
        setDigConfirm({ 'type': type, isToggle: true, data: data });
    }

    const process = () => {
        switch (digConfirm.type) {
            case '1':
                removeDoc(digConfirm.data);
                break;
            default:
                break;

        }
        setDigConfirm({ 'type': '0', isToggle: false, data: null });
    }

    const removeDoc = (data) => {
        if (docs.length == 1) {
            setDocs([]);
        } else {
            let d = docs.splice(data, 1);
            setDocs(d);
        }
    }

    const openBox = (e) => {
        e.preventDefault();
        if (isP && isS) {
            setFileError(false);
            setDoc({ isFile: false, type: '', doc: [] });
            setDigS({ title: '', msg: '', isToggle: true });
        } else {
            setFileError(true);
        }
    }

    const handelePincode = (e) => {
        let value = e.target.value;
        handleChange(e);
        if (value.length == 6) {
            optionService.getLocation({ 'Pincode': value }).then((res) => {
                setIsPincode(true);
                const geocoder = new window.google.maps.Geocoder();
                var address = res.data['RegionName'].replace(' Region', '').toUpperCase() + " ," + res.data['StateName'] + " " + value;
                geocoder.geocode({ 'address': address }, function (results, status) {
                    if (status == 'OK') {
                        map.setCenter(results[0].geometry.location);
                        let tempMarks = [...markers];
                        for (let i = 0; i < tempMarks.length; i++) {
                            tempMarks[i].setMap(null);
                        }
                        tempMarks.push(
                            new window.google.maps.Marker({
                                map: map,
                                position: results[0].geometry.location
                            })
                        );
                        setMarkers(tempMarks);
                    } else {
                        alert('Geocode was not successful for the following reason: ' + status);
                    }
                });
                setValues({ ...values, 'dst': res.data['District'], 'bp_latitude': res.data['Latitude'], 'loc': res.data['RegionName'].replace(' Region', '').toUpperCase(), 'bp_longitude': res.data['Longitude'], 'pncd': value });
            });
        } else {
            setIsPincode(false);
        }
    }

    const loadData = () => {
        const dmap = new window.google.maps.Map(ref.current, {
            center: { lat: 19.200, lng: 72.800 },
            zoom: 12
        });
        setMap(dmap);
        (async function () {
            setLoading(true);
            var res = await authService.getGSTUserByTRN({ 'trn': trnno, 'stdOwn': userID });
            setGSTUser(res.data[0]);
            SetTRNLevel({ ...trnLevel, form: res.data[0]['statusArr'], percentage: utilService.calculatePercentage(res.data[0]['statusArr']) });
            if (res.data[0]['statusArr'][4] === '1') {
                setBoxs(res.data[0]['plants'][0]['boxs']);
                setDocs(res.data[0]['plants'][0]['docs']);
                setValues({ ...values, ...res.data[0]['plants'][0] });
                setIsPincode(true);
                setFileError(false);
                setIsP(true);
                setIsS(true);
            } else {
                setIsP(false);
                setIsS(false);
                setValues({ ...values, bp_email: res.data[0].email, bp_mobile: res.data[0].mobile });
            }
            setLoading(false);
            window.scrollTo({
                top: 0,
                behavior: 'smooth',
            });
        })();
    }

    const changeFile = () => {
        if (doc.isFile) {
            let mDoc = {
                type: doc.type,
                title: values.bp_up_type,
                file: doc.doc
            }
            setDocs([...docs, mDoc]);
            setDoc({ isFile: false, type: '', doc: null });
        }
    }

    useEffect(() => {
        loadData();
    }, []);

    useEffect(() => {
        changeFile();
    }, [doc]);

    return (
        <form class="tabpane" onSubmit={handleSubmit} onReset={handleReset} autocomplete="off" noValidate>
            <Dialog open={digConfirm.isToggle} onClose={() => setDigConfirm({ 'type': '0', isToggle: false, data: null })}>
                <div id="confirmDlg" class="modal fade fade-scale in" role="dialog" style={{ display: 'block', paddingRight: '17px' }}>
                    <div class="modal-dialog sweet">
                        <div class="modal-content">
                            <div class="modal-body">
                                <div class="m-icon m-warning pulseWarning">
                                    <span class="micon-body pulseWarningIns"></span>
                                    <span class="micon-dot pulseWarningIns"></span>
                                </div>
                                <h2>Warning</h2>
                                <p>Are you sure, you want to continue?</p>
                            </div>
                            <div class="modal-footer">
                                <a class="btn btn-default" onClick={() => { cancel() }}>Cancel</a>
                                <a class="btn btn-primary" onClick={() => { process() }}>Proceed</a>
                            </div>
                        </div>
                    </div>
                </div>
            </Dialog>
            <Dialog open={errorDig} onClose={() => setErrorDig(false)}>
                <div id="confirmDlg" class="modal fade fade-scale in" role="dialog" style={{ display: 'block', paddingRight: '17px' }}>
                    <div class="modal-dialog sweet">
                        <div class="modal-content">
                            <div class="modal-body">
                                <div class="m-icon m-error">
                                    <span class="x-mark">
                                        <span class="m-line m-left">
                                        </span>
                                        <span class="m-line m-right">
                                        </span>
                                    </span>
                                </div>
                                <h2>Error</h2>
                                <p>You can only change the Nature of possession of premises after deleting the uploaded document</p>
                            </div>
                            <div class="modal-footer">
                                <a class="btn btn-default" onClick={() => { setErrorDig(false) }}>Close</a>
                            </div>
                        </div>
                    </div>
                </div>
            </Dialog>
            <Dialog open={errorDigB} onClose={() => setErrorDigB(false)}>
                <div id="confirmDlg" class="modal fade fade-scale in" role="dialog" style={{ display: 'block', paddingRight: '17px' }}>
                    <div class="modal-dialog sweet">
                        <div class="modal-content">
                            <div class="modal-body">
                                <div class="m-icon m-error">
                                    <span class="x-mark">
                                        <span class="m-line m-left">
                                        </span>
                                        <span class="m-line m-right">
                                        </span>
                                    </span>
                                </div>
                                <h2>Error</h2>
                                <p>Select at least one Nature of Business Activity</p>
                            </div>
                            <div class="modal-footer">
                                <a class="btn btn-default" onClick={() => { setErrorDigB(false) }}>Close</a>
                            </div>
                        </div>
                    </div>
                </div>
            </Dialog>
            <fieldset>
                <p class="mand-text">indicates mandatory fields</p>
                <h4><span>Details of Principal Place of Business</span></h4>
                <hr />
                <h4>
                    <i class="fa fa-envelope"></i>&nbsp;
                    <span>Address</span>
                </h4>
                <span></span>

                <div id="map3" class="mapIdClass leaflet-container leaflet-fade-anim leaflet-grab leaflet-touch-drag" tabindex="0" style={{ position: 'relative' }}>
                    <div>
                        <div ref={ref} id="map" style={{ minHeight: '400px' }} />
                    </div>
                    <div class="leaflet-pane leaflet-map-pane" style={{ transform: 'translate3d(0px, 0px, 0px)' }}>
                        <div class="leaflet-pane leaflet-tile-pane">
                            <div class="leaflet-layer " style={{ zIndex: 1, opacity: 1 }}>
                                <div class="leaflet-tile-container leaflet-zoom-animated" style={{ zIndex: 19, transform: 'translate3d(0px, 0px, 0px) scale(1)' }}>
                                    <div class="tbl-format">
                                        <div class="row">
                                            <div class="inner">
                                                <div class={`col-sm-4 col-xs-12 ${touched.pncd ? errors.pncd ? 'has-error' : 'has-success' : ''}`}>
                                                    <label class="reg m-cir" for="pncd">PIN Code</label>
                                                    <input value={values.pncd} name="pncd" id="pncd" type="text" class="form-control number" placeholder="Enter PIN Code" required="" maxLength={6} maxlength="6" autocomplete="!!!!!!" onChange={(e) => {
                                                        e.target.value = utilService.formatOnlyNumber(e.target.value);
                                                        handelePincode(e);
                                                    }} />
                                                    {(errors.pncd && touched.pncd) && <p class="err">{errors.pncd}</p>}
                                                </div>
                                                <div class="col-sm-4 col-xs-12">
                                                    <label for="bp_state" class="reg">State</label>
                                                    <p>{gstUser.state}</p>
                                                </div>
                                                <div class={`col-sm-4 col-xs-12 ${touched.dst ? errors.dst ? 'has-error' : 'has-success' : ''}`}>
                                                    <label class="reg m-cir" for="dst">District</label>
                                                    <input value={values.dst} id="dst" name="dst" type="text" class="form-control" placeholder="Enter District Name" autocomplete="!!!!!!" required="required" onChange={handleChange} disabled={!isPincode} />
                                                    {(errors.dst && touched.dst) && <p class="err">{errors.dst}</p>}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="inner">
                                                <div class={`col-sm-4 col-xs-12 ${touched.loc ? errors.loc ? 'has-error' : 'has-success' : ''}`}>
                                                    <label class="reg m-cir" for="loc">City / Town / Village</label>
                                                    <input value={values.loc} class="form-control" id="loc" name="loc" maxLength={60} placeholder="Enter City / Town / Village" autocomplete="!!!!!!" required="" onChange={handleChange} disabled={!isPincode} />
                                                    {(errors.loc && touched.loc) && <p class="err">{errors.loc}</p>}
                                                </div>
                                                <div class="col-sm-4 col-xs-12">
                                                    <label class="reg" for="pd_locality">Locality/Sub Locality</label>
                                                    <input value={values.ppbzdtls_locality} class="form-control" maxLength={60} id="ppbzdtls_locality" name="ppbzdtls_locality" placeholder="Enter Locality / Sublocality" autocomplete="!!!!!!" onChange={handleChange} disabled={!isPincode} />
                                                </div>
                                                <div class={`col-sm-4 col-xs-12 ${touched.st ? errors.st ? 'has-error' : 'has-success' : ''}`}>
                                                    <label class="reg m-cir" for="st">Road / Street</label>
                                                    <input value={values.st} class="form-control" id="st" name="st" maxLength={60} placeholder="Enter Road / Street / Lane" autocomplete="!!!!!!" required="" onChange={handleChange} disabled={!isPincode} />
                                                    {(errors.st && touched.st) && <p class="err">{errors.st}</p>}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="inner">
                                                <div class="col-sm-4 col-xs-12">
                                                    <label class="reg" for="bp_bdname">Name of the Premises / Building</label>
                                                    <input value={values.bp_bdname} class="form-control" id="bp_bdname" name="bp_bdname" maxLength={60} placeholder="Enter Name of Premises / Building" autocomplete="!!!!!!" onChange={handleChange} disabled={!isPincode} />
                                                </div>
                                                <div class={`col-sm-4 col-xs-12 ${touched.bno ? errors.bno ? 'has-error' : 'has-success' : ''}`}>
                                                    <label class="reg m-cir" for="bno">Building No. / Flat No.</label>
                                                    <input value={values.bno} class="form-control" id="bno" name="bno" maxLength={60} placeholder="Enter Building No. / Flat No. / Door No." autocomplete="!!!!!!" autofocus="" required="" onChange={handleChange} disabled={!isPincode} />
                                                    {(errors.bno && touched.bno) && <p class="err">{errors.bno}</p>}
                                                </div>
                                                <div class="col-sm-4 col-xs-12">
                                                    <label class="reg" for="bp_flrnum">Floor No.</label>
                                                    <input value={values.bp_flrnum} class="form-control" id="bp_flrnum" name="bp_flrnum" maxLength={60} placeholder="Enter Floor No." autocomplete="!!!!!!" onChange={handleChange} disabled={!isPincode} />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="inner">
                                                <div class="col-sm-4 col-xs-12">
                                                    <label class="reg" for="ppbzdtls_landmark">Nearby Landmark</label>
                                                    <input value={values.ppbzdtls_landmark} class="form-control" maxLength={60} id="ppbzdtls_landmark" name="ppbzdtls_landmark" placeholder="Enter Nearby Landmark" autocomplete="!!!!!!" onChange={handleChange} disabled={!isPincode} />
                                                </div>
                                                <div class="col-sm-4 col-xs-12">
                                                    <label class="reg" for="bp_latitude">Latitude</label>
                                                    <input value={values.bp_latitude} class="form-control" readonly="readonly" name="bp_latitude" id="bp_latitude" maxLength={27} placeholder="Enter Latitude" onChange={handleChange} />
                                                </div>
                                                <div class="col-sm-4 col-xs-12">
                                                    <label class="reg" for="bp_longitude">Longitude</label>
                                                    <input value={values.bp_longitude} class="form-control" readonly="readonly" name="bp_longitude" id="bp_longitude" maxLength={27} placeholder="Enter Longitude" onChange={handleChange} />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="inner">
                                                <div class="col-sm-4 col-xs-12">
                                                </div>
                                                <div class="col-sm-4 col-xs-12">
                                                    <center onClick={() => { resetAddress() }}>
                                                        <button type="button" class="btn btn-primary">
                                                            <i class="fa fa-refresh" aria-hidden="true"></i>
                                                            &nbsp;Reset Address</button>
                                                    </center>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="inner">
                                                <div class="col-sm-4 col-xs-12">
                                                    <label for="bd_sj" class="reg">State Jurisdiction</label>
                                                    <p>Charge</p>
                                                </div>
                                                <div class={`col-sm-4 col-xs-12 ${touched.stateJudi ? errors.stateJudi ? 'has-error' : 'has-success' : ''}`}>
                                                    <label for="stj" class="reg m-cir">
                                                        <span>Sector / Circle / Ward /Charge / Unit</span>&nbsp;
                                                        <span>
                                                            <i class="fa fa-info-circle" data-toggle="tooltip" title="Kindly select correct Jurisdiction."> </i>
                                                        </span>
                                                    </label>
                                                    <input value={values.stateJudi} class="form-control" id="stateJudi" name="stateJudi" placeholder="Enter State Jurisdiction" autocomplete="!!!!!!" onChange={handleChange} />
                                                    {(errors.stateJudi && touched.stateJudi) && <p class="err">{errors.stateJudi}</p>}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="inner">
                                                <div class="col-xs-12">
                                                    <h5>
                                                        <span>Center Jurisdiction (</span>
                                                        <i class="fa fa-info-circle"></i> <span>Refer the</span>
                                                        <a onClick={() => {
                                                            gotoURL('https://cbec-gst.gov.in/know-your-jurisdiction.html');
                                                        }}>
                                                            &nbsp;<span>link</span>
                                                            <i class="fa fa-external-link-square"></i></a>
                                                        &nbsp;<span>for Center Jurisdiction )</span>
                                                    </h5>
                                                </div>
                                                <div class={`col-sm-4 col-xs-12 ${touched.commJudi ? errors.commJudi ? 'has-error' : 'has-success' : ''}`}>
                                                    <label for="comcd" class="reg m-cir">Commissionerate</label>
                                                    <input value={values.commJudi} class="form-control" id="commJudi" name="commJudi" placeholder="Enter Commissionerate" autocomplete="!!!!!!" onChange={handleChange} />
                                                    {(errors.commJudi && touched.commJudi) && <p class="err">{errors.commJudi}</p>}
                                                </div>
                                                <div class={`col-sm-4 col-xs-12 ${touched.divJudi ? errors.divJudi ? 'has-error' : 'has-success' : ''}`}>
                                                    <label for="divcd" class="reg m-cir">Division</label>
                                                    <input value={values.divJudi} class="form-control" id="divJudi" name="divJudi" placeholder="Enter Division" autocomplete="!!!!!!" onChange={handleChange} />
                                                    {(errors.divJudi && touched.divJudi) && <p class="err">{errors.divJudi}</p>}
                                                </div>
                                                <div class={`col-sm-4 col-xs-12 ${touched.rangeJudi ? errors.rangeJudi ? 'has-error' : 'has-success' : ''}`}>
                                                    <label for="rgcd" class="reg m-cir">Range</label>
                                                    <input value={values.rangeJudi} class="form-control" id="rangeJudi" name="rangeJudi" placeholder="Enter Range" autocomplete="!!!!!!" onChange={handleChange} />
                                                    {(errors.rangeJudi && touched.rangeJudi) && <p class="err">{errors.rangeJudi}</p>}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <h4>
                                        <i class="fa fa-address-card"></i>
                                        <span>&nbsp;Contact Information</span>
                                    </h4>
                                    <div class="tbl-format">
                                        <div class="row">
                                            <div class="inner">
                                                <div class={`col-sm-4 col-xs-12 ${touched.bp_email ? errors.bp_email ? 'has-error' : 'has-success' : ''}`}>
                                                    <label for="bp_email" class="reg m-cir">
                                                        <i class="fa fa-envelope-open-o"></i>
                                                        <span>&nbsp;Office Email Address</span>
                                                    </label>
                                                    <input value={values.bp_email} id="bp_email" maxlength="254" class="form-control" type="email" placeholder="Enter Email Address" name="bp_email" onChange={handleChange} />
                                                    {(errors.bp_email && touched.bp_email) && <p class="err">{errors.bp_email}</p>}
                                                </div>
                                                <div class="col-sm-4 col-xs-12">
                                                    <label for="tlphnoStd" class="reg">
                                                        <i class="type-ico fa fa-phone"></i>
                                                        <span>&nbsp;Office Telephone Number (with STD Code)</span>
                                                    </label>
                                                    <div class="input-group">
                                                        <div class="col-sm-3 col-xs-3 addrow" style={{ padding: 0 }}>
                                                            <input value={values.tlphnoStd} type="text" id="tlphnoStd" name="tlphnoStd" class="number form-control" placeholder="STD" maxLength={8} onChange={(e) => {
                                                                e.target.value = utilService.formatOnlyNumber(e.target.value);
                                                                handleChange(e);
                                                            }} />
                                                        </div>
                                                        <div class="col-sm-9 col-xs-9 addrow" style={{ padding: 0 }}>
                                                            <input value={values.tlphno} type="text" id="tlphno" name="tlphno" class="number form-control" placeholder="Enter Telephone Number" maxLength={16} onChange={(e) => {
                                                                e.target.value = utilService.formatOnlyNumber(e.target.value);
                                                                handleChange(e);
                                                            }} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-sm-4 col-xs-12">
                                                    <label for="bp_mobile" class="reg m-cir">
                                                        <i class="type-ico fa fa-mobile"></i>
                                                        <span>&nbsp;Mobile Number</span>
                                                    </label>
                                                    <div class={`input-group ${touched.bp_mobile ? errors.bp_mobile ? 'has-error' : 'has-success' : ''}`}>
                                                        <span class="input-group-addon" id="basic-addon1">+91</span>
                                                        <input value={values.bp_mobile} id="bp_mobile" type="text" class="number form-control" placeholder="Enter Mobile Number" name="bp_mobile" maxLength={10} onChange={(e) => {
                                                            e.target.value = utilService.formatOnlyNumber(e.target.value);
                                                            handleChange(e)
                                                        }} />
                                                    </div>
                                                    {(errors.bp_mobile && touched.bp_mobile) && <p class="err">{errors.bp_mobile}</p>}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="inner">
                                                <div class="col-sm-4 col-xs-12">
                                                    <label for="fxnostd" class="reg">
                                                        <i class="type-ico fa fa-fax"></i>
                                                        <span data-ng-bind="trans.LBL_OFAX_NO">&nbsp;Office FAX Number (with STD Code)</span>
                                                    </label>
                                                    <div class="input-group">
                                                        <div class="col-sm-3 col-xs-3 addrow" style={{ padding: 0 }}>
                                                            <input value={values.fxnostd} type="text" id="fxnostd" name="fxnostd" class="number form-control" placeholder="STD" maxLength={8} onChange={(e) => {
                                                                e.target.value = utilService.formatOnlyNumber(e.target.value);
                                                                handleChange(e)
                                                            }} />
                                                        </div>
                                                        <div class="col-sm-9 col-xs-9 addrow" style={{ padding: 0 }}>
                                                            <input value={values.fxno} type="text" id="fxno" name="fxno" class="number form-control" placeholder="Enter Fax Number" maxLength={16} onChange={(e) => {
                                                                e.target.value = utilService.formatOnlyNumber(e.target.value);
                                                                handleChange(e)
                                                            }} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="tbl-format">
                                        <div class="row mb-6">
                                            <div className='inner'>
                                                <div class={`col-sm-6 col-xs-12 ${touched.bp_buss_poss ? errors.bp_buss_poss ? 'has-error' : 'has-success' : ''}`}>
                                                    <h4 class="m-cir">
                                                        <i class="fa fa-building"></i>&nbsp;
                                                        <span>Nature of possession of premises</span>
                                                    </h4>
                                                    <label class="reg" for="bp_buss_poss">Please Select</label>
                                                    <select class="form-control" name="bp_buss_poss" id="bp_buss_poss" value={values.bp_buss_poss} required="" onChange={(e) => {
                                                        let value = e.target.value;
                                                        if (docs.length < 1) {
                                                            if (value.length < 1) {
                                                                setIsS(false);
                                                            } else {
                                                                setIsS(true);
                                                            }
                                                            handleChange(e);
                                                        } else {
                                                            setErrorDig(true);
                                                        }
                                                    }}>
                                                        <option value="">Select</option>
                                                        <option value="CON">Consent</option>
                                                        <option value="LES">Leased</option>
                                                        <option value="OTH">Others</option>
                                                        <option value="OWN">Own</option>
                                                        <option value="REN">Rented</option>
                                                        <option value="SHA">Shared</option>
                                                    </select>
                                                    <p></p>
                                                </div>
                                                {
                                                    docs.length != 0 && <div class="col-sm-6 col-xs-12 mt-10">
                                                        <ul style={{ listStyle: 'none', marginTop: '14px' }}>
                                                            {
                                                                docs.map((d, index) => {
                                                                    return <li data-ng-repeat="doc in ppbzdtls.dcupdtls | filter: docTypeFilter">
                                                                        <div>
                                                                            <a href={d.file.fileurl} style={{ display: 'inline-block' }}>
                                                                                <img alt="preview" src={icPdf} />
                                                                                <p>{getByTitle(d.title)}</p>
                                                                            </a>
                                                                            <button title="Delete" type="button" class="btn btn-sm btn-danger pull-right" style={{ verticalAlign: 'top', marginLeft: '20px' }} data-conf-click="deleteFileNewHb(doc.id, 'ppbzdtls.dcupdtls', ppbzdtls.dcupdtls,'bp')" data-ng-disabled="['DFT','VAE'].indexOf(rpayload.rgdtls.aplst) < 0 || (clrPayload)">
                                                                                <i class="fa fa-trash"></i> <span data-ng-bind="trans.LBL_DELETE" onClick={() => showDig('1', index)}>Delete</span>
                                                                            </button>
                                                                        </div>
                                                                    </li>
                                                                })
                                                            }
                                                        </ul>
                                                    </div>
                                                }
                                                {
                                                    docs.length < 2 && <div class={`col-sm-6 col-xs-12 ${touched.bp_up_type ? errors.bp_up_type ? 'has-error' : 'has-success' : ''}`}>
                                                        <h4 class="m-cir">
                                                            <i class="fa fa-cloud-upload"></i>&nbsp;
                                                            <span>Document Upload</span>
                                                        </h4>
                                                        <label class="reg m-cir" for="bp_upload2">Proof of Principal Place of Business</label>
                                                        <select class="form-control" name="bp_up_type" id="bp_up_type" value={values.bp_up_type} onChange={(e) => {
                                                            let value = e.target.value;
                                                            if (value.length < 1) {
                                                                setIsP(false);
                                                            } else {
                                                                setIsP(true);
                                                            }
                                                            handleChange(e);
                                                        }} disabled={docs.length == 2}>
                                                            <option value="">Select</option>
                                                            {(values.bp_buss_poss === 'CON' || values.bp_buss_poss === 'SHA') && <option value="CNLR">Consent Letter</option>}
                                                            {(values.bp_buss_poss === 'CON' || values.bp_buss_poss === 'LES' || values.bp_buss_poss === 'OWN' || values.bp_buss_poss === 'REN' || values.bp_buss_poss === 'SHA') && <option value="ELCB">Electricity Bill</option>}
                                                            {(values.bp_buss_poss === 'CON' || values.bp_buss_poss === 'LES' || values.bp_buss_poss === 'OTH' || values.bp_buss_poss === 'OWN' || values.bp_buss_poss === 'REN' || values.bp_buss_poss === 'SHA') && <option value="LOWN">Legal ownership document</option>}
                                                            {(values.bp_buss_poss === 'CON' || values.bp_buss_poss === 'LES' || values.bp_buss_poss === 'OWN' || values.bp_buss_poss === 'REN' || values.bp_buss_poss === 'SHA') && <option value="CMUK">Municipal Khata Copy</option>}
                                                            {(values.bp_buss_poss === 'CON' || values.bp_buss_poss === 'LES' || values.bp_buss_poss === 'OWN' || values.bp_buss_poss === 'REN' || values.bp_buss_poss === 'SHA') && <option value="PAXR">Property Tax Receipt</option>}
                                                            {(values.bp_buss_poss === 'LES' || values.bp_buss_poss === 'REN') && <option value="TAXR">Rent / Lease agreement</option>}
                                                            {(values.bp_buss_poss === 'LES' || values.bp_buss_poss === 'REN') && <option value="TAXC">Rent receipt with NOC (In case of no/expired agreement)</option>}
                                                        </select>
                                                        <span class="help-block">
                                                            <p class="help-block">
                                                                <i class="fa fa-info-circle"></i>&nbsp;
                                                                <span data-ng-bind="trans.HLP_FILE_FORM">File with PDF or JPEG format is only allowed.</span>
                                                            </p>
                                                            <p class="help-block">
                                                                <i class="fa fa-info-circle"></i>&nbsp;
                                                                <span data-ng-bind="trans.HLP_MAX_FILE_SIZE">Maximum file size for upload is </span> 1 MB
                                                            </p>
                                                        </span>
                                                        <div data-ng-if="ngModel.length < maxFiles || !ngModel">
                                                            <input id="bp_upload" name="bp_upload" type="file" onClick={(e) => openBox(e)} />
                                                            {(isValid && docs.length === 0) && <p class="err" data-ng-bind="errVar">Please select one file.</p>}
                                                            {fileError && <p class="err" data-ng-bind="errVar">Please select file type.</p>}
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <h4 class="m-cir">
                                        <i class="fa fa-truck"></i>
                                        <span>Nature of Business Activity being carried out at above mentioned premises</span>
                                    </h4>
                                    <div class="tbl-format">
                                        <div class="row">
                                            <div class="inner">
                                                <ul class="list-child-inline">
                                                    <li onClick={() => { handleOnCheckBox('BWH') }}>
                                                        <input type="checkbox" class="chkbx" value="BWH" checked={boxs.includes('BWH')} />
                                                        <label for="bp_ck_BWH">Bonded Warehouse</label>
                                                    </li>
                                                    <li onClick={() => { handleOnCheckBox('ESE') }}>
                                                        <input type="checkbox" class="chkbx" value="ESE" checked={boxs.includes('ESE')} />
                                                        <label for="bp_ck_ESE">EOU / STP / EHTP</label>
                                                    </li>
                                                    <li onClick={() => { handleOnCheckBox('EXP') }}>
                                                        <input type="checkbox" class="chkbx" value="EXP" checked={boxs.includes('EXP')} />
                                                        <label for="bp_ck_EXP">Export</label>
                                                    </li>
                                                    <li onClick={() => { handleOnCheckBox('FMF') }}>
                                                        <input type="checkbox" class="chkbx" value="FMF" checked={boxs.includes('FMF')} />
                                                        <label for="bp_ck_FMF">Factory / Manufacturing</label>
                                                    </li>
                                                    <li onClick={() => { handleOnCheckBox('IMP') }}>
                                                        <input type="checkbox" class="chkbx" value="IMP" checked={boxs.includes('IMP')} />
                                                        <label for="bp_ck_IMP">Import</label>
                                                    </li>
                                                    <li onClick={() => { handleOnCheckBox('SOS') }}>
                                                        <input type="checkbox" class="chkbx" value="SOS" checked={boxs.includes('SOS')} />
                                                        <label for="bp_ck_SOS">Supplier of Services</label>
                                                    </li>
                                                    <li onClick={() => { handleOnCheckBox('LBU') }}>
                                                        <input type="checkbox" class="chkbx" value="LBU" checked={boxs.includes('LBU')} />
                                                        <label for="bp_ck_LBU">Leasing Business</label>
                                                    </li>
                                                    <li onClick={() => { handleOnCheckBox('OSO') }}>
                                                        <input type="checkbox" class="chkbx" value="OSO" checked={boxs.includes('OSO')} />
                                                        <label for="bp_ck_OSO">Office / Sale Office</label>
                                                    </li>
                                                    <li onClick={() => { handleOnCheckBox('SRE') }}>
                                                        <input type="checkbox" class="chkbx" value="SRE" checked={boxs.includes('SRE')} />
                                                        <label for="bp_ck_SRE">Recipient of Goods or Services</label>
                                                    </li>
                                                    <li onClick={() => { handleOnCheckBox('RBU') }}>
                                                        <input type="checkbox" class="chkbx" value="RBU" checked={boxs.includes('RBU')} />
                                                        <label for="bp_ck_RBU">Retail Business</label>
                                                    </li>
                                                    <li onClick={() => { handleOnCheckBox('WHD') }}>
                                                        <input type="checkbox" class="chkbx" value="WHD" checked={boxs.includes('WHD')} />
                                                        <label for="bp_ck_WHD">Warehouse / Depot</label>
                                                    </li>
                                                    <li onClick={() => { handleOnCheckBox('WBU') }}>
                                                        <input type="checkbox" class="chkbx" value="WBU" checked={boxs.includes('WBU')} />
                                                        <label for="bp_ck_WBU">Wholesale Business</label>
                                                    </li>
                                                    <li onClick={() => { handleOnCheckBox('WCO') }}>
                                                        <input type="checkbox" class="chkbx" value="WCO" checked={boxs.includes('WCO')} />
                                                        <label for="bp_ck_WCO">Works Contract</label>
                                                    </li>
                                                    <li onClick={() => { handleOnCheckBox('OTH') }}>
                                                        <input type="checkbox" class="chkbx" value="OTH" checked={boxs.includes('OTH')} />
                                                        <label for="bp_ck_OTH">Others (Please Specify)</label>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="inner">
                                                {
                                                    boxs.includes('OTH') && <div class={`col-sm-4 col-xs-12 ${isValid ? values.othBuss === "" ? 'has-error' : 'has-success' : ''}`}>
                                                        <label for="othBuss" class="reg m-cir">
                                                            <span>Other Nature Of Business</span>
                                                        </label>
                                                        <input value={values.othBuss} id="othBuss" maxLength={254} class="form-control" type="text" placeholder="Please specify" name="othBuss" onChange={handleChange} />
                                                        {isValid && (boxs.includes('OTH') && values.othBuss === "") && <p class="err">Please specify nature of business</p>}
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div class="tbl-format">
                                        <div class="row">
                                            <div class="col-xs-12">
                                                <label class="reg" for="bp_add">Have Additional Place of Business</label>
                                                <div class="switch round">
                                                    <input id="bp_add" name="bp_add" type="checkbox" disabled={true} />
                                                    <label for="bp_add">
                                                        <span class="switch-on" style={{ left: '4px' }}><span data-ng-bind="trans.LBL_YES">Yes</span></span>
                                                        <span class="switch-off" style={{ left: '35px' }}><span data-ng-bind="trans.LBL_NO">No</span></span>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div >
                        </div >
                    </div >
                </div >
            </fieldset >
            <div class="row">
                <div class="col-xs-12 next-tab-nav text-right">
                    <button title="Back" class="btn btn-default" style={{ marginRight: '8px' }} onClick={() => {
                        gotoPath('sa4');
                    }} >Back</button>
                    <button title="Save &amp; Continue" type="submit" class="btn btn-primary" disabled={isSubmit}>Save &amp; Continue</button>
                </div>
            </div>
        </form >
    )
}
