import React from 'react';

export default function PanelTrnDashboardA() {
    return (
        <>
            <div class="content-wrapper">
                <div class="container">
                    <div class="mypage">
                        <div class="row">
                            <div class="col-xs-10">
                                <div data-breadcrumb="">
                                    <ol class="breadcrumb">
                                        <li><a>Dashboard</a></li>
                                    </ol>
                                </div>
                            </div>
                            <div class="col-xs-2">
                                <div class="lang dropdown">
                                    <span>English</span>
                                    <ul class="dropdown-menu lang-dpdwn">
                                        <li>
                                            <a>English</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="dimmer-holder" style={{ display: 'none' }}>
                            <div id="dimmer"></div>
                        </div>
                        <div class="content-pane" style={{ minHeight: '473.4px' }}>
                            <div class="row">
                                <div class="col-sm-12 col-xs-12">
                                    <h4>My Saved Applications</h4>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-12 col-xs-12">
                                    <p>You do not have any saved applications</p>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-12 col-xs-12">
                                    <h4>Track Applications Status</h4>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-12">
                                    <div class="table-responsive" data-ng-if="savedata &amp;&amp; savedata.length > 0">
                                        <table class="table table-vertical-align thead-dark table-hover table-bordered">
                                            <thead>
                                                <tr>
                                                    <th width="15%" class="vrtcl-midl text-center">TRN</th>
                                                    <th class="vrtcl-midl text-center">Form No.</th>
                                                    <th class="vrtcl-midl text-center" style={{ width: '20%' }}>Form Description </th>
                                                    <th class="vrtcl-midl text-center">Submission Date</th>
                                                    <th class="vrtcl-midl text-center">Status</th>
                                                    <th class="vrtcl-midl text-center">Assigned To</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr class="vrtcl-midl text-center">
                                                    <td><a href='javascript:void(0)'>270000000001TRN</a></td>
                                                    <td>GST REG-01</td>
                                                    <td>Application for New Registration</td>
                                                    <td>11/04/2021</td>
                                                    <td>
                                                        <a download="https://storage.googleapis.com/itaa-prod/docs/GST%20Registration%20Notice%201.pdf" target="_blank" href='https://storage.googleapis.com/itaa-prod/docs/GST%20Registration%20Notice%201.pdf'>Pending for Clarification</a>
                                                    </td>
                                                    <td class="text-center">
                                                        XXXXX
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
