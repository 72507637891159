import React, { useState, useEffect, useRef } from 'react';

import { useRecoilState } from 'recoil';
import { loadingAtom, pathAtom, returnDateAtom, userIDAtom, gstrFileAtom, digErrorAtom, gstrpathAtom, itcSetOffAtom } from '../../../core/config/atoms';

import Tippy from '@tippyjs/react';
import { Dialog } from '@headlessui/react';
import html2pdf from 'html2pdf.js/dist/html2pdf';

import PanelPreviewPDF from '../PanelPreviewPDF';

import ServiceGSTF from '../../../services/serviceGSTF';

import imgLoader from '../../../assets/images/cloading.gif';

export default function Panel6AHome() {

    const [loading, setLoading] = useRecoilState(loadingAtom);
    const [gDate] = useRecoilState(returnDateAtom);
    const [userID] = useRecoilState(userIDAtom);
    const [gstrf, setGSTRF] = useRecoilState(gstrFileAtom);

    const [, setPath] = useRecoilState(pathAtom);
    const [, setDigError] = useRecoilState(digErrorAtom);
    const [, setGSTRPath] = useRecoilState(gstrpathAtom);
    const [, setSetOff] = useRecoilState(itcSetOffAtom);

    const [ledger, setLedger] = useState({
        data: {
            clb: {
                tax: {
                    total: 0,
                    igst: 0,
                    cgst: 0,
                    sgst: 0,
                    cess: 0
                },
                intrest: {
                    total: 0,
                    igst: 0,
                    cgst: 0,
                    sgst: 0,
                    cess: 0
                },
                fees: {
                    cgst: 0,
                    sgst: 0
                }
            },
            citc: {
                total: 0,
                igst: 0,
                cgst: 0,
                sgst: 0,
                cess: 0
            },
            setoff: {
                cess: 0,
                igst: {
                    igsta: 0,
                    igstb: 0,
                    igstc: 0,
                },
                cgst: {
                    cgsta: 0,
                    cgstb: 0,
                },
                sgst: {
                    sgsta: 0,
                    sgstb: 0,
                },
                bal: {
                    bala: 0,
                    balb: 0,
                    balc: 0,
                    bald: 0,
                }
            }
        }
    });

    const [setoff, setSetoff] = useState({
        cess: '0.00',
        igst: {
            igsta: '0.00',
            igstb: '0.00',
            igstc: '0.00',
        },
        cgst: {
            cgsta: '0.00',
            cgstb: '0.00',
        },
        sgst: {
            sgsta: '0.00',
            sgstb: '0.00',
        },
        bal: {
            bala: '0.00',
            balb: '0.00',
            balc: '0.00',
            bald: '0.00',
        }
    });

    const [itcigst, setITCIgst] = useState({ igsta: '0.00', igstb: '0.00', igstc: '0.00' });
    const [itccgst, setITCCgst] = useState({ cgsta: '0.00', cgstb: '0.00' });
    const [itcsgst, setITCSgst] = useState({ sgsta: '0.00', sgstb: '0.00' });
    const [itcbal, setITCBal] = useState({ a: '0.00', b: '0.00', c: '0.00', d: '0.00' });

    const [RIGST, setRIGST] = useState(0);
    const [RCGST, setRCGST] = useState(0);
    const [RSGST, setRSGST] = useState(0);

    const [isHighErr, setIsHighErr] = useState("");
    const [isITCHighErr, setIsITCHighErr] = useState("");

    const [digWarnningA, setDigWarnningA] = useState(false);
    const [digWarnningB, setDigWarnningB] = useState(false);
    const [digWarnningC, setDigWarnningC] = useState(false);
    const [isSubmit, setIsSubmit] = useState(false);
    const [isPrint, setIsPrint] = useState(false);
    const [isErr, setIsErr] = useState(false);

    const pdfRef = useRef(null);

    const fileService = new ServiceGSTF();

    const gotoPath = (path) => {
        setPath(path);
    }

    const gotoGstrPath = (path) => {
        setGSTRPath(path);
        gotoPath('gstr3B');
    }

    const handleOnChangeForm = (e) => {
        var { value, name } = e.target;
        switch (name) {
            case 'itccess':
                let cess = getCess('A3.1');

                let offset = { ...setoff };
                offset.cess = value;

                if (cess > 0) {
                    let temp = cess - parseFloat(value);
                    if (temp <= 0) {
                        offset.bal.bald = 0;
                    } else {
                        offset.bal.bald = temp;
                    }
                }

                setSetoff({ ...offset });
                break;
        }
    }

    const handleOnChangeFormB = (e, type) => {
        var { value, name } = e.target;
        switch (type) {
            case 'igsta':
                setSetoff({
                    ...setoff,
                    igst: {
                        ...setoff.igst,
                        igsta: value,
                    },
                });
                break;
            case 'igstb':
                setSetoff({
                    ...setoff,
                    igst: {
                        ...setoff.igst,
                        igstb: value,
                    },
                });
                break;
            case 'igstc':
                setSetoff({
                    ...setoff,
                    igst: {
                        ...setoff.igst,
                        igstc: value,
                    },
                });
                break;
            case 'cgsta':
                setSetoff({
                    ...setoff,
                    cgst: {
                        ...setoff.cgst,
                        cgsta: value,
                    },
                });
                break;
            case 'cgstb':
                setSetoff({
                    ...setoff,
                    cgst: {
                        ...setoff.cgst,
                        cgstb: value,
                    },
                });
                break;
            case 'sgsta':
                setSetoff({
                    ...setoff,
                    sgst: {
                        ...setoff.sgst,
                        sgsta: value,
                    },
                });
                break;
            case 'sgstb':
                setSetoff({
                    ...setoff,
                    sgst: {
                        ...setoff.sgst,
                        sgstb: value,
                    },
                });
                break;
        }
    }

    const getIGST = (type) => {
        let value = 0;
        switch (type) {
            case 'A3.1':
                value = parseFloat(gstrf.data.a3.user.a.igst.value) + parseFloat(gstrf.data.a3.user.b.igst.value) + parseFloat(gstrf.data.a3.user.d.igst.value);
                if (parseFloat(gstrf.data.a4.user.c.igst) < 0) {
                    value = value - parseFloat(gstrf.data.a4.user.c.igst);
                }
                break;
            case 'A5':
                value = parseFloat(gstrf.data.a5.user.a.igst.value) + parseFloat(gstrf.data.a5.user.b.igst.value);
                break;
            case 'A51':
                value = parseFloat(gstrf.data.a51.user.a.igst.value);
                break;
        }
        return value;
    }

    const getFIGST = (type, file) => {
        let value = 0;
        switch (type) {
            case 'A3.1':
                value = parseFloat(file.data.a3.user.a.igst.value) + parseFloat(file.data.a3.user.b.igst.value) + parseFloat(file.data.a3.user.d.igst.value);
                break;
            case 'A4':
                value = parseFloat(file.data.a4.user.a.rowa.igst.value) + parseFloat(file.data.a4.user.a.rowb.igst.value) + parseFloat(file.data.a4.user.a.rowc.igst.value) + parseFloat(file.data.a4.user.a.rowd.igst.value) + parseFloat(file.data.a4.user.a.rowe.igst.value);
                value = value + parseFloat(file.data.a4.user.b.rowa.igst.value) + parseFloat(file.data.a4.user.b.rowb.igst.value);
                value = value + parseFloat(file.data.a4.user.c.igst.value);
                value = value + parseFloat(file.data.a4.user.d.rowa.igst.value) + parseFloat(file.data.a4.user.d.rowb.igst.value);
                break;
            case 'A5':
                value = parseFloat(file.data.a5.user.a.igst.value) + parseFloat(file.data.a5.user.b.igst.value);
                break;
            case 'A51':
                value = parseFloat(file.data.a51.user.a.igst.value);
                break;
        }
        return value;
    }

    const getCGST = (type) => {
        let value = 0;
        switch (type) {
            case 'A3.1':
                value = parseFloat(gstrf.data.a3.user.a.cgst.value) + parseFloat(gstrf.data.a3.user.d.cgst.value);
                if (parseFloat(gstrf.data.a4.user.c.cgst) < 0) {
                    value = value - parseFloat(gstrf.data.a4.user.c.cgst);
                }
                break;
            case 'A5':
                value = parseFloat(gstrf.data.a5.user.a.cgst.value) + parseFloat(gstrf.data.a5.user.b.cgst.value);
                break;
            case 'A51':
                value = parseFloat(gstrf.data.a51.user.a.cgst.value) + parseFloat(gstrf.data.a51.user.b.igst.value);
                break;
            case 'A51A':
                value = parseFloat(gstrf.data.a51.user.a.cgst.value);
                break;
            case 'A51B':
                value = parseFloat(gstrf.data.a51.user.b.igst.value);
                break;
        }
        return value;
    }

    const getSGST = (type) => {
        let value = 0;
        switch (type) {
            case 'A3.1':
                value = parseFloat(gstrf.data.a3.user.a.sgst.value) + parseFloat(gstrf.data.a3.user.d.sgst.value);
                if (parseFloat(gstrf.data.a4.user.c.sgst) < 0) {
                    value = value - parseFloat(gstrf.data.a4.user.c.sgst);
                }
                break;
            case 'A51':
                value = parseFloat(gstrf.data.a51.user.a.sgst.value) + parseFloat(gstrf.data.a51.user.b.cgst.value);
                break;
            case 'A51A':
                value = parseFloat(gstrf.data.a51.user.a.sgst.value);
                break;
            case 'A51B':
                value = parseFloat(gstrf.data.a51.user.b.cgst.value);
                break;
        }
        return value;
    }

    const getFCGST = (type, file) => {
        let value = 0;
        switch (type) {
            case 'A3.1':
                value = parseFloat(file.data.a3.user.a.cgst.value) + parseFloat(file.data.a3.user.d.cgst.value);
                break;
            case 'A4':
                value = parseFloat(file.data.a4.user.a.rowc.cgst.value) + parseFloat(file.data.a4.user.a.rowd.cgst.value) + parseFloat(file.data.a4.user.a.rowe.cgst.value);
                value = value + parseFloat(file.data.a4.user.b.rowa.cgst.value) + parseFloat(file.data.a4.user.b.rowb.cgst.value);
                value = value + parseFloat(file.data.a4.user.c.cgst.value);
                value = value + parseFloat(file.data.a4.user.d.rowa.cgst.value) + parseFloat(file.data.a4.user.d.rowb.cgst.value);
                break;
            case 'A5':
                value = parseFloat(file.data.a5.user.a.cgst.value) + parseFloat(file.data.a5.user.b.cgst.value);
                break;
            case 'A51':
                value = parseFloat(file.data.a51.user.a.cgst.value) + parseFloat(file.data.a51.user.b.igst.value);
                break;
        }
        return value;
    }

    const getFSGST = (type, file) => {
        let value = 0;
        switch (type) {
            case 'A3.1':
                value = parseFloat(file.data.a3.user.a.sgst.value) + parseFloat(file.data.a3.user.d.sgst.value);
                break;
            case 'A4':
                value = parseFloat(file.data.a4.user.a.rowc.sgst.value) + parseFloat(file.data.a4.user.a.rowd.sgst.value) + parseFloat(file.data.a4.user.a.rowe.sgst.value);
                value = value + parseFloat(file.data.a4.user.b.rowa.sgst.value) + parseFloat(file.data.a4.user.b.rowb.sgst.value);
                value = value + parseFloat(file.data.a4.user.c.sgst.value);
                value = value + parseFloat(file.data.a4.user.d.rowa.sgst.value) + parseFloat(file.data.a4.user.d.rowb.sgst.value);
                break;
            case 'A51':
                value = parseFloat(file.data.a51.user.a.sgst.value) + parseFloat(file.data.a51.user.b.cgst.value);
                break;
        }
        return value;
    }

    const getCess = (type) => {
        let value = 0;
        switch (type) {
            case 'A3.1':
                value = parseFloat(gstrf.data.a3.user.a.cess.value) + parseFloat(gstrf.data.a3.user.b.cess.value) + parseFloat(gstrf.data.a3.user.d.cess.value);
                if (parseFloat(gstrf.data.a4.user.c.cess) < 0) {
                    value = value - parseFloat(gstrf.data.a4.user.c.cess);
                }
                break;
            case 'A51':
                value = parseFloat(gstrf.data.a51.user.a.cess.value);
                break;
        }
        return value;
    }

    const getFCess = (type, file) => {
        let value = 0;
        switch (type) {
            case 'A3.1':
                value = parseFloat(file.data.a3.user.a.cess.value) + parseFloat(file.data.a3.user.b.cess.value) + parseFloat(file.data.a3.user.d.cess.value);
                break;
            case 'A4':
                value = parseFloat(file.data.a4.user.a.rowa.cess.value) + parseFloat(file.data.a4.user.a.rowb.cess.value) + parseFloat(file.data.a4.user.a.rowc.cess.value) + parseFloat(file.data.a4.user.a.rowd.cess.value) + parseFloat(file.data.a4.user.a.rowe.cess.value);
                value = value + parseFloat(file.data.a4.user.b.rowa.cess.value) + parseFloat(file.data.a4.user.b.rowb.cess.value);
                value = value + parseFloat(file.data.a4.user.c.cess.value);
                value = value + parseFloat(file.data.a4.user.d.rowa.cess.value) + parseFloat(file.data.a4.user.d.rowb.cess.value);
                break;
            case 'A51':
                value = parseFloat(file.data.a51.user.a.cess.value);
                break;
        }
        return value;
    }

    const getFormatvalue = (val) => {
        if (!val) return val;
        let number = val.replace(/[^\d.]/g, "");
        if (val.includes(".")) {
            const a = number.split(".");
            if (a[1].length > 2) {
                a[1] = a[1].substring(0, 2);
            }
            if (a[0].length > 13) {
                a[0] = a[0].substring(0, 13);
            }
            number = a[0] + "." + a[1];
        } else {
            if (number.length > 13) {
                number = number.substring(0, 13);
            }
        }
        return number;
    }

    const getTotalP = (type) => {
        let value = 0;
        switch (type) {
            case 'igst':
                value = value + ledger.data.clb.tax.igst + ledger.data.clb.intrest.igst;
                return value.toFixed(2);
            case 'cgst':
                value = value + ledger.data.clb.tax.cgst + ledger.data.clb.intrest.cgst + ledger.data.clb.fees.cgst;
                return value.toFixed(2);
            case 'sgst':
                value = value + ledger.data.clb.tax.sgst + ledger.data.clb.intrest.sgst + ledger.data.clb.fees.sgst;
                return value.toFixed(2);
            case 'cess':
                value = value + ledger.data.clb.tax.cess + ledger.data.clb.intrest.cess;
                return value.toFixed(2);
        }
    }

    const getTotalR = (type) => {
        let value = 0;
        switch (type) {
            case 'igst':
                value = value + parseFloat(setoff.bal.bala) + getIGST('A51') - parseFloat(getTotalP('igst'));
                return value.toFixed(2);
            case 'cgst':
                value = value + parseFloat(setoff.bal.balb) + getCGST('A51A') + getCGST('A51B') - parseFloat(getTotalP('cgst'));
                return value.toFixed(2);
            case 'sgst':
                value = value + parseFloat(setoff.bal.balc) + getSGST('A51A') + getSGST('A51B') - parseFloat(getTotalP('sgst'));
                return value.toFixed(2);
            case 'cess':
                value = value + parseFloat(setoff.bal.bald) + getCess('A51').toFixed(2) - parseFloat(getTotalP('cess'));
                return value.toFixed(2);
        }
    }

    const loadData = () => {
        (async function () {
            let res = await fileService.getGSTFDoc({ lid: userID, month: gDate });
            if (res.status) {
                setGSTRF(res.data);
                if (res.data.status === "submitted" || res.data.status === 'done') {
                    setIsSubmit(true);
                } else {
                    setIsSubmit(false);
                }
            }

            let resB = await fileService.getGSTLedger({ lid: userID, month: gDate });
            if (resB.status) {
                setLedger(resB.data);
                setSetoff({ ...resB.data.data.setoff });
            }

            if (res.data.status !== "submitted" && res.data.status !== 'done') {
                initData(res.data, resB.data);
            }

            setLoading(false);
            window.scrollTo({
                top: 0,
                behavior: 'smooth',
            });
        })();
    }

    const initData = (file, ledger) => {

        let igst = getFIGST('A3.1', file);
        let cgst = getFCGST('A3.1', file);
        let sgst = getFSGST('A3.1', file);
        let cess = getFCess('A3.1', file);

        let Pigst = ledger.data.citc.igst;
        let Pcgst = ledger.data.citc.cgst;
        let Psgst = ledger.data.citc.sgst;
        let Pcess = ledger.data.citc.cess;

        setRIGST(Pigst);
        setRCGST(Pcgst);
        setRSGST(Psgst);

        let offset = { ...setoff };
        let temp = 0;
        let carry = 0;
        let tempICarry = Pigst;
        let tempCCarry = Pcgst;
        let tempSCarry = Psgst;

        if (cess > 0) {
            temp = cess - Pcess;
            if (temp <= 0) {
                offset.cess = cess.toFixed(2);
                offset.bal.bald = '0.00';
            } else {
                offset.cess = Pcess.toFixed(2);
                offset.bal.bald = temp.toFixed(2);
            }
        }

        if (igst > 0) {
            temp = igst - Pigst;
            if (temp <= 0) {
                offset.igst.igsta = igst.toFixed(2);
                offset.bal.bala = '0.00';
                tempICarry = Math.abs(temp);
            } else {
                tempICarry = 0;
                offset.igst.igsta = Pigst.toFixed(2);
                carry = temp;
                temp = temp - Pcgst;
                if (temp <= 0) {
                    offset.cgst.cgsta = carry.toFixed(2);
                    offset.bal.bala = '0.00';
                    tempCCarry = Math.abs(temp);
                } else {
                    tempCCarry = 0;
                    offset.cgst.cgsta = Pcgst.toFixed(2);
                    carry = temp;
                    temp = temp - Psgst;
                    if (temp <= 0) {
                        offset.sgst.sgsta = carry.toFixed(2);
                        offset.bal.bala = '0.00';
                        tempSCarry = Math.abs(temp);
                    } else {
                        tempSCarry = 0;
                        offset.sgst.sgsta = Psgst.toFixed(2);
                        offset.bal.bala = temp.toFixed(2);
                    }
                }
            }
        }

        if (cgst > 0) {
            if (tempICarry > 0) {
                temp = cgst - tempICarry;
                if (temp <= 0) {
                    offset.igst.igstb = cgst.toFixed(2);
                    offset.bal.balb = '0.00';
                    tempICarry = Math.abs(temp);
                } else {
                    offset.igst.igstb = tempICarry.toFixed(2);
                    tempICarry = 0;
                    carry = temp;
                    temp = temp - tempCCarry;
                    if (temp <= 0) {
                        offset.cgst.cgstb = carry.toFixed(2);
                        offset.bal.balb = '0.00';
                        tempCCarry = Math.abs(temp);
                    } else {
                        offset.cgst.cgstb = tempCCarry.toFixed(2);
                        offset.bal.balb = temp.toFixed(2);
                        tempCCarry = 0;
                    }
                }
            } else {
                if (tempCCarry > 0) {
                    temp = cgst - tempCCarry;
                    if (temp <= 0) {
                        offset.cgst.cgstb = cgst.toFixed(2);
                        offset.bal.balb = '0.00';
                        tempCCarry = Math.abs(temp);
                    } else {
                        offset.cgst.cgstb = tempCCarry.toFixed(2);
                        offset.bal.balb = temp.toFixed(2);
                        tempCCarry = 0;
                    }
                } else {
                    offset.bal.balb = cgst.toFixed(2);
                }
            }
        }

        if (sgst > 0) {
            if (tempICarry > 0) {
                temp = sgst - tempICarry;
                if (temp <= 0) {
                    offset.igst.igstc = sgst.toFixed(2);
                    offset.bal.balc = '0.00';
                    tempICarry = Math.abs(temp);
                } else {
                    offset.igst.igstc = tempICarry.toFixed(2);
                    tempICarry = 0;
                    carry = temp;
                    temp = temp - tempSCarry;
                    if (temp <= 0) {
                        offset.sgst.sgstb = carry.toFixed(2);
                        offset.bal.balc = '0.00';
                        tempSCarry = Math.abs(temp);
                    } else {
                        offset.sgst.sgstb = tempSCarry.toFixed(2);
                        offset.bal.balc = temp.toFixed(2);
                        tempSCarry = 0;
                    }
                }
            } else {
                if (tempSCarry > 0) {
                    temp = cgst - tempSCarry;
                    if (temp <= 0) {
                        offset.sgst.sgstb = cgst.toFixed(2);
                        offset.bal.balc = '0.00';
                        tempSCarry = Math.abs(temp);
                    } else {
                        offset.sgst.sgstb = tempSCarry.toFixed(2);
                        offset.bal.balc = temp.toFixed(2);
                        tempSCarry = 0;
                    }
                } else {
                    offset.bal.balc = sgst.toFixed(2);
                }
            }
        }

        setSetoff(offset);
    }

    const proceedToPayment = () => {
        if (parseFloat(getTotalR('cess')) !== 0 || parseFloat(getTotalR('igst')) !== 0 || parseFloat(getTotalR('cgst')) !== 0 || parseFloat(getTotalR('sgst')) !== 0) {
            setDigWarnningB(true);
        } else {
            setDigWarnningA(true);
        }
    }

    const proceedToSubmit = () => {
        setLoading(false);
        gotoGstrPath('A7');
    }

    const handleGeneratePDFB = async () => {
        const subComponent = pdfRef.current;
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
        const options = {
            margin: 0.2,
            filename: `Preview Draft GSTR3B.pdf`,
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: { scale: 2 },
            jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
            preserve: true,
        };
        html2pdf().set(options).from(subComponent).save().then(() => {
            setIsPrint(false);
        });
    };

    const getRIGST = () => {
        let val = parseFloat(RIGST);
        val = val - parseFloat(setoff.igst.igsta) - parseFloat(setoff.igst.igstb) - parseFloat(setoff.igst.igstc);
        return val.toFixed(2);
    }

    const getRCGST = () => {
        let val = parseFloat(RCGST);
        val = val - parseFloat(setoff.cgst.cgsta) - parseFloat(setoff.cgst.cgstb);
        return val.toFixed(2);
    }

    const getRSGST = () => {
        let val = parseFloat(RSGST);
        val = val - parseFloat(setoff.sgst.sgsta) - parseFloat(setoff.sgst.sgstb);
        return val.toFixed(2);
    }

    const getIsErrorInCalc = () => {
        let isE = false;

        let igst = parseFloat(setoff.igst.igsta) + parseFloat(setoff.igst.igstb) + parseFloat(setoff.igst.igstc);
        let cgst = parseFloat(setoff.cgst.cgsta) + parseFloat(setoff.cgst.cgstb);
        let sgst = parseFloat(setoff.sgst.sgsta) + parseFloat(setoff.sgst.sgstb);

        if (parseFloat(ledger.data.citc.igst) < igst) {
            isE = true;
            setIsITCHighErr('igst');
            setDigWarnningA(false);
            setLoading(false);
            window.scrollTo({
                top: 0,
                behavior: 'smooth',
            });
        }

        if (!isE && parseFloat(ledger.data.citc.cgst) < cgst) {
            isE = true;
            setIsITCHighErr('cgst');
            setDigWarnningA(false);
            setLoading(false);
            window.scrollTo({
                top: 0,
                behavior: 'smooth',
            });
        }

        if (!isE && parseFloat(ledger.data.citc.sgst) < sgst) {
            isE = true;
            setIsITCHighErr('sgst');
            setDigWarnningA(false);
            setLoading(false);
            window.scrollTo({
                top: 0,
                behavior: 'smooth',
            });
        }

        if (!isE && getIGST('A3.1') < (parseFloat(setoff.igst.igsta) + parseFloat(setoff.cgst.cgsta) + parseFloat(setoff.sgst.sgsta))) {
            isE = true;
            setIsHighErr('igst');
            setDigWarnningA(false);
            setLoading(false);
            window.scrollTo({
                top: 0,
                behavior: 'smooth',
            });
        }

        if (!isE && (getCGST('A3.1') < (parseFloat(setoff.igst.igstb) + parseFloat(setoff.cgst.cgstb)))) {
            isE = true;
            setIsHighErr('cgst');
            setDigWarnningA(false);
            setLoading(false);
            window.scrollTo({
                top: 0,
                behavior: 'smooth',
            });
        }

        if (!isE && (getSGST('A3.1') < (parseFloat(setoff.igst.igstc) + parseFloat(setoff.sgst.sgstb)))) {
            isE = true;
            setIsHighErr('sgst');
            setDigWarnningA(false);
            setLoading(false);
            window.scrollTo({
                top: 0,
                behavior: 'smooth',
            });
        }

        if (!isE && parseFloat(getRIGST()) > 0 && (cgst > 0 || sgst > 0)) {
            isE = true;
            setIsErr(true);
            setLoading(false);
            window.scrollTo({
                top: 0,
                behavior: 'smooth',
            });
        }
        return isE;
    }

    const submitLedger = () => {

        let igst = parseFloat(setoff.igst.igsta) + parseFloat(setoff.igst.igstb) + parseFloat(setoff.igst.igstc);
        let cgst = parseFloat(setoff.cgst.cgsta) + parseFloat(setoff.cgst.cgstb);
        let sgst = parseFloat(setoff.sgst.sgsta) + parseFloat(setoff.sgst.sgstb);
        let cess = parseFloat(setoff.cess);
        let total = igst + cgst + sgst + cess;

        let isE = false;

        if (parseFloat(ledger.data.citc.igst) < igst) {
            isE = true;
            setIsITCHighErr('igst');
            setDigWarnningA(false);
            setLoading(false);
            window.scrollTo({
                top: 0,
                behavior: 'smooth',
            });
        }

        if (!isE && parseFloat(ledger.data.citc.cgst) < cgst) {
            isE = true;
            setIsITCHighErr('cgst');
            setDigWarnningA(false);
            setLoading(false);
            window.scrollTo({
                top: 0,
                behavior: 'smooth',
            });
        }

        if (!isE && parseFloat(ledger.data.citc.sgst) < sgst) {
            isE = true;
            setIsITCHighErr('sgst');
            setDigWarnningA(false);
            setLoading(false);
            window.scrollTo({
                top: 0,
                behavior: 'smooth',
            });
        }

        if (!isE) {
            setIsITCHighErr("");
        }

        if (!isE && getIGST('A3.1') < (parseFloat(setoff.igst.igsta) + parseFloat(setoff.cgst.cgsta) + parseFloat(setoff.sgst.sgsta))) {
            isE = true;
            setIsHighErr('igst');
            setDigWarnningA(false);
            setLoading(false);
            window.scrollTo({
                top: 0,
                behavior: 'smooth',
            });
        }

        if (!isE && (getCGST('A3.1') < (parseFloat(setoff.igst.igstb) + parseFloat(setoff.cgst.cgstb)))) {
            isE = true;
            setIsHighErr('cgst');
            setDigWarnningA(false);
            setLoading(false);
            window.scrollTo({
                top: 0,
                behavior: 'smooth',
            });
        }

        if (!isE && (getSGST('A3.1') < (parseFloat(setoff.igst.igstc) + parseFloat(setoff.sgst.sgstb)))) {
            isE = true;
            setIsHighErr('sgst');
            setDigWarnningA(false);
            setLoading(false);
            window.scrollTo({
                top: 0,
                behavior: 'smooth',
            });
        }

        if (!isE && parseFloat(getRIGST()) > 0 && (cgst > 0 || sgst > 0)) {
            setIsHighErr("");
            setDigWarnningA(false);
            setIsErr(true);
            setLoading(false);
            window.scrollTo({
                top: 0,
                behavior: 'smooth',
            });
        }

        if (!isE) {
            setIsITCHighErr("");
            setIsHighErr("");
            setIsErr(false);
            let citc = {
                total: Math.abs(parseFloat((ledger.data.citc.total - total).toFixed(2))),
                igst: Math.abs(parseFloat((ledger.data.citc.igst - igst).toFixed(2))),
                cgst: Math.abs(parseFloat((ledger.data.citc.cgst - cgst).toFixed(2))),
                sgst: Math.abs(parseFloat((ledger.data.citc.sgst - sgst).toFixed(2))),
                cess: Math.abs(parseFloat((ledger.data.citc.cess - cess).toFixed(2)))
            };
            fileService.submitLedger({ id: gstrf._id, lid: userID, month: gDate, data: citc, sdata: setoff }).then((res) => {
                setDigWarnningA(false);
                if (res.status) {
                    setDigWarnningC(true);
                    loadData();
                } else {
                    setDigError({ title: 'Error', msg: 'Server error please try again!', isToggle: true });
                }
            });
        }
    }

    const getErrorCode = (code) => {
        let val = 0;
        if (isHighErr === "igst") {
            switch (code) {
                case "igsta":
                    val = parseFloat(setoff.igst.igsta);
                    return getIGST('A3.1') < val;
                case "igstb":
                    val = parseFloat(setoff.igst.igsta) + parseFloat(setoff.cgst.cgsta);
                    return setoff.cgst.cgsta === "0.00" ? false : getIGST('A3.1') < val;
                case "igstc":
                    val = parseFloat(setoff.igst.igsta) + parseFloat(setoff.cgst.cgsta) + parseFloat(setoff.sgst.sgsta);
                    return setoff.sgst.sgsta === "0.00" ? false : getIGST('A3.1') < val;
                default:
                    return false;
            }
        }
        if (isHighErr === "cgst") {
            switch (code) {
                case "cgsta":
                    val = parseFloat(setoff.igst.igstb);
                    return getCGST('A3.1') < val;
                case "cgstb":
                    val = parseFloat(setoff.cgst.cgstb) + parseFloat(setoff.igst.igstb);
                    return getCGST('A3.1') < val;
                default:
                    return false;
            }
        }
        if (isHighErr === "sgst") {
            switch (code) {
                case "sgsta":
                    val = parseFloat(setoff.igst.igstc);
                    return getSGST('A3.1') < val;
                case "sgstb":
                    val = parseFloat(setoff.igst.igstc) + parseFloat(setoff.sgst.sgstb);
                    return getSGST('A3.1') < val;
                default:
                    return false;
            }
        }

        if (isITCHighErr === "igst") {
            switch (code) {
                case "igsta":
                    val = parseFloat(setoff.igst.igsta);
                    return parseFloat(ledger.data.citc.igst) < val;
                case "igstb":
                    val = parseFloat(setoff.cgst.cgsta);
                    return parseFloat(ledger.data.citc.cgst) < val;
                case "igstc":
                    val = parseFloat(setoff.sgst.sgsta);
                    return parseFloat(ledger.data.citc.sgst) < val;
                default:
                    return false;
            }
        }
        if (isITCHighErr === "cgst") {
            switch (code) {
                case "cgsta":
                    val = parseFloat(setoff.igst.igsta) + parseFloat(setoff.igst.igstb);
                    return parseFloat(ledger.data.citc.igst) < val;
                case "cgstb":
                    val = parseFloat(setoff.cgst.cgsta) + parseFloat(setoff.cgst.cgstb);
                    return parseFloat(ledger.data.citc.cgst) < val;
                default:
                    return false;
            }
        }
        if (isITCHighErr === "sgst") {
            switch (code) {
                case "sgsta":
                    val = parseFloat(setoff.igst.igsta) + parseFloat(setoff.igst.igstb) + parseFloat(setoff.igst.igstc);
                    return parseFloat(ledger.data.citc.igst) < val;
                case "sgstb":
                    val = parseFloat(setoff.cgst.sgsta) + parseFloat(setoff.cgst.sgstb);
                    return parseFloat(ledger.data.citc.sgst) < val;
                default:
                    return false;
            }
        }
    }

    useEffect(() => {
        loadData();
    }, []);

    return (
        <>
            <div className="content-wrapper fade-ng-cloak">
                <div className="content-wrapper fade-ng-cloak">
                    <div className="container">
                        <div className="mypage">
                            <div className="row" data-ng-controller="transctrl" data-ng-init="init('returns')">
                                <div className="col-xs-10">
                                    <div data-breadcrumb="" name="Dashboard">
                                        <ol className="breadcrumb" data-ng-controller="crumbCtrl">
                                            <li>
                                                <a onClick={() => { gotoPath('gstr') }}>Dashboard</a>
                                            </li>
                                            <li>
                                                <a onClick={() => { gotoPath('gstRetrun') }}>Returns</a>
                                            </li>
                                            <li>
                                                <a onClick={() => {
                                                    setLoading(true);
                                                    gotoPath('gstr3BForm')
                                                }}>GSTR-3B</a>
                                            </li>
                                            <li>
                                                Payment of tax
                                            </li>
                                        </ol>
                                    </div>
                                </div>
                                <div className="col-xs-2">
                                    <div className="lang dropdown">
                                        <span className="dropdown-toggle" data-toggle="dropdown" data-ng-bind="selectedLang">English</span>
                                        <ul className="dropdown-menu lang-dpdwn">
                                            <li data-ng-repeat="language in languages">
                                                <a data-ng-click="selectLang(language.cd)" data-ng-bind="language.nm">English</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="container">

                                {
                                    loading && <div className='absolute z-50 w-full h-screen flex items-center justify-center bg-gray-200 bg-opacity-25'>
                                        <img src={imgLoader} alt="loader" />
                                    </div>
                                }

                                <div className="row invsumm">
                                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                        <div className="pull-left">
                                            <h4 data-ng-bind="trans.HEAD_PAYMENT_TILE">6.1 Payment of tax</h4>
                                        </div>
                                        <div className="pull-right">
                                            <h4><a data-toggle="modal">Help</a>&nbsp;
                                                <i className="fa fa-question-circle" aria-hidden="true"></i>
                                            </h4>
                                        </div>
                                    </div>
                                </div>

                                <form name="taxPaidForm" novalidate="" class="ng-pristine ng-valid">
                                    <div class="tabpane">
                                        <div name="validationmsg"></div>
                                        <div class="row">
                                            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                <div>
                                                    {
                                                        isErr && <div className="alert alert-msg alert-danger ng-hide" data-ng-show="gstr3bErrorMsg">
                                                            <button type="button" className="close" aria-hidden="true">
                                                                <i class="fa fa-times"></i>
                                                            </button>
                                                            <div className='flex items-center m-0'>
                                                                <i class="fa fa-warning"></i>
                                                                &nbsp;<p className='m-0'>Offset CGST or SGST/UTGST liability with IGST credit before utilizing CGST or SGST credit</p>
                                                            </div>
                                                        </div>
                                                    }
                                                    {
                                                        isHighErr === "igst" && <div className="alert alert-msg alert-danger ng-hide" data-ng-show="gstr3bErrorMsg">
                                                            <button type="button" className="close" aria-hidden="true">
                                                                <i class="fa fa-times"></i>
                                                            </button>
                                                            <div className='flex items-center m-0'>
                                                                &nbsp;<p className='m-0'>You cannot pay more than the Integrated tax payable amount which is Rs {getIGST('A3.1').toFixed(2)}</p>
                                                            </div>
                                                        </div>
                                                    }
                                                    {
                                                        isHighErr === "cgst" && <div className="alert alert-msg alert-danger ng-hide" data-ng-show="gstr3bErrorMsg">
                                                            <button type="button" className="close" aria-hidden="true">
                                                                <i class="fa fa-times"></i>
                                                            </button>
                                                            <div className='flex items-center m-0'>
                                                                &nbsp;<p className='m-0'>You cannot pay more than the Central tax payable amount which is Rs {getCGST('A3.1').toFixed(2)}</p>
                                                            </div>
                                                        </div>
                                                    }
                                                    {
                                                        isHighErr === "sgst" && <div className="alert alert-msg alert-danger ng-hide" data-ng-show="gstr3bErrorMsg">
                                                            <button type="button" className="close" aria-hidden="true">
                                                                <i class="fa fa-times"></i>
                                                            </button>
                                                            <div className='flex items-center m-0'>
                                                                &nbsp;<p className='m-0'>You cannot pay more than the State tax payable amount which is Rs {getSGST('A3.1').toFixed(2)}</p>
                                                            </div>
                                                        </div>
                                                    }

                                                    {
                                                        isITCHighErr === "igst" && <div className="alert alert-msg alert-danger ng-hide" data-ng-show="gstr3bErrorMsg">
                                                            <button type="button" className="close" aria-hidden="true">
                                                                <i class="fa fa-times"></i>
                                                            </button>
                                                            <div className='flex items-center m-0'>
                                                                &nbsp;<p className='m-0'>You do not have sufficient ITC balance in Integrated tax to utilize or pay your total liabilities</p>
                                                            </div>
                                                        </div>
                                                    }
                                                    {
                                                        isITCHighErr === "cgst" && <div className="alert alert-msg alert-danger ng-hide" data-ng-show="gstr3bErrorMsg">
                                                            <button type="button" className="close" aria-hidden="true">
                                                                <i class="fa fa-times"></i>
                                                            </button>
                                                            <div className='flex items-center m-0'>
                                                                &nbsp;<p className='m-0'>You do not have sufficient ITC balance in Central tax to utilize or pay your total liabilities</p>
                                                            </div>
                                                        </div>
                                                    }
                                                    {
                                                        isITCHighErr === "sgst" && <div className="alert alert-msg alert-danger ng-hide" data-ng-show="gstr3bErrorMsg">
                                                            <button type="button" className="close" aria-hidden="true">
                                                                <i class="fa fa-times"></i>
                                                            </button>
                                                            <div className='flex items-center m-0'>
                                                                &nbsp;<p className='m-0'>You do not have sufficient ITC balance in State tax to utilize or pay your total liabilities</p>
                                                            </div>
                                                        </div>
                                                    }

                                                    <div class="alert alert-msg alert-info alert-dismissible mar-t-5 mar-b-5">
                                                        <button type="button" class="close" data-dismiss="alert" aria-hidden="true">
                                                            <i class="fa fa-times"></i>
                                                        </button>
                                                        <i class="fa fa-info-circle"></i> The cash available as on date and ITC available (considering ITC of current tax period) are shown in this table.
                                                    </div>

                                                    <div class="table-responsive">
                                                        <table class="table tbl inv exp table-bordered">
                                                            <thead>
                                                                <tr>
                                                                    <th class="text-center" rowspan="2" data-ng-bind="trans.LBL_DESCRIPTION">Description</th>
                                                                    <th class="text-center" colspan="5" data-ng-bind="trans.LBL_GSTR3B_CASH_LDG_BAL">Cash Ledger Balance</th>
                                                                    <th class="text-center" colspan="5" data-ng-bind="trans.LBL_GSTR3B_CREDIT_LDG_BAL">Credit Ledger Balance(including current month's credit)</th>
                                                                </tr>
                                                                <tr>
                                                                    <th class="text-center" data-ng-bind="trans.LBL_GST3B_ITAX">Integrated Tax (₹)</th>
                                                                    <th class="text-center" data-ng-bind="trans.LBL_GST3B_CTAX">Central Tax (₹)</th>
                                                                    <th class="text-center" data-ng-bind="trans.LBL_GST3B_STAX">State/UT Tax (₹)</th>
                                                                    <th class="text-center" data-ng-bind="trans.LBL_GST3B_CS">CESS (₹)</th>
                                                                    <th class="text-center" data-ng-bind="trans.LBL_TOTAL_RS">Total (₹)</th>

                                                                    <th class="text-center" data-ng-bind="trans.LBL_GST3B_ITAX">Integrated Tax (₹)</th>
                                                                    <th class="text-center" data-ng-bind="trans.LBL_GST3B_CTAX">Central Tax (₹)</th>
                                                                    <th class="text-center" data-ng-bind="trans.LBL_GST3B_STAX">State/UT Tax (₹)</th>
                                                                    <th class="text-center" data-ng-bind="trans.LBL_GST3B_CS">CESS (₹)</th>
                                                                    <th class="text-center" data-ng-bind="trans.LBL_TOTAL_RS">Total (₹)</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <th class="text-center" data-ng-bind="trans.LBL_TAX">Tax</th>
                                                                    <td class="text-left" colspan="1">
                                                                        <input class="form-control newinv currency ng-pristine ng-untouched ng-valid ng-not-empty" data-ng-model="tax_pmt.bal.cash_bal.igst.tx" readonly="" assign-zero="" data-formating="currency" value={ledger.data.clb.tax.igst.toFixed(2)} />
                                                                    </td>
                                                                    <td class="text-left" colspan="1">
                                                                        <input class="form-control newinv currency ng-pristine ng-untouched ng-valid ng-not-empty" data-ng-model="tax_pmt.bal.cash_bal.cgst.tx" readonly="" assign-zero="" data-formating="currency" value={ledger.data.clb.tax.cgst.toFixed(2)} />
                                                                    </td>
                                                                    <td class="text-left" colspan="1">
                                                                        <input class="form-control newinv currency ng-pristine ng-untouched ng-valid ng-not-empty" data-ng-model="tax_pmt.bal.cash_bal.sgst.tx" readonly="" assign-zero="" data-formating="currency" value={ledger.data.clb.tax.sgst.toFixed(2)} />
                                                                    </td>
                                                                    <td class="text-left" colspan="1">
                                                                        <input class="form-control newinv currency ng-pristine ng-untouched ng-valid ng-not-empty" data-ng-model="tax_pmt.bal.cash_bal.cess.tx" readonly="" assign-zero="" data-formating="currency" value={ledger.data.clb.tax.cess.toFixed(2)} />
                                                                    </td>
                                                                    <td class="text-left" colspan="1">
                                                                        <input class="form-control newinv currency ng-pristine ng-untouched ng-valid ng-not-empty" data-ng-model="cash_tx_tot_bal" readonly="" assign-zero="" data-formating="currency" value={ledger.data.clb.tax.total.toFixed(2)} />
                                                                    </td>
                                                                    <td class="text-left" colspan="1">
                                                                        <input class="form-control newinv currency ng-pristine ng-untouched ng-valid ng-not-empty" data-ng-model="tax_pmt.bal.itc_bal.igst_bal" readonly="" assign-zero="" data-formating="currency" value={ledger.data.citc.igst.toFixed(2)} />
                                                                    </td>
                                                                    <td class="text-left" colspan="1">
                                                                        <input class="form-control newinv currency ng-pristine ng-untouched ng-valid ng-not-empty" data-ng-model="tax_pmt.bal.itc_bal.cgst_bal" readonly="" assign-zero="" data-formating="currency" value={ledger.data.citc.cgst.toFixed(2)} />
                                                                    </td>
                                                                    <td class="text-left" colspan="1">
                                                                        <input class="form-control newinv currency ng-pristine ng-untouched ng-valid ng-not-empty" data-ng-model="tax_pmt.bal.itc_bal.sgst_bal" readonly="" assign-zero="" data-formating="currency" value={ledger.data.citc.sgst.toFixed(2)} />
                                                                    </td>
                                                                    <td class="text-left" colspan="1">
                                                                        <input class="form-control newinv currency ng-pristine ng-untouched ng-valid ng-not-empty" data-ng-model="tax_pmt.bal.itc_bal.cess_bal" readonly="" assign-zero="" data-formating="currency" value={ledger.data.citc.cess.toFixed(2)} />
                                                                    </td>
                                                                    <td class="text-left" colspan="1">
                                                                        <input class="form-control newinv currency ng-pristine ng-untouched ng-valid ng-not-empty" data-ng-model="itc_tot_bal" readonly="" assign-zero="" data-formating="currency" value={ledger.data.citc.total.toFixed(2)} />
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <th class="text-center" data-ng-bind="trans.LBL_INTEREST">Interest</th>
                                                                    <td class="text-left" colspan="1">
                                                                        <input class="form-control newinv currency ng-pristine ng-untouched ng-valid ng-not-empty" data-ng-model="tax_pmt.bal.cash_bal.igst.intr" readonly="" assign-zero="" data-formating="currency" value={ledger.data.clb.intrest.igst.toFixed(2)} />
                                                                    </td>
                                                                    <td class="text-left" colspan="1">
                                                                        <input class="form-control newinv currency ng-pristine ng-untouched ng-valid ng-not-empty" data-ng-model="tax_pmt.bal.cash_bal.cgst.intr" readonly="" assign-zero="" data-formating="currency" value={ledger.data.clb.intrest.cgst.toFixed(2)} />
                                                                    </td>
                                                                    <td class="text-left" colspan="1">
                                                                        <input class="form-control newinv currency ng-pristine ng-untouched ng-valid ng-not-empty" data-ng-model="tax_pmt.bal.cash_bal.sgst.intr" readonly="" assign-zero="" data-formating="currency" value={ledger.data.clb.intrest.sgst.toFixed(2)} />
                                                                    </td>
                                                                    <td class="text-left" colspan="1">
                                                                        <input class="form-control newinv currency ng-pristine ng-untouched ng-valid ng-not-empty" data-ng-model="tax_pmt.bal.cash_bal.cess.intr" readonly="" assign-zero="" data-formating="currency" value={ledger.data.clb.intrest.cess.toFixed(2)} />
                                                                    </td>
                                                                    <td class="text-left" colspan="1">
                                                                        <input class="form-control newinv currency ng-pristine ng-untouched ng-valid ng-not-empty" data-ng-model="cash_intr_tot_bal" readonly="" assign-zero="" data-formating="currency" value={ledger.data.clb.intrest.total.toFixed(2)} />
                                                                    </td>
                                                                    <td class="gstr3b-blank">
                                                                    </td><td class="gstr3b-blank">
                                                                    </td><td class="gstr3b-blank">
                                                                    </td><td class="gstr3b-blank">
                                                                    </td><td class="gstr3b-blank">
                                                                    </td></tr>
                                                                <tr>
                                                                    <th class="text-center" data-ng-bind="trans.LBL_LATE_FEE">Late Fees</th>
                                                                    <td class="gstr3b-blank">
                                                                    </td><td class="text-left" colspan="1">
                                                                        <input class="form-control newinv currency ng-pristine ng-untouched ng-valid ng-not-empty" data-ng-model="tax_pmt.bal.cash_bal.cgst.fee" readonly="" assign-zero="" data-formating="currency" value={ledger.data.clb.fees.cgst.toFixed(2)} />
                                                                    </td>
                                                                    <td class="text-left" colspan="1">
                                                                        <input class="form-control newinv currency ng-pristine ng-untouched ng-valid ng-not-empty" data-ng-model="tax_pmt.bal.cash_bal.sgst.fee" readonly="" assign-zero="" data-formating="currency" value={ledger.data.clb.fees.sgst.toFixed(2)} />
                                                                    </td>
                                                                    <td class="gstr3b-blank">
                                                                    </td><td class="text-left" colspan="1">
                                                                        <input class="form-control newinv currency ng-pristine ng-untouched ng-valid ng-not-empty" data-ng-model="cash_fee_tot_bal" readonly="" assign-zero="" data-formating="currency" value={(ledger.data.clb.fees.cgst + ledger.data.clb.fees.sgst).toFixed(2)} />
                                                                    </td>
                                                                    <td class="gstr3b-blank">
                                                                    </td><td class="gstr3b-blank">
                                                                    </td><td class="gstr3b-blank">
                                                                    </td><td class="gstr3b-blank">
                                                                    </td><td class="gstr3b-blank">
                                                                    </td></tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                                <div class="rettbl-format">
                                                    <div class="alert alert-msg alert-info alert-dismissible mar-t-5 mar-b-5">
                                                        <button type="button" class="close" data-dismiss="alert" aria-hidden="true">
                                                            <i class="fa fa-times"></i>
                                                        </button>
                                                        <i class="fa fa-info-circle"></i> System has auto-populated “Tax to be paid through ITC” fields with optimum utilization amounts based on provisions of the law relating to credit utilization. However, you may edit the ITC utilization. As you change ITC utilization, the cash to be paid will also get changed. <br /><br /> If available cash balance in Electronic cash ledger is not sufficient to offset the liabilities, additional cash required for paying liability is being reflected in the last column of the Table (Addition cash required). You may create challan for that amount directly by clicking on the “Create Challan” button
                                                    </div>
                                                    <div class="table-responsive" data-ng-if="liabBtn">
                                                        <table class="table tbl inv exp table-bordered">
                                                            <thead>
                                                                <tr>
                                                                    <th class="text-center" rowspan="2" data-ng-bind="trans.LBL_DESCRIPTION">Description</th>
                                                                    <th class="text-center" rowspan="2" data-ng-bind="trans.LBL_REV_CHRG_OTHER+trans.LBL_ONE_BLANKSPACE+trans.LBL_TXPAY_RS">Other than reverse charge Tax payable (₹)</th>
                                                                    <th class="text-center" colspan="4" data-ng-bind="trans.LBL_PAID_THR_ITC">Paid through ITC</th>
                                                                    <th class="text-center" rowspan="2" data-ng-bind="trans.LBL_REV_CHRG_OTHER+trans.LBL_ONE_BLANKSPACE+trans.LBL_TAX_TOPAY_CASH">Other than reverse charge Tax to be paid in Cash(₹)</th>
                                                                    <th class="text-center" rowspan="2" data-ng-bind="trans.LBL_REV_CHRG+trans.LBL_ONE_BLANKSPACE+trans.LBL_TXPAY_RS">Reverse charge Tax payable (₹)</th>
                                                                    <th class="text-center" rowspan="2" data-ng-bind="trans.LBL_REV_CHRG+trans.LBL_ONE_BLANKSPACE+trans.LBL_TAX_TOPAY_CASH">Reverse charge Tax to be paid in Cash(₹)</th>
                                                                    {/* <!--<th class="text-center" rowspan="2" data-ng-bind="trans.LBL_TAXPD_CASH"></th>--> */}
                                                                    <th class="text-center" rowspan="2" data-ng-bind="trans.LBL_INTR_PAYBLE">Interest payable (₹)</th>
                                                                    <th class="text-center" rowspan="2" data-ng-bind="trans.LBL_INTR_TO_PAY_CASH">Interest to be paid in cash (₹)</th>
                                                                    <th class="text-center" rowspan="2" data-ng-bind="trans.LBL_LATEFEE_PAYBLE">Late Fee Payable (₹)</th>
                                                                    <th class="text-center" rowspan="2" data-ng-bind="trans.LBL_LATEFEE_TO_PAY_CASH">Late Fee to be paid in cash (₹)</th>
                                                                    <th class="text-center" rowspan="2" data-ng-bind="trans.LBL_UTILIZABLE_CSH_BAL">Utilizable Cash balance(₹)</th>
                                                                    <th class="text-center" rowspan="2" data-ng-bind="trans.LBL_ADD_CASH_REQ">Additional Cash required(₹)</th>
                                                                </tr>
                                                                <tr>
                                                                    <th class="text-center" data-ng-bind="trans.LBL_GST3B_ITAX">Integrated Tax (₹)</th>
                                                                    <th class="text-center" data-ng-bind="trans.LBL_GST3B_CTAX">Central Tax (₹)</th>
                                                                    <th class="text-center" data-ng-bind="trans.LBL_GST3B_STAX">State/UT Tax (₹)</th>
                                                                    <th class="text-center" data-ng-bind="trans.LBL_GST3B_CS">CESS (₹)</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <td class="text-center">1</td>
                                                                    <td class="text-center">2</td>
                                                                    <td class="text-center">3</td>
                                                                    <td class="text-center">4</td>
                                                                    <td class="text-center">5</td>
                                                                    <td class="text-center">6</td>
                                                                    <td class="text-center">7(2 - 3 - 4 - 5 - 6)</td>
                                                                    <td class="text-center">8</td>
                                                                    <td class="text-center">9</td>
                                                                    <td class="text-center">10</td>
                                                                    <td class="text-center">11</td>
                                                                    <td class="text-center">12</td>
                                                                    <td class="text-center">13</td>
                                                                    <td class="text-center">14</td>
                                                                    <td class="text-center">15</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Integrated Tax</td>
                                                                    <td class="currency newinv">
                                                                        {!isSubmit && <input class="form-control newinv currency" data-ng-model="tax_pmt.bal.cash_bal.igst.tx" readonly="" assign-zero="" data-formating="currency" value={getIGST('A3.1').toFixed(2)} />}
                                                                        {isSubmit && <p>{getIGST('A3.1').toFixed(2)}</p>}
                                                                    </td>
                                                                    <td class={`currency newinv ${getErrorCode('igsta') ? 'has-error' : ""}`}>
                                                                        {
                                                                            !isSubmit && <Tippy content={`Available balance is ${getRIGST()}`} placement='bottom' className='text-center text-sm' arrow={false}>
                                                                                <input class="form-control newinv currency" data-ng-model="tax_pmt.bal.cash_bal.igst.tx" name="igsta" assign-zero="" data-formating="currency" value={setoff.igst.igsta} onChange={(e) => {
                                                                                    const formatValue = getFormatvalue(e.target.value);
                                                                                    e.target.value = formatValue;
                                                                                    handleOnChangeFormB(e, 'igsta');
                                                                                }} />
                                                                            </Tippy>
                                                                        }
                                                                        {isSubmit && <p>{ledger.data.setoff.igst.igsta}</p>}
                                                                    </td>
                                                                    <td class={`currency newinv ${getErrorCode('igstb') ? 'has-error' : ""}`}>
                                                                        {
                                                                            !isSubmit && <Tippy content={`Available balance is ${getRCGST()}`} placement='bottom' className='text-center text-sm' arrow={false}>
                                                                                <input class="form-control newinv currency" data-ng-model="tax_pmt.bal.cash_bal.igst.tx" name="cgsta" assign-zero="" data-formating="currency" value={setoff.cgst.cgsta} onChange={(e) => {
                                                                                    const formatValue = getFormatvalue(e.target.value);
                                                                                    e.target.value = formatValue;
                                                                                    handleOnChangeFormB(e, 'cgsta');
                                                                                }} />
                                                                            </Tippy>
                                                                        }
                                                                        {isSubmit && <p>{ledger.data.setoff.cgst.cgsta}</p>}
                                                                    </td>
                                                                    <td class={`currency newinv ${getErrorCode('igstc') ? 'has-error' : ""}`}>
                                                                        {
                                                                            !isSubmit && <Tippy content={`Available balance is ${getRSGST()}`} placement='bottom' className='text-center text-sm' arrow={false}>
                                                                                <input class="form-control newinv currency" data-ng-model="tax_pmt.bal.cash_bal.igst.tx" name="sgsta" assign-zero="" data-formating="currency" value={setoff.sgst.sgsta} onChange={(e) => {
                                                                                    const formatValue = getFormatvalue(e.target.value);
                                                                                    e.target.value = formatValue;
                                                                                    handleOnChangeFormB(e, 'sgsta');
                                                                                }} />
                                                                            </Tippy>
                                                                        }
                                                                        {isSubmit && <p>{ledger.data.setoff.sgst.sgsta}</p>}
                                                                    </td>
                                                                    <td class="gstr3b-blank"></td>
                                                                    <td class={`currency newinv ${isHighErr === "igst" ? 'has-error' : ""}`}>
                                                                        {!isSubmit && <input class="form-control newinv currency" data-ng-model="tax_pmt.bal.cash_bal.igst.tx" readOnly={true} name="a" assign-zero="" data-formating="currency" value={setoff.bal.bala} />}
                                                                        {isSubmit && <p>{ledger.data.setoff.bal.bala}</p>}
                                                                    </td>
                                                                    <td class="currency newinv">
                                                                        {!isSubmit && <input class="form-control newinv currency ng-pristine ng-untouched ng-valid ng-not-empty" data-ng-model="tax_pmt.bal.cash_bal.igst.tx" readonly="" assign-zero="" data-formating="currency" value="0.00" />}
                                                                        {isSubmit && <p>0.00</p>}
                                                                    </td>
                                                                    <td class="currency newinv">
                                                                        {!isSubmit && <input class="form-control newinv currency ng-pristine ng-untouched ng-valid ng-not-empty" data-ng-model="tax_pmt.bal.cash_bal.igst.tx" readonly="" assign-zero="" data-formating="currency" value="0.00" />}
                                                                        {isSubmit && <p>0.00</p>}
                                                                    </td>
                                                                    {!loading && <td class="currency newinv">
                                                                        {!isSubmit && <input class="form-control newinv currency ng-pristine ng-untouched ng-valid ng-not-empty" data-ng-model="tax_pmt.bal.cash_bal.igst.tx" readonly="" assign-zero="" data-formating="currency" value={getIGST('A51').toFixed(2)} />}
                                                                        {isSubmit && <p>{getIGST('A51').toFixed(2)}</p>}
                                                                    </td>}
                                                                    {!loading && <td class="currency newinv">
                                                                        {!isSubmit && <input class="form-control newinv currency ng-pristine ng-untouched ng-valid ng-not-empty" data-ng-model="tax_pmt.bal.cash_bal.igst.tx" readonly="" assign-zero="" data-formating="currency" value={getIGST('A51').toFixed(2)} />}
                                                                        {isSubmit && <p>{getIGST('A51').toFixed(2)}</p>}
                                                                    </td>}
                                                                    <td class="gstr3b-blank"></td>
                                                                    <td class="gstr3b-blank"></td>
                                                                    {!loading && <td class="text-left newinv" colspan="1">
                                                                        {!isSubmit && <input class="form-control newinv currency ng-pristine ng-untouched ng-valid ng-not-empty" data-ng-model="tax_pmt.bal.cash_bal.igst.tx" readonly="" assign-zero="" data-formating="currency" value={getTotalP('igst')} />}
                                                                        {isSubmit && <p>{getTotalP('igst')}</p>}
                                                                    </td>}
                                                                    {!loading && <td class="text-left newinv" colspan="1">
                                                                        {!isSubmit && <input class="form-control newinv currency ng-pristine ng-untouched ng-valid ng-not-empty" data-ng-model="tax_pmt.bal.cash_bal.igst.tx" readonly="" assign-zero="" data-formating="currency" value={getTotalR('igst')} />}
                                                                        {isSubmit && <p>0.00</p>}
                                                                    </td>}
                                                                </tr>
                                                                <tr>
                                                                    <td>Central Tax</td>
                                                                    <td class="currency newinv">
                                                                        {
                                                                            !isSubmit && <input class="form-control newinv currency ng-pristine ng-untouched ng-valid ng-not-empty" data-ng-model="tax_pmt.bal.cash_bal.igst.tx" readonly="" assign-zero="" data-formating="currency" value={getCGST('A3.1').toFixed(2)} />
                                                                        }
                                                                        {isSubmit && <p>{getCGST('A3.1').toFixed(2)}</p>}
                                                                    </td>
                                                                    <td class={`currency newinv ${getErrorCode('cgsta') ? 'has-error' : ""}`}>
                                                                        {
                                                                            !isSubmit && <Tippy content={`Available balance is ${getRIGST()}`} placement='bottom' className='text-center text-sm' arrow={false}>
                                                                                <input class="form-control newinv currency" data-ng-model="tax_pmt.bal.cash_bal.igst.tx" name="igstb" assign-zero="" data-formating="currency" value={setoff.igst.igstb} onChange={(e) => {
                                                                                    const formatValue = getFormatvalue(e.target.value);
                                                                                    e.target.value = formatValue;
                                                                                    handleOnChangeFormB(e, 'igstb');
                                                                                }} />
                                                                            </Tippy>
                                                                        }
                                                                        {isSubmit && <p>{ledger.data.setoff.igst.igstb}</p>}
                                                                    </td>
                                                                    <td class={`currency newinv ${getErrorCode('cgstb') ? 'has-error' : ""}`}>
                                                                        {
                                                                            !isSubmit && <Tippy content={`Available balance is ${getRCGST()}`} placement='bottom' className='text-center text-sm' arrow={false}>
                                                                                <input class="form-control newinv currency" data-ng-model="tax_pmt.bal.cash_bal.igst.tx" name="cgstb" assign-zero="" data-formating="currency" value={setoff.cgst.cgstb} onChange={(e) => {
                                                                                    const formatValue = getFormatvalue(e.target.value);
                                                                                    e.target.value = formatValue;
                                                                                    handleOnChangeFormB(e, 'cgstb');
                                                                                }} />
                                                                            </Tippy>
                                                                        }
                                                                        {isSubmit && <p>{ledger.data.setoff.cgst.cgstb}</p>}
                                                                    </td>
                                                                    <td class="gstr3b-blank"></td>
                                                                    <td class="gstr3b-blank"></td>
                                                                    <td class={`currency newinv ${isHighErr === "cgst" ? 'has-error' : ""}`}>
                                                                        {!isSubmit && <input class="form-control newinv currency" data-ng-model="tax_pmt.bal.cash_bal.igst.tx" readOnly={true} name="b" data-formating="currency" value={setoff.bal.balb} />}
                                                                        {isSubmit && <p>{ledger.data.setoff.bal.balb}</p>}
                                                                    </td>
                                                                    {!loading && <td class="currency newinv">
                                                                        {!isSubmit && <input class="form-control newinv currency ng-pristine ng-untouched ng-valid ng-not-empty" data-ng-model="tax_pmt.bal.cash_bal.igst.tx" readonly="" assign-zero="" data-formating="currency" value="0.00" />}
                                                                        {isSubmit && <p>0.00</p>}
                                                                    </td>}
                                                                    {!loading && <td class="currency newinv">
                                                                        {!isSubmit && <input class="form-control newinv currency ng-pristine ng-untouched ng-valid ng-not-empty" data-ng-model="tax_pmt.bal.cash_bal.igst.tx" readonly="" assign-zero="" data-formating="currency" value="0.00" />}
                                                                        {isSubmit && <p>0.00</p>}
                                                                    </td>}
                                                                    {!loading && <td class="currency newinv">
                                                                        {!isSubmit && <input class="form-control newinv currency ng-pristine ng-untouched ng-valid ng-not-empty" data-ng-model="tax_pmt.bal.cash_bal.igst.tx" readonly="" assign-zero="" data-formating="currency" value={getCGST('A51A').toFixed(2)} />}
                                                                        {isSubmit && <p>{getCGST('A51A').toFixed(2)}</p>}
                                                                    </td>}
                                                                    {!loading && <td class="currency newinv">
                                                                        {!isSubmit && <input class="form-control newinv currency ng-pristine ng-untouched ng-valid ng-not-empty" data-ng-model="tax_pmt.bal.cash_bal.igst.tx" readonly="" assign-zero="" data-formating="currency" value={getCGST('A51A').toFixed(2)} />}
                                                                        {isSubmit && <p>{getCGST('A51A').toFixed(2)}</p>}
                                                                    </td>}
                                                                    {!loading && <td class="currency newinv">
                                                                        {!isSubmit && <input class="form-control newinv currency ng-pristine ng-untouched ng-valid ng-not-empty" data-ng-model="tax_pmt.bal.cash_bal.igst.tx" readonly="" assign-zero="" data-formating="currency" value={getCGST('A51B').toFixed(2)} />}
                                                                        {isSubmit && <p>{getCGST('A51B').toFixed(2)}</p>}
                                                                    </td>}
                                                                    {!loading && <td class="currency newinv">
                                                                        {!isSubmit && <input class="form-control newinv currency ng-pristine ng-untouched ng-valid ng-not-empty" data-ng-model="tax_pmt.bal.cash_bal.igst.tx" readonly="" assign-zero="" data-formating="currency" value={getCGST('A51B').toFixed(2)} />}
                                                                        {isSubmit && <p>{getCGST('A51B').toFixed(2)}</p>}
                                                                    </td>}
                                                                    {!loading && <td class="text-left" colspan="1">
                                                                        {!isSubmit && <input class="form-control newinv currency ng-pristine ng-untouched ng-valid ng-not-empty" data-ng-model="tax_pmt.bal.cash_bal.igst.tx" readonly="" assign-zero="" data-formating="currency" value={getTotalP('cgst')} />}
                                                                        {isSubmit && <p>{getTotalP('cgst')}</p>}
                                                                    </td>}
                                                                    {!loading && <td class="text-left" colspan="1">
                                                                        {!isSubmit && <input class="form-control newinv currency ng-pristine ng-untouched ng-valid ng-not-empty" data-ng-model="tax_pmt.bal.cash_bal.igst.tx" readonly="" assign-zero="" data-formating="currency" value={getTotalR('cgst')} />}
                                                                        {isSubmit && <p>0.00</p>}
                                                                    </td>}
                                                                </tr>
                                                                <tr>
                                                                    <td>State/UT Tax</td>
                                                                    <td class="currency newinv">
                                                                        {!isSubmit && <input class="form-control newinv currency ng-pristine ng-untouched ng-valid ng-not-empty" data-ng-model="tax_pmt.bal.cash_bal.igst.tx" readonly="" assign-zero="" data-formating="currency" value={getSGST('A3.1').toFixed(2)} />}
                                                                        {isSubmit && <p>{getSGST('A3.1').toFixed(2)}</p>}
                                                                    </td>
                                                                    <td class={`currency newinv ${getErrorCode('sgsta') ? 'has-error' : ""}`}>
                                                                        {
                                                                            !isSubmit && <Tippy content={`Available balance is ${getRIGST()}`} placement='bottom' className='text-center text-sm' arrow={false}>
                                                                                <input class="form-control newinv currency" data-ng-model="tax_pmt.bal.cash_bal.igst.tx" name="igstc" assign-zero="" data-formating="currency" value={setoff.igst.igstc} onChange={(e) => {
                                                                                    const formatValue = getFormatvalue(e.target.value);
                                                                                    e.target.value = formatValue;
                                                                                    handleOnChangeFormB(e, 'igstc');
                                                                                }} />
                                                                            </Tippy>
                                                                        }
                                                                        {isSubmit && <p>{ledger.data.setoff.igst.igstc}</p>}
                                                                    </td>
                                                                    <td class="gstr3b-blank"></td>
                                                                    <td class={`currency newinv ${getErrorCode('sgstb') ? 'has-error' : ""}`}>
                                                                        {
                                                                            !isSubmit && <Tippy content={`Available balance is ${getRSGST()}`} placement='bottom' className='text-center text-sm' arrow={false}>
                                                                                <input class="form-control newinv currency" data-ng-model="tax_pmt.bal.cash_bal.igst.tx" name="sgstb" assign-zero="" data-formating="currency" value={setoff.sgst.sgstb} onChange={(e) => {
                                                                                    const formatValue = getFormatvalue(e.target.value);
                                                                                    e.target.value = formatValue;
                                                                                    handleOnChangeFormB(e, 'sgstb');
                                                                                }} />
                                                                            </Tippy>
                                                                        }
                                                                        {isSubmit && <p>{ledger.data.setoff.sgst.sgstb}</p>}
                                                                    </td>
                                                                    <td class="gstr3b-blank"></td>
                                                                    <td class={`currency newinv ${isHighErr === "sgst" ? 'has-error' : ""}`}>
                                                                        {!isSubmit && <input class="form-control newinv currency" data-ng-model="tax_pmt.bal.cash_bal.igst.tx" readOnly={true} name="c" assign-zero="" data-formating="currency" value={setoff.bal.balc} />}
                                                                        {isSubmit && <p>{ledger.data.setoff.bal.balc}</p>}
                                                                    </td>
                                                                    {!loading && <td class="currency newinv">
                                                                        {!isSubmit && <input class="form-control newinv currency ng-pristine ng-untouched ng-valid ng-not-empty" data-ng-model="tax_pmt.bal.cash_bal.igst.tx" readonly="" assign-zero="" data-formating="currency" value="0.00" />}
                                                                        {isSubmit && <p>0.00</p>}
                                                                    </td>}
                                                                    {!loading && <td class="currency newinv">
                                                                        {!isSubmit && <input class="form-control newinv currency ng-pristine ng-untouched ng-valid ng-not-empty" data-ng-model="tax_pmt.bal.cash_bal.igst.tx" readonly="" assign-zero="" data-formating="currency" value="0.00" />}
                                                                        {isSubmit && <p>0.00</p>}
                                                                    </td>}
                                                                    {!loading && <td class="currency newinv">
                                                                        {!isSubmit && <input class="form-control newinv currency ng-pristine ng-untouched ng-valid ng-not-empty" data-ng-model="tax_pmt.bal.cash_bal.igst.tx" readonly="" assign-zero="" data-formating="currency" value={getSGST('A51A').toFixed(2)} />}
                                                                        {isSubmit && <p>{getSGST('A51A').toFixed(2)}</p>}
                                                                    </td>}
                                                                    {!loading && <td class="currency newinv">
                                                                        {!isSubmit && <input class="form-control newinv currency ng-pristine ng-untouched ng-valid ng-not-empty" data-ng-model="tax_pmt.bal.cash_bal.igst.tx" readonly="" assign-zero="" data-formating="currency" value={getSGST('A51A').toFixed(2)} />}
                                                                        {isSubmit && <p>{getSGST('A51A').toFixed(2)}</p>}
                                                                    </td>}
                                                                    {!loading && <td class="currency newinv">
                                                                        {!isSubmit && <input class="form-control newinv currency ng-pristine ng-untouched ng-valid ng-not-empty" data-ng-model="tax_pmt.bal.cash_bal.igst.tx" readonly="" assign-zero="" data-formating="currency" value={getSGST('A51B').toFixed(2)} />}
                                                                        {isSubmit && <p>{getSGST('A51B').toFixed(2)}</p>}
                                                                    </td>}
                                                                    {!loading && <td class="currency newinv">
                                                                        {!isSubmit && <input class="form-control newinv currency ng-pristine ng-untouched ng-valid ng-not-empty" data-ng-model="tax_pmt.bal.cash_bal.igst.tx" readonly="" assign-zero="" data-formating="currency" value={getSGST('A51B').toFixed(2)} />}
                                                                        {isSubmit && <p>{getSGST('A51B').toFixed(2)}</p>}
                                                                    </td>}
                                                                    {!loading && <td class="text-left newinv" colspan="1">
                                                                        {!isSubmit && <input class="form-control newinv currency ng-pristine ng-untouched ng-valid ng-not-empty" data-ng-model="tax_pmt.bal.cash_bal.igst.tx" readonly="" assign-zero="" data-formating="currency" value={getTotalP('sgst')} />}
                                                                        {isSubmit && <p>{getTotalP('sgst')}</p>}
                                                                    </td>}
                                                                    {!loading && <td class="text-left newinv" colspan="1">
                                                                        {!isSubmit && <input class="form-control newinv currency ng-pristine ng-untouched ng-valid ng-not-empty" data-ng-model="tax_pmt.bal.cash_bal.igst.tx" readonly="" assign-zero="" data-formating="currency" value={getTotalR('sgst')} />}
                                                                        {isSubmit && <p>0.00</p>}
                                                                    </td>}
                                                                </tr>
                                                                <tr>
                                                                    <td>CESS (₹)</td>
                                                                    <td class="currency newinv">
                                                                        {!isSubmit && <input class="form-control newinv currency ng-pristine ng-untouched ng-valid ng-not-empty" data-ng-model="tax_pmt.bal.cash_bal.igst.tx" readonly="" assign-zero="" data-formating="currency" value={getCess('A3.1').toFixed(2)} />}
                                                                        {isSubmit && <p>{getCess('A3.1').toFixed(2)}</p>}
                                                                    </td>
                                                                    <td class="gstr3b-blank"></td>
                                                                    <td class="gstr3b-blank"></td>
                                                                    <td class="gstr3b-blank"></td>
                                                                    <td class="currency newinv">
                                                                        {
                                                                            !isSubmit && <input class="form-control newinv currency" name="itccess" assign-zero="" data-formating="currency" value={setoff.cess} onChange={(e) => {
                                                                                const formatValue = getFormatvalue(e.target.value);
                                                                                e.target.value = formatValue;
                                                                                handleOnChangeForm(e);
                                                                            }} />
                                                                        }
                                                                        {isSubmit && <p>{ledger.data.setoff.cess}</p>}
                                                                    </td>
                                                                    <td class="currency newinv">
                                                                        {!isSubmit && <input class="form-control newinv currency" data-ng-model="tax_pmt.bal.cash_bal.igst.tx" readOnly={true} name="d" assign-zero="" data-formating="currency" value={setoff.bal.bald} />}
                                                                        {isSubmit && <p>{ledger.data.setoff.bal.bald}</p>}
                                                                    </td>
                                                                    <td class="currency newinv">
                                                                        {!isSubmit && <input class="form-control newinv currency ng-pristine ng-untouched ng-valid ng-not-empty" data-ng-model="tax_pmt.bal.cash_bal.igst.tx" readonly="" assign-zero="" data-formating="currency" value="0.00" />}
                                                                        {isSubmit && <p>0.00</p>}
                                                                    </td>
                                                                    <td class="currency newinv">
                                                                        {!isSubmit && <input class="form-control newinv currency ng-pristine ng-untouched ng-valid ng-not-empty" data-ng-model="tax_pmt.bal.cash_bal.igst.tx" readonly="" assign-zero="" data-formating="currency" value="0.00" />}
                                                                        {isSubmit && <p>0.00</p>}
                                                                    </td>
                                                                    {!loading && <td class="currency newinv">
                                                                        {!isSubmit && <input class="form-control newinv currency ng-pristine ng-untouched ng-valid ng-not-empty" data-ng-model="tax_pmt.bal.cash_bal.igst.tx" readonly="" assign-zero="" data-formating="currency" value={getCess('A51').toFixed(2)} />}
                                                                        {isSubmit && <p>{getCess('A51').toFixed(2)}</p>}
                                                                    </td>}
                                                                    {!loading && <td class="currency newinv">
                                                                        {!isSubmit && <input class="form-control newinv currency ng-pristine ng-untouched ng-valid ng-not-empty" data-ng-model="tax_pmt.bal.cash_bal.igst.tx" readonly="" assign-zero="" data-formating="currency" value={getCess('A51').toFixed(2)} />}
                                                                        {isSubmit && <p>{getCess('A51').toFixed(2)}</p>}
                                                                    </td>}
                                                                    <td class="gstr3b-blank"></td>
                                                                    <td class="gstr3b-blank"></td>
                                                                    {!loading && <td class="text-left newinv" colspan="1">
                                                                        {!isSubmit && <input class="form-control newinv currency ng-pristine ng-untouched ng-valid ng-not-empty" data-ng-model="tax_pmt.bal.cash_bal.igst.tx" readonly="" assign-zero="" data-formating="currency" value={getTotalP('cess')} />}
                                                                        {isSubmit && <p>{getTotalP('cess')}</p>}
                                                                    </td>}
                                                                    {!loading && <td class="text-left newinv" colspan="1">
                                                                        {!isSubmit && <input class="form-control newinv currency ng-pristine ng-untouched ng-valid ng-not-empty" data-ng-model="tax_pmt.bal.cash_bal.igst.tx" readonly="" assign-zero="" data-formating="currency" value={getTotalR('cess')} />}
                                                                        {isSubmit && <p>0.00</p>}
                                                                    </td>}
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="btn-toolbar">
                                            <button type="button" class="btn btn-primary pull-right" data-ng-bind="trans.LBL_GSTR3B_PROCEED_TO_FILE" disabled={gstrf.status !== 'submitted'} onClick={() => {
                                                proceedToSubmit();
                                            }}>Proceed to file</button>
                                            <button type="button" class="btn btn-primary pull-right" data-ng-bind="trans.LBL_UTLYZ_ITC_CASH" disabled={gstrf.status === 'submitted' || gstrf.status === 'done'} onClick={() => {
                                                proceedToPayment();
                                            }} >Make payment/Post credit to ledger</button>
                                            <button type="button" class="btn btn-primary pull-right" disabled="disabled">Create Challan</button>
                                            {
                                                gstrf.status !== 'done' && <button type="button" class="btn btn-primary pull-right" data-ng-bind="trans.LBL_GSTR3B_PROCEED_TO_FILE" onClick={() => {
                                                    setIsPrint(true);
                                                    handleGeneratePDFB();
                                                }}>Preview Draft GSTR-3B</button>
                                            }
                                            <button type="button" class="btn btn-primary pull-right" data-ng-bind="trans.LBL_BACK" onClick={() => {
                                                setLoading(true);
                                                gotoPath('gstr3BForm');
                                            }}>Back</button>
                                            <button class="btn btn-primary pull-right" disabled={true}>TAX LIABILITY BREAKUP, AS APPLICABLE</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Dialog open={digWarnningA} onClose={() => setDigWarnningA(false)} className='overflow-y-auto overflow-x-hidden z-50 w-full h-modal md:h-full fixed top-0 left-0 bg-black bg-opacity-40'>
                <div className='w-full h-full fixed z-50 flex items-center justify-center bg-black bg-opacity-60'>
                    <div id="confirmDlg" class="modal fade fade-scale in" role="dialog" style={{ display: 'block', paddingRight: '17px' }}>
                        <div class="modal-dialog sweet">
                            <div class="modal-content">
                                <div class="modal-body">
                                    <div class="m-icon m-warning pulseWarning">
                                        <span class="micon-body pulseWarningIns"></span>
                                        <span class="micon-dot pulseWarningIns"></span>
                                    </div>
                                    <h2>Warning</h2>
                                    <p>WARNING: You are about to agree to credit claim and utilization, as indicated. Relevant amount will be deducted from Electronic Cash and Credit ledgers and accordingly liability will be reduced. Also, amount of credit claimed will be credited to Electronic Credit ledger. Once these entries are made, these can NOT be reversed. Are you sure you want to continue ?</p>
                                </div>
                                <div class="modal-footer">
                                    <a class="btn btn-default" onClick={() => { setDigWarnningA(false) }}>CANCEL</a>
                                    <a class="btn btn-primary" onClick={() => {
                                        setLoading(true);
                                        submitLedger();
                                    }}>PROCEED</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Dialog>
            <Dialog open={digWarnningB} onClose={() => setDigWarnningB(false)} className='overflow-y-auto overflow-x-hidden z-50 w-full h-modal md:h-full fixed top-0 left-0 bg-black bg-opacity-40'>
                <div className='w-full h-full fixed z-50 flex items-center justify-center bg-black bg-opacity-60'>
                    <div id="confirmDlg" class="modal fade fade-scale in" role="dialog" style={{ display: 'block', paddingRight: '17px' }}>
                        <div class="modal-dialog sweet">
                            <div class="modal-content">
                                <div class="modal-body">
                                    <div class="m-icon m-warning pulseWarning">
                                        <span class="micon-body pulseWarningIns"></span>
                                        <span class="micon-dot pulseWarningIns"></span>
                                    </div>
                                    <h2>Warning</h2>
                                    <p>You do not have sufficient balance in Electronic Cash Ledger. Do you want to Create challan?</p>
                                </div>
                                <div class="modal-footer">
                                    <a class="btn btn-default" onClick={() => { setDigWarnningB(false) }}>CANCEL</a>
                                    <a class="btn btn-primary" onClick={() => {
                                        setSetOff(setoff.bal);
                                        if (!getIsErrorInCalc()) {
                                            setLoading(true);
                                            setGSTRPath('create');
                                            gotoPath('gstr3BChallan');
                                        } else {
                                            setDigWarnningB(false)
                                        }
                                    }}>PROCEED</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Dialog>
            <Dialog open={digWarnningC} onClose={() => setDigWarnningC(false)} className='overflow-y-auto overflow-x-hidden z-50 w-full h-modal md:h-full fixed top-0 left-0 bg-black bg-opacity-40'>
                <div className='w-full h-full fixed z-50 flex items-center justify-center bg-black bg-opacity-60'>
                    <div id="confirmDlg" className="modal fade fade-scale in" role="dialog" style={{ display: 'block', paddingRight: '17px' }}>
                        <div class="modal-dialog sweet">
                            <div class="modal-content">
                                <div class="modal-body">
                                    <div className="m-icon m-success animate">
                                        <span className="m-line m-tip animateSuccessTip"></span>
                                        <span className="m-line m-long animateSuccessLong"></span>
                                        <div className="m-placeholder"></div>
                                        <div className="m-fix"></div>
                                    </div>
                                    <h2>Offset Successful</h2>
                                    <p>Liability offset/Credit claim has been done successfully. Kindly file the return through EVC/DSC, by clicking on 'Proceed to File' button.</p>
                                </div>
                                <div class="modal-footer">
                                    <a class="btn btn-primary" onClick={() => { setDigWarnningC(false) }}>OK</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Dialog>
            <div ref={pdfRef}>
                {isPrint && <PanelPreviewPDF setoff={setoff} />}
            </div>
        </>
    );
}