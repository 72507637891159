import React, { useEffect, useRef } from 'react';

import { useRecoilState } from 'recoil';
import { adminAtom } from '../core/config/atoms';

import PageAdminLogin from '../pages/PageAdminLogin';
import PageAdminHome from '../pages/PageAdminHome';
import PageResponsive from '../pages/PageResponsive';

import '../assets/styles/bootstrap.css';
import '../assets/styles/main.css';

export default function PathAdmin() {

    const [isAdmin] = useRecoilState(adminAtom);

    const screenSize = useRef(window.innerWidth);

    useEffect(() => {
        window.addEventListener("resize", () => {
            screenSize.current = window.innerWidth;
        });
        return () => {
            window.removeEventListener("resize", () => {
                screenSize.current = window.innerWidth;
            });
        }
    }, []);

    return (
        <div>
            {
                screenSize.current > 1024 && isAdmin && <PageAdminHome />
            }
            {
                screenSize.current > 1024 && !isAdmin && <PageAdminLogin />
            }
            {
                screenSize.current <= 1024 && <PageResponsive />
            }
        </div>
    )
}
