import APIServiceLookUp from './serviceAPI';
import { API_URL } from './models/AppConstent';

export default class ServiceGSTF {
    lookupService = new APIServiceLookUp();

    async checkGstrDoc(body) {
        var serviceType = "POST";
        var url = `${API_URL}gstf/initDoc`;
        var token = '';
        var apiService = this.lookupService.getAPIService(serviceType);
        var response = await apiService.doCall(url, body, token);
        return response;
    }

    async getGSTFDoc(body) {
        var serviceType = "POST";
        var url = `${API_URL}gstf/getGSTFDoc`;
        var token = '';
        var apiService = this.lookupService.getAPIService(serviceType);
        var response = await apiService.doCall(url, body, token);
        return response;
    }

    async getGSTLedger(body) {
        var serviceType = "POST";
        var url = `${API_URL}gstf/getGSTLedger`;
        var token = '';
        var apiService = this.lookupService.getAPIService(serviceType);
        var response = await apiService.doCall(url, body, token);
        return response;
    }

    async submitLedger(body) {
        var serviceType = "POST";
        var url = `${API_URL}gstf/submitGSTLedger`;
        var token = '';
        var apiService = this.lookupService.getAPIService(serviceType);
        var response = await apiService.doCall(url, body, token);
        return response;
    }

    async creatChllan(body) {
        var serviceType = "POST";
        var url = `${API_URL}gstf/creatChallan`;
        var token = '';
        var apiService = this.lookupService.getAPIService(serviceType);
        var response = await apiService.doCall(url, body, token);
        return response;
    }

    async getChallan(body) {
        var serviceType = "POST";
        var url = `${API_URL}gstf/getChallan`;
        var token = '';
        var apiService = this.lookupService.getAPIService(serviceType);
        var response = await apiService.doCall(url, body, token);
        return response;
    }

    async payChllan(body) {
        var serviceType = "POST";
        var url = `${API_URL}gstf/payChllan`;
        var token = '';
        var apiService = this.lookupService.getAPIService(serviceType);
        var response = await apiService.doCall(url, body, token);
        return response;
    }

    async getChallanByFilter(body) {
        var serviceType = "POST";
        var url = `${API_URL}gstf/getChallanByFilter`;
        var token = '';
        var apiService = this.lookupService.getAPIService(serviceType);
        var response = await apiService.doCall(url, body, token);
        return response;
    }

    async submitGSTRFDoc(body) {
        var serviceType = "POST";
        var url = `${API_URL}gstf/submitGSTRFDoc`;
        var token = '';
        var apiService = this.lookupService.getAPIService(serviceType);
        var response = await apiService.doCall(url, body, token);
        return response;
    }

    async setGSTFDoc(body) {
        var serviceType = "POST";
        var url = `${API_URL}gstf/setGSTFDoc`;
        var token = '';
        var apiService = this.lookupService.getAPIService(serviceType);
        var response = await apiService.doCall(url, body, token);
        return response;
    }

    async saveGSTFDoc(body) {
        var serviceType = "POST";
        var url = `${API_URL}gstf/saveGSTFDoc`;
        var token = '';
        var apiService = this.lookupService.getAPIService(serviceType);
        var response = await apiService.doCall(url, body, token);
        return response;
    }

}