import React, { useState, useEffect } from 'react';

import { useRecoilState } from 'recoil';
import { pathAtom, loadingAtom, trnLevelAtom, trnAtom, digErrorAtom, dirtyAtom, userIDAtom, userAtom } from '../core/config/atoms';

import ServiceAuth from '../services/serviceAuth';
import ServiceUtility from '../services/serviceUtility';

import { useFormik } from 'formik';
import * as Yup from 'yup';

export default function ComponentTRNLevelH() {

    const [path, setPath] = useRecoilState(pathAtom);
    const [loading, setLoading] = useRecoilState(loadingAtom);
    const [trnLevel, SetTRNLevel] = useRecoilState(trnLevelAtom);
    const [trnno] = useRecoilState(trnAtom);
    const [digError, setDigError] = useRecoilState(digErrorAtom);
    const [isDirty, setIsDirty] = useRecoilState(dirtyAtom);
    const [userID] = useRecoilState(userIDAtom);
    const [userAuth] = useRecoilState(userAtom);

    const [gstUser, setGSTUser] = useState({});

    const [isSubmit, setIsSubmit] = useState(false);

    const authService = new ServiceAuth();
    const utilService = new ServiceUtility();

    const formSchema = Yup.object().shape({
        ec_tax: Yup.string().matches(/^[a-zA-Z0-9\_&amp;\'\-\.\/\,()?@!#%$~*;+= ]*[a-zA-Z0-9!#$%&amp;'*+\/=?^_`{|}~-\s]{1,99}$/, { message: 'Please enter a valid information' }),
        rc_tax: Yup.string().matches(/^[a-zA-Z0-9\_&amp;\'\-\.\/\,()?@!#%$~*;+= ]*[a-zA-Z0-9!#$%&amp;'*+\/=?^_`{|}~-\s]{1,99}$/, { message: 'Please enter a valid information' }),
        lic_no: Yup.string().matches(/^[a-zA-Z0-9\_&amp;\'\-\.\/\,()?@!#%$~*;+= ]*[a-zA-Z0-9!#$%&amp;'*+\/=?^_`{|}~-\s]{1,99}$/, { message: 'Please enter a valid information' }),
        per_lic_no: Yup.string().matches(/^[a-zA-Z0-9\_&amp;\'\-\.\/\,()?@!#%$~*;+= ]*[a-zA-Z0-9!#$%&amp;'*+\/=?^_`{|}~-\s]{1,99}$/, { message: 'Please enter a valid information' }),
    });

    const { values, errors, touched, dirty, handleChange, handleSubmit, handleReset, resetForm, setValues } = useFormik({
        enableReinitialize: true,
        initialValues: {
            ec_tax: '',
            rc_tax: '',
            lic_no: '',
            per_lic_no: '',
        },
        validationSchema: formSchema,
        onSubmit: values => {
            setLoading(true);
            setIsSubmit(true);
            let data = [...trnLevel.form];
            data[7] = getClear() ? "0" : "1";
            let body = {
                ec_tax: values.ec_tax,
                rc_tax: values.rc_tax,
                lic_no: values.lic_no,
                per_lic_no: values.per_lic_no,
                trnno: trnno,
                lid: userID,
                statusArr: data,
                user: userAuth,
            };
            authService.setGSTUserFormH(body).then((res) => {
                if (res.statuscode === 200) {
                    SetTRNLevel({ ...trnLevel, level: 'sa9' });
                } else {
                    setDigError({ title: 'Error', msg: 'Server error please try again!', isToggle: true });
                }
                setLoading(false);
            });
        }
    });

    const getClear = () => {
        if (values.ec_tax == "" && values.lic_no == "" && values.per_lic_no == "" && values.rc_tax == "") {
            return true;
        } else {
            return false;
        }
    }

    const gotoPath = (path) => {
        SetTRNLevel({ ...trnLevel, level: path });
    }

    const loadData = () => {
        (async function () {
            setLoading(true);
            var res = await authService.getGSTUserByTRN({ 'trn': trnno, 'stdOwn': userID });
            setGSTUser(res.data[0]);
            if (res.data[0].statusArr[7] === '1') {
                setValues({ ...values, ec_tax: res.data[0]['ec_tax'], rc_tax: res.data[0]['rc_tax'], lic_no: res.data[0]['lic_no'], per_lic_no: res.data[0]['per_lic_no'] });
            }
            SetTRNLevel({ ...trnLevel, form: res.data[0]['statusArr'], percentage: utilService.calculatePercentage(res.data[0]['statusArr']) });
            setLoading(false);
            window.scrollTo({
                top: 0,
                behavior: 'smooth',
            });
        })();
    }

    useEffect(() => {
        setIsDirty(dirty);
    }, [dirty]);

    useEffect(() => {
        loadData();
    }, []);

    return (
        <form class="tabpane" autocomplete="off" onSubmit={handleSubmit} handleReset={handleReset} noValidate="" >
            <h4>State Specific Information</h4>
            <div class="tbl-format">
                <div>
                    <fieldset>
                        <div class="row">
                            <div class="inner">
                                <div class={`col-sm-6 col-xs-12 ${touched.ec_tax ? errors.ec_tax ? 'has-error' : 'has-success' : ''}`}>
                                    <label class="reg" for="ec_tax">Professional Tax Employee Code (EC) No.</label>
                                    <input id="ec_tax" name="ec_tax" class="form-control ng-pristine ng-valid ng-empty ng-valid-pattern ng-valid-maxlength ng-touched" onChange={handleChange} placeholder="Enter Professions Tax E.C Number" maxLength={20} value={values.ec_tax} />
                                    {(errors.ec_tax && touched.ec_tax) && <p class="err">{errors.ec_tax}</p>}
                                </div>
                                <div class={`col-sm-6 col-xs-12 ${touched.rc_tax ? errors.rc_tax ? 'has-error' : 'has-success' : ''}`}>
                                    <label class="reg" for="rc_tax">Professional Tax Registration Certificate (RC) No.</label>
                                    <input id="rc_tax" name="rc_tax" class="form-control ng-pristine ng-untouched ng-valid ng-empty ng-valid-pattern ng-valid-maxlength" onChange={handleChange} placeholder="Enter Professions Tax R.C Number" maxLength={20} value={values.rc_tax} />
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="inner">
                                <div class={`col-sm-6 col-xs-12 ${touched.lic_no ? errors.lic_no ? 'has-error' : 'has-success' : ''}`}>
                                    <label class="reg" for="lic_no">State Excise License No.</label>
                                    <input id="lic_no" name="lic_no" class="form-control ng-pristine ng-untouched ng-valid ng-empty ng-valid-pattern ng-valid-maxlength" onChange={handleChange} placeholder="Enter State Excise License Number" maxlength="20" value={values.lic_no} />
                                </div>
                                <div class={`col-sm-6 col-xs-12 ${touched.per_lic_no ? errors.per_lic_no ? 'has-error' : 'has-success' : ''}`}>
                                    <label class="reg" for="per_lic_no">Name of the person in whose name Excise Licence is held</label>
                                    <input id="per_lic_no" name="per_lic_no" class="form-control ng-pristine ng-untouched ng-valid ng-empty ng-valid-pattern ng-valid-maxlength" onChange={handleChange} placeholder="Enter Name of the Person in whose name Excise License is held" maxLength={99} value={values.per_lic_no} />
                                </div>
                            </div>
                        </div>
                    </fieldset>
                </div>
                <div class="row next-tab-nav">
                    <div class="inner">
                        <div class="col-xs-12 text-right">
                            <a class="btn btn-default" style={{ marginRight: '10px' }} onClick={() => {
                                gotoPath('sa7');
                            }}>Back</a>
                            <button type="submit" class="btn btn-primary" disabled={isSubmit}>Save &amp; Continue</button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    );
}
