import React, { useState, useEffect } from 'react';

import { useRecoilState } from 'recoil';
import { pathAtom, loadingAtom, gstrFileAtom, digErrorAtom } from '../../../core/config/atoms';

import { useFormik } from 'formik';
import * as Yup from 'yup';
import Tippy from '@tippyjs/react';
import Scrollbars from 'react-custom-scrollbars';

import ServiceGSTF from '../../../services/serviceGSTF';

export default function Panel4Home() {

    const [loading, setLoading] = useRecoilState(loadingAtom);
    const [gstrf, setGSTRF] = useRecoilState(gstrFileAtom);

    const [, setPath] = useRecoilState(pathAtom);
    const [, setDigError] = useRecoilState(digErrorAtom);

    const [submit, setSubmit] = useState(false);
    const [isHelp, setIsHelp] = useState(false);

    const fileService = new ServiceGSTF();

    const formVSchema = Yup.object().shape({
        a: Yup.object().shape({
            rowa: Yup.object().shape({
                igst: Yup.object().shape({
                    value: Yup.string().required(),
                    isChange: Yup.boolean().required(),
                }).required(),
                cess: Yup.object().shape({
                    value: Yup.string().required(),
                    isChange: Yup.boolean().required(),
                }).required(),
            }),
            rowb: Yup.object().shape({
                igst: Yup.object().shape({
                    value: Yup.string().required(),
                    isChange: Yup.boolean().required(),
                }).required(),
                cess: Yup.object().shape({
                    value: Yup.string().required(),
                    isChange: Yup.boolean().required(),
                }).required(),
            }),
            rowc: Yup.object().shape({
                igst: Yup.object().shape({
                    value: Yup.string().required(),
                    isChange: Yup.boolean().required(),
                }).required(),
                cgst: Yup.object().shape({
                    value: Yup.string().required(),
                    isChange: Yup.boolean().required(),
                }).required(),
                sgst: Yup.object().shape({
                    value: Yup.string().required(),
                    isChange: Yup.boolean().required(),
                }).required(),
                cess: Yup.object().shape({
                    value: Yup.string().required(),
                    isChange: Yup.boolean().required(),
                }).required(),
            }),
            rowd: Yup.object().shape({
                igst: Yup.object().shape({
                    value: Yup.string().required(),
                    isChange: Yup.boolean().required(),
                }).required(),
                cgst: Yup.object().shape({
                    value: Yup.string().required(),
                    isChange: Yup.boolean().required(),
                }).required(),
                sgst: Yup.object().shape({
                    value: Yup.string().required(),
                    isChange: Yup.boolean().required(),
                }).required(),
                cess: Yup.object().shape({
                    value: Yup.string().required(),
                    isChange: Yup.boolean().required(),
                }).required(),
            }),
            rowe: Yup.object().shape({
                igst: Yup.object().shape({
                    value: Yup.string().required(),
                    isChange: Yup.boolean().required(),
                }).required(),
                cgst: Yup.object().shape({
                    value: Yup.string().required(),
                    isChange: Yup.boolean().required(),
                }).required(),
                sgst: Yup.object().shape({
                    value: Yup.string().required(),
                    isChange: Yup.boolean().required(),
                }).required(),
                cess: Yup.object().shape({
                    value: Yup.string().required(),
                    isChange: Yup.boolean().required(),
                }).required(),
            }),
        }).required(),
        b: Yup.object().shape({
            rowa: Yup.object().shape({
                igst: Yup.object().shape({
                    value: Yup.string().required(),
                    isChange: Yup.boolean().required(),
                }).required(),
                cgst: Yup.object().shape({
                    value: Yup.string().required(),
                    isChange: Yup.boolean().required(),
                }).required(),
                sgst: Yup.object().shape({
                    value: Yup.string().required(),
                    isChange: Yup.boolean().required(),
                }).required(),
                cess: Yup.object().shape({
                    value: Yup.string().required(),
                    isChange: Yup.boolean().required(),
                }).required(),
            }),
            rowb: Yup.object().shape({
                igst: Yup.object().shape({
                    value: Yup.string().required(),
                    isChange: Yup.boolean().required(),
                }).required(),
                cgst: Yup.object().shape({
                    value: Yup.string().required(),
                    isChange: Yup.boolean().required(),
                }).required(),
                sgst: Yup.object().shape({
                    value: Yup.string().required(),
                    isChange: Yup.boolean().required(),
                }).required(),
                cess: Yup.object().shape({
                    value: Yup.string().required(),
                    isChange: Yup.boolean().required(),
                }).required(),
            }),
        }).required(),
        d: Yup.object().shape({
            rowa: Yup.object().shape({
                igst: Yup.object().shape({
                    value: Yup.string().required(),
                    isChange: Yup.boolean().required(),
                }).required(),
                cgst: Yup.object().shape({
                    value: Yup.string().required(),
                    isChange: Yup.boolean().required(),
                }).required(),
                sgst: Yup.object().shape({
                    value: Yup.string().required(),
                    isChange: Yup.boolean().required(),
                }).required(),
                cess: Yup.object().shape({
                    value: Yup.string().required(),
                    isChange: Yup.boolean().required(),
                }).required(),
            }),
            rowb: Yup.object().shape({
                igst: Yup.object().shape({
                    value: Yup.string().required(),
                    isChange: Yup.boolean().required(),
                }).required(),
                cgst: Yup.object().shape({
                    value: Yup.string().required(),
                    isChange: Yup.boolean().required(),
                }).required(),
                sgst: Yup.object().shape({
                    value: Yup.string().required(),
                    isChange: Yup.boolean().required(),
                }).required(),
                cess: Yup.object().shape({
                    value: Yup.string().required(),
                    isChange: Yup.boolean().required(),
                }).required(),
            }),
        }).required(),
    });

    const { values, errors, touched, setValues, handleChange, handleSubmit, handleReset, resetForm } = useFormik({
        initialValues: {},
        validationSchema: formVSchema,
        onSubmit: values => {
            setLoading(true);
            setSubmit(true);
            var newObj = JSON.parse(JSON.stringify(gstrf.data));
            newObj.a4.user = { ...values };

            newObj.a4.user.c.igst = parseFloat(getCIGST());
            newObj.a4.user.c.cgst = parseFloat(getCCGST());
            newObj.a4.user.c.sgst = parseFloat(getCSGST());
            newObj.a4.user.c.cess = parseFloat(getCCess());

            var body = {
                id: gstrf._id,
                data: newObj
            };
            fileService.setGSTFDoc(body).then((res) => {
                setLoading(false);
                setSubmit(false);
                if (res.status) {
                    setGSTRF(res.data);
                    gotoPath('gstr3BForm');
                } else {
                    setDigError({ title: 'Error', msg: 'Server error please try again!', isToggle: true });
                    window.scrollTo({
                        top: 0,
                        behavior: 'smooth',
                    });
                }
            });
        }
    });

    const getFormatvalue = (val) => {
        if (!val) return val;
        let number = val.replace(/[^\d.]/g, "");
        if (val.includes(".")) {
            const a = number.split(".");
            if (a[1].length > 2) {
                a[1] = a[1].substring(0, 2);
            }
            if (a[0].length > 13) {
                a[0] = a[0].substring(0, 13);
            }
            number = a[0] + "." + a[1];
        } else {
            if (number.length > 13) {
                number = number.substring(0, 13);
            }
        }
        return number;
    }

    const checkIsChange = (obj) => {
        for (let key in obj) {
            if (typeof obj[key] === "object") {
                if (obj[key].hasOwnProperty("isChange") && obj[key].isChange === true) {
                    return true;
                } else if (checkIsChange(obj[key])) {
                    return true;
                }
            }
        }
        return false;
    }

    const getErrorCustom = (field) => {
        let cls = '';
        switch (field) {
            case 'a.rowa.igst':
                if (values.a.rowa.igst.isChange) {
                    cls = 'has-error';
                }
                break;
            case 'a.rowa.cess':
                if (values.a.rowa.cess.isChange) {
                    cls = 'has-error';
                }
                break;
            case 'a.rowb.igst':
                if (values.a.rowb.igst.isChange) {
                    cls = 'has-error';
                }
                break;
            case 'a.rowb.cess':
                if (values.a.rowb.cess.isChange) {
                    cls = 'has-error';
                }
                break;
            case 'a.rowc.igst':
                if (values.a.rowc.igst.isChange) {
                    cls = 'has-error';
                }
                break;
            case 'a.rowc.cgst':
                if (values.a.rowc.cgst.isChange) {
                    cls = 'has-error';
                }
                break;
            case 'a.rowc.sgst':
                if (values.a.rowc.sgst.isChange) {
                    cls = 'has-error';
                }
                break;
            case 'a.rowc.cess':
                if (values.a.rowc.cess.isChange) {
                    cls = 'has-error';
                }
                break;
            case 'a.rowd.igst':
                if (values.a.rowd.igst.isChange) {
                    cls = 'has-error';
                }
                break;
            case 'a.rowd.cgst':
                if (values.a.rowd.cgst.isChange) {
                    cls = 'has-error';
                }
                break;
            case 'a.rowd.sgst':
                if (values.a.rowd.sgst.isChange) {
                    cls = 'has-error';
                }
                break;
            case 'a.rowd.cess':
                if (values.a.rowd.cess.isChange) {
                    cls = 'has-error';
                }
                break;
            case 'a.rowe.igst':
                if (values.a.rowe.igst.isChange) {
                    cls = 'has-error';
                }
                break;
            case 'a.rowe.cgst':
                if (values.a.rowe.cgst.isChange) {
                    cls = 'has-error';
                }
                break;
            case 'a.rowe.sgst':
                if (values.a.rowe.sgst.isChange) {
                    cls = 'has-error';
                }
                break;
            case 'a.rowe.cess':
                if (values.a.rowe.cess.isChange) {
                    cls = 'has-error';
                }
                break;
            case 'b.rowa.igst':
                if (values.b.rowa.igst.isChange) {
                    cls = 'has-error';
                }
                break;
            case 'b.rowa.cgst':
                if (values.b.rowa.cgst.isChange) {
                    cls = 'has-error';
                }
                break;
            case 'b.rowa.sgst':
                if (values.b.rowa.sgst.isChange) {
                    cls = 'has-error';
                }
                break;
            case 'b.rowa.cess':
                if (values.b.rowa.cess.isChange) {
                    cls = 'has-error';
                }
                break;
            case 'b.rowb.igst':
                if (values.b.rowb.igst.isChange) {
                    cls = 'has-error';
                }
                break;
            case 'b.rowb.cgst':
                if (values.b.rowb.cgst.isChange) {
                    cls = 'has-error';
                }
                break;
            case 'b.rowb.sgst':
                if (values.b.rowb.sgst.isChange) {
                    cls = 'has-error';
                }
                break;
            case 'b.rowb.cess':
                if (values.b.rowb.cess.isChange) {
                    cls = 'has-error';
                }
                break;
            case 'd.rowa.igst':
                if (values.d.rowa.igst.isChange) {
                    cls = 'has-error';
                }
                break;
            case 'd.rowa.cgst':
                if (values.d.rowa.cgst.isChange) {
                    cls = 'has-error';
                }
                break;
            case 'd.rowa.sgst':
                if (values.d.rowa.sgst.isChange) {
                    cls = 'has-error';
                }
                break;
            case 'd.rowa.cess':
                if (values.d.rowa.cess.isChange) {
                    cls = 'has-error';
                }
                break;
            case 'd.rowb.igst':
                if (values.d.rowb.igst.isChange) {
                    cls = 'has-error';
                }
                break;
            case 'd.rowb.cgst':
                if (values.d.rowb.cgst.isChange) {
                    cls = 'has-error';
                }
                break;
            case 'd.rowb.sgst':
                if (values.d.rowb.sgst.isChange) {
                    cls = 'has-error';
                }
                break;
            case 'd.rowb.cess':
                if (values.d.rowb.cess.isChange) {
                    cls = 'has-error';
                }
                break;
        }
        return cls;
    }

    const gotoPath = (path) => {
        setPath(path);
    }

    function convertValuesToFixedDecimal(obj) {
        const newObj = {};

        for (let key in obj) {
            const value = obj[key];

            if (typeof value === 'object' && value !== null) {
                newObj[key] = convertValuesToFixedDecimal(value);
            } else if (typeof value === 'number') {
                newObj[key] = value.toFixed(2);
            } else {
                newObj[key] = value;
            }
        }

        return newObj;
    }

    const loadData = () => {
        var refs = convertValuesToFixedDecimal(gstrf.data.a4.user);
        setValues({ ...values, ...refs });
        setLoading(false);
        if (gstrf.status === "done" || gstrf.status === "submitted") {
            setSubmit(true);
        }
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    }

    const getCIGST = () => {
        let val = 0;
        val = parseFloat(values.a.rowa.igst.value) + parseFloat(values.a.rowb.igst.value) + parseFloat(values.a.rowc.igst.value) + parseFloat(values.a.rowd.igst.value) + parseFloat(values.a.rowe.igst.value) - parseFloat(values.b.rowa.igst.value) - parseFloat(values.b.rowb.igst.value);
        return val.toFixed(2);
    }

    const getCCGST = () => {
        let val = 0;
        val = parseFloat(values.a.rowc.cgst.value) + parseFloat(values.a.rowd.cgst.value) + parseFloat(values.a.rowe.cgst.value) - parseFloat(values.b.rowa.cgst.value) - parseFloat(values.b.rowb.cgst.value);
        return val.toFixed(2);
    }

    const getCSGST = () => {
        let val = 0;
        val = parseFloat(values.a.rowc.sgst.value) + parseFloat(values.a.rowd.sgst.value) + parseFloat(values.a.rowe.sgst.value) - parseFloat(values.b.rowa.sgst.value) - parseFloat(values.b.rowb.sgst.value);
        return val.toFixed(2);
    }

    const getCCess = () => {
        let val = 0;
        val = parseFloat(values.a.rowa.cess.value) + parseFloat(values.a.rowb.cess.value) + parseFloat(values.a.rowc.cess.value) + parseFloat(values.a.rowd.cess.value) + parseFloat(values.a.rowe.cess.value) - parseFloat(values.b.rowa.cess.value) - parseFloat(values.b.rowb.cess.value);
        return val.toFixed(2);
    }

    const callCheack = (e) => {
        const keyArr = e.target.id.split('.');

        let user = { ...values };
        let system = { ...gstrf.data.a4.system };

        let rhs = parseFloat(user[keyArr[0]][keyArr[1]][keyArr[2]][keyArr[3]]);
        let lhs = parseFloat(system[keyArr[0]][keyArr[1]][keyArr[2]][keyArr[3]]);

        let v = {};

        if (keyArr[2] === "cgst" || keyArr[2] === "sgst") {
            if (keyArr[2] === "cgst") {
                user[keyArr[0]][keyArr[1]]["sgst"]['value'] = parseFloat(user[keyArr[0]][keyArr[1]][keyArr[2]]['value']).toFixed(2).toString();
            }
            if (keyArr[2] === "sgst") {
                user[keyArr[0]][keyArr[1]]["cgst"]['value'] = parseFloat(user[keyArr[0]][keyArr[1]][keyArr[2]]['value']).toFixed(2).toString();
            }
        }

        if (lhs < rhs) {
            if (keyArr[2] === "cgst" || keyArr[2] === "sgst") {
                user[keyArr[0]][keyArr[1]]["cgst"]['isChange'] = true;
                user[keyArr[0]][keyArr[1]]["sgst"]['isChange'] = true;
            } else {
                user[keyArr[0]][keyArr[1]][keyArr[2]]['isChange'] = true;
            }
            v = { ...values, ...user };
        } else {
            if (keyArr[2] === "cgst" || keyArr[2] === "sgst") {
                user[keyArr[0]][keyArr[1]]["cgst"]['isChange'] = false;
                user[keyArr[0]][keyArr[1]]["sgst"]['isChange'] = false;
            } else {
                user[keyArr[0]][keyArr[1]][keyArr[2]]['isChange'] = false;
            }
            v = { ...values, ...user };
        }

        setValues({ ...v });
        handleChange(e);
    }

    const callCheackB = (e) => {
        const keyArr = e.target.id.split('.');
        let user = { ...values };
        let v = {};

        if (keyArr[2] === "cgst" || keyArr[2] === "sgst") {
            if (keyArr[2] === "cgst") {
                user[keyArr[0]][keyArr[1]]["sgst"]['value'] = parseFloat(user[keyArr[0]][keyArr[1]][keyArr[2]]['value']).toFixed(2).toString();
            }
            if (keyArr[2] === "sgst") {
                user[keyArr[0]][keyArr[1]]["cgst"]['value'] = parseFloat(user[keyArr[0]][keyArr[1]][keyArr[2]]['value']).toFixed(2).toString();
            }
        }

        v = { ...values, ...user };

        setValues({ ...v });
        handleChange(e);
    }

    useEffect(() => {
        loadData();
    }, []);

    return (
        <>
            {
                isHelp && <div className='w-full h-full overflow-hidden fixed top-0 z-50 flex items-center justify-center bg-black bg-opacity-60'>
                    <Scrollbars>
                        <div className="modal-dialog" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => { setIsHelp(false) }}>
                                        <span aria-hidden="true">×</span>
                                    </button>
                                    <div data-ng-if="gstr3bnegval"><h4 className="modal-title text-[#0b1e59d9]">Eligible ITC (Refer GSTR-2B to arrive on Eligible ITC and Ineligible ITC in a particular period)</h4></div>
                                </div>
                                <div className="modal-body">
                                    <h4 className='text-[#0b1e59d9]'>(A) ITC Available (whether in full or part)</h4>
                                    <p data-ng-if="gstr3bnegval" data-ng-bind="trans.ELGITC3B_NEW_167_GSTR3BNEG">Declare here the details of Net credit including impact of credit notes and their amendments being claimed on inward supplies made during the tax period, further categorized into:</p>
                                    <ol className='pl-16'>
                                        <li className="list-item" data-ng-bind="trans.ELGITC3B_169">Import of goods</li>
                                        <li className="list-item" data-ng-bind="trans.ELGITC3B_170">Import of Services (Should have been declared in 3.1 for tax liability under reverse charge mechanism)</li>
                                        <li className="list-item" data-ng-bind="trans.ELGITC3B_171">Inward supplies on which tax is payable on reverse charge basis (Should have been declared in 3.1 for tax liability on supplies attracting reverse charge)</li>
                                        <li className="list-item" data-ng-bind="trans.ELGITC3B_172">Credit received from ISD (Input Service Distributor)</li>
                                        <li className="list-item" data-ng-bind="trans.ELGITC3B_NEW_173">Any other credit. (This will cover all inward supplies from registered taxpayers on which tax has been charged. Any reclaimable ITC reversed in earlier period shall be re-claimed here and the breakup of reclaimed ITC shall be provided in Table 4(D)1)</li>
                                    </ol>
                                    <h4 className='text-[#0b1e59d9]'>B) ITC reversed</h4>
                                    <p data-ng-bind="trans.ELGITC3B_176">Any reversal of ITC claimed as per applicable rules will be declared in this section and the same will be reduced from the credit as per (A) above.</p>
                                    <ol className='pl-14'>
                                        <b><li className='text-black list-item'>As per rules 38, 42 and 43 of CGST Rules and Section 17(5)</li></b>
                                        <p data-ng-bind="trans.ELGITC3B_NEW_174_I">ITC , which are absolute in nature and are not reclaimable, such as on account of rule 38 (reversal of credit by a banking company or a financial institution), rule 42 (reversal on input and input services on account of supply of exempted goods or services), rule 43 (reversal on capital goods on account of supply of exempted goods or services) of the CGST Rules and ineligible ITC under section 17(5) shall be reported here.</p>
                                        <b><li className='text-black list-item'>Others</li></b>
                                        <p data-ng-bind="trans.ELGITC3B_NEW_174_II">ITC, which are not permanent in nature and can be reclaimed in future subject to fulfilment of specific conditions, such as on account of rule 37 of CGST Rules (non-payment of consideration to supplier within 180 days), section 16(2)(b) and section 16(2)(c) of the CGST Act shall be reported here. Such ITC may be reclaimed in Table 4(A)(5) on fulfilment of necessary conditions. Further, the breakup of all such reclaimed ITC shall also be shown in Table 4(D)(1). Any ITC availed inadvertently in Table 4(A) in previous tax periods due to clerical mistakes or some other inadvertent mistake may also be reversed here.</p>
                                    </ol>
                                    <h4 className='text-[#0b1e59d9]'>C) Net ITC Available (A-B)</h4>
                                    <p data-ng-bind="trans.ELGITC3B_NEW_178">Net ITC Available will be calculated in Table 4 (C) which is as per the formula (4A - [4B (1) + 4B (2)]) and same will be credited to the Electronic Credit Ledger of the registered person.</p>
                                    <h4 className='text-[#0b1e59d9]'>D) Other Details</h4>
                                    <ol className='pl-14'>
                                        <b><li className='text-black list-item'>ITC reclaimed which was reversed under Table 4(B)(2) in earlier tax period</li></b>
                                        <p data-ng-if="gstr3bnegval" data-ng-bind="trans.ELGITC3B_NEW_180_I_GSTR3BNEG">The breakup of amount of reclaimable ITC which was reversed earlier in Table 4(B)2 and now is being reclaimed in Table 4(A)5 of this period, shall be reported in this section.</p>
                                        <b><li className='text-black list-item'>Ineligible ITC under section 16(4) and ITC restricted due to PoS Provisions</li></b>
                                        <p data-ng-bind="trans.ELGITC3B_NEW_180_II">ITC not available, on account of limitation of time period as delineated in subsection (4) of section 16 of the CGST Act or where the recipient of an intra-State supply is located in a different State / UT than that of place of supply, may be reported here. Please ensure it is not availed or reversed in A &amp; B above.</p>
                                    </ol>
                                    <div data-ng-if="gstr3bnegval">
                                        <p className='text-black'><b className='text-black'>Note:</b><span> With effect from January 2023 tax period, table 4A(1),(3),(4),(5) and table 4D(2) have been auto-populated from GSTR-2B.</span></p>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-primary" data-dismiss="modal" data-ng-bind="trans.ELGITC3B_183" onClick={() => { setIsHelp(false) }}>Close</button>
                                </div>
                            </div>
                        </div>
                    </Scrollbars>
                </div>
            }
            <div className="content-wrapper fade-ng-cloak">
                <div className="container">
                    <div className="mypage">
                        <div className="row">
                            <div className="col-xs-10">
                                <div>
                                    <ol className="breadcrumb" data-ng-controller="crumbCtrl">
                                        <li>
                                            <a onClick={() => { gotoPath('gstr') }}>Dashboard</a></li>
                                        <li>
                                            <a onClick={() => { gotoPath('gstRetrun') }}>Returns</a>
                                        </li>
                                        <li>
                                            <a onClick={() => {
                                                setLoading(true);
                                                gotoPath('gstr3BForm')
                                            }}>GSTR-3B</a>
                                        </li>
                                        <li>
                                            <span ng-switch-when="true">Eligible ITC</span>
                                        </li>
                                    </ol>
                                </div>
                            </div>
                            <div className="col-xs-2">
                                <div className="lang dropdown">
                                    <span className="dropdown-toggle" data-toggle="dropdown" data-ng-bind="selectedLang">English</span>
                                    <ul className="dropdown-menu lang-dpdwn">
                                        <li>
                                            <a data-ng-bind="language.nm">English</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        {
                            !loading && <div>
                                <form autocomplete="off" novalidate="" onSubmit={handleSubmit} onReset={handleReset}>
                                    <div className="container" data-ng-init="getElgITCData()">
                                        <div className="row invsumm">
                                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                <div className="pull-left">
                                                    <h4 data-ng-bind="trans.HEAD_ELG_ITC">4. Eligible ITC</h4>
                                                </div>
                                                <div className="pull-right" ng-if="settlementEnabled == true">
                                                    <h4><a data-toggle="modal" onClick={() => { setIsHelp(true) }}>Help</a>
                                                        &nbsp;<i className="fa fa-question-circle" aria-hidden="true"></i>
                                                    </h4>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="tabpane">
                                            <div ng-show="statusMsg &amp;&amp; statusMessage" className="alert alert-msg alert-success alert-dismissible accept">
                                                <button type="button" className="close" data-ng-click="hideAlert()" aria-hidden="true">
                                                    <i className="fa fa-times"></i>
                                                </button>
                                                Last Save request has been processed successfully.
                                            </div>
                                            <div data-ng-if="autoCalc2bFlag || autoCalcQtr2bFlag" className="alert alert-msg alert-info alert-dismissible">
                                                <button type="button" className="close" data-dismiss="alert" aria-hidden="true">
                                                    <i className="fa fa-times"></i>
                                                </button>
                                                <i className="fa fa-info-circle"></i>
                                                <span> Tables 4(A)(1), (3), (4), (5) and 4(B)(2) are auto-drafted based on the data in GSTR-2B.
                                                </span>
                                            </div>
                                            {
                                                checkIsChange(values) && <div className="alert alert-msg alert-danger ng-hide" data-ng-show="gstr3bErrorMsg">
                                                    <div className='flex items-center m-0'>
                                                        <i class="fa fa-warning"></i>
                                                        &nbsp;<p className='m-0'>For table 4 - The input tax credit values have been auto-populated on the basis of the GSTR-2B generated for you. However, the values entered by you is at variance with the auto-populated data in table 4A. Please note that upward variance with the auto-populated input tax credit values is in contravention to Rule 36(4) of the CGST Rules, 2017.</p>
                                                    </div>
                                                </div>
                                            }
                                            <div className="row">
                                                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                    <div className="rettbl-format">
                                                        <div className="table-responsive">
                                                            <table className="table tbl inv exp table-bordered">
                                                                <thead>
                                                                    <tr>
                                                                        <th className="text-center" data-ng-bind="trans.HEAD_DETAILS">Details</th>
                                                                        <th className="text-center" data-ng-bind="trans.LBL_GST3B_ITAX">Integrated Tax (₹)</th>
                                                                        <th className="text-center" data-ng-bind="trans.LBL_GST3B_CTAX">Central Tax (₹)</th>
                                                                        <th className="text-center" data-ng-bind="trans.LBL_GST3B_STAX">State/UT Tax (₹)</th>
                                                                        <th className="text-center" data-ng-bind="trans.LBL_GST3B_CS">CESS (₹)</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr>
                                                                        <th><p className="text-left m-0 p-0">(A) ITC Available (whether in full or part)</p></th>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>
                                                                            <span>(1) Import of goods</span>
                                                                        </td>
                                                                        <td>
                                                                            <Tippy content={<span style={{ border: '1px solid black' }} className="bg-white px-4 py-2">Your amount: ₹{values.a.rowa.igst.value} System computed: ₹{gstrf.data.a4.system.a.rowa.igst.value.toFixed(2)}</span>} placement='bottom' maxWidth={400} className='text-center bg-transparent text-lg text-black w-[400px]' arrow={false}>
                                                                                <div className={getErrorCustom('a.rowa.igst')}>
                                                                                    <input name="a.rowa.igst.value" id="a.rowa.igst.value" value={values.a.rowa.igst.value} onChange={e => {
                                                                                        const formatValue = getFormatvalue(e.target.value);
                                                                                        e.target.value = formatValue;
                                                                                        handleChange(e);
                                                                                    }} onBlur={e => {
                                                                                        if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                            e.target.value = e.target.value + ".00";
                                                                                        }
                                                                                        callCheack(e);
                                                                                    }} className="form-control formedit newinv currency" disabled={gstrf.status === "done" || gstrf.status === "submitted"} />
                                                                                </div>
                                                                            </Tippy>
                                                                        </td>
                                                                        <td className="gstr3b-blank"></td>
                                                                        <td className="gstr3b-blank"></td>
                                                                        <td>
                                                                            <Tippy content={<span style={{ border: '1px solid black' }} className="bg-white px-4 py-2">Your amount: ₹{values.a.rowa.cess.value} System computed: ₹{gstrf.data.a4.system.a.rowa.cess.value.toFixed(2)}</span>} placement='bottom' maxWidth={400} className='text-center bg-transparent text-lg text-black w-[400px]' arrow={false}>
                                                                                <div className={getErrorCustom('a.rowa.cess')}>
                                                                                    <input name="a.rowa.cess.value" id="a.rowa.cess.value" value={values.a.rowa.cess.value} onChange={e => {
                                                                                        const formatValue = getFormatvalue(e.target.value);
                                                                                        e.target.value = formatValue;
                                                                                        handleChange(e);
                                                                                    }} onBlur={e => {
                                                                                        if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                            e.target.value = e.target.value + ".00";
                                                                                        }
                                                                                        callCheack(e);
                                                                                    }} className="form-control formedit newinv currency" disabled={gstrf.status === "done" || gstrf.status === "submitted"} />
                                                                                </div>
                                                                            </Tippy>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>
                                                                            <span ng-switch-when="IMPS">(2) Import of services</span>
                                                                        </td>
                                                                        <td>
                                                                            <Tippy content={<span style={{ border: '1px solid black' }} className="bg-white px-4 py-2">Your amount: ₹{values.a.rowb.igst.value} System computed: ₹{gstrf.data.a4.system.a.rowb.igst.value.toFixed(2)}</span>} placement='bottom' maxWidth={400} className='text-center bg-transparent text-lg text-black w-[400px]' arrow={false}>
                                                                                <div className={getErrorCustom('a.rowb.igst')}>
                                                                                    <input name="a.rowb.igst.value" id="a.rowb.igst.value" value={values.a.rowb.igst.value} onChange={e => {
                                                                                        const formatValue = getFormatvalue(e.target.value);
                                                                                        e.target.value = formatValue;
                                                                                        handleChange(e);
                                                                                    }} onBlur={e => {
                                                                                        if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                            e.target.value = e.target.value + ".00";
                                                                                        }
                                                                                        callCheack(e);
                                                                                    }} className="form-control formedit newinv currency" disabled={gstrf.status === "done" || gstrf.status === "submitted"} />
                                                                                </div>
                                                                            </Tippy>
                                                                        </td>
                                                                        <td className="gstr3b-blank"></td>
                                                                        <td className="gstr3b-blank"></td>
                                                                        <td>
                                                                            <Tippy content={<span style={{ border: '1px solid black' }} className="bg-white px-4 py-2">Your amount: ₹{values.a.rowb.cess.value} System computed: ₹{gstrf.data.a4.system.a.rowb.cess.value.toFixed(2)}</span>} placement='bottom' maxWidth={400} className='text-center bg-transparent text-lg text-black w-[400px]' arrow={false}>
                                                                                <div className={getErrorCustom('a.rowb.cess')}>
                                                                                    <input name="a.rowb.cess.value" id="a.rowb.cess.value" value={values.a.rowb.cess.value} onChange={e => {
                                                                                        const formatValue = getFormatvalue(e.target.value);
                                                                                        e.target.value = formatValue;
                                                                                        handleChange(e);
                                                                                    }} onBlur={e => {
                                                                                        if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                            e.target.value = e.target.value + ".00";
                                                                                        }
                                                                                        callCheack(e);
                                                                                    }} className="form-control formedit newinv currency" disabled={gstrf.status === "done" || gstrf.status === "submitted"} />
                                                                                </div>
                                                                            </Tippy>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>
                                                                            <span>(3) Inward supplies liable to reverse charge (other than 1 &amp; 2 above)</span>
                                                                        </td>
                                                                        <td>
                                                                            <Tippy content={<span style={{ border: '1px solid black' }} className="bg-white px-4 py-2">Your amount: ₹{values.a.rowc.igst.value} System computed: ₹{gstrf.data.a4.system.a.rowc.igst.value.toFixed(2)}</span>} placement='bottom' maxWidth={400} className='text-center bg-transparent text-lg text-black w-[400px]' arrow={false}>
                                                                                <div className={getErrorCustom('a.rowc.igst')}>
                                                                                    <input name="a.rowc.igst.value" id="a.rowc.igst.value" value={values.a.rowc.igst.value} onChange={e => {
                                                                                        const formatValue = getFormatvalue(e.target.value);
                                                                                        e.target.value = formatValue;
                                                                                        handleChange(e);
                                                                                    }} onBlur={e => {
                                                                                        if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                            e.target.value = e.target.value + ".00";
                                                                                        }
                                                                                        callCheack(e);
                                                                                    }} className="form-control formedit newinv currency" disabled={gstrf.status === "done" || gstrf.status === "submitted"} />
                                                                                </div>
                                                                            </Tippy>
                                                                        </td>
                                                                        <td>
                                                                            <Tippy content={<span style={{ border: '1px solid black' }} className="bg-white px-4 py-2">Your amount: ₹{values.a.rowc.cgst.value} System computed: ₹{gstrf.data.a4.system.a.rowc.cgst.value.toFixed(2)}</span>} placement='bottom' maxWidth={400} className='text-center bg-transparent text-lg text-black w-[400px]' arrow={false}>
                                                                                <div className={getErrorCustom('a.rowc.cgst')}>
                                                                                    <input name="a.rowc.cgst.value" id="a.rowc.cgst.value" value={values.a.rowc.cgst.value} onChange={e => {
                                                                                        const formatValue = getFormatvalue(e.target.value);
                                                                                        e.target.value = formatValue;
                                                                                        handleChange(e);
                                                                                    }} onBlur={e => {
                                                                                        if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                            e.target.value = e.target.value + ".00";
                                                                                        }
                                                                                        callCheack(e);
                                                                                    }} className="form-control formedit newinv currency" disabled={gstrf.status === "done" || gstrf.status === "submitted"} />
                                                                                </div>
                                                                            </Tippy>
                                                                        </td>
                                                                        <td>
                                                                            <Tippy content={<span style={{ border: '1px solid black' }} className="bg-white px-4 py-2">Your amount: ₹{values.a.rowc.sgst.value} System computed: ₹{gstrf.data.a4.system.a.rowc.sgst.value.toFixed(2)}</span>} placement='bottom' maxWidth={400} className='text-center bg-transparent text-lg text-black w-[400px]' arrow={false}>
                                                                                <div className={getErrorCustom('a.rowc.sgst')}>
                                                                                    <input name="a.rowc.sgst.value" id="a.rowc.sgst.value" value={values.a.rowc.sgst.value} onChange={e => {
                                                                                        const formatValue = getFormatvalue(e.target.value);
                                                                                        e.target.value = formatValue;
                                                                                        handleChange(e);
                                                                                    }} onBlur={e => {
                                                                                        if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                            e.target.value = e.target.value + ".00";
                                                                                        }
                                                                                        callCheack(e);
                                                                                    }} className="form-control formedit newinv currency" disabled={gstrf.status === "done" || gstrf.status === "submitted"} />
                                                                                </div>
                                                                            </Tippy>
                                                                        </td>
                                                                        <td>
                                                                            <Tippy content={<span style={{ border: '1px solid black' }} className="bg-white px-4 py-2">Your amount: ₹{values.a.rowc.cess.value} System computed: ₹{gstrf.data.a4.system.a.rowc.cess.value.toFixed(2)}</span>} placement='bottom' maxWidth={400} className='text-center bg-transparent text-lg text-black w-[400px]' arrow={false}>
                                                                                <div className={getErrorCustom('a.rowc.cess')}>
                                                                                    <input name="a.rowc.cess.value" id="a.rowc.cess.value" value={values.a.rowc.cess.value} onChange={e => {
                                                                                        const formatValue = getFormatvalue(e.target.value);
                                                                                        e.target.value = formatValue;
                                                                                        handleChange(e);
                                                                                    }} onBlur={e => {
                                                                                        if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                            e.target.value = e.target.value + ".00";
                                                                                        }
                                                                                        callCheack(e);
                                                                                    }} className="form-control formedit newinv currency" disabled={gstrf.status === "done" || gstrf.status === "submitted"} />
                                                                                </div>
                                                                            </Tippy>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>
                                                                            <span>(4) Inward supplies from ISD</span>
                                                                        </td>
                                                                        <td>
                                                                            <Tippy content={<span style={{ border: '1px solid black' }} className="bg-white px-4 py-2">Your amount: ₹{values.a.rowd.igst.value} System computed: ₹{gstrf.data.a4.system.a.rowd.igst.value.toFixed(2)}</span>} placement='bottom' maxWidth={400} className='text-center bg-transparent text-lg text-black w-[400px]' arrow={false}>
                                                                                <div className={getErrorCustom('a.rowd.igst')}>
                                                                                    <input name="a.rowd.igst.value" id="a.rowd.igst.value" value={values.a.rowd.igst.value} onChange={e => {
                                                                                        const formatValue = getFormatvalue(e.target.value);
                                                                                        e.target.value = formatValue;
                                                                                        handleChange(e);
                                                                                    }} onBlur={e => {
                                                                                        if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                            e.target.value = e.target.value + ".00";
                                                                                        }
                                                                                        callCheack(e);
                                                                                    }} className="form-control formedit newinv currency" disabled={gstrf.status === "done" || gstrf.status === "submitted"} />
                                                                                </div>
                                                                            </Tippy>
                                                                        </td>
                                                                        <td>
                                                                            <Tippy content={<span style={{ border: '1px solid black' }} className="bg-white px-4 py-2">Your amount: ₹{values.a.rowd.cgst.value} System computed: ₹{gstrf.data.a4.system.a.rowd.cgst.value.toFixed(2)}</span>} placement='bottom' maxWidth={400} className='text-center bg-transparent text-lg text-black w-[400px]' arrow={false}>
                                                                                <div className={getErrorCustom('a.rowd.cgst')}>
                                                                                    <input name="a.rowd.cgst.value" id="a.rowd.cgst.value" value={values.a.rowd.cgst.value} onChange={e => {
                                                                                        const formatValue = getFormatvalue(e.target.value);
                                                                                        e.target.value = formatValue;
                                                                                        handleChange(e);
                                                                                    }} onBlur={e => {
                                                                                        if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                            e.target.value = e.target.value + ".00";
                                                                                        }
                                                                                        callCheack(e);
                                                                                    }} className="form-control formedit newinv currency" disabled={gstrf.status === "done" || gstrf.status === "submitted"} />
                                                                                </div>
                                                                            </Tippy>
                                                                        </td>
                                                                        <td>
                                                                            <Tippy content={<span style={{ border: '1px solid black' }} className="bg-white px-4 py-2">Your amount: ₹{values.a.rowd.sgst.value} System computed: ₹{gstrf.data.a4.system.a.rowd.sgst.value.toFixed(2)}</span>} placement='bottom' maxWidth={400} className='text-center bg-transparent text-lg text-black w-[400px]' arrow={false}>
                                                                                <div className={getErrorCustom('a.rowd.sgst')}>
                                                                                    <input name="a.rowd.sgst.value" id="a.rowd.sgst.value" value={values.a.rowd.sgst.value} onChange={e => {
                                                                                        const formatValue = getFormatvalue(e.target.value);
                                                                                        e.target.value = formatValue;
                                                                                        handleChange(e);
                                                                                    }} onBlur={e => {
                                                                                        if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                            e.target.value = e.target.value + ".00";
                                                                                        }
                                                                                        callCheack(e);
                                                                                    }} className="form-control formedit newinv currency" disabled={gstrf.status === "done" || gstrf.status === "submitted"} />
                                                                                </div>
                                                                            </Tippy>
                                                                        </td>
                                                                        <td>
                                                                            <Tippy content={<span style={{ border: '1px solid black' }} className="bg-white px-4 py-2">Your amount: ₹{values.a.rowd.cess.value} System computed: ₹{gstrf.data.a4.system.a.rowd.cess.value.toFixed(2)}</span>} placement='bottom' maxWidth={400} className='text-center bg-transparent text-lg text-black w-[400px]' arrow={false}>
                                                                                <div className={getErrorCustom('a.rowd.cess')}>
                                                                                    <input name="a.rowd.cess.value" id="a.rowd.cess.value" value={values.a.rowd.cess.value} onChange={e => {
                                                                                        const formatValue = getFormatvalue(e.target.value);
                                                                                        e.target.value = formatValue;
                                                                                        handleChange(e);
                                                                                    }} onBlur={e => {
                                                                                        if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                            e.target.value = e.target.value + ".00";
                                                                                        }
                                                                                        callCheack(e);
                                                                                    }} className="form-control formedit newinv currency" disabled={gstrf.status === "done" || gstrf.status === "submitted"} />
                                                                                </div>
                                                                            </Tippy>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>
                                                                            <span>(5) All other ITC</span>
                                                                        </td>
                                                                        <td>
                                                                            <Tippy content={<span style={{ border: '1px solid black' }} className="bg-white px-4 py-2">Your amount: ₹{values.a.rowe.igst.value} System computed: ₹{gstrf.data.a4.system.a.rowe.igst.value.toFixed(2)}</span>} placement='bottom' maxWidth={400} className='text-center bg-transparent text-lg text-black w-[400px]' arrow={false}>
                                                                                <div className={getErrorCustom('a.rowe.igst')}>
                                                                                    <input name="a.rowe.igst.value" id="a.rowe.igst.value" value={values.a.rowe.igst.value} onChange={e => {
                                                                                        const formatValue = getFormatvalue(e.target.value);
                                                                                        e.target.value = formatValue;
                                                                                        handleChange(e);
                                                                                    }} onBlur={e => {
                                                                                        if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                            e.target.value = e.target.value + ".00";
                                                                                        }
                                                                                        callCheack(e);
                                                                                    }} className="form-control formedit newinv currency" disabled={gstrf.status === "done" || gstrf.status === "submitted"} />
                                                                                </div>
                                                                            </Tippy>
                                                                        </td>
                                                                        <td>
                                                                            <Tippy content={<span style={{ border: '1px solid black' }} className="bg-white px-4 py-2">Your amount: ₹{values.a.rowe.cgst.value} System computed: ₹{gstrf.data.a4.system.a.rowe.cgst.value.toFixed(2)}</span>} placement='bottom' maxWidth={400} className='text-center bg-transparent text-lg text-black w-[400px]' arrow={false}>
                                                                                <div className={getErrorCustom('a.rowe.cgst')}>
                                                                                    <input name="a.rowe.cgst.value" id="a.rowe.cgst.value" value={values.a.rowe.cgst.value} onChange={e => {
                                                                                        const formatValue = getFormatvalue(e.target.value);
                                                                                        e.target.value = formatValue;
                                                                                        handleChange(e);
                                                                                    }} onBlur={e => {
                                                                                        if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                            e.target.value = e.target.value + ".00";
                                                                                        }
                                                                                        callCheack(e);
                                                                                    }} className="form-control formedit newinv currency" disabled={gstrf.status === "done" || gstrf.status === "submitted"} />
                                                                                </div>
                                                                            </Tippy>
                                                                        </td>
                                                                        <td>
                                                                            <Tippy content={<span style={{ border: '1px solid black' }} className="bg-white px-4 py-2">Your amount: ₹{values.a.rowe.sgst.value} System computed: ₹{gstrf.data.a4.system.a.rowe.sgst.value.toFixed(2)}</span>} placement='bottom' maxWidth={400} className='text-center bg-transparent text-lg text-black w-[400px]' arrow={false}>
                                                                                <div className={getErrorCustom('a.rowe.sgst')}>
                                                                                    <input name="a.rowe.sgst.value" id="a.rowe.sgst.value" value={values.a.rowe.sgst.value} onChange={e => {
                                                                                        const formatValue = getFormatvalue(e.target.value);
                                                                                        e.target.value = formatValue;
                                                                                        handleChange(e);
                                                                                    }} onBlur={e => {
                                                                                        if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                            e.target.value = e.target.value + ".00";
                                                                                        }
                                                                                        callCheack(e);
                                                                                    }} className="form-control formedit newinv currency" disabled={gstrf.status === "done" || gstrf.status === "submitted"} />
                                                                                </div>
                                                                            </Tippy>
                                                                        </td>
                                                                        <td>
                                                                            <Tippy content={<span style={{ border: '1px solid black' }} className="bg-white px-4 py-2">Your amount: ₹{values.a.rowe.cess.value} System computed: ₹{gstrf.data.a4.system.a.rowe.cess.value.toFixed(2)}</span>} placement='bottom' maxWidth={400} className='text-center bg-transparent text-lg text-black w-[400px]' arrow={false}>
                                                                                <div className={getErrorCustom('a.rowe.cess')}>
                                                                                    <input name="a.rowe.cess.value" id="a.rowe.cess.value" value={values.a.rowe.cess.value} onChange={e => {
                                                                                        const formatValue = getFormatvalue(e.target.value);
                                                                                        e.target.value = formatValue;
                                                                                        handleChange(e);
                                                                                    }} onBlur={e => {
                                                                                        if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                            e.target.value = e.target.value + ".00";
                                                                                        }
                                                                                        callCheack(e);
                                                                                    }} className="form-control formedit newinv currency" disabled={gstrf.status === "done" || gstrf.status === "submitted"} />
                                                                                </div>
                                                                            </Tippy>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th><p className="text-left m-0 p-0">(B) ITC Reversed</p></th>
                                                                    </tr>
                                                                    <tr>
                                                                        <td ng-switch="obj.ty">
                                                                            <span ng-switch-when="RUL" ng-show="!settlementEnabled" className="ng-hide">(1) As per Rule 42 &amp; 43 of CGST/SGST rules</span>
                                                                            <span ng-switch-when="RUL" ng-show="settlementEnabled">(1) As per rules 38,42 &amp; 43 of CGST Rules and section 17(5)</span>
                                                                        </td>
                                                                        <td className="">
                                                                            <input name="b.rowa.igst.value" id="b.rowa.igst.value" value={values.b.rowa.igst.value} onChange={e => {
                                                                                const formatValue = getFormatvalue(e.target.value);
                                                                                e.target.value = formatValue;
                                                                                handleChange(e);
                                                                            }} onBlur={e => {
                                                                                if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                    e.target.value = e.target.value + ".00";
                                                                                }
                                                                                callCheackB(e);
                                                                            }} className="form-control formedit newinv currency" disabled={gstrf.status === "done" || gstrf.status === "submitted"} />
                                                                        </td>
                                                                        <td className="">
                                                                            <input name="b.rowa.cgst.value" id="b.rowa.cgst.value" value={values.b.rowa.cgst.value} onChange={e => {
                                                                                const formatValue = getFormatvalue(e.target.value);
                                                                                e.target.value = formatValue;
                                                                                handleChange(e);
                                                                            }} onBlur={e => {
                                                                                if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                    e.target.value = e.target.value + ".00";
                                                                                }
                                                                                callCheackB(e);
                                                                            }} className="form-control formedit newinv currency" disabled={gstrf.status === "done" || gstrf.status === "submitted"} />
                                                                        </td>
                                                                        <td className="">
                                                                            <input name="b.rowa.sgst.value" id="b.rowa.sgst.value" value={values.b.rowa.sgst.value} onChange={e => {
                                                                                const formatValue = getFormatvalue(e.target.value);
                                                                                e.target.value = formatValue;
                                                                                handleChange(e);
                                                                            }} onBlur={e => {
                                                                                if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                    e.target.value = e.target.value + ".00";
                                                                                }
                                                                                callCheackB(e);
                                                                            }} className="form-control formedit newinv currency" disabled={gstrf.status === "done" || gstrf.status === "submitted"} />
                                                                        </td>
                                                                        <td className="">
                                                                            <input name="b.rowa.cess.value" id="b.rowa.cess.value" value={values.b.rowa.cess.value} onChange={e => {
                                                                                const formatValue = getFormatvalue(e.target.value);
                                                                                e.target.value = formatValue;
                                                                                handleChange(e);
                                                                            }} onBlur={e => {
                                                                                if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                    e.target.value = e.target.value + ".00";
                                                                                }
                                                                                callCheackB(e);
                                                                            }} className="form-control formedit newinv currency" disabled={gstrf.status === "done" || gstrf.status === "submitted"} />
                                                                        </td>
                                                                    </tr>
                                                                    <tr ng-repeat="obj in itc_elg.itc_rev" data-ng-if="valid2Brel2rtnprd || valid2BQrel2rtnprd">
                                                                        <td ng-switch="obj.ty">
                                                                            <span ng-switch-when="OTH">(2) Others</span>
                                                                        </td>
                                                                        <td className="">
                                                                            <input name="b.rowb.igst.value" id="b.rowb.igst.value" value={values.b.rowb.igst.value} onChange={e => {
                                                                                const formatValue = getFormatvalue(e.target.value);
                                                                                e.target.value = formatValue;
                                                                                handleChange(e);
                                                                            }} onBlur={e => {
                                                                                if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                    e.target.value = e.target.value + ".00";
                                                                                }
                                                                                callCheackB(e);
                                                                            }} className="form-control formedit newinv currency" disabled={gstrf.status === "done" || gstrf.status === "submitted"} />
                                                                        </td>
                                                                        <td className="">
                                                                            <input name="b.rowb.cgst.value" id="b.rowb.cgst.value" value={values.b.rowb.cgst.value} onChange={e => {
                                                                                const formatValue = getFormatvalue(e.target.value);
                                                                                e.target.value = formatValue;
                                                                                handleChange(e);
                                                                            }} onBlur={e => {
                                                                                if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                    e.target.value = e.target.value + ".00";
                                                                                }
                                                                                callCheackB(e);
                                                                            }} className="form-control formedit newinv currency" disabled={gstrf.status === "done" || gstrf.status === "submitted"} />
                                                                        </td>
                                                                        <td className="">
                                                                            <input name="b.rowb.sgst.value" id="b.rowb.sgst.value" value={values.b.rowb.sgst.value} onChange={e => {
                                                                                const formatValue = getFormatvalue(e.target.value);
                                                                                e.target.value = formatValue;
                                                                                handleChange(e);
                                                                            }} onBlur={e => {
                                                                                if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                    e.target.value = e.target.value + ".00";
                                                                                }
                                                                                callCheackB(e);
                                                                            }} className="form-control formedit newinv currency" disabled={gstrf.status === "done" || gstrf.status === "submitted"} />
                                                                        </td>
                                                                        <td className="">
                                                                            <input name="b.rowb.cess.value" id="b.rowb.cess.value" value={values.b.rowb.cess.value} onChange={e => {
                                                                                const formatValue = getFormatvalue(e.target.value);
                                                                                e.target.value = formatValue;
                                                                                handleChange(e);
                                                                            }} onBlur={e => {
                                                                                if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                    e.target.value = e.target.value + ".00";
                                                                                }
                                                                                callCheackB(e);
                                                                            }} className="form-control formedit newinv currency" disabled={gstrf.status === "done" || gstrf.status === "submitted"} />
                                                                        </td>
                                                                    </tr>
                                                                    <tr ng-init="calculateNetITC()">
                                                                        <th><p className="text-left m-0 p-0">(C) Net ITC Available (A) - (B)</p></th>
                                                                        <td>
                                                                            <input name="c.igst.value" id="c.igst.value" value={getCIGST()} className="form-control formedit newinv currency" disabled={true} />
                                                                        </td>
                                                                        <td>
                                                                            <input name="c.cgst.value" id="c.cgst.value" value={getCCGST()} className="form-control formedit newinv currency" disabled={true} />
                                                                        </td>
                                                                        <td>
                                                                            <input name="c.sgst.value" id="c.sgst.value" value={getCSGST()} className="form-control formedit newinv currency" disabled={true} />
                                                                        </td>
                                                                        <td>
                                                                            <input name="c.cess.value" id="c.cess.value" value={getCCess()} className="form-control formedit newinv currency" disabled={true} />
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th><p className="text-left m-0 p-0">(D) Other Details</p></th>
                                                                    </tr>
                                                                    <tr ng-repeat="obj in itc_elg.itc_inelg">
                                                                        <td ng-switch="obj.ty">
                                                                            <span ng-switch-when="RUL" ng-show="!settlementEnabled" className="ng-hide">(1) As per section 17(5)</span>
                                                                            <span ng-switch-when="RUL" ng-show="settlementEnabled">(1) ITC reclaimed which was reversed under Table 4(B)(2) in earlier tax period</span>
                                                                        </td>
                                                                        <td className="">
                                                                            <input name="d.rowa.igst.value" id="d.rowa.igst.value" value={values.d.rowa.igst.value} onChange={e => {
                                                                                const formatValue = getFormatvalue(e.target.value);
                                                                                e.target.value = formatValue;
                                                                                handleChange(e);
                                                                            }} onBlur={e => {
                                                                                if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                    e.target.value = e.target.value + ".00";
                                                                                }
                                                                                callCheackB(e);
                                                                            }} className="form-control formedit newinv currency" disabled={gstrf.status === "done" || gstrf.status === "submitted"} />
                                                                        </td>
                                                                        <td className="">
                                                                            <input name="d.rowa.cgst.value" id="d.rowa.cgst.value" value={values.d.rowa.cgst.value} onChange={e => {
                                                                                const formatValue = getFormatvalue(e.target.value);
                                                                                e.target.value = formatValue;
                                                                                handleChange(e);
                                                                            }} onBlur={e => {
                                                                                if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                    e.target.value = e.target.value + ".00";
                                                                                }
                                                                                callCheackB(e);
                                                                            }} className="form-control formedit newinv currency" disabled={gstrf.status === "done" || gstrf.status === "submitted"} />
                                                                        </td>
                                                                        <td className="">
                                                                            <input name="d.rowa.sgst.value" id="d.rowa.sgst.value" value={values.d.rowa.sgst.value} onChange={e => {
                                                                                const formatValue = getFormatvalue(e.target.value);
                                                                                e.target.value = formatValue;
                                                                                handleChange(e);
                                                                            }} onBlur={e => {
                                                                                if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                    e.target.value = e.target.value + ".00";
                                                                                }
                                                                                callCheackB(e);
                                                                            }} className="form-control formedit newinv currency" disabled={gstrf.status === "done" || gstrf.status === "submitted"} />
                                                                        </td>
                                                                        <td className="">
                                                                            <input name="d.rowa.cess.value" id="d.rowa.cess.value" value={values.d.rowa.cess.value} onChange={e => {
                                                                                const formatValue = getFormatvalue(e.target.value);
                                                                                e.target.value = formatValue;
                                                                                handleChange(e);
                                                                            }} onBlur={e => {
                                                                                if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                    e.target.value = e.target.value + ".00";
                                                                                }
                                                                                callCheackB(e);
                                                                            }} className="form-control formedit newinv currency" disabled={gstrf.status === "done" || gstrf.status === "submitted"} />
                                                                        </td>
                                                                    </tr>
                                                                    <tr ng-repeat="obj in itc_elg.itc_inelg">
                                                                        <td ng-switch="obj.ty">
                                                                            <span ng-switch-when="OTH" ng-show="!settlementEnabled" className="ng-hide">(2) Others</span>
                                                                            <span ng-switch-when="OTH" ng-show="settlementEnabled">(2) Ineligible ITC under section 16(4) &amp; ITC restricted due to PoS rules</span>
                                                                        </td>
                                                                        <td className="">
                                                                            <Tippy content={<span style={{ border: '1px solid black' }} className="bg-white px-4 py-2">Your amount: ₹{values.d.rowb.igst.value} System computed: ₹{gstrf.data.a4.system.d.rowb.igst.value.toFixed(2)}</span>} placement='bottom' maxWidth={400} className='text-center bg-transparent text-lg text-black w-[400px]' arrow={false}>
                                                                                <div>
                                                                                    <input name="d.rowb.igst.value" id="d.rowb.igst.value" value={values.d.rowb.igst.value} onChange={e => {
                                                                                        const formatValue = getFormatvalue(e.target.value);
                                                                                        e.target.value = formatValue;
                                                                                        handleChange(e);
                                                                                    }} onBlur={e => {
                                                                                        if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                            e.target.value = e.target.value + ".00";
                                                                                        }
                                                                                        callCheackB(e);
                                                                                    }} className="form-control formedit newinv currency" disabled={gstrf.status === "done" || gstrf.status === "submitted"} />
                                                                                </div>
                                                                            </Tippy>
                                                                        </td>
                                                                        <td className="">
                                                                            <Tippy content={<span style={{ border: '1px solid black' }} className="bg-white px-4 py-2">Your amount: ₹{values.d.rowb.cgst.value} System computed: ₹{gstrf.data.a4.system.d.rowb.cgst.value.toFixed(2)}</span>} placement='bottom' maxWidth={400} className='text-center bg-transparent text-lg text-black w-[400px]' arrow={false}>
                                                                                <div>
                                                                                    <input name="d.rowb.cgst.value" id="d.rowb.cgst.value" value={values.d.rowb.cgst.value} onChange={e => {
                                                                                        const formatValue = getFormatvalue(e.target.value);
                                                                                        e.target.value = formatValue;
                                                                                        handleChange(e);
                                                                                    }} onBlur={e => {
                                                                                        if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                            e.target.value = e.target.value + ".00";
                                                                                        }
                                                                                        callCheackB(e);
                                                                                    }} className="form-control formedit newinv currency" disabled={gstrf.status === "done" || gstrf.status === "submitted"} />
                                                                                </div>
                                                                            </Tippy>
                                                                        </td>
                                                                        <td className="">
                                                                            <Tippy content={<span style={{ border: '1px solid black' }} className="bg-white px-4 py-2">Your amount: ₹{values.d.rowb.sgst.value} System computed: ₹{gstrf.data.a4.system.d.rowb.sgst.value.toFixed(2)}</span>} placement='bottom' maxWidth={400} className='text-center bg-transparent text-lg text-black w-[400px]' arrow={false}>
                                                                                <div>
                                                                                    <input name="d.rowb.sgst.value" id="d.rowb.sgst.value" value={values.d.rowb.sgst.value} onChange={e => {
                                                                                        const formatValue = getFormatvalue(e.target.value);
                                                                                        e.target.value = formatValue;
                                                                                        handleChange(e);
                                                                                    }} onBlur={e => {
                                                                                        if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                            e.target.value = e.target.value + ".00";
                                                                                        }
                                                                                        callCheackB(e);
                                                                                    }} className="form-control formedit newinv currency" disabled={gstrf.status === "done" || gstrf.status === "submitted"} />
                                                                                </div>
                                                                            </Tippy>
                                                                        </td>
                                                                        <td className="">
                                                                            <Tippy content={<span style={{ border: '1px solid black' }} className="bg-white px-4 py-2">Your amount: ₹{values.d.rowb.cess.value} System computed: ₹{gstrf.data.a4.system.d.rowb.cess.value.toFixed(2)}</span>} placement='bottom' maxWidth={400} className='text-center bg-transparent text-lg text-black w-[400px]' arrow={false}>
                                                                                <div>
                                                                                    <input name="d.rowb.cess.value" id="d.rowb.cess.value" value={values.d.rowb.cess.value} onChange={e => {
                                                                                        const formatValue = getFormatvalue(e.target.value);
                                                                                        e.target.value = formatValue;
                                                                                        handleChange(e);
                                                                                    }} onBlur={e => {
                                                                                        if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                            e.target.value = e.target.value + ".00";
                                                                                        }
                                                                                        callCheackB(e);
                                                                                    }} className="form-control formedit newinv currency" disabled={gstrf.status === "done" || gstrf.status === "submitted"} />
                                                                                </div>
                                                                            </Tippy>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="btn-toolbar">
                                                <button type="submit" className="btn btn-primary pull-right" disabled={submit}>CONFIRM</button>
                                                <button type="button" className="btn btn-primary pull-right" onClick={() => {
                                                    setLoading(true);
                                                    gotoPath('gstr3BForm')
                                                }}>CANCEL</button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </>
    )
}