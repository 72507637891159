import React, { useState } from 'react';

import { useRecoilState } from 'recoil';
import { pathAtom, redirectAtom, loginAtom, gstrpathAtom } from '../../../core/config/atoms';

export default function Menu() {

    const [path, setPath] = useRecoilState(pathAtom);
    const [isLogin] = useRecoilState(loginAtom);

    const [, setRedirect] = useRecoilState(redirectAtom);
    const [, setGSTRPath] = useRecoilState(gstrpathAtom);

    const [menu, setMenu] = useState("");

    const toggleMenu = (m) => {
        if (m === menu) {
            setMenu("");
        } else {
            setMenu(m);
        }
    }

    const gotoURL = (url) => {
        setRedirect({ url: url, isToggle: true });
    }

    const gotoPath = (pat) => {
        setMenu("");
        setPath(pat);
    }

    return (
        <>
            <nav className="navbar navbar-default">
                <div className="container">
                    <div id="main" className="navbar-collapse">
                        <ul className="nav navbar-nav" link="">
                            <li className={`menuList ${isLogin ? path === 'gstr' ? "active" : "" : path === 'home' ? "active" : ""}`} >
                                {isLogin && <a className="nav_home" onClick={() => { gotoPath('gstr') }}>Dashboard</a>}
                                {!isLogin && <a className="nav_home" onClick={() => { gotoPath('home') }}>Home</a>}
                            </li>
                            <li className={`dropdown drpdwn menuList ${menu === 'services' ? "active open" : ""} ${path === 'demoPage1' ? "active" : ""}`} data-toggle="tooltip" data-placement="top">
                                <a onClick={() => { toggleMenu('services') }} data-toggle="dropdown" role="button" aria-expanded="false">Services <span className="caret"></span></a>
                                <ul className="dropdown-menu smenu" role="menu">
                                    <li className="has-sub" data-ng-hide="udata.utype=='RF'">
                                        <a data-ng-show="!udata || (udata && udata.role == 'panLogin')" data-ng-href="{{servers.GST_SERVICES_R1_URL}}/services/quicklinks/registration">Registration</a>
                                        <ul className="isubmenu serv">
                                            <li>
                                                <a href='javascript:void(0)'>New Registration</a>
                                            </li>
                                            <li>
                                                <a href='javascript:void(0)'>Application for Filing Clarifications</a>
                                            </li>
                                            {
                                                isLogin && <li>
                                                    <a href='javascript:void(0)'>Amendment of Registration Core Fields</a>
                                                </li>
                                            }
                                            {
                                                isLogin && <li>
                                                    <a href='javascript:void(0)'>Amendment of Registration Non - Core Fields</a>
                                                </li>
                                            }
                                            {
                                                isLogin && <li>
                                                    <a href='javascript:void(0)'>Application to Opt for composition Levy</a>
                                                </li>
                                            }
                                            {
                                                isLogin && <li>
                                                    <a href='javascript:void(0)'>Application for Withdrawal from Composition Levy</a>
                                                </li>
                                            }
                                            <li>
                                                <a href='javascript:void(0)'>Track Application Status</a>
                                            </li>
                                            {
                                                isLogin && <li>
                                                    <a href='javascript:void(0)'>Application for Cancellation of Registration</a>
                                                </li>
                                            }
                                            {/* <li data-ng-show="udata && udata.role == 'login' && udata.IS_MIGRATED == 'Y' && udata.appStatus == 'A' && udata.utype!='TR' && udata.utype!='O' &&  udata.utype!='UN' && udata.utype != 'AR' && udata.utype != 'RF'">
                                                <a href="{{servers.GST_REGISTRATION_R1_URL}}/registration/auth/stockintim/stockintimmig">Stock intimation for opting Composition Levy</a>
                                            </li>                                            
                                            <li data-ng-show="udata && udata.role == 'login' && (udata.utype=='NR' || udata.regType=='CA') && udata.utype!='TPCAEX' && udata.utype!='NREX'">
                                                <a href="{{servers.GST_REGISTRATION_R1_URL}}/registration/auth/extnappln/business">Application for Extension of Registration period by Casual/Non Resident Taxable Person</a>
                                            </li>
                                            <li data-ng-show="udata && (udata.role == 'login' && udata.IS_MIGRATED == 'N') && (udata.utype=='TPCAEX' || udata.utype=='NREX')"><a data-ng-href="{{servers.GST_REGISTRATION_R1_URL}}/registration/auth/revoke">Application for Revocation of Cancelled Registration</a>
                                            </li> */}
                                        </ul>
                                    </li>
                                    {
                                        isLogin && <li class="has-sub" >
                                            <a data-ng-href="{{servers.GST_SERVICES_R1_URL}}/services/auth/quicklinks/ledgers">Ledgers</a>
                                            <ul class="isubmenu leg">
                                                <li>
                                                    <a data-ng-href="{{servers.GST_PAYMENTS_R1_URL}}/payment/auth/ledger/cashledger">Electronic Cash Ledger</a>
                                                </li>
                                                <li data-ng-if="udata && ((udatasp.appStatus=='C' || udata.appStatus == 'A' || udata.appStatus == 'E') && (udata.utype== 'NRCN'|| udata.regType== 'TP' || udata.utype== 'NR' ||  udata.utype== 'NREX' ||  udata.regType== 'CO' || udata.regType== 'NT' || udata.regType== 'TPCO' || udata.regType== 'TPCOCN' || udata.regType== 'TPNTCN'))">
                                                    <a target="_self" data-ng-href="{{servers.GST_RETURNS_R1_URL}}/returns/auth/ledger/itcledger">Electronic Credit Ledger</a>
                                                </li>
                                                <li>
                                                    <a data-ng-href="{{servers.GST_RETURNS_R1_URL}}/returns/auth/ledger/taxledger">Electronic Liability Register</a>
                                                </li>
                                                <li data-ng-hide="udata.utype == 'TC'">
                                                    <a data-ng-href="{{servers.GST_PAYMENTS_R1_URL}}/payment/auth/ledger/paymenttowardsdemand">Payment towards Demand</a>
                                                </li>
                                                <li>
                                                    <a target="_self" data-ng-href="{{servers.GST_STATEMENTS_R1_URL}}/ledgersw/auth/ledgers/negliab">Negative liability statement</a>
                                                </li>

                                            </ul>
                                        </li>
                                    }
                                    {/* <li class="has-sub" data-ng-show="(udata && udata.role == 'panLogin' && udata.utype!='UN') && udata.utype != 'RF'">
                                        <a data-ng-href="{{servers.GST_SERVICES_R1_URL}}/services/quicklinks/ledgers">Ledgers</a>
                                        <ul class="isubmenu leg post">
                                            <li>
                                                <a data-ng-href="{{servers.GST_PAYMENTS_R1_URL}}/payment/ledger/cashledger">Electronic Cash Ledger</a>
                                            </li>
                                        </ul>
                                    </li>*/}
                                    {
                                        isLogin && <li class="has-sub" data-ng-show="udata && udata.role == 'login' && udata.utype != 'AR' && udata.utype != 'RF'">
                                            <a>Returns</a>
                                            <ul class="isubmenu ret">
                                                <li>
                                                    <a onClick={() => { gotoPath('gstRetrun') }}>Returns Dashboard</a>
                                                </li>
                                                <li>
                                                    <a data-ng-href="{{servers.GST_RETURNS_R1_URL}}/returns/auth/efiledReturns">View Filed Returns</a>
                                                </li>
                                                <li>
                                                    <a data-ng-href="{{servers.GST_RETURNS_R1_URL}}/returns/auth/trackreturnstatus">Track Return Status</a>
                                                </li>
                                                <li data-ng-if="udata.utype !== 'CO' && udata.utype!='TD' && udata.utype !== 'TS' && udata.utype !== 'O' && udata.utype !== 'UN' &&  udata.utype !='TPCAEX' && udata.utype !='NREX'">
                                                    <a data-ng-href="{{servers.GST_RETURNS_R1_URL}}/returns/auth/transition/trans1" target="_self">Transition Forms</a>
                                                </li>
                                                <li data-ng-hide="udata.utype=='UN' || udata.utype=='TD' || udata.utype == 'O' || udata.utype =='TPCAEX' || udata.utype =='NREX' ">
                                                    <a data-ng-href="{{servers.GST_RETURNS_R1_URL}}/returns2/auth/itcform/links">ITC Forms</a>
                                                </li>
                                                <li data-ng-if="udata.utype == 'TP' && (udata.regType == 'NT' || udata.regType == 'CO'  || udata.regType == 'TP')">
                                                    <a target="_self" data-ng-href="{{servers.GST_RETURNS_R1_URL}}/returns2/auth/annualreturn">Annual Return</a>
                                                </li>
                                                <li data-ng-if="(udata.utype == 'TP' && (udata.regType == 'NT' || udata.regType == 'CA' || udata.regType == 'CO' || udata.regType=='TP')) || (udata.utype == 'TPCAEX' && udata.regType == 'CA') || (udata.utype == 'O')">
                                                    <a target="_self" data-ng-href="{{servers.GST_RETURNS_R1_URL}}/returns2/auth/comptds">TDS and TCS credit received </a>
                                                </li>
                                                <li data-ng-if="udata.utype == 'TP' && (udata.regType == 'NT' || udata.regType == 'CA' || udata.regType == 'CO' || udata.regType=='TP')">
                                                    <a data-ng-href="{{servers.GST_RETURNS_R1_URL}}/returns/auth/comparison">Tax liabilities and ITC comparison</a>
                                                </li>
                                                <li data-ng-if="udata.regType=='NT' || udata.regType=='TP'  || udata.regType =='CO'">
                                                    <a data-ng-click="manageProfile()">Opt-in for Quarterly Return</a>
                                                </li>
                                            </ul>
                                        </li>
                                    }
                                    <li class="has-sub" data-ng-hide="udata.utype == 'RF'">
                                        <a data-ng-show="!udata" data-ng-href="{{servers.GST_SERVICES_R1_URL}}/services/quicklinks/payments">Payments</a>
                                        <ul class="isubmenu pay" data-ng-class="{'post': udata && udata.role == 'login'  && udata.utype!='O' && udata.utype!='UN' && udata.utype!='AR', 'ar': udata && udata.role == 'login' && udata.utype == 'AR', 'pan': udata && udata.role == 'panLogin' && udata.utype!='UN', 'oidr': udata && udata.utype=='O', 'uinu': udata && udata.utype=='UN' && udata.role == 'login','uinu pre': udata && udata.utype=='UN' &&  udata.role == 'panLogin'}">
                                            <li>
                                                <a href='javascript:void(0)'>Create Challan</a>
                                            </li>
                                            {
                                                isLogin && <li>
                                                    <a href='javascript:void(0)'>Saved Challans</a>
                                                </li>
                                            }
                                            {
                                                isLogin && <li>
                                                    <a href='javascript:void(0)' onClick={() => {
                                                        setGSTRPath('history');
                                                        gotoPath('gstr3BChallan');
                                                    }}>Challan History</a>
                                                </li>
                                            }
                                            {
                                                isLogin && <li>
                                                    <a href='javascript:void(0)'>Application for Deferred Payment/Payment in Instalments</a>
                                                </li>
                                            }
                                            {
                                                !isLogin && <li>
                                                    <a href='javascript:void(0)'>Track Payment Status</a>
                                                </li>
                                            }
                                            {
                                                isLogin && <li>
                                                    <a href='javascript:void(0)'>Instalment Calendar</a>
                                                </li>
                                            }
                                            <li>
                                                <a href='javascript:void(0)'>Grievance against Payment(GST PMT-07)</a>
                                            </li>
                                        </ul>
                                    </li>
                                    <li class="has-sub">
                                        <a data-ng-show="!udata" data-ng-href="{{servers.GST_SERVICES_R1_URL}}/services/quicklinks/userservices">User Services</a>
                                        <ul class="isubmenu services" data-ng-class="{'services': !udata,'oth': udata && udata.role == 'login' && udata.utype != 'UN','isubmenu pay post': udata && udata.role == 'login' && udata.utype == 'O','isubmenu ar': udata && udata.role == 'login' && udata.utype == 'AR','isubmenu us uinu': udata  && udata.utype == 'UN' && udata.role == 'login','isubmenu us uinu pre': udata  && udata.utype == 'UN' && udata.role == 'panLogin', 'isubmenu us rf': udata  && udata.utype == 'RF'}">
                                            {
                                                isLogin && <li>
                                                    <a href='javascript:void(0)'>My Saved Applications</a>
                                                </li>
                                            }
                                            {
                                                isLogin && <li>
                                                    <a href='javascript:void(0)'>My Applications</a>
                                                </li>
                                            }
                                            {
                                                isLogin && <li>
                                                    <a href='javascript:void(0)'>View/Download Certificates</a>
                                                </li>
                                            }
                                            {
                                                isLogin && <li>
                                                    <a href='javascript:void(0)'>View Notices and Orders</a>
                                                </li>
                                            }
                                            {
                                                isLogin && <li>
                                                    <a href='javascript:void(0)'>View My Submissions</a>
                                                </li>
                                            }
                                            <li>
                                                <a href='javascript:void(0)'>Search HSN Code</a>
                                            </li>
                                            <li>
                                                <a href='javascript:void(0)'>Holiday List</a>
                                            </li>
                                            {
                                                isLogin && <li>
                                                    <a href='javascript:void(0)'>Feedback</a>
                                                </li>
                                            }
                                            {
                                                isLogin && <li>
                                                    <a href='javascript:void(0)'>Furnish Letter of Undertaking (LUT)</a>
                                                </li>
                                            }
                                            {
                                                isLogin && <li>
                                                    <a href='javascript:void(0)'>View My Submitted LUTs</a>
                                                </li>
                                            }
                                            {
                                                !isLogin && <li>
                                                    <a href='javascript:void(0)'>Generate User ID for Unregistered Applicant</a>
                                                </li>
                                            }
                                            <li>
                                                <a href='javascript:void(0)'>Locate GST Practitioner (GSTP)</a>
                                            </li>
                                            {
                                                isLogin && <li>
                                                    <a href='javascript:void(0)'>Engage / Disengage GST Practitioner (GSTP)</a>
                                                </li>
                                            }
                                            {
                                                isLogin && <li>
                                                    <a href='javascript:void(0)'>ITC02-Pending for action</a>
                                                </li>
                                            }
                                            {
                                                isLogin && <li>
                                                    <a href='javascript:void(0)'>View Additional Notices/Orders</a>
                                                </li>
                                            }
                                            <li>
                                                <a href='javascript:void(0)'>Cause List</a>
                                            </li>
                                            {
                                                isLogin && <li>
                                                    <a href='javascript:void(0)'>Communication Between Taxpayers</a>
                                                </li>
                                            }
                                            {
                                                isLogin && <li>
                                                    <a href='javascript:void(0)'>My Masters</a>
                                                </li>
                                            }
                                            <li>
                                                <a href='javascript:void(0)'>Search BoE</a>
                                            </li>
                                            <li>
                                                <a href='javascript:void(0)'>Search Advance Ruling</a>
                                            </li>
                                            {
                                                isLogin && <li>
                                                    <a href='javascript:void(0)'>View PMT-03A</a>
                                                </li>
                                            }
                                            {
                                                isLogin && <li>
                                                    <a href='javascript:void(0)'>Opting Forward Charge Payment by GTA (Annexure V)</a>
                                                </li>
                                            }
                                        </ul>
                                    </li>
                                    <li class="has-sub" data-ng-hide="udata.utype=='AR'">
                                        <a data-ng-show="!udata" data-ng-href="{{servers.GST_SERVICES_R1_URL}}/services/quicklinks/refunds">Refunds</a>
                                        <ul class="isubmenu ref" data-ng-class="{'isubmenu ref ntp': udata && udata.utype!='UN','isubmenu ref uinu': udata && udata.utype=='UN','isubmenu ref rf': udata && udata.utype == 'RF'}">
                                            {
                                                isLogin && <li>
                                                    <a href='javascript:void(0)'>Application for Refund</a>
                                                </li>
                                            }
                                            {
                                                isLogin && <li>
                                                    <a href='javascript:void(0)'>Refund pre-application form</a>
                                                </li>
                                            }
                                            {
                                                isLogin && <li>
                                                    <a href='javascript:void(0)'>My Saved/Filed Applications</a>
                                                </li>
                                            }
                                            <li class="has-sub">
                                                <a href='javascript:void(0)'>Track Application Status</a>
                                            </li>
                                            {
                                                isLogin && <li>
                                                    <a href='javascript:void(0)'>Track status of invoice data to be shared with ICEGATE</a>
                                                </li>
                                            }
                                            {
                                                isLogin && <li>
                                                    <a href='javascript:void(0)'>Intimation on account of Refund not received</a>
                                                </li>
                                            }
                                        </ul>
                                    </li>
                                    <li class="has-sub" data-ng-hide="udata.utype == 'RF'">
                                        <a href='javascript:void(0)'>e-Way Bill System</a>
                                    </li>
                                    <li class="has-sub">
                                        <a href='javascript:void(0)'>Track Application Status</a>
                                    </li>
                                </ul>
                            </li>
                            <li className="dropdown drpdwn menuList" data-toggle="tooltip" data-placement="top">
                                <a onClick={() => { gotoURL("https://services.gst.gov.in/services/gstlaw/gstlawlist") }} >GST Law </a>
                            </li>
                            <li className={`has-sub drpdwn menuList ${menu === 'download' ? "active open" : ""}`} data-toggle="tooltip" data-placement="top">
                                <a onClick={() => { toggleMenu('download') }} data-toggle="dropdown" role="button" aria-expanded="false">
                                    Downloads <span class="caret"></span>
                                </a>
                                <ul class="dropdown-menu smenu down pre" role="menu">
                                    <li class="has-sub">
                                        <a>Offline Tools</a>
                                        <ul class="isubmenu down">
                                            <li>
                                                <a href='javascript:void(0)'>Returns Offline Tool</a>
                                            </li>
                                            <li>
                                                <a href='javascript:void(0)'>Matching Offline Tool</a>
                                            </li>
                                            <li>
                                                <a href='javascript:void(0)'>Tran-1 Offline Tools</a>
                                            </li>
                                            <li>
                                                <a href='javascript:void(0)'>Tran-2 Offline Tools</a>
                                            </li>
                                            <li>
                                                <a href='javascript:void(0)'>GSTR3B Offline Utility</a>
                                            </li>
                                            <li>
                                                <a href='javascript:void(0)'>ITC01 Offline Tool</a>
                                            </li>
                                            <li>
                                                <a href='javascript:void(0)'>ITC03 Offline Tool</a>
                                            </li>
                                            <li>
                                                <a href='javascript:void(0)'>ITC04 Offline Tool</a>
                                            </li>
                                            <li>
                                                <a href='javascript:void(0)'>GST ARA 01 - Application for Advance Ruling</a>
                                            </li>
                                            <li>
                                                <a href='javascript:void(0)'>GSTR-4 Offline Tool (Quarterly filing)</a>
                                            </li>
                                            <li>
                                                <a href='javascript:void(0)'>GSTR 6 Offline Tool With Amendments</a>
                                            </li>
                                            <li>
                                                <a href='javascript:void(0)'>GSTR 11 Offline Tool</a>
                                            </li>
                                            <li>
                                                <a href='javascript:void(0)'>GSTR7 Offline Utility</a>
                                            </li>
                                            <li>
                                                <a href='javascript:void(0)'>GSTR8 Offline Tool</a>
                                            </li>
                                            <li>
                                                <a href='javascript:void(0)'>GSTR10 Offline Tool</a>
                                            </li>
                                            <li>
                                                <a href='javascript:void(0)'>GSTR-9 Offline Tool</a>
                                            </li>
                                            <li>
                                                <a href='javascript:void(0)'>GSTR-9A Offline Tool</a>
                                            </li>
                                            <li>
                                                <a href='javascript:void(0)'>GSTR-9C Offline Tool</a>
                                            </li>
                                            <li>
                                                <a href='javascript:void(0)'>GSTR-4 Offline Tool (Annual)</a>
                                            </li>
                                            <li>
                                                <a href='javascript:void(0)'>GST DRC-22A - Application for Objection to Provisional Attachment Order</a>
                                            </li>
                                            <li>
                                                <a href='javascript:void(0)'>TDS & TCS Credit Received Offline Tool</a>
                                            </li>
                                        </ul>
                                    </li>
                                    {
                                        isLogin && <li class="has-sub">
                                            <a href='javascript:void(0)'>Accounting and Billing Software</a>
                                        </li>
                                    }
                                    {
                                        !isLogin && <li class="has-sub">
                                            <a href='javascript:void(0)'>GST Statistics</a>
                                        </li>
                                    }
                                </ul>
                            </li>
                            <li className={`dropdown drpdwn menuList ${menu === 'st' ? "active open" : ""} ${path === 'demoPage2' ? "active" : ""}`} data-toggle="tooltip" data-placement="top">
                                <a onClick={() => { toggleMenu('st') }} data-toggle="dropdown" role="button" aria-expanded="false">Search Taxpayer <span class="caret"></span></a>
                                <ul class="dropdown-menu smenu searchtxp" data-ng-class="{'post' : udata && udata.role == 'login' && udata.utype != 'AR', 'ar': udata && udata.role == 'login' && udata.utype == 'AR'}" role="menu" data-ng-show="udata && udata.role == 'login'">
                                    <li class="has-sub">
                                        <a data-ng-href="{{servers.GST_SERVICES_R1_URL}}/services/auth/searchtp">Search by GSTIN/UIN</a>
                                    </li>
                                    <li class="has-sub">
                                        <a data-ng-href="{{servers.GST_SERVICES_R1_URL}}/services/auth/searchtpbypan">Search by PAN</a>
                                    </li>
                                    <li class="has-sub">
                                        <a data-ng-href="{{servers.GST_SERVICES_R1_URL}}/services/auth/searchsmregtp">Search Temporary ID</a>
                                    </li>
                                    <li class="has-sub">
                                        <a data-ng-href="{{servers.GST_SERVICES_R1_URL}}/services/auth/listoftaxpayer">Search Composition Taxpayer</a>
                                    </li>
                                </ul>
                                <ul class="dropdown-menu smenu searchtxp" role="menu" ng-hide="udata && udata.role == 'login'">
                                    <li class="has-sub">
                                        <a data-ng-href="{{servers.GST_SERVICES_R1_URL}}/services/searchtp">Search by GSTIN/UIN</a>
                                    </li>
                                    <li class="has-sub">
                                        <a data-ng-href="{{servers.GST_SERVICES_R1_URL}}/services/searchtpbypan">Search by PAN</a>
                                    </li>
                                    <li class="has-sub">
                                        <a data-ng-href="{{servers.GST_SERVICES_R1_URL}}/services/searchsmregtp">Search Temporary ID</a>
                                    </li>
                                    <li class="has-sub">
                                        <a data-ng-href="{{servers.GST_SERVICES_R1_URL}}/services/listoftaxpayer">Search Composition Taxpayer</a>
                                    </li>
                                </ul>
                            </li>
                            <li class="menuList" data-ng-class="{'active': servers.NAV_COMPONENT == 'help', 'disabled' :udata.disableMenu}" data-toggle="tooltip" data-placement="top">
                                <a onClick={() => { gotoURL("https://services.gst.gov.in/services/HelpandTaxpayer/HelpandTaxpayerfacilities") }}>Help and Taxpayer Facilities</a>
                            </li>
                            <li class="dropdown drpdwn menuList" data-ng-class="{'active': servers.NAV_COMPONENT == 'einvoice', 'disabled' :udata.disableMenu}" data-toggle="tooltip" data-placement="top">
                                <a onClick={() => { gotoURL("https://services.gst.gov.in/services/invoice/einvoice") }}>e-Invoice</a>
                            </li>
                            <li className="mnav"><a data-ng-href="{{servers.GST_SERVICES_R1_URL}}/services/login" data-ng-click="clearPostPP()">Login</a></li>
                            {/* <li class="mnav" data-ng-show="udata"><a data-ng-href="{{servers.GST_SERVICES_R1_URL}}/services/auth/myprofile">MyProfile</a></li>
                            <li class="mnav" data-ng-show="udata"><a data-ng-href="{{servers.GST_SERVICES_R1_URL}}/services/logout" data-ng-click="clearPostPP()">Logout</a></li> */}
                        </ul>
                    </div>
                </div>
            </nav>
        </>
    )
}