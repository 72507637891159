import React, { useState, useEffect } from 'react';

import { useRecoilState } from 'recoil';
import { pathAtom, challanAtom, gstrpathAtom, userIDAtom } from '../../../core/config/atoms';

import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import { ThemeProvider } from "@material-ui/styles";
import { createMuiTheme } from "@material-ui/core";
import DateFnsUtils from '@date-io/date-fns';
import Moment from 'moment';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import ServiceGSTF from '../../../services/serviceGSTF';

export default function PanelHistoryChallan() {

    const [, setChallan] = useRecoilState(challanAtom);
    const [, setPath] = useRecoilState(pathAtom);
    const [, setGSTRPath] = useRecoilState(gstrpathAtom);

    const [userID] = useRecoilState(userIDAtom);

    const [challans, setChallans] = useState([]);

    const [selOption, setSelOption] = useState('');

    const [isCPIn, setIsCPIn] = useState(true);
    const [isPicker, setIsPicker] = useState(false);

    const [mainDate, setMainDate] = useState(Moment().toDate());

    const fileService = new ServiceGSTF();

    const materialTheme = createMuiTheme({
        overrides: {
            MuiTypography: {
                subtitle1: {
                    fontSize: '16px',
                },
                caption: {
                    fontSize: '16px',
                    fontWeight: 'bold'
                },
                body1: {
                    "fontSize": '16px',
                },
                body2: {
                    "fontSize": '16px',
                }
            },
            MuiPickersToolbar: {
                toolbar: {
                    backgroundColor: '#239B56',
                },
            },
            MuiPickersDay: {
                day: {
                    color: '#17202A',
                },
                daySelected: {
                    backgroundColor: '#239B56',
                },
                dayDisabled: {
                    color: '#808B96',
                },
                current: {
                    color: '#239B56',
                },
            },
            MuiButton: {
                textPrimary: {
                    color: '#2E86C1',
                    fontSize: '12px',
                    border: '1px solid #2E86C1',
                    backgroundColor: 'transprent'
                }
            },
        },
    });

    const valA = {
        cpin: '',
    }

    const valB = {
        from: '',
        to: ''
    }

    const formVSchemaA = Yup.object().shape({
        cpin: Yup.string().required('This information is required'),
    });

    const formVSchemaB = Yup.object().shape({
        from: Yup.string().required('This information is required'),
        to: Yup.string().required('This information is required')
    });

    const { values, errors, touched, setValues, handleChange, handleSubmit, handleReset, resetForm } = useFormik({
        initialValues: isCPIn ? valA : valB,
        validationSchema: isCPIn ? formVSchemaA : formVSchemaB,
        enableReinitialize: true,
        onSubmit: values => {
            if (isCPIn) {
                let body = {
                    'lid': userID,
                    'cpin': values.cpin,
                    'status': 'paid',
                };
                fileService.getChallanByFilter(body).then((res) => {
                    setChallans(res.data);
                });
            } else {
                let body = {
                    'lid': userID,
                    'month': {
                        $gte: Moment(values.from, 'DD-MM-YYYY').toISOString(),
                        $lte: Moment(values.to, 'DD-MM-YYYY').toISOString()
                    },
                    'status': 'paid',
                };
                fileService.getChallanByFilter(body).then((res) => {
                    setChallans(res.data);
                });
            }
        }
    })

    const gotoPath = (path) => {
        setPath(path);
    }

    const handleDateChange = (d) => {
        switch (selOption) {
            case 'from':
                setValues({ ...values, from: Moment(d).format('DD-MM-YYYY').toString() });
                break;
            case 'to':
                setValues({ ...values, to: Moment(d).format('DD-MM-YYYY').toString() });
                break;
            default:
                break;
        }
        setSelOption('');
    }

    const openPicker = (id) => {
        setSelOption(id);
        setIsPicker(true);
    }

    const getTotal = (challan) => {
        let tax = challan.data.tax.igst + challan.data.tax.cgst + challan.data.tax.sgst + challan.data.tax.cess;
        let intr = challan.data.interest.igst + challan.data.interest.cgst + challan.data.interest.sgst + challan.data.interest.cess;
        let fees = challan.data.fees.cgst + challan.data.fees.sgst;
        return tax + intr + fees;
    }

    const gotoChallan = (challan) => {
        setChallan(challan);
        setGSTRPath('view');
        gotoPath('gstr3BChallan');
    }

    const loadData = () => {
        (async function () {
            let body = {
                'lid': userID,
                'status': 'paid'
            };
            let res = await fileService.getChallanByFilter(body);
            setChallans(res.data);
            window.scrollTo({
                top: 0,
                behavior: 'smooth',
            });
        })();
    }

    useEffect(() => {
        loadData();
    }, []);

    return (
        <div className="content-wrapper">
            <div className="dimmer-holder" style={{ display: 'none' }}>
                <div id="dimmer"></div>
            </div>
            <div className="container fade-ng-cloak">
                <div className="mypage">
                    <div className="row" data-ng-controller="transctrl" data-ng-init="init('payment')">
                        <div className="col-xs-10">
                            <div data-breadcrumb="" name="Dashboard">
                                <ol className="breadcrumb" data-ng-controller="crumbCtrl">
                                    <li>
                                        <a onClick={() => { gotoPath('gstr') }}>Dashboard</a>
                                    </li>
                                    <li>
                                        <a className='cursor-not-allowed'>Payment</a>
                                    </li>
                                    <li>
                                        Challan History
                                    </li>
                                </ol>
                            </div>
                        </div>
                        <div className="col-xs-2">
                            <div className="lang dropdown">
                                <span className="dropdown-toggle" data-toggle="dropdown" data-ng-bind="selectedLang">English</span>
                                <ul className="dropdown-menu lang-dpdwn">
                                    <li data-ng-repeat="language in languages">
                                        <a data-ng-click="selectLang(language.cd)" data-ng-bind="language.nm">English</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div ng-view="">
                        <div className="tabpane">
                            <ul data-ng-hide="isTrn" className="nav nav-tabs tp-tabs">
                                <li className="col-xs-12 col-sm-4 cursor-not-allowed">
                                    <a><span data-ng-bind="trans.HEAD_CRT_CHALLAN">Create Challan</span></a>
                                </li>
                                <li className="col-xs-12 col-sm-4 cursor-not-allowed">
                                    <a><span data-ng-bind="trans.HEAD_SAV_CHALLAN">Saved Challan</span></a>
                                </li>
                                <li className="col-xs-12 col-sm-4 active">
                                    <a><span data-ng-bind="trans.HEAD_CHLN_HISTRY">Challan History</span></a>
                                </li>
                            </ul>
                            <div className="row">
                                <p></p>
                            </div>
                            <form name="challanRec" novalidate="" id="challanRec" onSubmit={handleSubmit} onReset={handleReset}>
                                <div className="tbl-format inner">
                                    <div className="row">
                                        <p></p>
                                        <div className="col-sm-12 col-xs-12 ">
                                            <input type="radio" name="cpin" id="cpinRadio" checked={isCPIn} value={isCPIn} className="" onChange={(e) => {
                                                handleReset();
                                                setIsCPIn(true);
                                                loadData();
                                            }} />
                                            <label for="cpinRadio" data-ng-bind="trans.LBL_SRC_CPIN">Search By CPIN</label>
                                            <input type="radio" name="cpin" id="dateRadio" checked={!isCPIn} value={!isCPIn} className="" onChange={(e) => {
                                                handleReset();
                                                setIsCPIn(false);
                                                loadData();
                                            }} />
                                            <label for="dateRadio" data-ng-bind="trans.LBL_SRC_DT">Search By Date</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <p className="mand-text" data-ng-bind="trans.HLP_MAND_FIELD">indicates mandatory fields</p>
                                    {
                                        isCPIn && <div className={`col-sm-3 col-xs-12 ${touched.cpin ? errors.cpin ? 'has-error' : 'has-success' : ''}`}>
                                            <label className="reg m-cir">CPIN</label>
                                            <div className="form-group ">
                                                <div className="input-group">
                                                    <input type="text" name="cpin" id="cpin" value={values.cpin} onChange={handleChange} className="form-control number" maxLength="14" placeholder="Enter CPIN" autocomplete="off" />
                                                </div>
                                                {(errors.cpin && touched.cpin) && <span className='err'>{errors.cpin}</span>}
                                            </div>
                                        </div>
                                    }
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <ThemeProvider theme={materialTheme}>
                                            <DatePicker
                                                variant="dialog"
                                                open={isPicker}
                                                onOpen={() => setIsPicker(true)}
                                                onClose={() => setIsPicker(false)}
                                                label="Open me from button"
                                                format="dd/MM/yyyy"
                                                value={mainDate}
                                                maxDate={mainDate}
                                                minDate={new Date('06-01-2017')}
                                                onChange={handleDateChange}
                                                style={{ display: 'none' }}
                                            />
                                        </ThemeProvider>
                                    </MuiPickersUtilsProvider>
                                    {
                                        !isCPIn && <div>
                                            <div className={`col-xs-12 col-sm-4 ${touched.from ? errors.from ? 'has-error' : 'has-success' : ''}`}>
                                                <label className="reg m-cir" data-ng-bind="trans.LBL_FROM_DT">From</label>
                                                <div className="datepicker-icon input-group">
                                                    <input id="from" name="from" type="text" value={values.from} onChange={handleChange} className="form-control date-picker" placeholder="DD/MM/YYYY" />
                                                    <span className="input-group-addon" onClick={() => { openPicker('from') }}><span className="fa fa-calendar"></span></span>
                                                </div>
                                                {(errors.from && touched.from) && <span className='err'>{errors.from}</span>}
                                            </div>
                                            <div className={`col-xs-12 col-sm-4 ${touched.to ? errors.to ? 'has-error' : 'has-success' : ''}`}>
                                                <label className="reg m-cir" data-ng-bind="trans.LBL_TO_DT">To</label>
                                                <div className="datepicker-icon input-group">
                                                    <input id="to" name="to" type="text" value={values.to} onChange={handleChange} className="form-control date-picker" placeholder="DD/MM/YYYY" />
                                                    <span className="input-group-addon" onClick={() => { openPicker('to') }}><span className="fa fa-calendar"></span></span>
                                                </div>
                                                {(errors.to && touched.to) && <span className='err'>{errors.to}</span>}
                                            </div>
                                        </div>
                                    }
                                    <div className="col-xs-12 col-sm-4">
                                        <p className='pt-2'></p>
                                        <button className="btn btn-primary re" type="submit" title="Search" name="search">Search</button>&nbsp;&nbsp;
                                        <button className="btn btn-success re" type="button" title="Download as CSV" disabled={true}>Download as CSV</button>
                                    </div>
                                </div>
                                <p></p>
                                <p></p>
                                <span data-ng-bind="dateErr" className="err"></span>
                            </form>
                        </div>
                        <div className="row">
                            <div className="col-sm-12"></div>
                            <div className="col-sm-12"></div>
                        </div>
                        <div className="row" data-ng-if="chlnHisData">
                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <div className="table-responsive">
                                    <table className="table table-striped ng-table" style={{ display: 'table' }}>
                                        <thead ng-include="templates.header">
                                            <tr className="ng-table-sort-header">
                                                <th title="" className="header sortable">
                                                    <div className="ng-table-header" ><span className="sort-indicator">CPIN</span></div>
                                                </th>
                                                <th title="" className="header">
                                                    <div className="ng-table-header" > <span className="sort-indicator">Created On</span></div>
                                                </th>
                                                <th title="" className="header sortable">
                                                    <div className="ng-table-header" > <span className="sort-indicator">Amount (₹)</span></div>
                                                </th>
                                                <th title="" className="header">
                                                    <div className="ng-table-header" > <span className="sort-indicator">Mode</span></div>
                                                </th>
                                                <th title="" className="header">
                                                    <div className="ng-table-header" > <span className="sort-indicator">Challan Reason</span></div>
                                                </th>
                                                <th title="" className="header">
                                                    <div className="ng-table-header" > <span className="sort-indicator">Expiry Date</span></div>
                                                </th>
                                                <th title="" className="header sortable">
                                                    <div className="ng-table-header" > <span className="sort-indicator">Deposit Date</span></div>
                                                </th>
                                                <th title="" className="header">
                                                    <div className="ng-table-header" > <span className="sort-indicator">Deposit Status</span></div>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                challans.length !== 0 && challans.map((challan, index) => {
                                                    return <tr ng-repeat="user in $data" key={index}>
                                                        <td data-title="'CPIN'" className="text-center"><a onClick={() => { gotoChallan(challan); }}><span data-ng-bind="user.cpin">{challan.cpin}</span></a></td>
                                                        <td data-title="'Created On'" className="text-center" data-ng-bind="user.chln_cre_dt" data-title-text="Created On">{Moment(challan.month).add(1, 'month').add(19, 'days').format('DD/MM/YYYY hh:mm:ss')}</td>
                                                        <td data-title="'Amount (₹)'" className="text-right" sortable="'total_amt'" data-ng-bind="user.total_amt | INR:'pay'" data-title-text="Amount (₹)">{getTotal(challan)}</td>
                                                        <td data-title="'Mode'" className="text-center" data-ng-bind="user.payment_mod" data-title-text="Mode">E-Payment</td>
                                                        <td data-title="'Challan Reason'" className="text-center" data-ng-bind="user.chln_rsn" data-title-text="Challan Reason">AOP</td>
                                                        <td data-title="'Expiry Date'" className="text-center" data-ng-bind="user.chln_exp_dt" data-title-text="Expiry Date">{Moment(challan.month).add(1, 'month').add(34, 'days').format('DD/MM/YYYY')}</td>
                                                        <td data-title="'Deposit Date'" sortable="'payment_dt'" className="text-center" data-title-text="Deposit Date">{Moment(challan.month).add(1, 'month').add(19, 'days').format('DD/MM/YYYY')}</td>
                                                        <td data-title="'Deposit Status'" className="text-center" data-ng-bind="user.status" data-title-text="Deposit Status">PAID</td>
                                                    </tr>
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xs-12 ">
                                <span><strong><span className="fa fa-info-circle "></span> <span data-ng-bind="trans.HLP_GRV">If amount is deducted from bank account and not reflected in electronic cash ledger, you may raise grievance under Services&gt;Payments&gt;Grievance against payment(GST PMT-07) </span></strong>
                                </span><br />
                            </div>
                            <div className="col-xs-12 ">
                                <span><strong><span className="fa fa-info-circle "></span> <span data-ng-bind="trans.HLP_BANK_STATUS_EPY">*Awaiting Bank Confirmation: For e-payment mode of payment, if the maker has made a transaction and checker approval is not communicated by bank to GST System.</span></strong>
                                </span><br />
                            </div>
                            <div className="col-xs-12 ">
                                <span><strong><span className="fa fa-info-circle "></span> <span data-ng-bind="trans.HLP_BANK_STATUS_OTC">*Awaiting Bank Clearance: For OTC mode of payment, if bank has acknowledged the challan but remittance confirmation is not communicated by bank to GST System.</span></strong>
                                </span>
                            </div>
                            <div className="col-xs-12" data-ng-if="IS_CHALLAN_REASON_ENABLED">
                                <span><strong><span className="fa fa-info-circle "></span> <span data-ng-bind="trans.HLP_CHLN_RSN_ABRVTN">Challan Reason Abbreviations: ⬤ MPQR (35% Challan) - Monthly payment for quarterly return (35% challan) ⬤ MPQR (Self assessed) - Monthly payment for quarterly return (Self assessed) ⬤ AOP - Any other payment.</span></strong>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
