import React, { useState, useEffect } from 'react';

import { useAlert } from 'react-alert';
import FeatherIcon from 'feather-icons-react';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { useRecoilState } from 'recoil';
import { pathAtom, redirectAtom, trnAtom, navAtom, digErrorAtom, userIDAtom, userAtom } from '../core/config/atoms';

import ServiceUtility from '../services/serviceUtility';
import ServiceOption from '../services/serviceOptions';
import ServiceAuth from '../services/serviceAuth';

import captchBg from '../assets/images/misc/captcha.png';

export default function PanelRegister() {

    const [nav, setNav] = useRecoilState(navAtom);
    const [path, setPath] = useRecoilState(pathAtom);
    const [redirect, setRedirect] = useRecoilState(redirectAtom);
    const [trnno, setTrnno] = useRecoilState(trnAtom);
    const [digError, setDigError] = useRecoilState(digErrorAtom);
    const [userID] = useRecoilState(userIDAtom);
    const [userAuth] = useRecoilState(userAtom);

    const [states, setStates] = useState([]);
    const [districts, setDistricts] = useState([]);

    const [AData, setAData] = useState({});
    const [BData, setBData] = useState({});

    const [motp, setMOTP] = useState(0);
    const [eotp, setEOTP] = useState(0);
    const [otp, setOTP] = useState(0);
    const [captch, setCaptch] = useState(0);

    const [isNew, setIsNew] = useState(true);
    const [isOTP, setIsOTP] = useState(false);
    const [isAlertA, setIsAlertA] = useState(false);
    const [isAlertB, setIsAlertB] = useState(false);
    const [captchError, setCaptchError] = useState(false);
    const [isMValid, setIsMValid] = useState(false);
    const [isEValid, setIsEValid] = useState(false);
    const [isDemo, setIsDemo] = useState(false);

    let formAData = {
        applnType: '',
        state: '',
        district: '',
        bname: '',
        panCard: '',
        email: '',
        mobile: '',
        captcha: '',
    };

    let formBData = {
        motp: '',
        eotp: '',
    };

    let formCData = {
        trnno: '',
        captcha: '',
    };

    let formDData = {
        motp: '',
    };

    const alert = useAlert();

    const optionService = new ServiceOption();
    const utilService = new ServiceUtility();
    const authService = new ServiceAuth();

    const formASchema = Yup.object().shape({
        applnType: Yup.string().required('User profile is required'),
        state: Yup.string().required('State is required'),
        district: Yup.string().required('District is required'),
        bname: Yup.string().required('Legal name of the business is required'),
        panCard: Yup.string().required('Permanent Account Number (PAN) is required').matches(/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/, { message: 'Please enter a valid Permanent Account Number (PAN)' }),
        email: Yup.string().required('Email Address is required').matches(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, { message: 'Please enter a valid email address' }),
        mobile: Yup.string().required('Mobile number is required').matches(/^[0-9]{10}$/, { message: 'Please enter a valid mobile number' }),
        captcha: Yup.string().required('Enter characters as displayed in the CAPTCHA image'),
    });

    const formBSchema = Yup.object().shape({
        motp: Yup.string().required('Please enter a valid otp sent on mobile number').matches(/^[0-9]{6}$/, { message: 'Please enter a valid otp' }),
        eotp: Yup.string().required('Please enter a valid otp sent on email address').matches(/^[0-9]{6}$/, { message: 'Please enter a valid otp' }),
    });

    const formCSchema = Yup.object().shape({
        trnno: Yup.string().required('Temporary Reference Number (TRN) is required').matches(/^[0-9]{12}(T|t)(R|r)(N|n)$/, { message: 'Enter valid Temporary Reference Number (TRN)' }),
        captcha: Yup.string().required('Enter characters as displayed in the CAPTCHA image'),
    });

    const formDSchema = Yup.object().shape({
        motp: Yup.string().required('Please enter a valid otp sent on mobile number').matches(/^[0-9]{6}$/, { message: 'Please enter a valid otp' }),
    });

    const { values, errors, touched, dirty, handleChange, handleSubmit, handleReset, resetForm, setValues } = useFormik({
        enableReinitialize: true,
        initialValues: isNew ? (isOTP ? formBData : formAData) : (isOTP ? formDData : formCData),
        validationSchema: isNew ? (isOTP ? formBSchema : formASchema) : (isOTP ? formDSchema : formCSchema),
        onSubmit: values => {
            if (isNew && !isOTP) {
                if (captch.toString() === values.captcha) {
                    setAData({ ...values });
                    setCaptchError(false);
                    resetForm();
                    let mo = utilService.getCode();
                    let eo = utilService.getCode();
                    setMOTP(mo);
                    setEOTP(eo);
                    setIsOTP(true);
                    setIsAlertA(true);
                    setIsAlertB(true);
                } else {
                    setCaptchError(true);
                    setCaptch(utilService.getCode());
                    values.captcha = '';
                    setValues({ ...values, captcha: '' });
                }
            } else if (isNew && isOTP) {
                if (motp.toString() === values.motp) {
                    setIsMValid(false);
                    if (eotp.toString() === values.eotp) {
                        setIsEValid(false);
                        let body = {
                            "user": userAuth,
                            "accType": AData.applnType,
                            "state": AData.state,
                            "district": AData.district,
                            "buzName": AData.bname,
                            "panNumber": AData.panCard.toUpperCase(),
                            "email": AData.email,
                            "mobile": AData.mobile,
                            "trn": utilService.getTrnCode() + "TRN",
                            "stdOwn": userID,
                        }
                        authService.register(body).then((res) => {
                            setIsAlertA(false);
                            setIsAlertB(false);
                            alert.removeAll();
                            if (res.statuscode === 200) {
                                localStorage.setItem('trn', res.data['trn']);
                                setPath('successregister');
                            } else {
                                setDigError({ title: 'Error', msg: 'Server error please try again!', isToggle: true });
                            }
                        });
                    } else {
                        setIsEValid(true);
                    }
                } else {
                    setIsMValid(true);
                    if (eotp.toString() !== values.eotp) {
                        setIsEValid(true);
                    }
                }
            } else if (!isNew && !isOTP) {
                if (captch.toString() === values.captcha) {
                    setCaptchError(false);
                    if (values.trnno === "270000000002TRN" || values.trnno === "270000000001TRN") {
                        setIsDemo(true);
                        setBData({ ...values });
                        setIsOTP(true);
                        let ot = utilService.getCode();
                        setOTP(ot);
                        resetForm();
                        setIsAlertA(true);
                    } else {
                        authService.getGSTUserByTRN({ 'trn': values.trnno, 'stdOwn': userID }).then((res) => {
                            if (res.data.length === 0) {
                                setDigError({ title: 'Error', msg: 'Invalid TRN number please enter valid TRN number.', isToggle: true });
                            } else {
                                setBData({ ...values });
                                setIsOTP(true);
                                let ot = utilService.getCode();
                                setOTP(ot);
                                resetForm();
                                setIsAlertA(true);
                            }
                        });
                    }
                } else {
                    setCaptchError(true);
                    setCaptch(utilService.getCode());
                    values.captcha = '';
                    setValues({ ...values, captcha: '' });
                }
            } else if (!isNew && isOTP) {
                if (otp.toString() === values.motp) {
                    setIsAlertA(false);
                    setIsAlertB(false);
                    alert.removeAll();
                    localStorage.removeItem('trn');
                    setNav('trn');
                    if (isDemo) {
                        if (BData.trnno === "270000000001TRN") {
                            setPath('trnDashboardA');
                        }
                        if (BData.trnno === "270000000002TRN") {
                            setPath('trnDashboardB');
                        }
                    } else {
                        setTrnno(BData.trnno);
                        setPath('trnDashboard');
                    }
                } else {
                    setIsMValid(true);
                }
            }

            window.scrollTo(0, 0)
        },
    });

    const gotoPath = (path) => {
        setPath(path);
    }

    const gotoURL = (url) => {
        setRedirect({ url: url, isToggle: true });
    }

    const loadData = () => {
        (async function () {
            var res = await optionService.getStates({});
            setStates(res.data);
        })();
        setAData({});
        setBData({});
        setCaptch(utilService.getCode());
        let TRN = localStorage.getItem('trn');
        if (TRN !== null) {
            localStorage.removeItem('trn');
            setIsNew(false);
        }
    }

    const loadDistrict = (state) => {
        (async function () {
            var res = await optionService.getDistricts({ 'StateName': state });
            setDistricts(res.data);
        })();
    }

    useEffect(() => {
        loadData();
    }, []);

    return (
        <div class="content-wrapper">
            {
                isAlertA && <div className={`rounded text-lg font-serif mt-10 mr-8 bg-yellow-100 shadow border border-red-700 h-14 flex flex-col justify-center`} style={{ position: 'absolute', right: '200px', top: '142px' }}>
                    <p className={`px-4 py-2 flex text-red-600 text-2xl m-0`}>
                        <FeatherIcon icon="bell" size={18} />&nbsp;&nbsp;
                        {!isNew && <>Your OTP is for login is: {otp}</>}
                        {isNew && <>Your OTP is for mobile phone is: {motp}</>}
                    </p>
                </div>
            }
            {
                isAlertB && <div className={`rounded text-lg font-serif mt-10 mr-8 bg-yellow-100 shadow border border-red-700 h-14 flex flex-col justify-center`} style={{ position: 'absolute', right: '200px', top: '190px' }}>
                    <p className={`px-4 py-2 flex text-red-600 text-2xl m-0`}>
                        <FeatherIcon icon="bell" size={18} />&nbsp;&nbsp;Your OTP is for email address is: {eotp}
                    </p>
                </div>
            }
            <div class="dimmer-holder" style={{ display: 'none' }}>
                <div id="dimmer"></div>
            </div>
            <div class="container">
                <div class="mypage">
                    <div class="row">
                        <div class="col-xs-10">
                            <div name="Home">
                                <ol class="breadcrumb">
                                    <li>
                                        <a onClick={() => {
                                            gotoPath('home');
                                        }}>Home</a>
                                    </li>
                                    {
                                        isOTP && <>
                                            <li>
                                                <a onClick={() => {
                                                    resetForm();
                                                    setIsAlertA(false);
                                                    setIsAlertB(false);
                                                    setIsOTP(false);
                                                    setIsNew(true);
                                                }}>Registration</a>
                                            </li>
                                            <li>
                                                <span ng-switch-when="true">Verify</span>
                                            </li>
                                        </>
                                    }
                                    {
                                        !isOTP && <li>
                                            <span ng-switch-when="true">Registration</span>
                                        </li>
                                    }
                                </ol>
                            </div>
                        </div>
                        <div class="col-xs-2">
                            <div class="lang dropdown">
                                <span class="dropdown-toggle">English</span>
                                <ul class="dropdown-menu lang-dpdwn">
                                    <li data-ng-repeat="language in languages">
                                        <a>English</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="content-pane" style={{ minHeight: '473.4px' }}>
                        <div>
                            <form class="" autoComplete="off" onSubmit={handleSubmit} onReset={handleReset} noValidate={true}>
                                <div class="row">
                                    <div class="col-lg-offset-5 col-lg-4 col-md-offset-3 col-md-6 col-sm-offset-2 col-sm-8 col-xs-12">
                                        <div class="contain">
                                            <ul class="progressbar">
                                                <li class={`z1 ${!isOTP ? 'step-on' : 'step-done'}`}>
                                                    <p>
                                                        {!isOTP && <span class="circle on-page">1</span>}
                                                        {isOTP && <span class="circle">1</span>}
                                                    </p>
                                                    <span>User Credentials</span>
                                                </li>
                                                <li class={`z2 ${isOTP ? 'step-on' : ''}`}>
                                                    <p>
                                                        {!isOTP && <span class="circle not-active">2</span>}
                                                        {isOTP && <span class="circle on-page">2</span>}
                                                    </p>
                                                    <span>OTP Verification</span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-offset-2 col-md-offset-3 col-md-6 col-sm-8 col-xs-12">
                                        {captchError && <p class="err alert alert-danger">Enter characters as displayed in the CAPTCHA image</p>}
                                        {!isOTP && <h4>New Registration</h4>}
                                        {isOTP && <h4>Verify OTP</h4>}
                                        <hr />
                                        <p class="mand-text">indicates mandatory fields</p>
                                        {
                                            !isOTP && <div class="row">
                                                <div class="col-xs-12 flex">
                                                    <div onClick={() => {
                                                        setIsOTP(false);
                                                        setIsNew(true);
                                                        setIsMValid(false);
                                                        setIsEValid(false);
                                                        setCaptchError(false);
                                                        resetForm();
                                                    }}>
                                                        <input type="radio" name="typ" id="radionew" checked={isNew} />
                                                        <label For="radionew">New Registration</label>
                                                    </div>
                                                    <div onClick={() => {
                                                        setIsOTP(false);
                                                        setIsNew(false);
                                                        setIsMValid(false);
                                                        setIsEValid(false);
                                                        setCaptchError(false);
                                                        resetForm();
                                                    }}>
                                                        <input type="radio" name="typ" id="radiotrn" checked={!isNew} />
                                                        <label For="radiotrn" data-ng-bind="trans.LBL_TRN">Temporary Reference Number (TRN)</label>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        {
                                            (isNew && !isOTP) && <fieldset>
                                                <div className="row">
                                                    <div className={`col-xs-12 ${touched.applnType ? errors.applnType ? 'has-error' : 'has-success' : ''}`}>
                                                        <label className="reg m-cir" for="applnType">I am a</label>
                                                        <select className="form-control" name="applnType" id="applnType" autoFocus="" required="" onChange={handleChange} value={values.applnType}>
                                                            <option value="">Select</option>
                                                            <option value="TAX" className='text-black'>Taxpayer</option>
                                                            <option value="" disabled>Tax Deductor</option>
                                                            <option value="" disabled>Tax Collector (e-Commerce)</option>
                                                            <option value="" disabled>GST Practitioner</option>
                                                            <option value="" disabled>Non Resident Taxable Person</option>
                                                            <option value="" disabled>United Nation Body </option>
                                                            <option value="" disabled>Consulate or Embassy of Foreign Country </option>
                                                            <option value="" disabled>Other Notified Person</option>
                                                            <option value="" disabled>Non-Resident Online Services Provider</option>
                                                        </select>
                                                        {(errors.applnType && touched.applnType) && <p class="err m-0">{errors.applnType}</p>}
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className={`col-xs-12 ${touched.state ? errors.state ? 'has-error' : 'has-success' : ''}`}>
                                                        <label for="applnState" className="reg m-cir">State / UT</label>
                                                        <select className="form-control" id="state" name="state" autoFocus="" required="required" value={values.state} onChange={(e) => {
                                                            loadDistrict(e.target.value);
                                                            handleChange(e);
                                                        }}>
                                                            <option className="">Select</option>
                                                            {
                                                                states.map((state) => {
                                                                    return <option value={state} key={state}>{state}</option>
                                                                })
                                                            }
                                                        </select>
                                                        {(errors.state && touched.state) && <p class="err m-0">{errors.state}</p>}
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class={`col-xs-12 ${touched.district ? errors.district ? 'has-error' : 'has-success' : ''}`}>
                                                        <label class={`reg ${values.state === '' ? '' : 'm-cir'}`} for="applnDistr">District</label>
                                                        <select class="form-control" id="district" name="district" value={values.district} autoFocus="" required="required" onChange={handleChange}>
                                                            <option value="">Select</option>
                                                            {
                                                                districts.map((district) => {
                                                                    if (district === null || district === undefined)
                                                                        return "";
                                                                    return <option value={district} key={district}>{district}</option>
                                                                })
                                                            }
                                                        </select>
                                                        {(errors.district && touched.district) && <p class="err m-0">{errors.district}</p>}
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class={`col-xs-12 ${touched.bname ? errors.bname ? 'has-error' : 'has-success' : ''}`}>
                                                        <label for="bnm" class="reg m-cir">
                                                            <span>Legal Name of the Business</span>
                                                            <span class="help">(As mentioned in PAN)</span>
                                                        </label>
                                                        <input type="text" id="bname" name="bname" value={values.bname} autoComplete='off' placeholder="Enter Legal Name of Business" class="form-control" pattern="/^[a-zA-Z0-9\_&amp;'\-\.\/\,\}\]\[&quot;&quot;()?@!#%$~*;+= ]{1,99}$/" maxlength="99" autoFocus="" required="" onChange={handleChange} />
                                                        {(errors.bname && touched.bname) && <p class="err m-0">{errors.bname}</p>}
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class={`col-xs-12 ${touched.panCard ? errors.panCard ? 'has-error' : 'has-success' : ''}`}>
                                                        <label class="reg m-cir" for="pan_card">Permanent Account Number (PAN)</label>
                                                        <div class="has-feedback">
                                                            <input class={`form-control uppercase`} value={values.panCard} id="panCard" autoComplete='off' name="panCard" placeholder="Enter Permanent Account Number (PAN)" type="text" maxlength="10" pattern="^[a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}$" autoCapitalize={true} required="" onChange={(e) => {
                                                                e.target.value = e.target.value.toUpperCase();
                                                                handleChange(e);
                                                            }} />
                                                            {(errors.panCard && touched.panCard) && <p class="err m-0">{errors.panCard}</p>}
                                                        </div>
                                                        {
                                                            values.applnType !== '' && values.applnType !== undefined && <>
                                                                <span id="helpBlock" class="help-block">
                                                                    <i class="fa fa-info-circle"></i>
                                                                    <span> If you don't have PAN, Click</span>
                                                                    <a class="link" tabIndex="-1" onClick={() => { gotoURL('https://tin.tin.nsdl.com/pan/') }}>
                                                                        <span> here </span>
                                                                    </a>
                                                                    <span>to apply</span>
                                                                </span>
                                                                <div class="panHelper">
                                                                    <span>Eg:</span>
                                                                    &nbsp;<span>
                                                                        <code className={`${values.panCard[0] === undefined ? '' : utilService.patternMatch('^[a-zA-Z]{1}', values.panCard[0]) ? 'success' : 'failure'}`}>A</code>
                                                                    </span>
                                                                    &nbsp;<span>
                                                                        <code className={`${values.panCard[1] === undefined ? '' : utilService.patternMatch('^[a-zA-Z]{1}', values.panCard[1]) ? 'success' : 'failure'}`}>B</code>
                                                                    </span>
                                                                    &nbsp;<span>
                                                                        <code className={`${values.panCard[2] === undefined ? '' : utilService.patternMatch('^[a-zA-Z]{1}', values.panCard[2]) ? 'success' : 'failure'}`}>C</code>
                                                                    </span>
                                                                    &nbsp;<span>
                                                                        <code className={`${values.panCard[3] === undefined ? '' : utilService.patternMatch('^[a-zA-Z]{1}', values.panCard[3]) ? 'success' : 'failure'}`}>D</code>
                                                                    </span>
                                                                    &nbsp;<span>
                                                                        <code className={`${values.panCard[4] === undefined ? '' : utilService.patternMatch('^[a-zA-Z]{1}', values.panCard[4]) ? 'success' : 'failure'}`}>E</code>
                                                                    </span>
                                                                    &nbsp;<span>
                                                                        <code className={`${values.panCard[5] === undefined ? '' : utilService.patternMatch('^\\d{1}', values.panCard[5]) ? 'success' : 'failure'}`}>1</code>
                                                                    </span>
                                                                    &nbsp;<span>
                                                                        <code className={`${values.panCard[6] === undefined ? '' : utilService.patternMatch('^\\d{1}', values.panCard[6]) ? 'success' : 'failure'}`}>2</code>
                                                                    </span>
                                                                    &nbsp;<span>
                                                                        <code className={`${values.panCard[7] === undefined ? '' : utilService.patternMatch('^\\d{1}', values.panCard[7]) ? 'success' : 'failure'}`}>3</code>
                                                                    </span>
                                                                    &nbsp;<span>
                                                                        <code className={`${values.panCard[8] === undefined ? '' : utilService.patternMatch('^\\d{1}', values.panCard[8]) ? 'success' : 'failure'}`}>4</code>
                                                                    </span>
                                                                    &nbsp;<span>
                                                                        <code className={`${values.panCard[9] === undefined ? '' : utilService.patternMatch('^[a-zA-Z]{1}', values.panCard[9]) ? 'success' : 'failure'}`}>X</code>
                                                                    </span>
                                                                </div>
                                                            </>
                                                        }
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-xs-12">
                                                        <label class="reg m-cir" for="email">
                                                            <span>Email Address</span>
                                                        </label>
                                                        <div class={`input-group ${touched.email ? errors.email ? 'has-error' : 'has-success' : ''}`}>
                                                            <span class="input-group-addon" id="ba2"><i class="fa fa-envelope"></i></span>
                                                            <input id="email" class="form-control" type="text" autoComplete='off' placeholder="Enter Email Address" name="email" maxLength={254} required="" onChange={handleChange} value={values.email} />
                                                        </div>
                                                        {(errors.email && touched.email) && <p class="err m-0">{errors.email}</p>}
                                                        <div className={`${touched.email ? errors.email ? 'has-error' : 'has-success' : ''}`}>
                                                            <span class="help-block"><i class="fa fa-info-circle"></i><span> OTP will be sent to this Email Address</span></span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-xs-12">
                                                        <label for="mobile" class="reg m-cir">
                                                            <span>Mobile Number</span>
                                                        </label>
                                                        <div class={`input-group  ${touched.mobile ? errors.mobile ? 'has-error' : 'has-success' : ''}`}>
                                                            <span class="input-group-addon" id="ba">+91</span>
                                                            <input id="mobile" name="mobile" pattern="[0-9]*" type="text" class={`number form-control`} placeholder="Enter Mobile Number" autoComplete='off' aria-describedby="ba" maxLength="10" minLength="10" required="required" onChange={(e) => {
                                                                e.target.value = utilService.formatOnlyNumber(e.target.value);
                                                                handleChange(e);
                                                            }} value={values.mobile} />
                                                        </div>
                                                        {(errors.mobile && touched.mobile) && <p class="err m-0">{errors.mobile}</p>}
                                                        <div className={`${touched.mobile ? errors.mobile ? 'has-error' : 'has-success' : ''}`}>
                                                            <span class="help-block"><i class="fa fa-info-circle"></i><span>&nbsp;Separate OTP will be sent to this mobile number</span></span>
                                                        </div>
                                                    </div>
                                                </div>
                                                {
                                                    dirty && <div class="row">
                                                        <div class="col-xs-12">
                                                            <label class="reg m-cir" for="captcha">Type the characters you see in the image below</label>
                                                            <input class="form-control number" id="captcha" name="captcha" data-ng="/^([0-9]){6}$/" minLength={6} maxLength={6} required="" value={values.captcha} onChange={(e) => {
                                                                e.target.value = utilService.formatOnlyNumber(e.target.value);
                                                                handleChange(e);
                                                            }} />
                                                            <div class="row">
                                                                <div class="col-sm-12 col-xs-12">
                                                                    <div data-captcha="" data-captcha-object="captchaObj">
                                                                        <table>
                                                                            <tbody>
                                                                                <tr>
                                                                                    <th rowspan="2" className='border border-black'>
                                                                                        <div className='relative'>
                                                                                            <p className='text-black font-black text-5xl absolute left-10 top-4'>{captch}</p>
                                                                                            <img className="captcha" src={captchBg} />
                                                                                        </div>
                                                                                    </th>
                                                                                    <th>
                                                                                        <button type="button" className='border border-gray-700 hover:bg-gray-500' style={{ backgroundColor: 'rgb(240, 240, 240)' }}>
                                                                                            <i class="fa fa-volume-up p-2"></i>
                                                                                        </button>
                                                                                    </th>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>
                                                                                        <button type="button" className='border border-gray-700 hover:bg-gray-500' style={{ backgroundColor: 'rgb(240, 240, 240)' }} onClick={() => {
                                                                                            setCaptch(utilService.getCode());
                                                                                        }}>
                                                                                            <i class="fa fa-refresh p-2"></i>
                                                                                        </button>
                                                                                    </td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>
                                                                        {(errors.captcha && touched.captcha) && <p class="err m-0">{errors.captcha}</p>}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                            </fieldset>
                                        }
                                        {
                                            (isNew && isOTP) && <fieldset>
                                                <div class="row">
                                                    <div class={`col-xs-12 ${touched.motp ? (errors.motp || isEValid) ? 'has-error' : 'has-success' : ''}`}>
                                                        <label for="motp" class="reg m-cir">
                                                            <span>Mobile OTP</span>
                                                        </label>
                                                        <input type="text" id="motp" name="motp" placeholder="" class="form-control" pattern="^[0-9]{6}$" maxLength={6} minLength={6} autoFocus="" required="required" onChange={(e) => {
                                                            setIsMValid(false);
                                                            e.target.value = utilService.formatOnlyNumber(e.target.value);
                                                            handleChange(e);
                                                        }} value={values.motp} />
                                                        {(errors.motp && touched.motp) && <p class="err m-0">{errors.motp}</p>}
                                                        {isMValid && <p class="err m-0">Please enter valid OTP sent to your mobile number</p>}
                                                        <span class="help-block"><i class="fa fa-info-circle"></i> <span>Enter OTP sent to your mobile number</span></span>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class={`col-xs-12 ${touched.eotp ? (errors.eotp || isEValid) ? 'has-error' : 'has-success' : ''}`}>
                                                        <label for="motp" class="reg m-cir">
                                                            <span>Email OTP</span>
                                                        </label>
                                                        <input type="text" id="eotp" name="eotp" placeholder="" class="form-control" pattern="^[0-9]{6}$" maxLength={6} minLength={6} autoFocus="" required="required" onChange={(e) => {
                                                            setIsEValid(false);
                                                            e.target.value = utilService.formatOnlyNumber(e.target.value);
                                                            handleChange(e);
                                                        }} value={values.eotp} />
                                                        {(errors.eotp && touched.eotp) && <p class="err m-0">{errors.eotp}</p>}
                                                        {isEValid && <p class="err m-0">Please enter valid OTP sent to your email address</p>}
                                                        <span class="help-block"><i class="fa fa-info-circle"></i> <span>Enter OTP sent to your email address</span></span>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-sm-12">
                                                        <a class="link">Need OTP to be resent? Click here</a>
                                                    </div>
                                                </div>
                                            </fieldset>
                                        }

                                        {
                                            (!isNew && !isOTP) && <fieldset>
                                                <div class="row">
                                                    <div class={`col-xs-12 ${touched.trnno ? errors.trnno ? 'has-error' : 'has-success' : ''}`}>
                                                        <label class="reg m-cir" for="trnno">Temporary Reference Number (TRN)</label>
                                                        <input class="form-control" id="trnno" name="trnno" placeholder="Enter Temporary Reference Number (TRN)" type="text" maxLength={15} pattern="[0-9]{12}(T|t)(R|r)(N|n)$" required="" onChange={(e) => {
                                                            e.target.value = e.target.value.toUpperCase();
                                                            handleChange(e)
                                                        }} value={values.trnno} />
                                                        {(errors.trnno && touched.trnno) && <p class="err m-0">{errors.trnno}</p>}
                                                    </div>
                                                    <div class={`col-xs-12 ${touched.captcha ? errors.captcha ? 'has-error' : 'has-success' : ''}`}>
                                                        <label class="reg m-cir" for="captcha">Type the characters you see in the image below</label>
                                                        <input class={`form-control number`} id="captcha" name="captcha" placeholder="Enter characters as displayed in the CAPTCHA image" pattern="^([0-9]){6}$" minLength={6} maxLength={6} required="" onChange={handleChange} value={values.captcha} />
                                                        <div class="row">
                                                            <div class="col-sm-12 col-xs-12">
                                                                <div data-captcha="" data-captcha-object="captchaObj">
                                                                    <table>
                                                                        <tbody>
                                                                            <tr>
                                                                                <th rowspan="2" className='border border-black'>
                                                                                    <div className='relative'>
                                                                                        <p className='text-black font-black text-5xl absolute left-10 top-4'>{captch}</p>
                                                                                        <img className="captcha" src={captchBg} />
                                                                                    </div>
                                                                                </th>
                                                                                <th>
                                                                                    <button type="button" className='border border-gray-700 hover:bg-gray-500' style={{ backgroundColor: 'rgb(240, 240, 240)' }}>
                                                                                        <i class="fa fa-volume-up p-2"></i>
                                                                                    </button>
                                                                                </th>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>
                                                                                    <button type="button" className='border border-gray-700 hover:bg-gray-500' style={{ backgroundColor: 'rgb(240, 240, 240)' }} onClick={() => {
                                                                                        setCaptch(utilService.getCode());
                                                                                    }}>
                                                                                        <i class="fa fa-refresh p-2"></i>
                                                                                    </button>
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                    {(errors.captcha && touched.captcha) && <p class="err m-0">{errors.captcha}</p>}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </fieldset>
                                        }

                                        {
                                            (!isNew && isOTP) && <fieldset>
                                                <div class="row">
                                                    <div class={`col-xs-12 ${touched.motp ? (errors.motp || isMValid) ? 'has-error' : 'has-success' : ''}`}>
                                                        <label for="motp" class="reg m-cir">
                                                            <span>Mobile / Email OTP</span>
                                                        </label>
                                                        <input type="text" id="motp" name="motp" placeholder="" class="form-control" pattern="^[0-9]{6}$" maxLength={6} minLength={6} autoFocus="" required="required" onChange={(e) => {
                                                            setIsMValid(false);
                                                            e.target.value = utilService.formatOnlyNumber(e.target.value);
                                                            handleChange(e);
                                                        }} value={values.motp} />
                                                        {(errors.motp && touched.motp) && <p class="err m-0">{errors.motp}</p>}
                                                        {isMValid && <p class="err m-0">Please enter valid OTP sent to your mobile number</p>}
                                                    </div>
                                                    <span class="help-block"><i class="fa fa-info-circle"></i> <span>Fill OTP sent to Mobile or Email</span></span>
                                                    <span class="help-block"><i class="fa fa-info-circle"></i> <span>Please check the junk/spam folder in case you do not get email</span></span>
                                                </div>
                                                <div class="row">
                                                    <div class="col-sm-12">
                                                        <a class="link">Need OTP to be resent? Click here</a>
                                                    </div>
                                                </div>
                                            </fieldset>
                                        }

                                        <div class="row">
                                            <div class="col-xs-12 flex items-center justify-end">
                                                <button type='reset' class="text-gray-900 border border-black hover:text-black" style={{ width: '140px', height: '40px', marginRight: '20px', display: isOTP ? '' : 'none' }} onClick={() => {
                                                    setIsAlertA(false);
                                                    setIsAlertB(false);
                                                    setIsOTP(false);
                                                    alert.removeAll();
                                                }}>Back</button>
                                                <button type='submit' class="btn btn-block btn-primary" style={{ width: isOTP ? '140px' : '', height: '40px' }}>Proceed</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                            <div class="modal fade bs-example-modal-md" id="popUpOtp" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
                                <div class="modal-dialog modal-md" role="document">
                                    <div class="modal-content">
                                        <div class="modal-header">
                                            <h4 class="modal-title text-center" id="myModalLabel"><strong>NOTE</strong></h4>
                                        </div>
                                        <div class="modal-body">
                                            Information submitted above is subject to online verification before proceeding to fill up Part-B. Mobile number and Email address should be of the Primary Authorized Signatory filing the application.
                                        </div>
                                        <div class="modal-footer">
                                            <button type="button" class="btn btn-primary">OK</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </div >
    );
}
