import React, { useState, useEffect } from 'react';

import { useRecoilState } from 'recoil';
import { pathAtom, gstrpathAtom, digErrorAtom, returnDateAtom, loadingAtom, gstrUserAtom, gstrQueryAtom, gstrFileStatusAtom, userIDAtom } from '../../../core/config/atoms';

import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Dialog } from '@headlessui/react';
import Moment from 'moment';

import imgLoader from '../../../assets/images/cloading.gif';

import '../css/main.css';
import '../css/style.css';

import ServiceGSTR from '../../../services/serviceGSTR';
import ServiceUtility from '../../../services/serviceUtility';

export default function PanelACDRHome() {
    const [path, setPath] = useRecoilState(pathAtom);
    const [gstrPath, setGSTRPath] = useRecoilState(gstrpathAtom);
    const [gDate] = useRecoilState(returnDateAtom);
    const [loading, setLoading] = useRecoilState(loadingAtom);
    const [query, setQuery] = useRecoilState(gstrQueryAtom);
    const [GSTUser] = useRecoilState(gstrUserAtom);
    const [fileStatus] = useRecoilState(gstrFileStatusAtom);
    const [userID] = useRecoilState(userIDAtom);

    const [records, setRecords] = useState([]);
    const [fyears, setFYears] = useState([]);

    const [digConfirm, setDigConfirm] = useState({ 'type': '0', isToggle: false, data: null });

    const [isErrorAlert, setIsErrorAlert] = useState("");
    const [isInvoice, setIsInvoice] = useState("");

    const [isAlert, setIsAlrt] = useState(false);

    const returnService = new ServiceGSTR();
    const utilService = new ServiceUtility();

    const formVSchema = Yup.object().shape({
        year: Yup.string().required('This information is required'),
        inv_no: Yup.string().matches(/^(?=[/-0]*[a-zA-Z0-9/-]*[a-zA-Z1-9]+[a-zA-Z0-9/-]*).{1,16}$/, { message: 'Do enter only allowed characters.' }).max(16, { message: 'Please enter valid invoice number' }).required('This information is required'),
    });

    const { values, errors, touched, setValues, handleChange, handleSubmit, handleReset, resetForm } = useFormik({
        initialValues: {
            year: '223',
            inv_no: '',
        },
        validationSchema: formVSchema,
        onSubmit: values => {
            if (!cheackInvoice(values.inv_no)) {
                setIsInvoice("");
                setLoading(true);
                let body = {
                    lid: userID,
                    month: values.year,
                    cmonth: gDate,
                    invno: values.inv_no,
                    type: 'cdr'
                }
                returnService.findInvoice(body).then((res) => {
                    if (res.status) {
                        setQuery({ type: 'amend', data: res.data._id });
                        gotoGstrPath('acdrb');
                    } else {
                        setIsErrorAlert("No document found in selected financial year. Either this document exists in a different financial year or the document number is incorrect..");
                    }
                    setLoading(false);
                });
            } else {
                setIsInvoice("Note No. '" + values.inv_no + "' already amended in the current peroid.");
            }
        }
    });

    const cheackInvoice = (id) => {
        let res = false;
        for (var i = 0; i < records.length; i++) {
            if (records[i].data.cdr_no === id) {
                res = true;
                break;
            }
        }
        return res;
    }

    const gotoPath = (path) => {
        setLoading(true);
        setPath(path);
    }

    const gotoGstrPath = (path) => {
        setGSTRPath(path);
        gotoPath('gstrFile');
    }

    const showAlert = () => {
        setIsAlrt(true);
        setTimeout(
            () => setIsAlrt(false),
            5000
        );
    }

    const showDig = (type, data) => {
        setDigConfirm({ 'type': type, isToggle: true, data: data });
    }

    const calcInit = () => {
        let ye = [];

        let data = Moment(gDate).toDate();

        if (data.getMonth() >= 3) {
            data.setMonth(3);
            data.setDate(1);

            let partA = Moment(data).format("YYYY");
            let partB = Moment(data).add(1, 'years').format("YY");
            let label = partA + "-" + partB;

            ye.push({ label: label, data: Moment(data).toDate(), status: true });
        } else {
            data.setMonth(3);
            data.setDate(1);

            let partA = Moment(data).subtract(1, 'years').format("YYYY");
            let partB = Moment(data).format("YY");
            let label = partA + "-" + partB;

            ye.push({ label: label, data: Moment(data).subtract(1, 'years').toDate(), status: true });
        }

        setValues({ ...values, year: ye[0].data });
        setFYears(ye);
    }

    const getSelectionRecordsA = () => {
        let rows = [];
        records.forEach((row) => {
            let s = 'processed';
            if (s === row.status) {
                let tax = utilService.getTotalValue(row.data.items).toFixed(2);
                let igst = (GSTUser.state === row.data.pos ? 0 : utilService.getTotalI(row.data.items)).toFixed(2);
                let cgst = (GSTUser.state === row.data.pos ? utilService.getTotalC(row.data.items) : 0).toFixed(2);
                let sgst = (GSTUser.state === row.data.pos ? utilService.getTotalS(row.data.items) : 0).toFixed(2);
                let cess = utilService.getTotalCess(row.data.items).toFixed(2);
                rows.push({ id: row._id, oid: row.data.oid, invno: row.data.rcdr_no, date: Moment(row.data.rcdrdate, 'DD-MM-YYYY').toDate(), ctype: (row.data.notetype == "C" ? "Credit" : "Debit"), tax: tax, igst: igst, cgst: cgst, sgst: sgst, cess: cess });
            }
        });
        return rows;
    }

    const getSelectionRecordsB = () => {
        let rows = [];
        records.forEach((row) => {
            let s = 'pending';
            if (s === row.status) {
                let tax = utilService.getTotalValue(row.data.items).toFixed(2);
                let igst = (GSTUser.state === row.data.pos ? 0 : utilService.getTotalI(row.data.items)).toFixed(2);
                let cgst = (GSTUser.state === row.data.pos ? utilService.getTotalC(row.data.items) : 0).toFixed(2);
                let sgst = (GSTUser.state === row.data.pos ? utilService.getTotalS(row.data.items) : 0).toFixed(2);
                let cess = utilService.getTotalCess(row.data.items).toFixed(2);
                let action = row.data.status;
                rows.push({ id: row._id, oid: row.data.oid, invno: row.data.rcdr_no, date: Moment(row.data.rcdrdate, 'DD-MM-YYYY').toDate(), ctype: (row.data.notetype == "C" ? "Credit" : "Debit"), tax: tax, igst: igst, cgst: cgst, sgst: sgst, cess: cess, action: action });
            }
        });
        return rows;
    }

    const viewInvoice = (id) => {
        setQuery({ type: 'view', data: id });
        gotoGstrPath('acdrb');
    }

    const editInvoice = (id) => {
        setQuery({ type: 'edit', data: id });
        gotoGstrPath('acdrb');
    }

    const cancel = () => {
        setDigConfirm({ 'type': '0', isToggle: false, data: null });
    }

    const process = () => {
        switch (digConfirm.type) {
            case '1':
                deleteInvoice(digConfirm.data.id, digConfirm.data.oid, digConfirm.data.status);
                break;
            default:
                break;
        }
        setDigConfirm({ 'type': '0', isToggle: false, data: null });
    }

    const deleteInvoice = (id, oid, status) => {
        setLoading(true);
        let body = {
            lid: userID,
            type: 'cdra',
            status: status,
            month: gDate,
            id: id,
            oid: oid
        }
        returnService.deleteGstrDoc(body).then((res) => {
            setLoading(false);
            showAlert();
            loadData();
        });
    }

    const loadData = () => {
        setLoading(true);
        (async function () {
            if (query.type === 'done') {
                showAlert();
                setQuery({ type: '', data: '' });
            }
            let body = {
                lid: userID,
                month: gDate,
                type: 'cdra'
            }
            var res = await returnService.getGstrDocs(body);
            setRecords(res.data);
            calcInit();
            setLoading(false);
            window.scrollTo({
                top: 0,
                behavior: 'smooth',
            });
        })();
    }

    useEffect(() => {
        loadData();
    }, []);

    return (
        <div>
            <Dialog open={digConfirm.isToggle} onClose={() => setDigConfirm({ 'type': '0', isToggle: false, data: null })}>
                <div id="confirmDlg" class="modal fade fade-scale in" role="dialog" style={{ display: 'block', paddingRight: '17px' }}>
                    <div class="modal-dialog sweet">
                        <div class="modal-content">
                            <div class="modal-body">
                                <div class="m-icon m-warning pulseWarning">
                                    <span class="micon-body pulseWarningIns"></span>
                                    <span class="micon-dot pulseWarningIns"></span>
                                </div>
                                <h2>Warning</h2>
                                <p>Are you sure, you want to continue?</p>
                            </div>
                            <div class="modal-footer">
                                <a class="btn btn-default" onClick={() => { cancel() }}>Cancel</a>
                                <a class="btn btn-primary" onClick={() => { process() }}>Proceed</a>
                            </div>
                        </div>
                    </div>
                </div>
            </Dialog>
            <div class="content-wrapper fade-ng-cloak">
                <div class="dimmer-holder" style={{ display: 'none' }}>
                    <div id="dimmer"></div>
                </div>
                {
                    loading && <div className='absolute z-50 w-full h-screen flex items-center justify-center bg-gray-200 bg-opacity-25'>
                        <img src={imgLoader} alt="loader" />
                    </div>
                }
                <div class="container">
                    <div class="mypage">
                        <div class="row">
                            <div class="col-xs-10">
                                <div name="Dashboard">
                                    <div name="Dashboard">
                                        <ol class="breadcrumb" data-ng-controller="crumbCtrl">
                                            <li>
                                                <a onClick={() => {
                                                    gotoPath('gstr')
                                                }}>Dashboard</a></li>
                                            <li>
                                                <a onClick={() => {
                                                    gotoPath('gstRetrun')
                                                }}>Returns</a>
                                            </li>
                                            <li>
                                                <a onClick={() => {
                                                    gotoPath('gstrForm')
                                                }}>GSTR-1/IFF</a>
                                            </li>
                                            <li>
                                                <span ng-switch-when="true">CDNRA</span>
                                            </li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xs-2">
                                <div class="lang dropdown">
                                    <span class="dropdown-toggle">English</span>
                                    <ul class="dropdown-menu lang-dpdwn">
                                        <li>
                                            <a>English</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div class="container">
                                {isErrorAlert !== "" && <alert-message ng-show="showMsg" title="" type="msg alert-danger" message="" class="ng-hide">
                                    <div class="alert alert-msg alert-danger">
                                        {isErrorAlert}
                                        <a class="close" onClick={() => { setIsErrorAlert("") }} data-dismiss="alert" aria-label="close">×</a>
                                        <strong></strong>
                                    </div>
                                </alert-message>}
                                {isAlert && <alert-message ng-show="showMsg" title="" type="msg alert-success" message="" class="ng-hide"><div class="alert alert-msg alert-success">Request accepted successfully<a class="close" onClick={() => { setIsAlrt(false) }} data-dismiss="alert" aria-label="close">×</a><strong></strong>.</div></alert-message>}
                                <div class="row invsumm">
                                    <div class="col-xs-12 col-sm-12 taxp">
                                        <h4>9C - Amended Credit / Debit Notes (Registered)</h4>
                                        <button class="btn btn-primary btn-circle btn-sm pull-right" style={{ marginTop: '0px' }} disabled={true}><i class="fa fa-refresh" aria-hidden="true"></i></button>
                                        <button type="button" class="btn btn-primary btn-sm pull-right" data-ng-click="help()" style={{ marginTop: '0px', marginRight: '5px' }} disabled={true}>
                                            <span>Help</span>&nbsp;
                                            <i class="fa fa-question-circle" aria-hidden="true"></i>
                                        </button>
                                    </div>
                                </div>
                                <div class="tabpane">
                                    {records.length == 0 && <div class="alert alert-msg alert-info alert-dismissible">There are no records to be displayed.</div>}
                                    <p class="mand-text" data-ng-bind="trans.HLP_MAND_FIELD">Indicates Mandatory Fields</p>
                                    <div class="tbl-format rettbl-format">
                                        <form name="amendSearch" autocomplete="off" novalidate="" onSubmit={handleSubmit} onReset={handleReset}>
                                            <fieldset disabled={fileStatus}>
                                                <div class="row mar-b">
                                                    <div class={`col-sm-4 col-xs-12 ${touched.year ? errors.year ? 'has-error' : 'has-success' : ''}`}>
                                                        <label for="year" class="reg m-cir">Financial Year</label>
                                                        <select name="year" id="year" class="form-control" value={values.year} onChange={handleChange} >
                                                            {
                                                                fyears.map((ye) => {
                                                                    return <option value={ye.data}>{ye.label}</option>
                                                                })
                                                            }
                                                        </select>
                                                        {(errors.year && touched.year) && <span className='err'>{errors.year}</span>}
                                                    </div>
                                                    <div class={`col-sm-4 ${touched.inv_no ? errors.inv_no ? 'has-error' : 'has-success' : ''}`}>
                                                        <label class="reg m-cir" for="inviceNo" data-ng-bind="trans.LBL_INVOICE_NUMBER">Credit/Debit Note No.</label>
                                                        <input class="form-control" name="inv_no" id="inv_no" type="text" placeholder="Enter Invoice No" maxLength={16} value={values.inv_no} onChange={handleChange} />
                                                        {(errors.inv_no && touched.inv_no) && <span className='err'>{errors.inv_no}</span>}
                                                        {isInvoice != "" && <span className='err'>{isInvoice}</span>}
                                                    </div>
                                                </div>
                                            </fieldset>
                                            <div class="row mar-b">
                                                <div class="col-sm-12 col-xs-12 text-right next-tab-nav">
                                                    {
                                                        (getSelectionRecordsA().length === 0 && getSelectionRecordsB().length === 0) && <>
                                                            <button type="button" class="btn btn-default" onClick={() => {
                                                                gotoPath('gstrForm')
                                                            }}>Back</button>&nbsp;
                                                        </>
                                                    }
                                                    <button type="submit" class="btn btn-primary">Amend Record</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                    <div class="row">
                                        <div class="col-sm-12 col-xs-12">
                                            {
                                                getSelectionRecordsA().length !== 0 && < div class="row" data-ng-if="b2binvdt.processedInvoice">
                                                    <div class="col-sm-12">
                                                        <h4 data-ng-bind="trans.LBL_PRO_RCD">Processed Records</h4>
                                                    </div>
                                                    <br />

                                                    <div class="col-xs-12 col-sm-12">
                                                        <div class="row">
                                                            <div class="col-xs-12">
                                                                <div class="col-lg-3 pull-right">
                                                                    <div class="form-group pull-right" style={{ display: 'inline-flex' }}>
                                                                        <div class="col-lg-9"><label class="form-label">Records Per Page : </label></div>
                                                                        <div class="col-lg-3" style={{ paddingLeft: '0', marginLeft: '-0.7em' }}>
                                                                            <select class="form-control ng-pristine ng-untouched ng-valid ng-not-empty" style={{ minWidth: '5em', border: '1px solid' }} ng-model="obj.recPerPageProc" data-ng-change="changeRecordsPerpagePrs()" ng-options="item as item for item in recordsPerpageOptionsb2b" value="item">
                                                                                <option label="10" value="number:10" selected="selected">10</option>
                                                                                <option label="20" value="number:20">20</option>
                                                                                <option label="50" value="number:50">50</option>
                                                                            </select>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="table-responsive">
                                                            <table class="table exp inv tbl table-bordered ng-table" style={{ display: 'table' }}>
                                                                <thead ng-include="templates.header">
                                                                    <tr class="ng-table-sort-header">
                                                                        <th title="" ng-repeat="$column in $columns" class="header sortable">
                                                                            <div class="ng-table-header">
                                                                                <span class="sort-indicator">Revised Credit/Debit Note No.</span>
                                                                            </div>
                                                                        </th>
                                                                        <th title="" class="header ">
                                                                            <div>
                                                                                <span class="sort-indicator">Revised Credit/Debit Note date</span>
                                                                            </div>
                                                                        </th>
                                                                        <th title="" class="header ">
                                                                            <div>
                                                                                <span class="sort-indicator">Note Type</span>
                                                                            </div>
                                                                        </th>
                                                                        <th title="" class="header ">
                                                                            <div>
                                                                                <span class="sort-indicator">Revised taxable value (₹)</span>
                                                                            </div>
                                                                        </th>
                                                                        <th title="" class="header ">
                                                                            <div>
                                                                                <span class="sort-indicator">Integrated Tax (₹)</span>
                                                                            </div>
                                                                        </th>
                                                                        <th title="" class="header ">
                                                                            <div>
                                                                                <span class="sort-indicator">Central tax (₹)</span>
                                                                            </div>
                                                                        </th>
                                                                        <th title="" class="header ">
                                                                            <div>
                                                                                <span class="sort-indicator">State/UT Tax (₹)</span>
                                                                            </div>
                                                                        </th>
                                                                        <th title="" class="header ">
                                                                            <div>
                                                                                <span class="sort-indicator">Cess (₹)</span>
                                                                            </div>
                                                                        </th>
                                                                        <th title="" class="header ">
                                                                            <div>
                                                                                <span class="sort-indicator">Actions</span>
                                                                            </div>
                                                                        </th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody data-ng-repeat="t in $data">
                                                                    {
                                                                        getSelectionRecordsA().map((row) => {
                                                                            return <tr>
                                                                                <td class="text-center">
                                                                                    <a title="Click on the Invoice Number to view record" data-toggle="tooltip" onClick={() => {
                                                                                        viewInvoice(row.id);
                                                                                    }}>
                                                                                        {row.invno}
                                                                                    </a>
                                                                                </td>
                                                                                <td class="text-center w-10">
                                                                                    {Moment(row.date).format("DD/MM/YYYY")}
                                                                                </td>
                                                                                <td class="text-center">{row.ctype}</td>
                                                                                <td class="currency">{row.tax}</td>
                                                                                <td class="currency">{row.igst}</td>
                                                                                <td class="currency">{row.cgst}</td>
                                                                                <td class="currency">{row.sgst}</td>
                                                                                <td class="currency">{row.cess}</td>
                                                                                <td style={{ display: 'inline-block' }} class="text-center newinv" data-title-text="Actions">
                                                                                    <div className='flex'>
                                                                                        <button type="button" class="btn btn-sm btn-primary nomargin" onClick={() => {
                                                                                            editInvoice(row.id);
                                                                                        }} disabled={fileStatus}><i class="fa fa-pencil" aria-hidden="true"></i><span class="sr-only">text</span>
                                                                                        </button>&nbsp;
                                                                                        <button type="button" class="btn btn-sm btn-danger nomargin" title="Delete" onClick={() => {
                                                                                            showDig('1', { id: row.id, status: 'processed', oid: row.oid });
                                                                                        }} disabled={fileStatus}>
                                                                                            <i class="fa fa-trash"></i><span class="sr-only">text</span>
                                                                                        </button>
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                        })
                                                                    }
                                                                </tbody>
                                                            </table>
                                                            <div ng-table-pagination="params" template-url="templates.pagination">
                                                                <div ng-include="templateUrl">
                                                                    <div class="ng-table-pager" ng-if="params.data.length">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                            {
                                                getSelectionRecordsB().length !== 0 && <div class="row" data-ng-if="b2binvdt.processedInvoice">
                                                    <div class="col-sm-12">
                                                        <h4 data-ng-bind="trans.LBL_PRO_RCD">Pending records (These will be added after validation)</h4>
                                                    </div>
                                                    <br />
                                                    <div class="col-xs-12 col-sm-12">
                                                        <div class="table-responsive">
                                                            <table class="table exp inv tbl table-bordered ng-table" style={{ display: 'table' }}>
                                                                <thead ng-include="templates.header">
                                                                    <tr class="ng-table-sort-header">
                                                                        <th title="" ng-repeat="$column in $columns" class="header sortable">
                                                                            <div class="ng-table-header">
                                                                                <span class="sort-indicator">Revised Credit/Debit Note No.</span>
                                                                            </div>
                                                                        </th>
                                                                        <th title="" class="header ">
                                                                            <div>
                                                                                <span class="sort-indicator">Revised Credit/Debit Note date</span>
                                                                            </div>
                                                                        </th>
                                                                        <th title="" class="header ">
                                                                            <div>
                                                                                <span class="sort-indicator">Note Type</span>
                                                                            </div>
                                                                        </th>
                                                                        <th title="" class="header ">
                                                                            <div>
                                                                                <span class="sort-indicator">Revised taxable value (₹)</span>
                                                                            </div>
                                                                        </th>
                                                                        <th title="" class="header ">
                                                                            <div>
                                                                                <span class="sort-indicator">Integrated Tax (₹)</span>
                                                                            </div>
                                                                        </th>
                                                                        <th title="" class="header ">
                                                                            <div>
                                                                                <span class="sort-indicator">Central tax (₹)</span>
                                                                            </div>
                                                                        </th>
                                                                        <th title="" class="header ">
                                                                            <div>
                                                                                <span class="sort-indicator">State/UT Tax (₹)</span>
                                                                            </div>
                                                                        </th>
                                                                        <th title="" class="header ">
                                                                            <div>
                                                                                <span class="sort-indicator">Cess (₹)</span>
                                                                            </div>
                                                                        </th>
                                                                        <th title="" class="header ">
                                                                            <div>
                                                                                <span class="sort-indicator">Action Taken</span>
                                                                            </div>
                                                                        </th>
                                                                        <th title="" class="header ">
                                                                            <div>
                                                                                <span class="sort-indicator">Status</span>
                                                                            </div>
                                                                        </th>
                                                                        <th title="" class="header ">
                                                                            <div>
                                                                                <span class="sort-indicator">Actions</span>
                                                                            </div>
                                                                        </th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody data-ng-repeat="t in $data">
                                                                    {
                                                                        getSelectionRecordsB().map((row) => {
                                                                            return <tr>
                                                                                <td class="text-center">
                                                                                    <a title="Click on the Invoice Number to view record" data-toggle="tooltip" onClick={() => {
                                                                                        editInvoice(row.id);
                                                                                    }}>
                                                                                        {row.invno}
                                                                                    </a>
                                                                                </td>
                                                                                <td class="text-center w-10">
                                                                                    {Moment(row.date).format("DD/MM/YYYY")}
                                                                                </td>
                                                                                <td class="text-center">{row.ctype}</td>
                                                                                <td class="currency">{row.tax}</td>
                                                                                <td class="currency">{row.igst}</td>
                                                                                <td class="currency">{row.cgst}</td>
                                                                                <td class="currency">{row.sgst}</td>
                                                                                <td class="currency">{row.cess}</td>
                                                                                <td class="text-center">{row.action}</td>
                                                                                <td class="text-center">Processed with error</td>
                                                                                <td style={{ display: 'inline-block' }} class="text-center newinv" data-title-text="Actions">
                                                                                    <div className='flex'>
                                                                                        <button type="button" class="btn btn-sm btn-primary nomargin" onClick={() => {
                                                                                            editInvoice(row.id);
                                                                                        }} disabled={fileStatus}><i class="fa fa-pencil" aria-hidden="true"></i><span class="sr-only">text</span>
                                                                                        </button>&nbsp;
                                                                                        <button type="button" class="btn btn-sm btn-danger nomargin" title="Delete" onClick={() => {
                                                                                            showDig('1', { id: row.id, status: 'pending', oid: row.oid });
                                                                                        }} disabled={fileStatus}>
                                                                                            <i class="fa fa-trash"></i><span class="sr-only">text</span>
                                                                                        </button>
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                        })
                                                                    }
                                                                </tbody>
                                                            </table>
                                                            <div ng-table-pagination="params" template-url="templates.pagination">
                                                                <div ng-include="templateUrl">
                                                                    <div class="ng-table-pager" ng-if="params.data.length">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    {
                                        (getSelectionRecordsA().length !== 0 || getSelectionRecordsB().length !== 0) && <div class="row mar-b">
                                            <div class="col-sm-12 col-xs-12 text-right next-tab-nav">
                                                <button type="button" class="btn btn-default" onClick={() => {
                                                    gotoPath('gstrForm')
                                                }}>Back</button>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
