import React, { useState, useEffect } from 'react';

import { useRecoilState } from 'recoil';
import { pathAtom, loadingAtom, trnLevelAtom, trnAtom, digErrorAtom, dirtyAtom, userIDAtom, userAtom } from '../core/config/atoms';

import ServiceAuth from '../services/serviceAuth';
import ServiceUtility from '../services/serviceUtility';

import { useFormik } from 'formik';
import * as Yup from 'yup';

export default function ComponentLevelD() {

    const [path, setPath] = useRecoilState(pathAtom);
    const [loading, setLoading] = useRecoilState(loadingAtom);
    const [trnLevel, SetTRNLevel] = useRecoilState(trnLevelAtom);
    const [trnno] = useRecoilState(trnAtom);
    const [digError, setDigError] = useRecoilState(digErrorAtom);
    const [isDirty, setIsDirty] = useRecoilState(dirtyAtom);
    const [userID] = useRecoilState(userIDAtom);
    const [userAuth] = useRecoilState(userAtom);

    const [gstRepUser, setGSTRepUser] = useState({});
    const [gstUser, setGSTUser] = useState({});

    const [isValid, setIsValid] = useState(false);

    const authService = new ServiceAuth();
    const utilService = new ServiceUtility();

    const formSchema = Yup.object().shape({
        ar_eid: Yup.string().matches(/^[0-9]{12}[A-Z]{2}[0-9]{1}$/, { message: 'Enter valid entrollment ID' }),
    });

    const { values, errors, touched, dirty, handleChange, handleSubmit, handleReset, resetForm, setValues } = useFormik({
        enableReinitialize: true,
        initialValues: {
            cit_ind: false,
            ar_eid: '',
        },
        validationSchema: formSchema,
        onSubmit: values => {
            if (!values.cit_ind) {
                let data = [...trnLevel.form];
                data[3] = values.cit_ind ? '1' : '0';
                let body = {
                    isGstRep: values.cit_ind ? '1' : '0',
                    gstRepID: values.ar_eid,
                    trnno: trnno,
                    lid: userID,
                    statusArr: data,
                    user: userAuth,
                };
                authService.setGSTUserFormD(body).then((res) => {
                    if (res.statuscode === 200) {
                        setIsDirty(false);
                        SetTRNLevel({ ...trnLevel, level: 'sa5' });
                    } else {
                        setDigError({ title: 'Error', msg: 'Server error please try again!', isToggle: true });
                    }
                });
            } else {
                if (values.ar_eid === '') {
                    setIsValid(true);
                    setDigError({ title: 'Warning', msg: 'Please select and GST representive!', isToggle: true });
                } else {
                    if (gstRepUser.id === undefined || gstRepUser.id !== values.ar_eid) {
                        setIsValid(true);
                        setDigError({ title: 'Warning', msg: 'Please select and GST representive!', isToggle: true });
                    } else {
                        setIsValid(false);
                        let data = [...trnLevel.form];
                        data[3] = values.cit_ind ? '1' : '0';
                        let body = {
                            isGstRep: values.cit_ind ? '1' : '0',
                            gstRepID: values.ar_eid,
                            trnno: trnno,
                            lid: userID,
                            statusArr: data,
                            user: userAuth,
                        };
                        authService.setGSTUserFormD(body).then((res) => {
                            if (res.statuscode === 200) {
                                setIsDirty(false);
                                SetTRNLevel({ ...trnLevel, level: 'sa5' });
                            } else {
                                setDigError({ title: 'Error', msg: 'Server error please try again!', isToggle: true });
                            }
                        });
                    }
                }
            }
        }
    });

    const gotoPath = (path) => {
        SetTRNLevel({ ...trnLevel, level: path });
    }

    const getData = () => {
        setLoading(true);
        const testRegex = RegExp(
            /^[0-9]{12}[A-Z]{2}[0-9]{1}$/
        );
        if (testRegex.test(values.ar_eid)) {
            authService.getGSTRepUserByID({ id: values.ar_eid }).then((res) => {
                if (res.statuscode === 200) {
                    setIsValid(false);
                    setGSTRepUser(res.data[0]);
                } else {
                    setIsValid(true);
                    setGSTRepUser({});
                }
                setLoading(false);
            });
        } else {
            setGSTRepUser({});
            setIsValid(true);
            setLoading(false);
        }
    }

    const loadData = () => {
        (async function () {
            setLoading(true);
            var res = await authService.getGSTUserByTRN({ 'trn': trnno, 'stdOwn': userID });
            setGSTUser(res.data[0]);

            if (res.data[0].isGstRep === "1") {
                setValues({ ...values, cit_ind: true, ar_eid: res.data[0].gstRepID });
                authService.getGSTRepUserByID({ id: res.data[0].gstRepID }).then((res) => {
                    if (res.statuscode === 200) {
                        setGSTRepUser(res.data[0]);
                    } else {
                        setGSTRepUser({});
                    }
                    setLoading(false);
                });
            } else {
                setLoading(false);
                setValues({ ...values, cit_ind: false });
            }

            SetTRNLevel({ ...trnLevel, form: res.data[0]['statusArr'], percentage: utilService.calculatePercentage(res.data[0]['statusArr']) });
            window.scrollTo({
                top: 0,
                behavior: 'smooth',
            });
        })();
    }

    useEffect(() => {
        loadData();
    }, []);

    useEffect(() => {
        setIsDirty(dirty);
    }, [dirty]);

    return (
        <form class="tabpane" autocomplete="off" onSubmit={handleSubmit} onReset={handleReset} noValidate>
            <fieldset>
                <h4>Details of Authorized Representative</h4>
                <p class="mand-text">indicates mandatory fields</p>
                <div class="tbl-format">
                    <div class="row">
                        <div class="inner">
                            <div class="col-xs-12">
                                <label class="reg">Do you have any Authorized Representative?</label>
                                <div class="switch round">
                                    <input id="cit_ind" name="cit_ind" type="checkbox" class="" checked={values.cit_ind} onChange={(e) => {
                                        setValues({ ...values, ar_eid: '' });
                                        setGSTRepUser({});
                                        setIsValid(false);
                                        handleChange(e);
                                    }} />
                                    <label for="cit_ind">
                                        <span class="switch-on" style={{ left: '4px' }}><span>Yes</span></span>
                                        <span class="switch-off" style={{ left: '35px' }}><span>No</span></span>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>

                    {
                        values.cit_ind && <div>
                            <div class="row">
                                <div class="inner">
                                    <div class="col-sm-4 col-xs-12">
                                        <legend class="reg">Type of Authorised Representative</legend>
                                        <input type="radio" name="arep_AR" id="trp" value="TRP" checked="checked" />
                                        <label for="trp">GST Practitioner</label>
                                        <input type="radio" name="arep_AR" id="othrs" value="OTHR" disabled={true} />
                                        <label for="othrs">Other</label>
                                    </div>
                                    <div class="col-sm-4 col-xs-12">
                                        <label class="reg m-cir" for="ar_eid">Enrolment ID</label>
                                        <fieldset>
                                            <div class={`input-group ${touched.ar_eid ? errors.ar_eid ? 'has-error' : 'has-success' : ''}`}>
                                                <input name="ar_eid" id="ar_eid" type="text" class="form-control" placeholder="Enter Enrolment ID" maxLength={15} autofocus="" required="required" onChange={(e) => {
                                                    setIsValid(false);
                                                    handleChange(e);
                                                }} value={values.ar_eid} />
                                                <span class="input-group-btn">
                                                    <button class="btn btn-primary" type="button" onClick={() => {
                                                        getData();
                                                    }}>Search</button>
                                                </span>
                                            </div>
                                            {(errors.ar_eid && touched.ar_eid) && <span class="err">{errors.ar_eid}</span>}
                                            {isValid && !(errors.ar_eid && touched.ar_eid) && <span class="err">Enter valid entrollment ID</span>}
                                        </fieldset>
                                    </div>
                                </div>
                            </div>
                            <fieldset disabled="disabled">
                                <div class="row">
                                    <div class="inner">
                                        <h5 class="col-sm-12 col-xs-12">Name of Person</h5>
                                        <div class="col-sm-4 col-xs-12">
                                            <label class="reg" for="ar_fname">First Name</label>
                                            <input class="form-control" name="ar_fname" id="ar_fname" placeholder="Enter First Name" maxLength={99} value={gstRepUser['fname'] != undefined ? gstRepUser['fname'] : ''} />
                                        </div>
                                        <div class="col-sm-4 col-xs-12">
                                            <label class="reg" for="ar_mname">Middle Name</label>
                                            <input class="form-control" name="ar_mname" id="ar_mname" placeholder="Enter Middle Name" maxLength={99} value={gstRepUser['mname'] != undefined ? gstRepUser['mname'] : ''} />
                                        </div>
                                        <div class="col-sm-4 col-xs-12">
                                            <label class="reg" for="ar_lname">Last Name</label>
                                            <input class="form-control" name="ar_lname" placeholder="Enter Last Name" id="ar_lname" maxLength={99} value={gstRepUser['lname']} />
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="inner">
                                        <div class="col-sm-4 col-xs-12">
                                            <label class="reg" for="ar_des">Designation / Status</label>
                                            <select class="form-control" name="ar_des" id="ar_des">

                                            </select>
                                        </div>
                                        <div class="col-sm-4 col-xs-12">
                                            <label for="ar_mbno" class="reg" >
                                                <i class="type-ico fa fa-mobile"></i>&nbsp;
                                                <span>Mobile Number</span>
                                            </label>
                                            <div class="input-group">
                                                <span class="input-group-addon" id="basic-addon1">+91</span>
                                                <input class="form-control number" name="ar_mbno" id="ar_mbno" maxLength={10} placeholder="Enter Mobile Number" value={gstRepUser['mobile']} />
                                            </div>
                                        </div>
                                        <div class="col-sm-4 col-xs-12">
                                            <label for="ar_em" class="reg">
                                                <i class="fa fa-envelope-open-o"></i>&nbsp;
                                                <span >Email Address</span>
                                            </label>
                                            <input class="form-control" name="ar_em" id="ar_em" placeholder="Enter Email Address" maxLength={256} value={gstRepUser['email']} />
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="inner">
                                        <div class="col-sm-4 col-xs-12">
                                            <label for="pan" class="reg m-cir">Permanent Account Number (PAN)</label>
                                            <input id="pan" name="pan" class="form-control upper-input uppercase" type="text" placeholder="Enter Permanent Account Number (PAN)" maxLength={10} required="" value={gstRepUser['pan']} />
                                        </div>
                                        <div class="col-sm-4 col-xs-12">
                                            <label class="reg" for="uid">Aadhaar Number</label>
                                            <span>
                                                <i class="fa fa-info-circle" data-toggle="modal" data-target="#adhrmdar"></i>
                                            </span>
                                            <input name="uid" id="uid" class="form-control number" placeholder="Enter Aadhaar Number" disabled="" value={gstRepUser['aadhaar']} />
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="inner">
                                        <div class="col-sm-4 col-xs-12">
                                            <label for="ar_tlphnostd" class="reg">
                                                <i class="type-ico fa fa-phone"></i>&nbsp;
                                                <span>Telephone Number (with STD Code)</span>
                                            </label>
                                            <div class="input-group">
                                                <div class="col-sm-3 col-xs-3 addrow" style={{ padding: 0 }}>
                                                    <input type="text" id="ar_tlphnostd" name="ar_tlphnostd" class="number form-control" placeholder="STD " />
                                                </div>
                                                <div class="col-sm-9 col-xs-9 addrow" style={{ padding: 0 }}>
                                                    <input type="text" id="tlphno" name="tlphno" class="number form-control" placeholder="Telephone Number" value={gstRepUser['tel']} />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-4 col-xs-12">
                                            <label for="ar_fxno" class="reg">
                                                <i class="type-ico fa fa-fax"></i>&nbsp;
                                                <span>FAX Number (with STD Code)</span>
                                            </label>
                                            <div class="input-group">
                                                <div class="col-sm-3 col-xs-3 addrow" style={{ padding: 0 }}>
                                                    <input type="text" id="ar_fxno" name="fxnostd" class="number form-control" placeholder="STD " />
                                                </div>
                                                <div class="col-sm-9 col-xs-9 addrow" style={{ padding: 0 }}>
                                                    <input type="text" id="fxno" name="fxno" class="number form-control" placeholder="Fax Number" value={gstRepUser['fax']} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </fieldset>
                        </div>
                    }
                </div>
            </fieldset >
            <div class="row next-tab-nav">
                <div class="col-xs-12 text-right">
                    <button title="Back" type="button" class="btn btn-default backtab" onClick={() => {
                        gotoPath('sa3');
                    }} style={{ marginRight: '8px' }}>Back</button>
                    <button title="Save &amp; Continue" type="submit" class="btn btn-primary nexttab2">Save &amp; Continue</button>
                </div>
            </div>
        </form >
    );
}
