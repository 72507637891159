import React, { useState, useEffect } from 'react';

import { useRecoilState } from 'recoil';
import { pathAtom, loadingAtom, gstrFileAtom, digErrorAtom } from '../../../core/config/atoms';

import { useFormik } from 'formik';
import * as Yup from 'yup';
import Tippy from '@tippyjs/react';
import Scrollbars from 'react-custom-scrollbars';

import ServiceGSTF from '../../../services/serviceGSTF';
import ServiceOption from '../../../services/serviceOptions';

export default function Panel32Home() {

    const [loading, setLoading] = useRecoilState(loadingAtom);
    const [path, setPath] = useRecoilState(pathAtom);
    const [gstrf, setGSTRF] = useRecoilState(gstrFileAtom);
    const [digError, setDigError] = useRecoilState(digErrorAtom);

    const [states, setStates] = useState([]);

    const [submit, setSubmit] = useState(false);
    const [isHelp, setIsHelp] = useState(false);

    const [taba, setTabA] = useState(true);
    const [tabb, setTabB] = useState(false);
    const [tabc, setTabC] = useState(false);

    const [maina, setMainA] = useState(false);
    const [mainb, setMainB] = useState(false);
    const [mainc, setMainC] = useState(false);

    const optionService = new ServiceOption();
    const fileService = new ServiceGSTF();

    const formVSchema = Yup.object().shape({
        lista: Yup.array().of(
            Yup.object().shape({
                isCheck: Yup.boolean().required('This information is required'),
                pos: Yup.string().required('This information is required'),
                total: Yup.string().required('This information is required'),
                igst: Yup.string().required('This information is required')
            })
        ),
        listb: Yup.array().of(
            Yup.object().shape({
                isCheck: Yup.boolean().required('This information is required'),
                pos: Yup.string().required('This information is required'),
                total: Yup.string().required('This information is required'),
                igst: Yup.string().required('This information is required')
            })
        ),
        listc: Yup.array().of(
            Yup.object().shape({
                isCheck: Yup.boolean().required('This information is required'),
                pos: Yup.string().required('This information is required'),
                total: Yup.string().required('This information is required'),
                igst: Yup.string().required('This information is required')
            })
        ),
    });

    const { values, errors, touched, setValues, handleChange, handleSubmit, handleReset, resetForm } = useFormik({
        initialValues: {
            lista: [],
            listb: [],
            listc: []
        },
        validationSchema: formVSchema,
        enableReinitialize: true,
        onSubmit: values => {
            setLoading(true);
            setSubmit(true);

            var newObj = JSON.parse(JSON.stringify(gstrf.data));
            newObj.a32 = { ...values };
            var body = {
                id: gstrf._id,
                data: newObj
            };
            fileService.setGSTFDoc(body).then((res) => {
                setLoading(false);
                setSubmit(false);
                if (res.status) {
                    setGSTRF(res.data);
                    gotoPath('gstr3BForm');
                } else {
                    setDigError({ title: 'Error', msg: 'Server error please try again!', isToggle: true });
                    window.scrollTo({
                        top: 0,
                        behavior: 'smooth',
                    });
                }
            });
        }
    });

    const getFormatvalue = (val) => {
        if (!val) return val;
        let number = val.replace(/[^\d.]/g, "");
        if (val.includes(".")) {
            const a = number.split(".");
            if (a[1].length > 2) {
                a[1] = a[1].substring(0, 2);
            }
            if (a[0].length > 13) {
                a[0] = a[0].substring(0, 13);
            }
            number = a[0] + "." + a[1];
        } else {
            if (number.length > 13) {
                number = number.substring(0, 13);
            }
        }
        return number;
    }

    const gotoPath = (path) => {
        setPath(path);
    }

    const addListA = () => {
        var refVal = JSON.parse(JSON.stringify(values));
        refVal.lista.push({ 'isCheck': false, 'isChange': true, 'pos': '', 'total': '0.00', 'igst': '0.00' });
        setValues({ ...values, ...refVal });
    }

    const removeListA = () => {
        var refVal = JSON.parse(JSON.stringify(values));
        refVal.lista = refVal.lista.filter(rec => !rec.isCheck);
        if (refVal.lista.length === 0) {
            setMainA(false);
        }
        setValues({ ...values, ...refVal });
    }

    const addListB = () => {
        var refVal = JSON.parse(JSON.stringify(values));
        refVal.listb.push({ 'isCheck': false, 'pos': '', 'total': '0.00', 'igst': '0.00' });
        setValues({ ...values, ...refVal });
    }

    const removeListB = () => {
        var refVal = JSON.parse(JSON.stringify(values));
        refVal.listb = refVal.listb.filter(rec => !rec.isCheck);
        if (refVal.listb.length === 0) {
            setMainB(false);
        }
        setValues({ ...values, ...refVal });
    }

    const addListC = () => {
        var refVal = JSON.parse(JSON.stringify(values));
        refVal.listc.push({ 'isCheck': false, 'pos': '', 'total': '0.00', 'igst': '0.00' });
        setValues({ ...values, ...refVal });
    }

    const removeListC = () => {
        var refVal = JSON.parse(JSON.stringify(values));
        refVal.listc = refVal.listc.filter(rec => !rec.isCheck);
        if (refVal.listc.length === 0) {
            setMainC(false);
        }
        setValues({ ...values, ...refVal });
    }

    const selectAllA = () => {
        var refVal = JSON.parse(JSON.stringify(values));
        if (maina) {
            refVal.lista = refVal.lista.map(rec => ({ ...rec, isCheck: false }));
        } else {
            refVal.lista = refVal.lista.map(rec => ({ ...rec, isCheck: true }));
        }
        if (refVal.lista.length > 0) {
            setMainA(!maina);
        }
        setValues({ ...values, ...refVal });
    }

    const selectAllB = () => {
        var refVal = JSON.parse(JSON.stringify(values));
        if (mainb) {
            refVal.listb = refVal.listb.map(rec => ({ ...rec, isCheck: false }));
        } else {
            refVal.listb = refVal.listb.map(rec => ({ ...rec, isCheck: true }));
        }
        if (refVal.listb.length > 0) {
            setMainB(!mainb);
        }
        setValues({ ...values, ...refVal });
    }

    const selectAllC = () => {
        var refVal = JSON.parse(JSON.stringify(values));
        if (mainc) {
            refVal.listc = refVal.listc.map(rec => ({ ...rec, isCheck: false }));
        } else {
            refVal.listc = refVal.listc.map(rec => ({ ...rec, isCheck: true }));
        }
        if (refVal.listc.length > 0) {
            setMainC(!mainc);
        }
        setValues({ ...values, ...refVal });
    }

    const getCustomeErr = (ls, inde, input) => {
        let cls = "";
        if (ls === 'lista') {
            if (values.lista[inde].isChange) {
                cls = "has-error";
            } else {
                if (Object.keys(errors).length !== 0 && Object.keys(touched).length !== 0 && errors.lista !== undefined) {
                    if (input === 'total') {
                        cls = errors.lista[inde].total ? "has-error" : "";
                    }
                    if (input === 'igst') {
                        cls = errors.lista[inde].igst ? "has-error" : "";
                    }
                }
            }
        } else if (ls === 'listb') {
            if (Object.keys(errors).length !== 0 && Object.keys(touched).length !== 0 && errors.listb !== undefined) {
                if (input === 'total') {
                    cls = errors.listb[inde].total ? "has-error" : "";
                }
                if (input === 'igst') {
                    cls = errors.listb[inde].igst ? "has-error" : "";
                }
            }
        } else if (ls === 'listc') {
            if (Object.keys(errors).length !== 0 && Object.keys(touched).length !== 0 && errors.listc !== undefined) {
                if (input === 'total') {
                    cls = errors.listc[inde].total ? "has-error" : "";
                }
                if (input === 'igst') {
                    cls = errors.listc[inde].igst ? "has-error" : "";
                }
            }
        }
        return cls;
    }

    // const callCheack = (e) => {
    //     const keyArr = e.target.id.split('.');
    //     let user = { ...values };
    //     let system = { ...gstrf.data.a32 };

    //     let rhs = parseFloat(user[keyArr[0]][keyArr[1]][keyArr[2]]);
    //     let lhs = parseFloat(system[keyArr[0]][keyArr[1]][keyArr[2]]);

    //     let v = {};

    //     if (lhs > rhs) {
    //         user[keyArr[0]][keyArr[1]]['isChange'] = true;
    //         v = { ...values, ...user };
    //     } else {
    //         user[keyArr[0]][keyArr[1]]['isChange'] = false;
    //         v = { ...values, ...user };
    //     }

    //     setValues({ ...v });
    //     handleChange(e);
    // }

    const openB = () => {
        if (values.listb.length === 0) {
            values.listb.push({ 'isCheck': false, 'pos': '', 'total': '0.00', 'igst': '0.00' });
        }
        if (tabb) {
            setTabA(false);
            setTabB(false);
            setTabC(false);
        } else {
            setTabA(false);
            setTabB(true);
            setTabC(false);
        }
    }

    const openC = () => {
        if (values.listc.length === 0) {
            values.listc.push({ 'isCheck': false, 'pos': '', 'total': '0.00', 'igst': '0.00' });
        }
        if (tabc) {
            setTabA(false);
            setTabB(false);
            setTabC(false);
        } else {
            setTabA(false);
            setTabB(false);
            setTabC(true);
        }
    }

    const loadData = () => {
        (async function () {
            var resB = await optionService.getStates({});
            setStates(resB.data);

            var refs = JSON.parse(JSON.stringify(gstrf.data.a32));
            if (refs.lista.length === 0) {
                refs.lista.push({ 'isCheck': false, 'pos': '', 'total': '0.00', 'igst': '0.00' });
            }

            setValues({ ...values, ...refs });
            if (gstrf.status === "done" || gstrf.status === "submitted") {
                setSubmit(true);
            }

            setLoading(false);
            window.scrollTo({
                top: 0,
                behavior: 'smooth',
            });
        })();
    }

    useEffect(() => {
        loadData();
    }, []);

    return (
        <>
            {
                isHelp && <div className='w-full h-full overflow-hidden fixed top-0 z-50 flex items-center justify-center bg-black bg-opacity-60'>
                    <Scrollbars>
                        <div className="modal-dialog" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => { setIsHelp(false) }}>
                                        <span aria-hidden="true">×</span>
                                    </button>
                                    <h4 className="modal-title text-[#0b1e59d9]">Inter-state Supplies</h4>
                                </div>
                                <div className="modal-body">
                                    <p>
                                        <span data-ng-bind="trans.INTERSTATESUP3B_230">Out of supplies shown in earlier Table (3.1) and Table 3.1.1(i) (Effective from Tax period </span>
                                        <span data-ng-bind="ecomon">July </span>
                                        <span data-ng-bind="ecoyear">2022</span>
                                        <span data-ng-bind="trans.INTERSTATESUP3B_233">), declare the details of inter-State supplies made to unregistered persons, composition taxable persons and UIN holders in the respective sub-sections along with the place of supply.</span>
                                    </p>
                                    <p data-ng-bind="trans.INTERSTATESUP3B_231">The details mentioned in this Table will not be considered in computation of output liability.</p>
                                    <p data-ng-bind="trans.INTERSTATESUP3B_232">Please ensure the details of inter-State sales declared here is part of the declaration in Table 3.1 and Table 3.1.1(i) above and it doesn't exceed the amount declared over there.</p>
                                    <div data-ng-if="showNilQuestionaire">
                                        <p></p><h4 className="text-[#0b1e59d9] text-3xl"><span data-ng-bind="trans.DASHBOARD3B_1">Facilitation in filing GSTR-3B</span></h4><p></p>
                                        <ul className='pl-16 list-disc'>
                                            <li className="list-item"><p><span data-ng-bind="trans.INTERSTATESUP3B_HELP1">The summary is generated for table 3.2 based on values declared in GSTR-1.</span></p></li>
                                            <li className="list-item"><p><span data-ng-bind="trans.IOSUP3B_HELP4">The values will be auto-drafted in each cells and would be editable.</span></p></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-primary" data-dismiss="modal" data-ng-bind="trans.INTERSTATESUP3B_235" onClick={() => { setIsHelp(false) }}>Close</button>
                                </div>
                            </div>
                        </div>
                    </Scrollbars>
                </div>
            }
            <div className="content-wrapper fade-ng-cloak">
                <div className="container">
                    <div className="mypage">
                        <div className="row">
                            <div className="col-xs-10">
                                <div>
                                    <ol className="breadcrumb" data-ng-controller="crumbCtrl">
                                        <li>
                                            <a onClick={() => { gotoPath('gstr') }}>Dashboard</a></li>
                                        <li>
                                            <a onClick={() => { gotoPath('gstRetrun') }}>Returns</a>
                                        </li>
                                        <li>
                                            <a onClick={() => {
                                                setLoading(true);
                                                gotoPath('gstr3BForm')
                                            }}>GSTR-3B</a>
                                        </li>
                                        <li>
                                            <span ng-switch-when="true">Inter-state Supplies</span>
                                        </li>
                                    </ol>
                                </div>
                            </div>
                            <div className="col-xs-2">
                                <div className="lang dropdown">
                                    <span className="dropdown-toggle" data-toggle="dropdown" data-ng-bind="selectedLang">English</span>
                                    <ul className="dropdown-menu lang-dpdwn">
                                        <li>
                                            <a data-ng-bind="language.nm">English</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div>
                            {
                                !loading && <form autocomplete="off" novalidate="" onSubmit={handleSubmit} onReset={handleReset}>
                                    <div className="container" data-ng-init="getInterStateSupData()">
                                        <div className="row invsumm">
                                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                <div className="row">
                                                    <h4 className="col-xs-11" data-ng-if="ecoSupFlag" data-ng-bind="trans.HEAD_INTER_STATE_SUPPLIES_NEW">3.2 Of the supplies shown in 3.1(a) and 3.1.1(i), details of inter-state supplies made to unregistered persons, composition taxable persons and UIN holders</h4>
                                                    <h4 className="col-xs-1">
                                                        <a data-toggle="modal" onClick={() => { setIsHelp(true) }}>Help</a>
                                                        <i className="fa fa-question-circle" aria-hidden="true"></i>
                                                    </h4>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="tabpane">
                                            <div ng-show="statusMsg &amp;&amp; statusMessage" className="alert alert-msg alert-success alert-dismissible accept">
                                                <button type="button" className="close" data-ng-click="hideAlert()" aria-hidden="true">
                                                    <i className="fa fa-times"></i>
                                                </button>
                                                Last Save request has been processed successfully.
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                    <div className="panel-group" id="accordion">
                                                        <div className="panel panel-default">
                                                            <div className="panel-heading">
                                                                <a className="accordion-toggle" onClick={() => {
                                                                    if (taba) {
                                                                        setTabA(false);
                                                                        setTabB(false);
                                                                        setTabC(false);
                                                                    } else {
                                                                        setTabA(true);
                                                                        setTabB(false);
                                                                        setTabC(false);
                                                                    }
                                                                }}>
                                                                    <h4 className="panel-title">Supplies made to Unregistered Persons
                                                                        <i className={`pull-right ${taba ? 'fa fa-minus' : 'fa fa-plus'}`}></i>
                                                                    </h4>
                                                                </a>
                                                            </div>
                                                            <div id="collapse1" className={`panel-collapse ${taba ? 'in' : 'hidden'}`}>
                                                                <div className="panel-body">
                                                                    <div className="rettbl-format">
                                                                        <div className="table-responsive">
                                                                            <table className="table tbl inv exp table-bordered" style={{ display: 'table' }}>
                                                                                <thead>
                                                                                    <tr>
                                                                                        <th>
                                                                                            <input type="checkbox" className="chkbx ng-pristine ng-untouched ng-valid ng-empty" checked={maina} onClick={() => {
                                                                                                selectAllA();
                                                                                            }} disabled={gstrf.status === "done" || gstrf.status === "submitted"} />
                                                                                            <label for="main"></label>
                                                                                        </th>
                                                                                        <th className="text-center" data-ng-bind="trans.LBL_GSTR3B_POS">Place of Supply (State/UT)</th>
                                                                                        <th className="text-center" data-ng-bind="trans.LBL_TOTAL_TAXABLE_VAL">Total Taxable value (₹)</th>
                                                                                        <th className="text-center" data-ng-bind="trans.LBL_GST3B_AMT_ITAX">Amount of Integrated Tax (₹)</th>
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                    {
                                                                                        values.lista.length !== 0 && values.lista.map((item, index) => {
                                                                                            return <tr key={index}>
                                                                                                <th>
                                                                                                    <input type="checkbox" name={`lista.${index}.isCheck`} id={`lista.${index}.isCheck`} checked={values.lista[index].isCheck} className="chkbx ng-pristine ng-untouched ng-valid ng-empty" onChange={handleChange} disabled={gstrf.status === "done" || gstrf.status === "submitted"} />
                                                                                                    <label for="objUnreg_0"></label>
                                                                                                </th>
                                                                                                <td>
                                                                                                    <select name={`lista.${index}.pos`} id={`lista.${index}.pos`} className="form-control" onChange={handleChange} disabled={gstrf.status === "done" || gstrf.status === "submitted"}>
                                                                                                        <option value="">Select</option>
                                                                                                        {
                                                                                                            states.length !== 0 && states.map((s) => {
                                                                                                                return <option value={s} selected={values.lista[index].pos == s}>{s}</option>
                                                                                                            })
                                                                                                        }
                                                                                                    </select>
                                                                                                    {(Object.keys(errors).length !== 0 && Object.keys(touched).length !== 0 && errors.lista !== undefined && errors.lista[index].pos) && <span className='err'>{errors.lista[index].pos}</span>}
                                                                                                </td>
                                                                                                <td className={`col-sm-4 ${getCustomeErr('lista', index, 'total')}`}>
                                                                                                    <input name={`lista.${index}.total`} id={`lista.${index}.total`} className="form-control formedit newinv currency" value={values.lista[index].total} onChange={e => {
                                                                                                        const formatValue = getFormatvalue(e.target.value);
                                                                                                        e.target.value = formatValue;
                                                                                                        handleChange(e);
                                                                                                    }} onBlur={e => {
                                                                                                        if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                                            e.target.value = e.target.value + ".00";
                                                                                                        }
                                                                                                        handleChange(e);
                                                                                                        // callCheack(e);
                                                                                                    }} disabled={gstrf.status === "done" || gstrf.status === "submitted"} />
                                                                                                    {(Object.keys(errors).length !== 0 && Object.keys(touched).length !== 0 && errors.lista !== undefined && errors.lista[index].total) && <span className='err'>{errors.lista[index].total}</span>}
                                                                                                </td>
                                                                                                <td className={`col-sm-4 ${getCustomeErr('lista', index, 'igst')}`}>
                                                                                                    <Tippy content="Total amount of Integrated tax declared across all sections of Table 3.2 should not exceed the sum of amount of Integrated tax declared in row (A) of Table 3.1 and row (i) of Table 3.1.1" placement='top' arrow={false}>
                                                                                                        <input name={`lista.${index}.igst`} id={`lista.${index}.igst`} className="form-control formedit newinv currency" value={values.lista[index].igst} onChange={e => {
                                                                                                            const formatValue = getFormatvalue(e.target.value);
                                                                                                            e.target.value = formatValue;
                                                                                                            handleChange(e);
                                                                                                        }} onBlur={e => {
                                                                                                            if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                                                e.target.value = e.target.value + ".00";
                                                                                                            }
                                                                                                            handleChange(e);
                                                                                                            // callCheack(e);
                                                                                                        }} disabled={gstrf.status === "done" || gstrf.status === "submitted"} />
                                                                                                    </Tippy>
                                                                                                    {(Object.keys(errors).length !== 0 && Object.keys(touched).length !== 0 && errors.lista !== undefined && errors.lista[index].igst) && <span className='err'>{errors.lista[index].igst}</span>}
                                                                                                </td>
                                                                                            </tr>
                                                                                        })
                                                                                    }
                                                                                </tbody>
                                                                            </table>
                                                                            <div className="row pull-right">
                                                                                <button type="button" className="btn btn-primary" onClick={() => { addListA() }} disabled={gstrf.status === "done" || gstrf.status === "submitted"}>ADD</button>&nbsp;
                                                                                <button type="button" className="btn btn-danger" onClick={() => { removeListA() }} disabled={gstrf.status === "done" || gstrf.status === "submitted"}>REMOVE</button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="panel panel-default">
                                                            <div className="panel-heading">
                                                                <a className="accordion-toggle" onClick={() => {
                                                                    openB();
                                                                }}>
                                                                    <h4 className="panel-title">Supplies made to Composition Taxable Persons
                                                                        <i className={`pull-right ${tabb ? 'fa fa-minus' : 'fa fa-plus'}`}></i>
                                                                    </h4>
                                                                </a>
                                                            </div>
                                                            <div id="collapse2" className={`panel-collapse ${tabb ? 'in' : 'hidden'}`}>
                                                                <div className="panel-body">
                                                                    <div className="rettbl-format">
                                                                        <div className="table-responsive">
                                                                            <table className="table tbl inv exp table-bordered" style={{ display: 'table' }}>
                                                                                <thead>
                                                                                    <tr>
                                                                                        <th>
                                                                                            <input type="checkbox" className="chkbx ng-pristine ng-untouched ng-valid ng-empty" checked={mainb} onClick={() => {
                                                                                                selectAllB();
                                                                                            }} disabled={gstrf.status === "done" || gstrf.status === "submitted"} />
                                                                                            <label for="main"></label>
                                                                                        </th>
                                                                                        <th className="text-center" data-ng-bind="trans.LBL_GSTR3B_POS">Place of Supply (State/UT)</th>
                                                                                        <th className="text-center" data-ng-bind="trans.LBL_TOTAL_TAXABLE_VAL">Total Taxable value (₹)</th>
                                                                                        <th className="text-center" data-ng-bind="trans.LBL_GST3B_AMT_ITAX">Amount of Integrated Tax (₹)</th>
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                    {
                                                                                        values.listb.length !== 0 && values.listb.map((item, index) => {
                                                                                            return <tr key={index}>
                                                                                                <th>
                                                                                                    <input type="checkbox" name={`listb.${index}.isCheck`} id={`listb.${index}.isCheck`} checked={values.listb[index].isCheck} className="chkbx ng-pristine ng-untouched ng-valid ng-empty" onChange={handleChange} disabled={gstrf.status === "done" || gstrf.status === "submitted"} />
                                                                                                    <label for="objUnreg_0"></label>
                                                                                                </th>
                                                                                                <td>
                                                                                                    <select name={`listb.${index}.pos`} id={`listb.${index}.pos`} className="form-control" onChange={handleChange} disabled={gstrf.status === "done" || gstrf.status === "submitted"}>
                                                                                                        <option value="">Select</option>
                                                                                                        {
                                                                                                            states.length !== 0 && states.map((s) => {
                                                                                                                return <option value={s} selected={values.listb[index].pos == s}>{s}</option>
                                                                                                            })
                                                                                                        }
                                                                                                    </select>
                                                                                                    {(Object.keys(errors).length !== 0 && Object.keys(touched).length !== 0 && errors.listb !== undefined && errors.listb[index].pos) && <span className='err'>{errors.listb[index].pos}</span>}
                                                                                                </td>
                                                                                                <td className={`col-sm-4 ${getCustomeErr('listb', index, 'total')}`}>
                                                                                                    <input name={`listb.${index}.total`} id={`listb.${index}.total`} className="form-control formedit newinv currency" value={values.listb[index].total} onChange={e => {
                                                                                                        const formatValue = getFormatvalue(e.target.value);
                                                                                                        e.target.value = formatValue;
                                                                                                        handleChange(e);
                                                                                                    }} onBlur={e => {
                                                                                                        if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                                            e.target.value = e.target.value + ".00";
                                                                                                        }
                                                                                                        handleChange(e);
                                                                                                    }} disabled={gstrf.status === "done" || gstrf.status === "submitted"} />
                                                                                                    {(Object.keys(errors).length !== 0 && Object.keys(touched).length !== 0 && errors.listb !== undefined && errors.listb[index].total) && <span className='err'>{errors.listb[index].total}</span>}
                                                                                                </td>
                                                                                                <td className={`col-sm-4 ${getCustomeErr('listb', index, 'igst')}`}>
                                                                                                    <Tippy content="Total amount of Integrated tax declared across all sections of Table 3.2 should not exceed the sum of amount of Integrated tax declared in row (A) of Table 3.1 and row (i) of Table 3.1.1" placement='top' arrow={false}>
                                                                                                        <input name={`listb.${index}.igst`} id={`listb.${index}.igst`} className="form-control formedit newinv currency" value={values.listb[index].igst} onChange={e => {
                                                                                                            const formatValue = getFormatvalue(e.target.value);
                                                                                                            e.target.value = formatValue;
                                                                                                            handleChange(e);
                                                                                                        }} onBlur={e => {
                                                                                                            if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                                                e.target.value = e.target.value + ".00";
                                                                                                            }
                                                                                                            handleChange(e);
                                                                                                        }} disabled={gstrf.status === "done" || gstrf.status === "submitted"} />
                                                                                                    </Tippy>
                                                                                                    {(Object.keys(errors).length !== 0 && Object.keys(touched).length !== 0 && errors.listb !== undefined && errors.listb[index].igst) && <span className='err'>{errors.listb[index].igst}</span>}
                                                                                                </td>
                                                                                            </tr>
                                                                                        })
                                                                                    }
                                                                                </tbody>
                                                                            </table>
                                                                            <div className="row pull-right">
                                                                                <button type="button" className="btn btn-primary" onClick={() => { addListB() }} disabled={gstrf.status === "done" || gstrf.status === "submitted"}>ADD</button>&nbsp;
                                                                                <button type="button" className="btn btn-danger" onClick={() => { removeListB() }} disabled={gstrf.status === "done" || gstrf.status === "submitted"}>REMOVE</button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="panel panel-default">
                                                            <div className="panel-heading">
                                                                <a data-toggle="collapse" onClick={() => {
                                                                    openC();
                                                                }}>
                                                                    <h4 className="panel-title">Supplies made to UIN holders
                                                                        <i className={`pull-right ${tabc ? 'fa fa-minus' : 'fa fa-plus'}`}></i>
                                                                    </h4>
                                                                </a>
                                                            </div>
                                                            <div id="collapse3" className={`panel-collapse ${tabc ? 'in' : 'hidden'}`}>
                                                                <div className="panel-body">
                                                                    <div className="rettbl-format">
                                                                        <div className="table-responsive">
                                                                            <table className="table tbl inv exp table-bordered" style={{ display: 'table' }}>
                                                                                <thead>
                                                                                    <tr>
                                                                                        <th>
                                                                                            <input type="checkbox" className="chkbx ng-pristine ng-untouched ng-valid ng-empty" checked={mainb} onClick={() => {
                                                                                                selectAllC();
                                                                                            }} disabled={gstrf.status === "done" || gstrf.status === "submitted"} />
                                                                                            <label for="main"></label>
                                                                                        </th>
                                                                                        <th className="text-center" data-ng-bind="trans.LBL_GSTR3B_POS">Place of Supply (State/UT)</th>
                                                                                        <th className="text-center" data-ng-bind="trans.LBL_TOTAL_TAXABLE_VAL">Total Taxable value (₹)</th>
                                                                                        <th className="text-center" data-ng-bind="trans.LBL_GST3B_AMT_ITAX">Amount of Integrated Tax (₹)</th>
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                    {
                                                                                        values.listc.length !== 0 && values.listc.map((item, index) => {
                                                                                            return <tr key={index}>
                                                                                                <th>
                                                                                                    <input type="checkbox" name={`listc.${index}.isCheck`} id={`listc.${index}.isCheck`} checked={values.listc[index].isCheck} className="chkbx ng-pristine ng-untouched ng-valid ng-empty" onChange={handleChange} disabled={gstrf.status === "done" || gstrf.status === "submitted"} />
                                                                                                    <label for="objUnreg_0"></label>
                                                                                                </th>
                                                                                                <td>
                                                                                                    <select name={`listc.${index}.pos`} id={`listc.${index}.pos`} className="form-control" onChange={handleChange} disabled={gstrf.status === "done" || gstrf.status === "submitted"}>
                                                                                                        <option value="">Select</option>
                                                                                                        {
                                                                                                            states.length !== 0 && states.map((s) => {
                                                                                                                return <option value={s} selected={values.listc[index].pos == s}>{s}</option>
                                                                                                            })
                                                                                                        }
                                                                                                    </select>
                                                                                                    {(Object.keys(errors).length !== 0 && Object.keys(touched).length !== 0 && errors.listc !== undefined && errors.listc[index].pos) && <span className='err'>{errors.listc[index].pos}</span>}
                                                                                                </td>
                                                                                                <td className={`col-sm-4 ${getCustomeErr('listc', index, 'total')}`}>
                                                                                                    <input name={`listc.${index}.total`} id={`listc.${index}.total`} className="form-control formedit newinv currency" value={values.listc[index].total} onChange={e => {
                                                                                                        const formatValue = getFormatvalue(e.target.value);
                                                                                                        e.target.value = formatValue;
                                                                                                        handleChange(e);
                                                                                                    }} onBlur={e => {
                                                                                                        if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                                            e.target.value = e.target.value + ".00";
                                                                                                        }
                                                                                                        handleChange(e);
                                                                                                    }} disabled={gstrf.status === "done" || gstrf.status === "submitted"} />
                                                                                                    {(Object.keys(errors).length !== 0 && Object.keys(touched).length !== 0 && errors.listc !== undefined && errors.listc[index].total) && <span className='err'>{errors.listc[index].total}</span>}
                                                                                                </td>
                                                                                                <td className={`col-sm-4 ${getCustomeErr('listc', index, 'igst')}`}>
                                                                                                    <Tippy content="Total amount of Integrated tax declared across all sections of Table 3.2 should not exceed the sum of amount of Integrated tax declared in row (A) of Table 3.1 and row (i) of Table 3.1.1" placement='top' arrow={false}>
                                                                                                        <input name={`listc.${index}.igst`} id={`listc.${index}.igst`} className="form-control formedit newinv currency" value={values.listc[index].igst} onChange={e => {
                                                                                                            const formatValue = getFormatvalue(e.target.value);
                                                                                                            e.target.value = formatValue;
                                                                                                            handleChange(e);
                                                                                                        }} onBlur={e => {
                                                                                                            if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                                                e.target.value = e.target.value + ".00";
                                                                                                            }
                                                                                                            handleChange(e);
                                                                                                        }} disabled={gstrf.status === "done" || gstrf.status === "submitted"} />
                                                                                                    </Tippy>
                                                                                                    {(Object.keys(errors).length !== 0 && Object.keys(touched).length !== 0 && errors.listc !== undefined && errors.listc[index].igst) && <span className='err'>{errors.listc[index].igst}</span>}
                                                                                                </td>
                                                                                            </tr>
                                                                                        })
                                                                                    }
                                                                                </tbody>
                                                                            </table>
                                                                            <div className="row pull-right">
                                                                                <button type="button" className="btn btn-primary" onClick={() => { addListC() }} disabled={gstrf.status === "done" || gstrf.status === "submitted"}>ADD</button>&nbsp;
                                                                                <button type="button" className="btn btn-danger" onClick={() => { removeListC() }} disabled={gstrf.status === "done" || gstrf.status === "submitted"}>REMOVE</button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="btn-toolbar">
                                                <button type="submit" className="btn btn-primary pull-right" disabled={submit}>CONFIRM</button>
                                                <button type="button" className="btn btn-primary pull-right" onClick={() => {
                                                    setLoading(true);
                                                    gotoPath('gstr3BForm')
                                                }}>CANCEL</button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
