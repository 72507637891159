import React, { useState, useEffect } from 'react';

import Moment from 'react-moment';

import { useRecoilState } from 'recoil';
import { pathAtom, userIDAtom } from '../core/config/atoms';

import ServiceAuth from '../services/serviceAuth';

export default function PanelSuccessRegistration() {

    const [path, setPath] = useRecoilState(pathAtom);
    const [userID] = useRecoilState(userIDAtom);

    const [registrationData, setRegistrationData] = useState([]);

    const authService = new ServiceAuth();

    const gotoPath = (path) => {
        setPath(path);
    }

    useEffect(() => {
        (async function () {
            var res = await authService.getGSTUserByTRN({ 'trn': localStorage.getItem('trn'), 'stdOwn': userID });
            setRegistrationData(res.data);
        })();
    }, []);

    return (
        <div class="content-wrapper">
            <div class="dimmer-holder" style={{ display: 'none' }}>
                <div id="dimmer"></div>
            </div>
            <div class="container">
                <div class="mypage">
                    <div class="row">
                        <div class="col-xs-10">
                            <div name="Home">
                                <ol class="breadcrumb">
                                    <li>
                                        <a onClick={() => {
                                            gotoPath('home');
                                        }}>Home</a>
                                    </li>
                                    <li>
                                        <a>Registration</a>
                                    </li>
                                    <li>
                                        <span ng-switch-when="true">Verify</span>
                                    </li>
                                </ol>
                            </div>
                        </div>
                        <div class="col-xs-2">
                            <div class="lang dropdown">
                                <span class="dropdown-toggle">English</span>
                                <ul class="dropdown-menu lang-dpdwn">
                                    <li data-ng-repeat="language in languages">
                                        <a>English</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="content-pane" style={{ minHeight: '473.4px' }}>
                        <div className='p-6'>
                            <p class="alert  alert-success " id="alertMsg" data-ng-hide="alertMsgSuccess"><span data-ng-bind="successMsg"></span>You have successfully submitted Part A of the registration process. Your Temporary Reference Number (TRN) is {registrationData.length === 0 ? '' : registrationData[0]['trn']}.</p>
                            <p>Usting this TRN you can access the application from My saved Applications and submit on GST Portal. Part B of the application form needs to be completed within 15 days, i.e. by {
                                registrationData.length === 0 ? '' : <Moment format="DD/MM/YYYY">{registrationData[0]['expiryAt']}</Moment>
                            } using this TRN.</p>
                        </div>
                        <div class="row">
                            <div class="col-xs-12">
                                <button type="submit" class="btn btn-block btn-primary" style={{ width: '140px', float: 'right', marginRight: '20px' }} onClick={() => {
                                    setPath('register');
                                }}>Proceed</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
