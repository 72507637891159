import React, { useState } from 'react';

import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

import '../../../assets/styles/slider.css';

import slideA from '../../../assets/images/slide A.png';
import slideB from '../../../assets/images/slide B.png';
import slideC from '../../../assets/images/slide C.png';

export default function Slider() {

    const [intervalz, setIntervalz] = useState(10000);

    const gotoURL = (url) => {
        window.open(url);
    }

    const onChange = (index, item) => {
        setIntervalz(item.props["data-interval"]);
    };

    return (
        <Carousel
            onChange={onChange}
            autoPlay
            axis='horizontal'
            interval={intervalz}
            infiniteLoop={true}
            showThumbs={false}
        >
            <div data-interval={4000} onClick={() => { gotoURL("https://itaaedu.com/gst-pro-advanced/") }} className="cursor-pointer slide">
                <img alt="T1" src={slideA} />
            </div>
            <div data-interval={10000} onClick={() => { gotoURL("https://itaaedu.com/advanced-accounting/") }} className="cursor-pointer slide">
                <img alt="T13" src={slideC} />
            </div>
            <div data-interval={4000} onClick={() => { gotoURL("https://itaaedu.com/accounting-basic-combo/") }} className="cursor-pointer slide">
                <img alt="T12" src={slideB} />
            </div>
        </Carousel>
    );
}
