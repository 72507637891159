import React, { useState, useEffect } from 'react';

import { useRecoilState } from 'recoil';
import { loadingAtom, pathAtom, gstrpathAtom, digErrorAtom, gstrUserAtom, returnDateAtom, gstrQueryAtom, userIDAtom } from '../../../core/config/atoms';

import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import { ThemeProvider } from "@material-ui/styles";
import { createMuiTheme } from "@material-ui/core";
import DateFnsUtils from '@date-io/date-fns';
import Moment from 'moment';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Dialog } from '@headlessui/react';

import imgLoader from '../../../assets/images/cloading.gif';

import ServiceGSTR from '../../../services/serviceGSTR';
import ServiceUtility from '../../../services/serviceUtility';
import ServiceGSTN from '../../../services/serviceGSTN';
import ServiceOption from '../../../services/serviceOptions';

export default function PanelAB2BForm() {

    const [path, setPath] = useRecoilState(pathAtom);
    const [gstrPath, setGSTRPath] = useRecoilState(gstrpathAtom);
    const [loading, setLoading] = useRecoilState(loadingAtom);
    const [digError, setDigError] = useRecoilState(digErrorAtom);
    const [GSTUser] = useRecoilState(gstrUserAtom);
    const [gDate] = useRecoilState(returnDateAtom);
    const [query, setQuery] = useRecoilState(gstrQueryAtom);
    const [userID] = useRecoilState(userIDAtom);

    const [mainDate, setMainDate] = useState(Moment(gDate).add('month', 1).toDate());

    const [selOption, setSelOption] = useState('');
    const [invno, setInvno] = useState('');
    const [amid, setAmid] = useState('');
    const [title, setTitle] = useState('');
    const [editid, setEditid] = useState('');
    const [errGstn, setErrGstn] = useState('');
    const [isErrorAlert, setIsErrorAlert] = useState("");
    const [OldData, setOldData] = useState({});

    const [isEdit, setIsEdit] = useState(false);
    const [isView, setIsView] = useState(false);
    const [submit, setSubmit] = useState(false);
    const [isOld, setIsOld] = useState(false);
    const [isMaster, setIsMaster] = useState(false);
    const [isItem, setIsItem] = useState(false);
    const [isDigOpen, setIsDigOpen] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [isItems, setIsItems] = useState(false);

    const returnService = new ServiceGSTR();
    const utilService = new ServiceUtility();
    const gstnService = new ServiceGSTN();
    const optionService = new ServiceOption();

    const [states, setStates] = useState([]);

    const materialTheme = createMuiTheme({
        overrides: {
            MuiTypography: {
                subtitle1: {
                    fontSize: '16px',
                },
                caption: {
                    fontSize: '16px',
                    fontWeight: 'bold'
                },
                body1: {
                    "fontSize": '16px',
                },
                body2: {
                    "fontSize": '16px',
                }
            },
            MuiPickersToolbar: {
                toolbar: {
                    backgroundColor: '#239B56',
                },
            },
            MuiPickersDay: {
                day: {
                    color: '#17202A',
                },
                daySelected: {
                    backgroundColor: '#239B56',
                },
                dayDisabled: {
                    color: '#808B96',
                },
                current: {
                    color: '#239B56',
                },
            },
            MuiButton: {
                textPrimary: {
                    color: '#2E86C1',
                    fontSize: '12px',
                    border: '1px solid #2E86C1',
                    backgroundColor: 'transprent'
                }
            },
        },
    });

    const formVSchema = Yup.object().shape({
        ruid: Yup.string().matches(/^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[0-9]{1}Z[0-9A-Z]{1}$/, { message: 'The GSTIN/UIN is invalid. Please enter a valid GSTIN/UIN' }).required('This information is required'),
        name: Yup.string().required('This information is required'),
        pos: Yup.string().required('This information is required'),
        supplyty: Yup.string().required('This information is required'),
        inv_no: Yup.string().matches(/^(?=[/-0]*[a-zA-Z0-9/-]*[a-zA-Z1-9]+[a-zA-Z0-9/-]*).{1,16}$/, { message: 'Please enter valid invoice number' }).max(16, { message: 'Please enter valid invoice number' }).required('This information is required'),
        invdate: Yup.string().required('This information is required'),
        rinv_no: Yup.string().matches(/^(?=[/-0]*[a-zA-Z0-9/-]*[a-zA-Z1-9]+[a-zA-Z0-9/-]*).{1,16}$/, { message: 'Please enter valid invoice number' }).max(16, { message: 'Please enter valid invoice number' }).required('This information is required'),
        rinvdate: Yup.string().required('This information is required'),
        invval: Yup.string().max(13, { message: 'Please enter valid amout' }).required('This information is required'),
    });

    const { values, errors, touched, setValues, handleChange, handleSubmit, handleReset, resetForm } = useFormik({
        initialValues: {
            rev_charge: '',
            ruid: '',
            name: '',
            sup_name: '',
            pos: '',
            supplyty: '',
            inv_no: '',
            invdate: '',
            rinv_no: '',
            rinvdate: '',
            invval: '',
            type: false,
            oid: '',
            error: '',
            items: {
                a: {
                    total: '',
                    taxa: '',
                    taxb: '',
                    cess: '',
                },
                b: {
                    total: '',
                    taxa: '',
                    taxb: '',
                    cess: '',
                },
                c: {
                    total: '',
                    taxa: '',
                    taxb: '',
                    cess: '',
                },
                d: {
                    total: '',
                    taxa: '',
                    taxb: '',
                    cess: '',
                },
                e: {
                    total: '',
                    taxa: '',
                    taxb: '',
                    cess: '',
                },
                f: {
                    total: '',
                    taxa: '',
                    taxb: '',
                    cess: '',
                },
                g: {
                    total: '',
                    taxa: '',
                    taxb: '',
                    cess: '',
                },
                h: {
                    total: '',
                    taxa: '',
                    taxb: '',
                    cess: '',
                },
                i: {
                    total: '',
                    taxa: '',
                    taxb: '',
                    cess: '',
                },
                j: {
                    total: '',
                    taxa: '',
                    taxb: '',
                    cess: '',
                },
                k: {
                    total: '',
                    taxa: '',
                    taxb: '',
                    cess: '',
                },
                l: {
                    total: '',
                    taxa: '',
                    taxb: '',
                    cess: '',
                }
            }
        },
        validationSchema: formVSchema,
        onSubmit: values => {
            if (checkItems()) {
                setIsItems(false);
                if (errGstn === "") {
                    setSubmit(true);
                    let body = {};
                    if (isEdit) {
                        values.status = 'EDIT';
                        body = {
                            id: editid,
                            data: values,
                        }
                        returnService.updateGstrDoc(body).then((res) => {
                            if (res.status) {
                                gotoGstrPath('ab2ba');
                                setQuery({ type: 'done', data: 'done' });
                            } else {
                                setDigError({ title: 'Error', msg: 'Server error please try again!', isToggle: true });
                            }
                            setSubmit(false);
                        });
                    } else {
                        if (!isOld) {
                            body = {
                                gstn: values.ruid,
                                name: values.name,
                                mname: values.sup_name,
                                type: values.type,
                                lid: userID,
                            }
                            gstnService.setGSTN(body).then((res) => {
                                if (res.status) {
                                    setIsOld(true);
                                } else {
                                    setIsOld(false);
                                }
                            });
                        } else {
                            if (values.sup_name.length !== 0) {
                                body = {
                                    id: values.ruid,
                                    mname: values.sup_name,
                                    type: values.type,
                                    lid: userID,
                                }
                                gstnService.updateGSTN(body).then((res) => {
                                    if (res.status) {
                                        setIsOld(true);
                                    } else {
                                        setIsOld(false);
                                    }
                                });
                            }
                        }
                        values.status = 'ADD';
                        body = {
                            month: gDate,
                            lid: userID,
                            type: 'b2ba',
                            oid: amid,
                            data: values,
                            odata: OldData,
                        }
                        returnService.setGstrDoc(body).then((res) => {
                            if (res.status) {
                                if (res.statuscode === 202) {
                                    setInvno(values.inv_no);
                                } else {
                                    setQuery({ type: 'done', data: 'done' });
                                    gotoGstrPath('ab2ba');
                                }
                            } else {
                                setDigError({ title: 'Error', msg: 'Server error please try again!', isToggle: true });
                            }
                            setSubmit(false);
                            window.scrollTo({
                                top: 0,
                                behavior: 'smooth',
                            });
                        });
                    }
                }
            } else {
                setIsItems(true);
            }
        }
    });

    const checkItems = () => {
        return utilService.getTotalValue(values.items) === 0 ? false : true;
    }

    const gotoPath = (path) => {
        setLoading(true);
        setPath(path);
    }

    const gotoGstrPath = (path) => {
        setGSTRPath(path);
        gotoPath('gstrFile');
    }

    const getGSTNInfo = (num) => {
        const gstnRegex = RegExp(
            /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[0-9]{1}Z[0-9A-Z]{1}$/
        );
        if (gstnRegex.test(num)) {
            if (GSTUser.gstn === num) {
                setErrGstn("Recipient and supplier cannot be the same");
            } else {
                setErrGstn("");
                let body = {
                    lid: userID,
                    gstn: num,
                }
                gstnService.getGSTN(body).then((res) => {
                    setIsItem(true);
                    let type = '';
                    let state = utilService.getCodeState(num.substring(0, 2));
                    if (state === GSTUser.state) {
                        type = "Intra-state";
                    } else {
                        type = "Inter-state";
                    }
                    if (res.status) {
                        if (res.data.length > 0) {
                            setIsOld(true);
                            setValues({ ...values, ruid: res.data[0].gstn, name: res.data[0].name, sup_name: res.data[0].mname, pos: state, supplyty: type });
                        } else {
                            setIsOld(false);
                            setValues({ ...values, ruid: num, name: '', sup_name: '', pos: state, supplyty: type });
                        }
                    } else {
                        setIsOld(false);
                        setValues({ ...values, ruid: num, name: '', sup_name: '', pos: state, supplyty: type });
                        setDigError({ title: 'Error', msg: 'Server error please try again!', isToggle: true });
                    }
                    setIsMaster(true);
                });
            }
        }
    }

    const handleDateChange = (d) => {
        switch (selOption) {
            case 'rinvdate':
                values.rinvdate = Moment(d).format('DD-MM-YYYY').toString();
                break;
            default:
                break;
        }
        setSelOption('');
    }

    const openPicker = (id) => {
        setSelOption(id);
        setIsOpen(true);
    }

    const getFormatvalue = (val) => {
        if (!val) return val;
        let number = val.replace(/[^\d.]/g, "");
        if (val.includes(".")) {
            const a = number.split(".");
            if (a[1].length > 2) {
                a[1] = a[1].substring(0, 2);
            }
            if (a[0].length > 13) {
                a[0] = a[0].substring(0, 13);
            }
            number = a[0] + "." + a[1];
        } else {
            if (number.length > 13) {
                number = number.substring(0, 13);
            }
        }
        return number;
    }

    const getSubFormatvalue = (val) => {
        if (!val) return val;
        let number = val.replace(/[^\d.]/g, "");
        if (val.includes(".")) {
            const a = number.split(".");
            if (a[1].length > 2) {
                a[1] = a[1].substring(0, 2);
            }
            if (a[0].length > 11) {
                a[0] = a[0].substring(0, 11);
            }
            number = a[0] + "." + a[1];
        } else {
            if (number.length > 11) {
                number = number.substring(0, 11);
            }
        }
        return number;
    }

    const loadData = () => {
        (async function () {
            var resB = await optionService.getStates({});
            setStates(resB.data);
            setAmid(query.data);

            if (query.type === 'amend') {
                let body = {
                    id: query.data
                };
                var doc = await returnService.getGstrDocById(body);
                const odata = JSON.parse(JSON.stringify(doc.data.data));
                setOldData(odata);
                setValues({ ...values, ...doc.data.data, oid: query.data });
                setIsItem(true);
                setIsOld(true);
                setQuery({ type: '', data: '' });
                setTitle('amend');
            } else if (query.type === 'view') {
                let body = {
                    id: query.data
                };
                var doc = await returnService.getGstrDocById(body);
                setValues({ ...values, ...doc.data.data });
                setIsView(true);
                setIsItem(true);
                setQuery({ type: '', data: '' });
                setTitle('view');
            } else if (query.type === 'edit') {
                let body = {
                    id: query.data
                };
                var doc = await returnService.getGstrDocById(body);
                setValues({ ...values, ...doc.data.data });
                setIsItem(true);
                setIsEdit(true);
                setIsView(false);
                setIsOld(true);
                if (doc.data.status === 'pending') {
                    setIsErrorAlert(true);
                } else {
                    setIsErrorAlert(false);
                }
                setEditid(doc.data._id);
                setQuery({ type: '', data: '' });
                setTitle('edit');
            }

            setLoading(false);
            window.scrollTo({
                top: 0,
                behavior: 'smooth',
            });
        })();
    }

    const getTitleFunc = () => {
        switch (title) {
            case "amend":
                return "B2B - Amend Invoice";
            case "view":
                return "9A Amended B2B Invoices - View Details";
            case "edit":
                return "9A Amended B2B Invoices - Edit Details";
        }
    }

    const getError = () => {
        switch (values.error) {
            case "invoice":
                return "Ethier the original document no. or date is invalid or the revised document no. already exsist in database. Kindly re-check & enter correct details.";
            case "nfill":
                return "GSTR 1 for the period in which the document was originally reported has not been filed. Please file the same before amendment";
            case "date":
                return "Revised invoice/note is Invalid. Revised date cannot be later than the last day of the tax peroid when original invoice/note was uploaded. Kindly delete the record and add it again.";
            default:
                return "";
        }
    }

    useEffect(() => {
        loadData();
    }, []);

    return (
        <div class="content-wrapper fade-ng-cloak">
            <div class="dimmer-holder" style={{ display: 'none' }}>
                <div id="dimmer"></div>
            </div>
            {
                loading && <div className='absolute z-50 w-full h-screen flex items-center justify-center bg-gray-200 bg-opacity-25'>
                    <img src={imgLoader} alt="loader" />
                </div>
            }
            <div class="container">
                <div class="mypage">
                    <div class="row">
                        <div class="col-xs-10">
                            <div data-breadcrumb="" name="Dashboard">
                                <ol class="breadcrumb" data-ng-controller="crumbCtrl">
                                    <li>
                                        <a onClick={() => {
                                            gotoPath('gstr')
                                        }}>Dashboard</a>
                                    </li>
                                    <li>
                                        <a onClick={() => {
                                            gotoPath('gstRetrun')
                                        }}>Returns</a>
                                    </li>
                                    <li>
                                        <a onClick={() => {
                                            gotoPath('gstrForm')
                                        }}>GSTR-1/IFF</a>
                                    </li>
                                    <li>B2BA</li>
                                </ol>
                            </div>
                        </div>
                        <div class="col-xs-2">
                            <div class="lang dropdown">
                                <span class="dropdown-toggle" data-toggle="dropdown" data-ng-bind="selectedLang">English</span>
                                <ul class="dropdown-menu lang-dpdwn">
                                    <li data-ng-repeat="language in languages">
                                        <a data-ng-click="selectLang(language.cd)" data-ng-bind="language.nm">English</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div class="container content-wrapper">
                            {isErrorAlert && <alert-message ng-show="showMsg" title="" type="msg alert-danger" message="" class="ng-hide"><div class="alert alert-msg alert-danger"><b className='alert-msg alert-danger'>Error!</b> {getError()}<a class="close" onClick={() => { setIsErrorAlert(false) }} data-dismiss="alert" aria-label="close">×</a><strong></strong>.</div></alert-message>}
                            <Dialog open={isDigOpen} onClose={() => setIsDigOpen(false)} className='overflow-y-auto overflow-x-hidden z-50 w-full h-modal md:h-full fixed top-0 left-0 bg-black bg-opacity-70'>
                                <div id="suppMastAdd" class="modal fade fade-scale in" role="dialog" style={{ display: 'block' }}>
                                    <div class="modal-dialog sweet" style={{ width: '1250px' }}>
                                        <div class="modal-content">
                                            <div class="modal-header">
                                                <h4 class="modal-title font-weight-bold" id="exampleModalLabel" ng-bind="trans.LBL_ADD_MSTR">ADD SUPPLIER/RECIPIENT MASTER</h4>
                                            </div>
                                            <div class="modal-body" style={{ overflowY: 'auto', maxHeight: 'calc(100vh - 160px)', paddingTop: '0px' }}>
                                                <form name="b2bSupp_Add" novalidate="novalidate">
                                                    <div class="row">
                                                        <div class="col-sm-12 pull-left head" style={{ marginTop: '8px' }}>
                                                            <div class="row" style={{ paddingLeft: '26px' }}>
                                                                <div class="col-4">
                                                                </div>
                                                                <div class="col-4 pull-right font-weight-bold redlink" style={{ marginTop: '10px', marginRight: '15px' }}>
                                                                    <p>Field marked with red dot (.) are mandatory</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12" style={{ marginTop: '-5px' }}>
                                                            <div class="row">
                                                                <div class={`col-sm-6 ${touched.gstn ? errors.gstn ? 'has-error' : 'has-success' : ''}`} >
                                                                    <label class="reg font-weight-bold m-cir" for="gstn">GSTIN</label>
                                                                    <input class="form-control" id="ruid" maxLength={15} value={values.ruid} onChange={handleChange} />
                                                                    {(errors.ruid && touched.ruid) && <span class="err ng-hide">{errors.ruid}</span>}
                                                                    {/*<span class="err ng-hide" data-ng-show="sameSupGstin &amp;&amp; !b2bSupp_Add.gstin.$error.required" data-ng-bind="trans.ERR_SAME_GSTIN_ADD_MSTR">GSTIN entered in supplier/recipient master cannot be same as the GSTIN of taxpayer.</span> }*/}
                                                                </div>
                                                                <div class={`col-sm-6 ${touched.name ? errors.name ? 'has-error' : 'has-success' : ''}`}>
                                                                    <label class="reg font-weight-bold m-cir" for="name">Trade Name/Legal Name</label>
                                                                    <input class="form-control" name="name" disabled="" id="name" data-ng-model="supMaster.tradName" required="" value={values.name} onChange={handleChange} />
                                                                    {(errors.name && touched.name) && <span class="err ng-hide">{errors.name}</span>}
                                                                </div>
                                                            </div>
                                                            <br />
                                                            <div class="row">
                                                                <div class={`col-sm-6 ${touched.sup_name ? errors.sup_name ? 'has-error' : 'has-success' : ''}`}>
                                                                    <label class="reg font-weight-bold m-cir" for="sup_name">Supplier/Recipient Name</label>
                                                                    <input class="form-control" name="sup_name" id="sup_name" maxlength="99" required="" value={values.sup_name} onChange={handleChange} />
                                                                    {(errors.sup_name && touched.sup_name) && <span class="err ng-hide">{errors.sup_name}</span>}
                                                                </div>
                                                                <div class="col-sm-3" style={{ marginTop: '2em' }}>
                                                                    <input class="chkbx" id="type" type="checkbox" checked={!values.type} onChange={handleChange} />
                                                                    {(errors.type && touched.type) && <span class="err ng-hide">{errors.type}</span>}
                                                                    <label class="reg font-weight-bold m-cir" for="isRec">Recipient</label>
                                                                    <br />
                                                                </div>
                                                                <div class="col-sm-3" style={{ marginTop: '2em' }}>
                                                                    <input class="chkbx" id="type" type="checkbox" checked={values.type} onChange={handleChange} />
                                                                    {(errors.type && touched.type) && <span class="err ng-hide">{errors.type}</span>}
                                                                    <label class="reg font-weight-bold m-cir">Supplier</label>
                                                                    <br />
                                                                </div>
                                                            </div>
                                                            <br />
                                                            <div class="btn-toolbar" style={{ display: 'inherit', textAlign: 'right' }}>
                                                                <button type="button" style={{ float: 'right', marginRight: '8px' }} class="btn btn-secondary bn" data-ng-click="resetData()" data-dismiss="modal" data-ng-bind="trans.LBL_BACK " onClick={() => {
                                                                    setIsDigOpen(false);
                                                                }}>Back</button>
                                                                <button type="button" style={{ float: 'right' }} class="btn btn-primary bn" onClick={() => {
                                                                    setIsDigOpen(false);
                                                                }}>{isOld ? 'Update' : 'Save'}</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Dialog>
                            <form name="b2b_Page" autocomplete="off" novalidate="" onSubmit={handleSubmit} onReset={handleReset}>
                                <div class="row invsumm">
                                    <div data-ng-if="!edit" class="col-sm-12 col-xs-12">
                                        <h4>{getTitleFunc()}</h4>
                                    </div>
                                </div>
                                <div class="tabpane">
                                    <div class="rettbl-format" style={{ marginTop: '-20px' }}>
                                        <div class="row">
                                            <div class="col-sm-12" style={{ paddingRight: '0px' }}>
                                                <span class="mand-text pull-right" data-ng-bind="trans.HLP_MAND_FIELD">Indicates Mandatory Fields</span>
                                            </div>
                                        </div>
                                    </div>
                                    <fieldset class="tbl-format rettbl-format" disabled={isView}>
                                        <div class="row">
                                            <div class="col-sm-4">
                                                <br />
                                                <input type="checkbox" class="chkbx ng-pristine ng-untouched ng-valid ng-empty" id="deemedexp" name="deemedexp" data-ng-model="b2b[0].inv[0].inv_typ" data-ng-checked="b2b[0].inv[0].inv_typ=='DE'" data-ng-true-value="'DE'" data-ng-false-value="'R'" data-ng-change="matchpos(b2b[0].inv[0].pos,b2b[0].inv[0].inv_typ);uncheckbox(b2b[0].inv[0].inv_typ,b2b[0].inv[0].rchrg)" data-ng-disabled=" b2bnewview || is_ecom || sezDisable() || isdDisable() || sezTyp==='UN' || sezTyp==='NR' || sezTyp==='TD' ||sezTyp==='TS'" disabled={true} />
                                                <label for="deemedexp" data-ng-bind="trans.LBL_DEEM_EXP">Deemed Exports</label>
                                            </div>
                                            <div class="col-sm-4">
                                                <br />
                                                <input type="checkbox" class="chkbx ng-pristine ng-untouched ng-valid ng-empty ng-valid-required" id="SEZexpay" name="SEZexpay" data-ng-model="b2b[0].inv[0].inv_typ" data-ng-checked="b2b[0].inv[0].inv_typ=='SEWP' &amp;&amp; sezTyp==='SEZ'" data-ng-true-value="'SEWP'" data-ng-false-value="'R'" data-ng-required="(sezTyp==='SEZ' &amp;&amp; b2b[0].inv[0].inv_typ!=='SEWOP')" data-ng-disabled=" b2bnewview || sezopDisable()" data-ng-change="matchpos(b2b[0].inv[0].pos,b2b[0].inv[0].inv_typ);uncheckbox(b2b[0].inv[0].inv_typ,b2b[0].inv[0].rchrg)" disabled="disabled" />
                                                <label for="SEZexpay" data-ng-bind="trans.LBL_SEZ_PAY">SEZ Supplies with payment</label>
                                            </div>
                                            <div class="col-sm-4">
                                                <br />
                                                <input type="checkbox" class="chkbx ng-pristine ng-untouched ng-valid ng-empty ng-valid-required" id="SEZexwpay" name="SEZexwpay" data-ng-model="b2b[0].inv[0].inv_typ" data-ng-checked="b2b[0].inv[0].inv_typ=='SEWOP ' &amp;&amp; sezTyp==='SEZ'" data-ng-true-value="'SEWOP'" data-ng-false-value="'R'" data-ng-disabled=" b2bnewview || sezopDisable()" data-ng-required="(sezTyp==='SEZ' &amp;&amp; b2b[0].inv[0].inv_typ!=='SEWP')" data-ng-change="matchpos(b2b[0].inv[0].pos,b2b[0].inv[0].inv_typ);uncheckbox(b2b[0].inv[0].inv_typ,b2b[0].inv[0].rchrg)" disabled="disabled" />
                                                <label for="SEZexwpay" data-ng-bind="trans.LBL_SEZ_WPAY">SEZ Supplies without payment</label>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-sm-4">
                                                <br />
                                                <input type="checkbox" class="chkbx" id="rev_charge" name="rev_charge" value={values.rev_charge} onChange={handleChange} />
                                                <label for="rev_charge" data-ng-bind="trans.LBL_SUPPLY_REVERSE">Supply attract reverse charge</label>
                                            </div>
                                            <div class="col-sm-4">
                                                <br />
                                                <input type="checkbox" class="chkbx ng-pristine ng-untouched ng-valid ng-empty" id="WRBexpay" name="WRBexpay" data-ng-model="b2b[0].inv[0].inv_typ" data-ng-checked="b2b[0].inv[0].inv_typ=='CBW' " data-ng-true-value="'CBW'" data-ng-false-value="'R'" data-ng-change="matchpos(b2b[0].inv[0].pos,b2b[0].inv[0].inv_typ)" data-ng-disabled="b2bnewview || isdDisable() || b2b[0].inv[0].rchrg != 'Y'" disabled="disabled" />
                                                <label for="WRBexpay" data-ng-bind="trans.LBL_INTR_STAT">Intra-State Supplies attracting IGST</label>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-sm-8">
                                                <br />
                                                <input type="checkbox" class="chkbx ng-pristine ng-untouched ng-valid ng-empty" id="rate_flag" data-ng-model="rate_flag" data-ng-checked="rate_flag=='Y'" data-ng-true-value="'Y'" data-ng-false-value="'N'" data-ng-change="cleartaxes()" disabled={true} />
                                                <label for="rate_flag">Is the supply eligible to be taxed at a differential percentage (%) of the
                                                    existing rate of tax, as notified by the Government?</label>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class={`col-sm-4 ${touched.ruid ? errors.ruid ? 'has-error' : 'has-success' : ''}`}>
                                                <label class="reg m-cir" for="ruid" data-ng-bind="trans.LBL_RECIPIENT_GSTIN">Recipient GSTIN/UIN</label>
                                                <div ng-if="showAutocomplete &amp;&amp; usrmstrenabled">
                                                    <div autocomplete-gstin="" id="ruid" name="ruid" placeholder="Search by recipient name as in master or enter GSTIN" data-maxlength="15" class="ng-pristine ng-untouched ng-empty ng-invalid ng-invalid-required ng-valid-minlength ng-valid-maxlength">
                                                        <div class="autocomplete-holder" >
                                                            <input id="ruid" name="ruid" type="text" placeholder="Search by recipient name as in master or enter GSTIN" maxlength="15" minlength="1" class="form-control ng-pristine ng-untouched ng-valid ng-empty ng-valid-minlength ng-valid-maxlength hasclear" autocapitalize="off" autocorrect="off" autocomplete="off" value={values.ruid} onChange={e => {
                                                                e.target.value = e.target.value.toUpperCase();
                                                                getGSTNInfo(e.target.value);
                                                                handleChange(e);
                                                            }} disabled={isEdit} />
                                                        </div>
                                                    </div>
                                                </div>
                                                {(errors.ruid && touched.ruid) && <span className='err'>{errors.ruid}</span>}
                                                {errGstn != '' && <span className='err'>{errGstn}</span>}
                                            </div>
                                            <div class={`col-sm-4 ${touched.name ? errors.name ? 'has-error' : 'has-success' : ''}`}>
                                                <label class="reg m-cir" for="u_name" data-ng-bind="trans.LBL_RECIPIENT_NAME">Recipient Name</label>
                                                <input type="text" name="name" class="form-control formedit" id="name" value={values.name} onChange={handleChange} disabled={isOld} />
                                                {(errors.name && touched.name) && <span className='err'>{errors.name}</span>}
                                            </div>
                                            <div class="col-sm-4">
                                                <label class="reg" for="sup_name" data-ng-bind="trans.LBL_SUPP_RECIP_NAME">Name as in Master</label>
                                                <input type="text" name="sup_name" class="form-control formedit" id="sup_name" title="" data-ng-model="supRespName" value={values.sup_name} disabled={true} />
                                            </div>
                                        </div>
                                        {
                                            isMaster && <div class="row" data-ng-if="gstinRecordAdd">
                                                <div class="col-sm-6">
                                                    <span class="reg">GSTIN not present in master/ GSTIN is selected as supplier only.</span>
                                                    <br />
                                                    <button type="button" class="btn btn-primary" onClick={() => { setIsDigOpen(true) }}>Add to Master
                                                    </button>
                                                </div>
                                            </div>
                                        }
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <ThemeProvider theme={materialTheme}>
                                                <DatePicker
                                                    variant="dialog"
                                                    open={isOpen}
                                                    onOpen={() => setIsOpen(true)}
                                                    onClose={() => setIsOpen(false)}
                                                    label="Open me from button"
                                                    format="dd/MM/yyyy"
                                                    value={mainDate}
                                                    maxDate={mainDate}
                                                    minDate={new Date('06-01-2017')}
                                                    onChange={handleDateChange}
                                                    style={{ display: 'none' }}
                                                />
                                            </ThemeProvider>
                                        </MuiPickersUtilsProvider>
                                        <div class="row">
                                            <div class={`col-sm-4 ${touched.inv_no ? errors.inv_no ? 'has-error' : 'has-success' : ''}`}>
                                                <label class="reg" for="inv_no" data-ng-bind="trans.LBL_INVOICE_NUMBER">Revised/Original Invoice no.</label>
                                                <input type="text" name="inv_no" id="inv_no" class="form-control formedit" maxLength={16} value={values.inv_no} disabled={true} />
                                            </div>
                                            <div class={`col-sm-4 ${touched.invdate ? errors.invdate ? 'has-error' : 'has-success' : ''}`}>
                                                <label class="reg m-cir" for="invdate">Revised/Original Invoice date</label>
                                                <div class="datepicker-icon input-group">
                                                    <input name="invdate" value={values.invdate.substring(0, 10)} class="form-control date-picker" id="invdate" placeholder="DD/MM/YYYY" disabled={true} />
                                                    <span class="input-group-addon"><span class="fa fa-calendar"></span></span>
                                                </div>
                                            </div>
                                            <div class={`col-sm-4 ${touched.rinv_no ? errors.rinv_no ? 'has-error' : 'has-success' : ''}`}>
                                                <label class="reg m-cir" for="rinv_no" data-ng-bind="trans.LBL_INVOICE_NUMBER">Revised Invoice no.</label>
                                                <input type="text" name="rinv_no" class="form-control formedit" id="rinv_no" maxLength={16} value={values.rinv_no} onChange={(e) => {
                                                    setInvno('');
                                                    handleChange(e);
                                                }} disabled={isEdit} />
                                                {(errors.rinv_no && touched.rinv_no) && <span className='err'>{errors.rinv_no}</span>}
                                                {invno !== "" && <span className='err'>Invoice no. {invno} already exists in the current peroid.</span>}
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class={`col-sm-4 ${touched.rinvdate ? errors.rinvdate ? 'has-error' : 'has-success' : ''}`}>
                                                <label class="reg m-cir" for="rinvdate" data-ng-bind="trans.LBL_INVOICE_DATE">Revised Invoice date</label>
                                                <div class="datepicker-icon input-group">
                                                    <input name="rinvdate" value={values.rinvdate.substring(0, 10)} class="form-control date-picker" id="rinvdate" placeholder="DD/MM/YYYY" onChange={handleDateChange} disabled={isEdit} />
                                                    <span class="input-group-addon" onClick={() => {
                                                        if (!isEdit) {
                                                            openPicker('rinvdate');
                                                        }
                                                    }}><span class="fa fa-calendar"></span></span>
                                                </div>
                                                {(errors.rinvdate && touched.rinvdate) && <span className='err'>{errors.rinvdate}</span>}
                                            </div>
                                            <div class={`col-sm-4 ${touched.invval ? errors.invval ? 'has-error' : 'has-success' : ''}`}>
                                                <label class="reg m-cir" for="invval" data-ng-bind="trans.LBL_INVOICE_VALUE">Total invoice value (₹)</label>
                                                <input name="invval" class="form-control text-right" id="invval" maxLength={16} value={values.invval} onChange={e => {
                                                    const formatValue = getFormatvalue(e.target.value);
                                                    e.target.value = formatValue;
                                                    handleChange(e);
                                                }} onBlur={e => {
                                                    if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                        e.target.value = e.target.value + ".00";
                                                    }
                                                    handleChange(e);
                                                }} />
                                                {(errors.invval && touched.invval) && <span className='err'>{errors.invval}</span>}
                                            </div>
                                            <div class={`col-sm-4 ${touched.pos ? errors.pos ? 'has-error' : 'has-success' : ''}`}>
                                                <label class="reg m-cir" for="pos"><span data-ng-bind="trans.LBL_POS">POS</span>
                                                    <i class="fa fa-info-circle" data-placement="top" title="Place of Supply(Only if different from location of recipient)" data-ng-onmouseover="$(this).tooltip('show')" aria-hidden="true">
                                                    </i>
                                                </label>
                                                <select name="pos" class="form-control" value={values.pos} id="pos" onChange={e => {
                                                    let val = e.target.value;
                                                    if (val === GSTUser.state) {
                                                        setIsItem(true);
                                                        setValues({ ...values, "supplyty": "Intra-state", "pos": val });
                                                    } else {
                                                        setIsItem(true);
                                                        setValues({ ...values, "supplyty": "Inter-state", "pos": val });
                                                    }
                                                }}>
                                                    <option value="" disabled="" selected="selected">Select</option>
                                                    {
                                                        states.length !== 0 && states.map((s) => {
                                                            return <option value={s} selected={values.pos == s}>{s}</option>
                                                        })
                                                    }
                                                </select>
                                                {
                                                    (errors.pos && touched.pos) && <span className='err'>{errors.pos}</span>
                                                }
                                            </div>
                                        </div>
                                        <div class="row">

                                            <div class={`col-sm-4 ${touched.supplyty ? errors.supplyty ? 'has-error' : 'has-success' : ''}`}>
                                                <label class="reg" for="stype" data-ng-bind="trans.LBL_SUPPLY_TYPE">Supply Type</label>
                                                <input class="form-control" name="supplyty" id="supplyty" value={values.supplyty} readOnly={true} />
                                                {
                                                    (errors.supplyty && touched.supplyty) && <span className='err'>{errors.supplyty}</span>
                                                }
                                            </div>
                                        </div>
                                        {
                                            isItem && <div>
                                                <fieldset data-ng-disabled="work_flow || b2baview || cdnraview">
                                                    <div class="rettbl-format">
                                                        <div class="row">
                                                            <div class="col-xs-12 col-sm-12">
                                                                <h4 class="no-mar" data-ng-bind="trans.HEAD_ITEM_DETAILS">Item details</h4>
                                                                {isItems && <span className='err'>Do add an item</span>}
                                                            </div>
                                                        </div>
                                                        <div class="row">
                                                            <div class="col-sm-12">
                                                                <div class="table-responsive">
                                                                    <table class="table exp inv tbl table-bordered table-responsive" style={{ display: 'table' }}>
                                                                        <thead>
                                                                            <tr>
                                                                                <th rowspan="2" class="text-center">Rate (%)</th>
                                                                                <th rowspan="2" class="text-center m-cir">Taxable value (₹)</th>
                                                                                <th colspan="3" class="text-center">Amount of Tax</th>
                                                                            </tr>
                                                                            <tr>
                                                                                {
                                                                                    values.supplyty === "Inter-state" && <th class="text-center m-cir">Integrated Tax (₹)</th>
                                                                                }
                                                                                {
                                                                                    values.supplyty === "Intra-state" && <>
                                                                                        <th class="text-center m-cir">State Tax (₹)</th>
                                                                                        <th class="text-center m-cir">Central Tax (₹)</th>
                                                                                    </>
                                                                                }
                                                                                <th class="text-center">Cess (₹)</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            <tr>
                                                                                <td class="text-center">0%</td>
                                                                                <td class="text-center">
                                                                                    <input id="items.a.total" class="form-control newinv formedit currency" maxLength={16} value={values.items.a.total} onChange={e => {
                                                                                        const formatValue = getSubFormatvalue(e.target.value);
                                                                                        e.target.value = formatValue;
                                                                                        if (e.target.value === "") {
                                                                                            values.items.a.taxa = '';
                                                                                            values.items.a.taxb = '';
                                                                                            values.items.a.cess = '';
                                                                                        } else {
                                                                                            values.items.a.taxa = 0;
                                                                                            values.items.a.taxb = 0;
                                                                                            values.items.a.cess = '';
                                                                                        }
                                                                                        handleChange(e);
                                                                                    }} onBlur={e => {
                                                                                        if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                            e.target.value = e.target.value + ".00";
                                                                                        }
                                                                                        handleChange(e);
                                                                                    }} />
                                                                                </td>
                                                                                {
                                                                                    values.supplyty === "Inter-state" && <td class="text-center">
                                                                                        <input id="items.a.taxa" class="form-control newinv formedit currency" maxLength={16} value={values.items.a.taxa} onChange={e => {
                                                                                            const formatValue = getSubFormatvalue(e.target.value);
                                                                                            e.target.value = formatValue;
                                                                                            handleChange(e);
                                                                                        }} onBlur={e => {
                                                                                            if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                                e.target.value = e.target.value + ".00";
                                                                                            }
                                                                                            handleChange(e);
                                                                                        }} disabled={values.items.a.total === ""} />
                                                                                    </td>
                                                                                }
                                                                                {
                                                                                    values.supplyty === "Intra-state" && <>
                                                                                        <td class="text-center">
                                                                                            <input id="items.a.taxb" class="form-control newinv formedit currency" maxLength={16} value={values.items.a.taxb} onChange={e => {
                                                                                                const formatValue = getSubFormatvalue(e.target.value);
                                                                                                e.target.value = formatValue;
                                                                                                handleChange(e);
                                                                                            }} onBlur={e => {
                                                                                                if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                                    e.target.value = e.target.value + ".00";
                                                                                                }
                                                                                                handleChange(e);
                                                                                            }} disabled={values.items.a.total === ""} />
                                                                                        </td>
                                                                                        <td class="text-center">
                                                                                            <input id="items.a.taxb" class="form-control newinv formedit currency" maxLength={16} value={values.items.a.taxb} onChange={e => {
                                                                                                const formatValue = getSubFormatvalue(e.target.value);
                                                                                                e.target.value = formatValue;
                                                                                                handleChange(e);
                                                                                            }} onBlur={e => {
                                                                                                if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                                    e.target.value = e.target.value + ".00";
                                                                                                }
                                                                                                handleChange(e);
                                                                                            }} disabled={values.items.a.total === ""} />
                                                                                        </td>
                                                                                    </>
                                                                                }
                                                                                <td class="text-center">
                                                                                    <input id="items.a.cess" class="form-control newinv formedit currency" maxLength={16} value={values.items.a.cess} onChange={e => {
                                                                                        const formatValue = getSubFormatvalue(e.target.value);
                                                                                        e.target.value = formatValue;
                                                                                        handleChange(e);
                                                                                    }} onBlur={e => {
                                                                                        if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                            e.target.value = e.target.value + ".00";
                                                                                        }
                                                                                        handleChange(e);
                                                                                    }} disabled={values.items.a.total === ""} />
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td class="text-center">0.1%</td>
                                                                                <td class="text-center">
                                                                                    <input id="items.b.total" class="form-control newinv formedit currency" maxLength={16} value={values.items.b.total} onChange={e => {
                                                                                        const formatValue = getSubFormatvalue(e.target.value);
                                                                                        e.target.value = formatValue;
                                                                                        if (e.target.value === "") {
                                                                                            values.items.b.taxa = '';
                                                                                            values.items.b.taxb = '';
                                                                                            values.items.b.cess = '';
                                                                                        } else {
                                                                                            values.items.b.taxa = (parseFloat(e.target.value) * 0.001).toFixed(2);
                                                                                            values.items.b.taxb = ((parseFloat(e.target.value) * 0.001) / 2).toFixed(2);
                                                                                        }
                                                                                        handleChange(e);
                                                                                    }} onBlur={e => {
                                                                                        if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                            e.target.value = e.target.value + ".00";
                                                                                        }
                                                                                        handleChange(e);
                                                                                    }} />
                                                                                </td>
                                                                                {
                                                                                    values.supplyty === "Inter-state" && <td class="text-center">
                                                                                        <input id="items.b.taxa" class="form-control newinv formedit currency" maxLength={16} value={values.items.b.taxa} onChange={e => {
                                                                                            const formatValue = getSubFormatvalue(e.target.value);
                                                                                            e.target.value = formatValue;
                                                                                            handleChange(e);
                                                                                        }} onBlur={e => {
                                                                                            if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                                e.target.value = e.target.value + ".00";
                                                                                            }
                                                                                            handleChange(e);
                                                                                        }} disabled={values.items.b.total === ""} />
                                                                                    </td>
                                                                                }
                                                                                {
                                                                                    values.supplyty === "Intra-state" && <>
                                                                                        <td class="text-center">
                                                                                            <input id="items.b.taxb" class="form-control newinv formedit currency" maxLength={16} value={values.items.b.taxb} onChange={e => {
                                                                                                const formatValue = getSubFormatvalue(e.target.value);
                                                                                                e.target.value = formatValue;
                                                                                                handleChange(e);
                                                                                            }} onBlur={e => {
                                                                                                if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                                    e.target.value = e.target.value + ".00";
                                                                                                }
                                                                                                handleChange(e);
                                                                                            }} disabled={values.items.b.total === ""} />
                                                                                        </td>
                                                                                        <td class="text-center">
                                                                                            <input id="items.b.taxb" class="form-control newinv formedit currency" maxLength={16} value={values.items.b.taxb} onChange={e => {
                                                                                                const formatValue = getSubFormatvalue(e.target.value);
                                                                                                e.target.value = formatValue;
                                                                                                handleChange(e);
                                                                                            }} onBlur={e => {
                                                                                                if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                                    e.target.value = e.target.value + ".00";
                                                                                                }
                                                                                                handleChange(e);
                                                                                            }} disabled={values.items.b.total === ""} />
                                                                                        </td>
                                                                                    </>
                                                                                }
                                                                                <td class="text-center">
                                                                                    <input id="items.b.cess" class="form-control newinv formedit currency" maxLength={16} value={values.items.b.cess} onChange={e => {
                                                                                        const formatValue = getSubFormatvalue(e.target.value);
                                                                                        e.target.value = formatValue;
                                                                                        handleChange(e);
                                                                                    }} onBlur={e => {
                                                                                        if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                            e.target.value = e.target.value + ".00";
                                                                                        }
                                                                                        handleChange(e);
                                                                                    }} disabled={values.items.b.total === ""} />
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td class="text-center">0.25%</td>
                                                                                <td class="text-center">
                                                                                    <input id="items.c.total" class="form-control newinv formedit currency" maxLength={16} value={values.items.c.total} onChange={e => {
                                                                                        const formatValue = getSubFormatvalue(e.target.value);
                                                                                        e.target.value = formatValue;
                                                                                        if (e.target.value === "") {
                                                                                            values.items.c.taxa = '';
                                                                                            values.items.c.taxb = '';
                                                                                            values.items.c.cess = '';
                                                                                        } else {
                                                                                            values.items.c.taxa = (parseFloat(e.target.value) * 0.025).toFixed(2);
                                                                                            values.items.c.taxb = ((parseFloat(e.target.value) * 0.025) / 2).toFixed(2);
                                                                                        }
                                                                                        handleChange(e);
                                                                                    }} onBlur={e => {
                                                                                        if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                            e.target.value = e.target.value + ".00";
                                                                                        }
                                                                                        handleChange(e);
                                                                                    }} />
                                                                                </td>
                                                                                {
                                                                                    values.supplyty === "Inter-state" && <td class="text-center">
                                                                                        <input id="items.c.taxa" class="form-control newinv formedit currency" maxLength={16} value={values.items.c.taxa} onChange={e => {
                                                                                            const formatValue = getSubFormatvalue(e.target.value);
                                                                                            e.target.value = formatValue;
                                                                                            handleChange(e);
                                                                                        }} onBlur={e => {
                                                                                            if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                                e.target.value = e.target.value + ".00";
                                                                                            }
                                                                                            handleChange(e);
                                                                                        }} disabled={values.items.c.total === ""} />
                                                                                    </td>
                                                                                }
                                                                                {
                                                                                    values.supplyty === "Intra-state" && <>
                                                                                        <td class="text-center">
                                                                                            <input id="items.c.taxb" class="form-control newinv formedit currency" maxLength={16} value={values.items.c.taxb} onChange={e => {
                                                                                                const formatValue = getSubFormatvalue(e.target.value);
                                                                                                e.target.value = formatValue;
                                                                                                handleChange(e);
                                                                                            }} onBlur={e => {
                                                                                                if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                                    e.target.value = e.target.value + ".00";
                                                                                                }
                                                                                                handleChange(e);
                                                                                            }} disabled={values.items.c.total === ""} />
                                                                                        </td>
                                                                                        <td class="text-center">
                                                                                            <input id="items.c.taxb" class="form-control newinv formedit currency" maxLength={16} value={values.items.c.taxb} onChange={e => {
                                                                                                const formatValue = getSubFormatvalue(e.target.value);
                                                                                                e.target.value = formatValue;
                                                                                                handleChange(e);
                                                                                            }} onBlur={e => {
                                                                                                if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                                    e.target.value = e.target.value + ".00";
                                                                                                }
                                                                                                handleChange(e);
                                                                                            }} disabled={values.items.c.total === ""} />
                                                                                        </td>
                                                                                    </>
                                                                                }
                                                                                <td class="text-center">
                                                                                    <input id="items.c.cess" class="form-control newinv formedit currency" maxLength={16} value={values.items.c.cess} onChange={e => {
                                                                                        const formatValue = getSubFormatvalue(e.target.value);
                                                                                        e.target.value = formatValue;
                                                                                        handleChange(e);
                                                                                    }} onBlur={e => {
                                                                                        if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                            e.target.value = e.target.value + ".00";
                                                                                        }
                                                                                        handleChange(e);
                                                                                    }} disabled={values.items.c.total === ""} />
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td class="text-center">1%</td>
                                                                                <td class="text-center">
                                                                                    <input id="items.d.total" class="form-control newinv formedit currency" maxLength={16} value={values.items.d.total} onChange={e => {
                                                                                        const formatValue = getSubFormatvalue(e.target.value);
                                                                                        e.target.value = formatValue;
                                                                                        if (e.target.value === "") {
                                                                                            values.items.d.taxa = '';
                                                                                            values.items.d.taxb = '';
                                                                                            values.items.d.cess = '';
                                                                                        } else {
                                                                                            values.items.d.taxa = (parseFloat(e.target.value) * 0.01).toFixed(2);
                                                                                            values.items.d.taxb = ((parseFloat(e.target.value) * 0.01) / 2).toFixed(2);
                                                                                        }
                                                                                        handleChange(e);
                                                                                    }} onBlur={e => {
                                                                                        if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                            e.target.value = e.target.value + ".00";
                                                                                        }
                                                                                        handleChange(e);
                                                                                    }} />
                                                                                </td>
                                                                                {
                                                                                    values.supplyty === "Inter-state" && <td class="text-center">
                                                                                        <input id="items.d.taxa" class="form-control newinv formedit currency" maxLength={16} value={values.items.d.taxa} onChange={e => {
                                                                                            const formatValue = getSubFormatvalue(e.target.value);
                                                                                            e.target.value = formatValue;
                                                                                            handleChange(e);
                                                                                        }} onBlur={e => {
                                                                                            if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                                e.target.value = e.target.value + ".00";
                                                                                            }
                                                                                            handleChange(e);
                                                                                        }} disabled={values.items.d.total === ""} />
                                                                                    </td>
                                                                                }
                                                                                {
                                                                                    values.supplyty === "Intra-state" && <>
                                                                                        <td class="text-center">
                                                                                            <input id="items.d.taxb" class="form-control newinv formedit currency" maxLength={16} value={values.items.d.taxb} onChange={e => {
                                                                                                const formatValue = getSubFormatvalue(e.target.value);
                                                                                                e.target.value = formatValue;
                                                                                                handleChange(e);
                                                                                            }} onBlur={e => {
                                                                                                if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                                    e.target.value = e.target.value + ".00";
                                                                                                }
                                                                                                handleChange(e);
                                                                                            }} disabled={values.items.d.total === ""} />
                                                                                        </td>
                                                                                        <td class="text-center">
                                                                                            <input id="items.d.taxb" class="form-control newinv formedit currency" maxLength={16} value={values.items.d.taxb} onChange={e => {
                                                                                                const formatValue = getSubFormatvalue(e.target.value);
                                                                                                e.target.value = formatValue;
                                                                                                handleChange(e);
                                                                                            }} onBlur={e => {
                                                                                                if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                                    e.target.value = e.target.value + ".00";
                                                                                                }
                                                                                                handleChange(e);
                                                                                            }} disabled={values.items.d.total === ""} />
                                                                                        </td>
                                                                                    </>
                                                                                }
                                                                                <td class="text-center">
                                                                                    <input id="items.d.cess" class="form-control newinv formedit currency" maxLength={16} value={values.items.d.cess} onChange={e => {
                                                                                        const formatValue = getSubFormatvalue(e.target.value);
                                                                                        e.target.value = formatValue;
                                                                                        handleChange(e);
                                                                                    }} onBlur={e => {
                                                                                        if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                            e.target.value = e.target.value + ".00";
                                                                                        }
                                                                                        handleChange(e);
                                                                                    }} disabled={values.items.d.total === ""} />
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td class="text-center">1.5%</td>
                                                                                <td class="text-center">
                                                                                    <input id="items.e.total" class="form-control newinv formedit currency" maxLength={16} value={values.items.e.total} onChange={e => {
                                                                                        const formatValue = getSubFormatvalue(e.target.value);
                                                                                        e.target.value = formatValue;
                                                                                        if (e.target.value === "") {
                                                                                            values.items.e.taxa = '';
                                                                                            values.items.e.taxb = '';
                                                                                            values.items.e.cess = '';
                                                                                        } else {
                                                                                            values.items.e.taxa = (parseFloat(e.target.value) * 0.015).toFixed(2);
                                                                                            values.items.e.taxb = ((parseFloat(e.target.value) * 0.015) / 2).toFixed(2);
                                                                                        }
                                                                                        handleChange(e);
                                                                                    }} onBlur={e => {
                                                                                        if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                            e.target.value = e.target.value + ".00";
                                                                                        }
                                                                                        handleChange(e);
                                                                                    }} />
                                                                                </td>
                                                                                {
                                                                                    values.supplyty === "Inter-state" && <td class="text-center">
                                                                                        <input id="items.e.taxa" class="form-control newinv formedit currency" maxLength={16} value={values.items.e.taxa} onChange={e => {
                                                                                            const formatValue = getSubFormatvalue(e.target.value);
                                                                                            e.target.value = formatValue;
                                                                                            handleChange(e);
                                                                                        }} onBlur={e => {
                                                                                            if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                                e.target.value = e.target.value + ".00";
                                                                                            }
                                                                                            handleChange(e);
                                                                                        }} disabled={values.items.e.total === ""} />
                                                                                    </td>
                                                                                }
                                                                                {
                                                                                    values.supplyty === "Intra-state" && <>
                                                                                        <td class="text-center">
                                                                                            <input id="items.e.taxb" class="form-control newinv formedit currency" maxLength={16} value={values.items.e.taxb} onChange={e => {
                                                                                                const formatValue = getSubFormatvalue(e.target.value);
                                                                                                e.target.value = formatValue;
                                                                                                handleChange(e);
                                                                                            }} onBlur={e => {
                                                                                                if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                                    e.target.value = e.target.value + ".00";
                                                                                                }
                                                                                                handleChange(e);
                                                                                            }} disabled={values.items.e.total === ""} />
                                                                                        </td>
                                                                                        <td class="text-center">
                                                                                            <input id="items.e.taxb" class="form-control newinv formedit currency" maxLength={16} value={values.items.e.taxb} onChange={e => {
                                                                                                const formatValue = getSubFormatvalue(e.target.value);
                                                                                                e.target.value = formatValue;
                                                                                                handleChange(e);
                                                                                            }} onBlur={e => {
                                                                                                if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                                    e.target.value = e.target.value + ".00";
                                                                                                }
                                                                                                handleChange(e);
                                                                                            }} disabled={values.items.e.total === ""} />
                                                                                        </td>
                                                                                    </>
                                                                                }
                                                                                <td class="text-center">
                                                                                    <input id="items.e.cess" class="form-control newinv formedit currency" maxLength={16} value={values.items.e.cess} onChange={e => {
                                                                                        const formatValue = getSubFormatvalue(e.target.value);
                                                                                        e.target.value = formatValue;
                                                                                        handleChange(e);
                                                                                    }} onBlur={e => {
                                                                                        if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                            e.target.value = e.target.value + ".00";
                                                                                        }
                                                                                        handleChange(e);
                                                                                    }} disabled={values.items.e.total === ""} />
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td class="text-center">3%</td>
                                                                                <td class="text-center">
                                                                                    <input id="items.f.total" class="form-control newinv formedit currency" maxLength={16} value={values.items.f.total} onChange={e => {
                                                                                        const formatValue = getSubFormatvalue(e.target.value);
                                                                                        e.target.value = formatValue;
                                                                                        if (e.target.value === "") {
                                                                                            values.items.f.taxa = '';
                                                                                            values.items.f.taxb = '';
                                                                                            values.items.f.cess = '';
                                                                                        } else {
                                                                                            values.items.f.taxa = (parseFloat(e.target.value) * 0.03).toFixed(2);
                                                                                            values.items.f.taxb = ((parseFloat(e.target.value) * 0.03) / 2).toFixed(2);
                                                                                        }
                                                                                        handleChange(e);
                                                                                    }} onBlur={e => {
                                                                                        if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                            e.target.value = e.target.value + ".00";
                                                                                        }
                                                                                        handleChange(e);
                                                                                    }} />
                                                                                </td>
                                                                                {
                                                                                    values.supplyty === "Inter-state" && <td class="text-center">
                                                                                        <input id="items.f.taxa" class="form-control newinv formedit currency" maxLength={16} value={values.items.f.taxa} onChange={e => {
                                                                                            const formatValue = getSubFormatvalue(e.target.value);
                                                                                            e.target.value = formatValue;
                                                                                            handleChange(e);
                                                                                        }} onBlur={e => {
                                                                                            if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                                e.target.value = e.target.value + ".00";
                                                                                            }
                                                                                            handleChange(e);
                                                                                        }} disabled={values.items.f.total === ""} />
                                                                                    </td>
                                                                                }
                                                                                {
                                                                                    values.supplyty === "Intra-state" && <>
                                                                                        <td class="text-center">
                                                                                            <input id="items.f.taxb" class="form-control newinv formedit currency" maxLength={16} value={values.items.f.taxb} onChange={e => {
                                                                                                const formatValue = getSubFormatvalue(e.target.value);
                                                                                                e.target.value = formatValue;
                                                                                                handleChange(e);
                                                                                            }} onBlur={e => {
                                                                                                if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                                    e.target.value = e.target.value + ".00";
                                                                                                }
                                                                                                handleChange(e);
                                                                                            }} disabled={values.items.f.total === ""} />
                                                                                        </td>
                                                                                        <td class="text-center">
                                                                                            <input id="items.f.taxb" class="form-control newinv formedit currency" maxLength={16} value={values.items.f.taxb} onChange={e => {
                                                                                                const formatValue = getSubFormatvalue(e.target.value);
                                                                                                e.target.value = formatValue;
                                                                                                handleChange(e);
                                                                                            }} onBlur={e => {
                                                                                                if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                                    e.target.value = e.target.value + ".00";
                                                                                                }
                                                                                                handleChange(e);
                                                                                            }} disabled={values.items.f.total === ""} />
                                                                                        </td>
                                                                                    </>
                                                                                }
                                                                                <td class="text-center">
                                                                                    <input id="items.f.cess" class="form-control newinv formedit currency" maxLength={16} value={values.items.f.cess} onChange={e => {
                                                                                        const formatValue = getSubFormatvalue(e.target.value);
                                                                                        e.target.value = formatValue;
                                                                                        handleChange(e);
                                                                                    }} onBlur={e => {
                                                                                        if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                            e.target.value = e.target.value + ".00";
                                                                                        }
                                                                                        handleChange(e);
                                                                                    }} disabled={values.items.f.total === ""} />
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td class="text-center">5%</td>
                                                                                <td class="text-center">
                                                                                    <input id="items.g.total" class="form-control newinv formedit currency" maxLength={16} value={values.items.g.total} onChange={e => {
                                                                                        const formatValue = getSubFormatvalue(e.target.value);
                                                                                        e.target.value = formatValue;
                                                                                        if (e.target.value === "") {
                                                                                            values.items.g.taxa = '';
                                                                                            values.items.g.taxb = '';
                                                                                            values.items.g.cess = '';
                                                                                        } else {
                                                                                            values.items.g.taxa = (parseFloat(e.target.value) * 0.05).toFixed(2);
                                                                                            values.items.g.taxb = ((parseFloat(e.target.value) * 0.05) / 2).toFixed(2);
                                                                                        }
                                                                                        handleChange(e);
                                                                                    }} onBlur={e => {
                                                                                        if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                            e.target.value = e.target.value + ".00";
                                                                                        }
                                                                                        handleChange(e);
                                                                                    }} />
                                                                                </td>
                                                                                {
                                                                                    values.supplyty === "Inter-state" && <td class="text-center">
                                                                                        <input id="items.g.taxa" class="form-control newinv formedit currency" maxLength={16} value={values.items.g.taxa} onChange={e => {
                                                                                            const formatValue = getSubFormatvalue(e.target.value);
                                                                                            e.target.value = formatValue;
                                                                                            handleChange(e);
                                                                                        }} onBlur={e => {
                                                                                            if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                                e.target.value = e.target.value + ".00";
                                                                                            }
                                                                                            handleChange(e);
                                                                                        }} disabled={values.items.g.total === ""} />
                                                                                    </td>
                                                                                }
                                                                                {
                                                                                    values.supplyty === "Intra-state" && <>
                                                                                        <td class="text-center">
                                                                                            <input id="items.g.taxb" class="form-control newinv formedit currency" maxLength={16} value={values.items.g.taxb} onChange={e => {
                                                                                                const formatValue = getSubFormatvalue(e.target.value);
                                                                                                e.target.value = formatValue;
                                                                                                handleChange(e);
                                                                                            }} onBlur={e => {
                                                                                                if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                                    e.target.value = e.target.value + ".00";
                                                                                                }
                                                                                                handleChange(e);
                                                                                            }} disabled={values.items.g.total === ""} />
                                                                                        </td>
                                                                                        <td class="text-center">
                                                                                            <input id="items.g.taxb" class="form-control newinv formedit currency" maxLength={16} value={values.items.g.taxb} onChange={e => {
                                                                                                const formatValue = getSubFormatvalue(e.target.value);
                                                                                                e.target.value = formatValue;
                                                                                                handleChange(e);
                                                                                            }} onBlur={e => {
                                                                                                if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                                    e.target.value = e.target.value + ".00";
                                                                                                }
                                                                                                handleChange(e);
                                                                                            }} disabled={values.items.g.total === ""} />
                                                                                        </td>
                                                                                    </>
                                                                                }
                                                                                <td class="text-center">
                                                                                    <input id="items.g.cess" class="form-control newinv formedit currency" maxLength={16} value={values.items.g.cess} onChange={e => {
                                                                                        const formatValue = getSubFormatvalue(e.target.value);
                                                                                        e.target.value = formatValue;
                                                                                        handleChange(e);
                                                                                    }} onBlur={e => {
                                                                                        if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                            e.target.value = e.target.value + ".00";
                                                                                        }
                                                                                        handleChange(e);
                                                                                    }} disabled={values.items.g.total === ""} />
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td class="text-center">6%</td>
                                                                                <td class="text-center">
                                                                                    <input id="items.h.total" class="form-control newinv formedit currency" maxLength={16} value={values.items.h.total} onChange={e => {
                                                                                        const formatValue = getSubFormatvalue(e.target.value);
                                                                                        e.target.value = formatValue;
                                                                                        if (e.target.value === "") {
                                                                                            values.items.h.taxa = '';
                                                                                            values.items.h.taxb = '';
                                                                                            values.items.h.cess = '';
                                                                                        } else {
                                                                                            values.items.h.taxa = (parseFloat(e.target.value) * 0.06).toFixed(2);
                                                                                            values.items.h.taxb = ((parseFloat(e.target.value) * 0.06) / 2).toFixed(2);
                                                                                        }
                                                                                        handleChange(e);
                                                                                    }} onBlur={e => {
                                                                                        if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                            e.target.value = e.target.value + ".00";
                                                                                        }
                                                                                        handleChange(e);
                                                                                    }} />
                                                                                </td>
                                                                                {
                                                                                    values.supplyty === "Inter-state" && <td class="text-center">
                                                                                        <input id="items.h.taxa" class="form-control newinv formedit currency" maxLength={16} value={values.items.h.taxa} onChange={e => {
                                                                                            const formatValue = getSubFormatvalue(e.target.value);
                                                                                            e.target.value = formatValue;
                                                                                            handleChange(e);
                                                                                        }} onBlur={e => {
                                                                                            if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                                e.target.value = e.target.value + ".00";
                                                                                            }
                                                                                            handleChange(e);
                                                                                        }} disabled={values.items.h.total === ""} />
                                                                                    </td>
                                                                                }
                                                                                {
                                                                                    values.supplyty === "Intra-state" && <>
                                                                                        <td class="text-center">
                                                                                            <input id="items.h.taxb" class="form-control newinv formedit currency" maxLength={16} value={values.items.h.taxb} onChange={e => {
                                                                                                const formatValue = getSubFormatvalue(e.target.value);
                                                                                                e.target.value = formatValue;
                                                                                                handleChange(e);
                                                                                            }} onBlur={e => {
                                                                                                if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                                    e.target.value = e.target.value + ".00";
                                                                                                }
                                                                                                handleChange(e);
                                                                                            }} disabled={values.items.h.total === ""} />
                                                                                        </td>
                                                                                        <td class="text-center">
                                                                                            <input id="items.h.taxb" class="form-control newinv formedit currency" maxLength={16} value={values.items.h.taxb} onChange={e => {
                                                                                                const formatValue = getSubFormatvalue(e.target.value);
                                                                                                e.target.value = formatValue;
                                                                                                handleChange(e);
                                                                                            }} onBlur={e => {
                                                                                                if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                                    e.target.value = e.target.value + ".00";
                                                                                                }
                                                                                                handleChange(e);
                                                                                            }} disabled={values.items.h.total === ""} />
                                                                                        </td>
                                                                                    </>
                                                                                }
                                                                                <td class="text-center">
                                                                                    <input id="items.h.cess" class="form-control newinv formedit currency" maxLength={16} value={values.items.h.cess} onChange={e => {
                                                                                        const formatValue = getSubFormatvalue(e.target.value);
                                                                                        e.target.value = formatValue;
                                                                                        handleChange(e);
                                                                                    }} onBlur={e => {
                                                                                        if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                            e.target.value = e.target.value + ".00";
                                                                                        }
                                                                                        handleChange(e);
                                                                                    }} disabled={values.items.h.total === ""} />
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td class="text-center">7.5%</td>
                                                                                <td class="text-center">
                                                                                    <input id="items.i.total" class="form-control newinv formedit currency" maxLength={16} value={values.items.i.total} onChange={e => {
                                                                                        const formatValue = getSubFormatvalue(e.target.value);
                                                                                        e.target.value = formatValue;
                                                                                        if (e.target.value === "") {
                                                                                            values.items.i.taxa = '';
                                                                                            values.items.i.taxb = '';
                                                                                            values.items.i.cess = '';
                                                                                        } else {
                                                                                            values.items.i.taxa = (parseFloat(e.target.value) * 0.075).toFixed(2);
                                                                                            values.items.i.taxb = ((parseFloat(e.target.value) * 0.075) / 2).toFixed(2);
                                                                                        }
                                                                                        handleChange(e);
                                                                                    }} onBlur={e => {
                                                                                        if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                            e.target.value = e.target.value + ".00";
                                                                                        }
                                                                                        handleChange(e);
                                                                                    }} />
                                                                                </td>
                                                                                {
                                                                                    values.supplyty === "Inter-state" && <td class="text-center">
                                                                                        <input id="items.i.taxa" class="form-control newinv formedit currency" maxLength={16} value={values.items.i.taxa} onChange={e => {
                                                                                            const formatValue = getSubFormatvalue(e.target.value);
                                                                                            e.target.value = formatValue;
                                                                                            handleChange(e);
                                                                                        }} onBlur={e => {
                                                                                            if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                                e.target.value = e.target.value + ".00";
                                                                                            }
                                                                                            handleChange(e);
                                                                                        }} disabled={values.items.i.total === ""} />
                                                                                    </td>
                                                                                }
                                                                                {
                                                                                    values.supplyty === "Intra-state" && <>
                                                                                        <td class="text-center">
                                                                                            <input id="items.i.taxb" class="form-control newinv formedit currency" maxLength={16} value={values.items.i.taxb} onChange={e => {
                                                                                                const formatValue = getSubFormatvalue(e.target.value);
                                                                                                e.target.value = formatValue;
                                                                                                handleChange(e);
                                                                                            }} onBlur={e => {
                                                                                                if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                                    e.target.value = e.target.value + ".00";
                                                                                                }
                                                                                                handleChange(e);
                                                                                            }} disabled={values.items.i.total === ""} />
                                                                                        </td>
                                                                                        <td class="text-center">
                                                                                            <input id="items.i.taxb" class="form-control newinv formedit currency" maxLength={16} value={values.items.i.taxb} onChange={e => {
                                                                                                const formatValue = getSubFormatvalue(e.target.value);
                                                                                                e.target.value = formatValue;
                                                                                                handleChange(e);
                                                                                            }} onBlur={e => {
                                                                                                if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                                    e.target.value = e.target.value + ".00";
                                                                                                }
                                                                                                handleChange(e);
                                                                                            }} disabled={values.items.i.total === ""} />
                                                                                        </td>
                                                                                    </>
                                                                                }
                                                                                <td class="text-center">
                                                                                    <input id="items.i.cess" class="form-control newinv formedit currency" maxLength={16} value={values.items.i.cess} onChange={e => {
                                                                                        const formatValue = getSubFormatvalue(e.target.value);
                                                                                        e.target.value = formatValue;
                                                                                        handleChange(e);
                                                                                    }} onBlur={e => {
                                                                                        if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                            e.target.value = e.target.value + ".00";
                                                                                        }
                                                                                        handleChange(e);
                                                                                    }} disabled={values.items.i.total === ""} />
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td class="text-center">12%</td>
                                                                                <td class="text-center">
                                                                                    <input id="items.j.total" class="form-control newinv formedit currency" maxLength={16} value={values.items.j.total} onChange={e => {
                                                                                        const formatValue = getSubFormatvalue(e.target.value);
                                                                                        e.target.value = formatValue;
                                                                                        if (e.target.value === "") {
                                                                                            values.items.j.taxa = '';
                                                                                            values.items.j.taxb = '';
                                                                                            values.items.j.cess = '';
                                                                                        } else {
                                                                                            values.items.j.taxa = (parseFloat(e.target.value) * 0.12).toFixed(2);
                                                                                            values.items.j.taxb = ((parseFloat(e.target.value) * 0.12) / 2).toFixed(2);
                                                                                        }
                                                                                        handleChange(e);
                                                                                    }} onBlur={e => {
                                                                                        if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                            e.target.value = e.target.value + ".00";
                                                                                        }
                                                                                        handleChange(e);
                                                                                    }} />
                                                                                </td>
                                                                                {
                                                                                    values.supplyty === "Inter-state" && <td class="text-center">
                                                                                        <input id="items.j.taxa" class="form-control newinv formedit currency" maxLength={16} value={values.items.j.taxa} onChange={e => {
                                                                                            const formatValue = getSubFormatvalue(e.target.value);
                                                                                            e.target.value = formatValue;
                                                                                            handleChange(e);
                                                                                        }} onBlur={e => {
                                                                                            if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                                e.target.value = e.target.value + ".00";
                                                                                            }
                                                                                            handleChange(e);
                                                                                        }} disabled={values.items.j.total === ""} />
                                                                                    </td>
                                                                                }
                                                                                {
                                                                                    values.supplyty === "Intra-state" && <>
                                                                                        <td class="text-center">
                                                                                            <input id="items.j.taxb" class="form-control newinv formedit currency" maxLength={16} value={values.items.j.taxb} onChange={e => {
                                                                                                const formatValue = getSubFormatvalue(e.target.value);
                                                                                                e.target.value = formatValue;
                                                                                                handleChange(e);
                                                                                            }} onBlur={e => {
                                                                                                if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                                    e.target.value = e.target.value + ".00";
                                                                                                }
                                                                                                handleChange(e);
                                                                                            }} disabled={values.items.j.total === ""} />
                                                                                        </td>
                                                                                        <td class="text-center">
                                                                                            <input id="items.j.taxb" class="form-control newinv formedit currency" maxLength={16} value={values.items.j.taxb} onChange={e => {
                                                                                                const formatValue = getSubFormatvalue(e.target.value);
                                                                                                e.target.value = formatValue;
                                                                                                handleChange(e);
                                                                                            }} onBlur={e => {
                                                                                                if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                                    e.target.value = e.target.value + ".00";
                                                                                                }
                                                                                                handleChange(e);
                                                                                            }} disabled={values.items.j.total === ""} />
                                                                                        </td>
                                                                                    </>
                                                                                }
                                                                                <td class="text-center">
                                                                                    <input id="items.j.cess" class="form-control newinv formedit currency" maxLength={16} value={values.items.j.cess} onChange={e => {
                                                                                        const formatValue = getSubFormatvalue(e.target.value);
                                                                                        e.target.value = formatValue;
                                                                                        handleChange(e);
                                                                                    }} onBlur={e => {
                                                                                        if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                            e.target.value = e.target.value + ".00";
                                                                                        }
                                                                                        handleChange(e);
                                                                                    }} disabled={values.items.j.total === ""} />
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td class="text-center">18%</td>
                                                                                <td class="text-center">
                                                                                    <input id="items.k.total" class="form-control newinv formedit currency" maxLength={16} value={values.items.k.total} onChange={e => {
                                                                                        const formatValue = getSubFormatvalue(e.target.value);
                                                                                        e.target.value = formatValue;
                                                                                        if (e.target.value === "") {
                                                                                            values.items.k.taxa = '';
                                                                                            values.items.k.taxb = '';
                                                                                            values.items.k.cess = '';
                                                                                        } else {
                                                                                            values.items.k.taxa = (parseFloat(e.target.value) * 0.18).toFixed(2);
                                                                                            values.items.k.taxb = ((parseFloat(e.target.value) * 0.18) / 2).toFixed(2);
                                                                                        }
                                                                                        handleChange(e);
                                                                                    }} onBlur={e => {
                                                                                        if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                            e.target.value = e.target.value + ".00";
                                                                                        }
                                                                                        handleChange(e);
                                                                                    }} />
                                                                                </td>
                                                                                {
                                                                                    values.supplyty === "Inter-state" && <td class="text-center">
                                                                                        <input id="items.k.taxa" class="form-control newinv formedit currency" maxLength={16} value={values.items.k.taxa} onChange={e => {
                                                                                            const formatValue = getSubFormatvalue(e.target.value);
                                                                                            e.target.value = formatValue;
                                                                                            handleChange(e);
                                                                                        }} onBlur={e => {
                                                                                            if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                                e.target.value = e.target.value + ".00";
                                                                                            }
                                                                                            handleChange(e);
                                                                                        }} disabled={values.items.k.total === ""} />
                                                                                    </td>
                                                                                }
                                                                                {
                                                                                    values.supplyty === "Intra-state" && <>
                                                                                        <td class="text-center">
                                                                                            <input id="items.k.taxb" class="form-control newinv formedit currency" maxLength={16} value={values.items.k.taxb} onChange={e => {
                                                                                                const formatValue = getSubFormatvalue(e.target.value);
                                                                                                e.target.value = formatValue;
                                                                                                handleChange(e);
                                                                                            }} onBlur={e => {
                                                                                                if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                                    e.target.value = e.target.value + ".00";
                                                                                                }
                                                                                                handleChange(e);
                                                                                            }} disabled={values.items.k.total === ""} />
                                                                                        </td>
                                                                                        <td class="text-center">
                                                                                            <input id="items.k.taxb" class="form-control newinv formedit currency" maxLength={16} value={values.items.k.taxb} onChange={e => {
                                                                                                const formatValue = getSubFormatvalue(e.target.value);
                                                                                                e.target.value = formatValue;
                                                                                                handleChange(e);
                                                                                            }} onBlur={e => {
                                                                                                if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                                    e.target.value = e.target.value + ".00";
                                                                                                }
                                                                                                handleChange(e);
                                                                                            }} disabled={values.items.k.total === ""} />
                                                                                        </td>
                                                                                    </>
                                                                                }
                                                                                <td class="text-center">
                                                                                    <input id="items.k.cess" class="form-control newinv formedit currency" maxLength={16} value={values.items.k.cess} onChange={e => {
                                                                                        const formatValue = getSubFormatvalue(e.target.value);
                                                                                        e.target.value = formatValue;
                                                                                        handleChange(e);
                                                                                    }} onBlur={e => {
                                                                                        if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                            e.target.value = e.target.value + ".00";
                                                                                        }
                                                                                        handleChange(e);
                                                                                    }} disabled={values.items.k.total === ""} />
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td class="text-center">28%</td>
                                                                                <td class="text-center">
                                                                                    <input id="items.l.total" class="form-control newinv formedit currency" maxLength={16} value={values.items.l.total} onChange={e => {
                                                                                        const formatValue = getSubFormatvalue(e.target.value);
                                                                                        e.target.value = formatValue;
                                                                                        if (e.target.value === "") {
                                                                                            values.items.l.taxa = '';
                                                                                            values.items.l.taxb = '';
                                                                                            values.items.l.cess = '';
                                                                                        } else {
                                                                                            values.items.l.taxa = (parseFloat(e.target.value) * 0.28).toFixed(2);
                                                                                            values.items.l.taxb = ((parseFloat(e.target.value) * 0.28) / 2).toFixed(2);
                                                                                        }
                                                                                        handleChange(e);
                                                                                    }} onBlur={e => {
                                                                                        if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                            e.target.value = e.target.value + ".00";
                                                                                        }
                                                                                        handleChange(e);
                                                                                    }} />
                                                                                </td>
                                                                                {
                                                                                    values.supplyty === "Inter-state" && <td class="text-center">
                                                                                        <input id="items.l.taxa" class="form-control newinv formedit currency" maxLength={16} value={values.items.l.taxa} onChange={e => {
                                                                                            const formatValue = getSubFormatvalue(e.target.value);
                                                                                            e.target.value = formatValue;
                                                                                            handleChange(e);
                                                                                        }} onBlur={e => {
                                                                                            if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                                e.target.value = e.target.value + ".00";
                                                                                            }
                                                                                            handleChange(e);
                                                                                        }} disabled={values.items.l.total === ""} />
                                                                                    </td>
                                                                                }
                                                                                {
                                                                                    values.supplyty === "Intra-state" && <>
                                                                                        <td class="text-center">
                                                                                            <input id="items.l.taxb" class="form-control newinv formedit currency" maxLength={16} value={values.items.l.taxb} onChange={e => {
                                                                                                const formatValue = getSubFormatvalue(e.target.value);
                                                                                                e.target.value = formatValue;
                                                                                                handleChange(e);
                                                                                            }} onBlur={e => {
                                                                                                if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                                    e.target.value = e.target.value + ".00";
                                                                                                }
                                                                                                handleChange(e);
                                                                                            }} disabled={values.items.l.total === ""} />
                                                                                        </td>
                                                                                        <td class="text-center">
                                                                                            <input id="items.l.taxb" class="form-control newinv formedit currency" maxLength={16} value={values.items.l.taxb} onChange={e => {
                                                                                                const formatValue = getSubFormatvalue(e.target.value);
                                                                                                e.target.value = formatValue;
                                                                                                handleChange(e);
                                                                                            }} onBlur={e => {
                                                                                                if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                                    e.target.value = e.target.value + ".00";
                                                                                                }
                                                                                                handleChange(e);
                                                                                            }} disabled={values.items.l.total === ""} />
                                                                                        </td>
                                                                                    </>
                                                                                }
                                                                                <td class="text-center">
                                                                                    <input id="items.l.cess" class="form-control newinv formedit currency" maxLength={16} value={values.items.l.cess} onChange={e => {
                                                                                        const formatValue = getSubFormatvalue(e.target.value);
                                                                                        e.target.value = formatValue;
                                                                                        handleChange(e);
                                                                                    }} onBlur={e => {
                                                                                        if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                                            e.target.value = e.target.value + ".00";
                                                                                        }
                                                                                        handleChange(e);
                                                                                    }} disabled={values.items.l.total === ""} />
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </fieldset>
                                            </div>
                                        }
                                    </fieldset>
                                    <div class="row">
                                        <div class="col-sm-12 col-xs-12 text-right next-tab-nav">
                                            <button type="button" class="btn btn-default" onClick={() => {
                                                gotoGstrPath('ab2ba');
                                            }}>Back</button>&nbsp;&nbsp;
                                            {!isView && <button type="submit" class="btn btn-primary accpt" disabled={submit}>Save</button>}
                                        </div>
                                    </div>
                                </div>
                            </form>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
