import React, { useState, useEffect } from 'react';

import { useRecoilState } from 'recoil';
import { pathAtom, gstrpathAtom, digErrorAtom, returnDateAtom, loadingAtom, gstrUserAtom, gstrFileStatusAtom, userIDAtom } from '../../../core/config/atoms';

import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Dialog } from '@headlessui/react';

import '../css/main.css';
import '../css/style.css';

import ServiceOption from '../../../services/serviceOptions';
import ServiceGSTR from '../../../services/serviceGSTR';

import imgLoader from '../../../assets/images/cloading.gif';

export default function PanelA12Home() {

    const [path, setPath] = useRecoilState(pathAtom);
    const [gstrPath, setGSTRPath] = useRecoilState(gstrpathAtom);
    const [digError, setDigError] = useRecoilState(digErrorAtom);
    const [gDate] = useRecoilState(returnDateAtom);
    const [loading, setLoading] = useRecoilState(loadingAtom);
    const [GSTUser] = useRecoilState(gstrUserAtom);
    const [fileStatus] = useRecoilState(gstrFileStatusAtom);
    const [userID] = useRecoilState(userIDAtom);

    const [records, setRecords] = useState([]);
    const [processRecords, setProcessRecords] = useState([]);
    const [selected, setSelected] = useState([]);

    const [fullCode, setFullCode] = useState([]);
    const [topCode, setTopCode] = useState([]);

    const [selItem, setSelItem] = useState({});
    const [hsnErrors, setHSNError] = useState({ isError: false, error: "" });
    const [digConfirm, setDigConfirm] = useState({ 'type': '0', title: '', isToggle: false, data: null });

    const [query, setQuery] = useState("");
    const [invno, setInvno] = useState('');

    const [emptErrorA, setEmptErrorA] = useState('');
    const [emptErrorB, setEmptErrorB] = useState('');
    const [emptErrorC, setEmptErrorC] = useState('');

    const [editid, setEditid] = useState('');

    const [isHSN, setIsHSN] = useState(false);
    const [isHORS, setIsHORS] = useState(false);
    const [isMaster, setIsMaster] = useState(false);
    const [isSearching, setIsSearching] = useState(false);
    const [isDigOpen, setIsDigOpen] = useState(false);
    const [isNew, setIsNew] = useState(false);
    const [submit, setSubmit] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [isPEdit, setISPEdit] = useState(false);
    const [isAlert, setIsAlrt] = useState(false);
    const [isChecked, setIsChecked] = useState(false);

    const [isRowC, setIsRowC] = useState(true);

    const optionsService = new ServiceOption();
    const returnService = new ServiceGSTR();

    const queryRegex = RegExp(
        /^[0-9]*$/
    );

    const formVSchema = Yup.object().shape({
        hsnno: Yup.string().required('This information is required'),
        UQC: Yup.string().required('This information is required'),
        quantity: Yup.string().required('This information is required'),
        invval: Yup.string().required('This information is required'),
        rate: Yup.string().required('This information is required'),
    });

    const { values, errors, touched, setValues, handleChange, handleSubmit, handleReset, resetForm } = useFormik({
        initialValues: {
            hsnno: '',
            disc: '',
            pname: '',
            UQC: '',
            quantity: '',
            invval: '',
            rate: '',
            taxa: '',
            taxb: '',
            taxc: '',
            cess: '',
        },
        validationSchema: formVSchema,
        onSubmit: values => {
            if (getTaxErr()) {
                values.taxa = values.taxa.length == 0 ? '0' : values.taxa;
                values.taxb = values.taxb.length == 0 ? '0' : values.taxb;
                values.taxc = values.taxc.length == 0 ? '0' : values.taxc;

                setSubmit(true);
                let body = {};
                if (isEdit) {
                    if (isPEdit) {
                        body = {
                            month: gDate,
                            lid: userID,
                            type: 'a12',
                            data: values,
                        }
                        returnService.checkGstrDoc(body).then((res) => {
                            if (res.status) {
                                if (res.statuscode === 202) {
                                    setInvno(values.inv_no);
                                } else {
                                    showAlert();
                                    resetData(values);
                                }
                            } else {
                                setDigError({ title: 'Error', msg: 'Server error please try again!', isToggle: true });
                            }
                            setSubmit(false);
                            setIsRowC(true);
                            window.scrollTo({
                                top: 0,
                                behavior: 'smooth',
                            });
                        });
                    } else {
                        body = {
                            id: editid,
                            data: values,
                        }
                        returnService.updateGstrDoc(body).then((res) => {
                            if (res.status) {
                                showAlert();
                                resetAll();
                                setSubmit(false);
                                window.scrollTo({
                                    top: 0,
                                    behavior: 'smooth',
                                });
                            } else {
                                setDigError({ title: 'Error', msg: 'Server error please try again!', isToggle: true });
                            }
                            setSubmit(false);
                        });
                    }
                } else {
                    body = {
                        month: gDate,
                        lid: userID,
                        type: 'a12',
                        data: values,
                    }
                    returnService.checkGstrDoc(body).then((res) => {
                        if (res.status) {
                            if (res.statuscode === 202) {
                                setInvno(values.inv_no);
                            } else {
                                showAlert();
                                resetData(values);
                            }
                        } else {
                            setDigError({ title: 'Error', msg: 'Server error please try again!', isToggle: true });
                        }
                        setSubmit(false);
                        window.scrollTo({
                            top: 0,
                            behavior: 'smooth',
                        });
                    });
                }
            }
        }
    });

    const getTaxErr = () => {
        let A = values.taxa.length;
        let B = values.taxb.length;
        let C = values.taxc.length;
        if (A == 0 && B == 0 && C == 0) {
            setEmptErrorA('Please enter Integrated tax (₹)');
            setEmptErrorB('Please enter Central tax (₹)');
            setEmptErrorC('Please enter State/UT tax (₹)');
            return false;
        }

        if (B == 0 && C != 0) {
            setEmptErrorA('');
            setEmptErrorB('Please enter Central tax (₹)');
            setEmptErrorC('');
            return false;
        } else if (C == 0 && B != 0) {
            setEmptErrorA('');
            setEmptErrorB('');
            setEmptErrorC('Please enter State/UT tax (₹)');
            return false;
        } else {
            setEmptErrorA('');
            setEmptErrorB('');
            setEmptErrorC('');
            return true;
        }
    }

    const gotoPath = (path) => {
        setLoading(true);
        setPath(path);
    }

    const getFormatvalue = (val) => {
        if (!val) return val;
        let number = val.replace(/[^\d.]/g, "");
        if (val.includes(".")) {
            const a = number.split(".");
            if (a[1].length > 2) {
                a[1] = a[1].substring(0, 2);
            }
            if (a[0].length > 11) {
                a[0] = a[0].substring(0, 11);
            }
            number = a[0] + "." + a[1];
        } else {
            if (number.length > 11) {
                number = number.substring(0, 11);
            }
        }
        return number;
    }

    const handelQuery = (value) => {
        if (value.length >= 2) {
            setIsSearching(true);
        } else {
            setIsSearching(false);
        }

        if (value.length >= 2) {
            let data = [];
            data = [...fullCode.filter(item =>
                item.title.toLowerCase().includes(value.toLowerCase()) || item.description.toLowerCase().includes(value.toLowerCase())
            )];
            setTopCode(data);
            setIsSearching(false);
        }

        setQuery(value);
    }

    const selectItem = (item) => {
        setIsSearching(false);
        setQuery("");
        setTopCode([]);
        setValues({ ...values, hsnno: item.title, disc: item.description, quantity: (item.type === "HSN" || item.type === "new") ? '' : '0', UQC: "NA" });
        setSelItem(item);
        if (item.type === "HSN" || item.type === "new") {
            setIsHORS(true);
        } else {
            setIsHORS(false);
        }
        setIsNew(false);
        setIsHSN(true);
    }

    const showAlert = () => {
        setIsAlrt(true);
        setTimeout(
            () => setIsAlrt(false),
            5000
        );
    }

    const resetData = (val) => {
        resetForm();
        setSelItem({});
        setIsHORS(false);
        setIsHSN(false);
        setInvno('');
        let recs = [...processRecords]
        if (isPEdit) {
            recs[(editid - 1)] = values;
            setProcessRecords(recs);
            setIsEdit(false);
            setISPEdit(false);
            setEditid('');
        } else {
            recs.push(values);
            setProcessRecords(recs);
        }
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    }

    const resetAll = () => {
        resetForm();
        setSelItem({});
        setIsHORS(false);
        setIsHSN(false);
        setIsEdit(false);
        setISPEdit(false);
        setEditid('');
        setInvno('');
        setProcessRecords([]);
        loadData();
    }

    const saveAll = () => {
        setSubmit(true);
        let body = {};
        let count = 0;

        processRecords.forEach((val, index) => {
            setTimeout(function () {
                body = {
                    month: gDate,
                    lid: userID,
                    type: 'a12',
                    data: val,
                }
                returnService.setGstrDoc(body).then((res) => {
                    if (res.status) {
                        count = count + 1;
                        if (processRecords.length >= count) {
                            showAlert();
                            resetAll();
                            setSubmit(false);
                            window.scrollTo({
                                top: 0,
                                behavior: 'smooth',
                            });
                        }
                    } else {
                        setDigError({ title: 'Error', msg: 'Server error please try again!', isToggle: true });
                        setSubmit(false);
                        window.scrollTo({
                            top: 0,
                            behavior: 'smooth',
                        });
                    }
                });
            }, parseInt(index) * 100);
        });
    }

    const resetHSN = () => {
        resetForm();
        setSelItem({});
        setISPEdit(false);
        setIsEdit(false);
        setIsHORS(false);
        setIsHSN(false);
        setInvno('');
    }

    const getPRecords = () => {
        let data = [];
        processRecords.forEach((item, index) => {
            data.push({ id: (index + 1), hsn: item.hsnno, disc: item.disc, uqc: item.UQC, qty: item.quantity, tv: item.invval, rate: item.rate, igst: item.taxa, cgst: item.taxb, sgst: item.taxc, cess: item.cess });
        });
        return data;
    }

    const getRecords = () => {
        let data = [];
        records.forEach((item, index) => {
            data.push({ id: item._id, index: (index + 1), hsn: item.data.hsnno, disc: item.data.disc, uqc: item.data.UQC, qty: item.data.quantity, tv: item.data.invval, rate: item.data.rate, igst: item.data.taxa, cgst: item.data.taxb, sgst: item.data.taxc, cess: item.data.cess });
        });
        return data;
    }

    const showDig = (type, data, title) => {
        setDigConfirm({ 'type': type, title: title, isToggle: true, data: data });
    }

    const cancel = () => {
        setDigConfirm({ 'type': '0', title: '', isToggle: false, data: null });
    }

    const process = () => {
        switch (digConfirm.type) {
            case '1':
                let rec = [...processRecords];
                rec.splice((digConfirm.data - 1), 1);
                setProcessRecords(rec);
                break;
            case '2':
                gotoPath('gstrForm');
                break;
            case '3':
                deleteInvoice(digConfirm.data.id);
                break;
            case '4':
                resetAll();
                break;
            case '5':
                deleteSeletected();
                break;
            default:
                break;
        }
        setDigConfirm({ 'type': '0', title: '', isToggle: false, data: null });
    }

    const deleteInvoice = (id) => {
        setLoading(true);
        let body = {
            lid: userID,
            type: 'a12',
            status: 'processed',
            month: gDate,
            id: id
        }
        returnService.deleteGstrDoc(body).then((res) => {
            setLoading(false);
            showAlert();
            loadData();
        });
    }

    const editPRecords = (id) => {
        setISPEdit(true);
        setIsEdit(true);
        let erec = processRecords[(id - 1)];
        let code = fullCode[fullCode.findIndex((e) => e.title === erec.hsnno)];
        setIsSearching(false);
        setQuery("");
        setTopCode([]);

        if (code != undefined) {
            setSelItem(code);
            if (code.type === "HSN" || code.type === "new") {
                setIsHORS(true);
            } else {
                setIsHORS(false);
            }
            setIsNew(false);
        } else {
            let cd = { title: erec.hsnno, description: erec.disc, type: 'new' };
            setSelItem(cd);
            setIsNew(true);
        }

        setEditid(id);
        setValues({ ...erec });
        setIsHSN(true);
    }

    const editRecords = (id) => {
        resetAll();
        setIsEdit(true);
        let erec = records[records.findIndex((e) => e._id === id)];
        let code = fullCode[fullCode.findIndex((e) => e.title === erec.data.hsnno)];
        setIsSearching(false);
        setQuery("");
        setTopCode([]);

        if (code != undefined) {
            setSelItem(code);
            if (code.type === "HSN" || code.type === "new") {
                setIsHORS(true);
            } else {
                setIsHORS(false);
            }
            setIsNew(false);
        } else {
            let cd = { title: erec.data.hsnno, description: erec.data.disc, type: 'new' };
            setSelItem(cd);
            setIsNew(true);
        }

        setEditid(id);
        setValues({ ...erec.data });
        setIsHSN(true);
    }

    const deleteSeletected = async () => {
        setLoading(true);
        let count = 0;
        if (isChecked) {
            for (const item of records) {
                let body = {
                    lid: userID,
                    type: 'a12',
                    status: 'processed',
                    month: gDate,
                    id: item._id
                }
                await returnService.deleteGstrDoc(body);
            }

            setLoading(false);
            showAlert();
            setSelected([]);
            setIsChecked(false);
            loadData();
        } else {
            for (const item of selected) {
                let body = {
                    lid: userID,
                    type: 'a12',
                    status: 'processed',
                    month: gDate,
                    id: item._id
                }
                await returnService.deleteGstrDoc(body);
            }

            setLoading(false);
            showAlert();
            setSelected([]);
            setIsChecked(false);
            loadData();
        }
    }

    const getDeleteStatus = () => {
        if (isChecked) {
            return false;
        } else if (selected.length !== 0) {
            return false;
        }
        return true;
    }

    const setCheckIndex = (id) => {
        let sel = [...selected];
        if (sel.includes(id)) {
            sel.splice(sel.findIndex((e) => e === id), 1);
            setSelected(sel);
        } else {
            sel.push(id);
            setSelected(sel);
        }
        if (sel.length === records.length) {
            setIsChecked(true);
        } else {
            setIsChecked(false);
        }
    }

    const loadData = () => {
        (async function () {
            setLoading(true);

            let body = {};

            var resB = await optionsService.getFillterHsn(body);
            var resA = await optionsService.getFillterSac(body);

            setFullCode([...resB.data, ...resA.data]);

            let bodyB = {
                lid: userID,
                month: gDate,
                type: 'a12'
            }
            var res = await returnService.getGstrDocs(bodyB);
            setRecords(res.data);

            setLoading(false);
            window.scrollTo({
                top: 0,
                behavior: 'smooth',
            });
        })();
    }

    useEffect(() => {
        loadData();
    }, []);

    return (
        <div class="content-wrapper fade-ng-cloak">
            <Dialog open={digConfirm.isToggle} onClose={() => setDigConfirm({ 'type': '0', isToggle: false, data: null })}>
                <div id="confirmDlg" class="modal fade fade-scale in" role="dialog" style={{ display: 'block', paddingRight: '17px' }}>
                    <div class="modal-dialog sweet">
                        <div class="modal-content">
                            <div class="modal-body">
                                <div class="m-icon m-warning pulseWarning">
                                    <span class="micon-body pulseWarningIns"></span>
                                    <span class="micon-dot pulseWarningIns"></span>
                                </div>
                                <h2>Warning</h2>
                                <p>{digConfirm.title}</p>
                            </div>
                            <div class="modal-footer">
                                <a class="btn btn-default" onClick={() => { cancel() }}>Cancel</a>
                                <a class="btn btn-primary" onClick={() => { process() }}>{digConfirm.type === "2" ? "OK" : "Proceed"}</a>
                            </div>
                        </div>
                    </div>
                </div>
            </Dialog>
            <div class="dimmer-holder" style={{ display: 'none' }}>
                <div id="dimmer"></div>
            </div>
            {
                loading && <div className='absolute z-50 w-full h-screen flex items-center justify-center bg-gray-200 bg-opacity-25'>
                    <img src={imgLoader} alt="loader" />
                </div>
            }
            <div class="container">
                <div class="mypage">
                    <div class="row" data-ng-controller="transctrl" data-ng-init="init('returns')">
                        <div class="col-xs-10">
                            <div name="Dashboard">
                                <ol class="breadcrumb" data-ng-controller="crumbCtrl">
                                    <li onClick={() => {
                                        gotoPath('gstr')
                                    }}><a>Dashboard</a></li>
                                    <li onClick={() => {
                                        gotoPath('gstRetrun')
                                    }}><a>Returns</a></li>
                                    <li onClick={() => {
                                        gotoPath('gstrForm')
                                    }}><a>GSTR-1/IFF</a></li>
                                    <li>HSN</li>
                                </ol>
                            </div>
                        </div>
                        <div class="col-xs-2">
                            <div class="lang dropdown">
                                <span class="dropdown-toggle" data-toggle="dropdown" data-ng-bind="selectedLang">English</span>
                                <ul class="dropdown-menu lang-dpdwn">
                                    <li data-ng-repeat="language in languages">
                                        <a data-ng-click="selectLang(language.cd)" data-ng-bind="language.nm">English</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div class="myPage">
                            <div>
                                <div>
                                    {isAlert && <alert-message ng-show="showMsg" title="" type="msg alert-success" message="" class="ng-hide"><div class="alert alert-msg alert-success">Request accepted successfully<a class="close" onClick={() => { setIsAlrt(false) }} data-dismiss="alert" aria-label="close">×</a><strong></strong>.</div></alert-message>}

                                    <div class="row invsumm">
                                        <div class="col-xs-12 col-sm-12 taxp">
                                            <h4 data-ng-bind="trans.TITLE_HSN">12 - HSN - wise summary of outward supplies</h4>
                                            <button class="btn btn-primary btn-circle btn-sm pull-right" data-toggle="tooltip" title="Refresh" style={{ marginTop: '0px' }} disabled={true}>
                                                <i class="fa fa-refresh" aria-hidden="true"></i>
                                            </button>
                                            <button type="button" style={{ marginTop: '0px', marginRight: '5px' }} class="btn btn-primary btn-sm pull-right" disabled={true}>
                                                <span>Help</span>&nbsp;
                                                <i class="fa fa-question-circle" aria-hidden="true"></i>
                                            </button>
                                        </div>
                                    </div>

                                    <div class="tabpane aqua-blue">

                                        <div class="row">
                                            <span class="col-xs-12 col-sm-12">Note:
                                                <ol className='pl-10'>
                                                    <li data-ng-bind="trans.CLICK_OUTSIDE_TO_ENABLE_FIELDS">1. In case there are no suggestions for any HSN, then after typing the required HSN; click on description/UQC to enable other fields.</li>
                                                    <li data-ng-bind="trans.LBL_DRP_MANUAL">2. Please select HSN from the search results dropdown only. In case HSN entered is not available, you can enter HSN manually</li>
                                                    <li data-ng-bind="trans.LBL_SAVE_ALERT_HSN">3. Kindly click on save button after any modification( add, edit) to save the changes</li>
                                                </ol>
                                            </span>
                                        </div>

                                        {
                                            records.length === 0 && <div class="alert alert-msg alert-info alert-dismissible " data-ng-if="firttimeflag" data-ng-bind="trans.HSN_ERR_NO_INV">There are no saved records to be displayed.</div>
                                        }

                                        <Dialog open={isDigOpen} onClose={() => setIsDigOpen(false)} className='overflow-y-auto overflow-x-hidden z-50 w-full h-modal md:h-full fixed top-0 left-0 bg-black bg-opacity-70'>
                                            <div id="prodMastAdd" class="modal fade fade-scale in" role="dialog" style={{ display: 'block' }}>
                                                <div class="modal-dialog sweet" style={{ width: '1250px' }}>
                                                    <div class="modal-content">
                                                        <div class="modal-header">
                                                            <h4 class="modal-title font-weight-bold" style={{ fontWeight: 'bold' }}>ADD PRODUCT MASTER</h4>
                                                        </div>
                                                        <div class="modal-body" style={{ overflowY: 'auto', maxHeight: 'calc(100vh - 160px)', paddingTop: '0px' }}>
                                                            <form name="prdmstrFrm" novalidate="novalidate">
                                                                <div class="row">
                                                                    <div class="col-sm-12 pull-left head" style={{ marginTop: '8px' }}>
                                                                        <div class="row" style={{ paddingLeft: '26px' }}>
                                                                            <div class="col-4"></div>
                                                                            <div class="col-4  font-weight-bold redlink" style={{ marginTop: '10px', marginRight: '2.2%' }}>
                                                                                <b><span class="pull-right red" style={{ color: 'red' }}>Field(s) marked with red dot (.) are mandatory</span></b>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="row" style={{ marginLeft: '0%' }}>
                                                                    <div class="col-sm-6 form-group">
                                                                        <div>
                                                                            <label rowspan="2" class="gstTblHeadr m100TblHdr reg">HSN Code</label>
                                                                            <div className={`${touched.hsnno ? errors.hsnno ? 'has-error' : 'has-success' : ''}`}>
                                                                                <input type="text" class="form-control formedit" disabled={true} onChange={handleChange} value={values.hsnno} />
                                                                                {(errors.hsnno && touched.hsnno) && <span className='err'>{errors.hsnno}</span>}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-sm-6 form-group">
                                                                        <div>
                                                                            <label rowspan="2" class="gstTblHeadr m100TblHdr reg" data-ng-bind="trans.LBL_HSN_DESC">HSN Description</label>
                                                                        </div>
                                                                        <div className={`${touched.disc ? errors.disc ? 'has-error' : 'has-success' : ''}`} >
                                                                            <input type="text" class="form-control formedit" name="disc" title="" disabled={true} onChange={handleChange} value={values.disc} />
                                                                            {(errors.disc && touched.disc) && <span className='err'>{errors.disc}</span>}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="row" style={{ marginLeft: '0%' }}>
                                                                    <div class="col-sm-6 form-group">
                                                                        <div>
                                                                            <label class="gstTblHeadr m100TblHdr reg m-cir">Product Name</label>
                                                                        </div>
                                                                        <div>
                                                                            <input type="text" class="form-control formedit" maxLength={100} name="productName" />
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-sm-6 form-group">
                                                                        <div>
                                                                            <label class="gstTblHeadr m100TblHdr reg">IGST Rate(%)</label>
                                                                        </div>
                                                                        <div id="rt">
                                                                            <label className={`${touched.rate ? errors.rate ? 'has-error' : 'has-success' : ''}`}></label>
                                                                            <select name="rate" class="form-control" id="rate" value={values.rate} style={{ marginTop: '-3%' }} disabled={true}>
                                                                                <option value="" selected="selected">Select</option>
                                                                                <option label="0" value="0">0</option>
                                                                                <option label="0.1" value="0.1">0.1</option>
                                                                                <option label="0.25" value="0.25">0.25</option>
                                                                                <option label="1" value="1">1</option>
                                                                                <option label="1.5" value="1.5">1.5</option>
                                                                                <option label="3" value="3">3</option>
                                                                                <option label="5" value="5">5</option>
                                                                                <option label="6" value="6">6</option>
                                                                                <option label="7.5" value="7.5">7.5</option>
                                                                                <option label="12" value="12">12</option>
                                                                                <option label="18" value="18">18</option>
                                                                                <option label="28" value="28">28</option>
                                                                            </select>
                                                                            {(errors.rate && touched.rate) && <span className='err'>{errors.rate}</span>}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="row" style={{ marginLeft: '0%' }}>
                                                                    <div class="col-sm-6 form-group">
                                                                        <div>
                                                                            <label rowspan="2" class="gstTblHeadr psTblHdr reg">UQC</label>
                                                                        </div>
                                                                        <div id="uqc">
                                                                            <label class={`${touched.UQC ? errors.UQC ? 'has-error' : 'has-success' : ''}`}></label>
                                                                            <select class="form-control" style={{ marginTop: '-3%' }} name="uqc" disabled={true}>								<option value="">Select</option>
                                                                                <option value="">Select</option>
                                                                                <option value="BAG">BAG-BAGS </option>
                                                                                <option value="BAL">BAL-BALE </option>
                                                                                <option value="BDL">BDL-BUNDLES </option>
                                                                                <option value="BKL">BKL-BUCKLES </option>
                                                                                <option value="BOU">BOU-BILLION OF UNITS </option>
                                                                                <option value="BOX">BOX-BOX </option>
                                                                                <option value="BTL">BTL-BOTTLES </option>
                                                                                <option value="BUN">BUN-BUNCHES </option>
                                                                                <option value="CAN">CAN-CANS </option>
                                                                                <option value="CBM">CBM-CUBIC METERS </option>
                                                                                <option value="CCM">CCM-CUBIC CENTIMETERS </option>
                                                                                <option value="CMS">CMS-CENTIMETERS </option>
                                                                                <option value="CTN">CTN-CARTONS </option>
                                                                                <option value="DOZ">DOZ-DOZENS </option>
                                                                                <option value="DRM">DRM-DRUMS </option>
                                                                                <option value="GGK">GGK-GREAT GROSS </option>
                                                                                <option value="GMS">GMS-GRAMMES </option>
                                                                                <option value="GRS">GRS-GROSS </option>
                                                                                <option value="GYD">GYD-GROSS YARDS </option>
                                                                                <option value="KGS">KGS-KILOGRAMS </option>
                                                                                <option value="KLR">KLR-KILOLITRE</option>
                                                                                <option value="KME">KME-KILOMETRE </option>
                                                                                <option value="LTR">LTR-LITRES</option>
                                                                                <option value="MLT">MLT-MILILITRE</option>
                                                                                <option value="MTR">MTR-METERS</option>
                                                                                <option value="MTS">MTS-METRIC TON</option>
                                                                                <option value="NOS">NOS-NUMBERS</option>
                                                                                <option value="PAC">PAC-PACKS</option>
                                                                                <option value="PCS">PCS-PIECES</option>
                                                                                <option value="PRS">PRS-PAIRS</option>
                                                                                <option value="QTL">QTL-QUINTAL</option>
                                                                                <option value="ROL">ROL-ROLLS</option>
                                                                                <option value="SET">SET-SETS</option>
                                                                                <option value="SQF">SQF-SQUARE FEET</option>
                                                                                <option value="SQM">SQM-SQUARE METERS</option>
                                                                                <option value="SQY">SQY-SQUARE YARDS</option>
                                                                                <option value="TBS">TBS-TABLETS</option>
                                                                                <option value="TGM">TGM-TEN GROSS</option>
                                                                                <option value="THD">THD-THOUSANDS</option>
                                                                                <option value="TON">TON-TONNES</option>
                                                                                <option value="TUB">TUB-TUBES</option>
                                                                                <option value="UGS">UGS-US GALLONS</option>
                                                                                <option value="UNT">UNT-UNITS</option>
                                                                                <option value="YDS">YDS-YARDS</option>
                                                                                <option value="OTH">OTH-OTHERS</option>
                                                                                <option value="Test">Test - ER Scenario</option>
                                                                                <option value="NA" selected={true}>NA</option>
                                                                            </select>
                                                                            {(errors.UQC && touched.UQC) && <span className='err'>{errors.UQC}</span>}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="btn-toolbar" style={{ display: 'inherit', textAlign: 'right' }}>
                                                                    <button type="button" style={{ float: 'right' }} class="btn btn-secondary bn" onClick={() => {
                                                                        setIsDigOpen(false);
                                                                    }}>Back</button>
                                                                    <button type="submit" style={{ marginLeft: '84%', height: '37px' }} class="btn btn-primary" onClick={() => {
                                                                        setIsDigOpen(false);
                                                                    }}>Save</button>
                                                                </div>
                                                            </form>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Dialog>

                                        <div class="rettbl-format">
                                            <form name="quantity_pur" class="myPage" novalidate="" onSubmit={handleSubmit} onReset={handleReset}>
                                                <fieldset disabled={fileStatus}>
                                                    <div class="row">
                                                        <div class="row">
                                                            <div class="col-sm-6">
                                                                <h4 data-ng-bind="trans.ADD_EDIT_DET">Add/Edit Details</h4>
                                                            </div>
                                                            <div class="col-sm-6">
                                                                <a>
                                                                    <button type="button" class="btn btn-primary pull-right" style={{ margin: '0' }} data-toggle="tooltip" title="To import EWB data into HSN Section" disabled={true}>Import EWb Data</button>
                                                                </a>
                                                            </div>
                                                            <hr />
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class={`col-sm-4 ${touched.hsnno ? errors.hsnno ? 'has-error' : 'has-success' : ''}`} style={{ maxHeight: '79px' }}>
                                                            <label class="reg  m-cir" for="hsnno" data-ng-bind="trans.LBL_HSN">HSN</label>
                                                            <div placeholder="Enter Product Name as in Master/HSN Code/Description" title="Enter Product Name as in Master/HSN Code/Description" class="">
                                                                <div class="autocomplete-holder relative">
                                                                    <input id="hsnno" name="hsnno" type="text" placeholder="Enter Product Name as in Master/HSN Code/Description" maxLength={1000} minLength={1} class="form-control" value={values.hsnno} disabled={isHSN} onChange={(e) => {
                                                                        handelQuery(e.target.value);
                                                                        handleChange(e);
                                                                        setInvno('');
                                                                    }} onBlur={(e) => {
                                                                        let q = e.target.value;
                                                                        if (queryRegex.test(q) && q.length >= 2 && q.length <= 8) {
                                                                            let data = [];
                                                                            data = [...fullCode.filter(item =>
                                                                                item.title.toLowerCase().includes(q)
                                                                            )];
                                                                            if (data.length == 0) {
                                                                                setIsNew(true);
                                                                                selectItem({ title: query, description: '', type: 'new' });
                                                                            }
                                                                        }
                                                                    }} />
                                                                    {isHSN && <i className='fa fa-close icon absolute right-[10px] top-[10px] cursor-pointer' onClick={() => {
                                                                        resetHSN();
                                                                    }}></i>}
                                                                </div>
                                                            </div>
                                                            {(errors.hsnno && touched.hsnno) && <span className='err'>{errors.hsnno}</span>}
                                                            {query.length !== 0 && (queryRegex.test(query) ? query.length < 2 : query.length < 4) && <span className='err'>Enter 2 digits / 4 characters or more to search</span>}
                                                            {query.length !== 0 && (queryRegex.test(query) && query.length > 8) && <span className='err'>HSN code can be maximum of 8 digits</span>}
                                                            {(selItem !== {} && selItem.type === "new" && invno === "") && <span className='text-blue-600'>HSN not present in GST HSN Master</span>}
                                                            {invno !== "" && <span className='err'>Record with same HSN, UQC and Rate already exist</span>}
                                                            {hsnErrors.isError && <span className='err'>{hsnErrors.error}</span>}
                                                            {
                                                                (queryRegex.test(query) ? query.length >= 2 : query.length >= 4) && <div for="hsnno" class="autocomplete-dropdown bg-white z-50 absolute">
                                                                    {isSearching && <div class="autocomplete-searching">Searching..</div>}
                                                                    <div style={{ maxHeight: '360px', overflow: 'scroll' }}>
                                                                        {
                                                                            topCode.length >= 1 && topCode.map((item) => {
                                                                                return <div class="text-black p-2 hover:bg-blue-200 cursor-pointer" onClick={() => {
                                                                                    selectItem(item);
                                                                                }} key={item.title}>{item.title} - {item.description}</div>
                                                                            })
                                                                        }
                                                                    </div>
                                                                    {(!isSearching && topCode.length === 0) && (selItem === {}) && <div class="autocomplete-searching">No Results Found</div>}
                                                                </div>
                                                            }
                                                        </div>
                                                        <div class={`col-sm-4 ${touched.disc ? errors.disc ? 'has-error' : 'has-success' : ''}`}>
                                                            <label class="reg" for="disc">Description</label>
                                                            <input type="text" class="form-control" name="disc" id="disc" title="" value={values.disc} onChange={handleChange} />
                                                            {(errors.disc && touched.disc) && <span className='err'>{errors.disc}</span>}
                                                        </div>
                                                        <div class={`col-sm-4 ${touched.pname ? errors.pname ? 'has-error' : 'has-success' : ''}`}>
                                                            <label class="m-cir reg" for="pname">Product name as in Master</label>
                                                            <input type="text" name="pname" class="form-control formedit" title="" value={values.pname} onChange={handleChange} disabled={true} />
                                                            {(errors.pname && touched.pname) && <span className='err'>{errors.pname}</span>}
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-sm-4">
                                                            {(values.hsnno !== "" && values.UQC !== "" && values.rate !== "") && <button type="button" class="btn btn-primary ng-hide" style={{ marginLeft: '16px' }} onClick={() => { setIsDigOpen(true) }}>Add to Master</button>}
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class={`col-sm-4 ${touched.UQC ? errors.UQC ? 'has-error' : 'has-success' : ''} ${touched.UQC ? invno === "" ? 'has-error' : 'has-success' : ''}`}>
                                                            <label class="m-cir reg" for="UQC" data-ng-bind="trans.LBL_UQC">UQC</label>
                                                            <select name="UQC" class="form-control" id="UQC" value={values.UQC} onChange={(e) => {
                                                                setInvno('');
                                                                handleChange(e);
                                                            }} disabled={!isHSN || !isHORS}>
                                                                {
                                                                    !isHORS && <option value="NA" selected={true}>NA</option>
                                                                }
                                                                {
                                                                    isHORS && <>
                                                                        <option value="">Select</option>
                                                                        <option value="BAG">BAG-BAGS </option>
                                                                        <option value="BAL">BAL-BALE </option>
                                                                        <option value="BDL">BDL-BUNDLES </option>
                                                                        <option value="BKL">BKL-BUCKLES </option>
                                                                        <option value="BOU">BOU-BILLION OF UNITS </option>
                                                                        <option value="BOX">BOX-BOX </option>
                                                                        <option value="BTL">BTL-BOTTLES </option>
                                                                        <option value="BUN">BUN-BUNCHES </option>
                                                                        <option value="CAN">CAN-CANS </option>
                                                                        <option value="CBM">CBM-CUBIC METERS </option>
                                                                        <option value="CCM">CCM-CUBIC CENTIMETERS </option>
                                                                        <option value="CMS">CMS-CENTIMETERS </option>
                                                                        <option value="CTN">CTN-CARTONS </option>
                                                                        <option value="DOZ">DOZ-DOZENS </option>
                                                                        <option value="DRM">DRM-DRUMS </option>
                                                                        <option value="GGK">GGK-GREAT GROSS </option>
                                                                        <option value="GMS">GMS-GRAMMES </option>
                                                                        <option value="GRS">GRS-GROSS </option>
                                                                        <option value="GYD">GYD-GROSS YARDS </option>
                                                                        <option value="KGS">KGS-KILOGRAMS </option>
                                                                        <option value="KLR">KLR-KILOLITRE</option>
                                                                        <option value="KME">KME-KILOMETRE </option>
                                                                        <option value="LTR">LTR-LITRES</option>
                                                                        <option value="MLT">MLT-MILILITRE</option>
                                                                        <option value="MTR">MTR-METERS</option>
                                                                        <option value="MTS">MTS-METRIC TON</option>
                                                                        <option value="NOS">NOS-NUMBERS</option>
                                                                        <option value="PAC">PAC-PACKS</option>
                                                                        <option value="PCS">PCS-PIECES</option>
                                                                        <option value="PRS">PRS-PAIRS</option>
                                                                        <option value="QTL">QTL-QUINTAL</option>
                                                                        <option value="ROL">ROL-ROLLS</option>
                                                                        <option value="SET">SET-SETS</option>
                                                                        <option value="SQF">SQF-SQUARE FEET</option>
                                                                        <option value="SQM">SQM-SQUARE METERS</option>
                                                                        <option value="SQY">SQY-SQUARE YARDS</option>
                                                                        <option value="TBS">TBS-TABLETS</option>
                                                                        <option value="TGM">TGM-TEN GROSS</option>
                                                                        <option value="THD">THD-THOUSANDS</option>
                                                                        <option value="TON">TON-TONNES</option>
                                                                        <option value="TUB">TUB-TUBES</option>
                                                                        <option value="UGS">UGS-US GALLONS</option>
                                                                        <option value="UNT">UNT-UNITS</option>
                                                                        <option value="YDS">YDS-YARDS</option>
                                                                        <option value="OTH">OTH-OTHERS</option>
                                                                        <option value="Test">Test - ER Scenario</option>
                                                                    </>
                                                                }
                                                            </select>
                                                            {(errors.UQC && touched.UQC) && <span className='err'>{errors.UQC}</span>}
                                                        </div>
                                                        <div class={`col-sm-4 ${touched.quantity ? errors.quantity ? 'has-error' : 'has-success' : ''}`}>
                                                            <label class="m-cir reg " for="quantity">Total Quantity</label>
                                                            <input class="form-control text-right" name="quantity" id="quantity" maxLength={14} value={values.quantity} onChange={e => {
                                                                const formatValue = getFormatvalue(e.target.value);
                                                                e.target.value = formatValue;
                                                                handleChange(e);
                                                            }} onBlur={e => {
                                                                if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                    e.target.value = e.target.value + ".00";
                                                                }
                                                                handleChange(e);
                                                            }} disabled={!isHSN || !isHORS} />
                                                            {(errors.quantity && touched.quantity) && <span className='err'>{errors.quantity}</span>}
                                                        </div>
                                                        <div class={`col-sm-4 ${touched.invval ? errors.invval ? 'has-error' : 'has-success' : ''}`}>
                                                            <label class="m-cir reg " for="invval">Total taxable value (₹)</label>
                                                            <input class="form-control text-right" name="invval" id="invval" maxLength={14} value={values.invval} onChange={e => {
                                                                const formatValue = getFormatvalue(e.target.value);
                                                                e.target.value = formatValue;
                                                                handleChange(e);
                                                            }} onBlur={e => {
                                                                if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                    e.target.value = e.target.value + ".00";
                                                                }
                                                                handleChange(e);
                                                            }} disabled={!isHSN} />
                                                            {(errors.invval && touched.invval) && <span className='err'>{errors.invval}</span>}
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class={`col-sm-4 ${touched.rate ? errors.rate ? 'has-error' : 'has-success' : ''} ${touched.rate ? invno === "" ? 'has-error' : 'has-success' : ''}`}>
                                                            <label class="m-cir reg" for="rate">Rate (%)</label>
                                                            <select name="rate" class="form-control" id="rate" value={values.rate} onChange={(e) => {
                                                                setInvno('');
                                                                handleChange(e);
                                                            }} disabled={!isHSN}>
                                                                <option value="" selected="selected">Select</option>
                                                                <option label="0" value="0">0</option>
                                                                <option label="0.1" value="0.1">0.1</option>
                                                                <option label="0.25" value="0.25">0.25</option>
                                                                <option label="1" value="1">1</option>
                                                                <option label="1.5" value="1.5">1.5</option>
                                                                <option label="3" value="3">3</option>
                                                                <option label="5" value="5">5</option>
                                                                <option label="6" value="6">6</option>
                                                                <option label="7.5" value="7.5">7.5</option>
                                                                <option label="12" value="12">12</option>
                                                                <option label="18" value="18">18</option>
                                                                <option label="28" value="28">28</option>
                                                            </select>
                                                            {(errors.rate && touched.rate) && <span className='err'>{errors.rate}</span>}
                                                        </div>
                                                        <div class={`col-sm-4 ${touched.taxa ? emptErrorA.length != 0 ? 'has-error' : 'has-success' : ''}`}>
                                                            <label class="reg m-cir" for="taxa">Integrated tax (₹)</label>
                                                            <input class="form-control text-right" name="taxa" id="taxa" maxLength={14} value={values.taxa} onChange={e => {
                                                                const formatValue = getFormatvalue(e.target.value);
                                                                e.target.value = formatValue;
                                                                handleChange(e);
                                                            }} onBlur={e => {
                                                                if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                    e.target.value = e.target.value + ".00";
                                                                }
                                                                handleChange(e);
                                                            }} disabled={!isHSN} />
                                                            {emptErrorA.length != 0 && <span className='err'>{emptErrorA}</span>}
                                                        </div>
                                                        <div class={`col-sm-4 ${touched.taxb ? emptErrorB.length != 0 ? 'has-error' : 'has-success' : ''}`}>
                                                            <label class="reg m-cir" for="taxb">Central  tax (₹)</label>
                                                            <input class="form-control text-right" name="taxb" id="taxb" maxLength={14} value={values.taxb} onChange={e => {
                                                                const formatValue = getFormatvalue(e.target.value);
                                                                e.target.value = formatValue;
                                                                handleChange(e);
                                                            }} onBlur={e => {
                                                                if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                    e.target.value = e.target.value + ".00";
                                                                }
                                                                handleChange(e);
                                                            }} disabled={!isHSN} />
                                                            {emptErrorB.length != 0 && <span className='err'>{emptErrorB}</span>}
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class={`col-sm-4 ${touched.taxc ? emptErrorC.length != 0 ? 'has-error' : 'has-success' : ''}`}>
                                                            <label class="reg m-cir" for="taxc">State/UT  tax (₹)</label>
                                                            <input class="form-control text-right" name="taxc" id="taxc" maxLength={14} value={values.taxc} onChange={e => {
                                                                const formatValue = getFormatvalue(e.target.value);
                                                                e.target.value = formatValue;
                                                                handleChange(e);
                                                            }} onBlur={e => {
                                                                if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                    e.target.value = e.target.value + ".00";
                                                                }
                                                                handleChange(e);
                                                            }} disabled={!isHSN} />
                                                            {emptErrorC.length != 0 && <span className='err'>{emptErrorC}</span>}
                                                        </div>
                                                        <div class={`col-sm-4 ${touched.cess ? errors.cess ? 'has-error' : 'has-success' : ''}`}>
                                                            <label class="reg " for="cess">Cess (₹)</label>
                                                            <input class="form-control text-right" name="cess" id="cess" maxLength={14} value={values.cess} onChange={e => {
                                                                const formatValue = getFormatvalue(e.target.value);
                                                                e.target.value = formatValue;
                                                                handleChange(e);
                                                            }} onBlur={e => {
                                                                if (e.target.value.length != 0 && !e.target.value.includes(".")) {
                                                                    e.target.value = e.target.value + ".00";
                                                                }
                                                                handleChange(e);
                                                            }} disabled={!isHSN} />
                                                            {(errors.cess && touched.cess) && <span className='err'>{errors.cess}</span>}
                                                        </div>
                                                    </div>
                                                    <div class="row mar-b">
                                                        <div class="col-sm-12 col-xs-12 text-right next-tab-nav">
                                                            {
                                                                (processRecords.length === 0 && records.length === 0) && <>
                                                                    <button type="button" class="btn btn-default" onClick={() => {
                                                                        gotoPath('gstrForm');
                                                                    }}>Back</button>&nbsp;
                                                                </>
                                                            }
                                                            <button type="button" class="btn btn-default" msg="All details entered in the fields will be deleted, do you want to continue?" disabled={!isHSN} onClick={() => {
                                                                if (isEdit) {
                                                                    showDig('4', {}, 'All details entered in the fields will be deleted, do you want to continue?');
                                                                } else {
                                                                    resetHSN();
                                                                }
                                                                setIsRowC(true);
                                                            }}>{isEdit ? 'CANCEL' : 'RESET'}</button>&nbsp;
                                                            <button type="submit" class="btn btn-primary" disabled={!isHSN}>ADD</button>
                                                        </div>
                                                    </div>
                                                </fieldset>
                                                {
                                                    processRecords.length !== 0 && <>
                                                        <div class="row" data-ng-show="cnt.b2bCptSummary">
                                                            <h4 className='col-lg-4'>Added/Edited Invoices to be saved</h4>
                                                            <div class="col-lg-4">
                                                                <div class="form-group pull-right" style={{ display: 'inline-flex' }}>
                                                                    <div class="col-lg-6"><label class="form-label">Search : </label></div>
                                                                    <input class="form-control pull-right" type="text" style={{ width: '93%', border: '1px solid', fontFamily: 'FontAwesome' }} placeholder="&nbsp;Search..." disabled={true} />
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-4">
                                                                <div class="form-group pull-right" style={{ display: 'inline-flex' }}>
                                                                    <div class="col-lg-10"><label class="form-label">Records Per Page : </label></div>
                                                                    <div class="col-lg-2" style={{ paddingLeft: '0', marginLeft: '-0.7em' }}>
                                                                        <select class="form-control" style={{ minWidth: '5em', border: '1px solid' }} disabled={true} value="item">
                                                                            <option label="10" value="number:10" selected="selected">10</option>
                                                                            <option label="20" value="number:20">20</option>
                                                                            <option label="50" value="number:50">50</option>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="row" data-ng-show="cnt.b2bCptSummary">
                                                            <div class="col-sm-12">
                                                                <div class="table-responsive">
                                                                    <table class="table tbl inv table-bordered ng-table" style={{ display: 'table' }}>
                                                                        <thead ng-include="templates.header">
                                                                            <tr class="ng-table-sort-header">
                                                                                <th title="" rowSpan={2}>
                                                                                    <div class="ng-table-header">
                                                                                        <span class="sort-indicator">Sr No.</span>
                                                                                    </div>
                                                                                </th>
                                                                                <th title="" rowSpan={2}>
                                                                                    <div class="ng-table-header">
                                                                                        <span class="sort-indicator">HSN</span>
                                                                                    </div>
                                                                                </th>
                                                                                <th title="" rowSpan={2}>
                                                                                    <div class="ng-table-header">
                                                                                        <span class="sort-indicator">Description</span>
                                                                                    </div>
                                                                                </th>
                                                                                <th title="" rowSpan={2}>
                                                                                    <div class="ng-table-header">
                                                                                        <span class="sort-indicator">UQC</span>
                                                                                    </div>
                                                                                </th>
                                                                                <th title="" rowSpan={2}>
                                                                                    <div class="ng-table-header">
                                                                                        <span class="sort-indicator">Total Quantity</span>
                                                                                    </div>
                                                                                </th>
                                                                                <th title="" rowSpan={2}>
                                                                                    <div class="ng-table-header">
                                                                                        <span class="sort-indicator">Total taxable value (₹)</span>
                                                                                    </div>
                                                                                </th>
                                                                                <th title="" rowSpan={2}>
                                                                                    <div class="ng-table-header">
                                                                                        <span class="sort-indicator">Rate (%)</span>
                                                                                    </div>
                                                                                </th>
                                                                                <th title="" colSpan={4}>
                                                                                    <div class="ng-table-header">
                                                                                        <span class="sort-indicator">Amount of tax</span>
                                                                                    </div>
                                                                                </th>
                                                                                <th title="" class="header" rowSpan={2} colSpan={2}>
                                                                                    <div class="ng-table-header">
                                                                                        <span class="sort-indicator">Actions</span>
                                                                                    </div>
                                                                                </th>
                                                                            </tr>
                                                                            <tr class="ng-table-sort-header">
                                                                                <th title="">
                                                                                    <div class="ng-table-header">
                                                                                        <span class="sort-indicator">Integrated Tax (₹)</span>
                                                                                    </div>
                                                                                </th>
                                                                                <th title="">
                                                                                    <div class="ng-table-header">
                                                                                        <span class="sort-indicator">Central Tax (₹)</span>
                                                                                    </div>
                                                                                </th>
                                                                                <th title="">
                                                                                    <div class="ng-table-header">
                                                                                        <span class="sort-indicator">State / UT Tax (₹)</span>
                                                                                    </div>
                                                                                </th>
                                                                                <th title="">
                                                                                    <div class="ng-table-header">
                                                                                        <span class="sort-indicator">Cess (₹)</span>
                                                                                    </div>
                                                                                </th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody data-ng-repeat="cpty in $data">
                                                                            {
                                                                                getPRecords().map((row) => {
                                                                                    return <tr>
                                                                                        <td class="text-center">{row.id}</td>
                                                                                        <td class="text-center" >{row.hsn}</td>
                                                                                        <td class="text-center max-w-[156px]" style={{ maxWidth: '156px !important', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{row.disc}</td>
                                                                                        <td class="text-center" >{row.uqc}</td>
                                                                                        <td class="text-center" >{row.qty}</td>
                                                                                        <td class="text-center" >{parseInt(row.tv).toFixed(2)}</td>
                                                                                        <td class="text-center" >{row.rate}</td>
                                                                                        <td class="text-center" >{parseInt(row.igst).toFixed(2)}</td>
                                                                                        <td class="text-center" >{parseInt(row.cgst).toFixed(2)}</td>
                                                                                        <td class="text-center" >{parseInt(row.sgst).toFixed(2)}</td>
                                                                                        <td class="text-center" >{row.cess !== "" ? parseInt(row.cess).toFixed(2) : "-"}</td>
                                                                                        <td class="text-center">
                                                                                            <div className='flex'>
                                                                                                <button type="button" class="btn btn-sm btn-primary nomargin" onClick={() => {
                                                                                                    setIsRowC(false);
                                                                                                    editPRecords(row.id);
                                                                                                }} disabled={isPEdit && row.id == editid}><i class="fa fa-pencil" aria-hidden="true"></i><span class="sr-only">text</span>
                                                                                                </button>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td class="text-center">
                                                                                            <div className='flex'>
                                                                                                <button type="button" class="btn btn-sm btn-danger nomargin" title="Delete" onClick={() => {
                                                                                                    showDig('1', { id: row.id }, 'Are you sure, you want to continue?');
                                                                                                }} disabled={isPEdit && row.id == editid}>
                                                                                                    <i class="fa fa-trash"></i><span class="sr-only">text</span>
                                                                                                </button>
                                                                                            </div>
                                                                                        </td>
                                                                                    </tr>
                                                                                })
                                                                            }
                                                                        </tbody>
                                                                    </table>
                                                                    <div ng-table-pagination="params" template-url="templates.pagination">
                                                                        <div ng-include="templateUrl">
                                                                            <div class="ng-table-pager" ng-if="params.data.length"></div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {
                                                            isRowC && <div class="row mar-b">
                                                                <div class="col-sm-12 col-xs-12 text-right next-tab-nav">
                                                                    <button type="button" class="btn btn-primary" onClick={() => {
                                                                        saveAll();
                                                                    }} disabled={isPEdit}>SAVE</button>&nbsp;
                                                                    <button type="button" class="btn btn-primary" onClick={() => {
                                                                        resetForm();
                                                                        setSelItem({});
                                                                        setIsHORS(false);
                                                                        setIsHSN(false);
                                                                        setInvno('');
                                                                        setProcessRecords([]);
                                                                        window.scrollTo({
                                                                            top: 0,
                                                                            behavior: 'smooth',
                                                                        });
                                                                    }} disabled={isPEdit}>RESET</button>
                                                                </div>
                                                            </div>
                                                        }
                                                    </>
                                                }
                                            </form>
                                            <div class="row">
                                                <div class="col-sm-12 col-xs-12 text-right next-tab-nav">
                                                    {
                                                        (processRecords.length !== 0 && records.length === 0) && <>
                                                            <button type="button" class="btn btn-default" onClick={() => {
                                                                gotoPath('gstrForm');
                                                            }}>Back</button>&nbsp;
                                                        </>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div class="rettbl-format">
                                            {
                                                records.length !== 0 && <>
                                                    <div class="row" data-ng-show="cnt.b2bCptSummary">
                                                        <div class="col-sm-12">
                                                            <h4 className='col-lg-4'>Proceed Records</h4>
                                                            <div class="col-lg-4">
                                                                <div class="form-group pull-right" style={{ display: 'inline-flex' }}>
                                                                    <div class="col-lg-6"><label class="form-label">Search : </label></div>
                                                                    <input class="form-control pull-right" type="text" style={{ width: '93%', border: '1px solid', fontFamily: 'FontAwesome' }} placeholder="&nbsp;Search..." disabled={true} />
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-4">
                                                                <div class="form-group pull-right" style={{ display: 'inline-flex' }}>
                                                                    <div class="col-lg-10"><label class="form-label">Records Per Page : </label></div>
                                                                    <div class="col-lg-2" style={{ paddingLeft: '0', marginLeft: '-0.7em' }}>
                                                                        <select class="form-control" style={{ minWidth: '5em', border: '1px solid' }} disabled={true} value="item">
                                                                            <option label="10" value="number:10" selected="selected">10</option>
                                                                            <option label="20" value="number:20">20</option>
                                                                            <option label="50" value="number:50">50</option>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="row" data-ng-show="cnt.b2bCptSummary">
                                                        <div class="col-sm-12">
                                                            <div class="table-responsive">
                                                                <table class="table tbl inv table-bordered ng-table" style={{ display: 'table' }}>
                                                                    <thead ng-include="templates.header">
                                                                        <tr class="ng-table-sort-header">
                                                                            {
                                                                                !isEdit && <th title="" rowSpan={2}>
                                                                                    <div class="ng-table-header">
                                                                                        <div>
                                                                                            <input type="checkbox" class="chkbx" checked={isChecked} onChange={() => { setIsChecked(!isChecked) }} disabled={fileStatus} />
                                                                                            <label for="auth_prim"></label>
                                                                                        </div>
                                                                                    </div>
                                                                                </th>
                                                                            }
                                                                            <th title="" rowSpan={2}>
                                                                                <div class="ng-table-header">
                                                                                    <span class="sort-indicator">Sr No.</span>
                                                                                </div>
                                                                            </th>
                                                                            <th title="" rowSpan={2}>
                                                                                <div class="ng-table-header">
                                                                                    <span class="sort-indicator">HSN</span>
                                                                                </div>
                                                                            </th>
                                                                            <th title="" rowSpan={2}>
                                                                                <div class="ng-table-header">
                                                                                    <span class="sort-indicator">Description</span>
                                                                                </div>
                                                                            </th>
                                                                            <th title="" rowSpan={2}>
                                                                                <div class="ng-table-header">
                                                                                    <span class="sort-indicator">UQC</span>
                                                                                </div>
                                                                            </th>
                                                                            <th title="" rowSpan={2}>
                                                                                <div class="ng-table-header">
                                                                                    <span class="sort-indicator">Total Quantity</span>
                                                                                </div>
                                                                            </th>
                                                                            <th title="" rowSpan={2}>
                                                                                <div class="ng-table-header">
                                                                                    <span class="sort-indicator">Total taxable value (₹)</span>
                                                                                </div>
                                                                            </th>
                                                                            <th title="" rowSpan={2}>
                                                                                <div class="ng-table-header">
                                                                                    <span class="sort-indicator">Rate (%)</span>
                                                                                </div>
                                                                            </th>
                                                                            <th title="" colSpan={4}>
                                                                                <div class="ng-table-header">
                                                                                    <span class="sort-indicator">Amount of tax</span>
                                                                                </div>
                                                                            </th>
                                                                            <th title="" class="header" rowSpan={2} colSpan={2}>
                                                                                <div class="ng-table-header">
                                                                                    <span class="sort-indicator">Actions</span>
                                                                                </div>
                                                                            </th>
                                                                        </tr>
                                                                        <tr class="ng-table-sort-header">
                                                                            <th title="">
                                                                                <div class="ng-table-header">
                                                                                    <span class="sort-indicator">Integrated Tax (₹)</span>
                                                                                </div>
                                                                            </th>
                                                                            <th title="">
                                                                                <div class="ng-table-header">
                                                                                    <span class="sort-indicator">Central Tax (₹)</span>
                                                                                </div>
                                                                            </th>
                                                                            <th title="">
                                                                                <div class="ng-table-header">
                                                                                    <span class="sort-indicator">State / UT Tax (₹)</span>
                                                                                </div>
                                                                            </th>
                                                                            <th title="">
                                                                                <div class="ng-table-header">
                                                                                    <span class="sort-indicator">Cess (₹)</span>
                                                                                </div>
                                                                            </th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody data-ng-repeat="cpty in $data">
                                                                        {
                                                                            getRecords().map((row) => {
                                                                                return <tr>
                                                                                    {
                                                                                        !isEdit && <td class="text-center">
                                                                                            <div>
                                                                                                <input type="checkbox" class="chkbx" checked={isChecked || selected.includes(row.id)} onChange={() => {
                                                                                                    setCheckIndex(row.id);
                                                                                                }} disabled={fileStatus || (row.id === editid)} />
                                                                                                <label for="auth_prim"></label>
                                                                                            </div>
                                                                                        </td>
                                                                                    }
                                                                                    <td class="text-center">{row.index}</td>
                                                                                    <td class="text-center" >{row.hsn}</td>
                                                                                    <td class="text-center max-w-[156px]" style={{ maxWidth: '156px !important', width: '156px !important', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{row.disc}</td>
                                                                                    <td class="text-center" >{row.uqc}</td>
                                                                                    <td class="text-center" >{row.qty}</td>
                                                                                    <td class="text-center" >{parseInt(row.tv).toFixed(2)}</td>
                                                                                    <td class="text-center" >{row.rate}</td>
                                                                                    <td class="text-center" >{parseInt(row.igst).toFixed(2)}</td>
                                                                                    <td class="text-center" >{parseInt(row.cgst).toFixed(2)}</td>
                                                                                    <td class="text-center" >{parseInt(row.sgst).toFixed(2)}</td>
                                                                                    <td class="text-center" >{row.cess !== "" ? parseInt(row.cess).toFixed(2) : "-"}</td>
                                                                                    <td class="text-center">
                                                                                        <div className='flex'>
                                                                                            <button type="button" class="btn btn-sm btn-primary nomargin" onClick={() => {
                                                                                                editRecords(row.id);
                                                                                            }} disabled={fileStatus || (row.id === editid)}><i class="fa fa-pencil" aria-hidden="true"></i><span class="sr-only">text</span>
                                                                                            </button>
                                                                                        </div>
                                                                                    </td>
                                                                                    <td class="text-center">
                                                                                        <div className='flex'>
                                                                                            <button type="button" class="btn btn-sm btn-danger nomargin" title="Delete" onClick={() => {
                                                                                                showDig('3', { id: row.id }, 'Are you sure, you want to continue?');
                                                                                            }} disabled={fileStatus || (row.id === editid)}>
                                                                                                <i class="fa fa-trash"></i><span class="sr-only">text</span>
                                                                                            </button>
                                                                                        </div>
                                                                                    </td>
                                                                                </tr>
                                                                            })
                                                                        }
                                                                    </tbody>
                                                                </table>
                                                                <div ng-table-pagination="params" template-url="templates.pagination">
                                                                    <div ng-include="templateUrl">
                                                                        <div class="ng-table-pager" ng-if="params.data.length"></div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {
                                                        isEdit && <div class="row">
                                                            <div class="col-sm-12 col-xs-12 text-right next-tab-nav">
                                                                <button type="button" class="btn btn-primary" onClick={() => {
                                                                    resetForm();
                                                                    setSelItem({});
                                                                    setIsHORS(false);
                                                                    setIsHSN(false);
                                                                    setInvno('');
                                                                    setProcessRecords([]);
                                                                    window.scrollTo({
                                                                        top: 0,
                                                                        behavior: 'smooth',
                                                                    });
                                                                }}>RESET</button>
                                                            </div>
                                                        </div>
                                                    }
                                                    <div class="row mar-b">
                                                        <div class="col-sm-12 col-xs-12 text-right next-tab-nav">
                                                            {
                                                                (records.length !== 0 && !isEdit) && <button type="button" class="btn btn-primary" onClick={() => {
                                                                    showDig('5', {}, 'Selected records entered in the table will be deleted, do you want to continue?');
                                                                }} disabled={getDeleteStatus()}>DELETE</button>
                                                            }
                                                            &nbsp;
                                                            <button type="button" class="btn btn-default" data-ng-bind="trans.LBL_BACK" data-ng-hide="hsndata.length > 0 || phsndata.length > 0 || processedData.length > 0" onClick={() => {
                                                                if (isEdit) {
                                                                    showDig('2', {}, 'Are you sure you want to continue? Any unsaved data will be lost.');
                                                                } else {
                                                                    gotoPath('gstrForm');
                                                                }
                                                            }}>Back</button>
                                                        </div>
                                                    </div>
                                                </>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
