import React, { useState, useEffect } from 'react';

import Moment from 'moment';
import FeatherIcon from 'feather-icons-react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Dialog } from '@headlessui/react';

import { useRecoilState } from 'recoil';
import { pathAtom, loadingAtom, trnLevelAtom, trnAtom, digErrorAtom, dirtyAtom, userIDAtom, userAtom } from '../core/config/atoms';

import ServiceAuth from '../services/serviceAuth';
import ServiceUtility from '../services/serviceUtility';

export default function ComponentTRNLevelJ() {

    const [path, setPath] = useRecoilState(pathAtom);
    const [loading, setLoading] = useRecoilState(loadingAtom);
    const [trnLevel, SetTRNLevel] = useRecoilState(trnLevelAtom);
    const [trnno] = useRecoilState(trnAtom);
    const [digError, setDigError] = useRecoilState(digErrorAtom);
    const [isDirty, setIsDirty] = useRecoilState(dirtyAtom);
    const [userID] = useRecoilState(userIDAtom);
    const [userAuth] = useRecoilState(userAtom);

    const [gstUsers, setGSTUsers] = useState([]);

    const [gstUser, setGSTUser] = useState({});

    const [otpText, setOTPText] = useState('');

    const [selectedUser, setSelectedUser] = useState(-1);
    const [otp, setOTP] = useState(0);

    const [isSubmit, setIsSubmit] = useState(false);
    const [isSelected, setIsSelected] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [otpError, setOTPError] = useState(false);

    const authService = new ServiceAuth();
    const utilService = new ServiceUtility();

    const formSchema = Yup.object().shape({
        authveri: Yup.boolean().required('This information is required'),
        veriPlace: Yup.string().required('Verification place is required.'),
    });

    const { values, errors, touched, dirty, handleChange, handleSubmit, handleReset, resetForm, setValues } = useFormik({
        enableReinitialize: true,
        initialValues: {
            authveri: false,
            authsign: '',
            veriPlace: '',
        },
        validationSchema: formSchema,
        onSubmit: values => {
            let ot = utilService.getCode();
            setOTP(ot);
            setIsOpen(true);
        }
    });

    const gotoPath = (path) => {
        SetTRNLevel({ ...trnLevel, level: path });
    }

    const getEnable = () => {
        if (values.authveri && isSubmit && values.veriPlace) {
            if (isSelected) {
                return false;
            } else {
                return true;
            }
        } else {
            return true;
        }
    }

    const handleOnChangeForm = (e) => {
        var { value, name } = e.target;
        setOTPText(value);
    }

    const validate = () => {
        const otpRegex = RegExp(
            /^[0-9]{6}$/
        );
        if (otpRegex.test(otpText)) {
            if (otp == otpText) {
                setLoading(true);
                setOTPError(true);
                let data = [...trnLevel.form];
                data[9] = "1";
                var body = {
                    gstNum: getGSTNumber(),
                    place: values.veriPlace,
                    trnno: trnno,
                    lid: userID,
                    statusArr: data,
                    id: userID,
                    user: userAuth,
                }
                authService.setGSTUserFormJ(body).then((res) => {
                    if (res.statuscode === 200) {
                        gotoPath('sa11');
                    } else {
                        setDigError({ title: 'Error', msg: 'Server error please try again!', isToggle: true });
                    }
                    setLoading(false);
                });
            } else {
                setOTPError(true);
            }
        } else {
            setOTPError(true);
        }
    }

    const getGSTNumber = () => {
        let scode = utilService.getStateCode(gstUser.state);
        let numa = utilService.getRCode(1);
        let numb = utilService.getRCode(1);
        return scode + gstUser.panNumber + numa + "Z" + numb;
    }

    const loadData = () => {
        (async function () {
            setLoading(true);
            var res = await authService.getGSTUserByTRN({ 'trn': trnno, 'stdOwn': userID });
            setGSTUser(res.data[0]);
            SetTRNLevel({ ...trnLevel, form: res.data[0]['statusArr'], percentage: utilService.calculatePercentage(res.data[0]['statusArr']) });
            if (res.data[0]['statusArr'][1] === '1') {
                setGSTUsers(res.data[0]['users']);
            }
            if (utilService.calculatePercentage(res.data[0]['statusArr']) >= 100) {
                setIsSubmit(true);
            } else {
                setIsSubmit(false);
            }
            setLoading(false);
            window.scrollTo({
                top: 0,
                behavior: 'smooth',
            });
        })();
    }

    useEffect(() => {
        loadData();
    }, []);

    useEffect(() => {
        setIsDirty(dirty);
    }, [dirty]);

    return (
        <form class="tabpane" name="newRegForm" id="newRegForm" onSubmit={handleSubmit} onReset={handleReset} autocomplete="off" novalidate="">
            {
                <Dialog open={isOpen} onClose={() => setIsOpen(false)}>
                    <div id="confirmDlg" class="modal fade fade-scale in" role="dialog" style={{ display: 'block', paddingRight: '17px' }}>
                        <div class="modal-dialog">
                            <div className={`rounded text-lg font-serif mt-10 mr-8 bg-yellow-100 shadow border border-red-700 h-14 flex flex-col justify-center`} style={{ position: 'absolute', right: '0px', top: '20px', zIndex: '5' }}>
                                <p className={`px-4 py-2 flex text-red-600 text-lg m-0`}>
                                    <FeatherIcon icon="bell" size={14} />&nbsp;&nbsp;Your OTP is for verification is: {otp}
                                </p>
                            </div>

                            <div class="modal-content">
                                <div class="modal-header">
                                    <p className='text-center text-2xl'>Verify Aadhaar OTP</p>
                                </div>
                                <div class="modal-body">
                                    <div class="row">
                                        <div class={`col-xs-12 ${otpError ? 'has-error' : 'has-success'}`}>
                                            <label for="veriOTP" class="reg m-cir">Please enter OTP</label>
                                            <input id="veriOTP" name="veriOTP" class="form-control" type="text" placeholder="Enter OTP" maxLength={6} required="" onChange={(e) => {
                                                e.target.value = utilService.formatOnlyNumber(e.target.value);
                                                handleOnChangeForm(e);
                                            }} />
                                            {otpError && <p class="err" data-ng-bind="errVar">Please enter a valid OTP.</p>}
                                        </div>
                                    </div>
                                    <p></p>
                                </div>
                                <div class="modal-footer">
                                    <a class="btn btn-default" onClick={() => { setIsOpen(false) }}>Close</a>
                                    <a class="btn btn-primary" onClick={() => { validate() }}>VALIDATE OTP</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </Dialog>
            }
            <div name="staus_msg"></div>
            <p class="mand-text">indicates mandatory fields</p>
            <h4>
                <i class="las la-thumbs-up text-4xl"></i>&nbsp;
                <span>Verification</span>
            </h4>
            <div class="tbl-format">
                <fieldset>
                    <div class="row">
                        <div class="inner">
                            <div class="col-xs-12 radio-order">
                                <input type="checkbox" class="chkbx" id="authveri" required="" name="authveri" onChange={handleChange} value={values.authveri} />
                                <label for="authveri"><span>I hereby solemnly affirm and declare that the information given herein above is true and correct to the best of my knowledge and belief and nothing has been concealed therefrom.</span></label>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="inner">
                            <div class="col-xs-12 col-sm-6">
                                <label for="authsign" class="reg m-cir">Name of Authorized Signatory</label>
                                <select class="form-control" id="authsign" name="authsign" required="" autofocus="" onChange={(e) => {
                                    let value = e.target.value;
                                    if (value >= 0) {
                                        setIsSelected(true);
                                        setSelectedUser(parseInt(value));
                                    } else {
                                        setIsSelected(false);
                                        setSelectedUser(-1);
                                    }
                                    handleChange(e);
                                }} value={values.authsign}>
                                    <option value="">Select</option>
                                    {
                                        gstUsers.map((item, index) => {
                                            return <option value={index} className='uppercase'>{item.pd_fnm} {item.pd_mname} {item.pd_lname}[{item.pan.toUpperCase()}]</option>
                                        })
                                    }
                                </select>
                            </div>
                            <div class={`col-xs-12 col-sm-6 ${touched.veriPlace ? errors.veriPlace ? 'has-error' : 'has-success' : ''}`}>
                                <label for="veriPlace" class="reg m-cir">Place</label>
                                <input id="veriPlace" name="veriPlace" class="form-control" type="text" placeholder="Enter Place" maxLength={50} required="" onChange={handleChange} value={values.veriPlace} />
                                {(errors.veriPlace && touched.veriPlace) && <p class="err">{errors.veriPlace}</p>}
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="inner">
                            <div class="col-xs-12 col-sm-6">
                                <label for="desig" class="reg m-cir">Designation / Status</label>
                                {isSelected && selectedUser >= 0 && <p>{gstUsers[selectedUser].dig}</p>}
                            </div>
                            <div class="col-xs-12 col-sm-6">
                                <label for="veriDate" class="reg m-cir" data-ng-bind="trans.LBL_DATE">Date</label>
                                <p>{Moment(Date.now()).format('DD/MM/yyyy')}</p>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="inner">
                            <div class="col-sm-12 col-xs-12">
                                <div class="alert alert-warning">
                                    <span>Submit buttons will get enabled only after all mandatory fields are filled. Please check that you have filled all mandatory fields in the Form.</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </fieldset>
                <div class="row next-tab-nav">
                    <div class="inner">
                        <div class="col-xs-12 text-right">
                            <a class="btn btn-default" style={{ marginRight: '8px' }} onClick={() => {
                                gotoPath('sa9');
                            }}>Back</a>
                            <button class="btn btn-primary hidden-sm hidden-xs" type="button" style={{ marginRight: '8px' }} disabled="disabled">Submit with DSC</button>
                            <button class="btn btn-primary hidden-sm hidden-xs" type="submit" disabled={getEnable()}>Submit with EVC</button>
                            {/* selectedUser */}
                        </div>
                    </div>
                </div>

                <div id="myQueryModal" class="modal fade">
                    <div class="modal-dialog">
                        <div class="modal-content">
                            <div class="modal-header">
                                <button type="button" class="close" data-dismiss="modal">×</button>
                                <h4 class="modal-title">Information</h4>
                            </div>
                            <div class="modal-body">
                                <div class="row">
                                    <div class="col-xs-12 col-sm-12">
                                        <p>Kindly Enter values for all the mandatory fields in business details tab.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-primary" data-dismiss="modal">Ok</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div id="compMsg" class="modal fade" style={{ color: 'red' }}>
                        <div class="modal-dialog">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h3 class="modal-title "><i class="fa fa-info-circle"></i>&nbsp;Warning</h3>
                                </div>
                                <div class="modal-body">
                                    <div class="row" style={{ backgroundColor: 'white' }}>
                                        <div class="col-xs-12 col-sm-12">
                                            <p data-ng-bind="ExceedResp.warngMsg"></p>
                                            <div>
                                                <u>Note:</u><br />
                                                <p data-ng-bind="ExceedResp.NT_PT_1"></p>
                                                <p data-ng-bind="ExceedResp.NT_PT_2"></p>
                                                <p data-ng-bind="ExceedResp.NT_PT_3"></p>
                                            </div>
                                            <div>
                                                <p>
                                                    To continue your registration as Normal Taxpayer, <span><a href="" data-dismiss="modal" data-ng-click="compDisable()">click here.</a></span> To make changes to your application, click 'MODIFY'
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="modal-footer">
                                    <button type="button" class="btn btn-primary" data-dismiss="modal" data-ng-click="showBusTab()">Modify</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="specialJursdModal" class="modal fade">
                    <div class="modal-dialog">
                        <div class="modal-content">
                            <div class="modal-header">
                                <button type="button" class="close" data-dismiss="modal">×</button>
                                <h4 class="modal-title">Information</h4>
                            </div>
                            <div class="modal-body">
                                <div class="row">
                                    <div class="col-xs-12 col-sm-12">
                                        <p>Please reselect the State jurisdiction in business details section.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-primary" data-dismiss="modal">Ok</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form >
    );
}
