import APIServiceLookUp from './serviceAPI';
import { API_URL } from './models/AppConstent';

export default class ServiceGSTR {
    lookupService = new APIServiceLookUp();

    async checkGstrDoc(body) {
        var serviceType = "POST";
        var url = `${API_URL}gstr/checkGstrDoc`;
        var token = '';
        var apiService = this.lookupService.getAPIService(serviceType);
        var response = await apiService.doCall(url, body, token);
        return response;
    }

    async deleteAllA13GstrDoc(body) {
        var serviceType = "POST";
        var url = `${API_URL}gstr/deleteAllA13GstrDoc`;
        var token = '';
        var apiService = this.lookupService.getAPIService(serviceType);
        var response = await apiService.doCall(url, body, token);
        return response;
    }

    async getGstrFiles(body) {
        var serviceType = "POST";
        var url = `${API_URL}gstr/getGstrFiles`;
        var token = '';
        var apiService = this.lookupService.getAPIService(serviceType);
        var response = await apiService.doCall(url, body, token);
        return response;
    }

    async setGsrtFiled(body) {
        var serviceType = "POST";
        var url = `${API_URL}gstr/setGsrtFiled`;
        var token = '';
        var apiService = this.lookupService.getAPIService(serviceType);
        var response = await apiService.doCall(url, body, token);
        return response;
    }

    async setGstrDoc(body) {
        var serviceType = "POST";
        var url = `${API_URL}gstr/setGstrDoc`;
        var token = '';
        var apiService = this.lookupService.getAPIService(serviceType);
        var response = await apiService.doCall(url, body, token);
        return response;
    }

    async getGstrDocs(body) {
        var serviceType = "POST";
        var url = `${API_URL}gstr/getGstrDocs`;
        var token = '';
        var apiService = this.lookupService.getAPIService(serviceType);
        var response = await apiService.doCall(url, body, token);
        return response;
    }

    async getGstrSummary(body) {
        var serviceType = "POST";
        var url = `${API_URL}gstr/getGstrSummary`;
        var token = '';
        var apiService = this.lookupService.getAPIService(serviceType);
        var response = await apiService.doCall(url, body, token);
        return response;
    }

    async GenGstrSummary(body) {
        var serviceType = "POST";
        var url = `${API_URL}gstr/GenGstrSummary`;
        var token = '';
        var apiService = this.lookupService.getAPIService(serviceType);
        var response = await apiService.doCall(url, body, token);
        return response;
    }

    async resetGstrSummery(body) {
        var serviceType = "POST";
        var url = `${API_URL}gstr/resetGstrSummery`;
        var token = '';
        var apiService = this.lookupService.getAPIService(serviceType);
        var response = await apiService.doCall(url, body, token);
        return response;
    }

    async deleteGstrDoc(body) {
        var serviceType = "POST";
        var url = `${API_URL}gstr/deleteGstrDoc`;
        var token = '';
        var apiService = this.lookupService.getAPIService(serviceType);
        var response = await apiService.doCall(url, body, token);
        return response;
    }

    async getGstrDocById(body) {
        var serviceType = "POST";
        var url = `${API_URL}gstr/getGstrDocById`;
        var token = '';
        var apiService = this.lookupService.getAPIService(serviceType);
        var response = await apiService.doCall(url, body, token);
        return response;
    }

    async updateGstrDoc(body) {
        var serviceType = "POST";
        var url = `${API_URL}gstr/updateGstrDoc`;
        var token = '';
        var apiService = this.lookupService.getAPIService(serviceType);
        var response = await apiService.doCall(url, body, token);
        return response;
    }

    async findInvoice(body) {
        var serviceType = "POST";
        var url = `${API_URL}gstr/findInvoice`;
        var token = '';
        var apiService = this.lookupService.getAPIService(serviceType);
        var response = await apiService.doCall(url, body, token);
        return response;
    }

}