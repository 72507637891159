import React, { useState, useEffect } from 'react';

import { useFormik } from 'formik';
import * as Yup from 'yup';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import { ThemeProvider } from "@material-ui/styles";
import { createMuiTheme } from "@material-ui/core";
import DateFnsUtils from '@date-io/date-fns';
import Moment from 'moment';
import { Dialog } from '@headlessui/react';

import { useRecoilState } from 'recoil';
import { pathAtom, loadingAtom, trnLevelAtom, trnAtom, digStorageAtom, digErrorAtom, docAtom, dirtyAtom, userIDAtom, userAtom } from '../core/config/atoms';

import ServiceAuth from '../services/serviceAuth';
import ServiceOption from '../services/serviceOptions';
import ServiceUtility from '../services/serviceUtility';

import icPdf from '../assets/images/misc/pdf.png';

export default function ComponentTRNLevelA() {


    const [path, setPath] = useRecoilState(pathAtom);
    const [loading, setLoading] = useRecoilState(loadingAtom);
    const [trnLevel, SetTRNLevel] = useRecoilState(trnLevelAtom);
    const [trnno] = useRecoilState(trnAtom);
    const [dig, setDig] = useRecoilState(digStorageAtom);
    const [digError, setDigError] = useRecoilState(digErrorAtom);
    const [doc, setDoc] = useRecoilState(docAtom);
    const [isDirty, setIsDirty] = useRecoilState(dirtyAtom);
    const [userID] = useRecoilState(userIDAtom);
    const [userAuth] = useRecoilState(userAtom);

    const [districts, setDistricts] = useState([]);
    const [tradeNames, setTradeNames] = useState([]);
    const [regNames, setRegNames] = useState([]);

    const [gstUser, setGSTUser] = useState({});
    const [digConfirm, setDigConfirm] = useState({ 'type': '0', isToggle: false, data: null });
    const [tempReg, setTempReg] = useState({ 'id': '', 'dtreg': '', 'ty': '' });

    const [selOption, setSelOption] = useState('');
    const [adt, setADT] = useState("");
    const [isRegAError, setIsRegAError] = useState('');
    const [isRegBError, setIsRegBError] = useState('');
    const [isPanError, setIsPanError] = useState('');

    const [isADTEdit, setIsADTEdit] = useState(-1);
    const [isRegEdit, setIsRegEdit] = useState(-1);

    const [mainDate, setMainDate] = useState(new Date());
    const [bdDate, setBDDate] = useState(new Date());
    const [libDate, setLibDate] = useState(new Date());

    const [isOpenA, setIsOpenA] = useState(false);
    const [isOpenB, setIsOpenB] = useState(false);
    const [isOpenC, setIsOpenC] = useState(false);
    const [isSubmit, setIsSubmit] = useState(false);
    const [isTError, setIsTError] = useState(false);
    const [isTRError, setIsTRError] = useState(false);

    const authService = new ServiceAuth();
    const optionService = new ServiceOption();
    const utilService = new ServiceUtility();

    const materialTheme = createMuiTheme({
        overrides: {
            MuiTypography: {
                subtitle1: {
                    fontSize: '16px',
                },
                caption: {
                    fontSize: '16px',
                    fontWeight: 'bold'
                },
                body1: {
                    "fontSize": '16px',
                },
                body2: {
                    "fontSize": '16px',
                }
            },
            MuiPickersToolbar: {
                toolbar: {
                    backgroundColor: '#239B56',
                },
            },
            MuiPickersDay: {
                day: {
                    color: '#17202A',
                },
                daySelected: {
                    backgroundColor: '#239B56',
                },
                dayDisabled: {
                    color: '#808B96',
                },
                current: {
                    color: '#239B56',
                },
            },
            MuiButton: {
                textPrimary: {
                    color: '#2E86C1',
                    fontSize: '12px',
                    border: '1px solid #2E86C1',
                    backgroundColor: 'transprent'
                }
            },
        },
    });

    const formSchema = Yup.object().shape({
        tname: Yup.string().matches(/^([a-zA-Z0-9\_&amp;\'\-\.\/\,()?@!#%$~*;+= ]*[a-zA-Z0-9!#$%&amp;'*+\/=?^_`{|}~\-\s]{1,99}$)/, { message: 'Please enter a valid trade name' }),
        ConstBuss: Yup.string().required('User profile is required'),
        district: Yup.string().required('Please select district'),
        bd_rsl: Yup.string().required('Please select reason to obtain registration'),
        bd_cmbz: Yup.string().required('Date of commencement of business is required').matches(/^[0-9]{2}\/[0-9]{2}\/[0-9]{4}$/, { message: 'Please enter a valid date' }),
        lib: Yup.string().required('Date on which liability to register is required').matches(/^[0-9]{2}\/[0-9]{2}\/[0-9]{4}$/, { message: 'Please enter a valid date' }),
    });

    const { values, errors, touched, dirty, handleChange, handleSubmit, handleReset, resetForm, setValues } = useFormik({
        enableReinitialize: true,
        initialValues: {
            tname: '',
            ConstBuss: '',
            district: '',
            bd_rsl: '',
            bd_cmbz: '',
            lib: '',
        },
        validationSchema: formSchema,
        onSubmit: values => {
            if (tempReg.ty === '') {
                const panRegex = RegExp(
                    /^[A-Z]{3}[P]{1}[A-Z]{1}[0-9]{4}[A-Z]{1}$/
                );
                if (panRegex.test(gstUser.panNumber)) {
                    setIsSubmit(true);
                    setLoading(true);

                    let data = [...trnLevel.form];
                    data[0] = "1";

                    if (!doc.isFile) {
                        let body = {
                            "user": userAuth,
                            ConstBuss: values.ConstBuss,
                            bd_cmbz: Moment(values.bd_cmbz, 'DD-MM-YYYY').add(1, 'days'),
                            bd_rsl: values.bd_rsl,
                            district: values.district,
                            lib: Moment(values.lib, 'DD-MM-YYYY').add(1, 'days'),
                            tname: values.tname,
                            trnno: trnno,
                            lid: userID,
                            statusArr: data,
                            tradeNames: tradeNames,
                            regNames: regNames,
                            tradeFile: [],
                        }
                        authService.setGSTUserFormA(body).then((res) => {
                            setLoading(false);
                            if (res.statuscode === 200) {
                                setDoc({ isFile: false, type: '', doc: null });
                                SetTRNLevel({ ...trnLevel, level: 'sa2' });
                            } else {
                                setDigError({ title: 'Error', msg: 'Server error please try again!', isToggle: true });
                            }
                            window.scrollTo(0, 0)
                        });
                    } else {
                        if (doc.type === 'storage') {
                            let body = {
                                "user": userAuth,
                                ConstBuss: values.ConstBuss,
                                bd_cmbz: Moment(values.bd_cmbz, 'DD-MM-YYYY').add(1, 'days'),
                                bd_rsl: values.bd_rsl,
                                district: values.district,
                                lib: Moment(values.lib, 'DD-MM-YYYY').add(1, 'days'),
                                tname: values.tname,
                                trnno: trnno,
                                lid: userID,
                                statusArr: data,
                                tradeNames: tradeNames,
                                regNames: regNames,
                                tradeFile: {
                                    filename: doc.doc.filename,
                                    fileurl: doc.doc.fileurl,
                                    filetype: 'storage',
                                }
                            }
                            authService.setGSTUserFormA(body).then((res) => {
                                setLoading(false);
                                if (res.statuscode === 200) {
                                    setDoc({ isFile: false, type: '', doc: null });
                                    SetTRNLevel({ ...trnLevel, level: 'sa2' });
                                } else {
                                    setDigError({ title: 'Error', msg: 'Server error please try again!', isToggle: true });
                                }
                                window.scrollTo(0, 0)
                            });
                        } else {
                            let mPromis = [];
                            var formDataA = new FormData();
                            formDataA.append("doc", doc.doc);
                            formDataA.append("id", userID);
                            mPromis.push(authService.setDocument(formDataA, ''));
                            Promise.all(mPromis).then((d) => {
                                if (d[0].data.status) {
                                    let ddata = d[0].data.data;
                                    let body = {
                                        "user": userAuth,
                                        ConstBuss: values.ConstBuss,
                                        bd_cmbz: Moment(values.bd_cmbz, 'DD-MM-YYYY').add(1, 'days'),
                                        bd_rsl: values.bd_rsl,
                                        district: values.district,
                                        lib: Moment(values.lib, 'DD-MM-YYYY').add(1, 'days'),
                                        tname: values['tname'],
                                        trnno: trnno,
                                        lid: userID,
                                        statusArr: data,
                                        tradeNames: tradeNames,
                                        regNames: regNames,
                                        tradeFile: {
                                            filename: ddata.filename,
                                            fileurl: ddata.fileurl,
                                            filetype: 'doc',
                                        }
                                    }
                                    authService.setGSTUserFormA(body).then((res) => {
                                        setLoading(false);
                                        if (res.statuscode === 200) {
                                            setDoc({ isFile: false, type: '', doc: null });
                                            SetTRNLevel({ ...trnLevel, level: 'sa2' });
                                        } else {
                                            setDigError({ title: 'Error', msg: 'Server error please try again!', isToggle: true });
                                        }
                                        window.scrollTo(0, 0)
                                    });
                                } else {
                                    setLoading(false);
                                    setDigError({ title: 'Error', msg: 'Server error please try again!', isToggle: true });
                                    window.scrollTo(0, 0)
                                }
                            });
                        }
                    }
                } else {
                    setIsPanError('Please check pancard number provided');
                }
            } else {
                checkTempReg();
            }
        }
    });

    const openPicker = (id) => {
        setSelOption(id);
        if (id === 'bd_cmbz') {
            setValues({ ...values, bd_cmbz: undefined });
        }
        switch (id) {
            case 'bd_cmbz':
                setIsOpenA(true);
                break;
            case 'lib':
                setIsOpenB(true);
                break;
            case 'dtreg':
                setIsOpenC(true);
                break;
        }
    }

    const handleDateChange = (e) => {
        switch (selOption) {
            case 'bd_cmbz':
                if (values.lib != '') {
                    let lhs = Moment(e, 'DD/MM/YYYY').toDate();
                    var dateParts = values.lib.split("/");
                    let rhs = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);
                    if (lhs > rhs) {
                        setValues({ ...values, bd_cmbz: Moment(e).format('DD/MM/YYYY').toString(), lib: '' });
                    } else {
                        setValues({ ...values, bd_cmbz: Moment(e).format('DD/MM/YYYY').toString() });
                    }
                } else {
                    setValues({ ...values, bd_cmbz: Moment(e).format('DD/MM/YYYY').toString() });
                }
                setBDDate(Moment(e, 'DD/MM/YYYY').toDate());
                break;
            case 'lib':
                setValues({ ...values, lib: Moment(e).format('DD/MM/YYYY').toString() });
                setLibDate(Moment(e, 'DD/MM/YYYY').toDate());
                break;
            case 'dtreg':
                setTempReg({ ...tempReg, dtreg: Moment(e).format('DD/MM/YYYY').toString() });
                setMainDate(Moment(e, 'DD/MM/YYYY').toDate());
                break;
            default:
                break;
        }
        setSelOption('');
    }

    const onChangeTradeName = (e) => {
        var { value, name } = e.target;
        setADT(value);
    }

    const onAddADT = () => {
        if (adt.length === 0) {
            setIsTError(true);
            setIsTRError(false);
        } else {
            if (values.tname == adt) {
                setIsTRError(true);
                setIsTError(false);
            } else {
                if (isADTEdit < 0) {
                    setIsTError(false);
                    setIsTRError(false);
                    setTradeNames([...tradeNames, adt]);
                    setADT("");
                } else {
                    setIsTError(false);
                    setIsTRError(false);
                    let temp = [...tradeNames];
                    temp[isADTEdit] = adt;
                    setTradeNames(temp);
                    setADT("");
                    setIsADTEdit(-1);
                }
            }
        }
    }

    const onCancel = () => {
        setADT("");
        setIsADTEdit(-1);
        setIsTError(false);
        setIsTRError(false);
    }

    const onADTEdit = (index) => {
        setADT(tradeNames[index]);
        setIsADTEdit(index);
    }

    const onADTDelete = (index) => {
        let temp = [...tradeNames];
        temp.splice(index, 1);
        setTradeNames(temp);
    }

    const onChangeReg = (e) => {
        var { value, name } = e.target;
        setTempReg({ ...tempReg, [name]: value });
    }

    const onRegAdd = () => {
        if (isRegEdit < 0) {
            if (tempReg.ty !== '') {
                if (checkTempReg()) {
                    setRegNames([...regNames, tempReg]);
                    setTempReg({ 'id': '', 'dtreg': '', 'ty': '' });
                    setIsRegAError('');
                    setIsRegBError('');
                }
            } else {
                checkTempReg()
            }
        } else {
            let temp = [...regNames];
            temp[isRegEdit] = tempReg;
            setRegNames(temp);
            setTempReg({ 'id': '', 'dtreg': '', 'ty': '' });
            setIsRegEdit(-1);
            setIsRegAError('');
            setIsRegBError('');
        }
    }

    const checkTempReg = () => {
        let erro = true;
        if (tempReg.id === '') {
            erro = false;
            setIsRegAError('N');
        } else {
            setIsRegAError('Y');
        }
        if (tempReg.dtreg === '') {
            erro = false;
            setIsRegBError('N');
        } else {
            setIsRegBError('Y');
        }
        return erro;
    }

    const onRegCancel = () => {
        setTempReg({ 'id': '', 'dtreg': '', 'ty': '' });
        setIsRegAError('');
        setIsRegBError('');
        setIsRegEdit(-1);
    }

    const onRegEdit = (index) => {
        setTempReg({ 'id': regNames[index]['id'], 'dtreg': regNames[index]['dtreg'], 'ty': regNames[index]['ty'] });
        setIsRegEdit(index);
    }

    const onRegDelete = (index) => {
        let temp = [...regNames];
        temp.splice(index, 1);
        setRegNames(temp);
    }

    const openBox = (e) => {
        e.preventDefault();
        setDoc({ isFile: false, type: '', doc: null });
        setDig({ title: '', msg: '', isToggle: true });
    }

    const removeDoc = () => {
        setDoc({ isFile: false, type: '', doc: null });
    }

    const showDig = (type, data) => {
        setDigConfirm({ 'type': type, isToggle: true, data: data });
    }

    const cancel = () => {
        setDigConfirm({ 'type': '0', isToggle: false, data: null });
    }

    const process = () => {
        switch (digConfirm.type) {
            case '1':
                onADTDelete(digConfirm.data);
                break;
            case '2':
                onRegDelete(digConfirm.data);
                break;
            case '3':
                removeDoc();
                break;
            default:
                break;

        }
        setDigConfirm({ 'type': '0', isToggle: false, data: null });
    }

    const gotoBack = () => {
        setPath('trnDashboard');
    }

    const loadData = () => {
        (async function () {
            setLoading(true);
            var res = await authService.getGSTUserByTRN({ 'trn': trnno, 'stdOwn': userID });
            setGSTUser(res.data[0]);
            var resDist = await optionService.getDistricts({ 'StateName': res.data[0].state });

            if (res.data[0].statusArr[0] === '1') {
                setValues({ ...values, ConstBuss: res.data[0]['ConstBuss'], bd_cmbz: Moment(res.data[0]['bd_cmbz'].substring(0, 10), 'YYYY-MM-DD').format('DD/MM/YYYY').toString(), bd_rsl: res.data[0]['bd_rsl'], district: res.data[0]['district'], lib: Moment(res.data[0]['lib'].substring(0, 10), 'YYYY-MM-DD').format('DD/MM/YYYY').toString(), tname: res.data[0]['tname'] });
                setTradeNames(res.data[0].tradeNames);
                setRegNames(res.data[0].regNames);
                if (res.data[0].tradeFile[0] !== undefined) {
                    setDoc({ isFile: true, type: res.data[0].tradeFile[0].filetype, doc: res.data[0].tradeFile[0] });
                }
            }

            setDistricts(resDist.data);
            SetTRNLevel({ ...trnLevel, form: res.data[0]['statusArr'], percentage: utilService.calculatePercentage(res.data[0]['statusArr']) });
            setLoading(false);
            window.scrollTo({
                top: 0,
                behavior: 'smooth',
            });
        })();
    }

    useEffect(() => {
        loadData();
    }, []);

    useEffect(() => {
        setIsDirty(dirty);
    }, [dirty]);

    return (
        <form class="tabpane" autocomplete="off" onSubmit={handleSubmit} onReset={handleReset} noValidate>
            <Dialog open={digConfirm.isToggle} onClose={() => setDigConfirm({ 'type': '0', isToggle: false, data: null })}>
                <div id="confirmDlg" class="modal fade fade-scale in" role="dialog" style={{ display: 'block', paddingRight: '17px' }}>
                    <div class="modal-dialog sweet">
                        <div class="modal-content">
                            <div class="modal-body">
                                <div class="m-icon m-warning pulseWarning">
                                    <span class="micon-body pulseWarningIns"></span>
                                    <span class="micon-dot pulseWarningIns"></span>
                                </div>
                                <h2>Warning</h2>
                                <p>Are you sure, you want to continue?</p>
                            </div>
                            <div class="modal-footer">
                                <a class="btn btn-default" onClick={() => { cancel() }}>Cancel</a>
                                <a class="btn btn-primary" onClick={() => { process() }}>Proceed</a>
                            </div>
                        </div>
                    </div>
                </div>
            </Dialog>
            <fieldset>
                <p class="mand-text" data-ng-bind="trans.HLP_MAND_FIELD">indicates mandatory fields</p>
                <h4 data-ng-bind="trans.HEAD_BUS_DT_BUS">Details of your Business</h4>
                <div class="tbl-format">
                    <div class="row">
                        <div class="inner">
                            <div class="col-sm-4 col-xs-12">
                                <label for="lgnmbzpan" class="reg">Legal Name of the Business</label>
                                <p><strong>{gstUser.buzName}</strong></p>
                            </div>
                            <div class="col-sm-4 col-xs-12">
                                <label for="bd_pan_num" class="reg">Permanent Account Number (PAN)</label>
                                <p><strong>{gstUser.panNumber}</strong></p>
                            </div>
                            <div class="col-sm-4 col-xs-12">
                                <label for="bd_pan_date_num" class="reg">Date of Creation of PAN</label>
                                <p><strong>XX/XX/XXXX</strong></p>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="inner">
                            <div class={`col-sm-4 col-xs-12 ${touched.tname ? errors.tname ? 'has-error' : 'has-success' : ''}`}>
                                <label for="tname" class="reg">Trade Name </label>
                                <input id="tname" name="tname" class="form-control" type="text" maxLength={99} placeholder="Enter Trade Name" autofocus="" onChange={handleChange} value={values.tname} />
                            </div>
                            <div class={`col-sm-4 col-xs-12 ${touched.ConstBuss ? errors.ConstBuss ? 'has-error' : 'has-success' : ''}`}>
                                <label for="ConstBuss" class="reg m-cir">Constitution of Business (Select Appropriate)</label>
                                <select id="ConstBuss" class="form-control" name="ConstBuss" required="" onChange={(e) => {
                                    setIsPanError('');
                                    handleChange(e)
                                }} value={values.ConstBuss}>
                                    <option value="">Select</option>
                                    <option value="PRO" className='text-black' selected={"PRO" === values.ConstBuss}>Proprietorship</option>
                                    <option value="FCO" disabled>Foreign Company</option>
                                    <option value="FLL" disabled>Foreign Limited Liability Partnership</option>
                                    <option value="GOV" disabled>Government Department</option>
                                    <option value="HUF" disabled>Hindu Undivided Family</option>
                                    <option value="LLP" disabled>Limited Liability Partnership</option>
                                    <option value="LOC" disabled>Local Authority</option>
                                    <option value="OTH" disabled>Others</option>
                                    <option value="PAR" disabled>Partnership</option>
                                    <option value="PVT" disabled>Private Limited Company</option>
                                    <option value="PUB" disabled>Public Limited Company</option>
                                    <option value="PSU" disabled>Public Sector Undertaking</option>
                                    <option value="SCT" disabled>Society/ Club/ Trust/ AOP</option>
                                    <option value="STB" disabled>Statutory Body</option>
                                    <option value="ULC" disabled>Unlimited Company</option>
                                </select>
                                {(errors.ConstBuss && touched.ConstBuss) && <p class="err">{errors.ConstBuss}</p>}
                                {isPanError !== "" && <p class="err">{isPanError}</p>}
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="inner">
                            <div class="col-sm-5 col-xs-12">
                                <label for="tnmsec" class="reg">Additional Trade Name</label>
                                <input value={adt} id="tnmsec" name="trdnmsec" data-ng-model="sectrade" class="form-control" type="text" maxLength={99} placeholder="Enter Trade Name" autofocus="" onChange={onChangeTradeName} disabled={values.tname == ''} />
                                {isTError && <p class="err">Kindly Enter Trade Name</p>}
                                {isTRError && <p class="err">Duplicate Additional Trade Name</p>}
                            </div>
                            <div class="col-xs-12 col-sm-6 mt-6">
                                <button title="Add" name="addtrade" class="btn btn-primary rowtp-mar" type="button" style={{ marginRight: '10px' }} disabled={(values.tname == '' && isADTEdit < 0) || tradeNames.length === 3} onClick={() => onAddADT()}>
                                    <i class="fa fa-plus"></i>&nbsp;
                                    {isADTEdit < 0 && <span class="col-xs-hidden" data-ng-bind="trans.LBL_ADD">Add</span>}
                                    {isADTEdit >= 0 && <span class="col-xs-hidden" data-ng-bind="trans.LBL_ADD">Save</span>}
                                </button>
                                <button title="Cancel" name="addtrade" class="btn btn-default rowtp-mar" type="button" onClick={() => { onCancel() }} disabled={values.tname == ''}>
                                    <i class="fa fa-times"></i>&nbsp;
                                    <span class="col-xs-hidden" data-ng-bind="trans.LBL_SAVE_CANCEL">Cancel</span>
                                </button>
                            </div>
                        </div>
                    </div>

                    {
                        tradeNames.length !== 0 && <div class="tbl-format">
                            <div class="row">
                                <div class="inner">
                                    <div class="col-xs-12">
                                        <div class="table-responsive">
                                            <table class="table">
                                                <thead>
                                                    <tr>
                                                        <th data-ng-bind="trans.LBL_NM_TRADE">Trade Name </th>
                                                        <th data-ng-bind="trans.LBL_ACTIONS">Actions</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        tradeNames.map((item, index) => {
                                                            return <tr key={index}>
                                                                <td data-ng-bind="tradeDetails">{item}</td>
                                                                <td>
                                                                    <button title="Edit" type="button" class="btn btn-sm btn-primary" style={{ marginRight: '10px' }} onClick={() => onADTEdit(index)}>
                                                                        <i class="fa fa-pencil"></i> <span class="col-xs-hidden" data-ng-bind="trans.LBL_EDIT">Edit</span>
                                                                    </button>
                                                                    <button title="Delete" type="button" class="btn btn-sm btn-danger" onClick={() => showDig('1', index)}>
                                                                        <i class="fa fa-trash"></i> <span class="col-xs-hidden" data-ng-bind="trans.LBL_DELETE">Delete</span>
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                        })
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }

                    <div class="row">
                        <div class="inner">
                            <div class="col-sm-4 col-xs-12">
                                <label for="bd_states" class="reg">Name of the State</label>
                                <p>{gstUser.state}</p>
                            </div>
                            <div class={`col-sm-4 col-xs-12 ${touched.district ? errors.district ? 'has-error' : 'has-success' : ''}`}>
                                <label class="reg m-cir" for="dist">District</label>
                                <select class={`form-control`} name="district" id="district" required="required" value={values.district} onChange={handleChange}>
                                    <option value="">Select</option>
                                    {
                                        districts.map((district) => {
                                            if (district === null || district === undefined)
                                                return "";
                                            return <option value={district} key={district} selected={district === values.district}>{district}</option>
                                        })
                                    }
                                </select>
                                {(errors.district && touched.district) && <p class="err">{errors.district}</p>}
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="inner">
                            <div class="col-sm-4 col-xs-12">
                                <label class="reg">
                                    <span class="reg">Are you applying for registration as a casual taxable person?</span>&nbsp;
                                    <span>
                                        <i class="fa fa-info-circle"></i>
                                    </span>
                                </label>
                                <div class="switch round">
                                    <input id="casual" type="checkbox" name="casual" disabled checked={false} />
                                    <label for="casual">
                                        <span class="switch-on" style={{ left: '4px' }}><span>Yes</span></span>
                                        <span class="switch-off" style={{ left: '35px' }}><span>No</span></span>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row ">
                        <div class="inner ">
                            <div class="col-sm-6 col-xs-12 ">
                                <label>
                                    <span class="reg">Option For Composition</span>&nbsp;
                                    <span>
                                        <i class="fa fa-info-circle"></i>
                                    </span>
                                </label>
                                <div class="switch round">
                                    <input id="composition" type="checkbox" name="composition" disabled checked={false} />
                                    <label for="composition">
                                        <span class="switch-on" style={{ left: '4px' }}><span>Yes</span></span>
                                        <span class="switch-off" style={{ left: '35px' }}><span>No</span></span>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>

                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <ThemeProvider theme={materialTheme}>
                            <DatePicker
                                variant="dialog"
                                open={isOpenA}
                                clearable={true}
                                onOpen={() => setIsOpenA(true)}
                                onClose={() => setIsOpenA(false)}
                                label="Open me from button"
                                format="dd/MM/yyyy"
                                value={bdDate}
                                maxDate={new Date()}
                                minDate={selOption === "dtreg" ?
                                    new Date('1947') :
                                    values.bd_cmbz === undefined ? new Date('1947') : Moment(values.bd_cmbz, 'DD/MM/YYYY').toDate()}
                                onChange={handleDateChange}
                                style={{ display: 'none' }}
                            />
                        </ThemeProvider>
                    </MuiPickersUtilsProvider>

                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <ThemeProvider theme={materialTheme}>
                            <DatePicker
                                variant="dialog"
                                open={isOpenB}
                                clearable={true}
                                onOpen={() => setIsOpenB(true)}
                                onClose={() => setIsOpenB(false)}
                                label="Open me from button"
                                format="dd/MM/yyyy"
                                value={libDate}
                                maxDate={new Date()}
                                minDate={selOption === "dtreg" ?
                                    new Date('1947') :
                                    values.bd_cmbz === undefined ? new Date('1947') : Moment(values.bd_cmbz, 'DD/MM/YYYY').toDate()}
                                onChange={handleDateChange}
                                style={{ display: 'none' }}
                            />
                        </ThemeProvider>
                    </MuiPickersUtilsProvider>

                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <ThemeProvider theme={materialTheme}>
                            <DatePicker
                                variant="dialog"
                                open={isOpenC}
                                clearable={true}
                                onOpen={() => setIsOpenC(true)}
                                onClose={() => setIsOpenC(false)}
                                label="Open me from button"
                                format="dd/MM/yyyy"
                                value={mainDate}
                                maxDate={new Date()}
                                minDate={selOption === "dtreg" ?
                                    new Date('1947') :
                                    values.bd_cmbz === undefined ? new Date('1947') : Moment(values.bd_cmbz, 'DD/MM/YYYY').toDate()}
                                onChange={handleDateChange}
                                style={{ display: 'none' }}
                            />
                        </ThemeProvider>
                    </MuiPickersUtilsProvider>

                    <div class="row">
                        <div class="inner">
                            <div class={`col-sm-4 col-xs-12 ${touched.bd_rsl ? errors.bd_rsl ? 'has-error' : 'has-success' : ''}`}>
                                <label>
                                    <span class="reg m-cir" for="bd_rsl">Reason to obtain registration</span>
                                    <span></span>
                                </label>
                                <select class={`form-control`} id="bd_rsl" name="bd_rsl" required="required" onChange={handleChange} value={values.bd_rsl}>
                                    <option value="">Select</option>
                                    <option value="CRTH" className='text-black' selected={"CRTH" === values.bd_rsl}>Crossing the Threshold</option>
                                    <option value="INSS" className='text-black' selected={"INSS" === values.bd_rsl}>Inter-State supply</option>
                                    <option value="DSEP" className='text-black' selected={"DSEP" === values.bd_rsl}>Selling through e-Commerce portal</option>
                                    <option value="VOLN" className='text-black' selected={"VOLN" === values.bd_rsl}>Voluntary Basis</option>
                                    <option value="LPRS" disabled>Liability to pay as recipient of goods or services</option>
                                    <option value="TBCO" disabled>Transfer / Succession of business</option>
                                    <option value="DPRO" disabled>Death of the Proprietor</option>
                                    <option value="DMER" disabled>De-merger</option>
                                    <option value="CICB" disabled>Change in constitution of business</option>
                                    <option value="MEAM" disabled>Merger /Amalgamation</option>
                                    <option value="ECOM" disabled>E-Commerce Operator</option>
                                    <option value="ISDN" disabled>Input Service Distributor only</option>
                                    <option value="SBOP" disabled>Supplies on behalf of other taxable Person</option>
                                    <option value="SEZU" disabled>SEZ Unit</option>
                                    <option value="SDEV" disabled>SEZ Developer</option>
                                    <option value="NOTA" disabled>Others</option>
                                    <option value="AIRP" disabled>Corporate Debtor undergoing the Corporate Insolvency Resolution Process with IRP/RP</option>
                                </select>
                                {(errors.bd_rsl && touched.bd_rsl) && <p class="err">{errors.bd_rsl}</p>}
                            </div>
                            <div class={`col-sm-4 col-xs-12 ${touched.bd_cmbz ? errors.bd_cmbz ? 'has-error' : 'has-success' : ''}`}>
                                <label class="reg m-cir" for="bd_cmbz">Date of commencement of Business</label>
                                <div class="datepicker-icon input-group">
                                    <span class="input-group-addon">From</span>
                                    <input type="text" id="bd_cmbz" name="bd_cmbz" class={`form-control date-picker`} placeholder="DD/MM/YYYY" required="required" onChange={handleChange} value={values.bd_cmbz !== undefined ? values.bd_cmbz.substring(0, 10) : ''} />
                                    <span class="input-group-addon cursor-pointer" onClick={() => {
                                        openPicker('bd_cmbz')
                                    }}><span class="fa fa-calendar"></span></span>
                                </div>
                                {(errors.bd_cmbz && touched.bd_cmbz) && <p class="err">{errors.bd_cmbz}</p>}
                            </div>
                            <div class={`col-sm-4 col-xs-12 ${touched.lib ? errors.lib ? 'has-error' : 'has-success' : ''}`}>
                                <label class="reg m-cir" for="lib">Date on which liability to register arises</label>
                                <div class="datepicker-icon input-group">
                                    <input id="lib" name="lib" type="text" class={`form-control date-picker`} placeholder="DD/MM/YYYY" required="" onChange={handleChange} value={values.lib !== undefined ? values.lib.substring(0, 10) : ''} />
                                    <span class="input-group-addon cursor-pointer" onClick={() => {
                                        openPicker('lib')
                                    }}><span class="fa fa-calendar"></span></span>
                                </div>
                                {(errors.lib && touched.lib) && <p class="err">{errors.lib}</p>}
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="inner">
                            <h4 class="reg">Indicate Existing Registrations</h4>
                            <div class="col-xs-12 col-sm-3">
                                <label for="exty" class="reg">Type of Registration</label>
                                <select id="exty" name="ty" class="form-control" onChange={onChangeReg}>
                                    <option value="">Select</option>
                                    <option value="GSTIN" selected={tempReg.ty === "GSTIN"}>GSTIN</option>
                                    <option value="tmpgstin" selected={tempReg.ty === "Temporary ID"}>Temporary ID</option>
                                    <option value="Registration Number under Value Added Tax (TIN)" selected={tempReg.ty === "Registration Number under Value Added Tax (TIN)"}>Registration Number under Value Added Tax (TIN)</option>
                                    <option value="Central Sales Tax Registration Number" selected={tempReg.ty === "Central Sales Tax Registration Number"}>Central Sales Tax Registration Number</option>
                                    <option value="Central Excise Registration Number" selected={tempReg.ty === "Central Excise Registration Number"}>Central Excise Registration Number</option>
                                    <option value="Service Tax Registration Number" selected={tempReg.ty === "Service Tax Registration Number"}>Service Tax Registration Number</option>
                                    <option value="Importer/Exporter Code Number" selected={tempReg.ty === "Importer/Exporter Code Number"}>Importer/Exporter Code Number</option>
                                    <option value="Entry Tax Registration Number" selected={tempReg.ty === "Entry Tax Registration Number"}>Entry Tax Registration Number</option>
                                    <option value="Entertainment Tax Registration Number" selected={tempReg.ty === "Entertainment Tax Registration Number"}>Entertainment Tax Registration Number</option>
                                    <option value="Hotel And Luxury Tax Registration Number" selected={tempReg.ty === "Hotel And Luxury Tax Registration Number"}>Hotel And Luxury Tax Registration Number</option>
                                    <option value="Corporate Identity Number / Foreign Company Registration Number" selected={tempReg.ty === "Corporate Identity Number / Foreign Company Registration Number"}>Corporate Identity Number / Foreign Company Registration Number</option>
                                    <option value="Limited Liability Partnership / Foreign Limited Liability Partnership Identification Number" selected={tempReg.ty === "Limited Liability Partnership / Foreign Limited Liability Partnership Identification Number"}>Limited Liability Partnership / Foreign Limited Liability Partnership Identification Number</option>
                                    <option value="Registration number under Medicinal and Toilet Preparations (Excise Duties) Act" selected={tempReg.ty === "Registration number under Medicinal and Toilet Preparations (Excise Duties) Act"}>Registration number under Medicinal and Toilet Preparations (Excise Duties) Act</option>
                                    <option value="Registration under Shops and Establishment Act" selected={tempReg.ty === "Registration under Shops and Establishment Act"}>Registration under Shops and Establishment Act</option>
                                    <option value="Others (Please specify)" selected={tempReg.ty === "Others (Please specify)"}>Others (Please specify)</option>
                                </select>
                            </div>
                            <div class={`col-xs-12 col-sm-3 ${isRegAError !== '' ? isRegAError === 'N' ? 'has-error' : 'has-success' : ''}`}>
                                <label for="exno" class="reg m-cir">Registration No.</label>
                                <input value={tempReg.id} id="exno" name="id" type="text" class="form-control upper-input" maxLength="20" onChange={onChangeReg} />
                                {isRegAError === 'N' && <p class="err">Registration number is required</p>}
                            </div>
                            <div class={`col-xs-12 col-sm-3 ${isRegBError !== '' ? isRegBError === 'N' ? 'has-error' : 'has-success' : ''}`}>
                                <label for="exdt" class="reg m-cir">Date of Registration</label>
                                <div class="datepicker-icon input-group">
                                    <input value={tempReg['dtreg']} id="exdt" name="dtreg" type="text" class="form-control date-picker" placeholder="DD/MM/YYYY" onChange={onChangeReg} />
                                    <span class="input-group-addon cursor-pointer" onClick={() => { openPicker('dtreg') }}><span class="fa fa-calendar"></span></span>
                                </div>
                                {isRegBError === 'N' && <p class="err">Date of registration is required</p>}
                            </div>
                            <div class="col-xs-12 col-sm-3 pull-right" style={{ marginTop: '14px' }}>
                                <button title="Add" name="addexist" class="btn btn-primary rowtp-mar" type="button" style={{ marginRight: '10px' }} disabled={isRegEdit < 0 && regNames.length === 3} onClick={() => onRegAdd()}>
                                    <i class="fa fa-plus"></i>&nbsp;
                                    {isRegEdit < 0 && <span class="col-xs-hidden">Add</span>}
                                    {isRegEdit >= 0 && <span class="col-xs-hidden">Save</span>}
                                </button>
                                <button title="Cancel" name="addexist" class="btn btn-default rowtp-mar" type="button" onClick={() => onRegCancel()}>
                                    <i class="fa fa-times"></i>&nbsp;
                                    <span class="col-xs-hidden">Cancel</span>
                                </button>
                            </div>
                        </div>
                    </div>
                    {
                        regNames.length !== 0 && <div class="tbl-format">
                            <div class="row">
                                <div class="inner">
                                    <div class="col-xs-12">
                                        <div class="table-responsive">
                                            <table class="table">
                                                <thead>
                                                    <tr>
                                                        <th data-ng-bind="trans.LBL_REG_TYPE">Type of Registration</th>
                                                        <th data-ng-bind="trans.LBL_REG_NO">Registration No.</th>
                                                        <th data-ng-bind="trans.LBL_DT_REG">Date of Registration</th>
                                                        <th data-ng-bind="trans.LBL_ACTIONS">Actions</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        regNames.map((item, index) => {
                                                            return <tr key={index}>
                                                                <td>{item.ty}</td>
                                                                <td>{item.id.toUpperCase()}</td>
                                                                <td>{item.dtreg}</td>
                                                                <td>
                                                                    <button title="Edit" type="button" class="btn btn-sm btn-primary" style={{ marginRight: '10px' }} onClick={() => onRegEdit(index)}>
                                                                        <i class="fa fa-pencil"></i> <span class="col-xs-hidden">Edit</span>
                                                                    </button>
                                                                    <button title="Delete" type="button" class="btn btn-sm btn-danger" onClick={() => showDig('2', index)}>
                                                                        <i class="fa fa-trash"></i> <span class="col-xs-hidden">Delete</span>
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                        })
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                    {
                        values.tname !== "" && <>
                            <h4>Document Upload</h4>

                            {
                                !doc.isFile && <div class="tbl-format" data-ng-if="bd.bzdtlsbz &amp;&amp; bd.bzdtlsbz.trdnm &amp;&amp; (!bd.bzdtlsbz.dcupdtls || bd.bzdtlsbz.dcupdtls.length == 0 ||(bd.bzdtlsbz.trdnm &amp;&amp; !checkArrayElement(bd.bzdtlsbz.dcupdtls, 'SDTN', 'ty', true)))">
                                    <fieldset data-ng-disabled="clrPayload">
                                        <div class="row">
                                            <div class="inner">
                                                <div class="col-sm-6 col-xs-12">
                                                    <label class="reg"><span>Document for Trade Name</span></label>
                                                    <span class="help-block">
                                                        <p class="help-block">
                                                            <i class="fa fa-info-circle"></i>&nbsp;
                                                            <span data-ng-bind="trans.HLP_FILE_FORM">File with PDF or JPEG format is only allowed.</span>
                                                        </p>
                                                        <p class="help-block">
                                                            <i class="fa fa-info-circle"></i>&nbsp;
                                                            <span data-ng-bind="trans.HLP_MAX_FILE_SIZE">Maximum file size for upload is </span>5 MB
                                                        </p>
                                                    </span>
                                                    <input id="tr_upload" name="tr_upload" type="file" onClick={(e) => openBox(e)} />
                                                </div>
                                            </div>
                                        </div>
                                    </fieldset>
                                </div>
                            }

                            {
                                doc.isFile && <div class="tbl-format">
                                    <div class="row">
                                        <div class="inner">
                                            <div class="col-sm-8 col-xs-12">
                                                <ul style={{ listStyle: 'none' }}>
                                                    {
                                                        doc.type === 'storage' && <li>
                                                            <div className='flex justify-between'>
                                                                <a href={doc.doc['fileurl']} target="_black">
                                                                    <img alt="preview" src={icPdf} />
                                                                    <p data-ng-bind="trans.LBL_PHOTO">Document</p>
                                                                </a>
                                                                <button title="Delete" type="button" class="btn btn-sm btn-danger pull-right h-14" style={{ verticalAlign: 'top', marginLeft: '20px' }} onClick={() => { showDig('3') }}>
                                                                    <i class="fa fa-trash"></i> <span data-ng-bind="trans.LBL_DELETE">Delete</span>
                                                                </button>
                                                            </div>
                                                        </li>
                                                    }
                                                    {
                                                        doc.type !== 'storage' && <li>
                                                            <div className='flex justify-between'>
                                                                <a>
                                                                    <img alt="preview" src={icPdf} />
                                                                    <p data-ng-bind="trans.LBL_PHOTO">Document</p>
                                                                </a>
                                                                <button title="Delete" type="button" class="btn btn-sm btn-danger pull-right h-14" style={{ verticalAlign: 'top', marginLeft: '20px' }} onClick={() => { showDig('3') }}>
                                                                    <i class="fa fa-trash"></i> <span data-ng-bind="trans.LBL_DELETE">Delete</span>
                                                                </button>
                                                            </div>
                                                        </li>
                                                    }
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                        </>
                    }
                </div>
            </fieldset>
            <div class="row next-tab-nav">
                <div class="col-xs-12 text-right">
                    <button className="btn btn-default" type="button" onClick={() => {
                        gotoBack();
                    }} style={{ marginRight: '8px' }}>Back</button>
                    <button type="submit" class="btn btn-primary" disabled={isSubmit}>Save &amp; Continue</button>
                </div>
            </div>
        </form>
    );
}
