import React from 'react';
import styles from './css/printb.module.css';

import { useRecoilState } from 'recoil';
import { gstrUserAtom, returnDateAtom, gstrFileAtom } from '../../core/config/atoms';

import Moment from 'moment';

import Logo from '../../assets/images/logoblue.png';

export default function PanelSysGenPDF({ gstr, A, B, C, D }) {

    const [GSTUser] = useRecoilState(gstrUserAtom);
    const [gDate] = useRecoilState(returnDateAtom);
    const [gstrf] = useRecoilState(gstrFileAtom);

    const getStartY = () => {
        if (new Date(Moment(gDate).format('MM-DD-yyyy')).getMonth() < 3) {
            return Moment(gDate).subtract(1, 'year').format('YYYY');
        } else {
            return Moment(gDate).format('YYYY');
        }
    }

    const getEndY = () => {
        if (new Date(Moment(gDate).format('MM-DD-yyyy')).getMonth() < 3) {
            return Moment(gDate).format('YY');
        } else {
            return Moment(gDate).add(1, 'year').format('YY');
        }
    }

    const get3Total = (code) => {
        let total = 0;
        let list = [];

        switch (code) {
            case "lista":
                list = gstrf.data.a32[code];
                list.forEach((item) => {
                    total += parseFloat(item.total);
                });
                break;
            case "listb":
                list = gstrf.data.a32[code];
                list.forEach((item) => {
                    total += parseFloat(item.total);
                });
                break;
            case "listc":
                list = gstrf.data.a32[code];
                list.forEach((item) => {
                    total += parseFloat(item.total);
                });
                break;
        }

        return total.toFixed(2);
    }

    const get3Igst = (code) => {
        let igst = 0;
        let list = [];

        switch (code) {
            case "lista":
                list = gstrf.data.a32[code];
                list.forEach((item) => {
                    igst += parseFloat(item.igst);
                });
                break;
            case "listb":
                list = gstrf.data.a32[code];
                list.forEach((item) => {
                    igst += parseFloat(item.igst);
                });
                break;
            case "listc":
                list = gstrf.data.a32[code];
                list.forEach((item) => {
                    igst += parseFloat(item.igst);
                });
                break;
        }

        return igst.toFixed(2);
    }

    const getITCSummery = (type, get) => {
        let value = 0;
        switch (type) {
            case 'partA':
                switch (get) {
                    case "totalIGST":
                        value = 0;
                        A.forEach((item) => {
                            if (item['revc'] == "No" && item['ITC'] == "Yes") {
                                value = value + item['igst'];
                            }
                        });
                        B.forEach((item) => {
                            if (item['revc'] == "No" && item['ITC'] == "Yes") {
                                value = value + item['igst'];
                            }
                        });
                        C.forEach((item) => {
                            if (item['revc'] == "No" && item['ITC'] == "Yes" && item['notetype'] == "Debit Note") {
                                value = value + item['igst'];
                            }
                        });
                        return value;
                    case "totalCGST":
                        value = 0;
                        A.forEach((item) => {
                            if (item['revc'] == "No" && item['ITC'] == "Yes") {
                                value = value + item['cgst'];
                            }
                        });
                        B.forEach((item) => {
                            if (item['revc'] == "No" && item['ITC'] == "Yes") {
                                value = value + item['cgst'];
                            }
                        });
                        C.forEach((item) => {
                            if (item['revc'] == "No" && item['ITC'] == "Yes" && item['notetype'] == "Debit Note") {
                                value = value + item['cgst'];
                            }
                        });
                        return value;
                    case "totalSGST":
                        value = 0;
                        A.forEach((item) => {
                            if (item['revc'] == "No" && item['ITC'] == "Yes") {
                                value = value + item['sgst'];
                            }
                        });
                        B.forEach((item) => {
                            if (item['revc'] == "No" && item['ITC'] == "Yes") {
                                value = value + item['sgst'];
                            }
                        });
                        C.forEach((item) => {
                            if (item['revc'] == "No" && item['ITC'] == "Yes" && item['notetype'] == "Debit Note") {
                                value = value + item['sgst'];
                            }
                        });
                        return value;
                    case "totalCess":
                        value = 0;
                        A.forEach((item) => {
                            if (item['revc'] == "No" && item['ITC'] == "Yes") {
                                value = value + item['cess'];
                            }
                        });
                        B.forEach((item) => {
                            if (item['revc'] == "No" && item['ITC'] == "Yes") {
                                value = value + item['cess'];
                            }
                        });
                        C.forEach((item) => {
                            if (item['revc'] == "No" && item['ITC'] == "Yes" && item['notetype'] == "Debit Note") {
                                value = value + item['cess'];
                            }
                        });
                        return value;
                    case "b2bIGST":
                        value = 0;
                        A.forEach((item) => {
                            if (item['revc'] == "No" && item['ITC'] == "Yes") {
                                value = value + item['igst'];
                            }
                        });
                        return value;
                    case "b2bCGST":
                        value = 0;
                        A.forEach((item) => {
                            if (item['revc'] == "No" && item['ITC'] == "Yes") {
                                value = value + item['cgst'];
                            }
                        });
                        return value;
                    case "b2bSGST":
                        value = 0;
                        A.forEach((item) => {
                            if (item['revc'] == "No" && item['ITC'] == "Yes") {
                                value = value + item['sgst'];
                            }
                        });
                        return value;
                    case "b2bCess":
                        value = 0;
                        A.forEach((item) => {
                            if (item['revc'] == "No" && item['ITC'] == "Yes") {
                                value = value + item['cess'];
                            }
                        });
                        return value;
                    case "b2bndIGST":
                        value = 0;
                        C.forEach((item) => {
                            if (item['revc'] == "No" && item['ITC'] == "Yes" && item['notetype'] == "Debit Note") {
                                value = value + item['igst'];
                            }
                        });
                        return value;
                    case "b2bndCGST":
                        value = 0;
                        C.forEach((item) => {
                            if (item['revc'] == "No" && item['ITC'] == "Yes" && item['notetype'] == "Debit Note") {
                                value = value + item['cgst'];
                            }
                        });
                        return value;
                    case "b2bndSGST":
                        value = 0;
                        C.forEach((item) => {
                            if (item['revc'] == "No" && item['ITC'] == "Yes" && item['notetype'] == "Debit Note") {
                                value = value + item['sgst'];
                            }
                        });
                        return value;
                    case "b2bndCess":
                        value = 0;
                        C.forEach((item) => {
                            if (item['revc'] == "No" && item['ITC'] == "Yes" && item['notetype'] == "Debit Note") {
                                value = value + item['cess'];
                            }
                        });
                        return value;
                    case "b2baIGST":
                        value = 0;
                        B.forEach((item) => {
                            if (item['revc'] == "No" && item['ITC'] == "Yes") {
                                value = value + item['igst'];
                            }
                        });
                        return value;
                    case "b2baCGST":
                        value = 0;
                        B.forEach((item) => {
                            if (item['revc'] == "No" && item['ITC'] == "Yes") {
                                value = value + item['cgst'];
                            }
                        });
                        return value;
                    case "b2baSGST":
                        value = 0;
                        B.forEach((item) => {
                            if (item['revc'] == "No" && item['ITC'] == "Yes") {
                                value = value + item['sgst'];
                            }
                        });
                        return value;
                    case "b2baCess":
                        value = 0;
                        B.forEach((item) => {
                            if (item['revc'] == "No" && item['ITC'] == "Yes") {
                                value = value + item['cess'];
                            }
                        });
                        return value;
                    case "totalIGSTr":
                        value = 0;
                        A.forEach((item) => {
                            if (item['revc'] == "Yes" && item['ITC'] == "Yes") {
                                value = value + item['igst'];
                            }
                        });
                        B.forEach((item) => {
                            if (item['revc'] == "Yes" && item['ITC'] == "Yes") {
                                value = value + item['igst'];
                            }
                        });
                        C.forEach((item) => {
                            if (item['revc'] == "Yes" && item['ITC'] == "Yes" && item['notetype'] == "Debit Note") {
                                value = value + item['igst'];
                            }
                        });
                        return value;
                    case "totalCGSTr":
                        value = 0;
                        A.forEach((item) => {
                            if (item['revc'] == "Yes" && item['ITC'] == "Yes") {
                                value = value + item['cgst'];
                            }
                        });
                        B.forEach((item) => {
                            if (item['revc'] == "Yes" && item['ITC'] == "Yes") {
                                value = value + item['cgst'];
                            }
                        });
                        C.forEach((item) => {
                            if (item['revc'] == "Yes" && item['ITC'] == "Yes" && item['notetype'] == "Debit Note") {
                                value = value + item['cgst'];
                            }
                        });
                        return value;
                    case "totalSGSTr":
                        value = 0;
                        A.forEach((item) => {
                            if (item['revc'] == "Yes" && item['ITC'] == "Yes") {
                                value = value + item['sgst'];
                            }
                        });
                        B.forEach((item) => {
                            if (item['revc'] == "Yes" && item['ITC'] == "Yes") {
                                value = value + item['sgst'];
                            }
                        });
                        C.forEach((item) => {
                            if (item['revc'] == "Yes" && item['ITC'] == "Yes" && item['notetype'] == "Debit Note") {
                                value = value + item['sgst'];
                            }
                        });
                        return value;
                    case "totalCessr":
                        value = 0;
                        A.forEach((item) => {
                            if (item['revc'] == "Yes" && item['ITC'] == "Yes") {
                                value = value + item['cess'];
                            }
                        });
                        B.forEach((item) => {
                            if (item['revc'] == "Yes" && item['ITC'] == "Yes") {
                                value = value + item['cess'];
                            }
                        });
                        C.forEach((item) => {
                            if (item['revc'] == "Yes" && item['ITC'] == "Yes" && item['notetype'] == "Debit Note") {
                                value = value + item['cess'];
                            }
                        });
                        return value;
                    case "b2bIGSTr":
                        value = 0;
                        A.forEach((item) => {
                            if (item['revc'] == "Yes" && item['ITC'] == "Yes") {
                                value = value + item['igst'];
                            }
                        });
                        return value;
                    case "b2bCGSTr":
                        value = 0;
                        A.forEach((item) => {
                            if (item['revc'] == "Yes" && item['ITC'] == "Yes") {
                                value = value + item['cgst'];
                            }
                        });
                        return value;
                    case "b2bSGSTr":
                        value = 0;
                        A.forEach((item) => {
                            if (item['revc'] == "Yes" && item['ITC'] == "Yes") {
                                value = value + item['sgst'];
                            }
                        });
                        return value;
                    case "b2bCessr":
                        value = 0;
                        A.forEach((item) => {
                            if (item['revc'] == "Yes" && item['ITC'] == "Yes") {
                                value = value + item['cess'];
                            }
                        });
                        return value;
                    case "b2bndIGSTr":
                        value = 0;
                        C.forEach((item) => {
                            if (item['revc'] == "Yes" && item['ITC'] == "Yes" && item['notetype'] == "Debit Note") {
                                value = value + item['igst'];
                            }
                        });
                        return value;
                    case "b2bndCGSTr":
                        value = 0;
                        C.forEach((item) => {
                            if (item['revc'] == "Yes" && item['ITC'] == "Yes" && item['notetype'] == "Debit Note") {
                                value = value + item['cgst'];
                            }
                        });
                        return value;
                    case "b2bndSGSTr":
                        value = 0;
                        C.forEach((item) => {
                            if (item['revc'] == "Yes" && item['ITC'] == "Yes" && item['notetype'] == "Debit Note") {
                                value = value + item['sgst'];
                            }
                        });
                        return value;
                    case "b2bndCessr":
                        value = 0;
                        C.forEach((item) => {
                            if (item['revc'] == "Yes" && item['ITC'] == "Yes" && item['notetype'] == "Debit Note") {
                                value = value + item['cess'];
                            }
                        });
                        return value;
                    case "b2baIGSTr":
                        value = 0;
                        B.forEach((item) => {
                            if (item['revc'] == "Yes" && item['ITC'] == "Yes") {
                                value = value + item['igst'];
                            }
                        });
                        return value;
                    case "b2baCGSTr":
                        value = 0;
                        B.forEach((item) => {
                            if (item['revc'] == "Yes" && item['ITC'] == "Yes") {
                                value = value + item['cgst'];
                            }
                        });
                        return value;
                    case "b2baSGSTr":
                        value = 0;
                        B.forEach((item) => {
                            if (item['revc'] == "Yes" && item['ITC'] == "Yes") {
                                value = value + item['sgst'];
                            }
                        });
                        return value;
                    case "b2baCessr":
                        value = 0;
                        B.forEach((item) => {
                            if (item['revc'] == "Yes" && item['ITC'] == "Yes") {
                                value = value + item['cess'];
                            }
                        });
                        return value;
                    case "impgTotal":
                        value = 0;
                        D.forEach((item) => {
                            value = value + item['igst'];
                        });
                        return value;
                    case "impgCess":
                        value = 0;
                        D.forEach((item) => {
                            value = value + item['cess'];
                        });
                        return value;
                    case "b2bncIGST":
                        value = 0;
                        C.forEach((item) => {
                            if (item['revc'] == "No" && item['ITC'] == "Yes" && item['notetype'] == "Credit Note") {
                                value = value + item['igst'];
                            }
                        });
                        return value;
                    case "b2bncCGST":
                        value = 0;
                        C.forEach((item) => {
                            if (item['revc'] == "No" && item['ITC'] == "Yes" && item['notetype'] == "Credit Note") {
                                value = value + item['cgst'];
                            }
                        });
                        return value;
                    case "b2bncSGST":
                        value = 0;
                        C.forEach((item) => {
                            if (item['revc'] == "No" && item['ITC'] == "Yes" && item['notetype'] == "Credit Note") {
                                value = value + item['sgst'];
                            }
                        });
                        return value;
                    case "b2bncCess":
                        value = 0;
                        C.forEach((item) => {
                            if (item['revc'] == "No" && item['ITC'] == "Yes" && item['notetype'] == "Credit Note") {
                                value = value + item['cess'];
                            }
                        });
                        return value;
                    case "b2bncIGSTr":
                        value = 0;
                        C.forEach((item) => {
                            if (item['revc'] == "Yes" && item['ITC'] == "Yes" && item['notetype'] == "Credit Note") {
                                value = value + item['igst'];
                            }
                        });
                        return value;
                    case "b2bncCGSTr":
                        value = 0;
                        C.forEach((item) => {
                            if (item['revc'] == "Yes" && item['ITC'] == "Yes" && item['notetype'] == "Credit Note") {
                                value = value + item['cgst'];
                            }
                        });
                        return value;
                    case "b2bncSGSTr":
                        value = 0;
                        C.forEach((item) => {
                            if (item['revc'] == "Yes" && item['ITC'] == "Yes" && item['notetype'] == "Credit Note") {
                                value = value + item['sgst'];
                            }
                        });
                        return value;
                    case "b2bncCessr":
                        value = 0;
                        C.forEach((item) => {
                            if (item['revc'] == "Yes" && item['ITC'] == "Yes" && item['notetype'] == "Credit Note") {
                                value = value + item['cess'];
                            }
                        });
                        return value;
                    case "b2bncIGSTT":
                        value = 0;
                        C.forEach((item) => {
                            if (item['ITC'] == "Yes" && item['notetype'] == "Credit Note") {
                                value = value + item['igst'];
                            }
                        });
                        return value;
                    case "b2bncCGSTT":
                        value = 0;
                        C.forEach((item) => {
                            if (item['ITC'] == "Yes" && item['notetype'] == "Credit Note") {
                                value = value + item['cgst'];
                            }
                        });
                        return value;
                    case "b2bncSGSTT":
                        value = 0;
                        C.forEach((item) => {
                            if (item['ITC'] == "Yes" && item['notetype'] == "Credit Note") {
                                value = value + item['sgst'];
                            }
                        });
                        return value;
                    case "b2bncCessT":
                        value = 0;
                        C.forEach((item) => {
                            if (item['ITC'] == "Yes" && item['notetype'] == "Credit Note") {
                                value = value + item['cess'];
                            }
                        });
                        return value;
                    default:
                        value = 0;
                        return value;
                }
                break;
            case 'partB':
                switch (get) {
                    case "totalIGST":
                        value = 0;
                        A.forEach((item) => {
                            if (item['revc'] == "No" && item['ITC'] == "No") {
                                value = value + item['igst'];
                            }
                        });
                        B.forEach((item) => {
                            if (item['revc'] == "No" && item['ITC'] == "No") {
                                value = value + item['igst'];
                            }
                        });
                        C.forEach((item) => {
                            if (item['revc'] == "No" && item['ITC'] == "No" && item['notetype'] == "Debit Note") {
                                value = value + item['igst'];
                            }
                        });
                        return value;
                    case "totalCGST":
                        value = 0;
                        A.forEach((item) => {
                            if (item['revc'] == "No" && item['ITC'] == "No") {
                                value = value + item['cgst'];
                            }
                        });
                        B.forEach((item) => {
                            if (item['revc'] == "No" && item['ITC'] == "No") {
                                value = value + item['cgst'];
                            }
                        });
                        C.forEach((item) => {
                            if (item['revc'] == "No" && item['ITC'] == "No" && item['notetype'] == "Debit Note") {
                                value = value + item['cgst'];
                            }
                        });
                        return value;
                    case "totalSGST":
                        value = 0;
                        A.forEach((item) => {
                            if (item['revc'] == "No" && item['ITC'] == "No") {
                                value = value + item['sgst'];
                            }
                        });
                        B.forEach((item) => {
                            if (item['revc'] == "No" && item['ITC'] == "No") {
                                value = value + item['sgst'];
                            }
                        });
                        C.forEach((item) => {
                            if (item['revc'] == "No" && item['ITC'] == "No" && item['notetype'] == "Debit Note") {
                                value = value + item['sgst'];
                            }
                        });
                        return value;
                    case "totalCess":
                        value = 0;
                        A.forEach((item) => {
                            if (item['revc'] == "No" && item['ITC'] == "No") {
                                value = value + item['cess'];
                            }
                        });
                        B.forEach((item) => {
                            if (item['revc'] == "No" && item['ITC'] == "No") {
                                value = value + item['cess'];
                            }
                        });
                        C.forEach((item) => {
                            if (item['revc'] == "No" && item['ITC'] == "No" && item['notetype'] == "Debit Note") {
                                value = value + item['cess'];
                            }
                        });
                        return value;
                    case "b2bIGST":
                        value = 0;
                        A.forEach((item) => {
                            if (item['revc'] == "No" && item['ITC'] == "No") {
                                value = value + item['igst'];
                            }
                        });
                        return value;
                    case "b2bCGST":
                        value = 0;
                        A.forEach((item) => {
                            if (item['revc'] == "No" && item['ITC'] == "No") {
                                value = value + item['cgst'];
                            }
                        });
                        return value;
                    case "b2bSGST":
                        value = 0;
                        A.forEach((item) => {
                            if (item['revc'] == "No" && item['ITC'] == "No") {
                                value = value + item['sgst'];
                            }
                        });
                        return value;
                    case "b2bCess":
                        value = 0;
                        A.forEach((item) => {
                            if (item['revc'] == "No" && item['ITC'] == "No") {
                                value = value + item['cess'];
                            }
                        });
                        return value;
                    case "b2bndIGST":
                        value = 0;
                        C.forEach((item) => {
                            if (item['revc'] == "No" && item['ITC'] == "No" && item['notetype'] == "Debit Note") {
                                value = value + item['igst'];
                            }
                        });
                        return value;
                    case "b2bndCGST":
                        value = 0;
                        C.forEach((item) => {
                            if (item['revc'] == "No" && item['ITC'] == "No" && item['notetype'] == "Debit Note") {
                                value = value + item['cgst'];
                            }
                        });
                        return value;
                    case "b2bndSGST":
                        value = 0;
                        C.forEach((item) => {
                            if (item['revc'] == "No" && item['ITC'] == "No" && item['notetype'] == "Debit Note") {
                                value = value + item['sgst'];
                            }
                        });
                        return value;
                    case "b2bndCess":
                        value = 0;
                        C.forEach((item) => {
                            if (item['revc'] == "No" && item['ITC'] == "No" && item['notetype'] == "Debit Note") {
                                value = value + item['cess'];
                            }
                        });
                        return value;
                    case "b2baIGST":
                        value = 0;
                        B.forEach((item) => {
                            if (item['revc'] == "No" && item['ITC'] == "No") {
                                value = value + item['igst'];
                            }
                        });
                        return value;
                    case "b2baCGST":
                        value = 0;
                        B.forEach((item) => {
                            if (item['revc'] == "No" && item['ITC'] == "No") {
                                value = value + item['cgst'];
                            }
                        });
                        return value;
                    case "b2baSGST":
                        value = 0;
                        B.forEach((item) => {
                            if (item['revc'] == "No" && item['ITC'] == "No") {
                                value = value + item['sgst'];
                            }
                        });
                        return value;
                    case "b2baCess":
                        value = 0;
                        B.forEach((item) => {
                            if (item['revc'] == "No" && item['ITC'] == "No") {
                                value = value + item['cess'];
                            }
                        });
                        return value;
                    case "totalIGSTr":
                        value = 0;
                        A.forEach((item) => {
                            if (item['revc'] == "Yes" && item['ITC'] == "No") {
                                value = value + item['igst'];
                            }
                        });
                        B.forEach((item) => {
                            if (item['revc'] == "Yes" && item['ITC'] == "No") {
                                value = value + item['igst'];
                            }
                        });
                        C.forEach((item) => {
                            if (item['revc'] == "Yes" && item['ITC'] == "No" && item['notetype'] == "Debit Note") {
                                value = value + item['igst'];
                            }
                        });
                        return value;
                    case "totalCGSTr":
                        value = 0;
                        A.forEach((item) => {
                            if (item['revc'] == "Yes" && item['ITC'] == "No") {
                                value = value + item['cgst'];
                            }
                        });
                        B.forEach((item) => {
                            if (item['revc'] == "Yes" && item['ITC'] == "No") {
                                value = value + item['cgst'];
                            }
                        });
                        C.forEach((item) => {
                            if (item['revc'] == "Yes" && item['ITC'] == "No" && item['notetype'] == "Debit Note") {
                                value = value + item['cgst'];
                            }
                        });
                        return value;
                    case "totalSGSTr":
                        value = 0;
                        A.forEach((item) => {
                            if (item['revc'] == "Yes" && item['ITC'] == "No") {
                                value = value + item['sgst'];
                            }
                        });
                        B.forEach((item) => {
                            if (item['revc'] == "Yes" && item['ITC'] == "No") {
                                value = value + item['sgst'];
                            }
                        });
                        C.forEach((item) => {
                            if (item['revc'] == "Yes" && item['ITC'] == "No" && item['notetype'] == "Debit Note") {
                                value = value + item['sgst'];
                            }
                        });
                        return value;
                    case "totalCessr":
                        value = 0;
                        A.forEach((item) => {
                            if (item['revc'] == "Yes" && item['ITC'] == "No") {
                                value = value + item['cess'];
                            }
                        });
                        B.forEach((item) => {
                            if (item['revc'] == "Yes" && item['ITC'] == "No") {
                                value = value + item['cess'];
                            }
                        });
                        C.forEach((item) => {
                            if (item['revc'] == "Yes" && item['ITC'] == "No" && item['notetype'] == "Debit Note") {
                                value = value + item['cess'];
                            }
                        });
                        return value;
                    case "b2bIGSTr":
                        value = 0;
                        A.forEach((item) => {
                            if (item['revc'] == "Yes" && item['ITC'] == "No") {
                                value = value + item['igst'];
                            }
                        });
                        return value;
                    case "b2bCGSTr":
                        value = 0;
                        A.forEach((item) => {
                            if (item['revc'] == "Yes" && item['ITC'] == "No") {
                                value = value + item['cgst'];
                            }
                        });
                        return value;
                    case "b2bSGSTr":
                        value = 0;
                        A.forEach((item) => {
                            if (item['revc'] == "Yes" && item['ITC'] == "No") {
                                value = value + item['sgst'];
                            }
                        });
                        return value;
                    case "b2bCessr":
                        value = 0;
                        A.forEach((item) => {
                            if (item['revc'] == "Yes" && item['ITC'] == "No") {
                                value = value + item['cess'];
                            }
                        });
                        return value;
                    case "b2bndIGSTr":
                        value = 0;
                        C.forEach((item) => {
                            if (item['revc'] == "Yes" && item['ITC'] == "No" && item['notetype'] == "Debit Note") {
                                value = value + item['igst'];
                            }
                        });
                        return value;
                    case "b2bndCGSTr":
                        value = 0;
                        C.forEach((item) => {
                            if (item['revc'] == "Yes" && item['ITC'] == "No" && item['notetype'] == "Debit Note") {
                                value = value + item['cgst'];
                            }
                        });
                        return value;
                    case "b2bndSGSTr":
                        value = 0;
                        C.forEach((item) => {
                            if (item['revc'] == "Yes" && item['ITC'] == "No" && item['notetype'] == "Debit Note") {
                                value = value + item['sgst'];
                            }
                        });
                        return value;
                    case "b2bndCessr":
                        value = 0;
                        C.forEach((item) => {
                            if (item['revc'] == "Yes" && item['ITC'] == "No" && item['notetype'] == "Debit Note") {
                                value = value + item['cess'];
                            }
                        });
                        return value;
                    case "b2baIGSTr":
                        value = 0;
                        B.forEach((item) => {
                            if (item['revc'] == "Yes" && item['ITC'] == "No") {
                                value = value + item['igst'];
                            }
                        });
                        return value;
                    case "b2baCGSTr":
                        value = 0;
                        B.forEach((item) => {
                            if (item['revc'] == "Yes" && item['ITC'] == "No") {
                                value = value + item['cgst'];
                            }
                        });
                        return value;
                    case "b2baSGSTr":
                        value = 0;
                        B.forEach((item) => {
                            if (item['revc'] == "Yes" && item['ITC'] == "No") {
                                value = value + item['sgst'];
                            }
                        });
                        return value;
                    case "b2baCessr":
                        value = 0;
                        B.forEach((item) => {
                            if (item['revc'] == "Yes" && item['ITC'] == "No") {
                                value = value + item['cess'];
                            }
                        });
                        return value;
                    case "impgTotal":
                        value = 0;
                        D.forEach((item) => {
                            value = value + item['igst'];
                        });
                        return value;
                    case "impgCess":
                        value = 0;
                        D.forEach((item) => {
                            value = value + item['cess'];
                        });
                        return value;
                    case "b2bncIGST":
                        value = 0;
                        C.forEach((item) => {
                            if (item['revc'] == "No" && item['ITC'] == "No" && item['notetype'] == "Credit Note") {
                                value = value + item['igst'];
                            }
                        });
                        return value;
                    case "b2bncCGST":
                        value = 0;
                        C.forEach((item) => {
                            if (item['revc'] == "No" && item['ITC'] == "No" && item['notetype'] == "Credit Note") {
                                value = value + item['cgst'];
                            }
                        });
                        return value;
                    case "b2bncSGST":
                        value = 0;
                        C.forEach((item) => {
                            if (item['revc'] == "No" && item['ITC'] == "No" && item['notetype'] == "Credit Note") {
                                value = value + item['sgst'];
                            }
                        });
                        return value;
                    case "b2bncCess":
                        value = 0;
                        C.forEach((item) => {
                            if (item['revc'] == "No" && item['ITC'] == "No" && item['notetype'] == "Credit Note") {
                                value = value + item['cess'];
                            }
                        });
                        return value;
                    case "b2bncIGSTr":
                        value = 0;
                        C.forEach((item) => {
                            if (item['revc'] == "Yes" && item['ITC'] == "No" && item['notetype'] == "Credit Note") {
                                value = value + item['igst'];
                            }
                        });
                        return value;
                    case "b2bncCGSTr":
                        value = 0;
                        C.forEach((item) => {
                            if (item['revc'] == "Yes" && item['ITC'] == "No" && item['notetype'] == "Credit Note") {
                                value = value + item['cgst'];
                            }
                        });
                        return value;
                    case "b2bncSGSTr":
                        value = 0;
                        C.forEach((item) => {
                            if (item['revc'] == "Yes" && item['ITC'] == "No" && item['notetype'] == "Credit Note") {
                                value = value + item['sgst'];
                            }
                        });
                        return value;
                    case "b2bncCessr":
                        value = 0;
                        C.forEach((item) => {
                            if (item['revc'] == "Yes" && item['ITC'] == "No" && item['notetype'] == "Credit Note") {
                                value = value + item['cess'];
                            }
                        });
                        return value;
                    case "b2bncIGSTT":
                        value = 0;
                        C.forEach((item) => {
                            if (item['ITC'] == "No" && item['notetype'] == "Credit Note") {
                                value = value + item['igst'];
                            }
                        });
                        return value;
                    case "b2bncCGSTT":
                        value = 0;
                        C.forEach((item) => {
                            if (item['ITC'] == "No" && item['notetype'] == "Credit Note") {
                                value = value + item['cgst'];
                            }
                        });
                        return value;
                    case "b2bncSGSTT":
                        value = 0;
                        C.forEach((item) => {
                            if (item['ITC'] == "No" && item['notetype'] == "Credit Note") {
                                value = value + item['sgst'];
                            }
                        });
                        return value;
                    case "b2bncCessT":
                        value = 0;
                        C.forEach((item) => {
                            if (item['ITC'] == "No" && item['notetype'] == "Credit Note") {
                                value = value + item['cess'];
                            }
                        });
                        return value;
                    default:
                        value = 0;
                        return value;
                }
                break;
        }
    }

    const getB2CSTotal = (type, val) => {
        let cal = 0;
        if (val === "A") {
            switch (type) {
                case "Total":
                    gstr.b2co.data.forEach((inv) => {
                        if (inv.pos === GSTUser.state) {
                            cal = cal + inv.value;
                        }
                    });
                    return cal.toFixed(2).toString();
                case "cgst":
                    gstr.b2co.data.forEach((inv) => {
                        if (inv.pos === GSTUser.state) {
                            cal = cal + inv.taxb;
                        }
                    });
                    return cal.toFixed(2).toString();
                case "sgst":
                    gstr.b2co.data.forEach((inv) => {
                        if (inv.pos === GSTUser.state) {
                            cal = cal + inv.taxb;
                        }
                    });
                    return cal.toFixed(2).toString();
                case "cess":
                    gstr.b2co.data.forEach((inv) => {
                        if (inv.pos === GSTUser.state) {
                            cal = cal + inv.cess;
                        }
                    });
                    return cal.toFixed(2).toString();
            }
        } else {
            switch (type) {
                case "Total":
                    gstr.b2co.data.forEach((inv) => {
                        if (inv.pos !== GSTUser.state) {
                            cal = cal + inv.value;
                        }
                    });
                    return cal.toFixed(2).toString();
                case "igst":
                    gstr.b2co.data.forEach((inv) => {
                        if (inv.pos !== GSTUser.state) {
                            cal = cal + inv.taxa;
                        }
                    });
                    return cal.toFixed(2).toString();
                case "cess":
                    gstr.b2co.data.forEach((inv) => {
                        if (inv.pos !== GSTUser.state) {
                            cal = cal + inv.cess;
                        }
                    });
                    return cal.toFixed(2).toString();
            }
        }
        return cal.toFixed(2).toString();
    }

    const get9A = (type) => {
        let cal = 0;
        switch (type) {
            case "Total":
                cal = (gstr.b2bAA.total.value - (gstr.b2bAA.ototal ? gstr.b2bAA.ototal.value : 0));
                cal = cal + (gstr.b2bAB.total.value - (gstr.b2bAB.ototal ? gstr.b2bAB.ototal.value : 0));
                cal = cal + (gstr.b2cla.total.value - (gstr.b2cla.ototal ? gstr.b2cla.ototal.value : 0));
                return cal.toFixed(2);
            case "igst":
                cal = (gstr.b2bAA.total.taxa - (gstr.b2bAA.ototal ? gstr.b2bAA.ototal.taxa : 0));
                cal = cal + (gstr.b2bAB.total.taxa - (gstr.b2bAB.ototal ? gstr.b2bAB.ototal.taxa : 0));
                cal = cal + (gstr.b2cla.total.taxa - (gstr.b2cla.ototal ? gstr.b2cla.ototal.taxa : 0));
                return cal.toFixed(2);
            case "cgst":
            case "sgst":
                cal = (gstr.b2bAA.total.taxb - (gstr.b2bAA.ototal ? gstr.b2bAA.ototal.taxb : 0));
                cal = cal + (gstr.b2bAB.total.taxb - (gstr.b2bAB.ototal ? gstr.b2bAB.ototal.taxb : 0));
                cal = cal + (gstr.b2cla.total.taxb - (gstr.b2cla.ototal ? gstr.b2cla.ototal.taxb : 0));
                return cal.toFixed(2);
            case "cess":
                cal = (gstr.b2bAA.total.cess - (gstr.b2bAA.ototal ? gstr.b2bAA.ototal.cess : 0));
                cal = cal + (gstr.b2bAB.total.cess - (gstr.b2bAB.ototal ? gstr.b2bAB.ototal.cess : 0));
                cal = cal + (gstr.b2cla.total.cess - (gstr.b2cla.ototal ? gstr.b2cla.ototal.cess : 0));
                return cal.toFixed(2);
        }
        return cal.toFixed(2);
    }

    const get9B = (type) => {
        let cal = 0;
        switch (type) {
            case "Total":
                cal = gstr.cdrA.total.value + gstr.cdrB.total.value + gstr.cdu.cl.value;
                return cal.toFixed(2);
            case "igst":
                cal = gstr.cdrA.total.taxa + gstr.cdrB.total.taxa + gstr.cdu.cl.taxa;
                return cal.toFixed(2);
            case "cgst":
            case "sgst":
                cal = gstr.cdrA.total.taxb + gstr.cdrB.total.taxb + gstr.cdu.cl.taxb;
                return cal.toFixed(2);
            case "cess":
                cal = gstr.cdrA.total.cess + gstr.cdrB.total.cess + gstr.cdu.cl.cess;
                return cal.toFixed(2);
        }
        return cal.toFixed(2);
    }

    const getU9B = (type) => {
        let cal = 0;
        switch (type) {
            case "Total":
                cal = gstr.cdu.cl.value;
                return cal.toFixed(2);
            case "igst":
                cal = gstr.cdu.cl.taxa;
                return cal.toFixed(2);
        }
        return cal.toFixed(2);
    }

    const get9C = (type) => {
        let cal = 0;
        switch (type) {
            case "Total":
                cal = gstr.cdrAA.ntotal ? (gstr.cdrAA.ntotal.value + gstr.cdrAB.ntotal.value) : (gstr.cdrAA.total.value + gstr.cdrAB.total.value);
                cal = cal + gstr.cdua.cl.value;
                return cal.toFixed(2);
            case "igst":
                cal = gstr.cdrAA.ntotal ? (gstr.cdrAA.ntotal.taxa + gstr.cdrAB.ntotal.taxa) : (gstr.cdrAA.total.taxa + gstr.cdrAB.total.taxa);
                cal = cal + gstr.cdua.cl.taxa;
                return cal.toFixed(2);
            case "cgst":
            case "sgst":
                cal = gstr.cdrAA.ntotal ? (gstr.cdrAA.ntotal.taxb + gstr.cdrAB.ntotal.taxb) : (gstr.cdrAA.total.taxb + gstr.cdrAB.total.taxb);
                cal = cal + gstr.cdua.cl.taxb;
                return cal.toFixed(2);
            case "cess":
                cal = gstr.cdrAA.ntotal ? (gstr.cdrAA.ntotal.cess + gstr.cdrAB.ntotal.cess) : (gstr.cdrAA.total.cess + gstr.cdrAB.total.cess);
                cal = cal + gstr.cdua.cl.taxb;
                return cal.toFixed(2);
        }
        return cal.toFixed(2);
    }

    const getU9C = (type) => {
        let cal = 0;
        switch (type) {
            case "Total":
                cal = cal + gstr.cdua.cl.value;
                return cal.toFixed(2);
            case "igst":
                cal = cal + gstr.cdua.cl.taxa;
                return cal.toFixed(2);
        }
        return cal.toFixed(2);
    }

    const get10A = (type) => {
        let cal = 0;
        switch (type) {
            case "Total":
                gstr.b2coa.data.map((item) => {
                    if (item.pos === GSTUser.state) {
                        cal = cal + item.value
                    }
                });
                return cal.toFixed(2);
            case "cgst":
            case "sgst":
                gstr.b2coa.data.map((item) => {
                    if (item.pos === GSTUser.state) {
                        cal = cal + item.taxb
                    }
                });
                return cal.toFixed(2);
            case "cess":
                gstr.b2coa.data.map((item) => {
                    if (item.pos === GSTUser.state) {
                        cal = cal + item.cess
                    }
                });
                return cal.toFixed(2);
        }
        return cal.toFixed(2);
    }

    const get10B = (type) => {
        let cal = 0;
        switch (type) {
            case "Total":
                gstr.b2coa.data.map((item) => {
                    if (item.pos !== GSTUser.state) {
                        cal = cal + item.value
                    }
                });
                return cal.toFixed(2);
            case "igst":
                gstr.b2coa.data.map((item) => {
                    if (item.pos !== GSTUser.state) {
                        cal = cal + item.taxa
                    }
                });
                return cal.toFixed(2);
            case "cess":
                gstr.b2coa.data.map((item) => {
                    if (item.pos !== GSTUser.state) {
                        cal = cal + item.cess
                    }
                });
                return cal.toFixed(2);
        }
        return cal.toFixed(2);
    }

    const get11A1 = (type) => {
        let cal = 0;
        switch (type) {
            case "Total":
                cal = cal + gstr.a11.total.value;
                return cal.toFixed(2);
            case "igst":
                cal = cal + gstr.a11.total.taxa;
                return cal.toFixed(2);
            case "sgst":
            case "cgst":
                cal = cal + gstr.a11.total.taxb;
                return cal.toFixed(2);
            case "cess":
                cal = cal + gstr.a11.total.cess;
                return cal.toFixed(2);
        }
        return cal.toFixed(2);
    }

    const get11B1 = (type) => {
        let cal = 0;
        switch (type) {
            case "Total":
                cal = cal + gstr.b11.total.value;
                return cal.toFixed(2);
            case "igst":
                cal = cal + gstr.b11.total.taxa;
                return cal.toFixed(2);
            case "sgst":
            case "cgst":
                cal = cal + gstr.b11.total.taxb;
                return cal.toFixed(2);
            case "cess":
                cal = cal + gstr.b11.total.cess;
                return cal.toFixed(2);
        }
        return cal.toFixed(2);
    }

    const get11AA1 = (type) => {
        let cal = 0;
        switch (type) {
            case "Total":
                var ATotal = (gstr.a11a.total.value - gstr.a11a.ototal ? gstr.a11a.ototal.value : 0);
                var BTotal = (gstr.b11a.total.value - gstr.b11a.ototal ? gstr.b11a.ototal.value : 0);
                cal = ATotal - BTotal;
                return cal.toFixed(2);
            case "igst":
                var ATotal = (gstr.a11a.total.taxa - gstr.a11a.ototal ? gstr.a11a.ototal.taxa : 0);
                var BTotal = (gstr.b11a.total.taxa - gstr.b11a.ototal ? gstr.b11a.ototal.taxa : 0);
                cal = ATotal - BTotal;
                return cal.toFixed(2);
            case "sgst":
            case "cgst":
                var ATotal = (gstr.a11a.total.taxb - gstr.a11a.ototal ? gstr.a11a.ototal.taxb : 0);
                var BTotal = (gstr.b11a.total.taxb - gstr.b11a.ototal ? gstr.b11a.ototal.taxb : 0);
                cal = ATotal - BTotal;
                return cal.toFixed(2);
            case "cess":
                var ATotal = (gstr.a11a.total.cess - gstr.a11a.ototal ? gstr.a11a.ototal.cess : 0);
                var BTotal = (gstr.b11a.total.cess - gstr.b11a.ototal ? gstr.b11a.ototal.cess : 0);
                cal = ATotal - BTotal;
                return cal.toFixed(2);
        }
        return cal.toFixed(2);
    }

    const getTotalA = (type) => {
        let cal = 0;
        switch (type) {
            case "Total":
                cal = parseFloat(get11AA1('Total')) + parseFloat(get11B1('Total')) + parseFloat(get11A1('Total')) + parseFloat(get10B('Total')) + parseFloat(get10A('Total')) + + parseFloat(get9C('Total')) + parseFloat(get9B('Total')) + parseFloat(get9A('Total'));
                cal = cal + parseFloat(getB2CSTotal('Total', 'B')) + parseFloat(getB2CSTotal('Total', 'A')) + parseFloat(gstr.b2cl.total.value) + parseFloat(gstr.b2bA.total.value) + parseFloat(gstr.b2bB.total.value);
                return cal.toFixed(2);
            case "igst":
                cal = parseFloat(get11AA1('igst')) + parseFloat(get11B1('igst')) + parseFloat(get11A1('igst')) + parseFloat(get10B('igst')) + parseFloat(get10A('igst')) + + parseFloat(get9C('igst')) + parseFloat(get9B('igst')) + parseFloat(get9A('igst'));
                cal = cal + parseFloat(getB2CSTotal('igst', 'B')) + parseFloat(gstr.b2cl.total.taxa) + parseFloat(gstr.b2bA.total.taxa) + parseFloat(gstr.b2bB.total.taxa);
                return cal.toFixed(2);
            case "sgst":
            case "cgst":
                cal = parseFloat(get11AA1('cgst')) + parseFloat(get11B1('cgst')) + parseFloat(get11A1('cgst')) + parseFloat(get10B('cgst')) + parseFloat(get10A('cgst')) + + parseFloat(get9C('cgst')) + parseFloat(get9B('cgst')) + parseFloat(get9A('cgst'));
                cal = cal + parseFloat(getB2CSTotal('cgst', 'A')) + parseFloat(gstr.b2bA.total.taxb) + parseFloat(gstr.b2bB.total.taxb);
                return cal.toFixed(2);
            case "cess":
                cal = parseFloat(get11AA1('cess')) + parseFloat(get11B1('cess')) + parseFloat(get11A1('cess')) + parseFloat(get10B('cess')) + parseFloat(get10A('cess')) + + parseFloat(get9C('cess')) + parseFloat(get9B('cess')) + parseFloat(get9A('cess'));
                cal = cal + parseFloat(getB2CSTotal('cess', 'B')) + parseFloat(getB2CSTotal('cess', 'A')) + parseFloat(gstr.b2cl.total.cess) + parseFloat(gstr.b2bA.total.cess) + parseFloat(gstr.b2bB.total.cess);
                return cal.toFixed(2);
        }
        return cal.toFixed(2);
    }

    const getTotalB = (type) => {
        let cal = 0;
        switch (type) {
            case "Total":
                cal = gstr.a6.total.value + (gstr.a9.total.value - gstr.a9.ototal ? gstr.a9.ototal.value : 0) + (gstr.cdu.wp.value + gstr.cdu.wop.value) + (gstr.cdua.wp.value + gstr.cdua.wop.value);
                return cal.toFixed(2);
            case "igst":
                cal = gstr.a6.total.taxa + (gstr.a9.total.taxa - gstr.a9.ototal ? gstr.a9.ototal.taxa : 0) + (gstr.cdu.wp.taxa + gstr.cdu.wop.taxa) + (gstr.cdua.wp.taxa + gstr.cdua.wop.taxa);
                return cal.toFixed(2);
            case "cess":
                cal = gstr.a6.total.cess + (gstr.a9.total.cess - gstr.a9.ototal ? gstr.a9.ototal.cess : 0) + (gstr.cdu.wp.cess + gstr.cdu.wop.cess) + (gstr.cdua.wp.cess + gstr.cdua.wop.cess);
                return cal.toFixed(2);
        }
        return cal.toFixed(2);
    }

    const getF5A = (type) => {
        let cal = 0;
        switch (type) {
            case "total":
                cal = gstr.b2cl.total.value;
                break;
            case "igst":
                cal = gstr.b2cl.total.taxa;
                break;
        }
        return cal.toFixed(2);
    }

    const getF9A = (type) => {
        let cal = 0;
        switch (type) {
            case "total":
                cal = (gstr.b2cla.total.value - (gstr.b2cla.ototal ? gstr.b2cla.ototal.value : 0));
                break;
            case "igst":
                cal = (gstr.b2cla.total.taxa - (gstr.b2cla.ototal ? gstr.b2cla.ototal.taxa : 0));
                break;
        }
        return cal.toFixed(2);
    }

    return (
        <div className={styles.printDoc} id="divToPrint">
            <div className='fixed w-[595pt] h-[800pt] flex flex-col items-center justify-center opacity-20 pointer-events-none' style={{ zIndex: 9999 }}>
                <img src={Logo} alt="ITAA" className='w-[800] mx-auto h-[227px] pointer-events-none' style={{ transform: "rotate(-45deg)" }} />
            </div>
            <p className={styles.s1} style={{ textIndent: "0pt", textAlign: "center", marginTop: '28pt' }} >
                Form GSTR-3B
            </p>
            <p className={styles.s1} style={{ textIndent: "0pt", textAlign: "center" }} >
                System generated summary
            </p>
            <p className={styles.s2} style={{ paddingLeft: "22pt", paddingRight: "22pt", textIndent: "-1pt", textAlign: "center", maxWidth: '980px' }} >
                [This summary has been generated based on your FORM GSTR-1, GSTR-2B (auto-drafted ITC statement) and GSTR-3B (previous period). These values auto-populated below may be used to in relevant tables in FORM GSTR-3B. This summary is generated for information and guidance purposes only. Please enter correct values, after due]
            </p>
            <table style={{ borderCollapse: "collapse", marginLeft: "382pt", marginTop: '18pt' }} cellSpacing={0}>
                <tbody>
                    <tr style={{ height: "15pt" }}>
                        <td
                            style={{
                                width: "84pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            bgcolor="#F9D2B0"
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "4pt",
                                    textIndent: "0pt",
                                    textAlign: "left"
                                }}
                            >
                                Financial year
                            </p>
                        </td>
                        <td
                            style={{
                                width: "108pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "4pt",
                                    textIndent: "0pt",
                                    textAlign: "left"
                                }}
                            >
                                {getStartY()}-{getEndY()}
                            </p>
                        </td>
                    </tr>
                    <tr style={{ height: "15pt" }}>
                        <td
                            style={{
                                width: "84pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            bgcolor="#F9D2B0"
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "4pt",
                                    textIndent: "0pt",
                                    textAlign: "left"
                                }}
                            >
                                Tax period
                            </p>
                        </td>
                        <td
                            style={{
                                width: "108pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "4pt",
                                    textIndent: "0pt",
                                    textAlign: "left"
                                }}
                            >
                                {Moment(gDate).format("MMMM")}
                            </p>
                        </td>
                    </tr>
                </tbody>
            </table>
            <p style={{ textIndent: "0pt", textAlign: "left" }}>
                <br />
            </p>
            <table style={{ borderCollapse: "collapse", marginLeft: "20.25pt" }} cellSpacing={0}>
                <tbody>
                    <tr style={{ height: "14pt" }}>
                        <td
                            style={{
                                width: "29pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt",
                                margin: '0'
                            }}
                        >
                            <p
                                className={styles.s5}
                                style={{
                                    paddingTop: "1pt",
                                    textIndent: "0pt",
                                    textAlign: "center"
                                }}
                            >
                                1
                            </p>
                        </td>
                        <td
                            style={{
                                width: "188pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt",
                                margin: '0'
                            }}
                            colSpan={2}
                            bgcolor="#F9D2B0"
                        >
                            <p
                                className={styles.s5}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "68pt",
                                    paddingRight: "70pt",
                                    textIndent: "0pt",
                                    textAlign: "center"
                                }}
                            >
                                GSTIN
                            </p>
                        </td>
                        <td
                            style={{
                                width: "338pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt",
                                margin: '0'
                            }}
                        >
                            <p className={styles.s5}
                                style={{
                                    paddingLeft: "4pt",
                                    textIndent: "0pt",
                                    lineHeight: "10pt",
                                    textAlign: "left"
                                }}
                            >
                                {GSTUser.gstn}
                            </p>
                        </td>
                    </tr>
                    <tr style={{ height: "16pt" }}>
                        <td
                            style={{
                                width: "29pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            rowSpan={5}
                        >
                            <p
                                className={styles.s5}
                                style={{
                                    paddingTop: "1pt",
                                    textIndent: "0pt",
                                    textAlign: "center",
                                    margin: '0'
                                }}
                            >
                                2
                            </p>
                        </td>
                        <td
                            style={{
                                width: "24pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            bgcolor="#F9D2B0"
                        >
                            <p
                                className={styles.s5}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "5pt",
                                    paddingRight: "5pt",
                                    textIndent: "0pt",
                                    textAlign: "center",
                                    margin: '0'
                                }}
                            >
                                (a)
                            </p>
                        </td>
                        <td
                            style={{
                                width: "164pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            bgcolor="#F9D2B0"
                        >
                            <p
                                className={styles.s5}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "4pt",
                                    textIndent: "0pt",
                                    textAlign: "left",
                                    margin: '0'
                                }}
                            >
                                Legal name of the registered person
                            </p>
                        </td>
                        <td
                            style={{
                                width: "338pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p className={styles.s5}
                                style={{
                                    paddingLeft: "4pt",
                                    textIndent: "0pt",
                                    lineHeight: "10pt",
                                    textAlign: "left",
                                    paddingTop: '1pt',
                                    margin: '0'
                                }}
                            >
                                {GSTUser.buzName}
                            </p>
                        </td>
                    </tr>
                    <tr style={{ height: "16pt" }}>
                        <td
                            style={{
                                width: "24pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            bgcolor="#F9D2B0"
                        >
                            <p
                                className={styles.s5}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "6pt",
                                    paddingRight: "5pt",
                                    textIndent: "0pt",
                                    textAlign: "center",
                                    margin: '0'
                                }}
                            >
                                (b)
                            </p>
                        </td>
                        <td
                            style={{
                                width: "164pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            bgcolor="#F9D2B0"
                        >
                            <p
                                className={styles.s5}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "4pt",
                                    textIndent: "0pt",
                                    textAlign: "left",
                                    margin: '0'
                                }}
                            >
                                Trade name, if any
                            </p>
                        </td>
                        <td
                            style={{
                                width: "338pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p className={styles.s5}
                                style={{
                                    paddingLeft: "4pt",
                                    textIndent: "0pt",
                                    lineHeight: "10pt",
                                    textAlign: "left",
                                    paddingTop: '1pt',
                                    margin: '0'
                                }}
                            >
                                {GSTUser.tname}
                            </p>
                        </td>
                    </tr>
                    <tr style={{ height: "16pt" }}>
                        <td
                            style={{
                                width: "24pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            bgcolor="#F9D2B0"
                        >
                            <p
                                className={styles.s5}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "5pt",
                                    paddingRight: "5pt",
                                    textIndent: "0pt",
                                    textAlign: "center",
                                    margin: '0'
                                }}
                            >
                                (c)
                            </p>
                        </td>
                        <td
                            style={{
                                width: "164pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            bgcolor="#F9D2B0"
                        >
                            <p
                                className={styles.s5}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "4pt",
                                    textIndent: "0pt",
                                    textAlign: "left",
                                    margin: '0'
                                }}
                            >
                                GSTR-1 date
                                <span />
                            </p>
                        </td>
                        <td
                            style={{
                                width: "338pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s5}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "4pt",
                                    textIndent: "0pt",
                                    textAlign: "left",
                                    margin: '0'
                                }}
                            >
                                {Moment(gDate).add('month', 1).add('days', 10).format('DD-MM-yyyy')}
                            </p>
                        </td>
                    </tr>
                    <tr style={{ height: "16pt" }}>
                        <td
                            style={{
                                width: "24pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            bgcolor="#F9D2B0"
                        >
                            <p
                                className={styles.s5}
                                style={{
                                    paddingTop: "2pt",
                                    paddingLeft: "6pt",
                                    paddingRight: "5pt",
                                    textIndent: "0pt",
                                    textAlign: "center",
                                    margin: '0'
                                }}
                            >
                                (d)
                            </p>
                        </td>
                        <td
                            style={{
                                width: "164pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            bgcolor="#F9D2B0"
                        >
                            <p
                                className={styles.s5}
                                style={{
                                    paddingTop: "2pt",
                                    paddingLeft: "4pt",
                                    textIndent: "0pt",
                                    textAlign: "left",
                                    margin: '0'
                                }}
                            >
                                GSTR-2B generation date
                            </p>
                        </td>
                        <td
                            style={{
                                width: "338pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s5}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "4pt",
                                    textIndent: "0pt",
                                    textAlign: "left",
                                    margin: '0'
                                }}
                            >
                                {Moment(gDate).add('month', 1).add('days', 13).format('DD-MM-yyyy')}
                            </p>
                        </td>
                    </tr>
                    <tr style={{ height: "16pt" }}>
                        <td
                            style={{
                                width: "24pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            bgcolor="#F9D2B0"
                        >
                            <p
                                className={styles.s5}
                                style={{
                                    paddingTop: "2pt",
                                    paddingLeft: "5pt",
                                    paddingRight: "5pt",
                                    textIndent: "0pt",
                                    textAlign: "center",
                                    margin: '0'
                                }}
                            >
                                (e)
                            </p>
                        </td>
                        <td
                            style={{
                                width: "164pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            bgcolor="#F9D2B0"
                        >
                            <p
                                className={styles.s5}
                                style={{
                                    paddingTop: "2pt",
                                    paddingLeft: "4pt",
                                    textIndent: "0pt",
                                    textAlign: "left",
                                    margin: '0'
                                }}
                            >
                                GSTR-3B summary generation date
                            </p>
                        </td>
                        <td
                            style={{
                                width: "338pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s5}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "4pt",
                                    textIndent: "0pt",
                                    textAlign: "left",
                                    margin: '0'
                                }}
                            >
                                {Moment(gDate).add('days', 13).add('month', 1).format('DD-MM-yyyy')}
                            </p>
                        </td>
                    </tr>
                </tbody>
            </table>
            <p style={{ textIndent: "0pt", textAlign: "left" }}>
                <br />
            </p>
            <div className='bg-[#243f6d] pl-[20.25pt] py-[2pt]'>
                <p style={{
                    textIndent: "0pt",
                    lineHeight: "11pt",
                    textAlign: "left",
                    margin: '0'
                }}
                >
                    <span
                        style={{
                            color: "#FFF",
                            fontFamily: '"Trebuchet MS", sans-serif',
                            fontStyle: "normal",
                            fontWeight: "400",
                            textDecoration: "none",
                            fontSize: "9pt"
                        }}
                    >
                        Section I: Auto- populated details of Table 3.1, 3.2, 4 and 5.1 of FORM GSTR-3B
                    </span>
                </p>
                <p style={{
                    textIndent: "0pt",
                    lineHeight: "9pt",
                    textAlign: "left",
                    margin: '0'
                }}
                >
                    <span
                        style={{
                            color: "#FFF",
                            fontFamily: '"Trebuchet MS", sans-serif',
                            fontStyle: "italic",
                            fontWeight: "normal",
                            textDecoration: "none",
                            fontSize: "8pt"
                        }}
                    >
                        Taxpayers may use this data to their FORM GSTR-3B
                    </span>
                </p>
            </div>
            <p className='text-lg font-medium' style={{ paddingTop: "4pt", paddingRight: '18.75pt', textIndent: "0pt", textAlign: "right", margin: '0' }}>
                (Amount in ₹ for all tables)
            </p>
            <p style={{ marginLeft: '20.25pt', textIndent: "0pt", paddingTop: "2pt" }}>
                <a name="bookmark0">
                    <p className={styles.s7} style={{ backgroundColor: "#FCE9D9", width: '556pt', paddingTop: '2px', paddingBottom: '2px', paddingLeft: '4px' }}>
                        A. Table 3.1 of FORM GSTR-3B : Details of Outward supplies and inward supplies liable to reverse charge
                    </p>
                </a>
            </p>
            <table style={{ borderCollapse: "collapse", marginLeft: "20.25pt" }} cellSpacing={0}>
                <tbody>
                    <tr style={{ height: "26pt" }}>
                        <td
                            style={{
                                width: "159pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            bgcolor="#F1F1F1"
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "42pt",
                                    textIndent: "0pt",
                                    textAlign: "left",
                                    margin: '0'
                                }}
                            >
                                Nature of supplies
                            </p>
                        </td>
                        <td
                            style={{
                                width: "79pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            bgcolor="#F1F1F1"
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "29pt",
                                    textIndent: "-15pt",
                                    textAlign: "left",
                                    margin: '0'
                                }}
                            >
                                Total taxable value
                            </p>
                        </td>
                        <td
                            style={{
                                width: "79pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            bgcolor="#F1F1F1"
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "12pt",
                                    textIndent: "0pt",
                                    textAlign: "left",
                                    margin: '0'
                                }}
                            >
                                Integrated tax
                            </p>
                        </td>
                        <td
                            style={{
                                width: "79pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            bgcolor="#F1F1F1"
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "17pt",
                                    textIndent: "0pt",
                                    textAlign: "left",
                                    margin: '0'
                                }}
                            >
                                Central Tax
                            </p>
                        </td>
                        <td
                            style={{
                                width: "80pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            bgcolor="#F1F1F1"
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "13pt",
                                    textIndent: "0pt",
                                    textAlign: "left",
                                    margin: '0'
                                }}
                            >
                                State/ UT Tax
                            </p>
                        </td>
                        <td
                            style={{
                                width: "79pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            bgcolor="#F1F1F1"
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "28pt",
                                    paddingRight: "31pt",
                                    textIndent: "0pt",
                                    textAlign: "center",
                                    margin: '0'
                                }}
                            >
                                Cess
                            </p>
                        </td>
                    </tr>
                    <tr style={{ height: "36pt" }}>
                        <td
                            style={{
                                width: "159pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                style={{
                                    paddingTop: "1pt",
                                    paddingBottom: "1pt",
                                    paddingLeft: "4pt",
                                    textIndent: "0pt",
                                    textAlign: "left",
                                    margin: '0',
                                    lineHeight: '10pt'
                                }}
                            >
                                <a href="#bookmark4" className={styles.s8}>
                                    (a)Outward taxable supplies (other than zero rated, nil rated and exempted)
                                </a>
                            </p>
                        </td>
                        <td
                            style={{
                                width: "79pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "7pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    margin: '0',
                                }}
                            >
                                {parseFloat(gstrf.data.a3.user.a.total.value).toFixed(2)}
                            </p>
                        </td>
                        <td
                            style={{
                                width: "79pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "5pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    margin: '0',
                                }}
                            >
                                {parseFloat(gstrf.data.a3.user.a.igst.value).toFixed(2)}
                            </p>
                        </td>
                        <td
                            style={{
                                width: "79pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "7pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    margin: '0',
                                }}
                            >
                                {parseFloat(gstrf.data.a3.user.a.cgst.value).toFixed(2)}
                            </p>
                        </td>
                        <td
                            style={{
                                width: "80pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "7pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    margin: '0',
                                }}
                            >
                                {parseFloat(gstrf.data.a3.user.a.sgst.value).toFixed(2)}
                            </p>
                        </td>
                        <td
                            style={{
                                width: "79pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "5pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    margin: '0',
                                }}
                            >
                                {parseFloat(gstrf.data.a3.user.a.cess.value).toFixed(2)}
                            </p>
                        </td>
                    </tr>
                    <tr style={{ height: "26pt" }}>
                        <td
                            style={{
                                width: "159pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                style={{
                                    paddingTop: "1pt",
                                    paddingBottom: "1pt",
                                    paddingLeft: "4pt",
                                    textIndent: "0pt",
                                    textAlign: "left",
                                    margin: '0',
                                    lineHeight: '10pt'
                                }}
                            >
                                <a href="#bookmark5" className={styles.s8}>
                                    (b)Outward taxable supplies (zero rated)
                                </a>
                            </p>
                        </td>
                        <td
                            style={{
                                width: "79pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "5pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    margin: '0',
                                }}
                            >
                                {parseFloat(gstrf.data.a3.user.b.total.value).toFixed(2)}
                            </p>
                        </td>
                        <td
                            style={{
                                width: "79pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "5pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    margin: '0',
                                }}
                            >
                                {parseFloat(gstrf.data.a3.user.b.igst.value).toFixed(2)}
                            </p>
                        </td>
                        <td
                            style={{
                                width: "79pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            bgcolor="#BEBEBE"
                        >
                            <p style={{
                                textIndent: "0pt", textAlign: "left",
                                margin: '0'
                            }}>
                                <br />
                            </p>
                        </td>
                        <td
                            style={{
                                width: "80pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            bgcolor="#BEBEBE"
                        >
                            <p style={{
                                textIndent: "0pt", textAlign: "left",
                                margin: '0',
                            }}>
                                <br />
                            </p>
                        </td>
                        <td
                            style={{
                                width: "79pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "5pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    margin: '0',
                                }}
                            >
                                {parseFloat(gstrf.data.a3.user.b.cess.value).toFixed(2)}
                            </p>
                        </td>
                    </tr>
                    <tr style={{ height: "26pt" }}>
                        <td
                            style={{
                                width: "159pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                style={{
                                    paddingTop: "1pt",
                                    paddingBottom: "1pt",
                                    paddingLeft: "4pt",
                                    textIndent: "0pt",
                                    textAlign: "left",
                                    margin: '0',
                                    lineHeight: '10pt'
                                }}
                            >
                                <a href="#bookmark6" className={styles.s8}>
                                    (c)Other outward supplies (Nil rated, exempted)
                                </a>
                            </p>
                        </td>
                        <td
                            style={{
                                width: "79pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "5pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    margin: '0'
                                }}
                            >
                                {parseFloat(gstrf.data.a3.user.c.total.value).toFixed(2)}
                            </p>
                        </td>
                        <td
                            style={{
                                width: "79pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            bgcolor="#BEBEBE"
                        >
                            <p style={{
                                textIndent: "0pt", textAlign: "left",
                                margin: '0'
                            }}>
                                <br />
                            </p>
                        </td>
                        <td
                            style={{
                                width: "79pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            bgcolor="#BEBEBE"
                        >
                            <p style={{
                                textIndent: "0pt", textAlign: "left",
                                margin: '0'
                            }}>
                                <br />
                            </p>
                        </td>
                        <td
                            style={{
                                width: "80pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            bgcolor="#BEBEBE"
                        >
                            <p style={{
                                textIndent: "0pt", textAlign: "left",
                                margin: '0'
                            }}>
                                <br />
                            </p>
                        </td>
                        <td
                            style={{
                                width: "79pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            bgcolor="#BEBEBE"
                        >
                            <p style={{
                                textIndent: "0pt", textAlign: "left",
                                margin: '0'
                            }}>
                                <br />
                            </p>
                        </td>
                    </tr>
                    <tr style={{ height: "26pt" }}>
                        <td
                            style={{
                                width: "159pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                style={{
                                    paddingTop: "1pt",
                                    paddingBottom: "1pt",
                                    paddingLeft: "4pt",
                                    textIndent: "0pt",
                                    textAlign: "left",
                                    margin: '0',
                                    lineHeight: '10pt'
                                }}
                            >
                                <a href="#bookmark7" className={styles.s8}>
                                    (d)Inward supplies(liable to reverse charge)
                                </a>
                            </p>
                        </td>
                        <td
                            style={{
                                width: "79pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "5pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    margin: '0'
                                }}
                            >
                                {parseFloat(gstrf.data.a3.user.d.total.value).toFixed(2)}
                            </p>
                        </td>
                        <td
                            style={{
                                width: "79pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "5pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    margin: '0'
                                }}
                            >
                                {parseFloat(gstrf.data.a3.user.d.igst.value).toFixed(2)}
                            </p>
                        </td>
                        <td
                            style={{
                                width: "79pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "5pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    margin: '0'
                                }}
                            >
                                {parseFloat(gstrf.data.a3.user.d.cgst.value).toFixed(2)}
                            </p>
                        </td>
                        <td
                            style={{
                                width: "80pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "5pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    margin: '0'
                                }}
                            >
                                {parseFloat(gstrf.data.a3.user.d.sgst.value).toFixed(2)}
                            </p>
                        </td>
                        <td
                            style={{
                                width: "79pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "5pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    margin: '0'
                                }}
                            >
                                {parseFloat(gstrf.data.a3.user.d.cess.value).toFixed(2)}
                            </p>
                        </td>
                    </tr>
                    <tr style={{ height: "15pt" }}>
                        <td
                            style={{
                                width: "159pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                style={{
                                    paddingTop: "1pt",
                                    paddingBottom: "1pt",
                                    paddingLeft: "4pt",
                                    textIndent: "0pt",
                                    textAlign: "left",
                                    margin: '0',
                                    lineHeight: '10pt'
                                }}
                            >
                                <a href="#bookmark8" className={styles.s8}>
                                    (e)Non- GST outward supplies
                                </a>
                            </p>
                        </td>
                        <td
                            style={{
                                width: "79pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "5pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    margin: '0'
                                }}
                            >
                                {parseFloat(gstrf.data.a3.user.e.total.value).toFixed(2)}
                            </p>
                        </td>
                        <td
                            style={{
                                width: "79pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            bgcolor="#BEBEBE"
                        >
                            <p style={{
                                textIndent: "0pt", textAlign: "left",
                                margin: '0'
                            }}>
                                <br />
                            </p>
                        </td>
                        <td
                            style={{
                                width: "79pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            bgcolor="#BEBEBE"
                        >
                            <p style={{
                                textIndent: "0pt", textAlign: "left",
                                margin: '0'
                            }}>
                                <br />
                            </p>
                        </td>
                        <td
                            style={{
                                width: "80pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            bgcolor="#BEBEBE"
                        >
                            <p style={{
                                textIndent: "0pt", textAlign: "left",
                                margin: '0'
                            }}>
                                <br />
                            </p>
                        </td>
                        <td
                            style={{
                                width: "79pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            bgcolor="#BEBEBE"
                        >
                            <p style={{
                                textIndent: "0pt", textAlign: "left",
                                margin: '0'
                            }}>
                                <br />
                            </p>
                        </td>
                    </tr>
                </tbody>
            </table>
            <p style={{ marginLeft: '20.25pt', textIndent: "0pt", paddingTop: "4pt", paddingBottom: "4pt", marginBottom: "0", marginLeft: "20.25pt" }}>
                <a name="bookmark0">
                    <p className={styles.s7} style={{ backgroundColor: "#FCE9D9", width: '556pt', paddingTop: '2px', paddingBottom: '2px', paddingLeft: '4px', margin: "0" }}>
                        B. Table 3.2 of FORM GSTR-3B : Of the supplies shown in 3.1(a) above, details of inter- state supplies made to unregistered persons, composition taxable persons and UIN holders
                    </p>
                </a>
            </p>
            <table style={{ borderCollapse: "collapse", marginLeft: "20.25pt" }} cellSpacing={0}>
                <tbody>
                    <tr style={{ height: "26pt" }}>
                        <td
                            style={{
                                width: "259pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            bgcolor="#F1F1F1"
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "90pt",
                                    paddingRight: "92pt",
                                    textIndent: "0pt",
                                    textAlign: "center",
                                    margin: "0"
                                }}
                            >
                                Nature of supplies
                            </p>
                        </td>
                        <td
                            style={{
                                width: "98pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            bgcolor="#F1F1F1"
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "10pt",
                                    paddingRight: "10pt",
                                    textIndent: "-9pt",
                                    textAlign: "center",
                                    margin: "0"
                                }}
                            >
                                Place of supply (State/ UT)
                            </p>
                        </td>
                        <td
                            style={{
                                width: "99pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            bgcolor="#F1F1F1"
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "11pt",
                                    textIndent: "0pt",
                                    textAlign: "left",
                                    margin: "0"
                                }}
                            >
                                Total taxable value
                            </p>
                        </td>
                        <td
                            style={{
                                width: "99pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            bgcolor="#F1F1F1"
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "43pt",
                                    textIndent: "-36pt",
                                    textAlign: "left",
                                    margin: "0"
                                }}
                            >
                                Amount of Integrated tax
                            </p>
                        </td>
                    </tr>
                    <tr style={{ height: "15pt" }}>
                        <td
                            style={{
                                width: "259pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            rowSpan={gstrf.data.a32.lista.length === 0 ? 1 : gstrf.data.a32.lista.length}
                        >
                            <p
                                style={{
                                    paddingTop: "1pt",
                                    paddingBottom: "1pt",
                                    paddingLeft: "4pt",
                                    textIndent: "0pt",
                                    textAlign: "left",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                <a href="#bookmark9" className={styles.s8}>
                                    Supplies made to Unregistered persons
                                </a>
                            </p>
                        </td>
                        {
                            gstrf.data.a32.lista.length > 0 && <td
                                style={{
                                    width: "98pt",
                                    borderTopStyle: "solid",
                                    borderTopWidth: "1pt",
                                    borderLeftStyle: "solid",
                                    borderLeftWidth: "1pt",
                                    borderBottomStyle: "solid",
                                    borderBottomWidth: "1pt",
                                    borderRightStyle: "solid",
                                    borderRightWidth: "1pt"
                                }}
                            >
                                <p className={styles.s4} style={{ textIndent: "0pt", textAlign: "left", paddingLeft: "2pt", margin: "0" }}>
                                    {gstrf.data.a32.lista[0].pos}
                                </p>
                            </td>
                        }
                        {
                            gstrf.data.a32.lista.length > 0 && <td
                                style={{
                                    width: "99pt",
                                    borderTopStyle: "solid",
                                    borderTopWidth: "1pt",
                                    borderLeftStyle: "solid",
                                    borderLeftWidth: "1pt",
                                    borderBottomStyle: "solid",
                                    borderBottomWidth: "1pt",
                                    borderRightStyle: "solid",
                                    borderRightWidth: "1pt"
                                }}
                            >
                                <p
                                    className={styles.s4}
                                    style={{
                                        paddingTop: "1pt",
                                        paddingRight: "5pt",
                                        textIndent: "0pt",
                                        textAlign: "right",
                                        margin: "0"
                                    }}
                                >
                                    {gstrf.data.a32.lista[0].total}
                                </p>
                            </td>
                        }
                        {
                            gstrf.data.a32.lista.length > 0 && <td
                                style={{
                                    width: "99pt",
                                    borderTopStyle: "solid",
                                    borderTopWidth: "1pt",
                                    borderLeftStyle: "solid",
                                    borderLeftWidth: "1pt",
                                    borderBottomStyle: "solid",
                                    borderBottomWidth: "1pt",
                                    borderRightStyle: "solid",
                                    borderRightWidth: "1pt"
                                }}
                            >
                                <p
                                    className={styles.s4}
                                    style={{
                                        paddingTop: "1pt",
                                        paddingRight: "5pt",
                                        textIndent: "0pt",
                                        textAlign: "right",
                                        margin: "0"
                                    }}
                                >
                                    {gstrf.data.a32.lista[0].igst}
                                </p>
                            </td>
                        }
                        {
                            gstrf.data.a32.lista.length === 0 && <td
                                style={{
                                    width: "98pt",
                                    borderTopStyle: "solid",
                                    borderTopWidth: "1pt",
                                    borderLeftStyle: "solid",
                                    borderLeftWidth: "1pt",
                                    borderBottomStyle: "solid",
                                    borderBottomWidth: "1pt",
                                    borderRightStyle: "solid",
                                    borderRightWidth: "1pt"
                                }}
                            >
                                <p style={{ textIndent: "0pt", textAlign: "left", margin: "0" }}>
                                    <br />
                                </p>
                            </td>
                        }
                        {
                            gstrf.data.a32.lista.length === 0 && <td
                                style={{
                                    width: "99pt",
                                    borderTopStyle: "solid",
                                    borderTopWidth: "1pt",
                                    borderLeftStyle: "solid",
                                    borderLeftWidth: "1pt",
                                    borderBottomStyle: "solid",
                                    borderBottomWidth: "1pt",
                                    borderRightStyle: "solid",
                                    borderRightWidth: "1pt"
                                }}
                            >
                                <p
                                    className={styles.s4}
                                    style={{
                                        paddingTop: "1pt",
                                        paddingRight: "5pt",
                                        textIndent: "0pt",
                                        textAlign: "right",
                                        margin: "0"
                                    }}
                                >
                                    0.00
                                </p>
                            </td>
                        }
                        {
                            gstrf.data.a32.lista.length === 0 && <td
                                style={{
                                    width: "99pt",
                                    borderTopStyle: "solid",
                                    borderTopWidth: "1pt",
                                    borderLeftStyle: "solid",
                                    borderLeftWidth: "1pt",
                                    borderBottomStyle: "solid",
                                    borderBottomWidth: "1pt",
                                    borderRightStyle: "solid",
                                    borderRightWidth: "1pt"
                                }}
                            >
                                <p
                                    className={styles.s4}
                                    style={{
                                        paddingTop: "1pt",
                                        paddingRight: "5pt",
                                        textIndent: "0pt",
                                        textAlign: "right",
                                        margin: "0"
                                    }}
                                >
                                    0.00
                                </p>
                            </td>
                        }
                    </tr>
                    {
                        gstrf.data.a32.lista.length > 1 && gstrf.data.a32.lista.map((item, index) => {
                            if (index === 0) {
                                return null;
                            }
                            return <tr style={{ height: "15pt" }}>
                                <td
                                    style={{
                                        width: "98pt",
                                        borderTopStyle: "solid",
                                        borderTopWidth: "1pt",
                                        borderLeftStyle: "solid",
                                        borderLeftWidth: "1pt",
                                        borderBottomStyle: "solid",
                                        borderBottomWidth: "1pt",
                                        borderRightStyle: "solid",
                                        borderRightWidth: "1pt"
                                    }}
                                >
                                    <p className={styles.s4} style={{ textIndent: "0pt", textAlign: "left", paddingLeft: "2pt", margin: "0" }}>
                                        {item.pos}
                                    </p>
                                </td>
                                <td
                                    style={{
                                        width: "99pt",
                                        borderTopStyle: "solid",
                                        borderTopWidth: "1pt",
                                        borderLeftStyle: "solid",
                                        borderLeftWidth: "1pt",
                                        borderBottomStyle: "solid",
                                        borderBottomWidth: "1pt",
                                        borderRightStyle: "solid",
                                        borderRightWidth: "1pt"
                                    }}
                                >
                                    <p
                                        className={styles.s4}
                                        style={{
                                            paddingTop: "1pt",
                                            paddingRight: "5pt",
                                            textIndent: "0pt",
                                            textAlign: "right",
                                            margin: "0"
                                        }}
                                    >
                                        {item.total}
                                    </p>
                                </td>
                                <td
                                    style={{
                                        width: "99pt",
                                        borderTopStyle: "solid",
                                        borderTopWidth: "1pt",
                                        borderLeftStyle: "solid",
                                        borderLeftWidth: "1pt",
                                        borderBottomStyle: "solid",
                                        borderBottomWidth: "1pt",
                                        borderRightStyle: "solid",
                                        borderRightWidth: "1pt"
                                    }}
                                >
                                    <p
                                        className={styles.s4}
                                        style={{
                                            paddingTop: "1pt",
                                            paddingRight: "5pt",
                                            textIndent: "0pt",
                                            textAlign: "right",
                                            margin: "0"
                                        }}
                                    >
                                        {item.igst}
                                    </p>
                                </td>
                            </tr>
                        })
                    }
                    <tr style={{ height: "15pt" }}>
                        <td
                            style={{
                                width: "259pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                style={{
                                    paddingTop: "1pt",
                                    paddingBottom: "1pt",
                                    paddingLeft: "4pt",
                                    textIndent: "0pt",
                                    textAlign: "left",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                <a href="#bookmark10" className={styles.s8}>
                                    Supplies made to Composition taxable persons
                                </a>
                            </p>
                        </td>
                        <td
                            style={{
                                width: "98pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p style={{
                                textIndent: "0pt", textAlign: "left",
                                margin: "0"
                            }}>
                                <br />
                            </p>
                        </td>
                        <td
                            style={{
                                width: "99pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "5pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    margin: "0"
                                }}
                            >
                                {get3Total('listb')}
                            </p>
                        </td>
                        <td
                            style={{
                                width: "99pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "5pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    margin: "0"
                                }}
                            >
                                {get3Igst('listb')}
                            </p>
                        </td>
                    </tr>
                    <tr style={{ height: "15pt" }}>
                        <td
                            style={{
                                width: "259pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                style={{
                                    paddingTop: "1pt",
                                    paddingBottom: "1pt",
                                    paddingLeft: "4pt",
                                    textIndent: "0pt",
                                    textAlign: "left",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                <a href="#bookmark11" className={styles.s8}>
                                    Supplies made to UIN holders
                                </a>
                            </p>
                        </td>
                        <td
                            style={{
                                width: "98pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p style={{
                                textIndent: "0pt", textAlign: "left",
                                margin: "0"
                            }}>
                                <br />
                            </p>
                        </td>
                        <td
                            style={{
                                width: "99pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "5pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    margin: "0"
                                }}
                            >
                                {get3Total('listc')}
                            </p>
                        </td>
                        <td
                            style={{
                                width: "99pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "5pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    margin: "0"
                                }}
                            >
                                {get3Igst('listc')}
                            </p>
                        </td>
                    </tr>
                </tbody>
            </table>
            <p style={{ marginLeft: '20.25pt', textIndent: "0pt", paddingTop: "4pt", paddingBottom: "4pt", marginBottom: "0", marginLeft: "20.25pt" }}>
                <a name="bookmark0">
                    <p className={styles.s7} style={{ backgroundColor: "#FCE9D9", width: '556pt', paddingTop: '2px', paddingBottom: '2px', paddingLeft: '4px', margin: "0" }}>
                        C. Table 4 of FORM GSTR-3B : Eligible ITC
                    </p>
                </a>
            </p>
            <table style={{ borderCollapse: "collapse", marginLeft: "20.25pt" }} cellSpacing={0}>
                <tbody>
                    <tr style={{ height: "15pt" }}>
                        <td
                            style={{
                                width: "189pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            bgcolor="#F1F1F1"
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "69pt",
                                    paddingRight: "70pt",
                                    textIndent: "0pt",
                                    textAlign: "center",
                                    margin: "0"
                                }}
                            >
                                Details
                            </p>
                        </td>
                        <td
                            style={{
                                width: "91pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            bgcolor="#F1F1F1"
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "18pt",
                                    textIndent: "0pt",
                                    textAlign: "left",
                                    margin: "0"
                                }}
                            >
                                Integrated tax
                            </p>
                        </td>
                        <td
                            style={{
                                width: "92pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            bgcolor="#F1F1F1"
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "23pt",
                                    textIndent: "0pt",
                                    textAlign: "left",
                                    margin: "0"
                                }}
                            >
                                Central Tax
                            </p>
                        </td>
                        <td
                            style={{
                                width: "91pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            bgcolor="#F1F1F1"
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "19pt",
                                    textIndent: "0pt",
                                    textAlign: "left",
                                    margin: "0"
                                }}
                            >
                                State/ UT Tax
                            </p>
                        </td>
                        <td
                            style={{
                                width: "92pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            bgcolor="#F1F1F1"
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "32pt",
                                    paddingRight: "34pt",
                                    textIndent: "0pt",
                                    textAlign: "center",
                                    margin: "0"
                                }}
                            >
                                Cess
                            </p>
                        </td>
                    </tr>
                    <tr style={{ height: "15pt" }}>
                        <td
                            style={{
                                width: "189pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "4pt",
                                    textIndent: "0pt",
                                    textAlign: "left",
                                    margin: "0"
                                }}
                            >
                                (A) ITC Available (whether in full or part)
                            </p>
                        </td>
                        <td
                            style={{
                                width: "91pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p style={{
                                textIndent: "0pt", textAlign: "left",
                                margin: "0"
                            }}>
                                <br />
                            </p>
                        </td>
                        <td
                            style={{
                                width: "92pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p style={{
                                textIndent: "0pt", textAlign: "left",
                                margin: "0"
                            }}>
                                <br />
                            </p>
                        </td>
                        <td
                            style={{
                                width: "91pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p style={{
                                textIndent: "0pt", textAlign: "left",
                                margin: "0"
                            }}>
                                <br />
                            </p>
                        </td>
                        <td
                            style={{
                                width: "92pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p style={{
                                textIndent: "0pt", textAlign: "left",
                                margin: "0"
                            }}>
                                <br />
                            </p>
                        </td>
                    </tr>
                    <tr style={{ height: "15pt" }}>
                        <td
                            style={{
                                width: "189pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                style={{
                                    paddingTop: "1pt",
                                    paddingBottom: "1pt",
                                    paddingLeft: "14pt",
                                    textIndent: "0pt",
                                    textAlign: "left",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                <a href="#bookmark12" className={styles.s8} >
                                    (1) Import of goods
                                </a>
                            </p>
                        </td>
                        <td
                            style={{
                                width: "91pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "5pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    margin: "0"
                                }}
                            >
                                {parseFloat(gstrf.data.a4.user.a.rowa.igst.value).toFixed(2)}
                            </p>
                        </td>
                        <td
                            style={{
                                width: "92pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            bgcolor="#BEBEBE"
                        >
                            <p style={{
                                textIndent: "0pt", textAlign: "left",
                                margin: "0"
                            }}>
                                <br />
                            </p>
                        </td>
                        <td
                            style={{
                                width: "91pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            bgcolor="#BEBEBE"
                        >
                            <p style={{
                                textIndent: "0pt", textAlign: "left",
                                margin: "0"
                            }}>
                                <br />
                            </p>
                        </td>
                        <td
                            style={{
                                width: "92pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "5pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    margin: "0"
                                }}
                            >
                                {parseFloat(gstrf.data.a4.user.a.rowa.cess.value).toFixed(2)}
                            </p>
                        </td>
                    </tr>
                    <tr style={{ height: "15pt" }}>
                        <td
                            style={{
                                width: "189pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingBottom: "1pt",
                                    paddingLeft: "14pt",
                                    textIndent: "0pt",
                                    textAlign: "left",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                (2) Import of services
                            </p>
                        </td>
                        <td
                            style={{
                                width: "91pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "5pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    margin: "0"
                                }}>
                                {parseFloat(gstrf.data.a4.user.a.rowb.igst.value).toFixed(2)}
                            </p>
                        </td>
                        <td
                            style={{
                                width: "92pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            bgcolor="#BEBEBE"
                        >
                            <p style={{
                                textIndent: "0pt", textAlign: "left",
                                margin: "0"
                            }}>
                                <br />
                            </p>
                        </td>
                        <td
                            style={{
                                width: "91pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            bgcolor="#BEBEBE"
                        >
                            <p style={{
                                textIndent: "0pt", textAlign: "left",
                                margin: "0"
                            }}>
                                <br />
                            </p>
                        </td>
                        <td
                            style={{
                                width: "92pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "5pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    margin: "0"
                                }}>
                                {parseFloat(gstrf.data.a4.user.a.rowb.cess.value).toFixed(2)}
                            </p>
                        </td>
                    </tr>
                </tbody>
            </table>
            <p style={{ pageBreakAfter: 'always' }}>&nbsp;</p>
            <div className='fixed w-[595pt] h-[800pt] flex flex-col items-center justify-center opacity-20 pointer-events-none' style={{ zIndex: 9999 }}>
                <img src={Logo} alt="ITAA" className='w-[800] mx-auto h-[227px] pointer-events-none' style={{ transform: "rotate(-45deg)" }} />
            </div>
            <table style={{ borderCollapse: "collapse", marginLeft: "20.25pt", marginTop: "8pt" }} cellSpacing={0}>
                <tbody>
                    <tr style={{ height: "15pt" }}>
                        <td
                            style={{
                                width: "189pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            bgcolor="#F2F2F2"
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "71pt",
                                    paddingRight: "70pt",
                                    textIndent: "0pt",
                                    textAlign: "center",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                Details
                            </p>
                        </td>
                        <td
                            style={{
                                width: "91pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            bgcolor="#F2F2F2"
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "18pt",
                                    textIndent: "0pt",
                                    textAlign: "left",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                Integrated tax
                            </p>
                        </td>
                        <td
                            style={{
                                width: "92pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            bgcolor="#F2F2F2"
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "23pt",
                                    textIndent: "0pt",
                                    textAlign: "left",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                Central Tax
                            </p>
                        </td>
                        <td
                            style={{
                                width: "91pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            bgcolor="#F2F2F2"
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "19pt",
                                    textIndent: "0pt",
                                    textAlign: "left",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                State/UT Tax
                            </p>
                        </td>
                        <td
                            style={{
                                width: "92pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            bgcolor="#F2F2F2"
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "34pt",
                                    paddingRight: "34pt",
                                    textIndent: "0pt",
                                    textAlign: "center",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                Cess
                            </p>
                        </td>
                    </tr>
                    <tr style={{ height: "26pt" }}>
                        <td
                            style={{
                                width: "189pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                style={{
                                    paddingTop: "1pt",
                                    paddingBottom: "1pt",
                                    paddingLeft: "14pt",
                                    textIndent: "0pt",
                                    textAlign: "left",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                <a href="#bookmark13" className={styles.s8}>
                                    (3)Inward supplies liable to reverse charge (other than 1 &amp; 2 above)
                                </a>
                            </p>
                        </td>
                        <td
                            style={{
                                width: "91pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "5pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    margin: "0"
                                }}
                            >
                                {parseFloat(gstrf.data.a4.user.a.rowc.igst.value).toFixed(2)}
                            </p>
                        </td>
                        <td
                            style={{
                                width: "92pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "5pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    margin: "0"
                                }}
                            >
                                {parseFloat(gstrf.data.a4.user.a.rowc.cgst.value).toFixed(2)}
                            </p>
                        </td>
                        <td
                            style={{
                                width: "91pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "5pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    margin: "0"
                                }}
                            >
                                {parseFloat(gstrf.data.a4.user.a.rowc.sgst.value).toFixed(2)}
                            </p>
                        </td>
                        <td
                            style={{
                                width: "92pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "5pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    margin: "0"
                                }}
                            >
                                {parseFloat(gstrf.data.a4.user.a.rowc.cess.value).toFixed(2)}
                            </p>
                        </td>
                    </tr>
                    <tr style={{ height: "15pt" }}>
                        <td
                            style={{
                                width: "189pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                style={{
                                    paddingTop: "1pt",
                                    paddingBottom: "1pt",
                                    paddingLeft: "14pt",
                                    textIndent: "0pt",
                                    textAlign: "left",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                <a href="#bookmark14" className={styles.s8}>
                                    (4) Inward supplies from ISD
                                </a>
                            </p>
                        </td>
                        <td
                            style={{
                                width: "91pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "5pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    margin: "0"
                                }}
                            >
                                {parseFloat(gstrf.data.a4.user.a.rowd.igst.value).toFixed(2)}
                            </p>
                        </td>
                        <td
                            style={{
                                width: "92pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "5pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    margin: "0"
                                }}
                            >
                                {parseFloat(gstrf.data.a4.user.a.rowd.cgst.value).toFixed(2)}
                            </p>
                        </td>
                        <td
                            style={{
                                width: "91pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "5pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    margin: "0"
                                }}
                            >
                                {parseFloat(gstrf.data.a4.user.a.rowd.sgst.value).toFixed(2)}
                            </p>
                        </td>
                        <td
                            style={{
                                width: "92pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "5pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    margin: "0"
                                }}
                            >
                                {parseFloat(gstrf.data.a4.user.a.rowd.cess.value).toFixed(2)}
                            </p>
                        </td>
                    </tr>
                    <tr style={{ height: "15pt" }}>
                        <td
                            style={{
                                width: "189pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                style={{
                                    paddingTop: "1pt",
                                    paddingBottom: "1pt",
                                    paddingLeft: "14pt",
                                    textIndent: "0pt",
                                    textAlign: "left",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                <a href="#bookmark15" className={styles.s8}>
                                    (5) All other ITC
                                </a>
                            </p>
                        </td>
                        <td
                            style={{
                                width: "91pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "7pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    margin: "0"
                                }}
                            >
                                {parseFloat(gstrf.data.a4.user.a.rowe.igst.value).toFixed(2)}
                            </p>
                        </td>
                        <td
                            style={{
                                width: "92pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "6pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    margin: "0"
                                }}
                            >
                                {parseFloat(gstrf.data.a4.user.a.rowe.cgst.value).toFixed(2)}
                            </p>
                        </td>
                        <td
                            style={{
                                width: "91pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "6pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    margin: "0"
                                }}
                            >
                                {parseFloat(gstrf.data.a4.user.a.rowe.sgst.value).toFixed(2)}
                            </p>
                        </td>
                        <td
                            style={{
                                width: "92pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "5pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    margin: "0"
                                }}
                            >
                                {parseFloat(gstrf.data.a4.user.a.rowe.cess.value).toFixed(2)}
                            </p>
                        </td>
                    </tr>
                    <tr style={{ height: "15pt" }}>
                        <td
                            style={{
                                width: "189pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingBottom: "1pt",
                                    paddingLeft: "14pt",
                                    textIndent: "0pt",
                                    textAlign: "left",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                (B) ITC Reversed
                            </p>
                        </td>
                        <td
                            style={{
                                width: "91pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p style={{
                                textIndent: "0pt", textAlign: "left",
                                margin: "0"
                            }}>
                                <br />
                            </p>
                        </td>
                        <td
                            style={{
                                width: "92pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p style={{
                                textIndent: "0pt", textAlign: "left",
                                margin: "0"
                            }}>
                                <br />
                            </p>
                        </td>
                        <td
                            style={{
                                width: "91pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p style={{
                                textIndent: "0pt", textAlign: "left",
                                margin: "0"
                            }}>
                                <br />
                            </p>
                        </td>
                        <td
                            style={{
                                width: "92pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p style={{
                                textIndent: "0pt", textAlign: "left",
                                margin: "0"
                            }}>
                                <br />
                            </p>
                        </td>
                    </tr>
                    <tr style={{ height: "26pt" }}>
                        <td
                            style={{
                                width: "189pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "14pt",
                                    textIndent: "0pt",
                                    textAlign: "left",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                (1) As per rules 38,42 &amp; 43 of CGST Rules and section 17(5)
                            </p>
                        </td>
                        <td
                            style={{
                                width: "91pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "7pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    margin: "0"
                                }}>
                                {parseFloat(gstrf.data.a4.user.b.rowa.igst.value).toFixed(2)}
                            </p>
                        </td>
                        <td
                            style={{
                                width: "92pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "7pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    margin: "0"
                                }}>
                                {parseFloat(gstrf.data.a4.user.b.rowa.cgst.value).toFixed(2)}
                            </p>
                        </td>
                        <td
                            style={{
                                width: "91pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "7pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    margin: "0"
                                }}>
                                {parseFloat(gstrf.data.a4.user.b.rowa.sgst.value).toFixed(2)}
                            </p>
                        </td>
                        <td
                            style={{
                                width: "92pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "7pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    margin: "0"
                                }}>
                                {parseFloat(gstrf.data.a4.user.b.rowa.cess.value).toFixed(2)}
                            </p>
                        </td>
                    </tr>
                    <tr style={{ height: "15pt" }}>
                        <td
                            style={{
                                width: "189pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "14pt",
                                    textIndent: "0pt",
                                    textAlign: "left",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                (2) Others
                            </p>
                        </td>
                        <td
                            style={{
                                width: "91pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p className={styles.s4} style={{
                                paddingTop: "1pt",
                                paddingRight: "3pt",
                                textIndent: "0pt",
                                textAlign: "right",
                                margin: "0"
                            }}>
                                {parseFloat(gstrf.data.a4.user.b.rowb.igst.value).toFixed(2)}
                            </p>
                        </td>
                        <td
                            style={{
                                width: "92pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p className={styles.s4} style={{
                                paddingTop: "1pt",
                                paddingRight: "3pt",
                                textIndent: "0pt",
                                textAlign: "right",
                                margin: "0"
                            }}>
                                {parseFloat(gstrf.data.a4.user.b.rowb.cgst.value).toFixed(2)}
                            </p>
                        </td>
                        <td
                            style={{
                                width: "91pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p className={styles.s4} style={{
                                paddingTop: "1pt",
                                paddingRight: "3pt",
                                textIndent: "0pt",
                                textAlign: "right",
                                margin: "0"
                            }}>
                                {parseFloat(gstrf.data.a4.user.b.rowb.sgst.value).toFixed(2)}
                            </p>
                        </td>
                        <td
                            style={{
                                width: "92pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p className={styles.s4} style={{
                                paddingTop: "1pt",
                                paddingRight: "3pt",
                                textIndent: "0pt",
                                textAlign: "right",
                                margin: "0"
                            }}>
                                {parseFloat(gstrf.data.a4.user.b.rowb.cess.value).toFixed(2)}
                            </p>
                        </td>
                    </tr>
                    <tr style={{ height: "15pt" }}>
                        <td
                            style={{
                                width: "189pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "4pt",
                                    textIndent: "0pt",
                                    textAlign: "left",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                (C) Net ITC Available (A)-(B)
                            </p>
                        </td>
                        <td
                            style={{
                                width: "91pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            bgcolor="#BFBFBF"
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    margin: "0"
                                }}
                            >
                                {parseFloat(gstrf.data.a4.user.c.igst).toFixed(2)}
                            </p>
                        </td>
                        <td
                            style={{
                                width: "92pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            bgcolor="#BFBFBF"
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    margin: "0"
                                }}
                            >
                                {parseFloat(gstrf.data.a4.user.c.cgst).toFixed(2)}
                            </p>
                        </td>
                        <td
                            style={{
                                width: "91pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            bgcolor="#BFBFBF"
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    margin: "0"
                                }}
                            >
                                {parseFloat(gstrf.data.a4.user.c.sgst).toFixed(2)}
                            </p>
                        </td>
                        <td
                            style={{
                                width: "92pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            bgcolor="#BFBFBF"
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    margin: "0"
                                }}
                            >
                                {parseFloat(gstrf.data.a4.user.c.cess).toFixed(2)}
                            </p>
                        </td>
                    </tr>
                    <tr style={{ height: "15pt" }}>
                        <td
                            style={{
                                width: "189pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "4pt",
                                    textIndent: "0pt",
                                    textAlign: "left",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                (D) Other Details
                            </p>
                        </td>
                        <td
                            style={{
                                width: "91pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p style={{
                                textIndent: "0pt", textAlign: "left",
                                margin: "0"
                            }}>
                                <br />
                            </p>
                        </td>
                        <td
                            style={{
                                width: "92pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p style={{
                                textIndent: "0pt", textAlign: "left",
                                margin: "0"
                            }}>
                                <br />
                            </p>
                        </td>
                        <td
                            style={{
                                width: "91pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p style={{
                                textIndent: "0pt", textAlign: "left",
                                margin: "0"
                            }}>
                                <br />
                            </p>
                        </td>
                        <td
                            style={{
                                width: "92pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p style={{
                                textIndent: "0pt", textAlign: "left",
                                margin: "0"
                            }}>
                                <br />
                            </p>
                        </td>
                    </tr>
                    <tr style={{ height: "26pt" }}>
                        <td
                            style={{
                                width: "189pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "14pt",
                                    textIndent: "0pt",
                                    textAlign: "left",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                (1) ITC reclaimed which was reversed under Table 4(B)(2) in earlier
                                tax period
                            </p>
                        </td>
                        <td
                            style={{
                                width: "91pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            bgcolor="#BFBFBF"
                        >
                            <p className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    margin: "0"
                                }}>
                                {parseFloat(gstrf.data.a4.user.d.rowa.igst.value).toFixed(2)}
                            </p>
                        </td>
                        <td
                            style={{
                                width: "92pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            bgcolor="#BFBFBF"
                        >
                            <p className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    margin: "0"
                                }}>
                                {parseFloat(gstrf.data.a4.user.d.rowa.cgst.value).toFixed(2)}
                            </p>
                        </td>
                        <td
                            style={{
                                width: "91pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            bgcolor="#BFBFBF"
                        >
                            <p className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    margin: "0"
                                }}>
                                {parseFloat(gstrf.data.a4.user.d.rowa.sgst.value).toFixed(2)}
                            </p>
                        </td>
                        <td
                            style={{
                                width: "92pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            bgcolor="#BFBFBF"
                        >
                            <p className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    margin: "0"
                                }}>
                                {parseFloat(gstrf.data.a4.user.d.rowa.cess.value).toFixed(2)}
                            </p>
                        </td>
                    </tr>
                    <tr style={{ height: "23pt" }}>
                        <td
                            style={{
                                width: "189pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "14pt",
                                    textIndent: "0pt",
                                    textAlign: "left",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                (2) Ineligible ITC under section 16(4) &amp; ITC restricted due to PoS rules
                            </p>
                        </td>
                        <td
                            style={{
                                width: "91pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    margin: "0"
                                }}
                            >
                                {parseFloat(gstrf.data.a4.user.d.rowb.igst.value).toFixed(2)}
                            </p>
                        </td>
                        <td
                            style={{
                                width: "92pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    margin: "0"
                                }}
                            >
                                {parseFloat(gstrf.data.a4.user.d.rowb.cgst.value).toFixed(2)}
                            </p>
                        </td>
                        <td
                            style={{
                                width: "91pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    margin: "0"
                                }}
                            >
                                {parseFloat(gstrf.data.a4.user.d.rowb.sgst.value).toFixed(2)}
                            </p>
                        </td>
                        <td
                            style={{
                                width: "92pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    margin: "0"
                                }}
                            >
                                {parseFloat(gstrf.data.a4.user.d.rowb.cess.value).toFixed(2)}
                            </p>
                        </td>
                    </tr>
                </tbody>
            </table>
            <p style={{ marginLeft: '20.25pt', textIndent: "0pt", paddingTop: "4pt", paddingBottom: "4pt", marginBottom: "0", marginLeft: "20.25pt" }}>
                <a name="bookmark0">
                    <p className={styles.s7} style={{ backgroundColor: "#FCE9D9", width: '556pt', paddingTop: '2px', paddingBottom: '2px', paddingLeft: '4px', margin: "0" }}>
                        D. Table 5.1 of FORM GSTR-3B : Interest
                    </p>
                </a>
            </p>
            <table style={{ borderCollapse: "collapse", marginLeft: "20.25pt" }} cellSpacing={0}>
                <tbody>
                    <tr style={{ height: "15pt" }}>
                        <td
                            style={{
                                width: "189pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            bgcolor="#F2F2F2"
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "71pt",
                                    paddingRight: "70pt",
                                    textIndent: "0pt",
                                    textAlign: "center",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                Description
                            </p>
                        </td>
                        <td
                            style={{
                                width: "91pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            bgcolor="#F2F2F2"
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "18pt",
                                    textIndent: "0pt",
                                    textAlign: "left",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                Integrated tax
                            </p>
                        </td>
                        <td
                            style={{
                                width: "92pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            bgcolor="#F2F2F2"
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "24pt",
                                    textIndent: "0pt",
                                    textAlign: "left",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                Central tax
                            </p>
                        </td>
                        <td
                            style={{
                                width: "91pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            bgcolor="#F2F2F2"
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "20pt",
                                    textIndent: "0pt",
                                    textAlign: "left",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                State/UT tax
                            </p>
                        </td>
                        <td
                            style={{
                                width: "92pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            bgcolor="#F2F2F2"
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "35pt",
                                    textIndent: "0pt",
                                    textAlign: "left",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                Cess
                            </p>
                        </td>
                    </tr>
                    <tr style={{ height: "15pt" }}>
                        <td
                            style={{
                                width: "189pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "14pt",
                                    textIndent: "0pt",
                                    textAlign: "left",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                <a href="#bookmark17" className={styles.s8}>
                                    Interest
                                </a>
                            </p>
                        </td>
                        <td
                            style={{
                                width: "91pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "34pt",
                                    textIndent: "0pt",
                                    textAlign: "left",
                                    margin: "0"
                                }}
                            >
                                {parseFloat(gstrf.data.a51.user.a.igst.value).toFixed(2)}
                            </p>
                        </td>
                        <td
                            style={{
                                width: "92pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "34pt",
                                    textIndent: "0pt",
                                    textAlign: "left",
                                    margin: "0"
                                }}
                            >
                                {parseFloat(gstrf.data.a51.user.a.cgst.value).toFixed(2)}
                            </p>
                        </td>
                        <td
                            style={{
                                width: "91pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "34pt",
                                    textIndent: "0pt",
                                    textAlign: "left",
                                    margin: "0"
                                }}
                            >
                                {parseFloat(gstrf.data.a51.user.a.sgst.value).toFixed(2)}
                            </p>
                        </td>
                        <td
                            style={{
                                width: "92pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "34pt",
                                    textIndent: "0pt",
                                    textAlign: "left",
                                    margin: "0"
                                }}
                            >
                                {parseFloat(gstrf.data.a51.user.a.cess.value).toFixed(2)}
                            </p>
                        </td>
                    </tr>
                </tbody>
            </table>
            <div className='bg-[#243f6d] pl-[20.25pt] py-[2pt]' style={{ marginTop: '16pt' }}>
                <p style={{
                    textIndent: "0pt",
                    lineHeight: "11pt",
                    textAlign: "left",
                    margin: '0'
                }}
                >
                    <span
                        style={{
                            color: "#FFF",
                            fontFamily: '"Trebuchet MS", sans-serif',
                            fontStyle: "normal",
                            fontWeight: "400",
                            textDecoration: "none",
                            fontSize: "9pt"
                        }}
                    >
                        Section II : Detailed breakup of values in Table 3.1 and 3.2 of FORM GSTR-3B
                    </span>
                </p>
                <p style={{
                    textIndent: "0pt",
                    lineHeight: "9pt",
                    textAlign: "left",
                    margin: '0'
                }}
                >
                    <span
                        style={{
                            color: "#FFF",
                            fontFamily: '"Trebuchet MS", sans-serif',
                            fontStyle: "italic",
                            fontWeight: "normal",
                            textDecoration: "none",
                            fontSize: "8pt"
                        }}
                    >
                        Taxpayers may use this data for reconciliation of details auto-populated in Section I above and values filed by them in their FORM GSTR-1 or reflected in FORM GSTR-2B
                    </span>
                </p>
            </div>
            <p style={{ marginLeft: '20.25pt', textIndent: "0pt", paddingTop: "4pt", paddingBottom: "4pt", marginBottom: "0", marginLeft: "20.25pt" }}>
                <div className='flex justify-between items-center' style={{ width: '556pt', backgroundColor: "#FCE9D9", paddingTop: '2px', paddingBottom: '2px', paddingLeft: '4px' }}>
                    <p className={styles.s7} style={{ margin: "0" }}>
                        A. Break up of Table 3.1 (a) of GSTR-3B
                    </p>
                    <a href="#bookmark0" className='text-xl font-medium' style={{ margin: "0", paddingRight: "6pt" }}>[TOP]</a>
                </div>
            </p>
            <table style={{ borderCollapse: "collapse", marginLeft: "20.25pt" }} cellSpacing={0}>
                <tbody>
                    <tr style={{ height: "26pt" }}>
                        <td
                            style={{
                                width: "172pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            colSpan={2}
                            bgcolor="#F2F2F2"
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "54pt",
                                    textIndent: "0pt",
                                    textAlign: "left",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                Table in GSTR-1
                            </p>
                        </td>
                        <td
                            style={{
                                width: "77pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            bgcolor="#F2F2F2"
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "27pt",
                                    paddingRight: "11pt",
                                    textIndent: "-15pt",
                                    textAlign: "left",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                Total taxable value
                            </p>
                        </td>
                        <td
                            style={{
                                width: "76pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            bgcolor="#F2F2F2"
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "10pt",
                                    textIndent: "0pt",
                                    textAlign: "left",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                Integrated tax
                            </p>
                        </td>
                        <td
                            style={{
                                width: "77pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            bgcolor="#F2F2F2"
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "15pt",
                                    textIndent: "0pt",
                                    textAlign: "left",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                Central Tax
                            </p>
                        </td>
                        <td
                            style={{
                                width: "76pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            bgcolor="#F2F2F2"
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "12pt",
                                    textIndent: "0pt",
                                    textAlign: "left",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                State/UT Tax
                            </p>
                        </td>
                        <td
                            style={{
                                width: "77pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            bgcolor="#F2F2F2"
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "27pt",
                                    paddingRight: "27pt",
                                    textIndent: "0pt",
                                    textAlign: "center",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                Cess
                            </p>
                        </td>
                    </tr>
                    <tr style={{ height: "47pt" }}>
                        <td
                            style={{
                                width: "44pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "4pt",
                                    textIndent: "0pt",
                                    textAlign: "left",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                4A
                            </p>
                        </td>
                        <td
                            style={{
                                width: "128pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "4pt",
                                    paddingRight: "5pt",
                                    textIndent: "0pt",
                                    textAlign: "left",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                Taxable outward supplies made to registered persons (other than zero
                                rated supplies)
                            </p>
                        </td>
                        <td
                            style={{
                                width: "77pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                {(parseFloat(gstr.b2bA.total.value) + parseFloat(gstr.b2bB.total.value)).toFixed(2)}
                            </p>
                        </td>
                        <td
                            style={{
                                width: "76pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                {(parseFloat(gstr.b2bA.total.taxa) + parseFloat(gstr.b2bB.total.taxa)).toFixed(2)}
                            </p>
                        </td>
                        <td
                            style={{
                                width: "77pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                {(parseFloat(gstr.b2bA.total.taxb) + parseFloat(gstr.b2bB.total.taxb)).toFixed(2)}
                            </p>
                        </td>
                        <td
                            style={{
                                width: "76pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                {(parseFloat(gstr.b2bA.total.taxb) + parseFloat(gstr.b2bB.total.taxb)).toFixed(2)}
                            </p>
                        </td>
                        <td
                            style={{
                                width: "77pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                {(parseFloat(gstr.b2bA.total.cess) + parseFloat(gstr.b2bB.total.cess)).toFixed(2)}
                            </p>
                        </td>
                    </tr>
                    <tr style={{ height: "36pt" }}>
                        <td
                            style={{
                                width: "44pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "4pt",
                                    textIndent: "0pt",
                                    textAlign: "left",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                4B
                            </p>
                        </td>
                        <td
                            style={{
                                width: "128pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "4pt",
                                    paddingRight: "5pt",
                                    textIndent: "0pt",
                                    textAlign: "left",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                Outward supplies made to registered persons attracting tax on
                                reverse charge
                            </p>
                        </td>
                        <td
                            style={{
                                width: "77pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                {parseFloat(gstr.b2bB.total.value).toFixed(2)}
                            </p>
                        </td>
                        <td
                            style={{
                                width: "76pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            bgcolor="#BFBFBF"
                        >
                            <p style={{
                                textIndent: "0pt", textAlign: "left",
                                margin: "0"
                            }}>
                                <br />
                            </p>
                        </td>
                        <td
                            style={{
                                width: "77pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            bgcolor="#BFBFBF"
                        >
                            <p style={{
                                textIndent: "0pt", textAlign: "left",
                                margin: "0"
                            }}>
                                <br />
                            </p>
                        </td>
                        <td
                            style={{
                                width: "76pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            bgcolor="#BFBFBF"
                        >
                            <p style={{
                                textIndent: "0pt", textAlign: "left",
                                margin: "0"
                            }}>
                                <br />
                            </p>
                        </td>
                        <td
                            style={{
                                width: "77pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            bgcolor="#BFBFBF"
                        >
                            <p style={{
                                textIndent: "0pt", textAlign: "left",
                                margin: "0"
                            }}>
                                <br />
                            </p>
                        </td>
                    </tr>
                    <tr style={{ height: "36pt" }}>
                        <td
                            style={{
                                width: "44pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "4pt",
                                    textIndent: "0pt",
                                    textAlign: "left",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                4C
                            </p>
                        </td>
                        <td
                            style={{
                                width: "128pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "4pt",
                                    paddingRight: "15pt",
                                    textIndent: "0pt",
                                    textAlign: "left",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                Taxable outward supplies made through e-commerce operator attracting
                                TCS
                            </p>
                        </td>
                        <td
                            style={{
                                width: "77pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    margin: "0"
                                }}
                            >
                                0.00
                            </p>
                        </td>
                        <td
                            style={{
                                width: "76pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    margin: "0"
                                }}
                            >
                                0.00
                            </p>
                        </td>
                        <td
                            style={{
                                width: "77pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    margin: "0"
                                }}
                            >
                                0.00
                            </p>
                        </td>
                        <td
                            style={{
                                width: "76pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    margin: "0"
                                }}
                            >
                                0.00
                            </p>
                        </td>
                        <td
                            style={{
                                width: "77pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    margin: "0"
                                }}
                            >
                                0.00
                            </p>
                        </td>
                    </tr>
                    <tr style={{ height: "57pt" }}>
                        <td
                            style={{
                                width: "44pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "4pt",
                                    textIndent: "0pt",
                                    textAlign: "left",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                5A
                            </p>
                        </td>
                        <td
                            style={{
                                width: "128pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "4pt",
                                    paddingRight: "5pt",
                                    textIndent: "0pt",
                                    textAlign: "left",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                Taxable outward inter-state supplies made to unregistered persons
                                (where invoice value is more than Rs. 2.5 lakhs)
                            </p>
                        </td>
                        <td
                            style={{
                                width: "77pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    margin: "0"
                                }}
                            >
                                {parseFloat(gstr.b2cl.total.value).toFixed(2)}
                            </p>
                        </td>
                        <td
                            style={{
                                width: "76pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    margin: "0"
                                }}
                            >
                                {parseFloat(gstr.b2cl.total.taxa).toFixed(2)}
                            </p>
                        </td>
                        <td
                            style={{
                                width: "77pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            bgcolor="#BFBFBF"
                        >
                            <p style={{
                                textIndent: "0pt", textAlign: "left",
                                margin: "0"
                            }}>
                                <br />
                            </p>
                        </td>
                        <td
                            style={{
                                width: "76pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            bgcolor="#BFBFBF"
                        >
                            <p style={{
                                textIndent: "0pt", textAlign: "left",
                                margin: "0"
                            }}>
                                <br />
                            </p>
                        </td>
                        <td
                            style={{
                                width: "77pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    margin: "0"
                                }}
                            >
                                {parseFloat(gstr.b2cl.total.cess).toFixed(2)}
                            </p>
                        </td>
                    </tr>
                    <tr style={{ height: "57pt" }}>
                        <td
                            style={{
                                width: "44pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "4pt",
                                    textIndent: "0pt",
                                    textAlign: "left",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                5B
                            </p>
                        </td>
                        <td
                            style={{
                                width: "128pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "4pt",
                                    paddingRight: "4pt",
                                    textIndent: "0pt",
                                    textAlign: "left",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                Taxable outward inter-state supplies made through ecommerce operator
                                attracting TCS (where invoice value is more than Rs. 2.5 lakhs)
                            </p>
                        </td>
                        <td
                            style={{
                                width: "77pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    margin: "0"
                                }}
                            >
                                0.00
                            </p>
                        </td>
                        <td
                            style={{
                                width: "76pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    margin: "0"
                                }}
                            >
                                0.00
                            </p>
                        </td>
                        <td
                            style={{
                                width: "77pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            bgcolor="#BFBFBF"
                        >
                            <p style={{
                                textIndent: "0pt", textAlign: "left",
                                margin: "0"
                            }}>
                                <br />
                            </p>
                        </td>
                        <td
                            style={{
                                width: "76pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            bgcolor="#BFBFBF"
                        >
                            <p style={{
                                textIndent: "0pt", textAlign: "left",
                                margin: "0"
                            }}>
                                <br />
                            </p>
                        </td>
                        <td
                            style={{
                                width: "77pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    margin: "0"
                                }}
                            >
                                0.00
                            </p>
                        </td>
                    </tr>
                    <tr style={{ height: "15pt" }}>
                        <td
                            style={{
                                width: "44pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "4pt",
                                    textIndent: "0pt",
                                    textAlign: "left",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                6C
                            </p>
                        </td>
                        <td
                            style={{
                                width: "128pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "4pt",
                                    textIndent: "0pt",
                                    textAlign: "left",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                Deemed exports
                            </p>
                        </td>
                        <td
                            style={{
                                width: "77pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    margin: "0"
                                }}
                            >
                                0.00
                            </p>
                        </td>
                        <td
                            style={{
                                width: "76pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    margin: "0"
                                }}
                            >
                                0.00
                            </p>
                        </td>
                        <td
                            style={{
                                width: "77pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    margin: "0"
                                }}
                            >
                                0.00
                            </p>
                        </td>
                        <td
                            style={{
                                width: "76pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    margin: "0"
                                }}
                            >
                                0.00
                            </p>
                        </td>
                        <td
                            style={{
                                width: "77pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    margin: "0"
                                }}
                            >
                                0.00
                            </p>
                        </td>
                    </tr>
                    <tr style={{ height: "36pt" }}>
                        <td
                            style={{
                                width: "44pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "4pt",
                                    textIndent: "0pt",
                                    textAlign: "left",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                7A_1
                            </p>
                        </td>
                        <td
                            style={{
                                width: "128pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "4pt",
                                    paddingRight: "5pt",
                                    textIndent: "0pt",
                                    textAlign: "left",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                Taxable outward intra-state supplies made to unregistered persons
                            </p>
                        </td>
                        <td
                            style={{
                                width: "77pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    margin: "0"
                                }}
                            >
                                {getB2CSTotal('Total', 'A')}
                            </p>
                        </td>
                        <td
                            style={{
                                width: "76pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            bgcolor="#BFBFBF"
                        >
                            <p style={{
                                textIndent: "0pt", textAlign: "left",
                                margin: "0"
                            }}>
                                <br />
                            </p>
                        </td>
                        <td
                            style={{
                                width: "77pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    margin: "0"
                                }}
                            >
                                {getB2CSTotal('cgst', 'A')}
                            </p>
                        </td>
                        <td
                            style={{
                                width: "76pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    margin: "0"
                                }}
                            >
                                {getB2CSTotal('sgst', 'A')}
                            </p>
                        </td>
                        <td
                            style={{
                                width: "77pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    margin: "0"
                                }}
                            >
                                {getB2CSTotal('cess', 'A')}
                            </p>
                        </td>
                    </tr>
                    <tr style={{ height: "57pt" }}>
                        <td
                            style={{
                                width: "44pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "4pt",
                                    textIndent: "0pt",
                                    textAlign: "left",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                7B_1
                            </p>
                        </td>
                        <td
                            style={{
                                width: "128pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "4pt",
                                    paddingRight: "12pt",
                                    textIndent: "0pt",
                                    textAlign: "left",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                Taxable outward inter-state supplies made to unregistered persons
                                (where invoice value is upto Rs. 2.5 lakhs)
                            </p>
                        </td>
                        <td
                            style={{
                                width: "77pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    margin: "0"
                                }}
                            >
                                {getB2CSTotal('Total', 'B')}
                            </p>
                        </td>
                        <td
                            style={{
                                width: "76pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    margin: "0"
                                }}
                            >
                                {getB2CSTotal('igst', 'B')}
                            </p>
                        </td>
                        <td
                            style={{
                                width: "77pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            bgcolor="#BFBFBF"
                        >
                            <p style={{
                                textIndent: "0pt", textAlign: "left",
                                margin: "0"
                            }}>
                                <br />
                            </p>
                        </td>
                        <td
                            style={{
                                width: "76pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            bgcolor="#BFBFBF"
                        >
                            <p style={{
                                textIndent: "0pt", textAlign: "left",
                                margin: "0"
                            }}>
                                <br />
                            </p>
                        </td>
                        <td
                            style={{
                                width: "77pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    margin: "0"
                                }}
                            >
                                {getB2CSTotal('cess', 'B')}
                            </p>
                        </td>
                    </tr>
                </tbody>
            </table>
            <div className='fixed w-[595pt] h-[800pt] flex flex-col items-center justify-center opacity-20 pointer-events-none' style={{ zIndex: 9999 }}>
                <img src={Logo} alt="ITAA" className='w-[800] mx-auto h-[227px] pointer-events-none' style={{ transform: "rotate(-45deg)" }} />
            </div>
            <table style={{ borderCollapse: "collapse", marginLeft: "20.25pt", marginTop: "16pt" }} cellSpacing={0}>
                <tbody>
                    <tr style={{ height: "26pt" }}>
                        <td
                            style={{
                                width: "172pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            colSpan={2}
                            bgcolor="#F2F2F2"
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "54pt",
                                    textIndent: "0pt",
                                    textAlign: "left",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                Table in GSTR-1
                            </p>
                        </td>
                        <td
                            style={{
                                width: "77pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            bgcolor="#F2F2F2"
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "27pt",
                                    paddingRight: "11pt",
                                    textIndent: "-15pt",
                                    textAlign: "left",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                Total taxable value
                            </p>
                        </td>
                        <td
                            style={{
                                width: "76pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            bgcolor="#F2F2F2"
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "10pt",
                                    textIndent: "0pt",
                                    textAlign: "left",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                Integrated tax
                            </p>
                        </td>
                        <td
                            style={{
                                width: "77pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            bgcolor="#F2F2F2"
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "15pt",
                                    textIndent: "0pt",
                                    textAlign: "left",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                Central Tax
                            </p>
                        </td>
                        <td
                            style={{
                                width: "76pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            bgcolor="#F2F2F2"
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "12pt",
                                    textIndent: "0pt",
                                    textAlign: "left",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                State/UT Tax
                            </p>
                        </td>
                        <td
                            style={{
                                width: "77pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            bgcolor="#F2F2F2"
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "27pt",
                                    paddingRight: "27pt",
                                    textIndent: "0pt",
                                    textAlign: "center",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                Cess
                            </p>
                        </td>
                    </tr>
                    <tr style={{ height: "47pt" }}>
                        <td
                            style={{
                                width: "44pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "4pt",
                                    textIndent: "0pt",
                                    textAlign: "left",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                9A
                            </p>
                        </td>
                        <td
                            style={{
                                width: "128pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "4pt",
                                    paddingRight: "5pt",
                                    textIndent: "0pt",
                                    textAlign: "left",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                Amendments made to invoices declared in previous tax periods in
                                Tables-4, 5 and 6C
                            </p>
                        </td>
                        <td
                            style={{
                                width: "77pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    margin: "0"
                                }}
                            >
                                {get9A('Total')}
                            </p>
                        </td>
                        <td
                            style={{
                                width: "76pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    margin: "0"
                                }}
                            >
                                {get9A('igst')}
                            </p>
                        </td>
                        <td
                            style={{
                                width: "77pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    margin: "0"
                                }}
                            >
                                {get9A('cgst')}
                            </p>
                        </td>
                        <td
                            style={{
                                width: "76pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    margin: "0"
                                }}
                            >
                                {get9A('sgst')}
                            </p>
                        </td>
                        <td
                            style={{
                                width: "77pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    margin: "0"
                                }}
                            >
                                {get9A('cess')}
                            </p>
                        </td>
                    </tr>
                    <tr style={{ height: "36pt" }}>
                        <td
                            style={{
                                width: "44pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "4pt",
                                    textIndent: "0pt",
                                    textAlign: "left",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                9B
                            </p>
                        </td>
                        <td
                            style={{
                                width: "128pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "4pt",
                                    paddingRight: "6pt",
                                    textIndent: "0pt",
                                    textAlign: "left",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                Credit/Debit Notes pertaining to invoices declared in Tables-4, 5
                                and 6C
                            </p>
                        </td>
                        <td
                            style={{
                                width: "77pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    margin: "0"
                                }}
                            >
                                {get9B('Total')}
                            </p>
                        </td>
                        <td
                            style={{
                                width: "76pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    margin: "0"
                                }}
                            >
                                {get9B('igst')}
                            </p>
                        </td>
                        <td
                            style={{
                                width: "77pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    margin: "0"
                                }}
                            >
                                {get9B('cgst')}
                            </p>
                        </td>
                        <td
                            style={{
                                width: "76pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    margin: "0"
                                }}
                            >
                                {get9B('sgst')}
                            </p>
                        </td>
                        <td
                            style={{
                                width: "77pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    margin: "0"
                                }}
                            >
                                {get9B('cess')}
                            </p>
                        </td>
                    </tr>
                    <tr style={{ height: "36pt" }}>
                        <td
                            style={{
                                width: "44pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "4pt",
                                    textIndent: "0pt",
                                    textAlign: "left",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                9C
                            </p>
                        </td>
                        <td
                            style={{
                                width: "128pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "4pt",
                                    textIndent: "0pt",
                                    textAlign: "left",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                Amendments to Credit/Debit Notes pertaining to invoices declared in
                                Tables-4, 5 and 6C
                            </p>
                        </td>
                        <td
                            style={{
                                width: "77pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    margin: "0"
                                }}
                            >
                                {get9C('Total')}
                            </p>
                        </td>
                        <td
                            style={{
                                width: "76pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    margin: "0"
                                }}
                            >
                                {get9C('igst')}
                            </p>
                        </td>
                        <td
                            style={{
                                width: "77pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    margin: "0"
                                }}
                            >
                                {get9C('cgst')}
                            </p>
                        </td>
                        <td
                            style={{
                                width: "76pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    margin: "0"
                                }}
                            >
                                {get9C('sgst')}
                            </p>
                        </td>
                        <td
                            style={{
                                width: "77pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    margin: "0"
                                }}
                            >
                                {get9C('cess')}
                            </p>
                        </td>
                    </tr>
                    <tr style={{ height: "57pt" }}>
                        <td
                            style={{
                                width: "44pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "4pt",
                                    textIndent: "0pt",
                                    textAlign: "left",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                10A
                            </p>
                        </td>
                        <td
                            style={{
                                width: "128pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "4pt",
                                    paddingRight: "2pt",
                                    textIndent: "0pt",
                                    textAlign: "left",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                Amendment to taxable outward intra-state supplies made to
                                unregistered persons declared in Table-7 in previous tax periods
                            </p>
                        </td>
                        <td
                            style={{
                                width: "77pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    margin: "0"
                                }}
                            >
                                {get10A('Total')}
                            </p>
                        </td>
                        <td
                            style={{
                                width: "76pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            bgcolor="#BFBFBF"
                        >
                            <p style={{
                                textIndent: "0pt", textAlign: "left",
                                margin: "0"
                            }}>
                                <br />
                            </p>
                        </td>
                        <td
                            style={{
                                width: "77pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    margin: "0"
                                }}
                            >
                                {get10A('cgst')}
                            </p>
                        </td>
                        <td
                            style={{
                                width: "76pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    margin: "0"
                                }}
                            >
                                {get10A('sgst')}
                            </p>
                        </td>
                        <td
                            style={{
                                width: "77pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    margin: "0"
                                }}
                            >
                                {get10A('cess')}
                            </p>
                        </td>
                    </tr>
                    <tr style={{ height: "57pt" }}>
                        <td
                            style={{
                                width: "44pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "4pt",
                                    textIndent: "0pt",
                                    textAlign: "left",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                10B
                            </p>
                        </td>
                        <td
                            style={{
                                width: "128pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "4pt",
                                    paddingRight: "2pt",
                                    textIndent: "0pt",
                                    textAlign: "left",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                Amendment to taxable outward inter-state supplies made to
                                unregistered persons declared in Table-7 in previous tax periods
                            </p>
                        </td>
                        <td
                            style={{
                                width: "77pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    margin: "0"
                                }}
                            >
                                {get10B('Total')}
                            </p>
                        </td>
                        <td
                            style={{
                                width: "76pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    margin: "0"
                                }}
                            >
                                {get10B('igst')}
                            </p>
                        </td>
                        <td
                            style={{
                                width: "77pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            bgcolor="#BFBFBF"
                        >
                            <p style={{
                                textIndent: "0pt", textAlign: "left",
                                margin: "0"
                            }}>
                                <br />
                            </p>
                        </td>
                        <td
                            style={{
                                width: "76pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            bgcolor="#BFBFBF"
                        >
                            <p style={{
                                textIndent: "0pt", textAlign: "left",
                                margin: "0"
                            }}>
                                <br />
                            </p>
                        </td>
                        <td
                            style={{
                                width: "77pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    margin: "0"
                                }}
                            >
                                {get10B('cess')}
                            </p>
                        </td>
                    </tr>
                    <tr style={{ height: "26pt" }}>
                        <td
                            style={{
                                width: "44pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "4pt",
                                    textIndent: "0pt",
                                    textAlign: "left",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                11_I_A1
                            </p>
                        </td>
                        <td
                            style={{
                                width: "128pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "4pt",
                                    paddingRight: "5pt",
                                    textIndent: "0pt",
                                    textAlign: "left",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                Advance amount received in the tax period - Intra-state
                            </p>
                        </td>
                        <td
                            style={{
                                width: "77pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    margin: "0"
                                }}
                            >
                                {get11A1('Total')}
                            </p>
                        </td>
                        <td
                            style={{
                                width: "76pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            bgcolor="#BFBFBF"
                        >
                            <p style={{
                                textIndent: "0pt", textAlign: "left",
                                margin: "0"
                            }}>
                                <br />
                            </p>
                        </td>
                        <td
                            style={{
                                width: "77pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    margin: "0"
                                }}
                            >
                                {get11A1('sgst')}
                            </p>
                        </td>
                        <td
                            style={{
                                width: "76pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    margin: "0"
                                }}
                            >
                                {get11A1('cgst')}
                            </p>
                        </td>
                        <td
                            style={{
                                width: "77pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    margin: "0"
                                }}
                            >
                                {get11A1('cess')}
                            </p>
                        </td>
                    </tr>
                    <tr style={{ height: "26pt" }}>
                        <td
                            style={{
                                width: "44pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "4pt",
                                    textIndent: "0pt",
                                    textAlign: "left",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                11_I_A2
                            </p>
                        </td>
                        <td
                            style={{
                                width: "128pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "4pt",
                                    paddingRight: "5pt",
                                    textIndent: "0pt",
                                    textAlign: "left",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                Advance amount received in the tax period - Inter-state
                            </p>
                        </td>
                        <td
                            style={{
                                width: "77pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    margin: "0"
                                }}
                            >
                                {get11A1('Total')}
                            </p>
                        </td>
                        <td
                            style={{
                                width: "76pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    margin: "0"
                                }}
                            >
                                {get11A1('igst')}
                            </p>
                        </td>
                        <td
                            style={{
                                width: "77pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            bgcolor="#BFBFBF"
                        >
                            <p style={{
                                textIndent: "0pt", textAlign: "left",
                                margin: "0"
                            }}>
                                <br />
                            </p>
                        </td>
                        <td
                            style={{
                                width: "76pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            bgcolor="#BFBFBF"
                        >
                            <p style={{
                                textIndent: "0pt", textAlign: "left",
                                margin: "0"
                            }}>
                                <br />
                            </p>
                        </td>
                        <td
                            style={{
                                width: "77pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    margin: "0"
                                }}
                            >
                                {get11A1('cess')}
                            </p>
                        </td>
                    </tr>
                    <tr style={{ height: "57pt" }}>
                        <td
                            style={{
                                width: "44pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "4pt",
                                    textIndent: "0pt",
                                    textAlign: "left",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                11_I_B1
                            </p>
                        </td>
                        <td
                            style={{
                                width: "128pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "4pt",
                                    paddingRight: "4pt",
                                    textIndent: "0pt",
                                    textAlign: "left",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                Advance amount received in earlier tax period and adjusted against
                                supplies being shown in this tax period
                            </p>
                            <p
                                className={styles.s4}
                                style={{ paddingLeft: "4pt", textIndent: "0pt", textAlign: "left" }}
                            >
                                - Intra-state
                            </p>
                        </td>
                        <td
                            style={{
                                width: "77pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    margin: "0"
                                }}
                            >
                                {get11B1('Total')}
                            </p>
                        </td>
                        <td
                            style={{
                                width: "76pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            bgcolor="#BFBFBF"
                        >
                            <p style={{
                                textIndent: "0pt", textAlign: "left",
                                margin: "0"
                            }}>
                                <br />
                            </p>
                        </td>
                        <td
                            style={{
                                width: "77pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    margin: "0"
                                }}
                            >
                                {get11B1('sgst')}
                            </p>
                        </td>
                        <td
                            style={{
                                width: "76pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    margin: "0"
                                }}
                            >
                                {get11B1('cgst')}
                            </p>
                        </td>
                        <td
                            style={{
                                width: "77pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    margin: "0"
                                }}
                            >
                                {get11B1('cess')}
                            </p>
                        </td>
                    </tr>
                    <tr style={{ height: "57pt" }}>
                        <td
                            style={{
                                width: "44pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "4pt",
                                    textIndent: "0pt",
                                    textAlign: "left",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                11_I_B2
                            </p>
                        </td>
                        <td
                            style={{
                                width: "128pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "4pt",
                                    paddingRight: "4pt",
                                    textIndent: "0pt",
                                    textAlign: "left",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                Advance amount received in earlier tax period and adjusted against
                                supplies being shown in this tax period - Inter-state
                            </p>
                        </td>
                        <td
                            style={{
                                width: "77pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    margin: "0"
                                }}
                            >
                                {get11B1('Total')}
                            </p>
                        </td>
                        <td
                            style={{
                                width: "76pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    margin: "0"
                                }}
                            >
                                {get11B1('igst')}
                            </p>
                        </td>
                        <td
                            style={{
                                width: "77pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            bgcolor="#BFBFBF"
                        >
                            <p style={{
                                textIndent: "0pt", textAlign: "left",
                                margin: "0"
                            }}>
                                <br />
                            </p>
                        </td>
                        <td
                            style={{
                                width: "76pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            bgcolor="#BFBFBF"
                        >
                            <p style={{
                                textIndent: "0pt", textAlign: "left",
                                margin: "0"
                            }}>
                                <br />
                            </p>
                        </td>
                        <td
                            style={{
                                width: "77pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    margin: "0"
                                }}
                            >
                                {get11B1('cess')}
                            </p>
                        </td>
                    </tr>
                    <tr style={{ height: "36pt" }}>
                        <td
                            style={{
                                width: "44pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "4pt",
                                    textIndent: "0pt",
                                    textAlign: "left",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                11_II
                            </p>
                        </td>
                        <td
                            style={{
                                width: "128pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "4pt",
                                    paddingRight: "5pt",
                                    textIndent: "0pt",
                                    textAlign: "left",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                Amendments to information regarding advances in previous tax periods
                            </p>
                        </td>
                        <td
                            style={{
                                width: "77pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    margin: "0"
                                }}
                            >
                                {get11AA1('Total')}
                            </p>
                        </td>
                        <td
                            style={{
                                width: "76pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    margin: "0"
                                }}
                            >
                                {get11AA1('igst')}
                            </p>
                        </td>
                        <td
                            style={{
                                width: "77pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    margin: "0"
                                }}
                            >
                                {get11AA1('sgst')}
                            </p>
                        </td>
                        <td
                            style={{
                                width: "76pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    margin: "0"
                                }}
                            >
                                {get11AA1('cgst')}
                            </p>
                        </td>
                        <td
                            style={{
                                width: "77pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    margin: "0"
                                }}
                            >
                                {get11AA1('cess')}
                            </p>
                        </td>
                    </tr>
                    <tr style={{ height: "15pt" }}>
                        <td
                            style={{
                                width: "44pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p style={{
                                textIndent: "0pt", textAlign: "left",
                                margin: "0"
                            }}>
                                <br />
                            </p>
                        </td>
                        <td
                            style={{
                                width: "128pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "4pt",
                                    textIndent: "0pt",
                                    textAlign: "left",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0",
                                    fontWeight: "800",
                                }}
                            >
                                Total
                            </p>
                        </td>
                        <td
                            style={{
                                width: "77pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    margin: "0"
                                }}
                            >
                                {getTotalA('Total')}
                            </p>
                        </td>
                        <td
                            style={{
                                width: "76pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    margin: "0"
                                }}
                            >
                                {getTotalA('igst')}
                            </p>
                        </td>
                        <td
                            style={{
                                width: "77pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    margin: "0"
                                }}
                            >
                                {getTotalA('sgst')}
                            </p>
                        </td>
                        <td
                            style={{
                                width: "76pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    margin: "0"
                                }}
                            >
                                {getTotalA('cgst')}
                            </p>
                        </td>
                        <td
                            style={{
                                width: "77pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    margin: "0"
                                }}
                            >
                                {getTotalA('cess')}
                            </p>
                        </td>
                    </tr>
                </tbody>
            </table>
            <p style={{ marginLeft: '20.25pt', textIndent: "0pt", paddingTop: "16pt", paddingBottom: "4pt", marginBottom: "0", marginLeft: "20.25pt" }}>
                <div className='flex justify-between items-center' style={{ width: '556pt', backgroundColor: "#FCE9D9", paddingTop: '2px', paddingBottom: '2px', paddingLeft: '4px' }}>
                    <p className={styles.s7} style={{ margin: "0" }}>
                        B. Break up of Table 3.1 (b) of GSTR-3B
                    </p>
                    <a href="#bookmark0" className='text-xl font-medium' style={{ margin: "0", paddingRight: "6pt" }}>[TOP]</a>
                </div>
            </p>
            <table style={{ borderCollapse: "collapse", marginLeft: "20.25pt" }} cellSpacing={0}>
                <tbody>
                    <tr style={{ height: "26pt" }}>
                        <td
                            style={{
                                width: "215pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            colSpan={2}
                            bgcolor="#F2F2F2"
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "71pt",
                                    paddingRight: "71pt",
                                    textIndent: "0pt",
                                    textAlign: "center",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                Table in GSTR-1
                            </p>
                        </td>
                        <td
                            style={{
                                width: "68pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            bgcolor="#F2F2F2"
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "20pt",
                                    paddingRight: "7pt",
                                    textIndent: "-15pt",
                                    textAlign: "center",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                Total taxable value
                            </p>
                        </td>
                        <td
                            style={{
                                width: "68pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            bgcolor="#F2F2F2"
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "5pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                Integrated tax
                            </p>
                        </td>
                        <td
                            style={{
                                width: "68pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            bgcolor="#F2F2F2"
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "11pt",
                                    textIndent: "0pt",
                                    textAlign: "left",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                Central Tax
                            </p>
                        </td>
                        <td
                            style={{
                                width: "68pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            bgcolor="#F2F2F2"
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "7pt",
                                    textIndent: "0pt",
                                    textAlign: "left",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                State/UT Tax
                            </p>
                        </td>
                        <td
                            style={{
                                width: "68pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            bgcolor="#F2F2F2"
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "23pt",
                                    paddingRight: "22pt",
                                    textIndent: "0pt",
                                    textAlign: "center",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                Cess
                            </p>
                        </td>
                    </tr>
                    <tr style={{ height: "15pt" }}>
                        <td
                            style={{
                                width: "33pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "4pt",
                                    textIndent: "0pt",
                                    textAlign: "left",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                6A
                            </p>
                        </td>
                        <td
                            style={{
                                width: "182pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "4pt",
                                    textIndent: "0pt",
                                    textAlign: "left",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                Exports
                            </p>
                        </td>
                        <td
                            style={{
                                width: "68pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                {gstr.a6.total.value.toFixed(2)}
                            </p>
                        </td>
                        <td
                            style={{
                                width: "68pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                {gstr.a6.total.taxa.toFixed(2)}
                            </p>
                        </td>
                        <td
                            style={{
                                width: "68pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            bgcolor="#BFBFBF"
                        >
                            <p style={{
                                textIndent: "0pt", textAlign: "left",
                                margin: "0"
                            }}>
                                <br />
                            </p>
                        </td>
                        <td
                            style={{
                                width: "68pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            bgcolor="#BFBFBF"
                        >
                            <p style={{
                                textIndent: "0pt", textAlign: "left",
                                margin: "0"
                            }}>
                                <br />
                            </p>
                        </td>
                        <td
                            style={{
                                width: "68pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                {gstr.a6.total.cess.toFixed(2)}
                            </p>
                        </td>
                    </tr>
                    <tr style={{ height: "26pt" }}>
                        <td
                            style={{
                                width: "33pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "4pt",
                                    textIndent: "0pt",
                                    textAlign: "left",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                6B
                            </p>
                        </td>
                        <td
                            style={{
                                width: "182pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "4pt",
                                    paddingRight: "36pt",
                                    textIndent: "0pt",
                                    textAlign: "left",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                Supplies made to SEZ unit or SEZ developer
                            </p>
                        </td>
                        <td
                            style={{
                                width: "68pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                0.00
                            </p>
                        </td>
                        <td
                            style={{
                                width: "68pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                0.00
                            </p>
                        </td>
                        <td
                            style={{
                                width: "68pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            bgcolor="#BFBFBF"
                        >
                            <p style={{
                                textIndent: "0pt", textAlign: "left",
                                margin: "0"
                            }}>
                                <br />
                            </p>
                        </td>
                        <td
                            style={{
                                width: "68pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            bgcolor="#BFBFBF"
                        >
                            <p style={{
                                textIndent: "0pt", textAlign: "left",
                                margin: "0"
                            }}>
                                <br />
                            </p>
                        </td>
                        <td
                            style={{
                                width: "68pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                0.00
                            </p>
                        </td>
                    </tr>
                    <tr style={{ height: "26pt" }}>
                        <td
                            style={{
                                width: "33pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "4pt",
                                    textIndent: "0pt",
                                    textAlign: "left",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                9A
                            </p>
                        </td>
                        <td
                            style={{
                                width: "182pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "4pt",
                                    textIndent: "0pt",
                                    textAlign: "left",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                Amendments made to invoices declared in previous tax periods in
                                Table-6A, 6B
                            </p>
                        </td>
                        <td
                            style={{
                                width: "68pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                {(gstr.a9.total.value - gstr.a9.ototal ? gstr.a9.ototal.value : 0).toFixed(2)}
                            </p>
                        </td>
                        <td
                            style={{
                                width: "68pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                {(gstr.a9.total.taxa - gstr.a9.ototal ? gstr.a9.ototal.taxa : 0).toFixed(2)}
                            </p>
                        </td>
                        <td
                            style={{
                                width: "68pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            bgcolor="#BFBFBF"
                        >
                            <p style={{
                                textIndent: "0pt", textAlign: "left",
                                margin: "0"
                            }}>
                                <br />
                            </p>
                        </td>
                        <td
                            style={{
                                width: "68pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            bgcolor="#BFBFBF"
                        >
                            <p style={{
                                textIndent: "0pt", textAlign: "left",
                                margin: "0"
                            }}>
                                <br />
                            </p>
                        </td>
                        <td
                            style={{
                                width: "68pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                {(gstr.a9.total.cess - gstr.a9.ototal ? gstr.a9.ototal.cess : 0).toFixed(2)}
                            </p>
                        </td>
                    </tr>
                    <tr style={{ height: "26pt" }}>
                        <td
                            style={{
                                width: "33pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "4pt",
                                    textIndent: "0pt",
                                    textAlign: "left",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                9B
                            </p>
                        </td>
                        <td
                            style={{
                                width: "182pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "4pt",
                                    textIndent: "0pt",
                                    textAlign: "left",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                Credit/Debit Notes pertaining to invoices declared in Table-6A,6B
                            </p>
                        </td>
                        <td
                            style={{
                                width: "68pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                {(gstr.cdu.wp.value + gstr.cdu.wop.value).toFixed(2)}
                            </p>
                        </td>
                        <td
                            style={{
                                width: "68pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                {(gstr.cdu.wp.taxa + gstr.cdu.wop.taxa).toFixed(2)}
                            </p>
                        </td>
                        <td
                            style={{
                                width: "68pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            bgcolor="#BFBFBF"
                        >
                            <p style={{
                                textIndent: "0pt", textAlign: "left",
                                margin: "0"
                            }}>
                                <br />
                            </p>
                        </td>
                        <td
                            style={{
                                width: "68pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            bgcolor="#BFBFBF"
                        >
                            <p style={{
                                textIndent: "0pt", textAlign: "left",
                                margin: "0"
                            }}>
                                <br />
                            </p>
                        </td>
                        <td
                            style={{
                                width: "68pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                {(gstr.cdu.wp.cess + gstr.cdu.wop.cess).toFixed(2)}
                            </p>
                        </td>
                    </tr>
                    <tr style={{ height: "36pt" }}>
                        <td
                            style={{
                                width: "33pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "4pt",
                                    textIndent: "0pt",
                                    textAlign: "left",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                9C
                            </p>
                        </td>
                        <td
                            style={{
                                width: "182pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "4pt",
                                    paddingRight: "36pt",
                                    textIndent: "0pt",
                                    textAlign: "left",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                Amendments to Credit/Debit Notes pertaining to invoices declared in
                                Table-6A,6B
                            </p>
                        </td>
                        <td
                            style={{
                                width: "68pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                {(gstr.cdua.wp.value + gstr.cdua.wop.value).toFixed(2)}
                            </p>
                        </td>
                        <td
                            style={{
                                width: "68pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                {(gstr.cdua.wp.taxa + gstr.cdua.wop.taxa).toFixed(2)}
                            </p>
                        </td>
                        <td
                            style={{
                                width: "68pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            bgcolor="#BFBFBF"
                        >
                            <p style={{
                                textIndent: "0pt", textAlign: "left",
                                margin: "0"
                            }}>
                                <br />
                            </p>
                        </td>
                        <td
                            style={{
                                width: "68pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            bgcolor="#BFBFBF"
                        >
                            <p style={{
                                textIndent: "0pt", textAlign: "left", margin: "0",
                                margin: "0"
                            }}>
                                <br />
                            </p>
                        </td>
                        <td
                            style={{
                                width: "68pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                {(gstr.cdua.wp.cess + gstr.cdua.wop.cess).toFixed(2)}
                            </p>
                        </td>
                    </tr>
                    <tr style={{ height: "15pt" }}>
                        <td
                            style={{
                                width: "33pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p style={{
                                textIndent: "0pt", textAlign: "left",
                                margin: "0"
                            }}>
                                <br />
                            </p>
                        </td>
                        <td
                            style={{
                                width: "182pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "4pt",
                                    textIndent: "0pt",
                                    textAlign: "left",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                Total
                            </p>
                        </td>
                        <td
                            style={{
                                width: "68pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                {getTotalB('Total')}
                            </p>
                        </td>
                        <td
                            style={{
                                width: "68pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                {getTotalB('igst')}
                            </p>
                        </td>
                        <td
                            style={{
                                width: "68pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            bgcolor="#BFBFBF"
                        >
                            <p style={{
                                textIndent: "0pt", textAlign: "left",
                                margin: "0"
                            }}>
                                <br />
                            </p>
                        </td>
                        <td
                            style={{
                                width: "68pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            bgcolor="#BFBFBF"
                        >
                            <p style={{
                                textIndent: "0pt", textAlign: "left",
                                margin: "0"
                            }}>
                                <br />
                            </p>
                        </td>
                        <td
                            style={{
                                width: "68pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                {getTotalB('cess')}
                            </p>
                        </td>
                    </tr>
                </tbody>
            </table>
            <p style={{ pageBreakAfter: 'always' }}>&nbsp;</p>
            <div className='fixed w-[595pt] h-[800pt] flex flex-col items-center justify-center opacity-20 pointer-events-none' style={{ zIndex: 9999 }}>
                <img src={Logo} alt="ITAA" className='w-[800] mx-auto h-[227px] pointer-events-none' style={{ transform: "rotate(-45deg)" }} />
            </div>
            <p style={{ marginLeft: '20.25pt', textIndent: "0pt", paddingTop: "16pt", paddingBottom: "4pt", marginBottom: "0", marginLeft: "20.25pt" }}>
                <div className='flex justify-between items-center' style={{ width: '556pt', backgroundColor: "#FCE9D9", paddingTop: '2px', paddingBottom: '2px', paddingLeft: '4px' }}>
                    <p className={styles.s7} style={{ margin: "0" }}>
                        C. Break up of Table 3.1 (c) of GSTR-3B
                    </p>
                    <a href="#bookmark0" className='text-xl font-medium' style={{ margin: "0", paddingRight: "6pt" }}>[TOP]</a>
                </div>
            </p>
            <table style={{ borderCollapse: "collapse", marginLeft: "20.25pt" }} cellSpacing={0}>
                <tbody>
                    <tr style={{ height: "26pt" }}>
                        <td
                            style={{
                                width: "215pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            colSpan={2}
                            bgcolor="#F2F2F2"
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "71pt",
                                    paddingRight: "71pt",
                                    textIndent: "0pt",
                                    textAlign: "center",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                Table in GSTR-1
                            </p>
                        </td>
                        <td
                            style={{
                                width: "68pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            bgcolor="#F2F2F2"
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "20pt",
                                    paddingRight: "7pt",
                                    textIndent: "-15pt",
                                    textAlign: "center",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                Total taxable value
                            </p>
                        </td>
                        <td
                            style={{
                                width: "68pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            bgcolor="#F2F2F2"
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "6pt",
                                    textIndent: "0pt",
                                    textAlign: "left",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                Integrated tax
                            </p>
                        </td>
                        <td
                            style={{
                                width: "68pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            bgcolor="#F2F2F2"
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "11pt",
                                    textIndent: "0pt",
                                    textAlign: "left",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                Central Tax
                            </p>
                        </td>
                        <td
                            style={{
                                width: "68pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            bgcolor="#F2F2F2"
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "7pt",
                                    textIndent: "0pt",
                                    textAlign: "left",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                State/UT Tax
                            </p>
                        </td>
                        <td
                            style={{
                                width: "68pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            bgcolor="#F2F2F2"
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "23pt",
                                    paddingRight: "22pt",
                                    textIndent: "0pt",
                                    textAlign: "center",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                Cess
                            </p>
                        </td>
                    </tr>
                    <tr style={{ height: "26pt" }}>
                        <td
                            style={{
                                width: "33pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "4pt",
                                    textIndent: "0pt",
                                    textAlign: "left",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                8
                            </p>
                        </td>
                        <td
                            style={{
                                width: "182pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "4pt",
                                    paddingRight: "8pt",
                                    textIndent: "0pt",
                                    textAlign: "left",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                Nil rated and exempted supplies (columns 2 and 3)
                            </p>
                        </td>
                        <td
                            style={{
                                width: "68pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                {gstrf.data.a3.user.c.total.value}
                            </p>
                        </td>
                        <td
                            style={{
                                width: "68pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            bgcolor="#BFBFBF"
                        >
                            <p style={{
                                textIndent: "0pt", textAlign: "left",
                                margin: "0"
                            }}>
                                <br />
                            </p>
                        </td>
                        <td
                            style={{
                                width: "68pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            bgcolor="#BFBFBF"
                        >
                            <p style={{
                                textIndent: "0pt", textAlign: "left",
                                margin: "0"
                            }}>
                                <br />
                            </p>
                        </td>
                        <td
                            style={{
                                width: "68pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            bgcolor="#BFBFBF"
                        >
                            <p style={{
                                textIndent: "0pt", textAlign: "left",
                                margin: "0"
                            }}>
                                <br />
                            </p>
                        </td>
                        <td
                            style={{
                                width: "68pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            bgcolor="#BFBFBF"
                        >
                            <p style={{
                                textIndent: "0pt", textAlign: "left",
                                margin: "0"
                            }}>
                                <br />
                            </p>
                        </td>
                    </tr>
                </tbody>
            </table>
            <p style={{ marginLeft: '20.25pt', textIndent: "0pt", paddingTop: "16pt", paddingBottom: "4pt", marginBottom: "0", marginLeft: "20.25pt" }}>
                <div className='flex justify-between items-center' style={{ width: '556pt', backgroundColor: "#FCE9D9", paddingTop: '2px', paddingBottom: '2px', paddingLeft: '4px' }}>
                    <p className={styles.s7} style={{ margin: "0" }}>
                        D. Break up of Table 3.1 (d) of GSTR-3B
                    </p>
                    <a href="#bookmark0" className='text-xl font-medium' style={{ margin: "0", paddingRight: "6pt" }}>[TOP]</a>
                </div>
            </p>
            <table style={{ borderCollapse: "collapse", marginLeft: "20.25pt" }} cellSpacing={0}>
                <tbody>
                    <tr style={{ height: "26pt" }}>
                        <td
                            style={{
                                width: "215pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            colSpan={2}
                            bgcolor="#F2F2F2"
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "71pt",
                                    paddingRight: "71pt",
                                    textIndent: "0pt",
                                    textAlign: "center",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                Table in GSTR-2B
                            </p>
                        </td>
                        <td
                            style={{
                                width: "68pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            bgcolor="#F2F2F2"
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "20pt",
                                    paddingRight: "7pt",
                                    textIndent: "-15pt",
                                    textAlign: "center",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                Total taxable value
                            </p>
                        </td>
                        <td
                            style={{
                                width: "68pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            bgcolor="#F2F2F2"
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "5pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                Integrated tax
                            </p>
                        </td>
                        <td
                            style={{
                                width: "68pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            bgcolor="#F2F2F2"
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "11pt",
                                    textIndent: "0pt",
                                    textAlign: "left",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                Central Tax
                            </p>
                        </td>
                        <td
                            style={{
                                width: "68pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            bgcolor="#F2F2F2"
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "7pt",
                                    textIndent: "0pt",
                                    textAlign: "left",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                State/UT Tax
                            </p>
                        </td>
                        <td
                            style={{
                                width: "68pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            bgcolor="#F2F2F2"
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "23pt",
                                    paddingRight: "22pt",
                                    textIndent: "0pt",
                                    textAlign: "center",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                Cess
                            </p>
                        </td>
                    </tr>
                    <tr style={{ height: "26pt" }}>
                        <td
                            style={{
                                width: "33pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "6pt",
                                    paddingRight: "2pt",
                                    textIndent: "-2pt",
                                    textAlign: "left",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                3_Part A_ III
                            </p>
                        </td>
                        <td
                            style={{
                                width: "182pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "4pt",
                                    textIndent: "0pt",
                                    textAlign: "left",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                ITC Available - Inward Supplies liable for reverse charge
                            </p>
                        </td>
                        <td
                            style={{
                                width: "68pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                0.00
                            </p>
                        </td>
                        <td
                            style={{
                                width: "68pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                0.00
                            </p>
                        </td>
                        <td
                            style={{
                                width: "68pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                0.00
                            </p>
                        </td>
                        <td
                            style={{
                                width: "68pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                0.00
                            </p>
                        </td>
                        <td
                            style={{
                                width: "68pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                0.00
                            </p>
                        </td>
                    </tr>
                    <tr style={{ height: "26pt" }}>
                        <td
                            style={{
                                width: "33pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "6pt",
                                    paddingRight: "2pt",
                                    textIndent: "-2pt",
                                    textAlign: "left",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                4_Part A_III
                            </p>
                        </td>
                        <td
                            style={{
                                width: "182pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "4pt",
                                    paddingRight: "8pt",
                                    textIndent: "0pt",
                                    textAlign: "left",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                ITC not available - Inward Supplies liable for reverse charge
                            </p>
                        </td>
                        <td
                            style={{
                                width: "68pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                0.00
                            </p>
                        </td>
                        <td
                            style={{
                                width: "68pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                0.00
                            </p>
                        </td>
                        <td
                            style={{
                                width: "68pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                0.00
                            </p>
                        </td>
                        <td
                            style={{
                                width: "68pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                0.00
                            </p>
                        </td>
                        <td
                            style={{
                                width: "68pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                0.00
                            </p>
                        </td>
                    </tr>
                </tbody>
            </table>
            <p style={{ marginLeft: '20.25pt', textIndent: "0pt", paddingTop: "8pt", paddingBottom: "4pt", marginBottom: "0", marginLeft: "20.25pt" }}>
                <div className='flex justify-between items-center' style={{ width: '556pt', backgroundColor: "#FCE9D9", paddingTop: '2px', paddingBottom: '2px', paddingLeft: '4px' }}>
                    <p className={styles.s7} style={{ margin: "0" }}>
                        E. Break up of Table 3.1 (e) of GSTR-3B
                    </p>
                    <a href="#bookmark0" className='text-xl font-medium' style={{ margin: "0", paddingRight: "6pt" }}>[TOP]</a>
                </div>
            </p>
            <table style={{ borderCollapse: "collapse", marginLeft: "20.25pt" }} cellSpacing={0}>
                <tbody>
                    <tr style={{ height: "26pt" }}>
                        <td
                            style={{
                                width: "215pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            colSpan={2}
                            bgcolor="#F2F2F2"
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "71pt",
                                    paddingRight: "71pt",
                                    textIndent: "0pt",
                                    textAlign: "center",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                Table in GSTR-1
                            </p>
                        </td>
                        <td
                            style={{
                                width: "68pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            bgcolor="#F2F2F2"
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "23pt",
                                    paddingRight: "7pt",
                                    textIndent: "-15pt",
                                    textAlign: "left",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                Total taxable value
                            </p>
                        </td>
                        <td
                            style={{
                                width: "68pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            bgcolor="#F2F2F2"
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "6pt",
                                    textIndent: "0pt",
                                    textAlign: "left",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                Integrated tax
                            </p>
                        </td>
                        <td
                            style={{
                                width: "68pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            bgcolor="#F2F2F2"
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "11pt",
                                    textIndent: "0pt",
                                    textAlign: "left",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                Central Tax
                            </p>
                        </td>
                        <td
                            style={{
                                width: "68pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            bgcolor="#F2F2F2"
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "7pt",
                                    textIndent: "0pt",
                                    textAlign: "left",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                State/UT Tax
                            </p>
                        </td>
                        <td
                            style={{
                                width: "68pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            bgcolor="#F2F2F2"
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "23pt",
                                    paddingRight: "22pt",
                                    textIndent: "0pt",
                                    textAlign: "center",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                Cess
                            </p>
                        </td>
                    </tr>
                    <tr style={{ height: "15pt" }}>
                        <td
                            style={{
                                width: "33pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "4pt",
                                    textIndent: "0pt",
                                    textAlign: "left",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                8
                            </p>
                        </td>
                        <td
                            style={{
                                width: "182pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "4pt",
                                    textIndent: "0pt",
                                    textAlign: "left",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                Non-GST outward supplies (column 4)
                            </p>
                        </td>
                        <td
                            style={{
                                width: "68pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                {gstrf.data.a3.user.e.total.value}
                            </p>
                        </td>
                        <td
                            style={{
                                width: "68pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            bgcolor="#BFBFBF"
                        >
                            <p style={{
                                textIndent: "0pt", textAlign: "left",
                                margin: "0"
                            }}>
                                <br />
                            </p>
                        </td>
                        <td
                            style={{
                                width: "68pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            bgcolor="#BFBFBF"
                        >
                            <p style={{
                                textIndent: "0pt", textAlign: "left",
                                margin: "0"
                            }}>
                                <br />
                            </p>
                        </td>
                        <td
                            style={{
                                width: "68pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            bgcolor="#BFBFBF"
                        >
                            <p style={{
                                textIndent: "0pt", textAlign: "left",
                                margin: "0"
                            }}>
                                <br />
                            </p>
                        </td>
                        <td
                            style={{
                                width: "68pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            bgcolor="#BFBFBF"
                        >
                            <p style={{
                                textIndent: "0pt", textAlign: "left",
                                margin: "0"
                            }}>
                                <br />
                            </p>
                        </td>
                    </tr>
                </tbody>
            </table>
            <p style={{ marginLeft: '20.25pt', textIndent: "0pt", paddingTop: "8pt", paddingBottom: "4pt", marginBottom: "0", marginLeft: "20.25pt" }}>
                <div className='flex justify-between items-center' style={{ width: '556pt', backgroundColor: "#FCE9D9", paddingTop: '2px', paddingBottom: '2px', paddingLeft: '4px' }}>
                    <p className={styles.s7} style={{ margin: "0" }}>
                        F. Break up of Table 3.2 (Supplies made to Unregistered persons)
                    </p>
                    <a href="#bookmark0" className='text-xl font-medium' style={{ margin: "0", paddingRight: "6pt" }}>[TOP]</a>
                </div>
            </p>
            <table style={{ borderCollapse: "collapse", marginLeft: "20.25pt" }} cellSpacing={0}>
                <tbody>
                    <tr style={{ height: "15pt" }}>
                        <td
                            style={{
                                width: "172pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            colSpan={2}
                            bgcolor="#F2F2F2"
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "54pt",
                                    textIndent: "0pt",
                                    textAlign: "left",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                Table in GSTR-1
                            </p>
                        </td>
                        <td
                            style={{
                                width: "128pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            bgcolor="#F2F2F2"
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "11pt",
                                    textIndent: "0pt",
                                    textAlign: "left",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                Place of supply (State/UT)
                            </p>
                        </td>
                        <td
                            style={{
                                width: "127pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            bgcolor="#F2F2F2"
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "26pt",
                                    textIndent: "0pt",
                                    textAlign: "left",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                Total taxable value
                            </p>
                        </td>
                        <td
                            style={{
                                width: "128pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            bgcolor="#F2F2F2"
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "13pt",
                                    textIndent: "0pt",
                                    textAlign: "left",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                Amount of Integrated tax
                            </p>
                        </td>
                    </tr>
                    <tr style={{ height: "57pt" }}>
                        <td
                            style={{
                                width: "44pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "4pt",
                                    textIndent: "0pt",
                                    textAlign: "left",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                5A
                            </p>
                        </td>
                        <td
                            style={{
                                width: "128pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "4pt",
                                    paddingRight: "5pt",
                                    textIndent: "0pt",
                                    textAlign: "left",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                Taxable outward inter-state supplies made to unregistered persons
                                (where invoice value is more than Rs. 2.5 lakhs)
                            </p>
                        </td>
                        <td
                            style={{
                                width: "128pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p style={{
                                textIndent: "0pt", textAlign: "left",
                                margin: "0"
                            }}>
                                <br />
                            </p>
                        </td>
                        <td
                            style={{
                                width: "127pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                {getF5A('total')}
                            </p>
                        </td>
                        <td
                            style={{
                                width: "128pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                {getF5A('igst')}
                            </p>
                        </td>
                    </tr>
                    <tr style={{ height: "57pt" }}>
                        <td
                            style={{
                                width: "44pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "4pt",
                                    textIndent: "0pt",
                                    textAlign: "left",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                7B_1
                            </p>
                        </td>
                        <td
                            style={{
                                width: "128pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "4pt",
                                    paddingRight: "12pt",
                                    textIndent: "0pt",
                                    textAlign: "left",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                Taxable outward inter-state supplies made to unregistered persons
                                (where invoice value is upto Rs. 2.5 lakhs)
                            </p>
                        </td>
                        <td
                            style={{
                                width: "128pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p style={{
                                textIndent: "0pt", textAlign: "left",
                                margin: "0"
                            }}>
                                <br />
                            </p>
                        </td>
                        <td
                            style={{
                                width: "127pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                {getB2CSTotal('Total', 'B')}
                            </p>
                        </td>
                        <td
                            style={{
                                width: "128pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                {getB2CSTotal('igst', 'B')}
                            </p>
                        </td>
                    </tr>
                    <tr style={{ height: "36pt" }}>
                        <td
                            style={{
                                width: "44pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "4pt",
                                    textIndent: "0pt",
                                    textAlign: "left",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                9A
                            </p>
                        </td>
                        <td
                            style={{
                                width: "128pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "4pt",
                                    paddingRight: "6pt",
                                    textIndent: "0pt",
                                    textAlign: "left",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                Amendments made to invoices declared in previous tax periods in
                                Table-5A
                            </p>
                        </td>
                        <td
                            style={{
                                width: "128pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p style={{
                                textIndent: "0pt", textAlign: "left",
                                margin: "0"
                            }}>
                                <br />
                            </p>
                        </td>
                        <td
                            style={{
                                width: "127pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                {getF9A('total')}
                            </p>
                        </td>
                        <td
                            style={{
                                width: "128pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                {getF9A('igst')}
                            </p>
                        </td>
                    </tr>
                    <tr style={{ height: "36pt" }}>
                        <td
                            style={{
                                width: "44pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "4pt",
                                    textIndent: "0pt",
                                    textAlign: "left",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                9B
                            </p>
                        </td>
                        <td
                            style={{
                                width: "128pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "4pt",
                                    paddingRight: "5pt",
                                    textIndent: "0pt",
                                    textAlign: "left",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                Credit/Debit Notes pertaining to invoices declared in
                            </p>
                            <p
                                className={styles.s4}
                                style={{ paddingLeft: "4pt", textIndent: "0pt", textAlign: "left" }}
                            >
                                Table-5A
                            </p>
                        </td>
                        <td
                            style={{
                                width: "128pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p style={{
                                textIndent: "0pt", textAlign: "left",
                                margin: "0"
                            }}>
                                <br />
                            </p>
                        </td>
                        <td
                            style={{
                                width: "127pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                {getU9B('Total')}
                            </p>
                        </td>
                        <td
                            style={{
                                width: "128pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                {getU9B('igst')}
                            </p>
                        </td>
                    </tr>
                    <tr style={{ height: "47pt" }}>
                        <td
                            style={{
                                width: "44pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "4pt",
                                    textIndent: "0pt",
                                    textAlign: "left",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                9C
                            </p>
                        </td>
                        <td
                            style={{
                                width: "128pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "4pt",
                                    paddingRight: "9pt",
                                    textIndent: "0pt",
                                    textAlign: "left",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                Amendments to Credit/Debit Notes pertaining to Credit/ Debit notes
                                declared in Table-9B
                            </p>
                        </td>
                        <td
                            style={{
                                width: "128pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p style={{
                                textIndent: "0pt", textAlign: "left",
                                margin: "0"
                            }}>
                                <br />
                            </p>
                        </td>
                        <td
                            style={{
                                width: "127pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                {getU9C('Total')}
                            </p>
                        </td>
                        <td
                            style={{
                                width: "128pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                {getU9C('igst')}
                            </p>
                        </td>
                    </tr>
                    <tr style={{ height: "57pt" }}>
                        <td
                            style={{
                                width: "44pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "4pt",
                                    textIndent: "0pt",
                                    textAlign: "left",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                10B
                            </p>
                        </td>
                        <td
                            style={{
                                width: "128pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "4pt",
                                    paddingRight: "4pt",
                                    textIndent: "0pt",
                                    textAlign: "left",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                Amendment to taxable outward inter-state supplies made to
                                un-registered persons declared in Table-7 in previous tax periods
                            </p>
                        </td>
                        <td
                            style={{
                                width: "128pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p style={{
                                textIndent: "0pt", textAlign: "left",
                                margin: "0"
                            }}>
                                <br />
                            </p>
                        </td>
                        <td
                            style={{
                                width: "127pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                {get10B('Total')}
                            </p>
                        </td>
                        <td
                            style={{
                                width: "128pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                {get10B('igst')}
                            </p>
                        </td>
                    </tr>
                    <tr style={{ height: "26pt" }}>
                        <td
                            style={{
                                width: "44pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "4pt",
                                    textIndent: "0pt",
                                    textAlign: "left",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                11_I_A2
                            </p>
                        </td>
                        <td
                            style={{
                                width: "128pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "4pt",
                                    paddingRight: "5pt",
                                    textIndent: "0pt",
                                    textAlign: "left",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                Advance amount received in the tax period - Inter-state
                            </p>
                        </td>
                        <td
                            style={{
                                width: "128pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p style={{
                                textIndent: "0pt", textAlign: "left",
                                margin: "0"
                            }}>
                                <br />
                            </p>
                        </td>
                        <td
                            style={{
                                width: "127pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                {get11A1('Total')}
                            </p>
                        </td>
                        <td
                            style={{
                                width: "128pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                {get11A1('igst')}
                            </p>
                        </td>
                    </tr>
                    <tr style={{ height: "57pt" }}>
                        <td
                            style={{
                                width: "44pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "4pt",
                                    textIndent: "0pt",
                                    textAlign: "left",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                11_I_B2
                            </p>
                        </td>
                        <td
                            style={{
                                width: "128pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "4pt",
                                    paddingRight: "4pt",
                                    textIndent: "0pt",
                                    textAlign: "left",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                Advance amount received in earlier tax period and adjusted against
                                supplies being shown in this tax period - Inter-state
                            </p>
                        </td>
                        <td
                            style={{
                                width: "128pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p style={{
                                textIndent: "0pt", textAlign: "left",
                                margin: "0"
                            }}>
                                <br />
                            </p>
                        </td>
                        <td
                            style={{
                                width: "127pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                {get11B1('Total')}
                            </p>
                        </td>
                        <td
                            style={{
                                width: "128pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                {get11B1('igst')}
                            </p>
                        </td>
                    </tr>
                </tbody>
            </table>
            <p style={{ pageBreakAfter: 'always' }}>&nbsp;</p>
            <div className='fixed w-[595pt] h-[800pt] flex flex-col items-center justify-center opacity-20 pointer-events-none' style={{ zIndex: 9999 }}>
                <img src={Logo} alt="ITAA" className='w-[800] mx-auto h-[227px] pointer-events-none' style={{ transform: "rotate(-45deg)" }} />
            </div>
            <table style={{ borderCollapse: "collapse", marginLeft: "20.25pt" }} cellSpacing={0}>
                <tbody>
                    <tr style={{ height: "15pt" }}>
                        <td
                            style={{
                                width: "172pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            colSpan={2}
                            bgcolor="#F2F2F2"
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "54pt",
                                    textIndent: "0pt",
                                    textAlign: "left",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                Table in GSTR-1
                            </p>
                        </td>
                        <td
                            style={{
                                width: "128pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            bgcolor="#F2F2F2"
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "11pt",
                                    textIndent: "0pt",
                                    textAlign: "left",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                Place of supply (State/UT)
                            </p>
                        </td>
                        <td
                            style={{
                                width: "127pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            bgcolor="#F2F2F2"
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "26pt",
                                    textIndent: "0pt",
                                    textAlign: "left",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                Total taxable value
                            </p>
                        </td>
                        <td
                            style={{
                                width: "128pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            bgcolor="#F2F2F2"
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "13pt",
                                    textIndent: "0pt",
                                    textAlign: "left",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                Amount of Integrated tax
                            </p>
                        </td>
                    </tr>
                    <tr style={{ height: "68pt" }}>
                        <td
                            style={{
                                width: "44pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "4pt",
                                    textIndent: "0pt",
                                    textAlign: "left",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                11_II
                            </p>
                        </td>
                        <td
                            style={{
                                width: "128pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "4pt",
                                    paddingRight: "5pt",
                                    textIndent: "0pt",
                                    textAlign: "left",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                Amendments to information regarding advances in previous tax periods
                                and amendments to information regarding adjustment against supplies
                            </p>
                        </td>
                        <td
                            style={{
                                width: "128pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p style={{
                                textIndent: "0pt", textAlign: "left",
                                margin: "0"
                            }}>
                                <br />
                            </p>
                        </td>
                        <td
                            style={{
                                width: "127pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                {get11AA1('Total')}
                            </p>
                        </td>
                        <td
                            style={{
                                width: "128pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                {get11AA1('igst')}
                            </p>
                        </td>
                    </tr>
                </tbody>
            </table>
            <p style={{ marginLeft: '20.25pt', textIndent: "0pt", paddingTop: "8pt", paddingBottom: "4pt", marginBottom: "0", marginLeft: "20.25pt" }}>
                <div className='flex justify-between items-center' style={{ width: '556pt', backgroundColor: "#FCE9D9", paddingTop: '2px', paddingBottom: '2px', paddingLeft: '4px' }}>
                    <p className={styles.s7} style={{ margin: "0" }}>
                        G. Break up of Table 3.2 (Supplies made to Composition taxable persons)
                    </p>
                    <a href="#bookmark0" className='text-xl font-medium' style={{ margin: "0", paddingRight: "6pt" }}>[TOP]</a>
                </div>
            </p>
            <table style={{ borderCollapse: "collapse", marginLeft: "20.25pt" }} cellSpacing={0}>
                <tbody>
                    <tr style={{ height: "15pt" }}>
                        <td
                            style={{
                                width: "172pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            colSpan={2}
                            bgcolor="#F2F2F2"
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "54pt",
                                    textIndent: "0pt",
                                    textAlign: "left",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                Table in GSTR-1
                            </p>
                        </td>
                        <td
                            style={{
                                width: "128pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            bgcolor="#F2F2F2"
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "11pt",
                                    textIndent: "0pt",
                                    textAlign: "left",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                Place of supply (State/UT)
                            </p>
                        </td>
                        <td
                            style={{
                                width: "127pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            bgcolor="#F2F2F2"
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "26pt",
                                    textIndent: "0pt",
                                    textAlign: "left",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                Total taxable value
                            </p>
                        </td>
                        <td
                            style={{
                                width: "128pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            bgcolor="#F2F2F2"
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "13pt",
                                    textIndent: "0pt",
                                    textAlign: "left",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                Amount of Integrated tax
                            </p>
                        </td>
                    </tr>
                    <tr style={{ height: "47pt" }}>
                        <td
                            style={{
                                width: "44pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "4pt",
                                    textIndent: "0pt",
                                    textAlign: "left",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                4A
                            </p>
                        </td>
                        <td
                            style={{
                                width: "128pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "4pt",
                                    paddingRight: "5pt",
                                    textIndent: "0pt",
                                    textAlign: "left",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                Taxable outward supplies made to registered persons (other than zero
                                rated supplies)
                            </p>
                        </td>
                        <td
                            style={{
                                width: "128pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p style={{
                                textIndent: "0pt", textAlign: "left",
                                margin: "0"
                            }}>
                                <br />
                            </p>
                        </td>
                        <td
                            style={{
                                width: "127pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                0.00
                            </p>
                        </td>
                        <td
                            style={{
                                width: "128pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                0.00
                            </p>
                        </td>
                    </tr>
                    <tr style={{ height: "36pt" }}>
                        <td
                            style={{
                                width: "44pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "4pt",
                                    textIndent: "0pt",
                                    textAlign: "left",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                4C
                            </p>
                        </td>
                        <td
                            style={{
                                width: "128pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "4pt",
                                    paddingRight: "15pt",
                                    textIndent: "0pt",
                                    textAlign: "left",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                Taxable outward supplies made through e-commerce operator attracting
                                TCS
                            </p>
                        </td>
                        <td
                            style={{
                                width: "128pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p style={{
                                textIndent: "0pt", textAlign: "left",
                                margin: "0"
                            }}>
                                <br />
                            </p>
                        </td>
                        <td
                            style={{
                                width: "127pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                0.00
                            </p>
                        </td>
                        <td
                            style={{
                                width: "128pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                0.00
                            </p>
                        </td>
                    </tr>
                    <tr style={{ height: "36pt" }}>
                        <td
                            style={{
                                width: "44pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "4pt",
                                    textIndent: "0pt",
                                    textAlign: "left",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                9A
                            </p>
                        </td>
                        <td
                            style={{
                                width: "128pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "4pt",
                                    paddingRight: "6pt",
                                    textIndent: "0pt",
                                    textAlign: "left",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                Amendments made to invoices declared in previous tax periods in
                                Table-4A, 4C
                            </p>
                        </td>
                        <td
                            style={{
                                width: "128pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p style={{
                                textIndent: "0pt", textAlign: "left",
                                margin: "0"
                            }}>
                                <br />
                            </p>
                        </td>
                        <td
                            style={{
                                width: "127pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                0.00
                            </p>
                        </td>
                        <td
                            style={{
                                width: "128pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                0.00
                            </p>
                        </td>
                    </tr>
                    <tr style={{ height: "36pt" }}>
                        <td
                            style={{
                                width: "44pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "4pt",
                                    textIndent: "0pt",
                                    textAlign: "left",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                9B
                            </p>
                        </td>
                        <td
                            style={{
                                width: "128pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "4pt",
                                    paddingRight: "5pt",
                                    textIndent: "0pt",
                                    textAlign: "left",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                Credit/Debit Notes pertaining to invoices declared in
                            </p>
                            <p
                                className={styles.s4}
                                style={{ paddingLeft: "4pt", textIndent: "0pt", textAlign: "left" }}
                            >
                                Table-4A,4C
                            </p>
                        </td>
                        <td
                            style={{
                                width: "128pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p style={{
                                textIndent: "0pt", textAlign: "left",
                                margin: "0"
                            }}>
                                <br />
                            </p>
                        </td>
                        <td
                            style={{
                                width: "127pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                0.00
                            </p>
                        </td>
                        <td
                            style={{
                                width: "128pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                0.00
                            </p>
                        </td>
                    </tr>
                    <tr style={{ height: "36pt" }}>
                        <td
                            style={{
                                width: "44pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "4pt",
                                    textIndent: "0pt",
                                    textAlign: "left",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                9C
                            </p>
                        </td>
                        <td
                            style={{
                                width: "128pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "4pt",
                                    paddingRight: "5pt",
                                    textIndent: "0pt",
                                    textAlign: "left",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                Amendments to Credit/Debit Notes pertaining to invoices declared in
                                Table-9B
                            </p>
                        </td>
                        <td
                            style={{
                                width: "128pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p style={{
                                textIndent: "0pt", textAlign: "left",
                                margin: "0"
                            }}>
                                <br />
                            </p>
                        </td>
                        <td
                            style={{
                                width: "127pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                0.00
                            </p>
                        </td>
                        <td
                            style={{
                                width: "128pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                0.00
                            </p>
                        </td>
                    </tr>
                </tbody>
            </table>
            <p style={{ marginLeft: '20.25pt', textIndent: "0pt", paddingTop: "8pt", paddingBottom: "4pt", marginBottom: "0", marginLeft: "20.25pt" }}>
                <div className='flex justify-between items-center' style={{ width: '556pt', backgroundColor: "#FCE9D9", paddingTop: '2px', paddingBottom: '2px', paddingLeft: '4px' }}>
                    <p className={styles.s7} style={{ margin: "0" }}>
                        H. Break up of Table 3.2 (Supplies made to UIN holders)
                    </p>
                    <a href="#bookmark0" className='text-xl font-medium' style={{ margin: "0", paddingRight: "6pt" }}>[TOP]</a>
                </div>
            </p>
            <table style={{ borderCollapse: "collapse", marginLeft: "20.25pt" }} cellSpacing={0}>
                <tbody>
                    <tr style={{ height: "15pt" }}>
                        <td
                            style={{
                                width: "172pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            colSpan={2}
                            bgcolor="#F2F2F2"
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "54pt",
                                    textIndent: "0pt",
                                    textAlign: "left",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                Table in GSTR-1
                            </p>
                        </td>
                        <td
                            style={{
                                width: "128pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            bgcolor="#F2F2F2"
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "11pt",
                                    textIndent: "0pt",
                                    textAlign: "left",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                Place of supply (State/UT)
                            </p>
                        </td>
                        <td
                            style={{
                                width: "127pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            bgcolor="#F2F2F2"
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "26pt",
                                    textIndent: "0pt",
                                    textAlign: "left",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                Total taxable value
                            </p>
                        </td>
                        <td
                            style={{
                                width: "128pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            bgcolor="#F2F2F2"
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "13pt",
                                    textIndent: "0pt",
                                    textAlign: "left",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                Amount of Integrated tax
                            </p>
                        </td>
                    </tr>
                    <tr style={{ height: "47pt" }}>
                        <td
                            style={{
                                width: "44pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "4pt",
                                    textIndent: "0pt",
                                    textAlign: "left",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                4A
                            </p>
                        </td>
                        <td
                            style={{
                                width: "128pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "4pt",
                                    paddingRight: "5pt",
                                    textIndent: "0pt",
                                    textAlign: "left",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                Taxable outward supplies made to registered persons (other than zero
                                rated supplies)
                            </p>
                        </td>
                        <td
                            style={{
                                width: "128pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p style={{
                                textIndent: "0pt", textAlign: "left",
                                margin: "0"
                            }}>
                                <br />
                            </p>
                        </td>
                        <td
                            style={{
                                width: "127pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                0.00
                            </p>
                        </td>
                        <td
                            style={{
                                width: "128pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                0.00
                            </p>
                        </td>
                    </tr>
                    <tr style={{ height: "36pt" }}>
                        <td
                            style={{
                                width: "44pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "4pt",
                                    textIndent: "0pt",
                                    textAlign: "left",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                4C
                            </p>
                        </td>
                        <td
                            style={{
                                width: "128pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "4pt",
                                    paddingRight: "15pt",
                                    textIndent: "0pt",
                                    textAlign: "left",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                Taxable outward supplies made through e-commerce operator attracting
                                TCS
                            </p>
                        </td>
                        <td
                            style={{
                                width: "128pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p style={{
                                textIndent: "0pt", textAlign: "left",
                                margin: "0"
                            }}>
                                <br />
                            </p>
                        </td>
                        <td
                            style={{
                                width: "127pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                0.00
                            </p>
                        </td>
                        <td
                            style={{
                                width: "128pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                0.00
                            </p>
                        </td>
                    </tr>
                    <tr style={{ height: "36pt" }}>
                        <td
                            style={{
                                width: "44pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "4pt",
                                    textIndent: "0pt",
                                    textAlign: "left",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                9A
                            </p>
                        </td>
                        <td
                            style={{
                                width: "128pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "4pt",
                                    paddingRight: "6pt",
                                    textIndent: "0pt",
                                    textAlign: "left",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                Amendments made to invoices declared in previous tax periods in
                                Table-4A, 4C
                            </p>
                        </td>
                        <td
                            style={{
                                width: "128pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p style={{
                                textIndent: "0pt", textAlign: "left",
                                margin: "0"
                            }}>
                                <br />
                            </p>
                        </td>
                        <td
                            style={{
                                width: "127pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                0.00
                            </p>
                        </td>
                        <td
                            style={{
                                width: "128pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                0.00
                            </p>
                        </td>
                    </tr>
                    <tr style={{ height: "36pt" }}>
                        <td
                            style={{
                                width: "44pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "4pt",
                                    textIndent: "0pt",
                                    textAlign: "left",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                9B
                            </p>
                        </td>
                        <td
                            style={{
                                width: "128pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "4pt",
                                    paddingRight: "5pt",
                                    textIndent: "0pt",
                                    textAlign: "left",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                Credit/Debit Notes pertaining to invoices declared in
                            </p>
                            <p
                                className={styles.s4}
                                style={{ paddingLeft: "4pt", textIndent: "0pt", textAlign: "left" }}
                            >
                                Table-4A,4C
                            </p>
                        </td>
                        <td
                            style={{
                                width: "128pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p style={{
                                textIndent: "0pt", textAlign: "left",
                                margin: "0"
                            }}>
                                <br />
                            </p>
                        </td>
                        <td
                            style={{
                                width: "127pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                0.00
                            </p>
                        </td>
                        <td
                            style={{
                                width: "128pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                0.00
                            </p>
                        </td>
                    </tr>
                    <tr style={{ height: "36pt" }}>
                        <td
                            style={{
                                width: "44pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "4pt",
                                    textIndent: "0pt",
                                    textAlign: "left",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                9C
                            </p>
                        </td>
                        <td
                            style={{
                                width: "128pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "4pt",
                                    paddingRight: "5pt",
                                    textIndent: "0pt",
                                    textAlign: "left",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                Amendments to Credit/Debit Notes pertaining to invoices declared in
                                Table-9B
                            </p>
                        </td>
                        <td
                            style={{
                                width: "128pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p style={{
                                textIndent: "0pt", textAlign: "left",
                                margin: "0"
                            }}>
                                <br />
                            </p>
                        </td>
                        <td
                            style={{
                                width: "127pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                0.00
                            </p>
                        </td>
                        <td
                            style={{
                                width: "128pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                0.00
                            </p>
                        </td>
                    </tr>
                </tbody>
            </table>
            <div className='bg-[#243f6d] pl-[20.25pt] py-[2pt]' style={{ marginTop: '16pt' }}>
                <p style={{
                    textIndent: "0pt",
                    lineHeight: "11pt",
                    textAlign: "left",
                    margin: '0'
                }}
                >
                    <span
                        style={{
                            color: "#FFF",
                            fontFamily: '"Trebuchet MS", sans-serif',
                            fontStyle: "normal",
                            fontWeight: "400",
                            textDecoration: "none",
                            fontSize: "9pt"
                        }}
                    >
                        Section III : Detailed breakup of values in Table 4 of FORM GSTR-3B
                    </span>
                </p>
                <p style={{
                    textIndent: "0pt",
                    lineHeight: "9pt",
                    textAlign: "left",
                    margin: '0'
                }}
                >
                    <span
                        style={{
                            color: "#FFF",
                            fontFamily: '"Trebuchet MS", sans-serif',
                            fontStyle: "italic",
                            fontWeight: "normal",
                            textDecoration: "none",
                            fontSize: "8pt"
                        }}
                    >
                        Taxpayers may use this data for reconciliation of details auto-populated in Section I above and values refected in their FORM GSTR-2B
                    </span>
                </p>
            </div>
            <p style={{ marginLeft: '20.25pt', textIndent: "0pt", paddingTop: "8pt", paddingBottom: "4pt", marginBottom: "0", marginLeft: "20.25pt" }}>
                <div className='flex justify-between items-center' style={{ width: '556pt', backgroundColor: "#FCE9D9", paddingTop: '2px', paddingBottom: '2px', paddingLeft: '4px' }}>
                    <p className={styles.s7} style={{ margin: "0" }}>
                        A. Break up of Table 4A(1) (ITC Available - Import of goods)
                    </p>
                    <a href="#bookmark2" className='text-xl font-medium' style={{ margin: "0", paddingRight: "6pt" }}>[TOP]</a>
                </div>
            </p>
            <table style={{ borderCollapse: "collapse", marginLeft: "20.25pt" }} cellSpacing={0}>
                <tbody>
                    <tr style={{ height: "15pt" }}>
                        <td
                            style={{
                                width: "279pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            colSpan={2}
                            bgcolor="#F2F2F2"
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "103pt",
                                    paddingRight: "103pt",
                                    textIndent: "0pt",
                                    textAlign: "center",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                Table in GSTR-2B
                            </p>
                        </td>
                        <td
                            style={{
                                width: "69pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            bgcolor="#F2F2F2"
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "6pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                Integrated tax
                            </p>
                        </td>
                        <td
                            style={{
                                width: "69pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            bgcolor="#F2F2F2"
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "12pt",
                                    textIndent: "0pt",
                                    textAlign: "left",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                Central tax
                            </p>
                        </td>
                        <td
                            style={{
                                width: "69pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            bgcolor="#F2F2F2"
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "9pt",
                                    textIndent: "0pt",
                                    textAlign: "left",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                State/UT tax
                            </p>
                        </td>
                        <td
                            style={{
                                width: "69pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            bgcolor="#F2F2F2"
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "23pt",
                                    paddingRight: "23pt",
                                    textIndent: "0pt",
                                    textAlign: "center",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                Cess
                            </p>
                        </td>
                    </tr>
                    <tr style={{ height: "15pt" }}>
                        <td
                            style={{
                                width: "59pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "4pt",
                                    textIndent: "0pt",
                                    textAlign: "left",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                3_Part A_IV
                            </p>
                        </td>
                        <td
                            style={{
                                width: "220pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "4pt",
                                    textIndent: "0pt",
                                    textAlign: "left",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                ITC Available - Import of Goods
                            </p>
                        </td>
                        <td
                            style={{
                                width: "69pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                {getITCSummery('partA', 'impgTotal').toFixed(2)}
                            </p>
                        </td>
                        <td
                            style={{
                                width: "69pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            bgcolor="#BFBFBF"
                        >
                            <p style={{
                                textIndent: "0pt", textAlign: "left",
                                margin: "0"
                            }}>
                                <br />
                            </p>
                        </td>
                        <td
                            style={{
                                width: "69pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            bgcolor="#BFBFBF"
                        >
                            <p style={{
                                textIndent: "0pt", textAlign: "left",
                                margin: "0"
                            }}>
                                <br />
                            </p>
                        </td>
                        <td
                            style={{
                                width: "69pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                {getITCSummery('partA', 'impgCess').toFixed(2)}
                            </p>
                        </td>
                    </tr>
                </tbody>
            </table>
            <p style={{ pageBreakAfter: 'always' }}>&nbsp;</p>
            <div className='fixed w-[595pt] h-[800pt] flex flex-col items-center justify-center opacity-20 pointer-events-none' style={{ zIndex: 9999 }}>
                <img src={Logo} alt="ITAA" className='w-[800] mx-auto h-[227px] pointer-events-none' style={{ transform: "rotate(-45deg)" }} />
            </div>
            <p style={{ marginLeft: '20.25pt', textIndent: "0pt", paddingTop: "8pt", paddingBottom: "4pt", marginBottom: "0", marginLeft: "20.25pt" }}>
                <div className='flex justify-between items-center' style={{ width: '556pt', backgroundColor: "#FCE9D9", paddingTop: '2px', paddingBottom: '2px', paddingLeft: '4px' }}>
                    <p className={styles.s7} style={{ margin: "0" }}>
                        B. Break up of Table 4A(3) (ITC Available - Inward supplies liable to reverse charge (other than 1 &amp; 2 above))
                    </p>
                    <a href="#bookmark2" className='text-xl font-medium' style={{ margin: "0", paddingRight: "6pt" }}>[TOP]</a>
                </div>
            </p>
            <table style={{ borderCollapse: "collapse", marginLeft: "20.25pt" }} cellSpacing={0}>
                <tbody>
                    <tr style={{ height: "15pt" }}>
                        <td
                            style={{
                                width: "279pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            colSpan={2}
                            bgcolor="#F2F2F2"
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "103pt",
                                    paddingRight: "103pt",
                                    textIndent: "0pt",
                                    textAlign: "center",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                Table in GSTR-2B
                            </p>
                        </td>
                        <td
                            style={{
                                width: "69pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            bgcolor="#F2F2F2"
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "6pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                Integrated tax
                            </p>
                        </td>
                        <td
                            style={{
                                width: "69pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            bgcolor="#F2F2F2"
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "12pt",
                                    textIndent: "0pt",
                                    textAlign: "left",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                Central tax
                            </p>
                        </td>
                        <td
                            style={{
                                width: "69pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            bgcolor="#F2F2F2"
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "9pt",
                                    textIndent: "0pt",
                                    textAlign: "left",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                State/UT tax
                            </p>
                        </td>
                        <td
                            style={{
                                width: "69pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            bgcolor="#F2F2F2"
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "23pt",
                                    paddingRight: "23pt",
                                    textIndent: "0pt",
                                    textAlign: "center",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                Cess
                            </p>
                        </td>
                    </tr>
                    <tr style={{ height: "26pt" }}>
                        <td
                            style={{
                                width: "59pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "4pt",
                                    textIndent: "0pt",
                                    textAlign: "left",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                3_Part A_III
                            </p>
                        </td>
                        <td
                            style={{
                                width: "220pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "4pt",
                                    textIndent: "0pt",
                                    textAlign: "left",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                ITC Available - Inward Supplies liable for reverse charge
                            </p>
                        </td>
                        <td
                            style={{
                                width: "69pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                0.00
                            </p>
                        </td>
                        <td
                            style={{
                                width: "69pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                0.00
                            </p>
                        </td>
                        <td
                            style={{
                                width: "69pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                0.00
                            </p>
                        </td>
                        <td
                            style={{
                                width: "69pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                0.00
                            </p>
                        </td>
                    </tr>
                    <tr style={{ height: "26pt" }}>
                        <td
                            style={{
                                width: "59pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "4pt",
                                    textIndent: "0pt",
                                    textAlign: "left",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                3_Part B_I
                            </p>
                        </td>
                        <td
                            style={{
                                width: "220pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "4pt",
                                    textIndent: "0pt",
                                    textAlign: "left",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                ITC Available - B2B - Credit Notes (including amendment) (Reverse Charge) - (B)
                            </p>
                        </td>
                        <td
                            style={{
                                width: "69pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                0.00
                            </p>
                        </td>
                        <td
                            style={{
                                width: "69pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                0.00
                            </p>
                        </td>
                        <td
                            style={{
                                width: "69pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                0.00
                            </p>
                        </td>
                        <td
                            style={{
                                width: "69pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                0.00
                            </p>
                        </td>
                    </tr>
                    <tr style={{ height: "26pt" }}>
                        <td
                            style={{
                                width: "59pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "4pt",
                                    textIndent: "0pt",
                                    textAlign: "left",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                4_Part B_I
                            </p>
                        </td>
                        <td
                            style={{
                                width: "220pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "4pt",
                                    textIndent: "0pt",
                                    textAlign: "left",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                ITC Not Available - B2B - Credit Notes (including amendment) (Reverse Charge) - (C)
                            </p>
                        </td>
                        <td
                            style={{
                                width: "69pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                0.00
                            </p>
                        </td>
                        <td
                            style={{
                                width: "69pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                0.00
                            </p>
                        </td>
                        <td
                            style={{
                                width: "69pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                0.00
                            </p>
                        </td>
                        <td
                            style={{
                                width: "69pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                0.00
                            </p>
                        </td>
                    </tr>
                    <tr style={{ height: "26pt" }}>
                        <td
                            style={{
                                width: "220pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            colSpan={2}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "4pt",
                                    textIndent: "0pt",
                                    textAlign: "left",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0",
                                    fontWeight: 'bold'
                                }}
                            >
                                ITC Available - Inward Supplies liable to reverse charge(other than 1 & 2 above) (D) = (A) - (B) - (C)
                            </p>
                        </td>
                        <td
                            style={{
                                width: "69pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                0.00
                            </p>
                        </td>
                        <td
                            style={{
                                width: "69pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                0.00
                            </p>
                        </td>
                        <td
                            style={{
                                width: "69pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                0.00
                            </p>
                        </td>
                        <td
                            style={{
                                width: "69pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                0.00
                            </p>
                        </td>
                    </tr>
                </tbody>
            </table>
            <p style={{ marginLeft: '20.25pt', textIndent: "0pt", paddingTop: "8pt", paddingBottom: "4pt", marginBottom: "0", marginLeft: "20.25pt" }}>
                <div className='flex justify-between items-center' style={{ width: '556pt', backgroundColor: "#FCE9D9", paddingTop: '2px', paddingBottom: '2px', paddingLeft: '4px' }}>
                    <p className={styles.s7} style={{ margin: "0" }}>
                        C. Break up of Table 4A(4) (ITC Available - Inward Supplies from ISD)
                    </p>
                    <a href="#bookmark2" className='text-xl font-medium' style={{ margin: "0", paddingRight: "6pt" }}>[TOP]</a>
                </div>
            </p>
            <table style={{ borderCollapse: "collapse", marginLeft: "20.25pt" }} cellSpacing={0}>
                <tbody>
                    <tr style={{ height: "15pt" }}>
                        <td
                            style={{
                                width: "279pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            colSpan={2}
                            bgcolor="#F2F2F2"
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "103pt",
                                    paddingRight: "103pt",
                                    textIndent: "0pt",
                                    textAlign: "center",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                Table in GSTR-2B
                            </p>
                        </td>
                        <td
                            style={{
                                width: "69pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            bgcolor="#F2F2F2"
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "6pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                Integrated tax
                            </p>
                        </td>
                        <td
                            style={{
                                width: "69pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            bgcolor="#F2F2F2"
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "12pt",
                                    textIndent: "0pt",
                                    textAlign: "left",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                Central tax
                            </p>
                        </td>
                        <td
                            style={{
                                width: "69pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            bgcolor="#F2F2F2"
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "9pt",
                                    textIndent: "0pt",
                                    textAlign: "left",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                State/UT tax
                            </p>
                        </td>
                        <td
                            style={{
                                width: "69pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            bgcolor="#F2F2F2"
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "23pt",
                                    paddingRight: "23pt",
                                    textIndent: "0pt",
                                    textAlign: "center",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                Cess
                            </p>
                        </td>
                    </tr>
                    <tr style={{ height: "15pt" }}>
                        <td
                            style={{
                                width: "59pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "4pt",
                                    textIndent: "0pt",
                                    textAlign: "left",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                3_Part A_II
                            </p>
                        </td>
                        <td
                            style={{
                                width: "220pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "4pt",
                                    textIndent: "0pt",
                                    textAlign: "left",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                ITC Available - Inward Supplies from ISD
                            </p>
                        </td>
                        <td
                            style={{
                                width: "69pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                0.00
                            </p>
                        </td>
                        <td
                            style={{
                                width: "69pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                0.00
                            </p>
                        </td>
                        <td
                            style={{
                                width: "69pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                0.00
                            </p>
                        </td>
                        <td
                            style={{
                                width: "69pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                0.00
                            </p>
                        </td>
                    </tr>
                    <tr style={{ height: "15pt" }}>
                        <td
                            style={{
                                width: "59pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "4pt",
                                    textIndent: "0pt",
                                    textAlign: "left",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                3_Part B_I
                            </p>
                        </td>
                        <td
                            style={{
                                width: "220pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "4pt",
                                    textIndent: "0pt",
                                    textAlign: "left",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                ITC Available - ISD - Credit Notes (including amendment) - (B)
                            </p>
                        </td>
                        <td
                            style={{
                                width: "69pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                0.00
                            </p>
                        </td>
                        <td
                            style={{
                                width: "69pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                0.00
                            </p>
                        </td>
                        <td
                            style={{
                                width: "69pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                0.00
                            </p>
                        </td>
                        <td
                            style={{
                                width: "69pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                0.00
                            </p>
                        </td>
                    </tr>
                    <tr style={{ height: "15pt" }}>
                        <td
                            style={{
                                width: "59pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "4pt",
                                    textIndent: "0pt",
                                    textAlign: "left",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                4_Part B_I
                            </p>
                        </td>
                        <td
                            style={{
                                width: "220pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "4pt",
                                    textIndent: "0pt",
                                    textAlign: "left",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                ITC Not Available - ISD - Credit Notes (including amendment) - (C)
                            </p>
                        </td>
                        <td
                            style={{
                                width: "69pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                0.00
                            </p>
                        </td>
                        <td
                            style={{
                                width: "69pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                0.00
                            </p>
                        </td>
                        <td
                            style={{
                                width: "69pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                0.00
                            </p>
                        </td>
                        <td
                            style={{
                                width: "69pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                0.00
                            </p>
                        </td>
                    </tr>
                    <tr style={{ height: "26pt" }}>
                        <td
                            style={{
                                width: "220pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            colSpan={2}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "4pt",
                                    textIndent: "0pt",
                                    textAlign: "left",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0",
                                    fontWeight: 'bold'
                                }}
                            >
                                ITC Available - Inward Supplies from ISD (D) = (A) - (B) - (C)
                            </p>
                        </td>
                        <td
                            style={{
                                width: "69pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                0.00
                            </p>
                        </td>
                        <td
                            style={{
                                width: "69pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                0.00
                            </p>
                        </td>
                        <td
                            style={{
                                width: "69pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                0.00
                            </p>
                        </td>
                        <td
                            style={{
                                width: "69pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                0.00
                            </p>
                        </td>
                    </tr>
                </tbody>
            </table>
            <p style={{ marginLeft: '20.25pt', textIndent: "0pt", paddingTop: "8pt", paddingBottom: "4pt", marginBottom: "0", marginLeft: "20.25pt" }}>
                <div className='flex justify-between items-center' style={{ width: '556pt', backgroundColor: "#FCE9D9", paddingTop: '2px', paddingBottom: '2px', paddingLeft: '4px' }}>
                    <p className={styles.s7} style={{ margin: "0" }}>
                        D. Break up of Table 4A(5) (ITC Available - All other ITC - Supplies from registered persons other than reverse charge)
                    </p>
                    <a href="#bookmark2" className='text-xl font-medium' style={{ margin: "0", paddingRight: "6pt" }}>[TOP]</a>
                </div>
            </p>
            <table style={{ borderCollapse: "collapse", marginLeft: "20.25pt" }} cellSpacing={0}>
                <tbody>
                    <tr style={{ height: "15pt" }}>
                        <td
                            style={{
                                width: "279pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            colSpan={2}
                            bgcolor="#F2F2F2"
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "103pt",
                                    paddingRight: "103pt",
                                    textIndent: "0pt",
                                    textAlign: "center",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                Table in GSTR-2B
                            </p>
                        </td>
                        <td
                            style={{
                                width: "69pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            bgcolor="#F2F2F2"
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "6pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                Integrated tax
                            </p>
                        </td>
                        <td
                            style={{
                                width: "69pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            bgcolor="#F2F2F2"
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "12pt",
                                    textIndent: "0pt",
                                    textAlign: "left",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                Central tax
                            </p>
                        </td>
                        <td
                            style={{
                                width: "69pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            bgcolor="#F2F2F2"
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "9pt",
                                    textIndent: "0pt",
                                    textAlign: "left",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                State/UT tax
                            </p>
                        </td>
                        <td
                            style={{
                                width: "69pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            bgcolor="#F2F2F2"
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "23pt",
                                    paddingRight: "23pt",
                                    textIndent: "0pt",
                                    textAlign: "center",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                Cess
                            </p>
                        </td>
                    </tr>
                    <tr style={{ height: "26pt" }}>
                        <td
                            style={{
                                width: "59pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "4pt",
                                    textIndent: "0pt",
                                    textAlign: "left",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                3_Part A_I
                            </p>
                        </td>
                        <td
                            style={{
                                width: "220pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "4pt",
                                    textIndent: "0pt",
                                    textAlign: "left",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                ITC Available - Inward Supplies liable for other than reverse charge - (A)
                            </p>
                        </td>
                        <td
                            style={{
                                width: "69pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                {getITCSummery('partA', 'totalIGST').toFixed(2)}
                            </p>
                        </td>
                        <td
                            style={{
                                width: "69pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >

                                {getITCSummery('partA', 'totalCGST').toFixed(2)}
                            </p>
                        </td>
                        <td
                            style={{
                                width: "69pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >

                                {getITCSummery('partA', 'totalSGST').toFixed(2)}
                            </p>
                        </td>
                        <td
                            style={{
                                width: "69pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >

                                {getITCSummery('partA', 'totalCess').toFixed(2)}
                            </p>
                        </td>
                    </tr>
                    <tr style={{ height: "26pt" }}>
                        <td
                            style={{
                                width: "59pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "4pt",
                                    textIndent: "0pt",
                                    textAlign: "left",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                3_Part B_I
                            </p>
                        </td>
                        <td
                            style={{
                                width: "220pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "4pt",
                                    textIndent: "0pt",
                                    textAlign: "left",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                ITC Available - B2B - Credit Notes (including amendment) - (B)
                            </p>
                        </td>
                        <td
                            style={{
                                width: "69pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                {getITCSummery('partA', 'b2bncIGST').toFixed(2)}
                            </p>
                        </td>
                        <td
                            style={{
                                width: "69pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                {getITCSummery('partA', 'b2bncCGST').toFixed(2)}
                            </p>
                        </td>
                        <td
                            style={{
                                width: "69pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                {getITCSummery('partA', 'b2bncSGST').toFixed(2)}
                            </p>
                        </td>
                        <td
                            style={{
                                width: "69pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                {getITCSummery('partA', 'b2bncCess').toFixed(2)}
                            </p>
                        </td>
                    </tr>
                    <tr style={{ height: "26pt" }}>
                        <td
                            style={{
                                width: "59pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "4pt",
                                    textIndent: "0pt",
                                    textAlign: "left",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                4_Part B_I
                            </p>
                        </td>
                        <td
                            style={{
                                width: "220pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "4pt",
                                    textIndent: "0pt",
                                    textAlign: "left",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                ITC Not Available - B2B - Credit Notes (including amendment) - (C)
                            </p>
                        </td>
                        <td
                            style={{
                                width: "69pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                0.00
                            </p>
                        </td>
                        <td
                            style={{
                                width: "69pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                0.00
                            </p>
                        </td>
                        <td
                            style={{
                                width: "69pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                0.00
                            </p>
                        </td>
                        <td
                            style={{
                                width: "69pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                0.00
                            </p>
                        </td>
                    </tr>
                    <tr style={{ height: "26pt" }}>
                        <td
                            style={{
                                width: "220pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            colSpan={2}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "4pt",
                                    textIndent: "0pt",
                                    textAlign: "left",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0",
                                    fontWeight: 'bold'
                                }}
                            >
                                ITC Available - All other ITC - Supplies from registered persons other than reverse charge (D) = (A) - (B) - (C)
                            </p>
                        </td>
                        <td
                            style={{
                                width: "69pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                {(getITCSummery('partA', 'b2bIGST') + getITCSummery('partA', 'b2bncIGST')).toFixed(2)}
                            </p>
                        </td>
                        <td
                            style={{
                                width: "69pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                {(getITCSummery('partA', 'b2bCGST') + getITCSummery('partA', 'b2bncCGST')).toFixed(2)}
                            </p>
                        </td>
                        <td
                            style={{
                                width: "69pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                {(getITCSummery('partA', 'b2bSGST') + getITCSummery('partA', 'b2bncSGST')).toFixed(2)}
                            </p>
                        </td>
                        <td
                            style={{
                                width: "69pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                {(getITCSummery('partA', 'b2bCess') + getITCSummery('partA', 'b2bncCess')).toFixed(2)}
                            </p>
                        </td>
                    </tr>
                </tbody>
            </table>
            <p style={{ marginLeft: '20.25pt', textIndent: "0pt", paddingTop: "8pt", paddingBottom: "4pt", marginBottom: "0", marginLeft: "20.25pt" }}>
                <div className='flex justify-between items-center' style={{ width: '556pt', backgroundColor: "#FCE9D9", paddingTop: '2px', paddingBottom: '2px', paddingLeft: '4px' }}>
                    <p className={styles.s7} style={{ margin: "0" }}>
                        E. Break up of Table 4D(2) (Other Details - Ineligible ITC under Section 16(4) &amp; ITC restricted due to PoS rules)
                    </p>
                    <a href="#bookmark2" className='text-xl font-medium' style={{ margin: "0", paddingRight: "6pt" }}>[TOP]</a>
                </div>
            </p>
            <table style={{ borderCollapse: "collapse", marginLeft: "20.25pt" }} cellSpacing={0}>
                <tbody>
                    <tr style={{ height: "15pt" }}>
                        <td
                            style={{
                                width: "279pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            colSpan={2}
                            bgcolor="#F2F2F2"
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "103pt",
                                    paddingRight: "103pt",
                                    textIndent: "0pt",
                                    textAlign: "center",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                Table in GSTR-2B
                            </p>
                        </td>
                        <td
                            style={{
                                width: "69pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            bgcolor="#F2F2F2"
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "6pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                Integrated tax
                            </p>
                        </td>
                        <td
                            style={{
                                width: "69pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            bgcolor="#F2F2F2"
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "12pt",
                                    textIndent: "0pt",
                                    textAlign: "left",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                Central tax
                            </p>
                        </td>
                        <td
                            style={{
                                width: "69pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            bgcolor="#F2F2F2"
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "9pt",
                                    textIndent: "0pt",
                                    textAlign: "left",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                State/UT tax
                            </p>
                        </td>
                        <td
                            style={{
                                width: "69pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            bgcolor="#F2F2F2"
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "23pt",
                                    paddingRight: "23pt",
                                    textIndent: "0pt",
                                    textAlign: "center",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                Cess
                            </p>
                        </td>
                    </tr>
                    <tr style={{ height: "16pt" }}>
                        <td
                            style={{
                                width: "59pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "4pt",
                                    paddingRight: "11pt",
                                    textIndent: "0pt",
                                    textAlign: "left",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                4_Part A_I, II, III
                            </p>
                        </td>
                        <td
                            style={{
                                width: "220pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "4pt",
                                    textIndent: "0pt",
                                    textAlign: "left",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                ITC Not Available
                            </p>
                        </td>
                        <td
                            style={{
                                width: "69pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                {(getITCSummery('partB', 'b2bIGST') + getITCSummery('partB', 'b2baIGST')).toFixed(2)}
                            </p>
                        </td>
                        <td
                            style={{
                                width: "69pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                {(getITCSummery('partB', 'b2bCGST') + getITCSummery('partB', 'b2baCGST')).toFixed(2)}
                            </p>
                        </td>
                        <td
                            style={{
                                width: "69pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                {(getITCSummery('partB', 'b2bSGST') + getITCSummery('partB', 'b2baSGST')).toFixed(2)}
                            </p>
                        </td>
                        <td
                            style={{
                                width: "69pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                {(getITCSummery('partB', 'b2bCess') + getITCSummery('partB', 'b2baCess')).toFixed(2)}
                            </p>
                        </td>
                    </tr>
                </tbody>
            </table>
            <div className='bg-[#243f6d] pl-[20.25pt] py-[2pt]' style={{ marginTop: '12pt' }}>
                <p style={{
                    textIndent: "0pt",
                    lineHeight: "11pt",
                    textAlign: "left",
                    margin: '0'
                }}
                >
                    <span
                        style={{
                            color: "#FFF",
                            fontFamily: '"Trebuchet MS", sans-serif',
                            fontStyle: "normal",
                            fontWeight: "400",
                            textDecoration: "none",
                            fontSize: "9pt"
                        }}
                    >
                        Section IV : Detailed breakup of values in Table 5.1 (Interest) of FORM GSTR-3B
                    </span>
                </p>
                <p style={{
                    textIndent: "0pt",
                    lineHeight: "9pt",
                    textAlign: "left",
                    margin: '0'
                }}
                >
                    <span
                        style={{
                            color: "#FFF",
                            fontFamily: '"Trebuchet MS", sans-serif',
                            fontStyle: "italic",
                            fontWeight: "normal",
                            textDecoration: "none",
                            fontSize: "8pt"
                        }}
                    >
                        Taxpayers may use this data for reconciliation of details auto-populated in Section I above and detailed break-up of liability provided in their FORM GSTR-3B of previous period
                    </span>
                </p>
            </div>
            <p style={{ marginLeft: '20.25pt', textIndent: "0pt", paddingTop: "8pt", paddingBottom: "4pt", marginBottom: "0", marginLeft: "20.25pt" }}>
                <div className='flex justify-between items-center' style={{ width: '556pt', backgroundColor: "#FCE9D9", paddingTop: '2px', paddingBottom: '2px', paddingLeft: '4px' }}>
                    <p className={styles.s7} style={{ margin: "0" }}>
                        A. Total interest liability (As per liability breakup provided in previous GSTR-3B)
                    </p>
                    <a href="#bookmark2" className='text-xl font-medium' style={{ margin: "0", paddingRight: "6pt" }}>[TOP]</a>
                </div>
            </p>
            <table style={{ borderCollapse: "collapse", marginLeft: "20.25pt" }} cellSpacing={0}>
                <tbody>
                    <tr style={{ height: "15pt" }}>
                        <td
                            style={{
                                width: "199pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            bgcolor="#F2F2F2"
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "76pt",
                                    paddingRight: "76pt",
                                    textIndent: "0pt",
                                    textAlign: "left",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                Tax period
                            </p>
                        </td>
                        <td
                            style={{
                                width: "88pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            bgcolor="#F2F2F2"
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "16pt",
                                    textIndent: "0pt",
                                    textAlign: "left",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                Integrated tax
                            </p>
                        </td>
                        <td
                            style={{
                                width: "89pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            bgcolor="#F2F2F2"
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "22pt",
                                    textIndent: "0pt",
                                    textAlign: "left",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                Central tax
                            </p>
                        </td>
                        <td
                            style={{
                                width: "88pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            bgcolor="#F2F2F2"
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "19pt",
                                    textIndent: "0pt",
                                    textAlign: "left",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                State/UT tax
                            </p>
                        </td>
                        <td
                            style={{
                                width: "89pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            bgcolor="#F2F2F2"
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "33pt",
                                    paddingRight: "33pt",
                                    textIndent: "0pt",
                                    textAlign: "center",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                Cess
                            </p>
                        </td>
                    </tr>
                    <tr style={{ height: "15pt" }}>
                        <td
                            style={{
                                width: "199pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "4pt",
                                    textIndent: "0pt",
                                    textAlign: "left",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                {Moment(gDate).add('month', -1).format("MMMM yyyy")}
                            </p>
                        </td>
                        <td
                            style={{
                                width: "88pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}>
                                0.00
                            </p>
                        </td>
                        <td
                            style={{
                                width: "89pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}>
                                0.00
                            </p>
                        </td>
                        <td
                            style={{
                                width: "88pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}>
                                0.00
                            </p>
                        </td>
                        <td
                            style={{
                                width: "89pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}>
                                0.00
                            </p>
                        </td>
                    </tr>
                    <tr style={{ height: "15pt" }}>
                        <td
                            style={{
                                width: "199pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "4pt",
                                    textIndent: "0pt",
                                    textAlign: "left",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                Total
                            </p>
                        </td>
                        <td
                            style={{
                                width: "88pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}>
                                0.00
                            </p>
                        </td>
                        <td
                            style={{
                                width: "89pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}>
                                0.00
                            </p>
                        </td>
                        <td
                            style={{
                                width: "88pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}>
                                0.00
                            </p>
                        </td>
                        <td
                            style={{
                                width: "89pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}>
                                0.00
                            </p>
                        </td>
                    </tr>
                </tbody>
            </table>
            <p style={{ marginLeft: '20.25pt', textIndent: "0pt", paddingTop: "8pt", paddingBottom: "4pt", marginBottom: "0", marginLeft: "20.25pt" }}>
                <div className='flex justify-between items-center' style={{ width: '556pt', backgroundColor: "#FCE9D9", paddingTop: '2px', paddingBottom: '2px', paddingLeft: '4px' }}>
                    <p className={styles.s7} style={{ margin: "0" }}>
                        B. Breakup of tax liabilities paid by ITC and Cash in previous GSTR-3B<br />
                        <span className='text-lg'>[Other than reverse charge and reverse charge, clubbed together]</span>
                    </p>
                    <a href="#bookmark2" className='text-xl font-medium' style={{ margin: "0", paddingRight: "6pt" }}>[TOP]</a>
                </div>
            </p>
            <p style={{ pageBreakAfter: 'always' }}>&nbsp;</p>
            <div className='fixed w-[595pt] h-[800pt] flex flex-col items-center justify-center opacity-20 pointer-events-none' style={{ zIndex: 9999 }}>
                <img src={Logo} alt="ITAA" className='w-[800] mx-auto h-[227px] pointer-events-none' style={{ transform: "rotate(-45deg)" }} />
            </div>
            <p className={styles.s4} style={{
                paddingTop: "1pt",
                paddingBottom: "4pt",
                paddingLeft: "20pt",
                textIndent: "0pt",
                textAlign: "left"
            }} >
                <span className='font-medium'>Period:</span> {Moment(gDate).add('month', -1).format("MMMM yyyy")}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span className='font-medium'>Filing date:</span> {Moment(gDate).add('days', 19).format("DD/MM/YYYY")}
            </p>
            <table style={{ borderCollapse: "collapse", marginLeft: "20.25pt" }} cellSpacing={0}>
                <tbody>
                    <tr style={{ height: "15pt" }}>
                        <td
                            style={{
                                width: "199pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            bgcolor="#F2F2F2"
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "76pt",
                                    paddingRight: "76pt",
                                    textIndent: "0pt",
                                    textAlign: "center",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                Particulars
                            </p>
                        </td>
                        <td
                            style={{
                                width: "88pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            bgcolor="#F2F2F2"
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "16pt",
                                    textIndent: "0pt",
                                    textAlign: "left",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                Integrated tax
                            </p>
                        </td>
                        <td
                            style={{
                                width: "89pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            bgcolor="#F2F2F2"
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "22pt",
                                    textIndent: "0pt",
                                    textAlign: "left",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                Central tax
                            </p>
                        </td>
                        <td
                            style={{
                                width: "88pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            bgcolor="#F2F2F2"
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "19pt",
                                    textIndent: "0pt",
                                    textAlign: "left",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                State/UT tax
                            </p>
                        </td>
                        <td
                            style={{
                                width: "89pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            bgcolor="#F2F2F2"
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "33pt",
                                    paddingRight: "33pt",
                                    textIndent: "0pt",
                                    textAlign: "center",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                Cess
                            </p>
                        </td>
                    </tr>
                    {/* //Todo: add function to show tax payable */}
                    <tr style={{ height: "15pt" }}>
                        <td
                            style={{
                                width: "199pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "4pt",
                                    textIndent: "0pt",
                                    textAlign: "left",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                Tax payable
                            </p>
                        </td>
                        <td
                            style={{
                                width: "88pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}>
                                0.00
                            </p>
                        </td>
                        <td
                            style={{
                                width: "89pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}>
                                0.00
                            </p>
                        </td>
                        <td
                            style={{
                                width: "88pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}>
                                0.00
                            </p>
                        </td>
                        <td
                            style={{
                                width: "89pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}>
                                0.00
                            </p>
                        </td>
                    </tr>
                    {/* //Todo: add function to show pay by credit */}
                    <tr style={{ height: "15pt" }}>
                        <td
                            style={{
                                width: "199pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "4pt",
                                    textIndent: "0pt",
                                    textAlign: "left",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                Paid by credit
                            </p>
                        </td>
                        <td
                            style={{
                                width: "88pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}>
                                0.00
                            </p>
                        </td>
                        <td
                            style={{
                                width: "89pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}>
                                0.00
                            </p>
                        </td>
                        <td
                            style={{
                                width: "88pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}>
                                0.00
                            </p>
                        </td>
                        <td
                            style={{
                                width: "89pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}>
                                0.00
                            </p>
                        </td>
                    </tr>
                    {/* //Todo: add function to show pay by cash */}
                    <tr style={{ height: "15pt" }}>
                        <td
                            style={{
                                width: "199pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "4pt",
                                    textIndent: "0pt",
                                    textAlign: "left",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                Paid by cash
                            </p>
                        </td>
                        <td
                            style={{
                                width: "88pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}>
                                0.00
                            </p>
                        </td>
                        <td
                            style={{
                                width: "89pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}>
                                0.00
                            </p>
                        </td>
                        <td
                            style={{
                                width: "88pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}>
                                0.00
                            </p>
                        </td>
                        <td
                            style={{
                                width: "89pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}>
                                0.00
                            </p>
                        </td>
                    </tr>
                    {/* //Todo: add function to show pay by credit (%) */}
                    <tr style={{ height: "15pt" }}>
                        <td
                            style={{
                                width: "199pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "4pt",
                                    textIndent: "0pt",
                                    textAlign: "left",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                Liability paid by credit (%)
                            </p>
                        </td>
                        <td
                            style={{
                                width: "88pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}>
                                0.00
                            </p>
                        </td>
                        <td
                            style={{
                                width: "89pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}>
                                0.00
                            </p>
                        </td>
                        <td
                            style={{
                                width: "88pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}>
                                0.00
                            </p>
                        </td>
                        <td
                            style={{
                                width: "89pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}>
                                0.00
                            </p>
                        </td>
                    </tr>
                    {/* //Todo: add function to show pay by cash (%) */}
                    <tr style={{ height: "15pt" }}>
                        <td
                            style={{
                                width: "199pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "4pt",
                                    textIndent: "0pt",
                                    textAlign: "left",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                Liability paid by cash (%)
                            </p>
                        </td>
                        <td
                            style={{
                                width: "88pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}>
                                0.00
                            </p>
                        </td>
                        <td
                            style={{
                                width: "89pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}>
                                0.00
                            </p>
                        </td>
                        <td
                            style={{
                                width: "88pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}>
                                0.00
                            </p>
                        </td>
                        <td
                            style={{
                                width: "89pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}>
                                0.00
                            </p>
                        </td>
                    </tr>
                </tbody>
            </table>
            <p style={{ marginLeft: '20.25pt', textIndent: "0pt", paddingTop: "8pt", paddingBottom: "4pt", marginBottom: "0", marginLeft: "20.25pt" }}>
                <div className='flex justify-between items-center' style={{ width: '556pt', backgroundColor: "#FCE9D9", paddingTop: '2px', paddingBottom: '2px', paddingLeft: '4px' }}>
                    <p className={styles.s7sm} style={{ margin: "0" }}>
                        C. Breakup of system computed interest liability - Current tax period [As per sub-section(1) of Section 50 of CGST Act, 2017]<br />
                        <span className='text-lg'>Interest payable only on portion of liability paid by debiting the electronic cash ledger</span>
                    </p>
                    <a href="#bookmark2" className='text-xl font-medium' style={{ margin: "0", paddingRight: "6pt" }}>[TOP]</a>
                </div>
            </p>
            <p className={styles.s4} style={{
                paddingTop: "6pt",
                paddingBottom: "2pt",
                paddingLeft: "20pt",
                textIndent: "0pt",
                textAlign: "left"
            }}>
                Period: {Moment(gDate).add('month', -1).format("MMMM yyyy")}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Due date: {Moment(gDate).add('days', 19).format("DD/MM/YYYY")}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Filing date: {Moment(gDate).add('days', 19).format("DD/MM/YYYY")}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;No. of days: 0 days&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Rate of interest: 18% per annum
            </p>
            <table style={{ borderCollapse: "collapse", marginLeft: "20.25pt" }} cellSpacing={0}>
                <tbody>
                    <tr style={{ height: "15pt" }}>
                        <td
                            style={{
                                width: "199pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            bgcolor="#F2F2F2"
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "76pt",
                                    paddingRight: "76pt",
                                    textIndent: "0pt",
                                    textAlign: "center",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                Particulars
                            </p>
                        </td>
                        <td
                            style={{
                                width: "88pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            bgcolor="#F2F2F2"
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "16pt",
                                    textIndent: "0pt",
                                    textAlign: "left",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                Integrated tax
                            </p>
                        </td>
                        <td
                            style={{
                                width: "89pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            bgcolor="#F2F2F2"
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "22pt",
                                    textIndent: "0pt",
                                    textAlign: "left",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                Central tax
                            </p>
                        </td>
                        <td
                            style={{
                                width: "88pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            bgcolor="#F2F2F2"
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "19pt",
                                    textIndent: "0pt",
                                    textAlign: "left",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                State/UT tax
                            </p>
                        </td>
                        <td
                            style={{
                                width: "89pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                            bgcolor="#F2F2F2"
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "33pt",
                                    paddingRight: "33pt",
                                    textIndent: "0pt",
                                    textAlign: "center",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                Cess
                            </p>
                        </td>
                    </tr>
                    <tr style={{ height: "15pt" }}>
                        <td
                            style={{
                                width: "199pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "4pt",
                                    textIndent: "0pt",
                                    textAlign: "left",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                Tax payable for this period
                            </p>
                        </td>
                        <td
                            style={{
                                width: "88pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}>
                                0.00
                            </p>
                        </td>
                        <td
                            style={{
                                width: "89pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}>
                                0.00
                            </p>
                        </td>
                        <td
                            style={{
                                width: "88pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}>
                                0.00
                            </p>
                        </td>
                        <td
                            style={{
                                width: "89pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}>
                                0.00
                            </p>
                        </td>
                    </tr>
                    <tr style={{ height: "15pt" }}>
                        <td
                            style={{
                                width: "199pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "4pt",
                                    textIndent: "0pt",
                                    textAlign: "left",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                Percentage of liability paid by cash
                            </p>
                        </td>
                        <td
                            style={{
                                width: "88pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}>
                                0.00
                            </p>
                        </td>
                        <td
                            style={{
                                width: "89pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}>
                                0.00
                            </p>
                        </td>
                        <td
                            style={{
                                width: "88pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}>
                                0.00
                            </p>
                        </td>
                        <td
                            style={{
                                width: "89pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}>
                                0.00
                            </p>
                        </td>
                    </tr>
                    <tr style={{ height: "15pt" }}>
                        <td
                            style={{
                                width: "199pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "4pt",
                                    textIndent: "0pt",
                                    textAlign: "left",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                Liability paid by cash
                            </p>
                        </td>
                        <td
                            style={{
                                width: "88pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}>
                                0.00
                            </p>
                        </td>
                        <td
                            style={{
                                width: "89pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}>
                                0.00
                            </p>
                        </td>
                        <td
                            style={{
                                width: "88pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}>
                                0.00
                            </p>
                        </td>
                        <td
                            style={{
                                width: "89pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}>
                                0.00
                            </p>
                        </td>
                    </tr>
                    <tr style={{ height: "15pt" }}>
                        <td
                            style={{
                                width: "199pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p
                                className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingLeft: "4pt",
                                    textIndent: "0pt",
                                    textAlign: "left",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}
                            >
                                Interest payable for this period
                            </p>
                        </td>
                        <td
                            style={{
                                width: "88pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}>
                                0.00
                            </p>
                        </td>
                        <td
                            style={{
                                width: "89pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}>
                                0.00
                            </p>
                        </td>
                        <td
                            style={{
                                width: "88pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}>
                                0.00
                            </p>
                        </td>
                        <td
                            style={{
                                width: "89pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "1pt",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt"
                            }}
                        >
                            <p className={styles.s4}
                                style={{
                                    paddingTop: "1pt",
                                    paddingRight: "3pt",
                                    textIndent: "0pt",
                                    textAlign: "right",
                                    paddingBottom: "1pt",
                                    lineHeight: "10pt",
                                    margin: "0"
                                }}>
                                0.00
                            </p>
                        </td>
                    </tr>
                </tbody>
            </table>
            <p className={styles.s4} style={{
                paddingTop: "16pt",
                paddingLeft: "20pt",
                textIndent: "0pt",
                textAlign: "left",
                paddingBottom: "1pt",
                lineHeight: "10pt",
                margin: "0"
            }}>
                Instructions:
            </p>
            <ol id="l1">
                <li className={styles.liA} data-list-text={1} style={{ 'display': 'list-item', marginTop: "6pt" }}>
                    <p style={{ paddingLeft: "36pt", textIndent: "-9pt", textAlign: "left", margin: "0" }}>
                        1. Terms Used :
                    </p>
                    <ol id="l2">
                        <li data-list-text="a.">
                            <p
                                style={{
                                    paddingLeft: "54pt",
                                    textIndent: "-9pt",
                                    textAlign: "left",
                                    margin: "0"
                                }}
                            >
                                a. ITC – Input Tax Credit
                            </p>
                        </li>
                        <li data-list-text="b.">
                            <p
                                style={{
                                    paddingLeft: "54pt",
                                    textIndent: "-9pt",
                                    textAlign: "left",
                                    margin: "0"
                                }}
                            >
                                b. SEZ – Special Economic Zone
                            </p>
                        </li>
                        <li data-list-text="c.">
                            <p
                                style={{
                                    paddingLeft: "54pt",
                                    textIndent: "-9pt",
                                    textAlign: "left",
                                    margin: "0"
                                }}
                            >
                                c. ISD – Input Service Distributor
                            </p>
                        </li>
                        <li data-list-text="d.">
                            <p
                                style={{
                                    paddingLeft: "54pt",
                                    textIndent: "-9pt",
                                    textAlign: "left",
                                    margin: "0"
                                }}
                            >
                                d. p.a. – per annum
                            </p>
                        </li>
                        <li data-list-text="e.">
                            <p
                                style={{
                                    paddingLeft: "54pt",
                                    textIndent: "-9pt",
                                    textAlign: "left",
                                    margin: "0"
                                }}
                            >
                                e. AATO – Annual Aggregate Turnover
                            </p>
                        </li>
                    </ol>
                </li>
                <li className={styles.liA} data-list-text={2}>
                    <p style={{ paddingLeft: "36pt", textIndent: "-9pt", textAlign: "left", margin: "0" }}>
                        2. System generated summary is a summary of FORM GSTR-3B which will be generated for every registered person based on the following:
                    </p>
                    <ol id="l3">
                        <li data-list-text="a.">
                            <p
                                style={{
                                    paddingLeft: "54pt",
                                    textIndent: "-9pt",
                                    textAlign: "left", margin: "0"
                                }}
                            >
                                a. Details of outward supplies as filed by you in FORM GSTR-1 for the period,
                            </p>
                        </li>
                        <li data-list-text="b.">
                            <p
                                style={{
                                    paddingLeft: "54pt",
                                    textIndent: "-9pt",
                                    textAlign: "left", margin: "0"
                                }}
                            >
                                b. Details of inward supplies liable to reverse charge and input tax credit as per your FORM GSTR-2B generated for the period,
                            </p>
                        </li>
                        <li data-list-text="c.">
                            <p
                                style={{
                                    paddingLeft: "54pt",
                                    textIndent: "-9pt",
                                    textAlign: "left", margin: "0"
                                }}
                            >
                                c. Interest liability based on breakup of tax liability provided by you in FORM GSTR-3B of previous return period.
                            </p>
                        </li>
                    </ol>
                </li>
                <li className={styles.liA} data-list-text={3}>
                    <p style={{ paddingLeft: "36pt", textIndent: "-9pt", textAlign: "left", margin: "0" }}>
                        3. The summary is generated in the following manner:
                    </p>
                    <table style={{ borderCollapse: "collapse", marginLeft: "38pt", marginTop: "8pt" }} cellSpacing={0}>
                        <tbody>
                            <tr style={{ height: "15pt" }}>
                                <td
                                    style={{
                                        width: "137pt",
                                        borderTopStyle: "solid",
                                        borderTopWidth: "1pt",
                                        borderLeftStyle: "solid",
                                        borderLeftWidth: "1pt",
                                        borderBottomStyle: "solid",
                                        borderBottomWidth: "1pt",
                                        borderRightStyle: "solid",
                                        borderRightWidth: "1pt"
                                    }}
                                >
                                    <p
                                        className={styles.s4}
                                        style={{
                                            paddingTop: "1pt",
                                            paddingLeft: "39pt",
                                            textIndent: "0pt",
                                            textAlign: "left",
                                            paddingBottom: "1pt",
                                            lineHeight: "10pt",
                                            margin: "0"
                                        }}
                                    >
                                        GSTR-3B table
                                    </p>
                                </td>
                                <td
                                    style={{
                                        width: "111pt",
                                        borderTopStyle: "solid",
                                        borderTopWidth: "1pt",
                                        borderLeftStyle: "solid",
                                        borderLeftWidth: "1pt",
                                        borderBottomStyle: "solid",
                                        borderBottomWidth: "1pt",
                                        borderRightStyle: "solid",
                                        borderRightWidth: "1pt"
                                    }}
                                >
                                    <p
                                        className={styles.s4}
                                        style={{
                                            paddingTop: "1pt",
                                            paddingLeft: "26pt",
                                            textIndent: "0pt",
                                            textAlign: "left",
                                            paddingBottom: "1pt",
                                            lineHeight: "10pt",
                                            margin: "0"
                                        }}
                                    >
                                        GSTR-2B table
                                    </p>
                                </td>
                                <td
                                    style={{
                                        width: "289pt",
                                        borderTopStyle: "solid",
                                        borderTopWidth: "1pt",
                                        borderLeftStyle: "solid",
                                        borderLeftWidth: "1pt",
                                        borderBottomStyle: "solid",
                                        borderBottomWidth: "1pt",
                                        borderRightStyle: "solid",
                                        borderRightWidth: "1pt"
                                    }}
                                >
                                    <p
                                        className={styles.s4}
                                        style={{
                                            paddingTop: "1pt",
                                            paddingLeft: "123pt",
                                            paddingRight: "122pt",
                                            textIndent: "0pt",
                                            textAlign: "center",
                                            paddingBottom: "1pt",
                                            lineHeight: "10pt",
                                            margin: "0"
                                        }}
                                    >
                                        Reference
                                    </p>
                                </td>
                            </tr>
                            <tr style={{ height: "78pt" }}>
                                <td
                                    style={{
                                        width: "137pt",
                                        borderTopStyle: "solid",
                                        borderTopWidth: "1pt",
                                        borderLeftStyle: "solid",
                                        borderLeftWidth: "1pt",
                                        borderBottomStyle: "solid",
                                        borderBottomWidth: "1pt",
                                        borderRightStyle: "solid",
                                        borderRightWidth: "1pt"
                                    }}
                                >
                                    <p
                                        className={styles.s4}
                                        style={{
                                            paddingTop: "1pt",
                                            paddingLeft: "4pt",
                                            paddingRight: "15pt",
                                            textIndent: "0pt",
                                            lineHeight: "202%",
                                            textAlign: "left",
                                            paddingBottom: "1pt",
                                            lineHeight: "10pt",
                                            margin: "0"
                                        }}
                                    >
                                        3.1(a, b, c, e) and 3.2 Details of Outward supplies
                                    </p>
                                </td>
                                <td
                                    style={{
                                        width: "111pt",
                                        borderTopStyle: "solid",
                                        borderTopWidth: "1pt",
                                        borderLeftStyle: "solid",
                                        borderLeftWidth: "1pt",
                                        borderBottomStyle: "solid",
                                        borderBottomWidth: "1pt",
                                        borderRightStyle: "solid",
                                        borderRightWidth: "1pt"
                                    }}
                                >
                                    <p
                                        className={styles.s4}
                                        style={{
                                            paddingTop: "1pt",
                                            paddingLeft: "4pt",
                                            textIndent: "0pt",
                                            textAlign: "left",
                                            paddingBottom: "1pt",
                                            lineHeight: "10pt",
                                            margin: "0"
                                        }}
                                    >
                                        NA
                                    </p>
                                </td>
                                <td
                                    style={{
                                        width: "289pt",
                                        borderTopStyle: "solid",
                                        borderTopWidth: "1pt",
                                        borderLeftStyle: "solid",
                                        borderLeftWidth: "1pt",
                                        borderBottomStyle: "solid",
                                        borderBottomWidth: "1pt",
                                        borderRightStyle: "solid",
                                        borderRightWidth: "1pt"
                                    }}
                                >
                                    <p
                                        className={styles.s4}
                                        style={{
                                            paddingTop: "1pt",
                                            paddingLeft: "9pt",
                                            paddingRight: "4pt",
                                            textIndent: "0pt",
                                            textAlign: "left",
                                            paddingBottom: "1pt",
                                            lineHeight: "10pt",
                                            margin: "0"
                                        }}
                                    >
                                        This is auto-populated from FORM GSTR-1. Positive values shall be reported in respective tables. Negative values, if any, shall not be considered in the table and system will provide the value as zero.
                                    </p>
                                    <p style={{ textIndent: "0pt", textAlign: "left", margin: "0" }}>
                                        <br />
                                    </p>
                                    <p
                                        className={styles.s4}
                                        style={{
                                            paddingLeft: "9pt",
                                            paddingRight: "4pt",
                                            textIndent: "0pt",
                                            textAlign: "left",
                                            paddingBottom: "1pt",
                                            lineHeight: "10pt",
                                            margin: "0"
                                        }}
                                    >
                                        Negative values may arise in these tables due to credit notes and downward amendment of outward supplies. Detail break-up in Section II above may be referred in this respect.
                                    </p>
                                </td>
                            </tr>
                            <tr style={{ height: "78pt" }}>
                                <td
                                    style={{
                                        width: "137pt",
                                        borderTopStyle: "solid",
                                        borderTopWidth: "1pt",
                                        borderLeftStyle: "solid",
                                        borderLeftWidth: "1pt",
                                        borderBottomStyle: "solid",
                                        borderBottomWidth: "1pt",
                                        borderRightStyle: "solid",
                                        borderRightWidth: "1pt"
                                    }}
                                >
                                    <p
                                        className={styles.s4}
                                        style={{
                                            paddingTop: "1pt",
                                            paddingLeft: "4pt",
                                            textIndent: "0pt",
                                            textAlign: "left",
                                            paddingBottom: "1pt",
                                            lineHeight: "10pt",
                                            margin: "0"
                                        }}
                                    >
                                        3.1(d)
                                    </p>
                                    <p style={{
                                        textIndent: "0pt", textAlign: "left",
                                        paddingBottom: "1pt",
                                        lineHeight: "10pt",
                                        margin: "0"
                                    }}>
                                        <br />
                                    </p>
                                    <p
                                        className={styles.s4}
                                        style={{
                                            paddingLeft: "4pt",
                                            textIndent: "0pt",
                                            textAlign: "left",
                                            paddingBottom: "1pt",
                                            lineHeight: "10pt",
                                            margin: "0"
                                        }}
                                    >
                                        Inward supplies liable to reverse charge
                                    </p>
                                </td>
                                <td
                                    style={{
                                        width: "111pt",
                                        borderTopStyle: "solid",
                                        borderTopWidth: "1pt",
                                        borderLeftStyle: "solid",
                                        borderLeftWidth: "1pt",
                                        borderBottomStyle: "solid",
                                        borderBottomWidth: "1pt",
                                        borderRightStyle: "solid",
                                        borderRightWidth: "1pt"
                                    }}
                                >
                                    <p
                                        className={styles.s4}
                                        style={{
                                            paddingTop: "1pt",
                                            paddingLeft: "4pt",
                                            textIndent: "0pt",
                                            lineHeight: "202%",
                                            textAlign: "left",
                                            paddingBottom: "1pt",
                                            lineHeight: "10pt",
                                            margin: "0"
                                        }}
                                    >
                                        Table 3 Part A Section III<br /><br />Table 4 Part A Section III
                                    </p>
                                </td>
                                <td
                                    style={{
                                        width: "289pt",
                                        borderTopStyle: "solid",
                                        borderTopWidth: "1pt",
                                        borderLeftStyle: "solid",
                                        borderLeftWidth: "1pt",
                                        borderBottomStyle: "solid",
                                        borderBottomWidth: "1pt",
                                        borderRightStyle: "solid",
                                        borderRightWidth: "1pt"
                                    }}
                                >
                                    <p
                                        className={styles.s4}
                                        style={{
                                            paddingTop: "1pt",
                                            paddingLeft: "9pt",
                                            paddingRight: "4pt",
                                            textIndent: "0pt",
                                            textAlign: "left",
                                            paddingBottom: "1pt",
                                            lineHeight: "10pt",
                                            margin: "0"
                                        }}
                                    >
                                        This is auto-populated from FORM GSTR-2B. Positive values shall
                                        be reported in respective table. Negative values, if any, shall
                                        not be considered in the table and system will provide the value
                                        as zero.
                                    </p>
                                    <p style={{
                                        textIndent: "0pt", textAlign: "left",
                                        paddingBottom: "1pt",
                                        lineHeight: "10pt",
                                        margin: "0"
                                    }}>
                                        <br />
                                    </p>
                                    <p
                                        className={styles.s4}
                                        style={{
                                            paddingLeft: "9pt",
                                            paddingRight: "4pt",
                                            textIndent: "0pt",
                                            textAlign: "left",
                                            paddingBottom: "1pt",
                                            lineHeight: "10pt",
                                            margin: "0"
                                        }}
                                    >
                                        Negative values may arise in this table due to downward
                                        amendment of outward supplies. Detail break-up in Section II
                                        above may be referred in this respect.
                                    </p>
                                </td>
                            </tr>
                            <tr style={{ height: "78pt" }}>
                                <td
                                    style={{
                                        width: "137pt",
                                        borderTopStyle: "solid",
                                        borderTopWidth: "1pt",
                                        borderLeftStyle: "solid",
                                        borderLeftWidth: "1pt",
                                        borderBottomStyle: "solid",
                                        borderBottomWidth: "1pt",
                                        borderRightStyle: "solid",
                                        borderRightWidth: "1pt"
                                    }}
                                >
                                    <p
                                        className={styles.s4}
                                        style={{
                                            paddingTop: "1pt",
                                            paddingLeft: "4pt",
                                            textIndent: "0pt",
                                            textAlign: "left",
                                            paddingBottom: "1pt",
                                            lineHeight: "10pt",
                                            margin: "0"
                                        }}
                                    >
                                        4A (1, 3, 4, 5)
                                    </p>
                                    <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                        <br />
                                    </p>
                                    <p
                                        className={styles.s4}
                                        style={{
                                            paddingLeft: "4pt",
                                            textIndent: "0pt",
                                            textAlign: "left",
                                            paddingBottom: "1pt",
                                            lineHeight: "10pt",
                                            margin: "0"
                                        }}
                                    >
                                        ITC Available
                                    </p>
                                </td>
                                <td
                                    style={{
                                        width: "111pt",
                                        borderTopStyle: "solid",
                                        borderTopWidth: "1pt",
                                        borderLeftStyle: "solid",
                                        borderLeftWidth: "1pt",
                                        borderBottomStyle: "solid",
                                        borderBottomWidth: "1pt",
                                        borderRightStyle: "solid",
                                        borderRightWidth: "1pt"
                                    }}
                                >
                                    <p
                                        className={styles.s4}
                                        style={{
                                            paddingTop: "1pt",
                                            paddingLeft: "4pt",
                                            paddingRight: "4pt",
                                            textIndent: "0pt",
                                            textAlign: "left",
                                            paddingBottom: "1pt",
                                            lineHeight: "10pt",
                                            margin: "0"
                                        }}
                                    >
                                        Table 3 Part A Section I, II, III, IV
                                    </p>
                                </td>
                                <td
                                    style={{
                                        width: "289pt",
                                        borderTopStyle: "solid",
                                        borderTopWidth: "1pt",
                                        borderLeftStyle: "solid",
                                        borderLeftWidth: "1pt",
                                        borderBottomStyle: "solid",
                                        borderBottomWidth: "1pt",
                                        borderRightStyle: "solid",
                                        borderRightWidth: "1pt"
                                    }}
                                >
                                    <p
                                        className={styles.s4}
                                        style={{
                                            paddingTop: "1pt",
                                            paddingLeft: "9pt",
                                            paddingRight: "4pt",
                                            textIndent: "0pt",
                                            textAlign: "left",
                                            paddingBottom: "1pt",
                                            lineHeight: "10pt",
                                            margin: "0"
                                        }}
                                    >
                                        This is auto-populated from FORM GSTR-2B. Net ITC available
                                        including amendments (Invoices + Debit Notes - Credit Notes)
                                        shall be reported in respective tables.
                                    </p>
                                    <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                        <br />
                                    </p>
                                    <p
                                        className={styles.s4}
                                        style={{
                                            paddingLeft: "9pt",
                                            paddingRight: "17pt",
                                            textIndent: "0pt",
                                            textAlign: "left",
                                            paddingBottom: "1pt",
                                            lineHeight: "10pt",
                                            margin: "0"
                                        }}
                                    >
                                        Negative values, if any, due to credit notes and downward
                                        amendment shall also be reported in respective tables only.
                                        Detail break-up in Section III above may be referred in this
                                        respect
                                    </p>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </li>
            </ol>
            <p style={{ pageBreakAfter: 'always' }}>&nbsp;</p>
            <div className='fixed w-[595pt] h-[400pt] flex flex-col items-center justify-center opacity-20 pointer-events-none' style={{ zIndex: 9999 }}>
                <img src={Logo} alt="ITAA" className='w-[800] mx-auto h-[227px] pointer-events-none' style={{ transform: "rotate(-45deg)" }} />
            </div>
            <ol id="l1">
                <li>
                    <table style={{ borderCollapse: "collapse", marginLeft: "38pt", marginTop: "8pt" }} cellSpacing={0}>
                        <tbody>
                            <tr style={{ height: "57pt" }}>
                                <td
                                    style={{
                                        width: "137pt",
                                        borderTopStyle: "solid",
                                        borderTopWidth: "1pt",
                                        borderLeftStyle: "solid",
                                        borderLeftWidth: "1pt",
                                        borderBottomStyle: "solid",
                                        borderBottomWidth: "1pt",
                                        borderRightStyle: "solid",
                                        borderRightWidth: "1pt"
                                    }}
                                >
                                    <p
                                        className={styles.s4}
                                        style={{
                                            paddingTop: "1pt",
                                            paddingLeft: "4pt",
                                            textIndent: "0pt",
                                            textAlign: "left",
                                            paddingBottom: "1pt",
                                            lineHeight: "10pt",
                                            margin: "0"
                                        }}
                                    >
                                        4B(2)
                                    </p>
                                    <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                        <br />
                                    </p>
                                    <p
                                        className={styles.s4}
                                        style={{
                                            paddingLeft: "4pt",
                                            paddingRight: "15pt",
                                            textIndent: "0pt",
                                            textAlign: "left",
                                            paddingBottom: "1pt",
                                            lineHeight: "10pt",
                                            margin: "0"
                                        }}
                                    >
                                        ITC reversed – (2) Others
                                    </p>
                                </td>
                                <td
                                    style={{
                                        width: "111pt",
                                        borderTopStyle: "solid",
                                        borderTopWidth: "1pt",
                                        borderLeftStyle: "solid",
                                        borderLeftWidth: "1pt",
                                        borderBottomStyle: "solid",
                                        borderBottomWidth: "1pt",
                                        borderRightStyle: "solid",
                                        borderRightWidth: "1pt"
                                    }}
                                >
                                    <p
                                        className={styles.s4}
                                        style={{
                                            paddingTop: "1pt",
                                            paddingLeft: "4pt",
                                            textIndent: "0pt",
                                            textAlign: "left",
                                            paddingBottom: "1pt",
                                            lineHeight: "10pt",
                                            margin: "0"
                                        }}
                                    >
                                        Table 3 Part B Section I<br /><br />Table 4 Part B Section I
                                    </p>
                                </td>
                                <td
                                    style={{
                                        width: "289pt",
                                        borderTopStyle: "solid",
                                        borderTopWidth: "1pt",
                                        borderLeftStyle: "solid",
                                        borderLeftWidth: "1pt",
                                        borderBottomStyle: "solid",
                                        borderBottomWidth: "1pt",
                                        borderRightStyle: "solid",
                                        borderRightWidth: "1pt"
                                    }}
                                >
                                    <p
                                        className={styles.s4}
                                        style={{
                                            paddingTop: "1pt",
                                            paddingLeft: "9pt",
                                            paddingRight: "4pt",
                                            textIndent: "0pt",
                                            textAlign: "left",
                                            paddingBottom: "1pt",
                                            lineHeight: "10pt",
                                            margin: "0"
                                        }}
                                    >
                                        This is auto-populated from FORM GSTR-2B. This covers the following:
                                    </p>
                                    <p style={{
                                        textIndent: "0pt", textAlign: "left",
                                        paddingBottom: "1pt",
                                        lineHeight: "10pt",
                                        margin: "0"
                                    }}>
                                        <br />
                                    </p>
                                    <ol>
                                        <li className={styles.s4} style={{ marginLeft: "16pt", marginBottom: "1pt" }}><p style={{ margin: "0" }}>a. Positive values of all credit notes, on which ITC is available. If this is negative, then credit may be reclaimed subject to reversal of the same on an earlier instance.</p></li>
                                        <li className={styles.s4} style={{ marginLeft: "16pt", marginBottom: "1pt" }}><p style={{ margin: "0" }}>b. Positive values of all credit notes, on which ITC is not available.</p></li>
                                        <li className={styles.s4} style={{ marginLeft: "16pt", marginBottom: "1pt" }}><p style={{ margin: "0" }}>c. In case there are net negative values in ITC available table 4A(1,3,4,5), then such negative values shall be considered in this table.</p></li>
                                    </ol>
                                    <p
                                        className={styles.s4}
                                        style={{
                                            paddingLeft: "9pt",
                                            paddingTop: "9pt",
                                            textIndent: "0pt",
                                            textAlign: "left",
                                            paddingBottom: "1pt",
                                            lineHeight: "10pt",
                                            margin: "0"
                                        }}
                                    >
                                        Detail break-up in Section III above may be referred in this respect.
                                    </p>
                                </td>
                            </tr>
                            <tr style={{ height: "120pt" }}>
                                <td
                                    style={{
                                        width: "137pt",
                                        borderTopStyle: "solid",
                                        borderTopWidth: "1pt",
                                        borderLeftStyle: "solid",
                                        borderLeftWidth: "1pt",
                                        borderBottomStyle: "solid",
                                        borderBottomWidth: "1pt",
                                        borderRightStyle: "solid",
                                        borderRightWidth: "1pt"
                                    }}
                                >
                                    <p
                                        className={styles.s4}
                                        style={{
                                            paddingTop: "1pt",
                                            paddingLeft: "4pt",
                                            textIndent: "0pt",
                                            textAlign: "left",
                                            paddingBottom: "1pt",
                                            lineHeight: "10pt",
                                            margin: "0"
                                        }}
                                    >
                                        5.1
                                    </p>
                                    <p style={{
                                        textIndent: "0pt", textAlign: "left",
                                        paddingBottom: "1pt",
                                        lineHeight: "10pt",
                                        margin: "0"
                                    }}>
                                        <br />
                                    </p>
                                    <p
                                        className={styles.s4}
                                        style={{
                                            paddingLeft: "4pt",
                                            textIndent: "0pt",
                                            textAlign: "left",
                                            paddingBottom: "1pt",
                                            lineHeight: "10pt",
                                            margin: "0"
                                        }}
                                    >
                                        Interest
                                    </p>
                                </td>
                                <td
                                    style={{
                                        width: "111pt",
                                        borderTopStyle: "solid",
                                        borderTopWidth: "1pt",
                                        borderLeftStyle: "solid",
                                        borderLeftWidth: "1pt",
                                        borderBottomStyle: "solid",
                                        borderBottomWidth: "1pt",
                                        borderRightStyle: "solid",
                                        borderRightWidth: "1pt"
                                    }}
                                >
                                    <p
                                        className={styles.s4}
                                        style={{
                                            paddingTop: "1pt",
                                            paddingLeft: "4pt",
                                            textIndent: "0pt",
                                            textAlign: "left",
                                            paddingBottom: "1pt",
                                            lineHeight: "10pt",
                                            margin: "0"
                                        }}
                                    >
                                        NA
                                    </p>
                                </td>
                                <td
                                    style={{
                                        width: "289pt",
                                        borderTopStyle: "solid",
                                        borderTopWidth: "1pt",
                                        borderLeftStyle: "solid",
                                        borderLeftWidth: "1pt",
                                        borderBottomStyle: "solid",
                                        borderBottomWidth: "1pt",
                                        borderRightStyle: "solid",
                                        borderRightWidth: "1pt"
                                    }}
                                >
                                    <p
                                        className={styles.s4}
                                        style={{
                                            paddingTop: "1pt",
                                            paddingLeft: "4pt",
                                            paddingRight: "4pt",
                                            textIndent: "0pt",
                                            textAlign: "left",
                                            paddingBottom: "1pt",
                                            lineHeight: "10pt",
                                            margin: "0"
                                        }}
                                    >
                                        This is computed based on the breakup of tax liability provided
                                        in the previous tax period.
                                    </p>
                                    <p style={{
                                        textIndent: "0pt", textAlign: "left",
                                        paddingBottom: "1pt",
                                        lineHeight: "10pt",
                                        margin: "0"
                                    }}>
                                        <br />
                                    </p>
                                    <p
                                        className={styles.s4}
                                        style={{
                                            paddingLeft: "4pt",
                                            paddingRight: "4pt",
                                            textIndent: "0pt",
                                            textAlign: "left",
                                            paddingBottom: "1pt",
                                            lineHeight: "10pt",
                                            margin: "0"
                                        }}
                                    >
                                        Liability pertaining to the same return period, interest has
                                        been computed on the amount paid by debiting the electronic cash
                                        ledger.
                                    </p>
                                    <p style={{
                                        textIndent: "0pt", textAlign: "left",
                                        paddingBottom: "1pt",
                                        lineHeight: "10pt",
                                        margin: "0"
                                    }}>
                                        <br />
                                    </p>
                                    <p
                                        className={styles.s4}
                                        style={{
                                            paddingLeft: "4pt",
                                            paddingRight: "4pt",
                                            textIndent: "0pt",
                                            textAlign: "left",
                                            paddingBottom: "1pt",
                                            lineHeight: "10pt",
                                            margin: "0"
                                        }}
                                    >
                                        Liability pertaining to the pervious return period(s), interest
                                        has been computed on total liability.
                                    </p>
                                    <p style={{
                                        textIndent: "0pt", textAlign: "left",
                                        paddingBottom: "1pt",
                                        lineHeight: "10pt",
                                        margin: "0"
                                    }}>
                                        <br />
                                    </p>
                                    <p
                                        className={styles.s4}
                                        style={{
                                            paddingLeft: "4pt",
                                            paddingRight: "4pt",
                                            textIndent: "0pt",
                                            textAlign: "left",
                                            paddingBottom: "1pt",
                                            lineHeight: "10pt",
                                            margin: "0"
                                        }}
                                    >
                                        For quarterly taxpayers the interest is calculated after
                                        reducing the liability to the extent of cash paid in first two
                                        months of the quarter.
                                    </p>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </li>
                <li className={styles.liA} data-list-text={4}>
                    <p style={{ paddingLeft: "36pt", textIndent: "-9pt", textAlign: "left", margin: "0" }}>
                        4. If you have issued invoice and credit note in same month, then both shall be reported separately.
                    </p>
                </li>
                <li className={styles.liA} data-list-text={5}>
                    <p
                        style={{
                            paddingLeft: "36pt",
                            textIndent: "-9pt",
                            textAlign: "left", margin: "0"
                        }}
                    >
                        5. In case you have not filed FORM GSTR-1 for the period, system generated summary will display the respective values as ‘Not Filed’.
                    </p>
                </li>
                <li className={styles.liA} data-list-text={6}>
                    <p
                        style={{
                            paddingLeft: "36pt",
                            textIndent: "-9pt",
                            lineHeight: "151%",
                            textAlign: "left", margin: "0"
                        }}
                    >
                        6. Similarly, if FORM GSTR-2B is not generated for the period, system generated summary will display the respective values as ‘Not Generated’.
                    </p>
                </li>
                <li className={styles.liA} data-list-text={7}>
                    <p
                        style={{
                            paddingLeft: "36pt",
                            textIndent: "-9pt",
                            lineHeight: "151%",
                            textAlign: "left", margin: "0"
                        }}
                    >
                        7. Similarly, if FORM GSTR-3B is not filed for the previous period/or is not applicable, system generated summary will display the respective value as ‘Not Available’.
                    </p>
                </li>
                <li className={styles.liA} data-list-text={8}>
                    <p style={{ paddingLeft: "36pt", textIndent: "-9pt", textAlign: "left", margin: "0" }}>
                        8. In case net ITC available in table 4C is in negative, then such negative value shall be considered as liability.
                    </p>
                </li>
                <li className={styles.liA} data-list-text={9}>
                    <p
                        style={{
                            paddingLeft: "36pt",
                            textIndent: "-9pt",
                            textAlign: "left", margin: "0"
                        }}
                    >
                        9. Table 5 of FORM GSTR-3B is not part of this PDF.
                    </p>
                </li>
            </ol>
            <p style={{ textIndent: "0pt", textAlign: "left", marginTop: '60pt' }} />
        </div>
    );
}
