
import React from 'react';
import { useNavigate } from 'react-router-dom';

import { useRecoilState } from 'recoil';
import { adminAtom, adminPathAtom } from '../../../core/config/atoms';

import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';

import itaaLogo from '../../../assets/images/logoblue.png';

export default function UINavigationBar() {

    const [isAdmin, setIsAdmin] = useRecoilState(adminAtom);
    const [aPath, setAPath] = useRecoilState(adminPathAtom);

    const navigate = useNavigate();

    const changeMainMenu = (main) => {
        setAPath({ main: main, sub: 'sa1' });
    }

    const logout = () => {
        setIsAdmin(false);
        navigate('/');
    }

    return (
        <>
            <div className="h-screen shadow-md bg-white xs:block w-44">
                <span className="cursor-pointer">
                    <div className="w-36 mx-auto mt-8">
                        <img src={itaaLogo} className="w-36 m-auto py-2" />
                    </div>
                </span>
                <ul className="flex flex-col items-center list-none">
                    <Tippy content="Student Logs" placement='right'>
                        <li className={`cursor-pointer w-20 mt-8 rounded-md ${aPath['main'] === 'stdlog' ? 'bg-green-600 hover:bg-green-700 text-white' : 'hover:bg-green-100 text-gray-900'}`} onClick={() => { changeMainMenu('stdlog') }}>
                            <i className="las la-user-graduate w-full text-5xl text-center p-3"></i>
                        </li>
                    </Tippy>
                    <Tippy content="Student Managment" placement='right'>
                        <li className={`cursor-pointer w-20 mt-8 rounded-md ${aPath['main'] === 'stdman' ? 'bg-green-600 hover:bg-green-700 text-white' : 'hover:bg-green-100 text-gray-900'}`} onClick={() => { changeMainMenu('stdman') }}>
                            <i className="las la-user-edit w-full text-5xl text-center p-3"></i>
                        </li>
                    </Tippy>
                    <Tippy content="Faculty Logs" placement='right'>
                        <li className={`cursor-pointer w-20 mt-8 rounded-md ${aPath['main'] === 'faclog' ? 'bg-green-600 hover:bg-green-700 text-white' : 'hover:bg-green-100 text-gray-900'}`} onClick={() => { changeMainMenu('faclog') }}>
                            <i className="las la-user-tie w-full text-5xl text-center p-3"></i>
                        </li>
                    </Tippy>
                    <Tippy content="User Managment" placement='right'>
                        <li className={`cursor-pointer w-20 mt-8 rounded-md ${aPath['main'] === 'facman' ? 'bg-green-600 hover:bg-green-700 text-white' : 'hover:bg-green-100 text-gray-900'}`} onClick={() => { changeMainMenu('facman') }}>
                            <i className="las la-user-cog w-full text-5xl text-center p-3"></i>
                        </li>
                    </Tippy>
                    <Tippy content="Logout" placement='right'>
                        <li className={`cursor-pointer w-20 mt-8 rounded-md ${aPath['main'] === 'logout' ? 'bg-red-600 hover:bg-red-700 text-white' : 'hover:bg-red-400 text-gray-900 hover:text-white'}`} onClick={() => { logout('setting') }}>
                            <i className="las la-sign-out-alt w-full text-5xl text-center p-3"></i>
                        </li>
                    </Tippy>
                </ul>
            </div>
        </>
    );

}