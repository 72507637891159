import React, { useState, useEffect } from 'react';

import { useRecoilState } from 'recoil';
import { pathAtom, gstrpathAtom, digErrorAtom, digMainErrorAtom, returnDateAtom, loadingAtom, gstrUserAtom, redirectAtom, gstrFileStatusAtom, userIDAtom } from '../../core/config/atoms';

import Moment from 'moment';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import { Dialog } from '@headlessui/react';

import ServiceLMS from '../../services/serviceLMS';
import ServiceGSTR from '../../services/serviceGSTR';

import imgLoader from '../../assets/images/cloading.gif';
import processedImg from '../../assets/images/icons/processed.png';
import errorImg from '../../assets/images/icons/error.png';
import icDone from '../../assets/images/icons/done.png';

export default function PanelGSTRForm() {

    const [path, setPath] = useRecoilState(pathAtom);
    const [gstrPath, setGSTRPath] = useRecoilState(gstrpathAtom);
    const [digError, setDigError] = useRecoilState(digErrorAtom);
    const [digMainError, setDigMainError] = useRecoilState(digMainErrorAtom);
    const [gDate] = useRecoilState(returnDateAtom);
    const [loading, setLoading] = useRecoilState(loadingAtom);
    const [GSTUser, setGSTUser] = useRecoilState(gstrUserAtom);
    const [redirect, setRedirect] = useRecoilState(redirectAtom);
    const [fileStatus, setFileStatus] = useRecoilState(gstrFileStatusAtom);
    const [userID] = useRecoilState(userIDAtom);

    const [gstrFile, setGSTRFile] = useState({});
    const [digConfirm, setDigConfirm] = useState({ 'type': '0', isToggle: false, data: null });

    const [isTabA, setIsTabA] = useState(true);
    const [isTabB, setIsTabB] = useState(false);
    const [isTabC, setIsTabC] = useState(false);
    const [isNil, setIsNil] = useState(false);
    const [isAlertA, setIsAlrtA] = useState(false);

    const lmsService = new ServiceLMS();
    const returnService = new ServiceGSTR();

    const gotoPath = (path) => {
        setPath(path);
    }

    const gotoGstrPath = (path) => {
        setGSTRPath(path);
        gotoPath('gstrFile');
    }

    const gotoURL = (url) => {
        setRedirect({ url: url, isToggle: true });
    }

    const showLoading = () => {
        setLoading(true);
        setTimeout(
            () => setLoading(false),
            400
        );
    }

    const loadData = () => {
        setLoading(true);
        (async function () {
            let body = {
                id: userID
            };
            var res = await lmsService.getLMSUserByID(body);
            if (res.status) {
                setGSTUser(res.data['B']);
            } else {
                setDigError({ title: 'Error', msg: 'Server error please try again!', isToggle: true });
            }

            var resFiles = await returnService.getGstrFiles({ lid: userID, month: gDate });
            setGSTRFile(resFiles.data);

            if (resFiles.data.status === "done") {
                setFileStatus(true);
            } else {
                setFileStatus(false);
            }
        })();
    }

    const resetSummery = () => {
        setLoading(true);
        let body = {
            lid: userID,
            month: gDate,
        };
        returnService.resetGstrSummery(body).then((res) => {
            if (!res.status) {
                setDigError({ title: 'Error', msg: 'Server error please try again!', isToggle: true });
            }
            loadData();
            window.scrollTo({
                top: 0,
                behavior: 'smooth',
            });
        });
    }

    const genSummery = () => {
        let error = "1";
        for (var key in gstrFile.process) {
            if (key == "a12") {
                if (gstrFile.process["b2b"].c > 0 && gstrFile.process[key].c == 0) {
                    error = "3";
                    break;
                }
            }
            if (gstrFile.process[key].p > 0) {
                error = "2";
                break;
            }
        }
        if (error != "1") {
            showAlert(error);
        } else {
            showAlert(error);
            setLoading(true);
            let body = {
                lid: userID,
                month: gDate,
            };
            returnService.GenGstrSummary(body).then((res) => {
                if (res.status) {
                    if (isNil) {
                        setLoading(true);
                        gotoPath('gstrFileValid');
                    } else {
                        gotoPath('gstrSummary');
                    }
                } else {
                    setLoading(false);
                    setDigError({ title: 'Error', msg: 'Server error please try again!', isToggle: true });
                }
            });
        }
    }

    const showAlert = (type) => {
        switch (type) {
            case "1":
                setIsAlrtA(true);
                setTimeout(
                    () => setIsAlrtA(false),
                    5000
                );
                break;
            case "2":
                setDigError({ title: '', msg: 'There are some records which are errored out. Please resolve those issues before generating the GSTR-1/IFF summary.', isToggle: true });
                break;
            case "3":
                setDigConfirm({ 'type': "1", isToggle: true, data: null });
                break;
        }
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    }

    const getStatus = () => {
        switch (gstrFile.status) {
            case "new":
                return "Not Filed";
            case "draft":
                return "Ready to File";
            case "done":
                return "Filed";
        }
    }

    const getStartY = () => {
        if (new Date(Moment(gDate).format('MM-DD-yyyy')).getMonth() < 3) {
            return Moment(gDate).subtract(1, 'year').format('YYYY');
        } else {
            return Moment(gDate).format('YYYY');
        }
    }

    const getEndY = () => {
        if (new Date(Moment(gDate).format('MM-DD-yyyy')).getMonth() < 3) {
            return Moment(gDate).format('YY');
        } else {
            return Moment(gDate).add(1, 'year').format('YY');
        }
    }

    const changeIsNil = () => {
        var isDocs = false;
        for (var item in gstrFile.process) {
            if (gstrFile.process[item].c > 0) {
                isDocs = true;
                break;
            }
            if (gstrFile.process[item].p > 0) {
                isDocs = true;
                break;
            }
        }
        if (isDocs) {
            setDigMainError({ title: 'Error', msg: 'You have saved records in form GSTR-1/IFF for this period. Please delete or reset the saved records before you select File NIL GSTR-1', isToggle: true });
        } else {
            setIsNil(!isNil);
        }
    }

    const process = () => {
        switch (digConfirm.type) {
            case '1':
                gotoGstrPath('a12');
                break;
            case '2':
                resetSummery();
                break;
            default:
                break;
        }
        setDigConfirm({ 'type': '0', isToggle: false, data: null });
    }

    const cancel = () => {
        setDigConfirm({ 'type': '0', isToggle: false, data: null });
    }

    useEffect(() => {
        if (gstrFile !== {}) {
            setLoading(false);
            window.scrollTo({
                top: 0,
                behavior: 'smooth',
            });
        }
    }, [gstrFile]);

    useEffect(() => {
        loadData();
    }, []);

    return (
        <>
            <Dialog open={digConfirm.isToggle} onClose={() => setDigConfirm({ 'type': '0', isToggle: false, data: null })}>
                <div id="confirmDlg" class="modal fade fade-scale in" role="dialog" style={{ display: 'block', paddingRight: '17px' }}>
                    <div class="modal-dialog sweet">
                        <div class="modal-content">
                            <div class="modal-body">
                                <div class="m-icon m-warning pulseWarning">
                                    <span class="micon-body pulseWarningIns"></span>
                                    <span class="micon-dot pulseWarningIns"></span>
                                </div>
                                <h2>Error</h2>
                                {digConfirm.type == "2" && <p>All the details saved in different tables shall be deleted after reset. Are you sure, you want to reset the already saved data?</p>}
                                {digConfirm.type == "1" && <p>There are some records in B2B. Please add HSN Summary records before generating the GSTR-1/IFF summary.</p>}
                            </div>
                            <div class="modal-footer">
                                <a class="btn btn-default" onClick={() => { cancel() }}>
                                    {digConfirm.type == "2" ? 'No' : 'Cancel'}
                                </a>
                                <a class="btn btn-primary" onClick={() => { process() }}>
                                    {digConfirm.type == "2" ? 'Yes' : 'Proceed'}
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </Dialog>
            <div class="content-wrapper fade-ng-cloak">
                <div class="dimmer-holder" style={{ display: 'none' }}>
                    <div id="dimmer"></div>
                </div>
                {
                    loading && <div className='absolute z-50 w-full h-screen flex items-center justify-center bg-gray-200 bg-opacity-25'>
                        <img src={imgLoader} alt="loader" />
                    </div>
                }
                <div class="container">
                    {
                        !loading && <div class="mypage">
                            <div class="row" data-ng-controller="transctrl" data-ng-init="init('returns')">
                                <div class="col-xs-10">
                                    <div data-breadcrumb="" name="Dashboard">
                                        <ol class="breadcrumb" data-ng-controller="crumbCtrl">
                                            <li><a onClick={() => { gotoPath('gstr') }}>Dashboard</a></li>
                                            <li><a onClick={() => { gotoPath('gstRetrun') }}>Returns</a></li>
                                            <li>GSTR-1/IFF</li>
                                        </ol>
                                    </div>
                                </div>
                                <div class="col-xs-2">
                                    <div class="lang dropdown">
                                        <span class="dropdown-toggle" data-toggle="dropdown" data-ng-bind="selectedLang">English</span>
                                        <ul class="dropdown-menu lang-dpdwn">
                                            <li data-ng-repeat="language in languages">
                                                <a data-ng-click="selectLang(language.cd)" data-ng-bind="language.nm">English</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div>
                                {isAlertA && <alert-message ng-show="showMsg" title="" type="msg alert-success" message="" class="ng-hide">
                                    <div class="alert alert-msg alert-success flex items-center">
                                        <img src={icDone} alt='Done Image' className='w-6 h-6' />
                                        <p className='ml-2'>Your Generate Summary request has been received. Please check the status in sometime by clicking on 'Refresh' to view consolidated summary and file GSTR-1</p>
                                        <a class="close" onClick={() => { setIsAlrtA(false) }} data-dismiss="alert" aria-label="close">×</a><strong></strong>.
                                    </div>
                                </alert-message>}

                                <div class="col-lg-12">
                                    <div className='row'>
                                        <div>
                                            <div class="row" style={{ backgroundColor: '#17c4bb', color: 'white' }}>
                                                <div class="col-md-7 col-sm-7 col-xs-7">
                                                    <h4 class="pull-left" style={{ paddingTop: '0.3em', fontWeight: 'bold' }}>GSTR-1 - Details of outward supplies of goods or services</h4>
                                                </div>
                                                <div class="col-md-5 col-sm-5 col-xs-5">
                                                    <h4>
                                                        <button class="btn btn-primary btn-circle btn-sm pull-right" title="Refresh" style={{ marginTop: '0px' }} onClick={() => {
                                                            showLoading();
                                                        }}>
                                                            <i class="fa fa-refresh" aria-hidden="true"></i>
                                                        </button>
                                                        <div>
                                                            <button type="button" class="btn btn-primary btn-sm pull-right" style={{ marginTop: '0px', marginRight: '10px' }} onClick={() => {
                                                                gotoURL("https://tutorial.gst.gov.in/contextualhelp/Einv/index.htm#t=Dashboard.htm");
                                                            }}>
                                                                <span>Help</span>&nbsp;
                                                                <i class="fa fa-question-circle" aria-hidden="true"></i>
                                                            </button>
                                                            <a class="btn btn-primary btn-sm pull-right" style={{ marginTop: '0px', marginRight: '10px' }} onClick={() => {
                                                                gotoURL("https://tutorial.gst.gov.in/downloads/E-invoice_Advisory_GSTR-1_v2.pdf");
                                                            }}>e-Invoice Advisory</a>
                                                        </div>
                                                    </h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-sm-12 col-xs-12">
                                        <div class="row">
                                            <div class="panel panel-default">
                                                <div class="panel-body">
                                                    <div class="row">
                                                        <div class="col-sm-3 reg">
                                                            <span data-ng-bind="trans.LBL_GSTIN">GSTIN - </span>
                                                            <span data-ng-bind="main_page_gstin">{GSTUser.gstn}</span>
                                                        </div>
                                                        <div class="col-sm-3 reg">
                                                            <span data-ng-bind="trans.LBL_LEGAL_NAME">Legal Name - </span>
                                                            <span data-ng-bind="bus_name">{GSTUser.buzName.toUpperCase()}</span>
                                                        </div>
                                                        <div class="col-sm-3">
                                                            <span data-ng-bind="trans.LBL_TRADE_NAME">Trade Name - </span>
                                                            <span data-ng-bind="trade_name">{GSTUser.tname.toUpperCase()}</span>
                                                        </div>
                                                        <div class="col-sm-3">
                                                            <span class="mand-text" data-ng-bind="trans.HLP_MAND_FIELD">Indicates Mandatory Fields</span>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-sm-3">
                                                            <span data-ng-bind="trans.LBL_FY">FY - </span>
                                                            <span data-ng-bind="finyr">{getStartY()}-{getEndY()}</span>
                                                        </div>
                                                        <div class="col-sm-3">
                                                            <span data-ng-bind="trans.LBL_TAX_PERIOD_HEAD">Tax Period - </span>
                                                            <span data-ng-if="uPref =='M' || (uPref =='Q' &amp;&amp;  !enableGstr3bQuarterly) || IFF" data-ng-bind=" retprd ">{Moment(gDate).format("MMMM")}</span> </div>
                                                        <div class="col-sm-3" data-ng-show="!(fil_stat==='PE' || fil_stat==='ER')">
                                                            <span class="reg" data-ng-bind="trans.LBL_STATUS">Status - </span>
                                                            <span class="reg" data-ng-bind="fil_stat | STATUS">{getStatus()}</span>
                                                        </div>
                                                        <div class="col-sm-3" ng-if="formNameNew!='GSTR1-A'">
                                                            <span data-ng-if="IFF==false" data-ng-bind="trans.LBL_DUE_DATE">Due Date - </span>
                                                            <span data-ng-bind="due_dt">{Moment(gDate).add(1, 'month').add(10, 'days').format("DD/MM/YYYY")}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='row card'>
                                    <div class="col-xs-12">
                                        <div class="row" style={{ backgroundColor: '#ffffff' }}>
                                            <div class="panel-default bgWhite">
                                                <input type="checkbox" class="chkbx ng-pristine ng-untouched ng-valid ng-empty" id="nilCheckbox" name="nilCheckbox" onClick={() => {
                                                    changeIsNil();
                                                }} disabled={gstrFile.status === "done"} checked={isNil} />
                                                <label>File Nil GSTR-1</label>
                                            </div>
                                            {
                                                isNil && <div style={{ border: '1px solid #2c4e86', padding: '8px' }} >
                                                    <h5 className='text-blue-700' style={{ color: '#00f !important' }}><i><strong>Note: NIL Form GSTR-1 can be filed by you if you have:</strong></i></h5>
                                                    <ol className='text-blue-700' style={{ color: '#00f !important' }} type="a">
                                                        <li><span>No Outward Supplies (including supplies on which tax is to be charged on reverse charge basis, zero rated supplies and deemed exports) during the month or quarter for which the form is being filed for</span></li>
                                                        <li><span>No Amendments to be made to any of the supplies declared in an earlier form</span></li>
                                                        <li><span>No Credit or Debit Notes to be declared / amended</span></li>
                                                        <li><span>No details of advances received for services is to be declared or adjusted</span></li>
                                                    </ol>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    {
                                        !isNil && <>
                                            <div class="col-xs-12">
                                                <div class="row">
                                                    <button type="button" class="btn btn-primary col-xs-12 btn-lg text-left" onClick={() => { setIsTabA(!isTabA) }} style={{ textAlign: 'left', borderRadius: '0em', padding: '0.5em' }}>ADD RECORD DETAILS
                                                        <span class="text-right pull-right">
                                                            {!isTabA && <i class="fa fa-fw fa-chevron-down"></i>}
                                                            {isTabA && <i class="fa fa-fw fa-chevron-up"></i>}
                                                        </span>
                                                    </button>
                                                </div>
                                                {
                                                    isTabA && <div class="row">
                                                        <div class="row">
                                                            <Tippy content="Taxable outward supplies made to registered persons (including UIN-holders)" allowHTML={true} placement='top' className='text-center'>
                                                                <div class="col-lg-3" onClick={() => {
                                                                    gotoGstrPath('b2ba')
                                                                }}>
                                                                    <a>
                                                                        <div class={`headcard ${gstrFile.process.b2b.p != 0 ? 'errorHeadCard' : ''} `}>
                                                                            <div class="text-center" data-ng-bind="trans.LBL_B2B_INVOICES_GSTR1">4A, 4B, 6B, 6C - B2B, SEZ, DE Invoices</div>
                                                                            <br />
                                                                        </div>
                                                                        <div class="newcard">
                                                                            <div className='flex items-center justify-center'>
                                                                                {
                                                                                    !(gstrFile.process.b2b.p !== 0 && gstrFile.process.b2b.c === 0) && <span className="flex text-green-900" title="Processed Records" data-toggle="tooltip">
                                                                                        <img class="bigicon" src={processedImg} />
                                                                                        <span>{gstrFile.process.b2b.c}</span>&nbsp;
                                                                                    </span>
                                                                                }
                                                                                {
                                                                                    gstrFile.process.b2b.p !== 0 && <span class="flex text-red-600" title="Processed Records" data-toggle="tooltip">
                                                                                        <img class="bigicon" src={errorImg} />
                                                                                        <span>{gstrFile.process.b2b.p}</span>&nbsp;
                                                                                    </span>
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </a>
                                                                </div>
                                                            </Tippy>
                                                            <Tippy content="Taxable Outward Supplies to a unregistered person where Place of Supply ( State Code) is other than the state where supplier is located ( Inter-State Supplies) and Invoice value is more than Rs. 2.5 Lakh" allowHTML={true} placement='top' className='text-center'>
                                                                <div class="col-lg-3" onClick={() => {
                                                                    gotoGstrPath('b2cla')
                                                                }}>
                                                                    <a>
                                                                        <div class={`headcard ${gstrFile.process.b2cl.p != 0 ? 'errorHeadCard' : ''}`}>
                                                                            <div class="text-center" data-ng-bind="trans.LBL_B2C_INVOICES">5A - B2C (Large) Invoices</div>
                                                                            <br />
                                                                        </div>
                                                                        <div class="newcard">
                                                                            <div className='flex items-center justify-center'>
                                                                                {
                                                                                    !(gstrFile.process.b2cl.p !== 0 && gstrFile.process.b2cl.c === 0) && <span class="flex text-green-900" title="Processed Records" data-toggle="tooltip">
                                                                                        <img class="bigicon" src={processedImg} />
                                                                                        <span>{gstrFile.process.b2cl.c}</span>&nbsp;
                                                                                    </span>
                                                                                }
                                                                                {
                                                                                    gstrFile.process.b2cl.p !== 0 && <span class="flex text-red-600" title="Processed Records" data-toggle="tooltip">
                                                                                        <img class="bigicon" src={errorImg} />
                                                                                        <span>{gstrFile.process.b2cl.p}</span>&nbsp;
                                                                                    </span>
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </a>
                                                                </div>
                                                            </Tippy>
                                                            <Tippy content="Supplies Exported" allowHTML={true} placement='top' className='text-center'>
                                                                <div class="col-lg-3" onClick={() => {
                                                                    gotoGstrPath('a6a')
                                                                }}>
                                                                    <a>
                                                                        <div class={`headcard  ${gstrFile.process.a6.p != 0 ? 'errorHeadCard' : ''}`}>
                                                                            <div class="text-center" data-ng-bind="trans.LBL_EXPORTS_INVOICES">6A - Exports Invoices</div>
                                                                            <br />
                                                                        </div>
                                                                        <div class="newcard">
                                                                            <div className='flex items-center justify-center'>
                                                                                {
                                                                                    !(gstrFile.process.a6.p !== 0 && gstrFile.process.a6.c === 0) && <span class="flex text-green-900" title="Processed Records">
                                                                                        <img class="bigicon" src={processedImg} />
                                                                                        <span>{gstrFile.process.a6.c}</span>&nbsp;
                                                                                    </span>
                                                                                }
                                                                                {
                                                                                    gstrFile.process.a6.p !== 0 && <span class="flex text-red-600" title="Processed Records" data-toggle="tooltip">
                                                                                        <img class="bigicon" src={errorImg} />
                                                                                        <span>{gstrFile.process.a6.p}</span>&nbsp;
                                                                                    </span>
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </a>
                                                                </div>
                                                            </Tippy>
                                                            <Tippy content="Taxable Supplies(Net of debit notes and credit notes) to unregistered persons other than the supplies covered in Table 5" allowHTML={true} placement='top' className='text-center'>
                                                                <div class="col-lg-3" onClick={() => {
                                                                    gotoGstrPath('b2coa')
                                                                }}>
                                                                    <a>
                                                                        <div class={`headcard  ${gstrFile.process.b2co.p != 0 ? 'errorHeadCard' : ''}`}>
                                                                            <div class="text-center" data-ng-bind="trans.LBL_B2C_S">7 - B2C (Others)</div>
                                                                            <br />
                                                                        </div>
                                                                        <div class="newcard">
                                                                            <div className='flex items-center justify-center'>
                                                                                {
                                                                                    !(gstrFile.process.b2co.p !== 0 && gstrFile.process.b2co.c === 0) && <span class="flex text-green-900" title="Processed Records" data-toggle="tooltip">
                                                                                        <img class="bigicon" src={processedImg} />
                                                                                        <span>{gstrFile.process.b2co.c}</span>&nbsp;
                                                                                    </span>
                                                                                }
                                                                                {
                                                                                    gstrFile.process.b2co.p !== 0 && <span class="flex text-red-600" title="Processed Records" data-toggle="tooltip">
                                                                                        <img class="bigicon" src={errorImg} />
                                                                                        <span>{gstrFile.process.b2co.p}</span>&nbsp;
                                                                                    </span>
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </a>
                                                                </div>
                                                            </Tippy>
                                                        </div>
                                                        <div class="row">
                                                            <Tippy content="Nil rated,  Exempted  and Non GST outward supplies" allowHTML={true} placement='top' className='text-center'>
                                                                <div class="col-lg-3" onClick={() => {
                                                                    gotoGstrPath('nill')
                                                                }}>
                                                                    <a>
                                                                        <div class={`headcard ${gstrFile.process.nill.p != 0 ? 'errorHeadCard' : ''} `}>
                                                                            <div class="text-center" data-ng-bind="trans.HEAD_NIL">8A, 8B, 8C, 8D - Nil Rated Supplies</div>
                                                                            <br />
                                                                        </div>
                                                                        <div class="newcard">
                                                                            <div className='flex items-center justify-center'>
                                                                                {
                                                                                    !(gstrFile.process.nill.p !== 0 && gstrFile.process.nill.c === 0) && <span class="flex text-green-900" title="Processed Records" data-toggle="tooltip">
                                                                                        <img class="bigicon" src={processedImg} />
                                                                                        <span>{gstrFile.process.nill.c}</span>&nbsp;
                                                                                    </span>
                                                                                }
                                                                                {
                                                                                    gstrFile.process.nill.p !== 0 && <span class="flex text-red-600" title="Processed Records" data-toggle="tooltip">
                                                                                        <img class="bigicon" src={errorImg} />
                                                                                        <span>{gstrFile.process.nill.p}</span>&nbsp;
                                                                                    </span>
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </a>
                                                                </div>
                                                            </Tippy>
                                                            <Tippy content="Details of Credit / Debit Notes issued to registered taxpayers" allowHTML={true} placement='top' className='text-center'>
                                                                <div class="col-lg-3" onClick={() => {
                                                                    gotoGstrPath('cdra')
                                                                }}>
                                                                    <a>
                                                                        <div class={`headcard  ${gstrFile.process.cdr.p != 0 ? 'errorHeadCard' : ''}`}>
                                                                            <div class="text-center" data-ng-bind="trans.LBL_CRED_DEBT_NOTES_REG">9B - Credit / Debit Notes (Registered)</div>
                                                                            <br />
                                                                        </div>
                                                                        <div class="newcard">
                                                                            <div className='flex items-center justify-center'>
                                                                                {
                                                                                    !(gstrFile.process.cdr.p !== 0 && gstrFile.process.cdr.c === 0) && <span class="flex text-green-900" title="Processed Records" data-toggle="tooltip">
                                                                                        <img class="bigicon" src={processedImg} />
                                                                                        <span>{gstrFile.process.cdr.c}</span>&nbsp;
                                                                                    </span>
                                                                                }
                                                                                {
                                                                                    gstrFile.process.cdr.p !== 0 && <span class="flex text-red-600" title="Processed Records" data-toggle="tooltip">
                                                                                        <img class="bigicon" src={errorImg} />
                                                                                        <span>{gstrFile.process.cdr.p}</span>&nbsp;
                                                                                    </span>
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </a>
                                                                </div>
                                                            </Tippy>
                                                            <Tippy content="Details of Credit/Debit Notes for unregistered user" allowHTML={true} placement='top' className='text-center'>
                                                                <div class="col-lg-3" onClick={() => {
                                                                    gotoGstrPath('cdua')
                                                                }}>
                                                                    <a>
                                                                        <div class={`headcard  ${gstrFile.process.cdu.p != 0 ? 'errorHeadCard' : ''}`}>
                                                                            <div class="text-center" data-ng-bind="trans.LBL_CREDIT_DEBIT_NOTES_UNREG1">9B - Credit / Debit Notes (Unregistered)</div>
                                                                            <br />
                                                                        </div>
                                                                        <div class="newcard">
                                                                            <div className='flex items-center justify-center'>
                                                                                {
                                                                                    !(gstrFile.process.cdu.p !== 0 && gstrFile.process.cdu.c === 0) && <span class="flex text-green-900" title="Processed Records" data-toggle="tooltip">
                                                                                        <img class="bigicon" src={processedImg} />
                                                                                        <span>{gstrFile.process.cdu.c}</span>&nbsp;
                                                                                    </span>
                                                                                }
                                                                                {
                                                                                    gstrFile.process.cdu.p !== 0 && <span class="flex text-red-600" title="Processed Records" data-toggle="tooltip">
                                                                                        <img class="bigicon" src={errorImg} />
                                                                                        <span>{gstrFile.process.cdu.p}</span>&nbsp;
                                                                                    </span>
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </a>
                                                                </div>
                                                            </Tippy>
                                                            <Tippy content="Advance amount received in the tax period for which invoice has not been issued (tax amount to be added to output tax liability)." allowHTML={true} placement='top' className='text-center'>
                                                                <div class="col-lg-3" onClick={() => {
                                                                    gotoGstrPath('a11a')
                                                                }}>
                                                                    <a>
                                                                        <div class={`headcard  ${gstrFile.process.a11.p != 0 ? 'errorHeadCard' : ''}`}>
                                                                            <div class="text-center" data-ng-bind="trans.TITLE_TAX_LIABILITY">11A(1), 11A(2) - Tax Liability (Advances Received)</div>
                                                                            <br />
                                                                        </div>
                                                                        <div class="newcard">
                                                                            <div className='flex items-center justify-center'>
                                                                                {
                                                                                    !(gstrFile.process.a11.p !== 0 && gstrFile.process.a11.c === 0) && <span class="flex text-green-900" title="Processed Records" data-toggle="tooltip">
                                                                                        <img class="bigicon" src={processedImg} />
                                                                                        <span>{gstrFile.process.a11.c}</span>&nbsp;
                                                                                    </span>
                                                                                }
                                                                                {
                                                                                    gstrFile.process.a11.p !== 0 && <span class="flex text-red-600" title="Processed Records" data-toggle="tooltip">
                                                                                        <img class="bigicon" src={errorImg} />
                                                                                        <span>{gstrFile.process.a11.p}</span>&nbsp;
                                                                                    </span>
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </a>
                                                                </div>
                                                            </Tippy>
                                                        </div>
                                                        <div class="row">
                                                            <Tippy content="Advance amount received in earlier tax period and adjusted against the supplies being shown in this tax period in Table Nos. 4,5,6 and 7." allowHTML={true} placement='top' className='text-center'>
                                                                <div class="col-lg-3" onClick={() => {
                                                                    gotoGstrPath('b11a')
                                                                }}>
                                                                    <a>
                                                                        <div class={`headcard  ${gstrFile.process.b11.p != 0 ? 'errorHeadCard' : ''}`}>
                                                                            <div class="text-center" data-ng-bind="trans.TITLE_TAX_PAID1">11B(1), 11B(2) - Adjustment of Advances</div>
                                                                            <br />
                                                                        </div>
                                                                        <div class="newcard">
                                                                            <div className='flex items-center justify-center'>
                                                                                {
                                                                                    !(gstrFile.process.b11.p !== 0 && gstrFile.process.b11.c === 0) && <span class="flex text-green-900" title="Processed Records" data-toggle="tooltip">
                                                                                        <img class="bigicon" src={processedImg} />
                                                                                        <span>{gstrFile.process.b11.c}</span>&nbsp;
                                                                                    </span>
                                                                                }
                                                                                {
                                                                                    gstrFile.process.b11.p !== 0 && <span class="flex text-red-600" title="Processed Records" data-toggle="tooltip">
                                                                                        <img class="bigicon" src={errorImg} />
                                                                                        <span>{gstrFile.process.b11.p}</span>&nbsp;
                                                                                    </span>
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </a>
                                                                </div>
                                                            </Tippy>
                                                            <Tippy content="12 - HSN - wise summary of outward supplies." allowHTML={true} placement='top' className='text-center'>
                                                                <div class="col-lg-3" onClick={() => {
                                                                    gotoGstrPath('a12')
                                                                }}>
                                                                    <a>
                                                                        <div class={`headcard  ${gstrFile.process.a12.p != 0 ? 'errorHeadCard' : ''}`}>
                                                                            <div class="text-center" data-ng-bind="trans.TITLE_HSN_SAC">12 - HSN-wise summary of outward supplies</div>
                                                                            <br />
                                                                        </div>
                                                                        <div class="newcard">
                                                                            <div className='flex items-center justify-center'>
                                                                                {
                                                                                    !(gstrFile.process.a12.p !== 0 && gstrFile.process.a12.c === 0) && <span class="flex text-green-900" title="Processed Records" data-toggle="tooltip">
                                                                                        <img class="bigicon" src={processedImg} />
                                                                                        <span>{gstrFile.process.a12.c}</span>&nbsp;
                                                                                    </span>
                                                                                }
                                                                                {
                                                                                    gstrFile.process.a12.p !== 0 && <span class="flex text-red-600" title="Processed Records" data-toggle="tooltip">
                                                                                        <img class="bigicon" src={errorImg} />
                                                                                        <span>{gstrFile.process.a12.p}</span>&nbsp;
                                                                                    </span>
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </a>
                                                                </div>
                                                            </Tippy>
                                                            <Tippy content="13 - Documents issued during the tax period." allowHTML={true} placement='top' className='text-center'>
                                                                <div class="col-lg-3" onClick={() => {
                                                                    gotoGstrPath('a13')
                                                                }}>
                                                                    <a>
                                                                        <div class={`headcard  ${gstrFile.process.a13.p != 0 ? 'errorHeadCard' : ''}`}>
                                                                            <div class="text-center" data-ng-bind="trans.TITLE_DOC">13 - Documents Issued</div>
                                                                            <br />
                                                                        </div>
                                                                        <div class="newcard">
                                                                            <div className='flex items-center justify-center'>
                                                                                {
                                                                                    !(gstrFile.process.a13.p !== 0 && gstrFile.process.a13.c === 0) && <span class="flex text-green-900" title="Processed Records" data-toggle="tooltip">
                                                                                        <img class="bigicon" src={processedImg} />
                                                                                        <span>{gstrFile.process.a13.c}</span>&nbsp;
                                                                                    </span>
                                                                                }
                                                                                {
                                                                                    gstrFile.process.a13.p !== 0 && <span class="flex text-red-600" title="Processed Records" data-toggle="tooltip">
                                                                                        <img class="bigicon" src={errorImg} />
                                                                                        <span>{gstrFile.process.a13.p}</span>&nbsp;
                                                                                    </span>
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </a>
                                                                </div>
                                                            </Tippy>
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                            <div class="col-xs-12">
                                                <div class="row">
                                                    <button type="button" class="btn btn-primary col-xs-12 btn-lg text-left" onClick={() => { setIsTabB(!isTabB) }} style={{ textAlign: 'left', borderRadius: '0em', padding: '0.5em' }} data-ng-click="showAmendRecDetails = !showAmendRecDetails">AMEND RECORD DETAILS
                                                        <span class="text-right pull-right">
                                                            {!isTabB && <i class="fa fa-fw fa-chevron-down"></i>}
                                                            {isTabB && <i class="fa fa-fw fa-chevron-up"></i>}
                                                        </span>
                                                    </button>
                                                </div>
                                                {
                                                    isTabB && <div class="row">
                                                        <div class="row">
                                                            <Tippy content="Amendments to details of Outward Supplies to a registered person of earlier tax periods" allowHTML={true} placement='top' className='text-center'>
                                                                <div class="col-lg-3" onClick={() => {
                                                                    gotoGstrPath('ab2ba')
                                                                }}>
                                                                    <a>
                                                                        <div class={`headcard  ${gstrFile.process.ab2b.p != 0 ? 'errorHeadCard' : ''}`}>
                                                                            <div class="text-center" data-ng-bind="trans.LBL_AMNDED_B2B_INV">9A - Amended B2B Invoices</div>
                                                                            <br />
                                                                        </div>
                                                                        <div class="newcard">
                                                                            <div className='flex items-center justify-center'>
                                                                                {
                                                                                    !(gstrFile.process.ab2b.p !== 0 && gstrFile.process.ab2b.c === 0) && <span class="flex text-green-900" title="Processed Records" data-toggle="tooltip">
                                                                                        <img class="bigicon" src={processedImg} />
                                                                                        <span>{gstrFile.process.ab2b.c}</span>&nbsp;
                                                                                    </span>
                                                                                }
                                                                                {
                                                                                    gstrFile.process.ab2b.p !== 0 && <span class="flex text-red-600" title="Processed Records" data-toggle="tooltip">
                                                                                        <img class="bigicon" src={errorImg} />
                                                                                        <span>{gstrFile.process.ab2b.p}</span>&nbsp;
                                                                                    </span>
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </a>
                                                                </div>
                                                            </Tippy>
                                                            <Tippy content="Amendment to taxable Outward Supplies to an unregistered person of earlier tax periods where Place of Supply(State Code) is other than the State where supplier is located (Inter-state supplies) and Invoice value is more than Rs 2.5 lakh (optional in respect of other supplies) and Invoices issued against Advance Received in earlier periods" allowHTML={true} placement='top' className='text-center'>
                                                                <div class="col-lg-3" onClick={() => {
                                                                    gotoGstrPath('ab2cla')
                                                                }}>
                                                                    <a>
                                                                        <div class={`headcard  ${gstrFile.process.ab2cl.p != 0 ? 'errorHeadCard' : ''}`} >
                                                                            <div class="text-center" data-ng-bind="trans.LBL_AMNDD_B2C_INV">9A - Amended B2C (Large) Invoices</div>
                                                                            <br />
                                                                        </div>
                                                                        <div class="newcard">
                                                                            <div className='flex items-center justify-center'>
                                                                                {
                                                                                    !(gstrFile.process.ab2cl.p !== 0 && gstrFile.process.ab2cl.c === 0) && <span class="flex text-green-900" title="Processed Records" data-toggle="tooltip">
                                                                                        <img class="bigicon" src={processedImg} />
                                                                                        <span>{gstrFile.process.ab2cl.c}</span>&nbsp;
                                                                                    </span>
                                                                                }
                                                                                {
                                                                                    gstrFile.process.ab2cl.p !== 0 && <span class="flex text-red-600" title="Processed Records" data-toggle="tooltip">
                                                                                        <img class="bigicon" src={errorImg} />
                                                                                        <span>{gstrFile.process.ab2cl.p}</span>&nbsp;
                                                                                    </span>
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </a>
                                                                </div>
                                                            </Tippy>
                                                            <Tippy content="Amendment to Supplies Exported" allowHTML={true} placement='top' className='text-center'>
                                                                <div class="col-lg-3" onClick={() => {
                                                                    gotoGstrPath('aa6a')
                                                                }}>
                                                                    <a>
                                                                        <div class={`headcard  ${gstrFile.process.a9.p != 0 ? 'errorHeadCard' : ''}`} >
                                                                            <div class="text-center" data-ng-bind="trans.LBL_AMEND_EXP_INV">9A - Amended Exports Invoices</div>
                                                                            <br />
                                                                        </div>
                                                                        <div class="newcard">
                                                                            <div className='flex items-center justify-center'>
                                                                                {
                                                                                    !(gstrFile.process.a9.p !== 0 && gstrFile.process.a9.c === 0) && <span class="flex text-green-900" title="Processed Records" data-toggle="tooltip">
                                                                                        <img class="bigicon" src={processedImg} />
                                                                                        <span>{gstrFile.process.a9.c}</span>&nbsp;
                                                                                    </span>
                                                                                }
                                                                                {
                                                                                    gstrFile.process.a9.p !== 0 && <span class="flex text-red-600" title="Processed Records" data-toggle="tooltip">
                                                                                        <img class="bigicon" src={errorImg} />
                                                                                        <span>{gstrFile.process.a9.p}</span>&nbsp;
                                                                                    </span>
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </a>
                                                                </div>
                                                            </Tippy>
                                                            <Tippy content="Amendment to Details of Credit / Debit Notes issued to registered taxpayer in earlier tax periods" allowHTML={true} placement='top' className='text-center'>
                                                                <div class="col-lg-3" onClick={() => {
                                                                    gotoGstrPath('acdra')
                                                                }}>
                                                                    <a>
                                                                        <div class={`headcard  ${gstrFile.process.acdr.p != 0 ? 'errorHeadCard' : ''}`}>
                                                                            <div class="text-center" data-ng-bind="trans.LBL_AMENDED_CREDIT_DEBIT_NOTES_REG">9C - Amended Credit/Debit Notes (Registered)</div>
                                                                            <br />
                                                                        </div>
                                                                        <div class="newcard">
                                                                            <div className='flex items-center justify-center'>
                                                                                {
                                                                                    !(gstrFile.process.acdr.p !== 0 && gstrFile.process.acdr.c === 0) && <span class="flex text-green-900" title="Processed Records" data-toggle="tooltip">
                                                                                        <img class="bigicon" src={processedImg} />
                                                                                        <span>{gstrFile.process.acdr.c}</span>&nbsp;
                                                                                    </span>
                                                                                }
                                                                                {
                                                                                    gstrFile.process.acdr.p !== 0 && <span class="flex text-red-600" title="Processed Records" data-toggle="tooltip">
                                                                                        <img class="bigicon" src={errorImg} />
                                                                                        <span>{gstrFile.process.acdr.p}</span>&nbsp;
                                                                                    </span>
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </a>
                                                                </div>
                                                            </Tippy>
                                                        </div>
                                                        <div class="row ">
                                                            <Tippy content="Amendment to Details of Credit / Debit Notes issued to unregistered taxpayer in earlier tax periods" allowHTML={true} placement='top' className='text-center'>
                                                                <div class="col-lg-3" onClick={() => {
                                                                    gotoGstrPath('acdua')
                                                                }}>
                                                                    <a>
                                                                        <div class={`headcard ${gstrFile.process.acdu.p != 0 ? 'errorHeadCard' : ''}`}>
                                                                            <div class="text-center" data-ng-bind="trans.LBL_AMENDED_CREDIT_DEBIT_NOTES_UNREG">9C - Amended Credit/Debit Notes (Unregistered)</div>
                                                                            <br />
                                                                        </div>
                                                                        <div class="newcard">
                                                                            <div className='flex items-center justify-center'>
                                                                                {
                                                                                    !(gstrFile.process.acdu.p !== 0 && gstrFile.process.acdu.c === 0) && <span class="flex text-green-900" title="Processed Records" data-toggle="tooltip">
                                                                                        <img class="bigicon" src={processedImg} />
                                                                                        <span>{gstrFile.process.acdu.c}</span>&nbsp;
                                                                                    </span>
                                                                                }
                                                                                {
                                                                                    gstrFile.process.acdu.p !== 0 && <span class="flex text-red-600" title="Processed Records" data-toggle="tooltip">
                                                                                        <img class="bigicon" src={errorImg} />
                                                                                        <span>{gstrFile.process.acdu.p}</span>&nbsp;
                                                                                    </span>
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </a>
                                                                </div>
                                                            </Tippy>
                                                            <Tippy content="Amendment to Taxable Supplies (Net of debit notes and credit notes) to unregistered persons other than the supplies covered in Table 5." allowHTML={true} placement='top' className='text-center'>

                                                                <div class="col-lg-3" onClick={() => {
                                                                    gotoGstrPath('ab2coa')
                                                                }}>
                                                                    <a>
                                                                        <div class={`headcard ${gstrFile.process.ab2co.p != 0 ? 'errorHeadCard' : ''}`}>
                                                                            <div class="text-center" data-ng-bind="trans.LBL_AMD_B2C">10 - Amended B2C(Others)</div>
                                                                            <br />
                                                                        </div>
                                                                        <div class="newcard">
                                                                            <div className='flex items-center justify-center'>
                                                                                {
                                                                                    !(gstrFile.process.ab2co.p !== 0 && gstrFile.process.ab2co.c === 0) && <span class="flex text-green-900" title="Processed Records" data-toggle="tooltip">
                                                                                        <img class="bigicon" src={processedImg} />
                                                                                        <span>{gstrFile.process.ab2co.c}</span>&nbsp;
                                                                                    </span>
                                                                                }
                                                                                {
                                                                                    gstrFile.process.ab2co.p !== 0 && <span class="flex text-red-600" title="Processed Records" data-toggle="tooltip">
                                                                                        <img class="bigicon" src={errorImg} />
                                                                                        <span>{gstrFile.process.ab2co.p}</span>&nbsp;
                                                                                    </span>
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </a>
                                                                </div>
                                                            </Tippy>
                                                            <Tippy content="Consolidated Statement of Advances Received/Advance adjusted in the current tax period/ Amendments of information furnished in earlier tax period" allowHTML={true} placement='top' className='text-center'>
                                                                <div class="col-lg-3" onClick={() => {
                                                                    gotoGstrPath('aa11a')
                                                                }}>
                                                                    <a>
                                                                        <div class={`headcard ${gstrFile.process.aa11.p != 0 ? 'errorHeadCard' : ''}`}>
                                                                            <div class="text-center" data-ng-bind="trans.AMEND_TAX_LIAB">11A - Amended Tax Liability (Advances Received)</div>
                                                                            <br />
                                                                        </div>
                                                                        <div class="newcard">
                                                                            <div className='flex items-center justify-center'>
                                                                                {
                                                                                    !(gstrFile.process.aa11.p !== 0 && gstrFile.process.aa11.c === 0) && <span class="flex text-green-900" title="Processed Records" data-toggle="tooltip">
                                                                                        <img class="bigicon" src={processedImg} />
                                                                                        <span>{gstrFile.process.aa11.c}</span>&nbsp;
                                                                                    </span>
                                                                                }
                                                                                {
                                                                                    gstrFile.process.aa11.p !== 0 && <span class="flex text-red-600" title="Processed Records" data-toggle="tooltip">
                                                                                        <img class="bigicon" src={errorImg} />
                                                                                        <span>{gstrFile.process.aa11.p}</span>&nbsp;
                                                                                    </span>
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </a>
                                                                </div>
                                                            </Tippy>
                                                            <Tippy content="Advance amount received in earlier tax period and adjusted against the supplies being shown in this tax period in Table Nos. 4,5,6 and 7." allowHTML={true} placement='top' className='text-center'>
                                                                <div class="col-lg-3" onClick={() => {
                                                                    gotoGstrPath('ab11a')
                                                                }}>
                                                                    <a>
                                                                        <div class={`headcard ${gstrFile.process.ab11.p != 0 ? 'errorHeadCard' : ''}`}>
                                                                            <div class="text-center" data-ng-bind="trans.TTL_TXPDA">11B - Amendment of Adjustment of Advances</div>
                                                                            <br />
                                                                        </div>
                                                                        <div class="newcard">
                                                                            <div className='flex items-center justify-center'>
                                                                                {
                                                                                    !(gstrFile.process.ab11.p !== 0 && gstrFile.process.ab11.c === 0) && <span class="flex text-green-900" title="Processed Records" data-toggle="tooltip">
                                                                                        <img class="bigicon" src={processedImg} />
                                                                                        <span>{gstrFile.process.ab11.c}</span>&nbsp;
                                                                                    </span>
                                                                                }
                                                                                {
                                                                                    gstrFile.process.ab11.p !== 0 && <span class="flex text-red-600" title="Processed Records" data-toggle="tooltip">
                                                                                        <img class="bigicon" src={errorImg} />
                                                                                        <span>{gstrFile.process.ab11.p}</span>&nbsp;
                                                                                    </span>
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </a>
                                                                </div>
                                                            </Tippy>
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                            <div ng-if="!IFF" class="col-lg-12 alert alert-msg alert-info">
                                                <i class="fa fa-info"></i> The taxpayers for whom e-invoicing is not applicable may ignore the
                                                sections/options related to e-invoice download. The downloaded file would be blank in case taxpayer is not
                                                e-invoicing or when e-invoices reported to IRP are yet to be processed by GST system
                                            </div>
                                        </>
                                    }
                                    <div class="col-sm-12">
                                        <div class="row">
                                            <button class="btn btn-primary pull-left col-xs-12 btn-lg text-left" onClick={() => { setIsTabC(!isTabC) }} style={{ textAlign: 'left', borderRadius: '0em', padding: '0.5em' }} data-ng-click="getdownloadHistory(0)">E-INVOICE
                                                DOWNLOAD HISTORY
                                                <span data-ng-hide="tog4" class="text-right pull-right">
                                                    {!isTabC && <i class="fa fa-fw fa-chevron-down"></i>}
                                                    {isTabC && <i class="fa fa-fw fa-chevron-up"></i>}
                                                </span>
                                            </button>
                                        </div>
                                        {
                                            isTabC && <div class="row tabpane ng-hide" data-ng-show="tog4" ng-if="ENABLE_EINVOICE &amp;&amp; IFF==false" style={{ marginLeft: '0', marginRight: '0' }}>
                                                <div ng-if="!(excelURLs &amp;&amp; excelURLs.length>0)">
                                                    <div class="col-md-12">
                                                        <div class="alert alert-msg alert-info">
                                                            <i class="fa fa-info-circle"></i> No files available for download
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>

                                <div class="row mar-b">
                                    <div class="col-sm-12 text-right">
                                        <a type="button" class="btn btn-default" onClick={() => { gotoPath('gstRetrun') }}>Back</a>&nbsp;
                                        <button type="button" class="btn btn-primary" disabled={true} >DOWNLOAD DETAILS FROM E-INVOICES (EXCEL)</button>&nbsp;
                                        <button type="button" class="btn btn-primary" disabled={gstrFile.status === "done"} onClick={() => {
                                            setDigConfirm({ 'type': "2", isToggle: true, data: null })
                                        }}>RESET</button>&nbsp;
                                        {
                                            isNil && <>
                                                <button type="button" class="btn btn-primary" onClick={() => {
                                                    genSummery();
                                                }}>FILE STATEMENT</button>
                                            </>
                                        }
                                        {
                                            (gstrFile.status === "new" && !isNil) && <button type="button" class="btn btn-primary" onClick={() => {
                                                genSummery();
                                            }}>GENERATE SUMMARY</button>
                                        }
                                        {
                                            (gstrFile.status !== "new" && !isNil) && <button type="button" class="btn btn-primary ng-hide" onClick={() => {
                                                if (gstrFile.status === "draft") {
                                                    genSummery();
                                                } else {
                                                    setLoading(true);
                                                    gotoPath('gstrSummary');
                                                }
                                            }}>
                                                {gstrFile.status === "draft" && <span data-ng-show="fil_stat!='FIL'">PROCEED TO FILE/SUMMARY</span>}
                                                {gstrFile.status === "done" && <span data-ng-show="fil_stat=='FIL'" class="ng-hide">VIEW SUMMARY</span>}
                                            </button>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </>
    );
}