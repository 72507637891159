import React, { useEffect, useState } from 'react';

import { useRecoilState } from 'recoil';
import { pathAtom, digErrorAtom, gstrUserAtom, userIDAtom } from '../../core/config/atoms';

import ServiceLMS from '../../services/serviceLMS';

import Moment from 'moment';

import './css/style.css';
import './css/sub.css';

export default function PanelGSTRProfile() {

    const [path, setPath] = useRecoilState(pathAtom);
    const [digError, setDigError] = useRecoilState(digErrorAtom);
    const [GSTUser, setGSTUser] = useRecoilState(gstrUserAtom);
    const [userID] = useRecoilState(userIDAtom);

    const [uProms, setUProms] = useState([]);
    const [uAuths, setUAuths] = useState([]);

    const [LMSUser, setLMSUser] = useState({});

    const [mainTab, setMainTab] = useState('sa1');

    const [isLoading, setIsLoading] = useState(true);
    const [isTabA, setIsTabA] = useState(false);
    const [isTabB, setIsTabB] = useState(false);
    const [isTabC, setIsTabC] = useState(false);
    const [isTabD, setIsTabD] = useState(false);
    const [isTabE, setIsTabE] = useState(false);
    const [isTabF, setIsTabF] = useState(false);
    const [isTabG, setIsTabG] = useState(false);

    const lmsService = new ServiceLMS();

    const gotoPath = (path) => {
        setPath(path);
    }

    const changeTab = (tab) => {
        resetTabs();
        setMainTab(tab);
    }

    const resetTabs = () => {
        setIsTabA(false);
        setIsTabB(false);
        setIsTabC(false);

        setIsTabD(false);
        setIsTabE(false);

        setIsTabF(false);
        setIsTabG(false);
    }

    const loadData = () => {
        let body = {
            id: userID
        };
        setIsLoading(true);
        lmsService.getLMSUserByID(body).then((res) => {
            if (res.status) {
                setLMSUser(res.data['A']);
                setGSTUser(res.data['B']);
                let A = [];
                let B = [];
                res.data['B'].users.map((u) => {
                    if (u.isProm === '1') {
                        A.push(u);
                    }
                    if (u.isAuth === '1') {
                        B.push(u)
                    }
                });
                setUProms(A);
                setUAuths(B);
                setIsLoading(false);
            } else {
                setDigError({ title: 'Error', msg: 'Server error please try again!', isToggle: true });
            }
        });
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    }

    const getProfile = (key) => {
        switch (key) {
            case "BWH":
                return "Bonded Warehouse";
            case "ESE":
                return "EOU / STP / EHTP";
            case "EXP":
                return "Export";
            case "FMF":
                return "Factory / Manufacturing";
            case "IMP":
                return "Import";
            case "SOS":
                return "Supplier of Services";
            case "LBU":
                return "Leasing Business";
            case "OSO":
                return "Office / Sale Office";
            case "SRE":
                return "Recipient of Goods or Services";
            case "RBU":
                return "Retail Business";
            case "WHD":
                return "Warehouse / Depot";
            case "WBU":
                return "Wholesale Business";
            case "WCO":
                return "Works Contract";
            default:
                return "Others"
        }
    }

    useEffect(() => {
        loadData();
    }, []);

    return (
        <div class="content-wrapper">
            <div class="dimmer-holder" style={{ display: 'none' }}>
                <div id="dimmer"></div>
            </div>
            <div class="container">
                <div class="mypage">
                    <div class="row" data-ng-controller="transctrl">
                        <div class="col-xs-10">
                            <div data-breadcrumb="" data-target="_self" data-name="Dashboard">
                                <ol class="breadcrumb" data-ng-controller="crumbCtrl">
                                    {/* Todo: goto back action */}
                                    <li><a target="_self" data-ng-bind="name" onClick={() => { gotoPath('gstr') }}>Dashboard</a></li>
                                    <li><span ng-switch-when="true">My Profile</span></li>
                                </ol>
                            </div>
                        </div>
                    </div>
                    <div class="content-pane" style={{ minHeight: '493.125px' }}>
                        {
                            !isLoading && <div ng-view="">
                                <div class="tabpane">
                                    <div class="row">
                                        <div class="col-md-3 hidden-xs hidden-sm">
                                            <ul class="list-group">
                                                <li class="list-group-item text-center">
                                                    <h4 class="">Quick Links</h4>
                                                </li>
                                                <li class="list-group-item">
                                                    <a style={{ opacity: 0.7, pointerEvents: 'none' }}>History of Amendment</a>
                                                </li>
                                                <li class="list-group-item">
                                                    <a style={{ opacity: 0.7, pointerEvents: 'none' }}>Change Password</a>
                                                </li>
                                                <li class="list-group-item">
                                                    <a style={{ opacity: 0.7, pointerEvents: 'none' }}>Manage API Access</a>
                                                </li>
                                                <li class="list-group-item">
                                                    <a style={{ opacity: 0.7, pointerEvents: 'none' }}>Register / Update DSC</a>
                                                </li>
                                                <li class="list-group-item">
                                                    <a style={{ opacity: 0.7, pointerEvents: 'none' }}>Aadhaar Authentication Status</a>
                                                </li>
                                                <li class="list-group-item">
                                                    <a style={{ opacity: 0.7, pointerEvents: 'none' }}>My Registration Certificate</a>
                                                </li>
                                                <li class="list-group-item">
                                                    <a style={{ opacity: 0.7, pointerEvents: 'none' }}>Core Business Activity Status</a>
                                                </li>
                                                <li class="list-group-item">
                                                    <a style={{ opacity: 0.7, pointerEvents: 'none' }}>Bank Account Status</a>
                                                </li>
                                            </ul>
                                        </div>
                                        <div class="col-md-9">
                                            <div class="row">
                                                {/* Todo: make change in tabs pane */}
                                                <div class="col-xs-12">
                                                    <ul class="nav nav-pills nav-justified" id="pills-tab" role="tablist">
                                                        <li class={`nav-item ${mainTab == 'sa1' ? 'active' : ''}`}>
                                                            <a class="nav-link active" id="pills-business-tab" data-toggle="pill" role="tab" aria-controls="pills-business" aria-selected="true" data-ng-bind="trans.LBL_PROF" onClick={() => { changeTab('sa1') }}>Profile</a>
                                                        </li>
                                                        <li class={`nav-item ${mainTab == 'sa2' ? 'active' : ''}`}>
                                                            <a class="nav-link active" id="pills-partners-tab" data-toggle="pill" role="tab" aria-controls="pills-partners" aria-selected="true" data-ng-bind="trans.LBL_PLC_BUSI" onClick={() => { changeTab('sa2') }}>Place of Business</a>
                                                        </li>
                                                        <li class={`nav-item ${mainTab == 'sa3' ? 'active' : ''}`}>
                                                            <a class="nav-link active" id="pills-auth-tab" data-toggle="pill" role="tab" aria-controls="pills-auth" aria-selected="true" data-ng-bind="trans.LBL_CONTACTS" onClick={() => { changeTab('sa3') }}>Contacts</a>
                                                        </li>
                                                        <li class={`nav-item ${mainTab == 'sa4' ? 'active' : ''}`}>
                                                            <a class="nav-link active" id="pills-place-tab" data-toggle="pill" role="tab" aria-controls="pills-place" aria-selected="true" onClick={() => { changeTab('sa4') }}>Other Business</a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div class="row rettbl-format">
                                                <div class="col-xs-12">
                                                    <div class="tab-content panel-body panel panel-default" id="pills-tabContent">

                                                        <div class={`tab-pane fade ${mainTab == 'sa1' ? 'active in' : ''}`} id="pills-business" role="tabpanel" aria-labelledby="pills-business-tab">
                                                            <div class="row">
                                                                <div class="inner">
                                                                    <div class="row">
                                                                        <div class="col-md-4 col-sm-6 col-xs-6">
                                                                            <label class="reg show" data-ng-bind="trans.LBL_GSTIN">GSTIN/UIN</label>
                                                                            <label data-ng-bind="viewuser.gstin">{GSTUser.gstn}</label>
                                                                        </div>

                                                                        <div class="col-md-4 col-sm-6 col-xs-6">
                                                                            <label class="reg show" data-ng-bind="trans.LBL_LEAGAL_NAME_BUSI">Legal Name of Business</label>
                                                                            <label data-ng-bind="viewuser.lgnm">{GSTUser.buzName}</label>
                                                                        </div>

                                                                        <div class="col-md-4 col-sm-6 col-xs-6" data-ng-if="tradeNameFlag">
                                                                            <label class="reg show" data-ng-bind="trans.LBL_TRAD_NAME">Trade Name</label>
                                                                            <label data-ng-bind="viewuser.tradeNam">{GSTUser.tname}</label>
                                                                        </div>
                                                                    </div>
                                                                    <div class="row">
                                                                        <div class="col-md-4 col-sm-6 col-xs-6">
                                                                            <label class="reg show" data-ng-bind="trans.LBL_JURISDICTION_CENTER">Centre Jurisdiction</label>
                                                                            <label data-ng-if="ctjPattern" style={{ color: 'red' }} data-ng-bind="viewuser.ctj">Commissionerate - {GSTUser.plants[0].commJudi},Division - {GSTUser.plants[0].divJudi},Range - {GSTUser.plants[0].rangeJudi} (Jurisdictional Office)</label>
                                                                        </div>

                                                                        <div class="col-md-4 col-sm-6 col-xs-6">
                                                                            <label class="reg show" data-ng-bind="trans.LBL_JURISDICTION_STATE">State Jurisdiction</label>
                                                                            <label data-ng-if="!stjPattern" data-ng-bind="viewuser.stj">State - {GSTUser.state},Zone - {GSTUser.plants[0].dst},Division - {GSTUser.plants[0].divJudi},Charge - {GSTUser.plants[0].commJudi}</label>
                                                                        </div>

                                                                        <div class="col-md-4 col-sm-6 col-xs-6">
                                                                            <label class="reg show" data-ng-bind="trans.LBL_DATE_REGI">Date of Registration</label>
                                                                            <label data-ng-bind="viewuser.rgdt">{Moment(GSTUser.updatedAt).format('DD/MM/YYYY')}</label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div class="row">
                                                                <div class="inner">

                                                                    <div class="col-md-4 col-sm-6 col-xs-6">
                                                                        <label class="reg show" data-ng-bind="trans.LBL_CONST_BUSI">Constitution of Business</label>
                                                                        <label data-ng-bind="viewuser.ctb">{GSTUser.ConstBuss === "PRO" ? "Proprietor" : ""}</label>
                                                                    </div>

                                                                    <div class="col-md-4 col-sm-6 col-xs-6">
                                                                        <label class="reg show" data-ng-bind="trans.LBL_VUP_TAXPYER">Taxpayer Type</label>
                                                                        <label data-ng-bind="viewuser.dty">Regular</label>
                                                                    </div>

                                                                    <div class="col-md-4 col-sm-6 col-xs-6">
                                                                        <label class="reg show" data-ng-bind="trans.LBL_GSTIN_STAT">GSTIN / UIN  Status</label>
                                                                        <label data-ng-bind="viewuser.sts">Active</label>

                                                                    </div>

                                                                    <div class="col-md-4 col-sm-6 col-xs-6">
                                                                        <label class="reg show" data-ng-bind="trans.LBL_COMP_RATNG">Compliance Rating</label>
                                                                        <label data-ng-bind="viewuser.cmpRt || 'NA'">NA</label>
                                                                    </div>

                                                                    <div class="col-md-4 col-sm-6 col-xs-6">
                                                                        <label class="reg show" data-ng-bind="trans.LBL_SITE_CNDCT">Field Visit Conducted?</label>
                                                                        <label data-ng-bind="viewuser.isFieldVisitConducted || 'NA'">No</label>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div class="row">
                                                                <div class="col-xs-12">
                                                                    <div id="accordion" class="panel-group">
                                                                        <div class="panel panel-default" style={{ marginTop: isTabA ? '20px' : '' }}>
                                                                            <div class="panel-heading">
                                                                                <h4 class="panel-title">
                                                                                    <a data-toggle="collapse" class="collapsed" aria-expanded="false" onClick={() => { setIsTabA(!isTabA) }}>Name of the Proprietor / Director(s) / Partner(s) / Promoter(s)</a>
                                                                                </h4>
                                                                            </div>
                                                                            <div id="collapseOne" class={`panel-collapse collapse ${isTabA ? 'in' : ''}`}>
                                                                                <div class="panel-body">
                                                                                    <ol>
                                                                                        {
                                                                                            uProms.map((u, index) => {
                                                                                                return < li > {index + 1}. {u.pd_fnm} {u.pd_mname} {u.pd_lname} </li>
                                                                                            })
                                                                                        }
                                                                                    </ol>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="panel panel-default" style={{ marginTop: isTabB ? '20px' : '' }}>
                                                                            <div class="panel-heading">
                                                                                <h4 class="panel-title">
                                                                                    <a data-toggle="collapse" class="collapsed" aria-expanded="false" onClick={() => { setIsTabB(!isTabB) }}>Nature of Business Activities</a>
                                                                                </h4>
                                                                            </div>
                                                                            <div id="collapseTwo" class={`panel-collapse collapse ${isTabB ? 'in' : ''}`}>
                                                                                <div class="panel-body">
                                                                                    <ol>
                                                                                        {
                                                                                            GSTUser.plants !== undefined && GSTUser.plants[0].boxs.map((ot, index) => {
                                                                                                return <li>{index + 1}. {getProfile(ot)}</li>
                                                                                            })
                                                                                        }
                                                                                    </ol>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="panel panel-default" style={{ marginTop: isTabC ? '20px' : '', color: 'red' }}>
                                                                            <div class="panel-heading">
                                                                                <h4 class="panel-title">
                                                                                    <a data-toggle="collapse" class="collapsed" aria-expanded="true" onClick={() => { setIsTabC(!isTabC) }}>Nature Of Core Business Activity</a>
                                                                                </h4>
                                                                            </div>
                                                                            <div id="collapseThree" class={`panel-collapse collapse ${isTabC ? 'in' : ''}`} aria-expanded="true">
                                                                                <div class="panel-body text-black">
                                                                                    NA
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class={`tab-pane fade ${mainTab == 'sa2' ? 'active in' : ''}`} id="pills-partners" role="tabpanel" aria-labelledby="pills-partners-tab">
                                                            <div class="row">
                                                                <div class="col-sm-12">
                                                                    <div class="table-responsive" data-ng-if="busplaceaddr.adadr.length > 0">
                                                                        <table ng-table="busiTable" class="table tbl inv table-bordered exp ng-table" style={{ display: 'table' }}>
                                                                            <thead>
                                                                                <tr>
                                                                                    <th>Type</th>
                                                                                    <th>Nature of Business</th>
                                                                                    <th>Address</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                <tr ng-repeat="item in $data">
                                                                                    <td data-ng-bind="item.type=='p' ? 'Principal' : 'Additional'">Principal</td>
                                                                                    <td data-ng-bind="item.ntr">
                                                                                        <ol>
                                                                                            {
                                                                                                GSTUser.plants !== undefined && GSTUser.plants[0].boxs.map((ot, index) => {
                                                                                                    return <li>{index + 1}. {getProfile(ot)}</li>
                                                                                                })
                                                                                            }
                                                                                        </ol>
                                                                                    </td>
                                                                                    <td data-ng-bind="item.adr">{GSTUser.plants[0].bno}, {GSTUser.plants[0].st}, {GSTUser.plants[0].dst}, {GSTUser.state}, {GSTUser.plants[0].pncd}</td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>
                                                                        <div ng-table-pagination="params" template-url="templates.pagination">
                                                                            <div ng-include="templateUrl">
                                                                                <div class="ng-table-pager" ng-if="params.data.length"></div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class={`tab-pane fade ${mainTab == 'sa3' ? 'active in' : ''}`} id="pills-auth" role="tabpanel" aria-labelledby="pills-auth-tab">
                                                            <div class="row">
                                                                <div class="col-xs-12">
                                                                    <div id="accordion1" class="panel-group">
                                                                        <div class="panel panel-default">
                                                                            <div class="panel-heading">
                                                                                <h4 class="panel-title">
                                                                                    <a data-toggle="collapse" onClick={() => { setIsTabD(!isTabD) }}>GST Practitioner</a>
                                                                                </h4>
                                                                            </div>
                                                                            <div id="collapsePract" class={`panel-collapse collapse ${isTabD ? 'in' : ''}`} aria-expanded="false">
                                                                                <div class="panel-body">
                                                                                    <div class="table-responsive">
                                                                                        <table class="table tbl inv table-bordered exp" style={{ display: 'table' }}>
                                                                                            <thead>
                                                                                                <tr>
                                                                                                    <th class="vrtcl-midl" data-ng-bind="trans.LBL_NAME">Name</th>
                                                                                                    <th class="vrtcl-midl" data-ng-bind="trans.LBL_MOB_NO">Mobile Number</th>
                                                                                                    <th class="vrtcl-midl" data-ng-bind="trans.LBL_E_ADDR">Email Address</th>
                                                                                                </tr>
                                                                                            </thead>
                                                                                            <tbody>
                                                                                                {
                                                                                                    GSTUser.gstRepID === undefined && <tr>
                                                                                                        <td>NA</td>
                                                                                                        <td>NA</td>
                                                                                                        <td>NA</td>
                                                                                                    </tr>
                                                                                                }
                                                                                                {
                                                                                                    GSTUser.gstRepID !== undefined && <tr>
                                                                                                        <td>Raju Shyam Tiwari</td>
                                                                                                        <td>1234567890</td>
                                                                                                        <td>raju@gmail.com</td>
                                                                                                    </tr>
                                                                                                }
                                                                                            </tbody>
                                                                                        </table>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="panel panel-default" style={{ marginTop: isTabE ? '20px' : '' }}>
                                                                            <div class="panel-heading">
                                                                                <h4 class="panel-title">
                                                                                    <a data-toggle="collapse" onClick={() => { setIsTabE(!isTabE) }}>Authorized Signatory</a>
                                                                                </h4>
                                                                            </div>
                                                                            <div id="collapseAuthSign" class={`panel-collapse collapse ${isTabE ? 'in' : ''}`} aria-expanded="true">
                                                                                <div class="panel-body">
                                                                                    <div class="table-responsive" data-ng-if="authorisedSignatoryDetl.length > 0">
                                                                                        <table class="table tbl inv table-bordered exp" style={{ display: 'table' }}>
                                                                                            <thead>
                                                                                                <tr>
                                                                                                    <th class="vrtcl-midl" data-ng-bind="trans.LBL_NAME">Name</th>
                                                                                                    <th class="vrtcl-midl" data-ng-bind="trans.LBL_MOB_NO">Mobile Number</th>
                                                                                                    <th class="vrtcl-midl" data-ng-bind="trans.LBL_E_ADDR">Email Address</th>
                                                                                                </tr>
                                                                                            </thead>
                                                                                            <tbody>
                                                                                                {
                                                                                                    uAuths.map((u) => {
                                                                                                        return <tr ng-repeat="authorisedSignatory in authorisedSignatoryDetl | limitTo : 10">
                                                                                                            <td>{u.pd_fnm} {u.pd_mname} {u.pd_lname}</td>
                                                                                                            <td>{u.pd_mobile} </td>
                                                                                                            <td>{u.pd_email} </td>
                                                                                                        </tr>
                                                                                                    })
                                                                                                }
                                                                                                {
                                                                                                    uAuths.length === 0 && <tr ng-repeat="authorisedSignatory in authorisedSignatoryDetl | limitTo : 10">
                                                                                                        <td>NA</td>
                                                                                                        <td>NA</td>
                                                                                                        <td>NA</td>
                                                                                                    </tr>
                                                                                                }
                                                                                            </tbody>
                                                                                        </table>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class={`tab-pane fade ${mainTab == 'sa4' ? 'active in' : ''}`} id="pills-place" role="tabpanel" aria-labelledby="pills-place-tab">
                                                            <div class="row">
                                                                <div class="col-xs-12">
                                                                    <div id="accordion2" class="panel-group">
                                                                        <div class="panel panel-default">
                                                                            <div class="panel-heading">
                                                                                <h4 class="panel-title">
                                                                                    <a data-toggle="collapse" onClick={() => { setIsTabF(!isTabF) }}>Within the State</a>
                                                                                </h4>
                                                                            </div>
                                                                            <div id="collapseState" class={`panel-collapse collapse ${isTabF ? 'in' : ''}`}>
                                                                                <div class="panel-body">
                                                                                    <label class="reg">No Record Found</label>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="panel panel-default" style={{ marginTop: isTabG ? '20px' : '' }}>
                                                                            <div class="panel-heading">
                                                                                <h4 class="panel-title">
                                                                                    <a data-toggle="collapse" onClick={() => { setIsTabG(!isTabG) }}>Outside the State</a>
                                                                                </h4>
                                                                            </div>
                                                                            <div id="collapseOut" class={`panel-collapse collapse ${isTabG ? 'in' : ''}`}>
                                                                                <div class="panel-body">
                                                                                    <label class="reg">No Record Found</label>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row hidden-lg hidden-md">
                                        <div class="col-xs-12">
                                            <div id="accordion3" class="panel-group">
                                                <div class="panel panel-default">
                                                    <div class="panel-heading">
                                                        <h4 class="panel-title text-center">
                                                            <a href="#quickLinks">Quick Links</a>
                                                        </h4>
                                                    </div>
                                                    <div id="quickLinks" class="panel-collapse collapse">
                                                        <div class="panel-body">
                                                            <label class="reg show">
                                                                <a class="disabled" style={{ opacity: 0.7, pointerEvents: 'none' }}>History of Amendment</a>
                                                            </label>
                                                            <label class="reg show">
                                                                <a class="disabled" style={{ opacity: 0.7, pointerEvents: 'none' }}>Change Password</a>
                                                            </label>
                                                            <label class="reg show">
                                                                <a class="disabled" style={{ opacity: 0.7, pointerEvents: 'none' }}>Manage API Access</a>
                                                            </label>
                                                            <label class="reg show">
                                                                <a class="disabled" style={{ opacity: 0.7, pointerEvents: 'none' }}>Register / Update DSC</a>
                                                            </label>
                                                            <label class="reg show">
                                                                <a class="disabled" style={{ opacity: 0.7, pointerEvents: 'none' }}>Aadhaar Authentication Status</a>
                                                            </label>
                                                            <label class="reg show">
                                                                <a class="disabled" style={{ opacity: 0.7, pointerEvents: 'none' }}>My Registration Certificate</a>
                                                            </label>
                                                            <label class="reg show">
                                                                <a class="disabled" style={{ opacity: 0.7, pointerEvents: 'none' }}>Core Business Activity Status</a>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div >
    );
}
