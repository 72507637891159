import React, { useEffect } from 'react';

import icDone from '../assets/images/icons/done.png';

export default function ComponentTRNLevelK() {

    const loadData = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    }

    useEffect(() => {
        loadData();
    }, []);

    return (
        <div className='tabpane'>
            <h4 className='flex items-end'>
                <img src={icDone} alt='Done Image' className='w-6 h-6' />&nbsp;
                <span>SUCCESS</span>
            </h4>
            <p>
                Thank you for submission.<br />
                System will verify / validate the information submitted after which acknowledgement will be sent in next 15 minutes
            </p>
        </div>
    );
}
