import React, { useState, useEffect } from 'react';

import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Dialog } from '@headlessui/react';

import { useRecoilState } from 'recoil';
import { pathAtom, digErrorAtom, navAtom, userIDAtom, loginAtom } from '../core/config/atoms';

import ServiceUtility from '../services/serviceUtility';
import ServiceLMS from '../services/serviceLMS';

import captchBg from '../assets/images/misc/captcha.png';

export default function PanelLogin() {
    const [path, setPath] = useRecoilState(pathAtom);
    const [digError, setDigError] = useRecoilState(digErrorAtom);
    const [nav, setNav] = useRecoilState(navAtom);
    const [userID] = useRecoilState(userIDAtom);
    const [isLogin, setIsLogin] = useRecoilState(loginAtom);

    const [captch, setCaptch] = useState(0);

    const [isInvalid, setIsInvalid] = useState(false);
    const [isSubmit, setIsSubmit] = useState(false);
    const [captchError, setCaptchError] = useState(false);

    const utilService = new ServiceUtility();
    const lmsService = new ServiceLMS();

    const gotoPath = (path) => {
        setPath(path);
    }

    const closeAlert = () => {
        setIsInvalid(false);
    }

    const formSchema = Yup.object().shape({
        username: Yup.string().required('Please enter the mandatory fields'),
        password: Yup.string().required('Please enter the mandatory fields'),
        captcha: Yup.string().required('Please enter the mandatory fields').matches(/^[0-9]{6}$/, { message: 'Enter characters as displayed in the CAPTCHA image' }),
    });

    const { values, errors, touched, dirty, handleChange, handleSubmit, handleReset, resetForm, setValues } = useFormik({
        enableReinitialize: true,
        initialValues: {
            username: '',
            password: '',
            captcha: '',
        },
        validationSchema: formSchema,
        onSubmit: values => {
            setIsInvalid(false);
            if (captch.toString() === values.captcha) {
                setIsSubmit(true);
                let body = {
                    "username": values.username,
                    "password": values.password,
                    "id": userID,
                }
                lmsService.checkLogin(body).then((res) => {
                    if (res.status) {
                        if (res.data.GSTReg === 'done') {
                            setIsSubmit(false);
                            setNav('gstr');
                            setIsLogin(true);
                            gotoPath('gstr');
                        } else {
                            setIsSubmit(false);
                            setIsLogin(false);
                            setDigError({ title: 'Record not found', msg: 'Please complete the registration process first!', isToggle: true });
                        }
                    } else {
                        setIsInvalid(true);
                        setIsSubmit(false);
                        setIsLogin(false);
                        setDigError({ title: 'Record not found', msg: 'Username or password invalid please use your LMS credentials to login.', isToggle: true });
                    }
                });
            } else {
                setCaptchError(true);
                setIsLogin(false);
                setCaptch(utilService.getCode());
                setValues({ ...values, captcha: '' });
            }
        }
    });

    const loadData = () => {
        setCaptch(utilService.getCode());
    }

    useEffect(() => {
        loadData();
    }, []);

    return (
        <div class="content-wrapper">
            <div class="dimmer-holder" style={{ display: "none" }}>
                <div id="dimmer"></div>
            </div>
            <div class="container">
                <div class="mypage">
                    <div class="row">
                        <div class="col-xs-10">
                            <div>
                                <ol class="breadcrumb" data-ng-controller="crumbCtrl">
                                    <li><a onClick={() => {
                                        gotoPath('home');
                                    }}>Home</a></li>
                                    <li>
                                        <span>Login</span>
                                    </li>
                                </ol>
                            </div>
                        </div>
                    </div>
                    <div class="content-pane">
                        <div>
                            <div class="row">
                                <div class="col-sm-offset-2 col-md-offset-3 col-md-6 col-sm-8 col-xs-12">
                                    <div class="row">
                                        {captchError && <p class="err alert alert-danger">Enter characters as displayed in the CAPTCHA image</p>}
                                        {
                                            isInvalid && <div class="row">
                                                <div class="col-xs-12 err" data-ng-show="errors.login_error">
                                                    <div data-ng-if="errors.login_error_desc != 'ERR_SWEB_9036'" class="err" name="errContainer"><alert-message data-title="" data-type="danger" data-message="Invalid Username or Password. Please try again"><div class="alert alert-danger"><a class="close" data-dismiss="alert" aria-label="close" onClick={() => { closeAlert() }}>×</a><strong></strong> Invalid Username or Password. Please try again.</div></alert-message></div>
                                                </div>
                                            </div>
                                        }
                                        <div class="col-xs-12">
                                            <h4 class="mar-t-0">Login</h4>
                                            <hr />
                                            <p class="mand-text">indicates mandatory fields</p>
                                            <form noValidate onSubmit={handleSubmit}>
                                                <div class="row">
                                                    <div class={`col-xs-12 ${touched.username ? errors.username ? 'has-error' : 'has-success' : ''}`}>
                                                        <label for="username" class="m-cir reg">Username</label>
                                                        <div class="has-feedback">
                                                            <input value={values.username} id="username" class="form-control pad-r-0" type="text" placeholder="Enter Username" name="username" required="true" autofocus autocomplete="off" onChange={handleChange} />
                                                            <span class="glyphicon glyphicon-ok form-control-feedback"></span>
                                                        </div>
                                                        {(errors.username && touched.username) && <p class="err m-0">{errors.username}</p>}
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class={`col-xs-12 ${touched.password ? errors.password ? 'has-error' : 'has-success' : ''}`}>
                                                        <label for="user_pass" class="m-cir reg">Password</label>
                                                        <div class="has-feedback">
                                                            <input value={values.password} id="password" class="form-control" type="password" name="password" maxLength={15} required="true" placeholder="Enter Password" autocomplete="off" onChange={handleChange} />
                                                            <span class="glyphicon glyphicon-ok form-control-feedback"></span>
                                                        </div>
                                                        {(errors.password && touched.password) && <p class="err m-0">{errors.password}</p>}
                                                    </div>
                                                </div>
                                                {
                                                    dirty && <div class="row">
                                                        <div class={`col-xs-12 ${touched.captcha ? errors.captcha ? 'has-error' : 'has-success' : ''}`}>
                                                            <label class="reg m-cir" for="captch">Type the characters you see in the image below</label>
                                                            <input value={values.captcha} class="form-control number" id="captcha" name="captcha" placeholder="Enter characters as displayed in the CAPTCHA image" minLength={6} maxLength={6} required="" onChange={(e) => {
                                                                e.target.value = utilService.formatOnlyNumber(e.target.value);
                                                                handleChange(e);
                                                            }} />
                                                            {(errors.captcha && touched.captcha) && <p class="err m-0">{errors.captcha}</p>}
                                                            <div class="row">
                                                                <div class="col-sm-12 col-xs-12">
                                                                    <div data-captcha="" data-captcha-object="captchaObj">
                                                                        <table>
                                                                            <tbody>
                                                                                <tr>
                                                                                    <th rowspan="2" className='border border-black'>
                                                                                        <div className='relative'>
                                                                                            <p className='text-black font-black text-5xl absolute left-10 top-4'>{captch}</p>
                                                                                            <img className="captcha" src={captchBg} />
                                                                                        </div>
                                                                                    </th>
                                                                                    <th>
                                                                                        <button type="button" className='border border-gray-700 hover:bg-gray-500' style={{ backgroundColor: 'rgb(240, 240, 240)' }}>
                                                                                            <i class="fa fa-volume-up p-2"></i>
                                                                                        </button>
                                                                                    </th>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>
                                                                                        <button type="button" className='border border-gray-700 hover:bg-gray-500' style={{ backgroundColor: 'rgb(240, 240, 240)' }} onClick={() => {
                                                                                            setCaptch(utilService.getCode());
                                                                                        }}>
                                                                                            <i class="fa fa-refresh p-2"></i>
                                                                                        </button>
                                                                                    </td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                                <div class="row">
                                                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                                        <button type="submit" class="btn btn-primary" disabled={isSubmit}>LOGIN</button>
                                                        {/* isSubmit */}
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                                        <p class="text-blue-300 cursor-not-allowed">Forgot Username</p>
                                                    </div>
                                                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                                        <div class="pull-right">
                                                            <p class="text-blue-300 cursor-not-allowed">Forgot Password</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-sm-12">
                                                        <p class="help-block"><i class="fa fa-info-circle"></i><strong> First time login:</strong> If you are logging in for the first time, click <span class="text-blue-300 cursor-not-allowed"><u>here</u></span> to log in.</p>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
