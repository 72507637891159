import axios from 'axios';
import APIServiceLookUp from './serviceAPI';
import { API_URL } from './models/AppConstent';

export default class ServiceAuth {
    lookupService = new APIServiceLookUp();

    async register(body) {
        var serviceType = "POST";
        var url = `${API_URL}auth/register`;
        var token = '';
        var apiService = this.lookupService.getAPIService(serviceType);
        var response = await apiService.doCall(url, body, token);
        return response;
    }

    async getGSTUserByTRN(body) {
        var serviceType = "POST";
        var url = `${API_URL}auth/getGSTUserByTRN`;
        var token = '';
        var apiService = this.lookupService.getAPIService(serviceType);
        var response = await apiService.doCall(url, body, token);
        return response;
    }

    async getStorageBox(body) {
        var serviceType = "POST";
        var url = `${API_URL}doc/getStorageBox`;
        var token = '';
        var apiService = this.lookupService.getAPIService(serviceType);
        var response = await apiService.doCall(url, body, token);
        return response;
    }

    async setGSTUserFormA(body) {
        var serviceType = "POST";
        var url = `${API_URL}auth/setGSTUserFormA`;
        var token = '';
        var apiService = this.lookupService.getAPIService(serviceType);
        var response = await apiService.doCall(url, body, token);
        return response;
    }

    async setGSTUserFormB(body) {
        var serviceType = "POST";
        var url = `${API_URL}auth/setGSTUserFormB`;
        var token = '';
        var apiService = this.lookupService.getAPIService(serviceType);
        var response = await apiService.doCall(url, body, token);
        return response;
    }

    async getGSTRepUserByID(body) {
        var serviceType = "POST";
        var url = `${API_URL}auth/getGSTRepUserByID`;
        var token = '';
        var apiService = this.lookupService.getAPIService(serviceType);
        var response = await apiService.doCall(url, body, token);
        return response;
    }

    async setGSTUserFormD(body) {
        var serviceType = "POST";
        var url = `${API_URL}auth/setGSTUserFormD`;
        var token = '';
        var apiService = this.lookupService.getAPIService(serviceType);
        var response = await apiService.doCall(url, body, token);
        return response;
    }

    async setGSTUserFormE(body) {
        var serviceType = "POST";
        var url = `${API_URL}auth/setGSTUserFormE`;
        var token = '';
        var apiService = this.lookupService.getAPIService(serviceType);
        var response = await apiService.doCall(url, body, token);
        return response;
    }

    async setGSTUserFormG(body) {
        var serviceType = "POST";
        var url = `${API_URL}auth/setGSTUserFormG`;
        var token = '';
        var apiService = this.lookupService.getAPIService(serviceType);
        var response = await apiService.doCall(url, body, token);
        return response;
    }

    async setGSTUserFormH(body) {
        var serviceType = "POST";
        var url = `${API_URL}auth/setGSTUserFormH`;
        var token = '';
        var apiService = this.lookupService.getAPIService(serviceType);
        var response = await apiService.doCall(url, body, token);
        return response;
    }

    async setGSTUserFormJ(body) {
        var serviceType = "POST";
        var url = `${API_URL}auth/setGSTUserFormJ`;
        var token = '';
        var apiService = this.lookupService.getAPIService(serviceType);
        var response = await apiService.doCall(url, body, token);
        return response;
    }

    async setGSTUserFormI(body) {
        var serviceType = "POST";
        var url = `${API_URL}auth/setGSTUserFormI`;
        var token = '';
        var apiService = this.lookupService.getAPIService(serviceType);
        var response = await apiService.doCall(url, body, token);
        return response;
    }

    async setDocument(formData) {
        var url = `${API_URL}doc/setDocument`;
        var token = '';
        var requestHeaders = {
            'Content-type': 'multipart/form-data',
            'Accept': '*/*',
            'Authorization': `bearer ${token}`,
        };
        return axios.post(url, formData, { headers: requestHeaders });
    }

}