import React, { useState, useEffect, useRef } from 'react';

import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import { ThemeProvider } from "@material-ui/styles";
import { createMuiTheme } from "@material-ui/core";
import DateFnsUtils from '@date-io/date-fns';
import Moment from 'moment';
import { Dialog } from '@headlessui/react';

import { useRecoilState } from 'recoil';
import { pathAtom, loadingAtom, trnLevelAtom, trnAtom, digErrorAtom, dirtyAtom, docAtom, digStorageAtom, userIDAtom, userAtom } from '../core/config/atoms';

import icImg from '../assets/images/misc/jpeg.png';

import ServiceAuth from '../services/serviceAuth';
import ServiceUtility from '../services/serviceUtility';
import ServiceOption from '../services/serviceOptions';

import { useFormik } from 'formik';
import * as Yup from 'yup';

export default function ComponentTRNLevelC() {

    const [path, setPath] = useRecoilState(pathAtom);
    const [loading, setLoading] = useRecoilState(loadingAtom);
    const [trnLevel, SetTRNLevel] = useRecoilState(trnLevelAtom);
    const [trnno] = useRecoilState(trnAtom);
    const [digError, setDigError] = useRecoilState(digErrorAtom);
    const [isDirty, setIsDirty] = useRecoilState(dirtyAtom);
    const [doc, setDoc] = useRecoilState(docAtom);
    const [digDoc, setDigDoc] = useRecoilState(digStorageAtom);
    const [userID] = useRecoilState(userIDAtom);
    const [userAuth] = useRecoilState(userAtom);

    const [gstAllUsers, setGSTAllUsers] = useState([]);
    const [gstUsers, setGSTUsers] = useState([]);
    const [markers, setMarkers] = useState([]);

    const [map, setMap] = useState();

    const [digConfirm, setDigConfirm] = useState({ 'type': '0', isToggle: false, data: null });
    const [gstUser, setGSTUser] = useState({});
    const [docs, setDocs] = useState({ pic: null, doc: null });

    const [mainDate, setMainDate] = useState(new Date());

    const [selOption, setSelOption] = useState('');
    const [selDOption, setSelDOption] = useState('');

    const [pd_mobile, setPDmobile] = useState("");
    const [pd_email, setPDemail] = useState("");
    const [pan, setPan] = useState("");

    const [selectedUser, setSelectedUser] = useState(-1);
    const [primeUser, setPrimeUser] = useState(-1);

    const [isForm, setIsForm] = useState(true);
    const [isNew, setIsNew] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [isPincode, setIsPincode] = useState(false);
    const [isSubmit, setIsSubmit] = useState(false);
    const [isAuthr, setIsAuthr] = useState(false);
    const [errorDig, setErrorDig] = useState(false);
    const [isChecked, setIsChecked] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [isValid, setIsValid] = useState(false);

    const formSchema = Yup.object().shape({
        pd_fnm: Yup.string().required('First Name is required.'),
        fd_fname: Yup.string().required('Name of Father is required.'),
        pd_dob: Yup.string().required('Please select Date of Birth.').matches(/^[0-9]{2}-[0-9]{2}-[0-9]{4}$/, { message: 'Please enter a valid date' }),
        pd_mobile: Yup.string().required('Mobile Number is required.').matches(/^[0-9]{10}$/, { message: 'Please enter a valid mobile number' }),
        pd_email: Yup.string().required('Email Address is required.').matches(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, { message: 'Please enter a valid email address' }),
        gd: Yup.string().required('Gender is required.'),
        dig: Yup.string().required('Designation / Status is required.'),
        pan: Yup.string().required('Permanent Account Number is required.').matches(/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/, { message: 'Please enter a valid pan card number' }),
        pncd: Yup.string().required('PIN Code is required.').matches(/^[0-9]{6}$/, { message: 'Please enter a valid pincode' }),
        pd_state: Yup.string().required('State is required.'),
        dst: Yup.string().required('District is required.'),
        city: Yup.string().required('City / Town / Village is required.'),
        pd_road: Yup.string().required('Road / Street is required.'),
        pd_bdnum: Yup.string().required('Building No. / Flat No. is required.'),
    });

    const { values, errors, touched, dirty, handleChange, handleSubmit, handleReset, resetForm, setValues } = useFormik({
        enableReinitialize: true,
        initialValues: {
            pd_fnm: '',
            pd_mname: '',
            pd_lname: '',
            fd_fname: '',
            fd_mname: '',
            fd_lname: '',
            pd_dob: '',
            pd_mobile: '',
            pd_email: '',
            telstd: '',
            telno: '',
            gd: '',
            dig: '',
            pan: '',
            pd_cntry: '',
            pncd: '',
            pd_state: '',
            dst: '',
            city: '',
            pd_locality: '',
            pd_road: '',
            pd_bdname: '',
            pd_bdnum: '',
            pd_flrnum: '',
            pd_landmark: '',
            as_up_type: '',
        },
        validationSchema: formSchema,
        onSubmit: values => {
            if (docs.pic) {
                let isV = checkPrime();
                if (isV) {
                    setIsValid(false);
                    let formData = { ...values };

                    if (isNew) {
                        formData['isPrime'] = isChecked ? '1' : '0';
                        formData['isAuth'] = '1';
                        formData['isProm'] = '0';
                        let data = [...trnLevel.form];

                        if (isChecked) {
                            data[2] = "1";
                        } else {
                            if (primeUser == selectedUser) {
                                data[2] = "0";
                            }
                        }

                        if (docs.pic.type === 'storage' && docs.doc.type === 'storage') {
                            setLoading(true);
                            formData['userPhoto'] = {
                                filename: docs.pic.file.filename,
                                fileurl: docs.pic.file.fileurl,
                                filetype: 'storage',
                            };
                            formData['userDoc'] = {
                                filename: docs.doc.file.filename,
                                fileurl: docs.doc.file.fileurl,
                                filetype: 'storage',
                            };
                            let body = {};
                            let tempUsers = [...gstAllUsers];
                            tempUsers.push(formData);
                            body = {
                                users: [...tempUsers],
                                trnno: trnno,
                                lid: userID,
                                statusArr: data,
                                user: userAuth,
                            }
                            authService.setGSTUserFormB(body).then((res) => {
                                setLoading(false);
                                if (res.statuscode === 200) {
                                    setDoc({ isFile: false, type: '', doc: null });
                                    if (tempUsers.length > 1) {
                                        setIsForm(false);
                                        setIsNew(false);
                                        loadData();
                                    } else {
                                        SetTRNLevel({ ...trnLevel, level: 'sa4' });
                                    }
                                } else {
                                    setDigError({ title: 'Error', msg: 'Server error please try again!', isToggle: true });
                                }
                            });
                        } else {
                            let mPromis = [];
                            var formDataA = new FormData();

                            if (docs.pic.type !== 'storage') {
                                formDataA.append("doc", docs.pic);
                                formDataA.append("id", userID);
                                mPromis.push(authService.setDocument(formDataA, ''));
                            }

                            if (docs.doc.type !== 'storage') {
                                formDataA.append("doc", docs.doc);
                                formDataA.append("id", userID);
                                mPromis.push(authService.setDocument(formDataA, ''));
                            }

                            Promise.all(mPromis).then((d) => {
                                if (d[0].data.status) {
                                    let ddata = d[0].data.data;
                                    let bdata = d[1].data.data;
                                    formData['userPhoto'] = {
                                        filename: ddata.filename,
                                        fileurl: ddata.fileurl,
                                        filetype: 'doc',
                                    };
                                    formData['userDoc'] = {
                                        filename: bdata.filename,
                                        fileurl: bdata.fileurl,
                                        filetype: 'doc',
                                    };
                                    let body = {}
                                    let tempUsers = [...gstAllUsers];
                                    tempUsers.push(formData);
                                    body = {
                                        users: [...tempUsers],
                                        trnno: trnno,
                                        lid: userID,
                                        statusArr: data,
                                        user: userAuth,
                                    }
                                    authService.setGSTUserFormB(body).then((res) => {
                                        setLoading(false);
                                        if (res.statuscode === 200) {
                                            setDoc({ isFile: false, type: '', doc: null });
                                            if (tempUsers.length > 1) {
                                                setIsForm(false);
                                                setIsNew(false);
                                                loadData();
                                            } else {
                                                SetTRNLevel({ ...trnLevel, level: 'sa4' });
                                            }
                                        } else {
                                            setDigError({ title: 'Error', msg: 'Server error please try again!', isToggle: true });
                                        }
                                    });
                                } else {
                                    setLoading(false);
                                    setDigError({ title: 'Error', msg: 'Server error please try again!', isToggle: true });
                                }
                            });
                        }
                    } else if (gstUsers[selectedUser].isProm === '1') {
                        setIsSubmit(true);
                        setLoading(true);

                        let tempUsers = [...gstAllUsers];
                        let data = [...trnLevel.form];

                        if (isChecked) {
                            tempUsers[selectedUser]['isPrime'] = '1';
                            data[2] = "1";
                        } else {
                            if (primeUser == selectedUser) {
                                data[2] = "0";
                            }
                            tempUsers[selectedUser]['isPrime'] = '0';
                        }
                        let body = {
                            users: [...tempUsers],
                            trnno: trnno,
                            lid: userID,
                            statusArr: data,
                            user: userAuth,
                        }
                        authService.setGSTUserFormB(body).then((res) => {
                            setLoading(false);
                            setIsSubmit(false);
                            if (res.statuscode === 200) {
                                setDoc({ isFile: false, type: '', doc: null });
                                if (tempUsers.length > 1) {
                                    setIsForm(false);
                                    setIsNew(false);
                                    loadData();
                                } else {
                                    SetTRNLevel({ ...trnLevel, level: 'sa4' });
                                }
                            } else {
                                setDigError({ title: 'Error', msg: 'Server error please try again!', isToggle: true });
                            }
                        });
                    } else {
                        formData['isPrime'] = isChecked ? '1' : '0';
                        formData['isAuth'] = '1';
                        formData['isProm'] = '0';
                        let data = [...trnLevel.form];

                        if (isChecked) {
                            data[2] = "1";
                        } else {
                            if (primeUser == selectedUser) {
                                data[2] = "0";
                            }
                        }

                        if (docs.pic.filetype === 'storage' && docs.doc.filetype === 'storage') {
                            setLoading(true);
                            formData['userPhoto'] = {
                                filename: docs.pic.filename,
                                fileurl: docs.pic.fileurl,
                                filetype: 'storage',
                            };
                            formData['userDoc'] = {
                                filename: docs.doc.filename,
                                fileurl: docs.doc.fileurl,
                                filetype: 'storage',
                            };
                            let body = {};
                            let tempUsers = [...gstAllUsers];
                            if (gstAllUsers.length > gstUsers.length) {
                                tempUsers[getActiveIndex(selectedUser)] = formData;
                            } else {
                                tempUsers[selectedUser] = formData;
                            }
                            body = {
                                users: [...tempUsers],
                                trnno: trnno,
                                lid: userID,
                                statusArr: data,
                                user: userAuth,
                            }
                            authService.setGSTUserFormB(body).then((res) => {
                                setLoading(false);
                                if (res.statuscode === 200) {
                                    setDoc({ isFile: false, type: '', doc: null });
                                    if (tempUsers.length > 1) {
                                        setIsForm(false);
                                        setIsNew(false);
                                        loadData();
                                    } else {
                                        SetTRNLevel({ ...trnLevel, level: 'sa4' });
                                    }
                                } else {
                                    setDigError({ title: 'Error', msg: 'Server error please try again!', isToggle: true });
                                }
                            });
                        } else {
                            let mPromis = [];
                            var formDataA = new FormData();

                            if (docs.pic.type !== 'storage') {
                                formDataA.append("doc", docs.pic);
                                formDataA.append("id", userID);
                                mPromis.push(authService.setDocument(formDataA, ''));
                            }

                            if (docs.doc.type !== 'storage') {
                                formDataA.append("doc", docs.doc);
                                formDataA.append("id", userID);
                                mPromis.push(authService.setDocument(formDataA, ''));
                            }

                            Promise.all(mPromis).then((d) => {
                                if (d[0].data.status) {
                                    let ddata = d[0].data.data;
                                    let bdata = d[1].data.data;
                                    formData['userPhoto'] = {
                                        filename: ddata.filename,
                                        fileurl: ddata.fileurl,
                                        filetype: 'doc',
                                    };
                                    formData['userDoc'] = {
                                        filename: bdata.filename,
                                        fileurl: bdata.fileurl,
                                        filetype: 'doc',
                                    };
                                    let body = {}
                                    let tempUsers = [...gstAllUsers];
                                    tempUsers.push(formData);
                                    body = {
                                        users: [...tempUsers],
                                        trnno: trnno,
                                        lid: userID,
                                        statusArr: data,
                                        user: userAuth,
                                    }
                                    authService.setGSTUserFormB(body).then((res) => {
                                        setLoading(false);
                                        if (res.statuscode === 200) {
                                            setDoc({ isFile: false, type: '', doc: null });
                                            if (tempUsers.length > 1) {
                                                setIsForm(false);
                                                setIsNew(false);
                                                loadData();
                                            } else {
                                                SetTRNLevel({ ...trnLevel, level: 'sa4' });
                                            }
                                        } else {
                                            setDigError({ title: 'Error', msg: 'Server error please try again!', isToggle: true });
                                        }
                                    });
                                } else {
                                    setLoading(false);
                                    setDigError({ title: 'Error', msg: 'Server error please try again!', isToggle: true });
                                }
                            });
                        }
                    }
                }
            } else {
                setIsValid(true);
            }
        }
    });

    const materialTheme = createMuiTheme({
        overrides: {
            MuiTypography: {
                subtitle1: {
                    fontSize: '16px',
                },
                caption: {
                    fontSize: '16px',
                    fontWeight: 'bold'
                },
                body1: {
                    "fontSize": '16px',
                },
                body2: {
                    "fontSize": '16px',
                }
            },
            MuiPickersToolbar: {
                toolbar: {
                    backgroundColor: '#239B56',
                },
            },
            MuiPickersDay: {
                day: {
                    color: '#17202A',
                },
                daySelected: {
                    backgroundColor: '#239B56',
                },
                dayDisabled: {
                    color: '#808B96',
                },
                current: {
                    color: '#239B56',
                },
            },
            MuiButton: {
                textPrimary: {
                    color: '#2E86C1',
                    fontSize: '12px',
                    border: '1px solid #2E86C1',
                    backgroundColor: 'transprent'
                }
            },
        },
    });

    const ref = useRef();

    const authService = new ServiceAuth();
    const utilService = new ServiceUtility();
    const optionService = new ServiceOption();

    const checkPrime = () => {
        let er = false;
        gstUsers.map((u, index) => {
            if (index !== selectedUser) {
                if (u.pd_mobile === values.pd_mobile || u.pd_email === values.pd_email || u.pan === values.pan) {
                    if (u.pd_mobile === values.pd_mobile) {
                        setPDmobile("Another Authorized Signatory with same Mobile Number exists");
                    }
                    if (u.pd_email === values.pd_email) {
                        setPDemail("Another Authorized Signatory with same Email Address exists");
                    }
                    if (u.pan === values.pan) {
                        setPan("Another Authorized Signatory with same pan number exists");
                    }
                    er = true;
                }
            }
        });
        return !er;
    }

    const getActiveIndex = (selectedIndex) => {
        let promIndex = 0;
        gstAllUsers.map((u, index) => {
            if (u.isProm == 1) {
                promIndex = index;
            }
        });
        if (promIndex === 0) {
            return selectedIndex + 1;
        } else {
            if (selectedIndex < promIndex) {
                return selectedIndex;
            } else {
                return selectedIndex + 1;
            }
        }
    }

    const gotoPath = (path) => {
        SetTRNLevel({ ...trnLevel, level: path });
    }

    const showDig = (type, data) => {
        setDigConfirm({ 'type': type, isToggle: true, data: data });
    }

    const cancel = () => {
        setDigConfirm({ 'type': '0', isToggle: false });
    }

    const process = () => {
        switch (digConfirm.type) {
            case '1':
                removeDoc();
                break;
            case '2':
                deleteUser(digConfirm.data);
            default:
                break;
        }
        setDigConfirm({ 'type': '0', isToggle: false, data: null });
    }

    const removeDoc = () => {
        setDoc({ isFile: false, type: '', doc: null });
    }

    const deleteUser = (index) => {
        setLoading(true);
        let us = [...gstUsers];
        if (us[index].isProm == "1") {
            let data = [...trnLevel.form];
            if (us[index].isPrime == "1") {
                data[2] = "0";
            }
            us[index].isAuth = "0";
            us[index].isPrime = "0";

            let body = {
                users: [...us],
                trnno: trnno,
                lid: userID,
                statusArr: data,
                user: userAuth,
            }
            authService.setGSTUserFormB(body).then((res) => {
                if (res.statuscode === 200) {
                    loadData();
                } else {
                    setDigError({ title: 'Error', msg: 'Server error please try again!', isToggle: true });
                }
                setLoading(false);
            });
        } else {
            us.splice(index, 1);
            let data = [...trnLevel.form];
            if (us.length === 0) {
                data[2] = "0";
            }
            let body = {
                users: [...us],
                trnno: trnno,
                lid: userID,
                statusArr: data,
                user: userAuth,
            }
            authService.setGSTUserFormB(body).then((res) => {
                if (res.statuscode === 200) {
                    loadData();
                } else {
                    setDigError({ title: 'Error', msg: 'Server error please try again!', isToggle: true });
                }
                setLoading(false);
            });
        }
    }

    const getMinor = () => {
        return Moment().subtract(18, 'years');
    }

    const handleDateChange = (d) => {
        switch (selOption) {
            case 'pd_dob':
                setValues({ ...values, pd_dob: Moment(d).format('DD-MM-YYYY').toString() });
                break;
            default:
                break;
        }
        setSelOption('');
    }

    const handelePincode = (e) => {
        let value = e.target.value;
        handleChange(e);
        if (value.length == 6) {
            optionService.getLocation({ 'Pincode': value }).then((res) => {
                setIsPincode(true);
                const geocoder = new window.google.maps.Geocoder();
                var address = res.data['RegionName'].replace(' Region', '').toUpperCase() + " ," + res.data['StateName'] + " " + value;
                geocoder.geocode({ 'address': address }, function (results, status) {
                    if (status == 'OK') {
                        map.setCenter(results[0].geometry.location);
                        let tempMarks = [...markers];
                        for (let i = 0; i < tempMarks.length; i++) {
                            tempMarks[i].setMap(null);
                        }
                        tempMarks.push(
                            new window.google.maps.Marker({
                                map: map,
                                position: results[0].geometry.location
                            })
                        );
                        setMarkers(tempMarks);
                    } else {
                        alert('Geocode was not successful for the following reason: ' + status);
                    }
                });
                setValues({ ...values, 'pd_cntry': 'IND', 'pd_state': res.data['StateName'], 'dst': res.data['District'], 'city': res.data['RegionName'].replace(' Region', '').toUpperCase(), 'pncd': value });
            });
        } else {
            setIsPincode(false);
        }
    }

    const loadData = () => {
        (async function () {
            setLoading(true);
            var res = await authService.getGSTUserByTRN({ 'trn': trnno, 'stdOwn': userID });
            setGSTUser(res.data[0]);
            SetTRNLevel({ ...trnLevel, form: res.data[0]['statusArr'], percentage: utilService.calculatePercentage(res.data[0]['statusArr']) });
            setGSTAllUsers(res.data[0]['users']);
            let us = [];
            res.data[0]['users'].map((u) => {
                if (u.isAuth === "1") {
                    us.push(u);
                }
            });
            setGSTUsers(us);
            if (us.length !== 0) {
                setIsNew(false);
                let primeIndex = us.findIndex(item => {
                    return item.isPrime === '1';
                });
                setPrimeUser(primeIndex);
                let promIndex = us.findIndex(item => {
                    return item.isProm === '1';
                });
                if (promIndex < 0 || us.length > 1) {
                    setIsForm(false);
                } else {
                    let pramotor = us[promIndex];
                    if (pramotor['isAuth'] === '1') {
                        setDocs({ ...docs, pic: pramotor.userPhoto });
                        setValues({ ...values, ...pramotor });
                        setIsAuthr(true);
                        setIsPincode(true);
                        setSelectedUser(promIndex);
                    } else {
                        setDocs({ pic: null, doc: null });
                        setValues({ ...values });
                        setIsAuthr(false);
                        setSelectedUser(-1);
                    }
                    setIsChecked(pramotor['isPrime'] === '1');
                }
            } else {
                setIsNew(true);
                setDoc({ isFile: false, type: '', doc: null });
                setValues({ ...values });
                setIsAuthr(false);
            }
            setLoading(false);
            window.scrollTo({
                top: 0,
                behavior: 'smooth',
            });
        })();
    }

    const changeFile = () => {
        if (doc.isFile) {
            if (selDOption === "pd_upload") {
                let mDoc = {
                    type: doc.type,
                    title: "Photo",
                    file: doc.doc
                }
                setDocs({ ...docs, pic: mDoc });
            } else {
                let mDoc = {
                    type: doc.type,
                    title: values.as_up_type,
                    file: doc.doc
                }
                setDocs({ ...docs, doc: mDoc });
            }
            setDoc({ isFile: false, type: '', doc: null });
        }
    }

    const getPrimeSatus = () => {
        if (primeUser == -1) return false;
        if (isNew) {
            return true;
        } else {
            if (primeUser !== selectedUser) {
                return true;
            } else {
                return false;
            }
        }
    }

    const resetAddress = () => {
        setIsPincode(false);
        let tempMarks = [...markers];
        for (let i = 0; i < tempMarks.length; i++) {
            tempMarks[i].setMap(null);
        }
        setMarkers(tempMarks);
        setValues({ ...values, 'pd_landmark': '', 'pd_flrnum': '', 'pd_bdnum': '', 'pd_bdname': '', 'pd_road': '', 'pd_locality': '', 'city': '', 'dst': '', 'pd_state': '', 'pncd': '' });
    }

    const openBox = (e) => {
        e.preventDefault();
        var { name } = e.target;
        if (name == "as_upload_sign" && values.as_up_type === undefined) {
            setErrorDig(true);
        } else {
            setDoc({ isFile: false, type: '', doc: null });
            setDigDoc({ title: '', msg: '', isToggle: true });
            setSelDOption(name);
        }
    }

    const addNewAuth = () => {
        resetForm();
        setIsAuthr(false);
        setSelectedUser(-1);
        setIsChecked(false);
        setIsPincode(false);
        setIsNew(true);
        setDoc({ isFile: false, type: '', doc: null });
        setDocs({ pic: null, doc: null });
    }

    const onClickEdit = (index) => {
        let userD = gstUsers[index];
        setValues({ ...values, ...userD });
        setIsChecked(userD.isPrime === '1');
        setSelectedUser(index);
        setIsNew(false);
        setIsPincode(true);
        setIsEdit(true);
        if (userD.isProm === "1") {
            setIsAuthr(true);
            setDocs({ pic: userD.userPhoto, doc: null });
        } else {
            setIsAuthr(false);
            setDocs({ pic: userD.userPhoto, doc: userD.userDoc });
        }
    }

    const openPicker = (id) => {
        if (!isAuthr) {
            setSelOption(id);
            setIsOpen(true);
        }
    }

    const updatePrime = (state) => {
        if (!state) {
            setValues({ ...values, "pd_mobile": gstUser.mobile, "pd_email": gstUser.email })
        }
    }

    useEffect(() => {
        const dmap = new window.google.maps.Map(ref.current, {
            center: { lat: 19.200, lng: 72.800 },
            zoom: 12
        });
        setMap(dmap);
        loadData();
    }, []);

    useEffect(() => {
        changeFile();
    }, [doc]);

    return (
        <form class="tabpane" name="newRegForm" id="newRegForm" autocomplete="off" noValidate onSubmit={handleSubmit}>
            <Dialog open={digConfirm.isToggle} onClose={() => setDigConfirm({ 'type': '0', isToggle: false, data: null })}>
                <div id="confirmDlg" class="modal fade fade-scale in" role="dialog" style={{ display: 'block', paddingRight: '17px' }}>
                    <div class="modal-dialog sweet">
                        <div class="modal-content">
                            <div class="modal-body">
                                <div class="m-icon m-warning pulseWarning">
                                    <span class="micon-body pulseWarningIns"></span>
                                    <span class="micon-dot pulseWarningIns"></span>
                                </div>
                                <h2>Warning</h2>
                                <p>Are you sure, you want to continue?</p>
                            </div>
                            <div class="modal-footer">
                                <a class="btn btn-default" onClick={() => { cancel() }}>Cancel</a>
                                <a class="btn btn-primary" onClick={() => { process() }}>Proceed</a>
                            </div>
                        </div>
                    </div>
                </div>
            </Dialog>
            <Dialog open={errorDig} onClose={() => setErrorDig(false)}>
                <div id="confirmDlg" class="modal fade fade-scale in" role="dialog" style={{ display: 'block', paddingRight: '17px' }}>
                    <div class="modal-dialog sweet">
                        <div class="modal-content">
                            <div class="modal-body">
                                <div class="m-icon m-error">
                                    <span class="x-mark">
                                        <span class="m-line m-left">
                                        </span>
                                        <span class="m-line m-right">
                                        </span>
                                    </span>
                                </div>
                                <h2>Error</h2>
                                <p>Select type of document to be uploaded</p>
                            </div>
                            <div class="modal-footer">
                                <a class="btn btn-default" onClick={() => { setErrorDig(false) }}>Close</a>
                            </div>
                        </div>
                    </div>
                </div>
            </Dialog>
            {
                !isForm && <div data-ng-show="!showForm" class="ng-hide">
                    <div class="row">
                        {
                            gstUsers.length != 0 && <>
                                <div>
                                    <p class="text-right">
                                        <span>
                                            <i class="type-ico fa fa-user-circle-o"></i>
                                            <i class="valign-super fa fa-star"></i>
                                        </span>&nbsp;
                                        <span>Primary Authorized Signatory</span>
                                    </p>
                                    <p class="text-right">
                                        <span>
                                            <i class="fa fa-user-circle"></i>&nbsp;
                                            <span>Authorized Signatory, also Promoter</span>
                                        </span>
                                    </p>
                                    <p class="text-right">
                                        <span>
                                            <i class="fa fa-user-o"></i>&nbsp;
                                            <span>Authorized Signatory</span>
                                        </span>
                                    </p>
                                </div>
                                <div class="col-xs-12">
                                    <div name="staus_msg"></div>
                                    <div class="table-responsive" data-ng-if="rpayload.opdtls &amp;&amp; rpayload.opdtls.length > 0">
                                        <table class="table table-stripped" style={{ display: 'table' }}>
                                            <thead>
                                                <tr>
                                                    <th data-ng-bind="trans.LBL_SL_NO">Sl No</th>
                                                    <th data-ng-bind="trans.LBL_NAME">Name</th>
                                                    <th data-ng-bind="trans.LBL_DESGN">Designation / Status</th>
                                                    <th data-ng-bind="trans.LBL_ACTIONS">Actions</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    gstUsers.map((item, index) => {
                                                        return <tr data-ng-repeat="partner in rpayload.opdtls">
                                                            <td>{index + 1}</td>
                                                            <td>
                                                                <span class="pdg-box">
                                                                    <span data-ng-if="partner.isasg == 'Y'" data-toggle="tooltip" title="Promoter, also Authorized Signatory">
                                                                        {
                                                                            item.isProm != '1' && item.isPrime != '1' && <i class="fa fa-user-o text-green-600"></i>
                                                                        }
                                                                        {
                                                                            item.isProm != '1' && item.isPrime == '1' && <>
                                                                                <i class="type-ico fa fa-user-circle-o text-green-600"></i>
                                                                                <i class="valign-super fa fa-star text-green-600"></i>
                                                                            </>
                                                                        }
                                                                        {
                                                                            item.isProm == '1' && item.isAuth == '1' && item.isPrime == '1' && <>
                                                                                <i class="type-ico fa fa-user-circle-o text-green-600"></i>
                                                                                <i class="valign-super fa fa-star text-green-600"></i>
                                                                            </>
                                                                        }
                                                                        {
                                                                            item.isProm == '1' && item.isAuth == '1' && <i class="fa fa-user-circle text-green-600"></i>
                                                                        }
                                                                    </span>
                                                                </span>
                                                                {item.pd_fnm} {item.pd_mname} {item.pd_lname}
                                                            </td>
                                                            <td>{item.dig}</td>
                                                            <td>
                                                                <div>
                                                                    <button title="Edit" type="button" class="btn btn-sm btn-primary" style={{ marginRight: '8px' }} onClick={() => {
                                                                        setIsForm(true);
                                                                        onClickEdit(index);
                                                                    }}>
                                                                        <i class="fa fa-pencil"></i>&nbsp;
                                                                        <span>Edit</span>
                                                                    </button>
                                                                    <button title="Delete" type="button" class="btn btn-sm btn-danger" onClick={() => { showDig('2', index) }}>
                                                                        <i class="fa fa-trash"></i>&nbsp;
                                                                        <span>Delete</span>
                                                                    </button>
                                                                </div>

                                                            </td>
                                                        </tr>
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </>
                        }
                        {
                            gstUsers.length == 0 && <div class="alert alert-warning">
                                <p>You do not have any submitted applications</p>
                            </div>
                        }
                    </div>
                    <div class="row next-tab-nav">
                        <div class="col-xs-12 text-right">
                            <a title="Back" class="btn btn-default" onClick={() => { gotoPath('sa2') }} style={{ marginRight: '8px' }}>Back</a>
                            <button title="Add New" type="button" class="btn btn-primary" style={{ marginRight: '8px' }} disabled={gstUsers.length >= 3} onClick={() => {
                                setIsForm(true);
                                addNewAuth();
                            }} >Add New</button>
                            <button title="Continue" class="btn btn-primary" onClick={() => {
                                setDoc({ isFile: false, type: '', doc: null });
                                SetTRNLevel({ ...trnLevel, level: 'sa4' });
                            }}>Continue</button>
                        </div>
                    </div>
                </div>
            }
            {
                isForm && <div data-ng-show="showForm">
                    <p data-ng-show="showForm" class="mand-text">indicates mandatory fields</p>
                    <h4>
                        <span>Details of Authorized Signatory</span>
                    </h4>
                    <hr />
                    <div>
                        <input type="checkbox" name="auth_prim" id="auth_prim" class="chkbx" checked={isChecked} onChange={() => {
                            updatePrime(isChecked);
                            setIsChecked(!isChecked);
                        }} disabled={getPrimeSatus()} />
                        <label for="auth_prim">Primary Authorized Signatory</label>
                    </div>
                    <div class="row next-tab-nav">
                        <fieldset className='p-6' disabled={isAuthr}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <ThemeProvider theme={materialTheme}>
                                    <DatePicker
                                        variant="dialog"
                                        open={isOpen}
                                        onOpen={() => setIsOpen(true)}
                                        onClose={() => setIsOpen(false)}
                                        label="Open me from button"
                                        format="dd/MM/yyyy"
                                        value={mainDate}
                                        maxDate={getMinor()}
                                        onChange={handleDateChange}
                                        style={{ display: 'none' }}
                                    />
                                </ThemeProvider>
                            </MuiPickersUtilsProvider>
                            <div class="tbl-format">
                                <div class="row">
                                    <div class="inner">
                                        <h5 class="col-xs-12">Name of Person</h5>
                                        <div class={`col-sm-4 col-xs-12 ${touched.pd_fnm ? errors.pd_fnm ? 'has-error' : 'has-success' : ''}`}>
                                            <label for="pd_fnm" class="reg m-cir">First Name</label>
                                            <input value={values.pd_fnm} id="pd_fnm" name="pd_fnm" class="form-control" type="text" required="" placeholder="Enter First Name" maxLength={99} autofocus="" onChange={handleChange} />
                                            {(errors.pd_fnm && touched.pd_fnm) && <p class="err">{errors.pd_fnm}</p>}
                                        </div>
                                        <div class="col-sm-4 col-xs-12">
                                            <label for="pd_mname" class="reg">Middle Name</label>
                                            <input value={values.pd_mname} id="pd_mname" name="pd_mname" class="form-control" type="text" placeholder="Enter Middle Name" maxLength={99} onChange={handleChange} />
                                        </div>
                                        <div class="col-sm-4 col-xs-12">
                                            <label for="pd_lname" class="reg">Last Name</label>
                                            <input value={values.pd_lname} class="form-control" name="pd_lname" id="pd_lname" type="text" placeholder="Enter Last Name" maxLength={99} onChange={handleChange} />
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="inner">
                                        <h5 class="col-sm-12 col-xs-12">Name of Father</h5>
                                        <div class={`col-sm-4 col-xs-12 ${touched.fd_fname ? errors.fd_fname ? 'has-error' : 'has-success' : ''}`}>
                                            <label for="fd_fname" class="reg m-cir">First Name</label>
                                            <input value={values.fd_fname} id="fd_fname" name="fd_fname" class="form-control" type="text" required="" placeholder="Enter First Name" maxLength={99} onChange={handleChange} />
                                            {(errors.fd_fname && touched.fd_fname) && <p class="err">{errors.fd_fname}</p>}
                                        </div>
                                        <div class="col-sm-4 col-xs-12">
                                            <label for="fd_mname" class="reg">Middle Name</label>
                                            <input value={values.fd_mname} id="fd_mname" name="fd_mname" class="form-control" type="text" placeholder="Enter Middle Name" maxLength={99} onChange={handleChange} />
                                        </div>
                                        <div class="col-sm-4 col-xs-12">
                                            <label for="fd_lname" class="reg">Last Name</label>
                                            <input value={values.fd_lname} class="form-control" name="fd_lname" maxLength={99} id="fd_lname" type="text" placeholder="Enter Last Name" onChange={handleChange} />
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="inner">
                                        <div class={`col-sm-4 col-xs-12 ${touched.pd_dob ? errors.pd_dob ? 'has-error' : 'has-success' : ''}`}>
                                            <label for="pd_dob" class="reg m-cir">Date of Birth</label>
                                            <div class="datepicker-icon input-group">
                                                <input value={values.pd_dob !== undefined ? values.pd_dob.substring(0, 10) : ''} class="form-control date-picker" type="text" name="pd_dob" id="pd_dob" placeholder="DD/MM/YYYY" required="" onChange={handleChange} />
                                                <span class="input-group-addon cursor-pointer" onClick={() => { openPicker('pd_dob') }}><span class="fa fa-calendar"></span></span>
                                            </div>
                                            {(errors.pd_dob && touched.pd_dob) && <p class="err">{errors.pd_dob}</p>}
                                        </div>
                                        <div class={`col-sm-4 col-xs-12 ${touched.pd_mobile ? errors.pd_mobile ? 'has-error' : 'has-success' : ''}`}>
                                            <label for="pd_mobile" class="reg m-cir">
                                                <i class="type-ico fa fa-mobile"></i>&nbsp;
                                                <span>Mobile Number</span>
                                            </label>
                                            <div class="input-group">
                                                <span class="input-group-addon" id="pd_mob">+91</span>
                                                <input value={values.pd_mobile} id="pd_mobile" name="pd_mobile" type="text" class="number form-control" placeholder="Enter Mobile Number" maxlength="10" required="" onChange={(e) => {
                                                    e.target.value = utilService.formatOnlyNumber(e.target.value);
                                                    handleChange(e);
                                                }} />
                                            </div>
                                            {(errors.pd_mobile && touched.pd_mobile) && <p class="err">{errors.pd_mobile}</p>}
                                            {pd_mobile !== "" && <p class="err">{pd_mobile}</p>}
                                        </div>
                                        <div class={`col-sm-4 col-xs-12 ${touched.pd_email ? errors.pd_email ? 'has-error' : 'has-success' : ''}`}>
                                            <label for="pd_email" class="reg m-cir">
                                                <i class="fa fa-envelope-open-o"></i>&nbsp;
                                                <span>Email Address</span>
                                            </label>
                                            <input value={values.pd_email} name="pd_email" id="pd_email" class="form-control" type="email" placeholder="Enter Email Address" maxlength="254" required="" onChange={handleChange} />
                                            {(errors.pd_email && touched.pd_email) && <p class="err">{errors.pd_email}</p>}
                                            {pd_email !== "" && <p class="err">{pd_email}</p>}
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="inner">
                                        <div class={`col-sm-4 col-xs-12 radio-order ${touched.gd ? errors.gd ? 'has-error' : 'has-success' : ''}`}>
                                            <fieldset>
                                                <legend class="reg m-cir">Gender</legend>
                                                <input type="radio" name="gd" id="radiomale" value="M" required="" onChange={handleChange} checked={values.gd === 'M'} />
                                                <label for="radiomale">
                                                    <i class="type-ico fa fa-male"></i>&nbsp;
                                                    <span data-ng-bind="trans.LBL_MALE">Male</span>
                                                </label>
                                                <input type="radio" name="gd" id="radiofemale" value="F" required="" onChange={handleChange} checked={values.gd === 'F'} />
                                                <label for="radiofemale">
                                                    <i class="type-ico fa fa-female"></i>&nbsp;
                                                    <span data-ng-bind="trans.LBL_FEMALE">Female</span>
                                                </label>
                                                <input type="radio" name="gd" id="radiotrans" value="O" required="" onChange={handleChange} checked={values.gd === 'O'} />
                                                <label for="radiotrans">
                                                    <i class="type-ico fa fa-street-view "></i>&nbsp;
                                                    <span data-ng-bind="trans.LBL_TRNSGNDR">Others</span>
                                                </label>
                                            </fieldset>
                                            {(errors.gd && touched.gd) && <p class="err">{errors.gd}</p>}
                                        </div>
                                        <div class="col-sm-4 col-xs-12">
                                            <label for="telstd" class="reg">
                                                <i class="type-ico fa fa-phone"></i>
                                                <span>Telephone Number (with STD Code)</span>
                                            </label>
                                            <div class="input-group">
                                                <div class="col-sm-3 col-xs-3 addrow" style={{ padding: 0 }}>
                                                    <input value={values.telstd} type="text" id="telstd" name="telstd" class="number form-control" maxLength={3} placeholder="STD" onChange={(e) => {
                                                        e.target.value = utilService.formatOnlyNumber(e.target.value);
                                                        handleChange(e);
                                                    }} />
                                                </div>
                                                <div class="col-sm-9 col-xs-9 addrow" style={{ padding: 0 }}>
                                                    <input value={values.telno} type="text" id="telno" name="telno" class="number form-control" placeholder="Enter Telephone Number" onChange={(e) => {
                                                        e.target.value = utilService.formatOnlyNumber(e.target.value);
                                                        handleChange(e);
                                                    }} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <h4>
                                <i class="fa fa-id-card"></i>&nbsp;
                                <span>Identity Information</span>
                            </h4>
                            <div class="tbl-format">
                                <div class="row">
                                    <div class="inner">
                                        <div class={`col-sm-4 col-xs-12 ${touched.dig ? errors.dig ? 'has-error' : 'has-success' : ''}`}>
                                            <label for="dig" class="reg m-cir">Designation / Status</label>
                                            <input value={values.dig} id="dig" name="dig" class="form-control" placeholder="Enter Designation" maxLength={25} required="" onChange={handleChange} />
                                            {(errors.dig && touched.dig) && <p class="err">{errors.dig}</p>}
                                        </div>
                                        <div class="col-sm-4 col-xs-12">
                                            <label for="din" class="reg">Director Identification Number</label>
                                            <span>
                                                <i class="fa fa-info-circle"> </i>
                                            </span>
                                            <input id="din" name="din" class="number form-control" type="text" placeholder="Enter DIN Number" maxLength={8} disabled={true} />
                                        </div>
                                        <div class="col-sm-4 col-xs-12">
                                            <label for="pd_cit_ind" class="reg">Are you a citizen of India?</label>
                                            <div class="switch round">
                                                <input id="pd_cit_ind" name="pd_cit_ind" type="checkbox" checked="checked" disabled={true} />
                                                <label for="pd_cit_ind">
                                                    <span class="switch-on" style={{ left: '4px' }}><span>Yes</span></span>
                                                    <span class="switch-off" style={{ left: '35px' }}><span>No</span></span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="inner">
                                        <div class={`col-sm-4 col-xs-12 ${touched.pan ? errors.pan ? 'has-error' : 'has-success' : ''}`}>
                                            <label for="pan" class="reg  m-cir">Permanent Account Number (PAN)</label>
                                            <input value={values.pan} id="pan" name="pan" class="form-control upper-input" type="text" placeholder="Enter Permanent Account Number (PAN)" maxLength={10} required="required" onChange={(e) => {
                                                e.target.value = e.target.value.toUpperCase();
                                                handleChange(e);
                                            }} />
                                            {(errors.pan && touched.pan) && <p class="err">{errors.pan}</p>}
                                            {pan !== "" && <p class="err">{pan}</p>}
                                        </div>
                                        <div class="col-sm-4 col-xs-12">
                                            <label for="ppno" class="reg">Passport Number (In case of Foreigner)</label>
                                            <input class="form-control upper-input" id="ppno" name="ppno" placeholder="Enter Passport Number" maxLength={15} disabled={true} />
                                        </div>
                                        <div class="col-sm-4 col-xs-12">
                                            <label class="reg" for="uid">Aadhaar Number</label>
                                            <span>
                                                <i class="fa fa-info-circle"></i>
                                            </span>
                                            <input name="uid" id="uid" class="form-control number" placeholder="Enter Aadhaar Number" disabled="disabled" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <h4>
                                <i class="fa fa-envelope"></i>&nbsp;
                                <span>Residential Address</span>
                            </h4>
                            <div>
                                <div ref={ref} id="map" style={{ minHeight: '400px' }} />
                            </div>
                            <div class="tbl-format">
                                <div class="row">
                                    <div class="inner">
                                        <div class="col-sm-4 col-xs-12">
                                            <label for="pd_cntry " class="reg m-cir ">Country</label>
                                            <select class="form-control" name="pd_cntry" id="pd_cntry" required="" disabled onChange={handleChange}>
                                                <option value="">Select</option>
                                                <option value="IND" selected="selected">India</option>
                                            </select>
                                        </div>
                                        <div class={`col-sm-4 col-xs-12 ${touched.pncd ? errors.pncd ? 'has-error' : 'has-success' : ''}`}>
                                            <label class="reg m-cir" for="pncd">PIN Code</label>
                                            <input value={values.pncd} name="pncd" id="pncd" type="text" class="form-control" placeholder="Enter PIN Code" required="" autocomplete="!!!!!!" maxLength={6} onChange={(e) => {
                                                e.target.value = utilService.formatOnlyNumber(e.target.value);
                                                handelePincode(e);
                                            }} />
                                            {(errors.pncd && touched.pncd) && <p class="err">{errors.pncd}</p>}
                                        </div>
                                        <div class={`col-sm-4 col-xs-12 ${touched.pd_state ? errors.pd_state ? 'has-error' : 'has-success' : ''}`}>
                                            <label for="pd_state" class="reg m-cir" data-ng-bind="trans.LBL_STATE">State</label>
                                            <input value={values.pd_state} id="pd_state" name="pd_state" type="text" class="form-control" placeholder="Enter State Name" required="" maxLength={60} autocomplete="!!!!!!" onChange={handleChange} />
                                            {(errors.pd_state && touched.pd_state) && <p class="err">{errors.pd_state}</p>}
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="inner">
                                        <div class={`col-sm-4 col-xs-12 ${touched.dst ? errors.dst ? 'has-error' : 'has-success' : ''}`}>
                                            <label class="reg m-cir" for="dst" data-ng-bind="trans.LBL_DISTRICT">District</label>
                                            <input value={values.dst} id="dst" name="dst" type="text" class="form-control" placeholder="Enter District Name" autocomplete="!!!!!!" maxLength={60} required="" onChange={handleChange} disabled={!isPincode} />
                                            {(errors.dst && touched.dst) && <p class="err">{errors.dst}</p>}
                                        </div>
                                        <div class={`col-sm-4 col-xs-12 ${touched.city ? errors.city ? 'has-error' : 'has-success' : ''}`}>
                                            <label class="reg m-cir" for="city" data-ng-bind="trans.LBL_LAV_NO">City / Town / Village</label>
                                            <input value={values.city} class="form-control" id="city" name="city" type="text" placeholder="Enter City / Town / Village" data-ng-model="pd.rsad.loc" required="" autocomplete="!!!!!!" onChange={handleChange} disabled={!isPincode} />
                                            {(errors.city && touched.city) && <p class="err">{errors.city}</p>}
                                        </div>
                                        <div class="col-sm-4 col-xs-12">
                                            <label class="reg" for="pd_locality" data-ng-bind="trans.LBL_LOSUBL">Locality/Sub Locality</label>
                                            <input value={values.pd_locality} class="form-control" maxLength={60} id="pd_locality" name="pd_locality" placeholder="Enter Locality / Sublocality" autocomplete="!!!!!!" onChange={handleChange} disabled={!isPincode} />
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="inner">
                                        <div class={`col-sm-4 col-xs-12 ${touched.pd_road ? errors.pd_road ? 'has-error' : 'has-success' : ''}`}>
                                            <label class="reg m-cir" for="pd_road" data-ng-bind="trans.LBL_RSL_NO">Road / Street</label>
                                            <input value={values.pd_road} class="form-control" id="pd_road" name="pd_road" placeholder="Enter Road / Street / Lane" maxLength={60} autocomplete="!!!!!!" required="" onChange={handleChange} disabled={!isPincode} />
                                            {(errors.pd_road && touched.pd_road) && <p class="err">{errors.pd_road}</p>}
                                        </div>
                                        <div class="col-sm-4 col-xs-12">
                                            <label class="reg" for="pd_bdname" data-ng-bind="trans.LBL_NM_BULD">Name of the Premises / Building</label>
                                            <input value={values.pd_bdname} class="form-control" maxLength={60} id="pd_bdname" name="pd_bdname" placeholder="Enter Name of Premises / Building" autocomplete="!!!!!!" onChange={handleChange} disabled={!isPincode} />
                                        </div>
                                        <div class={`col-sm-4 col-xs-12 ${touched.pd_bdnum ? errors.pd_bdnum ? 'has-error' : 'has-success' : ''}`}>
                                            <label class="reg m-cir" for="pd_bdnum" data-ng-bind="trans.LBL_BFD_NO">Building No. / Flat No.</label>
                                            <input value={values.pd_bdnum} class="form-control" id="pd_bdnum" name="pd_bdnum" placeholder="Enter Building No. / Flat No. / Door No." maxLength={60} autocomplete="!!!!!!" onChange={handleChange} required="" disabled={!isPincode} />
                                            {(errors.pd_bdnum && touched.pd_bdnum) && <p class="err">{errors.pd_bdnum}</p>}
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="inner">
                                        <div class="col-sm-4 col-xs-12">
                                            <label class="reg" for="pd_flrnum">Floor No.</label>
                                            <input value={values.pd_flrnum} class="form-control" maxLength={60} id="pd_flrnum" name="pd_flrnum" placeholder="Enter Floor No." autocomplete="!!!!!!" onChange={handleChange} disabled={!isPincode} />
                                        </div>
                                        <div class="col-sm-4 col-xs-12">
                                            <label class="reg" for="pd_landmark">Nearby Landmark</label>
                                            <input value={values.pd_landmark} class="form-control" maxLength={60} id="pd_landmark" name="pd_landmark" placeholder="Enter Nearby Landmark" autocomplete="!!!!!!" onChange={handleChange} disabled={!isPincode} />
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="inner">
                                        <div class="col-sm-4 col-xs-12">
                                        </div>
                                        <div class="col-sm-4 col-xs-12">
                                            <center onClick={() => { resetAddress() }}><button type="button" class="btn btn-primary"><i class="fa fa-refresh" aria-hidden="true"></i> &nbsp;Reset Address</button></center>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <h4>
                                <i class="fa fa-cloud-upload"></i>&nbsp;
                                <span >Document Upload</span>
                            </h4>
                            <div class="tbl-format">
                                <div class="row">
                                    {
                                        docs.pic !== null && <div class="inner">
                                            <div class="col-sm-8 col-xs-12">
                                                <ul style={{ listStyle: 'none' }}>
                                                    {
                                                        docs.pic.filetype === 'storage' && <li>
                                                            <div className='flex justify-between'>
                                                                <a href={docs.pic.fileurl} target="_black" style={{ display: 'inlineBlock' }}>
                                                                    <img alt="preview" src={icImg} />
                                                                    <p>Photo</p>
                                                                </a>
                                                                <button title="Delete" type="button" class="btn btn-sm btn-danger pull-right" disabled={isAuthr} style={{ verticalAlign: 'Top', marginLeft: '20px' }} onClick={() => { showDig('1', '') }}>
                                                                    <i class="fa fa-trash"></i> <span data-ng-bind="trans.LBL_DELETE">Delete</span>
                                                                </button>
                                                            </div>
                                                        </li>
                                                    }
                                                    {
                                                        docs.pic.filetype !== 'storage' && <li>
                                                            <div className='flex justify-between'>
                                                                <a>
                                                                    <img alt="preview" src={icImg} />
                                                                    <p>Photo</p>
                                                                </a>
                                                                <button title="Delete" type="button" class="btn btn-sm btn-danger pull-right h-14" style={{ verticalAlign: 'top', marginLeft: '20px' }} onClick={() => { showDig('1', '') }}>
                                                                    <i class="fa fa-trash"></i> <span data-ng-bind="trans.LBL_DELETE">Delete</span>
                                                                </button>
                                                            </div>
                                                        </li>
                                                    }
                                                </ul>
                                            </div>
                                        </div>
                                    }
                                    {
                                        docs.doc !== null && <div class="inner">
                                            <div class="col-sm-8 col-xs-12">
                                                <ul style={{ listStyle: 'none' }}>
                                                    {
                                                        docs.doc.filetype === 'storage' && <li>
                                                            <div className='flex justify-between'>
                                                                <a href={docs.doc.fileurl} target="_black" style={{ display: 'inlineBlock' }}>
                                                                    <img alt="preview" src={icImg} />
                                                                    <p>Document</p>
                                                                </a>
                                                                <button title="Delete" type="button" class="btn btn-sm btn-danger pull-right" disabled={isAuthr} style={{ verticalAlign: 'Top', marginLeft: '20px' }} onClick={() => { showDig('1', '') }}>
                                                                    <i class="fa fa-trash"></i> <span data-ng-bind="trans.LBL_DELETE">Delete</span>
                                                                </button>
                                                            </div>
                                                        </li>
                                                    }
                                                    {
                                                        docs.doc.filetype !== 'storage' && <li>
                                                            <div className='flex justify-between'>
                                                                <a>
                                                                    <img alt="preview" src={icImg} />
                                                                    <p>Document</p>
                                                                </a>
                                                                <button title="Delete" type="button" class="btn btn-sm btn-danger pull-right h-14" style={{ verticalAlign: 'top', marginLeft: '20px' }} onClick={() => { showDig('1', '') }}>
                                                                    <i class="fa fa-trash"></i> <span data-ng-bind="trans.LBL_DELETE">Delete</span>
                                                                </button>
                                                            </div>
                                                        </li>
                                                    }
                                                </ul>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                            {
                                docs.doc === null && <fieldset class="inner">
                                    <div class={`col-xs-12 col-sm-8 p-6 ${touched.as_up_type ? errors.as_up_type ? 'has-error' : 'has-success' : ''}`}>
                                        <label class="reg m-cir" for="as_upload_sign2"> Proof of details of authorized signatory</label>
                                        <select class="form-control" name="as_up_type" id="as_up_type" onChange={handleChange} value={values.as_up_type}>
                                            <option value="">Select</option>
                                            <option data-ng-repeat="doc in docTypes" value="LOAU" data-ng-bind="doc.n">Letter of Authorisation</option>
                                            <option data-ng-repeat="doc in docTypes" value="CRBC" data-ng-bind="doc.n">Copy of resolution passed by BoD / Managing Committee</option>
                                        </select>
                                        {(errors.as_up_type && touched.as_up_type) && <p class="err">{errors.as_up_type}</p>}
                                        <span class="help-block">
                                            <p class="help-block">
                                                <i class="fa fa-info-circle"></i>&nbsp;
                                                <span>File with PDF or JPEG format is only allowed.</span>
                                            </p>
                                            <p class="help-block">
                                                <i class="fa fa-info-circle"></i>&nbsp;
                                                <span>Maximum file size for upload is </span>1 MB
                                            </p>
                                        </span>
                                        <input id="as_upload_sign" name="as_upload_sign" type="file" onClick={(e) => openBox(e)} />
                                        {(isValid) && <p class="err">Attach the Proof of authorized signatory</p>}
                                    </div>
                                </fieldset>
                            }
                            {
                                docs.pic === null && <fieldset class="inner">
                                    <div class="col-sm-6 col-xs-12">
                                        <label class="reg m-cir" for="pd_upload2"><span>Upload Photograph (of person whose information has been given above)</span></label>
                                        <span class="help-block">
                                            <p class="help-block">
                                                <i class="fa fa-info-circle"></i>
                                                <span>Only JPEG file format is allowed</span>
                                            </p>
                                            <p class="help-block">
                                                <i class="fa fa-info-circle"></i>
                                                <span>Maximum file size for upload is </span>100 KB
                                            </p>
                                        </span>
                                        <input id="pd_upload" name="pd_upload" type="file" onClick={(e) => openBox(e)} />
                                        {(isValid) && <p class="err" data-ng-bind="errVar">Please select upload document.</p>}
                                    </div>
                                    <div class="col-sm-6 col-xs-12">
                                        <div class="row">
                                            <div class="col-xs-2 selfie-seprator">
                                                <div class="vseparator">
                                                    <div class="line"></div>
                                                    <div class="wordwrapper">
                                                        <div class="word">OR</div>
                                                    </div>
                                                </div>
                                                <div class="hseparator upload">OR</div>
                                            </div>
                                            <div class="col-xs-10">
                                                <div class="col-xs-12 text-center">
                                                    <button title="Take Picture" type="button" class="btn btn-primary" disabled={true} >
                                                        <i class="fa fa-camera"></i> Take Picture</button>
                                                    <p>
                                                        <span class="fa fa-info-circle">&nbsp;You can use your device camera to take selfie photograph.</span>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </fieldset>
                            }
                            <div class="col-xs-12">
                                {gstUsers[selectedUser] && gstUsers[selectedUser]['isProm'] === '1' && <p class="err m-0">Proof of Authorized Signatory is not required for proprietor who is also an authorized signatory.</p>}
                            </div>
                        </fieldset>
                    </div>
                    <div class="row next-tab-nav">
                        <div class="col-xs-12 text-right">
                            <a title="Back" class="btn btn-default" style={{ marginRight: '8px' }} onClick={() => {
                                gotoPath('sa2');
                            }}>Back</a>
                            <button title="Show List" type="button" class="btn btn-default" style={{ marginRight: '8px' }} onClick={() => {
                                setIsForm(false);
                                resetForm();
                            }}>Show List</button>&nbsp;
                            {
                                !isEdit && <>
                                    <button title="Add New" type="button" class="btn btn-primary" style={{ marginRight: '8px' }} onClick={() => {
                                        addNewAuth()
                                    }} disabled={gstUsers.length >= 3}>Add New</button>&nbsp;
                                    <button title="Save &amp; Continue" type="submit" class="btn btn-primary" disabled={isSubmit}>Save &amp; Continue</button>
                                </>
                            }
                            {
                                isEdit && <button title="Save" type="submit" class="btn btn-primary" disabled={isSubmit}>Save</button>
                            }
                        </div>
                    </div>
                </div>
            }
        </form>
    );
}
