import React, { useState, useEffect } from 'react';

import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Dialog } from '@headlessui/react';
import Moment from 'moment';

import { useRecoilState } from 'recoil';
import { pathAtom, gstrpathAtom, returnDateAtom, loadingAtom, gstrQueryAtom, gstrFileStatusAtom, gstrUserAtom, userIDAtom } from '../../../core/config/atoms';

import imgLoader from '../../../assets/images/cloading.gif';

import '../css/main.css';
import '../css/style.css';

import ServiceOption from '../../../services/serviceOptions';
import ServiceGSTR from '../../../services/serviceGSTR';
import ServiceUtility from '../../../services/serviceUtility';

export default function PanelAA11Home() {

    const [path, setPath] = useRecoilState(pathAtom);
    const [gstrPath, setGSTRPath] = useRecoilState(gstrpathAtom);
    const [gDate] = useRecoilState(returnDateAtom);
    const [loading, setLoading] = useRecoilState(loadingAtom);
    const [query, setQuery] = useRecoilState(gstrQueryAtom);
    const [fileStatus] = useRecoilState(gstrFileStatusAtom);
    const [GSTUser] = useRecoilState(gstrUserAtom);
    const [userID] = useRecoilState(userIDAtom);

    const [states, setStates] = useState([]);
    const [records, setRecords] = useState([]);
    const [fyears, setFYears] = useState([]);
    const [mmonts, setMMonths] = useState([]);

    const [digConfirm, setDigConfirm] = useState({ 'type': '0', isToggle: false, data: null });

    const [isErrorAlert, setIsErrorAlert] = useState("");
    const [isInvoice, setIsInvoice] = useState("");

    const [isAlert, setIsAlrt] = useState(false);
    const [add, setAdd] = useState(false);

    const optionService = new ServiceOption();
    const returnService = new ServiceGSTR();
    const utilService = new ServiceUtility();

    const formVSchema = Yup.object().shape({
        year: Yup.string().required('This information is required'),
        month: Yup.string().required('This information is required'),
        pos: Yup.string().required('This information is required'),
    });

    const { values, errors, touched, setValues, handleChange, handleSubmit, handleReset, resetForm } = useFormik({
        initialValues: {
            year: '',
            month: '',
            pos: '',
        },
        validationSchema: formVSchema,
        onSubmit: values => {
            if (!cheackInvoice(values.pos, values.month)) {
                setIsInvoice("");
                setLoading(true);
                if (add) {
                    setQuery({ type: 'new', data: { pos: values.pos, month: values.month, year: values.year } });
                    gotoGstrPath('aa11b');
                } else {
                    let body = {
                        lid: userID,
                        month: values.month,
                        cmonth: gDate,
                        pos: values.pos,
                        type: 'a11'
                    }
                    returnService.findInvoice(body).then((res) => {
                        if (res.status) {
                            setQuery({ type: 'amend', data: { id: res.data._id, year: values.year, month: values.month } });
                            gotoGstrPath('aa11b');
                        } else {
                            setAdd(true);
                        }
                        setLoading(false);
                    });
                }
            } else {
                setIsInvoice("POS '" + values.pos + "' already amended in the current peroid.");
            }
        }
    });

    const cheackInvoice = (pos, month) => {
        let res = false;
        for (var i = 0; i < records.length; i++) {
            if (records[i].data.pos === pos && Moment(records[i].data.month).toDate().getUTCMilliseconds() === Moment(month).toDate().getUTCMilliseconds()) {
                res = true;
                break;
            }
        }
        return res;
    }

    const gotoPath = (path) => {
        setLoading(true);
        setPath(path);
    }

    const gotoGstrPath = (path) => {
        setGSTRPath(path);
        gotoPath('gstrFile');
    }

    const showAlert = () => {
        setIsAlrt(true);
        setTimeout(
            () => setIsAlrt(false),
            5000
        );
    }

    const calcInit = () => {
        let ye = [];
        let mon = [];

        let data = Moment(gDate).toDate();

        if (data.getMonth() >= 3) {
            data.setMonth(3);
            data.setDate(1);

            let partA = Moment(data).format("YYYY");
            let partB = Moment(data).add(1, 'years').format("YY");
            let label = partA + "-" + partB;

            ye.push({ label: label, data: Moment(data).toDate(), status: true });
        } else {
            data.setMonth(3);
            data.setDate(1);

            let partA = Moment(data).subtract(1, 'years').format("YYYY");
            let partB = Moment(data).format("YY");
            let label = partA + "-" + partB;

            ye.push({ label: label, data: Moment(data).subtract(1, 'years').toDate(), status: true });
        }

        let temp = Moment(gDate).toDate();
        temp.setMonth(3);
        let m = 3;

        do {
            mon.push({ label: Moment(temp).format("MMMM"), data: Moment(temp).toDate(), status: true });
            m++;
            temp.setMonth(m);
        } while (temp < Moment(gDate).toDate());

        setValues({ ...values, year: ye[0].data, month: mon[0].data });
        setFYears(ye);
        setMMonths(mon);
    }

    const editInvoice = (id) => {
        setQuery({ type: 'edit', data: id });
        gotoGstrPath('aa11b');
    }

    const showDig = (type, data) => {
        setDigConfirm({ 'type': type, isToggle: true, data: data });
    }

    const cancel = () => {
        setDigConfirm({ 'type': '0', isToggle: false, data: null });
    }

    const process = () => {
        switch (digConfirm.type) {
            case '1':
                deleteInvoice(digConfirm.data.id, digConfirm.data.oid, digConfirm.data.status);
                break;
            default:
                break;
        }
        setDigConfirm({ 'type': '0', isToggle: false, data: null });
    }

    const deleteInvoice = (id, oid, status) => {
        setLoading(true);
        let body = {
            lid: userID,
            type: 'a11a',
            status: status,
            month: gDate,
            id: id,
            oid: oid
        }
        returnService.deleteGstrDoc(body).then((res) => {
            setLoading(false);
            setIsInvoice("");
            showAlert();
            loadData();
        });
    }

    const getSelectionRecordsA = () => {
        let rows = [];
        records.forEach((row) => {
            let s = 'processed';
            if (s === row.status) {
                let tax = utilService.getTotalValue(row.data.items).toFixed(2);
                let igst = (GSTUser.state === row.data.pos ? 0 : utilService.getTotalI(row.data.items)).toFixed(2);
                let cgst = (GSTUser.state === row.data.pos ? utilService.getTotalC(row.data.items) : 0).toFixed(2);
                let sgst = (GSTUser.state === row.data.pos ? utilService.getTotalS(row.data.items) : 0).toFixed(2);
                let cess = utilService.getTotalCess(row.data.items).toFixed(2);
                let action = row.data.status;
                rows.push({ id: row._id, oid: row.data.oid, pos: row.data.pos, supply: row.data.supplyty, tax: tax, igst: igst, cgst: cgst, sgst: sgst, cess: cess, action: action });
            }
        });
        return rows;
    }

    const getSelectionRecordsB = () => {
        let rows = [];
        records.forEach((row) => {
            let s = 'pending';
            if (s === row.status) {
                let tax = utilService.getTotalValue(row.data.items).toFixed(2);
                let igst = (GSTUser.state === row.data.pos ? 0 : utilService.getTotalI(row.data.items)).toFixed(2);
                let cgst = (GSTUser.state === row.data.pos ? utilService.getTotalC(row.data.items) : 0).toFixed(2);
                let sgst = (GSTUser.state === row.data.pos ? utilService.getTotalS(row.data.items) : 0).toFixed(2);
                let cess = utilService.getTotalCess(row.data.items).toFixed(2);
                let action = row.data.status;
                rows.push({ id: row._id, oid: row.data.oid, pos: row.data.pos, supply: row.data.supplyty, tax: tax, igst: igst, cgst: cgst, sgst: sgst, cess: cess, action: action });
            }
        });
        return rows;
    }

    const loadData = () => {
        setLoading(true);
        (async function () {
            var resB = await optionService.getStates({});
            setStates(resB.data);

            if (query.type === 'done') {
                showAlert();
                setQuery({ type: '', data: '' });
            }
            let body = {
                lid: userID,
                month: gDate,
                type: 'a11a'
            }
            var res = await returnService.getGstrDocs(body);
            setRecords(res.data);
            calcInit();

            setLoading(false);
            window.scrollTo({
                top: 0,
                behavior: 'smooth',
            });
        })();
    }

    useEffect(() => {
        loadData();
    }, []);

    return (
        <div>
            <Dialog open={digConfirm.isToggle} onClose={() => setDigConfirm({ 'type': '0', isToggle: false, data: null })}>
                <div id="confirmDlg" class="modal fade fade-scale in" role="dialog" style={{ display: 'block', paddingRight: '17px' }}>
                    <div class="modal-dialog sweet">
                        <div class="modal-content">
                            <div class="modal-body">
                                <div class="m-icon m-warning pulseWarning">
                                    <span class="micon-body pulseWarningIns"></span>
                                    <span class="micon-dot pulseWarningIns"></span>
                                </div>
                                <h2>Warning</h2>
                                <p>Are you sure, you want to continue?</p>
                            </div>
                            <div class="modal-footer">
                                <a class="btn btn-default" onClick={() => { cancel() }}>Cancel</a>
                                <a class="btn btn-primary" onClick={() => { process() }}>Proceed</a>
                            </div>
                        </div>
                    </div>
                </div>
            </Dialog>
            <div class="content-wrapper fade-ng-cloak">
                <div class="dimmer-holder" style={{ display: 'none' }}>
                    <div id="dimmer"></div>
                </div>
                {
                    loading && <div className='absolute z-50 w-full h-screen flex items-center justify-center bg-gray-200 bg-opacity-25'>
                        <img src={imgLoader} alt="loader" />
                    </div>
                }
                <div class="container">
                    <div class="mypage">
                        <div class="row">
                            <div class="col-xs-10">
                                <div name="Dashboard">
                                    <div name="Dashboard">
                                        <ol class="breadcrumb" data-ng-controller="crumbCtrl">
                                            <li>
                                                <a onClick={() => {
                                                    gotoPath('gstr')
                                                }}>Dashboard</a></li>
                                            <li>
                                                <a onClick={() => {
                                                    gotoPath('gstRetrun')
                                                }}>Returns</a>
                                            </li>
                                            <li>
                                                <a onClick={() => {
                                                    gotoPath('gstrForm')
                                                }}>GSTR-1/IFF</a>
                                            </li>
                                            <li>
                                                <span ng-switch-when="true">Amended Tax Liability (Advances Received)</span>
                                            </li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xs-2">
                                <div class="lang dropdown">
                                    <span class="dropdown-toggle">English</span>
                                    <ul class="dropdown-menu lang-dpdwn">
                                        <li>
                                            <a>English</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div class="container">
                                {isErrorAlert !== "" && <alert-message ng-show="showMsg" title="" type="msg alert-danger" message="" class="ng-hide">
                                    <div class="alert alert-msg alert-danger">
                                        {isErrorAlert}
                                        <a class="close" onClick={() => { setIsErrorAlert("") }} data-dismiss="alert" aria-label="close">×</a>
                                        <strong></strong>
                                    </div>
                                </alert-message>}
                                {isAlert && <alert-message ng-show="showMsg" title="" type="msg alert-success" message="" class="ng-hide"><div class="alert alert-msg alert-success">Request accepted successfully<a class="close" onClick={() => { setIsAlrt(false) }} data-dismiss="alert" aria-label="close">×</a><strong></strong>.</div></alert-message>}
                                <div class="row invsumm">
                                    <div class="col-xs-12 col-sm-12 taxp">
                                        <h4>11A - Amended Tax Liability (Advances Received)</h4>
                                        <button class="btn btn-primary btn-circle btn-sm pull-right" style={{ marginTop: '0px' }} disabled={true}><i class="fa fa-refresh" aria-hidden="true"></i></button>
                                        <button type="button" class="btn btn-primary btn-sm pull-right" data-ng-click="help()" style={{ marginTop: '0px', marginRight: '5px' }} disabled={true}>
                                            <span>Help</span>&nbsp;
                                            <i class="fa fa-question-circle" aria-hidden="true"></i>
                                        </button>
                                    </div>
                                </div>
                                <div class="tabpane">
                                    {records.length == 0 && <div class="alert alert-msg alert-info alert-dismissible">There are no records to be displayed.</div>}
                                    <p class="mand-text" data-ng-bind="trans.HLP_MAND_FIELD">Indicates Mandatory Fields</p>
                                    <form name="amendSearch" autocomplete="off" novalidate="" onSubmit={handleSubmit} onReset={handleReset}>
                                        <div class="tbl-format rettbl-format">
                                            <fieldset disabled={fileStatus}>
                                                <div class="row">
                                                    <div class={`col-sm-4 col-xs-12 ${touched.year ? errors.year ? 'has-error' : 'has-success' : ''}`}>
                                                        <label for="year" class="reg m-cir">Financial Year</label>
                                                        <select name="year" id="year" class="form-control" value={values.year} onChange={(e) => {
                                                            setAdd(false);
                                                            handleChange(e);
                                                        }} >
                                                            {
                                                                fyears.map((ye) => {
                                                                    return <option value={ye.data}>{ye.label}</option>
                                                                })
                                                            }
                                                        </select>
                                                        {(errors.year && touched.year) && <span className='err'>{errors.year}</span>}
                                                    </div>
                                                    <div class={`col-sm-4 col-xs-12 ${touched.month ? errors.month ? 'has-error' : 'has-success' : ''}`}>
                                                        <label for="month" class="reg m-cir">Month</label>
                                                        <select name="month" id="month" class="form-control" value={values.month} onChange={(e) => {
                                                            setAdd(false);
                                                            handleChange(e);
                                                        }}>
                                                            {
                                                                mmonts.map((mon) => {
                                                                    return <option value={mon.data}>{mon.label}</option>
                                                                })
                                                            }
                                                        </select>
                                                        {(errors.month && touched.month) && <span className='err'>{errors.month}</span>}
                                                    </div>
                                                    <div class={`col-sm-4 ${touched.pos ? errors.pos ? 'has-error' : 'has-success' : ''}`}>
                                                        <label class="reg m-cir" for="pos"><span data-ng-bind="trans.ORG_LBL_POS">Original POS</span>
                                                            <i class="fa fa-info-circle" data-placement="top" title="Place of Supply(Only if different from location of recipient)" aria-hidden="true"></i>
                                                        </label>
                                                        <select name="pos" class="form-control" id="pos" value={values.pos} onChange={(e) => {
                                                            setAdd(false);
                                                            handleChange(e);
                                                        }}>
                                                            <option value="" selected="selected" disabled="">Select</option>
                                                            {
                                                                states.length !== 0 && states.map((s) => {
                                                                    return <option value={s} selected={values.pos == s} key={s}>{s}</option>
                                                                })
                                                            }
                                                        </select>
                                                        {(errors.pos && touched.pos) && <span className='err'>{errors.pos}</span>}
                                                        {isInvoice != "" && <span className='err'>{isInvoice}</span>}
                                                    </div>
                                                </div>
                                            </fieldset>
                                            <div class="row">
                                                <div class="col-sm-8">
                                                    <br />
                                                    <input type="checkbox" class="chkbx" id="rate_flag" disabled={true} />
                                                    <label for="rate_flag">Is the supply eligible to be taxed at a differential percentage (%) of the existing rate of tax, as notified by the Government?</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row mar-b">
                                            <div class="col-sm-12 col-xs-12 text-right next-tab-nav">
                                                {
                                                    (getSelectionRecordsA().length === 0 && getSelectionRecordsB().length === 0) && <>
                                                        <button type="button" class="btn btn-default" onClick={() => {
                                                            if (add) {
                                                                setAdd(false);
                                                            } else {
                                                                gotoPath('gstrForm');
                                                            }
                                                        }}>Back</button>&nbsp;
                                                    </>
                                                }
                                                {!add && <button type="submit" class="btn btn-primary">Amend Record</button>}
                                                {add && <button type="submit" class="btn btn-primary">Add POS</button>}
                                            </div>
                                        </div>
                                    </form>
                                    {
                                        getSelectionRecordsA().length !== 0 && < div class="row" data-ng-if="b2binvdt.processedInvoice">
                                            <div class="col-sm-12">
                                                <h4 data-ng-bind="trans.LBL_PRO_RCD">Processed Records</h4>
                                            </div>
                                            <br />

                                            <div class="col-xs-12 col-sm-12">
                                                <div class="row">
                                                    <div class="col-xs-12">
                                                        <div class="col-lg-3 pull-right">
                                                            <div class="form-group pull-right" style={{ display: 'inline-flex' }}>
                                                                <div class="col-lg-9"><label class="form-label">Records Per Page : </label></div>
                                                                <div class="col-lg-3" style={{ paddingLeft: '0', marginLeft: '-0.7em' }}>
                                                                    <select class="form-control ng-pristine ng-untouched ng-valid ng-not-empty" style={{ minWidth: '5em', border: '1px solid' }} ng-model="obj.recPerPageProc" data-ng-change="changeRecordsPerpagePrs()" ng-options="item as item for item in recordsPerpageOptionsb2b" value="item">
                                                                        <option label="10" value="number:10" selected="selected">10</option>
                                                                        <option label="20" value="number:20">20</option>
                                                                        <option label="50" value="number:50">50</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="table-responsive">
                                                    <table class="table exp inv tbl table-bordered ng-table" style={{ display: 'table' }}>
                                                        <thead ng-include="templates.header">
                                                            <tr class="ng-table-sort-header">
                                                                <th title="" ng-repeat="$column in $columns" class="header sortable">
                                                                    <div class="ng-table-header">
                                                                        <span class="sort-indicator">Place of Supply<br />(Name of State)</span>
                                                                    </div>
                                                                </th>
                                                                <th title="" class="header ">
                                                                    <div>
                                                                        <span class="sort-indicator">Supply Type</span>
                                                                    </div>
                                                                </th>
                                                                <th title="" class="header ">
                                                                    <div>
                                                                        <span class="sort-indicator">Gross Advance Received (excluding tax) (₹)</span>
                                                                    </div>
                                                                </th>
                                                                <th title="" class="header ">
                                                                    <div>
                                                                        <span class="sort-indicator">Integrated Tax (₹)</span>
                                                                    </div>
                                                                </th>
                                                                <th title="" class="header ">
                                                                    <div>
                                                                        <span class="sort-indicator">Central tax (₹)</span>
                                                                    </div>
                                                                </th>
                                                                <th title="" class="header ">
                                                                    <div>
                                                                        <span class="sort-indicator">State/UT Tax (₹)</span>
                                                                    </div>
                                                                </th>
                                                                <th title="" class="header ">
                                                                    <div>
                                                                        <span class="sort-indicator">Cess (₹)</span>
                                                                    </div>
                                                                </th>
                                                                <th title="" class="header ">
                                                                    <div>
                                                                        <span class="sort-indicator">Applicable percentage (%)</span>
                                                                    </div>
                                                                </th>
                                                                <th title="" class="header ">
                                                                    <div>
                                                                        <span class="sort-indicator">Actions</span>
                                                                    </div>
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody data-ng-repeat="t in $data">
                                                            {
                                                                getSelectionRecordsA().map((row) => {
                                                                    return <tr>
                                                                        <td class="text-center">
                                                                            {row.pos}
                                                                        </td>
                                                                        <td class="text-center">
                                                                            {row.supply}
                                                                        </td>
                                                                        <td class="currency">{row.tax}</td>
                                                                        <td class="currency">{row.igst}</td>
                                                                        <td class="currency">{row.cgst}</td>
                                                                        <td class="currency">{row.sgst}</td>
                                                                        <td class="currency">{row.cess}</td>
                                                                        <td class="text-right">-</td>
                                                                        <td style={{ display: 'inline-block' }} class="text-center newinv" data-title-text="Actions">
                                                                            <div className='flex'>
                                                                                <button type="button" class="btn btn-sm btn-primary nomargin" onClick={() => {
                                                                                    editInvoice(row.id);
                                                                                }} disabled={fileStatus}><i class="fa fa-pencil" aria-hidden="true"></i><span class="sr-only">text</span>
                                                                                </button>&nbsp;
                                                                                <button type="button" class="btn btn-sm btn-danger nomargin" title="Delete" onClick={() => {
                                                                                    showDig('1', { id: row.id, status: 'processed', oid: row.oid });
                                                                                }} disabled={fileStatus}>
                                                                                    <i class="fa fa-trash"></i><span class="sr-only">text</span>
                                                                                </button>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                })
                                                            }
                                                        </tbody>
                                                    </table>
                                                    <div ng-table-pagination="params" template-url="templates.pagination">
                                                        <div ng-include="templateUrl">
                                                            <div class="ng-table-pager" ng-if="params.data.length">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    {
                                        getSelectionRecordsB().length !== 0 && < div class="row" data-ng-if="b2binvdt.processedInvoice">
                                            <div class="col-sm-12">
                                                <h4 data-ng-bind="trans.LBL_PRO_RCD">Pending records (These will be added after validation)</h4>
                                            </div>
                                            <br />

                                            <div class="col-xs-12 col-sm-12">
                                                <div class="row">
                                                    <div class="col-xs-12">
                                                        <div class="col-lg-3 pull-right">
                                                            <div class="form-group pull-right" style={{ display: 'inline-flex' }}>
                                                                <div class="col-lg-9"><label class="form-label">Records Per Page : </label></div>
                                                                <div class="col-lg-3" style={{ paddingLeft: '0', marginLeft: '-0.7em' }}>
                                                                    <select class="form-control ng-pristine ng-untouched ng-valid ng-not-empty" style={{ minWidth: '5em', border: '1px solid' }} ng-model="obj.recPerPageProc" data-ng-change="changeRecordsPerpagePrs()" ng-options="item as item for item in recordsPerpageOptionsb2b" value="item">
                                                                        <option label="10" value="number:10" selected="selected">10</option>
                                                                        <option label="20" value="number:20">20</option>
                                                                        <option label="50" value="number:50">50</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="table-responsive">
                                                    <table class="table exp inv tbl table-bordered ng-table" style={{ display: 'table' }}>
                                                        <thead ng-include="templates.header">
                                                            <tr class="ng-table-sort-header">
                                                                <th title="" ng-repeat="$column in $columns" class="header sortable">
                                                                    <div class="ng-table-header">
                                                                        <span class="sort-indicator">Place of Supply<br />(Name of State)</span>
                                                                    </div>
                                                                </th>
                                                                <th title="" class="header ">
                                                                    <div>
                                                                        <span class="sort-indicator">Supply Type</span>
                                                                    </div>
                                                                </th>
                                                                <th title="" class="header ">
                                                                    <div>
                                                                        <span class="sort-indicator">Gross Advance Received (excluding tax) (₹)</span>
                                                                    </div>
                                                                </th>
                                                                <th title="" class="header ">
                                                                    <div>
                                                                        <span class="sort-indicator">Integrated Tax (₹)</span>
                                                                    </div>
                                                                </th>
                                                                <th title="" class="header ">
                                                                    <div>
                                                                        <span class="sort-indicator">Central tax (₹)</span>
                                                                    </div>
                                                                </th>
                                                                <th title="" class="header ">
                                                                    <div>
                                                                        <span class="sort-indicator">State/UT Tax (₹)</span>
                                                                    </div>
                                                                </th>
                                                                <th title="" class="header ">
                                                                    <div>
                                                                        <span class="sort-indicator">Cess (₹)</span>
                                                                    </div>
                                                                </th>
                                                                <th title="" class="header ">
                                                                    <div>
                                                                        <span class="sort-indicator">Applicable percentage (%)</span>
                                                                    </div>
                                                                </th>
                                                                <th title="" class="header ">
                                                                    <div>
                                                                        <span class="sort-indicator">Status</span>
                                                                    </div>
                                                                </th>
                                                                <th title="" class="header ">
                                                                    <div>
                                                                        <span class="sort-indicator">Actions</span>
                                                                    </div>
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody data-ng-repeat="t in $data">
                                                            {
                                                                getSelectionRecordsB().map((row) => {
                                                                    return <tr>
                                                                        <td class="text-center">
                                                                            {row.pos}
                                                                        </td>
                                                                        <td class="text-center">
                                                                            {row.supply}
                                                                        </td>
                                                                        <td class="currency">{row.tax}</td>
                                                                        <td class="currency">{row.igst}</td>
                                                                        <td class="currency">{row.cgst}</td>
                                                                        <td class="currency">{row.sgst}</td>
                                                                        <td class="currency">{row.cess}</td>
                                                                        <td class="text-right">-</td>
                                                                        <td class="text-center">Processed with error</td>
                                                                        <td style={{ display: 'inline-block' }} class="text-center newinv" data-title-text="Actions">
                                                                            <div className='flex'>
                                                                                <button type="button" class="btn btn-sm btn-primary nomargin" onClick={() => {
                                                                                    editInvoice(row.id);
                                                                                }} disabled={fileStatus}><i class="fa fa-pencil" aria-hidden="true"></i><span class="sr-only">text</span>
                                                                                </button>&nbsp;
                                                                                <button type="button" class="btn btn-sm btn-danger nomargin" title="Delete" onClick={() => {
                                                                                    showDig('1', { id: row.id, status: 'pending', oid: row.oid });
                                                                                }} disabled={fileStatus}>
                                                                                    <i class="fa fa-trash"></i><span class="sr-only">text</span>
                                                                                </button>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                })
                                                            }
                                                        </tbody>
                                                    </table>
                                                    <div ng-table-pagination="params" template-url="templates.pagination">
                                                        <div ng-include="templateUrl">
                                                            <div class="ng-table-pager" ng-if="params.data.length">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    {
                                        (getSelectionRecordsA().length !== 0 || getSelectionRecordsB().length !== 0) && <div class="row mar-b">
                                            <div class="col-sm-12 col-xs-12 text-right next-tab-nav">
                                                <button type="button" class="btn btn-default" onClick={() => {
                                                    if (add) {
                                                        setAdd(false);
                                                    } else {
                                                        gotoPath('gstrForm');
                                                    }
                                                }}>Back</button>&nbsp;
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
