import React, { useState, useEffect } from 'react';

import { useRecoilState } from 'recoil';
import { pathAtom, gstrpathAtom, returnDateAtom, gstrUserAtom, userIDAtom, challanAtom, isBankAtom, loadingAtom } from '../../../core/config/atoms';

import Moment from 'moment';
import { motion, AnimatePresence } from "framer-motion";
import FeatherIcon from 'feather-icons-react';

import ServiceGSTF from '../../../services/serviceGSTF';
import ServiceUtility from '../../../services/serviceUtility';

import bankLogo from '../../../assets/images/banklogo.png';

export default function PanelBankHome() {

    const [gDate] = useRecoilState(returnDateAtom);
    const [GSTUser] = useRecoilState(gstrUserAtom);
    const [userID] = useRecoilState(userIDAtom);

    const [, setGSTRPath] = useRecoilState(gstrpathAtom);
    const [, setIsBank] = useRecoilState(isBankAtom);
    const [, setPath] = useRecoilState(pathAtom);
    const [, setLoading] = useRecoilState(loadingAtom);

    const [challan, setCurrentChallan] = useRecoilState(challanAtom);

    const [passVal, setPassVal] = useState('');
    const [tid, setTid] = useState('');
    const [errMsg, setErrMsg] = useState('');

    const [step, setStep] = useState(1);

    const [isError, setIsError] = useState(false);
    const [isAlert, setIsAlert] = useState(false);

    const alertFade = {
        hidden: { opacity: 0 },
        show: { opacity: 1 }
    }

    const fileService = new ServiceGSTF();
    const utilService = new ServiceUtility();

    const gotoPath = (path) => {
        setPath(path);
    }

    const getTCGST = () => {
        if (challan !== {}) {
            return challan.data.tax.cgst + challan.data.interest.cgst + challan.data.fees.cgst;
        } else {
            return 0;
        }
    }

    const getTSGST = () => {
        if (challan !== {}) {
            return challan.data.tax.sgst + challan.data.interest.sgst + challan.data.fees.sgst;
        } else {
            return 0;
        }
    }

    const getTotal = () => {
        if (challan !== {}) {
            let tax = challan.data.tax.igst + challan.data.tax.cgst + challan.data.tax.sgst + challan.data.tax.cess;
            let intr = challan.data.interest.igst + challan.data.interest.cgst + challan.data.interest.sgst + challan.data.interest.cess;
            let fees = challan.data.fees.cgst + challan.data.fees.sgst;
            return tax + intr + fees;
        } else {
            return 0;
        }
    }

    const handleOnChangeForm = (e) => {
        var { value, name } = e.target;
        setPassVal(value);
        setIsError(false);
    }

    const submitChallan = () => {
        if (passVal === '') {
            setErrMsg('Password field is required.');
            setIsAlert(true);
            setIsError(true);
            setTimeout(() => {
                setIsAlert(false);
            }, 3000);
        } else {
            let body = {
                lid: userID,
                month: gDate,
                cpin: challan.cpin,
                tid: tid,
                password: passVal,
            }
            fileService.payChllan(body).then((res) => {
                if (res.status) {
                    setCurrentChallan(res.data);
                    setStep(3);
                    setTimeout(() => {
                        setLoading(true);
                        setIsBank(false);
                        setGSTRPath('success');
                        gotoPath('gstr3BChallan');
                    }, 5000);
                } else {
                    setErrMsg('Username or password invalid.');
                    setIsAlert(true);
                    setTimeout(() => {
                        setIsAlert(false);
                    }, 3000);
                }
            });
        }
    }

    useEffect(() => {
        setTid(utilService.getTID());
    }, []);

    return (
        <>
            <div className='h-44 bg-teal-600'>
                <img src={bankLogo} alt='logo' className='w-[280px] ml-16 mt-6' />
            </div>
            <AnimatePresence>
                <motion.div variants={alertFade}
                    initial="hidden"
                    animate={isAlert ? "show" : "hidden"}
                    transition={{ delay: 0.1 }}
                    className="rounded text-lg font-serif mt-10 mr-8 bg-yellow-100 shadow border border-red-700 h-14 flex flex-col justify-center" style={{ position: 'absolute', right: '20px', top: '20px' }}>
                    <p className="px-4 py-2 flex text-red-600 text-2xl m-0">
                        <FeatherIcon icon="bell" size={18} />&nbsp;&nbsp;
                        {errMsg}
                    </p>
                </motion.div>
            </AnimatePresence>
            <div className='w-[1024px] mx-auto'>
                <h3>Initiate Tax Payment</h3>
                <div className='w-full shadow mt-8 bg-white'>
                    <div className='flex items-center justify-evenly py-8'>
                        <div className='flex flex-col items-center'>
                            <div className={`text-3xl w-16 h-16 flex items-center justify-center rounded-full text-white ${step === 1 ? 'bg-orange-400' : step > 1 ? 'bg-teal-600' : 'bg-gray-400'}`}>1</div>
                            <p className='mt-4 text-center'>New Request Details</p>
                        </div>
                        <div className='h-1 w-[240px] bg-gray-800 rounded-full'></div>
                        <div className='flex flex-col items-center'>
                            <div className={`text-3xl w-16 h-16 flex items-center justify-center rounded-full text-white ${step === 2 ? 'bg-orange-400' : step > 2 ? 'bg-teal-600' : 'bg-gray-400'}`}>2</div>
                            <p className='mt-4 text-center'>Preview and Confirm</p>
                        </div>
                        <div className='h-1 w-[240px] bg-gray-800 rounded-full'></div>
                        <div className='flex flex-col items-center'>
                            <div className={`text-3xl w-16 h-16 flex items-center justify-center rounded-full text-white ${step === 3 ? 'bg-orange-400' : 'bg-gray-400'}`}>3</div>
                            <p className='mt-4 text-center'>Summary</p>
                        </div>
                    </div>
                    <div className='h-[1px] w-full bg-gray-400 rounded-full'></div>
                    {
                        step === 1 && <div>
                            <h4 className='pl-6'>Initiate GSTN Tax Payment</h4>
                            <div className='h-[1px] w-full bg-gray-400 rounded-full'></div>
                            <div className='pl-14 py-6'>
                                <div className='flex'>
                                    <div>
                                        <p>Merchant ID:</p>
                                        <p>Transaction Id:</p>
                                        <p>GSTN:</p>
                                        <p>CPIN:</p>
                                        <p>IGST Amount:</p>
                                        <p>SGST Amount:</p>
                                        <p>CGST Amount:</p>
                                        <p>CESS Amount:</p>
                                        <p>Beneficiary State:</p>
                                        <p>Transaction Date:</p>
                                        <p>Challan Expiry Date:</p>
                                        <p>IGST ACC Code:</p>
                                        <p>SGST ACC Code:</p>
                                        <p>CGST ACC Code:</p>
                                        <p>CESS ACC Code:</p>
                                        <p>Total Amount:</p>
                                        <p>Amount in words:</p>
                                        <p>Debit Account Number:</p>
                                    </div>
                                    <div style={{ marginLeft: '120px' }}>
                                        <p>GSTN</p>
                                        <p>{tid}</p>
                                        <p>{GSTUser.gstn}</p>
                                        <p>{challan.cpin}</p>
                                        <p>INR {(challan.data.tax.igst + challan.data.interest.igst).toFixed(2)}</p>
                                        <p>INR {getTSGST().toFixed(2)}</p>
                                        <p>INR {getTCGST().toFixed(2)}</p>
                                        <p>INR {(challan.data.tax.cess + challan.data.interest.cess).toFixed(2)}</p>
                                        <p>{utilService.getStateCode(GSTUser.state.toUpperCase())}</p>
                                        <p>{Moment(gDate).add(1, 'month').add(19, 'days').format('DD/MM/YYYY')}</p>
                                        <p>{Moment(gDate).add(1, 'month').add(34, 'days').format('DD/MM/YYYY')}</p>
                                        <p>0008</p>
                                        {utilService.getIsState(GSTUser.state) && <p>0006</p>}
                                        {!utilService.getIsState(GSTUser.state) && <p>0007</p>}
                                        <p>0005</p>
                                        <p>0009</p>
                                        <p>INR {getTotal().toFixed(2)}</p>
                                        <p>{utilService.wordify(getTotal())}</p>
                                        <p>XXXXXXXXXXXXXXXXXX</p>
                                    </div>
                                </div>
                            </div>
                            <div className='h-[1px] w-full bg-gray-400 rounded-full'></div>
                            <div className='p-6 float-right'>
                                <button type="button" className="btn btn-primary sendButton" onClick={() => {
                                    setStep(2);
                                }}>CONTINUE</button>&nbsp;&nbsp;
                                <button type="button" className="btn btn-primary sendButton" onClick={() => {
                                    setLoading(true);
                                    setGSTRPath('genrate');
                                    gotoPath('gstr3BChallan');
                                    setIsBank(false);
                                }}>CANCEL</button>
                            </div>
                        </div>
                    }
                    {
                        step === 2 && <div>
                            <div className='w-full p-6 flex'>
                                <div className='w-1/2 flex'>
                                    <div className='pl-6'>
                                        <p>Merchant ID:</p>
                                        <p>GSTN:</p>
                                        <p>IGST Amount:</p>
                                        <p>SGST Amount:</p>
                                        <p>Total Amount:</p>
                                        <p>Debit Account Number:</p>
                                    </div>
                                    <div style={{ marginLeft: '60px' }}>
                                        <p>GSTN</p>
                                        <p>{GSTUser.gstn}</p>
                                        <p>INR {(challan.data.tax.igst + challan.data.interest.igst).toFixed(2)}</p>
                                        <p>INR {getTSGST().toFixed(2)}</p>
                                        <p>INR {getTotal().toFixed(2)}</p>
                                        <p>XXXXXXXXXXXXXXXXXX</p>
                                    </div>
                                </div>
                                <div className='w-1/2 flex' style={{ borderLeft: '1px dashed black' }}>
                                    <div className='pl-6'>
                                        <p>Transaction Id:</p>
                                        <p>CPIN:</p>
                                        <p>CGST Amount:</p>
                                        <p>CESS Amount:</p>
                                        <p>Transaction Date:</p>
                                    </div>
                                    <div style={{ marginLeft: '60px' }}>
                                        <p>{tid}</p>
                                        <p>{challan.cpin}</p>
                                        <p>INR {getTCGST().toFixed(2)}</p>
                                        <p>INR {(challan.data.tax.cess + challan.data.interest.cess).toFixed(2)}</p>
                                        <p>{Moment(gDate).add(1, 'month').add(19, 'days').format('DD/MM/YYYY')}</p>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className='h-[1px] w-full bg-gray-400 rounded-full'></div>
                                <h4 className='pl-12'>Additional Details</h4>
                                <div className='w-full flex items-center justify-center'>
                                    <p>Remark</p>
                                    <input type="text" autoComplete='off' className='w-[340px] ml-6 p-2' />
                                </div>
                                <p className='text-base pl-12'>Enter your credentails to confirm the transaction</p>
                            </div>
                            <div>
                                <div className='h-[1px] w-full bg-gray-400 rounded-full'></div>
                                <h4 className='pl-12'>Confirmation Details</h4>
                                <div className='w-full flex items-center justify-center pb-20'>
                                    <p>Transaction Password*</p>
                                    <input type="password" autoComplete='off' className={`w-[340px] bg-gray-50 h-14 outline-none border rounded px-4 py-1 mt-6 ml-6 ${isError ? 'border-red-600 shadow shadow-red-100' : ''}`} onChange={handleOnChangeForm} value={passVal} />
                                </div>
                                <h4></h4>
                                <div className='p-6 float-right'>
                                    <button type="button" className="btn btn-primary sendButton" onClick={() => {
                                        submitChallan();
                                    }}>SUBMIT</button>&nbsp;&nbsp;
                                    <button type="button" className="btn btn-primary sendButton" onClick={() => {
                                        setLoading(true);
                                        setGSTRPath('genrate');
                                        gotoPath('gstr3BChallan');
                                        setIsBank(false);
                                    }}>CANCEL</button>
                                </div>
                            </div>
                        </div>
                    }
                    {
                        step === 3 && <div>
                            <div className='pl-14 py-6'>
                                <div className='flex'>
                                    <div>
                                        <p>Transaction Id:</p>
                                        <p>GSTN:</p>
                                        <p>CPIN:</p>
                                        <p>IGST Amount:</p>
                                        <p>SGST Amount:</p>
                                        <p>CGST Amount:</p>
                                        <p>CESS Amount:</p>
                                        <p>Total Amount:</p>
                                        <p>CIN:</p>
                                        <p>Transaction Reference Number:</p>
                                        <p>Status:</p>
                                        <p>Transaction Date:</p>
                                        <p>Payment Mode:</p>
                                    </div>
                                    <div style={{ marginLeft: '120px' }}>
                                        <p>{challan.tid}</p>
                                        <p>{GSTUser.gstn}</p>
                                        <p>{challan.cpin}</p>
                                        <p>INR {(challan.data.tax.igst + challan.data.interest.igst).toFixed(2)}</p>
                                        <p>INR {getTSGST().toFixed(2)}</p>
                                        <p>INR {getTCGST().toFixed(2)}</p>
                                        <p>INR {(challan.data.tax.cess + challan.data.interest.cess).toFixed(2)}</p>
                                        <p>INR {getTotal().toFixed(2)}</p>
                                        <p>ABCDXXXXXXXXXXXXXX</p>
                                        <p>XXXXXXXXX</p>
                                        <p>Success</p>
                                        <p>{Moment(gDate).add(1, 'month').add(19, 'days').format('DD/MM/YYYY hh:mm:ss')}</p>
                                        <p>Net Banking</p>
                                    </div>
                                </div>
                            </div>
                            <div className='h-[1px] w-full bg-gray-400 rounded-full'></div>
                            <div className='p-4 text-center'>
                                <p><b className='text-red-600'>PI Note: Tax Amount hs been debited. Do not close or refresh the webpage.</b></p>
                                <p><b className='text-red-600'>You are beiung navigated to Govermment of Goods and Service Tax website for completion of the transaction.</b></p>
                            </div>
                        </div>
                    }
                </div>
                <div className='h-[120ox]'>
                    <p></p>
                </div>
            </div>
        </>
    );
}
