import React from 'react';

import { useRecoilState } from 'recoil';
import { digErrorAtom } from '../../config/atoms';

import '../../../assets/styles/app.css';

export default function DigError() {

    const [dig, setDig] = useRecoilState(digErrorAtom);

    const cancel = () => {
        setDig({ title: '', msg: '', isToggle: false });
    }

    return (
        <div className='w-full h-full overflow-hidden fixed z-50 flex items-center justify-center bg-black bg-opacity-60'>
            <div id="confirmDlg" class="modal fade fade-scale in" role="dialog" style={{ display: 'block', paddingRight: '17px' }}>
                <div class="modal-dialog sweet">
                    <div class="modal-content">
                        <div class="modal-body">
                            <div class="m-icon m-warning pulseWarning">
                                <span class="micon-body pulseWarningIns"></span>
                                <span class="micon-dot pulseWarningIns"></span>
                            </div>
                            <h2>{dig.title}</h2>
                            <p>{dig.msg}</p>
                        </div>
                        <div class="modal-footer">
                            <a class="btn btn-primary" onClick={() => { cancel() }}>OK</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
