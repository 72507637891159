import React, { useState, useEffect, useRef } from 'react';

import { useRecoilState } from 'recoil';
import { pathAtom, challanAtom, gstrpathAtom, gstrUserAtom } from '../../../core/config/atoms';

import Moment from 'moment';
import html2pdf from 'html2pdf.js/dist/html2pdf';

import ServiceUtility from '../../../services/serviceUtility';

import PanelChallanPDF from './PanelChallanPDF';

export default function PanelPaidChallan() {

    const [challan] = useRecoilState(challanAtom);
    const [GSTUser] = useRecoilState(gstrUserAtom);

    const [, setPath] = useRecoilState(pathAtom);
    const [, setGSTRPath] = useRecoilState(gstrpathAtom);

    const [isPrintA, setIsPrintA] = useState(false);

    const pdfRefA = useRef(null);

    const utilService = new ServiceUtility();

    const getTCGST = () => {
        if (challan !== {}) {
            return challan.data.tax.cgst + challan.data.interest.cgst + challan.data.fees.cgst;
        } else {
            return 0;
        }
    }

    const getTSGST = () => {
        if (challan !== {}) {
            return challan.data.tax.sgst + challan.data.interest.sgst + challan.data.fees.sgst;
        } else {
            return 0;
        }
    }

    const getTotal = () => {
        if (challan !== {}) {
            let tax = challan.data.tax.igst + challan.data.tax.cgst + challan.data.tax.sgst + challan.data.tax.cess;
            let intr = challan.data.interest.igst + challan.data.interest.cgst + challan.data.interest.sgst + challan.data.interest.cess;
            let fees = challan.data.fees.cgst + challan.data.fees.sgst;
            return tax + intr + fees;
        } else {
            return 0;
        }
    }

    const gotoPath = (path) => {
        setPath(path);
    }

    const handleGeneratePDFA = async () => {
        const subComponent = pdfRefA.current;
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
        const options = {
            margin: 0.2,
            filename: `${challan.cpin}.pdf`,
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: { scale: 2 },
            jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
            preserve: true,
        };
        html2pdf().set(options).from(subComponent).save().then(() => {
            setIsPrintA(false);
        });
    };

    return (
        <>
            <div class="content-wrapper">
                <div class="container fade-ng-cloak">
                    <div class="mypage">
                        <div class="row" data-ng-controller="transctrl" data-ng-init="init('payment')">
                            <div class="col-xs-10">
                                <div data-breadcrumb="" name="Dashboard">
                                    <ol className="breadcrumb" data-ng-controller="crumbCtrl">
                                        <li>
                                            <a onClick={() => { gotoPath('gstr') }}>Dashboard</a>
                                        </li>
                                        <li>
                                            <a>Payment</a>
                                        </li>
                                        <li>
                                            Challan Receipt
                                        </li>
                                    </ol>
                                </div>
                            </div>
                            <div class="col-xs-2">
                                <div class="lang dropdown">
                                    <span class="dropdown-toggle" data-toggle="dropdown" data-ng-bind="selectedLang">English</span>
                                    <ul class="dropdown-menu lang-dpdwn">
                                        <li data-ng-repeat="language in languages">
                                            <a data-ng-click="selectLang(language.cd)" data-ng-bind="language.nm">English</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="tabpane" data-ng-show="showContent">
                            <div class="row">
                                <div class="col-xs-12">
                                    <h4 data-ng-bind="trans.HEAD_GST_PYRCPT">GOODS AND SERVICES TAX PAYMENT RECEIPT</h4>
                                </div>
                            </div>
                            <div class="tbl-format">
                                <div class="row">
                                    <div class="inner">
                                        <div class="col-sm-4 col-xs-12 ">
                                            <p data-ng-bind="trans.HEAD_CPIN">CPIN</p>
                                            <span><strong data-ng-bind="challanData.cpin">{challan.cpin}</strong></span>
                                        </div>
                                        <div class="col-sm-4 col-xs-12 ">
                                            <p data-ng-bind="trans.HEAD_PYMNT_DT">Deposit Date</p>
                                            <span><strong data-ng-bind="challanData.pymnt_dt">{Moment(challan.month).add(1, 'month').add(19, 'days').format('DD/MM/YYYY')}</strong></span>
                                        </div>
                                        <div class="col-sm-4 col-xs-12 ">
                                            <p data-ng-bind="trans.LBL_PYMNT_TM">Deposit Time</p>
                                            <span><strong data-ng-bind="challanData.pymnt_tmstmp">{Moment(challan.month).add(1, 'month').add(19, 'days').format('hh:mm:ss')}</strong></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <h4 data-ng-bind="trans.LBL_PYMNT_PAR">Payment Particulars</h4>
                            <div class="tbl-format">
                                <div class="row">
                                    <div class="inner">
                                        <div class="col-sm-4 col-xs-12 ">
                                            <p data-ng-bind="trans.LBL_CIN">CIN</p>
                                            <span><strong data-ng-bind="challanData.cin">DEMOXXXXXXXXXXXXXX</strong></span>
                                        </div>
                                        <div class="col-sm-4 col-xs-12 ">
                                            <p data-ng-bind="trans.LBL_BANK_NAME">Bank Name</p>
                                            <span><strong data-ng-bind="challanData.bank_nam">DEMO BANK</strong></span>
                                        </div>
                                        <div class="col-sm-4 col-xs-12 ">
                                            <p data-ng-bind="trans.LBL_BRN">BRN</p>
                                            <span><strong data-ng-bind="challanData.brn_num">XXXXXXXXX</strong></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <h4 data-ng-bind="trans.HEAD_TP_DETL">Details of Taxpayer</h4>
                            <div class="tbl-format">
                                <div class="row">
                                    <div class="inner">
                                        <div class="col-sm-4 col-xs-12">
                                            <p data-ng-bind="trans.LBL_GSTIN">GSTIN/Other Id</p>
                                            <span><strong data-ng-bind="challanData.gstin">{GSTUser.gstn}</strong></span>
                                        </div>
                                        <div class="col-sm-4 col-xs-12">
                                            <p data-ng-bind="trans.LBL_EMAIL">Email Address</p>
                                            <span>
                                                <strong style={{ wordWrap: 'break-word' }} data-ng-bind="challanData.email_id">{GSTUser.email[0]}XXXXX@XXXXXXXXXXXXXXXXXXom</strong>
                                            </span>
                                        </div>
                                        <div class="col-sm-4 col-xs-12">
                                            <p data-ng-bind="trans.LBL_MOBNUM">Mobile Number</p>
                                            <span><strong data-ng-bind="challanData.mobile_num">{GSTUser.mobile[0]}XXXXX{`${GSTUser.mobile[6]}${GSTUser.mobile[7]}${GSTUser.mobile[8]}${GSTUser.mobile[9]}`}</strong></span>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="inner">
                                        <div class="col-sm-4 col-xs-12">
                                            <p data-ng-bind="trans.LBL_NAME">Name</p>
                                            <span><strong data-ng-bind="challanData.reg_nam">{GSTUser.buzName}</strong></span>
                                        </div>
                                        <div class="col-sm-4 col-xs-12">
                                            <p data-ng-bind="trans.LBL_ADDR">Address</p>
                                            <span><strong data-ng-bind="challanData.address">XXXXXXXXXX {GSTUser.state}, {GSTUser.users[0].pncd}</strong></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <challan-reason-section>
                                <div data-ng-if="challanData.chln_rsn &amp;&amp; IS_CHALLAN_REASON_ENABLED">
                                    <div class="row">
                                        <div class="col-sm-4 col-xs-12">
                                            <h4 data-ng-bind="trans.HEAD_RSN_FR_CHLN">Reason For Challan</h4>
                                        </div>
                                    </div>
                                    <div class="tbl-format">
                                        <div class="row">
                                            <div class="inner">
                                                <div class="col-sm-4 col-xs-12">
                                                    <p data-ng-bind="trans.LBL_REASON">Reason</p>
                                                    <span data-ng-if="challanData.chln_rsn === 'AOP'"><strong data-ng-bind="trans.LBL_AOP">Any other payment</strong></span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </challan-reason-section>
                            <h4 data-ng-bind="trans.HEAD_BP_TAX">Details of Deposit</h4>
                            <div class="row">
                                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <div class="table-responsive">
                                        <table class="table tbl inv table-bordered exp" style={{ width: '99%', display: 'table' }}>
                                            <thead>
                                                <tr>
                                                    <th>
                                                        <p></p>
                                                    </th>
                                                    <th>
                                                        <div class="row">
                                                            <div class="col-xs-12 text-center">
                                                                <span data-ng-bind="trans.HEAD_TAX">Tax</span> <span> (₹)</span>
                                                            </div>
                                                        </div>

                                                    </th>
                                                    <th>
                                                        <div class="row">
                                                            <div class="col-xs-12 text-center">
                                                                <span data-ng-bind="trans.HEAD_INTR">Interest</span> <span> (₹)</span>
                                                            </div>
                                                        </div>

                                                    </th>
                                                    <th>
                                                        <div class="row">
                                                            <div class="col-xs-12 text-center">
                                                                <span data-ng-bind="trans.HEAD_PENALTY">Penalty</span> <span> (₹)</span>
                                                            </div>
                                                        </div>

                                                    </th>
                                                    <th>
                                                        <div class="row">
                                                            <div class="col-xs-12 text-center">
                                                                <span data-ng-bind="trans.HEAD_FEES">Fees</span> <span> (₹)</span>
                                                            </div>
                                                        </div>

                                                    </th>
                                                    <th>
                                                        <div class="row">
                                                            <div class="col-xs-12 text-center">
                                                                <span data-ng-bind="trans.HEAD_OTHER">Other</span> <span> (₹)</span>
                                                            </div>
                                                        </div>

                                                    </th>
                                                    <th>
                                                        <div class="row">
                                                            <div class="col-xs-12 text-center">
                                                                <span data-ng-bind="trans.HEAD_TOT">Total</span> <span> (₹)</span>
                                                            </div>
                                                        </div>

                                                    </th>
                                                </tr>

                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <label title="Central Goods &amp; Services Tax"><strong data-ng-bind="trans.HEAD_CGST">CGST(0005)</strong></label>
                                                    </td>

                                                    <td class="text-right">
                                                        <label data-ng-bind="challanData.cgst_tax_amt | INR:'pay'">{challan.data.tax.cgst}</label>
                                                    </td>
                                                    <td class="text-right">
                                                        <label data-ng-bind="challanData.cgst_int_amt | INR:'pay'">{challan.data.interest.cgst}</label>
                                                    </td>
                                                    <td class="text-right">
                                                        <label data-ng-bind="challanData.cgst_pen_amt | INR:'pay'">0</label>
                                                    </td>
                                                    <td class="text-right">
                                                        <label data-ng-bind="challanData.cgst_fee_amt | INR:'pay'">{challan.data.fees.cgst}</label>
                                                    </td>
                                                    <td class="text-right">
                                                        <label data-ng-bind="challanData.cgst_oth_amt | INR:'pay'">0</label>
                                                    </td>
                                                    <td class="text-right">
                                                        <label data-ng-bind="challanData.cgst_tot_amt | INR:'pay'">{getTCGST()}</label>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <label title="Integrated Goods &amp; Services Tax"><strong data-ng-bind="trans.HEAD_IGST">IGST(0008)</strong></label>
                                                    </td>
                                                    <td class="text-right">
                                                        <label data-ng-bind="challanData.igst_tax_amt | INR:'pay'">{challan.data.tax.igst}</label>
                                                    </td>
                                                    <td class="text-right">
                                                        <label data-ng-bind="challanData.igst_int_amt | INR:'pay'">{challan.data.interest.igst}</label>
                                                    </td>
                                                    <td class="text-right">
                                                        <label data-ng-bind="challanData.igst_pen_amt | INR:'pay'">0</label>
                                                    </td>
                                                    <td class="text-right">
                                                        <label data-ng-bind="challanData.igst_fee_amt | INR:'pay'">0</label>
                                                    </td>
                                                    <td class="text-right">
                                                        <label data-ng-bind="challanData.igst_oth_amt | INR:'pay'">0</label>
                                                    </td>
                                                    <td class="text-right">
                                                        <label data-ng-bind="challanData.igst_tot_amt | INR:'pay'">{(challan.data.tax.igst + challan.data.interest.igst)}</label>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <label><strong data-ng-bind="trans.HEAD_CESS">CESS(0009)</strong></label>
                                                    </td>
                                                    <td class="text-right">
                                                        <label data-ng-bind="challanData.cess_tax_amt | INR:'pay'">{challan.data.tax.cess}</label>
                                                    </td>
                                                    <td class="text-right">
                                                        <label data-ng-bind="challanData.cess_int_amt | INR:'pay'">{challan.data.interest.cess}</label>
                                                    </td>
                                                    <td class="text-right">
                                                        <label data-ng-bind="challanData.cess_pen_amt | INR:'pay'">0</label>
                                                    </td>
                                                    <td class="text-right">
                                                        <label data-ng-bind="challanData.cess_fee_amt | INR:'pay'">0</label>
                                                    </td>
                                                    <td class="text-right">
                                                        <label data-ng-bind="challanData.cess_oth_amt | INR:'pay'">0</label>
                                                    </td>
                                                    <td class="text-right">
                                                        <label data-ng-bind="challanData.cess_tot_amt | INR:'pay'">{(challan.data.tax.cess + challan.data.interest.cess)}</label>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <label title="State Goods &amp; Services Tax">
                                                            <strong>
                                                                {GSTUser.state}
                                                                {utilService.getIsState(GSTUser.state) && <span className=''>SGST(0006)</span>}
                                                                {!utilService.getIsState(GSTUser.state) && <span className=''>UTGST(0007)</span>}
                                                            </strong>
                                                        </label>
                                                    </td>
                                                    <td class="text-right">
                                                        <label data-ng-bind="challanData.sgst_tax_amt | INR:'pay'">{challan.data.tax.sgst}</label>
                                                    </td>
                                                    <td class="text-right">
                                                        <label data-ng-bind="challanData.sgst_int_amt | INR:'pay'">{challan.data.interest.sgst}</label>
                                                    </td>
                                                    <td class="text-right">
                                                        <label data-ng-bind="challanData.sgst_pen_amt | INR:'pay'">0</label>
                                                    </td>
                                                    <td class="text-right">
                                                        <label data-ng-bind="challanData.sgst_fee_amt | INR:'pay'">{challan.data.fees.sgst}</label>
                                                    </td>
                                                    <td class="text-right">
                                                        <label data-ng-bind="challanData.sgst_oth_amt | INR:'pay'">0</label>
                                                    </td>
                                                    <td class="text-right">
                                                        <label data-ng-bind="challanData.sgst_tot_amt | INR:'pay'">{getTSGST()}</label>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td colspan="2">
                                                        <label data-ng-bind="trans.HEAD_TOT_CHLNAMT">Total Challan Amount:</label>
                                                    </td>
                                                    <td colspan="5">
                                                        <span>  ₹</span>
                                                        <label data-ng-bind="challanData.total_amt |INR:'pay'">{getTotal()}</label>
                                                        <label data-ng-if="challanData.total_amt"> /-</label>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td colspan="2">
                                                        <label data-ng-bind="trans.HEAD_TOTAMT_WORDS">Total Challan Amount (In Words):</label>
                                                    </td>
                                                    <td colspan="5">
                                                        <label data-ng-bind="challanData.total_amt_word">Rupees {utilService.wordify(getTotal())}</label>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div class="tbl-format">
                                <div class="row">
                                    <div class="inner">
                                        <div class="col-sm-6 col-xs-12 ">
                                            <p data-ng-bind="trans.LBL_MODE_OF_PAY">Mode of Payment</p>
                                            <span><strong data-ng-bind="challanData.payment_mod">Net Banking</strong></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="tbl-format">
                                <div class="row">
                                    <div class="inner">
                                        <div class="col-sm-2 col-xs-12">
                                            <h4 data-ng-bind="trans.LBL_NOTE">Note</h4>
                                        </div>
                                        <div class="col-sm-10 col-xs-12">
                                            <p><strong><span>1. Status of the Transaction can be tracked under 'Track Payment Status' at GST website.</span></strong></p>
                                            <p><strong><span>2. Payment status will be set as 'Paid' for this transaction.</span></strong></p>
                                            <p><strong><span>3. This is a system generated receipt.</span></strong></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-right pad-b-20">
                                    <button type="button" title="Generate Challan" className="btn btn-primary" onClick={(e) => {
                                        setIsPrintA(true);
                                        handleGeneratePDFA();
                                    }}>DOWNLOAD</button>&nbsp;
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div ref={pdfRefA}>
                {isPrintA && <PanelChallanPDF />}
            </div>
        </>
    );
}
