import APIServiceLookUp from './serviceAPI';
import { API_URL } from './models/AppConstent';

export default class ServiceOption {
    lookupService = new APIServiceLookUp();

    async getStates(body) {
        var serviceType = "POST";
        var url = `${API_URL}location/getAllStates`;
        var token = '';
        var apiService = this.lookupService.getAPIService(serviceType);
        var response = await apiService.doCall(url, body, token);
        return response;
    }

    async getDistricts(body) {
        var serviceType = "POST";
        var url = `${API_URL}location/getAllDistricts`;
        var token = '';
        var apiService = this.lookupService.getAPIService(serviceType);
        var response = await apiService.doCall(url, body, token);
        return response;
    }

    async getLocation(body) {
        var serviceType = "POST";
        var url = `${API_URL}location/getLocationPincode`;
        var token = '';
        var apiService = this.lookupService.getAPIService(serviceType);
        var response = await apiService.doCall(url, body, token);
        return response;
    }

    async getFillterHsn(body) {
        var serviceType = "POST";
        var url = `${API_URL}options/getHsnByFillter`;
        var token = '';
        var apiService = this.lookupService.getAPIService(serviceType);
        var response = await apiService.doCall(url, body, token);
        return response;
    }

    async getFillterSac(body) {
        var serviceType = "POST";
        var url = `${API_URL}options/getSacByFillter`;
        var token = '';
        var apiService = this.lookupService.getAPIService(serviceType);
        var response = await apiService.doCall(url, body, token);
        return response;
    }

}